import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';

import { abortRefreshAccessToken } from '@/api/apiClients';
import { switchOrganization as switchOrganizationRequest } from '@/api/auth.api';
import { PayDashboardTabs } from '@/types/payDashboard.types';
import { usePartnerConfig } from './partners/usePartnerConfig';
import { useRouter } from './router.hooks';
import { useResetAccessToken } from './session.hooks';

export const useSwitchOrganization = () => {
  const setAccessToken = useResetAccessToken();
  const { goToPayDashboardTabLinkAndRefresh, generateMelioClientsLink } = useRouter();
  const { partnerConfig } = usePartnerConfig();
  const [isMelioMigrationWhitelistToBlacklistEnabled] = useFeature(
    FeatureFlags.MelioMigrationWhitelistToBlacklist,
    false,
  );

  const switchAccessToken = async (organizationId: string) => {
    abortRefreshAccessToken();
    const { accessToken, refreshToken } = await switchOrganizationRequest(organizationId);
    setAccessToken(accessToken, refreshToken!);
    return accessToken;
  };

  const switchOrganization = async ({
    organizationId,
    isAccountingFirm,
    skipPayDashboardRedirect,
  }: {
    organizationId: string;
    isAccountingFirm: boolean;
    skipPayDashboardRedirect?: boolean;
  }) => {
    let url;

    const accessToken = await switchAccessToken(organizationId);

    if (partnerConfig.getCurrentOrgUrl) {
      url = await partnerConfig.getCurrentOrgUrl({ isMelioMigrationWhitelistToBlacklistEnabled });
    }

    if (url) {
      window.location.replace(url);
      return;
    }

    if (isAccountingFirm) {
      location.href = generateMelioClientsLink(accessToken, true)!;
      return;
    }

    if (skipPayDashboardRedirect) {
      return;
    }

    goToPayDashboardTabLinkAndRefresh(PayDashboardTabs.Inbox);
  };

  return { switchOrganization };
};
