import type { Collaborator, Invitation, InvitationAction } from '../../types';

type Props = {
  actor?: Collaborator;
  invitation: Invitation;
};

export function getInvitationActions({ invitation, actor }: Props): InvitationAction[] {
  if (!actor) {
    return [];
  }

  return invitation.allowedActions;
}
