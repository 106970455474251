import React from 'react';
import { ListItem, OrderedList, Text as ChakraText, UnorderedList } from '@chakra-ui/react';
import { Accordion, Link, Text } from '@melio/penny';

import { usePartnerConfig } from '@/hooks/partners';
import { usePlatformIntl } from '@/translations/Intl';
import {
  SupportList,
  SupportParagraph,
  SupportQuestion,
} from '@/widgets/settings-page/SupportPage/SupportByPartner.types';

const ParseSupportLink = ({ href, text }: { isExternal: boolean; href: string; text: string }) => {
  return <Link href={href} label={text} newTab />;
};

const ParseSupportUnderline = ({ text }: { text: string }) => {
  return <ChakraText as="u">{text}</ChakraText>;
};

const ParseSupportList = ({ list }: { list: SupportList }) => {
  const { items, isOrdered } = list;
  const ListItems = items.map((paragraph, paragraphId) => (
    <ListItem key={paragraphId}>
      <ParseSupportParagraph paragraph={paragraph} />
    </ListItem>
  ));

  return isOrdered ? <OrderedList>{ListItems}</OrderedList> : <UnorderedList pl={4}>{ListItems}</UnorderedList>;
};

const ParseSupportParagraph = ({ paragraph }: { paragraph: SupportParagraph }) => {
  const { formatMessage } = usePlatformIntl();
  const {
    partnerConfig: {
      config: { links },
    },
  } = usePartnerConfig();

  const options: Record<string, JSX.Element> | undefined = {};

  paragraph.options?.supportUnderlines?.forEach(
    ({ underlineKey }, id) =>
      (options[`underline${id + 1}`] = <ParseSupportUnderline text={formatMessage(underlineKey)} />),
  );

  paragraph.options?.supportLists?.forEach((list, id) => (options[`list${id + 1}`] = <ParseSupportList list={list} />));

  paragraph.options?.supportLinks?.forEach(
    ({ isExternal, hrefKey, linkKey }, id) =>
      (options[`link${id + 1}`] = (
        <ParseSupportLink href={links[hrefKey]} text={formatMessage(linkKey)} isExternal={isExternal} />
      )),
  );

  return <Text textStyle="body3semi">{formatMessage(paragraph.paragraphKey, options)}</Text>;
};

type SupportQuestionsWidgetProps = { supportQuestions: SupportQuestion[] };

export const SupportQuestionsWidget = ({ supportQuestions }: SupportQuestionsWidgetProps) => {
  const { formatMessage } = usePlatformIntl();

  return (
    <Accordion
      items={supportQuestions.map((question) => ({
        title: formatMessage(question.questionKey),
        content: <ParseSupportParagraph paragraph={question.answer} />,
      }))}
    />
  );
};
