import { useMelioQueryClient, UseModelApiProps } from '@melio/api-client';
import {
  AccountsApiClient,
  GetCollaboratorResponse,
  TransferOrganizationOwnershipRequest,
} from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useIsMutating, useMutation } from 'react-query';

type Props = UseModelApiProps<typeof AccountsApiClient.getCollaborator, GetCollaboratorResponse>;

export const useTransferOwnership = (props: Props) => {
  const queryClient = useMelioQueryClient();

  const transferOwnership = useMutation(
    ['AccountsApi', props.id],
    ({ data }: { data: TransferOrganizationOwnershipRequest }) =>
      AccountsApiClient.transferOrganizationOwnership(props.id as string, data),
    {
      onSuccess: () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // add this ts ignore because the current collaborator hook is on v1 and the collaborator key doesn't exist on the api.
        void queryClient.invalidateQueries('collaborator');
      },
    }
  );

  const isUpdating = useIsMutating('AccountsApi') > 0;

  return {
    transferOwnership: transferOwnership.mutateAsync,
    isUpdating,
    _mutations: {
      transferOwnership,
    },
  };
};
