import { isAccountingFirmOrganization, useAccount, useOrganizations } from '@melio/platform-api';

export const useIsFirmOnClientOrganization = () => {
  const { data: organizations, isLoading: isLoadingOrganizations } = useOrganizations();
  const { data: currentAccount, isLoading: isLoadingAccount } = useAccount({ id: 'me' });

  const isLoading = isLoadingOrganizations || isLoadingAccount;

  const firmOrganization = organizations?.find(isAccountingFirmOrganization);

  const currentOrganizationId = currentAccount?.organizationId;
  const isFirmOnClientOrganization = !!(firmOrganization && firmOrganization.id !== currentOrganizationId); // when the current user has an organization with company type AccountingFirm, the rest of the orgs are firm clients

  return {
    isLoading,
    isFirmOnClientOrganization,
    firmOrganizationId: firmOrganization?.id,
  };
};
