import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';
import {
  ApplicationStatus,
  FinancingApplicationApprovalStatusActivity,
  FinancingApplicationFlowActivity,
} from '@melio/ap-activities';
import { useAnalyticsContext, withAnalyticsContext } from '@melio/platform-analytics';
import { usePermissions } from '@melio/platform-permissions';

const isRedirectedFromEmailCampaign = (searchParams: URLSearchParams) => searchParams.get('campaign') === 'financing';
export const FinancingApplicationRoute = withAnalyticsContext(() => {
  const { can } = usePermissions();
  const [searchParams] = useSearchParams();
  useAnalyticsContext({
    globalProperties: {
      EntryPoint: isRedirectedFromEmailCampaign(searchParams) ? 'marketing-email' : 'financing-tab',
    },
  });

  const canApplyForFinancing = can({
    subject: 'financing:application',
    action: 'create',
  });

  if (!canApplyForFinancing) {
    return <Navigate to="/pay-dashboard" />;
  }

  return (
    <Routes>
      <Route
        path="/application/*"
        element={
          <Routes>
            <Route index element={<FinancingApplicationFlowActivity />} />
            <Route
              path="/approved"
              element={<FinancingApplicationApprovalStatusActivity status={ApplicationStatus.Approved} />}
            />
            <Route
              path="/pending"
              element={<FinancingApplicationApprovalStatusActivity status={ApplicationStatus.Pending} />}
            />
            <Route
              path="/declined"
              element={<FinancingApplicationApprovalStatusActivity status={ApplicationStatus.Declined} />}
            />
          </Routes>
        }
      />
    </Routes>
  );
});
