/* eslint-disable max-lines */
import {
  AddVendorFormFields,
  ErrorTypeToErrorCodesMap,
  getErrorsByType,
  getInlineApiErrorsFields,
  getVendorNameForNotificationMessage,
  InvalidVendorBankAccountDetails,
  InvalidVendorBankAccountModal,
  useSwitchToUnmanagedForm,
  VendorFormBannerApiErrorCode,
} from '@melio/ap-widgets';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import {
  BusinessResultItem,
  CreateVendorAnalyticsMetadataFlow,
  CreateVendorParams,
  DeliveryMethod,
  isVendorBankAccountNotCreated,
  ModelError,
  useDeliveryMethodTypeOptions,
  useVendors,
  Vendor,
} from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig, usePartnerFeature } from '@melio/platform-provider';
import { useSetDocumentTitle } from '@melio/platform-sdk';
import { useSystemMessage } from '@melio/platform-utils';
import { useState } from 'react';

import { DeliveryMethodSelectionActivity } from '../delivery-methods';
import {
  AddVendorDetailsScreen,
  AddVendorScreenProps,
  SubmitTarget,
} from './screens/AddVendorDetails/AddVendorDetails.screen';
import { SearchBusinessScreen } from './screens/SearchVendor';
import { AddVendorActivityStep, AddVendorProps } from './types';

export const AddVendor: React.VFC<AddVendorProps> = withAnalyticsContext<AddVendorProps>(
  ({ setAnalyticsProperties, onClose, onDone, onError, onCreateVendorSuccess }) => {
    const [createdVendorId, setCreatedVendorId] = useState<Vendor['id']>();
    const [apiErrorsCodes, setApiErrorsCodes] = useState<Pick<ErrorTypeToErrorCodesMap, 'inline' | 'banner'>>();
    const [invalidVendorBankAccountDetails, setInvalidVendorBankAccountDetails] = useState<
      | (InvalidVendorBankAccountDetails & {
          target?: `${SubmitTarget}`;
        })
      | undefined
    >();

    const { formatMessage } = useMelioIntl();

    const [isInvalidBankAccountModalEnabled] = usePartnerFeature('InvalidVendorBankAccountModal', false);

    useSetDocumentTitle(formatMessage('activities.addVendor.screens.addVendorDetails.title'));
    setAnalyticsProperties({
      Flow: 'vendor',
    });

    const { create: createVendor, isMutating: isCreatingVendor } = useVendors({ enabled: false });
    const { data: deliveryMethodTypeOptions, isFetching } = useDeliveryMethodTypeOptions({
      vendorId: createdVendorId || '',
      enabled: !!createdVendorId,
    });

    const { onSwitchToUnmanaged, shouldSwitchToUnmanaged, resetAttemptsCount } = useSwitchToUnmanagedForm({
      onSwitch: ({ companyName, accountNumber, postalCode }) => {
        setVendorFormData({
          defaultFormValues: {
            companyName,
            accountNumber,
            confirmAccountNumber: accountNumber,
            postalCode,
          },
          managed: undefined,
        });
        setApiErrorsCodes(undefined);
      },
    });

    const [vendorFormData, setVendorFormData] = useState<Pick<AddVendorScreenProps, 'defaultFormValues' | 'managed'>>();

    const { showMessage } = useSystemMessage();

    const { track, trackMarketing } = useAnalytics();

    const {
      settings: {
        vendor: {
          collectedDetails: vendorCollectedDetails,
          createVendor: { hasSkipDeliveryMethodButton },
        },
      },
    } = useConfig();

    const [shouldStartFromSearchScreen] = useFeature<boolean>(
      FeatureFlags.IsSearchBusinessesInDirectoriesSupported,
      false
    );

    const [currentStep, goToStep] = useState<AddVendorActivityStep>(
      shouldStartFromSearchScreen ? 'SEARCH_VENDOR' : 'ADD_VENDOR_DETAILS'
    );

    const [completedBusinessSearch, setCompletedBusinessSearch] = useState(false);

    type TrackCreateVendorStatusParams = {
      vendorType: 'directory' | 'local';
      status: 'success' | 'failure';
      vendorId?: string;
      inlineErrorsFields?: (keyof AddVendorFormFields)[];
      bannerErrorsTypes?: VendorFormBannerApiErrorCode[];
    };
    const trackCreateVendorStatus = ({
      vendorType,
      status,
      vendorId,
      inlineErrorsFields,
      bannerErrorsTypes,
    }: TrackCreateVendorStatusParams) => {
      const properties = {
        PageName: 'add-a-vendor',
        Intent: 'add-a-vendor',
        VendorType: vendorType,
        Status: status,
        InlineErrorsFields: inlineErrorsFields,
        BannerErrorsTypes: bannerErrorsTypes,
        VendorCreatedOrigin: 'vendor',
        ...(vendorId ? { VendorId: vendorId } : {}),
      };

      track('Vendor', 'Status', properties);
      status === 'success' && trackMarketing('contacts_new-vendor-success', properties);
    };

    const handleCreateVendorFail = (
      submittedData: CreateVendorParams,
      isManagedVendor: boolean,
      error: ModelError,
      target?: `${SubmitTarget}`
    ) => {
      const { inline, banner } = getErrorsByType(error, !!vendorFormData?.managed?.isZipCodeNeeded);

      if (inline?.length || banner?.length) {
        trackCreateVendorStatus({
          vendorType: isManagedVendor ? 'directory' : 'local',
          status: 'failure',
          ...(inline?.length ? { inlineErrorsFields: getInlineApiErrorsFields(inline) } : {}),
          ...(banner?.length ? { bannerErrorsTypes: banner } : {}),
        });

        setApiErrorsCodes({ inline, banner });
      } else {
        // TODO: add monitor for a case where getErrorsByType return unknow key.length https://meliorisk.atlassian.net/browse/ME-41490
        showMessage({
          type: 'error',
          title: formatMessage('addVendor.createVendorToast.error', {
            companyName: submittedData.name,
          }),
          action: {
            text: formatMessage('addVendor.createVendorToast.error.action'),
            onAction: (hideMessage) => {
              hideMessage();
              handleAddVendorDetailsDone(submittedData, target);
            },
            type: 'button',
          },
        });
      }

      if (shouldSwitchToUnmanaged(error)) {
        onSwitchToUnmanaged({
          companyName: submittedData.name,
          accountNumber: submittedData.accountNumber,
          postalCode: submittedData.managed?.zipCode,
        });
      }

      onError?.(error);
    };

    const handleCreateVendorSuccess = (
      vendor: Pick<Vendor, 'id' | 'isManaged' | 'name' | 'nickname' | 'accountNumber' | 'warnings'>,
      submittedData: CreateVendorParams,
      target?: `${SubmitTarget}`,
      shouldSkipToast?: boolean
    ) => {
      const isBankAccountNotCreated = isVendorBankAccountNotCreated(vendor.warnings);
      const shouldSkipSelectDeliveryMethodsStep = vendor.isManaged || vendorCollectedDetails === 'extended';
      const submittedBankAccount = submittedData.bankAccount;

      const shouldShowInvalidBankAccountModal =
        isInvalidBankAccountModalEnabled &&
        shouldSkipSelectDeliveryMethodsStep &&
        isBankAccountNotCreated &&
        submittedBankAccount;

      if (shouldShowInvalidBankAccountModal) {
        trackCreateVendorStatus({ vendorType: vendor.isManaged ? 'directory' : 'local', status: 'success' });
        setInvalidVendorBankAccountDetails({
          vendorId: vendor.id,
          fullVendorName: getVendorNameForNotificationMessage(vendor),
          vendorName: vendor.name,
          bankAccountNumber: submittedBankAccount.accountNumber,
          target,
        });
        return;
      }

      if (!shouldSkipToast) {
        const vendorName = getVendorNameForNotificationMessage(vendor);

        onCreateVendorSuccess?.({
          type: 'success',
          title: formatMessage('addVendor.createVendorToast.success', {
            vendorName,
          }),
        });
      }

      trackCreateVendorStatus({
        vendorType: vendor.isManaged ? 'directory' : 'local',
        status: 'success',
        vendorId: vendor.id,
      });

      if (shouldSkipSelectDeliveryMethodsStep) {
        onDone(vendor.id, undefined, target);
      } else {
        setCreatedVendorId(vendor.id);
        goToStep('ADD_VENDOR_DELIVERY_METHOD');
      }
    };

    const handleAddVendorDetailsDone = (
      submittedData: CreateVendorParams,
      target?: `${SubmitTarget}`,
      shouldSkipToast?: boolean
    ) => {
      setApiErrorsCodes(undefined);

      const dataWithAnalyticsMetadata: CreateVendorParams = {
        ...submittedData,
        analyticsMetadata: {
          flow: CreateVendorAnalyticsMetadataFlow.Vendor,
        },
      };

      return createVendor(dataWithAnalyticsMetadata)
        .then((newVendor) => {
          handleCreateVendorSuccess(newVendor, submittedData, target, shouldSkipToast);
          return newVendor;
        })
        .catch((error) => handleCreateVendorFail(submittedData, !!submittedData.managed, error, target));
    };

    const handleAddDeliveryMethodSkip = () => {
      if (createdVendorId) {
        onDone(createdVendorId);
      }
    };

    const handleAddDeliveryMethodDone = (deliveryMethod: DeliveryMethod, target?: 'continue' | 'close') => {
      if (createdVendorId) {
        onDone(createdVendorId, deliveryMethod.id, target);
      }
    };

    const handleSelectOptionOnSearchScreen = (selectedBusiness: BusinessResultItem) => {
      setCompletedBusinessSearch(true);
      setVendorFormData({
        defaultFormValues: {
          companyName: selectedBusiness.business.name,
        },
        managed: {
          managedBy: selectedBusiness.directoryName,
          self: selectedBusiness.business.self,
          isZipCodeNeeded: selectedBusiness.business.merchantZipRequired,
        },
      });
      goToStep('ADD_VENDOR_DETAILS');
    };

    const handleSelectOptionOnFormScreen: AddVendorScreenProps['onSelectCompany'] = ({ companyName, managed }) => {
      setVendorFormData({
        defaultFormValues: {
          companyName,
        },
        managed,
      });
    };

    const handleCreateOption = (companyName: string) => {
      setVendorFormData({
        defaultFormValues: {
          companyName,
        },
      });
      goToStep('ADD_VENDOR_DETAILS');
    };

    const handleClearSearchBusinessField = () => {
      setVendorFormData(undefined);
      setApiErrorsCodes(undefined);
      resetAttemptsCount();
    };

    const onInvalidVendorBankAccountModalClose = () => {
      if (!invalidVendorBankAccountDetails) {
        return;
      }
      onDone(invalidVendorBankAccountDetails.vendorId, undefined, invalidVendorBankAccountDetails.target);
      setInvalidVendorBankAccountDetails(undefined);
    };

    const onInvalidVendorBankAccountModalSuccess = (vendorName: string) => {
      onCreateVendorSuccess?.({
        type: 'success',
        title: formatMessage('widgets.invalidVendorBankAccountModal.success', {
          vendorName,
        }),
      });
      onInvalidVendorBankAccountModalClose();
    };

    const handleClearCompanyNameSearchFieldOnFormScreen = () => {
      if (shouldStartFromSearchScreen) {
        goToStep('SEARCH_VENDOR');
      }
      handleClearSearchBusinessField();
    };

    switch (currentStep) {
      case 'ADD_VENDOR_DETAILS':
      default:
        return (
          <>
            <AddVendorDetailsScreen
              {...vendorFormData}
              onClose={onClose}
              isVendorSelected={completedBusinessSearch}
              onDone={handleAddVendorDetailsDone}
              onSelectCompany={handleSelectOptionOnFormScreen}
              isSaving={isCreatingVendor}
              inlineApiErrorCodes={apiErrorsCodes?.inline}
              bannerApiErrorsCodes={apiErrorsCodes?.banner}
              onClearCompanyNameSearchField={handleClearCompanyNameSearchFieldOnFormScreen}
            />
            {invalidVendorBankAccountDetails ? (
              <InvalidVendorBankAccountModal
                isOpen
                onClose={onInvalidVendorBankAccountModalClose}
                invalidVendorBankAccountDetails={invalidVendorBankAccountDetails}
                onSuccess={onInvalidVendorBankAccountModalSuccess}
              />
            ) : null}
          </>
        );

      case 'SEARCH_VENDOR':
        return (
          <SearchBusinessScreen
            onClose={onClose}
            onSelectOption={handleSelectOptionOnSearchScreen}
            onCreateOption={handleCreateOption}
            onClearField={handleClearSearchBusinessField}
            stepCompleted={completedBusinessSearch}
          />
        );

      case 'ADD_VENDOR_DELIVERY_METHOD':
        return createdVendorId ? (
          <DeliveryMethodSelectionActivity
            vendorId={createdVendorId}
            onClose={handleAddDeliveryMethodSkip}
            onBack={onClose}
            onDone={handleAddDeliveryMethodDone}
            {...(hasSkipDeliveryMethodButton ? { onSkip: handleAddDeliveryMethodSkip } : undefined)}
            origin="vendor"
            isLoading={isFetching}
            deliveryMethodTypeOptions={deliveryMethodTypeOptions}
          />
        ) : null;
    }
  }
);
