import { ModelProps, useModelApi } from '@melio/api-client';
import { ApiCreateRequest, ApiDeleteRequest, ApiGetRequest, ApiUpdateRequest } from '@melio/platform-api-axios-client';

import { AnyType, ModelParams, PromiseFunctionReturnData } from './types';
import { normalizeParams } from './utils';

export const useModel = <
  TQueryFn extends ApiGetRequest,
  TUpdateQueryFn extends ApiUpdateRequest<PromiseFunctionReturnData<TQueryFn>> = never,
  TDeleteQueryFn extends ApiDeleteRequest = never,
  TCreateQueryFn extends ApiCreateRequest = never,
  TData = PromiseFunctionReturnData<TQueryFn>,
  TUpdateVariables = RequiredParameters<TUpdateQueryFn>[1],
  TCreateVariables = Required<Parameters<TCreateQueryFn>>[0]
>({
  params,
  paramsParser,
  queryKey,
  queryFn,
  ...props
}: ModelProps<
  TQueryFn,
  TUpdateQueryFn,
  TDeleteQueryFn,
  TCreateQueryFn,
  TData,
  TUpdateVariables,
  TCreateVariables,
  ARPlatformError
> &
  ModelParams<TQueryFn>) =>
  useModelApi<
    TQueryFn,
    TUpdateQueryFn,
    TDeleteQueryFn,
    TCreateQueryFn,
    TData,
    TUpdateVariables,
    TCreateVariables,
    ARPlatformError
  >({
    ...props,
    queryKey: params ? [...(Array.isArray(queryKey) ? queryKey : [queryKey]), normalizeParams(params)] : queryKey,
    queryFn: (id: string) => (params ? queryFn(id, paramsParser ? paramsParser(params) : params) : queryFn(id)),
    // eslint-disable-next-line max-len
  } as ModelProps<TQueryFn, TUpdateQueryFn, TDeleteQueryFn, TCreateQueryFn, TData, TUpdateVariables, TCreateVariables, ARPlatformError>);

type RequiredParameters<T extends (...args: AnyType[]) => AnyType> = Required<Parameters<T>>;
