import { usePaymentsLateApprovals, usePaymentsLateApprovalsEnabled } from '@melio/ap-domain';
import { Payment, PaymentDate, usePaymentsETADates } from '@melio/platform-api';
import { convertDateToMidDay } from '@melio/platform-utils';
import { useCallback } from 'react';

export const useLatePayments = () => {
  const getPaymentsLateApprovals = usePaymentsLateApprovals();
  const getPaymentsLateApprovalsEnabled = usePaymentsLateApprovalsEnabled();
  const { getPaymentsETADatesMutation } = usePaymentsETADates({
    paymentIds: [],
    enabled: false,
  });

  const getLateAndOnTimePayments = useCallback(
    async ({
      payments,
      etaDatesApprovedByTheUser = [],
    }: {
      payments: Payment[];
      etaDatesApprovedByTheUser?: PaymentDate[];
    }) => {
      const paymentsLateApprovalsEnabled = getPaymentsLateApprovalsEnabled({ payments });
      const paymentsLateApprovals = getPaymentsLateApprovals({ payments });

      const paymentsWithDebitDateInThePast = payments.filter(
        (p) => paymentsLateApprovalsEnabled[p.id]?.isEnabled && paymentsLateApprovals[p.id]?.isDebitDateAfterToday
      );

      const paymentsWithDebitDateToday = payments.filter(
        (p) => paymentsLateApprovalsEnabled[p.id]?.isEnabled && paymentsLateApprovals[p.id]?.isDebitDateToday
      );

      const paymentsWithDebitDateInTheFuture = payments.filter(
        (p) =>
          !paymentsWithDebitDateInThePast.find((pInPast) => pInPast.id === p.id) &&
          !paymentsWithDebitDateToday.find((pToday) => pToday.id === p.id)
      );

      const paymentsThatRequiresETADates = [...paymentsWithDebitDateToday, ...paymentsWithDebitDateInThePast].map(
        (p) => p.id
      );

      let paymentsEtaDates: PaymentDate[] = [];
      if (paymentsThatRequiresETADates.length) {
        paymentsEtaDates = await getPaymentsETADatesMutation({ paymentIds: paymentsThatRequiresETADates });
      }

      const paymentsWithDebitDateTodayAndEtaMinDateTodayOrApprovedByUser = paymentsWithDebitDateToday.filter((p) => {
        const curEtaDates = paymentsEtaDates.find((eta) => eta.paymentId === p.id);
        if (!curEtaDates) {
          throw new Error(`missing eta dates for ${p.id}`);
        }
        const curEtaDatesApprovedByTheUser = etaDatesApprovedByTheUser.find((eta) => eta.paymentId === p.id);
        return (
          convertDateToMidDay(p.scheduledDate).getTime() >= curEtaDates.minScheduledDate.getTime() ||
          (curEtaDatesApprovedByTheUser &&
            curEtaDates.minScheduledDate.getTime() === curEtaDatesApprovedByTheUser.minScheduledDate.getTime())
        );
      });

      const paymentsWithDebitDateInThePastApprovedByUser = paymentsWithDebitDateInThePast.filter((p) => {
        const curEtaDates = paymentsEtaDates.find((eta) => eta.paymentId === p.id);
        if (!curEtaDates) {
          throw new Error(`missing eta dates for ${p.id}`);
        }
        const curEtaDatesApprovedByTheUser = etaDatesApprovedByTheUser.find((eta) => eta.paymentId === p.id);
        return (
          curEtaDatesApprovedByTheUser &&
          curEtaDates.minScheduledDate.getTime() === curEtaDatesApprovedByTheUser.minScheduledDate.getTime()
        );
      });

      const onTimePayments = [
        ...paymentsWithDebitDateInTheFuture,
        ...paymentsWithDebitDateTodayAndEtaMinDateTodayOrApprovedByUser,
        ...paymentsWithDebitDateInThePastApprovedByUser,
      ];

      const latePayments = [...paymentsWithDebitDateInThePast, ...paymentsWithDebitDateToday].filter(
        (p) => !onTimePayments.find((onTimePayment) => onTimePayment.id === p.id)
      );

      return { onTimePayments, latePayments };
    },
    [getPaymentsETADatesMutation, getPaymentsLateApprovals, getPaymentsLateApprovalsEnabled]
  );

  return { getLateAndOnTimePayments };
};
