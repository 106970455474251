import { useMelioIntl } from '@melio/ar-domain';

import { Filter } from '../types';

export const useFiltersLabel = (): Record<Filter, string> => {
  const { formatMessage } = useMelioIntl();

  return {
    all: formatMessage('ar.dashboard.activities.invoiceTable.filters.all.label'),
    draft: formatMessage('ar.dashboard.activities.invoiceTable.filters.draft.label'),
    paid: formatMessage('ar.dashboard.activities.invoiceTable.filters.paid.label'),
    overdue: formatMessage('ar.dashboard.activities.invoiceTable.filters.overdue.label'),
    open: formatMessage('ar.dashboard.activities.invoiceTable.filters.open.label'),
    canceled: formatMessage('ar.dashboard.activities.invoiceTable.filters.canceled.label'),
  };
};
