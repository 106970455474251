import { Group } from '@melio/penny';

import { ModalContent, useAccountCentricViewCase } from '@/hooks/useAccountCentricViewCase';
import { BannerTypeContent, BannerTypeContentProps } from './modal-content/BannerTypeContent';
import { DescriptionTypeContent, DescriptionTypeContentProps } from './modal-content/DescriptionTypeContent';

export const AccountCentricViewMessagingModalContent = () => {
  const { modalContent } = useAccountCentricViewCase();

  const getModalContentComponent = (section: ModalContent) => {
    const { key, ...props } = section;
    switch (section.type) {
      case 'banner':
        return <BannerTypeContent key={key} {...(props as BannerTypeContentProps)} />;
      case 'description':
      default:
        return <DescriptionTypeContent key={key} {...(props as DescriptionTypeContentProps)} />;
    }
  };

  return (
    <Group spacing="s" variant="vertical" data-testid="account-centric-view-modal-content">
      {modalContent.map(getModalContentComponent)}
    </Group>
  );
};
