import { PaymentRequest, usePaymentRequest } from '@melio/platform-api';
import { useOneTimeEffect } from '@melio/platform-utils';

import { NewSinglePaymentStepLayout } from '../NewSinglePaymentStepLayout';

export type ApprovePaymentRequestActivityProps = {
  paymentRequestId: string;
  onDone: (paymentRequest: PaymentRequest) => void;
  onError?: (err: unknown) => void;
};

export const ApprovePaymentRequestActivity = ({
  paymentRequestId,
  onDone,
  onError,
}: ApprovePaymentRequestActivityProps) => {
  const { approve } = usePaymentRequest({ id: paymentRequestId, enabled: false });

  useOneTimeEffect(
    () => {
      const approvePaymentRequest = async () => {
        try {
          const approvedPaymentRequest = await approve();
          onDone(approvedPaymentRequest);
        } catch (err) {
          onError?.(err);
        }
      };
      approvePaymentRequest();
    },
    () => true,
    [approve, onDone]
  );

  return <NewSinglePaymentStepLayout isLoading />;
};
