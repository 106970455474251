import { Container, Form, Group, Text, Typography } from '@melio/penny';
import { FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMemo } from 'react';

import { BillingAccountRegisterFieldFn, MultiLineOptionType, RenderOptionType } from '../types';
import { generateFundingSourcesIcons, getFundingSourcesOptions } from '../utils';

type PaymentMethodsDropdownProps = {
  fundingSources: FundingSource[];
  registerField: BillingAccountRegisterFieldFn;
};
export const PaymentMethodsDropdown = ({ fundingSources, registerField }: PaymentMethodsDropdownProps) => {
  const { formatMessage } = useMelioIntl();

  const options = getFundingSourcesOptions(fundingSources);
  const generatedIcons = useMemo(() => generateFundingSourcesIcons(fundingSources), [fundingSources]);
  const getIcon = (option: MultiLineOptionType) => generatedIcons.find((iconsFs) => option.value === iconsFs.id)?.icon;

  const MultiLineOption = ({ option }: { option: MultiLineOptionType }) => (
    <Group width="full" alignItems="center">
      {getIcon(option)}
      <Group variant="vertical" alignItems="flex-start" spacing="none">
        <Text textStyle="body3Semi" shouldSupportEllipsis color="inherit">
          {option.label}
        </Text>
        <Typography.Description label={option.description} isDisabled={option.disabled?.isDisabled} />
      </Group>
    </Group>
  );

  const valueRenderer = (option: RenderOptionType) => (
    <Container paddingY="s">
      <MultiLineOption option={option as MultiLineOptionType} />
    </Container>
  );

  const optionRenderer = (option: RenderOptionType) => (
    <Group width="full" justifyContent="space-between" alignItems="flex-start">
      <MultiLineOption option={option as MultiLineOptionType} />
    </Group>
  );

  return (
    <Form data-testid="subscription-upgrade-payment-methods">
      <Form.SelectNew
        {...registerField('fundingSourceId')}
        labelProps={{ label: formatMessage('activities.subscription.upgradeModal.billingAccount') }}
        options={options}
        optionRenderer={optionRenderer}
        shouldHideClearButton
        valueRenderer={valueRenderer}
        data-testid="subscription-upgrade-payment-methods-dropdown"
      />
    </Form>
  );
};
