import { Box } from '@chakra-ui/react';
import { Container, Group, Loader, OnSubmissionStateChange, Text } from '@melio/penny';
import { UpdateVendorParams, useVendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';
import { useEffect, useMemo, useRef } from 'react';

import { VendorFormInlineApiErrorCode } from '../types';
import {
  MissingVendorInfoDrawerDetails,
  MissingVendorInfoDrawerDetailsHandler,
} from './components/MissingVendorInfoDrawerDetails/MissingVendorInfoDrawerDetails';
import { MissingVendorInfoDrawerPaymentsOverview } from './components/MissingVendorInfoDrawerPaymentsOverview';
import { MissingVendorInfoExtendedUnmanagedFields } from './components/MissingVendorInfoExtendedUnmanagedForm/types';

type MissingVendorInfoDrawerBodyProps = {
  vendorId?: string;
  onSubmissionStateChange: OnSubmissionStateChange<MissingVendorInfoExtendedUnmanagedFields>;
  onSubmit: (data: UpdateVendorParams) => Promise<void>;
  inlineApiErrorCodes?: VendorFormInlineApiErrorCode[];
  loadingContainerId?: string;
  linkToUnpaidBills?: string;
};

export const MissingVendorInfoDrawerBody = ({
  vendorId,
  onSubmissionStateChange,
  onSubmit,
  inlineApiErrorCodes,
  linkToUnpaidBills,
}: MissingVendorInfoDrawerBodyProps) => {
  const { formatMessage } = useMelioIntl();

  const vendorDetailsRef = useRef<MissingVendorInfoDrawerDetailsHandler>(null);

  useEffect(() => {
    vendorDetailsRef.current?.scrollIntoView();
  }, []);

  const {
    data: vendor,
    isLoading: isVendorLoading,
    isMutating,
  } = useVendor({ id: vendorId, refetchOnMount: 'always', suspense: true, useErrorBoundary: false });

  const defaultValues = useMemo(
    () => ({
      companyName: vendor?.name || '',
      accountNumber: '',
      address: vendor?.contact.address || null,
      fullName: vendor?.contact.name || '',
      email: vendor?.contact.email || '',
      phone: vendor?.contact.phoneNumber || '',
      nickname: '',
      postalCode: vendor?.contact.address?.postalCode,
      city: vendor?.contact.address?.city,
      state: vendor?.contact.address?.state,
      line1: vendor?.contact.address?.line1,
      line2: vendor?.contact.address?.line2,
      bankRoutingNumber: '',
      bankAccountNumber: '',
    }),
    [vendor]
  );

  if (isVendorLoading) {
    return <Loader />;
  }
  if (!vendor) {
    return (
      <Box
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        data-testid="vendor-not-found-message"
      >
        <Text textStyle="body4" color="global.neutral.800">
          {formatMessage('widgets.missingVendorInfoDrawer.body.notFound')}
        </Text>
      </Box>
    );
  }

  return (
    <Container overflow="initial">
      <Group variant="vertical" spacing="l" hasDivider>
        <MissingVendorInfoDrawerPaymentsOverview linkToUnpaid={linkToUnpaidBills} vendor={vendor} />
        <MissingVendorInfoDrawerDetails
          vendorId={vendor.id}
          ref={vendorDetailsRef}
          onSubmit={onSubmit}
          onSubmissionStateChange={onSubmissionStateChange}
          defaultValues={defaultValues}
          isSaving={isMutating}
          inlineApiErrorCodes={inlineApiErrorCodes}
        />
      </Group>
    </Container>
  );
};
