import { IconButton, Tooltip, useToast } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect, useState } from 'react';

type CopyOwnerEmailToClipboradButtonProps = {
  onCopy?: () => void;
  ownerEmail?: string | null;
};
export const CopyOwnerEmailToClipboradButton = ({ onCopy, ownerEmail }: CopyOwnerEmailToClipboradButtonProps) => {
  const { formatMessage } = useMelioIntl();
  const [showCopiedTooltip, setShowCopiedTooltip] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowCopiedTooltip(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [showCopiedTooltip]);

  const copyEmailToClipboard = (email: string) => {
    navigator.clipboard
      .writeText(email)
      .then(() => {
        setShowCopiedTooltip(true);
      })
      .catch(() => onCopyEmailError());
  };

  const onCopyEmailError = () => {
    toast({
      type: 'error',
      title: formatMessage('activities.subscription.requestSubscriptionUpgradeModal.copyEmailToClipboard.error'),
      duration: 3000,
      position: 'bottom',
      containerStyle: { paddingBottom: '48px' },
    });
  };

  return (
    <Tooltip
      placement="top"
      isOpen={showCopiedTooltip || undefined}
      label={
        showCopiedTooltip
          ? formatMessage('activities.subscription.requestSubscriptionUpgradeModal.copyEmailToClipboard.success')
          : formatMessage('activities.subscription.requestSubscriptionUpgradeModal.copyEmailToClipboard.prompt')
      }
    >
      <IconButton
        variant="naked"
        icon="duplicate"
        onClick={() => {
          copyEmailToClipboard(ownerEmail || '');
          onCopy?.();
        }}
      />
    </Tooltip>
  );
};
