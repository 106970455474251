import { atom, selector, useSetRecoilState } from 'recoil';

import { ConfirmationBarModelButtonPorps, ConfirmationBarModelType } from './ConfirmationBar.types';

export const confirmationBarState = atom<ConfirmationBarModelType>({
  key: 'confirmationBar',
  default: {
    submitButtonProps: {
      text: '',
      isDisabled: false,
    },
    cancelButtonProps: {
      text: '',
      isDisabled: false,
    },
    isOpen: false,
  },
});

export const confirmationBarIsOpenSelector = selector({
  key: 'confirmationBarIsOpenSelector',
  get: ({ get }) => {
    const confirmationBar = get(confirmationBarState);
    return confirmationBar.isOpen;
  },
});

export const confirmationBarSubmitButtonPropsSelector = selector({
  key: 'confirmationBarSubmitButtonPropsSelector',
  get: ({ get }) => {
    const confirmationBar = get(confirmationBarState);
    return confirmationBar.submitButtonProps;
  },
});

export const confirmationBarCancelButtonPropsSelector = selector({
  key: 'confirmationBarCancelButtonPropsSelector',
  get: ({ get }) => {
    const confirmationBar = get(confirmationBarState);
    return confirmationBar.cancelButtonProps;
  },
});

export const useShowConfirmationBar = () => {
  const confirmationBar = useSetRecoilState(confirmationBarState);
  return (isOpen: boolean) =>
    confirmationBar((state) => {
      return { ...state, isOpen };
    });
};

export const useSetConfirmationBarSubmitButtonProps = () => {
  const confirmationBar = useSetRecoilState(confirmationBarState);
  return (submitButtonProps: ConfirmationBarModelButtonPorps) =>
    confirmationBar((state) => {
      return {
        ...state,
        submitButtonProps: {
          ...state.submitButtonProps,
          ...submitButtonProps,
        },
      };
    });
};

export const useSetConfirmationBarCancelButtonProps = () => {
  const confirmationBar = useSetRecoilState(confirmationBarState);
  return (cancelButtonProps: ConfirmationBarModelButtonPorps) =>
    confirmationBar((state) => {
      return {
        ...state,
        cancelButtonProps: {
          ...state.cancelButtonProps,
          ...cancelButtonProps,
        },
      };
    });
};
