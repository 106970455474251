import { isString } from 'lodash';
import { useEffect, VFC } from 'react';

import { NavigateOptions, useNavigate } from './useNavigate';

export type NavigateProps = NavigateOptions & {
  to: string;
};
export const Navigate: VFC<NavigateProps> = ({ to, ...options }) => {
  const navigate = useNavigate();
  useEffect(() => navigate(to, options), []); // eslint-disable-line react-hooks/exhaustive-deps
  return null;
};

/**
 * Adds a wildcard to each value in the input object.
 * @param routes - The input object.
 * @returns An object with the same keys as the input object, but with a wildcard appended to each value.
 */
export function addWildcardToRoutes<T extends Record<string, string>>(routes: T) {
  return Object.entries(routes).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: value ? `${value}/*` : '*' }),
    {} as AddWildcard<T>
  );
}
type AddWildcard<T extends Record<string, string>> = {
  [K in keyof T]: T[K] extends '' ? '*' : `${T[K]}/*`;
};

export function addPrefixToRoutes<T extends Record<string, string>, TPrefix extends string | void>(
  routes: T,
  prefix: TPrefix
) {
  return Object.entries(routes).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: isString(prefix) ? `${prefix}/${value}` : value }),
    {} as AddPrefix<T, TPrefix>
  );
}
type AddPrefix<T extends Record<string, string>, TPrefix extends string | void> = {
  [K in keyof T]: TPrefix extends string ? `${TPrefix}/${T[K]}` : T[K];
};
