import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { PaymentSchedulingPreference, useConfig } from '@melio/platform-provider';
import { useDateUtils } from '@melio/platform-utils';
import { addMonths } from 'date-fns';

export const usePaymentSchedulingPreference = () => {
  const { createDate } = useDateUtils();
  const {
    settings: {
      payment: {
        scheduling: { scheduleBy, maxDeliveryDateInMonths },
      },
    },
  } = useConfig();

  const [useDeliveryDateSchedulingPreference] = useFeature<boolean>(
    FeatureFlags.UseDeliveryDateSchedulingPreference,
    false
  );
  const [isSelectDeliveryDateFeatureEnabled] = useFeature<boolean>(
    FeatureFlags.SelectDeliveryDateFeatureEnabled,
    false
  );

  const schedulingPreference = isSelectDeliveryDateFeatureEnabled
    ? scheduleBy
    : PaymentSchedulingPreference.ByScheduleDate;

  const selectedPreference = useDeliveryDateSchedulingPreference
    ? PaymentSchedulingPreference.ByDeliveryDate
    : schedulingPreference;

  return {
    scheduleBy: selectedPreference,
    isByDeliveryDate: selectedPreference === PaymentSchedulingPreference.ByDeliveryDate,
    isByScheduleDate: selectedPreference === PaymentSchedulingPreference.ByScheduleDate,
    maxDeliveryDate: maxDeliveryDateInMonths ? addMonths(createDate(), maxDeliveryDateInMonths) : undefined,
  };
};
