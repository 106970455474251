import { Box } from '@chakra-ui/react';
import { Group, Icon, Text, Tooltip } from '@melio/penny';
import React from 'react';

export const CurrentPlanCell = ({
  label,
  tooltipLabel,
  tooltipPreTitle,
  children,
}: {
  label: string;
  tooltipLabel?: string;
  tooltipPreTitle?: string;
  children: React.ReactNode;
}) => (
  <Group width="full" variant="vertical" alignItems="flex-start" spacing="xxs">
    {/* @ts-expect-error group does not support color but we need it to in order for the icon to inherit it */}
    <Group spacing="xs" color="global.neutral.800">
      <Text textStyle="body4Semi" color="global.neutral.800">
        {label}
      </Text>
      {tooltipLabel && (
        <Tooltip
          label={tooltipLabel}
          shouldAddTriggerFocus
          preTitle={tooltipPreTitle}
          data-testid="current-plan-tooltip"
        >
          <Box as={Icon} type="info" size="small" />
        </Tooltip>
      )}
    </Group>
    {children}
  </Group>
);
