import { union } from 'lodash';
import { useRecoilState, useRecoilValue } from 'recoil';

import { GroupItemCard } from '@/cl/components/PayCard/PayCard.component';
import {
  payDashboardItemSelectionsSelector,
  payDashboardQuerySearchSelector,
} from '@/store/PayDashboard/PayDashboards.model';
import { usePlatformIntl } from '@/translations/Intl';
import { GroupItemType } from '@/types/payDashboard.types';
import { BasePaymentRequestCardProps } from '../types/PaymentCard.types';
import { useTrackBatchSelection } from '../useTrackBatchSelection';

export const PaymentRequestPayCard = ({ paymentRequest }: BasePaymentRequestCardProps) => {
  const { formatMessage, formatDate } = usePlatformIntl();
  const highlightedText = useRecoilValue(payDashboardQuerySearchSelector);
  const [itemSelections, setItemSelections] = useRecoilState(payDashboardItemSelectionsSelector);
  const { trackBatchSelection } = useTrackBatchSelection();

  const { dueDate, invoiceNumber, totalAmount, vendor } = paymentRequest;

  const dateText = formatMessage('widgets.paymentRequestCard.dueDate', {
    dueDate: formatDate(dueDate, {
      dateStyle: 'medium',
    }),
  });

  const handleItemChecked = (checked: boolean) => {
    trackBatchSelection({
      PaymentRequestId: paymentRequest.id,
      VendorId: paymentRequest.vendorId,
      Amount: totalAmount,
      DueDate: dueDate,
      selected: itemSelections.length,
      action: checked ? 'add' : 'remove',
    });

    setItemSelections((currVal) =>
      checked ? union(currVal, [paymentRequest.id]) : currVal.filter((id) => id !== paymentRequest.id),
    );
  };

  return (
    <GroupItemCard
      itemType={GroupItemType.PAYMENT_REQUEST}
      vendorName={vendor?.name || ''}
      topTag={null}
      bodyTag={{
        tagText: formatMessage('widgets.paymentRequestCard.badge'),
        status: 'neutral',
      }}
      amount={totalAmount}
      dateText={dateText}
      invoiceNumber={invoiceNumber}
      highlightedText={highlightedText}
      isChecked={itemSelections.includes(paymentRequest.id)}
      onCheck={handleItemChecked}
    />
  );
};

PaymentRequestPayCard.displayName = 'PaymentRequestCard';
