import { ModalProps, StatusModal, Text } from '@melio/penny';
import { useAnalytics, useAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';

export type BillWillBeFullyPaidModalProps = Pick<ModalProps, 'isOpen' | 'onClose'> & {
  newAmount: number;
  onSubmit?: VoidFunction;
  isSubmitting?: boolean;
};
export const BillWillBeFullyPaidModal: React.VFC<BillWillBeFullyPaidModalProps> = (props) => {
  const { isOpen, newAmount, onClose, onSubmit } = props;
  const { track } = useAnalytics();
  const { formatMessage, formatCurrency } = useMelioIntl();

  const handleDialogClose = () => {
    track('Dashboard', 'Click', {
      Cta: 'exit',
    });
    onClose();
  };

  const handleSubmit = () => {
    track('Bill', 'Click', {
      Cta: 'update-bill-amount',
    });
    onSubmit?.();
  };

  useAnalyticsContext({
    globalProperties: { PageName: 'bill-will-be-paid-in-full', Intent: 'bill-edit' },
  });

  useEffect(() => {
    isOpen && track('Bill', 'View');
  }, [isOpen, track]);

  return (
    <StatusModal
      header={formatMessage('activities.payDashboard.billsTab.billWillBeFullyPaidModal.header')}
      variant="warning"
      isOpen={isOpen}
      onClose={handleDialogClose}
      primaryButton={{
        label: formatMessage('activities.payDashboard.billsTab.billWillBeFullyPaidModal.confirmButton'),
        onClick: handleSubmit,
        variant: 'primary',
      }}
      data-testid="bill-will-be-fully-paid-modal"
    >
      <Text>
        {formatMessage('activities.payDashboard.billsTab.billWillBeFullyPaidModal.description', {
          amount: formatCurrency(newAmount),
        })}
      </Text>
    </StatusModal>
  );
};

BillWillBeFullyPaidModal.displayName = 'BillWillBeFullyPaidModal';
