import { useMelioIntl } from '@melio/platform-i18n';
import { pickBy } from 'lodash';
import { object, SchemaOf, string } from 'yup';

import { InternationalVendorDetailsFormFields } from './InternationalVendorDetailsForm.widget.types';

const ONLY_NUMBERS_PATTERN = /^(?![0-9]+$).*$/;

export const useInternationalVendorDetailsFormSchema = (showFullAddress: boolean) => {
  const { formatMessage } = useMelioIntl();

  const schemaFields: Array<keyof InternationalVendorDetailsFormFields> = ['legalBusinessName', 'fullName', 'line1'];
  if (showFullAddress) {
    schemaFields.push('line2', 'city', 'state', 'country', 'postalCode');
  }

  return object().shape(
    pickBy(
      {
        legalBusinessName: string()
          .required(formatMessage(`widgets.InternationalVendorDetailsForm.legalBusinessName.validation.required`))
          .matches(
            ONLY_NUMBERS_PATTERN,
            formatMessage('widgets.InternationalVendorDetailsForm.legalBusinessName.validation.numbersOnly')
          ),
        fullName: string().required(
          formatMessage(`widgets.InternationalVendorDetailsForm.fullName.validation.required`)
        ),
        line1: string().required(formatMessage(`widgets.InternationalVendorDetailsForm.line1.validation.required`)),
        line2: string(),
        city: string().required(formatMessage(`widgets.InternationalVendorDetailsForm.city.validation.required`)),
        state: string().required(formatMessage(`widgets.InternationalVendorDetailsForm.state.validation.required`)),
        country: string().required(formatMessage(`widgets.InternationalVendorDetailsForm.country.validation.required`)),
        postalCode: string().required(
          formatMessage(`widgets.InternationalVendorDetailsForm.postalCode.validation.required`)
        ),
      },
      (_, key) => schemaFields.includes(key as keyof InternationalVendorDetailsFormFields)
    )
  ) as unknown as SchemaOf<InternationalVendorDetailsFormFields>;
};
