import { usePaymentActions } from '@melio/ap-domain';
import { NakedButton, Table } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { BillSubscriptionApprovalDecisionStatusEnum, Payment, PaymentStatusEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { usePaymentStatus } from './PaymentsTabStatusCell';

type Props = {
  payment: Payment;
  onViewClick: (id: Payment['id']) => void;
};

export const PaymentsTabActionButtonCell = ({ payment, onViewClick }: Props) => {
  const { label, ariaLabel, handleActionClick } = useAction({ payment, onViewClick });

  const onClick = () => {
    handleActionClick();
  };

  return (
    <Table.Cell textAlign="end">
      <NakedButton
        variant="secondary"
        size="medium"
        data-testid={`payments-tab-action-cell-${payment.id}`}
        onClick={onClick}
        label={label}
        aria-label={ariaLabel}
      />
    </Table.Cell>
  );
};

const useAction = ({
  payment,
  onViewClick,
}: {
  payment: Payment;
  onViewClick: (id: Payment['id']) => void;
}): { label: string; ariaLabel: string; handleActionClick: VoidFunction } => {
  const { formatMessage, formatDate, formatCurrency } = useMelioIntl();
  const allowedActions = usePaymentActions(payment);
  const { track } = useAnalytics();
  const getPaymentStatus = usePaymentStatus();
  const { label: paymentStatusLabel } = getPaymentStatus(payment);

  const isPaymentRelatedToPendingApprovalBillSubscription =
    payment.subscriptionOccurrence?.billSubscription?.approvalDecisionStatus ===
    BillSubscriptionApprovalDecisionStatusEnum.Pending;

  const ariaLabelData = {
    vendorName: payment.vendor?.name,
    paymentCreatedByName: `${payment.createdBy?.firstName ?? ''} ${payment.createdBy?.lastName ?? ''}`,
    formattedScheduleDate: formatDate(payment.scheduledDate),
    formattedPaymentStatusLabel: paymentStatusLabel,
    formattedPaymentAmount: formatCurrency(payment.amount),
  };

  if (allowedActions.actions.approvalDecision) {
    return {
      label: isPaymentRelatedToPendingApprovalBillSubscription
        ? formatMessage('activities.payDashboard.paymentsTab.table.cells.actions.approveAll')
        : formatMessage('activities.payDashboard.paymentsTab.table.cells.actions.approve'),
      ariaLabel: isPaymentRelatedToPendingApprovalBillSubscription
        ? formatMessage('activities.payDashboard.paymentsTab.table.cells.actions.approveAll.ariaLabel', ariaLabelData)
        : formatMessage('activities.payDashboard.paymentsTab.table.cells.actions.approve.ariaLabel', ariaLabelData),
      handleActionClick: () => {
        track('Dashboard', 'Click', {
          Cta: 'approve',
          Intent: 'approve-payment',
        });
        onViewClick(payment.id);
      },
    };
  } else if (payment.status === PaymentStatusEnum.Failed) {
    const isResolveCTA = allowedActions.actions.resolveFailedToCollect || allowedActions.actions.resolveFailedToDeliver;
    if (isResolveCTA) {
      return {
        label: formatMessage('activities.payDashboard.paymentsTab.table.cells.actions.resolve'),
        ariaLabel: formatMessage(
          'activities.payDashboard.paymentsTab.table.cells.actions.resolve.ariaLabel',
          ariaLabelData
        ),
        handleActionClick: () => {
          track('Dashboard', 'Click', {
            Cta: 'resolve',
            Intent: 'view-payment',
          });
          onViewClick(payment.id);
        },
      };
    } else {
      return {
        label: formatMessage('activities.payDashboard.paymentsTab.table.cells.actions.review'),
        ariaLabel: formatMessage(
          'activities.payDashboard.paymentsTab.table.cells.actions.review.ariaLabel',
          ariaLabelData
        ),
        handleActionClick: () => {
          track('Dashboard', 'Click', {
            Cta: 'review',
            Intent: 'view-payment',
          });
          onViewClick(payment.id);
        },
      };
    }
  }
  return {
    label: payment.markedAsPaid
      ? formatMessage('activities.payDashboard.paymentsTab.table.cells.actions.view.details')
      : formatMessage('activities.payDashboard.paymentsTab.table.cells.actions.view'),
    ariaLabel: payment.markedAsPaid
      ? formatMessage('activities.payDashboard.paymentsTab.table.cells.actions.view.details.ariaLabel', ariaLabelData)
      : formatMessage('activities.payDashboard.paymentsTab.table.cells.actions.view.ariaLabel', ariaLabelData),
    handleActionClick: () => {
      track('Dashboard', 'Click', {
        Cta: 'view-payment',
        Intent: 'view-payment',
      });
      onViewClick(payment.id);
    },
  };
};
