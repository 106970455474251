import { SyncNowWidget } from '@melio/ap-widgets';
import { AccountingPlatform } from '@melio/platform-api';
import { usePermissions } from '@melio/platform-permissions';

import { AccountingPlatformConnectButton } from './AccountingPlatformConnectButton';

type Props = {
  accountingPlatform?: AccountingPlatform;
  analyticsProperties: Record<string, string>;
  goToSettingsAccountSoftware: VoidFunction;
};

export const SyncNowButton = ({ accountingPlatform, analyticsProperties, goToSettingsAccountSoftware }: Props) => {
  const { can } = usePermissions();

  if (accountingPlatform && can({ subject: 'accountingPlatform:sync', action: 'create' })) {
    return <SyncNowWidget accountingPlatform={accountingPlatform} analyticsProperties={analyticsProperties} />;
  }

  return (
    <AccountingPlatformConnectButton
      analyticsProperties={analyticsProperties}
      onAccountingPlatformConnect={goToSettingsAccountSoftware}
    />
  );
};
