import { ApiError, BillSubscriptionEndPolicyEnum, useBillSubscription, usePayment } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';

import { PaymentFlowSubmitFields } from '../types';

export function useCreatePaymentSubmit({
  billId,
  onError,
}: {
  billId: string | undefined;
  onError?: (error: ApiError<string>, payload: { fundingSourceId: string }) => void;
}) {
  const [addDeliveryDateToCreateAndUpdate] = useFeature<boolean>(
    FeatureFlags.AddDeliveryDateToCreateAndUpdatePayment,
    false
  );

  const {
    _mutations: {
      create: { data: payment, mutateAsync: createPayment, isLoading: isCreatingPayment, error: paymentError },
    },
  } = usePayment({ enabled: false, id: 'useCreatePaymentSubmitPaymentId', onCreateError: onError });

  const {
    _mutations: {
      create: {
        data: billSubscription,
        mutateAsync: createBillSubscription,
        isLoading: isCreatingBillSubscription,
        error: billSubscriptionError,
      },
    },
  } = useBillSubscription({ enabled: false, id: 'useCreatePaymentSubmitBillSubscriptionId', onCreateError: onError });

  const submit = (formFields: PaymentFlowSubmitFields) => {
    const {
      vendorId,
      deliveryMethodId,
      fundingSourceId,
      scheduleDate,
      amountToPay,
      deliveryPreference,
      recurrenceType,
      etaDetails,
    } = formFields;
    if (!vendorId || !deliveryMethodId || !fundingSourceId || !scheduleDate || !amountToPay || !deliveryPreference) {
      throw new Error('cannot create payment');
    }

    const amount = Number(amountToPay);
    const scheduledDate = scheduleDate.toISOString();

    if (recurrenceType === 'recurring') {
      const {
        intervalType,
        startDate,
        endPolicy,
        endDate,
        scheduleDate,
        numOfOccurrences,
        lastAmount: _lastAmount,
      } = formFields;
      if (
        !startDate ||
        !intervalType ||
        (endPolicy === BillSubscriptionEndPolicyEnum.EndDate && !endDate) ||
        (endPolicy === BillSubscriptionEndPolicyEnum.NumOfOccurrences && !numOfOccurrences)
      ) {
        throw new Error('cannot create payment');
      }

      const lastAmount = _lastAmount ? Number(_lastAmount) : undefined;
      return createBillSubscription({
        vendorId,
        amount,
        currency: 'USD',
        fundingSourceId,
        deliveryMethodId,
        memoToVendor: formFields.noteToVendor,

        intervalType,
        startDate,
        debitDate: scheduleDate,
        ...(endPolicy === BillSubscriptionEndPolicyEnum.NoEndDate
          ? {
              endPolicy: BillSubscriptionEndPolicyEnum.NoEndDate,
            }
          : endPolicy === BillSubscriptionEndPolicyEnum.EndDate
          ? {
              endPolicy: BillSubscriptionEndPolicyEnum.EndDate,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              endDate: endDate!,
              lastAmount,
            }
          : {
              endPolicy: BillSubscriptionEndPolicyEnum.NumOfOccurrences,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              numOfOccurrences: numOfOccurrences!,
              lastAmount,
            }),
      });
    } else {
      const { deliveryDate, noteToVendor } = formFields;
      if (!deliveryDate) {
        throw new Error('cannot create payment');
      }

      return createPayment({
        paymentBillsInfo: [
          {
            id: billId,
            paymentAmount: amount,
          },
        ],
        vendorId,
        deliveryMethodId,
        fundingSourceId,
        scheduledDate,
        deliveryDate: addDeliveryDateToCreateAndUpdate ? formFields?.deliveryDate?.toISOString() : undefined,
        deliveryPreference,
        createMethod: billId ? 'single' : 'just_pay',
        ...(noteToVendor && { noteToVendor }),
        ...(etaDetails && { etaDetails }),
      });
    }
  };

  return {
    error: paymentError || billSubscriptionError,
    submitting: isCreatingPayment || isCreatingBillSubscription,
    submit,
    result: payment || billSubscription,
  };
}
