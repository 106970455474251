import { Group, Icon, Table, Text, Tooltip } from '@melio/penny';
import { Bill, Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { BillsTabOriginCell } from '../../BillsTab/components/BillsTabOriginCell';

export const PaymentsTabBillsOriginCell = ({ payment }: { payment: Payment }) => {
  const { formatMessage } = useMelioIntl();

  const { bills } = payment;
  const [firstBill] = bills ?? [];
  if (!bills || !firstBill) {
    return null;
  }

  if (bills.length > 1) {
    const invoiceNumbers = bills
      .map((bill) =>
        bill.invoice.number
          ? formatMessage('activities.payDashboard.paymentsTab.table.cells.bills.tooltip.billInvoice', {
              invoiceNumber: bill.invoice.number,
            })
          : formatMessage('activities.payDashboard.paymentsTab.table.cells.bills.tooltip.noInvoice')
      )
      .join('\n');

    return (
      <Table.Cell data-testid="payments-tab-bills-origin-cell">
        <Tooltip label={invoiceNumbers}>
          <PaymentMultipleBillsOrigin bills={bills} />
        </Tooltip>
      </Table.Cell>
    );
  }

  return <BillsTabOriginCell billsTabItem={{ type: 'bill', payload: firstBill }} />;
};

export const PaymentMultipleBillsOrigin = ({ bills, showIcon = false }: { bills: Bill[]; showIcon?: boolean }) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Group spacing="xs" alignItems="center" justifyContent="flex-start">
      {showIcon && (
        <Text color="global.neutral.800">
          <Group alignItems="center" justifyContent="center">
            <Icon type="bills" size="small" color="inherit" />
          </Group>
        </Text>
      )}
      <Text textStyle="body3">
        {formatMessage('activities.payDashboard.paymentsTab.table.cells.bills.combinedPayment', {
          billsCount: bills.length,
        })}
      </Text>
    </Group>
  );
};
