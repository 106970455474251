import { FormattedMessage, useMelioIntl } from '@melio/ar-domain';
import { Group, NakedButton, Text } from '@melio/penny';
import React from 'react';

export const ResendCode = ({
  onClick,
  secToResend,
  onResetResendTimer,
}: {
  onClick: VoidFunction;
  secToResend: number;
  onResetResendTimer: VoidFunction;
}) => {
  const { formatMessage } = useMelioIntl();
  const onResendClick = () => {
    onClick();
    onResetResendTimer();
  };

  if (secToResend > 0) {
    return (
      <Text>
        <FormattedMessage
          id="ar.guestPayment.activities.authentication.modal.resendCode.counter"
          values={{ time: secToResend }}
        />
      </Text>
    );
  }

  return (
    <Group justifyContent="center">
      <NakedButton
        data-testid="resend-code-button"
        variant="secondary"
        onClick={onResendClick}
        label={formatMessage('ar.guestPayment.activities.authentication.modal.resendCode.action')}
      />
    </Group>
  );
};
