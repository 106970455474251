import React from 'react';
import { useRecoilValue } from 'recoil';
import { LocalStorageKeys } from '@melio/local-storage';
import { IconButton } from '@melio/penny';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';

import { LeftLayoutIconLink } from '@/cl/layouts/app-layout/leftLayout.component';
import { usePartnerLocalStorage } from '@/hooks/partners/usePartnerLocalStorage';
import { usePreferences } from '@/hooks/preferences.hooks';
import { ScreensEnum } from '@/store/app/app.types';
import { getAccessTokenData } from '@/utils/getAccessTokenData.utils';
import { appSelectedActiveScreenSelector } from '../store/app/app.model';
import { usePlatformIntl } from '../translations/Intl';
import { useRouter } from './router.hooks';

export const useExpensesTabLinks = () => {
  const { generateExpensesLink } = useRouter();
  const { formatMessage } = usePlatformIntl();
  const { orgPreferences } = usePreferences();
  const [isSpendManagementEnabledFF] = useFeature(FeatureFlags.IsSpendManagementEnabled, false);
  const activeScreen = useRecoilValue(appSelectedActiveScreenSelector);
  const localStorage = usePartnerLocalStorage();
  const accessToken = localStorage.getItem(LocalStorageKeys.accessToken);
  const accessTokenData = getAccessTokenData();
  const isSpendManagementEnabled: boolean =
    isSpendManagementEnabledFF ||
    orgPreferences?.isSpendManagementEnabled === 'true' ||
    accessTokenData?.user.registrationFlow === 'spend-management';

  if (!accessToken || !isSpendManagementEnabled) {
    return {
      links: [],
    };
  }

  let links: LeftLayoutIconLink[] = [
    {
      id: 'Expenses',
      Icon: () => <IconButton aria-label="download" icon="wire-transfer" size="small" variant="naked" />,
      href: generateExpensesLink(accessToken)!,
      linkText: formatMessage('app.navigation.expenses'),
      isActive: activeScreen === ScreensEnum.expenses,
      isHidden: !isSpendManagementEnabled,
      isExternal: true,
    },
  ];

  return {
    links,
  };
};
