import { useInvalidFundingSources } from '@melio/ap-domain';
import { useFundingSourcesIconKeys } from '@melio/ap-widgets';
import { SelectableDropdownMenuItem, Table } from '@melio/penny';
import { FundingSource, FundingSourceTypeOptionsServer, useAccountingPlatforms, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';

import { isInternationalDeliveryMethod } from '../../../../../../utils/pay-flow/deliveryMethods';
import { useGetFundingSourceWarning } from '../../PaymentIntentsTable.screen.utils';
import { getFundingSourceLabel } from '../utils';
import { AddPaymentMethodCell } from './AddPaymentMethodCell';

export type FundingSourceSelectCellProps = {
  vendor: Vendor;
  fundingSources: FundingSource[]; // there will always be at least 1 funding source
  selectedFundingSourceId?: FundingSource['id'];
  onSelect: (selectFundingSourceId: FundingSource['id']) => void;
  onOpenReconciliationModal: VoidFunction;
  onOpenVendorDetailsModal: VoidFunction;
  fundingSourceTypesOptions?: FundingSourceTypeOptionsServer;
  onAddFundingSourceClick: VoidFunction;
};

export const FundingSourceSelectCell = forwardRef<FundingSourceSelectCellProps, 'div'>(
  (
    {
      fundingSources,
      vendor,
      selectedFundingSourceId,
      onOpenReconciliationModal,
      onOpenVendorDetailsModal,
      fundingSourceTypesOptions,
      onAddFundingSourceClick,
      onSelect,
      ...props
    },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();
    const { getFundingSourceTooltip, getIsValidFundingSource } = useInvalidFundingSources(fundingSourceTypesOptions);
    const fundingSourcesAndIcons = useFundingSourcesIconKeys(fundingSources);
    const options: SelectableDropdownMenuItem[] = fundingSourcesAndIcons.map(({ fundingSource, icon }) => {
      const isDisabled = !getIsValidFundingSource(fundingSource);
      const message = getFundingSourceTooltip(fundingSource);
      return {
        label: getFundingSourceLabel(fundingSource, formatMessage),
        value: fundingSource.id,
        icon,
        disabled: {
          isDisabled,
          message,
        },
      };
    });

    const selectedFundingSource = fundingSources.find((fs) => fs.id === selectedFundingSourceId);
    const { activeAccountingPlatform } = useAccountingPlatforms();

    const { popoverProps, shouldShowWarning } = useGetFundingSourceWarning({
      vendor,
      selectedFundingSource,
      activeAccountingPlatform,
      onOpenReconciliationModal,
      onOpenVendorDetailsModal,
    });

    if (
      isInternationalDeliveryMethod(vendor.deliveryMethods[0]) &&
      options.every((option) => option.disabled?.isDisabled)
    ) {
      return <AddPaymentMethodCell onClick={onAddFundingSourceClick} selectedFundingSource={selectedFundingSource} />;
    }

    return (
      <Table.SelectCell
        data-testid="funding-source-select-cell"
        {...props}
        options={options}
        value={selectedFundingSourceId}
        onSelect={onSelect}
        isInvalid={shouldShowWarning}
        popoverProps={popoverProps}
        ref={ref}
      />
    );
  }
);

FundingSourceSelectCell.displayName = 'FundingSourceSelectCell';
