import { useCollectionApi, UseCollectionApiProps } from '@melio/api-client';
import { BillingFeeSetting, BillingFeeSettingsApiClient } from '@melio/platform-api-axios-client';

type QueryFn = typeof BillingFeeSettingsApiClient.getBillingFeeSettings;
type CreateFn = typeof BillingFeeSettingsApiClient.postBillingFeeSettings;
type UpdateFn = typeof BillingFeeSettingsApiClient.patchBillingFeeSettingBillingFeeId;
type DeleteFn = typeof BillingFeeSettingsApiClient.deleteBillingFeeSettingBillingFeeId;

export type UseOrgBillingFeeSettingsProps = UseCollectionApiProps<QueryFn, BillingFeeSetting>;

export const useOrgBillingFeeSettings = (props: UseOrgBillingFeeSettingsProps = {}) => {
  const query = useCollectionApi<QueryFn, CreateFn, UpdateFn, DeleteFn>({
    ...props,
    queryKey: 'BillingFeeSettingsApi',
    queryFn: BillingFeeSettingsApiClient.getBillingFeeSettings,
    createFn: BillingFeeSettingsApiClient.postBillingFeeSettings,
    updateFn: BillingFeeSettingsApiClient.patchBillingFeeSettingBillingFeeId,
    deleteFn: BillingFeeSettingsApiClient.deleteBillingFeeSettingBillingFeeId,
  });

  return {
    ...query,
  };
};

export type OrgBillingFeeSettingsCollection = ReturnType<typeof useOrgBillingFeeSettings>;
