/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { defaults } from 'lodash';
import { date, object, SchemaOf, string } from 'yup';
import { FormWidgetProps } from '@melio/ap-widgets';
import { Form, useMelioForm } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { usePlatformIntl } from '@/translations/Intl';

export type ScannedInvoiceDetailsFormProps = FormWidgetProps<ScannedInvoiceDetailsFormFields>;

export type ScannedInvoiceDetailsFormFields = {
  vendorName: string;
  totalAmount: string; //it's should be number but platform not support number input
  dueDate: Date | null;
  invoiceNumber: string;
  noteToSelf: string;
};

export const useSchema = () => {
  return object().shape({
    vendorName: string(),
    totalAmount: string(),
    dueDate: date().nullable(),
    invoiceNumber: string(),
    noteToSelf: string(),
  }) as SchemaOf<ScannedInvoiceDetailsFormFields>;
};

export const ScannedInvoiceDetailsForm = forwardRef<ScannedInvoiceDetailsFormProps, 'form'>(
  ({ onSubmit, defaultValues: _defaultValues, onSubmissionStateChange, isDisabled, ...props }, ref) => {
    const defaultValues = defaults(_defaultValues, {
      vendorName: '',
      totalAmount: undefined,
      dueDate: null,
      invoiceNumber: '',
      noteToSelf: '',
    });

    const { formatMessage } = usePlatformIntl();

    const { formProps, registerField, reset } = useMelioForm<ScannedInvoiceDetailsFormFields>({
      onSubmit,
      schema: useSchema(),
      defaultValues,
      onSubmissionStateChange,
    });

    useEffect(() => reset(defaultValues), [defaultValues]);

    return (
      <Form size="small" isViewMode ref={ref} {...props} {...formProps}>
        <Form.TextField
          {...registerField('vendorName')}
          labelProps={{ label: formatMessage('widgets.scannedInvoiceDetailsForm.form.vendor.label') }}
          viewModePlaceholder={formatMessage('widgets.scannedInvoiceDetailsForm.form.vendor.viewModePlaceholder')}
        />
        <Form.AmountField
          {...registerField('totalAmount')}
          labelProps={{ label: formatMessage('widgets.scannedInvoiceDetailsForm.form.totalAmount.label') }}
          viewModePlaceholder={formatMessage('widgets.scannedInvoiceDetailsForm.form.totalAmount.viewModePlaceholder')}
        />
        <Form.DateField
          {...registerField('dueDate')}
          labelProps={{ label: formatMessage('widgets.scannedInvoiceDetailsForm.form.dueDate.label') }}
          placeholder={formatMessage('widgets.scannedInvoiceDetailsForm.form.dueDate.placeholder')}
          viewModePlaceholder={formatMessage('widgets.scannedInvoiceDetailsForm.form.dueDate.viewModePlaceholder')}
        />
        <Form.TextField
          {...registerField('invoiceNumber')}
          labelProps={{ label: formatMessage('widgets.scannedInvoiceDetailsForm.form.billNumber.label') }}
          placeholder={formatMessage('widgets.scannedInvoiceDetailsForm.form.billNumber.placeholder')}
          viewModePlaceholder={formatMessage('widgets.scannedInvoiceDetailsForm.form.billNumber.viewModePlaceholder')}
        />
      </Form>
    );
  },
);
