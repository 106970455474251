import { FreeChecksData, FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

import { useDeliveryMethodFee } from '../../../../utils';

export const usePaperCheckDescription = (
  fundingSource: FundingSource | undefined,
  link: React.ReactNode,
  freeChecks?: FreeChecksData
): { description: string; isLoading: boolean } => {
  const { formatMessage } = useMelioIntl();
  const { getFee, isFetched: isFeeFetched } = useDeliveryMethodFee(fundingSource?.type);

  const fee = getFee('paper-check');

  if (!fee) {
    return {
      description: formatMessage(`widgets.deliveryMethods.selectDeliveryMethodCard.paper-check.description.noFee`, {
        link,
      }),
      isLoading: false,
    };
  }

  const freeChecksPromotionEnabled = !!freeChecks?.total;
  const freeChecksPromotionMessage = freeChecksPromotionEnabled ? 'withChecksPromotion' : 'noChecksPromotion';

  const description = formatMessage(
    `widgets.deliveryMethods.selectDeliveryMethodCard.paper-check.description.withFee.${freeChecksPromotionMessage}`,
    { fee, monthlyFreeChecksPromotion: freeChecks?.total, link }
  );

  return {
    description,
    isLoading: !isFeeFetched,
  };
};
