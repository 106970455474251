import { addWildcardToRoutes, useNavigate, withOutlet } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';
import { Route, Routes } from 'react-router-dom';

import {
  EditInvoiceFormatDialogActivity,
  EditInvoiceNoteDialogActivity,
  EditInvoicePaymentOptionsDialogActivity,
  InvoiceSettingsActivity,
} from '../../activities';

export const InvoiceSettingsFlow = forwardRef((props, ref) => {
  const { Paths, goToEditInvoiceNote, goToEditInvoiceFormat, goToHome, goToEditInvoiceCustomPaymentDetails } =
    useNavigation();

  return (
    <Routes>
      <Route
        path={Paths.Home}
        element={withOutlet(
          <InvoiceSettingsActivity
            onEditCustomPaymentDetails={goToEditInvoiceCustomPaymentDetails}
            onEditInvoiceFormat={goToEditInvoiceFormat}
            onEditInvoiceNote={goToEditInvoiceNote}
            ref={ref}
            {...props}
          />
        )}
      >
        <Route
          path={Paths.EditInvoiceFormat}
          element={<EditInvoiceFormatDialogActivity onDone={goToHome} onClose={goToHome} />}
        />
        <Route
          path={Paths.EditInvoiceNote}
          element={<EditInvoiceNoteDialogActivity onDone={goToHome} onClose={goToHome} />}
        />
        <Route
          path={Paths.EditInvoiceCustomPaymentDetails}
          element={<EditInvoicePaymentOptionsDialogActivity onDone={goToHome} onClose={goToHome} />}
        />
      </Route>
    </Routes>
  );
});

const useNavigation = () => {
  enum Paths {
    Home = '',
    EditInvoiceFormat = 'edit-invoice-format',
    EditInvoiceNote = 'edit-invoice-note',
    EditInvoiceCustomPaymentDetails = 'edit-custom-payment-details',
  }

  const navigate = useNavigate<Paths>();

  return {
    Paths: addWildcardToRoutes(Paths),
    goToHome: () => navigate(Paths.Home),
    goToEditInvoiceFormat: () => navigate(Paths.EditInvoiceFormat),
    goToEditInvoiceNote: () => navigate(Paths.EditInvoiceNote),
    goToEditInvoiceCustomPaymentDetails: () => navigate(Paths.EditInvoiceCustomPaymentDetails),
  };
};
