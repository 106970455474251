import { PartnerName } from '@melio/platform-api';

import { SupportQuestion } from '@/widgets/settings-page/SupportPage/SupportByPartner.types';

const prefix = `app.settings.SupportSection.accordion`;

export const supportQuestions = (partnerName: PartnerName): SupportQuestion[] => [
  {
    id: 1,
    questionKey: `${prefix}.businessBills.header`,
    answer: {
      paragraphKey: `${prefix}.businessBills.body`,
      options: {
        supportLinks: [
          {
            linkKey: `${prefix}.businessBills.links.link1.value`,
            hrefKey: `${prefix}.businessBills.links.link1.href`,
            isExternal: true,
          },
        ],
      },
    },
  },
  {
    id: 2,
    questionKey: `${prefix}.addSparkCard.header`,
    answer: {
      paragraphKey: `${prefix}.addSparkCard.body`,
      options: {
        supportLists: [
          {
            items: [
              {
                paragraphKey: `${prefix}.addSparkCard.lists.list1.line1.key`,
              },
              {
                paragraphKey: `${prefix}.addSparkCard.lists.list1.line2.key`,
              },
            ],
            isOrdered: true,
          },
        ],
        supportLinks: [
          {
            linkKey: `${prefix}.addSparkCard.links.link1.value`,
            hrefKey: `${prefix}.addSparkCard.links.link1.href`,
            isExternal: true,
          },
        ],
      },
    },
  },
  {
    id: 3,
    questionKey: `${prefix}.syncQuickBooks.header`,
    answer: {
      paragraphKey: `${prefix}.syncQuickBooks.body`,
      options: {
        supportLists: [
          {
            items: [
              {
                paragraphKey: `${prefix}.syncQuickBooks.lists.list1.line1.key`,
                options: {
                  supportUnderlines: [
                    {
                      underlineKey: `${prefix}.syncQuickBooks.lists.list1.line1.underlines.underline1.key`,
                    },
                  ],
                },
              },
              {
                paragraphKey: `${prefix}.syncQuickBooks.lists.list1.line2.key`,
                options: {
                  supportUnderlines: [
                    {
                      underlineKey: `${prefix}.syncQuickBooks.lists.list1.line2.underlines.underline1.key`,
                    },
                  ],
                },
              },
            ],
            isOrdered: true,
          },
        ],
        supportLinks: [
          {
            linkKey: `${prefix}.syncQuickBooks.links.link1.value`,
            hrefKey: `${prefix}.syncQuickBooks.links.link1.href`,
            isExternal: true,
          },
        ],
      },
    },
  },
  {
    id: 4,
    questionKey: `${prefix}.addDebitCard.header`,
    answer: {
      paragraphKey: `${prefix}.addDebitCard.body`,
      options: {
        supportLists: [
          {
            items: [
              {
                paragraphKey: `${prefix}.addDebitCard.lists.list1.line1.key`,
              },
              {
                paragraphKey: `${prefix}.addDebitCard.lists.list1.line2.key`,
              },
            ],
            isOrdered: true,
          },
        ],
        supportLinks: [
          {
            linkKey: `${prefix}.addDebitCard.links.link1.value`,
            hrefKey: `${prefix}.addDebitCard.links.link1.href`,
            isExternal: true,
          },
        ],
      },
    },
  },
  {
    id: 5,
    questionKey: `${prefix}.verifyMicroDeposits.header`,
    answer: {
      paragraphKey: `${prefix}.verifyMicroDeposits.body`,
      options: {
        supportLinks: [
          {
            linkKey: `${prefix}.verifyMicroDeposits.links.link1.value`,
            hrefKey: `${prefix}.verifyMicroDeposits.links.link1.href`,
            isExternal: true,
          },
        ],
      },
    },
  },
  {
    id: 6,
    questionKey: `${prefix}.addBankAccount.header`,
    answer: {
      paragraphKey: `${prefix}.addBankAccount.body`,
      options: {
        supportLists: [
          {
            items: [
              {
                paragraphKey: `${prefix}.addBankAccount.lists.list1.line1.key`,
              },
              {
                paragraphKey: `${prefix}.addBankAccount.lists.list1.line2.key`,
              },
            ],
            isOrdered: true,
          },
        ],
        supportLinks: [
          {
            linkKey: `${prefix}.addBankAccount.links.link1.value`,
            hrefKey: `${prefix}.addBankAccount.links.link1.href`,
            isExternal: true,
          },
        ],
      },
    },
  },
  {
    id: 7,
    questionKey: `${prefix}.addBill.header`,
    answer: {
      paragraphKey: `${prefix}.addBill.body`,
      options: {
        supportLists: [
          {
            items: [
              {
                paragraphKey: `${prefix}.addBill.lists.list1.line1.key`,
              },
              {
                paragraphKey: `${prefix}.addBill.lists.list1.line2.key`,
              },
              {
                paragraphKey: `${prefix}.addBill.lists.list1.line3.key`,
              },
            ],
            isOrdered: false,
          },
        ],
        supportLinks: [
          {
            linkKey: `${prefix}.addBill.links.link1.value`,
            hrefKey: `${prefix}.addBill.links.link1.href`,
            isExternal: true,
          },
        ],
      },
    },
  },
  {
    id: 8,
    questionKey: `${prefix}.payBill.header`,
    answer: {
      paragraphKey: `${prefix}.payBill.body`,
      options: {
        supportLists: [
          {
            items: [
              {
                paragraphKey: `${prefix}.payBill.lists.list1.line1.key`,
              },
              {
                paragraphKey: `${prefix}.payBill.lists.list1.line2.key`,
              },
              {
                paragraphKey: `${prefix}.payBill.lists.list1.line3.key`,
              },
            ],
            isOrdered: true,
          },
          {
            items: [
              {
                paragraphKey: `${prefix}.payBill.lists.list2.line1.key`,
              },
              {
                paragraphKey: `${prefix}.payBill.lists.list2.line2.key`,
              },
            ],
            isOrdered: false,
          },
        ],
        supportLinks: [
          {
            linkKey: `${prefix}.payBill.links.link1.value`,
            hrefKey: `${prefix}.payBill.links.link1.href`,
            isExternal: true,
          },
        ],
      },
    },
  },
  {
    id: 9,
    questionKey: `${prefix}.deliveryTimelines.header`,
    answer: {
      paragraphKey: `${prefix}.deliveryTimelines.body`,
      options: {
        supportLists: [
          {
            items:
              partnerName === PartnerName.Clover
                ? [
                    {
                      paragraphKey: `${prefix}.deliveryTimelines.lists.list1.line1.key`,
                    },
                    {
                      paragraphKey: `${prefix}.deliveryTimelines.lists.list1.line2.key`,
                    },
                    {
                      paragraphKey: `${prefix}.deliveryTimelines.lists.list1.line3.key`,
                    },
                    {
                      paragraphKey: `${prefix}.deliveryTimelines.lists.list1.line4.key`,
                    },
                  ]
                : [
                    {
                      paragraphKey: `${prefix}.deliveryTimelines.lists.list1.line1.key`,
                    },
                    {
                      paragraphKey: `${prefix}.deliveryTimelines.lists.list1.line2.key`,
                    },
                  ],
            isOrdered: false,
          },
          {
            items:
              partnerName === PartnerName.Clover
                ? [
                    {
                      paragraphKey: `${prefix}.deliveryTimelines.lists.list2.line1.key`,
                    },
                    {
                      paragraphKey: `${prefix}.deliveryTimelines.lists.list2.line2.key`,
                    },
                    {
                      paragraphKey: `${prefix}.deliveryTimelines.lists.list2.line3.key`,
                    },
                  ]
                : [
                    {
                      paragraphKey: `${prefix}.deliveryTimelines.lists.list2.line1.key`,
                    },
                    {
                      paragraphKey: `${prefix}.deliveryTimelines.lists.list2.line2.key`,
                    },
                  ],
            isOrdered: false,
          },
        ],
        supportLinks: [
          {
            linkKey: `${prefix}.deliveryTimelines.links.link1.value`,
            hrefKey: `${prefix}.deliveryTimelines.links.link1.href`,
            isExternal: true,
          },
        ],
      },
    },
  },
  {
    id: 10,
    questionKey: `${prefix}.vendors.header`,
    answer: {
      paragraphKey: `${prefix}.vendors.body`,
      options: {
        supportLinks: [
          {
            linkKey: `${prefix}.vendors.links.link1.value`,
            hrefKey: `${prefix}.vendors.links.link1.href`,
            isExternal: true,
          },
        ],
        supportLists: [
          {
            items: [
              {
                paragraphKey: `${prefix}.vendors.lists.list1.line1.key`,
              },
              {
                paragraphKey: `${prefix}.vendors.lists.list1.line2.key`,
              },
            ],
            isOrdered: false,
          },
          {
            items: [
              {
                paragraphKey: `${prefix}.vendors.lists.list2.line1.key`,
              },
              {
                paragraphKey: `${prefix}.vendors.lists.list2.line2.key`,
              },
              {
                paragraphKey: `${prefix}.vendors.lists.list2.line3.key`,
              },
            ],
            isOrdered: true,
          },
        ],
      },
    },
  },
  {
    id: 11,
    questionKey: `${prefix}.cancelPayment.header`,
    answer: {
      paragraphKey: `${prefix}.cancelPayment.body`,
      options: {
        supportLinks: [
          {
            linkKey: `${prefix}.cancelPayment.links.link1.value`,
            hrefKey: `${prefix}.cancelPayment.links.link1.href`,
            isExternal: true,
          },
        ],
      },
    },
  },
  {
    id: 12,
    questionKey: `${prefix}.security.header`,
    answer: {
      paragraphKey: `${prefix}.security.body`,
      options: {
        supportLists: [
          {
            items: [
              {
                paragraphKey: `${prefix}.security.lists.list1.line1.key`,
              },
              {
                paragraphKey: `${prefix}.security.lists.list1.line2.key`,
              },
              {
                paragraphKey: `${prefix}.security.lists.list1.line3.key`,
              },
              {
                paragraphKey: `${prefix}.security.lists.list1.line4.key`,
              },
            ],
            isOrdered: false,
          },
        ],
        supportLinks: [
          {
            linkKey: `${prefix}.security.links.link1.value`,
            hrefKey: `${prefix}.security.links.link1.href`,
            isExternal: true,
          },
        ],
      },
    },
  },
  {
    id: 13,
    questionKey: `${prefix}.addBillClone.header`,
    answer: {
      paragraphKey: `${prefix}.addBillClone.body`,
      options: {
        supportLists: [
          {
            items: [
              {
                paragraphKey: `${prefix}.addBillClone.lists.list1.line1.key`,
              },
              {
                paragraphKey: `${prefix}.addBillClone.lists.list1.line2.key`,
              },
              {
                paragraphKey: `${prefix}.addBillClone.lists.list1.line3.key`,
              },
            ],
            isOrdered: true,
          },
          {
            items: [
              {
                paragraphKey: `${prefix}.addBillClone.lists.list2.line1.key`,
              },
              {
                paragraphKey: `${prefix}.addBillClone.lists.list2.line2.key`,
              },
              {
                paragraphKey: `${prefix}.addBillClone.lists.list2.line3.key`,
              },
            ],
            isOrdered: false,
          },
        ],
        supportLinks: [
          {
            linkKey: `${prefix}.addBillClone.links.link1.value`,
            hrefKey: `${prefix}.addBillClone.links.link1.href`,
            isExternal: true,
          },
          {
            linkKey: `${prefix}.addBillClone.links.link2.value`,
            hrefKey: `${prefix}.addBillClone.links.link2.href`,
            isExternal: true,
          },
          {
            linkKey: `${prefix}.addBillClone.links.link3.value`,
            hrefKey: `${prefix}.addBillClone.links.link3.href`,
            isExternal: true,
          },
        ],
      },
    },
  },
];
