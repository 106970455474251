import { FileAttachment, FileAttachmentProps } from '@melio/penny';
import { BillInfo, FileInfo, useFile } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef, XOR } from '@melio/platform-utils';
import { useEffect, useState } from 'react';

type FileProp = XOR<
  {
    fileId?: BillInfo['invoice']['fileId'];
  },
  {
    file?: FileInfo;
  }
>;

export type FileAttachmentWidgetProps = Pick<
  FileAttachmentProps,
  'isReadOnly' | 'isLoading' | 'acceptTypes' | 'onViewModeClick' | 'isViewMode' | 'aria-label' | 'viewModeIcon'
> &
  FileProp & {
    onChange?: (fileBlob?: File | null) => void;
    externalFileUrl?: string | null;
  };

export const FileAttachmentWidget = forwardRef<FileAttachmentWidgetProps, 'div'>(
  (
    {
      isReadOnly,
      isViewMode,
      file,
      fileId,
      externalFileUrl,
      isLoading,
      onChange,
      onViewModeClick,
      acceptTypes,
      viewModeIcon,
      ...props
    },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();

    const { data, isLoading: isWaitingForFileInfo } = useFile({ id: fileId as string | undefined, enabled: !file });
    const fileData = file ?? data;
    const fileUrl = fileData?.previewUrls[0] ?? fileData?.url ?? externalFileUrl;
    const fileToDownload = fileData?.url || fileData?.previewUrls[0];
    const [selectedFileUrl, setSelectedFileUrl] = useState<string | undefined | null>(fileUrl);

    useEffect(() => {
      setSelectedFileUrl(fileUrl);
    }, [fileUrl]);

    const onChangeSelectedFile = (fileUrl?: string | null, fileBlob?: File | null) => {
      const wasFileDeleted = fileUrl === null;

      setSelectedFileUrl(fileUrl);
      onChange?.(wasFileDeleted ? null : fileBlob);
    };

    return (
      <FileAttachment
        data-component="FileAttachmentWidget"
        data-testid="file-attachment-widget"
        {...props}
        value={selectedFileUrl}
        fileAltText={formatMessage('widgets.fileAttachment.altText')}
        isReadOnly={isReadOnly}
        isViewMode={isViewMode}
        isLoading={isLoading || isWaitingForFileInfo}
        onChange={onChangeSelectedFile}
        onViewModeClick={(url: string) => {
          onViewModeClick?.(fileToDownload || url);
        }}
        acceptTypes={acceptTypes}
        deleteButtonText={formatMessage('widgets.fileAttachment.deleteButton')}
        ref={ref}
        viewModeIcon={viewModeIcon}
      />
    );
  }
);

FileAttachmentWidget.displayName = 'FileAttachmentWidget';
