import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const CompaniesIcon = (props: IconProps) => (
  <Icon width="20px" height="20px" viewBox="0 0 20 20" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 9.16667H6.66667C8.045 9.16667 9.16667 8.045 9.16667 6.66667V2.5C9.16667 1.12167 8.045 0 6.66667 0H2.5C1.12167 0 0 1.12167 0 2.5V6.66667C0 8.045 1.12167 9.16667 2.5 9.16667ZM1.66667 2.5C1.66667 2.04 2.04 1.66667 2.5 1.66667H6.66667C7.12667 1.66667 7.5 2.04 7.5 2.5V6.66667C7.5 7.12667 7.12667 7.5 6.66667 7.5H2.5C2.04 7.5 1.66667 7.12667 1.66667 6.66667V2.5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3333 9.16667H17.5C18.8783 9.16667 20 8.045 20 6.66667V2.5C20 1.12167 18.8783 0 17.5 0H13.3333C11.955 0 10.8333 1.12167 10.8333 2.5V6.66667C10.8333 8.045 11.955 9.16667 13.3333 9.16667ZM12.5 2.5C12.5 2.04 12.8733 1.66667 13.3333 1.66667H17.5C17.96 1.66667 18.3333 2.04 18.3333 2.5V6.66667C18.3333 7.12667 17.96 7.5 17.5 7.5H13.3333C12.8733 7.5 12.5 7.12667 12.5 6.66667V2.5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.66667 20H2.5C1.12167 20 0 18.8783 0 17.5V13.3333C0 11.955 1.12167 10.8333 2.5 10.8333H6.66667C8.045 10.8333 9.16667 11.955 9.16667 13.3333V17.5C9.16667 18.8783 8.045 20 6.66667 20ZM2.5 12.5C2.04 12.5 1.66667 12.8733 1.66667 13.3333V17.5C1.66667 17.96 2.04 18.3333 2.5 18.3333H6.66667C7.12667 18.3333 7.5 17.96 7.5 17.5V13.3333C7.5 12.8733 7.12667 12.5 6.66667 12.5H2.5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3333 20H17.5C18.8783 20 20 18.8783 20 17.5V13.3333C20 11.955 18.8783 10.8333 17.5 10.8333H13.3333C11.955 10.8333 10.8333 11.955 10.8333 13.3333V17.5C10.8333 18.8783 11.955 20 13.3333 20ZM12.5 13.3333C12.5 12.8733 12.8733 12.5 13.3333 12.5H17.5C17.96 12.5 18.3333 12.8733 18.3333 13.3333V17.5C18.3333 17.96 17.96 18.3333 17.5 18.3333H13.3333C12.8733 18.3333 12.5 17.96 12.5 17.5V13.3333Z"
    />
  </Icon>
);
