import { useDisclosure } from '@chakra-ui/react';
import { forwardRef } from '@melio/platform-utils';
import { useState } from 'react';

import { customers_screen_id, customers_tab_panel_id } from '../../utils';
import { InvoiceDashboardQueryParams } from '../invoice-table';
import { useCustomersFiltering } from './hooks';
import { CannotDeleteCustomerModal, CustomersTabScreen } from './screens';
import { CustomerActions } from './types';
import { doesCustomerHaveInvoices } from './utils';

export type CustomersTabActivityProps = {
  onCreateCustomer: VoidFunction;
  onCreateInvoice: (customerId: string) => unknown;
  onEditCustomer: (id: string) => unknown;
  onDeleteCustomer: (id: string) => unknown;
  onImportCustomers: VoidFunction;
  onDisplayInvoices?: (params: InvoiceDashboardQueryParams) => unknown;
  onError?: ARErrorFunction;
  isCustomersImportEnabled: boolean;
};

export const CustomersTabActivity = forwardRef<CustomersTabActivityProps>(
  (
    {
      onCreateCustomer,
      onCreateInvoice,
      onEditCustomer,
      onDeleteCustomer: _onDeleteCustomer,
      onDisplayInvoices,
      onImportCustomers,
      onError,
      isCustomersImportEnabled,
      ...props
    },
    ref
  ) => {
    const { customers, isLoading, search, setParams, pagination, sortParams } = useCustomersFiltering({ onError });
    const cannotDeleteCustomerModal = useDisclosure();
    const [customerToDeleteName, setCustomerToDeleteName] = useState<string>();

    const onDeleteCustomer = (id: string) => {
      const customer = customers.find((customer) => customer.id === id);

      if (customer) {
        if (!doesCustomerHaveInvoices(customer)) {
          _onDeleteCustomer(id);
        } else {
          setCustomerToDeleteName(customer.companyName);
          cannotDeleteCustomerModal.onOpen();
        }
      }
    };

    const tableActions: CustomerActions = {
      onCreateInvoice,
      onEditCustomer,
      onDisplayInvoices,
      onDeleteCustomer,
    };

    return (
      <>
        <CustomersTabScreen
          customers={customers}
          tableActions={tableActions}
          isLoading={isLoading}
          search={search}
          onCreateCustomer={onCreateCustomer}
          isCustomersImportEnabled={isCustomersImportEnabled}
          onImportCustomers={onImportCustomers}
          onParamsChange={setParams}
          pagination={pagination}
          sortParams={sortParams}
          ref={ref}
          id={customers_screen_id}
          aria-labeledby={customers_tab_panel_id}
          {...props}
        />
        <CannotDeleteCustomerModal
          isOpen={cannotDeleteCustomerModal.isOpen}
          onClose={cannotDeleteCustomerModal.onClose}
          customerName={customerToDeleteName ?? ''}
        />
      </>
    );
  }
);
