import React from 'react';
import { Box } from '@chakra-ui/react';
import { Container, Divider, StatusBar } from '@melio/penny';

import { usePlatformIntl } from '@/translations/Intl';
import { PaymentDetailsProps } from '@/widgets/pay-dashboard/payment-details/PaymentDetails.types';
import { usePaymentScheduledByProps } from '../../hooks/usePaymentScheduledByProps';
import { PayFromSectionRefund } from '../general/PayFromSectionRefund';
import { PaymentDetailsSection } from '../general/PaymentDetailsSection';
import { PaymentDetailsTop } from '../general/PaymentDetailsTop';
import { PaymentScheduledBySection } from '../general/PaymentScheduledBySection';
import { PaymentTimelineTrackerSection } from '../general/PaymentTimelineTracker/PaymentTimelineTrackerSection';
import { RefundInProgressSectionBanner } from './RefundInProgressSectionBanner';

export const RefundInProgress = ({ payment, isActionsEnabled }: PaymentDetailsProps) => {
  const { formatMessage } = usePlatformIntl();
  const { fundingSource, processedTimeline } = payment;
  const { showPaymentScheduledBySection, ...props } = usePaymentScheduledByProps(payment);

  return (
    <>
      <StatusBar variant={'neutral'} content={formatMessage('widgets.paymentDetails.inProgressRefund.title')} />

      <Box py="l" data-testid="pay-dashboard-payment-in-progress">
        <PaymentTimelineTrackerSection payment={payment}>
          <Container paddingY="l">
            <Divider />
          </Container>
        </PaymentTimelineTrackerSection>
        <PaymentDetailsSection gridGap="m">
          <RefundInProgressSectionBanner payment={payment} />
          <PaymentDetailsTop payment={payment} isActionsEnabled={isActionsEnabled} />
        </PaymentDetailsSection>
        {showPaymentScheduledBySection && (
          <PaymentDetailsSection gridGap="m">
            <PaymentScheduledBySection {...props} />
          </PaymentDetailsSection>
        )}
        <PaymentDetailsSection gridGap="m">
          <PayFromSectionRefund fundingSource={fundingSource} initiatedDate={processedTimeline?.refundInitiated} />
        </PaymentDetailsSection>
      </Box>
    </>
  );
};
