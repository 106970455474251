import { NavLink } from 'react-router-dom';
import { Box } from '@chakra-ui/react';

import { LeftLayoutIconLink } from '@/cl/layouts/app-layout/leftLayout.component';
import { IconLabel } from '../IconLabel/IconLabel.component';

export type TabLinkProps = {
  link: LeftLayoutIconLink;
  onClick: () => void;
};

export const TabLink = ({ link, onClick }: TabLinkProps) => (
  <Box data-testid={`left-layout-menu-${link.id}`} title={link.id} onClick={onClick}>
    <IconLink {...link} />
  </Box>
);

const IconLink = ({ Icon, href, linkText, isActive, isExternal = false }: LeftLayoutIconLink) =>
  isExternal ? (
    <a href={href}>
      <IconLabel Icon={Icon} label={linkText} variant={isActive ? 'active' : 'default'} />
    </a>
  ) : (
    <NavLink to={href}>
      <IconLabel Icon={Icon} label={linkText} variant={isActive ? 'active' : 'default'} />
    </NavLink>
  );
