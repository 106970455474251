import { ConversionUtils } from '@melio/ar-domain';
import { Table } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export type AmountCellProps = Parameters<typeof Table.AmountCell>[0];

export const AmountCell = forwardRef<AmountCellProps>(({ value, ...props }, ref) => (
  // eslint-disable-next-line react/forbid-elements
  <Table.AmountCell value={value && ConversionUtils.fromCentsToDollars(value)} {...props} ref={ref} />
));
