import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAnalyticsContext, withAnalyticsContext } from '@melio/platform-analytics';

import { useActiveScreen } from '@/hooks/useActiveScreen';
import { BatchPaymentsScreen } from '@/screens/batch-payments/BatchPayments.screen';
import { ActiveFlowEnum, ScreensEnum } from '@/store/app/app.types';

export const BatchPaymentsRoute = withAnalyticsContext(() => {
  useActiveScreen(ScreensEnum.batchPayments, ActiveFlowEnum.batchPayments);
  useAnalyticsContext({
    globalProperties: { Flow: 'batch-payment' },
  });

  return (
    <Routes>
      <Route path="/" element={<BatchPaymentsScreen />} />
    </Routes>
  );
});
