import { object, string } from 'yup';

import { ModalMessageSeparator, SplitModalMessage } from '../types';

// eslint-disable max-len
const urlPattern =
  /(https:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}|melio:\/\/)/gi;

export const urlValidatorSchema = object().shape({
  url: string().min(11).matches(urlPattern),
});

export const isValidUrl = async (url?: string) => await urlValidatorSchema.isValid({ url });

export const getDeepLinkUrl = (url?: string): string | undefined => {
  const pattern = 'melio://';

  if (!url?.includes(pattern)) {
    return undefined;
  }

  return url.split(pattern)[1];
};

export const splitModalMessageToSubDescriptions = (message?: string) => {
  if (!message) {
    return undefined;
  }

  const result: SplitModalMessage = { description: message };

  const splitMessage = message.split(ModalMessageSeparator.SUB_DESCRIPTION);

  if (splitMessage[0]) {
    result.description = splitMessage[0];
    result.subDescription = splitMessage[1];
  }

  if (!splitMessage[0] && splitMessage[1]) {
    result.description = splitMessage[1];
  }

  return result;
};
