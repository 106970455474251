import { useMelioQueryClient, UseModelApiProps } from '@melio/api-client';
import {
  ApprovalWorkflowsApiClient,
  SetApprovalLimitRequest,
  SetApprovalLimitResponseData,
} from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useIsMutating, useMutation } from 'react-query';

type UseSetApprovalWorkflowLimitModelType = UseModelApiProps<
  typeof ApprovalWorkflowsApiClient.getApprovalWorkflows,
  SetApprovalLimitResponseData
>;

export type SetApprovalLimitRequestType = SetApprovalLimitRequest;

export type UseSetApprovalWorkflowLimitPropsType = UseSetApprovalWorkflowLimitModelType & {
  options?: {
    onUpdate?: (approvalLimitData: SetApprovalLimitResponseData) => void;
    onUpdateError?: VoidFunction;
  };
};

export const useApprovalLimit = (props: UseSetApprovalWorkflowLimitPropsType = {}) => {
  const queryClient = useMelioQueryClient();

  const setApprovalLimitMutation = useMutation(
    ['ApprovalWorkflowsApi'],
    ({ approvalLimit, userId }: SetApprovalLimitRequest) =>
      ApprovalWorkflowsApiClient.setApprovalLimit({ approvalLimit, userId }),
    {
      onSuccess: ({ data }) => {
        const mappedApprovalLimit = data.data;
        void queryClient.invalidateQueries('ApprovalWorkflowsApi');
        props.options?.onUpdate?.(mappedApprovalLimit);
      },
      onError: props.options?.onUpdateError,
    }
  );

  const isMutating = useIsMutating('ApprovalWorkflowsApi') > 0;

  return {
    setApprovalLimit: setApprovalLimitMutation.mutateAsync,
    isMutating,
    _mutations: {
      setApprovalLimitMutation,
    },
  };
};
