import { Group, Table, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { LoansTabItem } from '../types';

export const FinancingTabTotalAmountCell = ({ loansTabItem }: { loansTabItem: LoansTabItem }) => {
  const { formatCurrency } = useMelioIntl();

  const {
    id,
    loan: {
      fees: { totalAmount },
    },
  } = loansTabItem;

  return (
    <Table.Cell data-testid={`financing-tab-row-${id}-total-amount-cell`} textAlign="end">
      <Group variant="vertical" spacing="xxs" alignItems="flex-end">
        <Text textStyle="body3Semi">{formatCurrency(totalAmount || 0)}</Text>
      </Group>
    </Table.Cell>
  );
};
