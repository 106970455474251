import { FormDialog, FormDialogWidgetProps, useMelioIntl } from '@melio/ar-domain';
import { Form, useMelioForm } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export type CustomMessageFormFields = {
  customMessage?: string;
};

export type CustomMessageDialogProps = FormDialogWidgetProps<CustomMessageFormFields>;

export const CustomMessageDialog = forwardRef<CustomMessageDialogProps>(
  ({ onSubmit, isSaving, defaultValues, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const { registerField, ...useFormResults } = useMelioForm<CustomMessageFormFields>({
      onSubmit,
      isSaving,
      defaultValues,
      subscribeToDefaultValuesChanges: true,
    });

    return (
      <FormDialog
        data-component={CustomMessageDialog.displayName}
        data-testid="custom-message-dialog"
        useFormResults={useFormResults}
        header={formatMessage('ar.settings.activities.invoiceEmailPreferences.customMessageDialog.header')}
        description={formatMessage('ar.settings.activities.invoiceEmailPreferences.customMessageDialog.description')}
        primaryButton={{
          label: formatMessage(
            'ar.settings.activities.invoiceEmailPreferences.customMessageDialog.primaryButton.label'
          ),
        }}
        secondaryButton={{
          label: formatMessage(
            'ar.settings.activities.invoiceEmailPreferences.customMessageDialog.secondaryButton.label'
          ),
        }}
        {...props}
        ref={ref}
      >
        <Form.TextArea
          {...registerField('customMessage')}
          labelProps={{
            label: formatMessage(
              'ar.settings.activities.invoiceEmailPreferences.customMessageDialog.fields.customMessage.label'
            ),
          }}
          data-private
          maxChars={500}
        />
      </FormDialog>
    );
  }
);

CustomMessageDialog.displayName = 'CustomMessageDialog';
