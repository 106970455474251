import { InvoiceTimeline, InvoiceTimelineEmailTypeEnum } from '@melio/ar-domain';
import { useDateUtils } from '@melio/platform-utils';

const TimelineRemindersTypes = [
  InvoiceTimelineEmailTypeEnum.ArInvoiceReminderApproachingDueDate,
  InvoiceTimelineEmailTypeEnum.ArInvoiceReminderOverdue,
  InvoiceTimelineEmailTypeEnum.ArInvoiceReminderOnDueDate,
];

export const useGetLastSentEmails = (invoiceTimeline: InvoiceTimeline) => {
  const { createDate } = useDateUtils();
  const sortedEmails = invoiceTimeline.emails.sort(
    (a, b) => createDate(b.sentAt).getTime() - createDate(a.sentAt).getTime()
  );

  return {
    lastSentEmail: sortedEmails[0],
    lastSentReminder: sortedEmails.find((email) =>
      (TimelineRemindersTypes as unknown as InvoiceTimelineEmailTypeEnum).includes(email.type)
    ),
  };
};
