import { ApprovalWorkflow, WorkflowActions } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { AnySchema, array, BaseSchema, lazy, mixed, number, object, SchemaOf, string } from 'yup';

export const useSchemaV2 = (): SchemaOf<ApprovalWorkflow> => {
  const { formatMessage } = useMelioIntl();

  return object().shape({
    criteria: object().shape({
      conditions: array().of(
        lazy((value: ApprovalWorkflow['criteria']['conditions'][number]) => {
          switch (value.condition.type) {
            case 'workflowNumericCondition':
              return object().shape({
                condition: object().shape({
                  type: string().oneOf(['workflowNumericCondition']),
                  configuration: object().shape({
                    operator: string().oneOf(['greaterEqual', 'belowEqual']),
                    value: number().when('operator', {
                      is: 'belowEqual',
                      then: number()
                        .typeError(
                          formatMessage('activities.create-approval-workflows.conditions.amount.errors.required')
                        )
                        .required('activities.create-approval-workflows.conditions.amount.errors.required')
                        .min(
                          0.01,
                          formatMessage('activities.create-approval-workflows.conditions.amount.errors.greater')
                        ),
                      otherwise: number()
                        .typeError(
                          formatMessage('activities.create-approval-workflows.conditions.amount.errors.required')
                        )
                        .required(
                          formatMessage('activities.create-approval-workflows.conditions.amount.errors.required')
                        )
                        .min(
                          0,
                          formatMessage('activities.create-approval-workflows.conditions.amount.errors.greaterEqual')
                        ),
                    }),
                  }),
                }),
              });
            case 'workflowCollaboratorsCondition': {
              const config =
                value.condition.configuration.filterBy === 'role'
                  ? object().shape({
                      filterBy: string().oneOf(['role']),
                      roles: array().of(string()).min(1),
                    })
                  : object().shape({
                      filterBy: string().oneOf(['user']),
                      ids: array().of(string()).min(1),
                    });

              return object().shape({
                condition: object().shape({
                  type: mixed().oneOf([value.condition.type]),
                  configuration: config,
                }),
              });
            }
            case 'workflowVendorsCondition':
              return object().shape({
                condition: object().shape({
                  type: string().oneOf(['workflowVendorsCondition']),
                  configuration: object().shape({
                    ids: array().of(string()).min(1),
                  }),
                }),
              });

            default:
              return {} as unknown as BaseSchema;
          }
        }) as unknown as BaseSchema
      ) as unknown as AnySchema,
    }),
    actions: array()
      .of(
        lazy((value: WorkflowActions) => {
          switch (value.type) {
            case 'role':
              return object().shape({
                type: string().oneOf(['role']).required(),
                configuration: object().shape({ numberOfApprovers: number().moreThan(0) }),
              });
            case 'user':
              return object().shape({
                type: string().oneOf(['user']).required(),
                configuration: object().shape({ ids: array().of(string()).min(1).max(3) }),
              });
            default:
              return {} as unknown as BaseSchema;
          }
        }) as unknown as BaseSchema
      )
      .min(1) as unknown as AnySchema,
  }) as unknown as SchemaOf<ApprovalWorkflow>;
};
