import { DemoRequestAckWidget, DemoRequestFormWidget } from '@melio/ap-widgets';
import { useCallback, useState } from 'react';

import { DemoRequestActivityStep } from './types';

export type DemoRequestActivityProps = {
  organizationId: number | string;
  email: string;
  helpCenterLink: string;
  pageEntryPoint?: string;
  onClose: VoidFunction;
};

export const DemoRequestActivity = ({
  organizationId,
  email,
  helpCenterLink,
  pageEntryPoint,
  onClose,
}: DemoRequestActivityProps) => {
  const [currentStep, goToStep] = useState<DemoRequestActivityStep>('FORM');
  const [isPhone, setIsPhone] = useState(false);

  const onDoneForm = useCallback(
    (phone?: string) => {
      setIsPhone(!!phone);
      goToStep('SUCCESS');
    },
    [setIsPhone, goToStep]
  );

  switch (currentStep) {
    case 'FORM':
      return (
        <DemoRequestFormWidget
          organizationId={organizationId}
          email={email}
          onDone={onDoneForm}
          onClose={onClose}
          helpCenterLink={helpCenterLink}
          pageEntryPoint={pageEntryPoint}
          isOpen
        />
      );
    case 'SUCCESS':
      return <DemoRequestAckWidget isPhoneAck={isPhone} onClose={onClose} pageEntryPoint={pageEntryPoint} isOpen />;
  }
};
DemoRequestActivity.displayName = 'DemoRequestActivity';
