import { SelectedInboxItemType } from '../PayDashboard/components/BillsTab/types';
import { PaymentRequestRejectModal } from '../PaymentRequest';
import { BatchDeleteBillItemsModalActivity } from './batch-delete-bills';
import { DeleteBillActionModalActivity } from './delete-single-bill';

export const DeleteBillItemsModalActivity = (props: {
  billItems: SelectedInboxItemType[];
  isOpen: boolean;
  onClose: VoidFunction;
  onSuccess?: VoidFunction;
}) => {
  const { billItems, ...rest } = props;

  if (billItems.length > 1) {
    return <BatchDeleteBillItemsModalActivity {...rest} billItems={billItems} />;
  }

  const [billItem] = billItems;

  if (billItem?.type === 'bill') {
    return <DeleteBillActionModalActivity {...rest} billId={billItem.id} />;
  }

  if (billItem?.type === 'paymentRequest') {
    return <PaymentRequestRejectModal paymentRequestId={billItem.id} {...rest} />;
  }

  return null;
};
