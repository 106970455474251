import { Payment } from '@melio/platform-api';
import { useDateUtils } from '@melio/platform-utils';
import { useCallback } from 'react';

export const usePaymentsLateApprovals = () => {
  const { isValidScheduledDate, createDate, isDatePartEqual } = useDateUtils();

  return useCallback(
    ({ payments }: { payments: Payment[] }) =>
      payments.reduce((acc, payment) => {
        acc[payment.id] = {
          isDebitDateAfterToday: !isValidScheduledDate(payment.scheduledDate),
          isDebitDateToday: isDatePartEqual(payment.scheduledDate, createDate()),
        };
        return acc;
      }, {} as { [paymentId: string]: { isDebitDateAfterToday: boolean; isDebitDateToday: boolean } }),
    [createDate, isDatePartEqual, isValidScheduledDate]
  );
};
