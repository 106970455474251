import { Button, Container, Divider, Group, Icon, Menu, RadioGroup, StatusIndicator, Text } from '@melio/penny';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import { useState } from 'react';

import { FilterOption } from './types';

type FilterProps<T> = {
  options: FilterOption<T>[];
  activeFilter: T;
  defaultFilter: T;
  title: MessageKey;
  onChange: (id: T) => void;
};

export const Filter = <T extends string>({ options, activeFilter, defaultFilter, title, onChange }: FilterProps<T>) => {
  const { formatMessage } = useMelioIntl();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<T>(defaultFilter);
  const label = formatMessage('filter.button.text');

  const onApply = () => {
    onChange(filter);
    handleOpenMenuChange(false);
  };

  const onClear = () => {
    onChange(defaultFilter);
    handleOpenMenuChange(false);
  };

  const handleOpenMenuChange = (isOpen: boolean) => {
    setFilter(activeFilter);
    setIsMenuOpen(isOpen);
  };

  const isDefaultFilterSelected = filter === defaultFilter;

  const renderOptions = () =>
    options.map(({ id, label, status }, index) => {
      const isSelected = activeFilter === id;
      const formatLabel = formatMessage(label);
      return {
        label: (
          <Container
            data-testid={`filter-item-${id}`}
            key={`${id}.${index}`}
            paddingY="s"
            paddingLeft="xs"
            width="fit-content"
          >
            <Group spacing="xs">
              {status && !isSelected ? (
                <Group>
                  <StatusIndicator status={status}>
                    <Container paddingRight="xs">
                      <Text textStyle="body3Semi" color="global.neutral.1000">
                        {formatMessage(label)}
                      </Text>
                    </Container>
                  </StatusIndicator>
                </Group>
              ) : (
                <Text textStyle="body3Semi" color="global.neutral.1000">
                  {formatMessage(label)}
                </Text>
              )}
              {id === defaultFilter && (
                <Text textStyle="body3Semi" color="global.neutral.800">
                  {formatMessage('filter.item.default')}
                </Text>
              )}
            </Group>
          </Container>
        ),
        value: id,
        ariaLabel: formatLabel,
      };
    });

  const renderMenuOptions = () => (
    <RadioGroup
      value={filter}
      variant="vertical"
      onChange={(e) => setFilter(e.target.value as T)}
      options={renderOptions()}
      gap="none"
      paddingX="m"
    ></RadioGroup>
  );

  const renderTrigger = () => (
    <Button
      leftElement={<Icon size="small" type="filter" color="inherit" aria-hidden />}
      label={label}
      variant="tertiary"
      size="medium"
      aria-label="icon-button-filter"
      isFullWidth
      data-testid="filter-trigger"
    />
  );

  const renderMenuFooter = () => (
    <Container>
      <Divider />
      <Container data-testid="filter-footer-menu" paddingX="s" paddingY="s">
        <Group justifyContent="space-between" alignItems="center">
          <Button
            label={formatMessage('filter.button.clear')}
            variant="secondary"
            onClick={onClear}
            data-testid="filter-clear"
            isDisabled={isDefaultFilterSelected}
          />
          <Button label={formatMessage('filter.button.apply')} onClick={onApply} data-testid="filter-apply" />
        </Group>
      </Container>
    </Container>
  );

  return (
    <Menu
      data-testid="filter-menu"
      trigger={renderTrigger()}
      onOpenChange={handleOpenMenuChange}
      isOpen={isMenuOpen}
      title={formatMessage(title)}
    >
      {renderMenuOptions()}
      {renderMenuFooter()}
    </Menu>
  );
};
