import { useModelApi } from '@melio/api-client';
import { FinancingApiClient } from '@melio/platform-api-axios-client';

import { convertOrgEligibilityResponseCentsToDollars } from './utils';

export const useFinancingCachedOrgEligibility = (props = {}) =>
  useModelApi({
    ...props,
    id: 'my-id',
    queryKey: ['FinancingApi', 'cachedEligibility'],
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    queryFn: (_id, ...args) => FinancingApiClient.getOrgFinancingCachedEligibility(...args),
    select: convertOrgEligibilityResponseCentsToDollars,
  });
