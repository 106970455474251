import { MessageKey } from '@melio/platform-i18n';

export const getUnsupportedTypeModalContent = (
  isUnsupportedBusinessType: boolean,
  isUnsupportedIndustryType: boolean
): {
  header: MessageKey;
  primaryButton: MessageKey;
  body: MessageKey;
} => {
  if (isUnsupportedBusinessType && isUnsupportedIndustryType) {
    return {
      header:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.unsupportedIndustryAndBusinessTypeModal.title',
      primaryButton:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.unsupportedIndustryAndBusinessTypeModal.cta',
      body: 'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.unsupportedIndustryAndBusinessTypeModal.content',
    };
  }
  if (isUnsupportedBusinessType) {
    return {
      header: 'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.unsupportedBusinessTypeModal.title',
      primaryButton: 'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.unsupportedBusinessTypeModal.cta',
      body: 'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.unsupportedBusinessTypeModal.content',
    };
  }

  return {
    header: 'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.unsupportedIndustryTypeModal.title',
    primaryButton: 'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.unsupportedIndustryTypeModal.cta',
    body: 'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.unsupportedIndustryTypeModal.content',
  };
};
