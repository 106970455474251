const PATH_SEPARATOR = '/';
const VAR_PREFIX = ':';

export type PathParams = Record<string, unknown>;
type Options = {
  pathSeparator?: string;
  varPrefix?: string;
};

export function compileUrlTemplate<T extends PathParams = PathParams>(
  template: string,
  { pathSeparator = PATH_SEPARATOR, varPrefix = VAR_PREFIX }: Options = {}
) {
  return (params: T | undefined = {} as T) => {
    if (!template.includes(varPrefix)) {
      return template;
    }
    return template
      .split(pathSeparator)
      .map((segment) => {
        if (segment.startsWith(varPrefix)) {
          const key = segment.slice(1);
          if (key in params) {
            return params[key];
          } else {
            throw new Error(`Parameter ${key} not found in provided object.`);
          }
        }
        return segment;
      })
      .join(pathSeparator);
  };
}
