import { DeliveryMethodsApiClient } from '@melio/platform-api-axios-client';

import { PaginatedCollectionParams, useCollection, UseCollectionProps } from '../../api-client';
import {
  CreateFN,
  DeleteFN,
  PatchReceivingMethod,
  PostReceivingMethod,
  QueryFN,
  ReceivingMethod,
  UpdateFN,
} from './types';
import { useReceivingMethodsMutations } from './useReceivingMethodsMutations';

export type UseReceivingMethodsProps = UseCollectionProps<
  QueryFN,
  CreateFN,
  UpdateFN,
  ReceivingMethod,
  PaginatedCollectionParams<QueryFN>,
  PostReceivingMethod,
  PatchReceivingMethod
>;

export const useReceivingMethods = (props: UseReceivingMethodsProps = {}) => {
  const query = useCollection<
    QueryFN,
    CreateFN,
    UpdateFN,
    DeleteFN,
    ReceivingMethod,
    PostReceivingMethod,
    PatchReceivingMethod
  >({
    ...props,
    queryKey: 'ReceivingMethodsApi',
    queryFn: DeliveryMethodsApiClient.getDeliveryMethods,
    createFn: DeliveryMethodsApiClient.postDeliveryMethods,
    deleteFn: DeliveryMethodsApiClient.deleteDeliveryMethodsDeliveryMethodId,
    updateFn: DeliveryMethodsApiClient.patchDeliveryMethodsDeliveryMethodId,
    select: (data) => data.filter((item) => item.type === 'bank-account') as ReceivingMethod[],
  });

  const { createFromFundingSource } = useReceivingMethodsMutations(query.queryKey);

  return {
    ...query,
    createFromFundingSource,
  };
};

export type ReceivingMethodCollection = ReturnType<typeof useReceivingMethods>;
