import { ExternalLayout } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import {
  AutoPayment,
  useAccountWithLogoUrl,
  useAutoPaymentOptions,
  useFundingSources,
  useVendor,
  Vendor,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useSystemMessage } from '@melio/platform-utils';
import { useEffect } from 'react';

import { MonitoredAction } from '../../../monitoring';
import { AutoPaymentAction } from '../screens';
import { AutoPaymentActivationScreen } from './screens';
import { AutoPaymentActivationFormFields } from './screens/AutoPaymentActivation/AutoPaymentActivationForm/types';

const INTENT = {
  [AutoPaymentAction.Create]: 'set-autopay',
  [AutoPaymentAction.Edit]: 'edit-autopay',
};

export type AutoPaymentActivationFormActivityProps = {
  vendorId: Vendor['id'];
  onDone: (fields: AutoPaymentActivationFormFields) => void;
  onClose: () => void;
  isMutating: boolean;
  autoPayment?: AutoPayment;
  action: AutoPaymentAction;
};
export const AutoPaymentActivationFormActivity = withAnalyticsContext<AutoPaymentActivationFormActivityProps>(
  ({ vendorId, onClose, onDone, isMutating, autoPayment, action, setAnalyticsProperties }) => {
    const { data: vendor } = useVendor({ id: vendorId });
    const { showMessage } = useSystemMessage();
    const { formatMessage } = useMelioIntl();
    const { startAction } = useMonitoring<MonitoredAction>();
    const { data: autoPaymentOptions } = useAutoPaymentOptions({
      id: vendorId,
      onError: () => {
        showMessage({
          type: 'error',
          title: formatMessage('activities.vendor.autoPaymentActivationForm.screens.error.toast'),
        });

        onClose();
      },
    });
    const { data: fundingSources } = useFundingSources();
    const { data: account } = useAccountWithLogoUrl({ id: 'me' });
    const { track, createTrackHandler } = useAnalytics();
    const trackAndHandleClick = createTrackHandler<{
      Cta: 'exit';
    }>('PaymentMethod', 'Click');

    setAnalyticsProperties({
      PageName: 'set-up-autopay',
      Intent: INTENT[action],
      Flow: 'autopay',
      VendorId: vendorId,
    });

    useEffect(() => {
      if (!vendor) {
        return;
      }
      track('PaymentMethod', 'View');
    }, [track, vendor]);

    const handleSubmit = (fields: AutoPaymentActivationFormFields) => {
      track('PaymentMethod', 'Click', {
        Cta: 'set-up-autopay',
        DeliveryDate: fields.deliveryDateType,
        daysBeforeDueDate: fields.scheduleDaysBefore,
      });
      startAction(
        action === AutoPaymentAction.Create
          ? 'auto_pay_activation_creation_submitted'
          : 'auto_pay_activation_edit_submitted'
      );
      onDone(fields);
    };

    if (!autoPaymentOptions || !vendor || !fundingSources || !account) {
      return <ExternalLayout isLoading />;
    }

    return (
      <AutoPaymentActivationScreen
        vendor={vendor}
        autoPaymentOptions={autoPaymentOptions}
        fundingSources={fundingSources}
        account={account}
        isSaving={isMutating}
        onDone={handleSubmit}
        onClose={() => trackAndHandleClick({ Cta: 'exit' }, onClose)}
        autoPayment={autoPayment}
        mode={action === AutoPaymentAction.Create ? 'create' : 'edit'}
      />
    );
  }
);
