import { Flex } from '@chakra-ui/react';
import {
  Container,
  Control,
  Form,
  FormLineItems,
  NakedButton,
  Text,
  Typography,
  useBreakpoint,
  useFieldArray,
  useWatch,
} from '@melio/penny';
import {
  AccountingPlatformBillLineItemLabelOption,
  Currency,
  useAccountingPlatformItems,
  useAccountingPlatforms,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { isEmpty, keyBy, mapValues } from 'lodash';
import { useMemo, useRef, useState } from 'react';

import { useLineItemAccessibility } from '../../../hooks/useLineItemAccessibility';
import { useLineItemAnalytics } from '../../../hooks/useLineItemAnalytics';
import { AddBillV2FormDefaultValues, AddBillV2FormValues, AddBillV2SetValue, RegisterFieldFn } from '../../../types';
import { createEmptyItemBasedLineItem, useGetLabelForItemsPickerByAccountingSoftware } from '../../../utils';
import { TotalAmount } from '../../TotalAmount';
import { ItemBasedBillLineItem } from './ItemBasedBillLineItem';

type Props = {
  isHidden: boolean;
  formControl: Control<AddBillV2FormValues>;
  formDefaultValues?: AddBillV2FormDefaultValues;
  billLineItemLabelOptions?: AccountingPlatformBillLineItemLabelOption[];
  currency?: string;
  setValue: AddBillV2SetValue;
  registerField: RegisterFieldFn<AddBillV2FormValues>;
};

export const ItemBasedBillLineItemsForm = ({
  isHidden,
  formControl,
  currency,
  formDefaultValues,
  billLineItemLabelOptions,
  setValue,
  registerField,
}: Props) => {
  const { isExtraSmallScreen: isMobile } = useBreakpoint();
  const { onDeleteLineItemSuccess } = useLineItemAccessibility();
  const { trackLineItemAdded, trackLineItemDeleted } = useLineItemAnalytics();
  const { formatMessage, formatCurrency } = useMelioIntl();
  const { activeAccountingPlatform, hasAccountingPlatform } = useAccountingPlatforms();
  const { label: selectItemLabel } = useGetLabelForItemsPickerByAccountingSoftware({
    activeAccountingPlatform,
    index: 0,
  });
  const { data: accountingPlatformItems, hasAccountingPlatformItems } = useAccountingPlatformItems({
    accountingPlatformId: activeAccountingPlatform?.id,
    enabled: hasAccountingPlatform,
  });

  const { fields, append, remove, update } = useFieldArray<AddBillV2FormValues, 'itemBasedLineItems'>({
    control: formControl,
    name: 'itemBasedLineItems',
    shouldUnregister: false,
  });
  const categoryFields = useWatch({
    control: formControl,
    name: 'categoryBasedLineItems',
  });
  const amount = useWatch({
    control: formControl,
    name: 'amount',
  });
  const totalAmount = formatCurrency(parseFloat(amount || '0'), currency as Currency);
  const footerRef = useRef<HTMLDivElement>(null);

  const [itemFields] = useWatch({
    control: formControl,
    name: ['itemBasedLineItems'],
  });

  const initialLineItems = formDefaultValues?.itemBasedLineItems;
  const initialLineItemExternalLabelIdsMap = useMemo(
    () => mapValues(keyBy(initialLineItems, 'lineItemId'), 'externalLabelId'),
    [initialLineItems]
  );

  const [shouldAutoFocus, setShouldAutoFocus] = useState(false);
  const onAddLineItem = () => {
    if (!shouldAutoFocus) {
      setShouldAutoFocus(true);
    }

    if (!fields.length && !categoryFields?.length) {
      append(
        createEmptyItemBasedLineItem({
          quantity: '1',
          amount: amount ?? '',
          unitPrice: amount ?? '',
        })
      );
      return;
    }

    append(createEmptyItemBasedLineItem());
    trackLineItemAdded();
    window.setTimeout(() => footerRef.current?.scrollIntoView({ behavior: 'smooth' }));
    return;
  };

  if (!hasAccountingPlatformItems) {
    return null;
  }

  const onDeleteLineItem = (index: number) => {
    remove(index);
    trackLineItemDeleted();
    onDeleteLineItemSuccess();
  };

  if (isHidden) {
    return null;
  }

  const canDeleteRow = (categoryFields?.length || 0) + (itemFields?.length || 0) > 1;
  const shouldShowClassField = !isEmpty(billLineItemLabelOptions);
  const hasLineItems = fields.length > 0;

  const lineItems = isMobile ? (
    <FormLineItems.MobileList>
      {fields.map((field, index) => (
        <ItemBasedBillLineItem
          updateField={update}
          formControl={formControl}
          key={field.id}
          initialExternalLabelId={
            field.lineItemId ? (initialLineItemExternalLabelIdsMap?.[field.lineItemId] as string) : undefined
          }
          items={accountingPlatformItems ?? []}
          itemsPickerProps={registerField(`itemBasedLineItems.${index}.externalItemId`)}
          descriptionFieldProps={registerField(`itemBasedLineItems.${index}.description`)}
          amountFieldProps={registerField(`itemBasedLineItems.${index}.amount`)}
          unitPriceFieldProps={registerField(`itemBasedLineItems.${index}.unitPrice`)}
          quantityFieldProps={registerField(`itemBasedLineItems.${index}.quantity`)}
          index={index}
          currency={currency}
          setValue={setValue}
          onClickRemoveLineItem={canDeleteRow ? () => onDeleteLineItem(index) : null}
          labelFieldProps={registerField(`itemBasedLineItems.${index}.externalLabelId`)}
          activeAccountingPlatform={activeAccountingPlatform}
          billLineItemLabelOptions={billLineItemLabelOptions}
          hasLineItems={hasLineItems}
          shouldAutoFocus={shouldAutoFocus}
        />
      ))}
    </FormLineItems.MobileList>
  ) : (
    <Form.ContentBox colSpan={16}>
      <FormLineItems>
        <FormLineItems.HeaderRow>
          <FormLineItems.HeaderCell size="xs" />
          <FormLineItems.HeaderCell size="m">
            <Typography.Label labelShouldSupportEllipsis label={selectItemLabel} description="*" />
          </FormLineItems.HeaderCell>
          <FormLineItems.HeaderCell size="m">
            <Typography.Label
              labelShouldSupportEllipsis
              label={formatMessage('activities.addBillV2.lineItems.itemBased.description.label')}
            />
          </FormLineItems.HeaderCell>
          <FormLineItems.HeaderCell size="s">
            <Typography.Label
              labelShouldSupportEllipsis
              label={formatMessage('activities.addBillV2.lineItems.itemBased.quantity.placeholder')}
              description="*"
            />
          </FormLineItems.HeaderCell>
          <FormLineItems.HeaderCell size="s">
            <Typography.Label
              labelShouldSupportEllipsis
              label={formatMessage('activities.addBillV2.lineItems.itemBased.unitPrice.placeholder')}
              description="*"
            />
          </FormLineItems.HeaderCell>
          {shouldShowClassField && (
            <FormLineItems.HeaderCell size="m">
              <Typography.Label
                labelShouldSupportEllipsis
                label={formatMessage('activities.addBillV2.lineItems.itemBased.class.label')}
              />
            </FormLineItems.HeaderCell>
          )}
          <FormLineItems.HeaderCell size={shouldShowClassField ? 'l' : 'm'} isSticky={shouldShowClassField}>
            <Typography.Label
              labelShouldSupportEllipsis
              label={formatMessage('activities.addBillV2.lineItems.itemBased.amount.placeholder')}
              description="*"
            />
          </FormLineItems.HeaderCell>
        </FormLineItems.HeaderRow>

        <FormLineItems.Body>
          {fields.map((field, index) => (
            <ItemBasedBillLineItem
              updateField={update}
              formControl={formControl}
              key={field.id}
              initialExternalLabelId={
                field.lineItemId ? (initialLineItemExternalLabelIdsMap?.[field.lineItemId] as string) : undefined
              }
              items={accountingPlatformItems ?? []}
              itemsPickerProps={registerField(`itemBasedLineItems.${index}.externalItemId`)}
              descriptionFieldProps={registerField(`itemBasedLineItems.${index}.description`)}
              amountFieldProps={registerField(`itemBasedLineItems.${index}.amount`)}
              unitPriceFieldProps={registerField(`itemBasedLineItems.${index}.unitPrice`)}
              quantityFieldProps={registerField(`itemBasedLineItems.${index}.quantity`)}
              index={index}
              currency={currency}
              setValue={setValue}
              onClickRemoveLineItem={canDeleteRow ? () => onDeleteLineItem(index) : null}
              labelFieldProps={registerField(`itemBasedLineItems.${index}.externalLabelId`)}
              activeAccountingPlatform={activeAccountingPlatform}
              billLineItemLabelOptions={billLineItemLabelOptions}
              hasLineItems={hasLineItems}
              shouldAutoFocus={shouldAutoFocus}
            />
          ))}
        </FormLineItems.Body>
      </FormLineItems>
    </Form.ContentBox>
  );

  return (
    <>
      <Form.ContentBox colSpan={16} data-testid="add-bill-v2-item-line-items-title">
        <Container paddingTop="xs">
          <Text as="h3" textStyle="body2Semi">
            {formatMessage('activities.addBillV2.lineItems.itemBased.sectionTitle')}
          </Text>
        </Container>
      </Form.ContentBox>

      {!isEmpty(fields) && lineItems}

      <Form.ContentBox colSpan={16}>
        <Flex justifyContent="space-between" pb="s" alignItems="flex-start" ref={footerRef}>
          <NakedButton
            data-testid="add-bill-v2-items-line-items-add-new-line"
            variant="secondary"
            label={formatMessage('activities.addBillV2.lineItems.itemBased.addNewLine')}
            onClick={onAddLineItem}
            aria-label={formatMessage('activities.addBillV2.lineItems.itemBased.addNewLine.ariaLabel')}
          />
          <TotalAmount amount={totalAmount} />
        </Flex>
      </Form.ContentBox>
    </>
  );
};
