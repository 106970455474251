import { FileInfo, useFiles } from '@melio/platform-api';
import { useEffect, useState } from 'react';

export type UseUploadBillFileProps = {
  onError?: (error: Error) => void;
  onUploadStarted?: (file: File) => void;
  onUploadFinished?: (fileInfo: FileInfo) => void;
  initialFileInfo?: FileInfo | null;
  initialFile?: File | null;
};
export function useUploadBillFile({
  onError,
  initialFile,
  initialFileInfo,
  onUploadStarted,
  onUploadFinished,
}: UseUploadBillFileProps) {
  const [file, setFile] = useState<File | null>(initialFile ?? null);
  const [fileInfo, setFileInfo] = useState<FileInfo | null>(initialFileInfo ?? null);
  const { create: uploadBillFile, isMutating: isUploadingBillFile } = useFiles({ enabled: false });

  useEffect(() => {
    setFileInfo(initialFileInfo ?? null);
  }, [initialFileInfo]);

  useEffect(() => {
    setFile(initialFile ?? null);
  }, [initialFile]);

  const uploadFile = async (fileToUpload: File) => {
    try {
      onUploadStarted?.(fileToUpload);
      const res = await uploadBillFile(fileToUpload);
      setFileInfo(res);
      onUploadFinished?.(res);
    } catch (e) {
      onError?.(e as Error);
    }
  };
  function setFileAndRemoveFileInfo(file?: File | null) {
    setFile(file || null);
    setFileInfo(null);

    if (file) {
      uploadFile(file);
    }
  }

  const getFileValue = () => {
    if (file) {
      return file;
    }
    if (fileInfo?.previewUrls[0]) {
      return {
        url: fileInfo.previewUrls[0],
        type: 'image/jpeg',
        name: fileInfo.fileName || 'file',
      };
    }
    return null;
  };

  return {
    file,
    fileInfo,
    fileValue: getFileValue(),
    setFile: setFileAndRemoveFileInfo,
    isLoading: isUploadingBillFile,
  };
}
