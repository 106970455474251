import { Container, Group, SectionBanner } from '@melio/penny';
import { Payment, PaymentFullyExpanded } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { isPaymentScheduledVoid, isPaymentScheduledVoidAndRefund } from '../../../../../utils/pay-flow/Payment.utils';
import { usePaymentDescription } from '../../../hooks/usePaymentDescription';
import { PaymentDescription } from '../PaymentDescription';
import { PaymentDetailsTop } from '../PaymentDetailsTop';
import { PayFromSectionRefund } from '../sections/PayFromSectionRefund';
import { SectionWrapper } from '../sections/SectionWrapper';

export const RefundInProgressSectionBanner = ({ payment }: { payment: PaymentFullyExpanded }) => {
  const { formatMessage, formatDate } = useMelioIntl();

  const isProcessingVoid = isPaymentScheduledVoid(payment);
  const isProcessingVoidAndRefund = isPaymentScheduledVoidAndRefund(payment);
  const refundSentDate = formatDate(payment.processedTimeline?.refundSent, { dateStyle: 'medium' });

  const getDescription = (payment: Payment) => {
    const isRefundSent = !!payment.processedTimeline?.refundSent;
    if (isProcessingVoid) {
      return formatMessage('widgets.paymentDetails.failed.reason.voidAndRefundInitiated.description');
    } else if (isProcessingVoidAndRefund) {
      return formatMessage('widgets.paymentDetails.failed.reason.voidAndRefundInProgress.description', {
        date: refundSentDate,
      });
    } else if (isRefundSent) {
      return formatMessage('widgets.paymentDetails.failed.reason.refundInProgress.description', {
        date: formatDate(payment.processedTimeline?.refundSent, { dateStyle: 'medium' }),
      });
    } else {
      return formatMessage('widgets.paymentDetails.failed.reason.refundInitiated.description');
    }
  };

  const description = getDescription(payment);

  return <SectionBanner description={description} variant="informative" data-testid="refund-in-progress-alert" />;
};

export const RefundInProgress = ({ payment }: { payment: PaymentFullyExpanded }) => {
  const { fundingSource, processedTimeline } = payment;
  const isProcessingVoid = isPaymentScheduledVoid(payment);
  const initiatedDate = isProcessingVoid ? processedTimeline?.voidCheckInitiated : processedTimeline?.refundInitiated;
  const description = usePaymentDescription(payment);

  return (
    <>
      {description ? <PaymentDescription description={description} /> : null}
      <Container data-testid="pay-dashboard-payment-in-progress">
        <Group variant="vertical" spacing="m">
          <SectionWrapper>
            <PaymentDetailsTop payment={payment} />
          </SectionWrapper>

          <SectionWrapper>
            <PayFromSectionRefund fundingSource={fundingSource} initiatedDate={initiatedDate} />
          </SectionWrapper>
        </Group>
      </Container>
    </>
  );
};
