import { Tourtip, TourtipProps } from '@melio/penny';
import { EventProperties, withAnalyticsContext } from '@melio/platform-analytics';
import React from 'react';

export const PlatformTourtip = withAnalyticsContext<TourtipProps & { analyticsProperties?: EventProperties }>(
  ({ disableScrolling = true, setAnalyticsProperties, analyticsProperties, ...tourtipProps }) => {
    setAnalyticsProperties(analyticsProperties);
    return <Tourtip disableScrolling={disableScrolling} {...tourtipProps} />;
  }
);
