import { Payment } from '@melio/platform-api';
import { noop } from 'lodash';
import { createContext } from 'react';

export const PaymentDrawerContext = createContext<{
  onRetryFailedToDeliverPayment: (paymentId: Payment['id']) => void;
  onRetryFailedToCollectPayment: (paymentId: Payment['id']) => void;
  onRefundPayment: (paymentId: Payment['id']) => void;
  onVoidAndRefundPayment: (paymentId: Payment['id']) => void;
  onVoidAndResendPayment: (paymentId: Payment['id']) => void;
  onEditPayment: (paymentId: Payment['id']) => void;
  onMarkAsUnpaid: (payment: Payment) => void;
  onEditBillSubscription?: (billSubscriptionId: Payment['id']) => void;
}>({
  onRetryFailedToDeliverPayment: noop,
  onRetryFailedToCollectPayment: noop,
  onRefundPayment: noop,
  onVoidAndRefundPayment: noop,
  onVoidAndResendPayment: noop,
  onEditPayment: noop,
  onMarkAsUnpaid: noop,
  onEditBillSubscription: noop,
});
