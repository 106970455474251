import { CompleteLegalInfoFormWidget, CompleteLegalInfoFormWidgetFields } from '@melio/ap-widgets';
import { Group, useFormSubmissionController } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { LegalInfoField, TaxIdTypeEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';

import { SubmissionTargetType } from '../../../delivery-methods/AddVendorBankDetails/types';
import { DeliveryMethodFlowOrigin } from '../../../delivery-methods/types';
import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';

export type AddInternationalBusinessInfoProps = {
  isSaving?: boolean;
  isLoading?: boolean;
  defaultValues?: Partial<
    Pick<
      CompleteLegalInfoFormWidgetFields,
      'legalCompanyName' | 'dateOfBirth' | 'legalAddress' | 'taxInfoIdentifier' | 'taxInfoType' | 'businessType'
    >
  >;
  onBack: VoidFunction;
  onClose?: VoidFunction;
  onDone: (
    data: CompleteLegalInfoFormWidgetFields,
    event?: React.MouseEvent<HTMLButtonElement>,
    target?: SubmissionTargetType
  ) => void;
  missingLegalInfoFields: LegalInfoField[];
  taxIdType?: TaxIdTypeEnum;
  origin: DeliveryMethodFlowOrigin;
};

export const AddInternationalBusinessInfoScreen = forwardRef<AddInternationalBusinessInfoProps, 'div'>(
  (
    {
      isSaving,
      defaultValues,
      onBack,
      onClose,
      onDone,
      origin,
      isLoading,
      missingLegalInfoFields,
      taxIdType,
      ...props
    },
    ref
  ) => {
    const { track } = useAnalytics();
    const { formatMessage } = useMelioIntl();
    const { onSubmissionStateChange, cancelButtonProps, submitButtonProps, handleSubmit } =
      useFormSubmissionController<CompleteLegalInfoFormWidgetFields>();

    const trackClick = (cta: string) =>
      track(`Organization`, 'Click', {
        Cta: cta,
      });

    return (
      <NewSinglePaymentStepLayout
        data-component="AddInternationalDetailsActivity.AddInternationalBusinessInfoScreen"
        data-testid="add-international-details-activity-add-business-info-screen"
        isLoading={isLoading}
        {...props}
        ref={ref}
        headerContent={
          <NewSinglePaymentStepLayout.Header>
            {onClose && (
              <NewSinglePaymentStepLayout.CloseButton
                onClick={() => {
                  trackClick('exit');
                  onClose();
                }}
              />
            )}
            <NewSinglePaymentStepLayout.BackButton {...cancelButtonProps} onClick={onBack} />
          </NewSinglePaymentStepLayout.Header>
        }
        footerContent={
          <NewSinglePaymentStepLayout.Actions>
            {origin === 'vendor' ? (
              taxIdType ? (
                <Group variant="vertical" spacing="m" width="full">
                  <NewSinglePaymentStepLayout.NextButton
                    {...submitButtonProps}
                    label={formatMessage(
                      `activities.addInternationalDetails.screens.addInternationalBusinessInfo.actions.vendorOrigin.continueToBill`
                    )}
                    onClick={() => {
                      trackClick('save-details-and-add-a-bill');
                      handleSubmit?.('continue')();
                    }}
                  />
                  <NewSinglePaymentStepLayout.SecondaryNextButton
                    {...submitButtonProps}
                    onClick={() => {
                      trackClick('save-and-close');
                      handleSubmit?.('close')();
                    }}
                    variant="tertiary"
                    label={formatMessage(
                      'activities.addInternationalDetails.screens.addInternationalBusinessInfo.actions.vendorOrigin.close'
                    )}
                  />
                </Group>
              ) : (
                <NewSinglePaymentStepLayout.NextButton
                  {...submitButtonProps}
                  label={formatMessage(
                    `activities.addInternationalDetails.screens.addInternationalBusinessInfo.actions.vendorOrigin.continue`
                  )}
                  onClick={() => {
                    trackClick('save-details');
                    handleSubmit?.('close')();
                  }}
                />
              )
            ) : (
              <NewSinglePaymentStepLayout.NextButton
                {...submitButtonProps}
                label={formatMessage(
                  `activities.addInternationalDetails.screens.addInternationalBusinessInfo.actions.paymentOrigin.continue`
                )}
                onClick={() => {
                  trackClick('continue');
                  handleSubmit?.('continue')();
                }}
              />
            )}
          </NewSinglePaymentStepLayout.Actions>
        }
      >
        <NewSinglePaymentStepLayout.Title>
          {formatMessage('activities.addInternationalDetails.screens.addInternationalBusinessInfo.title')}
        </NewSinglePaymentStepLayout.Title>
        <NewSinglePaymentStepLayout.Description>
          {formatMessage('activities.addInternationalDetails.screens.addInternationalBusinessInfo.description')}
        </NewSinglePaymentStepLayout.Description>
        <NewSinglePaymentStepLayout.Content>
          <CompleteLegalInfoFormWidget
            missingLegalInfoFields={missingLegalInfoFields}
            taxIdType={taxIdType}
            onSubmit={onDone}
            isSaving={isSaving}
            defaultValues={defaultValues}
            onSubmissionStateChange={onSubmissionStateChange}
          />
        </NewSinglePaymentStepLayout.Content>
      </NewSinglePaymentStepLayout>
    );
  }
);

AddInternationalBusinessInfoScreen.displayName = 'AddInternationalDetailsActivity.AddInternationalBusinessInfoScreen';
