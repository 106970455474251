import { Table } from '@melio/penny';
import { Bill, BillingFeeSetting, PaymentIntent } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';
import { forwardRef, sumBy } from '@melio/platform-utils';

import { getTotalFee, usePaymentFees } from './AmountCell.utils';

export type AmountCellProps = {
  paymentIntent: PaymentIntent;
  bills: Bill[];
  orgBillingFeeSettings: BillingFeeSetting[];
};

export const AmountCell = forwardRef<AmountCellProps, 'div'>(
  ({ paymentIntent, orgBillingFeeSettings, bills, ...props }, ref) => {
    const {
      settings: { batchPayments },
    } = useConfig();
    const amountToPay = sumBy(bills, (bill) => bill.balance);

    const fee = getTotalFee(paymentIntent.estimatedFees);
    const shouldShowFees = fee > 0 || batchPayments.showZeroFees;

    const { getFeesTooltip } = usePaymentFees(paymentIntent.estimatedFees, orgBillingFeeSettings);
    const tooltip = getFeesTooltip();
    return (
      <Table.AmountCell
        {...props}
        value={amountToPay}
        ref={ref}
        {...(shouldShowFees && { additionalAmount: { amount: fee, tooltip } })}
      />
    );
  }
);

AmountCell.displayName = 'AmountCell';
