import { Button, Divider, Form, useMelioForm } from '@melio/penny';
import {
  DeliveryPreference,
  FundingSource,
  FundingSourceType,
  PaymentIntentWithDerivatives,
  SelectedRepaymentOption,
} from '@melio/platform-api';
import { useDateUtils } from '@melio/platform-utils';
import { addDays } from 'date-fns';
import { date, object, SchemaOf, string } from 'yup';

import { SelectedRepaymentOptionFormSchema } from '../types';
import { ApprovedBankAccountPicker, PaymentDateField, RepaymentStartDate } from './SelectedRepaymentOptionFormFields';

const filterVerifiedBankAccounts = (fundingSources: FundingSource) =>
  fundingSources.isVerified && fundingSources.type === FundingSourceType.BankAccount;

const FINANCING_MAX_AVAILABLE_SCHEDULING_DAYS = 30;

export const SelectedCardContent: React.VFC<{
  testId?: string;
  repaymentFundingSources: FundingSource[];
  paymentIntent?: PaymentIntentWithDerivatives;
  onSubmit: (values: SelectedRepaymentOptionFormSchema) => void;
  selectedRepaymentTerm?: SelectedRepaymentOption;
  selectedDate?: Date;
  setSelectedDate: (date?: Date) => void;
  deliveryPreferenceOption?: DeliveryPreference;
  onAddBankAccount: VoidFunction;
  isLoadingSelectRepaymentTerms?: boolean;
  isDisplayed?: boolean;
}> = (props) => {
  const verifiedFundingSources = props.repaymentFundingSources.filter(filterVerifiedBankAccounts);
  const defaultFundingSource = verifiedFundingSources.find((fs) => fs.id === props.paymentIntent?.fundingSourceId)
    ? props.paymentIntent?.fundingSourceId
    : verifiedFundingSources[0]?.id;

  const { registerField, submitButtonProps, formProps, setValue } = useMelioForm({
    onSubmit: props.onSubmit,
    schema: object().shape({
      paymentScheduleDate: date().required(),
      repaymentFundingSourceId: string().required(),
    }) as SchemaOf<SelectedRepaymentOptionFormSchema>,
    defaultValues: {
      paymentScheduleDate: props.selectedDate ?? undefined,
      repaymentFundingSourceId: defaultFundingSource ?? undefined,
    },
  });

  const { createDate } = useDateUtils();

  const onScheduleDateChanged = (date?: Date) => {
    if (date?.getTime() != props.selectedDate?.getTime()) {
      setValue('paymentScheduleDate', date || props.paymentIntent?.scheduledDate || createDate());
      props.setSelectedDate?.(date || props.paymentIntent?.scheduledDate || createDate());
    }
  };

  if (!props.isDisplayed) {
    return null;
  }

  return (
    <>
      <Form columns={2} size="small" {...formProps}>
        <PaymentDateField
          testId={`${props.testId ?? ''}-payment-date-field`}
          dateFieldProps={{
            ...registerField('paymentScheduleDate'),
            onChange: (date) => onScheduleDateChanged(date as Date),
            isViewMode: props.isLoadingSelectRepaymentTerms,
          }}
          aria-label="payment schedule date"
          minDate={props.paymentIntent?.computedDeliveryPreference?.minScheduleDate}
          maxDate={addDays(createDate(), FINANCING_MAX_AVAILABLE_SCHEDULING_DAYS)}
          selectedRepaymentTerm={props.selectedRepaymentTerm}
          deliveryPreferenceOption={props.deliveryPreferenceOption}
          dueDate={props.paymentIntent?.billInfo.dueDate}
        />
        <RepaymentStartDate
          startDate={props.selectedRepaymentTerm?.installments?.[0]?.scheduledDate}
          isLoading={props.isLoadingSelectRepaymentTerms}
        />
        <Form.ContentBox colSpan={2}>
          <Divider />
        </Form.ContentBox>
        <ApprovedBankAccountPicker
          registerFormFieldProps={{ ...registerField('repaymentFundingSourceId') }}
          testId={`${props.testId ?? ''}-bank-account-picker`}
          fundingSources={verifiedFundingSources}
          onAddBankAccount={props.onAddBankAccount}
        />
        <Form.ContentBox colSpan={2}>
          <Button
            isFullWidth
            {...submitButtonProps}
            isLoading={props.isLoadingSelectRepaymentTerms || submitButtonProps.isLoading}
            isDisabled={submitButtonProps.isDisabled || verifiedFundingSources.length === 0}
            label="continue"
            data-testid="repayment-card-continue"
          />
        </Form.ContentBox>
      </Form>
    </>
  );
};
