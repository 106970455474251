import { Box, useBreakpointValue } from '@chakra-ui/react';
import { Group, Illustration, IllustrationProps } from '@melio/penny';
import { DeliveryPreferenceType } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';

export type PaymentScheduledScreenProps = {
  date: string;
  amount: string;
  vendorName: string;
  shouldShowNotifyVendorButton: boolean;
  deliveryPreferenceType?: DeliveryPreferenceType | null;
  onDone: VoidFunction;
  onNotifyVendor: VoidFunction;
};

export const PaymentScheduledScreen = ({
  date,
  amount,
  vendorName,
  shouldShowNotifyVendorButton,
  deliveryPreferenceType,
  onDone,
  onNotifyVendor,
}: PaymentScheduledScreenProps) => {
  const { formatMessage } = useMelioIntl();
  const illustrationSize = useBreakpointValue<IllustrationProps['size']>({ xs: 'small', s: 'small', m: 'large' });
  const descriptionLabel =
    deliveryPreferenceType === DeliveryPreferenceType.Check
      ? 'activities.voidAndResend.screens.paymentScheduled.check.description'
      : 'activities.voidAndResend.screens.paymentScheduled.ach.description';

  return (
    <NewSinglePaymentStepLayout
      data-component="VoidAndResendActivity.PaymentScheduledScreen"
      data-testid="payment-scheduled-activity-payments-scheduled-screen"
      footerContent={
        <NewSinglePaymentStepLayout.Actions>
          <Box width="100%">
            <Group variant="vertical" alignItems="center">
              {shouldShowNotifyVendorButton ? (
                <>
                  <NewSinglePaymentStepLayout.NextButton
                    isFullWidth
                    onClick={onNotifyVendor}
                    data-testid="track-void-and-resend-notify-vendor-button"
                    label={formatMessage('activities.voidAndResend.screens.paymentScheduled.notifyVendor')}
                  />
                  <NewSinglePaymentStepLayout.NakedNextButton
                    onClick={onDone}
                    data-testid="track-void-and-resend-go-to-dashboard-button"
                    label={formatMessage('activities.voidAndResend.screens.paymentScheduled.backToDashboard')}
                  />
                </>
              ) : (
                <NewSinglePaymentStepLayout.NextButton
                  onClick={onDone}
                  data-testid="track-void-and-resend-go-to-dashboard-button"
                  label={formatMessage('activities.voidAndResend.screens.paymentScheduled.backToDashboard')}
                />
              )}
            </Group>
          </Box>
        </NewSinglePaymentStepLayout.Actions>
      }
    >
      <Group justifyContent="center">
        <Illustration type="success" size={illustrationSize} />
      </Group>
      <NewSinglePaymentStepLayout.Title>
        {formatMessage('activities.voidAndResend.screens.paymentScheduled.title')}
      </NewSinglePaymentStepLayout.Title>
      <NewSinglePaymentStepLayout.Description>
        {formatMessage(descriptionLabel, {
          date,
          amount,
          vendorName,
        })}
      </NewSinglePaymentStepLayout.Description>
    </NewSinglePaymentStepLayout>
  );
};

PaymentScheduledScreen.displayName = 'VoidAndResendActivity.PaymentScheduledScreen';
