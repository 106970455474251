import { ComponentThemeType } from '@/cl/theme/theme.hooks';

export const CompanyCardListTheme: ComponentThemeType = (currentTheme) => ({
  baseStyle: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: ['100%', '100%', '1100px'],
      margin: { xs: 'none', s: '0 auto' } as never,
      gridGap: { xs: '24px', s: '48px' } as never,
    },
    title: {
      ...currentTheme.textStyles.heading1Semi,
      gap: { xs: '24px', s: '48px' } as never,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    } as never,
    listContainer: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',

      '& > *': {
        maxHeight: '140px',
        marginBottom: '16px',
        flexBasis: { xs: '100%', s: 'calc(50% - 8px)' } as never,
      },
    },
  },
});
