import { useAnalytics } from '@melio/platform-analytics';
import { useCallback } from 'react';

import { PromotionProps, PromotionType } from '../../types';
import { PromotionBanner } from '../PromotionBanner';

export const QBOSyncPromotionBanner = ({ analyticProperties, onClose, onDone }: PromotionProps) => {
  const { track } = useAnalytics();

  const onClickDismiss = useCallback(() => {
    track('SyncAccountingSoftware', 'Click', {
      ...analyticProperties,
      Intent: 'remove-banner',
      Cta: 'exit',
    });
    onClose();
  }, [analyticProperties, onClose, track]);

  const onClickConfirm = useCallback(() => {
    track('SyncAccountingSoftware', 'Click', {
      ...analyticProperties,
      Intent: 'sync-accounting-software',
      Cta: 'connect-now',
    });
    onDone();
  }, [analyticProperties, onDone, track]);

  return (
    <PromotionBanner
      promoType={PromotionType.QBOSync}
      data-testid="account-platform-sync-promotion"
      icon="quickbooks"
      onDismiss={onClickDismiss}
      onConfirm={onClickConfirm}
    />
  );
};
