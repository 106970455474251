import { ModalProps, StatusModal, Text } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useBill } from '@melio/platform-api';
import { useEffect } from 'react';

import { useCannotDeleteBillModal } from './useCannotDeleteBillModal';
import { useDeleteBillModal } from './useDeleteBillModal';

export type DeleteBillActionModalActivityProps = Pick<ModalProps, 'isOpen' | 'onClose'> & {
  billId: string;
  onSuccess?: VoidFunction;
};

export const DeleteBillActionModalActivity = withAnalyticsContext<DeleteBillActionModalActivityProps>((props) => {
  const { isOpen, billId, onClose, onSuccess, setAnalyticsProperties } = props;
  const { track } = useAnalytics();
  const { data: bill, isLoading: isBillLoading } = useBill({ id: billId, enabled: isOpen });

  const isBillHasCollectedPayments = bill?.payments?.find(
    (payment) =>
      payment.collectStatus === 'sent' || payment.collectStatus === 'cleared' || payment.collectStatus === 'settled'
  );

  setAnalyticsProperties({
    PageName: 'bill-delete',
    Intent: 'delete-bill',
    ...(isBillHasCollectedPayments
      ? {
          ErrorType: 'bill-cant-deleted-payment-in-progress',
          Status: 'failure',
        }
      : {}),
  });

  useEffect(() => {
    isOpen && track('Bill', 'View');
  }, [isOpen, track]);

  const handleClose = () => {
    track('Bill', 'Click');
    onClose();
  };

  const cannotDeleteModalProps = useCannotDeleteBillModal({ isOpen, onClose: handleClose });
  const deleteModalProps = useDeleteBillModal({ bill, isOpen, onClose: handleClose, onSuccess });

  const modalProps = isBillHasCollectedPayments ? cannotDeleteModalProps : deleteModalProps;
  const { description, ...rest } = modalProps;
  return (
    <StatusModal isLoading={isBillLoading} {...rest}>
      <Text>{description}</Text>
    </StatusModal>
  );
});

DeleteBillActionModalActivity.displayName = 'DeleteBillActionModalActivity';
