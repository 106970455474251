import {
  getFundingSourceIconType,
  getFundingSourceImageProps,
  PaymentReviewLineItem,
  useGetFundingSourceLabel,
} from '@melio/ap-widgets';
import { Group } from '@melio/penny';
import { PaymentFullyExpanded } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { SectionBlock } from './SectionBlock';

export const PaymentCanceledPayFromSection = ({ payment }: { payment: PaymentFullyExpanded }) => {
  const { formatMessage } = useMelioIntl();

  const { fundingSource } = payment;
  const { displayName, nickname } = fundingSource;

  const fundingSourceImage = getFundingSourceImageProps(fundingSource);
  const fundingSourceIconType = getFundingSourceIconType(fundingSource);
  const fundingSourceLabel = useGetFundingSourceLabel(fundingSource);

  return (
    <Group variant="vertical" spacing="s" data-testid="pay-dashboard-payment-details-canceled-pay-from-section">
      <SectionBlock.Title label={formatMessage('widgets.paymentDetails.payFrom.title')} />
      <PaymentReviewLineItem
        labelProps={{ label: fundingSourceLabel }}
        mainLabelProps={{ label: nickname || displayName }}
        {...(fundingSourceImage ? { imageIcon: fundingSourceImage } : { icon: { type: fundingSourceIconType } })}
      />
    </Group>
  );
};
