import { Bill, InvoiceData, InvoiceDataSourceEntityTypeEnum } from '@melio/platform-api';

export const buildInvoiceDataFromBill = ({ id, balance, invoice }: Bill): InvoiceData => ({
  invoiceNumber: invoice.number || null,
  amount: balance,
  sourceEntity: {
    id,
    type: InvoiceDataSourceEntityTypeEnum.Bill,
  },
});
