/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useGetFundingSourceContent } from '@melio/ap-domain';
import { PaymentReviewLineItem } from '@melio/ap-widgets';
import { Group } from '@melio/penny';
import { PaymentFullyExpanded } from '@melio/platform-api';
import { useConfig, useMelioIntl } from '@melio/platform-provider';

import { SectionBlock } from '../SectionBlock';
import { LoanDetails } from './components/LoanDetails';
import { LoanHeader } from './components/LoanHeader';

export const LoanSection = ({ payment }: { payment: PaymentFullyExpanded }) => {
  const { formatMessage } = useMelioIntl();
  const { settings } = useConfig();

  const { fundingSource, loan } = payment;

  return (
    <Group variant="vertical" spacing="s" data-testid="payment-review-repayment-section">
      <SectionBlock.Title label={formatMessage('widgets.paymentDetails.payFrom.loan.title')} />
      <Group variant="vertical" spacing="m" hasDivider>
        <LoanHeader payment={payment} />
        <LoanDetails loan={loan!} />
        <PaymentReviewLineItem
          labelProps={{ label: formatMessage('widgets.paymentDetails.payFrom.loan.fundingSource.title') }}
          mainLabelProps={{ label: useGetFundingSourceContent(fundingSource) }}
          icon={{ type: settings.icons.fundingSource.bank }}
        />
      </Group>
    </Group>
  );
};
