import { ARErrorCode, useInvoicePreference, useMelioIntl } from '@melio/ar-domain';
import { useToast } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { EditInvoiceFormatDialogScreen, InvoiceFormatFormFields } from './screens';

type EditInvoiceFormatDialogActivityProps = {
  onError?: ARErrorFunction;
  onDone: VoidFunction;
  onClose: VoidFunction;
};

export const EditInvoiceFormatDialogActivity = forwardRef<EditInvoiceFormatDialogActivityProps>(
  ({ onDone, onClose, onError, ...props }, ref) => {
    const { data: invoicePreferences, isFetching, isMutating, update } = useInvoicePreference({ onError });
    const { toast } = useToast();
    const { formatMessage } = useMelioIntl();

    // TODO: ME-69180: REMOVE STRING CASTING
    const onSubmit = (values: InvoiceFormatFormFields) => {
      update({ prefix: values.prefix, invoiceNumber: values.invoiceNumber.toString() })
        .then(() => {
          onDone();
          toast({
            type: 'success',
            title: formatMessage('ar.settings.flows.invoiceSettings.sections.editInvoiceFormat.toast.success.label'),
          });
        })
        .catch((err: ARPlatformError) => {
          toast({
            type: 'error',
            title: formatMessage(
              err.errorCode === ARErrorCode.DuplicateInvoiceNumber
                ? 'ar.settings.flows.invoiceSettings.sections.editInvoiceFormat.toast.failure.duplicatedInvoiceNumber.label'
                : 'ar.settings.flows.invoiceSettings.sections.editInvoiceFormat.toast.failure.generalErrorMessage.label'
            ),
          });
          onError?.(err);
        });
    };

    return (
      <EditInvoiceFormatDialogScreen
        data-testid="edit-invoice-format-dialog"
        onSubmit={onSubmit}
        onClose={onClose}
        isOpen
        defaultValues={{
          prefix: invoicePreferences?.prefix,
          invoiceNumber: Number(invoicePreferences?.invoiceNumber),
        }}
        isLoading={isFetching}
        isSaving={isMutating}
        ref={ref}
        {...props}
      />
    );
  }
);
