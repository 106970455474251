import { Navigate } from 'react-router-dom';

import { useIsSubscriptionsEnabled, useRedirects, useWizardParams } from '../../../hooks';
import { SubscriptionWizardScreen } from './screens/SubscriptionWizard.screen';

export const SubscriptionWizardActivity = () => {
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const { goToReturnUrlOrDashboard } = useRedirects();

  const { returnUrl, planId } = useWizardParams();

  const handleOnClose = () => {
    goToReturnUrlOrDashboard();
  };

  if (!isSubscriptionsEnabled || !planId) {
    return <Navigate to="/error" />;
  }

  return <SubscriptionWizardScreen returnUrl={returnUrl} planId={planId} onClose={handleOnClose} />;
};
