import {
  PatchVendorsVendorIdRequest,
  PostVendorsLinkToDirectoryRequest,
  PostVendorsManaged,
  PostVendorsRequest,
  Vendor as BaseVendor,
  VendorAllOfContact,
  VendorWarning,
} from '@melio/platform-api-axios-client';

import { DeliveryMethod } from './delivery-method';

export type Vendor = Omit<BaseVendor, 'deliveryMethods' | 'contact'> & {
  deliveryMethods: DeliveryMethod[];
  // XXX type mismatch - email is being evaluated as `any`
  contact: Omit<VendorAllOfContact, 'email'> & { email: string };
  warnings?: VendorWarning[];
};

export type CreateVendorParams = PostVendorsRequest;

export enum CreateVendorAnalyticsMetadataFlow {
  Vendor = 'vendor',
  Bill = 'bill',
  Payment = 'payment',
}

export type UpdateVendorParams = PatchVendorsVendorIdRequest;
export type LinkVendorToDirectoryParams = PostVendorsLinkToDirectoryRequest;

export type CreateVendorManaged = PostVendorsManaged;

export type { VendorsApiGetVendorsRequest } from '@melio/platform-api-axios-client';

export type CreateVendorErrorCode =
  | 'payee_address_invalid_1207'
  | 'invalid_zip_code_1208'
  | 'city_name_invalid_1212'
  | 'state_code_invalid_1213'
  | 'unit_number_missing_1221'
  | 'house_or_street_number_missing_1222'
  | 'directional_missing_1224'
  | 'suffix_missing_1226'
  | 'zipcode_address_mismatch_1229'
  | 'invalid_payee_status_1230'
  | 'invalid_vru_name_code_1231'
  | 'payee_inactive_1214'
  | 'active_payee_exists_1215'
  | 'merchant_number_invalid_1216'
  | 'account_scheming_failed_1218'
  | 'invalid_validation_error_payee_account_number'
  | 'invalid_validation_error_payee_name'
  | 'invalid_validation_error_zipcode_for_merchant_uri'
  | 'invalid_validation_error_zip_code'
  | 'invalid_validation_error_city_name'
  | 'invalid_validation_error_state_code'
  | 'invalid_validation_error_address1'
  | 'zip_code_address_mismatch'
  | 'subscriber_invalid_status'
  | 'active_payee_already_exists'
  | 'invalid_payee_status';

export type EditVendorErrorCode =
  | 'payee_not_found'
  | 'invalid'
  | 'require_payee_name'
  | 'invalid_payee'
  | 'invalid_zip_code_1208'
  | 'invalid_payee_account_number_1205'
  | 'active_payee_exists_1215'
  | 'non_modifiable_fields_1253'
  | 'payee_inactive_1214'
  | 'modification_not_allowed'
  | 'cannot_modify_personal_payee'
  | 'cannot_modify_payee'
  | 'subscriber_invalid_status'
  | 'zipcode_address_mismatch_1229'
  | 'only_us_address_allow'
  | 'invalid_state_code';
