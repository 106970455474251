import { Container, Group, Skeleton, Text } from '@melio/penny';
import { AccountAddress } from '@melio/platform-api-axios-client';
import { useMelioIntl } from '@melio/platform-i18n';

import { SubscriptionPreview } from '../../../../api';

type SubscriptionDetailedTotalProps = {
  isLoading: boolean;
  legalAddress: AccountAddress | null;
  preview?: SubscriptionPreview;
};

export const SubscriptionDetailedSubtotal = ({ preview, legalAddress, isLoading }: SubscriptionDetailedTotalProps) => {
  const { formatMessage, formatCurrency } = useMelioIntl();

  if (isLoading || !preview) {
    return (
      <Container>
        <Skeleton height="12px" />
      </Container>
    );
  }

  return (
    <Container data-testid="subscription-checkout-charged-immediately-details">
      <Group variant="vertical" spacing="xs">
        <Group justifyContent="space-between" alignItems="flex-start">
          <Text textStyle="body3" color="global.neutral.800">
            {formatMessage('activities.subscription.checkout.summary.subTotal')}
          </Text>
          <Text textStyle="body3" data-testid="subscription-checkout-subtotal">
            {formatCurrency(preview.amountWithoutTax)}
          </Text>
        </Group>

        <Group justifyContent="space-between" alignItems="flex-start">
          <Text textStyle="body3" color="global.neutral.800">
            {formatMessage('activities.subscription.checkout.summary.taxes')}
          </Text>
          {legalAddress ? (
            <Text textStyle="body3" data-testid="subscription-checkout-taxes">
              {formatCurrency(preview.taxAmount)}
            </Text>
          ) : (
            <Text textStyle="body3" color="global.neutral.800" data-testid="subscription-checkout-no-taxes">
              {formatMessage('activities.subscription.checkout.summary.noTax')}
            </Text>
          )}
        </Group>
      </Group>
    </Container>
  );
};
