import { apiClient, Invitation } from '@melio/javascript-sdk';
// eslint-disable-next-line no-restricted-imports
import { useIsMutating } from 'react-query';

import { useNonEntityMutation } from '../../core';
import { createQueryKey } from '../../core/util';
import { InvitationModel } from './types';

type ResendModel = Pick<InvitationModel, 'queryKey'> & {
  isResending: boolean;
  resend: (id: string) => Promise<Invitation>;
};

export const useResendInvitation = (): ResendModel => {
  const client = apiClient.invitations();

  const queryKey = createQueryKey({
    queryKey: 'invitation',
    role: 'collection',
    scope: 'default',
  });

  const { mutateAsync } = useNonEntityMutation<Invitation>(
    ({ id }) => (id ? client.resend(id) : Promise.reject(new Error('id must be sent'))),
    { queryKey },
    { invalidateQuery: true }
  );

  const isResending = useIsMutating(queryKey) > 0;

  return {
    queryKey,
    resend: (id: string) => mutateAsync({ id }),
    isResending,
  };
};
