import {
  calculateRecurringEndDateByNumOfOccurrences,
  getNumberOfOccurrences,
  useDeliveryMethodsSelectOptions,
} from '@melio/ap-domain';
import { useFundingSourcesSelectOptions } from '@melio/ap-widgets';
import {
  BillSubscriptionEndPolicyEnum,
  BillSubscriptionIntervalTypeEnum,
  BillSubscriptionManagedBy,
  DeliveryMethod,
  FundingSource,
  InvoiceDataSourceEntityTypeEnum,
  useDeliveryMethods,
  useFundingSources,
  usePaymentSettings,
  Vendor,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { converDateToStringRepresentation } from '@melio/platform-utils';
import { isBefore } from 'date-fns';
import { isNil } from 'lodash';

import { ExpandedBillSubscription } from '../../../types';

// TODO: move it to a util file that can be used for create bill too
export const useRecurringFrequencies = () => {
  const { formatMessage } = useMelioIntl();
  const {
    settings: {
      newBillExperience: { isRecurringPaymentImprovementsEnabled },
    },
  } = useConfig();
  const recurringFrequenciesAvailableValues = isRecurringPaymentImprovementsEnabled
    ? [
        BillSubscriptionIntervalTypeEnum.Weekly,
        BillSubscriptionIntervalTypeEnum.Every2Weeks,
        BillSubscriptionIntervalTypeEnum.Every4Weeks,
        BillSubscriptionIntervalTypeEnum.TwiceAMonth,
        BillSubscriptionIntervalTypeEnum.Monthly,
        BillSubscriptionIntervalTypeEnum.Every2Months,
        BillSubscriptionIntervalTypeEnum.Every3Months,
        BillSubscriptionIntervalTypeEnum.Every4Months,
        BillSubscriptionIntervalTypeEnum.Every6Months,
        BillSubscriptionIntervalTypeEnum.Yearly,
      ]
    : [BillSubscriptionIntervalTypeEnum.Weekly, BillSubscriptionIntervalTypeEnum.Monthly];

  const recurringFrequenciesOptionsProps = recurringFrequenciesAvailableValues.map((frequency) => ({
    label: formatMessage(`activities.addBillV2.billForm.frequencyPicker.${frequency}`), // TODO: move the text to a more generic key so it can be used in all places
    value: frequency,
    testId: frequency,
  }));

  return recurringFrequenciesOptionsProps;
};

// TODO: move it to a util file that can be used for create bill too
export const useEndPolicy = () => {
  const { formatMessage } = useMelioIntl();
  const {
    settings: {
      newBillExperience: { isRecurringPaymentImprovementsEnabled },
    },
  } = useConfig();
  const endByAvailableValues = isRecurringPaymentImprovementsEnabled
    ? [
        BillSubscriptionEndPolicyEnum.NoEndDate,
        BillSubscriptionEndPolicyEnum.EndDate,
        BillSubscriptionEndPolicyEnum.NumOfOccurrences,
      ]
    : [BillSubscriptionEndPolicyEnum.EndDate, BillSubscriptionEndPolicyEnum.NumOfOccurrences];

  const endPolicyOptionsProps = endByAvailableValues.map((endBy) => ({
    label: formatMessage(`activities.addBillV2.billForm.recurringEndBy.values.${endBy}`), // TODO: move the text to a more generic key so it can be used in all places
    value: endBy,
    testId: endBy,
  }));

  return endPolicyOptionsProps;
};

type UseEndDateProps = {
  endDate?: Date;
  startDate?: Date;
  managedBy?: BillSubscriptionManagedBy | null;
  paymentFrequency?: BillSubscriptionIntervalTypeEnum;
  uneditablePaymentsNumber?: number;
};
export const useEndDate = ({
  endDate,
  startDate,
  paymentFrequency,
  managedBy,
  uneditablePaymentsNumber,
}: UseEndDateProps) => {
  const { formatMessage } = useMelioIntl();

  const canCalculateNumOfOccurrences = paymentFrequency && startDate && endDate && !isBefore(endDate, startDate);

  const calculatedNumOfOccurrences = canCalculateNumOfOccurrences
    ? getNumberOfOccurrences({
        paymentFrequency,
        numOfOccurrences: undefined,
        startDate,
        endDate,
      })
    : undefined;

  const helperText =
    calculatedNumOfOccurrences && !isNil(uneditablePaymentsNumber)
      ? formatMessage('activities.editBillSubscription.screens.editBillSubscription.form.endDate.helperText', {
          numOfOccurrences: calculatedNumOfOccurrences,
          uneditablePaymentsNumber,
          managedBy,
        })
      : '';

  return { helperText };
};

type UseNumOfOccurrencesProps = {
  uneditablePaymentsNumber?: number;
  paymentFrequency?: BillSubscriptionIntervalTypeEnum;
  startDate?: Date;
  managedBy?: BillSubscriptionManagedBy | null;
  numOfOccurrences?: number;
};

export const useNumOfOccurrences = ({
  uneditablePaymentsNumber,
  paymentFrequency,
  startDate,
  managedBy,
  numOfOccurrences,
}: UseNumOfOccurrencesProps) => {
  const { formatMessage, formatDate } = useMelioIntl();

  const canCalculateEndDate = paymentFrequency && numOfOccurrences && startDate;

  const lastPaymentDate = canCalculateEndDate
    ? calculateRecurringEndDateByNumOfOccurrences({
        paymentFrequency,
        startDate,
        numOfOccurrences,
      })
    : undefined;

  const helperText =
    !isNil(uneditablePaymentsNumber) && lastPaymentDate
      ? formatMessage('activities.editBillSubscription.screens.editBillSubscription.form.numOfOccurrences.helperText', {
          uneditablePaymentsNumber,
          managedBy,
          endDate: formatDate(lastPaymentDate, {
            dateStyle: 'medium',
          }),
        })
      : '';

  return { helperText };
};

// TODO: move it to a common place so it can be used also for create bill and add tests for each time
export const MAX_ALLOWED_OCCURRENCES: { [key in BillSubscriptionIntervalTypeEnum]: number } = {
  [BillSubscriptionIntervalTypeEnum.Weekly]: 260,
  [BillSubscriptionIntervalTypeEnum.Monthly]: 60,
  [BillSubscriptionIntervalTypeEnum.Yearly]: 5,
  [BillSubscriptionIntervalTypeEnum.Every2Weeks]: 130,
  [BillSubscriptionIntervalTypeEnum.Every4Weeks]: 65,
  [BillSubscriptionIntervalTypeEnum.TwiceAMonth]: 120,
  [BillSubscriptionIntervalTypeEnum.Every2Months]: 30,
  [BillSubscriptionIntervalTypeEnum.Every3Months]: 20,
  [BillSubscriptionIntervalTypeEnum.Every4Months]: 15,
  [BillSubscriptionIntervalTypeEnum.Every6Months]: 10,
};

// TODO: move it to a common place so it can be used also for create bill and add tests for each time
const baseFields = [
  'amount',
  'memoToVendor',
  'intervalType',
  'endPolicy',
  'startDate',
  'fundingSourceId',
  'deliveryMethodId',
];
const END_POLICY_TO_RELEVANT_FIELDS = {
  [BillSubscriptionEndPolicyEnum.EndDate]: [...baseFields, 'endDate', 'lastAmount'],
  [BillSubscriptionEndPolicyEnum.NumOfOccurrences]: [...baseFields, 'numOfOccurrences', 'lastAmount'],
  [BillSubscriptionEndPolicyEnum.NoEndDate]: baseFields,
};
export const getReleventFields = (currentEndPolicy?: BillSubscriptionEndPolicyEnum) => {
  const defaultPolicy = BillSubscriptionEndPolicyEnum.NoEndDate;
  return END_POLICY_TO_RELEVANT_FIELDS[currentEndPolicy || defaultPolicy];
};

type UseFundingSourcesOptionsProps = {
  vendorId: Vendor['id'];
  startDate?: Date;
  amount?: number;
  billSubscriptionInvoiceNumber: ExpandedBillSubscription['invoiceNumber'];
  billSubscriptionId: ExpandedBillSubscription['id'];
  deliveryMethodId?: DeliveryMethod['id'];
  fundingSourceId?: FundingSource['id'];
};

export const useFundingSourcesOptions = ({
  startDate,
  amount,
  vendorId,
  fundingSourceId,
  deliveryMethodId,
  billSubscriptionInvoiceNumber = null,
  billSubscriptionId,
}: UseFundingSourcesOptionsProps) => {
  const { data: fundingSources, isLoading: isFundingSourcesLoading } = useFundingSources();

  const isPaymentSettingsEnabled = !!(amount && deliveryMethodId && startDate);
  const { data: [paymentSettings] = [], isLoading: isPaymentSettingsLoading } = usePaymentSettings({
    payload: isPaymentSettingsEnabled
      ? [
          {
            dueDate: converDateToStringRepresentation(startDate), // TODO: this conversion should be on the API level
            amountToPay: amount,
            vendorId,
            fundingSourceId,
            deliveryMethodId,
            invoicesData: [
              {
                amount,
                invoiceNumber: billSubscriptionInvoiceNumber,
                sourceEntity: {
                  id: billSubscriptionId,
                  type: InvoiceDataSourceEntityTypeEnum.BillSubscription,
                },
              },
            ],
          },
        ]
      : [],
    params: {
      fillWithDefaults: true,
    },
    enabled: isPaymentSettingsEnabled,
  });
  const fundingSourcesOptions = useFundingSourcesSelectOptions(
    fundingSources || [],
    paymentSettings?.fundingSourceTypesOptions
  );

  return {
    isLoading: isFundingSourcesLoading || isPaymentSettingsLoading,
    options: fundingSourcesOptions.filter((option) => !option.disabled.isDisabled),
  };
};

type UseDeliveryMethodsOptionsProps = {
  vendor: Vendor;
};
export const useDeliveryMethodsOptions = ({ vendor }: UseDeliveryMethodsOptionsProps) => {
  const { data: deliveryMethods, isLoading: isDeliveryMethodsLoading } = useDeliveryMethods({
    vendorId: vendor.id,
  });

  const deliveryMethodsOptions = useDeliveryMethodsSelectOptions(deliveryMethods || [], vendor.name);

  return { isLoading: isDeliveryMethodsLoading, options: deliveryMethodsOptions };
};
