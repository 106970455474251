import { SearchBarProps } from '@melio/penny';
import { FundingSource } from '@melio/platform-api';
import { useCallback, useMemo, useState } from 'react';

const MIN_FUNDING_SOURCES_TO_SHOW_SEARCH_BAR = 11;

export function useFundingSourcesSearchBar(fundingSources: FundingSource[]): {
  props: Pick<SearchBarProps, 'value' | 'onChange'>;
  resetQuery: () => void;
  show: boolean;
  filtered: FundingSource[];
} {
  const [searchQuery, setSearchQuery] = useState('');
  const resetQuery = useCallback(() => setSearchQuery(''), [setSearchQuery]);
  const show = fundingSources.length >= MIN_FUNDING_SOURCES_TO_SHOW_SEARCH_BAR;

  const lowerCasedSearchQuery = searchQuery.toLowerCase();

  const filtered = useMemo(
    () =>
      fundingSources.filter(
        (fundingSource) =>
          fundingSource.displayName.toLowerCase().includes(lowerCasedSearchQuery) ||
          fundingSource.nickname?.toLowerCase().includes(lowerCasedSearchQuery)
      ),
    [fundingSources, lowerCasedSearchQuery]
  );

  return {
    props: {
      value: searchQuery,
      onChange: (e) => setSearchQuery(e.target.value),
    },
    resetQuery,
    show,
    filtered,
  };
}
