import { useDisclosure } from '@chakra-ui/react';
import { Group } from '@melio/penny';
import { useAnalytics, useAnalyticsContext, useAnalyticsView } from '@melio/platform-analytics';
import {
  DeliveryPreference,
  FinancingEligibilityStatus,
  FundingSource,
  PaymentIntent,
  RepaymentTerms,
  SelectedRepaymentOption,
} from '@melio/platform-api';
import { useMonitoring } from '@melio/platform-monitoring';
import { useMelioIntl } from '@melio/platform-provider';
import { useCallback, useState } from 'react';

import { MonitoredAction } from '../../../../monitoring';
import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';
import { AgreementModal } from '../components/AgreementModal';
import { InstallmentOptionsList } from '../components/RepaymentOptionsList';
import { TermsAndConditions } from '../components/TermsAndConditions';
import { SelectedRepaymentOptionFormSchema } from '../types';

export type RepaymentTermsScreenProps = {
  scheduledDate?: Date | null;
  isLoading?: boolean;
  step: number;
  totalSteps: number;
  availableCredits?: number;
  onBack: VoidFunction;
  onClose: VoidFunction;
  onPressContinue: () => Promise<void>;
  onNext: (data: SelectedRepaymentOptionFormSchema) => void;
  onSelect: (numOfInstallments: number) => void;
  selectedNumOfInstallments?: number;
  repaymentTerms?: RepaymentTerms;
  repaymentFundingSources?: FundingSource[];
  paymentIntent?: PaymentIntent;
  selectedRepaymentTerm?: SelectedRepaymentOption;
  selectedDate?: Date;
  deliveryPreferenceOption?: DeliveryPreference;
  setSelectedDate: (date?: Date) => void;
  onAddBankAccount: VoidFunction;
  isLoadingSelectRepaymentTerms?: boolean;
};

export const RepaymentTermsScreen = ({
  step,
  totalSteps,
  scheduledDate,
  onBack,
  onClose,
  onNext,
  onPressContinue,
  onSelect,
  selectedNumOfInstallments,
  isLoading,
  availableCredits,
  repaymentTerms,
  repaymentFundingSources,
  paymentIntent,
  selectedRepaymentTerm,
  selectedDate,
  setSelectedDate,
  deliveryPreferenceOption,
  onAddBankAccount,
  isLoadingSelectRepaymentTerms,
  ...props
}: RepaymentTermsScreenProps) => {
  const { formatMessage, formatCurrency } = useMelioIntl();
  const [selectedRepaymentOptionFormData, setSelectedRepaymentOptionFormData] =
    useState<SelectedRepaymentOptionFormSchema>();
  const {
    isOpen: isAgreementModalOpen,
    onOpen: onAgreementModalOpen,
    onClose: onAgreementModalClose,
  } = useDisclosure();

  const { startAction, endAction } = useMonitoring<MonitoredAction>();
  const endActionMonitoring = useCallback(() => {
    endAction('funding_source_selection');
    endAction('goods_received_flow');
    endAction('vendor_missing_details_flow');
    endAction('mcc_flow');
    endAction('reconciliation_flow');
    endAction('delivery_method_selection');
  }, [endAction]);

  const { createTrackHandler } = useAnalytics();

  const trackAndHandleClick = createTrackHandler<{
    Cta: 'continue' | 'exit' | 'back';
  }>('Payment', 'Click');

  const onClickCardContinue = async (data: SelectedRepaymentOptionFormSchema) => {
    await onPressContinue();
    setSelectedRepaymentOptionFormData(data);
    onAgreementModalOpen();
  };

  useAnalyticsContext({
    globalProperties: {
      PageName: 'choose-repayment-terms',
      Flow: 'financing',
      CountInTab: repaymentTerms?.options?.length,
      FinancingExperience: repaymentTerms?.appliedToProvider ? 'stx' : 'ftx',
      IsEligibleToFinancing: repaymentTerms?.status === FinancingEligibilityStatus.Eligible,
    },
  });

  useAnalyticsView('Payment', !isLoading, true);

  return (
    <NewSinglePaymentStepLayout
      data-component="RepaymentTermsActivity.RepaymentTermsScreen"
      data-testid="repayment-terms-activity-repayment-terms-screen"
      ref={endActionMonitoring}
      isLoading={isLoading}
      {...props}
      headerContent={
        <NewSinglePaymentStepLayout.Header>
          <NewSinglePaymentStepLayout.CloseButton onClick={() => trackAndHandleClick({ Cta: 'exit' }, onClose)} />
          <NewSinglePaymentStepLayout.ProgressBar currentStep={step} totalSteps={totalSteps} />
          <NewSinglePaymentStepLayout.BackButton onClick={() => trackAndHandleClick({ Cta: 'back' }, onBack)} />
        </NewSinglePaymentStepLayout.Header>
      }
    >
      <NewSinglePaymentStepLayout.Title>
        {formatMessage('activities.repaymentTerms.screens.repaymentTerms.title')}
      </NewSinglePaymentStepLayout.Title>
      <NewSinglePaymentStepLayout.Description>
        {formatMessage('activities.repaymentTerms.screens.repaymentTerms.description', {
          availableCredits: formatCurrency(repaymentTerms?.balance || 0),
        })}
      </NewSinglePaymentStepLayout.Description>
      <NewSinglePaymentStepLayout.Content>
        <Group variant="vertical" width="full" spacing="l">
          <InstallmentOptionsList
            deliveryPreferenceOption={deliveryPreferenceOption}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            onPressContinue={(data) => trackAndHandleClick({ Cta: 'continue' }, onClickCardContinue(data))}
            paymentIntent={paymentIntent}
            repaymentFundingSources={repaymentFundingSources}
            repaymentOptions={repaymentTerms?.options ?? []}
            onSelect={onSelect}
            selectedNumOfInstallments={selectedNumOfInstallments}
            selectedRepaymentTerm={selectedRepaymentTerm}
            onAddBankAccount={onAddBankAccount}
            isLoadingSelectRepaymentTerms={isLoadingSelectRepaymentTerms}
          />
          <AgreementModal
            isOpen={isAgreementModalOpen}
            onClose={onAgreementModalClose}
            onSubmit={() => {
              if (selectedRepaymentOptionFormData) {
                startAction('repayment_terms');
                onNext(selectedRepaymentOptionFormData);
              }
            }}
            agreementContent={selectedRepaymentTerm?.loanAgreement}
          />
          <TermsAndConditions />
        </Group>
      </NewSinglePaymentStepLayout.Content>
    </NewSinglePaymentStepLayout>
  );
};
