import { Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { getFinancedPaymentFailureOrCancelDate } from '../../../../utils/pay-flow/Financing.utils';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const getFailureDescriptionKey = (payment: Payment) => (payment.loan!.status === 'failed' ? 'failed' : 'canceled');

export const useFailureDescriptionProps = ({ payment }: { payment: Payment }) => {
  const { formatMessage, formatDate } = useMelioIntl();

  if (!payment.loan) {
    return { isFailure: false };
  }

  const isFailure = payment.loan.status === 'failed' || payment.loan.status === 'canceled';

  return {
    isFailure,
    descriptionProps: isFailure
      ? {
          label: formatMessage(
            `activities.payDashboard.drawer.body.loan.${getFailureDescriptionKey(payment)}.payBy.lineItem.description`,
            {
              date: formatDate(getFinancedPaymentFailureOrCancelDate(payment), { dateStyle: 'medium' }),
            }
          ),
        }
      : undefined,
  };
};
