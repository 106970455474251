import React from 'react';
import { Box } from '@chakra-ui/react';

import { useAppTheme } from '@/cl/theme/theme.hooks';
import { SettingsSectionContainerTheme } from './SettingsSectionContainer.component.theme';

export const SettingsSectionContainer: React.FC<{}> = ({ children, ...rest }) => {
  const styles = useAppTheme(SettingsSectionContainerTheme, {});
  return (
    <Box sx={styles['container']} data-component="SettingsSectionContainer" {...rest}>
      {children}
    </Box>
  );
};
