import { addWildcardToRoutes, useNavigate } from '@melio/ar-domain';

enum Paths {
  Custom = 'custom',
  Bank = 'bank',
  PayByBank = 'bank/:fundingSourceId',
  Card = 'card',
  Error = 'error',
  PaymentConfirmation = 'payment-confirmation/:paymentId',
  InvoicePaid = 'invoice-paid',
}

export const useGuestPaymentRouterFlow = () => {
  const navigate = useNavigate<Paths>({ withSearchparams: true });
  const navigateAndReplace = (path: Paths, options: object = {}) => navigate(path, { replace: true, ...options });
  const goToBank = () => navigateAndReplace(Paths.Bank);
  const goToPayByBank = (fundingSourceId: string) =>
    navigateAndReplace(Paths.PayByBank, { pathParams: { fundingSourceId } });
  const goToCard = () => navigateAndReplace(Paths.Card);
  const goToCustom = () => navigateAndReplace(Paths.Custom);
  const goToError = () => navigateAndReplace(Paths.Error);
  const goToPaymentConfirmation = (paymentId: string) =>
    navigateAndReplace(Paths.PaymentConfirmation, { pathParams: { paymentId } });
  const goToInvoicePaid = () => navigateAndReplace(Paths.InvoicePaid);

  return {
    RedirectPaths: Paths,
    Paths: addWildcardToRoutes(Paths),
    goToBank,
    goToCard,
    goToCustom,
    goToPayByBank,
    goToError,
    goToPaymentConfirmation,
    goToInvoicePaid,
  };
};
