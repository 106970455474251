import { useRoleText } from '@melio/ap-domain';
import { useCollaborators, WorkflowPaymentCreatedByCondition } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { ConditionFormatter } from '../types';
import { formatList } from './formatters.utils';

export function useWorkflowCollaboratorsConditionFormatter() {
  const { formatMessage, locale } = useMelioIntl();
  const { data: collaborators } = useCollaborators({
    expand: ['user'],
    suspense: true,
    useErrorBoundary: true,
  });
  const { getRoleText } = useRoleText();

  const format: ConditionFormatter<WorkflowPaymentCreatedByCondition> = ({ condition, templateKey }) => {
    const conditionConfig = condition.condition;
    if (conditionConfig.configuration.filterBy === 'role') {
      const formattedRolesList = formatList(
        conditionConfig.configuration.roles.map(getRoleText),
        locale,
        'disjunction'
      ).toLowerCase();

      const formattedValue = formatMessage(
        'activities.settings.approval-workflow-settings.conditions.collaborator.value.role',
        { roles: formattedRolesList }
      );
      return formatMessage(templateKey, {
        attribute: formatMessage('activities.settings.approval-workflow-settings.entities.payment'),
        operator: formatMessage(
          `activities.settings.approval-workflow-settings.conditions.collaborator.operators.scheduled-by`
        ),
        value: formattedValue,
      });
    } else if (conditionConfig.configuration.filterBy === 'user') {
      const collaboratorNames: string[] = conditionConfig.configuration.ids.map((id) => {
        const user = collaborators?.find((c) => c.userId === id)?.user;
        return formatMessage('activities.settings.approval-workflow-settings.formatter.collaborator.value.user', {
          firstName: user?.firstName,
          lastName: user?.lastName,
        });
      });
      const formattedUsersList = formatList(collaboratorNames, locale, 'disjunction');

      return formatMessage(templateKey, {
        attribute: formatMessage('activities.settings.approval-workflow-settings.entities.payment'),
        operator: formatMessage(
          `activities.settings.approval-workflow-settings.conditions.collaborator.operators.scheduled-by`
        ),
        value: formattedUsersList,
      });
    } else {
      return '';
    }
  };

  return { format };
}
