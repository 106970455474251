import { useMelioIntl } from '@melio/platform-i18n';
import { object, string } from 'yup';

export const useSchema = (activeAccountingPlatformName: string, disabledNewOption?: boolean) => {
  const { formatMessage } = useMelioIntl();
  if (disabledNewOption) {
    return object().shape({
      accountingPlatformBankAccountId: string().required(
        formatMessage(
          'widgets.fundingSources.reconciliationForm.fields.accountingPlatformPaymentAccountId.validation.requiredWithoutNewAccount',
          {
            accountingPlatform: activeAccountingPlatformName,
          }
        )
      ),
    });
  }

  return object().shape({
    accountingPlatformBankAccountId: string().required(
      formatMessage(
        'widgets.fundingSources.reconciliationForm.fields.accountingPlatformPaymentAccountId.validation.required',
        {
          accountingPlatform: activeAccountingPlatformName,
        }
      )
    ),
  });
};
