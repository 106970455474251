import { useOneTimeEffect } from '@melio/platform-utils';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { AccountingPlatformSyncConnectionErrorEnum, SyncAppStateType, SyncFlowEnum } from '../types';

type ConnectAppStateType = SyncAppStateType & {
  authError?: AccountingPlatformSyncConnectionErrorEnum;
  authParams: Record<string, unknown>;
};

export const useParseAuthSearchParams = () => {
  const [searchParams] = useSearchParams();
  const [connectAppState, setConnectAppState] = React.useState<ConnectAppStateType>();

  useOneTimeEffect(
    function parseAuthSearchParams() {
      const { error, state = '', ...rest } = Object.fromEntries(searchParams);
      let authError;
      let flow = SyncFlowEnum.Setting;
      let accountingPlatformId;
      let isQuickBooksDesktop = false;

      if (error) {
        authError =
          error === 'access_denied'
            ? AccountingPlatformSyncConnectionErrorEnum.AccessDenied
            : AccountingPlatformSyncConnectionErrorEnum.GeneralError;
      }

      try {
        const parsedState = JSON.parse(window.atob(state)) as SyncAppStateType;
        flow = parsedState?.flow || SyncFlowEnum.Setting;
        isQuickBooksDesktop = parsedState?.isQuickBooksDesktop || false;
        accountingPlatformId = parsedState?.accountingPlatformId;
        if (!accountingPlatformId) {
          authError = AccountingPlatformSyncConnectionErrorEnum.InvalidState;
        }
      } catch (error) {
        authError = AccountingPlatformSyncConnectionErrorEnum.InvalidState;
      }

      setConnectAppState({
        flow,
        isQuickBooksDesktop,
        accountingPlatformId,
        authError,
        authParams: { ...rest },
      });
    },
    () => true,
    [searchParams]
  );

  return connectAppState;
};
