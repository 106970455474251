import {
  useCatalogItems,
  useCustomers,
  useInvoiceDiscounts,
  usePreloadApiHooks,
  useServiceChargeCatalogs,
  useTaxRates,
} from '@melio/ar-domain';

type UsePrefetchProps = {
  onError?: ARErrorFunction;
};

export const usePrefetch = (props?: UsePrefetchProps) => {
  usePreloadApiHooks([useInvoiceDiscounts, useServiceChargeCatalogs, useTaxRates], props);
  return usePreloadApiHooks([useCustomers, useCatalogItems], { delay: 0, ...props });
};
