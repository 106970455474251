import { useVendors, Vendor, WorkflowCriteria, WorkflowPaymentVendorCondition } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { ConditionFormatter } from '../types';
import { formatList } from './formatters.utils';

export const useWorkflowVendorsConditionFormatter = ({ criteria }: { criteria: WorkflowCriteria }) => {
  const vendorIds = extractVendorIdsFromCriteria(criteria);

  const { formatMessage, locale } = useMelioIntl();
  const { data: vendors } = useVendors({
    params: { search: { 'vendor.id': vendorIds } },
    useErrorBoundary: true,
    suspense: true,
    enabled: vendorIds.length > 0,
  });

  const format: ConditionFormatter<WorkflowPaymentVendorCondition> = ({ condition, templateKey }) => {
    const conditionConfig = condition.condition;
    if (!vendors) {
      return '';
    }

    const vendorNames = formatList(
      conditionConfig.configuration.ids.map((id) => vendors.find((v) => v.id === id)?.name ?? id),
      locale,
      'disjunction'
    );

    return formatMessage(templateKey, {
      attribute: formatMessage('activities.settings.approval-workflow-settings.entities.payment'),
      operator: formatMessage(
        `activities.settings.approval-workflow-settings.conditions.collaborator.operators.vendor`
      ),
      value: vendorNames,
    });
  };

  return { format };
};

const extractVendorIdsFromCriteria = (criteria: WorkflowCriteria): Vendor['id'][] => {
  const vendorConditions = criteria.conditions.filter(
    (cond): cond is WorkflowPaymentVendorCondition => cond.condition.type === 'workflowVendorsCondition'
  );
  return vendorConditions.flatMap((cond) => cond.condition.configuration.ids);
};
