import { useMelioIntl } from '@melio/ar-domain';
import { Button, Group } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';

import { CustomPaymentInstructions } from '../components';

export type CustomPaymentScreenProps = {
  onViewInvoice: VoidFunction;
  customPayInstructions?: string;
};

export const CustomPaymentScreen = forwardRef<CustomPaymentScreenProps>(
  ({ onViewInvoice, customPayInstructions }, ref) => {
    const { formatMessage } = useMelioIntl();

    return (
      <Group variant="vertical" spacing="l" ref={ref}>
        {customPayInstructions ? <CustomPaymentInstructions paymentInstructions={customPayInstructions} /> : null}
        <Button
          data-testid="view-invoice-button"
          variant="tertiary"
          onClick={onViewInvoice}
          label={formatMessage('ar.guestPayment.previewInvoice.button.label')}
        />
      </Group>
    );
  }
);
CustomPaymentScreen.displayName = 'CustomPaymentScreen';
