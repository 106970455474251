import { DeliveryMethod, DeliveryMethodType, useDeliveryMethods } from '@melio/platform-api';
import { useEffect } from 'react';

const hasSuvcOverStpDeliveryMethod = (deliveryMethods?: DeliveryMethod[]) =>
  Boolean(
    deliveryMethods
      ?.filter(({ type }) => type === DeliveryMethodType.VirtualCard)
      .some(({ details }) => 'isStp' in details && details.isStp)
  );

export const useVendorSelectSideEffects = (vendorId?: string) => {
  const { data, refetch, isFetching } = useDeliveryMethods({ vendorId, enabled: false });

  useEffect(() => {
    if (vendorId) {
      refetch({
        cancelRefetch: true,
      });
    }
  }, [refetch, vendorId]);

  return {
    isSuvcOverStpVendor: isFetching ? false : hasSuvcOverStpDeliveryMethod(data),
    isFetching,
  };
};
