import { DeliveryMethod, DeliveryMethodType, Vendor } from '@melio/platform-api';
import React from 'react';

import { PaymentFlowOnChangeHandlers } from '../../../../types';
import { SelectedDeliveryMethodCard } from './components/DeliveryMethodCard/SelectedDeliveryMethodCard';
import { NotSelected } from './components/NotSelected';

type DeliveryMethodSectionProps = {
  vendorId: Vendor['id'];
  deliveryMethodId: DeliveryMethod['id'] | undefined;
  isRecurring: boolean;
  isDisabled: boolean;
  isLoading: boolean;
  onDeliveryMethodChange: PaymentFlowOnChangeHandlers['onDeliveryMethodChange'];
  onEditDeliveryMethod: (type: DeliveryMethodType) => void;
};
export const DeliveryMethodSection = ({
  vendorId,
  deliveryMethodId,
  isRecurring,
  isDisabled,
  isLoading,
  onDeliveryMethodChange,
  onEditDeliveryMethod,
}: DeliveryMethodSectionProps) => {
  if (!deliveryMethodId && !isLoading) {
    return (
      <NotSelected
        onBankClick={() => onEditDeliveryMethod('bank-account')}
        onPaperCheckClick={() => onEditDeliveryMethod('paper-check')}
      />
    );
  }

  return (
    <SelectedDeliveryMethodCard
      vendorId={vendorId}
      deliveryMethodId={deliveryMethodId}
      isRecurring={isRecurring}
      isLoading={isLoading}
      isDisabled={isDisabled}
      onDeliveryMethodChange={onDeliveryMethodChange}
      onEditDeliveryMethod={onEditDeliveryMethod}
    />
  );
};
