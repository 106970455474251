/* eslint-disable react-hooks/exhaustive-deps */
import { StatusModal } from '@melio/penny';
import { useAnalytics, useAnalyticsContext, withAnalyticsContext } from '@melio/platform-analytics';
import { Payment, useBill, useBillSubscription, usePayment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useSystemMessage } from '@melio/platform-utils';
import { useEffect } from 'react';

import { MonitoredAction } from '../../monitoring';
import { getPaymentAmount } from '../../utils/pay-flow/Payment.utils';

export type SelectedEntity = 'payment' | 'subscription' | null;
export type PaymentType = 'singlePayment' | 'recurringPayment' | 'partialPayment';

export type DeletePaymentModalProps = {
  payment: Payment;
  isOpen: boolean;
  onClose: () => void;
  selectedEntity: SelectedEntity;
  onSuccess?: () => void;
};

const getDialogPrefixKey = (selectedEntity: SelectedEntity, paymentType: PaymentType, isFinanced: boolean) => {
  if (selectedEntity === 'subscription') {
    return 'billSubscription';
  }
  if (paymentType === 'recurringPayment') {
    return 'recurringPayment';
  }
  if (paymentType === 'partialPayment') {
    return 'partialPayment';
  }
  if (isFinanced) {
    return 'financedPayment';
  }
  return 'singlePayment';
};

export const DeletePaymentModal = withAnalyticsContext(
  ({ payment, isOpen, onClose, selectedEntity, onSuccess }: DeletePaymentModalProps) => {
    const { formatMessage } = useMelioIntl();
    const { showMessage } = useSystemMessage();
    const { track } = useAnalytics();
    const { startAction, endAction } = useMonitoring<MonitoredAction>();

    const { isMutating: isUpdatingPayment, delete: deletePayment } = usePayment({ id: payment.id, enabled: false });
    const { delete: deleteBill, isMutating: isUpdatingBill } = useBill({ id: payment.bills?.[0]?.id, enabled: false });
    const { cancel: cancelBillSubscription, isMutating: isUpdatingBillSubscription } = useBillSubscription({
      id: payment.subscriptionOccurrence?.billSubscriptionId,
      enabled: false,
    });

    useAnalyticsContext({
      globalProperties: { PageName: 'delete-payment' },
    });
    useEffect(() => {
      if (isOpen) {
        track('Dashboard', 'View', { Intent: 'delete-payment' });
      }
    }, [isOpen]);

    const paymentAmount = getPaymentAmount(payment.amount, payment.foreignAmount);
    const isPartialPayment =
      payment?.bills?.length === 1 && payment.bills[0] && paymentAmount < payment.bills[0].amount;

    const paymentType = isPartialPayment
      ? 'partialPayment'
      : payment.subscriptionOccurrenceId
      ? 'recurringPayment'
      : 'singlePayment';

    const dialogPrefixKey = getDialogPrefixKey(selectedEntity, paymentType, payment.isFinanced);

    const onConfirm = async () => {
      try {
        await deleteEntity();
        showMessage({
          type: 'informative',
          title: formatMessage(
            `activities.payDashboard.paymentsTab.table.cells.deletePaymentDialog.${dialogPrefixKey}.toast.success`
          ),
        });
        track('Dashboard', 'Status', { Intent: 'payment-delete' });
        track('Dashboard', 'Click', { Intent: 'payment-delete', Cta: 'delete-payment' });
        onSuccess?.();
        onClose();
      } catch (error) {
        showMessage({
          type: 'error',
          title: formatMessage(
            `activities.payDashboard.paymentsTab.table.cells.deletePaymentDialog.${dialogPrefixKey}.toast.error`
          ),
        });
        onClose();
      }
    };

    const handleCloseClick = () => {
      track('Dashboard', 'Click', { Intent: 'payment-delete', Cta: 'exit' });
      onClose();
    };

    const deleteEntity = async () => {
      if (selectedEntity === 'subscription') {
        startAction('recurring-payment-delete');
        await cancelBillSubscription();
        endAction('recurring-payment-delete');
      } else if (paymentType === 'recurringPayment' && payment.bills?.length) {
        await deleteBill();
      } else {
        startAction('payment_delete');
        await deletePayment();
        endAction('payment_delete');
      }
    };

    const isLoading = isUpdatingPayment || isUpdatingBill || isUpdatingBillSubscription;

    return (
      <StatusModal
        isOpen={isOpen}
        variant="cancel"
        header={formatMessage(
          `activities.payDashboard.paymentsTab.table.cells.deletePaymentDialog.${dialogPrefixKey}.title`,
          {
            vendorName: payment.vendor?.name,
          }
        )}
        primaryButton={{
          label: formatMessage(
            `activities.payDashboard.paymentsTab.table.cells.deletePaymentDialog.${dialogPrefixKey}.confirm`
          ),
          onClick: () => onConfirm(),
          variant: 'primary',
          isLoading,
        }}
        onClose={handleCloseClick}
        data-testid="delete-payment-modal"
      >
        {formatMessage(`activities.payDashboard.paymentsTab.table.cells.deletePaymentDialog.${dialogPrefixKey}.body`, {
          vendorName: payment.vendor?.name,
        })}
      </StatusModal>
    );
  }
);
