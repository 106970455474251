import { AccountingPlatform, AccountingPlatformSlug } from '@melio/platform-api';

export const getAccountingPlatformNameForAnalytics = (
  accountingSlug?: AccountingPlatform['accountingSlug']
): string => {
  if (!accountingSlug) {
    return '';
  }

  switch (accountingSlug) {
    case AccountingPlatformSlug.QuickBooksOnline:
      return 'qbo';
    case AccountingPlatformSlug.Xero:
      return 'xero';
    case AccountingPlatformSlug.QuickBooksDesktop:
      return 'qbdt';
    default:
      return '';
  }
};
