import { UploadBillFilesWidgetProps, UploadFilesWidget } from '@melio/ap-widgets';
import { BaseMenu, Button, Menu } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { compact } from 'lodash';
import { useRef, useState } from 'react';

import { AddBillDropdownMenuItem } from './AddBillDropdownMenuItem';

export type AddBillDropdownMenuProps = {
  label: string;
  onGmailConnect: () => void;
  onAddNewBillUpload: UploadBillFilesWidgetProps['onUploadFiles'];
  onAccountingPlatformConnect: () => void;
  shouldShouldGmailOption: boolean;
  shouldShowConnectAccountingSoftwareOption: boolean;
  connectGmailCta: string;
};

export const AddBillDropdownMenu = ({
  label,
  onGmailConnect,
  onAddNewBillUpload,
  onAccountingPlatformConnect,
  shouldShouldGmailOption,
  shouldShowConnectAccountingSoftwareOption,
  connectGmailCta,
}: AddBillDropdownMenuProps) => {
  const { track } = useAnalytics();
  const { formatMessage } = useMelioIntl();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const fileInput = useRef<HTMLInputElement>(null);

  const handleUploadBillButtonClick = () => {
    track('Dashboard', 'Click', { Intent: 'add-bill', Cta: 'upload-bills' });

    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const handleUploadBillOptionClick = () => {
    track('Dashboard', 'Click', { Intent: 'add-bill', Cta: 'import-upload-files' });

    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const actionsItems = compact([
    {
      label: formatMessage('activities.payDashboard.billsTab.addBillDropdownMenu.uploadBills'),
      onClick: handleUploadBillOptionClick,
      icon: 'upload' as const,
      dataTestId: 'add-bill-dropdown-menu-upload-bill-item',
    },
    shouldShowConnectAccountingSoftwareOption && {
      label: formatMessage('activities.payDashboard.billsTab.addBillDropdownMenu.connectAccountingSoftware'),
      onClick: onAccountingPlatformConnect,
      icon: 'refresh' as const,
      dataTestId: 'add-bill-dropdown-menu-connect-accounting-software-item',
    },
    shouldShouldGmailOption && {
      label: connectGmailCta,
      onClick: onGmailConnect,
      icon: 'mail-inbox' as const,
      dataTestId: 'add-bill-dropdown-menu-connect-gmail-item',
    },
  ]);

  const shouldShowUploadBillsButton = actionsItems.length === 1;

  return (
    <>
      <UploadFilesWidget onUploadFiles={onAddNewBillUpload} ref={fileInput} />
      {shouldShowUploadBillsButton ? (
        <Button
          data-testid="bills-tab-add-upload-bills-button"
          onClick={handleUploadBillButtonClick}
          label={formatMessage('activities.payDashboard.billsTab.uploadBillsButton')}
          variant="tertiary"
        />
      ) : (
        <Menu
          trigger={
            <BaseMenu.ActionTrigger size="medium" label={label} data-testid="add-bill-dropdown-menu-action-trigger" />
          }
          isOpen={isMenuOpen}
          onOpenChange={setIsMenuOpen}
          data-testid="add-bill-dropdown-menu"
        >
          {actionsItems.map((item, index) => (
            <AddBillDropdownMenuItem
              key={`${item.label}-${index}`}
              index={index}
              label={item.label}
              icon={item.icon}
              onClick={item.onClick}
              dataTestId={item.dataTestId}
            />
          ))}
        </Menu>
      )}
    </>
  );
};
