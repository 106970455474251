import { useDeleteCollaborator, useUpdateCollaborator } from '@melio/platform-api';
import { PermissionLevelEnum } from '@melio/platform-api/src/entities/collaborators/useCollaborator';
import { ComponentProps, useState } from 'react';

import { useAsyncActionWithToast } from './hooks/useAsyncActionWithToast';
import { ApproveAccountingFirmModalScreen } from './screens/modals/InviteOrEdit/ApproveAccountingFirm.modal-screen.';
import { Collaborator } from './types';

export const useApproveAccountingFirmModel = () => {
  const [state, setModalState] = useState<{
    isOpen: boolean;
    collaborator: Collaborator | null;
  }>({
    isOpen: false,
    collaborator: null,
  });
  const openModal = (collaborator: Collaborator) => setModalState({ isOpen: true, collaborator });
  const closeModal = () => setModalState({ isOpen: false, collaborator: null });

  const { isUpdating, update: updateCollaborator } = useUpdateCollaborator({
    id: state.collaborator?.id,
    enabled: !!state.collaborator?.id,
  });

  const approveWithToast = useAsyncActionWithToast({
    successLabel: 'activities.manageCollaborators.messages.collaborator.approveFirmInvite.success',
    errorLabel: 'activities.manageCollaborators.messages.collaborator.approveFirmInvite.error',
    onSuccess: closeModal,
    isInfo: true,
  });

  const { isDeleting, delete: deleteCollaborator } = useDeleteCollaborator({
    id: state.collaborator?.id,
    enabled: !!state.collaborator?.id,
  });

  const declineWithToast = useAsyncActionWithToast({
    successLabel: 'activities.manageCollaborators.messages.collaborator.declineFirmInvite.success',
    errorLabel: 'activities.manageCollaborators.messages.collaborator.declineFirmInvite.error',
    onSuccess: closeModal,
    isInfo: true,
  });

  const submit: ComponentProps<typeof ApproveAccountingFirmModalScreen>['onApprove'] = async () => {
    if (state.collaborator?.roleUniqueName) {
      const currentUniqueName = state.collaborator?.roleUniqueName;
      await approveWithToast(() =>
        updateCollaborator({
          roleUniqueName: currentUniqueName,
          permissionLevel: PermissionLevelEnum.Full,
        })
      );
    }
  };
  const onDecline: ComponentProps<typeof ApproveAccountingFirmModalScreen>['onDecline'] = async () => {
    await declineWithToast(deleteCollaborator);
  };

  return {
    openModal,
    closeModal,
    state,
    submit,
    onDecline,
    isDeleting,
    isUpdating,
  };
};
