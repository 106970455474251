import { useDeleteCollaborator } from '@melio/platform-api';
import { ComponentProps, useState } from 'react';

import { useDeleteCollaboratorAnalytics } from './hooks/analytics/useDeleteCollaboratorAnalytics';
import { useAsyncActionWithToast } from './hooks/useAsyncActionWithToast';
import { DeleteCollaboratorModalScreen } from './screens/modals/DeleteCollaborator/DeleteCollaborator.modal-screen';
import type { Collaborator } from './types';

export const useDeleteCollaboratorModal = () => {
  const [state, setModalState] = useState<{
    isOpen: boolean;
    collaborator: Collaborator | null;
  }>({
    isOpen: false,
    collaborator: null,
  });
  const { isDeleting, delete: deleteCollaborator } = useDeleteCollaborator({
    id: state.collaborator?.id,
    enabled: !!state.collaborator?.id,
  });
  const analytics = useDeleteCollaboratorAnalytics();

  const openModal = (collaborator: Collaborator) => setModalState({ isOpen: true, collaborator });
  const closeModal = (reason?: 'cancel' | 'exit') => {
    setModalState({ isOpen: false, collaborator: null });
    reason ? analytics.close(reason) : null;
  };

  const actionWithToast = useAsyncActionWithToast({
    successLabel: 'activities.manageCollaborators.messages.collaborator.delete.success',
    errorLabel: 'activities.manageCollaborators.messages.collaborator.delete.error',
    onSuccess: closeModal,
    isInfo: true,
  });

  const submit: ComponentProps<typeof DeleteCollaboratorModalScreen>['onDone'] = async () => {
    await actionWithToast(deleteCollaborator);
    analytics.submit();
  };

  return {
    openModal,
    closeModal,
    state,
    submit,
    isDeleting,
  };
};
