import { CatalogItem, useMelioIntl } from '@melio/ar-domain';
import { ActionsDropdownMenuItemProps } from '@melio/penny';

export type UseCatalogItemActionsItemsProps = {
  catalogItem: CatalogItem;
  onEditItem: (itemId: string) => unknown;
  onDeleteItem: (itemId: string) => unknown;
};

export const useCatalogItemActionsItems = ({
  catalogItem,
  onEditItem,
  onDeleteItem,
}: UseCatalogItemActionsItemsProps): ActionsDropdownMenuItemProps[] => {
  const { formatMessage } = useMelioIntl();

  const editCatalogItemAction = {
    label: formatMessage(
      'ar.settings.activities.catalogItemsTable.components.catalogItemsTable.columns.actions.edit.label'
    ),
    onClick: () => onEditItem(catalogItem.id),
    dataTestId: 'catalog-item-edit',
  };

  const deleteCatalogItemAction = {
    label: formatMessage(
      'ar.settings.activities.catalogItemsTable.components.catalogItemsTable.columns.actions.delete.label'
    ),
    onClick: () => onDeleteItem(catalogItem.id),
    variant: 'critical',
    dataTestId: 'catalog-item-delete',
  };

  return [editCatalogItemAction, deleteCatalogItemAction];
};
