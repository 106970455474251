import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useEngagement } from '../EngagementProvider';
import {
  ActionHandlerTypeEnum,
  EngagementModalButton,
  ModalButtons,
  ModalButtonsBehaviorEnum,
  ModalButtonsPropsEnum,
  ModalMessage,
  ModalMessageExtrasEnum,
  SplitModalMessage,
  UseEngagementModalProps,
} from '../types';
import { getDeepLinkUrl, isValidUrl, splitModalMessageToSubDescriptions } from '../utils';

export const useEngagementModal = ({
  eventName,
}: UseEngagementModalProps): {
  modalMessage?: ModalMessage;
  onCloseHandler: () => void;
  modalMessageDescriptions?: SplitModalMessage;
  secondaryButton?: ModalButtons[ModalButtonsPropsEnum.SECONDARY_BUTTON];
  primaryButton?: ModalButtons[ModalButtonsPropsEnum.PRIMARY_BUTTON];
  combinedButton?: ModalButtons[ModalButtonsPropsEnum.COMBINED_BUTTON];
} => {
  const location = useLocation();
  const { pathname } = location;

  const {
    engagementModalSubscriptionEventHandler,
    resetEngagementModal,
    logEngagementModalImpression,
    logEngagementModalClick,
    logEngagementModalButtonClick,
    logEngagementCustomEvent,
  } = useEngagement();

  const navigate = useNavigate();
  const [modalMessage, setModalMessage] = useState<ModalMessage>();
  const [shouldLogEvent, setShouldLogEvent] = useState<boolean>(true);

  useEffect(() => {
    if (shouldLogEvent) {
      logEngagementCustomEvent(eventName);
      setShouldLogEvent(false);
    }
  }, [shouldLogEvent, eventName, logEngagementCustomEvent]);

  useEffect(() => {
    engagementModalSubscriptionEventHandler((message) => {
      if (message) {
        if (!message?.isControl) {
          setModalMessage(message);
        }
        logEngagementModalImpression(message);
      }
    });
  }, [engagementModalSubscriptionEventHandler, logEngagementModalImpression]);

  const onCloseHandler = (isActionButtonClicked?: boolean): void => {
    if (modalMessage && !isActionButtonClicked) {
      logEngagementModalClick(modalMessage);
    }
    setModalMessage(undefined);
    resetEngagementModal();
  };

  const onActionHandler = (actionHandlerType: number, clickBehavior?: string): void => {
    if (modalMessage?.buttons[actionHandlerType]) {
      logEngagementModalButtonClick(modalMessage.buttons[actionHandlerType] as EngagementModalButton, modalMessage);
    }

    const url = modalMessage?.buttons[actionHandlerType]?.uri;
    const deepLink = getDeepLinkUrl(url);

    if (!url) {
      onCloseHandler(true);
    }

    void isValidUrl(url).then((isValid) => {
      if (deepLink) {
        const pathPrefix = pathname.match(/^\/[^/]+/);
        if (pathPrefix?.[0]) {
          onCloseHandler(true);
          navigate(`${pathPrefix[0]}/${deepLink}`);
        }
      } else {
        if (isValid && url && clickBehavior === ModalButtonsBehaviorEnum.NEW_TAB) {
          onCloseHandler(true);

          window.open(url);
        }

        if (isValid && url && clickBehavior === ModalButtonsBehaviorEnum.SAME_TAB) {
          onCloseHandler(true);

          window.location.replace(url);
        }
      }
    });
  };

  const primaryButton: ModalButtons[ModalButtonsPropsEnum.PRIMARY_BUTTON] = modalMessage?.buttons[
    ActionHandlerTypeEnum.PRIMARY_BUTTON
  ]
    ? {
        label: modalMessage.buttons[ActionHandlerTypeEnum.PRIMARY_BUTTON].text,
        variant: 'tertiary',
        onClick: () =>
          onActionHandler?.(
            ActionHandlerTypeEnum.PRIMARY_BUTTON,
            modalMessage?.extras[ModalMessageExtrasEnum.CLICK_BEHAVIOR_BUTTON_01]
          ),
      }
    : undefined;

  const secondaryButton: ModalButtons[ModalButtonsPropsEnum.SECONDARY_BUTTON] = modalMessage?.buttons[
    ActionHandlerTypeEnum.SECONDARY_BUTTON
  ]
    ? {
        label: modalMessage.buttons[ActionHandlerTypeEnum.SECONDARY_BUTTON].text,
        variant: 'primary',
        onClick: () =>
          onActionHandler?.(
            ActionHandlerTypeEnum.SECONDARY_BUTTON,
            modalMessage?.extras[ModalMessageExtrasEnum.CLICK_BEHAVIOR_BUTTON_02]
          ),
      }
    : undefined;

  const getCombinedButton = (): ModalButtons[ModalButtonsPropsEnum.COMBINED_BUTTON] | undefined => {
    if (modalMessage?.buttons?.length != 1) {
      return undefined;
    }

    return modalMessage?.buttons[ActionHandlerTypeEnum.PRIMARY_BUTTON]
      ? {
          label: modalMessage?.buttons[ActionHandlerTypeEnum.PRIMARY_BUTTON].text,
          variant: 'primary',
          onClick: () =>
            onActionHandler?.(
              ActionHandlerTypeEnum.PRIMARY_BUTTON,
              modalMessage?.extras[ModalMessageExtrasEnum.CLICK_BEHAVIOR_BUTTON_01]
            ),
        }
      : undefined;
  };

  return {
    modalMessage,
    modalMessageDescriptions: splitModalMessageToSubDescriptions(modalMessage?.message),
    onCloseHandler,
    primaryButton,
    secondaryButton,
    combinedButton: getCombinedButton(),
  };
};
