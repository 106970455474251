import { apiClient, Collaborator, PatchCollaboratorRequest } from '@melio/javascript-sdk';
// eslint-disable-next-line no-restricted-imports
import { useIsMutating } from 'react-query';

import { UseModelApiProps } from '../../core';
import { useUpdateMutation } from '../../core/useUpdateMutation';
import { createQueryKey } from '../../core/util';
import { CollaboratorModel } from './types';

type Props = UseModelApiProps<Collaborator>;
type UpdateModel = Pick<CollaboratorModel, 'isUpdating' | 'queryKey' | 'update'>;

export const useUpdateCollaborator = (props: Props): UpdateModel => {
  const client = apiClient.collaborators();

  const queryKey = createQueryKey({
    queryKey: 'collaborator',
    role: 'model',
    scope: 'default',
    id: props.id,
  });

  const update = useUpdateMutation<Collaborator, PatchCollaboratorRequest>(
    (data) => client.update(props.id as string, data),
    { queryKey }
  );

  const isUpdating = useIsMutating(queryKey) > 0;

  return {
    queryKey,
    update,
    isUpdating,
  };
};
