import { Container, Skeleton } from '@melio/penny';
import { AccountAddress } from '@melio/platform-api-axios-client';

import { PreviewSubscriptionBillingItem, SubscriptionPreview } from '../../../../api';
import { SubscriptionCheckoutFreeTrialTracker } from './SubscriptionCheckoutFreeTrialTracker';
import { SubscriptionDetailedSubtotal } from './SubscriptionDetailedSubtotal';

type SubscriptionDetailedTotalProps = {
  preview?: SubscriptionPreview;
  paidPeriodData?: PreviewSubscriptionBillingItem;
  freeTrialPeriodData?: {
    chargingAmount?: number;
    freeBillingItem?: PreviewSubscriptionBillingItem;
  };
  isLoading: boolean;
  legalAddress: AccountAddress | null;
};

export const SubscriptionDetailedTotal = ({
  paidPeriodData,
  preview,
  freeTrialPeriodData,
  legalAddress,
  isLoading,
}: SubscriptionDetailedTotalProps) => {
  if (isLoading || !paidPeriodData) {
    return (
      <Container>
        <Skeleton height="12px" />
      </Container>
    );
  }

  if (freeTrialPeriodData?.chargingAmount) {
    return (
      <SubscriptionCheckoutFreeTrialTracker
        startChargingDate={paidPeriodData.serviceStartDate}
        chargingAmount={freeTrialPeriodData.chargingAmount}
      />
    );
  }

  return <SubscriptionDetailedSubtotal isLoading={isLoading} preview={preview} legalAddress={legalAddress} />;
};
