import { useMelioIntl } from '@melio/ar-domain';
import { Group, Illustration, Text } from '@melio/penny';

export const PaymentProcessingIndicator = () => {
  const { formatMessage } = useMelioIntl();

  return (
    <Group
      alignItems="center"
      variant="vertical"
      height="full"
      spacing="m"
      justifyContent="center"
      data-testid="guest-payment-processing-indicator"
    >
      <Illustration size="small" type="processing" />
      <Group alignItems="center" spacing="xs" variant="vertical" textAlign="center">
        <Text textStyle="heading2Semi">{formatMessage('ar.guestPayment.paymentProcessing.title')}</Text>
        <Text textStyle="body2">{formatMessage('ar.guestPayment.paymentProcessing.description')}</Text>
      </Group>
    </Group>
  );
};

PaymentProcessingIndicator.displayName = 'PaymentProcessingIndicator';
