import { useConfig } from '@melio/platform-provider';
import { useEffect } from 'react';

export const useSetDocumentTitle = (title: string) => {
  const {
    tabTitle,
    settings: { isEmbeddedExperience },
  } = useConfig();

  useEffect(() => {
    if (isEmbeddedExperience) {
      document.title = title;
    }
  }, [title, isEmbeddedExperience]);

  useEffect(
    () => () => {
      if (isEmbeddedExperience) {
        document.title = tabTitle;
      }
    },
    [tabTitle, isEmbeddedExperience]
  );
};
