import { ServiceChargeCatalog, useServiceChargeCatalog, useServiceChargeCatalogs } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';
import { useEffect, useState } from 'react';

import { ServiceChargeFormFields } from './components';
import { CreateServiceChargeModalScreen } from './screens';
import { ServiceChargeParams } from './types';

const NEW_SERVICE_CHARGE_ID = 'NEW';

export type CreateServiceChargeModalActivityProps = {
  isOpen: boolean;
  onError?: ARErrorFunction;
  onClose: VoidFunction;
  onDone: (serviceCharge: ServiceChargeParams) => void;
  serviceChargeCatalogId?: string;
};

export const CreateServiceChargeActivity = forwardRef<CreateServiceChargeModalActivityProps>(
  ({ isOpen, onClose, onError, onDone: _onDone, serviceChargeCatalogId, ...props }, ref) => {
    const [newServiceCharge, setNewServiceCharge] = useState<Pick<ServiceChargeCatalog, 'id' | 'name'>>();
    const [selectedServiceChargeId, setSelectedServiceChargeId] = useState<string | undefined>(serviceChargeCatalogId);

    const onDone = (data: ServiceChargeCatalog) =>
      _onDone({ serviceChargeCatalogId: data.id, name: data.name, type: data.type, value: data.value });

    const {
      data: serviceChargesData,
      isFetched,
      create: createServiceCharge,
      isMutating: isCreating,
    } = useServiceChargeCatalogs({ onError, onCreate: onDone, onCreateError: onError });

    const { update: updateServiceCharge, isMutating: isUpdating } = useServiceChargeCatalog({
      id: selectedServiceChargeId,
      enabled: false,
      onUpdate: onDone,
      onUpdateError: onError,
    });

    const serviceCharges = [newServiceCharge, ...(serviceChargesData ?? [])].filter(Boolean) as ServiceChargeCatalog[];
    const selectedServiceCharge = serviceCharges.find((serviceCharge) => serviceCharge.id === selectedServiceChargeId);
    const selectOptions = serviceCharges.map((serviceCharge) => ({
      label: serviceCharge.name,
      value: serviceCharge.id,
    }));

    useEffect(() => {
      serviceChargeCatalogId && handleChangeServiceCharge(serviceChargeCatalogId);
    }, [serviceChargeCatalogId]);

    const submitHandler = async ({ id, type, value }: ServiceChargeFormFields) => {
      if (!selectedServiceCharge) {
        return;
      } else if (selectedServiceCharge.id === NEW_SERVICE_CHARGE_ID) {
        await createServiceCharge({ name: selectedServiceCharge.name, type, value });
      } else if (
        selectedServiceCharge.id === id &&
        (selectedServiceCharge.type !== type || selectedServiceCharge.value !== value)
      ) {
        await updateServiceCharge({ type, value });
      } else {
        onDone(selectedServiceCharge);
      }
      setNewServiceCharge(undefined);
    };

    const handleChangeServiceCharge = (selectedId: string) => {
      setNewServiceCharge(undefined);
      setSelectedServiceChargeId(selectedId);
    };

    const handleCreateServiceCharge = (name: string): string => {
      const option = { name, id: NEW_SERVICE_CHARGE_ID };
      setNewServiceCharge(option);
      setSelectedServiceChargeId(option.id);
      return option.id;
    };

    const handleOnClose = () => {
      setNewServiceCharge(undefined);
      onClose();
    };

    return (
      <CreateServiceChargeModalScreen
        isOpen={isOpen}
        isLoading={!isFetched}
        isSaving={isCreating || isUpdating}
        selectOptions={selectOptions}
        selectedServiceCharge={selectedServiceCharge}
        onClose={handleOnClose}
        onSubmit={submitHandler}
        onChangeServiceCharge={handleChangeServiceCharge}
        onCreateServiceCharge={handleCreateServiceCharge}
        data-component={CreateServiceChargeActivity.displayName}
        data-testid="create-service-charge-modal"
        {...props}
        ref={ref}
      />
    );
  }
);

CreateServiceChargeActivity.displayName = 'CreateServiceChargeModalActivity';
