import { AccountAddress, ApplyForFinancingBusinessType, Industry } from '@melio/platform-api';

export type FinancingApplicationBusinessDetailsForm = {
  companyName: string;
  legalBusinessName: string;
  legalBusinessAddress: AccountAddress;
  ownerPhone: string;
  annualRevenue: string;
  businessIndustry: Industry;
  businessType: ApplyForFinancingBusinessType;
  employerId: string;
};

export type FinancingApplicationOwnerDetailsForm = {
  isOwnQuarter: boolean;
  firstName: string;
  lastName: string;
  jobTitle: string;
  email: string;
  dateOfBirth: Date;
  ssn: string;
  phone: string;
  address: AccountAddress;
  additionalOwners: AdditionalOwnerDetailsForm[];
};

export type AdditionalOwnerDetailsForm = {
  firstName: string;
  lastName: string;
  email: string;
  ownershipPercentage: string;
  dateOfBirth: Date;
  ssn: string;
  address: AccountAddress;
};

export enum ApplicationStatus {
  Approved = 'Approved',
  Pending = 'Pending',
  Declined = 'Declined',
  Error = 'Error',
}

export const TAX_ID_NUMBER_REGEX = /^[0-9]{9}$|^[0-9]{3}-[0-9]{2}-[0-9]{4}$|^[0-9]{2}-[0-9]{7}$/;
