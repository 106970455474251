import { useMelioIntl } from '@melio/ar-domain';
import { Button, Group, Icon, IconButton, Tooltip } from '@melio/penny';

type ButtonsProps = {
  onCreateInvoice: VoidFunction;
  onVisitSupportSettingsPage: VoidFunction;
};

export const HeaderToolBar: React.FC<ButtonsProps> = ({ onCreateInvoice, onVisitSupportSettingsPage }) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Group spacing="s" variant="horizontal">
      <Tooltip
        dontDescribeChild
        label={formatMessage('ar.dashboard.activities.tabsLayout.supportButton.tooltip.label')}
      >
        <IconButton
          onClick={onVisitSupportSettingsPage}
          icon="help-circle"
          data-testid="dashboard-support-icon-button"
          variant="primary"
          aria-label={formatMessage('ar.dashboard.activities.tabsLayout.supportButton.tooltip.label')}
        />
      </Tooltip>
      <Button
        variant="primary"
        label={formatMessage('ar.dashboard.activities.tabsLayout.createInvoiceButton.label')}
        leftElement={<Icon size="small" type="add" color="inherit" aria-hidden />}
        onClick={onCreateInvoice}
        data-testid="dashboard-create-invoice-button"
      />
    </Group>
  );
};

HeaderToolBar.displayName = 'HeaderToolBar';
