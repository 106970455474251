import { useAnalyticsContext, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { useDeliveryMethods, useVendor } from '@melio/platform-api';

import { SubmissionTargetType } from '../../../../delivery-methods/AddVendorBankDetails/types';
import { DeliveryMethodFlowOrigin } from '../../../../delivery-methods/types';
import { InternationalVendorDetailsScreen } from './screens/InternationalVendorDetails';
import {
  InternationalVendorDetailsFormReturnedValue,
  VendorDetailsFormDefaultValues,
} from './screens/InternationalVendorDetails/widgets/InternationalVendorDetailsForm';

export type InternationalVendorDetailsActivityProps = {
  onBack: VoidFunction;
  onClose: VoidFunction;
  onDone: (data: InternationalVendorDetailsFormReturnedValue, target?: SubmissionTargetType) => void;
  onError?: ErrorFunction;
  origin: DeliveryMethodFlowOrigin;
  vendorId: string;
  isMissingLegalDetails: boolean;
  isMissingOrganizationBeneficialOwnersDetails: boolean;
  defaultValues?: VendorDetailsFormDefaultValues;
};

export const InternationalVendorDetailsActivity = withAnalyticsContext(
  ({
    onBack,
    onClose,
    onDone,
    origin,
    vendorId,
    isMissingOrganizationBeneficialOwnersDetails,
    isMissingLegalDetails,
    defaultValues,
  }: InternationalVendorDetailsActivityProps) => {
    const { data: vendor, isLoading: isVendorLoading } = useVendor({ id: vendorId });
    const { isMutating: isDeliveryMethodsLoading } = useDeliveryMethods({
      vendorId,
      enabled: false,
    });

    const isLoading = isVendorLoading || isDeliveryMethodsLoading;

    useAnalyticsContext({
      globalProperties: {
        PageName: 'what-are-vendor-details',
        Intent: 'add-vendors-details',
      },
    });

    useAnalyticsView('DeliveryMethod', !isLoading, true);

    return (
      <InternationalVendorDetailsScreen
        onClose={onClose}
        onBack={onBack}
        origin={origin}
        onDone={onDone}
        organizationName={vendor?.name || ''}
        isMissingLegalDetails={isMissingLegalDetails}
        isMissingOrganizationBeneficialOwnersDetails={isMissingOrganizationBeneficialOwnersDetails}
        isLoading={isLoading}
        defaultValues={defaultValues}
      />
    );
  }
);

InternationalVendorDetailsActivity.displayName = 'InternationalVendorDetailsActivity';
