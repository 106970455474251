import { Container, Group, GroupProps, IconKey, useBreakpointValue } from '@melio/penny';
import { Bill, DeliveryMethod, PaymentApprovalDecisionStatusEnum, SubscriptionOccurrence } from '@melio/platform-api';
import { MessageKey } from '@melio/platform-i18n';
import { FC } from 'react';

import { PaymentDetailsBanners } from './PaymentDetailsBanners';
import { PaymentDetailsCardTitle } from './PaymentDetailsTitle';

type PaymentScheduledPaymentDetailsCardContainerProps = {
  paymentId?: string | null;
  paymentAmount: number;
  foreignAmount?: number | null;
  bills: Bill[];
  deliveryMethod: DeliveryMethod;
  scheduledDate: Date;
  approvalDecisionStatus: PaymentApprovalDecisionStatusEnum;
  subscriptionOccurrence?: SubscriptionOccurrence | null;
  isEditPaymentFlow?: boolean;
  hasBorder?: boolean;
  isRecurringFlow?: boolean;
  icon?: { type: IconKey; ariaLabel: MessageKey };
};

export const PaymentScheduledPaymentDetailsCardContainer: FC<PaymentScheduledPaymentDetailsCardContainerProps> = ({
  paymentId,
  paymentAmount,
  foreignAmount,
  bills,
  deliveryMethod,
  scheduledDate,
  approvalDecisionStatus,
  hasBorder = true,
  isEditPaymentFlow,
  subscriptionOccurrence,
  children,
  icon,
  isRecurringFlow = false,
}) => {
  const containerSpacing = useBreakpointValue<GroupProps['spacing']>({ xs: 's', s: 'm', m: 'l' });

  const getDeliveryMethodCurrency = () => {
    if (deliveryMethod.type === 'international-account') {
      return deliveryMethod.details.currency;
    }
    return 'USD';
  };
  const currency = getDeliveryMethodCurrency();
  return (
    <Container
      paddingX={containerSpacing}
      paddingY={containerSpacing}
      data-testid="payment-details-card-container"
      border={hasBorder ? 'regular' : undefined}
    >
      <Group variant="vertical" spacing="l">
        <PaymentDetailsCardTitle
          currency={currency}
          foreignAmount={foreignAmount}
          paymentId={paymentId}
          amount={paymentAmount}
          bills={bills}
          icon={icon}
        />
        {children}
        <PaymentDetailsBanners
          deliveryMethod={deliveryMethod}
          scheduledDate={scheduledDate}
          approvalDecisionStatus={approvalDecisionStatus}
          subscriptionOccurrence={subscriptionOccurrence}
          isEditPaymentFlow={isEditPaymentFlow}
          isRecurringFlow={isRecurringFlow}
        />
      </Group>
    </Container>
  );
};
