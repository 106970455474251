/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Stack } from '@chakra-ui/react';
import { MonitoredAction } from '@melio/ap-activities';
import { useMtlDateUtils } from '@melio/ap-domain';
import { Button, Container, Link, SectionBanner } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { RoleUniqueNames, useAccountWithLogoUrl, useCollaborator } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { MessageKey } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { usePermissions } from '@melio/platform-permissions';
import { SettingsPageHeader, useSystemMessage } from '@melio/platform-utils';

import { SettingsCardIdEnum } from '@/cl/components/SettingsCard/SettingsCard.component';
import { SettingsSectionContainer } from '@/cl/components/SettingsSectionContainer/SettingsSectionContainer.component';
import { WithLoading } from '@/hoc/withLoading.hoc';
import { usePartnerConfig } from '@/hooks/partners';
import { useConfirmationBarSubmissionController } from '@/hooks/useConfirmationBar';
import { usePlatformIntl } from '@/translations/Intl';
import { ProfileForm, ProfileFormDetails } from '@/widgets/settings-page/ProfileSettingsPage/ProfileForm.widget';

export type ProfileSettingsProps = {
  allowEdit: boolean;
};

export const ProfileSettings = ({ allowEdit }: ProfileSettingsProps) => {
  const {
    isLoading: isAccountLoading,
    data: accountMeData,
    update: updateAccount,
  } = useAccountWithLogoUrl({ id: 'me' });
  const { startAction, endAction } = useMonitoring<MonitoredAction>();
  const { isLoading: isCollaboratorLoading, data: actor } = useCollaborator({ id: 'me' });
  const { roles } = usePermissions();
  const { formatMessage } = usePlatformIntl();
  const { showMessage } = useSystemMessage();
  const primaryText = formatMessage('widgets.confirmation.settings.primary');
  const secondaryText = formatMessage('widgets.confirmation.settings.secondary');
  const roleText = useRoleText(actor?.roleUniqueName);
  const { createDate, isEqualDate, convertFromISO, dateToISOString, dateToString } = useMtlDateUtils();
  const [mtlKycUpliftEnabled] = useFeature<boolean>(FeatureFlags.MtlKycUplift, false);
  const { track } = useAnalytics();

  useEffect(() => {
    track('Settings', 'View', { PageName: SettingsCardIdEnum.ProfileSettings });
  }, [track]);

  const {
    isOpen: isConfirmationBarOpen,
    showConfirmationBar,
    onSubmissionStateChange,
    setSubmitButtonState,
    reset,
  } = useConfirmationBarSubmissionController<ProfileFormDetails>(
    {
      submitButtonProps: {
        text: primaryText,
      },
      cancelButtonProps: {
        text: secondaryText,
      },
    },
    { resetOnSecondary: true },
  );

  const btnText = formatMessage('widgets.profileSettings.header.buttonText');

  const {
    partnerConfig: { allowEditFirstAndLastName },
  } = usePartnerConfig();

  const isDataUnchanged = (formData: ProfileFormDetails) =>
    accountMeData &&
    accountMeData.user.firstName === formData.firstName &&
    accountMeData.user.lastName === formData.lastName &&
    accountMeData.user.phoneNumber === formData.mobileNumber &&
    accountMeData.user.dateOfBirth &&
    formData.dateOfBirth &&
    isEqualDate(accountMeData.user.dateOfBirth, formData.dateOfBirth);

  const onSubmitHandler = async (formData: ProfileFormDetails) => {
    if (isDataUnchanged(formData)) {
      showConfirmationBar(false);
      return;
    }
    startAction('profile_settings_submit');
    setSubmitButtonState({ isLoading: true });
    try {
      await updateAccount({
        user: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          ...(formData.mobileNumber && { phoneNumber: formData.mobileNumber }),
          ...(formData.dateOfBirth && { dateOfBirth: dateToISOString(createDate(formData.dateOfBirth)) }),
        },
      });
      showMessage({
        type: 'informative',
        title: formatMessage('widgets.profileSettings.toast.success'),
      });
      endAction('profile_settings_submit');
    } catch (e) {
      reset?.();
    } finally {
      setSubmitButtonState({ isLoading: false });
      showConfirmationBar(false);
    }
  };

  return (
    <WithLoading isLoading={isAccountLoading || isCollaboratorLoading}>
      <SettingsSectionContainer data-component="ProfileSettingsComponent">
        <SettingsPageHeader
          title={formatMessage('app.settings.companySection.cards.profile.title')}
          subTitle={formatMessage(
            `app.settings.companySection.cards.profile.description${allowEditFirstAndLastName ? '' : '.uneditable'}`,
          )}
        />
        <Stack flexDirection="column" gridRowGap={'24px'} spacing={0}>
          {!allowEdit && (
            <SectionBanner
              variant="neutral"
              description={formatMessage('widgets.profileSettings.sectionBanner', {
                link: (
                  <Link
                    href={formatMessage('widgets.profileSettings.sectionBanner.link')}
                    label={formatMessage('widgets.profileSettings.sectionBanner.label')}
                    newTab
                  />
                ),
              })}
              hideIcon
            />
          )}
          <ProfileForm
            onSubmit={onSubmitHandler}
            onSubmissionStateChange={onSubmissionStateChange}
            isEditable={isConfirmationBarOpen}
            hideRole={!roles.length}
            defaultValues={{
              role: roleText,
              ...(accountMeData && {
                email: accountMeData.user.email || '',
                firstName: accountMeData.user.firstName,
                lastName: accountMeData.user.lastName,
                mobileNumber: accountMeData.user.phoneNumber || '',
                dateOfBirth: accountMeData.user.dateOfBirth
                  ? dateToString(convertFromISO(accountMeData.user.dateOfBirth))
                  : '',
              }),
            }}
            mtlKycUpliftEnabled={mtlKycUpliftEnabled}
          />
          <Container>
            {!isConfirmationBarOpen && allowEdit ? (
              <Button variant={'tertiary'} onClick={() => showConfirmationBar(true)} label={btnText} />
            ) : null}
          </Container>
        </Stack>
      </SettingsSectionContainer>
    </WithLoading>
  );
};

ProfileSettings.displayName = 'ProfileSettings';

function useRoleText(roleUniqueName?: RoleUniqueNames | null) {
  const { formatMessage } = usePlatformIntl();
  const roleText = formatMessage(`activities.manageCollaborators.roles.${roleUniqueName ?? 'null'}` as MessageKey);

  return roleText === `activities.manageCollaborators.roles.${roleUniqueName}`
    ? formatMessage('activities.manageCollaborators.roles.null')
    : roleText;
}
