import { Button, Container } from '@melio/penny';

type DeleteLineItemMobileProps = {
  IndexNumber: number;
  testId: string;
  onClick: VoidFunction;
  label: string;
  ariaLabel?: string;
};

export const DeleteLineItemMobile = ({ IndexNumber, testId, onClick, label, ariaLabel }: DeleteLineItemMobileProps) => (
  <Container paddingBottom="xs" justifyContent="flex-start">
    <Button
      label={label}
      onClick={onClick}
      variant="critical-secondary"
      size="small"
      data-testid={`${testId}-${IndexNumber}`}
      aria-label={ariaLabel ?? 'Delete line item'}
    />
  </Container>
);
