import { Button, Container, Drawer, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { SubscriptionPaymentMethod } from '@melio/subscriptions/src/ui/activities/SubscriptionCheckout/components/SubscriptionCheckoutSelection/components/SubscriptionPaymentMethod';
import { useState } from 'react';

import { AnalyticsPaymentDrawerActionType } from '../../types';

type AccountantsFundingSourceDrawerProps = {
  isOpen: boolean;
  fundingSourceId: string | null;
  setFundingSourceId: (fundingSourceId: string | null) => void;
  onClose: (action?: AnalyticsPaymentDrawerActionType) => void;
};

export const AddPaymentMethodDrawer = ({
  isOpen,
  fundingSourceId,
  setFundingSourceId,
  onClose,
}: AccountantsFundingSourceDrawerProps) => {
  const { formatMessage } = useMelioIntl();
  const [selectedFundingSourceId, setSelectedFundingSourceId] = useState<string | null>(fundingSourceId);

  const onCancel = () => {
    setSelectedFundingSourceId(fundingSourceId);
    onClose('cancel');
  };

  const onAdd = () => {
    setFundingSourceId(selectedFundingSourceId);
    onClose('continue');
  };

  const onExit = () => {
    setSelectedFundingSourceId(fundingSourceId);
    onClose('exit');
  };

  return (
    <Drawer
      isOpen={isOpen}
      header={
        <Text textStyle="heading2Semi">
          {formatMessage('activities.accountants.paymentMethodCard.paymentMethodDrawer.header.label')}
        </Text>
      }
      body={
        <SubscriptionPaymentMethod
          fundingSourceId={selectedFundingSourceId}
          setFundingSourceId={setSelectedFundingSourceId}
          skipSelectedView
        />
      }
      footer={
        <Container justifyContent="space-between">
          <Button
            data-testid="payment-method-drawer-cancel"
            label={formatMessage('activities.accountants.paymentMethodCard.paymentMethodDrawer.footer.cancel')}
            variant="secondary"
            onClick={onCancel}
          />
          <Button
            data-testid="payment-method-drawer-continue"
            label={formatMessage('activities.accountants.paymentMethodCard.paymentMethodDrawer.footer.continue')}
            variant="primary"
            onClick={onAdd}
          />
        </Container>
      }
      onClose={onExit}
    />
  );
};
