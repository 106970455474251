import React from 'react';
import { object, SchemaOf, string } from 'yup';
import { FormWidgetProps } from '@melio/ap-widgets';
import { Form, useMelioForm } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { usePlatformIntl } from '@/translations/Intl';

export type NicknameFormDetails = {
  nickname?: string;
};

const useSchema = (): SchemaOf<NicknameFormDetails> => {
  const { formatMessage } = usePlatformIntl();
  const emptyError = formatMessage('widgets.paymentMethods.editLabelDialog.form.emptyError');

  return object().shape({
    nickname: string().required(emptyError),
  });
};

type NicknameFormProps = FormWidgetProps<NicknameFormDetails>;

export const NicknameForm = forwardRef<NicknameFormProps, 'form'>(
  ({ defaultValues, isSaving, onSubmit, onSubmissionStateChange, ...props }, ref) => {
    const { formatMessage } = usePlatformIntl();
    const { formProps, registerField } = useMelioForm<NicknameFormDetails>({
      onSubmit,
      schema: useSchema(),
      isSaving,
      defaultValues,
      mode: 'onSubmit',
      onSubmissionStateChange,
    });
    const label = formatMessage('widgets.paymentMethods.editLabelDialog.form.inputLabel');
    const placeholder = formatMessage('widgets.paymentMethods.editLabelDialog.form.placeholder');

    return (
      <Form data-component="NicknameForm" size={'small'} {...props} {...formProps} ref={ref}>
        <Form.TextField type="text" labelProps={{ label }} placeholder={placeholder} {...registerField('nickname')} />
      </Form>
    );
  },
);

NicknameForm.displayName = 'NicknameForm';
