import { useDisclosure } from '@chakra-ui/react';
import { useNavigate, withRouteId } from '@melio/ar-domain';
import { PartnerName } from '@melio/platform-api';
import { forwardRef } from '@melio/platform-utils';
import { Route, Routes } from 'react-router-dom';

import { InvoiceDrawerActivity as _InvoiceDrawerActivity } from '../activities';
import { GuestPaymentRouterFlow as _GuestPaymentRouterFlow } from './GuestPayment.router.flow';

const InvoiceDrawerActivity = withRouteId(_InvoiceDrawerActivity, 'paymentRequestLink');
const GuestPaymentRouterFlow = withRouteId(_GuestPaymentRouterFlow, 'paymentRequestLink');

type GuestPaymentWithAuthFlowProps = {
  partnerName?: PartnerName;
  onLoggedIn?: (accessToken: string, refreshToken?: string | null) => Promise<unknown>;
  onDone?: (id: string) => unknown;
  onBack?: VoidFunction;
  onClose?: VoidFunction;
  onError?: ARErrorFunction;
  isNavigateApUsersToDashboardEnabled?: boolean;
};

export const GuestPaymentWithAuthFlow = forwardRef<GuestPaymentWithAuthFlowProps>(
  ({ onLoggedIn, partnerName, isNavigateApUsersToDashboardEnabled }, ref) => {
    const { isOpen: isDrawerOpened, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
    const navigate = useNavigate();
    const goToUpdatedLink = (paymentRequestLink: string) =>
      navigate('pay/r/:id', { pathParams: { id: paymentRequestLink }, replace: true });

    return (
      <Routes>
        <Route
          path="pay/r/:id/*"
          element={
            <>
              <GuestPaymentRouterFlow
                partnerName={partnerName || 'melio'}
                onViewInvoice={onDrawerOpen}
                onUpdatedLink={goToUpdatedLink}
                onLoggedIn={onLoggedIn}
                ref={ref}
                isNavigateApUsersToDashboardEnabled={isNavigateApUsersToDashboardEnabled}
              />
              <InvoiceDrawerActivity
                isOpened={isDrawerOpened}
                onDownloadInvoice={(fileUrl) => {
                  window.location.href = fileUrl;
                }}
                onClose={onDrawerClose}
                ref={ref}
              />
            </>
          }
        />
      </Routes>
    );
  }
);
GuestPaymentWithAuthFlow.displayName = 'GuestPaymentWithAuthFlow';
