import { AddBankAccountMicroDepositsActivity } from '@melio/ap-activities';

import { useReceivingMethodActivityEvents } from '@/hooks/useReceivingMethodActivityEvents';

export const AddAchFromReceivingMethodFlowWrapper = () => {
  const { onBack, onDone, onClose } = useReceivingMethodActivityEvents();

  return (
    <AddBankAccountMicroDepositsActivity onBack={onBack} onClose={onClose} onDone={onDone} isReceivingMethodFlow />
  );
};
