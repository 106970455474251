import { useNavigate } from 'react-router-dom';

export const useBrazeNavigate = () => {
  const navigate = useNavigate();

  const route = (uri: string, openInNewTab: boolean) => {
    if (uri.startsWith('/')) {
      if (openInNewTab) {
        const fullUrl = `${window.location.origin}/melio${uri}`;
        return window.open(fullUrl);
      }
      return navigate(uri);
    }
    if (openInNewTab) {
      return window.open(uri);
    }

    return window.location.replace(uri);
  };

  return {
    route,
  };
};
