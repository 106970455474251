import { Container, GridItem, SectionBanner } from '@melio/penny';

import { useSubscriptionPromotions } from '../../../../hooks';

type GracePeriodBannersProps = {
  gridItemColSpan: number;
};

export const PlansPromotionBanners = ({ gridItemColSpan }: GracePeriodBannersProps) => {
  const { getPromotionBanner } = useSubscriptionPromotions();

  const promotionBanner = getPromotionBanner();

  if (promotionBanner) {
    return (
      <GridItem colSpan={gridItemColSpan}>
        <Container paddingBottom="s" width="full">
          <SectionBanner
            data-testid="subscription-promotion-banners"
            description={promotionBanner}
            variant="brand"
            data-role="alert"
            icon="crown"
            size="large"
          />
        </Container>
      </GridItem>
    );
  }

  return null;
};
