import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const CapOneIcon = (props: IconProps) => (
  <Icon width="101" height="100" viewBox="0 0 101 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_9022_432634)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.5503 100C78.1645 100 100.55 77.6142 100.55 50C100.55 22.3858 78.1645 0 50.5503 0C22.9361 0 0.550293 22.3858 0.550293 50C0.550293 77.6142 22.9361 100 50.5503 100Z"
        fill="#128020"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.1994 71.8L24.3994 57C23.8994 56.5 23.8994 55.7 24.3994 55.2C24.8994 54.7 25.6994 54.7 26.1994 55.2L40.0994 69.1L73.8994 30.2C74.3994 29.7 75.0994 29.6 75.6994 30.1C76.1994 30.6 76.2994 31.3 75.7994 31.9L41.0994 71.9C40.7994 72.1 40.4994 72.2 40.0994 72.2C39.7994 72.2 39.4994 72.1 39.1994 71.8Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_9022_432634">
        <rect width="101" height="100" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </Icon>
);
