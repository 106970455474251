import { Text, useBreakpointValue } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export const MandatoryFieldDisclaimer = () => {
  const { formatMessage } = useMelioIntl();
  const isMobile = useBreakpointValue({ xs: true, s: false });

  return (
    <Text
      color="global.neutral.900"
      textStyle={isMobile ? 'caption1' : 'body4'}
      data-testid="payment-flow-form-content-disclaimer"
    >
      {formatMessage('activities.paymentFlow.form.content.mandatoryFieldDisclaimer')}
    </Text>
  );
};
