import { useContext } from 'react';
import { Box, Stack } from '@chakra-ui/react';
import { Button, Link, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { SectionHeader } from '@melio/platform-utils';

import { SettingsCardIdEnum } from '@/cl/components/SettingsCard/SettingsCard.component';
import { usePartnerConfig } from '@/hooks/partners';
import { SettingsContext } from '@/screens/settings/SettingsProvider';
import { usePlatformIntl } from '@/translations/Intl';

export const PaymentsReportSection = () => {
  const { formatMessage } = usePlatformIntl();
  const { partnerConfig } = usePartnerConfig();
  const { track } = useAnalytics();
  const { openCreateReportModal } = useContext(SettingsContext);

  const handleCreateReportClick = () => {
    openCreateReportModal();
    track('Settings', 'Click', {
      PageName: SettingsCardIdEnum.CompanySettings,
      Cta: 'create-report',
    });
  };

  return (
    <Stack flexDirection="column" gridGap={'24px'} spacing={0}>
      <SectionHeader title={formatMessage('widgets.companySettings.paymentsReportSection.title')} titleAs="h3" />
      <Box>
        <Box mb={6}>
          <Text>
            {formatMessage('widgets.companySettings.paymentsReportSection.content', {
              helpLink: (
                <Link
                  variant="inline"
                  href={partnerConfig.config.links.helpCenter}
                  label={formatMessage('widgets.companySettings.paymentsReportSection.linkText')}
                  newTab
                />
              ),
            })}
          </Text>
        </Box>
        <Button
          variant="tertiary"
          onClick={handleCreateReportClick}
          label={formatMessage('widgets.companySettings.paymentsReportSection.btnText')}
          data-testid="create-report-btn"
        />
      </Box>
    </Stack>
  );
};
