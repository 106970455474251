import { Route, Routes, useParams } from 'react-router-dom';
import { AutoPaymentActivationFlowActivity, AutoPaymentEditFlowActivity } from '@melio/ap-activities';

export const AutoPaymentActivationRoute = () => {
  const { vendorId } = useParams<string>();

  if (!vendorId) return null;

  return (
    <Routes>
      <Route path="new/*" element={<AutoPaymentActivationFlowActivity vendorId={vendorId} />} />
      <Route path="edit/*" element={<AutoPaymentEditFlowActivity vendorId={vendorId} />} />
    </Routes>
  );
};
