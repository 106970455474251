import { TaxpayerIdentificationRequestStatusEnum, Vendor } from '@melio/platform-api';

export const useAnalyticsTaxIdType = (vendor?: Vendor) => {
  const isTaxPayerInfoExists = !!vendor?.taxpayerInfo;
  const taxPayerIdentificationRequestStatus = vendor?.lastTaxpayerIdentificationRequest?.status;
  const isPendingTaxPayerRequestStatus =
    taxPayerIdentificationRequestStatus === TaxpayerIdentificationRequestStatusEnum.Pending;

  if (isTaxPayerInfoExists) return 'file-attached';

  if (isPendingTaxPayerRequestStatus) return 'request-sent';

  if (vendor?.contractor) return 'email';

  return null;
};
