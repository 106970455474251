import React, { useRef } from 'react';
import { Box, useOutsideClick } from '@chakra-ui/react';
import { Collapse } from '@melio/penny';

import { FormSearch } from '@/cl/components/FormSearch/FormSearch.component';

type Props = {
  isExpanded: boolean;
  onOutsideClickHandler?: (event: Event) => void;
  onSearch: (query: string) => void;
  onSearchInput?: (query: string) => void;
  placeholder?: string;
  defaultValue?: string;
};

export const ExpandableFormSearch = ({
  isExpanded,
  onOutsideClickHandler,
  onSearch,
  onSearchInput,
  placeholder,
  defaultValue,
}: Props) => {
  const searchBarRef = useRef<HTMLInputElement>(null);

  useOutsideClick({
    ref: searchBarRef,
    handler: onOutsideClickHandler,
  });

  return (
    <Collapse
      in={isExpanded}
      onAnimationStart={() => {
        isExpanded && searchBarRef.current?.focus();
      }}
    >
      <Box mb={{ xs: '24px', s: '40px' }}>
        <FormSearch
          onSearch={onSearch}
          onSearchInput={onSearchInput}
          placeholder={placeholder}
          defaultValue={defaultValue}
          searchBarRef={searchBarRef}
        />
      </Box>
    </Collapse>
  );
};
