import { BrandSymbolKey, IconKey } from '@melio/penny';
import {
  DeliveryMethodByPayor,
  formatAddress,
  FreeChecksData,
  FundingSource,
  SupportedDeliveryMethodTypeOption,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';

import { useDeliveryMethodFee } from '../../../utils';

export function useDeliveryMethodCardHelpers(
  fundingSource?: FundingSource,
  freeChecks?: FreeChecksData,
  isDisabled?: boolean,
  disabledReason?: SupportedDeliveryMethodTypeOption['reason']
) {
  const { getDescription, isFeeFetched } = useDescription(fundingSource, freeChecks, isDisabled, disabledReason);
  const { getIconKey } = useIconKey();
  const { formatMessage } = useMelioIntl();

  return {
    getLabel: (deliveryMethod: DeliveryMethodByPayor, vendorName: string) => {
      switch (deliveryMethod.type) {
        case 'bank-account':
          return formatMessage('widgets.deliveryMethods.selectDeliveryMethodCard.bank-account.label', {
            name: vendorName,
            account: deliveryMethod.details.accountNumber.slice(-4),
          });

        case 'paper-check':
          return formatMessage('widgets.deliveryMethods.selectDeliveryMethodCard.paper-check.label', {
            name: deliveryMethod.details.printName,
            address: formatAddress(deliveryMethod.details.address),
          });

        case 'domestic-wire-account':
          return formatMessage('widgets.deliveryMethods.selectDeliveryMethodCard.domestic-wire-account.label', {
            name: vendorName,
            account: deliveryMethod.details.accountNumber.slice(-4),
          });

        case 'virtual-card':
          return formatMessage('widgets.deliveryMethods.selectDeliveryMethodCard.virtualCard.label', {
            email: deliveryMethod.details.accountEmail,
          });

        case 'international-account':
          return ''; //todo

        default:
          return formatMessage('widgets.deliveryMethods.selectDeliveryMethodCard.unilateral.label');
      }
    },
    getDescription,
    getIconKey,
    isLoading: !isFeeFetched,
  };
}

function useDescription(
  fundingSource?: FundingSource,
  freeChecks?: FreeChecksData,
  isDisabled?: boolean,
  disabledReason?: SupportedDeliveryMethodTypeOption['reason']
) {
  const { formatMessage } = useMelioIntl();
  const { getFee, isFetched: isFeeFetched } = useDeliveryMethodFee(fundingSource?.type);

  const getDisabledDescription = (deliveryMethodType: DeliveryMethodByPayor['type']): string | undefined => {
    if (!isDisabled) {
      return undefined;
    }
    const isDisabledDueToBankPayment = disabledReason === 'vendorIsBank';

    if (isDisabledDueToBankPayment) {
      switch (deliveryMethodType) {
        case 'bank-account':
        case 'virtual-account':
        case 'virtual-card':
        case 'international-account':
          return formatMessage('widgets.deliveryMethods.selectDeliveryMethodCard.disabledReason.bankRestriction');
      }
    }

    switch (deliveryMethodType) {
      case 'virtual-card':
        return formatMessage('widgets.deliveryMethods.selectDeliveryMethodCard.virtualCard.description.disabled');
      default:
        return undefined;
    }
  };

  return {
    getDescription: (deliveryMethod: DeliveryMethodByPayor) => {
      const disabledReason = getDisabledDescription(deliveryMethod.type);
      if (disabledReason) {
        return disabledReason;
      }

      const fee = getFee(deliveryMethod.type);
      switch (deliveryMethod.type) {
        case 'bank-account': {
          const feeMessagePart = fee ? 'withFee' : 'noFee';
          return formatMessage(
            `widgets.deliveryMethods.selectDeliveryMethodCard.bank-account.description.${feeMessagePart}`,
            {
              fee,
            }
          );
        }
        case 'paper-check':
          if (fee) {
            const freeChecksPromotionMessagePart = freeChecks?.total ? 'withChecksPromotion' : 'noChecksPromotion';

            return formatMessage(
              `widgets.deliveryMethods.selectDeliveryMethodCard.paper-check.description.withFee.${freeChecksPromotionMessagePart}`,
              { fee, monthlyFreeChecksPromotion: freeChecks?.total }
            );
          } else {
            return formatMessage(`widgets.deliveryMethods.selectDeliveryMethodCard.paper-check.description.noFee`);
          }
        case 'domestic-wire-account':
          return formatMessage('widgets.deliveryMethods.selectDeliveryMethodCard.domestic-wire-account.description', {
            fee,
          });
        case 'virtual-card':
          return formatMessage('widgets.deliveryMethods.selectDeliveryMethodCard.virtualCard.description');
        case 'international-account':
          return ''; //todo
        default:
          return formatMessage('widgets.deliveryMethods.selectDeliveryMethodCard.unilateral.description');
      }
    },
    isFeeFetched,
  };
}

const getDeliveryMethodIconKey = (
  type: DeliveryMethodByPayor['type'],
  icons: { [key: string]: BrandSymbolKey }
): IconKey | BrandSymbolKey => {
  switch (type) {
    case 'bank-account':
      return 'bank';
    case 'paper-check':
      return 'paper-check';
    case 'domestic-wire-account':
      return 'bank';
    case 'virtual-account':
      return 'email';
    case 'virtual-card':
      return icons['virtualCard'] as BrandSymbolKey;
    case 'international-account':
      return 'international';
  }
};

const useIconKey = () => {
  const { settings } = useConfig();

  return {
    getIconKey: (deliveryMethod: DeliveryMethodByPayor, selected?: boolean): IconKey | BrandSymbolKey =>
      selected ? 'checked' : getDeliveryMethodIconKey(deliveryMethod.type, settings.icons.deliveryMethod),
  };
};
