import { CatalogItem, useCatalogItems } from '@melio/ar-domain';

import { InvoiceFormValuesLineItem } from '../types';

export const useUpdateCatalogItems = () => {
  const { data = [], update, isMutating } = useCatalogItems();

  const updateCatalogItems = (lineItems: InvoiceFormValuesLineItem[]) =>
    Promise.all(
      lineItems
        .map((lineItem) => {
          const catalogItem = data.find((item) => item.id === lineItem.catalogItemId);
          if (!catalogItem) {
            throw new Error('Catalog item not found');
          }
          // new catalog item
          if (catalogItem.price === 0) {
            return update(catalogItem.id, { price: lineItem.price, taxable: lineItem.taxable });
          }
          return null;
        })
        .filter(Boolean) as Promise<CatalogItem>[]
    );

  return {
    updateCatalogItems,
    isSaving: isMutating,
  };
};
