import {
  BillingFeeSetting,
  FeeType,
  hasAchToCheckBillingFeeMethod,
  hasAchToCheckFeeType,
  PaymentFee,
  useFeeCatalog,
  useFreeChecks,
} from '@melio/platform-api';
import { FormattedMessage, useMelioIntl } from '@melio/platform-i18n';
import { sumBy, useLocation } from '@melio/platform-utils';

export type PaymentFeeDetails = {
  type: FeeType;
  amount: number;
  description: React.ReactNode;
  helperText: string;
};

const useFeeValueFormatting = () => {
  const { formatCurrency, formatPercents } = useMelioIntl();

  const { data: feeCatalogEntries, isLoading } = useFeeCatalog();

  return {
    formatFeeValue: (fee: PaymentFee) => {
      const feeCatalogEntry = feeCatalogEntries?.find((entry) => entry.feeType === fee.type);

      if (feeCatalogEntry) {
        return feeCatalogEntry.valueType === 'fixed'
          ? formatCurrency(feeCatalogEntry.value)
          : formatPercents(feeCatalogEntry.value, { divide: true });
      } else {
        return '';
      }
    },
    isLoading,
  };
};

const getFeeDescription = ({
  formatFeeValue,
  fee,
}: {
  formatFeeValue: (fee: PaymentFee) => string;
  fee: PaymentFee;
}): React.ReactNode => {
  if (
    fee.type === 'card' ||
    fee.type === 'check' ||
    fee.type === 'virtual' ||
    fee.type === 'push-to-debit' ||
    fee.type === 'overnight-check' ||
    fee.type === 'overnight-ach' ||
    fee.type === 'rtp' ||
    fee.type === 'international-fx'
  ) {
    return null;
  }

  return (
    <FormattedMessage
      id={`widgets.reviewAndConfirm.feesSection.feeTypes.${fee.type}`}
      values={{
        feeValue: formatFeeValue(fee),
      }}
    />
  );
};

export const usePaymentFee = (paymentFees: PaymentFee[]): PaymentFeeDetails[] | null => {
  const { formatMessage } = useMelioIntl();
  const { pathname } = useLocation();

  const { formatFeeValue, isLoading: isLoadingFormatting } = useFeeValueFormatting();

  const { data: feeCatalogEntries } = useFeeCatalog();

  const isChecksPromotionSupported = feeCatalogEntries?.some(
    (entry) => entry.feeType === 'ach-to-check' && entry.promotedFee
  );

  const isCheckPaymentFee = paymentFees.some((fee) => fee.type === 'ach-to-check');

  const shouldFetchFreeChecksPromotionData = isChecksPromotionSupported && isCheckPaymentFee;

  const { data: freeChecksData } = useFreeChecks({
    enabled: !!shouldFetchFreeChecksPromotionData,
  });

  if (!feeCatalogEntries || (shouldFetchFreeChecksPromotionData && !freeChecksData)) {
    return null;
  }

  // This is a real patch until we'll fix promotions on edit flows
  const isCreatePaymentFlow = !/.pymnt_\d+/.test(pathname);

  const results = paymentFees.map((fee) => {
    const showFreeChecksPromotionHelperText =
      isCreatePaymentFlow && fee.type === 'ach-to-check' && freeChecksData && freeChecksData.available > 0;

    return {
      type: fee.type,
      amount: fee.amount,
      description: isLoadingFormatting
        ? ''
        : getFeeDescription({
            formatFeeValue,
            fee,
          }),
      helperText: showFreeChecksPromotionHelperText
        ? formatMessage('widgets.reviewAndConfirm.feesSection.monthlyFreeChecksAvailableHelperText', {
            available: freeChecksData.available,
            total: freeChecksData.total,
          })
        : '',
    };
  });

  return results.filter(filterUnusedFeeTypes);
};

export const filterUnusedFeeTypes = ({ type }: PaymentFee) => type !== 'virtual';

export const useTotalFees = ({
  transactionFees,
  orgBillingFeeSettings,
  recurringLastAmountFees,
}: {
  transactionFees: { type: string; amount: number }[];
  orgBillingFeeSettings: BillingFeeSetting[];
  recurringLastAmountFees?: PaymentFee[];
}) => {
  const { formatMessage, formatCurrency } = useMelioIntl();

  const totalFeesAmount = sumBy(transactionFees, (fee) => fee.amount);
  const recurringLastAmountTotalFees = recurringLastAmountFees
    ? sumBy(recurringLastAmountFees, (fee) => fee.amount)
    : undefined;

  if (recurringLastAmountTotalFees && recurringLastAmountTotalFees !== totalFeesAmount) {
    return {
      amount: formatCurrency(totalFeesAmount),
      description: formatMessage('widgets.reviewAndConfirm.feesSection.totalFeesDescription.recurringLastAmount', {
        feeValue: formatCurrency(recurringLastAmountTotalFees),
      }),
    };
  }

  if (!totalFeesAmount) {
    return {
      amount: formatCurrency(totalFeesAmount),
      description: formatMessage('widgets.reviewAndConfirm.feesSection.noFeesDescription'),
    };
  }

  const isRtpFeeType = transactionFees.some((fee) => fee.type === FeeType.RtpOrganization);

  if (isRtpFeeType) {
    return {
      amount: formatCurrency(totalFeesAmount),
      description: formatMessage('widgets.reviewAndConfirm.feesSection.totalFeesDescription.rtp-organization'),
    };
  }

  const orgHasBillingFeeMethod = hasAchToCheckBillingFeeMethod(orgBillingFeeSettings);
  const hasAchToCheckFees = hasAchToCheckFeeType(transactionFees);

  if (orgHasBillingFeeMethod && hasAchToCheckFees) {
    return {
      amount: formatCurrency(totalFeesAmount),
      description: formatMessage('widgets.reviewAndConfirm.feesSection.totalFeesDescription.billingMethodAvailable'),
    };
  }

  return {
    amount: formatCurrency(totalFeesAmount),
    description: formatMessage('widgets.reviewAndConfirm.feesSection.totalFeesDescription'),
  };
};
