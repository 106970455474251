import {
  FormDialog,
  FormDialogWidgetProps,
  FormInputs,
  maxEntityNameLength,
  maxTaxPercentage,
  useMelioIntl,
} from '@melio/ar-domain';
import { Form, Group, SelectOption, Typography, useMelioForm } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { useEffect, useMemo } from 'react';
import { boolean, number, object, SchemaOf, string } from 'yup';

import { TaxRateSelectWidget } from '../components';
import { AddTaxRateFormFields } from '../types';

const useSchema = () => {
  const { formatMessage } = useMelioIntl();

  const schema = object().shape({
    id: string()
      .nullable()
      .required(formatMessage('ar.modals.activities.taxRate.formDialog.fields.name.validation.required'))
      .min(2, formatMessage('ar.modals.activities.taxRate.formDialog.fields.name.validation.min')),
    rate: number()
      .nullable()
      .typeError(formatMessage('ar.modals.activities.taxRate.formDialog.fields.rate.validation.required'))
      .moreThan(0.001, formatMessage('ar.modals.activities.taxRate.formDialog.fields.rate.validation.min'))
      .lessThan(maxTaxPercentage, formatMessage('ar.modals.activities.taxRate.formDialog.fields.rate.validation.max'))
      .required(formatMessage('ar.modals.activities.taxRate.formDialog.fields.rate.validation.required')),
    isDefault: boolean(),
  }) as SchemaOf<AddTaxRateFormFields>;

  return schema;
};

export type TaxRateModalScreenProps = FormDialogWidgetProps<
  AddTaxRateFormFields,
  {
    selectOptions: SelectOption[];
    onChangeSelectedTaxRate: (selectedId: string) => void;
    onCreateTaxRate: (name: string) => string;
  }
>;

export const TaxRateModalScreen = forwardRef<TaxRateModalScreenProps>(
  ({ isSaving, selectOptions, onSubmit, onCreateTaxRate, onChangeSelectedTaxRate, defaultValues, ...props }, ref) => {
    const { registerField, setError, clearErrors, reset, ...useFormResults } = useMelioForm<AddTaxRateFormFields>({
      onSubmit,
      schema: useSchema(),
      defaultValues: useMemo(() => ({ rate: '' as never, ...defaultValues }), [defaultValues]),
      isSaving,
      subscribeToDefaultValuesChanges: true,
    });

    const handleCreateTaxRate = (name: string) => {
      if (name.length > maxEntityNameLength) {
        setError('id', {
          message: formatMessage('ar.modals.activities.taxRate.formDialog.fields.name.validation.max', {
            maxLength: maxEntityNameLength,
          }),
        });
        return '';
      }
      clearErrors('id');
      return onCreateTaxRate(name);
    };

    const { formatMessage } = useMelioIntl();

    useEffect(() => reset, [props.isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <FormDialog
        header={formatMessage('ar.modals.activities.taxRate.formDialog.header.text')}
        primaryButton={{
          label: formatMessage('ar.modals.activities.taxRate.formDialog.buttons.confirm.label'),
        }}
        secondaryButton={{
          label: formatMessage('ar.modals.activities.taxRate.formDialog.buttons.cancel.label'),
        }}
        useFormResults={useFormResults}
        data-testid="add-tax-rate-modal-screen"
        {...props}
        ref={ref}
      >
        <TaxRateSelectWidget
          labelProps={{
            label: formatMessage('ar.modals.activities.taxRate.formDialog.fields.name.label'),
          }}
          placeholder={formatMessage('ar.modals.activities.taxRate.formDialog.fields.name.placeholder')}
          colSpan={2}
          onCreateOption={handleCreateTaxRate}
          onChangeOption={onChangeSelectedTaxRate}
          selectOptions={selectOptions}
          {...registerField('id')}
        />
        <FormInputs.PercentageField
          labelProps={{
            label: formatMessage('ar.modals.activities.taxRate.formDialog.fields.rate.label'),
          }}
          decimalLimit={3}
          placeholder={formatMessage('ar.modals.activities.taxRate.formDialog.fields.rate.placeholder')}
          {...registerField('rate')}
        />
        <Group variant="vertical">
          <Typography.Label label="&nbsp;" /> {/* This is a hack to align the checkbox with the text field */}
          <Form.Checkbox
            label={formatMessage('ar.modals.activities.taxRate.formDialog.fields.isDefault.label')}
            aria-label={formatMessage('ar.modals.activities.taxRate.formDialog.fields.isDefault.label')}
            {...registerField('isDefault')}
          />
        </Group>
      </FormDialog>
    );
  }
);
