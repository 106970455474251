import {
  CountryInternationalDeliveryDetails,
  InternationalAccountBicSwift,
  InternationalAccountIban,
} from '@melio/platform-api';
import lookup from 'country-code-lookup';

import { Subset } from './types';

export const getCountryCode = (countryCodeOrName: string) => {
  if (!countryCodeOrName) {
    return undefined;
  }

  if (countryCodeOrName.length === 2) {
    return lookup.byIso(countryCodeOrName)?.iso2;
  }

  return lookup.byCountry(countryCodeOrName)?.iso2;
};

const isIbanData = (
  deliveryMethodCreateData: Subset<InternationalAccountIban> | Subset<InternationalAccountBicSwift>
): deliveryMethodCreateData is Subset<InternationalAccountIban> => deliveryMethodCreateData?.identifierType === 'iban';

const isBicSwiftData = (
  deliveryMethodCreateData: Subset<InternationalAccountIban> | Subset<InternationalAccountBicSwift>
): deliveryMethodCreateData is Subset<InternationalAccountBicSwift> =>
  deliveryMethodCreateData?.identifierType === 'bicSwift';

export const getAddInternationalDeliveryMethodDefaultValues = ({
  deliveryMethodCreateData,
  defaultCountryDetails,
  vendorCountryName,
}: {
  deliveryMethodCreateData: Subset<InternationalAccountIban> | Subset<InternationalAccountBicSwift>;
  defaultCountryDetails?: CountryInternationalDeliveryDetails;
  vendorCountryName?: string;
}) => {
  const bankDetailsDefaultValues = {
    selectedInternationalCountries: defaultCountryDetails,
    bankName: deliveryMethodCreateData.identifierDetails?.bankName,
    ...(isIbanData(deliveryMethodCreateData)
      ? {
          ibanCode: deliveryMethodCreateData.identifierDetails?.iban,
        }
      : isBicSwiftData(deliveryMethodCreateData)
      ? {
          swiftCode: deliveryMethodCreateData.identifierDetails?.bicSwift,
          accountNumber: deliveryMethodCreateData.identifierDetails?.accountNumber,
        }
      : {}),
  };

  const vendorDetailsDefaultValues = {
    ...deliveryMethodCreateData?.address,
    legalBusinessName: deliveryMethodCreateData?.companyName,
    fullName: deliveryMethodCreateData?.identifierDetails?.bankAccountHolderName,
    country: vendorCountryName,
  };

  return {
    bankDetailsDefaultValues,
    vendorDetailsDefaultValues,
  };
};
