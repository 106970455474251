import { AvatarProps } from '@melio/penny';

// This hash algorithm shouldn't be modified until we have legacy running - for consistancy
export function getNameHashCode(str: string) {
  if (!str || str.length === 0) {
    return 0;
  }

  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    const chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }

  hash = Math.abs(hash);

  return hash;
}

const avatarColors: Required<AvatarProps>['bgColor'][] = [
  'avatar1',
  'avatar2',
  'avatar3',
  'avatar4',
  'avatar5',
  'avatar6',
];

const avatarColorsCompatibleWithLegacy = avatarColors.slice(0, 5);

type GetAvatarBgColorCompatibleWithLegacyProps = {
  name: string;
  isDefault?: boolean;
};

export function getAvatarBgColorCompatibleWithLegacy({ name, isDefault }: GetAvatarBgColorCompatibleWithLegacyProps) {
  if (isDefault) {
    return undefined;
  }

  const index = getNameHashCode(name) % avatarColorsCompatibleWithLegacy.length;
  return avatarColorsCompatibleWithLegacy[index];
}
