import { useMelioIntl } from '@melio/platform-i18n';
import { useSetDocumentTitle } from '@melio/platform-sdk';

import { usePayDashboardTabs } from './usePayDashboardTabs';

export const useSetPayDashboardDocumentTitle = () => {
  const { formatMessage } = useMelioIntl();

  const { selectedTab } = usePayDashboardTabs();
  const tabLabel = formatMessage(`activities.payDashboard.tabs.${selectedTab}`);

  useSetDocumentTitle(tabLabel);
};
