import { isPOBox } from '@melio/ap-domain';
import { AddressSearchWidget, FormWidgetProps } from '@melio/ap-widgets';
import { Form, useMelioForm } from '@melio/penny';
import { AccountAddressParams } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { boolean, object, SchemaOf, string } from 'yup';

export type CompanyDetailsFormWidgetFields = {
  name: string;
  aptNumber?: string;
  address: AccountAddressParams;
  isOwner?: boolean;
};

export type CompanyDetailsFormWidgetProps = FormWidgetProps<CompanyDetailsFormWidgetFields> & {
  defaultFields?: Partial<CompanyDetailsFormWidgetFields>;
};

export const useSchema = () => {
  const { formatMessage } = useMelioIntl();

  const addressSchema = object({
    line1: string().required(),
    line2: string().notRequired(),
    city: string().required(),
    state: string().required(),
    postalCode: string().required(),
  })
    .nullable()
    .default(null)
    .test(
      'isNotPoBox',
      formatMessage(`activities.addCompany.screens.companyDetails.form.address.validation.poBox`),
      (address) => !isPOBox(address?.line1)
    )
    .required(
      formatMessage('activities.addCompany.screens.companyDetails.form.address.validation.required')
    ) as SchemaOf<AccountAddressParams | null>;

  return object().shape({
    name: string()
      .nullable()
      .required(formatMessage('activities.addCompany.screens.companyDetails.form.name.validation.required')),
    aptNumber: string().nullable(),
    address: addressSchema,
    isOwner: boolean().default(true),
  }) as SchemaOf<CompanyDetailsFormWidgetFields>;
};

export const CompanyDetailsFormWidget = ({
  onSubmit,
  isSaving,
  onSubmissionStateChange,
  defaultFields,
  ...props
}: CompanyDetailsFormWidgetProps) => {
  const defaultValues = {
    name: defaultFields?.name || '',
    address: defaultFields?.address ?? undefined,
    aptNumber: defaultFields?.aptNumber || '',
    isOwner: defaultFields?.isOwner ?? true,
  };

  const { formatMessage } = useMelioIntl();

  const { formProps, registerField } = useMelioForm<CompanyDetailsFormWidgetFields>({
    onSubmit,
    schema: useSchema(),
    defaultValues,
    isSaving,
    onSubmissionStateChange,
    subscribeToDefaultValuesChanges: true,
  });

  return (
    <Form data-component="CompanyDetailsFormWidget" {...props} {...formProps}>
      <Form.TextField
        labelProps={{ label: formatMessage('activities.addCompany.screens.companyDetails.form.name.label') }}
        helperTextProps={{ label: formatMessage('activities.addCompany.screens.companyDetails.form.name.hint') }}
        {...registerField('name')}
      />
      <AddressSearchWidget
        {...registerField('address')}
        labelProps={{ label: formatMessage('activities.addCompany.screens.companyDetails.form.address.label') }}
      />
      <Form.TextField
        isRequired={false}
        labelProps={{ label: formatMessage('activities.addCompany.screens.companyDetails.form.aptNumber.label') }}
        {...registerField('aptNumber')}
      />
      <Form.Checkbox
        label={formatMessage('activities.addCompany.screens.companyDetails.form.companyOwner.label')}
        aria-label={formatMessage('activities.addCompany.screens.companyDetails.form.companyOwner.label')}
        {...registerField('isOwner')}
      />
    </Form>
  );
};

CompanyDetailsFormWidget.displayName = 'CompanyDetailsFormWidget';
