import { Button, Grid, Illustration, Modal, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { useIsSubscriptionsEnabled, usePlanInfo, useSubscription } from '../../../../../hooks';

export type SubscriptionCancelSuccessModalScreenProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const SubscriptionCancelSuccessModalScreen = ({
  isOpen,
  onClose,
}: SubscriptionCancelSuccessModalScreenProps) => {
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const subscription = useSubscription();
  const { formatMessage, formatDate } = useMelioIntl();

  const formattedNextSubscriptionDate = formatDate(subscription?.nextSubscription?.startDate, {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  const { planName: newPlanName } = usePlanInfo(subscription?.nextSubscription?.planId);

  const isFreeTrial = subscription?.isFreeTrial;

  const description = formatMessage(
    isFreeTrial
      ? 'activities.subscription.cancelSuccessModal.descriptionWhileOnFreeTrial'
      : 'activities.subscription.cancelSuccessModal.description',
    {
      formattedNextSubscriptionDate,
      newPlanName,
    }
  );

  if (!isSubscriptionsEnabled) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      footer={undefined}
      header={formatMessage('activities.subscription.cancelSuccessModal.title')}
      data-testid="subscription-cancel-success-modal-screen"
    >
      <Grid gap="s" gridTemplateColumns="auto auto">
        <Text textStyle="body2">{description}</Text>
        <Illustration type="together" size="large" />
      </Grid>

      <Button
        label={formatMessage('activities.subscription.cancelSuccessModal.primaryButton')}
        isFullWidth
        onClick={onClose}
      />
    </Modal>
  );
};
