import { Box } from '@chakra-ui/react';
import { Container, Divider, StatusBar } from '@melio/penny';

import { usePlatformIntl } from '@/translations/Intl';
import { MarkedAsPaidSection } from '@/widgets/pay-dashboard/payment-details/components/general/MarkedAsPaidSection';
import { PaymentDetailsProps } from '@/widgets/pay-dashboard/payment-details/PaymentDetails.types';
import { usePaymentScheduledByProps } from '../../hooks/usePaymentScheduledByProps';
import { PayFromSectionRefund } from '../general/PayFromSectionRefund';
import { PaymentDetailsSection } from '../general/PaymentDetailsSection';
import { PaymentDetailsTop } from '../general/PaymentDetailsTop';
import { PaymentScheduledBySection } from '../general/PaymentScheduledBySection';
import { PaymentTimelineTrackerSection } from '../general/PaymentTimelineTracker/PaymentTimelineTrackerSection';
import { RefundCompletedSectionBanner } from './RefundCompletedSectionBanner';

export const RefundCompleted = ({ payment, isActionsEnabled }: PaymentDetailsProps) => {
  const { formatMessage } = usePlatformIntl();
  const { showPaymentScheduledBySection, ...props } = usePaymentScheduledByProps(payment);

  return (
    <>
      <StatusBar variant={'success'} content={formatMessage('widgets.paymentDetails.completedRefund.title')} />

      <Box py="l" data-testid="pay-dashboard-payment-completed">
        <PaymentTimelineTrackerSection payment={payment}>
          <Container paddingY="l">
            <Divider />
          </Container>
        </PaymentTimelineTrackerSection>

        <PaymentDetailsSection gridGap="m">
          <RefundCompletedSectionBanner payment={payment} />
          <PaymentDetailsTop payment={payment} isActionsEnabled={isActionsEnabled} />
        </PaymentDetailsSection>
        {showPaymentScheduledBySection && (
          <PaymentDetailsSection gridGap="m">
            <PaymentScheduledBySection {...props} />
          </PaymentDetailsSection>
        )}
        <PaymentDetailsSection gridGap="m">
          {payment.markedAsPaid ? (
            <MarkedAsPaidSection markedAsPayBy={payment.createdBy!} scheduledDate={payment.scheduledDate} />
          ) : (
            <PayFromSectionRefund
              fundingSource={payment.fundingSource}
              initiatedDate={payment.processedTimeline?.refundInitiated}
            />
          )}
        </PaymentDetailsSection>
      </Box>
    </>
  );
};
