import { BankAccountFormModel } from '@melio/ap-widgets';
import { useToast } from '@melio/penny';
import { Traits, useAnalytics } from '@melio/platform-analytics';
import { DeliveryMethod, FundingSource, useDeliveryMethod, useFundingSources } from '@melio/platform-api';
import uniq from 'lodash/uniq';
import { useState } from 'react';

type Props = {
  isReceivingMethodFlow?: boolean;
  updateDeliveryMethodId?: string;
  onError?: ErrorFunction;
};

export const useAddBankAccountMicroDeposits = ({ isReceivingMethodFlow, updateDeliveryMethodId, onError }: Props) => {
  const [fundingSource, setFundingSource] = useState<FundingSource>();
  const [deliveryMethod, setDeliveryMethod] = useState<DeliveryMethod>();

  const collection = useFundingSources({ enabled: false });

  const {
    data: deliveryMethodData,
    create: addBankAccount,
    update: updateBankAccount,
    isMutating,
  } = useDeliveryMethod({ id: updateDeliveryMethodId, enabled: false });
  const { toast } = useToast();

  const { createTrackHandler, setTraits } = useAnalytics();

  const handleFail = (error: PlatformError) => {
    toast({ type: 'error', title: error.message });
    onError?.(error);
  };

  const onSuccess = (data: BankAccountFormModel) => {
    const trackSubmitted = createTrackHandler<{ Status: 'succeeded' | 'failed' }>('VerifyDepositDetails', 'Submitted');
    const setFundingSourceTraits = async () => {
      const traits: Traits = { added_funding: true };
      const result = await collection.refetch();

      if (result.data?.length && result.data.length > 0) {
        const fundingSourcesTypes = uniq(result.data?.map((fundingSource) => fundingSource.type)).join(',');
        traits['funding_sources_types'] = fundingSourcesTypes;
      }

      setTraits(traits);
    };
    const setDeliveryMethodTraits = () => {
      const traits: Traits = { added_delivery_method: true };

      setTraits(traits);
    };
    if (isReceivingMethodFlow && deliveryMethodData?.id) {
      updateBankAccount({
        type: 'bank-account',
        details: {
          accountNumber: data.accountNumber,
          accountType: 'checking',
          routingNumber: data.routingNumber,
        },
      })
        .then((deliveryMethod) => {
          trackSubmitted({ Status: 'succeeded' });
          setDeliveryMethod(deliveryMethod);
        })
        .catch((err) => {
          trackSubmitted({ Status: 'failed' });
          handleFail(err);
        });
    } else if (isReceivingMethodFlow && !deliveryMethodData?.id) {
      addBankAccount({
        type: 'bank-account',
        details: {
          accountNumber: data.accountNumber,
          accountType: 'checking',
          routingNumber: data.routingNumber,
        },
      })
        .then((deliveryMethod) => {
          trackSubmitted({ Status: 'succeeded' });
          setDeliveryMethodTraits();
          setDeliveryMethod(deliveryMethod);
        })
        .catch((err) => {
          trackSubmitted({ Status: 'failed' });
          handleFail(err);
        });
    } else {
      collection
        .create({
          type: 'bank-account',
          details: {
            accountNumber: data.accountNumber,
            accountType: 'checking',
            routingNumber: data.routingNumber,
          },
        })
        .then((fundingSource) => {
          trackSubmitted({ Status: 'succeeded' });
          setFundingSourceTraits();
          setFundingSource(fundingSource);
        })
        .catch((err) => {
          trackSubmitted({ Status: 'failed' });
          handleFail(err);
        });
    }
  };

  return {
    fundingSource,
    deliveryMethod,
    onSuccess,
    isMutating: isMutating || collection.isMutating,
  };
};
