import { useRef, useState } from 'react';
import { FileInfo, useFileOCRData, useFiles } from '@melio/platform-api';

export const useUploadFile = () => {
  const { create, isMutating: isUploading } = useFiles();
  const isChangedRef = useRef<boolean>();
  const [fileInfo, setFileInfo] = useState<FileInfo | null>();
  const { data: billDetails, isLoading } = useFileOCRData({ id: fileInfo?.id });

  const upload: typeof create = (...args) =>
    create(...args).then((data) => {
      isChangedRef.current = true;
      setFileInfo(data);
      return data;
    });

  const reset = () => {
    isChangedRef.current = true;
    setFileInfo(null);
  };

  return {
    upload,
    reset,
    isModified: !!isChangedRef.current,
    isUploading: isUploading || isLoading,
    data: fileInfo && { fileInfo, billDetails },
  };
};
