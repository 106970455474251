/* eslint-disable react-hooks/exhaustive-deps */
import { useToast } from '@melio/penny';
import { Traits, useAnalytics, useAnalyticsContext } from '@melio/platform-analytics';
import {
  Card,
  CardHolderDetails,
  CardNetwork,
  CardParams,
  CardType,
  FundingSource,
  useFundingSource,
  useFundingSources,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useBoolean } from '@melio/platform-utils';
import uniq from 'lodash/uniq';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useReconciliationModal } from '../../FundingSourceSelection';

export type UseAddCardFundingSourceActivityCallbacksProps = {
  vaulting: 'TBT' | 'Tabapay';
  cardType?: CardType;
  onError?: ErrorFunction;
  cardDetailsParams?: CardParams;
  onDone: (fundingSource: FundingSource) => void;
};

export const useAddCardFundingSourceActivityCallbacks = ({
  vaulting,
  cardType,
  onError,
  cardDetailsParams,
  onDone,
}: UseAddCardFundingSourceActivityCallbacksProps) => {
  const collection = useFundingSources({ enabled: false });
  const { toast } = useToast();
  const [isCardError, cardError] = useBoolean(false);
  const [fundingSourceId, setFundingSourceId] = useState<FundingSource['id']>();

  const [isDone, setIsDone] = useState(false);

  const { formatMessage } = useMelioIntl();

  const { data: fundingSource } = useFundingSource({ id: fundingSourceId });
  useAnalyticsContext({ globalProperties: { CardType: cardType, Vaulting: vaulting } });
  const {
    shouldRunReconciliation,
    shouldShowReconciliationModal,
    onCloseReconciliationFlow,
    onDoneReconciliationFlow,
    runReconciliationFlow,
  } = useReconciliationModal();

  const { createTrackHandler, setTraits, trackMarketing } = useAnalytics();
  const trackRequest = createTrackHandler<{ Status: 'succeeded' | 'failed'; CardNetwork: CardNetwork }>(
    'AddCCHolderDetails',
    'Submitted'
  );
  const setFundingSourceTraits = async () => {
    const traits: Traits = { added_funding: true };
    const result = await collection.refetch();

    if (result.data?.length && result.data.length > 0) {
      const fundingSourcesTypes = uniq(result.data?.map((fundingSource) => fundingSource.type)).join(',');
      traits['funding_sources_types'] = fundingSourcesTypes;
    }

    setTraits(traits);
  };

  const handleFail = (error: PlatformError) => {
    toast({ type: 'error', title: error.message });
    onError?.(error);
  };

  const showVerificationErrorDialog = (error: PlatformError) => {
    cardError.on();
    onError?.(error);
  };

  const handleDone = useCallback(
    (fundingSource: FundingSource) => {
      toast({
        type: 'success',
        title: formatMessage('activities.addCardFundingSource.screens.cardHolderDetails.successMessageTitle'),
      });
      onDone(fundingSource);
      setIsDone(true);
    },
    [toast, onDone]
  );

  const isReconciliationNeeded = useMemo(() => shouldRunReconciliation(fundingSource), [fundingSource]);

  const handleCloseReconciliationFlow = (onClose: VoidFunction) => () => {
    onCloseReconciliationFlow(onClose);
    if (!fundingSource?.id) {
      return;
    }
    handleDone(fundingSource);
  };

  useEffect(() => {
    if (!fundingSource?.id || isDone) {
      return;
    }

    if (isReconciliationNeeded) {
      runReconciliationFlow();
    } else {
      handleDone(fundingSource);
    }
  }, [fundingSource, isReconciliationNeeded, handleDone, isDone]);

  const handleSubmit = (data: CardHolderDetails) => {
    if (cardDetailsParams) {
      const { firstName, lastName, ...address } = data;
      const details = { ...cardDetailsParams, address, cardOwner: { firstName, lastName } };
      collection
        .create({ type: 'card', details })
        .then(({ id, details }) => {
          const cardNetwork = (details as Card)?.network;
          trackRequest({ Status: 'succeeded', CardNetwork: cardNetwork });
          trackMarketing('add-card_done-adding-new-card', { Status: 'succeeded', CardNetwork: cardNetwork });
          setFundingSourceTraits();
          setFundingSourceId(id);
        })
        .catch((...args) => trackRequest({ Status: 'failed' }, () => handleFail(...args)));
    }
  };

  const isCardValidationModalOpen = isCardError;
  const onCardValidationModalDone = cardError.off;

  return {
    showVerificationErrorDialog,
    handleSubmit,
    isCardValidationModalOpen,
    onCardValidationModalDone,
    reconciliation: {
      shouldShowReconciliationModal,
      onCloseReconciliationFlow: handleCloseReconciliationFlow,
      onDoneReconciliationFlow,
      isReconciliationNeeded,
    },
    fundingSourceId,
  };
};
