import { useAccountingPlatformName } from '@melio/ap-widgets';
import { useAccountingPlatforms } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';

type ErrorLinkBankAccountProps = {
  onClose: VoidFunction;
};

export const ErrorLinkBankAccount = forwardRef<ErrorLinkBankAccountProps, 'div'>(({ onClose, ...props }, ref) => {
  const { formatMessage } = useMelioIntl();

  const { activeAccountingPlatform, isLoading: isActiveAccountingPlatformLoading } = useAccountingPlatforms();

  const accountingPlatformName: string = useAccountingPlatformName(activeAccountingPlatform?.accountingSlug);

  return (
    <NewSinglePaymentStepLayout
      data-component="LinkBankAccount"
      data-testid="link-bank-account-error-screen"
      isLoading={isActiveAccountingPlatformLoading}
      {...props}
      ref={ref}
      headerContent={
        <NewSinglePaymentStepLayout.Header>
          <NewSinglePaymentStepLayout.CloseButton onClick={onClose} />
        </NewSinglePaymentStepLayout.Header>
      }
    >
      <NewSinglePaymentStepLayout.Title>
        {formatMessage('activities.FirstBankAccount.screens.isFirstBankAccount.title', {
          platformName: accountingPlatformName,
        })}
      </NewSinglePaymentStepLayout.Title>
      <NewSinglePaymentStepLayout.Description>
        {formatMessage('activities.linkBankAccount.screens.linkBankAccount.description', {
          platformLabel: accountingPlatformName,
        })}
      </NewSinglePaymentStepLayout.Description>
    </NewSinglePaymentStepLayout>
  );
});
