import { Route, Routes } from 'react-router-dom';
import {
  ContextualOnboardingActivity,
  EbillsImportRedirectStateEntryPoint,
  SuccessfulQboConnectActivity,
  SyncFlowEnum,
} from '@melio/ap-activities';

import { WithLoading } from '@/hoc/withLoading.hoc';
import { usePaymentFlowIntuitConnect } from '@/hooks/accountingPlatforms.hooks';
import { useRouter } from '@/hooks/router.hooks';
import { useGmailConnect } from '@/hooks/useGmailSync';

export const ContextualOnboardingRoute = () => {
  const { goToPayDashboard, generateJustPayLink } = useRouter();
  const { connectAccountingPlatformCustomErrorMessage, isLoginToAccountPlatformAuthLoading } =
    usePaymentFlowIntuitConnect({ flowToReturn: SyncFlowEnum.ContextualOnboarding });
  const { connect: handleGmailSyncConnect, isLoading: isLoginToGmailLoading } = useGmailConnect({
    entryPoint: EbillsImportRedirectStateEntryPoint.Onboarding,
  });

  return (
    <WithLoading isLoading={isLoginToAccountPlatformAuthLoading || isLoginToGmailLoading}>
      <Routes>
        <Route path="/successful-qbo-connect" element={<SuccessfulQboConnectActivity onClick={goToPayDashboard} />} />
        <Route
          path="/*"
          element={
            <ContextualOnboardingActivity
              onQuickBooksOnlineClick={connectAccountingPlatformCustomErrorMessage}
              onGmailSyncClick={handleGmailSyncConnect}
              onFirstPaymentClick={generateJustPayLink}
              onExitClick={goToPayDashboard}
            />
          }
        />
      </Routes>
    </WithLoading>
  );
};
