import Big from 'big.js';

import { usePlansTiers } from './usePlansTiers';

export const useCyclesPricing = () => {
  const { getFirstPaidPlan } = usePlansTiers();
  const firstPaidPlan = getFirstPaidPlan();

  const getAnnualPlanSavingPercents = () => {
    // saving is the same for all plans
    if (!firstPaidPlan?.cycles.annual) {
      return;
    }

    const monthlyPlanPricePerYearBig = new Big(firstPaidPlan.cycles.monthly.basePrice).mul(12);
    const annualPlanPricePerYearBig = new Big(firstPaidPlan.cycles.annual.basePrice);
    const absoluteDiffBig = monthlyPlanPricePerYearBig.sub(annualPlanPricePerYearBig);
    return absoluteDiffBig.div(monthlyPlanPricePerYearBig).mul(100).round().toNumber();
  };

  return { getAnnualPlanSavingPercents };
};
