import { DomesticWireFormFields } from '@melio/ap-widgets';
import { useToast } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { DomesticWireDeliveryMethod, useDeliveryMethod, useDeliveryMethods, useVendor } from '@melio/platform-api';

import { EditVendorDomesticWireDetailsScreen } from './screens';
import { EditVendorDomesticWireDetailsActivityProps } from './types';

export const EditVendorDomesticWireDetailsActivity = withAnalyticsContext<EditVendorDomesticWireDetailsActivityProps>(
  ({ deliveryMethodId, vendorId, onBack: onFirstStepBack, onClose, onError, onDone }) => {
    const deliveryMethod = useDeliveryMethod({ id: deliveryMethodId });
    const deliveryMethodCollection = useDeliveryMethods({ vendorId });
    const vendorModel = useVendor({ id: vendorId });
    const currentDetails = deliveryMethod.data?.details as DomesticWireDeliveryMethod['details'];
    const { toast } = useToast();

    const handleFail = (error: PlatformError) => {
      toast({ type: 'error', title: error.message });
      onError?.(error);
    };

    const { createTrackHandler } = useAnalytics();
    const submitHandler = createTrackHandler<{
      Status: 'failed' | 'succeeded';
      DeliveryMethodChosen: 'domestic-wire';
    }>('DeliveryMethodEdited', 'Saved', { DeliveryMethodChosen: 'domestic-wire' });

    const updateDeliveryMethod = (data: DomesticWireFormFields) => {
      deliveryMethodCollection
        .create({
          type: 'domestic-wire-account',
          details: {
            accountNumber: data.accountNumber,
            routingNumber: data.routingNumber,
            address: {
              city: data.city,
              state: data.state,
              postalCode: data.postalCode,
            },
          },
        })
        .then((data) => submitHandler({ Status: 'succeeded' }, () => onDone(data as DomesticWireDeliveryMethod)))
        .catch((...args) => submitHandler({ Status: 'failed' }, () => handleFail(...args)));
    };

    const accountDetails = deliveryMethod.data;
    const hasFailedPayments = accountDetails?.hasFailedPayments;

    // The form's structure is different from the delivery method's structure
    const formCurrentDetails = {
      accountNumber: currentDetails?.accountNumber,
      routingNumber: currentDetails?.routingNumber,
      city: currentDetails?.address?.city,
      state: currentDetails?.address?.state,
      postalCode: currentDetails?.address?.postalCode,
    };

    return (
      <EditVendorDomesticWireDetailsScreen
        isLoading={deliveryMethod.isLoading || vendorModel.isLoading}
        defaultValues={hasFailedPayments ? undefined : formCurrentDetails}
        onBack={onFirstStepBack}
        onClose={onClose}
        onDone={updateDeliveryMethod}
        isSaving={deliveryMethodCollection.isMutating}
        vendorName={vendorModel.data?.name || ''}
      />
    );
  }
);

EditVendorDomesticWireDetailsActivity.displayName = 'EditVendorDomesticWireDetailsActivity';
