import { useBankAccountFundingSources, usePlaid, useReceivingMethods } from '@melio/ar-domain';
import { useToast } from '@melio/penny';
import { PlaidAccountData } from '@melio/platform-api';
import { forwardRef, useBoolean } from '@melio/platform-utils';
import { useEffect, useMemo, useState } from 'react';

import { AddReceivingMethodModalScreen, ImportReceivingMethodModalScreen } from './screens';

export type AddReceivingMethodActivityProps = {
  onClose: VoidFunction;
  onError?: ARErrorFunction;
  onDone?: () => Promise<unknown>;
  isOpen: boolean;
};

export const AddReceivingMethodModalActivity = forwardRef<AddReceivingMethodActivityProps>(
  ({ onError, onDone, isOpen, onClose, ...props }, ref) => {
    const [isPlaidOpen, plaidDialog] = useBoolean(false);
    const { createFromFundingSource, create, isMutating } = useReceivingMethods({ enabled: false, onError });

    const onPlaidSuccess = ({ accounts: [account], public_token: plaidToken }: PlaidAccountData) => {
      exit();
      void create({ type: 'plaid', details: { plaidAccountId: account?.id as string, plaidToken } }).then(onDone);
    };

    const { open, ready, exit } = usePlaid({
      onSuccess: onPlaidSuccess,
      onError,
      onExit: () => {
        plaidDialog.off();
        onClose();
      },
    });

    const { data: fundingSources, isLoading: isLoadingFundingSources } = useBankAccountFundingSources({ onError });
    const [selectedFundingSourceId, setSelectedFundingSourceId] = useState<string>();

    // reset the state when modal is closing
    useEffect(() => {
      if (!isOpen) setSelectedFundingSourceId(undefined);
    }, [isOpen]);

    useEffect(() => {
      if (ready && isPlaidOpen) open();
    }, [ready, open, isPlaidOpen]);

    const openModalState = useMemo<OpenModalState>(() => {
      if (!isMutating && isPlaidOpen) {
        return 'plaid-dialog';
      }
      if (isLoadingFundingSources || fundingSources?.length) {
        return 'import-funding-source';
      }
      return 'add-receiving-method';
    }, [isMutating, isPlaidOpen, isLoadingFundingSources, fundingSources?.length]);

    const { toast } = useToast();

    if (!isOpen) return null;

    return (
      <>
        <ImportReceivingMethodModalScreen
          isLoading={isLoadingFundingSources}
          isSaving={isMutating}
          fundingSources={fundingSources ?? []}
          onAdd={(type) => {
            if (type === 'plaid') plaidDialog.on();
            else if (selectedFundingSourceId) {
              void createFromFundingSource({ fundingSourceId: selectedFundingSourceId })
                .then(onClose)
                .then(onDone)
                .catch((error: ARPlatformError) => {
                  toast({ type: 'error', title: error.message });
                  onError?.(error);
                });
            } else {
              toast({ type: 'error', title: 'Unsupported receiving method' });
              onClose();
            }
          }}
          onSelectedFundingSource={setSelectedFundingSourceId}
          selectedFundingSourceId={selectedFundingSourceId}
          isOpen={openModalState == 'import-funding-source'}
          onClose={onClose}
          {...props}
          ref={ref}
        />
        <AddReceivingMethodModalScreen
          isSaving={isMutating}
          onAddPlaidAccount={plaidDialog.on}
          isOpen={openModalState == 'add-receiving-method'}
          onClose={onClose}
          {...props}
          ref={ref}
        />
      </>
    );
  }
);

AddReceivingMethodModalActivity.displayName = 'AddReceivingMethodModalActivity';

type OpenModalState = 'plaid-dialog' | 'add-receiving-method' | 'import-funding-source';
