import { Group, Table, Text } from '@melio/penny';
import { Loan } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { LoansTabItem } from '../types';

export const FinancingTabRemainingAmountCell = ({ loansTabItem }: { loansTabItem: LoansTabItem }) => {
  const { formatCurrency } = useMelioIntl();
  const {
    id,
    loan: { status, remainingAmount },
  } = loansTabItem;

  const allowedStatusToDisplay: Loan['status'][] = ['active', 'failed', 'completed'];
  const isAllowedStatusToDisplay = allowedStatusToDisplay.includes(status);

  return (
    <Table.Cell data-testid={`financing-tab-row-${id}-remaining-amount-cell`} textAlign="end">
      <Group variant="vertical" spacing="xxs" alignItems="flex-end" data-testid="financing-tab-remaining-amount-cell">
        <Text textStyle="body3Semi" color={isAllowedStatusToDisplay ? 'global.neutral.1000' : 'global.neutral.600'}>
          {formatCurrency(isAllowedStatusToDisplay ? remainingAmount : 0)}
        </Text>
      </Group>
    </Table.Cell>
  );
};
