import { Box } from '@chakra-ui/react';
import { Container, Divider, StatusBar } from '@melio/penny';
import { PaymentStatusEnum } from '@melio/platform-api';

import { usePlatformIntl } from '@/translations/Intl';
import { MarkedAsPaidSection } from '@/widgets/pay-dashboard/payment-details/components/general/MarkedAsPaidSection';
import { PaymentDetailsProps } from '@/widgets/pay-dashboard/payment-details/PaymentDetails.types';
import { usePaymentScheduledByProps } from '../../hooks/usePaymentScheduledByProps';
import { PayFromSection } from '../general/PayFromSection';
import { PaymentDetailsSection } from '../general/PaymentDetailsSection';
import { PaymentDetailsTop } from '../general/PaymentDetailsTop';
import { PaymentScheduledBySection } from '../general/PaymentScheduledBySection';
import { PaymentTimelineTrackerSection } from '../general/PaymentTimelineTracker/PaymentTimelineTrackerSection';
import { VendorReceivesSection } from '../general/VendorReceivesSection/VendorReceivesSection';

export const PaymentCompleted = ({ payment, isActionsEnabled }: PaymentDetailsProps) => {
  const { formatMessage } = usePlatformIntl();
  const { showPaymentScheduledBySection, ...props } = usePaymentScheduledByProps(payment);

  return (
    <>
      <StatusBar variant={'success'} content={formatMessage('widgets.paymentDetails.completed.title')} />

      <Box py={'32px'} data-testid="pay-dashboard-payment-completed">
        <PaymentTimelineTrackerSection payment={payment}>
          <Container paddingY="l">
            <Divider />
          </Container>
        </PaymentTimelineTrackerSection>

        <PaymentDetailsSection>
          <PaymentDetailsTop payment={payment} isActionsEnabled={isActionsEnabled} />
        </PaymentDetailsSection>
        {showPaymentScheduledBySection && (
          <PaymentDetailsSection gridGap={'24px'}>
            <PaymentScheduledBySection {...props} />
          </PaymentDetailsSection>
        )}
        <PaymentDetailsSection gridGap={'24px'}>
          {payment.markedAsPaid ? (
            <MarkedAsPaidSection markedAsPayBy={payment.createdBy!} scheduledDate={payment.scheduledDate} />
          ) : (
            <>
              <PayFromSection
                fundingSource={payment.fundingSource}
                scheduledDate={payment.scheduledDate}
                paymentStatus={PaymentStatusEnum.Completed}
                deliveryMethod={payment.deliveryMethod}
              />
              <Divider />
              <VendorReceivesSection
                vendorName={payment.vendor.name}
                deliveryMethod={payment.deliveryMethod}
                estimatedDelivery={payment.estimatedDelivery}
                maxEstimatedDelivery={payment.maxEstimatedDelivery}
                processedTimeline={payment.processedTimeline}
                memoToVendor={payment.note}
                timeline={payment.timeline}
                paymentStatus={PaymentStatusEnum.Completed}
              />
            </>
          )}
        </PaymentDetailsSection>
      </Box>
    </>
  );
};
