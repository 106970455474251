import { useMelioIntl } from '@melio/ar-domain';
import { Button, Container, Group } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export type DiscountsTableToolbarProps = {
  onAdd: VoidFunction;
};

export const DiscountsTableToolbar = forwardRef<DiscountsTableToolbarProps>(({ onAdd, ...props }, ref) => {
  const { formatMessage } = useMelioIntl();
  return (
    <Container paddingX="xxs" paddingY="xxs" data-component={DiscountsTableToolbar.displayName}>
      <Group variant="horizontal" justifyContent="flex-end" {...props} ref={ref}>
        <Button
          variant="tertiary"
          label={formatMessage(
            'ar.settings.activities.discountsTable.components.discountsTableToolbar.addButton.label'
          )}
          onClick={onAdd}
          data-testid="add-discount-button"
        />
      </Group>
    </Container>
  );
});
DiscountsTableToolbar.displayName = 'DiscountsTableToolbar';
