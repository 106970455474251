import { Button, Card, Container, Group, Modal, Text } from '@melio/penny';
// eslint-disable-next-line no-restricted-imports
import { FirmClientExpanded } from '@melio/platform-api-axios-client';
import { useMelioIntl } from '@melio/platform-i18n';

import { useAccountantsRoutes } from '../../../../utils/useAccountantsRoutes';
import { CompanyAvatar } from '../CompanyAvatar';

type Props = {
  isOpen: boolean;
  clients: FirmClientExpanded[];
  onClose: VoidFunction;
};

export const ClientsPlanSelectionModal = ({ isOpen, clients, onClose }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { goToAssignClientPlan } = useAccountantsRoutes();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={formatMessage('activities.subscription.firmDashboard.clientsPlanSelectionModal.title')}
      data-testid="firm-dashboard-clients-plan-selection-modal"
    >
      <Group variant="vertical" spacing="m">
        <Text>{formatMessage('activities.subscription.firmDashboard.clientsPlanSelectionModal.subTitle')}</Text>
        {clients.map((client) => (
          <Container
            key={client.organization.id}
            border="regular"
            data-testid={`clients-plan-selection-${client.organization.id}`}
          >
            <Card>
              <Container alignItems="center">
                <Group alignItems="center" spacing="s">
                  <CompanyAvatar name={client.organization.name || ''} size="medium" variant="circle" />
                  <Group variant="vertical" spacing="xxs">
                    <Text textStyle="body2Semi" shouldSupportEllipsis>
                      {client.organization.name}
                    </Text>
                    <Text textStyle="body4" color="neutral.darker">
                      {formatMessage(
                        'activities.subscription.firmDashboard.clientsPlanSelectionModal.client.numberOfMembers.label',
                        { numberOfMembers: client.firmMembers?.length || 0 }
                      )}
                    </Text>
                  </Group>
                </Group>
              </Container>
              <Container justifyContent="flex-end">
                <Button
                  variant="tertiary"
                  label={formatMessage('activities.subscription.firmDashboard.clientsPlanSelectionModal.action.label')}
                  onClick={() => goToAssignClientPlan({ accountingClientId: client.organization.id })}
                />
              </Container>
            </Card>
          </Container>
        ))}
      </Group>
    </Modal>
  );
};
