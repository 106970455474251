import { IconKey } from '@melio/penny';
import { Bill } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export const useBillDetailsButtonProps = ({ bill }: { bill: Bill | undefined }): { icon: IconKey; label: string } => {
  const { formatMessage } = useMelioIntl();

  return bill
    ? { icon: 'edit', label: formatMessage('activities.paymentFlow.form.content.bill.edit.label') }
    : { icon: 'add-bill', label: formatMessage('activities.paymentFlow.form.content.bill.add.label') };
};
