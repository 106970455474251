import { useMelioIntl } from '@melio/ar-domain';
import { Container, Divider, Group, IconButton } from '@melio/penny';
import { forwardRef, PageTitle } from '@melio/platform-utils';

type InvoiceFormMobileHeaderProps = {
  headerText: string;
  onBack: VoidFunction;
};

export const InvoiceFormHeader = forwardRef<InvoiceFormMobileHeaderProps>(({ headerText, onBack }, ref) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Group
      variant="vertical"
      spacing="none"
      data-testid="invoice-mobile-header"
      data-component={InvoiceFormHeader.displayName}
      ref={ref}
    >
      <Container paddingX="s" paddingY="s" backgroundColor="light">
        <Group variant="horizontal" alignItems="center" spacing="s">
          <IconButton
            icon="arrow-left"
            aria-label={formatMessage('ar.invoiceLifecycle.activities.issueInvoice.form.header.buttons.back.ariaLabel')}
            variant="naked"
            size="medium"
            onClick={onBack}
            data-testid="invoice-header-mobile-back-button"
          />
          <PageTitle textStyle="heading1Semi">{headerText}</PageTitle>
        </Group>
      </Container>
      <Divider />
    </Group>
  );
});
InvoiceFormHeader.displayName = 'InvoiceMobileHeader';
