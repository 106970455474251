import { FormInputs, FormWidgetProps, RoleType, useMelioIntl } from '@melio/ar-domain';
import { Form, useMelioForm } from '@melio/penny';
import { forwardRef as reactForwardRef, MutableRefObject, useEffect } from 'react';
import * as yup from 'yup';

import { BusinessOwnerForm, OwnerDetailsFormValues } from '../types';

export type OwnerDetailsFormProps = Omit<
  FormWidgetProps<OwnerDetailsFormValues>,
  'onSubmit' | 'onSubmissionStateChange'
> & {
  ownerId: string;
  selectedMainOwnerId: string | null;
  setMainOwner: (isSelected: boolean) => void;
};

export const OwnerDetailsForm = reactForwardRef<BusinessOwnerForm, OwnerDetailsFormProps>(
  ({ isSaving, selectedMainOwnerId, ownerId, setMainOwner, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const formControl = useMelioForm<OwnerDetailsFormValues>({
      onSubmit: () => null,
      isSaving,
      schema: yup.object().shape({
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        dateOfBirth: yup.date().nullable().required(),
        nationality: yup.string().required(),
        taxNumber: yup.string().required(),
        phoneNumber: yup.string().required(),
        email: yup.string().required().email(),
        line1: yup.string().required(),
        city: yup.string().required(),
        state: yup.string().required(),
        countryCode: yup.string().required(),
        postalCode: yup.string().required(),
        role: yup.string().oneOf(Object.values(RoleType)).required(),
        percentageOwnership: yup.string().required(),
        mainOwnership: yup.boolean().notRequired(),
      }) as yup.SchemaOf<OwnerDetailsFormValues>,
    });

    useEffect(() => {
      (ref as MutableRefObject<BusinessOwnerForm>).current = formControl;
    }, [formControl, ref]);

    const isSelectedOwner = formControl.watch('mainOwnership');

    useEffect(() => {
      if (!selectedMainOwnerId || (!isSelectedOwner && ownerId === selectedMainOwnerId)) {
        setMainOwner(isSelectedOwner);
      }
    }, [isSelectedOwner, ownerId, selectedMainOwnerId, setMainOwner]);

    const { registerField, formProps } = formControl;

    return (
      <Form {...props} {...formProps} columns={2}>
        <Form.TextField
          labelProps={{
            label: formatMessage('ar.onboarding.activities.ownershipDetails.forms.ownerDetails.firstName.label'),
          }}
          {...registerField('firstName')}
        />
        <Form.TextField
          labelProps={{
            label: formatMessage('ar.onboarding.activities.ownershipDetails.forms.ownerDetails.lastName.label'),
          }}
          {...registerField('lastName')}
        />
        <Form.DateField
          labelProps={{
            label: formatMessage('ar.onboarding.activities.ownershipDetails.forms.ownerDetails.dateOfBirth.label'),
          }}
          {...registerField('dateOfBirth')}
        />
        <Form.TextField
          labelProps={{
            label: formatMessage('ar.onboarding.activities.ownershipDetails.forms.ownerDetails.nationality.label'),
          }}
          {...registerField('nationality')}
        />
        <Form.TextField
          labelProps={{
            label: formatMessage('ar.onboarding.activities.ownershipDetails.forms.ownerDetails.taxNumber.label'),
          }}
          {...registerField('taxNumber')}
          colSpan={2}
        />
        <Form.PhoneField
          labelProps={{
            label: formatMessage('ar.onboarding.activities.ownershipDetails.forms.ownerDetails.phoneNumber.label'),
          }}
          {...registerField('phoneNumber')}
        />
        <Form.TextField
          labelProps={{
            label: formatMessage('ar.onboarding.activities.ownershipDetails.forms.ownerDetails.email.label'),
          }}
          {...registerField('email')}
        />
        <Form.TextField
          labelProps={{
            label: formatMessage('ar.onboarding.activities.ownershipDetails.forms.ownerDetails.ownerAddress.label'),
          }}
          {...registerField('line1')}
          colSpan={2}
        />
        <Form.TextField
          labelProps={{
            label: formatMessage('ar.onboarding.activities.ownershipDetails.forms.ownerDetails.city.label'),
          }}
          {...registerField('city')}
        />
        <Form.TextField
          labelProps={{
            label: formatMessage('ar.onboarding.activities.ownershipDetails.forms.ownerDetails.state.label'),
          }}
          {...registerField('state')}
        />
        <Form.TextField
          labelProps={{
            label: formatMessage('ar.onboarding.activities.ownershipDetails.forms.ownerDetails.countryCode.label'),
          }}
          {...registerField('countryCode')}
        />
        <Form.TextField
          labelProps={{
            label: formatMessage('ar.onboarding.activities.ownershipDetails.forms.ownerDetails.postalCode.label'),
          }}
          {...registerField('postalCode')}
        />
        <FormInputs.Select
          labelProps={{
            label: formatMessage('ar.onboarding.activities.ownershipDetails.forms.ownerDetails.role.label'),
          }}
          {...registerField('role')}
          options={[
            {
              value: RoleType.VicePresident,
              label: formatMessage(
                'ar.onboarding.activities.ownershipDetails.forms.ownerDetails.role.type.VicePresident.label'
              ),
            },
            {
              value: RoleType.President,
              label: formatMessage(
                'ar.onboarding.activities.ownershipDetails.forms.ownerDetails.role.type.President.label'
              ),
            },
            {
              value: RoleType.Treasurer,
              label: formatMessage(
                'ar.onboarding.activities.ownershipDetails.forms.ownerDetails.role.type.Treasurer.label'
              ),
            },
            {
              value: RoleType.Owner,
              label: formatMessage(
                'ar.onboarding.activities.ownershipDetails.forms.ownerDetails.role.type.Owner.label'
              ),
            },
            {
              value: RoleType.Partner,
              label: formatMessage(
                'ar.onboarding.activities.ownershipDetails.forms.ownerDetails.role.type.Partner.label'
              ),
            },
            {
              value: RoleType.Ceo,
              label: formatMessage('ar.onboarding.activities.ownershipDetails.forms.ownerDetails.role.type.Ceo.label'),
            },
            {
              value: RoleType.Secretary,
              label: formatMessage(
                'ar.onboarding.activities.ownershipDetails.forms.ownerDetails.role.type.Secretary.label'
              ),
            },
            {
              value: RoleType.Director,
              label: formatMessage(
                'ar.onboarding.activities.ownershipDetails.forms.ownerDetails.role.type.Director.label'
              ),
            },
            {
              value: RoleType.SoleProprietor,
              label: formatMessage(
                'ar.onboarding.activities.ownershipDetails.forms.ownerDetails.role.type.SoleProprietor.label'
              ),
            },
            {
              value: RoleType.Coo,
              label: formatMessage('ar.onboarding.activities.ownershipDetails.forms.ownerDetails.role.type.Coo.label'),
            },
            {
              value: RoleType.Cfo,
              label: formatMessage('ar.onboarding.activities.ownershipDetails.forms.ownerDetails.role.type.Cfo.label'),
            },
            {
              value: RoleType.MemberLlc,
              label: formatMessage(
                'ar.onboarding.activities.ownershipDetails.forms.ownerDetails.role.type.MemberLlc.label'
              ),
            },
            {
              value: RoleType.Other,
              label: formatMessage(
                'ar.onboarding.activities.ownershipDetails.forms.ownerDetails.role.type.Other.label'
              ),
            },
          ]}
        />
        <Form.TextField
          labelProps={{
            label: formatMessage(
              'ar.onboarding.activities.ownershipDetails.forms.ownerDetails.percentageOwnership.label'
            ),
          }}
          {...registerField('percentageOwnership')}
        />
        <Form.Checkbox
          size="small"
          label={formatMessage('ar.onboarding.activities.ownershipDetails.forms.ownerDetails.mainOwnership.label')}
          isDisabled={!!selectedMainOwnerId && ownerId !== selectedMainOwnerId}
          {...registerField('mainOwnership')}
        />
      </Form>
    );
  }
);
