import { HStack } from '@chakra-ui/react';
import { VendorAvatar } from '@melio/ap-widgets';
import { Text, useBreakpointValue } from '@melio/penny';
import { Vendor } from '@melio/platform-api';

export interface Props {
  vendor: Vendor;
}

export const VendorAvatarWithTitle = ({ vendor }: Props) => {
  const shouldSupportEllipsis = useBreakpointValue({ xs: false, s: true });
  const { name: vendorName, externalLogoUrl: vendorExternalLogoUrl, isManaged: isVendorManaged } = vendor;
  return (
    <HStack alignItems={'center'}>
      <VendorAvatar name={vendorName} logoUrl={vendorExternalLogoUrl} isManaged={isVendorManaged} />
      {vendorName && (
        <Text textStyle="body2Semi" shouldSupportEllipsis={shouldSupportEllipsis}>
          {vendorName}
        </Text>
      )}
    </HStack>
  );
};
