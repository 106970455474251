import { Container } from '@melio/penny';
import { Loan, Payment } from '@melio/platform-api';
import { useCallback } from 'react';

import { LoanActive } from './Active/LoanActive';
import { LoanCanceled } from './Canceled/LoanCanceled';
import { LoanCompleted } from './Completed/LoanCompleted';
import { LoanFailed } from './Failed/LoanFailed';
import { LoanHandledByCreditKey } from './HandledByCreditKey/LoanHandledByCreditKey';

export const LoanDetails = ({ payment }: { payment: Override<Payment, { loan: Loan }> }) => {
  const renderLoanDetails = useCallback(() => {
    switch (payment.loan.status) {
      case 'failed':
        return <LoanFailed payment={payment} />;
      case 'handled-by-credit-key':
        return <LoanHandledByCreditKey payment={payment} />;
      case 'canceled':
        return <LoanCanceled payment={payment} />;
      case 'completed':
        return <LoanCompleted payment={payment} />;
      default:
        return <LoanActive payment={payment} />;
    }
  }, [payment]);

  return <Container border="regular">{renderLoanDetails()}</Container>;
};
