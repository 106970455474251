import { ActionsDropdownMenu, ActionsDropdownMenuItemProps, Avatar, Group, Typography } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useState } from 'react';

import { toFullName } from '../utils/toFullName';

type Props = {
  firstName: string;
  lastName: string;
  email: string | null;
  badgeText: string;
  badgeVariant?: 'neutral' | 'secondary';
  menuItems?: ActionsDropdownMenuItemProps[];
};

export const CollaboratorItem = ({
  badgeText,
  firstName,
  lastName,
  email,
  badgeVariant = 'neutral',
  menuItems,
}: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const fullName = toFullName(firstName, lastName);

  const { track } = useAnalytics();

  const onDropdownMenuToggle = (isOpen: boolean) => {
    if (isOpen) {
      track('Settings', 'Click', {
        PageName: 'manage-collaborators',
        Flow: 'settings',
        Intent: 'user-actions-menu',
        Cta: 'user-actions-menu',
      });
    }

    setIsMenuOpen(isOpen);
  };

  return (
    <Group data-testid="collaborator-item">
      <Group.Item alignSelf="flex-start">
        <Avatar name={fullName} />
      </Group.Item>
      <Group.Item grow={1}>
        <Group variant="vertical" spacing="xxs">
          <Typography.MainLabel
            label={fullName}
            variant="bold"
            pillProps={{
              label: badgeText,
              status: 'neutral',
              type: badgeVariant === 'secondary' ? 'secondary' : 'primary',
            }}
          />
          <Typography.Description label={email || ''} />
        </Group>
      </Group.Item>
      {!!menuItems?.length && (
        <Group.Item alignSelf="flex-start">
          <ActionsDropdownMenu
            size="small"
            label="actions"
            items={menuItems || []}
            isOpen={isMenuOpen}
            onOpenChange={onDropdownMenuToggle}
          />
        </Group.Item>
      )}
    </Group>
  );
};
