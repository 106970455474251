import { useRoleText } from '@melio/ap-domain';
import { Group, Pill, Table, Text } from '@melio/penny';
import { Collaborator, FundingSource, useCollaborator } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { getIsCollaboratorHasFullAccess, getIsCollaboratorsWithOnlyOneEntitledFundingSource } from './utils';

export const UsersCell = ({
  collaborator,
  fundingSourceId,
  rowIndex,
}: {
  collaborator: Collaborator<'user'>;
  fundingSourceId: FundingSource['id'];
  rowIndex: number;
}) => {
  const { formatMessage } = useMelioIntl();
  const { data: actor } = useCollaborator({ id: 'me' });

  const { getRoleText } = useRoleText();

  const isCollaboratorHasFullAccess = getIsCollaboratorHasFullAccess(collaborator);

  const isCollaboratorsWithOnlyOneEntitledFundingSource = getIsCollaboratorsWithOnlyOneEntitledFundingSource(
    collaborator,
    fundingSourceId
  );

  const getLabel = () => {
    let label = '';
    if (collaborator.user) {
      label = `${collaborator.user.firstName} ${collaborator.user.lastName} `;
    }
    if (actor?.id === collaborator.id) {
      return label + formatMessage('activities.settings.collaborators.table.cells.user.actor');
    }
    return label;
  };

  const getDescription = () => {
    if (isCollaboratorHasFullAccess) {
      return formatMessage(
        'widgets.addOrUpdateDeliveryMethod.entitled.fundingSource.modal.list.header.description.fullAccess'
      );
    }
    if (isCollaboratorsWithOnlyOneEntitledFundingSource) {
      return formatMessage(
        'widgets.addOrUpdateDeliveryMethod.entitled.fundingSource.modal.list.header.description.oneFundingSource'
      );
    }
    return null;
  };

  const description = getDescription();

  return (
    <Table.Cell data-testid={`row-${collaborator.id}-users-name-cell`}>
      <Group variant="vertical" spacing="none">
        <Group spacing="xxs" variant="vertical">
          <Group spacing="xxs" variant="horizontal">
            <Text textStyle="body3Semi" color="global.neutral.1000" id={`user-cell-label-${rowIndex}`}>
              {getLabel()}
            </Text>
            <Pill
              type={collaborator.id === actor?.id ? 'primary' : 'secondary'}
              status="neutral"
              label={getRoleText(collaborator.roleUniqueName) || ''}
            />
          </Group>
          {description && (
            <Text
              textStyle="body3"
              color="neutral.700"
              data-testid={`row-${collaborator.id}-users-name-cell-description`}
              id={`user-cell-description-${rowIndex}`}
            >
              {description}
            </Text>
          )}
        </Group>
      </Group>
    </Table.Cell>
  );
};
