import {
  GuestPayorUserTypes,
  useAccount,
  useGuestPayorOnboarding,
  useGuestPayorPayment,
  useGuestPayorPaymentRequestDetails,
  useGuestPayorUserDetailsByEmail,
} from '@melio/ar-domain';
import { useToast } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { PartnerName } from '@melio/platform-api';
import { convertDateToStringRepresentation, forwardRef, useBoolean } from '@melio/platform-utils';
import { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';

import { SignInBanner } from '../../components';
import { AuthenticationModalActivity } from '../authentication-modal';
import { IndustrySelectionModalActivity } from '../industry-selection-modal';
import { CardHolderDetailsFormFields } from '../types';
import { useAddCardFundingSource } from './hooks';
import { AddCardFormScreen } from './screens';

type AddCardFundingSourceActivityProps = {
  onCreatePayment: (paymentId: string) => void;
  partnerName: PartnerName;
  paymentRequestLink: string;
  onLoggedIn?: (accessToken: string, refreshToken?: string | null) => Promise<unknown>;
  onError: ARErrorFunction;
  onFormLoading: (isLoading: boolean) => void;
  setIsPaymentProcessing: (isProcessing: boolean) => void;
  redirectToPayDashboardUrl?: string;
  isNavigateApUsersToDashboardEnabled?: boolean;
};

export const AddCardFundingSourceActivity = withAnalyticsContext<AddCardFundingSourceActivityProps>(
  forwardRef(
    (
      {
        onError,
        onFormLoading,
        onLoggedIn,
        partnerName,
        paymentRequestLink,
        onCreatePayment,
        setIsPaymentProcessing,
        redirectToPayDashboardUrl,
        isNavigateApUsersToDashboardEnabled,
        setAnalyticsProperties,
      },
      ref
    ) => {
      const { data: paymentRequestDetails, isLoading: isLoadingPaymentRequestLink } =
        useGuestPayorPaymentRequestDetails({
          paymentRequestLink,
        });
      const { createPayment, isCreatingPayment } = useGuestPayorPayment({ enabled: false });
      const [userEmail, setUserEmail] = useState<string | undefined>();
      const { data: userDetails, isLoading: isLoadingUserDetails } = useGuestPayorUserDetailsByEmail({
        paymentRequestLink,
        email: userEmail as string,
        enabled: !!userEmail && !!isNavigateApUsersToDashboardEnabled,
      });
      const { toast } = useToast();
      const [formValues, setFormValues] = useState<CardHolderDetailsFormFields>();
      const [isOTPModalOpen, otpModalOpen] = useBoolean(false);
      const [isIndustryModalOpen, industryModalOpen] = useBoolean(false);
      const { onboarding, data: onboardingData, isLoading: isLoadingOnBoarding } = useGuestPayorOnboarding();
      const { data: account, error: fetchAccountError } = useAccount({ id: 'me' });
      const prevFormValuesRef = useRef<CardHolderDetailsFormFields>();
      const cardNetwork = formValues?.cardNumber.cardMetadata?.cardBrand || '';

      const { isCreating, addCard } = useAddCardFundingSource({
        onAddCardSuccess: async (fundingSourceId: string) => {
          try {
            if (formValues) {
              const { paymentId } = await createPayment({
                fundingSourceId,
                paymentRequestLink,
                scheduledDate: convertDateToStringRepresentation(formValues.scheduledDate),
              });
              onCreatePayment(paymentId);
            }
          } catch (error) {
            setIsPaymentProcessing(false);
            onError(error as ARPlatformError);
          }
        },
      });

      const handleIndustrySelectionDone = async () => {
        try {
          industryModalOpen.off();
          setIsPaymentProcessing(true);
          await addCard(formValues as CardHolderDetailsFormFields);
        } catch (error) {
          toast({ type: 'error', title: (error as ARPlatformError).message });
          setIsPaymentProcessing(false);
          onError(error as ARPlatformError);
        }
      };

      const { track } = useAnalytics();

      const onEmailEntered = (email: string) => {
        const isValidEmail = Yup.string().email().isValidSync(email);
        setUserEmail(isValidEmail ? email : undefined);
      };

      useEffect(() => {
        const prevFormValues = prevFormValuesRef.current;

        if (formValues && formValues !== prevFormValues) {
          prevFormValuesRef.current = formValues;
          const isAuthenticated = !fetchAccountError && account && account.user.email === formValues.email;
          isAuthenticated ? void handleAuthenticationDone() : otpModalOpen.on();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [formValues, account]);
      const isSaving = isLoadingOnBoarding || isLoadingPaymentRequestLink || isCreating || isCreatingPayment;

      useEffect(() => {
        onFormLoading(isSaving);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isSaving]);

      const handleOnSubmit = (values: CardHolderDetailsFormFields) => {
        track('PaymentRequest', 'Click', {
          PaymentMethodType: 'card',
          Cta: 'pay',
        });
        setFormValues(values);
      };

      const handleAuthenticationDone = async () => {
        try {
          otpModalOpen.off();
          const { isMissingMcc } = await onboarding({ paymentRequestLink });
          if (isMissingMcc && ['visa', 'american-express'].includes(cardNetwork)) {
            industryModalOpen.on();
          } else {
            setIsPaymentProcessing(true);
            await addCard(formValues as CardHolderDetailsFormFields);
          }
        } catch (error) {
          toast({ type: 'error', title: (error as ARPlatformError).message });
          setIsPaymentProcessing(false);
          onError(error as ARPlatformError);
        }
      };

      setAnalyticsProperties({
        UserType: userDetails?.userType,
        Email: userEmail,
      });

      const shouldRedirectToPayDashboard = userDetails?.userType === GuestPayorUserTypes.REGISTERED_AND_MATCHED;
      const shouldDisplaySignInBanner =
        isNavigateApUsersToDashboardEnabled && shouldRedirectToPayDashboard && !!redirectToPayDashboardUrl;

      useEffect(() => {
        if (shouldDisplaySignInBanner) {
          track('PaymentRequest', 'View', {
            PageName: 'payment-request-sign-in',
          });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [shouldDisplaySignInBanner]);

      const onSignInClick = () => {
        track('PaymentRequest', 'Click', { PageName: 'payment-request-sign-in', Cta: 'sign-in' });
        window.location.href = redirectToPayDashboardUrl as string;
      };

      return (
        <>
          <AddCardFormScreen
            onSubmit={handleOnSubmit}
            isSaving={isSaving}
            amount={paymentRequestDetails?.invoice.totalAmount || 0}
            ref={ref}
            onEmailFieldBlur={onEmailEntered}
            emailBanner={shouldDisplaySignInBanner ? <SignInBanner onSignInClick={onSignInClick} /> : null}
            isEmailFieldLoading={isLoadingUserDetails}
          />
          <AuthenticationModalActivity
            partnerName={partnerName}
            email={formValues?.email || ''}
            onLoggedIn={onLoggedIn}
            onClose={otpModalOpen.off}
            isOpen={isOTPModalOpen}
            onDone={handleAuthenticationDone}
          />
          {onboardingData?.vendorId && (
            <IndustrySelectionModalActivity
              isAmex={cardNetwork === 'american-express'}
              isOpen={isIndustryModalOpen}
              onDone={handleIndustrySelectionDone}
              onClose={industryModalOpen.off}
              vendorId={onboardingData.vendorId}
              vendorCompanyName={paymentRequestDetails?.payeeDetails.companyName || ''}
            />
          )}
        </>
      );
    }
  )
);

AddCardFundingSourceActivity.displayName = 'AddCardFundingSourceActivity';
