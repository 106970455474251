import { Box } from '@chakra-ui/react';
import { Form, Group, NakedButton, useFieldArray } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { difference } from 'lodash';
import React from 'react';

import { useFormContext } from '../../FormContext';
import { ApprovalFormDivider } from '../ApprovalFormDivider';
import { CriteriaSelector } from './CriteriaSelector';
import { useConditionsList } from './useConditionsList';

export const ApprovalCriteria = () => {
  const { formatMessage } = useMelioIntl();
  const form = useFormContext();

  const allConditions = useConditionsList();
  const fieldArray = useFieldArray({ control: form.control, name: 'criteria.conditions' });
  const { conditions } = form.getValues().criteria;

  const onAddCondition = () => {
    const differenceValue = difference(
      allConditions.map((con) => con.value),
      conditions.map((con) => con.condition.type)
    );

    const lastConditionToAdd = allConditions.find((cond) => cond.value === differenceValue[0]);
    if (lastConditionToAdd?.value) {
      fieldArray.append(lastConditionToAdd.condition);
    }
  };

  return (
    <Group variant="vertical" spacing="l" width="full">
      <Form columns={12} gap="m" as="div">
        {fieldArray.fields.map((field, index) => (
          <React.Fragment key={field.id}>
            {index !== 0 && (
              <Form.ContentBox colSpan={12}>
                <ApprovalFormDivider type="and" />
              </Form.ContentBox>
            )}
            <CriteriaSelector
              index={index}
              onClickRemoveCriteria={
                conditions.length > 1
                  ? () => {
                      fieldArray.remove(index);
                    }
                  : null
              }
            />
          </React.Fragment>
        ))}
      </Form>
      {conditions.length < allConditions.length && (
        <Box>
          <NakedButton
            data-testid="approval-workflow-v2-add-new-condition"
            variant="secondary"
            label={formatMessage('activities.create-approval-workflows.conditions.addNew')}
            onClick={onAddCondition}
          />
        </Box>
      )}
    </Group>
  );
};
