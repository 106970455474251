/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useAnalytics } from '@melio/platform-analytics';

import { useRouter } from '@/hooks/router.hooks';
import { useIsMobile } from '@/hooks/useIsMobile';
import { useSelectedVendor } from '@/hooks/vendors.hooks';
import {
  vendorsDashboardSearchQuerySelector,
  vendorsFilteredBySearchQuerySelector,
} from '@/store/Vendors/Vendors.model';

export const useVendorsDashboardHandleNavigation = () => {
  const { vendorId: vendorIdParam } = useParams();
  const vendorsByGroup = useRecoilValue(vendorsFilteredBySearchQuerySelector) || [];
  const vendorsFiltered = Object.values(vendorsByGroup!).flat();
  const { selectedVendorId, onSelect } = useSelectedVendor();
  const isMobile = useIsMobile();
  const { goToVendor } = useRouter();
  const { track } = useAnalytics();
  const setSearchQuery = useSetRecoilState(vendorsDashboardSearchQuerySelector);

  React.useEffect(() => {
    if (vendorsFiltered?.length) {
      if (!selectedVendorId) {
        if (!isMobile) {
          onSelect({ vendorId: vendorIdParam || vendorsFiltered[0].id });
        }
      } else if (!vendorsFiltered.find((vendor) => vendor.id === selectedVendorId)) {
        onSelect({ vendorId: vendorsFiltered[0].id });
      }
    } else {
      onSelect({ vendorId: undefined });
    }
  }, [vendorsFiltered]);

  React.useEffect(() => {
    return () => {
      setSearchQuery('');
      onSelect({ vendorId: undefined });
    };
  }, []);

  React.useEffect(() => {
    if (selectedVendorId) {
      goToVendor(selectedVendorId);
    }
  }, [selectedVendorId]);

  React.useEffect(() => {
    track('VendorsDashboard', 'Viewed');
  }, []);

  return { vendorsFiltered };
};
