import { Box, Flex } from '@chakra-ui/react';
import { getIsVoidAndRefundEnabled, useEstimatedDeliveryDateLabel } from '@melio/ap-domain';
import { Group, StatusIconSolid, Table, Text, Tooltip } from '@melio/penny';
import { Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import {
  isPaymentCompletedRefund,
  isPaymentCompletedVoidAndRefund,
  isPaymentScheduledVoid,
  isPaymentScheduledVoidAndRefund,
} from '../../../../../utils/pay-flow/Payment.utils';

export const PaymentsTabDeliveryDateCell = ({ payment }: { payment: Payment }) => {
  const { formatMessage } = useMelioIntl();
  const isProcessingVoid = isPaymentScheduledVoid(payment);
  const isProcessingVoidAndRefund =
    isPaymentScheduledVoidAndRefund(payment) || isPaymentCompletedVoidAndRefund(payment);
  const isCompletedRefundPayment = isPaymentCompletedRefund(payment);
  const shouldShowPlaceholder = !payment.estimatedDelivery || isProcessingVoid || isCompletedRefundPayment;
  const placeholder =
    isProcessingVoid || isCompletedRefundPayment
      ? formatMessage('activities.payDashboard.paymentsTab.table.cells.notAvailable')
      : formatMessage('activities.batchPayments.screens.paymentIntentsTable.deliveryDateCell.date.missing');
  const isVoidAndRefundEnabled = getIsVoidAndRefundEnabled(payment);
  const { dateLabel } = useEstimatedDeliveryDateLabel({
    minDeliveryDate: payment.estimatedDelivery,
    maxDeliveryDate: payment.maxEstimatedDelivery,
  });

  return (
    <Table.Cell placeholder={placeholder} data-testid={`payments-tab-row-${payment.id}-delivery-date-cell`}>
      <Group spacing="s" alignItems="center">
        <Flex direction="column">
          {shouldShowPlaceholder ? <Text color="global.neutral.700">{placeholder}</Text> : dateLabel}
          {isProcessingVoidAndRefund ? (
            <Text textStyle="caption1" color="global.neutral.900">
              {formatMessage(`activities.batchPayments.screens.paymentIntentsTable.deliveryDateCell.date.checkVoided`)}
            </Text>
          ) : null}
        </Flex>
        {isVoidAndRefundEnabled && (
          <Tooltip
            label={formatMessage(
              'activities.batchPayments.screens.paymentIntentsTable.deliveryDateCell.date.undepositedCheckTooltip'
            )}
            placement="top"
          >
            <Box
              as={StatusIconSolid}
              data-testid="undeposited-check-warning-tooltip"
              variant="warning"
              size="small"
              tabIndex={0}
            />
          </Tooltip>
        )}
      </Group>
    </Table.Cell>
  );
};
