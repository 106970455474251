import { ApiError } from '@melio/api-client';
import {
  PaymentWidgetFundingSourceType,
  PayorApiClient,
  PayorVerifyOTPResponseProperties,
  PostPayorOnboardingRequest,
  PostPayorOnboardingResponseData,
  PostSendOTPRequest,
  PostVerifyOTPRequest,
  PublicPayorApiClient,
} from '@melio/platform-api-axios-client';
import { useMutation, useQuery } from 'react-query';

import { useModel, UseModelProps } from '../../api-client';

type UseGuestPayorPaymentRequestDetailsProps = Omit<
  UseModelProps<typeof PublicPayorApiClient.getPaymentRequestDetails>,
  'id'
> & {
  paymentRequestLink: string;
};

export {
  UserTypes as GuestPayorUserTypes,
  OTPVerifyForbiddenErrorCode as OTPVerifyErrorCodes,
} from '@melio/platform-api-axios-client';

export const useGuestPayorPaymentRequestDetails = (props: UseGuestPayorPaymentRequestDetailsProps) =>
  useModel({
    ...props,
    id: props.paymentRequestLink,
    queryKey: 'PublicPayorApi',
    queryFn: PublicPayorApiClient.getPaymentRequestDetails,
  });

export const useGuestPayorOnboarding = () => {
  const { mutateAsync, ...rest } = useMutation<PostPayorOnboardingResponseData, ApiError, PostPayorOnboardingRequest>(
    async ({ paymentRequestLink }) => {
      const response = await PayorApiClient.postPayorOnboarding({ paymentRequestLink });
      return response.data.data;
    }
  );

  return { ...rest, onboarding: mutateAsync };
};

export const useGuestPayorOtp = () => {
  const { mutateAsync: sendVerificationCodeMutateAsync } = useMutation<unknown, ApiError, PostSendOTPRequest>(
    ({ email, partnerName }) => PublicPayorApiClient.postPublicPayorOtpStart({ email, partnerName })
  );

  const { mutateAsync: verifyCodeMutateAsync, ...restVerify } = useMutation<
    PayorVerifyOTPResponseProperties,
    ApiError,
    PostVerifyOTPRequest
  >(async ({ email, partnerName, otp }) => {
    const response = await PublicPayorApiClient.postPublicPayorOtpVerify({
      email,
      partnerName,
      otp,
    });
    return response.data.data;
  });

  return {
    sendVerificationCode: sendVerificationCodeMutateAsync,
    verifyCode: verifyCodeMutateAsync,
    isLoadingVerifyCode: restVerify.isLoading,
  };
};

export const useUpdatedPaymentRequestLink = ({
  paymentRequestLink,
  enabled,
}: {
  paymentRequestLink: string;
  enabled: boolean;
}) =>
  useQuery<string | null, ARPlatformError>(
    ['updatedPaymentRequestLink', paymentRequestLink],
    () =>
      PublicPayorApiClient.getUpdatedPaymentRequestLink(paymentRequestLink).then(
        (response) => response.data.data.updatedPaymentRequestLink
      ),
    { enabled }
  );

export const usePaymentWidgetUrl = ({
  fundingSourceType,
  paymentRequestLink,
}: {
  fundingSourceType: PaymentWidgetFundingSourceType;
  paymentRequestLink: string;
}) =>
  useQuery<string | null, ARPlatformError>(['paymentWidgetUrl', paymentRequestLink, fundingSourceType], () =>
    PublicPayorApiClient.getPaymentWidgetUrl(fundingSourceType, paymentRequestLink).then(
      (response) => response.data.data.paymentWidgetUrl
    )
  );

type UseGuestPayorUserDetailsProps = {
  email: string;
  paymentRequestLink: string;
  enabled: boolean;
};

export const useGuestPayorUserDetailsByEmail = ({
  email,
  paymentRequestLink,
  enabled,
}: UseGuestPayorUserDetailsProps) =>
  useQuery(
    ['guestPayorUserDetailsByMail', paymentRequestLink, email],
    () =>
      PublicPayorApiClient.getPublicPayorPaymentRequestUserDetailsByEmail(paymentRequestLink, email).then(
        (response) => response.data.data
      ),
    { enabled }
  );

export const useGuestPayorUserDetails = ({ paymentRequestLink }: { paymentRequestLink: string }) =>
  useQuery(['guestPayorUserDetails', paymentRequestLink], () =>
    PublicPayorApiClient.getPublicPayorPaymentRequestUserDetails(paymentRequestLink).then(
      (response) => response.data.data
    )
  );
