import { UserDetails } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { SectionBlock } from './SectionBlock';

type Props = { markedAsPayBy?: UserDetails; scheduledDate: Date };
export const MarkedAsPaidSection = ({ markedAsPayBy, scheduledDate }: Props) => {
  const { formatMessage, formatDate } = useMelioIntl();

  return (
    <SectionBlock
      title={{ label: formatMessage('widgets.paymentDetails.markedAsPay.title') }}
      body={{
        label: formatMessage('widgets.paymentDetails.markedAsPay.name', {
          lastName: markedAsPayBy?.lastName ?? '',
          firstName: markedAsPayBy?.firstName ?? '',
        }),
      }}
      description={{
        label: formatMessage('widgets.paymentDetails.markedAsPay.subtitle', {
          scheduledDate: formatDate(scheduledDate, { dateStyle: 'medium' }),
        }),
      }}
    />
  );
};
