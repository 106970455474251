import { Container } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-provider';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';
import { PaymentFlowDoneAction } from '../../../../types';

type AutoPayActionsProps = {
  onDone: (action: PaymentFlowDoneAction) => void;
};

export const AutoPayActions = ({ onDone }: AutoPayActionsProps) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();

  return (
    <Container justifyContent="center">
      <NewSinglePaymentStepLayout.SecondaryNextButton
        onClick={() => {
          track('Payment', 'Click', {
            PageName: 'payment-scheduled',
            Intent: 'go-to-dashboard',
            Cta: 'go-to-dashboard',
          });
          onDone('goToDashboard');
        }}
        size="medium"
        label={formatMessage('activities.paymentScheduled.screens.paymentScheduled.continue')}
      />
    </Container>
  );
};
