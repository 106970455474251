import { Collaborator, FundingSource, FundingSourceEntitlementType } from '@melio/platform-api';

export const getIsCollaboratorHasFullAccess = (collaborator: Collaborator) =>
  collaborator.fundingSourceEntitlements?.entitlementType === FundingSourceEntitlementType.All;

export const getIsCollaboratorsWithOnlyOneEntitledFundingSource = (
  collaborator: Collaborator,
  fundingSourceId: FundingSource['id']
) =>
  collaborator.fundingSourceEntitlements?.fundingSourceIds.includes(fundingSourceId) &&
  collaborator.fundingSourceEntitlements?.fundingSourceIds.length === 1;
