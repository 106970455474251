import { usePaymentsLateApprovals, usePaymentsLateApprovalsEnabled } from '@melio/ap-domain';
import { Container, Group, StatusIconOutlined, StatusIconSolid, Table, Text, Tooltip } from '@melio/penny';
import { Payment } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';

export const PaymentsTabDebitDateCell = ({ payment }: { payment: Payment }) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const [isFinancingEnabled] = useFeature<boolean>(FeatureFlags.PlatformFinancing, false);
  const getPaymentsLateApprovalsEnabled = usePaymentsLateApprovalsEnabled();

  const paymentsLateApprovalsEnabled = getPaymentsLateApprovalsEnabled({ payments: [payment] });
  const paymentLateApprovalEnabled = !!paymentsLateApprovalsEnabled[payment.id]?.isEnabled;
  return (
    <Table.Cell
      data-testid={`payments-tab-row-${payment.id}-debit-date-cell`}
      placeholder={formatMessage('activities.payDashboard.paymentsTab.table.cells.notAvailable')}
    >
      {payment.scheduledDate && (
        <Group variant="vertical" spacing="xxs" alignItems="flex-start">
          <Text textStyle="body3">{formatDate(payment.scheduledDate, { dateStyle: 'medium' })}</Text>
          {isFinancingEnabled && payment?.loan && (
            <Container width="full">
              <Text textStyle="body4" color="global.neutral.900">
                {formatMessage('activities.payDashboard.paymentsTab.table.cells.debitDate.installments', {
                  installmentNumber: payment.loan.nextInstallmentNumber ?? 1,
                  totalInstallments: payment.loan.installments.length,
                })}
              </Text>
            </Container>
          )}
          {paymentLateApprovalEnabled ? <PaymentLateApprovalIndication payment={payment} /> : null}
        </Group>
      )}
    </Table.Cell>
  );
};

const PaymentLateApprovalIndication = ({ payment }: { payment: Payment }) => {
  const { formatMessage } = useMelioIntl();
  const getPaymentsLateApprovals = usePaymentsLateApprovals();

  const paymentsLateApprovals = getPaymentsLateApprovals({ payments: [payment] });
  const paymentLateApproval = paymentsLateApprovals[payment.id];
  const isDebitDateAfterToday = !!paymentLateApproval?.isDebitDateAfterToday;
  const getTooltipLabel = () => {
    if (payment.fundingSource?.type === 'bank-account') {
      return isDebitDateAfterToday
        ? formatMessage(
            'activities.payDashboard.paymentsTab.table.cells.debitDate.indication.tooltip.title.lateDebitDate.bank-account'
          )
        : formatMessage(
            'activities.payDashboard.paymentsTab.table.cells.debitDate.indication.tooltip.title.debitDateToday.bank-account'
          );
    } else if (payment.fundingSource?.type === 'card') {
      return isDebitDateAfterToday
        ? formatMessage(
            'activities.payDashboard.paymentsTab.table.cells.debitDate.indication.tooltip.title.lateDebitDate.card'
          )
        : formatMessage(
            'activities.payDashboard.paymentsTab.table.cells.debitDate.indication.tooltip.title.debitDateToday.card'
          );
    }
    return isDebitDateAfterToday
      ? formatMessage(
          'activities.payDashboard.paymentsTab.table.cells.debitDate.indication.tooltip.title.lateDebitDate.default'
        )
      : formatMessage(
          'activities.payDashboard.paymentsTab.table.cells.debitDate.indication.tooltip.title.debitDateToday.default'
        );
  };

  const label = isDebitDateAfterToday
    ? formatMessage('activities.payDashboard.paymentsTab.table.cells.debitDate.indication.lateDebitDate')
    : formatMessage('activities.payDashboard.paymentsTab.table.cells.debitDate.indication.debitDateToday');
  const tooltipLabel = getTooltipLabel();

  return (
    <Group variant="horizontal" spacing="xxs" alignItems="center">
      <Text textStyle="body4" color="global.neutral.900">
        {label}
      </Text>

      <Tooltip
        label={tooltipLabel}
        triggerAriaLabel={tooltipLabel}
        shouldAddTriggerFocus
        data-testid="payment-late-approval-indication-tooltip"
      >
        {isDebitDateAfterToday ? (
          <StatusIconSolid size="small" variant="warning" data-testid="late-approval-indication-warning-icon" />
        ) : (
          <StatusIconOutlined size="small" variant="neutral" data-testid="late-approval-indication-info-icon" />
        )}
      </Tooltip>
    </Group>
  );
};
