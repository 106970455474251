import React from 'react';
import { Container, Group, Text, useBreakpoint } from '@melio/penny';

import { SettingsCard, SettingsCardProps } from '@/cl/components/SettingsCard/SettingsCard.component';
import { SettingsGroupEnum } from '@/partnersConfig.types';

interface SettingsGroupProps {
  title: React.ReactNode;
  settings: Array<SettingsCardProps>;
  type?: SettingsGroupEnum | undefined;
}

export const SettingsGroupWidget = ({ title, settings, type }: SettingsGroupProps) => {
  const { isExtraSmallScreen } = useBreakpoint();
  return (
    <Container
      data-component="SettingsGroup"
      data-testid={`settings-group${type ? '-' + type : ''}`}
      overflow="initial"
    >
      <Group spacing="xs" variant="vertical">
        {title ? (
          <Container paddingX={isExtraSmallScreen ? 's' : 'xs'}>
            <Text
              textStyle={isExtraSmallScreen ? 'body3Semi' : 'body4Semi'}
              color="global.neutral.900"
              data-testid={`settings-group-title`}
            >
              {title}
            </Text>
          </Container>
        ) : null}
        <Group as="ul" variant="vertical" spacing="xxs">
          {settings.map((props) => (
            <Container
              as="li"
              key={props.cardName}
              paddingX={isExtraSmallScreen ? 'xs' : 'none'}
              overflow="initial"
              paddingLeft="xxxs"
            >
              <SettingsCard {...props} />
            </Container>
          ))}
        </Group>
      </Group>
    </Container>
  );
};
