import React, { useRef } from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';
import { NakedButton, Text } from '@melio/penny';
import { FileInfo } from '@melio/platform-api';

import { AttachIcon, InvoiceIcon } from '@/cl/icons/system';
import { useAppTheme } from '@/cl/theme/theme.hooks';
import { WithLoading } from '@/hoc/withLoading.hoc';
import { InvoiceImageTheme } from './InvoiceImage.component.theme';

type ButtonConfig = {
  text: string;
  onClick?: VoidFunction;
};

export type InvoiceImageProps = {
  editMode?: boolean;
  onFileUploaded: (fileList: FileList | null) => void;
  onFileDeleted: () => void;
  file?: FileInfo | null;
  isProcessingFile: boolean;
  buttonsConfig?: {
    deleteButton: ButtonConfig;
    replaceButton: ButtonConfig;
    uploadButton: ButtonConfig;
  };
};

export const InvoiceImage = (props: InvoiceImageProps) => {
  const { editMode, file, buttonsConfig, onFileDeleted, isProcessingFile, onFileUploaded } = props;
  const { deleteButton, replaceButton, uploadButton } = buttonsConfig || {};
  const styles = useAppTheme(InvoiceImageTheme, props);
  const uploadInputFile = useRef<HTMLInputElement>(null);

  const url = file?.previewUrls[0] || file?.url;

  const onUploadFileClick = () => {
    uploadInputFile.current?.click();
  };
  const onDownloadFileClick = () => {
    if (file?.url) window.location.href = file.url;
  };
  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    onFileUploaded(fileList);
  };

  const imageBoxContainerProps = editMode
    ? {
        onClick: onUploadFileClick,
        role: 'button',
        tabIndex: 0,
      }
    : {
        onClick: onDownloadFileClick,
        role: 'group',
      };

  return (
    <Box sx={styles['container']}>
      <WithLoading isLoading={isProcessingFile} isAbsoluteCenter overContent>
        <Box sx={styles['imageContainer']} {...imageBoxContainerProps}>
          {url && (
            <Box sx={styles['iconContainer']}>
              <AttachIcon />
              <Text>{file?.previewUrls?.length > 1 ? file.previewUrls.length : ''}</Text>
            </Box>
          )}
          <Image src={url} fallback={<InvoiceIcon />} objectFit="contain" sx={styles['image']} alt="invoice" />
        </Box>
      </WithLoading>
      {editMode && (
        <Box sx={styles['buttonsContainer']}>
          {url ? (
            <Flex justifyContent={'space-between'} width={'100%'} alignItems={'baseline'}>
              <Box>
                <NakedButton
                  onClick={onFileDeleted}
                  isDisabled={isProcessingFile}
                  variant="critical"
                  label={deleteButton?.text ?? ''}
                />
              </Box>
              <Box>
                <input
                  data-testid="file-upload"
                  style={{ display: 'none' }}
                  ref={uploadInputFile}
                  onChange={handleFileUpload}
                  type="file"
                  accept="image/png,image/jpeg,application/pdf"
                />
                <NakedButton
                  variant="primary"
                  onClick={onUploadFileClick}
                  isDisabled={isProcessingFile}
                  label={replaceButton?.text ?? ''}
                />
              </Box>
            </Flex>
          ) : (
            <Box>
              <input
                data-testid="file-upload"
                style={{ display: 'none' }}
                ref={uploadInputFile}
                onChange={handleFileUpload}
                type="file"
                accept="image/png,image/jpeg,application/pdf"
              />
              <NakedButton
                variant="primary"
                onClick={onUploadFileClick}
                isDisabled={isProcessingFile}
                label={uploadButton?.text ?? ''}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
