import { CheckAccountFormModel, VendorCheckDetailsPayorForm } from '@melio/ap-widgets';
import { useFormSubmissionController } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { ReactElement } from 'react';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';

export type EditVendorCheckDetailsScreenProps = {
  title?: string;
  isLoading?: boolean;
  isSaving?: boolean;
  defaultValues?: Partial<CheckAccountFormModel>;
  banner?: ReactElement;
  onBack: VoidFunction;
  onClose: VoidFunction;
  onDone: (data: CheckAccountFormModel) => void;
};

export const EditVendorCheckDetailsScreen = forwardRef<EditVendorCheckDetailsScreenProps, 'div'>(
  ({ title, isLoading, isSaving, defaultValues, banner, onBack, onClose, onDone, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { onSubmissionStateChange, cancelButtonProps, submitButtonProps } =
      useFormSubmissionController<CheckAccountFormModel>();

    return (
      <NewSinglePaymentStepLayout
        isLoading={isLoading}
        data-component="EditVendorCheckDetailsActivity.EditVendorCheckDetailsScreen"
        data-testid="edit-vendor-check-details-activity-edit-vendor-check-details-screen"
        {...props}
        ref={ref}
        headerContent={
          <NewSinglePaymentStepLayout.Header>
            <NewSinglePaymentStepLayout.CloseButton onClick={onClose} />
            <NewSinglePaymentStepLayout.BackButton {...cancelButtonProps} onClick={onBack} />
          </NewSinglePaymentStepLayout.Header>
        }
        footerContent={
          <NewSinglePaymentStepLayout.Actions>
            <NewSinglePaymentStepLayout.NextButton
              {...submitButtonProps}
              label={formatMessage('activities.editVendorCheckDetails.screens.editVendorCheckDetails.continue')}
            />
          </NewSinglePaymentStepLayout.Actions>
        }
      >
        <NewSinglePaymentStepLayout.Title data-testid="edit-vendor-check-details-activity-edit-vendor-check-details-screen-title">
          {title ?? formatMessage('activities.editVendorCheckDetails.screens.editVendorCheckDetails.title')}
        </NewSinglePaymentStepLayout.Title>
        {banner}
        <NewSinglePaymentStepLayout.Content>
          <VendorCheckDetailsPayorForm
            onSubmit={onDone}
            isSaving={isSaving}
            defaultValues={defaultValues}
            onSubmissionStateChange={onSubmissionStateChange}
          />
        </NewSinglePaymentStepLayout.Content>
      </NewSinglePaymentStepLayout>
    );
  }
);

EditVendorCheckDetailsScreen.displayName = 'EditVendorCheckDetailsActivity.EditVendorCheckDetailsScreen';
