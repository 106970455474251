import {
  InboxItemBillTypeEnum,
  InboxItemPaymentRequestTypeEnum,
  InboxItemScannedInvoiceTypeEnum,
  useInboxItems,
} from '@melio/platform-api';

import { usePayDashboardSorting } from '../../PayDashboardSortingProvider';
import { PayDashboardSortingType } from '../../types';
import { usePayDashboardPagination } from '../Pagination';
import { BillsTabItem } from './types';
import { convertBillsTabSortToApiSort } from './utils';

export const useBillsTabItems = ({
  enabled = true,
  searchTerm,
  scope,
  sort,
}: {
  enabled?: boolean;
  searchTerm?: string | null;
  scope?: string;
  sort?: PayDashboardSortingType['sortingState'];
}) => {
  const { pageSize } = usePayDashboardPagination();
  const activeSort = usePayDashboardSorting();
  const sortParam = sort ?? activeSort?.sortingState;

  return useInboxItems<BillsTabItem>({
    enabled,
    cacheTime: 0,
    staleTime: 0,
    scope,
    params: {
      limit: pageSize,
      sort: sortParam ? convertBillsTabSortToApiSort(sortParam) : undefined,
      inboxItemTypes: [
        InboxItemBillTypeEnum.Bill,
        InboxItemPaymentRequestTypeEnum.PaymentRequest,
        InboxItemScannedInvoiceTypeEnum.ScannedInvoice,
      ],
      ...(searchTerm ? { searchTerm } : {}),
    },
  });
};
