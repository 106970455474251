import { getVendorNameForNotificationMessage } from '@melio/ap-widgets';
import { StatusModal, Text } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { ModelError, useVendors, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useEffect } from 'react';

import { MonitoredAction } from '../../../../../../monitoring';

type Props = {
  vendor: Vendor;
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  onFail?: (e: ModelError) => void;
};

export const ConfirmVendorDeletionModal = withAnalyticsContext<Props>(
  ({ vendor, isOpen, onClose, onSuccess, onFail, setAnalyticsProperties }) => {
    const { formatMessage } = useMelioIntl();
    const { startAction, endAction } = useMonitoring<MonitoredAction>();
    const { delete: deleteVendor, isMutating } = useVendors({ enabled: false });
    const { track } = useAnalytics();

    setAnalyticsProperties({
      PageName: 'delete-vendor',
      Intent: 'delete-vendor',
      VendorId: vendor.id,
    });

    useEffect(() => {
      if (isOpen) {
        track('Vendor', 'View');
      }
    }, [isOpen, track]);

    const onDeletionConfirm = async () => {
      try {
        startAction('vendor_delete');
        track('Vendor', 'Click', {
          Cta: 'yes-delete-this-vendor',
        });
        await deleteVendor(vendor.id);
        onSuccess?.();
        endAction('vendor_delete');
      } catch (e) {
        onFail?.(e as ModelError);
      }
    };

    const onExitHandler = () => {
      track('Vendor', 'Click', {
        Cta: 'exit',
      });
      onClose();
    };

    const onCancelHandler = () => {
      track('Vendor', 'Click', {
        Cta: 'cancel',
      });
      onClose();
    };

    const vendorName = getVendorNameForNotificationMessage(vendor);

    return (
      <StatusModal
        role="alertdialog"
        aria-modal="true"
        data-testid="delete-vendor-modal"
        isOpen={isOpen}
        onClose={onExitHandler}
        variant="alert"
        header={formatMessage('activities.payDashboard.deleteVendorModal.confirm.title', { vendorName })}
        primaryButton={{
          label: formatMessage('activities.payDashboard.deleteVendorModal.confirm.confirm'),
          onClick: onDeletionConfirm,
          variant: 'primary',
          isLoading: isMutating,
        }}
        secondaryButton={{
          label: formatMessage('activities.payDashboard.deleteVendorModal.confirm.cancel'),
          onClick: onCancelHandler,
          variant: 'tertiary',
          isDisabled: isMutating,
        }}
      >
        <Text>
          {formatMessage('activities.payDashboard.deleteVendorModal.confirm.body', {
            vendorName,
          })}
        </Text>
      </StatusModal>
    );
  }
);
