import React, { useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { MicroDepositModalRefHandlers, MicroDepositModalWrapper } from '@melio/ap-activities';
import { useFundingSourceIconOrImage, useFundingSourcePermissions } from '@melio/ap-domain';
import { useFundingSourceCardDisplayText } from '@melio/ap-widgets';
import {
  ActionsDropdownMenu,
  ActionsDropdownMenuItemProps,
  Card,
  NakedButton,
  Pill,
  Text,
  useTheme,
} from '@melio/penny';
import { FundingSource } from '@melio/platform-api';

import { usePlatformIntl } from '@/translations/Intl';

type Props = {
  actions: ActionsDropdownMenuItemProps[];
  fundingSource: FundingSource;
  isMethodAssignedByFirm: boolean;
};

export const ExposedSelectedMethodCard = ({ actions, fundingSource, isMethodAssignedByFirm }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { formatMessage } = usePlatformIntl();
  const theme = useTheme();
  const { getDisplayText } = useFundingSourceCardDisplayText();
  const microDepositRef = React.useRef<MicroDepositModalRefHandlers>(null);
  const { canUpdate } = useFundingSourcePermissions({ fundingSource });

  const openMicroDepositsModal = React.useCallback(() => {
    microDepositRef.current?.openModal();
  }, [microDepositRef]);

  const title = getDisplayText(fundingSource);
  const description = fundingSource.isVerified
    ? formatMessage('widgets.billingSettings.paymentMethod.card.exist.description')
    : formatMessage('widgets.billingSettings.paymentMethod.card.unverified.description', {
        link:
          canUpdate && !isMethodAssignedByFirm ? (
            <NakedButton
              variant="secondary"
              onClick={openMicroDepositsModal}
              label={formatMessage('widgets.billingSettings.paymentMethod.card.unverified.link')}
              data-testid={`verify-${fundingSource.id}-funding-source-link`}
            />
          ) : null,
      });
  const fundingSourceIcon = useFundingSourceIconOrImage(fundingSource);

  return (
    <>
      <Card variant="flat" paddingX="m" paddingY="m" data-testid="billing-method-card">
        <Flex w="inherit" justify="space-between" align="flex-start">
          <Flex align="center">
            <Flex mr="s">{fundingSourceIcon}</Flex>
            <Flex direction="column">
              <Flex align="center" textStyle="body2Semi" mb="xxs">
                <Box mr="xxs">
                  <Text textStyle="body2Semi">{title}</Text>
                </Box>
                {isMethodAssignedByFirm ? (
                  <Pill
                    status="neutral"
                    label={formatMessage('widgets.billingSettings.paymentMethod.card.firmBadge')}
                  />
                ) : null}
              </Flex>
              <Box textStyle={{ xs: 'body5Semi', s: 'body4' }} color={theme.colors.global.neutral['800']}>
                <Text>{description}</Text>
              </Box>
            </Flex>
          </Flex>
          <ActionsDropdownMenu
            isOpen={isMenuOpen}
            onOpenChange={setIsMenuOpen}
            size="small"
            label="menu action"
            data-testid="billing-method-actions-menu"
            items={actions}
          />
        </Flex>
      </Card>
      <MicroDepositModalWrapper ref={microDepositRef} fundingSourceId={fundingSource.id} />
    </>
  );
};
