import { FormSelectNewOption } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import {
  ACCOUNTHOLDER_DECISION_MAKER_KEY,
  AccountHolderForm,
  OTHER_DECISION_MAKER_KEY,
  OwnershipDetails,
} from '../types';

export const useDecisionMakerOptions = ({
  owners,
  accountHolder,
}: {
  owners: OwnershipDetails[];
  accountHolder: AccountHolderForm;
}) => {
  const { formatMessage } = useMelioIntl();

  const getOption = (optionFirstName: string, optionLastName: string, id: string) => ({
    label: `${optionFirstName} ${optionLastName}`,
    value: id,
    testId: `decision-maker-${optionFirstName}-${optionLastName}`,
  });

  const options: FormSelectNewOption[] =
    owners?.map((o) => getOption(o.ownerFirstName, o.ownerLastName, o.uboId)) ?? [];

  !accountHolder.isUbo &&
    options.push(getOption(accountHolder.firstName, accountHolder.lastName, ACCOUNTHOLDER_DECISION_MAKER_KEY));

  options.push({
    label: formatMessage(
      'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.decisionMakerDetailsCard.decisionMakerField.otherOption.label'
    ),
    value: OTHER_DECISION_MAKER_KEY,
    testId: `decision-maker-${OTHER_DECISION_MAKER_KEY}`,
  });

  return options;
};
