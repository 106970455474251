import { Account, OrganizationBeneficialOwner } from '@melio/platform-api';
import { useCallback, useState } from 'react';

import { AddInternationalDeliveryMethodActivityProps, AddInternationalDeliveryMethodStep } from './types';

const initialStep: AddInternationalDeliveryMethodStep = 'INTERNATIONAL_BANK_DETAILS';

export const useAddInternationalDeliveryMethodStep = ({
  onFirstStepBack,
  isMissingLegalDetails,
  isMissingOrganizationBeneficialOwnersDetails,
  organizationBeneficialOwners,
  handleDone,
}: {
  onFirstStepBack: AddInternationalDeliveryMethodActivityProps['onBack'];
  isMissingLegalDetails: boolean;
  isMissingOrganizationBeneficialOwnersDetails: boolean;
  organizationBeneficialOwners?: OrganizationBeneficialOwner[];
  handleDone: () => void;
}) => {
  const [currentStep, setCurrentStep] = useState<AddInternationalDeliveryMethodStep>(initialStep);

  const goToInitialStep = () => setCurrentStep(initialStep);

  const goToPreviousStep = (): void => {
    if (currentStep === initialStep) {
      return onFirstStepBack();
    }

    const previousStepMap: Omit<
      Record<AddInternationalDeliveryMethodStep, AddInternationalDeliveryMethodStep>,
      typeof initialStep
    > = {
      INTERNATIONAL_VENDOR_DETAILS: 'INTERNATIONAL_BANK_DETAILS',
      BUSINESS_LEGAL_DETAILS: 'INTERNATIONAL_VENDOR_DETAILS',
      ADD_ORGANIZATION_BENEFICIAL_OWNERS: isMissingLegalDetails
        ? 'BUSINESS_LEGAL_DETAILS'
        : 'INTERNATIONAL_VENDOR_DETAILS',
    };

    setCurrentStep(previousStepMap[currentStep]);
  };

  const handleBankDetailsNextStep = () => setCurrentStep('INTERNATIONAL_VENDOR_DETAILS');

  const handleVendorDetailsNextStep = useCallback(() => {
    if (isMissingLegalDetails) {
      return setCurrentStep('BUSINESS_LEGAL_DETAILS');
    } else if (isMissingOrganizationBeneficialOwnersDetails) {
      return setCurrentStep('ADD_ORGANIZATION_BENEFICIAL_OWNERS');
    } else {
      handleDone();
    }
  }, [setCurrentStep, handleDone, isMissingLegalDetails, isMissingOrganizationBeneficialOwnersDetails]);

  const handleBusinessInfoNextStep = useCallback(
    (account: Account) => {
      if (account?.company.taxInfo?.type === 'EIN' && !organizationBeneficialOwners?.length) {
        return setCurrentStep('ADD_ORGANIZATION_BENEFICIAL_OWNERS');
      } else {
        handleDone();
      }
    },
    [setCurrentStep, handleDone, organizationBeneficialOwners]
  );

  const handleOwnersNextStep = () => {
    handleDone();
  };

  return {
    currentStep,
    goToInitialStep,
    goToPreviousStep,
    handleBankDetailsNextStep,
    handleVendorDetailsNextStep,
    handleBusinessInfoNextStep,
    handleOwnersNextStep,
    goToStep: setCurrentStep,
  };
};
