import { useMelioQueryClient, useModelApi, UseModelApiProps } from '@melio/api-client';
import {
  EbillsSubscriptionOptions,
  PostVendorEbillsSubscriptionRequest,
  VendorsApiClient,
} from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useMutation } from 'react-query';
export type {
  EbillsSubscriptionOptions,
  EbillsSubscriptionOptionsField,
  EbillsSubscriptionOptionsReceivingOption,
  PostVendorEbillsSubscriptionRequest,
} from '@melio/platform-api-axios-client';
export {
  EbillsSubscriptionOptionsReceivingOptionNameEnum,
  InvoiceDataSourceEntityTypeEnum,
  EBillStatus as VendorEBillStatusEnum,
} from '@melio/platform-api-axios-client';

type Props = UseModelApiProps<typeof VendorsApiClient.getVendorEbillsSubscriptionForm, EbillsSubscriptionOptions>;

export const useEBillsSubscription = (props: Props) => {
  const query = useModelApi({
    ...props,
    queryKey: ['EBillsSubscriptionsApi', props.id],
    queryFn: VendorsApiClient.getVendorEbillsSubscriptionForm,
  });

  const queryClient = useMelioQueryClient();

  const createEBillsSubscription = useMutation(
    query.queryKey,
    ({
      vendorId,
      ebillsSubscription: eBillsSubscription,
    }: {
      vendorId: string;
      ebillsSubscription: PostVendorEbillsSubscriptionRequest;
    }) => VendorsApiClient.postEbillsSubscription(eBillsSubscription, vendorId),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries('VendorsApi');
      },
    }
  );

  const cancelEBillsSubscription = useMutation(
    query.queryKey,
    ({ vendorId }: { vendorId: string }) => VendorsApiClient.deleteEbillsSubscription(vendorId),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries('VendorsApi');
      },
    }
  );

  return {
    ...query,
    createEBillsSubscription: createEBillsSubscription.mutateAsync,
    cancelEBillsSubscription: cancelEBillsSubscription.mutateAsync,
    _mutations: {
      ...query._mutations,
      createEBillsSubscription,
      cancelEBillsSubscription,
    },
  };
};
