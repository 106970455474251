import { useAnalytics } from '@melio/platform-analytics';

type CommonEventFields = {
  action: 'add' | 'remove';
  selected: number;
  DueDate?: Date;
  Amount: number;
  VendorId?: string;
};
export type TrackBatchSelectionProps = CommonEventFields & { PaymentId?: string } & { PaymentRequestId?: string } & {
  BillId?: string;
};

export const useTrackBatchSelection = () => {
  const { track } = useAnalytics();
  const trackBatchSelection = (eventData: TrackBatchSelectionProps) => track('StartBatchSelection', 'Chose', eventData);

  return { trackBatchSelection };
};
