import { useMelioQueryClient } from '@melio/api-client';
import { CustomersApiClient } from '@melio/platform-api-axios-client';

import { useModel, UseModelProps } from '../../api-client';

export type UseCustomerProps = UseModelProps<
  typeof CustomersApiClient.getCustomerCustomerId,
  typeof CustomersApiClient.updateCustomerById,
  typeof CustomersApiClient.postCustomer
>;

export const useCustomer = ({ onCreate, onUpdate, ...props }: UseCustomerProps = {}) => {
  const queryClient = useMelioQueryClient();
  return useModel({
    ...props,
    queryKey: 'CustomersApi',
    queryFn: CustomersApiClient.getCustomerCustomerId,
    createFn: CustomersApiClient.postCustomer,
    deleteFn: CustomersApiClient.deleteCustomerCustomerId,
    updateFn: CustomersApiClient.updateCustomerById,
    onUpdate: (...args) => {
      void queryClient.invalidateQueries('CustomersDashboardApi', 'collection');
      void queryClient.invalidateQueries('CustomersDashboardApi', 'paginated-collection');
      void onUpdate?.(...args);
    },
    onCreate: (...args) => {
      void queryClient.invalidateQueries('CustomersDashboardApi', 'collection');
      void queryClient.invalidateQueries('CustomersDashboardApi', 'paginated-collection');
      void onCreate?.(...args);
    },
    onDelete: () => {
      void queryClient.invalidateQueries('CustomersDashboardApi', 'collection');
      void queryClient.invalidateQueries('CustomersDashboardApi', 'paginated-collection');
    },
  });
};

export type CustomerModel = ReturnType<typeof useCustomer>;
