import { CardNetwork, FundingSource, FundingSourceType, Vendor } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useState } from 'react';

import { VendorDetailsModalState } from '../VendorDetails/components/types';
import { getVendorRequiredFormFields, isSupportedVendor } from '../VendorDetails/utils';

const EMPTY_STATE: VendorDetailsModalState = { isOpen: false, requiredFormFields: [], cardNetwork: CardNetwork.Other };

export const useVendorDetailsModal = () => {
  const [vendorDetailsModalState, setVendorDetailsModalState] = useState(EMPTY_STATE);
  const [shouldShowVisaVendorDetailsModal] = useFeature<boolean>(FeatureFlags.ShowVendorDetailsModalForVisa, false);

  const onOpenVendorDetailsModal = (vendor: Vendor | undefined, fundingSource: FundingSource | null | undefined) => {
    const cardNetwork = fundingSource?.type === FundingSourceType.Card ? fundingSource.details.network : undefined;
    const requiredFormFields = getVendorRequiredFormFields(vendor, fundingSource);
    if (!cardNetwork || requiredFormFields.length === 0) {
      setVendorDetailsModalState(EMPTY_STATE);
    } else {
      setVendorDetailsModalState({ isOpen: true, cardNetwork, requiredFormFields });
    }
  };

  const onCloseVendorDetailsModal = () => {
    setVendorDetailsModalState(EMPTY_STATE);
  };

  const isVendorHasMissingDetails = ({
    vendor,
    selectedFundingSource,
  }: {
    vendor?: Vendor;
    selectedFundingSource: FundingSource;
  }): boolean => {
    if (selectedFundingSource.type !== FundingSourceType.Card || !isSupportedVendor(vendor)) {
      return false;
    }
    const requiredFormFields = getVendorRequiredFormFields(vendor, selectedFundingSource);
    if (!Object.values(requiredFormFields).some(Boolean)) {
      return false;
    }
    switch (selectedFundingSource.details.network) {
      case CardNetwork.Visa:
        return shouldShowVisaVendorDetailsModal;
      case CardNetwork.Amex:
        return true;
      default:
        return false;
    }
  };

  return {
    vendorDetailsModalState,
    onOpenVendorDetailsModal,
    onCloseVendorDetailsModal,
    isVendorHasMissingDetails,
  };
};
