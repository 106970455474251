import { KeyboardEvent, useRef } from 'react';

export const useKeyDownHandlerButtonClickSimulation = (key: KeyboardEvent['key']) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === key) {
      event.preventDefault(); // Prevent the default form submission
      buttonRef.current?.click();
    }
  };

  return { buttonRef, handleKeyDown };
};
