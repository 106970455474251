import { InvoiceLineItem } from '@melio/ar-domain';

import { InvoiceFormValuesLineItem } from '../types';

export const createDefaultFormLineItem = (): InvoiceFormValuesLineItem => ({
  quantity: 1,
  // In order to display an empty field in the form, price needs to be null (or an empty string) here,
  // otherwise if we set the default value it to 0 it will display 0 in the form field
  price: null as never,
  catalogItemId: '',
  taxable: false,
});

export const parseInvoiceLineItem = (lineItems: InvoiceLineItem): InvoiceFormValuesLineItem => ({
  catalogItemId: lineItems.catalogItemId,
  price: lineItems.price,
  quantity: lineItems.quantity,
  taxable: lineItems.taxable,
});
