/* eslint-disable react-hooks/exhaustive-deps */
import { StatusModal, Text } from '@melio/penny';
import { useAnalytics, useAnalyticsContext } from '@melio/platform-analytics';
import { Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';

type Props = {
  vendor: Vendor;
  isOpen: boolean;
  onClose: () => void;
};

export const DeleteVendorErrorModal = ({ isOpen, onClose, vendor }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();

  useAnalyticsContext({
    globalProperties: {
      PageName: 'vendor-cant-be-deleted',
      Intent: 'delete-vendor',
      VendorId: vendor.id,
    },
  });

  useEffect(() => {
    if (isOpen) {
      track('Dashboard', 'View');
    }
  }, [isOpen]);

  const onExitHandler = () => {
    track('Dashboard', 'Click', {
      Cta: 'exit',
    });
    onClose();
  };

  const onCloseHandler = () => {
    track('Dashboard', 'Click', {
      Cta: 'close',
    });
    onClose();
  };

  return (
    <StatusModal
      data-testid="delete-vendor-failed-modal"
      isOpen={isOpen}
      onClose={onExitHandler}
      variant="cancel"
      header={formatMessage('activities.payDashboard.deleteVendorModal.failed.title')}
      secondaryButton={{
        label: formatMessage('activities.payDashboard.deleteVendorModal.failed.close'),
        onClick: onCloseHandler,
        variant: 'tertiary',
      }}
    >
      <Text>{formatMessage('activities.payDashboard.deleteVendorModal.failed.body')}</Text>
    </StatusModal>
  );
};
