import { Box, Flex } from '@chakra-ui/react';
import { CardProps, Currency, Icon, Pill, PillProps, Text } from '@melio/penny';
import { Bill } from '@melio/platform-api';

import { GroupItemTheme } from '@/cl/components/PayCard/PayCard.component.theme';
import { useAppTheme } from '@/cl/theme/theme.hooks';
import { useDisclosure } from '@/hooks/useDisclosure';
import { usePlatformIntl } from '@/translations/Intl';
import { PaymentType } from '@/types/accounting.types';
import { GroupItemType } from '@/types/payDashboard.types';
import { ContactSummary } from '../ContactSummary/ContactSummary.component';
import { HighlightedText } from '../HighlightedText/HighlightedText.component';

export type PayCardTagStatus = PillProps['status'];

type PayCardProps = {
  vendorName: string;
  topTag: {
    tagText?: string;
    status?: PayCardTagStatus;
  } | null;
  bodyTag?: {
    tagText: string;
    status?: PayCardTagStatus;
  };
  dateText?: string;
  recurringText?: string;
  itemType: GroupItemType;
  amount: number;
  invoiceNumber?: string | null;
  bills?: Bill[];
  paymentType?: PaymentType;
  footerText?: string;
  highlightedText?: string;
  shouldShowCheckbox?: boolean;
  isChecked?: boolean;
  onCheck?: (checked: boolean) => void;
} & CardProps;

export const GroupItemCard = (props: PayCardProps) => {
  const {
    vendorName,
    highlightedText,
    topTag,
    bodyTag,
    dateText,
    recurringText,
    invoiceNumber,
    bills,
    paymentType,
    amount,
    footerText,
    itemType,
    shouldShowCheckbox,
    isChecked,
    onCheck,
  } = props;
  const theme = useAppTheme(GroupItemTheme, { itemType });
  const { isOpen: isHover, onOpen: onMouseEnter, onClose: onMouseLeave } = useDisclosure();

  const { formatMessage } = usePlatformIntl();

  const Top = () => {
    return (
      <Flex sx={theme['topContainer']}>
        <ContactSummary
          title={vendorName}
          size="small"
          highlightedText={highlightedText}
          shouldShowCheckbox={shouldShowCheckbox && isHover}
          isChecked={isChecked}
          onCheckedChange={onCheck}
        />
        <Flex>
          {recurringText && <Icon size="small" type="repeat" color="inherit" />}
          {topTag && (
            <Box ml={3}>
              <Pill type="secondary" status={topTag.status || 'promotional'} label={topTag.tagText ?? ''} />
            </Box>
          )}
        </Flex>
      </Flex>
    );
  };

  const Body = () => {
    let billsText;
    if (bills && bills.length > 1) {
      billsText = formatMessage('app.cl.payCard.bills.multipleBills', {
        number: bills.length,
      });
    } else {
      const cardInvoiceNumber = bills?.[0]?.invoice.number || invoiceNumber;
      if (cardInvoiceNumber) {
        billsText = formatMessage('app.cl.payCard.bills.singleBill', {
          invoiceNumber: cardInvoiceNumber,
        });
      }
    }
    return (
      <Flex data-component={'body-pay-card'} sx={theme['bodyContainer']}>
        <Flex data-component={'body-baseline-pay-card'} sx={theme['bodyBaseLine']}>
          <Flex direction={'column'} height="32px" justifyContent={'center'}>
            {dateText && (
              <Text textStyle="body4Semi" color="global.neutral.900">
                {dateText}
              </Text>
            )}

            {recurringText && (
              <Text textStyle="body4" color="global.neutral.900">
                {recurringText}
              </Text>
            )}
            {billsText && (
              <Text textStyle="body4" color="global.neutral.900">
                <HighlightedText highlightedText={highlightedText} text={billsText} />
              </Text>
            )}
          </Flex>
          <Flex alignItems={'center'} justifyContent={'center'}>
            {paymentType && <Icon type={paymentType === PaymentType.AP ? 'caret-down' : 'caret-up'} color="inherit" />}
            <Text textStyle="heading2Semi" as="div">
              <Currency data-testid="pay-card-currency-amount" value={amount} />
            </Text>
          </Flex>
        </Flex>
        {bodyTag ? (
          <Box mt={4}>
            <Pill type="secondary" status={bodyTag.status || 'promotional'} label={bodyTag.tagText} />
          </Box>
        ) : null}
      </Flex>
    );
  };

  const Footer = ({ text }: { text: string }) => (
    <Box data-component={'footer-pay-card'} sx={theme['footerContainer']}>
      <Text textStyle="body4" color="global.neutral.900">
        {text}
      </Text>
    </Box>
  );

  return (
    <Box onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Top />
      <Body />
      {footerText && <Footer text={footerText} />}
    </Box>
  );
};

GroupItemCard.displayName = 'GroupItemCardBase';
