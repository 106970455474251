/* eslint-disable max-lines */
import { AddressSearchWidget } from '@melio/ap-widgets';
import {
  Button,
  CollapsibleCard,
  Divider,
  Form,
  NakedButton,
  Typography,
  useFieldArray,
  UseMelioFormResults,
  useWatch,
} from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { Fragment, useEffect } from 'react';
import type { FieldErrorsImpl, FormState } from 'react-hook-form';

import { AdditionalOwnerDetailsForm, FinancingApplicationOwnerDetailsForm } from '../types';
import { AdditionalOwnerFormFields } from './AdditionalOwnerFormFields';
import { TaxIdField } from './commonFormFields/TaxIdField';

export type Props = {
  onSubmit: VoidFunction;
  isExpanded: boolean;
  form: UseMelioFormResults<FinancingApplicationOwnerDetailsForm>;
  isDisabled: boolean;
  onExpandChange: (isExpanded: boolean) => void;
};
export const OwnershipDetailsForm = ({ isDisabled, isExpanded, onExpandChange, form, onSubmit }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();

  const { formState, registerField, getValues } = form;
  useWatch({
    control: form.control,
    name: 'address',
  });

  const formatFormFieldsErrors = (formState: FormState<FinancingApplicationOwnerDetailsForm>) =>
    Object.fromEntries(Object.entries(formState.errors).map(([key, value]) => [key, value.message]));

  const formatAddressFieldsErrors = (
    address: FieldErrorsImpl<FinancingApplicationOwnerDetailsForm['address']> | undefined
  ) => Object.fromEntries(Object.entries(address || {}).map(([key, value]) => [key, value.message]));

  const formatAdditionalOwnersFieldsErrors = (
    additionalOwner: FieldErrorsImpl<FinancingApplicationOwnerDetailsForm['additionalOwners'][0]> | undefined
  ) => Object.fromEntries(Object.entries(additionalOwner || {}).map(([key, value]) => [key, value.message]));

  useEffect(() => {
    if (Object.keys(formState.errors).length) {
      track('PaymentMethod', 'Status', {
        PageName: 'ownership-details',
        Flow: 'financing',
        Intent: 'complete-application',
        Cta: 'apply',
        FinancingExperience: 'application',
        Status: 'MissingFields',
        ErrorDetails: {
          ...formatFormFieldsErrors(formState),
          ...(formState.errors.address ? { address: formatAddressFieldsErrors(formState.errors.address) } : {}),
          ...(formState.errors.additionalOwners?.length
            ? {
                additionalOwners: formState.errors.additionalOwners?.map?.((owner) =>
                  formatAdditionalOwnersFieldsErrors(owner)
                ),
              }
            : {}),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.errors]);

  const {
    fields: additionalOwnersFields,
    append,
    remove,
  } = useFieldArray<FinancingApplicationOwnerDetailsForm>({
    control: form.control,
    name: 'additionalOwners',
    rules: {
      maxLength: 3,
    },
  });

  const addOwner = () => {
    track('PaymentMethod', 'Click', {
      PageName: 'ownership-details',
      Flow: 'financing',
      FinancingExperience: 'application',
      Cta: 'add-another-owner',
      Intent: 'ownership-details',
    });

    append({} as AdditionalOwnerDetailsForm);
  };

  return (
    <CollapsibleCard
      data-testid="ownership-details-form-card"
      onExpandChange={onExpandChange}
      isExpanded={isExpanded}
      title={formatMessage('activities.financingApplication.applicationFormScreen.ownershipDetails.title')}
      description={formatMessage('activities.financingApplication.applicationFormScreen.ownershipDetails.description')}
      isDisabled={isDisabled}
    >
      <Form columns={12} {...form.formProps}>
        <Form.Checkbox
          {...registerField('isOwnQuarter')}
          isRequired
          labelProps={{
            label: formatMessage(
              'activities.financingApplication.applicationFormScreen.ownershipDetails.isOwnQuarter.title'
            ),
          }}
          label={formatMessage(
            'activities.financingApplication.applicationFormScreen.ownershipDetails.isOwnQuarter.label'
          )}
          colSpan={12}
        />
        <Form.ContentBox colSpan={12}>
          <Typography.SectionLabel
            label={formatMessage(
              'activities.financingApplication.applicationFormScreen.ownershipDetails.ownerSectionTitle'
            )}
          />
        </Form.ContentBox>

        <Form.TextField
          {...registerField('firstName')}
          labelProps={{
            label: formatMessage(
              'activities.financingApplication.applicationFormScreen.ownershipDetails.firstName.label'
            ),
          }}
          colSpan={6}
        />
        <Form.TextField
          {...registerField('lastName')}
          labelProps={{
            label: formatMessage(
              'activities.financingApplication.applicationFormScreen.ownershipDetails.lastName.label'
            ),
          }}
          colSpan={6}
        />
        <Form.TextField
          {...registerField('jobTitle')}
          labelProps={{
            label: formatMessage(
              'activities.financingApplication.applicationFormScreen.ownershipDetails.jobTitle.label'
            ),
          }}
          colSpan={12}
        />
        <Form.TextField
          {...registerField('email')}
          labelProps={{
            label: formatMessage('activities.financingApplication.applicationFormScreen.ownershipDetails.email.label'),
          }}
          colSpan={12}
        />
        <Form.DateField
          {...registerField('dateOfBirth')}
          excludeHolidays={false}
          placeholder={formatMessage(
            'activities.financingApplication.applicationFormScreen.ownershipDetails.dateOfBirth.placeholder'
          )}
          labelProps={{
            label: formatMessage(
              'activities.financingApplication.applicationFormScreen.ownershipDetails.dateOfBirth.label'
            ),
          }}
          weekDays={[0, 1, 2, 3, 4, 5, 6]}
          colSpan={6}
        />
        <TaxIdField
          {...registerField('ssn')}
          placeholder={formatMessage(
            'activities.financingApplication.applicationFormScreen.ownershipDetails.ssn.placeholder'
          )}
          labelProps={{
            label: formatMessage('activities.financingApplication.applicationFormScreen.ownershipDetails.ssn.label'),
            tooltipProps: {
              label: formatMessage(
                'activities.financingApplication.applicationFormScreen.ownershipDetails.ssn.tooltip.label'
              ),
            },
          }}
          colSpan={6}
        />
        <Form.PhoneField
          {...registerField('phone')}
          placeholder={formatMessage(
            'activities.financingApplication.applicationFormScreen.ownershipDetails.phone.placeholder'
          )}
          labelProps={{
            label: formatMessage('activities.financingApplication.applicationFormScreen.ownershipDetails.phone.label'),
          }}
          colSpan={12}
        />
        <AddressSearchWidget
          placeholder={formatMessage(
            'activities.financingApplication.applicationFormScreen.ownershipDetails.address.placeholder'
          )}
          {...registerField('address')}
          error={
            form.formState.errors.address?.line1 || !!form.formState.errors.address
              ? {
                  message: formatMessage(
                    'activities.financingApplication.applicationFormScreen.ownershipDetails.address.validation.required'
                  ),
                }
              : undefined
          }
          labelProps={{
            label: formatMessage(
              'activities.financingApplication.applicationFormScreen.ownershipDetails.address.label'
            ),
          }}
          colSpan={12}
        />
        <Form.TextField
          {...registerField('address.city')}
          labelProps={{
            label: formatMessage(
              'activities.financingApplication.applicationFormScreen.ownershipDetails.address.city.label'
            ),
          }}
          value={getValues('address.city') as string}
          colSpan={12}
        />
        <Form.TextField
          {...registerField('address.state')}
          labelProps={{
            label: formatMessage(
              'activities.financingApplication.applicationFormScreen.ownershipDetails.address.state.label'
            ),
          }}
          value={getValues('address.state') as string}
          colSpan={6}
        />
        <Form.TextField
          {...registerField('address.postalCode')}
          labelProps={{
            label: formatMessage(
              'activities.financingApplication.applicationFormScreen.ownershipDetails.address.postalCode.label'
            ),
          }}
          value={getValues('address.postalCode') as string}
          colSpan={6}
        />
        {additionalOwnersFields.length > 0 ? (
          <Form.ContentBox colSpan={12}>
            <Typography.SectionLabel
              label={formatMessage(
                'activities.financingApplication.applicationFormScreen.ownershipDetails.additionalOwners.title'
              )}
            />
          </Form.ContentBox>
        ) : null}
        {additionalOwnersFields.map((field, index) => (
          <Fragment key={field.id}>
            {index > 0 && (
              <Form.ContentBox colSpan={12}>
                <Divider />
              </Form.ContentBox>
            )}
            <AdditionalOwnerFormFields form={form} index={index} onClickRemove={() => remove(index)} />
          </Fragment>
        ))}
        {additionalOwnersFields.length < 3 ? (
          <Form.ContentBox colSpan={12}>
            <Typography.Label
              label={formatMessage(
                'activities.financingApplication.applicationFormScreen.ownershipDetails.additionalOwners.addMore.title'
              )}
            />
            <NakedButton
              variant="secondary"
              label={formatMessage(
                'activities.financingApplication.applicationFormScreen.ownershipDetails.additionalOwners.addMore.cta'
              )}
              data-testid="add-additional-owners-button"
              onClick={addOwner}
            />
          </Form.ContentBox>
        ) : null}
      </Form>
      <Button
        {...form.submitButtonProps}
        data-testid="ownership-details-form-apply-button"
        label={formatMessage(
          'activities.financingApplication.applicationFormScreen.ownershipDetails.ApplyButton.label'
        )}
        isFullWidth
        onClick={onSubmit}
      />
    </CollapsibleCard>
  );
};
