import { Container, Divider, Group, Layout, Text, useFormSubmissionController } from '@melio/penny';
import { Account, AutoPayment, AutoPaymentOptions, FundingSource, Vendor } from '@melio/platform-api';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { SystemMessageDisplay } from '@melio/platform-utils';

import { MonitoredAction } from '../../../../../monitoring';
import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';
import { AutoPaymentActivationForm } from './AutoPaymentActivationForm/AutoPaymentActivationForm';
import { AutoPaymentActivationFormFields } from './AutoPaymentActivationForm/types';

export type AutoPaymentActivationScreenProps = {
  vendor: Vendor;
  autoPaymentOptions: AutoPaymentOptions;
  fundingSources: FundingSource[];
  account: Account;
  isSaving: boolean;
  autoPayment?: AutoPayment;
  onDone: (data: AutoPaymentActivationFormFields) => void;
  onClose: () => void;
  mode: 'create' | 'edit';
};

export const AutoPaymentActivationScreen = ({
  vendor,
  autoPaymentOptions,
  fundingSources,
  account,
  isSaving,
  onDone,
  onClose,
  autoPayment,
  mode,
}: AutoPaymentActivationScreenProps) => {
  const { formatMessage } = useMelioIntl();
  const { onSubmissionStateChange, submitButtonProps } = useFormSubmissionController<AutoPaymentActivationFormFields>();
  const { routeReady } = useMonitoring<MonitoredAction>();

  const nextLabelKey: MessageKey =
    mode === 'create'
      ? 'activities.vendor.autoPaymentActivationForm.screens.activation.submit.create'
      : 'activities.vendor.autoPaymentActivationForm.screens.activation.submit.edit';

  const titleKey: MessageKey =
    mode === 'create'
      ? 'activities.vendor.autoPaymentActivationForm.screens.activation.title.create'
      : 'activities.vendor.autoPaymentActivationForm.screens.activation.title.edit';

  const descriptionKey: MessageKey =
    mode === 'create'
      ? 'activities.vendor.autoPaymentActivationForm.screens.activation.description.create'
      : 'activities.vendor.autoPaymentActivationForm.screens.activation.description.edit';

  return (
    <Layout
      variant="6Columns"
      ref={routeReady}
      data-testid="auto-payment-activation-activity-activation-screen"
      header={{
        content: (
          <NewSinglePaymentStepLayout.Header>
            <NewSinglePaymentStepLayout.CloseButton
              onClick={onClose}
              aria-label={formatMessage('activities.vendor.autoPaymentActivationForm.screens.activation.cancel.aria')}
            />
          </NewSinglePaymentStepLayout.Header>
        ),
      }}
      footer={{
        isSticky: true,
        content: (
          <Container width="full" paddingX="s" paddingY="s">
            <Group variant="vertical" width="full" alignItems="flex-end">
              <Divider />
              <Group>
                <NewSinglePaymentStepLayout.SecondaryNextButton
                  label={formatMessage('activities.vendor.autoPaymentActivationForm.screens.activation.cancel')}
                  aria-label={formatMessage(
                    'activities.vendor.autoPaymentActivationForm.screens.activation.cancel.aria'
                  )}
                  onClick={onClose}
                  size="medium"
                ></NewSinglePaymentStepLayout.SecondaryNextButton>
                <NewSinglePaymentStepLayout.NextButton
                  {...submitButtonProps}
                  size="medium"
                  data-testid="auto-payment-form-submit-button"
                  label={formatMessage(nextLabelKey)}
                />
              </Group>
            </Group>
          </Container>
        ),
      }}
    >
      <SystemMessageDisplay />
      <NewSinglePaymentStepLayout.Title>{formatMessage(titleKey)}</NewSinglePaymentStepLayout.Title>
      <Text textStyle="body3">
        {formatMessage(descriptionKey, {
          vendorName: vendor.name,
        })}
      </Text>

      <NewSinglePaymentStepLayout.Content>
        <Group variant="vertical" spacing="m">
          <Text color="global.neutral.900" textStyle="body4">
            {formatMessage('activities.vendor.autoPaymentActivationForm.screens.activation.form.requireFields')}
          </Text>
          <AutoPaymentActivationForm
            vendor={vendor}
            autoPaymentOptions={autoPaymentOptions}
            fundingSources={fundingSources}
            account={account}
            isSaving={isSaving}
            onSubmit={onDone}
            onSubmissionStateChange={onSubmissionStateChange}
            autoPayment={autoPayment}
          />
        </Group>
      </NewSinglePaymentStepLayout.Content>
    </Layout>
  );
};
