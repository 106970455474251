import { TourtipCallbackStepData, TourtipProps, TourtipSteps } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useUserPreference } from '@melio/platform-api';
import { useUnmount } from '@melio/platform-utils';
import { kebabCase } from 'lodash';
import { useCallback } from 'react';

import { PickedTourtipProps } from './types';

export const usePlatformTourtip = ({
  tourName,
  userPreferenceKey,
  enabled: _enabled = true,
  steps: _steps,
  ...tourtipProps
}: {
  tourName: string;
  userPreferenceKey: string;
  enabled: boolean;
  steps: TourtipProps['steps'] | undefined;
  isLoading?: boolean;
} & PickedTourtipProps): TourtipProps & {
  onTourIgnored: VoidFunction;
  isTourActive: boolean;
} => {
  const { track } = useAnalytics();
  const steps = (_steps || []) as TourtipSteps;
  const enabled = _enabled && !!steps?.length;

  const {
    data: userPreference,
    isFetching: isFetchingUserPreference,
    create: createUserPreference,
  } = useUserPreference({
    id: userPreferenceKey,
    enabled,
  });

  const handleEndTour = useCallback(() => {
    createUserPreference({ userPreference: { key: userPreferenceKey, value: 'false' } });
  }, [createUserPreference, userPreferenceKey]);

  const handleStartTour = (data: TourtipCallbackStepData) => {
    track(tourName, 'View', {
      PageName: kebabCase(steps[0].title),
      StepOfTotal: `${data.currentIndex + 1}/${steps.length}`,
    });
  };

  const handleNextClick = (data: TourtipCallbackStepData) => {
    track(tourName, 'Click', {
      PageName: kebabCase(steps[data.currentIndex]?.title),
      StepOfTotal: `${data.currentIndex + 1}/${steps.length}`,
      Cta: 'next',
    });

    if (data.newIndex) {
      track(tourName, 'View', {
        PageName: kebabCase(steps[data.newIndex]?.title),
        StepOfTotal: `${data.newIndex + 1}/${steps.length}`,
      });
    }
  };

  const handlePrevClick = (data: TourtipCallbackStepData) => {
    track(tourName, 'Click', {
      PageName: kebabCase(steps[data.currentIndex]?.title),
      StepOfTotal: `${data.currentIndex + 1}/${steps.length}`,
      Cta: 'previous',
    });
    if (data.newIndex) {
      track(tourName, 'View', {
        PageName: kebabCase(steps[data.newIndex]?.title),
        StepOfTotal: `${data.newIndex + 1}/${steps.length}`,
      });
    }
  };

  const handleDismissTour = (data: TourtipCallbackStepData) => {
    track(tourName, 'Click', {
      PageName: kebabCase(steps[data.currentIndex]?.title),
      StepOfTotal: `${data.currentIndex + 1}/${steps.length}`,
      Cta: 'exit',
    });
    handleEndTour();
  };

  const handleCompleteTour = () => {
    track(tourName, 'Click', {
      PageName: kebabCase(steps[steps.length - 1]?.title),
      StepOfTotal: `${steps.length}/${steps.length}`,
      Cta: 'done',
    });
    handleEndTour();
  };

  const isTourActive = userPreference?.value === 'true';
  const shouldShowTour = isTourActive && enabled && !isFetchingUserPreference && !!steps.length;

  useUnmount(() => {
    // if the tour is active and user navigates outside, dismiss it when the component is unmounted
    if (shouldShowTour) {
      handleEndTour();
    }
  });

  return {
    disableScrolling: true,
    run: shouldShowTour,
    onTourDismiss: handleDismissTour,
    onTourStart: handleStartTour,
    onTourComplete: handleCompleteTour,
    onNextClick: handleNextClick,
    onPrevClick: handlePrevClick,
    onTourIgnored: handleEndTour,
    isTourActive,
    steps,
    ...tourtipProps,
  };
};
