import { AbsoluteCenter } from '@chakra-ui/react';
import { Group, Illustration, useBreakpoint } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-provider';

import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';

type SuccessfulQboConnectScreenProps = {
  onClick: () => void;
};

export const SuccessfulQboConnectScreen = ({ onClick }: SuccessfulQboConnectScreenProps) => {
  const { formatMessage } = useMelioIntl();
  const { isExtraSmallScreen } = useBreakpoint();

  return (
    <AbsoluteCenter justifyContent="center" alignItems="center" width="full">
      <NewSinglePaymentStepLayout
        data-component="SuccessfulQboConnectScreen"
        data-testid="successful-qbo-connect-screen"
      >
        <NewSinglePaymentStepLayout.Content width={isExtraSmallScreen ? 'full' : '500px'}>
          <Group variant="vertical" spacing="xl" alignItems="center">
            <Illustration type="success" size="large" />
            <Group variant="vertical">
              <NewSinglePaymentStepLayout.Title>
                {formatMessage('screens.successfulQboConnect.title')}
              </NewSinglePaymentStepLayout.Title>
              <NewSinglePaymentStepLayout.Description>
                {formatMessage('screens.successfulQboConnect.description')}
              </NewSinglePaymentStepLayout.Description>
            </Group>
            <NewSinglePaymentStepLayout.NextButton
              data-testid="successful-qbo-connect-screen-button"
              label={formatMessage('screens.successfulQboConnect.button')}
              onClick={onClick}
              size="medium"
            />
          </Group>
        </NewSinglePaymentStepLayout.Content>
      </NewSinglePaymentStepLayout>
    </AbsoluteCenter>
  );
};

SuccessfulQboConnectScreen.displayName = 'SuccessfulQboConnectScreen';
