import { Bill, PaymentIntent, useBill, usePaymentIntents } from '@melio/platform-api';
import { useOneTimeEffect } from '@melio/platform-utils';
import { useState } from 'react';

export type UseCreatePaymentIntentFromBill = {
  billId: Bill['id'];
  onError?: ErrorFunction;
};

export const useCreatePaymentIntentFromBill = (params: UseCreatePaymentIntentFromBill) => {
  const { billId, onError } = params;
  const { data: bill, isFetching: isFetchingBill } = useBill({
    id: billId,
    refetchOnMount: 'always',
  });

  const [paymentIntentId, setPaymentIntentId] = useState<PaymentIntent['id'] | undefined>();

  const { createFromBill, isMutating: isCreatingPaymentIntentId } = usePaymentIntents({ enabled: false });
  useOneTimeEffect(
    () => {
      void (async () => {
        if (bill) {
          try {
            const paymentIntent = await createFromBill({
              amountToPay: bill.balance,
              billPayments: [{ billId: bill.id, amount: bill.balance }],
            });

            setPaymentIntentId(paymentIntent.id);
          } catch (e) {
            onError?.(e as PlatformError);
          }
        }
      })();
    },
    () => !!bill && !paymentIntentId && !isFetchingBill,
    [bill, paymentIntentId]
  );

  return {
    paymentIntentId,
    isLoading: isCreatingPaymentIntentId || isFetchingBill,
    bill,
  };
};
