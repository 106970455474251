import { Form, FormSelectNewOption, FormSelectNewProps, Group, Icon, Text } from '@melio/penny';
import { AccountingPlatformBillLabel } from '@melio/platform-api';
import { forwardRef } from '@melio/platform-utils';

import { generateBillLabelSelectOptions } from './utils';

export type AccountingPlatformBillLabelSelectWidgetProps = {
  billLabel?: AccountingPlatformBillLabel;
} & Omit<FormSelectNewProps, 'value' | 'options'>;

export const AccountingPlatformBillLabelSelectWidget = forwardRef<
  AccountingPlatformBillLabelSelectWidgetProps,
  'input'
>(({ isReadOnly, isViewMode, billLabel, ...props }, ref) => {
  const placeholder = billLabel?.name;

  const options = generateBillLabelSelectOptions(billLabel?.options);

  const optionRenderer = (option: FormSelectNewOption) => (
    <Group alignItems="center" spacing="s">
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      {option.isNested && <Icon type="sub-arrow" size="small" />}
      <Text textStyle="inline" shouldSupportEllipsis color="inherit">
        {option.label}
      </Text>
    </Group>
  );

  return (
    <Form.SelectNew
      options={options}
      optionRenderer={optionRenderer}
      data-component="AccountingPlatformBillLabelSelectWidget"
      ref={ref}
      placeholder={placeholder}
      viewModePlaceholder={placeholder}
      isReadOnly={isReadOnly}
      isViewMode={isViewMode}
      labelProps={{ label: placeholder ?? '' }}
      {...props}
    />
  );
});

AccountingPlatformBillLabelSelectWidget.displayName = 'AccountingPlatformBillLabelSelect';
