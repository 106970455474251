/* eslint-disable react-hooks/exhaustive-deps */
import React, { Dispatch, SetStateAction, useState } from 'react';
import { StatusModal, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { Vendor } from '@melio/platform-api';
import { useSystemMessage } from '@melio/platform-utils';

import { useVendorDelete } from '@/hooks/vendors.hooks';
import { usePlatformIntl } from '@/translations/Intl';

type DeleteVendorDialogProps = {
  vendor: Vendor;
  isDeleteConfirmationModalOpen: boolean;
  setIsDeleteConfirmationModalOpen: Dispatch<SetStateAction<boolean>>;
  isVendorGotHistory: boolean;
};

export const DeleteVendorModal = ({
  vendor,
  isDeleteConfirmationModalOpen,
  setIsDeleteConfirmationModalOpen,
  isVendorGotHistory,
}: DeleteVendorDialogProps) => {
  const { formatMessage } = usePlatformIntl();
  const { deleteVendor } = useVendorDelete();
  const { showMessage } = useSystemMessage();
  const { track } = useAnalytics();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showFailedModal, setShowFailedModal] = useState<boolean>(false);

  React.useEffect(() => {
    if (isDeleteConfirmationModalOpen) {
      track('VendorsDeleteApproval', 'Viewed');
    }
  }, [isDeleteConfirmationModalOpen]);

  React.useEffect(() => {
    setShowFailedModal(false);
  }, [vendor]);

  const onDeletionConfirm = async () => {
    try {
      track('VendorsDeleteApprovalDelete', 'Chose');
      setIsLoading(true);
      await deleteVendor(vendor!.id);
      showMessage({
        type: 'informative',
        title: formatMessage('widgets.deleteVendor.toast.success'),
      });
      track('VendorsDeleteSucceed', 'Viewed');
    } catch (e) {
      track('VendorsDeleteFailed', 'Viewed');
      if (isVendorGotHistory) {
        setIsLoading(false);
        setShowFailedModal(true);
        track('VendorsDeleteApprovalDeclineByBill', 'Viewed');
      } else {
        closeModal();
        showMessage({
          type: 'error',
          title: formatMessage('widgets.deleteVendor.toast.failure'),
        });
      }
    }
  };

  const closeModal = React.useCallback(() => {
    setIsDeleteConfirmationModalOpen(false);
    setIsLoading(false);
  }, [setIsDeleteConfirmationModalOpen, setIsLoading]);

  const onCloseHandler = React.useCallback(() => {
    closeModal();
    if (!showFailedModal) {
      track('VendorsDeleteApprovalDelete', 'Chose');
    }
  }, [showFailedModal, closeModal]);

  const onCancelHandler = React.useCallback(() => {
    closeModal();
    if (showFailedModal) {
      track('VendorsDeleteApprovalDeclineByBill', 'Chose');
    } else {
      track('VendorsDeleteApprovalCancel', 'Chose');
    }
  }, [showFailedModal, closeModal]);

  const modalBody = showFailedModal
    ? formatMessage(`widgets.singleVendor.deleteConfirmationModal.failed.subtitle`)
    : formatMessage('widgets.singleVendor.deleteConfirmationModal.subtitle');

  const modalProps = showFailedModal
    ? {
        title: formatMessage('widgets.singleVendor.deleteConfirmationModal.failed.title'),
        cancelText: formatMessage('widgets.singleVendor.deleteConfirmationModal.failed.cancel'),
      }
    : {
        title: formatMessage('widgets.singleVendor.deleteConfirmationModal.title', { companyName: vendor?.name }),
        confirmText: formatMessage('widgets.singleVendor.deleteConfirmationModal.confirm'),
        onConfirm: onDeletionConfirm,
        cancelText: formatMessage('widgets.singleVendor.deleteConfirmationModal.cancel'),
        isLoading,
      };

  return (
    <StatusModal
      data-testid="delete-vendor-modal"
      isOpen={isDeleteConfirmationModalOpen}
      onClose={onCloseHandler}
      variant="cancel"
      header={modalProps.title}
      isLoading={modalProps.isLoading}
      primaryButton={
        modalProps.confirmText
          ? { label: modalProps.confirmText, onClick: modalProps.onConfirm, variant: 'primary' }
          : undefined
      }
      secondaryButton={{ label: modalProps.cancelText, onClick: onCancelHandler, variant: 'tertiary' }}
    >
      <Text>{modalBody}</Text>
    </StatusModal>
  );
};
