import { usePayDashboardPagination } from '../components/Pagination';
import { failedPaymentsSearchParams } from '../components/PaymentsTab/usePaymentsTabQueryParams';
import { sortPaymentsByUpdatedAt } from '../utils/sort.utils';
import { useGetNewPayments } from './useGetNewPayments';

type UseGetNewPaymentsParams = {
  preferenceKey: 'npePaymentsTabLastFailedBadge' | 'npeFailedFilterLastFailedBadge';
};

const useGetNewFailedPayments = ({ preferenceKey }: UseGetNewPaymentsParams) => {
  const { pageSize } = usePayDashboardPagination();

  return useGetNewPayments({
    preferenceKey,
    sortPaymentsBy: sortPaymentsByUpdatedAt,
    paymentsParams: {
      limit: pageSize,
      expand: 'none',
      search: failedPaymentsSearchParams,
      sort: [
        {
          field: 'updatedAt',
          order: 'desc',
        },
      ],
    },
  });
};

export const usePaymentsTabNewFailedPayments = () =>
  useGetNewFailedPayments({ preferenceKey: 'npePaymentsTabLastFailedBadge' });

export const useFiltersNewFailedPayments = () =>
  useGetNewFailedPayments({ preferenceKey: 'npeFailedFilterLastFailedBadge' });
