import { Box } from '@chakra-ui/react';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { Organization } from '@melio/platform-api';
import { NavigationMap, useWizard, UseWizardArgs } from '@melio/platform-utils';
import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import { EmailAlreadyInUseModal } from './components';
import {
  CompanyAddedScreen,
  CompanyDetailsScreen,
  ERROR_USER_ALREADY_EXISTS,
  JoinOrganizationRequestCreatedScreen,
  OwnerDetailsScreen,
  useCompanyDetailsStep,
  useCreateJoinOrganizationRequest,
  useOwnerDetailsStep,
} from './screens';
import { CompanyDetailsFormWidgetFields } from './screens/CompanyDetails/widgets';
import { OwnerDetailsFormWidgetFields } from './screens/OwnerDetails/widgets';

const enum ActivityStepsEnum {
  CompanyDetails = 'company-details',
  OwnerDetails = 'owner-details',
  CompanyAdded = 'company-added',
  JoinOrganizationRequestCreated = 'join-organization-request-created',
}

type Steps =
  | ActivityStepsEnum.CompanyDetails
  | ActivityStepsEnum.CompanyAdded
  | ActivityStepsEnum.OwnerDetails
  | ActivityStepsEnum.JoinOrganizationRequestCreated;

const navigationMap: NavigationMap<Steps> = {
  [ActivityStepsEnum.CompanyDetails]: ({ shouldFillOwnerDetails }: { shouldFillOwnerDetails: boolean }) => {
    if (shouldFillOwnerDetails) {
      return ActivityStepsEnum.OwnerDetails;
    }

    return ActivityStepsEnum.CompanyAdded;
  },
  [ActivityStepsEnum.OwnerDetails]: ({ isOrganizationCreated }: { isOrganizationCreated: boolean }) => {
    if (isOrganizationCreated) {
      return ActivityStepsEnum.CompanyAdded;
    }

    return ActivityStepsEnum.JoinOrganizationRequestCreated;
  },
  [ActivityStepsEnum.CompanyAdded]: () => ActivityStepsEnum.CompanyAdded,
  [ActivityStepsEnum.JoinOrganizationRequestCreated]: () => ActivityStepsEnum.JoinOrganizationRequestCreated,
};

const locationsMap: UseWizardArgs<Steps>['locationsMap'] = {
  [ActivityStepsEnum.CompanyDetails]: ActivityStepsEnum.CompanyDetails,
  [ActivityStepsEnum.OwnerDetails]: ActivityStepsEnum.OwnerDetails,
  [ActivityStepsEnum.CompanyAdded]: ActivityStepsEnum.CompanyAdded,
  [ActivityStepsEnum.JoinOrganizationRequestCreated]: ActivityStepsEnum.JoinOrganizationRequestCreated,
};

type EnteredData = Partial<CompanyDetailsFormWidgetFields & OwnerDetailsFormWidgetFields>;

export type AddCompanyActivityProps = {
  onDone: (organization?: Organization) => void;
  entryPoint: string;
};

export const AddCompanyActivity = withAnalyticsContext<AddCompanyActivityProps>(
  ({ onDone, entryPoint, setAnalyticsProperties }) => {
    const { goBack, goNextMap, completeFlow, cancelFlow } = useWizard<Steps, NavigationMap<Steps>>({
      firstStep: ActivityStepsEnum.CompanyDetails,
      flowName: 'AddCompany',
      navigationMap,
      locationsMap,
      cancelUrlFallback: '',
    });

    setAnalyticsProperties({
      Flow: 'companies',
      Intent: 'add-company',
      EntryPoint: entryPoint,
    });

    const [createdOrganization, setCreatedOrganization] = useState<Organization>();
    const [enteredData, setEnteredData] = useState<EnteredData>();
    const [isEmailAlreadyInUseModalShown, setEmailAlreadyInUseModalShown] = useState<boolean>(false);

    const { handleStepDone: handleCompanyDetailsScreenDone, isSaving: isCompanyDetailsScreenSaving } =
      useCompanyDetailsStep({
        onOrganizationCreate(data) {
          setEnteredData((prevData) => ({
            ...prevData,
            ...data,
            address: {
              ...prevData?.address,
              ...data.address,
            },
          }));
        },
        onOrganizationCreateSuccess(createdOrganization) {
          setCreatedOrganization(createdOrganization);
          goNextMap[ActivityStepsEnum.CompanyDetails]({ navArgs: [{ shouldFillOwnerDetails: false }] });
        },
        onOrganizationCreateContinueOnBehalf() {
          goNextMap[ActivityStepsEnum.CompanyDetails]({ navArgs: [{ shouldFillOwnerDetails: true }] });
        },
      });

    const { handleStepDone: handleOwnerDetailsScreenDone, isSaving: isOwnerDetailsScreenSaving } = useOwnerDetailsStep({
      enteredData,
      onOrganizationCreateOnBehalf(data) {
        setEnteredData((prevData) => ({
          ...prevData,
          ...data,
        }));
      },
      onOrganizationCreateOnBehalfSuccess(createdOrganization) {
        setCreatedOrganization(createdOrganization);
        goNextMap[ActivityStepsEnum.OwnerDetails]({ navArgs: [{ isOrganizationCreated: true }] });
      },
      onOrganizationCreateOnBehalfError(error) {
        if (error.message === ERROR_USER_ALREADY_EXISTS) {
          setEmailAlreadyInUseModalShown(true);
        }
      },
    });

    const { handleCreateJoinOrganizationRequest, isLoading: isEmailAlreadyInUseModalLoading } =
      useCreateJoinOrganizationRequest({
        onJoinOrganizationRequestCreateSuccess() {
          setEmailAlreadyInUseModalShown(false);
          goNextMap[ActivityStepsEnum.OwnerDetails]({ navArgs: [{ isOrganizationCreated: false }] });
        },
      });

    const handleOwnerDetailsScreenModalConfirm = () => {
      handleCreateJoinOrganizationRequest(enteredData as CompanyDetailsFormWidgetFields & OwnerDetailsFormWidgetFields);
    };

    const handleOwnerDetailsScreenModalClose = () => {
      setEmailAlreadyInUseModalShown(false);
    };

    const handleCompanyAddedScreenDone = () => {
      completeFlow(() => onDone(createdOrganization));
    };

    const handleJoinOrganizationRequestCreatedScreenDone = () => {
      completeFlow(() => onDone());
    };

    const handleBack = () => {
      goBack();
    };

    const handleClose = () => {
      cancelFlow();
    };

    return (
      <Box data-testid="add-new-company-activity" height="100%">
        <Routes>
          <Route
            path={locationsMap[ActivityStepsEnum.CompanyDetails]}
            element={
              <CompanyDetailsScreen
                defaultFields={enteredData}
                isSaving={isCompanyDetailsScreenSaving}
                onBack={handleClose}
                onClose={handleClose}
                onDone={handleCompanyDetailsScreenDone}
              />
            }
          />
          <Route
            path={locationsMap[ActivityStepsEnum.OwnerDetails]}
            element={
              <>
                <OwnerDetailsScreen
                  defaultFields={enteredData}
                  companyName={enteredData?.name}
                  isSaving={isOwnerDetailsScreenSaving}
                  onBack={handleBack}
                  onClose={handleClose}
                  onDone={handleOwnerDetailsScreenDone}
                />
                <EmailAlreadyInUseModal
                  contactEmail={enteredData?.contactEmail}
                  isLoading={isEmailAlreadyInUseModalLoading}
                  isOpen={isEmailAlreadyInUseModalShown}
                  onClose={handleOwnerDetailsScreenModalClose}
                  onConfirm={handleOwnerDetailsScreenModalConfirm}
                />
              </>
            }
          />
          <Route
            path={locationsMap[ActivityStepsEnum.CompanyAdded]}
            element={
              <CompanyAddedScreen
                isAddedOnBehalf={!enteredData?.isOwner}
                companyName={createdOrganization?.name as string}
                onDone={handleCompanyAddedScreenDone}
              />
            }
          />
          <Route
            path={locationsMap[ActivityStepsEnum.JoinOrganizationRequestCreated]}
            element={
              <JoinOrganizationRequestCreatedScreen
                companyName={enteredData?.name}
                onDone={handleJoinOrganizationRequestCreatedScreenDone}
              />
            }
          />
        </Routes>
      </Box>
    );
  }
);

AddCompanyActivity.displayName = 'AddCompanyActivity';
