import { Typography } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

type Props = {
  isDisabled?: boolean;
  onOpen: () => void;
};

export const Description = ({ isDisabled = false, onOpen }: Props) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Typography.Description
      label={formatMessage('widgets.fundingSources.selectFundingSourceCard.financing.helperText')}
      action={{
        label: formatMessage('widgets.fundingSources.selectFundingSourceCard.financing.helperTextAction'),
        onClick: onOpen,
        'data-testid': `financing-more-details-modal-button`,
      }}
      isDisabled={isDisabled}
    />
  );
};
