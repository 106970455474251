import { Form, useMelioForm } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { defaults } from 'lodash';

import { FormWidgetProps } from '../../../types';

export type DomesticWireFormFields = {
  routingNumber: string;
  accountNumber: string;
  city: string;
  state: string;
  postalCode: string;
};

import { US_STATES } from '@melio/platform-api';
import { useEffect } from 'react';
import { FieldErrors } from 'react-hook-form';
import * as yup from 'yup';

import {
  ACCOUNT_NUMBER_MAX_LENGTH,
  ACCOUNT_NUMBER_MIN_LENGTH,
  ROUTING_NUMBER_LENGTH,
} from '../../bank-details/util/bank-account-util';
import { useAccountNumberSchema, useRoutingNumberSchema } from '../../utils/aba-schema';

export type DomesticWireFormProps = FormWidgetProps<DomesticWireFormFields> & {
  onValidation?: (errors: FieldErrors<DomesticWireFormFields>) => void;
};

export const DomesticWireForm = forwardRef<DomesticWireFormProps, 'form'>(
  ({ onSubmit, onSubmissionStateChange, defaultValues: _defaultValues, isSaving, onValidation, ...props }, ref) => {
    const defaultValues = defaults(_defaultValues, {
      routingNumber: '',
      accountNumber: '',
      city: '',
      state: '',
      postalCode: '',
    });
    const { formatMessage } = useMelioIntl();

    const schema = yup.object().shape({
      routingNumber: useRoutingNumberSchema(),
      accountNumber: useAccountNumberSchema(),
      city: yup
        .string()
        .required(formatMessage('widgets.deliveryMethods.vendorDomesticWireDetailsForm.city.validation.required')),
      state: yup
        .string()
        .required(formatMessage('widgets.deliveryMethods.vendorDomesticWireDetailsForm.state.validation.required')),
      postalCode: yup
        .string()
        .required(
          formatMessage('widgets.deliveryMethods.vendorDomesticWireDetailsForm.postalCode.validation.required')
        ),
    });

    const {
      formProps,
      registerField,
      formState: { errors },
    } = useMelioForm<DomesticWireFormFields>({
      onSubmit,
      defaultValues,
      schema,
      isSaving,
      onSubmissionStateChange,
      subscribeToDefaultValuesChanges: true,
    });

    useEffect(() => {
      onValidation?.(errors);
    }, [errors, onValidation]);

    return (
      <Form data-component="DomesticWireForm" {...props} {...formProps} ref={ref}>
        <Form.TextField
          colSpan={2}
          labelProps={{
            label: formatMessage('widgets.deliveryMethods.vendorDomesticWireDetailsForm.routingNumber.label'),
          }}
          placeholder={formatMessage(
            'widgets.deliveryMethods.vendorDomesticWireDetailsForm.routingNumber.placeholder',
            {
              length: ROUTING_NUMBER_LENGTH,
            }
          )}
          autoFocus
          {...registerField('routingNumber')}
        />
        <Form.SecuredTextField
          colSpan={2}
          data-private
          labelProps={{
            label: formatMessage('widgets.deliveryMethods.vendorDomesticWireDetailsForm.accountNumber.label'),
          }}
          placeholder={formatMessage(
            'widgets.deliveryMethods.vendorDomesticWireDetailsForm.accountNumber.placeholder',
            {
              minLength: ACCOUNT_NUMBER_MIN_LENGTH,
              maxLength: ACCOUNT_NUMBER_MAX_LENGTH,
            }
          )}
          {...registerField('accountNumber')}
        />
        <Form.TextField
          colSpan={2}
          labelProps={{ label: formatMessage('widgets.deliveryMethods.vendorDomesticWireDetailsForm.city.label') }}
          placeholder={formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayorForm.city.placeholder')}
          {...registerField('city')}
        />
        <Form.SelectNew
          labelProps={{ label: formatMessage('widgets.deliveryMethods.vendorDomesticWireDetailsForm.state.label') }}
          placeholder={formatMessage('widgets.deliveryMethods.vendorDomesticWireDetailsForm.state.placeholder')}
          {...registerField('state')}
          options={US_STATES.map((state) => ({ value: state, label: formatMessage(`local.USA.states.${state}`) }))}
        />
        <Form.TextField
          labelProps={{
            label: formatMessage('widgets.deliveryMethods.vendorDomesticWireDetailsForm.postalCode.label'),
          }}
          placeholder={formatMessage('widgets.deliveryMethods.vendorDomesticWireDetailsForm.postalCode.placeholder')}
          {...registerField('postalCode')}
        />
      </Form>
    );
  }
);

DomesticWireForm.displayName = 'DomesticWireForm';
