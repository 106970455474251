import { useParams } from 'react-router-dom';
import { LinkBankAccountActivity } from '@melio/ap-activities';

import { useReceivingMethodActivityEvents } from '@/hooks/useReceivingMethodActivityEvents';

export const ReceivingMethodLinkBankAccountPage = () => {
  const { onBack, onDone, onClose } = useReceivingMethodActivityEvents();
  const { deliveryMethodId } = useParams();

  return (
    <LinkBankAccountActivity onBack={onBack} onDone={onDone} onClose={onClose} deliveryMethodId={deliveryMethodId} />
  );
};
