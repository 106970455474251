import { useMelioIntl } from '@melio/platform-i18n';
import { FileType, useConfig } from '@melio/platform-provider';
import { addYears, isAfter, isBefore } from 'date-fns';
import { pickBy } from 'lodash';
import { BaseSchema, date, mixed, number, object, SchemaOf, string } from 'yup';

import { AddBillFormWidgetFields, isRecurringFrequency } from './types';
import { MAX_ALLOWED_OCCURRENCES } from './utils';

export const useAddBillFormSchema = (fieldsForValidation: string[]) => {
  const { formatMessage, formatCurrency, formatDate } = useMelioIntl();
  const { fileSizeLimit, fileAllowedFormats } = useConfig().settings;

  const schema = {
    vendorId: string().required(formatMessage('widgets.addBillForm.vendorName.validation.required')).nullable(),
    billAmount: string()
      .required(formatMessage('widgets.addBillForm.billAmount.validation.required'))
      .test(
        'greaterThan',
        formatMessage('widgets.addBillForm.billAmount.validation.greaterThan', {
          minValue: formatCurrency(0),
        }),
        (value = '') => parseFloat(value) > 0
      ),
    billNumber: string(),
    paymentFrequency: string().required(),
    invoice: mixed<File>()
      .nullable()
      .test('is-file-size-below-limit', formatMessage('widgets.addBillForm.invoice.validation.fileSize'), (invoice) => {
        if (invoice) {
          return invoice?.size <= fileSizeLimit;
        }
        return true;
      })
      .test(
        'is-file-format-allowed',
        formatMessage('widgets.addInstantBillForm.invoice.validation.fileFormat'),
        (invoice) => {
          if (invoice) {
            return fileAllowedFormats.includes(invoice.type?.split('/')[1] as FileType);
          }
          return true;
        }
      ),
    startDate: date().nullable().required(formatMessage('widgets.addBillForm.startDate.validation.required')),
    endPolicy: string().required(),
    numOfOccurrences: number()
      .positive(formatMessage('widgets.addBillForm.numOfOccurrences.validation.valid'))
      .integer()
      .nullable()
      .required(formatMessage('widgets.addBillForm.numOfOccurrences.validation.required'))
      .typeError(formatMessage('widgets.addBillForm.numOfOccurrences.validation.required'))
      .when('paymentFrequency', (paymentFrequency: string, schema: BaseSchema) =>
        schema.test(
          'is-number-allowed',
          isRecurringFrequency(paymentFrequency)
            ? formatMessage('widgets.addBillForm.numOfOccurrences.validation.maxLimit', {
                maxNumOfPayments: MAX_ALLOWED_OCCURRENCES[paymentFrequency],
              })
            : '',
          (numOfOccurrences: number) =>
            !(
              isRecurringFrequency(paymentFrequency) &&
              numOfOccurrences &&
              numOfOccurrences > MAX_ALLOWED_OCCURRENCES[paymentFrequency]
            )
        )
      ),
    endDate: date()
      .nullable()
      .required(formatMessage('widgets.addBillForm.endDate.validation.required'))
      .when('startDate', (startDate: Date, schema: BaseSchema) =>
        schema
          .test(
            'is-date-valid',
            formatMessage('widgets.addBillForm.endDate.validation.validDate'),
            (endDate: Date) => !(startDate && endDate && isBefore(endDate, startDate))
          )
          .test(
            'is-exceeding-max-payments',
            startDate
              ? formatMessage('widgets.addBillForm.endDate.validation.maxLimit', {
                  maxDate: formatDate(addYears(startDate, 2), { dateStyle: 'medium' }),
                })
              : '',
            (endDate: Date) => !(startDate && endDate && isAfter(endDate, addYears(startDate, 2)))
          )
      ),
    dueDate: date().nullable().required(formatMessage('widgets.addBillForm.dueDate.validation.required')),
    note: string().optional(),
    categoryId: string().optional(),
  };
  return object().shape(
    pickBy(schema, (_, key: string) => fieldsForValidation.includes(key))
  ) as unknown as SchemaOf<AddBillFormWidgetFields>;
};
