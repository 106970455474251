import { FundingSource, FundingSourcesApiClient } from '@melio/platform-api-axios-client';

import { useCollection, UseCollectionProps } from '../../api-client';
import { FundingSourceBankAccount } from './types';

type QueryFn = typeof FundingSourcesApiClient.getFundingSources;

export type UseBankAccountFundingSourcesProps = UseCollectionProps<QueryFn, never, never, FundingSourceBankAccount>;

export const useBankAccountFundingSources = (props: UseBankAccountFundingSourcesProps = {}) =>
  useCollection<QueryFn, never, never, never, FundingSourceBankAccount>({
    ...props,
    queryKey: 'FundingSourcesApi',
    queryFn: FundingSourcesApiClient.getFundingSources,
    select: (data) => data.filter(isFundingSourceBankAccount),
  });

export type BankAccountFundingSourceCollection = ReturnType<typeof useBankAccountFundingSources>;

const isFundingSourceBankAccount = (fs: FundingSource): fs is FundingSourceBankAccount => fs.type === 'bank-account';
