import React, { useMemo } from 'react';
import { useAnalytics } from '@melio/platform-analytics';
import { ScannedInvoice } from '@melio/platform-api';

export const useScannedInvoiceReviewAnalytics = ({ scannedInvoice }: { scannedInvoice?: ScannedInvoice }) => {
  const { track } = useAnalytics();

  const eventProperties = useMemo(() => {
    if (scannedInvoice) {
      return {
        ScannedInvoiceId: scannedInvoice.id,
        MissingData: (['invoiceNumber', 'dueDate', 'amount', 'vendorName'] as (keyof ScannedInvoice)[]).filter(
          (key) => !scannedInvoice[key],
        ),
      };
    }
    return undefined;
  }, [scannedInvoice]);

  const trackOnFilePagination = React.useCallback(() => {
    track('DraftBillReviewFilePagination', 'Chose', eventProperties);
  }, [track, eventProperties]);

  const trackOnFileDownload = React.useCallback(() => {
    track('DraftBillReviewFileDownload', 'Chose', eventProperties);
  }, [track, eventProperties]);

  return {
    eventProperties,
    trackOnFilePagination,
    trackOnFileDownload,
  };
};
