import { useParams } from 'react-router-dom';
import { NewSinglePaymentStepLayout } from '@melio/ap-activities';
import { Group, Illustration } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-provider';

import { useRouter } from '@/hooks/router.hooks';

export const ReceivingMethodCantFindBankAccountPageScreen = () => {
  const { formatMessage } = useMelioIntl();

  useAnalyticsView('ReceivingMethodCantFindBankAccountPage');

  const { deliveryMethodId: updateDeliveryMethodId } = useParams();

  const { goToAddAchFromReceivingMethodFlow, goToReceivingMethods } = useRouter();

  const onDone = () => {
    if (updateDeliveryMethodId) {
      goToAddAchFromReceivingMethodFlow(updateDeliveryMethodId);
    } else {
      goToReceivingMethods();
    }
  };

  return (
    <NewSinglePaymentStepLayout
      data-testid="receiving-method-cant-find-bank-account-page-screen"
      footerContent={
        <NewSinglePaymentStepLayout.Actions>
          <NewSinglePaymentStepLayout.NextButton
            onClick={onDone}
            label={formatMessage('widgets.receivingMethodsSettings.receivingMethodCantFindBankAccount.nextBtnLabel')}
          />
        </NewSinglePaymentStepLayout.Actions>
      }
    >
      <Group justifyContent="center">
        <Illustration type="bank" size="large" />
      </Group>
      <NewSinglePaymentStepLayout.Title>
        {formatMessage('widgets.receivingMethodsSettings.receivingMethodCantFindBankAccount.title')}
      </NewSinglePaymentStepLayout.Title>
      <NewSinglePaymentStepLayout.Description>
        {formatMessage('widgets.receivingMethodsSettings.receivingMethodCantFindBankAccount.description')}
      </NewSinglePaymentStepLayout.Description>
    </NewSinglePaymentStepLayout>
  );
};
