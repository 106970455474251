import { useRouter } from '@/hooks/router.hooks';
import { useActiveFlowRoute } from '@/hooks/useActiveFlowRoute.hooks';

export const useReceivingMethodActivityEvents = () => {
  const { navigateToActiveFlow } = useActiveFlowRoute();

  const { goBack } = useRouter();

  const onBack = () => {
    goBack();
  };

  const onClose = () => {
    navigateToActiveFlow();
  };

  const onDone = () => {
    navigateToActiveFlow();
  };
  return {
    onDone,
    onBack,
    onClose,
  };
};
