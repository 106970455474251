import { CSSProperties } from 'react';

import { ComponentThemeType } from '@/cl/theme/theme.hooks';
import { zIndex } from '../layout.config';

const HEADER_SECTION_HEIGHT = 56;
const MENU_BORDER_WIDTH = 2;

const stickyProperties: CSSProperties = {
  position: 'sticky',
  top: 0,
  zIndex: zIndex.stickyHeader,
};

export const MiniTopNavLayoutTheme: ComponentThemeType<{ sticky?: boolean }> = (currentTheme, { sticky }) => ({
  baseStyle: {
    header: {
      ...(sticky && stickyProperties),
    },
    banner: {
      height: HEADER_SECTION_HEIGHT,
      display: 'flex',
      padding: '16px',
      alignItems: 'center',
      backgroundColor: currentTheme.colors.global.neutral['100'],
      position: 'relative',
      ...(sticky && stickyProperties),
    },
    menu: {
      position: 'relative',
      height: HEADER_SECTION_HEIGHT,
      display: 'flex',
      padding: '16px',
      alignItems: 'center',
      backgroundColor: currentTheme.colors.global.neutral['100'],
      borderTop: `${MENU_BORDER_WIDTH}px solid ${currentTheme.colors.global.neutral['300']}`,
      ...(sticky && { ...stickyProperties, top: -MENU_BORDER_WIDTH }),
    },
    centerIcon: {
      maxHeight: '40px',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
    mobileAccountSwitcher: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
});
