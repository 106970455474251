import { useWizard, UseWizardArgs } from '@melio/platform-utils';

import { Steps } from './types';

const firstStep: Steps = 'chooseHowToResend';
const locationsMap: UseWizardArgs<Steps>['locationsMap'] = {
  chooseHowToResend: 'choose-how-to-resend',
  addBankDetails: 'add-bank-details',
  reviewAndConfirmPayment: 'review-and-confirm-payment',
  confirmMailingAddress: 'confirm-mailing-address',
  chooseDeliverySpeed: 'choose-delivery-speed',
  paymentScheduled: 'payment-scheduled',
};

export const useVoidAndResendWizard = () => {
  const wizard = useWizard({
    firstStep,
    flowName: 'void-and-resend-payment',
    locationsMap,
    cancelUrlFallback: '/',
    navigationMap: {
      chooseHowToResend: ({
        isResendingPaperCheck,
        isBankAccountDeliveryMethodExist,
      }: {
        isBankAccountDeliveryMethodExist: boolean;
        isResendingPaperCheck: boolean;
      }) => {
        if (isResendingPaperCheck) {
          return 'confirmMailingAddress';
        }

        return isBankAccountDeliveryMethodExist ? 'reviewAndConfirmPayment' : 'addBankDetails';
      },
      addBankDetails: () => 'reviewAndConfirmPayment',
      confirmMailingAddress: ({ hasDeliveryFastOption }: { hasDeliveryFastOption: boolean }) =>
        hasDeliveryFastOption ? 'chooseDeliverySpeed' : 'reviewAndConfirmPayment',
      chooseDeliverySpeed: () => 'reviewAndConfirmPayment',
      reviewAndConfirmPayment: () => 'paymentScheduled',
      paymentScheduled: () => void 0,
    },
  });

  return {
    ...wizard,
    locationsMap,
  };
};
