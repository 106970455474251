import { Form, Group, OnSubmissionStateChange, Text, TextField, useMelioForm } from '@melio/penny';
import { Account, EbillsSubscriptionOptions, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { EBillsSubscriptionFormCompany } from './EBillsSubscriptionFormCompany';
import { EBillsSusbscriptionFormReceivingOptions } from './EBillsSusbscriptionFormReceivingOptions';
import { EBillsSusbscriptionFormTerms } from './EBillsSusbscriptionFormTerms';
import { useEBillsSusbscriptionFormSchema } from './schema';
import { EbillsSubscriptionFormFields } from './types';
import { formatFieldName, isAddress } from './utils';

export const EBillsSubscriptionForm = ({
  vendor,
  account,
  ebillsSubscriptionOptions,
  onSubmit,
  onSubmissionStateChange,
  isSaving,
}: {
  vendor: Vendor;
  account: Account;
  ebillsSubscriptionOptions: EbillsSubscriptionOptions;
  onSubmit: (data: EbillsSubscriptionFormFields) => void;
  onSubmissionStateChange: OnSubmissionStateChange<EbillsSubscriptionFormFields>;
  isSaving: boolean;
}) => {
  const { receivingOptions, restriction, trialPeriodDays, isAddressRequired, fields } = ebillsSubscriptionOptions;
  const { formatMessage } = useMelioIntl();
  const schema = useEBillsSusbscriptionFormSchema({ fields, isAddressRequired });
  const { contactFirstName, contactLastName, address } = account.company;

  const { formProps, registerField, watch, setError } = useMelioForm<EbillsSubscriptionFormFields>({
    onSubmit: (data) => {
      const transformedFields = Object.keys(data.fields).reduce((acc, formattedKey) => {
        const fieldValue = data.fields[formattedKey];
        const { name: originalKey } = fields.find(({ name }) => formatFieldName(name) === formattedKey) ?? {};

        if (!originalKey || !fieldValue) {
          throw new Error(`Could not find original key for formatted key: ${formattedKey}`);
        }

        acc[originalKey] = fieldValue;
        return acc;
      }, {} as EbillsSubscriptionFormFields['fields']);

      onSubmit({ ...data, fields: transformedFields });
    },
    onSubmissionStateChange,
    schema,
    isSaving,
    defaultValues: {
      address: isAddressRequired && isAddress(address) ? address : null,
      name: isAddressRequired
        ? {
            firstName: contactFirstName ?? undefined,
            lastName: contactLastName ?? undefined,
            middleName: null,
          }
        : null,
      receivingOption: receivingOptions[0]?.name,
      acceptedTerms: false,
      fields: fields.reduce((acc, field) => ({ ...acc, [formatFieldName(field.name)]: field.value }), {}),
    },
  });

  return (
    <Group variant="vertical">
      <Text textStyle="body3Semi" as="h2">
        {formatMessage('activities.ebillsSubscriptionForm.form.sections.vendorInformation.title')}
      </Text>
      <Group spacing="xs" variant="vertical">
        <Text textStyle="body4Semi" color="global.neutral.800" id="accountNumberLabel">
          {formatMessage('activities.ebillsSubscriptionForm.form.fields.accountNumber.label')}
        </Text>
        <TextField value={vendor.accountNumber} isReadOnly aria-labelledby="accountNumberLabel" />
      </Group>
      <Form {...formProps}>
        {fields.map((field, index) => (
          <Form.TextField
            key={field.name}
            {...registerField(`fields.${formatFieldName(field.name)}`)}
            labelProps={{ label: field.name }}
            isRequired
            data-testid={`ebills-subscription-form-field-${index}`}
          />
        ))}
        {isAddressRequired && !!account && (
          <EBillsSubscriptionFormCompany company={account.company} registerField={registerField} />
        )}
        <EBillsSusbscriptionFormReceivingOptions
          receivingOptions={receivingOptions}
          trialPeriodDays={trialPeriodDays}
          registerField={registerField}
        />
        <EBillsSusbscriptionFormTerms
          selectedReceivingOption={watch('receivingOption')}
          receivingOptions={receivingOptions}
          restriction={restriction}
          registerField={registerField}
          setError={setError}
        />
      </Form>
    </Group>
  );
};
