import { useInvalidFundingSources, usePaymentSchedulingPreference } from '@melio/ap-domain';
import { BrandSymbolKey, IconKey } from '@melio/penny';
import { FsTypeOption, FundingSource, FundingSourceBankAccount } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';

import { getFundingSourceIconByNetwork, getFundingSourceIconByType } from '../components';

export const getFundingSourceIconKey = (
  fundingSource: FundingSource,
  icon: IconKey | BrandSymbolKey
): IconKey | BrandSymbolKey => {
  const { type: fundingType } = fundingSource;

  if (fundingType === 'bank-account' || fundingType === 'flex-account') {
    return getFundingSourceIconByType({ fundingType, bankIcon: icon });
  }

  const { network, type: cardType } = fundingSource.details;
  return network
    ? getFundingSourceIconByNetwork(network)
    : getFundingSourceIconByType({ fundingType, bankIcon: icon, cardType });
};

export const useFundingSourceIconKey = (fundingSource: FundingSource): IconKey | BrandSymbolKey => {
  const { settings } = useConfig();
  return getFundingSourceIconKey(fundingSource, settings.icons.fundingSource.bank);
};

export const useFundingSourcesIconKeys = (fundingSources: FundingSource[]) => {
  const { settings } = useConfig();
  return fundingSources.map((fundingSource) => ({
    fundingSource,
    icon: getFundingSourceIconKey(fundingSource, settings.icons.fundingSource.bank),
  }));
};

export const useFundingSourcesSelectOptions = (
  fundingSources: FundingSource[],
  paymentFundingSourceTypesOptions?: FsTypeOption[]
) => {
  const { getFundingSourceTooltip, getIsValidFundingSource } = useInvalidFundingSources(
    paymentFundingSourceTypesOptions
  );
  const { settings } = useConfig();

  return fundingSources.map((fundingSource) => ({
    label: fundingSource.displayName,
    value: fundingSource.id,
    testId: fundingSource.id,
    icon: getFundingSourceIconKey(fundingSource, settings.icons.fundingSource.bank),
    disabled: {
      isDisabled: !getIsValidFundingSource(fundingSource),
      message: getFundingSourceTooltip(fundingSource),
    },
  }));
};

const isBankAccount = (fundingSource: FundingSource): fundingSource is FundingSourceBankAccount =>
  'accountNumber' in fundingSource.details;

export const useFundingSourceUnverifiedText = ({
  fundingSource,
  effectiveScheduleDate,
}: {
  fundingSource: FundingSource;
  effectiveScheduleDate: Date;
}) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const { scheduleBy } = usePaymentSchedulingPreference();

  const fundingSourceRequireVerification = isBankAccount(fundingSource) && !fundingSource.isVerified;

  if (!fundingSourceRequireVerification) {
    return '';
  }

  const scheduleDate = formatDate(effectiveScheduleDate, { month: 'short', day: 'numeric' });
  const last4digits = fundingSource.details.accountNumber.slice(-4);

  return formatMessage(`widgets.deliveryDateListItemByPayor.${scheduleBy}.unverified.helperText`, {
    date: scheduleDate,
    last4digits,
  });
};
