import { Group, Icon, NakedButton, Text, Typography } from '@melio/penny';
import { SelectedRepaymentOption } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';

import { InstallmentsList } from './InstallmentsList';

type InstallmentsListContainerProps = {
  repaymentOption: SelectedRepaymentOption;
  onEdit?: VoidFunction;
};

export const InstallmentsListContainer = ({ onEdit, repaymentOption }: InstallmentsListContainerProps) => (
  <Group data-component="InstallmentsListContainer">
    <Icon size="large" type="scheduled" />
    <Group.Item grow={1}>
      <Group variant="vertical" spacing="xxs">
        <InstallmentsListHeader onEdit={onEdit} repaymentOption={repaymentOption} />
        <InstallmentsList repaymentOption={repaymentOption} />
      </Group>
    </Group.Item>
  </Group>
);

export const InstallmentsListHeader = ({ onEdit, repaymentOption }: InstallmentsListContainerProps) => {
  const { formatMessage, formatDate } = useMelioIntl();

  return (
    <Group justifyContent="space-between" alignItems="flex-start">
      <Group variant="vertical" spacing="xxs">
        <Typography.Label label={formatMessage('widgets.reviewAndConfirm.repaymentSection.installments.title')} />
        <Text>{formatDate(repaymentOption.installments[0]?.scheduledDate, { dateStyle: 'medium' })}</Text>
      </Group>
      <NakedButton
        variant="secondary"
        onClick={onEdit}
        data-testid="repayment-section-installments-edit-button"
        aria-label="Edit item"
        label={formatMessage('widgets.reviewAndConfirm.repaymentSection.installments.edit')}
      />
    </Group>
  );
};
