import React from 'react';
import { Swiper as SwiperReact, SwiperProps, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperRectType } from 'swiper/types';
import 'swiper/css';

type SwipeProps = {} & SwiperProps;

export type SwiperHandler = {
  slideTo: (index: number) => void;
  slideNext: () => void;
  slidePrev: () => void;
  isEnd: () => boolean;
  isBeginning: () => boolean;
};
export const Swiper = React.forwardRef<SwiperHandler, SwipeProps>(({ children, ...rest }, ref) => {
  const [swiperInstance, setSwiperInstance] = React.useState<SwiperRectType | null>(null);

  React.useImperativeHandle(ref, () => ({
    slideTo: (index) => {
      swiperInstance?.slideTo(index);
    },
    slideNext: () => {
      swiperInstance?.slideNext();
    },
    slidePrev: () => {
      swiperInstance?.slidePrev();
    },
    isBeginning: () => {
      return !!swiperInstance?.isBeginning;
    },
    isEnd: () => {
      return !!swiperInstance?.isEnd;
    },
  }));

  const renderChildren = () => {
    const arrayChildren = React.Children.toArray(children);

    return arrayChildren.map((child, index) => {
      return <SwiperSlide key={`swiper_child_${index}`}>{child}</SwiperSlide>;
    });
  };

  return (
    <SwiperReact
      allowTouchMove={false}
      loop={true}
      {...rest}
      onSwiper={(swiperInstance) => {
        //   setSwiper(swiperInstance);
        setSwiperInstance(swiperInstance);
      }}
    >
      {renderChildren()}
    </SwiperReact>
  );
});

Swiper.displayName = 'Swiper';
