import { SkipNavContent, SkipNavLink } from '@chakra-ui/skip-nav';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

export const ContentToSkip = () => <SkipNavContent id="main" />;

export const SkipToContentLink = () => {
  const { formatMessage } = useMelioIntl();
  return <SkipNavLink id="main">{formatMessage('activities.payDashboard.skipToContent.text')}</SkipNavLink>;
};
