import { Bill, PaymentRequest, ScannedInvoice } from '@melio/platform-api';
import { Payment } from '@melio/platform-api';

import { filterBillByVendor, filterPaymentByVendor, filterPaymentRequestByVendor } from '@/hooks/payDashboard.hooks';
import {
  GroupItem,
  isBillGroupItem,
  isPaymentGroupItem,
  isPaymentRequestGroupItem,
  isScannedInvoiceGroupItem,
} from '@/types/payDashboard.types';

export const filterPayCardByQuery = (groupItem: GroupItem, searchQuery: string): boolean => {
  if (isScannedInvoiceGroupItem(groupItem)) {
    return filterScannedInvoicePayCardByQuery(groupItem.scannedInvoice, searchQuery);
  }
  if (isBillGroupItem(groupItem)) {
    return filterBillPayCardByQuery(groupItem.bill, searchQuery);
  }
  if (isPaymentRequestGroupItem(groupItem)) {
    return filterPaymentRequestPayCardByQuery(groupItem.paymentRequest, searchQuery);
  }
  if (isPaymentGroupItem(groupItem)) {
    return filterPaymentPayCardByQuery(groupItem.payment, searchQuery);
  }
  return true;
};

export const filterPayCardByVendorId = (groupItem: GroupItem, vendorId: string | null): boolean => {
  if (isScannedInvoiceGroupItem(groupItem)) {
    return !vendorId;
  }
  if (isPaymentGroupItem(groupItem)) {
    return filterPaymentByVendor({ payment: groupItem.payment, vendorId });
  }
  if (isBillGroupItem(groupItem)) {
    return filterBillByVendor({ bill: groupItem.bill, vendorId });
  }
  if (isPaymentRequestGroupItem(groupItem)) {
    return filterPaymentRequestByVendor({ paymentRequest: groupItem.paymentRequest, vendorId });
  }
  return true;
};

export const filterBillPayCardByQuery = (bill: Bill, searchQuery: string) => {
  const { email, name } = bill.vendor?.contact || {};

  return (
    (!!email && searchString(email, searchQuery)) ||
    (!!name && searchString(name, searchQuery)) ||
    searchString(bill.vendor?.name, searchQuery) ||
    bill.invoice.number?.includes(searchQuery) ||
    bill.amount.toString().includes(searchQuery)
  );
};
export const filterPaymentRequestPayCardByQuery = (paymentRequest: PaymentRequest, searchQuery: string) => {
  const { email, name } = paymentRequest.vendor?.contact || {};

  return (
    (!!email && searchString(email, searchQuery)) ||
    (!!name && searchString(name, searchQuery)) ||
    searchString(paymentRequest.vendor?.name, searchQuery) ||
    paymentRequest.invoiceNumber?.includes(searchQuery) ||
    paymentRequest.totalAmount.toString().includes(searchQuery)
  );
};

export const filterPaymentPayCardByQuery = (payment: Payment, searchQuery: string) => {
  const { email, name } = payment.vendor?.contact || {};

  return (
    (!!email && searchString(email, searchQuery)) ||
    (!!name && searchString(name, searchQuery)) ||
    searchString(payment.vendor?.name, searchQuery) ||
    payment.bills?.some((bill) => bill.invoice?.number?.includes(searchQuery)) ||
    payment.bills?.some((bill) => bill.amount.toString().includes(searchQuery)) ||
    payment.amount.toString().includes(searchQuery)
  );
};

export const filterScannedInvoicePayCardByQuery = (scannedInvoice: ScannedInvoice, searchQuery: string) => {
  return (
    (!!scannedInvoice.invoiceNumber && searchString(scannedInvoice.invoiceNumber, searchQuery)) ||
    (!!scannedInvoice.note && searchString(scannedInvoice.note, searchQuery)) ||
    (!!scannedInvoice.vendorName && searchString(scannedInvoice.vendorName, searchQuery)) ||
    (!!scannedInvoice.amount && scannedInvoice.amount.toString().includes(searchQuery))
  );
};

const searchString = (stringToSearch: string = '', query: string) =>
  query ? stringToSearch.toLocaleLowerCase().includes(query.toLocaleLowerCase()) : false;
