import { isFXCurrency } from '@melio/ap-domain';
import { TableRowSelectionState, useBreakpoint, useTable } from '@melio/penny';
import { Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

type UsePaymentsTabSelectionResult = { rowSelections: string[]; areAllSelected: boolean } & Pick<
  Parameters<typeof useTable<Payment>>[0],
  'selectedRows' | 'onRowSelectionChange' | 'onAllRowsSelectionChange' | 'disableRowSelection' | 'rowSelectionTooltips'
>;

export const useApprovalsTabSelection = (
  paymentsApprovalsData: Payment[],
  clearSelected = false,
  setClearSelectedFalse: Dispatch<SetStateAction<boolean>>
): UsePaymentsTabSelectionResult => {
  const [rowSelections, setRowSelections] = useState<string[]>([]);
  const [areAllSelected, setAreAllSelected] = useState(false);
  const { formatMessage } = useMelioIntl();
  const { isExtraSmallScreen } = useBreakpoint();

  useEffect(() => {
    const selectableRows = paymentsApprovalsData;
    setAreAllSelected(selectableRows.length > 0 && selectableRows.length === rowSelections.length);
  }, [rowSelections, paymentsApprovalsData]);

  useEffect(() => {
    if (clearSelected) {
      setRowSelections([]);
    }
  }, [clearSelected]);

  const shouldDisableRowSelection = useCallback((payment: Payment) => {
    if (isFXCurrency(payment.currency)) {
      return true;
    }

    return false;
  }, []);

  const onRowSelectionChange = useCallback(
    (selectionState: TableRowSelectionState<Payment>) => {
      setClearSelectedFalse(false);
      const { rowId, isSelected } = selectionState;
      setRowSelections((selectedRows) =>
        isSelected ? [...selectedRows, rowId] : selectedRows.filter((selectedRowId) => selectedRowId !== rowId)
      );
    },
    [setClearSelectedFalse]
  );

  const onAllRowsSelectionChange = useCallback(
    (areAllSelected: boolean) => {
      const rowSelections = areAllSelected ? paymentsApprovalsData?.map((val) => val.id) ?? [] : [];
      setRowSelections(rowSelections);
    },
    [paymentsApprovalsData]
  );
  const rowSelectionTooltips = useMemo(
    () => ({
      header: {
        label: !areAllSelected
          ? formatMessage('activities.payDashboard.billsTab.table.tooltips.selectAll')
          : formatMessage('activities.payDashboard.billsTab.table.tooltips.deselectAll'),
      },
      row: ({ rowData }: { rowData: Payment }) => {
        if (shouldDisableRowSelection(rowData)) {
          return { label: formatMessage('activities.payDashboard.approvalsTab.table.tooltips.disableFXSelection') };
        }

        return undefined;
      },
    }),
    [areAllSelected, shouldDisableRowSelection, formatMessage]
  );

  if (isExtraSmallScreen) {
    return { rowSelections, areAllSelected };
  }

  return {
    rowSelections,
    selectedRows: rowSelections.reduce<Record<string, boolean>>((obj, rowId) => ({ ...obj, [rowId]: true }), {}),
    onRowSelectionChange,
    onAllRowsSelectionChange,
    disableRowSelection: shouldDisableRowSelection,
    rowSelectionTooltips,
    areAllSelected,
  };
};
