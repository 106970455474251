import { useMelioIntl } from '@melio/ar-domain';
import { AvatarImageControl, BaseModal, Button, Container, Group, Text, useBreakpoint } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { useState } from 'react';

export type UploadCompanyLogoModalScreenProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  onSave: () => Promise<unknown>;
  onLogoUpload: (file: File | null) => unknown;
  isSaving: boolean;
  isSaveDisabled?: boolean;
  logoUrl?: string;
};

export const UploadCompanyLogoModalScreen = forwardRef<UploadCompanyLogoModalScreenProps>(
  ({ isOpen, isSaving, isSaveDisabled, logoUrl, onClose, onSave, onLogoUpload, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { isExtraSmallScreen: isMobile } = useBreakpoint();
    const [selectedFileUrl, setSelectedFileUrl] = useState<string | undefined>(logoUrl ?? '');

    const handleLogoUpload = (file: File | null) => {
      file ? setSelectedFileUrl(URL.createObjectURL(file)) : setSelectedFileUrl(undefined);
      onLogoUpload(file);
    };

    return (
      <BaseModal
        isOpen={isOpen}
        onClose={() => !isSaving && onClose()}
        data-testid="upload-company-logo-modal-screen"
        data-component={UploadCompanyLogoModalScreen.displayName}
        {...props}
        ref={ref}
      >
        <BaseModal.Body>
          <Container paddingX={isMobile ? 'xs' : 'm'} paddingY={isMobile ? 'xs' : 'xl'} textAlign="center">
            <Group spacing="xxl" variant="vertical">
              <Group spacing="l" variant="vertical" alignItems="center">
                <Text textStyle="heading2Semi">
                  {formatMessage('ar.invoiceLifecycle.activities.uploadCompanyLogo.modal.header')}
                </Text>
                <Group spacing="s" variant="vertical" alignItems="center" data-testid="upload-company-logo-modal-body">
                  <Text textStyle="body2">
                    {formatMessage('ar.invoiceLifecycle.activities.uploadCompanyLogo.modal.text')}
                  </Text>
                  <AvatarImageControl
                    variant="square"
                    isViewMode={isSaving}
                    viewModePlaceholder="Company Logo"
                    deleteButtonText={formatMessage(
                      'ar.invoiceLifecycle.activities.uploadCompanyLogo.modal.buttons.delete.text'
                    )}
                    value={selectedFileUrl}
                    onChange={handleLogoUpload}
                    acceptTypes={['png', 'jpg', 'pdf']}
                    data-testid="upload-company-logo-avatar-control"
                  />
                </Group>
              </Group>
              <Button
                label={formatMessage('ar.invoiceLifecycle.activities.uploadCompanyLogo.modal.buttons.save.text')}
                onClick={onSave}
                isDisabled={isSaveDisabled}
                data-testid="upload-company-logo-modal-footer-button"
                isLoading={isSaving}
                isFullWidth
              />
            </Group>
          </Container>
        </BaseModal.Body>
      </BaseModal>
    );
  }
);
UploadCompanyLogoModalScreen.displayName = 'UploadCompanyLogoModalScreen';
