import {
  AccountingPlatformConnectActivity,
  AccountingPlatformSyncConnectionErrorEnum,
  SyncFlowEnum,
} from '@melio/ap-activities';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils';

import { useRouter } from '@/hooks/router.hooks';
import { getPartnerName } from '@/utils/partner.utils';

export const AccountingPlatformAuthCallbackScreen = () => {
  const { track } = useAnalytics();
  const { showMessage } = useSystemMessage();
  const { goToAccountingPlatform, goToPayDashboard } = useRouter();
  const { formatMessage } = useMelioIntl();
  const partnerName = getPartnerName();

  const onDone = (flow: SyncFlowEnum, accountingPlatformName: string) => {
    showMessage({
      type: 'success',
      dataTestId: 'accounting-platform-sync-success',
      title: formatMessage('activities.accountingPlatformConnectCallback.success.toast', { accountingPlatformName }),
    });

    if (flow === SyncFlowEnum.Setting) {
      track('SyncAccountingSoftware', 'Status', { PageName: 'accounting-software-sync', Status: 'success' });
      goToAccountingPlatform({});
    } else {
      track('Dashboard', 'Status', { PageName: 'accounting-software-sync', Status: 'success' });
      goToPayDashboard({ keepToast: true });
    }
  };
  const onError = (error: AccountingPlatformSyncConnectionErrorEnum, accountingPlatformId?: string) => {
    track('SyncAccountingSoftware', 'Status', {
      PageName: 'accounting-software-sync',
      Status: 'failure',
    });

    if (!accountingPlatformId) {
      showMessage({
        type: 'error',
        dataTestId: 'accounting-platform-sync-error',
        title: formatMessage('activities.accountingPlatformConnectCallback.authError.toast'),
      });
    }

    goToAccountingPlatform({ syncError: error, accountingPlatformId }, { keepToast: true });
  };

  const onClose = (flow: SyncFlowEnum) => {
    if (flow === SyncFlowEnum.Setting) {
      goToAccountingPlatform({});
    } else {
      goToPayDashboard();
    }
  };

  return (
    <AccountingPlatformConnectActivity partnerName={partnerName} onDone={onDone} onError={onError} onClose={onClose} />
  );
};

AccountingPlatformAuthCallbackScreen.displayName = 'AccountingPlatformAuthCallbackScreen';
