import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { AccountingPlatformInitQuickBooksDesktopResponseData } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils';
import { useEffect } from 'react';

import { ConnectToQuickBooksDesktopScreen } from '../screens/ConnectToQuickBooksDesktop';
import { ConnectionScreenCtaType, SyncFlowEnum } from '../types';

type Props = {
  flow?: SyncFlowEnum;
  isError: boolean;
  isLoading: boolean;
  isVerifyLoading: boolean;
  initQuickBooksDesktopData?: AccountingPlatformInitQuickBooksDesktopResponseData;
  onCancel: VoidFunction;
  onVerifyConnection: () => Promise<void>;
  initQuickBooksDesktop: () => Promise<void>;
  onDownloadWebConnector: VoidFunction;
};

export const ConnectToQuickBooksDesktopStep = ({
  flow,
  isError,
  isLoading,
  isVerifyLoading,
  initQuickBooksDesktopData,
  onCancel,
  onVerifyConnection,
  initQuickBooksDesktop,
  onDownloadWebConnector,
}: Props) => {
  const { showMessage } = useSystemMessage();
  const { formatMessage } = useMelioIntl();
  const { track, createTrackHandler } = useAnalytics();
  const defaultAnalyticsFields = {
    PageName: 'connect-to-quickbooks-desktop',
    Flow: `connect-accounting-software`,
    Intent: `sync`,
    EntryPoint: flow === SyncFlowEnum.Setting ? 'settings-page' : 'dashboard',
  };
  const trackAndHandleClick = createTrackHandler<{
    Cta: ConnectionScreenCtaType;
  }>('SyncAccountingSoftware', 'Click');

  useAnalyticsView('SyncAccountingSoftware', true, true, {
    ...defaultAnalyticsFields,
    ErrorType: isError ? 'could-not-verify-connection' : null,
  });

  useEffect(() => {
    track('SyncAccountingSoftware', 'View', {
      ...defaultAnalyticsFields,
      ErrorType: isError ? 'could-not-verify-connection' : null,
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (!initQuickBooksDesktopData) {
      initQuickBooksDesktop();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initQuickBooksDesktopData]);

  const onCancelClick = () => {
    trackAndHandleClick({ Cta: 'cancel', ...defaultAnalyticsFields }, onCancel);
  };

  const onSubmitClick = async () => {
    trackAndHandleClick({ Cta: 'verify-connection', ...defaultAnalyticsFields }, await onVerifyConnection());
  };

  const onDownloadWebConnectorClick = () => {
    trackAndHandleClick({ Cta: 'download-file', ...defaultAnalyticsFields }, onDownloadWebConnector());
  };

  const onGuideLinkClick = (cta: ConnectionScreenCtaType) => {
    trackAndHandleClick({ Cta: cta, ...defaultAnalyticsFields });
  };

  const handleCopyToClipboard = (value: string) => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        showMessage({
          type: 'informative',
          dataTestId: 'copy-password-success',
          title: formatMessage(
            'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.copyPassword.toast.success'
          ),
        });
      })
      .catch(() =>
        showMessage({
          type: 'error',
          title: formatMessage(
            'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.copyPassword.toast.error'
          ),
        })
      );
  };

  const onCopyPassword = (value?: string) => {
    if (value) {
      trackAndHandleClick({ Cta: 'copy-password' }, handleCopyToClipboard(value));
    }
  };

  return (
    <ConnectToQuickBooksDesktopScreen
      isLoading={isLoading}
      isVerifyLoading={isVerifyLoading}
      initQuickBooksDesktopData={initQuickBooksDesktopData}
      onCancel={onCancelClick}
      onCopyPassword={onCopyPassword}
      onVerifyConnection={onSubmitClick}
      onGuideLinkClick={onGuideLinkClick}
      onDownloadWebConnector={onDownloadWebConnectorClick}
    />
  );
};
