import { FormattedMessage } from '@melio/ar-domain';
import { Container, Group, Illustration, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export const EmptyTableState = forwardRef((props, ref) => (
  <Container
    data-component={EmptyTableState.displayName}
    data-testid="pay-dashboard-no-search-result"
    paddingY="xxxl"
    {...props}
    ref={ref}
  >
    <Group spacing="s" alignItems="center" justifyContent="center" variant="vertical">
      <Illustration type="no-items" size="small" />
      <Text>
        <FormattedMessage id="ar.dashboard.activities.invoiceTable.emptyState.label" />
      </Text>
    </Group>
  </Container>
));
EmptyTableState.displayName = 'EmptyTableState';
