import { Flex } from '@chakra-ui/react';
import { useBreakpoint } from '@melio/penny';

export const FormContentDisplay = ({ children }: { children: React.ReactNode; isLineItems?: boolean }) => {
  const { isExtraSmallScreen: isMobile } = useBreakpoint();

  if (isMobile) {
    return (
      <Flex flexDir="column" gap="s">
        {children}
      </Flex>
    );
  }

  return <>{children}</>;
};
