import { Container, Group, Modal, SectionBanner, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { useSubscriptionGracePeriod } from '../../../hooks';

export type SubscriptionGraceTimeToDecideModalProps = {
  isOpen: boolean;
  onDismiss: () => void;
  onConfirmSwitchSubscriptionPlan: () => void;
  planName: string;
};

export const SubscriptionGraceTimeToDecideModal = ({
  isOpen,
  onDismiss,
  onConfirmSwitchSubscriptionPlan,
  planName,
}: SubscriptionGraceTimeToDecideModalProps) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const { gracePeriodEndDateToDisplay } = useSubscriptionGracePeriod();

  return (
    <Modal
      isOpen={isOpen}
      size="small"
      header={formatMessage('activities.subscription.graceTimeToDecideModal.title')}
      onClose={onDismiss}
      primaryButton={{
        label: formatMessage('activities.subscription.graceTimeToDecideModal.decideLater'),
        onClick: onDismiss,
        variant: 'primary',
      }}
      secondaryButton={{
        variant: 'tertiary',
        label: formatMessage('activities.subscription.graceTimeToDecideModal.switchPlan', {
          planName,
        }),
        onClick: onConfirmSwitchSubscriptionPlan,
      }}
      data-testid="subscription-grace-time-to-decide-modal"
    >
      <Container paddingX="none" paddingTop="none" overflow="auto">
        <Group variant="vertical" spacing="l">
          <Text data-testid="subscription-grace-time-to-decide-modal-description">
            {formatMessage('activities.subscription.graceTimeToDecideModal.description', {
              date: formatDate(gracePeriodEndDateToDisplay, { month: 'short', day: 'numeric' }),
            })}
          </Text>

          <SectionBanner
            description={formatMessage('activities.subscription.graceTimeToDecideModal.importantNote.description', {
              planName,
            })}
            size="large"
            title={formatMessage('activities.subscription.graceTimeToDecideModal.importantNote.title')}
            icon="info"
            variant="neutral"
          />
        </Group>
      </Container>
    </Modal>
  );
};
