import { CardHolderDetailsForm } from '@melio/ap-widgets';
import { useFormSubmissionController } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { CardHolderDetails } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';

export type CardHolderDetailsScreenProps = {
  isSaving?: boolean;
  defaultValues?: Partial<CardHolderDetails>;
  onBack: VoidFunction;
  onClose: VoidFunction;
  onDone: (data: CardHolderDetails) => void;
};

export const CardHolderDetailsScreen: React.VFC<CardHolderDetailsScreenProps> = forwardRef<
  CardHolderDetailsScreenProps,
  'div'
>(({ defaultValues, isSaving, onBack, onClose, onDone, ...props }, ref) => {
  const { formatMessage } = useMelioIntl();
  const { onSubmissionStateChange, cancelButtonProps, submitButtonProps } =
    useFormSubmissionController<CardHolderDetails>();

  useAnalyticsView('AddCCHolderDetails');

  return (
    <NewSinglePaymentStepLayout
      data-component="AddCardFundingSourceActivity.CardHolderDetailsScreen"
      data-testid="add-card-funding-source-activity-card-holder-details-screen"
      ref={ref}
      {...props}
      headerContent={
        <NewSinglePaymentStepLayout.Header>
          <NewSinglePaymentStepLayout.CloseButton {...cancelButtonProps} onClick={onClose} />
          <NewSinglePaymentStepLayout.BackButton {...cancelButtonProps} onClick={onBack} />
        </NewSinglePaymentStepLayout.Header>
      }
      footerContent={
        <NewSinglePaymentStepLayout.Actions>
          <NewSinglePaymentStepLayout.NextButton
            {...submitButtonProps}
            label={formatMessage('activities.addCardFundingSource.screens.cardHolderDetails.continue')}
          />
        </NewSinglePaymentStepLayout.Actions>
      }
    >
      <NewSinglePaymentStepLayout.Title>
        {formatMessage('activities.addCardFundingSource.screens.cardHolderDetails.title')}
      </NewSinglePaymentStepLayout.Title>
      <NewSinglePaymentStepLayout.Content>
        <CardHolderDetailsForm
          onSubmit={onDone}
          defaultValues={defaultValues}
          isSaving={isSaving}
          onSubmissionStateChange={onSubmissionStateChange}
        />
      </NewSinglePaymentStepLayout.Content>
    </NewSinglePaymentStepLayout>
  );
});

CardHolderDetailsScreen.displayName = 'AddCardFundingSourceActivity.CardHolderDetailsScreen';
