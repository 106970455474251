import {
  addDays as addDaysFns,
  format,
  // eslint-disable-next-line no-restricted-imports
  isEqual as isEqualFns,
} from 'date-fns';

export const sortDates = (
  dateA: Date | string | undefined,
  dateB: Date | string | undefined,
  sortOption: 'asc' | 'desc' = 'asc',
) => {
  if (!dateA && !dateB) {
    return 0;
  } else if (!dateA) {
    return sortOption === 'asc' ? -1 : 1;
  } else if (!dateB) {
    return sortOption === 'asc' ? 1 : -1;
  }

  const date1 = typeof dateA === 'string' ? new Date(dateA) : dateA;
  const date2 = typeof dateB === 'string' ? new Date(dateB) : dateB;

  if (date1 < date2) {
    return sortOption === 'asc' ? -1 : 1;
  } else if (date1 > date2) {
    return sortOption === 'asc' ? 1 : -1;
  } else {
    return 0;
  }
};

export const isEqual = (dateLeft: Date, dateRight: Date) => isEqualFns(dateLeft, dateRight);
export const dateFormatToOpenApiSpec = (date: Date) => format(date, 'yyyy-MM-dd');
export const addDays = (date: Date, days: number) => addDaysFns(date, days);
