import React from 'react';
import { IconProps, LinkBox, LinkOverlay } from '@chakra-ui/react';
import { Text, useBreakpointValue } from '@melio/penny';

import { BackToPartnerButtonTheme } from '@/cl/components/BackToPartnerButton/BackToPartnerButton.component.theme';
import { useAppTheme } from '@/cl/theme/theme.hooks';
import { useIsMobile } from '@/hooks/useIsMobile';

type BackToPartnerButtonProps = {
  BackIcon: (props: IconProps) => JSX.Element;
  buttonText: string;
  href: string;
  onClick?: VoidFunction;
};

export const BackToPartnerButton = ({ BackIcon, href, buttonText }: BackToPartnerButtonProps) => {
  const isMobile = useIsMobile();
  const styles = useAppTheme(BackToPartnerButtonTheme, { isMobile });
  const textStyle = useBreakpointValue({ xs: 'body4', s: 'body1' });

  return (
    <LinkBox>
      <LinkOverlay data-testid="back-to-partner-button" href={href} sx={styles['backIconContainer']}>
        <BackIcon />
        <Text textStyle={textStyle}>{buttonText}</Text>
      </LinkOverlay>
    </LinkBox>
  );
};
