import { useMelioIntl } from '@melio/platform-i18n';
import { Plan, PlanCardViewProps, usePlanInfo } from '@melio/subscriptions';
import { usePredefinedDiscountedPlanPricing } from '@melio/subscriptions/src/hooks';

export const useFirmClientPlanCardViewProps = ({
  plan,
  discountPercentage,
}: {
  plan: Plan;
  discountPercentage?: number;
}): PlanCardViewProps => {
  const { id, tier } = plan;
  const { priceBeforeDiscount, finalPrice, unitPrice, selectedBillingCycle } = usePredefinedDiscountedPlanPricing({
    planCycle: plan.cycles.monthly,
    discountPercentage,
  });
  const { formatMessage } = useMelioIntl();
  const planName = formatMessage(`activities.subscription.plans.${tier}.title`);
  const { capabilities } = usePlanInfo(plan.id);

  return {
    id,
    headerProps: {
      planName,
      planDescription: formatMessage(`activities.subscription.plans.${tier}.description`),
    },
    priceProps: {
      priceBeforeDiscount,
      finalPrice,
      isFreePlan: false,
      selectedBillingCycle,
      unitPrice,
    },
    featureListProps: {
      capabilities,
    },
  };
};
