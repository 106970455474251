import { IconKey } from '@melio/penny';
import { DeliveryMethod, formatAddress, useDeliveryMethodUnmaskedAccountNumber, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export const useDeliveryMethodCardProps = ({
  vendor,
  deliveryMethod,
}: {
  vendor: Vendor | undefined;
  deliveryMethod: DeliveryMethod | undefined;
}): { title: string; description: string; icon: IconKey; isLoading: boolean } => {
  const { formatMessage } = useMelioIntl();
  const { data: unmaskedAccountNumber, isLoading } = useDeliveryMethodUnmaskedAccountNumber({
    enabled: deliveryMethod?.type === 'bank-account',
    deliveryMethodId: deliveryMethod?.id || 'useDeliveryMethodCardPropsDeliveryMethodId',
  });

  if (!vendor || !deliveryMethod) {
    return {
      title: '',
      isLoading: false,
      icon: 'error',
      description: '',
    };
  }

  switch (deliveryMethod.type) {
    case 'managed-account':
      if (deliveryMethod.details.deliveryType === 'check') {
        return {
          title: formatMessage('activities.paymentFlow.form.content.deliveryMethodCard.managed-account.check.title'),
          description: formatMessage(
            'activities.paymentFlow.form.content.deliveryMethodCard.managed-account.check.description',
            { vendorName: vendor.name }
          ),
          icon: 'paper-check',
          isLoading: false,
        };
      }
      return {
        title: formatMessage(
          'activities.paymentFlow.form.content.deliveryMethodCard.managed-account.bank-account.title'
        ),
        description: formatMessage(
          'activities.paymentFlow.form.content.deliveryMethodCard.managed-account.bank-account.description',
          { vendorName: vendor.name }
        ),
        icon: 'bank-out',
        isLoading: false,
      };
    case 'bank-account':
      return {
        title: formatMessage('activities.paymentFlow.form.content.deliveryMethodCard.bank-account.title'),
        description: formatMessage('activities.paymentFlow.form.content.deliveryMethodCard.bank-account.description', {
          account: unmaskedAccountNumber?.accountNumber?.slice(-4),
        }),
        icon: 'bank-out',
        isLoading,
      };
    case 'paper-check':
      return {
        title: formatMessage('activities.paymentFlow.form.content.deliveryMethodCard.paper-check.title'),
        description: formatMessage('activities.paymentFlow.form.content.deliveryMethodCard.paper-check.description', {
          vendorName: vendor.name,
          address: formatAddress(deliveryMethod.details.address),
        }),
        icon: 'paper-check',
        isLoading: false,
      };
    default:
      return {
        title: 'not implemented',
        description: 'not implemented',
        icon: 'error',
        isLoading: false,
      };
  }
};
