import { useModelApi, UseModelApiProps } from '@melio/api-client';
import { FinancingApiClient, RepaymentTerms } from '@melio/platform-api-axios-client';
import { convertDollarsToCents } from '@melio/platform-utils';

import { GetRepaymentTermsFn } from './types';
import { convertFinancingRepaymentTermsResponseCentsToDollars } from './utils';

export type UseFinancingProps = UseModelApiProps<GetRepaymentTermsFn, RepaymentTerms> & {
  paymentAmount: number;
  vendorId: string;
};

export const useFinancingRepaymentTerms = (args: UseFinancingProps) => {
  const { paymentAmount } = convertDollarsToCents(args, ['paymentAmount']);
  return useModelApi({
    ...args,
    id: args.vendorId,
    queryKey: ['FinancingApi', args.id, args.paymentAmount],
    queryFn: (id) => FinancingApiClient.getRepaymentTerms(id, paymentAmount),
    select: convertFinancingRepaymentTermsResponseCentsToDollars,
  });
};
