import { FormattedMessage, invoiceNumberPaddingLength } from '@melio/ar-domain';
import { Container, Group, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { padStart } from 'lodash';

type InvoiceFormatSummaryProps = {
  invoiceNumber: number;
  prefix: string;
};

export const InvoiceFormatSummary = forwardRef<InvoiceFormatSummaryProps>(({ prefix, invoiceNumber }, ref) => (
  <Container paddingY="xs" ref={ref}>
    <Group variant="vertical">
      <Text textStyle="body2">
        <FormattedMessage id="ar.settings.flows.invoiceSettings.sections.invoiceFormat.modal.summary.title.text" />
      </Text>
      <Group width="fit-content">
        <Container border="dashed" paddingX="l" paddingY="m" backgroundColor="light">
          <Text textStyle="body3Semi">
            <FormattedMessage id="ar.settings.flows.invoiceSettings.sections.invoiceFormat.modal.summary.invoicePrefix.text" />{' '}
            {prefix}
            {padStart(invoiceNumber.toString(), invoiceNumberPaddingLength, '0')}
          </Text>
        </Container>
      </Group>
    </Group>
  </Container>
));
InvoiceFormatSummary.displayName = 'InvoiceFormatSummary';
