import { _TableHeaderCellProps, SortDirection, Table } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useCallback } from 'react';

import { PayDashboardSortingType } from '../types';

const invertOrder = (order: SortDirection) => (order === 'asc' ? 'desc' : 'asc');

export const useTableColumnsSort = <SortableCells extends string>({
  sorting,
  onSortChange,
}: {
  sorting: PayDashboardSortingType | null;
  onSortChange?: VoidFunction;
}) => {
  const { track } = useAnalytics();
  const isNpeSortEnabled = useFeature(FeatureFlags.NpeSort, false);

  const sortColumn = useCallback(
    (cellId: SortableCells, defaultSortOrder: SortDirection) => {
      const sortOrder =
        sorting?.sortingState?.id === cellId ? invertOrder(sorting.sortingState.sortDirection) : defaultSortOrder;

      track('Dashboard', 'Click', {
        Intent: 'sort',
        SortDirection: sortOrder,
        Cta: `sort-${cellId}`,
        PreviousSortingColumn: sorting?.sortingState?.id ?? null,
        PreviousSortingDirection: sorting?.sortingState?.sortDirection ?? null,
      });

      sorting?.setSortState({ id: cellId, sortDirection: sortOrder });
      onSortChange?.();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sorting]
  );
  const getSortableHeader = useCallback(
    ({
      label,
      cellId,
      textAlign,
      defaultSortOrder,
    }: {
      label: string;
      cellId: SortableCells;
      textAlign?: _TableHeaderCellProps['textAlign'];
      defaultSortOrder: SortDirection;
    }) => (
      <Table.HeaderSortableCell
        aria-labelledby={`header-sortable-cell-${cellId}`}
        sortDirection={sorting?.sortingState?.id === cellId ? sorting.sortingState.sortDirection : undefined}
        initialSortDirection={defaultSortOrder}
        textAlign={textAlign}
        onClick={isNpeSortEnabled ? () => sortColumn(cellId, defaultSortOrder) : undefined}
      >
        {label}
      </Table.HeaderSortableCell>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sorting, isNpeSortEnabled]
  );

  return { getSortableHeader, sortColumn };
};
