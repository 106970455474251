import { Plan, PlanCardView } from '@melio/subscriptions';

import { useFirmClientPlanCardViewProps } from '../../../hooks';

export const FirmClientPlan = ({
  plan,
  isSelected,
  onSelect,
  discountPercentage,
}: {
  plan: Plan;
  isSelected: boolean;
  onSelect: VoidFunction;
  discountPercentage?: number;
}) => {
  const cardProps = useFirmClientPlanCardViewProps({ plan, discountPercentage });

  return <PlanCardView {...cardProps} shouldRenderCurrentPlanVariation={isSelected} onClick={onSelect} />;
};
