import { Form, FormSelectNewOption, FormSelectNewProps, Group, Icon, Text } from '@melio/penny';
import {
  AccountingPlatform,
  AccountingPlatformBillLineItemLabelOption,
  AccountingPlatformBillLineItemLabelOptionStatusEnum,
  AccountingPlatformSlug,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import { useMemo } from 'react';

import { generateBillLineItemLabelSelectOptions, getPlaceholder } from './utils';

export type AccountingPlatformBillLineItemLabelSelectWidgetProps = {
  isFetched: boolean;
  initialLabelId?: string;
  billLineItemLabelOptions?: AccountingPlatformBillLineItemLabelOption[];
  activeAccountingPlatform?: AccountingPlatform;
} & Omit<FormSelectNewProps, 'value' | 'options'>;

export const AccountingPlatformBillLineItemLabelSelectWidget = forwardRef<
  AccountingPlatformBillLineItemLabelSelectWidgetProps,
  'input'
>(
  (
    {
      isFetched,
      initialLabelId,
      billLineItemLabelOptions,
      activeAccountingPlatform,
      isReadOnly,
      isViewMode,
      viewModePlaceholder,
      ...props
    },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();

    const { accountingSlug } = activeAccountingPlatform || {};
    const isQuickBooksOnline = accountingSlug === AccountingPlatformSlug.QuickBooksOnline;
    const placeholder = getPlaceholder(isQuickBooksOnline, formatMessage, isViewMode);
    const filteredBillLineItemLabels = useMemo(
      () =>
        billLineItemLabelOptions?.filter(
          (o) => o?.id === initialLabelId || o?.status !== AccountingPlatformBillLineItemLabelOptionStatusEnum.Archived
        ) || [],
      [billLineItemLabelOptions, initialLabelId]
    );
    const processedOptions = generateBillLineItemLabelSelectOptions(filteredBillLineItemLabels);

    const optionRenderer = (option: FormSelectNewOption) => (
      <Group alignItems="center" spacing="s">
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        {option.isNested && <Icon type="sub-arrow" size="small" />}
        <Text textStyle="inline" shouldSupportEllipsis color="inherit">
          {option.label}
        </Text>
      </Group>
    );

    return (
      <Form.SelectNew
        options={processedOptions}
        optionRenderer={optionRenderer}
        data-component="AccountingPlatformBillLineItemLabelSelect"
        ref={ref}
        isLoading={!isFetched && !isReadOnly && !isViewMode}
        placeholder={placeholder}
        viewModePlaceholder={placeholder}
        isReadOnly={isReadOnly}
        isViewMode={isViewMode}
        {...props}
      />
    );
  }
);

AccountingPlatformBillLineItemLabelSelectWidget.displayName = 'AccountingPlatformBillLineItemLabelSelect';
