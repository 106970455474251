import { isJoinOrganizationRequestExpired, JoinOrganizationRequest } from '@melio/platform-api';

import { JoinOrganizationRequestCard } from '@/cl/components/JoinOrganizationRequestCard/JoinOrganizationRequestCard.component';

type JoinOrganizationRequestCardWidgetProps = {
  joinOrganizationRequest: JoinOrganizationRequest;
  onRequestCancel: VoidFunction;
  onRequestResend: VoidFunction;
};

export const JoinOrganizationRequestCardWidget = ({
  joinOrganizationRequest,
  onRequestCancel,
  onRequestResend,
}: JoinOrganizationRequestCardWidgetProps) => {
  const isExpired = isJoinOrganizationRequestExpired(joinOrganizationRequest);

  return (
    <JoinOrganizationRequestCard
      companyName={joinOrganizationRequest.companyName}
      status={joinOrganizationRequest.status}
      isExpired={isExpired}
      id={joinOrganizationRequest.id}
      onRequestCancel={onRequestCancel}
      onRequestResend={onRequestResend}
    />
  );
};
