import {
  FinancingEligibilityStatus,
  useFinancingCachedOrgEligibility,
  useFinancingOrgEligibility,
  usePayments,
} from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { usePermissions } from '@melio/platform-permissions';

import { useIsMobile } from '../../../utils/viewport-utils/useIsMobile';
import { PayDashboardTabs } from '../types';
import { useCanPayWithFinancing } from './useCanPayWithFinancing';

export type ShouldShowTabs = {
  isLoading: boolean;
  shouldShow: {
    [key in PayDashboardTabs]: boolean;
  };
};

export const usePayDashboardTabsToShow = (): ShouldShowTabs => {
  const [isApprovalWorkflowsFFEnabled] = useFeature(FeatureFlags.ApprovalWorkflows, false, {
    shouldTrack: true,
  });
  const [isFinancingFFEnabled] = useFeature(FeatureFlags.PlatformFinancing, false);
  const [isFinancingApplicationFlowEnabled] = useFeature(FeatureFlags.PlatformFinancingApplicationFlow, false);
  const { data: cachedOrgEligibility, isLoading: isLoadingCachedOrgEligibility } = useFinancingCachedOrgEligibility({
    enabled: !isFinancingApplicationFlowEnabled,
  });

  const { data: orgEligibility, isLoading: isLoadingOrgEligibility } = useFinancingOrgEligibility({
    enabled: isFinancingApplicationFlowEnabled,
  });

  const { can } = usePermissions();
  const isMobile = useIsMobile();
  const { data: financedPayments } = usePayments({
    refetchOnMount: 'always',
    params: {
      search: { 'payment.isFinanced': true },
      expand: 'none',
      limit: 1,
    },
  });

  const { canReadRepaymentTerms } = useCanPayWithFinancing();

  const financingEligibilityStatus = !isFinancingApplicationFlowEnabled
    ? cachedOrgEligibility?.status
    : orgEligibility?.status;

  const financingAppliedToProvider = orgEligibility?.appliedToProvider ?? cachedOrgEligibility?.appliedToProvider;

  const supportedFinancingEligibilityStatus =
    financingEligibilityStatus === FinancingEligibilityStatus.Eligible ||
    financingEligibilityStatus === FinancingEligibilityStatus.Pending;

  const shouldShowFinancingTab =
    isFinancingFFEnabled &&
    (canReadRepaymentTerms || !!financedPayments?.length) &&
    (isMobile
      ? financingEligibilityStatus === FinancingEligibilityStatus.Eligible && !!financingAppliedToProvider
      : !!(supportedFinancingEligibilityStatus || financedPayments?.length));

  const shouldShowApprovalsTab =
    isApprovalWorkflowsFFEnabled && can({ subject: 'payment:approvalDecision', action: 'approve' });

  const shouldShowAllTabs = Object.values(PayDashboardTabs).reduce((acc, value) => {
    acc[value] = true;
    return acc;
  }, {} as { [key in PayDashboardTabs]: boolean });

  return {
    isLoading: isLoadingCachedOrgEligibility || isLoadingOrgEligibility,
    shouldShow: {
      ...shouldShowAllTabs, // this is so that any future tab added will be shown by default unless specified otherwise
      ...{
        [PayDashboardTabs.Approvals]: shouldShowApprovalsTab,
        [PayDashboardTabs.Financing]: shouldShowFinancingTab,
      },
    },
  };
};
