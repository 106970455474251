import { FormDialog, FormWidgetProps, useMelioIntl } from '@melio/ar-domain';
import { Form, useMelioForm } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import * as yup from 'yup';

export type CustomPaymentDetailsFormFields = {
  customPayInstructions: string;
};

const useValidationSchema = (): yup.SchemaOf<CustomPaymentDetailsFormFields> => {
  const { formatMessage } = useMelioIntl();

  return yup.object().shape({
    customPayInstructions: yup
      .string()
      .required(
        formatMessage(
          'ar.settings.flows.invoiceSettings.sections.editInvoiceCustomPaymentDetails.modal.fields.customPayInstructions.validations.required.text'
        )
      ),
  });
};

export type EditCustomPaymentDetailsDialogScreenProps = Pick<
  FormWidgetProps<CustomPaymentDetailsFormFields>,
  'defaultValues' | 'isSaving' | 'onSubmit'
> & {
  onClose: VoidFunction;
  isOpen: boolean;
  isLoading: boolean;
};
export const EditCustomPaymentDetailsDialogScreen = forwardRef<EditCustomPaymentDetailsDialogScreenProps>(
  ({ isOpen, onSubmit, defaultValues, isSaving, isLoading, onClose, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const { registerField, watch, ...rest } = useMelioForm<CustomPaymentDetailsFormFields>({
      schema: useValidationSchema(),
      isSaving,
      defaultValues,
      onSubmit,
      subscribeToDefaultValuesChanges: true,
    });

    return (
      <FormDialog
        closeButtonAriaLabel={formatMessage(
          'ar.settings.flows.invoiceSettings.sections.editInvoiceCustomPaymentDetails.modal.closeButtonAriaLabel'
        )}
        data-component={EditCustomPaymentDetailsDialogScreen.displayName}
        isOpen={isOpen}
        size="large"
        isLoading={isLoading}
        onClose={() => !isSaving && onClose()}
        header={formatMessage(
          'ar.settings.flows.invoiceSettings.sections.editInvoiceCustomPaymentDetails.modal.title.text'
        )}
        description={formatMessage(
          'ar.settings.flows.invoiceSettings.sections.editInvoiceCustomPaymentDetails.modal.subtitle.text'
        )}
        primaryButton={{
          label: formatMessage(
            'ar.settings.flows.invoiceSettings.sections.editInvoiceCustomPaymentDetails.modal.primaryButton.label'
          ),
        }}
        secondaryButton={{
          label: formatMessage(
            'ar.settings.flows.invoiceSettings.sections.editInvoiceCustomPaymentDetails.modal.secondaryButton.label'
          ),
        }}
        useFormResults={rest}
        {...props}
        ref={ref}
      >
        <Form.TextArea
          labelProps={{
            label: formatMessage(
              'ar.settings.flows.invoiceSettings.sections.editInvoiceCustomPaymentDetails.modal.fields.customPayInstructions.label'
            ),
          }}
          placeholder={formatMessage(
            'ar.settings.flows.invoiceSettings.sections.editInvoiceCustomPaymentDetails.modal.fields.customPayInstructions.placeholder.text'
          )}
          helperTextProps={{
            label: formatMessage(
              'ar.settings.flows.invoiceSettings.sections.editInvoiceCustomPaymentDetails.modal.fields.customPayInstructions.helperText.text'
            ),
          }}
          {...registerField('customPayInstructions')}
          data-private
          maxChars={300}
        />
      </FormDialog>
    );
  }
);
