import { apiClient, FreeChecksData } from '@melio/javascript-sdk';

import { useModelApi, UseModelApiProps, UseModelApiResult } from '../../core';

type FreeChecksModel = UseModelApiResult<FreeChecksData>;

type UseFreeChecksProps = UseModelApiProps<FreeChecksData>;

export const useFreeChecks = (props: UseFreeChecksProps = {}): FreeChecksModel => {
  const client = apiClient.freeChecks();

  return useModelApi<FreeChecksData>({
    ...props,
    id: 'free-checks',
    queryKey: 'free-checks',
    queryFn: client.get,
    cacheTime: 0,
  });
};
