import { FundingSource } from '@melio/platform-api';
import { usePermissions } from '@melio/platform-permissions';

type Props = {
  fundingSource?: FundingSource;
};

type ReturnType = {
  canUpdate: boolean;
};

export const useFundingSourcePermissions = ({ fundingSource }: Props): ReturnType => {
  const { can } = usePermissions();
  const canUpdate = can({
    subject: 'fundingSource',
    action: 'update',
    subjectData: {
      createdById: fundingSource?.createdById,
    },
  });

  return { canUpdate };
};
