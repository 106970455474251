import { BankAccountFormModel } from '@melio/ap-widgets';
import { useToast } from '@melio/penny';
import { Traits, useAnalytics } from '@melio/platform-analytics';
import { BankAccountDeliveryMethod, useDeliveryMethods, useVendor } from '@melio/platform-api';

import { AddVendorBankDetailsScreen } from './screens';
import { AddVendorBankDetailsActivityProps, SubmissionTargetType } from './types';

export const AddVendorBankDetailsActivity = ({
  vendorId,
  onBack: onFirstStepBack,
  onClose,
  onError,
  onDone,
  origin = 'payment',
  bottomContent,
  defaultValues,
}: AddVendorBankDetailsActivityProps) => {
  const collection = useDeliveryMethods({ vendorId, enabled: false });
  const vendorModel = useVendor({ id: vendorId });
  const vendorName = vendorModel.data?.name || '';
  const { toast } = useToast();

  const handleFail = (error: PlatformError) => {
    toast({ type: 'error', title: error.message });
    onError?.(error);
  };

  const { createTrackHandler, setTraits } = useAnalytics();
  const submitHandler = createTrackHandler<{ Status: 'failed' | 'succeeded' }>('AddVendorBankDetails', 'Saved');
  const setDeliveryMethodTraits = () => {
    const traits: Traits = { added_delivery_method: true };

    setTraits(traits);
  };

  const createDeliveryMethod = (data: BankAccountFormModel) =>
    collection.create({ type: 'bank-account', details: { ...data, accountType: 'checking' } });

  const createDeliveryMethodAndSubmitTrack = (data: BankAccountFormModel, target?: SubmissionTargetType) => {
    createDeliveryMethod(data)
      .then((data) => {
        submitHandler({ Status: 'succeeded' });
        setDeliveryMethodTraits();
        onDone(data as BankAccountDeliveryMethod, target);
      })
      .catch((...args) => submitHandler({ Status: 'failed' }, () => handleFail(...args)));
  };

  return (
    <AddVendorBankDetailsScreen
      onBack={onFirstStepBack}
      onClose={onClose}
      onDone={(data, _event, target) => createDeliveryMethodAndSubmitTrack(data, target)}
      isSaving={collection.isMutating}
      origin={origin}
      vendorName={vendorName}
      bottomContent={bottomContent}
      defaultValues={defaultValues}
    />
  );
};

AddVendorBankDetailsActivity.displayName = 'AddVendorBankDetailsActivity';
