import { _TableHeaderCellProps, Table } from '@melio/penny';
import { VFC } from 'react';

type Order = 'Asc' | 'Desc';

export type SortableHeaderCellProps<T extends string> = {
  label: string;
  order?: Order;
  field?: T;
  selectedField?: T;
  textAlign?: _TableHeaderCellProps['textAlign'];
  onClick: (field: T, order: Order) => void;
};

type WithDisplayName = VFC<SortableHeaderCellProps<string>> & {
  displayName: string;
};

export const SortableHeaderCell: <T extends string>(props: SortableHeaderCellProps<T>) => JSX.Element = ({
  label,
  order,
  field,
  selectedField,
  onClick,
  textAlign,
  ...props
}) => {
  const isSameField = !!field && field === selectedField;
  const targetOrder = isSameField ? (order === 'Asc' ? 'Desc' : 'Asc') : 'Desc';

  return (
    <Table.HeaderSortableCell
      data-component={(SortableHeaderCell as WithDisplayName).displayName}
      data-testid={`sortable-header-cell-${field ?? ''}`}
      textAlign={textAlign}
      onClick={field ? () => onClick(field, targetOrder) : undefined}
      sortDirection={isSameField ? (order === 'Desc' ? 'desc' : 'asc') : undefined}
      {...props}
    >
      {label}
    </Table.HeaderSortableCell>
  );
};

(SortableHeaderCell as WithDisplayName).displayName = 'SortableHeaderCell';
