import { Container, Link, NakedButton, SectionBanner, Text } from '@melio/penny';
// eslint-disable-next-line no-restricted-imports
import { FirmClientExpanded } from '@melio/platform-api-axios-client';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSubscriptionGracePeriod } from '@melio/subscriptions/src/hooks';
import { useState } from 'react';

import { useIsSmallScreen } from '../../../../hooks';
import { ClientsPlanSelectionModal } from './ClientsPlanSelectionModal';

export const TopPlanSelectionBanner = ({ clients }: { clients: FirmClientExpanded[] }) => {
  const [opened, setOpened] = useState<boolean>(false);
  const { formatMessage, formatDate } = useMelioIntl();
  const isSmallScreen = useIsSmallScreen();
  const { gracePeriodEndDateToDisplay } = useSubscriptionGracePeriod();

  // TODO: add a link while we get one
  const learnMoreLink = (
    <Link label={formatMessage('activities.accountants.firmDashboard.topBanner.description.link.label')} href="#" />
  );

  return (
    <>
      <SectionBanner
        title={formatMessage('activities.accountants.firmDashboard.topBanner.title', {
          endDate: formatDate(gracePeriodEndDateToDisplay, {
            month: 'short',
            day: 'numeric',
          }),
        })}
        description={
          <Container paddingRight={!isSmallScreen ? 'xxxl' : 's'}>
            <Container paddingRight={!isSmallScreen ? 'xxxl' : 's'}>
              <Text textStyle="body3">
                {formatMessage('activities.accountants.firmDashboard.topBanner.description', {
                  endDate: formatDate(gracePeriodEndDateToDisplay, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  }),
                  link: learnMoreLink,
                })}
              </Text>
            </Container>
          </Container>
        }
        data-testid="firm-dashboard-plan-selection-banner"
        icon="crown"
        size="large"
        variant="neutral"
        action={
          <NakedButton
            label={formatMessage('activities.accountants.firmDashboard.topBanner.action.button.label')}
            data-testid="firm-dashboard-plan-selection-banner-open-selection"
            onClick={() => setOpened(true)}
          />
        }
      />
      <ClientsPlanSelectionModal isOpen={opened} clients={clients} onClose={() => setOpened(false)} />
    </>
  );
};
