import { InvoiceDiscount, useMelioIntl } from '@melio/ar-domain';
import { Table, TableColumnDef, useTable } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { useCallback, useMemo } from 'react';

import { DiscountActionCell } from './DiscountActionCell';
import { DiscountsTableListItem } from './DiscountsTableListItem.mobile';

export type DiscountsTableProps = {
  isLoading: boolean;
  discounts: InvoiceDiscount[];
  onEdit: (discountId: string) => unknown;
  onDelete: (discountId: string) => unknown;
};

export const DiscountsTable = forwardRef<DiscountsTableProps>(
  ({ discounts, onDelete, onEdit, isLoading, ...props }, ref) => {
    const { formatMessage, formatCurrency, formatPercents } = useMelioIntl();
    const mobileRowRenderer = useCallback(
      (row: InvoiceDiscount) => <DiscountsTableListItem discount={row} onEdit={onEdit} onDelete={onDelete} />,
      [onDelete, onEdit]
    );

    const columns = useMemo<TableColumnDef<InvoiceDiscount>[]>(
      () => [
        {
          id: 'name',
          size: 'l',
          header: formatMessage('ar.settings.activities.discountsTable.components.discountsTable.columns.name.header'),
          cell: ({ row }) => <Table.Cell data-testid="discount-name">{row.name}</Table.Cell>,
        },
        {
          id: 'value',
          size: 's',
          header: formatMessage('ar.settings.activities.discountsTable.components.discountsTable.columns.value.header'),
          cell: ({ row }) => (
            <Table.Cell data-testid="discount-value">
              {row.type === 'percentage' ? formatPercents(row.value) : formatCurrency(row.value)}
            </Table.Cell>
          ),
        },
        {
          id: 'actions',
          cell: ({ row }) => <DiscountActionCell discount={row} onEdit={onEdit} onDelete={onDelete} />,
          size: 'xs',
        },
      ],
      [formatPercents, formatMessage, formatCurrency, onEdit, onDelete]
    );

    const table = useTable<InvoiceDiscount>({
      data: discounts,
      isLoading,
      columns,
      headerVariant: 'dark',

      mobileRowRenderer,
    });
    return <Table {...table} {...props} ref={ref} />;
  }
);
DiscountsTable.displayName = 'DiscountsTable';
