import { usePaymentSchedulingPreference } from '@melio/ap-domain';
import { Button, CalendarLegendProps, Container, Group, Table } from '@melio/penny';
import { DeliveryPreference, PaymentIntent } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useBoolean } from '@melio/platform-utils';
import { useState } from 'react';

import { DeliveryDateValue } from './components/DeliveryDateValue';

type DeliveryDateSelectCellProps = {
  paymentIntentId: PaymentIntent['id'];
  selectedDeliveryPreferenceType: PaymentIntent['selectedDeliveryPreferenceType'];
  effectiveDeliveryDate: PaymentIntent['effectiveDeliveryDate'];
  effectiveScheduledDate: PaymentIntent['effectiveScheduledDate'];
  earliestDeliveryDate?: DeliveryPreference['earliestDeliveryDate'];
  onDeliveryDateSelect: (
    paymentIntentId: PaymentIntent['id'],
    date: NonNullable<PaymentIntent['deliveryDate']>
  ) => void;
  isLate: boolean;
  isDisabled: boolean;
  isDeliveryMethodSelected: boolean;
};

export const DeliveryDateSelectCell = ({
  paymentIntentId,
  selectedDeliveryPreferenceType,
  effectiveDeliveryDate,
  earliestDeliveryDate,
  onDeliveryDateSelect,
  isLate,
  isDisabled,
  effectiveScheduledDate,
  isDeliveryMethodSelected,
}: DeliveryDateSelectCellProps) => {
  const { formatMessage } = useMelioIntl();
  const { maxDeliveryDate } = usePaymentSchedulingPreference();

  const [isCalendarOpen, calendar] = useBoolean(false);
  const [selectedDate, setSelectedDate] = useState(effectiveDeliveryDate);

  const legendItems: CalendarLegendProps[] = [
    {
      label: formatMessage(`activities.batchPayments.screens.paymentIntentsTable.deliveryDateCell.legend.today`),
      variant: 'today',
    },
    {
      label: formatMessage(`activities.batchPayments.screens.paymentIntentsTable.deliveryDateCell.legend.secondary`),
      variant: 'secondary',
    },
    {
      label: formatMessage(`activities.batchPayments.screens.paymentIntentsTable.deliveryDateCell.legend.primary`),
      variant: 'primary',
    },
  ];

  return (
    <Table.DateCell
      onOpenChange={(open) => {
        if (open) {
          calendar.on();
        }
      }}
      legendItems={legendItems}
      showToday
      excludeHolidays
      isOpen={isCalendarOpen}
      weekDays={[1, 2, 3, 4, 5]}
      minDate={earliestDeliveryDate}
      maxDate={maxDeliveryDate}
      onSelect={(date) => {
        setSelectedDate(date);
      }}
      placeholder={formatMessage('activities.batchPayments.screens.paymentIntentsTable.scheduledDateCell.placeholder')}
      data-testid={`${paymentIntentId}-delivery-date-cell`}
      isReadOnly={isDisabled}
      displayValue={
        <DeliveryDateValue
          paymentIntentId={paymentIntentId}
          deliveryDate={effectiveDeliveryDate}
          selectedDeliveryPreferenceType={selectedDeliveryPreferenceType}
          isLate={isLate}
          isDeliveryMethodSelected={isDeliveryMethodSelected}
        />
      }
      secondarySelectedDate={effectiveScheduledDate || undefined}
      selectedDate={selectedDate || undefined}
      footer={
        <Container paddingX="s" paddingY="s" data-testid="deliveryDate-calendar-footer">
          <Group variant="horizontal" justifyContent="space-between">
            <Button
              data-testid="batch-payments-deliveryDate-calendar-footer-close"
              variant="tertiary"
              size="small"
              label={formatMessage(
                'activities.batchPayments.screens.paymentIntentsTable.deliveryDateCell.footer.close'
              )}
              onClick={() => {
                calendar.off();
                setSelectedDate(effectiveDeliveryDate);
              }}
            />
            <Button
              data-testid="batch-payments-deliveryDate-calendar-footer-apply"
              variant="primary"
              size="small"
              label={formatMessage(
                'activities.batchPayments.screens.paymentIntentsTable.deliveryDateCell.footer.apply'
              )}
              onClick={() => {
                if (!selectedDate) {
                  return;
                }
                onDeliveryDateSelect(paymentIntentId, selectedDate);
              }}
            />
          </Group>
        </Container>
      }
    />
  );
};
