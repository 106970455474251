import { useCallback } from 'react';
import { isAccountingFirmOrganization } from '@melio/platform-api';
import {
  OrganizationExpanded,
  OrganizationFullyExpanded,
} from '@melio/platform-api/src/entities-v2/organizations/types';

import { AccountCard } from '@/cl/components/AccountCard/AccountCard.component';

type OrganizationInCard = OrganizationExpanded &
  Partial<Pick<OrganizationFullyExpanded, 'logoUrl' | 'billsAndPaymentsOverview'>>;

type OrganizationCardWidgetProps = {
  organization: OrganizationInCard;
  isSelected: boolean;
  isPaymentsOverviewLoading: boolean;
  onClick: (args: { organizationId: string; isAccountingFirm: boolean }) => void;
};

export const OrganizationCardWidget = ({
  organization,
  isSelected,
  isPaymentsOverviewLoading,
  onClick,
}: OrganizationCardWidgetProps) => {
  const onClickHandler = useCallback(() => {
    onClick({ organizationId: organization.id, isAccountingFirm: isAccountingFirmOrganization(organization) });
  }, [organization, onClick]);

  return (
    <AccountCard
      companyName={organization.name || ''}
      companyId={organization.id}
      companyLogo={organization.logoUrl}
      isSelected={isSelected}
      onClick={onClickHandler}
      isPaymentsOverviewLoading={isPaymentsOverviewLoading}
      paymentsOverview={organization.billsAndPaymentsOverview}
    />
  );
};
