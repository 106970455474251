import { Payment } from '@melio/javascript-sdk';
import { ApiKeys, GetPaymentsResponse } from '@melio/platform-api-axios-client';

// eslint-disable-next-line no-restricted-imports
import { usePaginatedCollectionApi, UsePaginatedCollectionOptions } from '../../core/usePaginatedCollectionApi';
import { PaymentsListParams } from './types';
import { convertPaymentsCentsToDollars, getPayments } from './utils';

export type UsePaginatedPaymentsProps = UsePaginatedCollectionOptions<
  Override<GetPaymentsResponse, { data: Payment[] }>
> & {
  params?: PaymentsListParams;
};

export const usePaginatedPayments = ({ params, ...props }: UsePaginatedPaymentsProps) =>
  usePaginatedCollectionApi(
    [ApiKeys.PaymentsApi, params],
    async (context) => {
      const response = await getPayments({ ...params, cursor: context.pageParam?.cursor ?? params?.cursor });
      const data = response?.data.data;
      const pagination = response?.data.pagination;

      return { data: convertPaymentsCentsToDollars(data), pagination };
    },
    props
  );

export type PaginatedPaymentCollection = ReturnType<typeof usePaginatedPayments>;
