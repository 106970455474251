/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Traits, useAnalytics } from '@melio/platform-analytics';
import { useDeliveryMethods, useUnilateralRequests, VirtualAccountDeliveryMethod } from '@melio/platform-api';

export const useUnilateralRequestDetails = ({ vendorId }: { vendorId: string }) => {
  const [enableSendRequest, setEnableSendRequest] = useState(false);

  const vendorDeliveryMethodsCollection = useDeliveryMethods({ vendorId });
  const unilateralRequestsCollection = useUnilateralRequests({ vendorId });

  const { setTraits } = useAnalytics();
  const setDeliveryMethodTraits = () => {
    const traits: Traits = { added_delivery_method: true };

    setTraits(traits);
  };

  useEffect(() => {
    unilateralRequestsCollection
      .getValidationForResend()
      .then(({ isValidForResend }) => setEnableSendRequest(isValidForResend));
  }, [unilateralRequestsCollection.data]);

  const sendUnilateralRequest = (originalDeliveryMethodId: string) =>
    unilateralRequestsCollection.create({ originalDeliveryMethodId }).then(() => setEnableSendRequest(false));

  const createVirtualAccountAndSendUnilateralRequest = ({ email }: VirtualAccountDeliveryMethod['details']) =>
    vendorDeliveryMethodsCollection.create({ type: 'virtual-account', details: { email } }).then(({ id }) => {
      setDeliveryMethodTraits();
      sendUnilateralRequest(id).then(() => setEnableSendRequest(false));
    });

  const vendorVirtualDeliveryMethod = vendorDeliveryMethodsCollection.data?.find(
    ({ type }) => type === 'virtual-account',
  );

  return {
    sendUnilateralRequest,
    createVirtualAccountAndSendUnilateralRequest,
    enableSendRequest,
    isSaving: vendorDeliveryMethodsCollection.isMutating || unilateralRequestsCollection.isMutating,
    vendorVirtualDeliveryMethod,
  };
};
