import { Container, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

export const EmptySubscriptionBillingHistory = () => {
  const { formatMessage } = useMelioIntl();

  return (
    <Container data-testid="empty-subscription-billing-history">
      <Text>{formatMessage('activities.settings.subscriptionBillingHistory.emptyMessage')}</Text>
    </Container>
  );
};
