import { ComponentThemeType } from '@/cl/theme/theme.hooks';

export const VendorCardTheme: ComponentThemeType = () => ({
  baseStyle: {
    checkbox: {
      paddingLeft: '8px',
      width: '40px',
      height: '40px',
    },
    rightSide: {
      w: '300px',
    },
  },
});
