import { Flex } from '@chakra-ui/react';
import { useMtlSchemaValidations } from '@melio/ap-domain';
import { FormWidgetProps } from '@melio/ap-widgets';
import { Button, Divider, Form, Loader, useFormSubmissionController, useMelioForm } from '@melio/penny';
import { useAnalytics, useTrackAnalyticsOnFailedFormSubmit } from '@melio/platform-analytics';
import { Collaborator, useApprovalWorkflowRoles } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useIsSubscriptionsEnabled } from '@melio/subscriptions';
import { defaults } from 'lodash';
import { useEffect, useState } from 'react';
import { object, string, StringSchema } from 'yup';

import { RoleUniqueNameEnum, RoleUniqueNames } from '../../../../types';
import { AddSeatToSubscriptionBanner } from '../../../components/AddSeatToSubscriptionBanner';
import { AdminApproval } from './fields/AdminApproval';
import { EmailInput } from './fields/EmailInput';
import { FirstNameInput } from './fields/FirstNameInput';
import { FormTitle } from './fields/FormTitle';
import { LastNameInput } from './fields/LastNameInput';
import { RoleRadioGroup } from './fields/RoleRadioGroup';

export type InvitationFormFields = {
  firstName: string;
  lastName: string;
  email: string;
  roleUniqueName: RoleUniqueNames;
  approvalAmountThreshold?: string;
};
type Props = Omit<FormWidgetProps<InvitationFormFields>, 'onSubmissionStateChange'> & {
  maxAllowedThreshold?: number;
  actor: Collaborator;
  availableRoleOptions: RoleUniqueNames[];
  showApprovalSection?: boolean;
};

const useSchema = ({
  isApprovalOn,
  maxAllowedThreshold = Number.POSITIVE_INFINITY,
  defaultRole,
}: Pick<Props, 'maxAllowedThreshold'> & { isApprovalOn: boolean; defaultRole?: RoleUniqueNames }) => {
  const { formatMessage } = useMelioIntl();
  const { name } = useMtlSchemaValidations();

  return object().shape({
    firstName: name('firstName'),
    lastName: name('lastName'),
    email: string()
      .email(formatMessage('activities.manageCollaborators.screens.userDetailsForm.fields.email.validation.invalid'))
      .required(
        formatMessage('activities.manageCollaborators.screens.userDetailsForm.fields.email.validation.required')
      ),
    roleUniqueName: new StringSchema<RoleUniqueNames>()
      .oneOf(Object.values(RoleUniqueNameEnum))
      .default(defaultRole)
      .required(),
    approvalAmountThreshold: isApprovalOn
      ? string()
          .required(
            formatMessage(
              'activities.manageCollaborators.screens.userDetailsForm.fields.approvalAmountThreshold.validation.required'
            )
          )
          .test({
            test: (value = '') => parseFloat(value) <= maxAllowedThreshold,
            message: formatMessage(
              'activities.manageCollaborators.screens.userDetailsForm.fields.approvalAmountThreshold.validation.amountTooHigh'
            ),
          })
      : string(),
  });
};

export const InvitationForm = ({
  onSubmit,
  isSaving,
  defaultValues,
  actor,
  maxAllowedThreshold,
  availableRoleOptions,
  showApprovalSection,
}: Props) => {
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const hasMaxAllowedThreshold = maxAllowedThreshold !== undefined;
  const [showApprovalAmount, setShowApprovalAmount] = useState(false);

  const isApprovalThresholdRequired = !actor.approvalActions.canApprovePayments && hasMaxAllowedThreshold;
  const { onSubmissionStateChange, submitButtonProps } = useFormSubmissionController<InvitationFormFields>();
  const { formProps, registerField, watch, resetField, formState } = useMelioForm<InvitationFormFields>({
    onSubmit,
    schema: useSchema({ isApprovalOn: showApprovalAmount, maxAllowedThreshold, defaultRole: availableRoleOptions[0] }),
    defaultValues: defaults(defaultValues, {
      firstName: '',
      lastName: '',
      email: '',
      roleUniqueName: availableRoleOptions[0],
      approvalAmountThreshold: '',
    }),
    isSaving,
    onSubmissionStateChange,
  });
  const { data, isLoading } = useApprovalWorkflowRoles({ enabled: !!showApprovalSection });

  useTrackAnalyticsOnFailedFormSubmit(formState, 'Setting', 'Status');

  useEffect(() => {
    track('Settings', 'View', { PageName: 'send-an-invite', Flow: 'user-roles', Intent: 'invite-user' });
  }, [track]);

  const roleUniqueNameIntent = watch('roleUniqueName');
  const renderApprovalSection = () => {
    if (isLoading) {
      return (
        <Form.ContentBox colSpan={2}>
          <Flex height="56px" justifyContent="center" alignItems="center">
            <Loader />
          </Flex>
        </Form.ContentBox>
      );
    }
    if (data?.paymentApprovalRoles.approvers.includes(roleUniqueNameIntent)) {
      return null;
    }
    return (
      <>
        <Form.ContentBox colSpan={2}>
          <Divider />
        </Form.ContentBox>
        <AdminApproval
          isSwitchOn={showApprovalAmount}
          isAdminApprovalRequired={isApprovalThresholdRequired}
          colSpan={2}
          amountInputProps={{ ...registerField('approvalAmountThreshold') }}
          switchProps={{
            onChange: (isChecked) => {
              setShowApprovalAmount(isChecked);
              resetField('approvalAmountThreshold');
            },
          }}
        />
      </>
    );
  };

  return (
    <>
      <FormTitle />
      <Form columns={2} {...formProps} isDisabled={formProps.isDisabled}>
        <FirstNameInput {...registerField('firstName')} aria-label="first name" />
        <LastNameInput {...registerField('lastName')} aria-label="last name" />
        <EmailInput colSpan={2} {...registerField('email')} aria-label="email" />
        {isSubscriptionsEnabled && (
          <Form.ContentBox colSpan={2}>
            <AddSeatToSubscriptionBanner />
          </Form.ContentBox>
        )}
        <RoleRadioGroup
          colSpan={2}
          title={formatMessage('activities.manageCollaborators.screens.userDetailsForm.fields.roleUniqueName.label')}
          availableRoleOptions={availableRoleOptions}
          selectedRoleUniqueName={roleUniqueNameIntent}
          isDisabled={isSaving}
          onChangeCapture={() => {
            setShowApprovalAmount(false);
            resetField('approvalAmountThreshold');
          }}
          {...registerField('roleUniqueName')}
        />
        {showApprovalSection ? renderApprovalSection() : null}
        <Form.ContentBox colSpan={2}>
          <Button
            variant="primary"
            data-testid="invitation-form-submit-button"
            isFullWidth
            label={formatMessage('activities.manageCollaborators.screens.invitationModal.submitBtn')}
            {...submitButtonProps}
            isDisabled={isLoading || isSaving}
          />
        </Form.ContentBox>
      </Form>
    </>
  );
};
