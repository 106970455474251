import { Group, Modal, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { ComponentProps } from 'react';

import { AccountingFirmInvitationForm } from './forms/AccountingFirmInvitationForm';

type Props = {
  isOpen: boolean;
  onDone: ComponentProps<typeof AccountingFirmInvitationForm>['onSubmit'];
  onClose: VoidFunction;
  isSaving?: boolean;
};

export const CreateAccountingFirmInvitationModalScreen = ({ isOpen, onDone, onClose, isSaving, ...props }: Props) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      data-testid="manage-collaborators-activity-create-external-firm-invitation-modal-screen"
      header={
        <Group spacing="m" variant="vertical" width="full">
          <Text as="h3" textStyle="heading2Semi">
            {formatMessage('activities.manageCollaborators.screens.accountingFirmInvitationModal.title')}
          </Text>
          <Text textStyle="heading3">
            {formatMessage('activities.manageCollaborators.screens.accountingFirmInvitationModal.description')}
          </Text>
        </Group>
      }
      {...props}
    >
      <AccountingFirmInvitationForm onSubmit={onDone} isSaving={isSaving} />
    </Modal>
  );
};
