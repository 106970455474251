import { apiClient, ReceiptInfo } from '@melio/javascript-sdk';

import { useCollectionApi, UseCollectionApiProps, UseCollectionApiResult } from '../../core';

export type FeeReceiptCollection = UseCollectionApiResult<ReceiptInfo> & {
  download: (id: ReceiptInfo['id']) => Promise<string>;
  get: (id: ReceiptInfo['id']) => Promise<string>;
};

export const useFeeReceipts = (props: UseCollectionApiProps<ReceiptInfo> = {}): FeeReceiptCollection => {
  const client = apiClient.feeReceipt();

  const get = (id: ReceiptInfo['id']) => client.getData(id);
  const download = (id: ReceiptInfo['id']) => client.downloadPdf(id);

  const query = useCollectionApi<ReceiptInfo>({
    ...props,
    queryKey: 'fee-receipt',
    queryFn: client.fetch,
  });

  return { ...query, get, download };
};
