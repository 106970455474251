import { CSSObject } from '@emotion/styled';

import { ComponentThemeType } from '@/cl/theme/theme.hooks';

const baseDotStyle: CSSObject = {
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  cursor: 'pointer',
};

export const SwiperWizardTheme: ComponentThemeType = (currentTheme) => ({
  baseStyle: {
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    dotContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: '8px',
      marginBottom: '48px',
    },
    dot: {
      ...baseDotStyle,
      backgroundColor: currentTheme.colors.global.neutral['400'],
    },
    activeDot: {
      ...baseDotStyle,
      backgroundColor: currentTheme.colors.global.brand['700'],
    },
    swiperBody: {
      height: '400px',
      overflowY: 'hidden',
    },
    footerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      gap: '16px',
    },
  },
});
