import { useMelioQueryClient, useNonEntityMutation } from '@melio/api-client';
// eslint-disable-next-line no-restricted-imports
import {
  AccountantsFirmClientApiClient,
  PatchFirmClientRequest,
  PatchFirmClientResponse,
} from '@melio/platform-api-axios-client';

export const updateFunction = ({ id, data }: { id: string; data: PatchFirmClientRequest }) =>
  AccountantsFirmClientApiClient.patchAccountantFirmClient(id, data);
type UpdateFunction = typeof updateFunction;

export const usePatchAccountantFirmClient = () => {
  const queryClient = useMelioQueryClient();
  const updateMutation = useNonEntityMutation<UpdateFunction, Parameters<UpdateFunction>[0], PatchFirmClientResponse>(
    updateFunction,
    ['AccountantsFirmClientApi', 'collection', 'default'],
    {
      onSuccess() {
        void queryClient.invalidateQueries('AccountantsFirmClientApi', 'collection');
        void queryClient.invalidateQueries('OrganizationsApi', 'collection');
      },
    }
  );

  return updateMutation;
};
