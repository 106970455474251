import { FormattedMessage, invoiceNumberPaddingLength, useMelioIntl } from '@melio/ar-domain';
import { Button, Container, Group, Text } from '@melio/penny';
import { padStart } from 'lodash';

export type InvoiceFormatProps = {
  prefix: string;
  invoiceNumber: number;
  onEditInvoiceFormat: VoidFunction;
};

export const InvoiceFormat = ({ prefix, invoiceNumber, onEditInvoiceFormat }: InvoiceFormatProps) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Group variant="vertical" spacing="m">
      <Group variant="vertical" spacing="xs">
        <Text textStyle="body1Semi" as="h3">
          <FormattedMessage id="ar.settings.flows.invoiceSettings.sections.invoiceFormat.title.text" />
        </Text>
        <Text textStyle="body3">
          <FormattedMessage id="ar.settings.flows.invoiceSettings.sections.invoiceFormat.subtitle.text" />
        </Text>
      </Group>
      <Group variant="vertical" spacing="m">
        <Group variant="vertical" spacing="none">
          <Text textStyle="body4Semi" color="global.neutral.800">
            <FormattedMessage id="ar.settings.flows.invoiceSettings.sections.invoiceFormat.invoice.label" />
          </Text>
          <Text data-testid="invoice-format-text">
            {prefix}
            {padStart(invoiceNumber.toString(), invoiceNumberPaddingLength, '0')}
          </Text>
        </Group>
        <Container>
          <Button
            data-testid="edit-invoice-format-button"
            onClick={onEditInvoiceFormat}
            variant="secondary"
            label={formatMessage('ar.settings.flows.invoiceSettings.sections.invoiceFormat.edit.button.label')}
          />
        </Container>
      </Group>
    </Group>
  );
};
