import { VendorDetailsFormFields } from '@/types/vendors.types';

export const getUpdateVendorPayload = (data: VendorDetailsFormFields) => {
  const { companyName, nickname, accountNumber } = data;

  return {
    name: companyName || '',
    nickname: nickname || '',
    accountNumber: accountNumber || '',
  };
};
