import { useState } from 'react';

import { Collaborator } from './types';

export const useMakeOwnerCollaboratorModal = () => {
  const [collaborator, setCollaborator] = useState<Collaborator | null>(null);

  const openModal = (newCollaborator: Collaborator) => {
    setCollaborator(newCollaborator);
  };

  const closeModal = () => {
    setCollaborator(null);
  };

  return {
    openModal,
    closeModal,
    isOpen: !!collaborator,
    collaborator,
  };
};
