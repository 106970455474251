/* eslint-disable react-hooks/exhaustive-deps */
import { usePaymentsLateApprovals, usePaymentsLateApprovalsEnabled } from '@melio/ap-domain';
import {
  getFundingSourceIconType,
  getFundingSourceImageProps,
  isCreditCardFundingSource,
  PaymentReviewLineItem,
  useGetFundingSourceCardHelperText,
  useGetFundingSourceLabel,
} from '@melio/ap-widgets';
import { Group } from '@melio/penny';
import { sanitizeStringForAnalytics, useAnalytics } from '@melio/platform-analytics';
import { PaymentFullyExpanded, PaymentStatusEnum } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import React, { RefObject } from 'react';

import { MicroDepositModalRefHandlers } from '../../../../funding-sources';
import { LoanSection } from './loan-section/LoanSection';
import { SectionBlock } from './SectionBlock';

export const PayFromSection = ({
  payment,
  microDepositModalRef,
}: {
  payment: PaymentFullyExpanded;
  microDepositModalRef?: RefObject<MicroDepositModalRefHandlers>;
}) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const [isFinancingEnabled] = useFeature<boolean>(FeatureFlags.PlatformFinancing, false);
  const { track } = useAnalytics();

  const getPaymentsLateApprovalsEnabled = usePaymentsLateApprovalsEnabled();
  const { mainLabelTooltipProps } = usePaymentLateApprovalTooltip(payment);

  const paymentsLateApprovalsEnabled = getPaymentsLateApprovalsEnabled({ payments: [payment] });
  const paymentLateApprovalEnabled = !!paymentsLateApprovalsEnabled[payment.id]?.isEnabled;
  const { fundingSource, loan, scheduledDate, status: paymentStatus } = payment;
  const { type: fundingSourceType, displayName, nickname } = fundingSource;

  const helperText = useGetFundingSourceCardHelperText({
    fundingSource,
    onClick: () => {
      track('PaymentMethod', 'Click', {
        PageName: 'payment-summary',
        Flow: 'dashboard',
        Intent: 'verify-payment-method',
        PaymentMethodId: payment.fundingSourceId,
        PaymentMethodType: fundingSource?.type,
        Cta: sanitizeStringForAnalytics(
          formatMessage('widgets.paymentMethods.paymentMethodLineItem.bankAccount.unverified.link')
        ),
        EntryPoint: 'payment-summary',
      });
      microDepositModalRef?.current?.openModal();
    },
  });

  const fundingSourceImage = getFundingSourceImageProps(fundingSource);
  const fundingSourceIconType = getFundingSourceIconType(fundingSource);
  const fundingSourceLabel = useGetFundingSourceLabel(fundingSource);

  return (
    <Group data-testid="pay-from-section">
      {isFinancingEnabled && loan ? (
        <LoanSection payment={payment} />
      ) : (
        <Group variant="vertical" spacing="s">
          <SectionBlock.Title label={formatMessage('widgets.paymentDetails.payFrom.title')} />
          <PaymentReviewLineItem
            labelProps={{ label: fundingSourceLabel }}
            mainLabelProps={{ label: nickname || displayName }}
            contentTestId="pay-from-section-bank-details"
            {...(fundingSourceType === 'bank-account' && helperText ? { descriptionProps: { label: helperText } } : {})}
            {...(fundingSourceImage ? { imageIcon: fundingSourceImage } : { icon: { type: fundingSourceIconType } })}
          />
          <PaymentReviewLineItem
            labelProps={{
              label: formatMessage(
                paymentStatus === PaymentStatusEnum.Completed
                  ? 'widgets.paymentDetails.payFrom.completedPaymentDate'
                  : `widgets.paymentDetails.payFrom.scheduledDate.label.${
                      isCreditCardFundingSource(fundingSource) ? 'creditCardFundingType' : 'default'
                    }`
              ),
            }}
            mainLabelProps={{
              label: formatDate(scheduledDate, { dateStyle: 'medium' }),
              tooltipProps: paymentLateApprovalEnabled ? mainLabelTooltipProps : undefined,
            }}
            contentTestId="pay-from-section-deducted-from"
            icon={{ type: 'scheduled' }}
          />
        </Group>
      )}
    </Group>
  );
};

const usePaymentLateApprovalTooltip = (payment: PaymentFullyExpanded) => {
  const { formatMessage } = useMelioIntl();
  const getPaymentsLateApprovals = usePaymentsLateApprovals();

  const paymentsLateApprovals = getPaymentsLateApprovals({ payments: [payment] });
  const paymentLateApproval = paymentsLateApprovals[payment.id];

  const getMainLabelTooltipProps = (): {
    label: string;
    title?: string;
    triggerAriaLabel?: string;
    triggerStatus?: 'warning' | 'informative';
  } => {
    const isDebitDateAfterToday = !!paymentLateApproval?.isDebitDateAfterToday;

    if (payment.fundingSource?.type === 'bank-account') {
      return isDebitDateAfterToday
        ? {
            title: formatMessage('widgets.paymentDetails.payFrom.lateDebitDate.bank-account.title'),
            label: formatMessage('widgets.paymentDetails.payFrom.lateDebitDate.bank-account.description'),
            triggerStatus: 'warning',
          }
        : {
            title: formatMessage('widgets.paymentDetails.payFrom.debitDateToday.bank-account.title'),
            label: formatMessage('widgets.paymentDetails.payFrom.debitDateToday.bank-account.description'),
            triggerStatus: 'informative',
          };
    } else if (payment.fundingSource?.type === 'card') {
      return isDebitDateAfterToday
        ? {
            title: formatMessage('widgets.paymentDetails.payFrom.lateDebitDate.card.title'),
            label: formatMessage('widgets.paymentDetails.payFrom.lateDebitDate.card.description'),
            triggerStatus: 'warning',
          }
        : {
            title: formatMessage('widgets.paymentDetails.payFrom.debitDateToday.card.title'),
            label: formatMessage('widgets.paymentDetails.payFrom.debitDateToday.card.description'),
            triggerStatus: 'informative',
          };
    }
    return isDebitDateAfterToday
      ? {
          title: formatMessage('widgets.paymentDetails.payFrom.lateDebitDate.default.title'),
          label: formatMessage('widgets.paymentDetails.payFrom.lateDebitDate.default.description'),
          triggerStatus: 'warning',
        }
      : {
          title: formatMessage('widgets.paymentDetails.payFrom.debitDateToday.default.title'),
          label: formatMessage('widgets.paymentDetails.payFrom.debitDateToday.default.description'),
          triggerStatus: 'informative',
        };
  };

  return { mainLabelTooltipProps: getMainLabelTooltipProps() };
};
