import { ComponentThemeType } from '@/cl/theme/theme.hooks';

export const TagTheme: ComponentThemeType = () => ({
  baseStyle: {
    container: {
      display: 'flex',
      flexDirection: 'row',
      gap: '8px',
      alignItems: 'center',
    },
  },
});
