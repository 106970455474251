import { BankAccountFormModel } from '@melio/ap-widgets';
import {
  Container,
  Form,
  Group,
  Modal,
  NakedButton,
  SectionBanner,
  Text,
  useBreakpoint,
  UseMelioFormResults,
} from '@melio/penny';
import { Vendor } from '@melio/platform-api';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import { ComponentProps } from 'react';

import { MandatoryFieldDisclaimer } from '../../MandatoryFieldDisclaimer';
import { AriaLive } from '../../util/AriaLive';

type Props = Pick<ComponentProps<typeof Modal>, 'onClose' | 'isOpen'> &
  UseMelioFormResults<BankAccountFormModel> & {
    isSaving: boolean;
    isError: boolean;
  } & {
    editing: boolean;
    routingNumber?: string;
    unmaskedAccountNumber?: string;
    vendor?: Vendor;
    onEdit: VoidFunction;
  };

export function BankAccountFormModal({
  isOpen,
  onClose,
  isError,
  editing,
  routingNumber,
  unmaskedAccountNumber,
  onEdit,
  vendor,
  isSaving,
  ...form
}: Props) {
  const { formatMessage } = useMelioIntl();
  const { isExtraSmallScreen } = useBreakpoint();

  const headerKey: MessageKey = 'activities.paymentFlow.form.content.deliveryMethod.bankFormModal.title';

  const primaryButtonLabelKey: MessageKey = editing
    ? 'activities.paymentFlow.form.content.deliveryMethod.bankFormModal.actions.save'
    : 'activities.paymentFlow.form.content.deliveryMethod.bankFormModal.actions.confirm';

  const modalContent = editing ? (
    <>
      <Group variant="vertical" spacing="xs">
        <MandatoryFieldDisclaimer />
        <Group variant={isExtraSmallScreen ? 'vertical' : 'horizontal'} width="full" spacing="m">
          <Group.Item grow={1} basis={2}>
            <Form.TextField
              {...form.registerField('routingNumber')}
              labelProps={{
                label: formatMessage(
                  'activities.paymentFlow.form.content.deliveryMethod.bankFormModal.fields.routingNumber.label'
                ),
              }}
              maskProps={{
                mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
              }}
            />
          </Group.Item>
          <Group.Item grow={1} basis={2}>
            <Form.TextField
              {...form.registerField('accountNumber')}
              labelProps={{
                label: formatMessage(
                  'activities.paymentFlow.form.content.deliveryMethod.bankFormModal.fields.accountNumber.label'
                ),
              }}
            />
          </Group.Item>
        </Group>
      </Group>
    </>
  ) : unmaskedAccountNumber && routingNumber && vendor ? (
    <BankAccountCard unmaskedAccountNumber={unmaskedAccountNumber} routingNumber={routingNumber} onEdit={onEdit} />
  ) : null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={formatMessage(headerKey)}
      primaryButton={{
        ...form.submitButtonProps,
        isLoading: isSaving,
        label: formatMessage(primaryButtonLabelKey),
        variant: 'primary',
      }}
      secondaryButton={{
        isDisabled: isSaving,
        label: formatMessage('activities.paymentFlow.form.content.deliveryMethod.bankFormModal.actions.cancel'),
        onClick: onClose,
        variant: 'tertiary',
      }}
    >
      <Form {...form.formProps} data-testid="bank-account-form">
        <Group variant="vertical" spacing="m">
          <Text textStyle="body2">
            {editing
              ? formatMessage('activities.paymentFlow.form.content.deliveryMethod.bankFormModal.disclaimer')
              : formatMessage('activities.paymentFlow.form.content.deliveryMethod.bankFormModal.confirm-disclaimer', {
                  vendorName: vendor?.name,
                })}
          </Text>
          <AriaLive shown={isError}>
            <SectionBanner
              data-testid="bank-account-form-modal-error-section"
              size="large"
              variant="critical"
              title={formatMessage('activities.paymentFlow.form.content.deliveryMethod.bankFormModal.error.title')}
              description={formatMessage(
                'activities.paymentFlow.form.content.deliveryMethod.bankFormModal.error.description'
              )}
            />
          </AriaLive>
          {modalContent}
        </Group>
      </Form>
    </Modal>
  );
}

function BankAccountCard({
  routingNumber,
  unmaskedAccountNumber,
  onEdit,
}: {
  routingNumber: string;
  unmaskedAccountNumber: string;
  onEdit: VoidFunction;
}) {
  const { formatMessage } = useMelioIntl();

  return (
    <Container paddingX="s" paddingY="s" border="regular">
      <Group justifyContent="space-between" alignItems="flex-start">
        <Group variant="vertical">
          <Group variant="vertical" spacing="xxxs">
            <Text as="span" textStyle="body2Semi">
              {formatMessage(
                'activities.paymentFlow.form.content.deliveryMethod.bankFormModal.fields.routingNumber.label.short'
              )}
            </Text>
            <Text as="span">{routingNumber}</Text>
          </Group>

          <Group variant="vertical" spacing="xxxs">
            <Text as="span" textStyle="body2Semi">
              {formatMessage(
                'activities.paymentFlow.form.content.deliveryMethod.bankFormModal.fields.accountNumber.label.short'
              )}
            </Text>
            <Text as="span">{unmaskedAccountNumber}</Text>
          </Group>
        </Group>

        <NakedButton
          data-testid="edit-button"
          variant="secondary"
          onClick={onEdit}
          label={formatMessage('activities.paymentFlow.form.content.deliveryMethod.bankFormModal.actions.edit')}
        />
      </Group>
    </Container>
  );
}
