import { useAnalyticsContext, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { useInternationalCountriesDetails, useVendor } from '@melio/platform-api';

import { InternationalBankDetailsFormFields, InternationalBankDetailsScreen } from './screens';

type InternationalBankDetailsActivityProps = {
  onClose: VoidFunction;
  onBack: VoidFunction;
  onDone: (data: InternationalBankDetailsFormFields) => void;
  vendorId: string;
  defaultValues?: Partial<InternationalBankDetailsFormFields>;
  shouldShowFailedCreationBanner: boolean;
};

export const InternationalBankDetailsActivity = withAnalyticsContext(
  ({
    onBack,
    onClose,
    onDone,
    vendorId,
    defaultValues,
    shouldShowFailedCreationBanner,
  }: InternationalBankDetailsActivityProps) => {
    const { isLoading: isVendorLoading, data: vendor } = useVendor({ id: vendorId });
    const { isLoading: isCountriesDetailsLoading, data: countriesDetails } = useInternationalCountriesDetails();

    const isLoading = isVendorLoading || isCountriesDetailsLoading;

    useAnalyticsContext({
      globalProperties: {
        PageName: 'what-are-vendor-international-payment-details',
        Intent: 'add-delivery-method-details',
      },
    });

    useAnalyticsView('DeliveryMethod', !isLoading, true);

    return (
      <InternationalBankDetailsScreen
        onBack={onBack}
        onClose={onClose}
        onDone={onDone}
        isLoading={isLoading}
        vendorName={vendor?.name || ''}
        countriesDetails={countriesDetails || []}
        defaultValues={defaultValues}
        shouldShowFailedCreationBanner={shouldShowFailedCreationBanner}
      />
    );
  }
);

InternationalBankDetailsActivity.displayName = 'InternationalBankDetailsActivity';
