import { useBills, Vendor } from '@melio/platform-api';
import { useMemo } from 'react';

import { VendorTabAmountCellAmounts } from './types';

type Props = {
  selectedVendorIds: Vendor['id'][];
  vendorsAmountsMap: VendorTabAmountCellAmounts;
  enabled?: boolean;
};

export const useVendorsBatchActionsData = ({ selectedVendorIds, vendorsAmountsMap, enabled }: Props) => {
  const { data: unpaidBills = [], isLoading: isLoadingBills } = useBills({
    cacheTime: 0,
    enabled: enabled ? enabled : selectedVendorIds.length > 0,
    params: { search: { 'vendor.id': selectedVendorIds, 'bill.balance': { $gt: 0 } }, expand: 'none' },
  });

  const selectedVendorsIdsWithEmptyAmounts = useMemo(
    () =>
      selectedVendorIds.filter(
        (vendorId) => !vendorsAmountsMap[vendorId] && !unpaidBills.some((bill) => bill.vendorId === vendorId)
      ),
    [selectedVendorIds, unpaidBills, vendorsAmountsMap]
  );

  const selectedVendorIdsWithAmounts = selectedVendorIds.filter((vendorId) => !!vendorsAmountsMap[vendorId]);

  const selectedVendorsAmountsMap = selectedVendorIdsWithAmounts.reduce((acc, vendorId) => {
    if (vendorsAmountsMap[vendorId]) {
      Object.assign(acc, { [vendorId]: vendorsAmountsMap[vendorId] });
    }
    return acc;
  }, {} as VendorTabAmountCellAmounts);

  return {
    selectedUnpaidBills: unpaidBills,
    isLoadingBills,
    selectedVendorsIdsWithEmptyAmounts,
    selectedVendorIdsWithAmounts,
    selectedVendorsAmountsMap,
  };
};
