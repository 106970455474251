import { Link } from '@melio/penny';
import { FinancingEligibilityStatus } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export const useFinancingTabEmptyStateContent = ({
  limit,
  isAppliedToProvider,
  onAddNewPayment,
  onFinancingApplicationApply,
  eligibilityStatus,
}: {
  limit: number;
  isAppliedToProvider: boolean;
  onAddNewPayment: VoidFunction;
  onFinancingApplicationApply: VoidFunction;
  eligibilityStatus: FinancingEligibilityStatus | undefined;
}): {
  title: string;
  description: string;
  ariaLabel: string;
  footer: string | null;
  primaryButtonLabel: string | null;
  onPrimaryButtonClick: VoidFunction | null;
} => {
  const { formatMessage, formatCurrency } = useMelioIntl();
  const footer = formatMessage('activities.payDashboard.financingTab.emptyState.footer');

  if (eligibilityStatus === FinancingEligibilityStatus.Pending) {
    return {
      title: formatMessage('activities.payDashboard.financingTab.emptyState.pending.title'),
      description: formatMessage('activities.payDashboard.financingTab.emptyState.pending.description', {
        link: (
          <Link
            href="https://melio.zendesk.com/hc/en-us/articles/6457841438108-Pay-over-time-with-installments-in-Melio?utm_source=kb&utm_medium=article&utm_campaign=installments"
            label={formatMessage('activities.payDashboard.financingTab.emptyState.description.link')}
            newTab
          />
        ),
      }),
      ariaLabel: formatMessage('activities.payDashboard.financingTab.emptyState.pending.ariaLabel'),
      primaryButtonLabel: null,
      footer: null,
      onPrimaryButtonClick: null,
    };
  }

  if (!isAppliedToProvider) {
    return {
      title: formatMessage('activities.payDashboard.financingTab.emptyState.applicationFlow.title'),
      description: formatMessage('activities.payDashboard.financingTab.emptyState.applicationFlow.description', {
        link: (
          <Link
            href="https://melio.zendesk.com/hc/en-us/articles/6457841438108-Pay-over-time-with-installments-in-Melio?utm_source=kb&utm_medium=article&utm_campaign=installments"
            label={formatMessage('activities.payDashboard.financingTab.emptyState.description.link')}
            newTab
          />
        ),
      }),
      ariaLabel: formatMessage('activities.payDashboard.financingTab.emptyState.ariaLabel'),
      footer,
      primaryButtonLabel: formatMessage(
        'activities.payDashboard.financingTab.emptyState.applicationFlow.primaryButton'
      ),
      onPrimaryButtonClick: onFinancingApplicationApply,
    };
  }

  return {
    title: formatMessage('activities.payDashboard.financingTab.emptyState.title', { limit: formatCurrency(limit) }),
    ariaLabel: formatMessage('activities.payDashboard.financingTab.emptyState.ariaLabel'),
    description: formatMessage('activities.payDashboard.financingTab.emptyState.description', {
      link: (
        <Link
          href="https://melio.zendesk.com/hc/en-us/articles/6457841438108-Pay-over-time-with-installments-in-Melio"
          label={formatMessage('activities.payDashboard.financingTab.emptyState.description.link')}
          newTab
        />
      ),
    }),
    footer,
    primaryButtonLabel: formatMessage('activities.payDashboard.financingTab.emptyState.primaryButton'),
    onPrimaryButtonClick: onAddNewPayment,
  };
};
