import { Container, Group, Layout, Text, useToast } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { Logger } from '@melio/platform-logger';

import { useRequestAccountAccess } from '../../api/entities/client/useRequestAccountAccess';
import { FlowHeader } from '../../components/FlowHeader/FlowHeader.component';
import { useAccountantsRoutes } from '../../utils/useAccountantsRoutes';
import { InviteFirmClientForm } from './InviteFirmClientForm';
import { InviteFirmClientFormFields } from './types';

export const InviteFirmClient = ({ onClose }: { onClose: VoidFunction }) => {
  const { goToInvitationSent } = useAccountantsRoutes();
  const { formatMessage } = useMelioIntl();
  const { toast } = useToast();
  const { mutateAsync: requestAccountAccess, isLoading: isLoadingRequestAccountAccess } = useRequestAccountAccess({
    onSuccess: goToInvitationSent,
    onError(error) {
      toast({
        type: 'error',
        title: formatMessage('activities.accountants.activities.existingClientAccount.requestAccess.error'),
      });
      Logger.log(`Error while trying to send a join organization request. ${error.message}`);
    },
  });

  const handleSubmit = async (data: InviteFirmClientFormFields) => {
    const {
      clientEmailAddress,
      clientFirstName,
      clientLastName,
      companyName,
      // includeMyEmailInCC
    } = data;

    await requestAccountAccess({
      user: {
        email: clientEmailAddress,
        firstName: clientFirstName,
        lastName: clientLastName,
      },
      company: {
        name: companyName,
      },
      // TODO: open when api is ready
      // includeMyEmailInCC
    });
  };

  return (
    <Layout variant="full">
      <Container justifyContent="center" height="full">
        <Group spacing="m" variant="vertical" width={{ xs: '100%', s: '800px' } as never}>
          <FlowHeader
            title={formatMessage('activities.accountants.inviteClient.form.header.text')}
            leaveConfirmationModal={{
              title: formatMessage('activities.accountants.inviteClient.leaveConfirmation.title'),
              description: formatMessage('activities.accountants.inviteClient.leaveConfirmation.description'),
              cancelButtonText: formatMessage(
                'activities.accountants.inviteClient.leaveConfirmation.cancelButton.text'
              ),
            }}
            onClose={onClose}
          />
          <Text textStyle="heading3Semi">
            {formatMessage('activities.accountants.inviteClient.form.subheader.text')}
          </Text>
          <Text textStyle="body2">{formatMessage('activities.accountants.inviteClient.form.description.text')}</Text>
          <InviteFirmClientForm onSubmit={handleSubmit} isSaving={isLoadingRequestAccountAccess} onClose={onClose} />
        </Group>
      </Container>
    </Layout>
  );
};
