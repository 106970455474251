import { useModelApi } from '@melio/api-client';
import {
  FinancingApiClient,
  MelioPromise,
  SelectedRepaymentOption,
  SelectRepaymentTermRequest,
} from '@melio/platform-api-axios-client';

import {
  convertSelectedRepaymentTermResponseCentsToDollars,
  prepareSelectRepaymentOptionParams,
  SelectRepaymentTermParams,
} from './utils';

const selectRepaymentOptionFn = (_id: string, params: SelectRepaymentTermRequest) =>
  FinancingApiClient.selectRepaymentTerm(params);

export const useSelectFinancingRepaymentTerm = (params: { eligibilityToken: string }) => {
  const query = useModelApi({
    id: params.eligibilityToken,
    queryKey: ['FinancingApi', params.eligibilityToken],
    queryFn: async () => Promise.resolve({ data: {} }) as MelioPromise<SelectedRepaymentOption>,
    updateFn: selectRepaymentOptionFn,
    prepareUpdateParams: prepareSelectRepaymentOptionParams,
    select: convertSelectedRepaymentTermResponseCentsToDollars,
    enabled: false,
  });

  return {
    ...query,
    selectRepaymentTerm: (params: SelectRepaymentTermParams) =>
      query.update(params).then((res) => convertSelectedRepaymentTermResponseCentsToDollars(res)),
  };
};
