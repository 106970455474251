import { CompleteLegalInfoFormWidgetFields } from '@melio/ap-widgets';
import { Account, PatchAccountsAccountIdRequest } from '@melio/platform-api';

type Company = NonNullable<PatchAccountsAccountIdRequest['company']>;

export const getDefaultValuesFromAccount = (account?: Account): Partial<CompleteLegalInfoFormWidgetFields> => {
  type LegalAddress = Company['legalAddress'];

  if (!account) {
    return {};
  }

  const legalAddress: Partial<LegalAddress> | undefined = account.company.legalAddress && {
    city: account.company.legalAddress.city || undefined,
    line1: account.company.legalAddress.line1 || undefined,
    line2: account.company.legalAddress.line2 || undefined,
    postalCode: account.company.legalAddress.postalCode || undefined,
    state: account.company.legalAddress.state || undefined,
  };

  return {
    businessType: account.company.businessType || undefined,
    taxInfoType: account.company.taxInfo?.type || undefined,
    taxInfoIdentifier: account.company.taxInfo?.identifier,
    legalAddress: legalAddress as LegalAddress,
    legalCompanyName: account.company.legalName || undefined,
    phoneNumber: account.company.phoneNumber || undefined,
    industry: account.company.industry,
  };
};
