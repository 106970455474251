import { ModelError } from '@melio/platform-api';
import { MessageKey } from '@melio/platform-i18n';

export const getSubmitErrorMessageKey = (error: ModelError): MessageKey => {
  const submitErrorDefaultMessageKey = 'activities.vendor.autoPaymentActivationFlow.screens.error.general';
  const errorCode = error.errorCode;

  const unsupportedCardCodes = ['card_type_not_allowed_for_the_payee', 'card_not_supported', 'card_not_allowed'];
  const unauthorizedCodes = ['user_not_authorized'];

  if (!errorCode) {
    return submitErrorDefaultMessageKey;
  }

  if (unsupportedCardCodes.includes(errorCode)) {
    return 'activities.vendor.autoPaymentActivationFlow.screens.error.unsupportedCard';
  } else if (unauthorizedCodes.includes(errorCode)) {
    return 'activities.vendor.autoPaymentActivationFlow.screens.error.unauthorized';
  }

  return submitErrorDefaultMessageKey;
};
