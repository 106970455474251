import { FileAttachment, Group, Icon, Link, Loader, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export const EBillAttachment = ({
  isLoading,
  url,
  showFileAttachment = true,
  showViewIcon = false,
}: {
  isLoading: boolean;
  url: string;
  showFileAttachment?: boolean;
  showViewIcon?: boolean;
}) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Group variant="vertical" justifyContent="center" alignItems="center">
      {showFileAttachment ? <FileAttachment isReadOnly /> : null}
      {isLoading ? (
        <Group spacing="xs">
          <Text color="global.neutral.600" textStyle="body3">
            {formatMessage('widgets.billDetails.eBillAttachment.link')}
          </Text>
          <Loader />
        </Group>
      ) : null}
      {url ? (
        <Group spacing="xxs" alignItems="center">
          {showViewIcon ? <Icon type="show" size="small" color="brand" /> : null}
          <Link
            data-testid="ebill-attachment-link"
            href={url}
            newTab
            label={formatMessage('widgets.billDetails.eBillAttachment.link')}
            size="medium"
            variant="standalone"
          />
        </Group>
      ) : null}
    </Group>
  );
};
