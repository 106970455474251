import { FundingSource } from '@melio/platform-api';

import { getFundingSourceIconOrImage } from '../../../hooks';

type GetSelectedFundingSourceIdProps = {
  subscriptionFundingSourceId?: string;
  fundingSources: FundingSource[];
};
export const getSelectedFundingSourceId = ({
  subscriptionFundingSourceId,
  fundingSources,
}: GetSelectedFundingSourceIdProps) => {
  const subscriptionSelectedFundingSource = fundingSources?.find(
    (fundingSource) => fundingSource.id === subscriptionFundingSourceId
  );

  return subscriptionSelectedFundingSource || fundingSources?.[0];
};

export const getFundingSourcesOptions = (fundingSources: FundingSource[]) =>
  fundingSources.map((fs) => ({
    value: fs.id,
    label: fs.displayName,
    description: fs.nickname,
  }));

export const generateFundingSourcesIcons = (fundingSources: FundingSource[]) =>
  fundingSources.map((fs) => ({
    id: fs.id,
    icon: getFundingSourceIconOrImage(fs),
  }));
