import { BillSubscriptionIntervalTypeEnum } from '@melio/platform-api';

export const RECURRING_SINGLE_PAYMENT = 'One-time payment';

export const RECURRING_NUM_OF_OCCURRENCES_REGEX = [/[1-9]/, /\d/, /\d/, /\d/];

export const RECURRING_MAX_ALLOWED_OCCURRENCES: { [key in BillSubscriptionIntervalTypeEnum]: number } = {
  [BillSubscriptionIntervalTypeEnum.Weekly]: 104,
  [BillSubscriptionIntervalTypeEnum.Monthly]: 24,
  [BillSubscriptionIntervalTypeEnum.Yearly]: -1, // Not supported
  [BillSubscriptionIntervalTypeEnum.Every2Months]: -1, // Not supported
  [BillSubscriptionIntervalTypeEnum.Every3Months]: -1, // Not supported
  [BillSubscriptionIntervalTypeEnum.Every4Months]: -1, // Not supported
  [BillSubscriptionIntervalTypeEnum.Every6Months]: -1, // Not supported
  [BillSubscriptionIntervalTypeEnum.TwiceAMonth]: -1, // Not supported
  [BillSubscriptionIntervalTypeEnum.Every2Weeks]: -1, // Not supported
  [BillSubscriptionIntervalTypeEnum.Every4Weeks]: -1, // Not supported
};

export const INTERVALS_MAP: { [key in BillSubscriptionIntervalTypeEnum]?: string } = {
  [BillSubscriptionIntervalTypeEnum.Weekly]: 'weeks',
  [BillSubscriptionIntervalTypeEnum.Monthly]: 'months',
  [BillSubscriptionIntervalTypeEnum.Yearly]: 'years',
};
