import { useMelioIntl } from '@melio/ar-domain';
import { Button, Container, Form } from '@melio/penny';
import { ComponentProps, useState } from 'react';

import { useEstimatedDeliveryDate } from './hooks';

type ScheduledDateFieldProps = ComponentProps<typeof Form.DateField> & {
  scheduledDate: Date;
  minScheduledDate: Date;
  setScheduledDate: (date: Date) => void;
  fundingSourceType: 'card' | 'bank-account';
};

export const ScheduledDateField = ({
  scheduledDate,
  minScheduledDate,
  setScheduledDate,
  fundingSourceType,
  ...props
}: ScheduledDateFieldProps) => {
  const { formatMessage } = useMelioIntl();
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>();
  const [selectedDate, setSelectedDate] = useState<Date>(scheduledDate);

  const CalendarFooter = (
    <Container justifyContent="space-between" paddingX="s" paddingY="s">
      <Button
        label={formatMessage('ar.guestPayment.activities.scheduledDate.footer.close.label')}
        variant="tertiary"
        onClick={() => setIsCalendarOpen(false)}
      />
      <Button
        label={formatMessage('ar.guestPayment.activities.scheduledDate.footer.apply.label')}
        onClick={() => {
          setScheduledDate(selectedDate);
          setIsCalendarOpen(false);
        }}
      />
    </Container>
  );

  return (
    <Form.DateField
      {...props}
      hideClear
      data-testid="guest-payment-scheduled-date-field"
      value={scheduledDate}
      isOpen={isCalendarOpen}
      minDate={minScheduledDate}
      selectedDate={selectedDate}
      secondarySelectedDate={useEstimatedDeliveryDate(selectedDate, fundingSourceType)}
      onSelectDate={(selectedDate: Date | null | undefined) => {
        if (selectedDate) setSelectedDate(selectedDate);
      }}
      onOpenChange={(isOpen: boolean) => {
        if (isOpen) {
          setSelectedDate(scheduledDate);
          setIsCalendarOpen(true);
        }
      }}
      labelProps={{ label: formatMessage('ar.guestPayment.activities.scheduledDate.label') }}
      legendItems={[
        {
          label: formatMessage('ar.guestPayment.activities.scheduledDate.calendar.legend.today'),
          variant: 'today',
        },
        {
          label: formatMessage('ar.guestPayment.activities.scheduledDate.calendar.legend.debit'),
          variant: 'primary',
        },
        {
          label: formatMessage('ar.guestPayment.activities.scheduledDate.calendar.legend.delivery'),
          variant: 'secondary',
        },
      ]}
      footer={CalendarFooter}
    />
  );
};

ScheduledDateField.displayName = 'ScheduledDateField';
