import { Form, Group, OnSubmissionStateChange, useMelioForm } from '@melio/penny';
import { PermissionLevelEnum } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';
import { SystemMessageDisplay } from '@melio/platform-utils/system-message';
import { pick } from 'lodash';
import type { DefaultValues } from 'react-hook-form';
import { SchemaOf } from 'yup';

import { FormContext } from '../../FormContext';
import { FormFields, FormFieldsSettings } from '../../types';
import { DetailsSection, PaymentApprovalLimitSection, PermissionLevelSection, RolesSection } from './sections';
import { EntitlementSection } from './sections/EntitlementSection';

type Props = {
  formFieldsSettings: FormFieldsSettings;
  defaultValues: DefaultValues<FormFields>;
  onSubmissionStateChange: OnSubmissionStateChange<FormFields>;
  onSubmit: (data: FormFields) => void;
  onChange?: VoidFunction;
  isSaving: boolean;
  isLoading: boolean;
  schema?: SchemaOf<FormFields>;
};
export const CollaboratorDrawerBody = (props: Props) => {
  const { settings } = useConfig();
  const form = useMelioForm<FormFields>({
    onSubmit: props.onSubmit,
    schema: props.schema,
    mode: 'onSubmit',
    isSaving: props.isSaving,
    isLoading: props.isLoading,
    defaultValues: props.defaultValues,
    onSubmissionStateChange: props.onSubmissionStateChange,
    subscribeToDefaultValuesChanges: true,
  });

  const hasFullPermissionLevel = form.watch('permissionLevel') === PermissionLevelEnum.Full;

  const { hidden: isPermissionLevelHidden } = props.formFieldsSettings.permissionLevel;
  const { hidden: isEntitlementsFundingSourceIdsHidden } = props.formFieldsSettings.entitlementsFundingSourceIds;
  const { hidden: isEntitlementsFundingSourceTypeHidden } = props.formFieldsSettings.entitlementsFundingSourceType;
  const { hidden: isPaymentApprovalLimitHidden } = props.formFieldsSettings.paymentApprovalLimit;

  return (
    <Group variant="vertical" spacing="s">
      <SystemMessageDisplay data-testid="collaborator-drawer-notification" />
      <FormContext.Provider value={form}>
        <Form {...form.formProps} data-testid="collaborator-drawer-body-form" onChange={props.onChange}>
          <Group variant="vertical" spacing="m" hasDivider>
            <Group.Item>
              <DetailsSection formFieldsSettings={pick(props.formFieldsSettings, ['firstName', 'lastName', 'email'])} />
            </Group.Item>

            {settings.collaborator.isChangeCollaboratorPermissionLevelEnabled && !isPermissionLevelHidden ? (
              <Group.Item>
                <PermissionLevelSection />
              </Group.Item>
            ) : null}

            {hasFullPermissionLevel ? (
              <Group variant="vertical" spacing="m" hasDivider>
                <Group.Item>
                  <RolesSection formFieldsSettings={pick(props.formFieldsSettings, ['roleUniqueName'])} />
                </Group.Item>

                {settings.isEntitlementsEnabled &&
                (!isEntitlementsFundingSourceIdsHidden || !isEntitlementsFundingSourceTypeHidden) ? (
                  <Group.Item>
                    <EntitlementSection
                      formFieldsSettings={pick(props.formFieldsSettings, [
                        'entitlementsFundingSourceIds',
                        'entitlementsFundingSourceType',
                      ])}
                    />
                  </Group.Item>
                ) : null}

                {!isPaymentApprovalLimitHidden ? (
                  <Group.Item>
                    <PaymentApprovalLimitSection
                      formFieldsSettings={pick(props.formFieldsSettings, ['paymentApprovalLimit'])}
                    />
                  </Group.Item>
                ) : null}
              </Group>
            ) : null}

            {/* last divider */}
            <Group.Item />
          </Group>
        </Form>
      </FormContext.Provider>
    </Group>
  );
};
