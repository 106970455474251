import { useMtlKycUpliftEnabled } from '@melio/ap-domain';
import { Loader } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { CompleteLegalInfoFormWidgetProps } from './types';
import {
  CompleteLegalInfoFormWidget as CompleteLegalInfoFormWidgetV1,
  CompleteLegalInfoFormWidgetProps as CompleteLegalInfoFormWidgetPropsV1,
} from './v1/CompleteLegalInfoForm.widget';
import { CompleteLegalInfoFormWidget as CompleteLegalInfoFormWidgetV2 } from './v2/CompleteLegalInfoForm.widget';

export const CompleteLegalInfoFormWidget = forwardRef<CompleteLegalInfoFormWidgetProps, 'form'>((props, ref) => {
  const { mtlKycUpliftEnabled, isLoading } = useMtlKycUpliftEnabled();

  const { companyName, ...restProps } = props;

  if (isLoading) {
    return <Loader />;
  }

  return mtlKycUpliftEnabled ? (
    <CompleteLegalInfoFormWidgetV2 {...props} ref={ref} />
  ) : (
    <CompleteLegalInfoFormWidgetV1 {...(restProps as unknown as CompleteLegalInfoFormWidgetPropsV1)} ref={ref} />
  );
});

CompleteLegalInfoFormWidget.displayName = 'CompleteLegalInfoFormWidget';
