import { PaymentReviewLineItem } from '@melio/ap-widgets';
import { Group, Typography } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export const LoanProviderSection = () => {
  const { formatMessage } = useMelioIntl();
  return (
    <Group variant="vertical">
      <Typography.SectionLabel
        label={formatMessage('activities.payDashboard.drawer.body.loan.active.loanProvider.sectionLabel')}
      />
      <PaymentReviewLineItem
        labelProps={{
          label: formatMessage('activities.payDashboard.drawer.body.loan.active.loanProvider.lineItem.label'),
        }}
        mainLabelProps={{
          label: formatMessage('activities.payDashboard.drawer.body.loan.active.loanProvider.lineItem.mainLabel'),
        }}
        icon={{ type: 'bank' }}
      />
    </Group>
  );
};
