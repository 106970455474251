import { AddVendorFormFields, Managed } from '../../../../types';

export const getCreateVendorPayload = (data: AddVendorFormFields, managed: Managed) => {
  const { companyName, accountNumber, nickname, postalCode } = data;
  const { managedBy, self } = managed;

  return {
    name: companyName ?? '',
    managed: { self, managedBy, ...(postalCode ? { zipCode: postalCode } : null) },
    accountNumber,
    ...(nickname ? { nickname } : {}),
  };
};
