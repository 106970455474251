import { Box } from '@chakra-ui/react';
import { Modal, ModalProps, Text, useFormSubmissionController } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

import { InvoiceAttachmentFormFields, InvoiceBillDetails } from '../types';
import { InvoiceAttachmentForm } from './invoice-attachment-form';

type InvoiceAttachmentModalProps = Pick<ModalProps, 'isOpen' | 'onClose'> & {
  isLoading?: boolean;
  isSaving?: boolean;
  onClose: VoidFunction;
  onDone: (data: InvoiceAttachmentFormFields) => void;
  billDetails: InvoiceBillDetails;
};

export const InvoiceAttachmentModalScreen: React.VFC<InvoiceAttachmentModalProps> = ({
  onClose,
  onDone,
  isOpen,
  isSaving,
  billDetails,
}) => {
  const { formatMessage } = useMelioIntl();
  const { onSubmissionStateChange, submitButtonProps } = useFormSubmissionController<InvoiceAttachmentFormFields>();

  const componentPrefix = 'activities.invoiceAttachment.screens.invoiceAttachment';

  return (
    <Modal
      data-testid="invoice-attachment-activity-invoice-attachment-modal-screen"
      header={formatMessage(`${componentPrefix}.title`)}
      primaryButton={{
        ...submitButtonProps,
        variant: 'primary',
        label: formatMessage(`${componentPrefix}.save`),
      }}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box marginBottom={8}>
        <Text data-testid="attachment-modal-screen-description">
          {formatMessage(`${componentPrefix}.description`, { ...billDetails })}
        </Text>
      </Box>
      <InvoiceAttachmentForm
        onSubmit={onDone}
        isSaving={isSaving}
        onSubmissionStateChange={onSubmissionStateChange}
      ></InvoiceAttachmentForm>
    </Modal>
  );
};

InvoiceAttachmentModalScreen.displayName = 'InvoiceAttachmentActivity.InvoiceAttachmentModalScreen';
