import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { RouterComponentWrapper } from '@/hoc/routerComponentWrapper';
import { APP_EVENTS, emitAppEvent } from '@/queries/event-emitter-query';
import { ViewManualBankAccountRoute } from '@/router/routes/ViewManualBankAccount.route';
import { AddCardAccountScreen } from '@/screens/AddCardAccount.screen';
import { PaymentMethodBankSelectScreen } from '@/screens/PaymentMethodBankSelect.screen';
export const PaymentMethodsRoute = () => {
  useEffect(() => {
    return () => {
      emitAppEvent(APP_EVENTS.SCHEDULE_PAYMENT_FLOW_CLOSED);
    };
  }, []);
  return (
    <Routes>
      <Route path="bank/select" element={<PaymentMethodBankSelectScreen />} />
      <Route path="card" element={<AddCardAccountScreen />} />
      <Route
        path="bank/manually/:id"
        element={
          <RouterComponentWrapper
            Component={ViewManualBankAccountRoute}
            componentProps={{ id: { _pathParam: 'id' } }}
          />
        }
      />
    </Routes>
  );
};
