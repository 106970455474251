import { TableColumnDef } from '@melio/penny';
import { Collaborator, FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMemo } from 'react';

import { UsersCell } from './UsersCell';

export const useEntitledUsersTableColumns = (fundingSourceId: FundingSource['id']) => {
  const { formatMessage } = useMelioIntl();

  return useMemo<TableColumnDef<Collaborator<'user'>>[]>(() => {
    const usersCell: TableColumnDef<Collaborator<'user'>> = {
      id: 'Users',
      size: 'l',
      header: formatMessage('widgets.addOrUpdateDeliveryMethod.entitled.fundingSource.modal.list.header.users'),
      cell: ({ row, rowIndex }) => (
        <UsersCell collaborator={row} fundingSourceId={fundingSourceId} rowIndex={rowIndex} />
      ),
    };

    return [usersCell];
  }, [formatMessage, fundingSourceId]);
};
