import { useAnalyticsContext } from '@melio/platform-analytics';
import { Bill, Vendor, VendorAutoPayStatusEnum, VendorEBillStatusEnum } from '@melio/platform-api';
import { useSearchParams } from 'react-router-dom';

import { usePayDashboardSorting } from '../../PayDashboardSortingProvider';

export const useVendorsTabAnalytics = ({
  vendors,
  unpaidBills,
  areAllSelected,
}: {
  vendors: Vendor[];
  unpaidBills: Bill[];
  areAllSelected: boolean;
}) => {
  const [searchParams] = useSearchParams();
  const sorting = usePayDashboardSorting();

  useAnalyticsContext({
    globalProperties: {
      PageName: 'dashboard-vendors',
      EntryPoint: 'dashboard-vendors',
      Flow: 'dashboard',
      CountInTab: vendors.length,
      SearchValue: searchParams.get('search'),
      UnpaidBills: unpaidBills.length,
      MultiSelection: areAllSelected,
      SortColumn: sorting?.sortingState?.id,
      SortDirection: sorting?.sortingState?.sortDirection,
      ...getBillersAnalytics(vendors),
    },
  });
};

const getBillersAnalytics = (vendors: Vendor[]) => {
  const localVendorsCount = vendors.filter((vendor) => !vendor.isManaged).length;
  const eBillers = vendors.filter((vendor) => !!vendor.isManaged);
  const {
    capableEBillersCount,
    pendingEBillersCount,
    activeEBillersCount,
    rejectedEBillersCount,
    notCapableEBillersCount,
    notCapableAutoPayCount,
    capableAutoPayCount,
    activeAutoPayCount,
  } = eBillers.reduce(
    (acc, vendor) => {
      switch (vendor.eBillStatus) {
        case VendorEBillStatusEnum.NotCapable:
          acc.notCapableEBillersCount++;
          break;
        case VendorEBillStatusEnum.Capable:
          acc.capableEBillersCount++;
          break;
        case VendorEBillStatusEnum.Pending:
          acc.pendingEBillersCount++;
          break;
        case VendorEBillStatusEnum.Active:
          acc.activeEBillersCount++;
          break;
        case VendorEBillStatusEnum.Rejected:
          acc.rejectedEBillersCount++;
          break;
      }

      switch (vendor.autoPayStatus) {
        case VendorAutoPayStatusEnum.NotCapable:
          acc.notCapableAutoPayCount++;
          break;
        case VendorAutoPayStatusEnum.Capable:
          acc.capableAutoPayCount++;
          break;
        case VendorAutoPayStatusEnum.Active:
          acc.activeAutoPayCount++;
          break;
      }

      return acc;
    },
    {
      notCapableEBillersCount: 0,
      capableEBillersCount: 0,
      pendingEBillersCount: 0,
      activeEBillersCount: 0,
      rejectedEBillersCount: 0,
      notCapableAutoPayCount: 0,
      capableAutoPayCount: 0,
      activeAutoPayCount: 0,
    }
  );

  return {
    Billers: {
      local: localVendorsCount,
      eBillerNotCapable: notCapableEBillersCount,
      eBillerCapable: capableEBillersCount,
      eBillerPending: pendingEBillersCount,
      eBillerActive: activeEBillersCount,
      eBillerRejected: rejectedEBillersCount,
    },
    AutoPay: {
      notCapable: notCapableAutoPayCount,
      capable: capableAutoPayCount,
      active: activeAutoPayCount,
    },
  };
};
