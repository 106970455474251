import React from 'react';
import { Box, Flex, Stack } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { Container, Text } from '@melio/penny';

import { NoResultsFound } from '@/cl/components/NoResultsFound/NoResultsFound.component';
import { VendorCard } from '@/cl/components/VendorCard/VendorCard.component';
import { WithLoading } from '@/hoc/withLoading.hoc';
import { usePartnerConfig } from '@/hooks/partners';
import { useSelectedVendor, useVendors } from '@/hooks/vendors.hooks';
import {
  vendorsDashboardSearchQuerySelector,
  vendorsFilteredBySearchQuerySelector,
} from '@/store/Vendors/Vendors.model';
import { usePlatformIntl } from '@/translations/Intl';
import { DataComponentEnum } from '@/types/vendors.types';

export const VendorsSortedCardList = () => {
  const searchQuery = useRecoilValue(vendorsDashboardSearchQuerySelector);
  const { isLoading } = useVendors();
  const vendors = useRecoilValue(vendorsFilteredBySearchQuerySelector);
  const { isSelected } = useSelectedVendor();
  const { formatMessage } = usePlatformIntl();
  const { partnerConfig } = usePartnerConfig();
  const { NoVendorsFoundIcon } = partnerConfig.icons;
  const { onSelect } = useSelectedVendor();

  const onClickHandler = React.useCallback(
    ({ vendorId }: { vendorId: string }) => {
      onSelect({ vendorId });
    },
    [onSelect],
  );

  let cardLocationIndexInList = 0;

  return (
    <WithLoading isLoading={isLoading}>
      <Flex mt="16px" direction={'column'} data-component={DataComponentEnum.SORTED_CARD_LIST}>
        {Object.keys(vendors!).map((letter, idx) => (
          <Flex direction={'column'} data-component={DataComponentEnum.CARD_LIST} key={idx}>
            {letter && vendors![letter].length > 0 && (
              <Container paddingTop="m" paddingBottom="xs">
                <Text as="h2" textStyle="heading2Semi">
                  {letter}
                </Text>
              </Container>
            )}
            <Stack flexDirection="column" gridGap="16px" spacing={0}>
              {vendors![letter].map((vendor) => (
                <VendorCard
                  key={vendor.id}
                  locationIndexInList={cardLocationIndexInList++}
                  vendor={vendor}
                  highlightedText={searchQuery}
                  isSelected={isSelected({ vendorId: vendor.id })}
                  onClickHandler={onClickHandler}
                />
              ))}
            </Stack>
          </Flex>
        ))}
        {Object.values(vendors!).every((vendors) => !vendors.length) && (
          <Box paddingTop={'90px'}>
            <NoResultsFound text={formatMessage('widgets.NoResultsFound.text')} icon={<NoVendorsFoundIcon />} />
          </Box>
        )}
      </Flex>
    </WithLoading>
  );
};
