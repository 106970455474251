import { useMelioIntl } from '@melio/ar-domain';
import { Chip, Group, SearchBar as DSSearchBar, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { useFiltersLabel } from '../hooks';
import { Filter } from '../types';

export type FilterBarProps = {
  selectedFilter: Filter;
  onSelectFilter: (filter: Filter) => void;
  search?: string;
  onSearch: (value: string) => void;
  filters: Filter[];
};

export const FiltersToolBar = forwardRef<FilterBarProps>(
  ({ onSelectFilter, selectedFilter, onSearch, search, filters, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const filtersLabel = useFiltersLabel();

    return (
      <Group
        variant="horizontal"
        justifyContent="flex-start"
        alignItems="center"
        width="full"
        spacing="s"
        data-component="FiltersToolBar"
        {...props}
        ref={ref}
      >
        <DSSearchBar
          data-testid="invoice-table-search-input"
          value={search}
          onChange={(e) => onSearch(e.target.value)}
          label={formatMessage('ar.dashboard.activities.invoiceTable.searchInput.label')}
          placeholder={formatMessage('ar.dashboard.activities.invoiceTable.searchInput.placeholder')}
          onClear={() => onSearch('')}
          aria-label={formatMessage('ar.dashboard.activities.invoiceTable.searchInput.aria-label')}
          instructionsText={formatMessage('ar.dashboard.activities.invoiceTable.searchInput.instructions')}
        />
        <Text {...{ minWidth: 'fit-content' }} textStyle="body3Semi" color="global.neutral.900">
          {formatMessage('ar.dashboard.activities.invoiceTable.filters.label')}
        </Text>
        <Group
          variant="horizontal"
          justifyContent="flex-start"
          alignItems="center"
          width="full"
          spacing="s"
          allowOverflowX
          ref={ref}
        >
          {filters.map((filter) => (
            <Chip
              key={filter}
              onClick={() => onSelectFilter(filter)}
              label={filtersLabel[filter]}
              isSelected={selectedFilter === filter}
              data-testid={`ar-dashboard-filter-${filter}`}
            />
          ))}
        </Group>
      </Group>
    );
  }
);
FiltersToolBar.displayName = 'FiltersToolBar';
