import { isEbill } from '@melio/ap-domain';
import { Bill, BillSubscriptionOptions, Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { string, TestContext } from 'yup';

import { PaymentFlowFormFields } from '../../../types';
import { getAvailableBalance } from '../../util/amount';

export const useAmountSchema = ({
  bill,
  payment,
  billSubscriptionOptions,
}: {
  bill?: Bill;
  payment?: Payment;
  billSubscriptionOptions?: BillSubscriptionOptions;
}) => {
  const { formatMessage, formatCurrency } = useMelioIntl();
  const {
    settings: {
      payment: {
        scheduling: { minAmountUsd, maxAmountUsd },
      },
    },
  } = useConfig();

  const isInRange = (value: number, min = 0, max = Infinity) => value >= min && value <= max;
  const createErrorMessage = (context: TestContext, min: number, max: number) =>
    context.createError({
      message: formatMessage('activities.paymentFlow.form.content.amountToPay.validBetweenMinimumAndMaximum.label', {
        minAmount: formatCurrency(min),
        maxAmount: formatCurrency(max),
      }),
    });

  const createRangeTest =
    (min = 0, max = Infinity) =>
    (value: number, context?: TestContext) => {
      const inRange = isInRange(value, min, max);

      if (!inRange) {
        return context ? createErrorMessage(context, min, max) : false;
      }

      return true;
    };

  const recurringRangeTestWithContext = (value: string | undefined, context: TestContext) => {
    const { fundingSourceId, recurrenceType } = context.parent as PaymentFlowFormFields;

    if (!value || !billSubscriptionOptions || !fundingSourceId || recurrenceType !== 'recurring') {
      return true;
    }

    const fundingSourceOption = billSubscriptionOptions?.eligibleFundingSources?.find(
      (source) => source.id === fundingSourceId
    );

    if (!fundingSourceOption) {
      return true;
    }

    return createRangeTest(fundingSourceOption.minAmount, fundingSourceOption.maxAmount)(Number(value), context);
  };

  const recurringRangeTest = (value: string | undefined, fundingSourceId: string | undefined) => {
    const fundingSourceOption = billSubscriptionOptions?.eligibleFundingSources?.find(
      (source) => source.id === fundingSourceId
    );

    if (!fundingSourceOption) {
      return true;
    }

    return createRangeTest(fundingSourceOption.minAmount, fundingSourceOption.maxAmount)(Number(value));
  };

  const oneTimeRangeTestWithContext = (value: string | undefined, context: TestContext) =>
    createRangeTest(minAmountUsd, maxAmountUsd)(Number(value), context);

  const oneTimeRangeTest = (value: string | undefined) => createRangeTest(minAmountUsd, maxAmountUsd)(Number(value));

  const isValidAmount = (value: string, fundingSourceId: string | undefined, isRecurring: boolean) =>
    isRecurring ? recurringRangeTest(value, fundingSourceId) : oneTimeRangeTest(value);

  return {
    amountToPay: () =>
      string()
        .required(formatMessage('activities.paymentFlow.form.content.amountToPay.required'))
        .test('validLessThanOrEqualBalance', '', (value, context) => {
          if (!bill) {
            return true;
          }

          if (isEbill(bill)) {
            return true;
          }

          if (
            payment?.subscriptionOccurrence?.billSubscription &&
            payment?.subscriptionOccurrence?.billSubscription?.status !== 'canceled'
          ) {
            return true;
          }

          if (payment?.createMethod === 'just_pay') {
            return true;
          }

          const amount = Number(value);
          const billAvailableBalance = getAvailableBalance(bill, payment);

          return (
            amount <= billAvailableBalance.toNaturalUnit() ||
            context.createError({
              message: formatMessage(
                'activities.paymentFlow.form.content.amountToPay.validLessThanOrEqualBalance.label',
                {
                  balance: formatCurrency(billAvailableBalance.toNaturalUnit()),
                }
              ),
            })
          );
        })
        .when('recurrenceType', {
          is: 'recurring',
          then: (schema) => schema.test('recurringAmountRange', '', recurringRangeTestWithContext),
          otherwise: (schema) => schema.test('oneTimeAmountRange', '', oneTimeRangeTestWithContext),
        }),
    lastAmount: () => string().test('lastAmountRange', '', recurringRangeTestWithContext).notRequired(),
    isValidAmount,
  };
};
