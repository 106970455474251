import { Box } from '@chakra-ui/react';
import { Group, Icon, Text, Tooltip } from '@melio/penny';
import { Collaborator } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { isCollaboratorBlocked } from '@melio/platform-utils';
import { useIsSubscriptionsEnabled, useSubscriptionFeature } from '@melio/subscriptions';

export type CollaboratorsSummaryProps = { collaborators: Collaborator[] };
export const CollaboratorsSummary = ({ collaborators }: CollaboratorsSummaryProps) => {
  const { formatMessage, formatCurrency } = useMelioIntl();

  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const collaboratorsInSubscriptionPlan = collaborators?.filter((c) => !isCollaboratorBlocked(c)) ?? [];
  const { quota } = useSubscriptionFeature({
    featureName: 'collaborators',
    requirements: { totalUnits: collaboratorsInSubscriptionPlan.length + 1 },
  });

  const getSubscriptionTooltipLabel = () => {
    // user is in a plan in which he can exceed free units limit by paying excessUnitFee
    if (quota.excessUnitFee) {
      if (collaboratorsInSubscriptionPlan.length === quota.freeUnitsLimit) {
        return formatMessage(
          'activities.settings.collaborators.summary.subscription.planWithExcessFee.reachedMaximumFreeSeats',
          { excessFee: formatCurrency(quota.excessUnitFee) }
        );
      }
      if (collaboratorsInSubscriptionPlan.length < quota.freeUnitsLimit) {
        return formatMessage('activities.settings.collaborators.summary.subscription.hasUnusedFreeSeats', {
          unusedSeatsCount: quota.freeUnitsLimit - collaboratorsInSubscriptionPlan.length,
        });
      }
      return formatMessage(
        'activities.settings.collaborators.summary.subscription.planWithExcessFee.exceedsMaximumFreeSeats',
        {
          freeSeatsCount: quota.freeUnitsLimit,
          excessFee: formatCurrency(quota.excessUnitFee),
          extraSeatsCount: collaboratorsInSubscriptionPlan.length - quota.freeUnitsLimit,
        }
      );
    } else {
      if (collaboratorsInSubscriptionPlan.length === quota.freeUnitsLimit) {
        return formatMessage(
          'activities.settings.collaborators.summary.subscription.planWithoutExcessFee.reachedMaximumFreeSeats'
        );
      }
      // free tier
      return formatMessage('activities.settings.collaborators.summary.subscription.hasUnusedFreeSeats', {
        unusedSeatsCount: quota.freeUnitsLimit - collaboratorsInSubscriptionPlan.length,
      });
    }
  };

  return (
    <Group
      spacing="s"
      data-testid="collaborators-summary"
      alignItems="center"
      as="ul"
      hasDivider
      dividerProps={{ role: 'presentation' }}
    >
      <Group spacing="xs" alignItems="center" as="li">
        <Text textStyle="body3" color="global.neutral.800">
          {formatMessage('activities.settings.collaborators.summary.permittedCount')}
        </Text>

        <Text textStyle="body3" color="global.neutral.800" data-testid="collaborators-summary-permittedCount">
          {collaboratorsInSubscriptionPlan.length}
        </Text>

        {isSubscriptionsEnabled ? (
          <Tooltip data-testid="subscriptions-tooltip" label={getSubscriptionTooltipLabel()}>
            <Box
              as={Icon}
              type="info"
              size="small"
              data-testid="subscriptions-tooltip-icon"
              role="button"
              aria-label={getSubscriptionTooltipLabel()}
              tabIndex={0}
            />
          </Tooltip>
        ) : null}
      </Group>

      <Group spacing="xs" alignItems="center" as="li">
        <Text textStyle="body3" color="global.neutral.800">
          {formatMessage('activities.settings.collaborators.summary.totalCount')}
        </Text>

        <Text textStyle="body3" color="global.neutral.800" data-testid="collaborators-summary-totalCount">
          {collaborators.length}
        </Text>
      </Group>
    </Group>
  );
};
