import { isSameMonth as isSameMonthFn, isSameWeek as isSameWeekFn } from 'date-fns';
import {
  Bill,
  DeliveryMethodType,
  FundingSource,
  InboxItem,
  InboxItemBillTypeEnum,
  InboxItemPaymentRequestTypeEnum,
  InboxItemPaymentTypeEnum,
  InboxItemScannedInvoiceTypeEnum,
  Payment,
  PaymentRequest,
  PaymentStatusEnum,
  ScannedInvoice,
  Vendor,
} from '@melio/platform-api';

import {
  BillGroupItem,
  GroupItem,
  GroupItemType,
  isBillGroupItem,
  isPaymentGroupItem,
  isScannedInvoiceGroupItem,
  PayDashboardInboxTabGroup,
  PayDashboardPaidTabGroup,
  PayDashboardScheduledTabGroup,
  PayDashboardSectionEnum,
  PayDashboardTabGroups,
  PayDashboardTabs,
  PaymentGroupItem,
  ScannedInvoiceGroupItem,
} from '@/types/payDashboard.types';
import { isPaymentRequestGroupItem, PaymentRequestGroupItem } from './../types/payDashboard.types';
import { filterPayCardByQuery, filterPayCardByVendorId } from './filterPayCardByQuery.utils';
import { getDateWithoutTime } from './getDateWithoutTime';

type InboxSections =
  | PayDashboardSectionEnum.error
  | PayDashboardSectionEnum.overdue
  | PayDashboardSectionEnum.thisWeek
  | PayDashboardSectionEnum.later;

type SchedulePaymentSection =
  | PayDashboardSectionEnum.pendingInfo
  | PayDashboardSectionEnum.thisWeek
  | PayDashboardSectionEnum.thisMonth
  | PayDashboardSectionEnum.later;
type CompletedPaymentSection = PayDashboardSectionEnum.older | PayDashboardSectionEnum.thisWeek;

const vendorIdToVendor: Record<string, Vendor> = {};
const deliveryMethodIdToDeliveryMethodType: Record<string, DeliveryMethodType> = {};
const fundingSourceIdToVerifiedStatus: Record<string, boolean> = {};

const checkRelativeDateForToday = (date: Date) => {
  const nowWithoutTime = getDateWithoutTime(new Date(Date.now()));
  const dateWithoutTime = getDateWithoutTime(date);

  return {
    isOverdue: dateWithoutTime < nowWithoutTime,
    isSameWeek: isSameWeekFn(dateWithoutTime, nowWithoutTime),
    isSameMonth: isSameMonthFn(dateWithoutTime, nowWithoutTime),
  };
};

const isPaymentVirtualDeliveryMethod = (payment: Payment) =>
  deliveryMethodIdToDeliveryMethodType[payment.deliveryMethodId] === DeliveryMethodType.VirtualAccount;

const isPaymentFundingSourceVerified = (payment: Payment) => {
  // if the funding source does not exits we assume its verified which means that the payment is not pending
  if (typeof fundingSourceIdToVerifiedStatus[payment.fundingSourceId] === 'undefined') {
    return true;
  }

  return !fundingSourceIdToVerifiedStatus[payment.fundingSourceId];
};

const convertToUnpaidBillGroupItem = (bill: Bill): BillGroupItem => ({
  type: GroupItemType.UNPAID,
  bill,
  vendor: vendorIdToVendor[bill.vendorId],
});

const convertToPaymentRequestGroupItem = (paymentRequest: PaymentRequest): PaymentRequestGroupItem => ({
  type: GroupItemType.PAYMENT_REQUEST,
  paymentRequest,
});

const convertToScannedInvoiceGroupItem = (scannedInvoice: ScannedInvoice): ScannedInvoiceGroupItem => ({
  type: GroupItemType.SCANNED_INVOICE,
  scannedInvoice,
});

const convertToFailedPaymentGroupItem = (payment: Payment): PaymentGroupItem => ({
  type: GroupItemType.FAILED,
  payment,
  vendor: payment.vendorId ? vendorIdToVendor[payment.vendorId] : undefined,
});

const convertToSchedulePaymentGroupItem = (payment: Payment): PaymentGroupItem => ({
  type: GroupItemType.SCHEDULED,
  payment,
  vendor: payment.vendorId ? vendorIdToVendor[payment.vendorId] : undefined,
});

const convertToPaidPaymentGroupItem = (payment: Payment): PaymentGroupItem => ({
  type: GroupItemType.PAID,
  payment,
  vendor: payment.vendorId ? vendorIdToVendor[payment.vendorId] : undefined,
  isVirtualDeliverymethod: isPaymentVirtualDeliveryMethod(payment),
});

const getInboxItemsGroups = (inboxItems: InboxItem[]) => {
  const failedPayments: PaymentGroupItem[] = [];

  // all items in the inbox teb
  const unpaidBills: Record<InboxSections, BillGroupItem[]> = {
    [PayDashboardSectionEnum.error]: [],
    [PayDashboardSectionEnum.overdue]: [],
    [PayDashboardSectionEnum.thisWeek]: [],
    [PayDashboardSectionEnum.later]: [],
  };

  const unReviewedScannedInvoices: Record<InboxSections, ScannedInvoiceGroupItem[]> = {
    [PayDashboardSectionEnum.error]: [],
    [PayDashboardSectionEnum.overdue]: [],
    [PayDashboardSectionEnum.thisWeek]: [],
    [PayDashboardSectionEnum.later]: [],
  };

  const paymentRequests: Record<InboxSections, PaymentRequestGroupItem[]> = {
    [PayDashboardSectionEnum.error]: [],
    [PayDashboardSectionEnum.overdue]: [],
    [PayDashboardSectionEnum.thisWeek]: [],
    [PayDashboardSectionEnum.later]: [],
  };

  // all scheduled payments
  const scheduledPayments: Record<SchedulePaymentSection, PaymentGroupItem[]> = {
    [PayDashboardSectionEnum.pendingInfo]: [], // unilateral
    [PayDashboardSectionEnum.thisWeek]: [],
    [PayDashboardSectionEnum.thisMonth]: [],
    [PayDashboardSectionEnum.later]: [],
  };

  // all completed payments
  const completedPayments: Record<CompletedPaymentSection, PaymentGroupItem[]> = {
    [PayDashboardSectionEnum.thisWeek]: [],
    [PayDashboardSectionEnum.older]: [],
  };

  inboxItems.forEach((inboxItem) => {
    if (inboxItem.type === InboxItemBillTypeEnum.Bill) {
      const bill = inboxItem.payload;
      const sectionName = getInboxSectionName(bill.dueDate);
      const billGroupItem = convertToUnpaidBillGroupItem(bill);
      unpaidBills[sectionName].push(billGroupItem);
    } else if (inboxItem.type === InboxItemPaymentRequestTypeEnum.PaymentRequest) {
      const paymentRequest = inboxItem.payload;
      const sectionName = getInboxSectionName(paymentRequest.dueDate);
      const paymentRequestGroupItem = convertToPaymentRequestGroupItem(paymentRequest);
      paymentRequests[sectionName].push(paymentRequestGroupItem);
    } else if (inboxItem.type === InboxItemScannedInvoiceTypeEnum.ScannedInvoice) {
      const scannedInvoice = inboxItem.payload;
      const sectionName = getInboxSectionName(scannedInvoice.dueDate);
      const scannedInvoiceGroupItem = convertToScannedInvoiceGroupItem(scannedInvoice);
      unReviewedScannedInvoices[sectionName].push(scannedInvoiceGroupItem);
    } else if (inboxItem.type === InboxItemPaymentTypeEnum.Payment) {
      const payment = inboxItem.payload;
      if (payment.status === PaymentStatusEnum.Failed) {
        failedPayments.push(convertToFailedPaymentGroupItem(payment));
      } else if (payment.status === PaymentStatusEnum.Blocked || payment.status === PaymentStatusEnum.Scheduled) {
        const sectionName = getSchedulePaymentSectionName(payment);
        scheduledPayments[sectionName].push(convertToSchedulePaymentGroupItem(payment));
      } else if (payment.status === PaymentStatusEnum.Completed || payment.status === PaymentStatusEnum.InProgress) {
        const sectionName = getCompletedPaymentSectionName(payment);
        completedPayments[sectionName].push(convertToPaidPaymentGroupItem(payment));
      }
    }
  });

  return {
    failedPayments,
    completedPayments,
    scheduledPayments,
    unpaidBills,
    paymentRequests,
    unReviewedScannedInvoices,
  };
};

const getSchedulePaymentSectionName = (payment: Payment): SchedulePaymentSection => {
  const { scheduledDate } = payment;

  if (!scheduledDate) {
    return PayDashboardSectionEnum.later;
  }

  const { isSameWeek: isScheduleDateSameWeek, isSameMonth: isScheduleDateSameMonth } =
    checkRelativeDateForToday(scheduledDate);

  if (isPaymentVirtualDeliveryMethod(payment) || isPaymentFundingSourceVerified(payment)) {
    return PayDashboardSectionEnum.pendingInfo;
  } else if (isScheduleDateSameWeek) {
    return PayDashboardSectionEnum.thisWeek;
  } else if (isScheduleDateSameMonth) {
    return PayDashboardSectionEnum.thisMonth;
  }

  return PayDashboardSectionEnum.later;
};

const getInboxSectionName = (dueDate?: Date | null): InboxSections => {
  if (!dueDate) {
    return PayDashboardSectionEnum.later;
  }

  const { isOverdue, isSameWeek } = checkRelativeDateForToday(dueDate);

  if (isOverdue) {
    return PayDashboardSectionEnum.overdue;
  } else if (isSameWeek) {
    return PayDashboardSectionEnum.thisWeek;
  }
  return PayDashboardSectionEnum.later;
};

const getCompletedPaymentSectionName = (payment: Payment): CompletedPaymentSection => {
  const { scheduledDate } = payment;

  if (!scheduledDate) {
    return PayDashboardSectionEnum.older;
  }

  const { isSameWeek: isScheduleDateSameWeek } = checkRelativeDateForToday(scheduledDate);

  return isScheduleDateSameWeek ? PayDashboardSectionEnum.thisWeek : PayDashboardSectionEnum.older;
};

const fillHashmaps = (vendors: Vendor[], fundingSources: FundingSource[]) => {
  vendors.forEach((vendor) => {
    vendorIdToVendor[vendor.id] = vendor;
    vendor.deliveryMethods?.forEach((deliveryMethod) => {
      deliveryMethodIdToDeliveryMethodType[deliveryMethod.id] = deliveryMethod.type;
    });
  });

  fundingSources.forEach((fundingSource) => {
    fundingSourceIdToVerifiedStatus[fundingSource.id] = fundingSource.isVerified;
  });
};

const filterItemsByQuery = <
  T extends ScannedInvoiceGroupItem | BillGroupItem | PaymentRequestGroupItem | PaymentGroupItem,
>(
  items: T[],
  queryString?: string,
): T[] => (queryString ? items.filter((it) => filterPayCardByQuery(it, queryString)) : items);

type CreatePayDashboardGroupsProp = {
  inboxItems?: InboxItem[];
  vendors: Vendor[] | null;
  fundingSources: FundingSource[] | null;
};

export const createUnfilteredPayDashboardGroups = ({
  inboxItems,
  vendors,
  fundingSources,
}: CreatePayDashboardGroupsProp): PayDashboardTabGroups => {
  if (!vendors || !fundingSources || !inboxItems) {
    return {
      [PayDashboardTabs.Inbox]: [],
      [PayDashboardTabs.Scheduled]: [],
      [PayDashboardTabs.Paid]: [],
    };
  }
  // Filling hashmaps for vendorID -> vendor & deliveryMethodId -> deliveryMethod for easy access on the map function
  fillHashmaps(vendors, fundingSources);

  const {
    failedPayments,
    completedPayments,
    scheduledPayments,
    unpaidBills,
    paymentRequests,
    unReviewedScannedInvoices,
  } = getInboxItemsGroups(inboxItems);
  return {
    [PayDashboardTabs.Inbox]: [
      {
        tab: PayDashboardTabs.Inbox,
        section: PayDashboardSectionEnum.error,
        items: failedPayments,
      },
      {
        tab: PayDashboardTabs.Inbox,
        section: PayDashboardSectionEnum.overdue,
        items: [
          ...unReviewedScannedInvoices[PayDashboardSectionEnum.overdue],
          ...unpaidBills[PayDashboardSectionEnum.overdue],
          ...paymentRequests[PayDashboardSectionEnum.overdue],
        ],
      },
      {
        tab: PayDashboardTabs.Inbox,
        section: PayDashboardSectionEnum.thisWeek,
        items: [
          ...unReviewedScannedInvoices[PayDashboardSectionEnum.thisWeek],
          ...unpaidBills[PayDashboardSectionEnum.thisWeek],
          ...paymentRequests[PayDashboardSectionEnum.thisWeek],
        ],
      },
      {
        tab: PayDashboardTabs.Inbox,
        section: PayDashboardSectionEnum.later,
        items: [
          ...unReviewedScannedInvoices[PayDashboardSectionEnum.later],
          ...unpaidBills[PayDashboardSectionEnum.later],
          ...paymentRequests[PayDashboardSectionEnum.later],
        ],
      },
    ],
    [PayDashboardTabs.Scheduled]: [
      {
        tab: PayDashboardTabs.Scheduled,
        section: PayDashboardSectionEnum.pendingInfo,
        items: scheduledPayments[PayDashboardSectionEnum.pendingInfo],
      },
      {
        tab: PayDashboardTabs.Scheduled,
        section: PayDashboardSectionEnum.thisWeek,
        items: scheduledPayments[PayDashboardSectionEnum.thisWeek],
      },
      {
        tab: PayDashboardTabs.Scheduled,
        section: PayDashboardSectionEnum.thisMonth,
        items: scheduledPayments[PayDashboardSectionEnum.thisMonth],
      },
      {
        tab: PayDashboardTabs.Scheduled,
        section: PayDashboardSectionEnum.later,
        items: scheduledPayments[PayDashboardSectionEnum.later],
      },
    ],
    [PayDashboardTabs.Paid]: [
      {
        tab: PayDashboardTabs.Paid,
        section: PayDashboardSectionEnum.thisWeek,
        items: completedPayments[PayDashboardSectionEnum.thisWeek],
      },
      {
        tab: PayDashboardTabs.Paid,
        section: PayDashboardSectionEnum.older,
        items: completedPayments[PayDashboardSectionEnum.older],
      },
    ],
  };
};

export const filterPayDashboardGroups = ({
  groups,
  querySearch,
  vendorId,
}: {
  groups: PayDashboardTabGroups;
  querySearch: string;
  vendorId: string | null;
}): PayDashboardTabGroups => {
  const filterTab = <T extends PayDashboardInboxTabGroup | PayDashboardScheduledTabGroup | PayDashboardPaidTabGroup>(
    tab: T,
  ): T => ({
    ...tab,
    items: filterItemsByQuery(tab.items, querySearch).filter((tab) => filterPayCardByVendorId(tab, vendorId)),
  });
  return {
    [PayDashboardTabs.Inbox]: groups[PayDashboardTabs.Inbox].map(filterTab).filter((g) => g.items.length),
    [PayDashboardTabs.Scheduled]: groups[PayDashboardTabs.Scheduled].map(filterTab).filter((g) => g.items.length),
    [PayDashboardTabs.Paid]: groups[PayDashboardTabs.Paid].map(filterTab).filter((g) => g.items.length),
  };
};

export const getPayDashboardItemId = (payDashboardItem: GroupItem) => {
  if (isScannedInvoiceGroupItem(payDashboardItem)) {
    return payDashboardItem.scannedInvoice.id;
  } else if (isPaymentGroupItem(payDashboardItem)) {
    return payDashboardItem.payment.id;
  } else if (isBillGroupItem(payDashboardItem)) {
    return payDashboardItem.bill.id;
  } else if (isPaymentRequestGroupItem(payDashboardItem)) {
    return payDashboardItem.paymentRequest.id;
  }
};
export const getPayDashboardItemType = (payDashboardItem: GroupItem) => payDashboardItem.type;

export const getInboxItemTypeByIdPrefix = (
  inboxItemId?: string,
):
  | InboxItemBillTypeEnum
  | InboxItemPaymentTypeEnum
  | InboxItemPaymentRequestTypeEnum
  | InboxItemScannedInvoiceTypeEnum
  | null => {
  const inboxItemPrefixToType: {
    [char: string]:
      | InboxItemBillTypeEnum
      | InboxItemPaymentTypeEnum
      | InboxItemPaymentRequestTypeEnum
      | InboxItemScannedInvoiceTypeEnum;
  } = {
    bill: InboxItemBillTypeEnum.Bill,
    pymnt: InboxItemPaymentTypeEnum.Payment,
    pymntreq: InboxItemPaymentRequestTypeEnum.PaymentRequest,
    scn: InboxItemScannedInvoiceTypeEnum.ScannedInvoice,
  };

  if (!inboxItemId) {
    return null;
  }

  const itemPrefix = inboxItemId.split('_')?.[0];
  return inboxItemPrefixToType[itemPrefix] || null;
};
