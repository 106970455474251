import { Group, Icon, IconProps, Link, Modal, Text, useBreakpointValue, Video } from '@melio/penny';
import { CompanyType, useIsAccountingFirm, useUserPreference } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { useLearnMoreLink, useSubscriptionGracePeriod, useSubscriptionPlan } from '../../../hooks';

const VIP_USER_PREFERENCE_KEY = 'vipWelcomeModal';

export const VipWelcomeModalActivity = () => {
  const isAccountingFirm = useIsAccountingFirm();
  const { isVip } = useSubscriptionPlan();
  const isMobile = useBreakpointValue({ xs: true, s: false });
  const isMobileOrTablet = useBreakpointValue({ s: true, m: false });
  const learnMoreLink = useLearnMoreLink();
  const { gracePeriodEndDateToDisplay } = useSubscriptionGracePeriod();
  const { formatMessage, formatDate } = useMelioIntl();

  const {
    data: userPreference,
    create: createUserPreference,
    isFetching: isUserPreferenceFetching,
    isMutating: isUserPreferenceMutating,
  } = useUserPreference({
    id: VIP_USER_PREFERENCE_KEY,
    enabled: isVip,
  });

  if (!isVip || isUserPreferenceFetching || isUserPreferenceMutating || !!userPreference) {
    return null;
  }

  const onClose = () => {
    void createUserPreference({ userPreference: { key: VIP_USER_PREFERENCE_KEY, value: 'true' } });
  };

  const userType = isAccountingFirm ? CompanyType.AccountingFirm : CompanyType.Smb;

  const whatsNewList: { label: string; iconType: IconProps['type'] }[] = [
    {
      label: formatMessage(`activities.subscription.vip.welcomeModal.${userType}.whatsNewList.item.first`),
      iconType: userType === CompanyType.AccountingFirm ? 'crown' : 'file-move',
    },
    {
      label: formatMessage(`activities.subscription.vip.welcomeModal.${userType}.whatsNewList.item.second`),
      iconType: userType === CompanyType.AccountingFirm ? 'contacts' : 'checked-circle',
    },
    {
      label: formatMessage(`activities.subscription.vip.welcomeModal.${userType}.whatsNewList.item.third`),
      iconType: userType === CompanyType.AccountingFirm ? 'favourite' : 'stopwatch-fast',
    },
  ];

  const { photoOrder, textOrder } = isMobileOrTablet
    ? { photoOrder: 1, textOrder: 2 }
    : { photoOrder: 2, textOrder: 1 };

  return (
    <Modal
      isOpen
      size="medium"
      header={formatMessage(`activities.subscription.vip.welcomeModal.${userType}.header`)}
      onClose={onClose}
      primaryButton={{
        label: formatMessage(`activities.subscription.vip.welcomeModal.primaryButton.label`),
        variant: 'primary',
        onClick: onClose,
      }}
      data-testid="vip-welcome-modal"
    >
      <Group variant={isMobileOrTablet ? 'vertical' : 'horizontal'}>
        <Group.Item basis="50%" order={textOrder}>
          <Group variant="vertical" spacing="l">
            <Text>
              {formatMessage(`activities.subscription.vip.welcomeModal.${userType}.description`, {
                gracePeriodEnd: formatDate(gracePeriodEndDateToDisplay, {
                  dateStyle: 'long',
                }),
              })}
            </Text>
            <Group variant="vertical">
              <Text textStyle="body3">
                {formatMessage(`activities.subscription.vip.welcomeModal.${userType}.whatsNewList.header`)}
              </Text>
              {whatsNewList.map(({ label, iconType }) => (
                <Group key={label}>
                  <Icon type={iconType} />
                  <Text textStyle="body3">{label}</Text>
                </Group>
              ))}
              {isAccountingFirm && (
                <Text textStyle="body3">
                  <Link
                    href={learnMoreLink}
                    data-testid="vip-learn-more-link"
                    color="secondary"
                    label={formatMessage(`activities.subscription.vip.welcomeModal.learnMoreLink`)}
                    newTab
                  />
                </Text>
              )}
            </Group>
          </Group>
        </Group.Item>
        <Group.Item basis="50%" order={photoOrder} alignSelf="center">
          <Video
            src="https://s3.amazonaws.com/platform-static.meliopayments.com/assets/melio/illustrations/vip-welcome.mp4"
            hideControls
            autoPlay
            loop
            height={isMobile ? '320px' : '360px'}
            width="100%"
          />
        </Group.Item>
      </Group>
    </Modal>
  );
};
