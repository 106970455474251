import { useLocation } from '@melio/platform-utils';
import { useNavigate } from 'react-router-dom';

import {
  DEFAULT_DASHBOARD_ROUTE,
  SETTINGS_COLLABORATORS,
  SETTINGS_SUPPORT,
  SUBSCRIPTION_SETTINGS_PLANS_ROUTE,
  SUBSCRIPTION_SUCCESS_PAGE_ROUTE,
} from '../ui/constants';

export const useRedirects = () => {
  const navigate = useNavigate();
  const { state } = useLocation<{ returnUrl?: string } | null>();

  const { returnUrl } = (state as { returnUrl?: string }) || {};

  const goToReturnUrlOrDashboard = () => navigate(returnUrl || DEFAULT_DASHBOARD_ROUTE);

  const goToDashboard = (state = {}) => navigate(DEFAULT_DASHBOARD_ROUTE, state);
  const goToSettingsPage = () => navigate(SUBSCRIPTION_SETTINGS_PLANS_ROUTE);
  const goToSuccessPage = () => navigate(SUBSCRIPTION_SUCCESS_PAGE_ROUTE, { state: { returnUrl } });
  const goToSettingsCollaboratorsPage = () => navigate(SETTINGS_COLLABORATORS);
  const goToSettingsSupportPage = () => navigate(SETTINGS_SUPPORT);

  return {
    goToReturnUrlOrDashboard,
    goToDashboard,
    goToSettingsPage,
    goToSuccessPage,
    goToSettingsCollaboratorsPage,
    goToSettingsSupportPage,
  };
};
