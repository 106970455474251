import { USBankLegalText } from '@/screens/embedded-experience-demo/layouts/us-bank/us-bank.components';

export const USBankLegalNotes = () => (
  <>
    <USBankLegalText>
      Other Investments/Retirement accounts and U.S. Bank Beneficiary accounts are not included in asset total.
    </USBankLegalText>
    <USBankLegalText>
      Account aggregation services are provided by Yodlee, our third-party vendor. Data is obtained by Yodlee or
      manually entered. U.S. Bank and U.S. Bancorp Investments are not responsible for the accuracy or availability of
      content. External account details include data on assets and liabilities not held or valued by U.S. Bank, U.S.
      Bancorp Investments, and U.S. Bancorp Advisors. External account balances displayed may not reflect the latest
      transactions or available balance. Contact your financial institution with external account questions. For more
      information on linking external accounts refer to Terms and Conditions.
    </USBankLegalText>
    <USBankLegalText>
      Your account totals may include the value of assets held at U.S. Bancorp Investments and assets and/or insurance
      policies not held at U.S. Bancorp Investments. Held-away asset information is provided only for informational
      purposes only, has not been verified, and may not reflect current market values. We obtained it from sources
      believed reliable, but we do not guarantee its accuracy or completeness. Certain held-away assets and policies may
      reflect zero market value, while others may reflect value where no actual market value exists. Assets held-away
      may not be covered by SIPC. This information is not a replacement for account documents that you receive from the
      company holding the asset(s) (including trade confirmations, accounts statements, or 1099 forms.) For more
      information, please see the Holdings section of the online site or U.S. Bancorp Investments mobile app summarizing
      your assets and/or policies not held at U.S. Bancorp Investments.
    </USBankLegalText>
    <USBankLegalText>
      U.S. Bank, U.S. Bancorp Investments, U.S. Bancorp Advisors, and their representatives do not provide tax or legal
      advice. Your tax and financial situation is unique. You should consult your tax and/or legal advisor for advice
      and information concerning your particular situation.
    </USBankLegalText>
    <USBankLegalText>
      The Sweep account balance is included in the associated account’s available balance.
    </USBankLegalText>
    <USBankLegalText>
      The Current Balance may not be the final payoff amount. Please contact us for payoff amount.
    </USBankLegalText>
    <USBankLegalText>
      If you do not pay the Minimum Payment due by the Payment Due Date, you may be subject to late charges which will
      appear on your next statement. The Minimum Payment includes any Past Due Amount. The Past Due Amount is any
      outstanding principal, interest, insurance, payment protection, escrow, fees or late charges billed for the month.
      The Minimum Payment does not reflect todays account activity.
    </USBankLegalText>
    <USBankLegalText>
      Late fees may be charged if you do not pay the Minimum Payment Due by this date and will appear on your next
      statement.
    </USBankLegalText>
    <USBankLegalText>
      This includes your current minimum payment amount plus any past due amounts. The amount does not reflect todays
      account activity and may vary depending on the interest rate.
    </USBankLegalText>
    <USBankLegalText>
      This is the amount that is available to draw from your line of credit. The amount may not reflect todays account
      activity.
    </USBankLegalText>
    <USBankLegalText>
      The Current Balance may not be the final amount owed. Please contact us at 800.673.3555 if your intent is to pay
      off and close your account.
    </USBankLegalText>
    <USBankLegalText>
      Amount due includes your base rent plus any past due amounts, tax, fees and/or misc. charges. The amount due does
      not reflect todays account activity. We may report information about your account to credit bureaus. Late
      payments, missed payments or other defaults on your account may be reflected on your credit report.
    </USBankLegalText>
    <USBankLegalText>
      Late fees may be charged if you do not pay the Amount due by this date and will appear on your next statement.
    </USBankLegalText>
    <USBankLegalText>
      <b>For U.S. Bank:</b>
    </USBankLegalText>
    <USBankLegalText>
      Equal Housing Lender. Deposit products offered by U.S. Bank National Association. Member FDIC. U.S. Bank is not
      responsible for and does not guarantee the products, services or performance of U.S. Bancorp Investments and U.S.
      Bancorp Advisors. Mortgage, Home Equity and credit products are offered by U.S. Bank National Association. Loan
      approval is subject to credit approval and program guidelines. Not all loan programs are available in all states
      for all loan amounts. Interest rates and program terms are subject to change without notice.
    </USBankLegalText>
  </>
);
