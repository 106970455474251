import { Group, Icon, Text, Tooltip } from '@melio/penny';

type RadioLabelWithTooltipProps = {
  label: string;
  tooltip?: string;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  dataTestId?: string;
};

// this component is a bypass to the design system RadioItem. It allows us to add a non disabled tooltip
// to a disabled RadioItem. This should not be used outside of this context of the Pay Flow.
// The alternative to this is an broader Design System solution for explaining why an item is disabled
// within a radio group.
export const RadioLabelWithTooltip = ({
  label,
  tooltip,
  isReadOnly,
  isDisabled,
  dataTestId,
}: RadioLabelWithTooltipProps) => {
  const getColor = () => {
    if (isReadOnly) {
      return 'global.neutral.800';
    }
    if (isDisabled) {
      return 'global.neutral.500';
    }
    return undefined;
  };
  return (
    <Group spacing="xs" alignItems="center">
      <Text color={getColor()}>{label}</Text>
      {tooltip && (
        <Tooltip label={tooltip} shouldAddTriggerFocus>
          <Icon type="info" size="small" data-testid={dataTestId} />
        </Tooltip>
      )}
    </Group>
  );
};
