import { usePayDashboardPagination } from '../components/Pagination';
import { sortPaymentsByUpdatedAt } from '../utils/sort.utils';
import { useGetNewPayments } from './useGetNewPayments';

type UseGetNewPaymentsParams = {
  preferenceKey: 'payDashboardApprovalsTabLastSeen';
};

const useGetNewPendingApprovalPayments = ({ preferenceKey }: UseGetNewPaymentsParams) => {
  const { pageSize } = usePayDashboardPagination();

  return useGetNewPayments({
    preferenceKey,
    sortPaymentsBy: sortPaymentsByUpdatedAt,
    paymentsParams: {
      expand: 'none',
      pendingCurrentUserApproval: true,
      limit: pageSize,
      sort: [
        {
          field: 'updatedAt',
          order: 'desc',
        },
      ],
    },
  });
};

export const useNewApprovalsTabItems = () =>
  useGetNewPendingApprovalPayments({ preferenceKey: 'payDashboardApprovalsTabLastSeen' });
