import { useModelApi } from '@melio/api-client';
import { ApiKeys, VendorsApiClient } from '@melio/platform-api-axios-client';

type Props = { disabled: boolean; vendorId: string };

export const useVendorEditableFields = (props: Props) =>
  useModelApi({
    id: `editable-fields-${props.vendorId}`,
    enabled: !props.disabled,
    queryKey: [ApiKeys.VendorsApi, 'editable-fields', props.vendorId],
    queryFn: () => VendorsApiClient.getVendorEditableFields(props.vendorId),
  });
