import noop from 'lodash/noop';

import { NavigationMap, UseWizardArgs, UseWizardReturnType } from './types';
import { useWizard } from './useWizard';

export type UseWizardSimpleArgs<Steps extends string> = Omit<UseWizardArgs<Steps>, 'navigationMap'> & {
  navigationSequence: Steps[];
};

type SimpleNavigationMap<Steps extends string> = {
  [Step in Steps]: () => Steps | void;
};

export type UseWizardSimpleReturnType<Steps extends string> = Omit<
  UseWizardReturnType<Steps, SimpleNavigationMap<Steps>>,
  'goNextMap'
> & {
  goNext: (shouldReplace?: boolean) => void;
};

export const useWizardSimple = <Steps extends string>(
  args: UseWizardSimpleArgs<Steps>
): UseWizardSimpleReturnType<Steps> => {
  const { navigationSequence, locationsMap } = args;
  const allSteps = Object.keys(locationsMap);
  const navMapFromNavArr = Object.fromEntries(
    navigationSequence.map((stepName, index) => [stepName, () => navigationSequence[index + 1]])
  );
  const navigationMap = Object.fromEntries(
    allSteps.map((stepName) => [stepName, navMapFromNavArr[stepName] || noop])
  ) as NavigationMap<Steps>;

  const useWizardArgs: UseWizardArgs<Steps> = { ...args, navigationMap };

  const result = useWizard(useWizardArgs);
  const goNext = (shouldReplace?: boolean) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    result.goNextMap[result.currentStep]({ navArgs: [], shouldReplace });
  };

  return {
    ...result,
    goNext,
  };
};
