import { Box } from '@chakra-ui/react';
import { FileAttachment, Group, Link, Loader, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export const EBillAttachment = ({ isLoading, url }: { isLoading: boolean; url: string }) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Box>
      <FileAttachment isReadOnly />
      {isLoading ? (
        <Group spacing="xs">
          <Text color="global.neutral.600" textStyle="body3">
            {formatMessage('widgets.billDetails.eBillAttachment.link')}
          </Text>
          <Loader />
        </Group>
      ) : null}
      {url ? (
        <Link
          data-testid="ebill-attachment-link"
          href={url}
          newTab
          label={formatMessage('widgets.billDetails.eBillAttachment.link')}
          size="medium"
          variant="standalone"
        />
      ) : null}
    </Box>
  );
};
