import { Box } from '@chakra-ui/react';

export const USBankFooterIllustration = () => {
  return (
    <Box display="flex" width="full">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1200"
        height="121"
        viewBox="0 0 1200 121"
        fill="none"
        style={{ flexShrink: 0 }}
      >
        <path
          d="M1070.91 28.3519V0.390137H-768.007V5.31153H1065.99V28.3519H955.536V93.9016L1012.46 120.39L1070.91 93.9196V33.2716H1200V28.3502L1070.91 28.3519ZM1065.99 90.7453L1012.49 114.977L960.458 90.765V33.2733H1065.99V90.7453Z"
          fill="white"
        />
      </svg>
      <Box width={'full'} height={120} marginTop={'28px'} borderTop="5px solid white" />
    </Box>
  );
};
