import { useState } from 'react';

import { RepaymentTermsActivityProps, RepaymentTermsActivityStep } from './types';

const initialStep: RepaymentTermsActivityStep = 'REPAYMENT_TERMS';

export const useRepaymentTermsActivityStep = ({
  onFirstStepBack,
}: {
  onFirstStepBack: RepaymentTermsActivityProps['onBack'];
}) => {
  const [currentStep, goToStep] = useState<RepaymentTermsActivityStep>(initialStep);

  const goToPreviousStep = (): void => {
    if (currentStep === initialStep) {
      onFirstStepBack();
    } else {
      goToStep('REPAYMENT_TERMS');
    }
  };

  return {
    currentStep,
    goToStep,
    goToPreviousStep,
  };
};
