/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@chakra-ui/react';
import { Theme, useBreakpoint, useTheme } from '@melio/penny';
import { useEffect, useState } from 'react';

import { UploadFile } from '../scheduled/types';
import { DocumentsUploadIndicatorItemComponent } from './DocumentsUploadIndicatorItem.component';
import { DocumentsUploadIndicatorTitleComponent } from './DocumentsUploadIndicatorTitle.component';

type DocumentsUploadIndicatorProps = {
  showUploadIndicator: boolean;
  files: UploadFile[];
};

export const DocumentsUploadIndicator = ({ showUploadIndicator, files }: DocumentsUploadIndicatorProps) => {
  const [minimize, setMinimize] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const style = useTheme();
  const { isExtraSmallScreen } = useBreakpoint();

  const isUploadingDocuments = showUploadIndicator && files?.length > 0;

  useEffect(() => {
    if (isUploadingDocuments) {
      setShow(true);
    }
  }, [isUploadingDocuments]);

  const toggleSize = () => setMinimize(!minimize);
  const closeModal = () => {
    setShow(false);
  };

  return (
    <Box
      data-testid="documents-upload-indicator-container"
      sx={documentsUploadIndicatorTheme(style, show, isExtraSmallScreen).baseStyle.uploadIndicator}
    >
      <DocumentsUploadIndicatorTitleComponent
        onClose={closeModal}
        onToggle={toggleSize}
        isMinimized={minimize}
        files={files}
      />
      {files?.map((file: UploadFile) => (
        <DocumentsUploadIndicatorItemComponent key={file.name} file={file} isMinimized={minimize} />
      ))}
    </Box>
  );
};

export const documentsUploadIndicatorTheme = (currentTheme: Theme, show: boolean, isSmallScreen: boolean) => ({
  baseStyle: {
    uploadIndicator: {
      position: 'fixed',
      width: isSmallScreen ? '90%' : '440px',
      bottom: '0px',
      right: isSmallScreen ? '5%' : '47px',
      display: show ? 'flex' : 'none',
      flexDirection: 'column',
      alignItems: 'start',
      backgroundColor: currentTheme.colors.global.neutral['100'],
      border: 'basic.light',
      borderRadius: '10px 10px 0 0',
      padding: '0px',
      overflow: 'hidden',
      zIndex: 9999,
    },
  },
});
