import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const LogoutIcon = (props: IconProps) => (
  <Icon width="20px" height="20px" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 14c0-.6.4-1 1-1s1 .4 1 1v2c0 1.1-.9 2-2 2h-3v4c0 .4-.2.7-.5.9-.2.1-.3.1-.5.1s-.4-.1-.6-.2l-7-5c-.2-.2-.4-.5-.4-.8V2a1 1 0 0 1 1-1h11c1.1 0 2 .9 2 2v3.2c0 .6-.4 1-1 1s-1-.4-1-1V3H5.1l4.5 3.2c.2.2.4.5.4.8v9h3v-2Zm-5 6.1-5-3.6V3.9l5 3.6v12.6Z"
      fill="currentColor"
    />
    <path
      d="M12 9a1 1 0 1 0 0 2h7.586l-2.293 2.293a1 1 0 0 0 1.414 1.414l3.996-3.996A.992.992 0 0 0 23 10a.997.997 0 0 0-.297-.711l-3.996-3.996a1 1 0 0 0-1.414 1.414L19.586 9H12Z"
      fill="currentColor"
    />
  </Icon>
);
