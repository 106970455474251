import { convertFxAmountToUsdAmount } from '@melio/ap-domain';
import { FundingSourceTypesOption } from '@melio/ap-widgets';
import { Bill, ExchangeRateBase, FinancingEligibilityStatus, RepaymentTerms } from '@melio/platform-api';

type AdditionalAnalyticsParams = {
  paymentAmount: number;
  bill?: Bill;
  fundingSourceTypesOptions?: FundingSourceTypesOption[];
  repaymentTerms?: RepaymentTerms;
  isFxPayment: boolean;
  fxRate?: ExchangeRateBase;
};

export const getAdditionalAnalytics = ({
  paymentAmount,
  bill,
  fundingSourceTypesOptions,
  repaymentTerms,
  isFxPayment,
  fxRate,
}: AdditionalAnalyticsParams) => {
  let data = {};

  if (fundingSourceTypesOptions) {
    const supported = fundingSourceTypesOptions.filter((option) => option.supported);
    const isCardSupported = supported.find((option) => option.type === 'card');
    const isAchSupported = supported.find((option) => option.type === 'bank-account');
    data = {
      ...data,
      PaymentMethodShow: isAchSupported ? (isCardSupported ? 'ach_card' : 'ach') : isCardSupported ? 'card' : undefined,
    };
  }

  if (bill) {
    const billTotalAmount = bill?.amount;
    const isPartialPayment = !!(billTotalAmount && 0 < paymentAmount && paymentAmount < billTotalAmount);
    data = {
      ...data,
      BillId: bill?.id,
      BillTotalAmount: billTotalAmount,
      PaymentAmount: paymentAmount,
      Is_Partial: isPartialPayment,
    };
  }

  if (repaymentTerms) {
    data = {
      ...data,
      FinancingExperience: repaymentTerms.appliedToProvider ? 'stx' : 'ftx',
      IsEligibleToFinancing: repaymentTerms.status === FinancingEligibilityStatus.Eligible,
    };
  }
  if (isFxPayment && fxRate) {
    const fxAmountInUsd = convertFxAmountToUsdAmount({
      fxRate,
      paymentAmount,
      currency: bill?.currency,
    });
    data = {
      ...data,
      FxAmount: isFxPayment ? bill?.amount : undefined,
      UsdAmount: fxAmountInUsd.toFixed(2),
    };
  }

  return data;
};
