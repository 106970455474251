import { RoleUniqueNames } from '@melio/platform-api';

type Variant = 'neutral' | 'secondary';
type RoleToVariantMap = {
  [roleUniqueName in RoleUniqueNames]: Variant;
};

const roleToVariantMap: Partial<RoleToVariantMap> = {
  'melio-owner': 'neutral',
  'melio-admin': 'neutral',
  'melio-accountant': 'secondary',
  'melio-contributor': 'secondary',
  'capital-one-primary': 'neutral',
  'capital-one-am': 'secondary',
};

export const roleToVariant = (roleUniqueName: string | null | undefined): Variant => {
  if (roleUniqueName && Object.values(RoleUniqueNames).includes(roleUniqueName as RoleUniqueNames)) {
    return roleToVariantMap[roleUniqueName as RoleUniqueNames] || 'secondary';
  }
  return 'secondary';
};
