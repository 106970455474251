import { useState } from 'react';

import { FundingSourceSelectionActivityProps, FundingSourceSelectionActivityStep } from './types';

const initialStep: FundingSourceSelectionActivityStep = 'FUNDING_SOURCE_SELECTION';

export const useFundingSourceSelectionActivityStep = ({
  onFirstStepBack,
}: {
  onFirstStepBack: FundingSourceSelectionActivityProps['onBack'];
}) => {
  const [currentStep, setCurrentStep] = useState<FundingSourceSelectionActivityStep>(initialStep);

  const goToPreviousStep = (): void => {
    if (currentStep === initialStep) {
      onFirstStepBack();
    }

    const previousStepMap: Partial<Record<FundingSourceSelectionActivityStep, FundingSourceSelectionActivityStep>> = {
      ADD_BANK: 'FUNDING_SOURCE_SELECTION',
      ADD_CARD: 'FUNDING_SOURCE_SELECTION',
    };

    setCurrentStep(previousStepMap[currentStep] ?? initialStep);
  };

  return {
    currentStep,
    goToPreviousStep,
    goToStep: setCurrentStep,
  };
};
