/* eslint-disable react-hooks/exhaustive-deps */
import { forwardRef, useCallback } from 'react';
import { useAnalytics } from '@melio/platform-analytics';
import { Bill, Vendor } from '@melio/platform-api';
import { convertCentsToDollars } from '@melio/platform-utils';

import { useRouter } from '@/hooks/router.hooks';
import { PaymentsOverviewCard } from '@/widgets/vendorsDashboard/PaymentsOverview/PaymentsOverviewCard.widget';

export interface VendorPaymentsOverviewProps {
  vendor: Vendor;
  vendorBills: Bill[];
  linkToUnpaid?: string;
  linkToScheduled?: string;
  linkToPaid?: string;
  isInternationalSupportedForBusiness?: boolean;
}

export type VendorPaymentsOverviewHandler = {
  scrollIntoView: () => void;
};

const MINIMUM_BILLS_TO_BATCH_PAYMENTS = 1;

export const VendorPaymentsOverview = forwardRef<VendorPaymentsOverviewHandler, VendorPaymentsOverviewProps>(
  ({ vendor, vendorBills, linkToPaid, linkToScheduled, linkToUnpaid, isInternationalSupportedForBusiness }, _ref) => {
    const {
      generatePayInboxLink,
      generatePayScheduledLink,
      generatePayPaidLink,
      navigateToBatchPayment,
      navigateToSchedulePayment,
    } = useRouter();

    const { track } = useAnalytics();
    const { paymentsOverview } = vendor;
    const vendorUnpaidBillsCount = vendorBills.length;

    const handlePayUnpaidBillsClick = useCallback(() => {
      const unpaidBillsIds = vendorBills.map((bill) => bill.id);

      track('Vendor', 'Click', {
        VendorId: vendor.id,
        CountUnpaidBills: vendorUnpaidBillsCount,
        CountPaidBills: paymentsOverview?.paid.count,
        UnpaidAmount: paymentsOverview ? convertCentsToDollars(paymentsOverview?.unpaid, ['sum']).sum : 0,
      });

      if (unpaidBillsIds.length) {
        vendorUnpaidBillsCount > MINIMUM_BILLS_TO_BATCH_PAYMENTS
          ? navigateToBatchPayment({ billIds: unpaidBillsIds })
          : navigateToSchedulePayment({ billId: unpaidBillsIds[0] });
      }
    }, [vendor, vendorBills, vendorUnpaidBillsCount, paymentsOverview]);

    const handleLinkClick = useCallback(() => {
      track('ViewUnpaidBills', 'Chose', {
        VendorId: vendor.id,
        CountUnpaidBills: vendorUnpaidBillsCount,
        CountPaidBills: paymentsOverview?.paid.count,
        UnpaidAmount: paymentsOverview?.unpaid.sum,
      });
      track('Vendor', 'Click', {
        Cta: 'view-bills',
      });
    }, [vendor, paymentsOverview, vendorUnpaidBillsCount]);

    return (
      <PaymentsOverviewCard
        vendorBusinessGeography={vendor.businessGeography}
        vendorName={vendor.name}
        isInternationalSupportedForBusiness={isInternationalSupportedForBusiness}
        linkToUnpaid={linkToUnpaid ?? generatePayInboxLink(vendor.id)}
        unpaidCount={paymentsOverview?.unpaid.count}
        unpaidSum={paymentsOverview?.unpaid.sum}
        linkToScheduled={linkToScheduled ?? generatePayScheduledLink(vendor.id)}
        scheduledCount={paymentsOverview?.scheduled.count}
        scheduledSum={paymentsOverview?.scheduled.sum}
        linkToPaid={linkToPaid ?? generatePayPaidLink(vendor.id)}
        paidCount={paymentsOverview?.paid.count}
        paidSum={paymentsOverview?.paid.sum}
        onPayUnpaidBillsClick={handlePayUnpaidBillsClick}
        unpaidBillsCount={vendorUnpaidBillsCount}
        onLinkClick={handleLinkClick}
        currency={vendor.currency}
      />
    );
  },
);

VendorPaymentsOverview.displayName = 'VendorPaymentsOverview';
