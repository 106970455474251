import { CardNetwork, DeliveryMethod, FundingSource, PaymentIntent, Vendor } from '@melio/platform-api';
import { compact, map, some, sumBy, uniq, uniqBy } from 'lodash';

import { getCreditCardFundingSourceNetwork } from '../../../funding-sources/fundingSources.utils';
import { VendorDetailsRequiredFormField } from '../../../funding-sources/VendorDetails/components/types';
import { getVendorRequiredFormFields } from '../../../funding-sources/VendorDetails/utils';
import { SchedulePaymentIntent } from '../../types';

export type PaymentIntentForAnalytics = Pick<PaymentIntent, 'id' | 'fundingSourceId' | 'deliveryMethodId'> & {
  payment: PaymentIntent['amountToPay'];
  fundingSourceType?: ReturnType<typeof getFundingSourceType>;
  deliveryMethodType?: DeliveryMethod['type'];
  vendorId: Vendor['id'];
  estimatedFees: number;
};

type CardNetworkMissingDetails = {
  paymentIntentId: PaymentIntent['id'];
  cardNetwork: CardNetwork | null;
  details: { total: number; fields: string[] };
};

const getNumberOfBills = (paymentIntentsWithDerivatives: SchedulePaymentIntent[]) =>
  sumBy(paymentIntentsWithDerivatives, ({ bills }) => bills.length);

const getBillsIds = (paymentIntentsWithDerivatives: SchedulePaymentIntent[]) =>
  paymentIntentsWithDerivatives.flatMap(({ bills }) => bills.map(({ id }) => id));

const getPaymentIntentById = (
  paymentIntentsWithDerivatives: SchedulePaymentIntent[],
  paymentIntentId: PaymentIntent['id']
) => paymentIntentsWithDerivatives.find(({ paymentIntent }) => paymentIntent.id === paymentIntentId);

const getFundingSourceById = (fundingSources: FundingSource[], fundingSourceId: FundingSource['id']) =>
  fundingSources.find((fundingSource) => fundingSource.id === fundingSourceId);

const getPaymentIntentDeliveryMethod = (paymentIntent: PaymentIntent, vendor: Vendor) =>
  vendor?.deliveryMethods.find((dm) => dm.id === paymentIntent.deliveryMethodId);

const getTotalEstimatedFeesForSinglePaymentIntent = (paymentIntent: PaymentIntent) =>
  sumBy(paymentIntent.estimatedFees, 'amount');

const mapPaymentIntentsForAnalytics = (
  paymentIntentsWithDerivatives: SchedulePaymentIntent[],
  fundingSources: FundingSource[]
): PaymentIntentForAnalytics[] =>
  paymentIntentsWithDerivatives.map<PaymentIntentForAnalytics>(({ paymentIntent, bills, vendor }) => ({
    id: paymentIntent.id,
    fundingSourceId: paymentIntent.fundingSourceId,
    fundingSourceType: getFundingSourceType(fundingSources.find((fs) => fs.id === paymentIntent.fundingSourceId)),
    deliveryMethodId: paymentIntent.deliveryMethodId,
    deliveryMethodType: getPaymentIntentDeliveryMethod(paymentIntent, vendor)?.type,
    vendorId: vendor.id,
    payment: sumBy(bills, 'amount'),
    estimatedFees: getTotalEstimatedFeesForSinglePaymentIntent(paymentIntent),
    billsCount: bills.length,
    cardNetwork: getCreditCardFundingSourceNetwork({
      fundingSources,
      fundingSourceId: paymentIntent.fundingSourceId,
    }),
  }));

const getUniqueFundingSources = (paymentIntents: PaymentIntent[], fundingSources: FundingSource[]): FundingSource[] =>
  uniqBy(
    compact(
      paymentIntents.map(
        (paymentIntent) =>
          paymentIntent.fundingSourceId && getFundingSourceById(fundingSources, paymentIntent.fundingSourceId)
      )
    ),
    (fundingSource: FundingSource) => fundingSource?.id
  );

const getUniqueDeliveryMethodsIds = (paymentIntents: PaymentIntent[]): DeliveryMethod['id'][] =>
  uniq(compact(map(paymentIntents, 'deliveryMethodId')));

const getTotalPaymentVolume = (paymentIntents: PaymentIntent[]): number => sumBy(paymentIntents, 'amountToPay');

const getTotalEstimatedFees = (paymentIntents: PaymentIntent[]): number =>
  sumBy(paymentIntents, getTotalEstimatedFeesForSinglePaymentIntent);

const getPaymentIntentsExposedToFastACH = (paymentIntents: PaymentIntent[]): PaymentIntent[] =>
  paymentIntents.filter((paymentIntent) =>
    some(paymentIntent.deliveryPreferenceOptions, (preference) => preference.type === 'expedited-ach')
  );

const getPaymentIntentsExposedToFastCheck = (paymentIntents: PaymentIntent[]): PaymentIntent[] =>
  paymentIntents.filter((paymentIntent) =>
    some(paymentIntent.deliveryPreferenceOptions, (preference) => preference.type === 'express-check')
  );
type CriticalMissingDataFields = keyof Pick<
  PaymentIntent,
  'fundingSourceId' | 'deliveryMethodId' | 'scheduledDate' | 'selectedDeliveryPreferenceType'
>;
const getPaymentIntentsWithCriticalMissingData = (paymentIntents: PaymentIntent[]) => {
  const paymentIntentsWithMissingData: {
    paymentIntentId: PaymentIntent['id'];
    missingFields: CriticalMissingDataFields[];
  }[] = [];
  const criticalMissingDataFields: CriticalMissingDataFields[] = [
    'fundingSourceId',
    'deliveryMethodId',
    'scheduledDate',
    'selectedDeliveryPreferenceType',
  ];

  paymentIntents.forEach((paymentIntent) => {
    const missingFields: CriticalMissingDataFields[] = [];
    criticalMissingDataFields.forEach((field) => {
      if (!paymentIntent[field]) {
        missingFields.push(field);
      }
    });
    if (missingFields.length > 0) {
      paymentIntentsWithMissingData.push({ paymentIntentId: paymentIntent.id, missingFields });
    }
  });
  return paymentIntentsWithMissingData;
};

const getFundingSourceType = (fs: PaymentIntent['fundingSource']) =>
  fs?.type === 'bank-account' || fs?.type === 'flex-account' ? fs?.type : fs?.details?.type;

const getVendorMissingDetailsList = (requiredFields: VendorDetailsRequiredFormField[]) => ({
  fields: requiredFields,
  total: requiredFields.length,
});

const getCardNetworkMissingDetails = (
  paymentIntentsWithDerivatives: SchedulePaymentIntent[],
  fundingSources: FundingSource[] | undefined
) => {
  const cardNetworkMissingDetails: CardNetworkMissingDetails[] = paymentIntentsWithDerivatives
    .map((paymentIntentWithDerivatives) => {
      const { paymentIntent, vendor } = paymentIntentWithDerivatives;
      const fundingSource = fundingSources?.find((fs) => fs.id === paymentIntent.fundingSourceId);
      const requiredFields = getVendorRequiredFormFields(vendor, fundingSource);
      const vendorMissingDetailsList = getVendorMissingDetailsList(requiredFields);
      if (vendorMissingDetailsList.fields.length) {
        return {
          paymentIntentId: paymentIntent.id,
          cardNetwork: getCreditCardFundingSourceNetwork({
            fundingSourceId: paymentIntent.fundingSourceId,
            fundingSources,
          }),
          details: vendorMissingDetailsList,
        };
      }

      return null;
    })
    .filter(Boolean) as CardNetworkMissingDetails[];

  return cardNetworkMissingDetails;
};

export {
  getBillsIds,
  getCardNetworkMissingDetails,
  getFundingSourceById,
  getFundingSourceType,
  getNumberOfBills,
  getPaymentIntentById,
  getPaymentIntentDeliveryMethod,
  getPaymentIntentsExposedToFastACH,
  getPaymentIntentsExposedToFastCheck,
  getPaymentIntentsWithCriticalMissingData,
  getTotalEstimatedFees,
  getTotalPaymentVolume,
  getUniqueDeliveryMethodsIds,
  getUniqueFundingSources,
  getVendorMissingDetailsList,
  mapPaymentIntentsForAnalytics,
};
