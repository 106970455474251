import { SectionBanner } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';

export const CreationBadRequestBanner = () => {
  const { formatMessage } = useMelioIntl();

  return (
    <NewSinglePaymentStepLayout.Content>
      <SectionBanner
        description={formatMessage('activities.addInternationalDeliveryMethod.error.badRequest')}
        variant="critical"
      />
    </NewSinglePaymentStepLayout.Content>
  );
};
