import { Group, Modal, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import {
  Collaborator,
  FundingSourceEntitlementType,
  PermissionLevelEnum,
  useCollaborators,
  useUpdateCollaboratorsFundingSourceEntitlements,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePermissions } from '@melio/platform-permissions';
import { useSystemMessage } from '@melio/platform-utils';
import React, { useEffect } from 'react';

import { EntitledUserTable } from './EntitledUserTable';
import { useEntitledUsersSelection } from './useEntitledUsersSelection';

type Props = {
  actor: Collaborator;
  fundingSourceDisplayName: string | React.ReactNode;
  isOpen: boolean;
  onClose: VoidFunction;
  fundingSourceId: string;
  entitlementCollaboratorsId: string[];
};
export const EntitledFundingSourceModalActivity = ({
  actor,
  fundingSourceId,
  fundingSourceDisplayName,
  isOpen,
  onClose,
  entitlementCollaboratorsId,
}: Props) => {
  const { track } = useAnalytics();
  const { can } = usePermissions();
  const { showMessage } = useSystemMessage();
  const { formatMessage } = useMelioIntl();
  const canUpdateEntitlements = can({
    subject: 'collaborator:entitlements:fundingSource',
    action: 'update',
  });
  const { data: collaborators, isLoading: isCollaboratorsLoading } = useCollaborators({
    expand: canUpdateEntitlements ? ['user', 'fundingSourceEntitlements'] : ['user'],
  });
  const { update: updateUsersEntitlements, isUpdating } = useUpdateCollaboratorsFundingSourceEntitlements();

  const collaboratorsWithFullPermission = collaborators?.filter(
    (collaborator) => collaborator.permissionLevel === PermissionLevelEnum.Full
  );

  useEffect(() => {
    track(`PaymentMethod`, 'View', {
      Product: 'ap',
      PageName: 'choose-who-can-pay',
      Flow: 'add-payment-method',
      intent: 'manage-payment-method-permissions',
      PaymentMethodId: fundingSourceId,
    });
  }, [track, fundingSourceId]);

  const onClickCloseModal = (cta: string) => {
    track(`PaymentMethod`, 'Click', {
      Product: 'ap',
      PageName: 'choose-who-can-pay',
      Flow: 'add-payment-method',
      intent: 'exit-flow',
      Cta: cta,
      PaymentMethodId: fundingSourceId,
    });
    onClose();
  };

  const {
    selectedRows,
    rowSelectionTooltips,
    onAllRowsSelectionChange,
    onRowSelectionChange,
    disableRowSelection,
    changedOriginRows,
    disableAllRowsSelection,
  } = useEntitledUsersSelection(
    collaboratorsWithFullPermission || [],
    actor?.id,
    entitlementCollaboratorsId,
    fundingSourceId
  );

  const getUpdatedUsersEntitlements = ():
    | { userId: string; fundingSourceIds: string[]; entitlementType: FundingSourceEntitlementType }[]
    | undefined => {
    const updatedUserIdsEntitlements = changedOriginRows.map((row) => {
      const collaboratorToUpdate = collaboratorsWithFullPermission?.find((col) => col.id === row.rowId);

      if (!collaboratorToUpdate) {
        throw new Error('Collaborator not found');
      }

      if (row.selected) {
        return {
          userId: collaboratorToUpdate.userId,
          entitlementType: FundingSourceEntitlementType.Selective,
          fundingSourceIds: [
            ...(collaboratorToUpdate.fundingSourceEntitlements?.fundingSourceIds || []),
            fundingSourceId,
          ],
        };
      } else {
        return {
          userId: collaboratorToUpdate.userId,
          entitlementType: FundingSourceEntitlementType.Selective,
          fundingSourceIds:
            collaboratorToUpdate.fundingSourceEntitlements?.fundingSourceIds?.filter((fs) => fs !== fundingSourceId) ||
            [],
        };
      }
    });

    return updatedUserIdsEntitlements;
  };

  const onClickSave = async () => {
    const userEntitlementsToUpdate = getUpdatedUsersEntitlements();
    try {
      if (userEntitlementsToUpdate?.length) {
        await updateUsersEntitlements({ entitlements: userEntitlementsToUpdate });
      }
      track(`PaymentMethod`, 'Click', {
        Product: 'ap',
        PageName: 'choose-who-can-pay',
        Flow: 'add-payment-method',
        intent: 'save-payment-method-permission',
        Cta: 'save',
        ManageUserId: userEntitlementsToUpdate?.map((entitlement) => entitlement.userId),
        CountPermissions: userEntitlementsToUpdate?.length,
        PaymentMethodId: fundingSourceId,
      });
      showMessage({
        type: 'success',
        id: 'edit-entitlement-modal-success-toast',
        title: formatMessage('widgets.addOrUpdateDeliveryMethod.entitled.fundingSource.modal.toast.success'),
      });
    } catch (e) {
      showMessage({
        type: 'error',
        id: 'edit-entitlement-modal-failed-toast',
        title: formatMessage('widgets.addOrUpdateDeliveryMethod.entitled.fundingSource.modal.toast.error'),
      });
    } finally {
      onClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClickCloseModal('close')}
      data-testid="modal-entitled-funding-source"
      header={formatMessage('widgets.addOrUpdateDeliveryMethod.entitled.fundingSource.modal.title', {
        card: fundingSourceDisplayName,
      })}
      primaryButton={{
        label: formatMessage('widgets.addOrUpdateDeliveryMethod.entitled.fundingSource.modal.cta'),
        onClick: () => onClickSave(),
        variant: 'primary',
        isLoading: isUpdating,
      }}
      secondaryButton={{
        label: formatMessage('widgets.addOrUpdateDeliveryMethod.entitled.fundingSource.modal.cancel'),
        onClick: () => onClickCloseModal('cancel'),
        variant: 'tertiary',
      }}
      isLoading={isCollaboratorsLoading}
    >
      <Group variant="vertical">
        <Text>
          {formatMessage('widgets.addOrUpdateDeliveryMethod.entitled.fundingSource.modal.description', {
            card: fundingSourceDisplayName,
          })}
        </Text>
        <EntitledUserTable
          collaborators={collaboratorsWithFullPermission || []}
          rowSelectionTooltips={rowSelectionTooltips}
          isCollaboratorsLoading={isCollaboratorsLoading}
          selectedRows={selectedRows}
          disableRowSelection={disableRowSelection}
          onRowSelectionChange={onRowSelectionChange}
          onAllRowsSelectionChange={onAllRowsSelectionChange}
          disableAllRowsSelection={disableAllRowsSelection}
          fundingSourceId={fundingSourceId}
        />
      </Group>
    </Modal>
  );
};
