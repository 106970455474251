import { Form, MultiSelectOption } from '@melio/penny';
import { useVendors } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';

import { useFormContext } from '../../FormContext';

type VendorConditionProps = { index: number };

export const VendorCondition = ({ index }: VendorConditionProps) => {
  const { formatMessage } = useMelioIntl();
  const { registerField } = useFormContext();

  const { data: vendors, isLoading } = useVendors({ params: { sort: [{ field: 'name', order: 'asc' }] } });

  const options: MultiSelectOption[] = useMemo(() => {
    if (isLoading || !vendors) {
      return [];
    }
    return vendors.map(({ id, name }) => ({
      label: name,
      value: id,
      testId: `approval-vendor-condition-option-${id}`,
    }));
  }, [isLoading, vendors]);

  const field = registerField(`criteria.conditions.${index}.condition.configuration.ids`);

  return (
    <>
      <Form.ContentBox colSpan={4} data-testid="vendor-condition">
        {!isEmpty(options) ? (
          <Form.MultiSelect
            {...field}
            placeholder={formatMessage('activities.create-approval-workflows.conditions.vendor.placeholder')}
            aria-label="specific users"
            isDisabled={false}
            data-testid="approval-vendor-condition"
            error={
              field.error
                ? { message: formatMessage('activities.create-approval-workflows.conditions.vendor.errors.required') }
                : undefined
            }
            options={options}
            isLoading={isLoading}
          />
        ) : (
          <Form.SelectNew
            {...field}
            placeholder={formatMessage('activities.create-approval-workflows.conditions.vendor.placeholder')}
            aria-label="specific users"
            isDisabled={false}
            data-testid="approval-vendor-condition-empty-data"
            error={
              field.error
                ? { message: formatMessage('activities.create-approval-workflows.conditions.vendor.errors.required') }
                : undefined
            }
            options={[]}
            emptyState={formatMessage('activities.create-approval-workflows.conditions.vendor.empty-data')}
          />
        )}
      </Form.ContentBox>
      <Form.ContentBox colSpan={3} />
    </>
  );
};
