import { useAnalytics } from '@melio/platform-analytics';
import { Bill, BillLineItemType, PostBillsRequest, useAccountingPlatforms, useBills } from '@melio/platform-api';
import { useMonitoring } from '@melio/platform-monitoring';
import { useMelioIntl } from '@melio/platform-provider';
import { useDateUtils, useSystemMessage } from '@melio/platform-utils';

import { MonitoredAction } from '../../../../monitoring';
import { useActivitiesNavigate } from '../../../../utils';
import { VendorTabAmountCellAmounts } from './types';

type Props = {
  amountsToPay: VendorTabAmountCellAmounts;
  unpaidBills: Bill[];
  onSuccess?: () => void;
  onFailure?: () => void;
};

export const useVendorsBatchPayments = ({ amountsToPay, unpaidBills, onSuccess, onFailure }: Props) => {
  const { startAction, endAction } = useMonitoring<MonitoredAction>();
  const { batchCreate: createBatchBills, isMutating: isCreatingBills } = useBills({
    enabled: false,
  });
  const { showMessage } = useSystemMessage();
  const { track } = useAnalytics();
  const { formatMessage } = useMelioIntl();
  const { hasAccountingPlatform } = useAccountingPlatforms({});
  const { navigateToBatchPayment } = useActivitiesNavigate();
  const { createDate } = useDateUtils();

  const getInitialValuesToCreateBills = () =>
    Object.keys(amountsToPay).map((key) => ({ vendorId: key, amount: amountsToPay[key] }));

  const getVendorsUnpaidBills = () => unpaidBills.map((bill) => bill.id);

  const createLineItemsPayload = ({ billAmount }: { billAmount: number }) => ({
    lineItems: [
      {
        type: BillLineItemType.Category,
        amount: billAmount,
        externalCategoryId: null,
        description: null,
        order: 0,
        ...(hasAccountingPlatform ? { externalLabelId: '' } : {}),
      },
    ],
  });

  const createBillPayload = ({
    amount,
    vendorId,
  }: {
    amount: string | undefined;
    vendorId: string;
  }): PostBillsRequest => {
    const billDueDate = createDate().toISOString();
    const billAmount = Number(amount);
    return {
      vendorId,
      amount: billAmount,
      dueDate: billDueDate,
      isDraft: true,
      ...createLineItemsPayload({ billAmount }),
    };
  };

  const handleFail = () => {
    showMessage({
      type: 'error',
      title: formatMessage('activities.payDashboard.vendorsTab.batchPayments.toast.error'),
    });
    onFailure?.();
  };

  const createBills = async () => {
    const billsCreateData = getInitialValuesToCreateBills();
    if (!billsCreateData.length) {
      return [];
    }

    const billsBatchCreateData = billsCreateData.map(({ vendorId, amount }) => createBillPayload({ amount, vendorId }));

    startAction('batch_create_bills');
    try {
      const createdBills = await createBatchBills(billsBatchCreateData);
      const createdBillIds = createdBills.map((bill) => bill.id);

      track('Dashboard', 'Status', {
        Intent: 'bill-created',
        Status: 'success',
        BillId: createdBillIds,
      });

      onSuccess?.();
      endAction('batch_create_bills');
      return createdBillIds;
    } catch (error) {
      handleFail();
      throw error;
    }
  };

  const handlePrepareBatchPaymentsData = async () => {
    const newBillIds = (await createBills()) ?? [];
    const unpaidBillIds = getVendorsUnpaidBills();
    return [...newBillIds, ...unpaidBillIds];
  };

  const handleGoToBatchPayments = async () => {
    const billIdsForBatchPayments = await handlePrepareBatchPaymentsData();
    navigateToBatchPayment({ billIds: billIdsForBatchPayments });
  };

  return {
    handleGoToBatchPayments,
    isCreatingBills,
  };
};
