import { Container, Divider, Group } from '@melio/penny';
import { useAccount } from '@melio/platform-api';
import { useIsSubscriptionsEnabled, usePlansTiers } from '@melio/subscriptions';
import { SubscriptionBillingCycleEnum, useSubscriptionsPreview } from '@melio/subscriptions/src/api';
import {
  SubscriptionCheckoutBillingTotal,
  SubscriptionCheckoutSummaryPlan,
  SubscriptionDetailedSubtotal,
} from '@melio/subscriptions/src/ui/components/SubscriptionCheckoutSummary';

import { FirmClientSubscriptionReviewAndConfirmDisclaimer } from './components/FirmClientSubscriptionReviewAndConfirmDisclaimer.component';

export type FirmClientSubscriptionPlanSelectionActivityProps = {
  planId: string;
  fundingSourceId: string | null;
  organizationId?: string;
  discountPercentage?: number;
  planCycle: SubscriptionBillingCycleEnum;
};

export const FirmClientSubscriptionReviewAndConfirmActivity = ({
  planId,
  fundingSourceId,
  organizationId,
  planCycle,
  discountPercentage,
}: FirmClientSubscriptionPlanSelectionActivityProps) => {
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const { getPlan } = usePlansTiers();
  const plan = getPlan(planId);
  const { data: accountData } = useAccount({ id: 'me' });
  const { data: preview, isFetching: isPreviewFetching } = useSubscriptionsPreview({
    enabled: isSubscriptionsEnabled,
    params: {
      planId,
      fundingSourceId: fundingSourceId || undefined,
      planCycle,
      organizationId,
    },
  });

  return (
    <Container
      backgroundColor="default"
      border="regular"
      height="auto"
      overflow="hidden"
      paddingX="m"
      paddingY="m"
      width="full"
    >
      <Group variant="vertical" spacing="s">
        <Group variant="vertical" spacing="m">
          <SubscriptionCheckoutSummaryPlan
            selectedPlan={plan}
            currentCycle={planCycle}
            preview={preview}
            isLoading={isPreviewFetching}
            predefinedDiscountPercentage={discountPercentage}
          />

          <Divider />

          <SubscriptionDetailedSubtotal
            legalAddress={fundingSourceId ? accountData?.company.legalAddress || null : null}
            preview={preview}
            isLoading={isPreviewFetching}
          />

          <Divider />
          <SubscriptionCheckoutBillingTotal preview={preview} isFreeTrial={false} isLoading={isPreviewFetching} />
          {fundingSourceId && (
            <FirmClientSubscriptionReviewAndConfirmDisclaimer
              isLoading={isPreviewFetching}
              preview={preview}
              planCycle={planCycle}
            />
          )}
        </Group>
      </Group>
    </Container>
  );
};
