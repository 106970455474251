import { CatalogItemsApiClient } from '@melio/platform-api-axios-client';

import { useModel, UseModelProps } from '../../api-client';

export type UseCatalogItemProps = UseModelProps<
  typeof CatalogItemsApiClient.getCatalogItemCatalogItemId,
  typeof CatalogItemsApiClient.updateCatalogItemCatalogItemId,
  typeof CatalogItemsApiClient.postCatalogItem
>;

export const useCatalogItem = (props: UseCatalogItemProps = {}) =>
  useModel({
    ...props,
    queryKey: 'CatalogItemsApi',
    queryFn: CatalogItemsApiClient.getCatalogItemCatalogItemId,
    deleteFn: CatalogItemsApiClient.deleteCatalogItemCatalogItemId,
    updateFn: CatalogItemsApiClient.updateCatalogItemCatalogItemId,
    createFn: CatalogItemsApiClient.postCatalogItem,
  });

export type CatalogItemModel = ReturnType<typeof useCatalogItem>;
