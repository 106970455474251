import { AccountingPlatform, FundingSource } from '@melio/platform-api';

export const getIsReconciliationEnabled = (
  activeAccountingPlatform?: AccountingPlatform,
  fundingSource?: FundingSource
) =>
  !!activeAccountingPlatform?.accountPayableEnabled &&
  !!fundingSource &&
  !fundingSource.isBlocked &&
  fundingSource.isVerified;

export const getIsReconciliationNeeded = (
  activeAccountingPlatform?: AccountingPlatform,
  fundingSource?: FundingSource
) =>
  getIsReconciliationEnabled(activeAccountingPlatform, fundingSource) &&
  !fundingSource?.accountingPlatformPaymentAccountId;
