import { addWildcardToRoutes, Navigate, useMelioIntl } from '@melio/ar-domain';
import { forwardRef, SettingsPageHeader } from '@melio/platform-utils';
import { Route, Routes } from 'react-router-dom';

import { CatalogItemsTableFlow } from '../catalog-items-table';
import { DiscountsTableFlow } from '../discounts-table';
import { ServiceChargesTableFlow } from '../service-charges-table';
import { TaxRatesTableFlow } from '../tax-rates-table';
import { TabsLayout } from './TabsLayout';
import { SettingsTab } from './types';
import { useTabs } from './useTabs';

export const InvoiceItemsSettingsFlow = forwardRef(() => {
  const { tabs, activeTab, handleTabChange } = useTabs();
  const { formatMessage } = useMelioIntl();

  const Paths = addWildcardToRoutes(SettingsTab);

  return (
    <>
      <SettingsPageHeader
        title={formatMessage('ar.settings.flows.invoiceItems.title')}
        subTitle={formatMessage('ar.settings.flows.invoiceItems.description')}
      />
      <Routes>
        <Route
          element={
            <TabsLayout
              tabs={tabs}
              activeTab={activeTab}
              onChange={handleTabChange}
              data-testid="invoice-items-settings-container"
            />
          }
        >
          <Route index element={<Navigate to={SettingsTab.Items} replace />} />

          <Route path={Paths.Items} element={<CatalogItemsTableFlow />} />
          <Route path={Paths.Tax} element={<TaxRatesTableFlow />} />
          <Route path={Paths.ServiceCharges} element={<ServiceChargesTableFlow />} />
          <Route path={Paths.Discounts} element={<DiscountsTableFlow />} />
        </Route>
      </Routes>
    </>
  );
});
