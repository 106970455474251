import { Box } from '@chakra-ui/react';
import { Icon, Text } from '@melio/penny';
import { CreateMultipleInvoicesResults } from '@melio/platform-api';

import { UploadIndicatorItemTheme } from '@/cl/components/UploadIndicator/UploadIndicator.component.theme';
import { useAppTheme } from '@/cl/theme/theme.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { formatBytes } from '@/utils/files.utils';

type UploadIndicatorItemProps = {
  file: {
    name: string;
    size: number;
  };
  isMinimized: boolean;
  isUploading: boolean;
  multipleInvoices: CreateMultipleInvoicesResults;
};

export const UploadIndicatorItemComponent = ({
  file,
  isMinimized,
  isUploading,
  multipleInvoices,
}: UploadIndicatorItemProps) => {
  const style = useAppTheme(UploadIndicatorItemTheme, { minimize: isMinimized });
  const { formatMessage } = usePlatformIntl();

  const { success: createdInvoices } = multipleInvoices?.results?.data || {};

  const isSuccess = (name: string) => createdInvoices?.find((file) => file.filename === name);

  const getFileStatus = (
    name: string,
  ): {
    type: 'bills' | 'checked-circle' | 'error';
    status: 'fail' | 'success' | 'default';
  } => {
    let type: 'bills' | 'checked-circle' | 'error' = 'bills';
    let uploadStatus: 'fail' | 'success' | 'default' = 'default';

    if (createdInvoices) {
      const success = isSuccess(name);

      uploadStatus = success ? 'success' : 'fail';
      type = success ? 'checked-circle' : 'error';
    }

    return { type, status: uploadStatus };
  };

  return (
    <Box data-testid={`upload-indicator-item-${file.name}`} css={style.uploadIndicatorItem} key={file.name}>
      <Box css={style.uploadIndicatorIcon[getFileStatus(file.name).status]} height="fit-content">
        <Icon type={getFileStatus(file.name).type} size="large" color="inherit" />
      </Box>
      <Box css={style.uploadIndicatorItem.text}>
        <Text textStyle="inline" color="inherit" shouldSupportEllipsis data-testid="file-name">
          {file.name}
        </Text>
        <Text textStyle="body4" color="global.neutral.600" data-testid="description">
          {!createdInvoices || isSuccess(file.name)
            ? formatBytes(file.size)
            : formatMessage('widgets.uploadIndicator.upload.error.unknown')}
        </Text>
      </Box>
      {isUploading && <Box css={style.uploadIndicatorItem.progressBar} />}
    </Box>
  );
};
