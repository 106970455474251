import { SortDirection, SortingState } from '@melio/penny';
import { Dispatch, SetStateAction } from 'react';

export enum PayDashboardTabs {
  Vendors = 'vendors',
  Bills = 'bills',
  Payments = 'payments',
  Approvals = 'approvals',
  Financing = 'financing',
}

export type PayDashboardSortingType = {
  sortingState?: SortingState;
  setSortState: Dispatch<SetStateAction<SortingState | undefined>>;
};

export type PayDashboardTabSortableColumn<T extends string> = {
  label: string;
  cellId: T;
  defaultSortOrder: SortDirection;
};
