import { ARFeeDetails, ARFeeValueTypeEnum, useMelioIntl } from '@melio/ar-domain';

export const useFormattedFee = () => {
  const { formatMessage, formatPercents, formatCurrency } = useMelioIntl();

  const getFeeLabel = (feeDetails: ARFeeDetails | undefined): string => {
    if (feeDetails) {
      const { value, valueType } = feeDetails;

      if (value === 0) {
        return formatMessage('ar.guestPayment.paymentMethods.fees.free');
      }

      return formatMessage('ar.guestPayment.paymentMethods.fees.amount', {
        fee: valueType === ARFeeValueTypeEnum.Fixed ? formatCurrency(value) : formatPercents(value),
      });
    }

    return '';
  };

  return { getFeeLabel };
};
