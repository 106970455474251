import React, { PropsWithChildren } from 'react';
import { Button, Container, Group, Illustration, Layout, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { ErrorBoundary } from '@melio/platform-monitoring';

import { useRouter } from '@/hooks/router.hooks';

export function UnexpectedErrorBoundary(props: PropsWithChildren<{}>) {
  const { goToPayDashboard } = useRouter();
  return (
    <ErrorBoundary
      FallbackComponent={({ resetErrorBoundary }) => (
        <UnexpectedErrorPage
          onClick={() => {
            resetErrorBoundary();
            goToPayDashboard();
          }}
        />
      )}
    >
      {props.children}
    </ErrorBoundary>
  );
}

function UnexpectedErrorPage(props: { onClick: VoidFunction }) {
  const { formatMessage } = useMelioIntl();
  return (
    <Layout variant="6Columns">
      <Group height="full" data-testid="unexpected-error" spacing="xxl" variant="vertical" justifyContent="center">
        <Container justifyContent="center">
          <Illustration size="large" type="page-not-found" />
        </Container>
        <Container justifyContent="center">
          <Group variant="vertical" textAlign="center" spacing="m">
            <Text data-testid="unexpected-error-title" textStyle="display2Semi">
              {formatMessage('app.unexpectedError.title')}
            </Text>
            <Text data-testid="unexpected-error-message" textStyle="body1">
              {formatMessage('app.unexpectedError.description')}
            </Text>
          </Group>
        </Container>
        <Container justifyContent={'center'}>
          <Button label={formatMessage('app.unexpectedError.cta')} onClick={props.onClick} />
        </Container>
      </Group>
    </Layout>
  );
}
