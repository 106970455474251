import { Container, Group, Icon, Text } from '@melio/penny';

export const PaymentDescription = ({ description }: { description: string }) => (
  <Container backgroundColor="light" paddingX="l" paddingY="s" data-testid="payment-description">
    <Group variant="horizontal" spacing="xxs">
      <Icon type="lock" size="small" color="inherit" />
      <Text textStyle="body4" color="global.neutral.800">
        {description}
      </Text>
    </Group>
  </Container>
);
