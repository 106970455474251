import { FinancingEligibilityStatus, RepaymentTermsIneligibilityReason } from '@melio/platform-api';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import { LinkConstants, useConfig } from '@melio/platform-provider';

export const repaymentStatusToTestId: Record<FinancingEligibilityStatus, string> = {
  [FinancingEligibilityStatus.Suspended]: 'account-suspended-financing-card',
  [FinancingEligibilityStatus.Declined]: 'account-declined-financing-card',
  [FinancingEligibilityStatus.Pending]: 'account-pending-financing-card',
  [FinancingEligibilityStatus.Eligible]: 'account-eligibile-financing-card',
  [FinancingEligibilityStatus.Ineligible]: 'account-ineligible-financing-card',
};

export const RepaymentTermsIneligibilityReasonToTestId: Record<RepaymentTermsIneligibilityReason, string> = {
  [RepaymentTermsIneligibilityReason.VendorIneligible]: 'vendor-ineligible-financing-card',
  [RepaymentTermsIneligibilityReason.InsufficientBalance]: 'insufficient-balance-financing-card',
  [RepaymentTermsIneligibilityReason.OrganizationIneligible]: 'organisation-ineligible-financing-card',
  [RepaymentTermsIneligibilityReason.PaymentIneligible]: 'payment-ineligibile-financing-card',
  [RepaymentTermsIneligibilityReason.Unknown]: 'unknown-reason-financing-card',
  [RepaymentTermsIneligibilityReason.PreEligibility]: 'pre-eligibility-reason-financing-card',
  [RepaymentTermsIneligibilityReason.UnresolvedInstallments]: 'unresolved-installments-financing-card',
};
export const getFinancingCardTestId = ({
  status,
  reason,
}: {
  status: FinancingEligibilityStatus;
  reason: RepaymentTermsIneligibilityReason | null;
}): string => {
  if (reason) {
    return RepaymentTermsIneligibilityReasonToTestId[reason];
  }

  return repaymentStatusToTestId[status];
};

const REASONS_WHITELIST: RepaymentTermsIneligibilityReason[] = [
  RepaymentTermsIneligibilityReason.UnresolvedInstallments,
  RepaymentTermsIneligibilityReason.VendorIneligible,
  RepaymentTermsIneligibilityReason.InsufficientBalance,
];

const STATUSES_WHITELIST: FinancingEligibilityStatus[] = [
  FinancingEligibilityStatus.Pending,
  FinancingEligibilityStatus.Suspended,
];

export const FALLBACK_REASON_TEXT =
  'widgets.fundingSources.selectFundingSourceCard.payWithFinancing.ineligible.reason.fallback-reason';
export const getIneligibilityText = ({
  reason,
  status,
}: {
  status: FinancingEligibilityStatus;
  reason: RepaymentTermsIneligibilityReason | null;
}) => {
  if (!reason) {
    if (!STATUSES_WHITELIST.includes(status)) {
      return FALLBACK_REASON_TEXT;
    }
    return `widgets.fundingSources.selectFundingSourceCard.payWithFinancing.ineligible.status.${status}`;
  }
  if (!REASONS_WHITELIST.includes(reason)) {
    return FALLBACK_REASON_TEXT;
  }
  return `widgets.fundingSources.selectFundingSourceCard.payWithFinancing.ineligible.reason.${reason}`;
};

const RepaymentTermsIneligibilityReasonToLearnMoreUrl: {
  [key in RepaymentTermsIneligibilityReason]?: keyof LinkConstants;
} = {
  [RepaymentTermsIneligibilityReason.VendorIneligible]:
    'widgets.fundingSources.selectFundingSourceCard.payWithFinancing.ineligible.reason.vendor-ineligible.learnMore.href',
};
const RepaymentTermsIneligibilityReasonToLearnMoreText: { [key in RepaymentTermsIneligibilityReason]?: MessageKey } = {
  [RepaymentTermsIneligibilityReason.VendorIneligible]:
    'widgets.fundingSources.selectFundingSourceCard.payWithFinancing.ineligible.reason.vendor-ineligible.learnMore',
};
export const useIneligibilityLearnMore = ({ reason }: { reason: RepaymentTermsIneligibilityReason | null }) => {
  const { formatMessage } = useMelioIntl();
  const { links } = useConfig();

  if (!reason) {
    return;
  }

  const url = RepaymentTermsIneligibilityReasonToLearnMoreUrl[reason];
  const label = RepaymentTermsIneligibilityReasonToLearnMoreText[reason];

  if (!url || !label) {
    return;
  }

  return {
    onClick: () => window.open(links[url], '_blank'),
    label: formatMessage(label),
  };
};
