import { ActionsDropdownMenuItemProps } from '@melio/penny';
import { FundingSource, useIsMemberOfAccountingFirm } from '@melio/platform-api';

import { BlockedSelectedMethodCard } from '../SmbSelectedMethodCard/BlockedSelectedMethodCard';
import { ExposedSelectedMethodCard } from '../SmbSelectedMethodCard/ExposedSelectedMethodCard';

type Props = {
  actions: ActionsDropdownMenuItemProps[];
  fundingSource?: FundingSource;
  isMethodAssignedByFirm: boolean;
};

export const SelectedMethodCard = ({ actions, fundingSource, isMethodAssignedByFirm = false }: Props) => {
  const isUserPartOfFirm = useIsMemberOfAccountingFirm();
  const shouldShowBlockedView = !isUserPartOfFirm && isMethodAssignedByFirm;

  if (shouldShowBlockedView) {
    return <BlockedSelectedMethodCard actions={actions} />;
  }

  if (fundingSource) {
    return (
      <ExposedSelectedMethodCard
        actions={actions}
        fundingSource={fundingSource}
        isMethodAssignedByFirm={isMethodAssignedByFirm}
      />
    );
  }

  return null;
};
