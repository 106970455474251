import { PartnerName } from '@melio/platform-api';

import { supportQuestions } from '@/configByPartner/supportQuestions';

export const getSupportQuestionsByIds = (ids: number[] | undefined, partnerName: PartnerName) =>
  ids
    ? supportQuestions(partnerName)
        .filter((q) => ids.includes(q.id))
        .sort((id1, id2) => ids.indexOf(id1.id) - ids.indexOf(id2.id))
    : null;
