import { AccountAddressParams, ApplyForFinancingBusinessType } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import * as yup from 'yup';
import { object, SchemaOf, string } from 'yup';

import { phoneIsValid } from '../../../utils/validation/phone.valdiation.utils';
import { FinancingApplicationBusinessDetailsForm, TAX_ID_NUMBER_REGEX } from '../types';

export const useBusinessDetailsFormValidation = () => {
  const { formatMessage } = useMelioIntl();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const addressSchema = object()
    .shape({
      line1: string().required(
        formatMessage(
          'activities.financingApplication.applicationFormScreen.businessDetails.legalBusinessAddress.validation.required'
        )
      ),
      line2: string().notRequired(),
      city: string().required(
        formatMessage(
          'activities.financingApplication.applicationFormScreen.businessDetails.legalBusinessAddress.validation.required'
        )
      ),
      state: string().required(
        formatMessage(
          'activities.financingApplication.applicationFormScreen.businessDetails.legalBusinessAddress.validation.required'
        )
      ),
      postalCode: string().required(
        formatMessage(
          'activities.financingApplication.applicationFormScreen.businessDetails.legalBusinessAddress.validation.required'
        )
      ),
    })
    .default(null)
    .required(
      formatMessage(
        'activities.financingApplication.applicationFormScreen.businessDetails.legalBusinessAddress.validation.required'
      )
    ) as SchemaOf<AccountAddressParams | null>;

  const schema = yup.object().shape({
    companyName: yup
      .string()
      .trim()
      .required(
        formatMessage(
          'activities.financingApplication.applicationFormScreen.businessDetails.companyNameField.validation.required'
        )
      ),
    legalBusinessName: yup
      .string()
      .trim()
      .required(
        formatMessage(
          'activities.financingApplication.applicationFormScreen.businessDetails.legalBusinessName.validation.required'
        )
      ),
    legalBusinessAddress: addressSchema,
    ownerPhone: yup
      .string()
      .required(
        formatMessage(
          'activities.financingApplication.applicationFormScreen.businessDetails.ownerPhone.validation.required'
        )
      )
      .test(
        'phoneIsValid',
        formatMessage(
          'activities.financingApplication.applicationFormScreen.businessDetails.ownerPhone.validation.invalid'
        ),
        phoneIsValid
      ),
    annualRevenue: yup
      .string()
      .required(
        formatMessage(
          'activities.financingApplication.applicationFormScreen.businessDetails.annualRevenue.validation.required'
        )
      ),
    businessIndustry: yup
      .object()
      .shape({
        name: yup
          .string()
          .required(
            formatMessage(
              'activities.financingApplication.applicationFormScreen.businessDetails.businessIndustry.validation.required'
            )
          ),
        naicsCode: yup.number().nullable().optional(),
      })
      .typeError(
        formatMessage(
          'activities.financingApplication.applicationFormScreen.businessDetails.businessIndustry.validation.required'
        )
      ),
    businessType: yup
      .mixed<ApplyForFinancingBusinessType>()
      .oneOf(Object.values(ApplyForFinancingBusinessType))
      .required(
        formatMessage(
          'activities.financingApplication.applicationFormScreen.businessDetails.businessType.validation.required'
        )
      ),
    employerId: yup
      .string()
      .nullable()
      .when('businessType', {
        is: (type: ApplyForFinancingBusinessType) => type !== ApplyForFinancingBusinessType.SoleProprietorship,
        then: yup
          .string()
          .matches(
            TAX_ID_NUMBER_REGEX,
            formatMessage(
              'activities.financingApplication.applicationFormScreen.businessDetails.employerId.validation.invalid'
            )
          )
          .required(),
        otherwise: yup.string().optional().nullable(),
      }),
  }) as yup.SchemaOf<FinancingApplicationBusinessDetailsForm>;

  return schema;
};
