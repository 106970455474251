import { FormattedMessage, useMelioIntl } from '@melio/ar-domain';
import { Button, FileAttachment, Group, StatusIconSolid, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';

import { GuestPaymentLayout } from '../../../layout';
import { PaymentConfirmationSummary } from '../components';

export type CardPaymentConfirmationScreenProps = {
  amount: number;
  paidAt: Date;
  invoiceNumber: string;
  paymentMethod: string;
  payee: string;
  companyName: string;
  invoicePreviewUrl: string;
  onDownloadInvoice: (fileUrl: string) => void;
  onDownloadReceipt: VoidFunction;
};

export const CardPaymentConfirmationScreen = forwardRef<CardPaymentConfirmationScreenProps>(
  (
    {
      paymentMethod,
      invoiceNumber,
      payee,
      companyName,
      amount,
      paidAt,
      invoicePreviewUrl,
      onDownloadInvoice,
      onDownloadReceipt,
    },
    ref
  ) => {
    const { formatMessage, formatCurrency, formatDate } = useMelioIntl();

    return (
      <GuestPaymentLayout data-component={CardPaymentConfirmationScreen.displayName} ref={ref}>
        <Group variant="vertical" spacing="l" hasDivider>
          <Group justifyContent="space-between">
            <Group variant="vertical" justifyContent="space-between">
              <StatusIconSolid variant="success" size="extra-large" />
              <Text textStyle="heading3Semi" color="global.neutral.1000">
                <FormattedMessage id="ar.guestPayment.successfulPayment.paymentCompleted.title" />
              </Text>
              <Group variant="vertical" spacing="xxxs">
                <Text textStyle="heading1Semi">{formatCurrency(amount)}</Text>
                <Text textStyle="body3Semi" color="global.success.700">
                  <FormattedMessage
                    id="ar.guestPayment.successfulPayment.paymentCompleted.paidAt.text"
                    values={{ date: formatDate(paidAt) }}
                  />
                </Text>
              </Group>
            </Group>
            <FileAttachment onViewModeClick={onDownloadInvoice} value={invoicePreviewUrl} isViewMode />
          </Group>
          <>
            <PaymentConfirmationSummary
              payee={payee}
              companyName={companyName}
              invoiceNumber={invoiceNumber}
              paymentMethod={paymentMethod}
            />
            <Button
              onClick={onDownloadReceipt}
              variant="tertiary"
              label={formatMessage('ar.guestPayment.successfulPayment.paymentInfo.footer.button.downloadReceipt.label')}
            />
          </>
        </Group>
      </GuestPaymentLayout>
    );
  }
);

CardPaymentConfirmationScreen.displayName = 'CardPaymentConfirmationScreen';
