import { CatalogItemsApiClient } from '@melio/platform-api-axios-client';

import { useCollection, UseCollectionProps } from '../../api-client';

export type UseCatalogItemsProps = UseCollectionProps<
  typeof CatalogItemsApiClient.getCatalogItems,
  typeof CatalogItemsApiClient.postCatalogItem,
  typeof CatalogItemsApiClient.updateCatalogItemCatalogItemId
>;

export const useCatalogItems = (props: UseCatalogItemsProps = {}) =>
  useCollection({
    ...props,
    queryKey: 'CatalogItemsApi',
    queryFn: CatalogItemsApiClient.getCatalogItems,
    deleteFn: CatalogItemsApiClient.deleteCatalogItemCatalogItemId,
    updateFn: CatalogItemsApiClient.updateCatalogItemCatalogItemId,
    createFn: CatalogItemsApiClient.postCatalogItem,
    populateModels: true,
  });

export type CatalogItemCollection = ReturnType<typeof useCatalogItems>;
