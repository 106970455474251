import { Form, FormAmountFieldProps, Switch, SwitchProps } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

type Props = {
  isSwitchOn: boolean;
  isAdminApprovalRequired: boolean;
  colSpan?: number;
  switchProps: SwitchProps;
  amountInputProps: Omit<FormAmountFieldProps, 'colSpan' | 'aria-label'>;
};
export const AdminApproval = ({
  isSwitchOn,
  isAdminApprovalRequired,
  amountInputProps,
  switchProps,
  colSpan,
}: Props) => {
  const { formatMessage } = useMelioIntl();

  return (
    <>
      <Form.ContentBox colSpan={colSpan}>
        <Switch
          label={formatMessage('activities.manageCollaborators.screens.userDetailsForm.requireApproval')}
          value={isSwitchOn}
          isDisabled={isAdminApprovalRequired}
          {...switchProps}
          size="large"
        />
      </Form.ContentBox>
      <Form.AmountField
        allowNegativeValue={false}
        isDisabled={!isSwitchOn}
        labelProps={{
          label: formatMessage(
            'activities.manageCollaborators.screens.userDetailsForm.fields.approvalAmountThreshold.label'
          ),
        }}
        colSpan={colSpan}
        {...amountInputProps}
      />
    </>
  );
};
