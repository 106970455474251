import { PlaidAccountData } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { Logger } from '@melio/platform-logger';
import { throttle } from 'lodash';
import { useCallback } from 'react';
import { PlaidLinkError, usePlaidLink } from 'react-plaid-link';

export type UsePlaidProps = {
  onSuccess: (data: PlaidAccountData) => void;
  onExit?: VoidFunction;
  onLoad?: VoidFunction;
  onError?: ErrorFunction;
  onEvent: (data: PlaidEventData) => void;
  plaidToken: string;
};

export type PlaidEventData = { pageName: string; action: 'View' | 'Click'; properties?: Record<string, string> };

const PageNameMapper: { [key: string]: string } = {
  ['CONSENT']: 'melio-uses-plaid-to-connect-your-account',
  ['SELECT_AUTH_TYPE']: 'choose-how-youll-link-your-bank-account',
  ['NUMBERS']: 'bank-account-details',
  ['SELECT_INSTITUTION']: 'select-your-institution',
  ['OATH']: 'sign-in',
  ['SELECT_ACCOUNT']: 'select-account',
  ['CONNECTED']: 'plaid-account-connected',
  ['TOO_MANY_VERIFICATION_ATTEMPTS']: 'too-many-verification-attempts',
  ['ERROR']: 'plaid-error-page',
  ['EXIT']: 'are-you-sure',
};

export const usePlaid = ({ onError, onSuccess, onLoad, onExit: _onExit, onEvent, plaidToken }: UsePlaidProps) => {
  const { formatMessage } = useMelioIntl();

  // plaid sends duplicate error events we need to throttle in order to not get multiple toast messages
  // XXX standardize error codes and messages https://linear.app/meliopayments/issue/PLA-905
  const handleError = throttle(
    useCallback(
      ({
        error_message: errorMessage,
        error_code: errorCode,
      }: {
        error_message: string | null;
        error_code: string | null;
      }) => {
        if (!errorMessage) {
          return onError?.({ message: '', code: '500' });
        }
        if (errorCode === 'TOO_MANY_VERIFICATION_ATTEMPTS') {
          return onError?.({
            message: formatMessage('activities.microDepositsVerification.screens.exceedingFailure.title'),
            code: '409',
          });
        }
        return onError?.({ message: errorMessage || '', code: '500' });
      },
      [onError, formatMessage]
    ),
    100,
    { trailing: false }
  );

  const onExit = throttle(
    (data?: PlaidLinkError | null) => {
      if (data?.error_code === 'TOO_MANY_VERIFICATION_ATTEMPTS') {
        onEvent?.({ action: 'View', pageName: PageNameMapper['TOO_MANY_VERIFICATION_ATTEMPTS'] || '' });
        return handleError(data);
      }
      return _onExit?.();
    },
    100,
    { trailing: false }
  );

  return usePlaidLink({
    token: plaidToken,
    onLoad,
    onExit,
    onSuccess: (public_token, metadata) => onSuccess({ public_token, ...metadata } as PlaidAccountData),
    onEvent: (name, data) => {
      if (name === 'ERROR' || data.error_code) {
        //this case is already handled in line 92
        if (data?.error_code !== 'TOO_MANY_VERIFICATION_ATTEMPTS') {
          handleError(data);
        }
      }
      const pageNameForAnalytics = PageNameMapper[data.view_name || ''];
      Logger.log(
        `Event Received from Plaid. Name: ${name}. plaid view_name: ${data.view_name || 'unknown'}. Melio Page name: ${
          pageNameForAnalytics || 'unknown'
        }, event metadata: ${JSON.stringify(data)}`
      );
      if (name === 'TRANSITION_VIEW') {
        if (pageNameForAnalytics) {
          onEvent?.({ action: 'View', pageName: PageNameMapper[data.view_name || ''] || data.view_name || '' });
        }
      }
      if (name === 'EXIT') {
        onEvent?.({ action: 'Click', pageName: PageNameMapper['EXIT'] || '', properties: { Cta: 'exit' } });
      }
    },
  });
};
