import { Modal } from '@melio/penny';

export type NotifyLoadingModalScreenProps = {
  isOpen: boolean;
  onClose: VoidFunction;
};

// TODO: https://meliorisk.atlassian.net/browse/ME-358 update the modal loading state according to design
export const NotifyLoadingModalScreen: React.VFC<NotifyLoadingModalScreenProps> = ({ isOpen, onClose }) => (
  <Modal
    // TODO: add modal missing header
    // https://meliorisk.atlassian.net/browse/ME-57684
    header=""
    isOpen={isOpen}
    onClose={onClose}
    data-testid="payment-scheduled-notify-vendors-modal-activity-notify-loading-modal-screen"
    isLoading
  >
    {}
  </Modal>
);

NotifyLoadingModalScreen.displayName = 'PaymentScheduledNotifyVendorModalActivity.NotifyLoadingModalScreen';
