import { _createFormFieldInput, Search, SearchOption, SearchProps, useToast } from '@melio/penny';
import { Address, formatAddress } from '@melio/platform-api';
import { Logger } from '@melio/platform-logger';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import { useState } from 'react';

import { useAddressAutocomplete } from './useAddressAutocomplete';

export type AddressSearchWidgetProps = Omit<SearchProps, 'options' | 'emptyState'> & {
  emptyStateOverride?: (searchTerm: string) => SearchProps['emptyState'];
};

export const AddressSearchWidget = _createFormFieldInput(
  forwardRef<AddressSearchWidgetProps, 'input'>(({ value, isViewMode, emptyStateOverride, ...props }, ref) => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const { addressAutocomplete } = useAddressAutocomplete();
    const { toast } = useToast();
    const { formatMessage } = useMelioIntl();
    const [options, setOptions] = useState<SearchOption[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const loadOptions = (searchTerm: string): void => {
      setIsLoading(true);
      setSearchTerm(searchTerm);
      addressAutocomplete(searchTerm)
        .then((results) => {
          setOptions(
            results.map((option) => ({ value: option, label: formatAddress(option) })) as unknown as SearchOption[]
          );
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error('addressAutocomplete failed', err);
          Logger.handleException(err);

          toast({ type: 'error', title: formatMessage('form.addressSearch.searchError') });

          setOptions([]);
        })
        .finally(() => setIsLoading(false));
    };
    const emptyStateOverrideObj = emptyStateOverride?.(searchTerm) || {};

    return (
      <Search
        data-component="AddressSearchWidget"
        ref={ref}
        options={options}
        onInputChange={loadOptions}
        emptyState={{ label: formatMessage('form.addressSearch.emptyState'), ...emptyStateOverrideObj }}
        isViewMode={isViewMode}
        isLoading={isLoading}
        value={value}
        formatSelectedValue={(data) => (data.value ? formatAddress(data.value as unknown as Address) : data.value)}
        filterOptions={(options) => options}
        {...props}
      />
    );
  })
);

AddressSearchWidget.displayName = 'AddressSearchWidget';
