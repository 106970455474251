import { Form, useMelioForm } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { defaults } from 'lodash';
import { object, SchemaOf, string } from 'yup';

import { GoodsReceivedFormProps, GoodsReceivedFormWidgetFields } from './types';

const schema = object().shape({
  goodsReceivedRadioValue: string()
    .default('yes')
    .oneOf(Object.values(['yes', 'no']))
    .required(),
}) as SchemaOf<GoodsReceivedFormWidgetFields>;

export const GoodsReceivedFormWidget = forwardRef<GoodsReceivedFormProps, 'form'>(
  ({ defaultValues: _defaultValues, onSubmit, onSubmissionStateChange, isSaving, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const defaultValues = defaults(_defaultValues, {
      goodsReceivedRadioValue: 'yes',
    });

    const { formProps, registerField } = useMelioForm<GoodsReceivedFormWidgetFields>({
      onSubmit,
      schema,
      defaultValues,
      isSaving,
      onSubmissionStateChange,
    });

    return (
      <Form data-component="GoodsReceivedForm" {...props} {...formProps} ref={ref}>
        <Form.RadioGroup
          aria-label="goods received"
          variant="horizontal"
          options={[
            {
              mainLabelProps: { label: formatMessage('widgets.goodsReceivedForm.yes.label') },
              value: 'yes',
            },
            {
              mainLabelProps: { label: formatMessage('widgets.goodsReceivedForm.no.label') },
              value: 'no',
            },
          ]}
          {...registerField('goodsReceivedRadioValue')}
        />
      </Form>
    );
  }
);

GoodsReceivedFormWidget.displayName = 'GoodsReceivedForm';
