import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-provider';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';
import { PaymentFlowDoneAction } from '../../../../types';

type PaymentScheduledActionProps = {
  onDone: (action: PaymentFlowDoneAction) => void;
  showResolvePaymentsButton: boolean;
};

export const PaymentScheduledAction = ({ onDone, showResolvePaymentsButton }: PaymentScheduledActionProps) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();

  return (
    <>
      <NewSinglePaymentStepLayout.NextButton
        onClick={() => {
          track('Payment', 'Click', {
            PageName: 'payment-scheduled',
            Intent: 'go-to-dashboard',
            Cta: 'go-to-dashboard',
          });
          onDone('goToDashboard');
        }}
        size="medium"
        variant={showResolvePaymentsButton ? 'secondary' : 'primary'}
        label={formatMessage('activities.paymentScheduled.screens.paymentScheduled.continue')}
      />
      {showResolvePaymentsButton && (
        <NewSinglePaymentStepLayout.NextButton
          onClick={() => {
            track('Payment', 'Click', {
              PageName: 'payment-scheduled',
              Intent: 'resolve-failed-payments',
              Cta: 'resolve-failed-payments',
            });
            onDone('resolveFailedPayments');
          }}
          size="medium"
          label={formatMessage(
            'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentSchedulingFailure.cta.multiplePayments'
          )}
        />
      )}
    </>
  );
};
