import { useIndustries, useOnboarding } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';

import { useOnboardingStepsTracker } from '../../components';
import { BusinessDetailsScreen } from './screens';
import { BusinessDetailsFormFields } from './types';

type BusinessDetailsActivityProps = {
  onDone: VoidFunction;
  onError?: ARErrorFunction;
  onBack: VoidFunction;
  onClose?: VoidFunction;
};

export const BusinessDetailsActivity = forwardRef(
  ({ onDone, onBack, onError, ...props }: BusinessDetailsActivityProps, ref) => {
    const { steps, currentStepIndex, goToStepById } = useOnboardingStepsTracker();

    useEffect(() => {
      goToStepById('business-details');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStepIndex]);

    const { data: industries, isLoading: isIndustriesLoading, isMutating: isIndustriesMutating } = useIndustries();
    const {
      isLoading: isOnboardingDataLoading,
      isMutating: isOnboardingDataMutating,
      update,
    } = useOnboarding({ enabled: false });

    const handleSubmit = async (data: BusinessDetailsFormFields) => {
      const businessDetailsRequest = {
        legalName: data.legalName,
        irsLegalName: data.isBusinessNameConsistent ? data.legalName : data.irsLegalName,
        legalAddress: data.legalAddress,
        deliveryAddress: data.isDeliveryAddressConsistent ? data.legalAddress : data.deliveryAddress,
        email: data.email,
        dateIncorporated: data.dateIncorporated.toISOString().split('T')[0],
        websiteUrl: data.websiteUrl,
        merchantCategoryCode: data.industrySubCategory,
        businessStructure: data.businessStructure,
        contactNumber: data.contactNumber,
      };
      await update({ businessDetails: businessDetailsRequest })
        .then(() => {
          onDone();
        })
        .catch(onError);
    };

    return (
      <BusinessDetailsScreen
        isLoading={isIndustriesLoading || isOnboardingDataLoading}
        defaultValues={{
          isBusinessNameConsistent: true,
          isDeliveryAddressConsistent: true,
        }}
        industries={industries || []}
        steps={steps}
        currentStepIndex={currentStepIndex}
        onSubmit={handleSubmit}
        isSaving={isIndustriesMutating || isOnboardingDataMutating}
        onBack={onBack}
        {...props}
        ref={ref}
      />
    );
  }
);

BusinessDetailsActivity.displayName = 'BusinessDetailsActivity';
