import { Box } from '@chakra-ui/react';
import { ControlProps, Form, Group, Typography } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-provider';
import { useEffect, useRef } from 'react';

type Props = ControlProps & {
  onClick?: VoidFunction;
};

export const SearchToggle = ({ name, control, onClick }: Props) => {
  const { formatMessage } = useMelioIntl();
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleFocusIn = (event: FocusEvent) => {
      if (ref?.current?.contains(event.target as Node)) {
        event.preventDefault();
        ref.current.blur();
        // fix for Android /w Talkback. We should leave this handler triggering here and in Box below
        // to make it work on both Android and iOS
        onClick?.();
      }
    };

    document.addEventListener('focusin', handleFocusIn);
    return () => {
      document.removeEventListener('focusin', handleFocusIn);
    };
  }, [onClick]);

  const onClickContainer = (e: React.MouseEvent) => {
    //need to do that because the button click create a form validation test and show error on inputs that were not yet changed
    e.preventDefault();
    e.stopPropagation();
    onClick?.();
  };

  return (
    <Group variant="vertical" spacing="xxs">
      <Typography.Label label={formatMessage('widgets.businessSearch.label')} />
      <Box
        as="button"
        cursor="pointer"
        onClick={onClickContainer}
        aria-label={formatMessage('widgets.businessSearch.placeholder')}
      >
        <Form.Search
          ref={ref}
          data-component="BusinessSearchWidgetToggle"
          options={[]}
          emptyState={{ label: '' }}
          placeholder={formatMessage('widgets.businessSearch.placeholder')}
          name={name}
          control={control}
          aria-hidden
        />
      </Box>
    </Group>
  );
};
