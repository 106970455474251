import { ApiError, useMelioQueryClient, useModelApi, UseModelApiProps } from '@melio/api-client';
import {
  AutoPayment,
  PatchVendorAutoPaymentRequest,
  PostVendorAutoPaymentRequest,
  VendorsApiClient,
} from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useMutation } from 'react-query';

import {
  convertAutoPaymentResponseCentsToDollars,
  prepareCreateAutoPaymentParams,
  prepareUpdateAutoPaymentParams,
} from './utils';

type UseAutoPaymentModelType = UseModelApiProps<typeof VendorsApiClient.getAutoPayment, AutoPayment>;

export type UseAutoPaymentPropsType = UseAutoPaymentModelType & {
  vendorId: string;
  options?: {
    onCreate?: (autoPayment: AutoPayment) => void;
    onCreateError?: (error: ApiError<string>) => void;
    onUpdate?: (autoPayment: AutoPayment) => void;
    onUpdateError?: (error: ApiError<string>) => void;
    onDelete?: VoidFunction;
    onDeleteError?: VoidFunction;
  };
};

export const useAutoPayment = ({ vendorId, options, ...props }: UseAutoPaymentPropsType) => {
  const queryClient = useMelioQueryClient();

  const query = useModelApi({
    ...props,
    id: vendorId,
    queryKey: ['AutoPayApi', vendorId],
    queryFn: () => VendorsApiClient.getAutoPayment(vendorId),
    createFn: (data: PostVendorAutoPaymentRequest) => VendorsApiClient.postAutoPayment(data, vendorId),
    prepareCreateParams: prepareCreateAutoPaymentParams,
    select: convertAutoPaymentResponseCentsToDollars,
    onCreate: (autoPayment) => {
      void queryClient.invalidateQueries('VendorsApi');
      void queryClient.invalidateQueries('AutoPayApi');
      options?.onCreate?.(autoPayment);
    },
    onCreateError: options?.onCreateError,
    enabled: props.enabled ?? false,
  });

  const updateAutoPayMutation = useMutation(
    query.queryKey,
    (data: PatchVendorAutoPaymentRequest) =>
      VendorsApiClient.patchAutoPayment(prepareUpdateAutoPaymentParams(data), vendorId),
    {
      onSuccess: (autoPayment) => {
        const mappedAutoPayment = convertAutoPaymentResponseCentsToDollars(autoPayment.data.data);
        void queryClient.invalidateQueries('VendorsApi');
        void queryClient.invalidateQueries('AutoPayApi');
        options?.onUpdate?.(mappedAutoPayment);
      },
      onError: options?.onUpdateError,
    }
  );

  const deleteAutoPayMutation = useMutation(query.queryKey, () => VendorsApiClient.deleteAutoPayment(vendorId), {
    onSuccess: () => {
      void queryClient.invalidateQueries('VendorsApi');
      void queryClient.invalidateQueries('AutoPayApi');
      options?.onDelete?.();
    },
    onError: options?.onDeleteError,
  });

  return {
    ...query,
    deleteAutoPayment: deleteAutoPayMutation.mutate,
    updateAutoPayment: updateAutoPayMutation.mutate,
  };
};
