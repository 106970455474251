import { useAnalytics } from '@melio/platform-analytics';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { MessageKey } from '@melio/platform-i18n';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ContextualOnboardingScreen } from './screens';

export type ContextualOnboardingActivityProps = {
  onQuickBooksOnlineClick: ({ errorMessageKey }: { errorMessageKey: MessageKey }) => Promise<void>;
  onGmailSyncClick?: () => Promise<void>;
  onFirstPaymentClick: () => string;
  onExitClick: VoidFunction;
};
export const ContextualOnboardingActivity = ({
  onQuickBooksOnlineClick,
  onGmailSyncClick,
  onFirstPaymentClick,

  onExitClick,
}: ContextualOnboardingActivityProps) => {
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const [isContextualOnboardingGmailSyncEnabled] = useFeature(
    FeatureFlags.ContextualOnboardingGmailSyncEnabled,
    false,
    { shouldTrack: true }
  );

  const onFirstPaymentClickWithAnalytics = useCallback(() => {
    track('Dashboard', 'Click', {
      Cta: 'make-a-payment',
      PageName: 'onboarding',
      Flow: 'just-pay',
      Intent: 'create-a-payment',
    });
    navigate(onFirstPaymentClick());
  }, [onFirstPaymentClick, track, navigate]);

  const onExitClickWithAnalytics = useCallback(
    (isClose) => {
      track('Dashboard', 'Click', {
        Cta: isClose ? 'close' : 'skip-for-now',
        PageName: 'onboarding',
        Flow: 'dashboard',
        Intent: 'skip-onboarding',
      });
      onExitClick();
    },
    [onExitClick, track]
  );

  const onQuickBooksOnlineClickWithAnalytics = useCallback(async () => {
    track('Dashboard', 'Click', {
      Cta: 'connect-your-quickbooks-online',
      PageName: 'onboarding',
      Flow: 'accounting-software-connection',
      Intent: 'connect-accounting-software',
      AccountingSoftwareType: 'quickbooks-online',
    });
    await onQuickBooksOnlineClick({
      errorMessageKey: 'screens.contextualOnboarding.quickbooksButton.error',
    });
  }, [onQuickBooksOnlineClick, track]);

  const onGmailSyncClickWithAnalytics =
    isContextualOnboardingGmailSyncEnabled && onGmailSyncClick
      ? async () => {
          track('Dashboard', 'Click', {
            Cta: 'import-from-your-gmail',
            PageName: 'onboarding',
            Flow: 'bill-provider-connection',
            Intent: 'connect-bill-provider',
            BillProviderType: 'gmail',
          });
          await onGmailSyncClick();
        }
      : undefined;

  useEffect(() => {
    track('Dashboard', 'View', {
      PageName: 'onboarding',
      Flow: 'onboarding',
      Intent: 'onboarding',
      CountAll: 0,
    });
  }, [track]);

  return (
    <ContextualOnboardingScreen
      onQuickBooksOnlineClick={onQuickBooksOnlineClickWithAnalytics}
      onGmailSyncClick={onGmailSyncClickWithAnalytics}
      onFirstPaymentClick={onFirstPaymentClickWithAnalytics}
      onExitClick={onExitClickWithAnalytics}
    />
  );
};
