import { FilesApiClient } from '@melio/platform-api-axios-client';

import { useModel, UseModelProps } from '../../api-client';

export type UseFileProps = UseModelProps<typeof FilesApiClient.getFilesFileId>;

export const useFile = (props: UseFileProps = {}) =>
  useModel({
    ...props,
    queryKey: 'FilesApi',
    queryFn: FilesApiClient.getFilesFileId,
    createFn: FilesApiClient.postFiles,
  });

export type FileModel = ReturnType<typeof useFile>;
