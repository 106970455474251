import { Img } from '@chakra-ui/react';
import { Card, Drawer, Group, IconButton, Text } from '@melio/penny';
import { PayorPaymentRequestDetailsInvoice } from '@melio/platform-api';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';

export type InvoiceDrawerScreenProps = {
  isOpen: boolean;
  isLoading: boolean;
  onCloseButtonClick: VoidFunction;
  onClose: VoidFunction;
  invoicePaymentRequestDetails?: Pick<PayorPaymentRequestDetailsInvoice, 'fileInfo' | 'invoiceNumber'>;
  onDownloadInvoice: VoidFunction;
  previewUrl?: string;
  invoiceNumber?: string;
};

export const InvoiceDrawerScreen = forwardRef<InvoiceDrawerScreenProps>(
  (
    { onCloseButtonClick, isLoading, onDownloadInvoice, isOpen, onClose: onCloseComplete, previewUrl, invoiceNumber },
    ref
  ) => (
    <Drawer
      ref={ref}
      data-testid="invoice-drawer-screen"
      isOpen={isOpen}
      onClose={onCloseButtonClick}
      onCloseComplete={onCloseComplete}
      isLoading={isLoading}
      body={
        <Card paddingY="none" paddingX="none">
          <Img width="100%" src={previewUrl} data-testid="invoice-image" />
        </Card>
      }
      header={
        <Group justifyContent="space-between" ref={ref}>
          <Text data-testid="drawer-header-invoice-number" textStyle="heading3Semi">
            {invoiceNumber}
          </Text>
          <IconButton
            data-testid="download-button"
            aria-label="download"
            variant="naked"
            size="medium"
            icon="download"
            onClick={onDownloadInvoice}
          />
        </Group>
      }
    />
  )
);
InvoiceDrawerScreen.displayName = 'InvoiceDrawerScreen';
