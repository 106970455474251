import {
  InboxItemsSortQueryParam,
  InboxItemsSortQueryParamFieldEnum,
  Payment,
  PaymentsSortQueryParam,
  PaymentsSortQueryParamFieldEnum,
} from '@melio/platform-api';

import { SortableHeaderCell } from '../components/SortableHeaderCell';

export const getSortableHeaderCell = ({
  activeSort,
  field,
  label,
  dataTestId,
}: {
  activeSort?: PaymentsSortQueryParam[] | InboxItemsSortQueryParam[];
  label: string;
  field: PaymentsSortQueryParamFieldEnum | InboxItemsSortQueryParamFieldEnum;
  dataTestId: string;
}) => {
  if (activeSort?.[0]?.field === field) {
    return <SortableHeaderCell label={label} order={activeSort[0].order} dataTestId={dataTestId} />;
  }
  return label;
};

export const sortPaymentsByUpdatedAt = (payment: Payment) => payment.history.updatedAt;
export const sortPaymentsByCreatedAt = (payment: Payment) => payment.history.createdAt;
