/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { CheckProtectionModalWidget } from '@melio/ap-widgets';
import { NakedButton } from '@melio/penny';
import { DeliveryMethod, DeliveryMethodType, FeeType, PaperCheck, Vendor } from '@melio/platform-api';

import { useRouter } from '@/hooks/router.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { BasicDeliveryMethodCardProps } from '@/types/deliveryMethod.types';
import { DeliveryMethodIconsEnum } from '@/types/vendors.types';
import { Fee } from '@/widgets/Fee/Fee.widget';
import { DeliveryMethodCard } from '@/widgets/vendorsDashboard/DeliveryMethod/components/DeliveryMethodCard.component';
import { usePaperCheckDescription } from './hooks/usePaperCheckDescription';

export interface CheckDeliveryMethodProps extends BasicDeliveryMethodCardProps {
  vendor: Vendor;
  check: DeliveryMethod | null;
}

export const PaperCheckDeliveryMethodCard = ({ vendor, check, navigationStateParams }: CheckDeliveryMethodProps) => {
  const { formatMessage, formatDate } = usePlatformIntl();
  const { generateVendorPaperCheckLink } = useRouter();
  const [checkAddress, setCheckAddress] = useState<string | null>(null);
  const { description } = usePaperCheckDescription();

  useEffect(() => {
    if (check) {
      const address = (check.details as PaperCheck).address;
      const addressString = formatMessage('widgets.addOrUpdateDeliveryMethod.check.address', {
        addressLine1: address.line1,
        addressLine2: address.line2 ? ' ' + address.line2 : '',
        addressCity: address.city,
        addressPostalCode: address.postalCode,
        addressState: address.state,
      });
      setCheckAddress(addressString);
    }
  }, [vendor, check]);

  const info1 = check
    ? formatMessage('widgets.addOrUpdateDeliveryMethod.check.info.info1', {
        printName: (check.details as PaperCheck).printName,
        checkAddress: checkAddress,
      })
    : undefined;

  const info2 = check
    ? formatMessage('widgets.addOrUpdateDeliveryMethod.check.info.info2', {
        updatedAt: formatDate(check.history.updatedAt),
      })
    : undefined;

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <CheckProtectionModalWidget isOpen={isOpen} onClose={onClose} />

      <DeliveryMethodCard
        type={DeliveryMethodType.PaperCheck}
        deliveryMethodExists={check !== null}
        navigationParams={generateVendorPaperCheckLink(navigationStateParams)}
        iconType={DeliveryMethodIconsEnum.PAPER_CHECK}
        displayName={formatMessage('widgets.addOrUpdateDeliveryMethod.check.title')}
        helperText={description}
        info={info1 && info2 ? { info1, info2 } : undefined}
        caption={formatMessage('widgets.addOrUpdateDeliveryMethod.check.comment', {
          modal: (
            <NakedButton
              variant="secondary"
              onClick={onOpen}
              data-testid="widgets-addOrUpdateDeliveryMethod-check-caption-button"
              label={formatMessage('widgets.addOrUpdateDeliveryMethod.check.captionWithModalText')}
              aria-label={formatMessage('widgets.addOrUpdateDeliveryMethod.check.captionWithModalText.ariaLabel')}
            />
          ),
        })}
        addMethodAriaLabel={formatMessage('widgets.addOrUpdateDeliveryMethod.check.add.ariaLabel', {
          fee: <Fee feeType={FeeType.Check} />,
        })}
      />
    </>
  );
};
