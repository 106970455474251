import addBusinessDaysFns from 'date-fns/addBusinessDays';

import { isHoliday } from './isHoliday';

export function addBusinessDays(date: Date, days: number): Date {
  let daysToAdd = days;
  let currentDate = date;
  while (daysToAdd) {
    const advancedDate = addBusinessDaysFns(currentDate, 1);
    if (!isHoliday(advancedDate)) {
      daysToAdd--;
    }
    currentDate = advancedDate;
  }

  return currentDate;
}
