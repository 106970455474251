import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';

export type RepaymentTermsLoadingScreenProps = {
  onBack?: VoidFunction;
  onClose?: VoidFunction;
};

export const RepaymentTermsLoadingScreen: React.VFC<RepaymentTermsLoadingScreenProps> = ({
  onBack,
  onClose,
  ...props
}) => (
  <NewSinglePaymentStepLayout
    data-component="RepaymentTermsActivity.RepaymentTermsLoadingScreen"
    data-testid="repayment-terms-activity-repayment-terms-loading-screen"
    {...props}
    isLoading
  />
);

RepaymentTermsLoadingScreen.displayName = 'RepaymentTermsActivity.RepaymentTermsLoadingScreen';
