import {
  FundingSourcesVerifyRequestMicroDeposits,
  PostFundingSourcesVerifyRequest,
} from '@melio/platform-api-axios-client';
import { convertDollarsToCents } from '@melio/platform-utils';

export const convertVerifyParamsDollarsToCents = (params: FundingSourcesVerifyRequestMicroDeposits) =>
  convertDollarsToCents(params.details, ['amount1', 'amount2']);

export const isMicroDepositsRequest = (
  params: PostFundingSourcesVerifyRequest
): params is FundingSourcesVerifyRequestMicroDeposits => params.type === 'micro-deposits';
