import { ComponentThemeType } from '@/cl/theme/theme.hooks';

export const DemoLoginAccountUrlTheme: ComponentThemeType = () => ({
  baseStyle: {
    container: {
      display: 'flex',
      flexDir: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      p: '8px',
      position: 'absolute',
      right: '5px',
      border: '1px solid #D8D8D8D8',
      borderRadius: '8px',
    },
    closeContainer: {
      position: 'absolute',
      right: '4px',
      width: 'full',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
});
