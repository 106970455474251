/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { SyncFlowEnum } from '@melio/ap-activities';
import { useAccountingPlatformName } from '@melio/ap-widgets';
import {
  AccountingPlatformSlug,
  useAccountingPlatform,
  useAccountingPlatforms,
  useAccountingPlatformSync as useAccountingPlatformSyncStatus,
} from '@melio/platform-api';
import {
  AccountingPlatformSyncStatus,
  useAccountingPlatformSync as useAccountingPlatformSyncAPI,
} from '@melio/platform-api';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils';
import { useSubscriptionFeature } from '@melio/subscriptions';

import { authApiGetIntuitConnectLink } from '@/api/auth.api';
import { APP_EVENTS, emitAppEvent } from '@/queries/event-emitter-query';
import { usePlatformIntl } from '@/translations/Intl';
import { getQboAccountingId } from '@/utils/accountingPlatform.utils';
import { getPartnerUrlPrefixFromUrl } from '@/utils/partner.utils';

export const useAccountingPlatformDisconnect = ({
  accountingPlatformId,
  accountingSlug,
  organizationId,
}: {
  accountingPlatformId?: string;
  accountingSlug?: AccountingPlatformSlug;
  organizationId: string;
}) => {
  const { showMessage } = useSystemMessage();
  const { formatMessage } = usePlatformIntl();
  const { disconnect, isMutating } = useAccountingPlatform({ id: accountingPlatformId, enabled: false });
  const accountingPlatformName = useAccountingPlatformName(accountingSlug);

  const disconnectAccountingPlatform = useCallback(async () => {
    try {
      await disconnect(organizationId);
      showMessage({
        type: 'informative',
        title: formatMessage('widgets.accountingPlatform.disconnect.disconnectSuccess', {
          accountingPlatformName: accountingPlatformName,
        }),
      });
    } catch (error) {
      showMessage({
        type: 'error',
        title: formatMessage('widgets.accountingPlatform.disconnect.disconnectError'),
      });
    }
  }, [disconnect, organizationId]);

  return {
    isLoading: isMutating,
    disconnectAccountingPlatform,
  };
};

export const useAccountingPlatformConnect = ({
  flowToReturn,
  accountingPlatformId,
}: {
  flowToReturn: SyncFlowEnum;
  accountingPlatformId?: string;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { showMessage } = useSystemMessage();
  const { formatMessage } = usePlatformIntl();
  const pathPrefix = getPartnerUrlPrefixFromUrl();
  const { generateAuthLink } = useAccountingPlatform({ id: accountingPlatformId, enabled: false });

  const connectToAccountingPlatform = useCallback(async () => {
    setIsLoading(true);

    const redirectUrl = `${window.location.origin}${pathPrefix}/accounting-software/auth/callback`;
    const state = window.btoa(
      JSON.stringify({
        flow: flowToReturn,
        accountingPlatformId,
      }),
    );

    try {
      const { link } = await generateAuthLink({
        redirectUrl,
        state,
      });

      window.location.href = link;
    } catch (error) {
      showMessage({
        type: 'error',
        title: formatMessage('widgets.accountingPlatform.connect.generateAuthLinkError'),
      });
    }
  }, [flowToReturn, pathPrefix, generateAuthLink]);

  return {
    isLoading,
    connectToAccountingPlatform,
  };
};

export const useQuickBooksDesktopConnect = ({
  flowToReturn,
  accountingPlatformId,
}: {
  flowToReturn: SyncFlowEnum;
  accountingPlatformId?: string;
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { tryUseFeature: tryUseConnectQbdtFeature } = useSubscriptionFeature({ featureName: 'accountingPlatformQbdt' });
  const pathPrefix = getPartnerUrlPrefixFromUrl();

  const connectToAccountingPlatform = useCallback(async () => {
    setIsLoading(true);
    tryUseConnectQbdtFeature({
      onFeatureIsEligible: () => {
        const state = window.btoa(
          JSON.stringify({
            flow: flowToReturn,
            accountingPlatformId,
            isQuickBooksDesktop: true,
          }),
        );
        const redirectUrl = `/accounting-software/auth/callback?state=${state}`;
        navigate(redirectUrl);
      },
      onFeatureIsBlocked: () => {
        setIsLoading(false);
      },
    });
  }, [flowToReturn, pathPrefix]);

  return {
    isLoading,
    connectToAccountingPlatform,
  };
};

type RedirectMethod = 'push' | 'replace';

const redirectHandlers: Record<RedirectMethod, (link: string) => void> = {
  replace: (link) => window.location.replace(link),
  push: (link) => (window.location.href = link),
};

export const useIntuitConnect = (
  flowToReturn: SyncFlowEnum,
  accountingPlatformId?: string,
  options?: { redirectMethod?: RedirectMethod; isAccountant?: boolean },
) => {
  const [isLoading, setIsLoading] = useState(false);
  const pathPrefix = getPartnerUrlPrefixFromUrl();

  const state = encodeURIComponent(
    JSON.stringify({
      flow: flowToReturn,
      accountingPlatformId,
    }),
  );
  const errorReturnUrl = encodeURIComponent(
    `${window.location.origin}${pathPrefix}/auth/providers/intuit/callback/error?state=${state}`,
  );
  const successReturnUrl = encodeURIComponent(
    `${window.location.origin}${pathPrefix}/auth/providers/intuit/callback/success?state=${state}`,
  );

  const loginToQuickBooks = useCallback(async () => {
    setIsLoading(true);

    const { token, link, loginWithTokenLink } = await authApiGetIntuitConnectLink({
      successReturnUrl,
      errorReturnUrl,
      isAccountant: !!options?.isAccountant,
    });
    await axios.post(
      loginWithTokenLink,
      {
        token,
      },
      {
        withCredentials: true,
      },
    );
    //because we use the redirect here we don't need to setIsLoading to false because we want that the loading will continue until the redirect is done (and then once the user return or click on the back button the sate will initial)
    redirectHandlers[options?.redirectMethod || 'push'](link);
  }, [errorReturnUrl, successReturnUrl]);

  return {
    loginToQuickBooks,
    isLoading,
  };
};

export const useAccountingPlatformSync = ({
  accountingPlatformId,
  onSyncDone,
  onSyncError,
}: {
  accountingPlatformId: string;
  onSyncDone?: VoidFunction;
  onSyncError?: VoidFunction;
}) => {
  const syncStatusRef = useRef<{
    intervalCallbackNumber: number | null;
    isFetching: boolean;
    shouldRefetchData: boolean;
  }>({
    isFetching: false,
    intervalCallbackNumber: null,
    shouldRefetchData: false,
  });
  const [isSyncStatusLoading, setIsSyncStatusLoading] = useState(false);
  const {
    data: syncStatusData,
    isLoading: isSyncStatusApiLoading,
    refetch,
  } = useAccountingPlatformSyncStatus({ id: accountingPlatformId });
  const { sync } = useAccountingPlatformSyncAPI({ id: accountingPlatformId });

  useEffect(() => {
    syncStatusRef.current.isFetching = isSyncStatusApiLoading;
  }, [isSyncStatusApiLoading]);

  useEffect(() => {
    if (!isSyncStatusApiLoading) {
      if (syncStatusData?.syncStatus === AccountingPlatformSyncStatus.Running) {
        setIsSyncStatusLoading(true);
        syncStatusRef.current.shouldRefetchData = true;
      } else {
        setIsSyncStatusLoading(false);
      }
      if (syncStatusData) {
        if (syncStatusData?.syncStatus === AccountingPlatformSyncStatus.Running) {
          if (!syncStatusRef.current.intervalCallbackNumber) {
            syncStatusRef.current.intervalCallbackNumber = window.setInterval(() => {
              if (!syncStatusRef.current.isFetching) {
                refetch();
              }
            }, 1000);
          }
        } else {
          if (syncStatusRef.current.intervalCallbackNumber) {
            window.clearInterval(syncStatusRef.current.intervalCallbackNumber);
            syncStatusRef.current.intervalCallbackNumber = null;
          }
          if (
            syncStatusData.syncStatus === AccountingPlatformSyncStatus.Done &&
            syncStatusRef.current.shouldRefetchData
          ) {
            syncStatusRef.current.shouldRefetchData = false;
            emitAppEvent(APP_EVENTS.ACCOUNTING_PLATFORM_SYNC_STATUS_DONE, {
              accountingPlatformId,
            });
            onSyncDone?.();
          }
          if (
            onSyncError &&
            syncStatusData.syncStatus === AccountingPlatformSyncStatus.Error &&
            syncStatusRef.current.shouldRefetchData
          ) {
            onSyncError();
          }
        }
      }
    }
  }, [syncStatusData, isSyncStatusApiLoading]);

  const triggerSync = useCallback(() => {
    setIsSyncStatusLoading(true);
    sync().then(() => {
      syncStatusRef.current.shouldRefetchData = true;
    });
  }, [setIsSyncStatusLoading, sync, syncStatusData]);

  return {
    triggerSync,
    isSyncStatusApiLoading,
    isSyncStatusLoading,
    triggerStatusSync: refetch,
  };
};

export const usePaymentFlowIntuitConnect = (options?: {
  redirectMethod?: RedirectMethod;
  flowToReturn?: SyncFlowEnum;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { data: accountingPlatformData } = useAccountingPlatforms();
  const qboAccountingId = getQboAccountingId(accountingPlatformData);
  const { loginToQuickBooks } = useIntuitConnect(options?.flowToReturn || SyncFlowEnum.PayBoard, qboAccountingId, {
    redirectMethod: options?.redirectMethod,
  });
  const { formatMessage } = useMelioIntl();
  const { showMessage } = useSystemMessage();

  const loginToAccountPlatformAuth = async () => {
    try {
      setIsLoading(true);
      await loginToQuickBooks();
    } catch (e: unknown) {
      setIsLoading(false);
      showMessage({
        type: 'error',
        title: formatMessage('screens.serverError.title'),
      });
    } finally {
      // Don't set isLoading to false, user still see the ui during the location.href updating, so wwe should view the loader
    }
  };

  const connectAccountingPlatformCustomErrorMessage = async ({ errorMessageKey }: { errorMessageKey: MessageKey }) => {
    try {
      setIsLoading(true);
      await loginToQuickBooks();
    } catch (e: unknown) {
      setIsLoading(false);
      showMessage({
        type: 'error',
        title: formatMessage(errorMessageKey),
      });
    }
  };

  return {
    connectAccountingPlatformCustomErrorMessage,
    loginToAccountPlatformAuth,
    isLoginToAccountPlatformAuthLoading: isLoading,
  };
};
