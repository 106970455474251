import { ComponentThemeType } from '@/cl/theme/theme.hooks';

export const TopNavLayoutTheme: ComponentThemeType = (currentTheme) => ({
  baseStyle: {
    container: {
      flexWrap: 'wrap',
      display: 'flex',
      position: 'relative',
      flexDirection: 'row',
      alignItems: 'center',
      gridGap: '16px',
      paddingX: '16px',
      height: { xs: '108px', s: '72px' } as never,
      borderBottom: 'basic.light',
    },
    iconContainer: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      padding: '8px',
    },
    smallIconContainer: {
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      paddingRight: 'calc(16px - 1px)',
      borderRight: 'basic.light',
      backgroundColor: currentTheme.colors.global.neutral['100'],
    },
    centerIcon: {
      maxHeight: '40px',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
    cta: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      flex: 1,
      padding: '16px',
    },
  },
});
