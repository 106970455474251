import { BillSubscriptionEndPolicyEnum, BillSubscriptionIntervalTypeEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { differenceInDays } from 'date-fns';
import { pick } from 'lodash';
import { BaseSchema, date, number, object, SchemaOf, string } from 'yup';

import { getReleventFields, MAX_ALLOWED_OCCURRENCES } from './EditBillSubscriptionForm.utils';
import { EditBillSubscriptionFormFields } from './types';

export const useEditBillSubscriptionSchema = (
  currentEndPolicy?: BillSubscriptionEndPolicyEnum,
  uneditablePaymentsNumber = 0
): SchemaOf<EditBillSubscriptionFormFields> => {
  const { formatMessage, formatCurrency } = useMelioIntl();

  const relevantFields = getReleventFields(currentEndPolicy);

  const schema = {
    fundingSourceId: string().required(),
    deliveryMethodId: string().required(),
    amount: number()
      .typeError(
        // a workaround. waiting for a fix from the DS team - when the field is empty, it throws error for "" is not a number - https://meliopayments.slack.com/archives/C04RFB93DTQ/p1702802207042489
        formatMessage(
          'activities.editBillSubscription.screens.editBillSubscription.form.billAmount.validation.required'
        )
      )
      .required(
        formatMessage(
          'activities.editBillSubscription.screens.editBillSubscription.form.billAmount.validation.required'
        )
      )
      .moreThan(
        0,
        formatMessage(
          'activities.editBillSubscription.screens.editBillSubscription.form.billAmount.validation.greaterThan',
          {
            minValue: formatCurrency(0),
          }
        )
      ),
    memoToVendor: string(),
    intervalType: string()
      .nullable()
      .required(
        formatMessage(
          'activities.editBillSubscription.screens.editBillSubscription.form.intervalType.validation.required'
        )
      ),
    endPolicy: string()
      .nullable()
      .required(
        formatMessage('activities.editBillSubscription.screens.editBillSubscription.form.endPolicy.validation.required')
      ),
    endDate: date()
      .nullable()
      .required(
        formatMessage('activities.editBillSubscription.screens.editBillSubscription.form.endDate.validation.required')
      )
      .when('startDate', (startDate: Date, schema: BaseSchema) =>
        schema.test(
          'not before-start-date',
          formatMessage(
            'activities.editBillSubscription.screens.editBillSubscription.form.endDate.validation.laterThanStartDate'
          ),
          (endDate: Date) => !startDate || !endDate || differenceInDays(endDate, startDate) >= 0
        )
      ),
    lastAmount: number()
      .nullable()
      .transform((currentValue: number) => (isNaN(currentValue) ? null : currentValue)) // workaround for a case where the currentValue is "" which shoudln't happen but ther eis a bug - when the field is empty, the value is "" ( https://meliopayments.slack.com/archives/C04RFB93DTQ/p1702802207042489)
      .moreThan(
        0,
        formatMessage(
          'activities.editBillSubscription.screens.editBillSubscription.form.lastAmount.validation.greaterThan',
          {
            minValue: formatCurrency(0),
          }
        )
      ),
    startDate: date()
      .nullable()
      .required(
        formatMessage('activities.editBillSubscription.screens.editBillSubscription.form.startDate.validation.required')
      ),
    numOfOccurrences: number()
      .nullable()
      .required(
        formatMessage(
          'activities.editBillSubscription.screens.editBillSubscription.form.numOfOccurrences.validation.required'
        )
      )
      .transform((currentValue: number) => (isNaN(currentValue) ? null : currentValue)) // workaround for a case where the currentValue is "" which shoudln't happen but there is a bug - when the field is empty, the value is "" ( https://meliopayments.slack.com/archives/C04RFB93DTQ/p1702802207042489)
      .positive()
      .integer()
      .when('intervalType', (intervalType: BillSubscriptionIntervalTypeEnum, schema: BaseSchema) =>
        schema.test(
          'not-exceed-max-allowed-occurrences',
          formatMessage(
            'activities.editBillSubscription.screens.editBillSubscription.form.numOfOccurrences.validation.exceededLimit',
            {
              maxNumOfOccurrences: MAX_ALLOWED_OCCURRENCES[intervalType],
            }
          ),
          (value: number) => value <= MAX_ALLOWED_OCCURRENCES[intervalType]
        )
      )
      .moreThan(
        uneditablePaymentsNumber,
        formatMessage(
          'activities.editBillSubscription.screens.editBillSubscription.form.numOfOccurrences.validation.lessOrEqualThanUneditablePaymentsNumber'
        )
      ),
  };

  return object().shape(pick(schema, relevantFields)) as SchemaOf<EditBillSubscriptionFormFields>;
};
