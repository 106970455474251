import { FeeCatalogValueTypeEnum, FundingSource, useFeeCatalog } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export type UsePaymentMethodFeeParams = {
  fundingSourceType: FundingSource['type'];
};

export const usePaymentMethodFee = ({ fundingSourceType }: UsePaymentMethodFeeParams) => {
  const { data: fees = [] } = useFeeCatalog();
  const { formatMessage, formatCurrency } = useMelioIntl();

  const catalogFee = fees.find((fee) => fee.feeType === fundingSourceType);

  if (!catalogFee) {
    return formatMessage('app.noFee.label');
  }

  const suffix = formatMessage('activities.subscription.fee.suffix');

  const feeValue =
    catalogFee.valueType === FeeCatalogValueTypeEnum.Fixed ? formatCurrency(catalogFee.value) : catalogFee.value;

  return `${feeValue}% ${suffix}`;
};
