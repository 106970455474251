/* eslint-disable max-lines */
export const billsInMelioComOnly = {
  1198: true,
  2657: true,
  2944: true,
  4743: true,
  5443: true,
  5471: true,
  6444: true,
  6473: true,
  6845: true,
  7891: true,
  8393: true,
  10561: true,
  11621: true,
  11678: true,
  11904: true,
  13109: true,
  13119: true,
  13308: true,
  15434: true,
  19238: true,
  19976: true,
  24012: true,
  25031: true,
  27564: true,
  27732: true,
  28574: true,
  31365: true,
  32442: true,
  33347: true,
  34472: true,
  34530: true,
  35402: true,
  36561: true,
  36993: true,
  37714: true,
  38110: true,
  39583: true,
  40119: true,
  40328: true,
  42624: true,
  42939: true,
  44492: true,
  46832: true,
  47479: true,
  55205: true,
  58117: true,
  59427: true,
  60882: true,
  64362: true,
  64962: true,
  69316: true,
  71831: true,
  76289: true,
  83596: true,
  84620: true,
  87366: true,
  94631: true,
  98196: true,
  99308: true,
  100630: true,
  106876: true,
  114731: true,
  116159: true,
  116624: true,
  117451: true,
  120159: true,
  122633: true,
  126132: true,
  134087: true,
  134452: true,
  137774: true,
  138348: true,
  138789: true,
  142141: true,
  144724: true,
  145729: true,
  146528: true,
  146678: true,
  149399: true,
  150143: true,
  151003: true,
  153424: true,
  156681: true,
  158471: true,
  158500: true,
  159989: true,
  162654: true,
  165183: true,
  167983: true,
  171978: true,
  174063: true,
  179316: true,
  180876: true,
  182223: true,
  182752: true,
  185293: true,
  186103: true,
  186587: true,
  194907: true,
  208130: true,
  218584: true,
  218783: true,
  219985: true,
  226914: true,
  227507: true,
  229125: true,
  231854: true,
  232911: true,
  235598: true,
  250165: true,
  251670: true,
  256452: true,
  257473: true,
  261009: true,
  263280: true,
  275903: true,
  277438: true,
  277451: true,
  282624: true,
  283583: true,
  284073: true,
  288843: true,
  289118: true,
  298867: true,
  301552: true,
  312846: true,
  314149: true,
  314156: true,
  321649: true,
  322861: true,
  326963: true,
  329043: true,
  331699: true,
  336905: true,
  337480: true,
  364145: true,
  367305: true,
  367688: true,
  372564: true,
  375048: true,
  383860: true,
  384521: true,
  386138: true,
  387326: true,
  390400: true,
  398195: true,
  403469: true,
  404890: true,
  405548: true,
  408602: true,
  409191: true,
  413101: true,
  413217: true,
  413454: true,
  413735: true,
  414269: true,
  414364: true,
  415543: true,
  415746: true,
  417040: true,
  420187: true,
  422926: true,
  424976: true,
  426128: true,
  427089: true,
  432449: true,
  441042: true,
  443016: true,
  454038: true,
  459106: true,
  465752: true,
  480181: true,
  482940: true,
  483057: true,
  492019: true,
  492923: true,
  496071: true,
  501881: true,
  509937: true,
  512239: true,
  517664: true,
  521197: true,
  537080: true,
  543683: true,
  544056: true,
  548796: true,
  569951: true,
  583344: true,
  585218: true,
  586652: true,
  594501: true,
  599616: true,
  599924: true,
  600263: true,
  600482: true,
  600937: true,
  600962: true,
  601366: true,
  602095: true,
  603007: true,
  607635: true,
  610261: true,
  610445: true,
  610584: true,
  610730: true,
  611082: true,
  612334: true,
  612373: true,
  612865: true,
  615691: true,
  616575: true,
  634740: true,
  647271: true,
  648345: true,
  649691: true,
  657055: true,
  665452: true,
  668103: true,
  707841: true,
  711837: true,
  718326: true,
  728585: true,
  728714: true,
  733017: true,
  742572: true,
  742913: true,
  743005: true,
  743020: true,
  743147: true,
  745943: true,
  746263: true,
  746937: true,
  750976: true,
  754763: true,
  761099: true,
  761942: true,
  763925: true,
  787636: true,
  796836: true,
  801193: true,
  812764: true,
  818760: true,
  820690: true,
  847116: true,
  851707: true,
  852488: true,
  856401: true,
  857498: true,
  864138: true,
  865417: true,
  872260: true,
  889878: true,
  890784: true,
  892145: true,
  894195: true,
  896875: true,
  897281: true,
  906124: true,
  906215: true,
  910259: true,
  910451: true,
  913747: true,
  913876: true,
  913902: true,
  918941: true,
  919091: true,
  923430: true,
  926021: true,
  1486442: true,
  1487102: true,
  1487598: true,
  1487905: true,
  1490806: true,
  1492724: true,
  1494678: true,
  1615178: true,
  1616094: true,
  1616407: true,
  1617838: true,
  1621207: true,
  1622459: true,
  1622720: true,
  1627587: true,
  1629871: true,
  1633779: true,
  1637856: true,
  1638042: true,
  1645510: true,
  1646923: true,
  1654512: true,
  1657072: true,
  1668536: true,
  1673365: true,
  1673739: true,
  1677467: true,
  1679276: true,
  1681986: true,
  1691394: true,
  1693047: true,
  1695136: true,
  1699935: true,
  1705583: true,
  1714979: true,
  1717102: true,
  1718107: true,
  1720966: true,
  1721949: true,
  1723553: true,
  1726519: true,
  1728937: true,
  1731210: true,
  1732608: true,
  1741883: true,
  1742357: true,
  1742646: true,
  1744427: true,
  1749007: true,
  1760520: true,
  1765645: true,
  1767562: true,
  1767618: true,
  1774850: true,
  1775738: true,
  1775810: true,
  1779320: true,
  1785452: true,
  1786938: true,
  1795762: true,
  1803439: true,
  1806175: true,
  1808132: true,
  1809600: true,
  1844116: true,
  1847320: true,
  1851281: true,
  1855078: true,
  1856210: true,
  1862158: true,
  1865165: true,
  1866485: true,
  1880272: true,
  1890155: true,
  1892636: true,
  1897393: true,
  1900544: true,
  1901941: true,
  1916211: true,
  1929860: true,
  1931903: true,
  1936925: true,
  1948163: true,
  1948539: true,
  1948903: true,
  1959687: true,
  1968104: true,
  1968860: true,
  1970175: true,
  1983257: true,
  1983561: true,
  1983913: true,
  1996314: true,
  1996820: true,
  2005775: true,
  2012097: true,
  2027838: true,
  2039645: true,
  2046496: true,
  2048244: true,
  2071789: true,
  2080479: true,
  2083323: true,
  2148857: true,
  2153405: true,
  2175223: true,
  2231249: true,
  2250143: true,
  2283484: true,
  2285282: true,
  2286746: true,
  2294308: true,
  2338163: true,
  2346547: true,
  2352512: true,
  2379288: true,
  2389334: true,
};

export const billsInPayVendorsOnly = {
  29354: true,
  39945: true,
  42970: true,
  90431: true,
  136781: true,
  155982: true,
  165472: true,
  172152: true,
  190994: true,
  196013: true,
  197814: true,
  221080: true,
  240231: true,
  271871: true,
  295043: true,
  313553: true,
  353552: true,
  374385: true,
  375960: true,
  393005: true,
  398269: true,
  410156: true,
  413231: true,
  425098: true,
  438437: true,
  481274: true,
  483803: true,
  497277: true,
  502870: true,
  533907: true,
  561980: true,
  562290: true,
  573066: true,
  599695: true,
  599787: true,
  606895: true,
  738666: true,
  785072: true,
  808573: true,
  820076: true,
  845068: true,
  1612965: true,
  1652973: true,
  1659074: true,
  1670127: true,
  1699059: true,
  1701465: true,
  1702845: true,
  1703861: true,
  1741318: true,
  1744381: true,
  1747770: true,
  1751792: true,
  1771743: true,
  1785994: true,
  1792031: true,
  1805026: true,
  1806211: true,
  1848654: true,
  1882047: true,
  1920193: true,
  1967844: true,
  1994064: true,
  2003640: true,
  2013307: true,
  2042286: true,
  2051838: true,
  2186043: true,
  2217425: true,
  2285262: true,
};
