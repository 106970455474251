import { Box } from '@chakra-ui/react';

import { EmbeddedDemoLayouts } from '@/screens/embedded-experience-demo/layouts';
import { EmbeddedDemoLayout } from '@/screens/embedded-experience-demo/layouts/types';

export const EmbeddedExperienceDemoScreen = ({
  embeddedUrl,
  layout = EmbeddedDemoLayout.US_BANK,
}: {
  embeddedUrl: string;
  layout?: EmbeddedDemoLayout;
}) => {
  const { LayoutComponent, headerHeight = '0px' } = EmbeddedDemoLayouts[layout];
  return (
    <LayoutComponent>
      <Box
        as="iframe"
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-downloads"
        title="Deployments demo"
        src={embeddedUrl}
        width="full"
        height={`calc(100vh - ${headerHeight})`}
      />
    </LayoutComponent>
  );
};
