import { CountryCode, Currency, getCountries, getCurrencies } from '@melio/international-payments-utils';
import { useInternationalCurrencies } from '@melio/platform-api';

type UseSupportedOptions = {
  currency?: Currency;
  countryCode?: CountryCode;
};

export const useSupportedFXData = ({ currency, countryCode }: UseSupportedOptions) => {
  const { data, isLoading } = useInternationalCurrencies();
  const currencies = data || [];

  const countries = currencies.length ? getCountries(currencies as Currency[]) : [];

  if (currency) {
    return { countries: getCountries([currency]), currencies: [currency], isLoading };
  }

  if (countryCode) {
    const country = countries.filter((c) => c.code === countryCode);
    if (country.length) {
      return {
        currencies: getCurrencies(countryCode).filter((c) => currencies.includes(c)),
        countries: country,
        isLoading,
      };
    }
  }

  return { countries, currencies, isLoading };
};
