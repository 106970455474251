import { useToast } from '@melio/penny';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { useBillSubscription } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMonitoring } from '@melio/platform-monitoring';
import { NavigationMap, useWizard, UseWizardArgs } from '@melio/platform-utils';
import { noop, pick } from 'lodash';
import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import { MonitoredAction } from '../../monitoring';
import { AddDeliveryMethodActivity } from './components/AddDeliveryMethod';
import { AddPaymentMethodActivity } from './components/AddPaymentMethod';
import { EditBillSubscriptionFormFields } from './screens/EditBillSubscription/components/types';
import { EditBillSubscriptionScreen } from './screens/EditBillSubscription/EditBillSubscription.screen';
import { EditBillSubscriptionLoadingScreen } from './screens/EditBillSubscription/EditBillSubscriptionLoading.screen';
import { SeriesPaymentUpdatedSuccessfullyScreen } from './screens/newPaymentUpdated/SeriesPaymentUpdatedSuccessfully.screen';
import { PaymentUpdatedScreen } from './screens/PaymentUpdated/PaymentUpdated.screen';
import { EditableData, ExpandedBillSubscription } from './types';

const enum Steps {
  Form = 'form',
  Success = 'success',
  CreateFundingSource = 'createFundingSource',
  CreateDeliveryMethod = 'createDeliveryMethod',
}

type WizardSteps = Steps.Form | Steps.Success | Steps.CreateFundingSource | Steps.CreateDeliveryMethod;

export type EditBillSubscriptionActivityProps = {
  billSubscriptionId: string;
  onDone: VoidFunction;
  onError?: ErrorFunction;
};

export const EditBillSubscriptionActivity = withAnalyticsContext<EditBillSubscriptionActivityProps>(
  ({ setAnalyticsProperties, billSubscriptionId, onDone, onError }) => {
    const { toast } = useToast();
    const { startAction, endAction } = useMonitoring<MonitoredAction>();
    const [isNewPaymentScheduledSuccessfully] = useFeature(FeatureFlags.NewPaymentScheduledSuccessfully, false);
    const {
      data: billSubscription,
      update: updateBillSubscription,
      isLoading: isLoadingBillSubscription,
      isMutating: isUpdatingBillSubscription,
      error,
    } = useBillSubscription({
      id: billSubscriptionId,
      params: { expand: ['nextOccurrence', 'vendor', 'fundingSource', 'deliveryMethod', 'occurrences'] },
    });

    const navigationMap: NavigationMap<Steps> = {
      [Steps.Form]: ({
        nextStep,
      }: {
        nextStep: Steps.Success | Steps.CreateFundingSource | Steps.CreateDeliveryMethod;
      }) => nextStep,
      [Steps.CreateFundingSource]: ({ fundingSourceId }: { fundingSourceId: string }) => {
        setFormChanges((prevChanges) => ({ ...prevChanges, fundingSourceId }));
        return Steps.Form;
      },
      [Steps.CreateDeliveryMethod]: ({ deliveryMethodId }: { deliveryMethodId: string }) => {
        setFormChanges((prevChanges) => ({ ...prevChanges, deliveryMethodId }));
        return Steps.Form;
      },
      [Steps.Success]: noop,
    };

    const locationsMap: UseWizardArgs<Steps>['locationsMap'] = {
      [Steps.Form]: Steps.Form,
      [Steps.Success]: Steps.Success,
      [Steps.CreateFundingSource]: Steps.CreateFundingSource,
      [Steps.CreateDeliveryMethod]: Steps.CreateDeliveryMethod,
    };

    const { goBack, goNextMap, completeFlow, cancelFlow } = useWizard<WizardSteps, NavigationMap<WizardSteps>>({
      flowName: 'edit-bill-subscription',
      firstStep: Steps.Form,
      navigationMap,
      locationsMap,
      cancelUrlFallback: '/',
    });

    const [formChanges, setFormChanges] = useState<EditableData>();

    const onChangeField = (changes: EditableData) => {
      setFormChanges((prevChanges) => ({ ...prevChanges, ...changes }));
    };

    useEffect(() => {
      if (error && onError) {
        onError(error);
      }
    }, [error, onError]);

    setAnalyticsProperties({
      Intent: 'edit-recurring-payment',
    });

    const handleDone = (data: EditBillSubscriptionFormFields) => {
      startAction('recurring-payment-edit-submitted');
      updateBillSubscription(data)
        .then(() => {
          goNextMap[Steps.Form]({ navArgs: [{ nextStep: Steps.Success }] });
        })
        .catch((error: PlatformError) => {
          toast({
            type: 'error',
            title: error.message,
          });

          onError?.(error);
        });
    };

    const onUpdatedScreenLoad = () => {
      endAction('recurring-payment-edit-submitted');
    };

    if (!billSubscription) {
      return <EditBillSubscriptionLoadingScreen />;
    }
    const initialState = {
      amount: billSubscription.amount,
      memoToVendor: billSubscription.memoToVendor || undefined,
      intervalType: billSubscription.intervalType,
      endPolicy: billSubscription.endPolicy,
      startDate: billSubscription.nextOccurrence?.deliveryDate || billSubscription.nextOccurrence?.dueDate,
      endDate: billSubscription.endDate || null,
      lastAmount: billSubscription.lastAmount || null,
      numOfOccurrences: billSubscription.numOfOccurrences || null,
      fundingSourceId: billSubscription.fundingSourceId,
      deliveryMethodId: billSubscription.deliveryMethodId,
      fileId: billSubscription.fileId || undefined,
    };

    const onCreateFundingSource = () => {
      goNextMap[Steps.Form]({ navArgs: [{ nextStep: Steps.CreateFundingSource }] });
    };

    const onCreateDeliveryMethod = () => {
      goNextMap[Steps.Form]({ navArgs: [{ nextStep: Steps.CreateDeliveryMethod }] });
    };

    const formState = { ...initialState, ...formChanges };

    return (
      <Routes>
        <Route
          path={locationsMap.form}
          element={
            <EditBillSubscriptionScreen
              onCreateFundingSource={onCreateFundingSource}
              onCreateDeliveryMethod={onCreateDeliveryMethod}
              formState={formState}
              onChangeField={onChangeField}
              isLoading={isLoadingBillSubscription}
              onDone={handleDone}
              onClose={cancelFlow}
              isSaving={isUpdatingBillSubscription}
              onError={onError}
              {...pick(billSubscription as ExpandedBillSubscription, [
                'nextOccurrence',
                'managedBy',
                'vendor',
                'id',
                'invoiceNumber',
              ])}
            />
          }
        />
        <Route
          path={locationsMap.success}
          element={
            isNewPaymentScheduledSuccessfully ? (
              <SeriesPaymentUpdatedSuccessfullyScreen
                onScreenLoad={onUpdatedScreenLoad}
                billSubscription={billSubscription}
                onBackToDashboard={() => {
                  completeFlow(() => {
                    onDone();
                  });
                }}
              />
            ) : (
              <PaymentUpdatedScreen
                onScreenLoad={onUpdatedScreenLoad}
                billSubscription={billSubscription}
                onBackToDashboard={() => {
                  completeFlow(() => {
                    onDone();
                  });
                }}
              />
            )
          }
        />
        <Route
          path={locationsMap[Steps.CreateFundingSource]}
          element={
            <AddPaymentMethodActivity
              formState={formState}
              vendorId={billSubscription.vendorId}
              invoiceNumber={billSubscription.invoiceNumber}
              billSubscriptionId={billSubscription.id}
              onClose={goBack}
              onError={onError}
              onDone={(fundingSourceId) => {
                goNextMap[Steps.CreateFundingSource]({ navArgs: [{ fundingSourceId }] });
              }}
            />
          }
        />
        <Route
          path={locationsMap[Steps.CreateDeliveryMethod]}
          element={
            <AddDeliveryMethodActivity
              formState={formState}
              billCurrency={billSubscription.currency}
              vendorId={billSubscription.vendorId}
              invoiceNumber={billSubscription.invoiceNumber}
              billSubscriptionId={billSubscription.id}
              onBack={goBack}
              onClose={goBack}
              onError={onError}
              onDone={(deliveryMethodId) => {
                goNextMap[Steps.CreateDeliveryMethod]({ navArgs: [{ deliveryMethodId }] });
              }}
            />
          }
        />
      </Routes>
    );
  }
);
