import { Bill, PaymentRequest, ScannedInvoice } from '@melio/platform-api';
import { Payment, Vendor } from '@melio/platform-api';

import { GroupItem } from '@/types/payDashboard.types';

export type BaseCardProps = {
  isSelected: boolean;
  isInactive?: boolean;
  onClick: (item: GroupItem) => void;
};

export type BaseBillCardProps = {
  bill: Bill;
  invoiceNumber?: string | null;
  vendorName: string;
};

export type BasePaymentRequestCardProps = {
  paymentRequest: PaymentRequest;
};

export type BasePaymentCardProps = {
  payment: Payment;
  invoiceNumber?: string | null;
  vendorName: string;
};

export type BaseScannedInvoiceCardProps = {
  scannedInvoice: ScannedInvoice;
  invoiceNumber?: string;
  vendorName?: string;
};

export const getPaymentCardBaseProps = ({
  payment,
  vendor,
}: {
  payment: Payment;
  vendor?: Vendor;
}): Omit<BasePaymentCardProps, 'isSelected' | 'onClick'> => ({
  payment,
  vendorName: vendor ? vendor.name : '',
  invoiceNumber: payment.bills?.[0]?.invoice?.number,
});

export const getBillCardBaseProps = ({
  bill,
  vendor,
}: {
  bill: Bill;
  vendor?: Vendor;
}): Omit<BaseBillCardProps, 'isSelected' | 'onClick'> => ({
  bill,
  vendorName: vendor ? vendor.name : '',
  invoiceNumber: bill.invoice?.number,
});

export const getPaymentRequestCardBaseProps = ({
  paymentRequest,
}: {
  paymentRequest: PaymentRequest;
}): Omit<BasePaymentRequestCardProps, 'isSelected' | 'onClick'> => ({
  paymentRequest,
});

export const getScannedInvoiceCardBaseProps = ({
  scannedInvoice,
}: {
  scannedInvoice: ScannedInvoice;
}): Omit<BaseScannedInvoiceCardProps, 'isSelected' | 'onClick'> => ({
  scannedInvoice,
  vendorName: scannedInvoice.vendorName || '',
  invoiceNumber: scannedInvoice.invoiceNumber || undefined,
});
