import { CardNetwork, FundingSource, FundingSourceType } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { getCardNetworkLabelKey } from '../../functions/funding-sources';

export const useInternationalPaymentCardRestrictionsText = () => {
  const { formatMessage } = useMelioIntl();

  return (fundingSource?: FundingSource) =>
    formatMessage('widgets.fundingSources.selectFundingSourceCard.card.disabledReason.cardNetworkNotSupported', {
      cardNetwork:
        fundingSource?.type === FundingSourceType.Card &&
        fundingSource.details.network &&
        fundingSource.details.network !== CardNetwork.Other
          ? formatMessage(getCardNetworkLabelKey(fundingSource?.details.network))
          : formatMessage('widgets.fundingSources.selectFundingSourceCard.card.unknownNetwork'),
    });
};
