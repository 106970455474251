import { atom, DefaultValue, selector } from 'recoil';

import { PayDashboardType, SelectedCard } from './PayDashboard.types';

export const payDashboardState = atom<PayDashboardType>({
  key: 'payDashboardState',
  default: {
    selectedCard: {},
    querySearch: '',
    forceSelectFirst: false,
    itemSelections: [],
  },
});

export const payDashboardSelectedCardSelector = selector<SelectedCard>({
  key: 'payDashboardSelectedCardSelector',
  get: ({ get }) => {
    const payDashboard = get(payDashboardState);
    return payDashboard.selectedCard;
  },
  set: ({ get, set }, selectedCard) => {
    if (selectedCard instanceof DefaultValue) {
      return;
    }
    set(payDashboardState, { ...get(payDashboardState), selectedCard });
  },
});

export const payDashboardItemSelectionsSelector = selector<string[]>({
  key: 'payDashboardItemSelectionsSelector',
  get: ({ get }) => {
    const payDashboard = get(payDashboardState);
    return payDashboard.itemSelections;
  },
  set: ({ get, set }, itemSelections) => {
    if (itemSelections instanceof DefaultValue) {
      return;
    }
    set(payDashboardState, { ...get(payDashboardState), itemSelections });
  },
});

export const payDashboardQuerySearchSelector = selector<string>({
  key: 'payDashboardQuerySearchSelector',
  get: ({ get }) => {
    const payDashboard = get(payDashboardState);
    return payDashboard.querySearch;
  },
  set: ({ get, set }, querySearch) => {
    if (querySearch instanceof DefaultValue) {
      return;
    }
    set(payDashboardState, { ...get(payDashboardState), querySearch });
  },
});

export const payDashboardForceSelectFirstSelector = selector<boolean>({
  key: 'payDashboardForceSelectFirstSelector',
  get: ({ get }) => {
    const payDashboard = get(payDashboardState);
    return payDashboard.forceSelectFirst;
  },
  set: ({ get, set }, forceSelectFirst) => {
    if (forceSelectFirst instanceof DefaultValue) {
      return;
    }
    set(payDashboardState, { ...get(payDashboardState), forceSelectFirst });
  },
});

export const filterByVendorIdState = atom<string | null>({
  key: 'filterByVendorIdState',
  default: null,
});

export const mobileSideNavState = atom<boolean>({
  key: 'mobileSideNavState',
  default: false,
});
