import { TBTFormWidgetFields } from '@melio/ap-widgets';
import { useTbt } from '@melio/form-controls';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { CardParams, useFundingSources } from '@melio/platform-api';
import { useBoolean } from '@melio/platform-utils';
import { useState } from 'react';

import { ReconciliationModalActivity } from '../Reconciliation';
import { CardHolderDetailsScreen, CardValidationModalScreen, TBTCreateCardDetailsScreen } from './screens';
import { useAddCardFundingSourceActivityCallbacks } from './shared/useAddCardFundingSourceActivityCallbacks';
import { AddCardFundingSourceActivityProps } from './types';
import { useAddCardFundingSourceActivityStep } from './useAddCardFundingSourceActivityStep';

export const TBTAddCardFundingSourceActivity = withAnalyticsContext<AddCardFundingSourceActivityProps>(
  ({ onClose, onDone, onBack: onFirstStepBack, onError, cardType }) => {
    const { currentStep, goToPreviousStep, goToStep } = useAddCardFundingSourceActivityStep({ onFirstStepBack });
    const [cardDetailsParams, setCardDetailsParams] = useState<CardParams>();
    const collection = useFundingSources({ enabled: false });

    const [isVerifying, verifying] = useBoolean(false);

    const { bt, tokenize } = useTbt();

    const {
      showVerificationErrorDialog,
      handleSubmit,
      isCardValidationModalOpen,
      onCardValidationModalDone,
      reconciliation,
      fundingSourceId,
    } = useAddCardFundingSourceActivityCallbacks({ vaulting: 'TBT', cardType, onError, cardDetailsParams, onDone });
    const { createTrackHandler } = useAnalytics();
    const trackCreateCardDetailsSubmit = createTrackHandler<{ Status?: 'succeeded' | 'failed' }>(
      'AddCCDetails',
      'Submitted'
    );

    const {
      onCloseReconciliationFlow,
      onDoneReconciliationFlow,
      shouldShowReconciliationModal,
      isReconciliationNeeded,
    } = reconciliation;

    const onCreateCardDetailsDone = async ({
      cardNumber,
      cardExpiration,
      cardVerificationCode,
    }: TBTFormWidgetFields) => {
      if (bt) {
        try {
          verifying.on();
          const cardParams = await tokenize({
            cardNumber,
            cardExpiration,
            cardVerificationCode,
          });
          collection
            .verifyCard({
              cardBin: cardParams.cardBin,
              tabapayToken: cardParams.tabapayToken,
              tokenProvider: 'basistheory',
            })
            .then(() => {
              trackCreateCardDetailsSubmit({ Status: 'succeeded' });
              setCardDetailsParams(cardParams);
              goToStep('ADD_CARD_HOLDER_DETAILS');
            })
            .catch(showVerificationErrorDialog)
            .finally(verifying.off);
        } catch (error) {
          verifying.off();
          trackCreateCardDetailsSubmit({ Status: 'failed' });
          onError?.(error as PlatformError);
          return;
        }
      }
    };

    if (currentStep == 'ADD_CARD_HOLDER_DETAILS') {
      return (
        <>
          <CardHolderDetailsScreen
            onDone={handleSubmit}
            onClose={onClose}
            onBack={goToPreviousStep}
            isSaving={collection.isMutating}
          />
          {fundingSourceId && isReconciliationNeeded && (
            <ReconciliationModalActivity
              isOpen={shouldShowReconciliationModal}
              onClose={onCloseReconciliationFlow(onClose)}
              onDone={onDoneReconciliationFlow}
              selectedFundingSourceId={fundingSourceId}
              isNewFundingSource
            />
          )}
        </>
      );
    }

    return (
      <>
        <TBTCreateCardDetailsScreen
          cardType={cardType}
          onClose={onClose}
          onError={showVerificationErrorDialog}
          onDone={onCreateCardDetailsDone}
          isSaving={isVerifying}
        />
        <CardValidationModalScreen
          isOpen={isCardValidationModalOpen}
          onDone={onCardValidationModalDone}
          cardType={cardType}
        />
      </>
    );
  }
);

TBTAddCardFundingSourceActivity.displayName = 'AddCardFundingSourceActivity';
