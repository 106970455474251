import { AddressSearchWidget } from '@melio/ap-widgets';
import { Form, Group, useBreakpoint } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect, useRef } from 'react';

import { RegisterFormField } from './types';

export const EBillsSusbscriptionFormEditCompany = ({
  registerField,
  hidden,
  isEditMode,
}: {
  registerField: RegisterFormField;
  hidden: boolean;
  isEditMode: boolean;
}) => {
  const { formatMessage } = useMelioIntl();
  const { isExtraSmallScreen } = useBreakpoint();
  const ref = useRef<HTMLInputElement>(null);

  useAnalyticsView('subscribe-to-ebills-and-edit');

  useEffect(() => {
    if (isEditMode) {
      ref.current?.focus();
    }
  }, [isEditMode]);

  return (
    // Penny should support passing hidden prop to Group component
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Group variant="vertical" spacing="l" hidden={hidden}>
      <Group variant={isExtraSmallScreen ? 'vertical' : 'horizontal'} width="full" justifyContent="space-between">
        <Form.TextField
          {...registerField('name.firstName')}
          labelProps={{ label: formatMessage('activities.ebillsSubscriptionForm.form.fields.firstName.label') }}
          isRequired
          ref={ref}
        />
        <Form.TextField
          width={isExtraSmallScreen ? 'full' : '85px'}
          {...registerField('name.middleName')}
          labelProps={{ label: formatMessage('activities.ebillsSubscriptionForm.form.fields.middleName.label') }}
        />
        <Form.TextField
          {...registerField('name.lastName')}
          labelProps={{ label: formatMessage('activities.ebillsSubscriptionForm.form.fields.lastName.label') }}
          isRequired
        />
      </Group>
      <AddressSearchWidget
        {...registerField('address')}
        labelProps={{ label: formatMessage('activities.ebillsSubscriptionForm.form.fields.address.label') }}
        isRequired
      />
    </Group>
  );
};
