import React from 'react';
import { StatusModal, StatusModalProps, Text } from '@melio/penny';

type AsyncModalDialogProps = {
  isOpen: boolean;
  type: StatusModalProps['variant'];
  onConfirm: () => Promise<void>;
  onClose: () => void;
  title: string;
  body: React.ReactNode;
  confirmText: string;
  cancelText: string;
  hideSecondaryButton?: boolean;
};

export const AsyncModalDialog = ({
  isOpen,
  type,
  title,
  body,
  confirmText,
  cancelText,
  onConfirm,
  onClose,
  hideSecondaryButton = false,
}: AsyncModalDialogProps) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const modalBody = typeof body === 'string' ? <Text>{body}</Text> : body;

  const onDialogConfirm = async () => {
    setIsLoading(true);
    await onConfirm();
    setIsLoading(false);
  };

  // Resetting loading state on unmount
  React.useEffect(() => {
    return () => setIsLoading(false);
  }, []);

  return (
    <StatusModal
      header={title}
      variant={type}
      isOpen={isOpen}
      onClose={onClose}
      isLoading={isLoading}
      primaryButton={{ label: confirmText, onClick: onDialogConfirm, variant: 'primary' }}
      secondaryButton={hideSecondaryButton ? undefined : { label: cancelText, onClick: onClose, variant: 'tertiary' }}
    >
      {modalBody}
    </StatusModal>
  );
};
