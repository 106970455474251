import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { useRouter } from '@/hooks/router.hooks';
import { useIsMissingKycComplianceInformation } from '@/hooks/useIsMissingKycComplianceInformation';
import { CompleteMtlDetailsScreen } from '@/screens/CompleteMtlDetails.screen';

export const CompleteDetailsRoute = () => {
  const { goHome } = useRouter();
  const { onBlockerScreenUserInteraction } = useIsMissingKycComplianceInformation();

  const handleClose = () => {
    onBlockerScreenUserInteraction();
    goHome();
  };

  return (
    <Routes>
      <Route path="*" element={<CompleteMtlDetailsScreen onDone={handleClose} isTriggerManually />} />
    </Routes>
  );
};
