import { isNil, isObject } from 'lodash';

export const normalizeParams = <T extends object>(params?: T): string[] => {
  if (!params) {
    return [];
  }

  return Object.entries(params)
    .filter(([, value]) => value !== '' && !isNil(value))
    .sort(([a], [b]) => a.localeCompare(b))
    .reduce<string[]>((acc, [key, value]) => {
      const normalizedValue = isObject(value) ? normalizeParams<typeof value>(value) : String(value);
      const nestedList = Array.isArray(normalizedValue) ? normalizedValue : [normalizedValue];
      const nestedListWithKey = nestedList.map((item) => `${key}.${item}`);
      return [...acc, ...nestedListWithKey];
    }, []);
};
