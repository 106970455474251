/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { useAccountantsRoutes } from '@melio/accountants';
import { AccountingPlatformSyncConnectionErrorEnum, SyncAppStateType, SyncFlowEnum } from '@melio/ap-activities';
import { useAnalytics } from '@melio/platform-analytics';

import { Loader } from '@/cl/components/Loader/Loader.component';
import { useRouter } from '@/hooks/router.hooks';
import { getAccessTokenOrganizationId, getAccessTokenUserId } from '@/utils/getAccessTokenData.utils';

export const AuthIntuitCallbackRouteSuccess = () => {
  const [searchParams] = useSearchParams();
  const {
    goToAccountingPlatform,
    goToAccountingPlatformError,
    goToAccountingPlatformSync,
    goToSuccessfulQboConnectLink,
  } = useRouter();
  const { goToClientQbRedirect } = useAccountantsRoutes();
  const { trackMarketing, track } = useAnalytics();

  useEffect(() => {
    const appStateString = searchParams.get('state');
    const appState: SyncAppStateType = appStateString && JSON.parse(decodeURIComponent(appStateString));
    const appFlowToReturn = appState ? appState.flow : SyncFlowEnum.Setting;
    const accountingPlatformId = appState?.accountingPlatformId;
    const isSettingFlow = appFlowToReturn === SyncFlowEnum.Setting;
    const accessTokenUserId = getAccessTokenUserId();
    const accessTokenOrganizationId = getAccessTokenOrganizationId();

    const orgId = searchParams.get('orgId');
    const authToken = searchParams.get('authToken');
    if (!authToken) {
      if (isSettingFlow) {
        goToAccountingPlatform({
          syncError: AccountingPlatformSyncConnectionErrorEnum.GeneralError,
          accountingPlatformId,
        });
      } else {
        goToAccountingPlatformError({
          syncError: AccountingPlatformSyncConnectionErrorEnum.GeneralError,
          flowToReturn: appFlowToReturn,
        });
      }
    }
    const { userId } = jwtDecode<{ userId: number }>(authToken!);

    if (Number(accessTokenUserId) !== Number(userId) || Number(orgId) !== Number(accessTokenOrganizationId)) {
      if (appState.flow === SyncFlowEnum.AccountingFirmAddClient) {
        goToClientQbRedirect(`org_${orgId}`);
      } else if (isSettingFlow) {
        goToAccountingPlatform({
          syncError: AccountingPlatformSyncConnectionErrorEnum.AccountPlatformConnectToDifferentMelioAccount,
          accountingPlatformId,
        });
      } else {
        goToAccountingPlatformError({
          syncError: AccountingPlatformSyncConnectionErrorEnum.AccountPlatformConnectToDifferentMelioAccount,
          flowToReturn: appFlowToReturn,
        });
      }
    }

    //TODO:: move to the sync page
    else if (!isSettingFlow && accountingPlatformId) {
      trackMarketing('connected-quickbooks');
      if (appState.flow === SyncFlowEnum.ContextualOnboarding) {
        track('SyncAccountingSoftware', 'Status', {
          StatusType: 'success',
          Cta: 'connect-your-quickbooks-online',
          PageName: 'onboarding',
          Flow: 'accounting-software-connection',
          Intent: 'connect-accounting-software',
          AccountingSoftwareType: 'quickbooks-online',
        });
        goToSuccessfulQboConnectLink();
      } else {
        goToAccountingPlatformSync({ accountingPlatformId, flowToReturn: appFlowToReturn });
      }
    } else {
      trackMarketing('connected-quickbooks');
      goToAccountingPlatform({});
    }
  }, []);

  return <Loader isAbsoluteCenter />;
};
