import { useModelApi } from '@melio/api-client';
import { FinancingApiClient } from '@melio/platform-api-axios-client';

import { convertOrgEligibilityResponseCentsToDollars } from './utils';

export const useFinancingOrgEligibility = (props = {}) =>
  useModelApi({
    ...props,
    id: 'my-id', // trick the system to fetch without an actual id
    queryKey: ['FinancingApi', 'orgEligibility'],
    // I needed this hack because the queryFn type expects an id
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    queryFn: (_id, ...args) => FinancingApiClient.getOrgFinancingEligibility(...args),
    select: convertOrgEligibilityResponseCentsToDollars,
  });
