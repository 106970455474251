import { forwardRef } from '@melio/platform-utils';

import { FormWidgetProps } from '../../../../types';
import { BankAccountFormModel } from '../../../bank-details';
import { BankDetailsForm } from '../../../bank-details/BankDetailsForm.widget';

type MicroDepositsFormProps = FormWidgetProps<BankAccountFormModel>;

export const MicroDepositsForm = forwardRef<MicroDepositsFormProps, 'form'>(
  ({ onSubmit, onSubmissionStateChange, isSaving, ...props }, ref) => (
    <BankDetailsForm
      onSubmit={onSubmit}
      onSubmissionStateChange={onSubmissionStateChange}
      isSaving={isSaving}
      {...props}
      ref={ref}
    />
  )
);

MicroDepositsForm.displayName = 'MicroDepositsForm';
