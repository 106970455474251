import { MessageKey, OTPVerifyErrorCodes, useMelioIntl } from '@melio/ar-domain';

export type TitleMessageKey = typeof OTPVerifyErrorCodes.ExpiredCode | typeof OTPVerifyErrorCodes.MaxAttemptsReached;

export type DescriptionMessageKey =
  | typeof OTPVerifyErrorCodes.ExpiredCode
  | typeof OTPVerifyErrorCodes.MaxAttemptsReached
  | typeof OTPVerifyErrorCodes.ExpiredAuthenticator
  | typeof OTPVerifyErrorCodes.Forbidden
  | typeof OTPVerifyErrorCodes.GeneralErrorCode
  | typeof OTPVerifyErrorCodes.WrongCode;

const titleMessageKeyMap: Record<TitleMessageKey, MessageKey> = {
  EXPIRED_CODE: 'ar.guestPayment.otp.verifyCode.errors.expiredCode.title.text',
  MAX_ATTEMPTS_REACHED: 'ar.guestPayment.otp.verifyCode.errors.maxAttemptsReached.title.text',
};

const descriptionMessageKeyMap: Record<DescriptionMessageKey, MessageKey> = {
  EXPIRED_CODE: 'ar.guestPayment.otp.verifyCode.errors.expiredCode.description.text',
  MAX_ATTEMPTS_REACHED: 'ar.guestPayment.otp.verifyCode.errors.maxAttemptsReached.description.text',
  EXPIRED_AUTHENTICATOR: 'ar.guestPayment.otp.verifyCode.errors.expiredSession.description.text',
  FORBIDDEN: 'ar.guestPayment.otp.verifyCode.errors.forbidden.description.text',
  WRONG_CODE: 'ar.guestPayment.otp.verifyCode.errors.wrongCode.description.text',
  GENERAL_ERROR_CODE: 'ar.guestPayment.otp.verifyCode.errors.forbidden.description.text',
};

const isTitleMessageKey = (code: string): code is TitleMessageKey => code in titleMessageKeyMap;
const isDescriptionMessageKey = (code: string): code is DescriptionMessageKey => code in descriptionMessageKeyMap;

export const useVerifyCodeErrorDetails = () => {
  const { formatMessage } = useMelioIntl();
  const getSectionBannerErrorDetails = (code: string): { title: string; description: string } => {
    const title = isTitleMessageKey(code) ? formatMessage(titleMessageKeyMap[code]) : '';
    const description = isDescriptionMessageKey(code)
      ? formatMessage(descriptionMessageKeyMap[code])
      : formatMessage('ar.guestPayment.otp.verifyCode.errors.forbidden.description.text');

    return { title, description };
  };

  return {
    getSectionBannerErrorDetails,
  };
};
