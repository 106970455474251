import { Group, Modal, Text } from '@melio/penny';
import { Payment, PaymentDate, usePaymentsETADates } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { SystemMessageDisplay, SystemMessageProvider } from '@melio/platform-utils';

import { PaymentsLateApprovalModalBody } from './components/PaymentsLateApprovalModalBody';
import { PaymentsLateApprovalModalHeader } from './components/PaymentsLateApprovalModalHeader';

export type PaymentsLateApprovalModalActivityProps = {
  lateApprovalPayments: Payment[];
  approvedPayments: Payment[];
  isOpen: boolean;
  isMutating: boolean;
  onClose: VoidFunction;
  onSuccess: (lateApprovalPaymentsDates: PaymentDate[]) => Promise<void>;
};
export const PaymentsLateApprovalModalActivity = ({
  lateApprovalPayments,
  approvedPayments,
  isOpen,
  isMutating,
  onClose,
  onSuccess,
}: PaymentsLateApprovalModalActivityProps) => {
  const { formatMessage } = useMelioIntl();

  const { data: lateApprovalPaymentsDates, isLoading: isLoadingPaymentsDates } = usePaymentsETADates({
    enabled: isOpen,
    paymentIds: lateApprovalPayments.map((p) => p.id),
  });

  const handleSubmit = () => {
    onSuccess(lateApprovalPaymentsDates ?? []);
  };

  return (
    <SystemMessageProvider>
      <Modal
        data-testid="payments-late-approval-modal"
        isOpen={isOpen}
        onClose={onClose}
        isLoading={isLoadingPaymentsDates}
        header={<PaymentsLateApprovalModalHeader />}
        primaryButton={{
          label: formatMessage('activities.paymentsLateApprovalModal.footer.submit'),
          onClick: handleSubmit,
          isLoading: isMutating,
          variant: 'primary',
        }}
      >
        <Group variant="vertical">
          {lateApprovalPayments.length === 1 ? (
            <Text>{formatMessage('activities.paymentsLateApprovalModal.description.single')}</Text>
          ) : null}
          <Group variant="vertical" spacing="l">
            <SystemMessageDisplay />

            <PaymentsLateApprovalModalBody
              lateApprovalPayments={lateApprovalPayments}
              approvedPayments={approvedPayments}
              lateApprovalPaymentsDates={lateApprovalPaymentsDates ?? []}
            />
          </Group>
        </Group>
      </Modal>
    </SystemMessageProvider>
  );
};
