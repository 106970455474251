import { Box } from '@chakra-ui/react';
import { Card, Container, Group, Image, LoadingContainer } from '@melio/penny';
import { Logger } from '@melio/platform-logger';
import { forwardRef, useBoolean } from '@melio/platform-utils';
import { useEffect, useState } from 'react';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = '//platform-static.meliopayments.com/js/pdf.worker.min.js';

type PDFPreviewerProps = {
  previewContent?: string;
  onError?: ARErrorFunction;
  isLoadingContent?: boolean;
};

const fetchPdf = async (previewContent: string) => {
  const pageImagesList: string[] = [];
  const firstPageNumber = 1;
  const scale = 3;
  const binaryContent = convertDataURIToBinary(previewContent);
  const pdf = await pdfjs.getDocument(binaryContent).promise;

  for (let i = firstPageNumber; i <= pdf.numPages; i++) {
    const page = await pdf.getPage(i);
    const viewport = page.getViewport({ scale });

    // Prepare canvas using PDF page dimensions.
    const canvas = document.createElement('canvas');
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    // Render PDF page into canvas context.
    const renderContext = {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      canvasContext: canvas.getContext('2d')!,
      viewport,
    };

    await page.render(renderContext).promise;
    const pageImage = canvas.toDataURL('image/png');
    pageImagesList.push(pageImage);
  }

  return pageImagesList;
};

export const PDFPreviewer = forwardRef<PDFPreviewerProps>(
  ({ previewContent = '', isLoadingContent = false, onError, ...props }, ref) => {
    const [isLoading, loadingState] = useBoolean(true);
    const [pdfPages, setPdfPages] = useState<string[]>([]);

    useEffect(() => {
      loadingState.on();
      if (previewContent) {
        try {
          void fetchPdf(previewContent).then((pdfPagesList) => {
            setPdfPages(pdfPagesList);
            loadingState.off();
          });
        } catch (error) {
          Logger.handleException(error);
          onError?.(error as ARPlatformError);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previewContent]);

    return (
      <LoadingContainer isLoading={isLoading || isLoadingContent}>
        <Box
          data-component={PDFPreviewer.displayName}
          data-testid="pdf-previewer-container"
          height={isLoading || isLoadingContent ? '50vh' : 'auto'}
          {...props}
          ref={ref}
        >
          <Group variant="vertical" spacing="xs">
            {pdfPages.map((pageImage, index) => (
              <Card
                key={`pdf-page-${index}`}
                paddingX="none"
                paddingY="none"
                data-testid={`pdf-page-${index}`}
                data-component="PDFPage"
              >
                <Container border="regular">
                  <Image src={pageImage} objectFit="contain" alt={`invoice page ${index + 1}`} />
                </Container>
              </Card>
            ))}
          </Group>
        </Box>
      </LoadingContainer>
    );
  }
);
PDFPreviewer.displayName = 'PDFPreviewer';

function convertDataURIToBinary(dataURI: string) {
  const BASE64_MARKER = ';base64,';
  const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  const base64 = dataURI.substring(base64Index);
  const raw = window.atob(base64);
  const rawLength = raw.length;
  const array = new Uint8Array(new ArrayBuffer(rawLength));

  for (let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
}
