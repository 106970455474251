import { useAnalyticsView } from '@melio/platform-analytics';

import { AccountingPlatformSyncScreen } from '../screens';
import { SyncFlowEnum } from '../types';

type Props = {
  flow?: SyncFlowEnum;
  accountingPlatformName: string;
  accountingPlatformNameForAnalytics: string;
  isLoadingAccountingPlatform: boolean;
};

export const SyncStep = ({
  flow,
  accountingPlatformName,
  accountingPlatformNameForAnalytics,
  isLoadingAccountingPlatform,
}: Props) => {
  const defaultAnalyticsFields = {
    PageName: `connecting-to-${accountingPlatformNameForAnalytics}-loader`,
    Flow: `connecting-${accountingPlatformNameForAnalytics}`,
    Intent: `sync`,
    EntryPoint: flow === SyncFlowEnum.Setting ? 'settings-page' : 'dashboard',
  };

  useAnalyticsView('SyncAccountingSoftware', true, true, {
    ...defaultAnalyticsFields,
  });

  return (
    <AccountingPlatformSyncScreen
      accountingPlatformName={accountingPlatformName}
      isLoadingAccountingPlatform={isLoadingAccountingPlatform}
    />
  );
};
