import { useMelioIntl } from '@melio/platform-i18n';

import { CELLS_IDS, PaymentsTabFilter, PaymentsTabSortableCells, PaymentsTabSortableColumn } from './types';

export const usePaymentsTabSortableColumns = () => {
  const { formatMessage } = useMelioIntl();

  const lastActivityColumn: PaymentsTabSortableColumn = {
    label: formatMessage('activities.payDashboard.paymentsTab.table.headers.lastActivity'),
    cellId: CELLS_IDS.UPDATED_AT,
    defaultSortOrder: 'desc',
  };

  const vendorNameColumn: PaymentsTabSortableColumn = {
    label: formatMessage('activities.payDashboard.paymentsTab.table.headers.vendor'),
    cellId: CELLS_IDS.VENDOR_NAME,
    defaultSortOrder: 'asc',
  };

  const debitDateColumn: PaymentsTabSortableColumn = {
    label: formatMessage('activities.payDashboard.paymentsTab.table.headers.debitDate'),
    cellId: CELLS_IDS.DEBIT_DATE,
    defaultSortOrder: 'desc',
  };

  const deliveryDateColumn: PaymentsTabSortableColumn = {
    label: formatMessage('activities.payDashboard.paymentsTab.table.headers.deliveryDate'),
    cellId: CELLS_IDS.DELIVERY_DATE,
    defaultSortOrder: 'desc',
  };

  const amountColumn: PaymentsTabSortableColumn = {
    label: formatMessage('activities.payDashboard.paymentsTab.table.headers.amount'),
    cellId: CELLS_IDS.AMOUNT,
    defaultSortOrder: 'desc',
  };

  const sortableColumns: { [key in PaymentsTabSortableCells]: PaymentsTabSortableColumn } = {
    [CELLS_IDS.UPDATED_AT]: lastActivityColumn,
    [CELLS_IDS.VENDOR_NAME]: vendorNameColumn,
    [CELLS_IDS.DEBIT_DATE]: debitDateColumn,
    [CELLS_IDS.DELIVERY_DATE]: deliveryDateColumn,
    [CELLS_IDS.AMOUNT]: amountColumn,
  };

  const sortableColumnsMobile = (filter: PaymentsTabFilter) => {
    switch (filter) {
      case PaymentsTabFilter.Scheduled:
      case PaymentsTabFilter.InProgress:
        return [debitDateColumn, vendorNameColumn, deliveryDateColumn, amountColumn];
      case PaymentsTabFilter.Paid:
        return [deliveryDateColumn, vendorNameColumn, amountColumn];
      case PaymentsTabFilter.Failed:
        return [debitDateColumn, vendorNameColumn, amountColumn];
      case PaymentsTabFilter.All:
      default:
        return [lastActivityColumn, vendorNameColumn, debitDateColumn, deliveryDateColumn, amountColumn];
    }
  };
  return { sortableColumns, sortableColumnsMobile };
};
