import { Card } from '@melio/platform-api';
import { FormattedMessage } from '@melio/platform-i18n';
import React, { VFC } from 'react';

type CreateCardDetailsScreenTitleProps = {
  cardType?: Card['type'];
};

export const CreateCardDetailsScreenTitle: VFC<CreateCardDetailsScreenTitleProps> = ({ cardType }) => {
  switch (cardType) {
    case 'credit':
      return <FormattedMessage id="activities.addCardFundingSource.screens.createCardDetails.credit.title" />;
    case 'debit':
      return <FormattedMessage id="activities.addCardFundingSource.screens.createCardDetails.debit.title" />;
    default:
      return <FormattedMessage id="activities.addCardFundingSource.screens.createCardDetails.card.title" />;
  }
};
