import { AllowedMccApiClient, AllowedMccApiGetAllowedMccRequest } from '@melio/platform-api-axios-client';

import { useCollection, UseCollectionProps } from '../../api-client';

export type UseAllowedMccProps = UseCollectionProps<typeof AllowedMccApiClient.getAllowedMcc> & {
  cardNetwork: AllowedMccApiGetAllowedMccRequest['cardNetwork'];
};

export const useAllowedMcc = ({ cardNetwork, ...props }: UseAllowedMccProps) =>
  useCollection({
    ...props,
    queryKey: ['AllowedMccApi', cardNetwork],
    queryFn: () => AllowedMccApiClient.getAllowedMcc(cardNetwork),
  });

export type AllowedMccCollection = ReturnType<typeof useAllowedMcc>;
