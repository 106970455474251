/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { PostScannedInvoicesBulkResponse, useScannedInvoices } from '@melio/platform-api';

import { useAppTheme } from '@/cl/theme/theme.hooks';
import { UploadIndicatorTheme } from './UploadIndicator.component.theme';
import { UploadIndicatorItemComponent } from './UploadIndicatorItem.component';
import { UploadIndicatorTitleComponent } from './UploadIndicatorTitle.component';

type UploadIndicatorProps = {
  multipleInvoices?: { results?: PostScannedInvoicesBulkResponse; files?: File[] };
};

export const UploadIndicator = ({ multipleInvoices }: UploadIndicatorProps) => {
  const { isBatchMutating: isUploading, reset } = useScannedInvoices();
  const [minimize, setMinimize] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const style = useAppTheme(UploadIndicatorTheme, { show, minimize });

  useEffect(() => {
    if (isUploading) {
      setShow(true);
    }
  }, [isUploading]);

  const toggleSize = () => setMinimize(!minimize);
  const closeModal = () => {
    setShow(false);
    reset();
  };

  const sharedProps = {
    isMinimized: minimize,
    isUploading: isUploading,
    multipleInvoices,
  };

  return (
    <Box data-testid="upload-indicator-container" css={style.uploadIndicator}>
      <UploadIndicatorTitleComponent onClose={closeModal} onToggle={toggleSize} {...sharedProps} />
      {multipleInvoices?.files?.map((file: File) => (
        <UploadIndicatorItemComponent key={file.name} file={file} {...sharedProps} />
      ))}
    </Box>
  );
};
