import {
  DeliveryMethod,
  DeliveryPreference,
  DeliveryPreferenceType,
  FundingSource,
  PaymentIntent,
  PaymentIntentWithDerivatives,
  useFeesBreakdown,
  usePaymentIntent,
} from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { merge } from 'lodash';

type ValidatedPaymentIntent = Override<
  PaymentIntentWithDerivatives,
  {
    fundingSource: FundingSource;
    deliveryMethod: DeliveryMethod;
    amountToPay: number;
    selectedDeliveryPreferenceType: DeliveryPreferenceType;
  }
>;

export const useDeliveryOptionsWithPromotions = (
  paymentIntent: PaymentIntentWithDerivatives | undefined
): { deliveryOptionsWithPromotions: DeliveryPreference[] | undefined; isLoading: boolean } => {
  const [shouldUseFeesBreakdownApi] = useFeature(FeatureFlags.CheckFeesPromotionEnabled, false);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const isValidParams = (params: PaymentIntentWithDerivatives | undefined): params is ValidatedPaymentIntent =>
    Boolean(
      params?.fundingSource?.type &&
        params?.deliveryMethod?.type &&
        params?.amountToPay &&
        params?.selectedDeliveryPreferenceType
    );

  const enabled = shouldUseFeesBreakdownApi && isValidParams(paymentIntent);

  const { data: feesBreakdown, isLoading: isLoadingFeesBreakdown } = useFeesBreakdown({
    params: {
      feesBreakdownParams: enabled
        ? [
            {
              deliveryMethodType: paymentIntent.deliveryMethod.type,
              deliveryPreference: paymentIntent.selectedDeliveryPreferenceType,
              fundingSourceType: paymentIntent.fundingSource.type,
              paymentAmount: paymentIntent.amountToPay,
              paymentId: paymentIntent.paymentId,
              deliveryMethodId: paymentIntent.deliveryMethodId as string,
              fundingSourceId: paymentIntent.fundingSourceId as string,
            },
          ]
        : [],
    },
    enabled,
  });

  if (!shouldUseFeesBreakdownApi) {
    return { deliveryOptionsWithPromotions: paymentIntent?.deliveryPreferenceOptions, isLoading: false };
  }

  const deliveryOptionsWithPromotions = paymentIntent?.deliveryPreferenceOptions?.map((option) => {
    const optionFee = option.fee;

    if (optionFee) {
      const promotion = feesBreakdown?.feesBreakdown.find((fee) => fee.type === optionFee.type);
      return { ...option, fee: { type: optionFee.type, amount: promotion?.amountToPay ?? optionFee.amount } };
    }

    return option;
  });

  return { deliveryOptionsWithPromotions, isLoading: isLoadingFeesBreakdown };
};

export const usePaymentIntentWithUpdates = (id: PaymentIntent['id']) => {
  const scope = 'delivery-date-activity';
  const { data: paymentIntent, isLoading } = usePaymentIntent({ id });
  const { data, update, isUpdating } = usePaymentIntent({ id, scope, enabled: false, optimisticUpdate: true });
  merge(paymentIntent, data);
  return { paymentIntent, isLoading, update, isUpdating };
};
