import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';

import { AccountingPlatformSelectOrganizationScreen } from '../screens';
import { ExternalOrganizations } from '../types';

type Props = {
  isSubmitting: boolean;
  externalOrganizations: ExternalOrganizations;
  accountingPlatformName: string;
  onCancel: VoidFunction;
  onSubmitOrganization: (externalOrganizationId: string) => void;
};

export const SelectOrganizationStep = ({
  isSubmitting,
  accountingPlatformName,
  externalOrganizations = [],
  onCancel,
  onSubmitOrganization,
}: Props) => {
  const { createTrackHandler } = useAnalytics();
  const trackAndHandleClick = createTrackHandler<{
    Cta: 'exit' | 'connect';
  }>('SyncAccountingSoftware', 'Click');

  useAnalyticsView('connect-to-accounting-software-multiple-connections');

  const onCloseClick = () => {
    trackAndHandleClick({ Cta: 'exit' }, onCancel);
  };

  const onSubmitClick = (externalOrganizationId: string) => {
    trackAndHandleClick({ Cta: 'connect' }, onSubmitOrganization(externalOrganizationId));
  };

  return (
    <AccountingPlatformSelectOrganizationScreen
      isSubmitting={isSubmitting}
      externalOrganizations={externalOrganizations}
      accountingPlatformName={accountingPlatformName}
      onClose={onCloseClick}
      onSubmitOrganization={onSubmitClick}
    />
  );
};
