import { useState } from 'react';
import { StatusModal, Text } from '@melio/penny';
import { PaymentStatusEnum, usePayments } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';

const nullifyEmptyString = (value: unknown) => {
  if (value === '') {
    return null;
  }
  if (value === undefined) {
    return null;
  }

  return value;
};

type PartialFormData = Record<string, unknown>;

const canChangesAffectVendorManagedState = (formData1: PartialFormData, formData2: PartialFormData) => {
  const fields = new Set([...Object.keys(formData1), ...Object.keys(formData2)]);
  fields.delete('nickname');

  return Array.from(fields).some((key) => nullifyEmptyString(formData1[key]) !== nullifyEmptyString(formData2[key]));
};

export const useConfirmVendorUpdate = ({
  vendorId,
  initialFormData,
}: {
  vendorId: string;
  initialFormData: PartialFormData | undefined;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    settings: {
      vendor: { warnEditingVendorWithFuturePayments },
    },
  } = useConfig();

  const { data, isLoading } = usePayments({
    params: {
      search: {
        'vendor.id': vendorId,
        'payment.status': [PaymentStatusEnum.Scheduled],
      },
      limit: 1,
    },
  });

  const vendorMightHaveFuturePayments = !data || data.length > 0;

  const shouldShowConfirm = (formData: PartialFormData) => {
    if (!warnEditingVendorWithFuturePayments) {
      return false;
    }

    if (vendorMightHaveFuturePayments && canChangesAffectVendorManagedState(initialFormData ?? {}, formData)) {
      return true;
    }

    return false;
  };

  return {
    isLoading,
    shouldShowConfirm,
    onClose: () => setIsOpen(false),
    onOpen: () => setIsOpen(true),
    isOpen,
  };
};

export const ConfirmVendorUpdateModal = ({
  isSubmitting,
  submitForm,
  onClose,
  isOpen,
}: {
  isSubmitting: boolean;
  submitForm: () => void;
  onClose: () => void;
  isOpen: boolean;
}) => {
  const { formatMessage } = useMelioIntl();

  return (
    <StatusModal
      variant="warning"
      header={formatMessage(`widgets.vendorDetails.form.confirmModal.header`)}
      primaryButton={{
        variant: 'primary',
        label: formatMessage(`widgets.vendorDetails.form.confirmModal.primaryButton`),
        isLoading: isSubmitting,
        onClick: submitForm,
      }}
      secondaryButton={{
        variant: 'secondary',
        label: formatMessage(`widgets.vendorDetails.form.confirmModal.secondaryButton`),
        isDisabled: isSubmitting,
        onClick: onClose,
      }}
      onClose={onClose}
      isOpen={isOpen}
    >
      <Text>{formatMessage(`widgets.vendorDetails.form.confirmModal.description`)}</Text>
    </StatusModal>
  );
};
