import { Box } from '@chakra-ui/react';
import { Form, Group, Text, useWatch } from '@melio/penny';
import { ApprovalWorkflowRequest, RoleUniqueNames, useCollaborators } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

import { useFormContext } from '../FormContext';
import { CollaboratorsSelector } from './CollaboratorsSelector';

export const PaymentSchedulerCondition: React.FC<{ index: number }> = ({ index }) => {
  const { formatMessage } = useMelioIntl();
  const { registerField, setValue, control } = useFormContext();
  const config = useWatch<ApprovalWorkflowRequest, `criteria.conditions.${number}.condition`>({
    name: `criteria.conditions.${index}.condition`,
    control,
  });
  const { data } = useCollaborators({
    expand: ['user'],
    suspense: true,
    select(res) {
      return res.filter(({ approvalActions: { canApprovePayments } }) => !canApprovePayments);
    },
  });

  const onSelectConfiguration = (value: React.ChangeEvent<HTMLInputElement>): void => {
    if (value.target.value === 'role') {
      setValue(
        `criteria.conditions.${index}.condition`,
        {
          type: 'workflowCollaboratorsCondition',
          configuration: {
            filterBy: 'role',
            roles: [RoleUniqueNames.MelioAccountant, RoleUniqueNames.MelioContributor],
          },
        },
        {
          shouldValidate: true,
        }
      );
    } else if (value.target.value === 'user') {
      setValue(
        `criteria.conditions.${index}.condition`,
        {
          type: 'workflowCollaboratorsCondition',
          configuration: {
            filterBy: 'user',
            ids: [],
          },
        },
        {
          shouldValidate: true,
        }
      );
    }
  };
  return (
    <Group variant="horizontal" alignItems="center">
      <Box>
        <Text>{formatMessage('activities.create-approval-workflows.conditions.scheduler.label')}</Text>
      </Box>
      <Box width="280px">
        <Form.Select
          {...registerField(`criteria.conditions.${index}.condition.configuration.filterBy`)}
          onChange={onSelectConfiguration}
          emptyState={{ label: '' }}
          data-testid="scheduler-condition-type-list"
          size="large"
          aria-label="scheduler"
          options={[
            ...(data?.length
              ? [
                  {
                    label: formatMessage(
                      'activities.create-approval-workflows.conditions.scheduler.options.specific.title'
                    ),
                    value: 'user',
                    testId: 'scheduler-condition-type-user',
                    description: formatMessage(
                      'activities.create-approval-workflows.conditions.scheduler.options.specific.description'
                    ),
                  },
                ]
              : []),

            {
              label: formatMessage('activities.create-approval-workflows.conditions.scheduler.options.any-role.title'),
              value: 'role',
              testId: 'scheduler-condition-type-role',
              description: formatMessage(
                'activities.create-approval-workflows.conditions.scheduler.options.any-role.description'
              ),
            },
          ]}
        ></Form.Select>
      </Box>
      {config?.type === 'workflowCollaboratorsCondition' && config?.configuration.filterBy === 'user' && (
        <Box width="280px">
          <CollaboratorsSelector index={index} />
        </Box>
      )}
    </Group>
  );
};
