import { FormWidgetProps, useMelioIntl } from '@melio/ar-domain';
import { Form, useMelioForm } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { object, SchemaOf, string } from 'yup';

import { CardHolderNameDetails } from '../../types';

const useSchema = () => {
  const { formatMessage } = useMelioIntl();

  return object().shape({
    firstName: string()
      .required(formatMessage('ar.guestPayment.activities.cardHolder.form.fields.firstName.required.text'))
      .min(2, formatMessage('ar.guestPayment.activities.cardHolder.form.fields.firstName.minChars.text')),
    lastName: string()
      .required(formatMessage('ar.guestPayment.activities.cardHolder.form.fields.lastName.required.text'))
      .min(2, formatMessage('ar.guestPayment.activities.cardHolder.form.fields.lastName.minChars.text')),
  }) as SchemaOf<CardHolderNameDetails>;
};

export type CardHolderNameDetailsFormProps = FormWidgetProps<CardHolderNameDetails>;

export const CardHolderNameDetailsForm = forwardRef<CardHolderNameDetailsFormProps, 'form'>(
  ({ onSubmit, onSubmissionStateChange, isSaving, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { formProps, registerField } = useMelioForm<CardHolderNameDetails>({
      onSubmit,
      schema: useSchema(),
      isSaving,
      onSubmissionStateChange,
    });

    return (
      <Form
        data-component="CardHolderNameDetailsForm"
        data-testid="card-holder-name-details-form"
        {...props}
        {...formProps}
        columns={2}
        ref={ref}
      >
        <Form.TextField
          labelProps={{ label: formatMessage('ar.guestPayment.activities.cardHolder.form.fields.firstName.label') }}
          {...registerField('firstName')}
        />
        <Form.TextField
          labelProps={{ label: formatMessage('ar.guestPayment.activities.cardHolder.form.fields.lastName.label') }}
          {...registerField('lastName')}
        />
      </Form>
    );
  }
);
CardHolderNameDetailsForm.displayName = 'CardHolderNameDetailsForm';
