import { ARInvoice, FormattedMessage, useMelioIntl } from '@melio/ar-domain';
import { Group, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';

type InvoiceLineItemsProps = {
  invoiceLineItems: ARInvoice['invoiceLineItems'];
};

export const InvoiceLineItemsDetails = forwardRef<InvoiceLineItemsProps>(({ invoiceLineItems = [] }, ref) => {
  const { formatCurrency } = useMelioIntl();

  return (
    <Group variant="vertical" spacing="s" ref={ref}>
      {invoiceLineItems.map((lineItem) => (
        <Group key={lineItem.id} variant="vertical" spacing="xxs" data-testid={`line-item-group-${lineItem.id}`}>
          <Group justifyContent="space-between">
            <Text textStyle="body2" data-testid="line-item-name">
              {lineItem.name}
            </Text>
            <Text textStyle="body2" data-testid="line-item-calculate-value">
              {formatCurrency(lineItem.quantity * lineItem.price)}
            </Text>
          </Group>
          {lineItem.quantity > 1 && (
            <Text data-testid="line-item-multiple-quantity-row-text" textStyle="body4" color="global.neutral.800">
              <FormattedMessage
                id="ar.dashboard.activities.InvoiceDrawer.body.summary.lineItem.multipleQuantity.text"
                values={{
                  price: formatCurrency(lineItem.price),
                  quantity: lineItem.quantity,
                }}
              />
            </Text>
          )}
        </Group>
      ))}
    </Group>
  );
});
