import { Group, Modal, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { useSubscribeToFreePlan } from '../../../hooks';
import { LostFeatureDetails } from './LostFeatureDetails';

export type SubscriptionGraceSwitchPlanConfirmationProps = {
  isOpen: boolean;
  onDismiss: () => void;
  planName: string;
};

export const SubscriptionGraceSwitchPlanConfirmation = ({
  isOpen,
  onDismiss,
  planName,
}: SubscriptionGraceSwitchPlanConfirmationProps) => {
  const { formatMessage } = useMelioIntl();
  const { subscribeToFreePlan, isSubscribingToFreePlan } = useSubscribeToFreePlan();

  const featureDetails = [
    formatMessage('activities.subscription.graceSwitchPlanConfirmationModal.feature.accountantAndUsers'),
    formatMessage('activities.subscription.graceSwitchPlanConfirmationModal.feature.paymentNeedApproval'),
    formatMessage('activities.subscription.graceSwitchPlanConfirmationModal.feature.advancedBill'),
    formatMessage('activities.subscription.graceSwitchPlanConfirmationModal.feature.twoWaySync'),
    formatMessage('activities.subscription.graceSwitchPlanConfirmationModal.feature.qboDesktop'),
    formatMessage('activities.subscription.graceSwitchPlanConfirmationModal.feature.paymentsBatch'),
    formatMessage('activities.subscription.graceSwitchPlanConfirmationModal.feature.vendorReporting'),
    formatMessage('activities.subscription.graceSwitchPlanConfirmationModal.feature.combinedPayments'),
    formatMessage('activities.subscription.graceSwitchPlanConfirmationModal.feature.premiumSupport'),
  ];

  const onConfirmClick = () => {
    void subscribeToFreePlan();
    onDismiss();
  };

  return (
    <Modal
      isOpen={isOpen}
      size="small"
      header={formatMessage('activities.subscription.graceSwitchPlanConfirmationModal.title', {
        planName,
      })}
      onClose={onDismiss}
      primaryButton={{
        label: formatMessage('activities.subscription.graceSwitchPlanConfirmationModal.confirm'),
        onClick: onConfirmClick,
        variant: 'primary',
        isDisabled: isSubscribingToFreePlan,
      }}
      secondaryButton={{
        variant: 'tertiary',
        label: formatMessage('activities.subscription.graceSwitchPlanConfirmationModal.cancel'),
        onClick: onDismiss,
        isDisabled: isSubscribingToFreePlan,
      }}
      data-testid="subscription-grace-switch-confirmation-modal"
    >
      <Group variant="vertical">
        <Text>{formatMessage('activities.subscription.graceSwitchPlanConfirmationModal.featuresYouWillLose')}</Text>
        <Group spacing="xs" variant="vertical" width="full">
          {featureDetails.map((details) => (
            <LostFeatureDetails label={details} key={details} />
          ))}
        </Group>
      </Group>
    </Modal>
  );
};
