import { isFXCurrency } from '@melio/ap-domain';
import { Table, Text } from '@melio/penny';
import { Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export const PaymentAmountCell = ({ payment }: { payment: Payment }) => {
  const { formatCurrency } = useMelioIntl();

  const isFxPayment = isFXCurrency(payment.currency);
  const formattedAmount = formatCurrency(
    isFxPayment && payment.foreignAmount ? payment.foreignAmount : payment.amount,
    payment.currency
  );

  return (
    <Table.Cell data-testid="payments-late-approval-row-payment-amount-cell" textAlign="end">
      <Text textStyle="body3Semi">{formattedAmount}</Text>
    </Table.Cell>
  );
};
