import React from 'react';
import { BankAccountFormModel } from '@melio/ap-widgets';

import { FullScreenWizard } from '@/cl/components/FullScreenWizard/FullScreenWizard.component';
import { ViewManualBankAccount } from '@/widgets/viewManualBankAccount/ViewManualBankAccount.widget';

type ViewManualBankAccountScreenProps = {
  onClose: () => void;
  onDone: () => void;
  defaultValues: BankAccountFormModel;
};

export const ViewManualBankAccountScreen = ({ onClose, onDone, defaultValues }: ViewManualBankAccountScreenProps) => {
  return (
    <FullScreenWizard onClickClose={onClose}>
      <ViewManualBankAccount onDone={onDone} defaultValues={defaultValues} />
    </FullScreenWizard>
  );
};

ViewManualBankAccountScreen.displayName = 'ViewManualBankAccountScreen';
