import { getRegularDeliveryPreference } from '@melio/ap-domain';
import {
  FundingSource,
  InvoiceDataSourceEntityTypeEnum,
  useFinancingRepaymentTerms,
  useFundingSources,
  usePaymentIntent,
  usePaymentSettings,
  useSelectFinancingRepaymentTerm,
} from '@melio/platform-api';
import { useEffect, useRef, useState } from 'react';

import { AddBankAccountPlaidActivity } from '../../funding-sources';
import { RepaymentTermsLoadingScreen, RepaymentTermsScreen } from './screens';
import { RepaymentTermsActivityProps, SelectedRepaymentOptionFormSchema } from './types';
import { useRepaymentTermsActivityStep } from './useRepaymentTermsActivityStep';

export const RepaymentTermsActivity = ({
  step,
  totalSteps,
  paymentIntentId,
  onBack,
  onClose,
  onError,
  onDone: _onDone,
  setSelectedRepaymentOption,
  selectedRepaymentOption,
}: RepaymentTermsActivityProps) => {
  const { currentStep, goToStep, goToPreviousStep } = useRepaymentTermsActivityStep({ onFirstStepBack: onBack });
  const {
    data: basicPaymentIntent,
    isLoading: isPaymentIntentLoading,
    update: updatePaymentIntent,
  } = usePaymentIntent({ id: paymentIntentId });
  const { data: repaymentTerms } = useFinancingRepaymentTerms({
    paymentAmount: basicPaymentIntent?.amountToPay ?? 0,
    vendorId: basicPaymentIntent?.billInfo.vendorId ?? '',
  });
  const { data: fundingSources } = useFundingSources();

  const { selectRepaymentTerm, isMutating: isLoadingSelectRepaymentTerms } = useSelectFinancingRepaymentTerm({
    eligibilityToken: repaymentTerms?.eligibilityToken ?? '',
  });
  const isSelectedDefaultRepaymentOption = useRef<boolean>(false);
  const [selectedNumOfInstallments, setSelectedNumOfInstallments] = useState<number>();
  const [selectedDate, setSelectedDate] = useState(basicPaymentIntent?.scheduledDate ?? undefined);
  const { data: paymentSettings } = usePaymentSettings({
    payload: [
      {
        amountToPay: basicPaymentIntent?.amountToPay ?? 0,
        vendorId: basicPaymentIntent?.billInfo.vendorId ?? '',
        isRecurring: false,
        isFinancing: true,
        scheduleDate: selectedDate as Date,
        fundingSourceId: basicPaymentIntent?.fundingSourceId ?? undefined,
        deliveryMethodId: basicPaymentIntent?.deliveryMethodId ?? undefined,
        invoicesData: [
          {
            amount: basicPaymentIntent?.amountToPay ?? 0,
            invoiceNumber: basicPaymentIntent?.billInfo.invoice.number ?? null,
            ...(basicPaymentIntent?.billInfo.id
              ? {
                  sourceEntity: {
                    id: basicPaymentIntent.billInfo.id,
                    type: InvoiceDataSourceEntityTypeEnum.Bill,
                  },
                }
              : {}),
          },
        ],
      },
    ],
    enabled: !!selectedDate,
    params: {
      fillWithDefaults: true,
    },
  });

  const onSelectedDateChanged = (date?: Date) => {
    date && setSelectedDate(date);
    if (date && repaymentTerms && basicPaymentIntent) {
      selectRepaymentTerm({
        eligibilityToken: repaymentTerms.eligibilityToken ?? '',
        paymentAmount: basicPaymentIntent.amountToPay ?? 0,
        numOfInstallments: repaymentTerms.options?.[0]?.numOfInstallments ?? 1,
        scheduleDate: date,
        providerName: repaymentTerms.providerName ?? 'CreditKey',
      }).then((res) => {
        setSelectedRepaymentOption(res);
      });
    }
  };

  useEffect(() => {
    if (basicPaymentIntent?.financingNumberOfInstallments && !selectedNumOfInstallments) {
      setSelectedNumOfInstallments(basicPaymentIntent.financingNumberOfInstallments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicPaymentIntent]);

  useEffect(() => {
    if (
      repaymentTerms?.eligibilityToken &&
      basicPaymentIntent?.amountToPay &&
      basicPaymentIntent?.scheduledDate &&
      repaymentTerms.providerName &&
      repaymentTerms.options?.[0]?.numOfInstallments &&
      !selectedRepaymentOption &&
      !isSelectedDefaultRepaymentOption.current
    ) {
      isSelectedDefaultRepaymentOption.current = true;
      selectRepaymentTerm({
        eligibilityToken: repaymentTerms.eligibilityToken,
        paymentAmount: basicPaymentIntent?.amountToPay,
        numOfInstallments: repaymentTerms.options[0].numOfInstallments,
        scheduleDate: basicPaymentIntent.scheduledDate,
        providerName: repaymentTerms.providerName,
      }).then((res) => setSelectedRepaymentOption(res));
    }
  }, [
    isSelectedDefaultRepaymentOption,
    selectedRepaymentOption,
    repaymentTerms,
    basicPaymentIntent,
    selectRepaymentTerm,
    setSelectedRepaymentOption,
  ]);

  const onPressContinue = async () => {
    if (
      repaymentTerms?.eligibilityToken &&
      basicPaymentIntent?.amountToPay &&
      selectedNumOfInstallments &&
      basicPaymentIntent?.scheduledDate &&
      repaymentTerms.providerName
    ) {
      const selectedRepaymentOptionRes = await selectRepaymentTerm({
        eligibilityToken: repaymentTerms.eligibilityToken,
        paymentAmount: basicPaymentIntent?.amountToPay,
        numOfInstallments: selectedNumOfInstallments,
        scheduleDate: selectedDate || basicPaymentIntent.scheduledDate,
        providerName: repaymentTerms.providerName,
      });

      setSelectedRepaymentOption(selectedRepaymentOptionRes);
    }
  };

  const onNext = (data: SelectedRepaymentOptionFormSchema) => {
    if (
      repaymentTerms?.eligibilityToken &&
      basicPaymentIntent?.amountToPay &&
      selectedNumOfInstallments &&
      basicPaymentIntent?.scheduledDate &&
      repaymentTerms.providerName
    ) {
      _onDone(
        repaymentTerms.eligibilityToken,
        selectedNumOfInstallments,
        repaymentTerms.providerName,
        data.repaymentFundingSourceId,
        selectedDate || basicPaymentIntent.scheduledDate
      );
    }
  };

  const onAddBankAccount = (fundingSource: FundingSource) => {
    updatePaymentIntent({
      fundingSourceId: fundingSource.id,
      financingNumberOfInstallments: selectedNumOfInstallments,
    }).then(() => {
      goToStep('REPAYMENT_TERMS');
    });
  };

  if (isPaymentIntentLoading) {
    return <RepaymentTermsLoadingScreen onBack={onBack} onClose={onClose} />;
  }

  const regularDeliveryPreference = paymentSettings?.[0]?.deliveryPreferenceOptions
    ? getRegularDeliveryPreference(paymentSettings?.[0]?.deliveryPreferenceOptions)
    : undefined;

  switch (currentStep) {
    case 'REPAYMENT_TERMS':
    default:
      return (
        <RepaymentTermsScreen
          deliveryPreferenceOption={regularDeliveryPreference}
          selectedDate={selectedDate}
          setSelectedDate={onSelectedDateChanged}
          selectedRepaymentTerm={selectedRepaymentOption}
          paymentIntent={basicPaymentIntent}
          repaymentFundingSources={fundingSources}
          repaymentTerms={repaymentTerms}
          step={step}
          totalSteps={totalSteps}
          scheduledDate={basicPaymentIntent?.scheduledDate}
          onBack={onBack}
          onClose={onClose}
          onNext={onNext}
          onPressContinue={onPressContinue}
          onSelect={setSelectedNumOfInstallments}
          selectedNumOfInstallments={selectedNumOfInstallments}
          onAddBankAccount={() => goToStep('ADD_ACH_PLAID')}
          isLoadingSelectRepaymentTerms={isLoadingSelectRepaymentTerms}
        />
      );

    case 'ADD_ACH_PLAID':
      return (
        <AddBankAccountPlaidActivity
          onClose={goToPreviousStep}
          onBack={goToPreviousStep}
          onDone={(fundingSource) => onAddBankAccount(fundingSource as FundingSource)}
          onError={onError}
        />
      );
  }
};
