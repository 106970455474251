import { Group } from '@melio/penny';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { ApprovalWorkflow as ApprovalWorkflowEntity, useApprovalWorkflows } from '@melio/platform-api';
import { useState } from 'react';

import { ApprovalWorkflowCard } from './ApprovalWorkflowCard';
import { DeleteWorkflowModal } from './DeleteWorkflowModal.activity';
import { EmptyState } from './EmptyState';

type OnEditProps = { action: 'edit'; meta: { id: string } };
type OnAddProps = { action: 'add' };
export type OnActionProps = OnEditProps | OnAddProps;
export const ApprovalWorkflowsList = withAnalyticsContext<{ onAction: (props: OnActionProps) => void }>(
  ({ onAction, setAnalyticsProperties }) => {
    const [workflowIdToDelete, setWorkflowIdToDelete] = useState<ApprovalWorkflowEntity['id'] | null>(null);

    const { data: workflows } = useApprovalWorkflows({
      refetchOnMount: 'always',
      useErrorBoundary: true,
      suspense: true,
    });

    setAnalyticsProperties({
      PageName: 'payment-approval-workflow',
      Flow: 'approval-workflow',
      CountInTab: workflows?.length,
    });

    return (
      <>
        <Group variant="vertical">
          {workflows?.length ? (
            workflows.map((workflow) => (
              <ApprovalWorkflowCard
                key={workflow.id}
                workflow={workflow}
                onDeleteClick={({ id }) => setWorkflowIdToDelete(id)}
                onEditClick={({ id }) => onAction({ action: 'edit', meta: { id } })}
              />
            ))
          ) : (
            <EmptyState />
          )}
        </Group>
        {workflowIdToDelete && (
          <DeleteWorkflowModal
            workflowId={workflowIdToDelete}
            isOpen={!!workflowIdToDelete}
            onClose={() => setWorkflowIdToDelete(null)}
          />
        )}
      </>
    );
  }
);
