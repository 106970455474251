import { Form, useMelioForm } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';
import { date, number, object, SchemaOf, string } from 'yup';

import { ScannedInvoiceDetailsFormFields, ScannedInvoiceDetailsWidgetProps } from '../types';

export type ScannedInvoiceDetailsFormProps = {
  scannedInvoice: Partial<ScannedInvoiceDetailsWidgetProps['scannedInvoice']>;
};

export const useSchema = () =>
  object().shape({
    vendorName: string(),
    amount: number(),
    creationDate: date(),
    dueDate: date().nullable(),
    invoiceNumber: string(),
    note: string(),
  }) as SchemaOf<ScannedInvoiceDetailsFormFields>;

export const ScannedInvoiceDetailsForm = forwardRef<ScannedInvoiceDetailsFormProps, 'form'>(
  ({ scannedInvoice, ...props }, ref) => {
    const defaultValues = {
      vendorName: scannedInvoice.vendorName || '',
      amount: scannedInvoice.amount || undefined,
      creationDate: scannedInvoice.history?.createdAt,
      dueDate: scannedInvoice.dueDate || undefined,
      invoiceNumber: scannedInvoice.invoiceNumber || '',
      note: scannedInvoice.note || '',
    };

    const { formatMessage } = useMelioIntl();

    const { formProps, registerField } = useMelioForm<ScannedInvoiceDetailsFormFields>({
      onSubmit: () => undefined,
      schema: useSchema(),
      defaultValues,
      onSubmissionStateChange: () => undefined,
    });

    return (
      <Form data-component="ScannedInvoiceDetailsForm" size="small" isViewMode ref={ref} {...props} {...formProps}>
        <Form.TextField
          {...registerField('vendorName')}
          labelProps={{ label: formatMessage('widgets.scannedInvoiceDetailsForm.form.vendor.label') }}
          viewModePlaceholder={formatMessage('widgets.scannedInvoiceDetailsForm.form.vendor.viewModePlaceholder')}
        />
        <Form.AmountField
          {...registerField('amount')}
          labelProps={{ label: formatMessage('widgets.scannedInvoiceDetailsForm.form.totalAmount.label') }}
          viewModePlaceholder={formatMessage('widgets.scannedInvoiceDetailsForm.form.totalAmount.viewModePlaceholder')}
        />
        <Form.DateField
          {...registerField('creationDate')}
          labelProps={{ label: formatMessage('widgets.scannedInvoiceDetailsForm.form.creationDate.label') }}
        />
        <Form.DateField
          {...registerField('dueDate')}
          labelProps={{ label: formatMessage('widgets.scannedInvoiceDetailsForm.form.dueDate.label') }}
          placeholder={formatMessage('widgets.scannedInvoiceDetailsForm.form.dueDate.placeholder')}
          viewModePlaceholder={formatMessage('widgets.scannedInvoiceDetailsForm.form.dueDate.viewModePlaceholder')}
        />
        <Form.TextField
          {...registerField('invoiceNumber')}
          labelProps={{ label: formatMessage('widgets.scannedInvoiceDetailsForm.form.billNumber.label') }}
          placeholder={formatMessage('widgets.scannedInvoiceDetailsForm.form.billNumber.placeholder')}
          viewModePlaceholder={formatMessage('widgets.scannedInvoiceDetailsForm.form.billNumber.viewModePlaceholder')}
        />
      </Form>
    );
  }
);

ScannedInvoiceDetailsForm.displayName = 'ScannedInvoiceDetailsForm';
