import { Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export const CollaboratorDrawerHeader = () => {
  const { formatMessage } = useMelioIntl();

  return (
    <Group data-testid="collaborator-drawer-header" variant="horizontal" spacing="xxs" alignItems="center">
      <Text as="h2" textStyle="heading2Semi">
        {formatMessage('activities.collaboratorDrawer.header.title')}
      </Text>
    </Group>
  );
};
