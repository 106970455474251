import { useCollectionApi, UseCollectionApiProps } from '@melio/api-client';
import { SignaturesApiClient } from '@melio/platform-api-axios-client';

import { useSignaturesApiClient } from './utils';

export type UseSignaturesProps = UseCollectionApiProps<typeof SignaturesApiClient.getSignatures> & {
  agreementId?: string;
};

export const useSignatures = ({ agreementId, ...props }: UseSignaturesProps = {}) => {
  const ApiClient = useSignaturesApiClient({ agreementId });

  return useCollectionApi({
    ...props,
    enabled: !!agreementId && props.enabled,
    queryKey: ['SignaturesApi', agreementId],
    queryFn: ApiClient.getSignatures,
    createFn: ApiClient.postSignatures,
    deleteFn: ApiClient.deleteSignaturesSignatureId,
  });
};

export type SignatureCollection = ReturnType<typeof useSignatures>;
