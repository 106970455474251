import { useModelApi, UseModelApiProps } from '@melio/api-client';
import { OrganizationPreference, OrganizationPreferencesApiClient } from '@melio/platform-api-axios-client';

import { GetFn } from './types';

export type UseOrganizationPreferenceProps = UseModelApiProps<GetFn, OrganizationPreference>;

export const useOrganizationPreference = (props: UseOrganizationPreferenceProps = {}) =>
  useModelApi({
    ...props,
    queryKey: 'OrganizationPreferencesApi',
    queryFn: () =>
      OrganizationPreferencesApiClient.getOrganizationPreferencesOrganizationPreferenceKey(props.id as string),
    updateFn: (_id: string, { key, value }: OrganizationPreference) =>
      OrganizationPreferencesApiClient.setOrganizationPreferences({ organizationPreference: { key, value } }),
  });

export type OrganizationPreferenceModel = ReturnType<typeof useOrganizationPreference>;
