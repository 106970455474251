import { useMelioIntl } from '@melio/platform-i18n';
import { string } from 'yup';

import {
  ACCOUNT_NUMBER_MAX_LENGTH,
  ACCOUNT_NUMBER_MIN_LENGTH,
  ROUTING_NUMBER_LENGTH,
  validateRoutingNumber,
  ValidationError,
} from '../bank-details/util/bank-account-util';

const isDigitsOnly = (value = '') => /^\d+$/.test(value);

export const useRoutingNumberSchema = () => {
  const { formatMessage } = useMelioIntl();

  return string()
    .required(
      formatMessage('widgets.bankDetailsForm.fields.routingNumber.validation.required', {
        length: ROUTING_NUMBER_LENGTH,
      })
    )
    .test('routingNumber', '', (value, testContext) => {
      const routingNumberValidation = validateRoutingNumber(value);
      if (routingNumberValidation.valid) {
        return true;
      }
      switch (routingNumberValidation.error) {
        case ValidationError.INVALID_CHARACTERS:
          return testContext.createError({
            message: formatMessage('widgets.bankDetailsForm.fields.routingNumber.validation.digitsOnly'),
          });
        case ValidationError.INVALID_LENGTH:
          return testContext.createError({
            message: formatMessage('widgets.bankDetailsForm.fields.routingNumber.validation.invalidLength', {
              length: ROUTING_NUMBER_LENGTH,
            }),
          });
      }
      return testContext.createError({
        message: formatMessage('widgets.bankDetailsForm.fields.routingNumber.validation.invalidNumber', {
          length: ROUTING_NUMBER_LENGTH,
        }),
      });
    });
};

export const useAccountNumberSchema = () => {
  const { formatMessage } = useMelioIntl();

  return string()
    .required(
      formatMessage('widgets.bankDetailsForm.fields.accountNumber.validation.required', {
        minLength: ACCOUNT_NUMBER_MIN_LENGTH,
        maxLength: ACCOUNT_NUMBER_MAX_LENGTH,
      })
    )
    .test(
      'digitsOnly',
      formatMessage('widgets.bankDetailsForm.fields.accountNumber.validation.digitsOnly'),
      isDigitsOnly
    )
    .min(
      ACCOUNT_NUMBER_MIN_LENGTH,
      formatMessage('widgets.bankDetailsForm.fields.accountNumber.validation.invalidMinLength', {
        minLength: ACCOUNT_NUMBER_MIN_LENGTH,
        maxLength: ACCOUNT_NUMBER_MAX_LENGTH,
      })
    )
    .max(
      ACCOUNT_NUMBER_MAX_LENGTH,
      formatMessage('widgets.bankDetailsForm.fields.accountNumber.validation.invalidMaxLength', {
        minLength: ACCOUNT_NUMBER_MIN_LENGTH,
        maxLength: ACCOUNT_NUMBER_MAX_LENGTH,
      })
    );
};
