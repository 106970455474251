import { Box } from '@chakra-ui/react';
import { usePaymentSchedulingPreference } from '@melio/ap-domain';
import { Group, Icon, Text, Tooltip } from '@melio/penny';
import { DeliveryPreference, DeliveryPreferenceType } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { useDeliveryPreferenceMessages } from '../DeliverySpeedCell.utils';

type DeliverySpeedProps = {
  selectedDeliveryPreference: DeliveryPreference;
};
export const DeliverySpeed = ({ selectedDeliveryPreference }: DeliverySpeedProps) => {
  const { formatMessage } = useMelioIntl();
  const { isByDeliveryDate } = usePaymentSchedulingPreference();
  const { getLabel } = useDeliveryPreferenceMessages();
  const { totalBusinessDays, totalMaxBusinessDays, type } = selectedDeliveryPreference;
  const shouldShowVirtualTooltip = isByDeliveryDate && type === DeliveryPreferenceType.Virtual;

  return (
    <Group spacing="xs" alignItems="center">
      <Text color="inherit" textStyle="inline" shouldSupportEllipsis>
        {getLabel(selectedDeliveryPreference)}
      </Text>
      {shouldShowVirtualTooltip ? (
        <Tooltip
          data-testid="delivery-speed-cell-icon-tooltip"
          label={formatMessage(
            'activities.batchPayments.screens.paymentIntentsTable.deliverySpeedCell.virtual.tooltip.label',
            { totalBusinessDays, totalMaxBusinessDays }
          )}
        >
          <Box as={Icon} type="info" size="small" data-testid="delivery-speed-cell-icon" tabIndex={0} />
        </Tooltip>
      ) : null}
    </Group>
  );
};
