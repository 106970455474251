import { Vendor } from '@melio/platform-api';
import { useEffect, useMemo, useState } from 'react';

import { DeliveryMethodSelectionActivityProps, DeliveryMethodSelectionStep } from './types';

export const useDeliveryMethodSelectionActivityStep = ({
  onFirstStepBack,
  vendor,
  billCurrency,
}: {
  onFirstStepBack: DeliveryMethodSelectionActivityProps['onBack'];
  vendor?: Vendor;
  billCurrency?: string;
}) => {
  const [currentStep, goToStep] = useState<DeliveryMethodSelectionStep>();

  const isPaymentCurrencyNotUsd = useMemo(
    () => (vendor?.currency && vendor?.currency !== 'USD') || (billCurrency && billCurrency !== 'USD'),
    [billCurrency, vendor]
  );

  useEffect(() => {
    goToStep(isPaymentCurrencyNotUsd ? 'ADD_VENDOR_INTERNATIONAL_FX_DETAILS' : 'DELIVERY_METHOD_SELECTION');
  }, [isPaymentCurrencyNotUsd]);

  const goToPreviousStep = () => {
    if (currentStep === 'DELIVERY_METHOD_SELECTION' || isPaymentCurrencyNotUsd) {
      onFirstStepBack();
    } else {
      goToStep('DELIVERY_METHOD_SELECTION');
    }
  };

  return { currentStep, goToStep, goToPreviousStep };
};
