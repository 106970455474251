import { Link, Table } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { LoansTabItem } from '../types';

type Props = {
  loansTabItem: LoansTabItem;
  onViewClick: ({ id }: { id: LoansTabItem['id'] }) => void;
};

export const FinancingTabActionButtonCell = ({ loansTabItem, onViewClick }: Props) => {
  const { formatMessage } = useMelioIntl();

  const { id } = loansTabItem;

  return (
    <Table.Cell textAlign="end" data-testid={`financing-tab-row-${id}-action-button-cell`}>
      <Link
        isBold
        variant="standalone"
        size="medium"
        color="secondary"
        onClick={(e) => {
          e.stopPropagation();
          onViewClick({ id });
        }}
        href="#"
        label={formatMessage('activities.payDashboard.financingTab.table.cells.actions.view')}
      />
    </Table.Cell>
  );
};
