import { VendorDetailsFormFields } from '@/types/vendors.types';

export const getUpdateVendorPayload = (data: VendorDetailsFormFields) => {
  const { companyName, fullName, email, phone } = data;

  return {
    name: companyName || '',
    contact: {
      name: fullName || '',
      email: email || '',
      phoneNumber: phone || '',
    },
  };
};
