import { ComponentThemeType } from '@/cl/theme/theme.hooks';

export const IconLabelTheme: ComponentThemeType<{ splitContactsTab: boolean }> = (
  currentTheme,
  { splitContactsTab },
) => ({
  baseStyle: {
    container: {
      display: 'flex',
      flexDirection: { xs: 'row', s: 'column' } as never,
      alignItems: 'center',
      padding: { xs: '8px', s: 0 } as never,
      gap: { xs: '16px', s: '4px' } as never,
    },
    label: {
      ...currentTheme.textStyles.caption1Semi,
    } as never,
  },
  variants: {
    active: {
      container: {
        color: currentTheme.colors.global.brand['700'],
      },
      icon: {
        fill: currentTheme.colors.global.brand['700'],
      },
    },
    default: {
      container: {
        color: splitContactsTab ? currentTheme.colors.global.neutral['800'] : currentTheme.colors.global.neutral['900'],
        _hover: {
          color: currentTheme.colors.global.neutral['900'],
        },
      },
      icon: {
        fill: splitContactsTab ? currentTheme.colors.global.neutral['800'] : currentTheme.colors.global.neutral['900'],
      },
    },
  },
});
