import React, { useCallback, useEffect } from 'react';
import { EbillsImportSettingsCards } from '@melio/ap-activities';
import { getAccountingPlatformNameForAnalytics } from '@melio/ap-domain';
import { Group, Text, useBreakpoint } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { getAvailableToConnectAccountingPlatforms, useAccount, useAccountingPlatforms } from '@melio/platform-api';
import { AccountingPlatformSlug } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { SettingsPageHeader, useLocation } from '@melio/platform-utils';

import { BillsInboxSettingsSection } from '@/components/bills-inbox/BillsInboxSettingsSection';
import { WithLoading } from '@/hoc/withLoading.hoc';
import { AccountingPlatformStateType } from '@/hooks/router.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { getAccessTokenOrganizationId } from '@/utils/getAccessTokenData.utils';
import { AccountingPlatformCardQbdt } from '@/widgets/accounting-platforms/AccountingPlatformCards/AccountingPlatformCardQbdt.widget';
import { AccountingPlatformCardQbo } from '@/widgets/accounting-platforms/AccountingPlatformCards/AccountingPlatformCardQbo.widget';
import { AccountingPlatformCardXero } from '@/widgets/accounting-platforms/AccountingPlatformCards/AccountingPlatformCardXero.widget';

export const AccountingSoftwareContainer = () => {
  const { data, isLoading, hasAccountingPlatform, activeAccountingPlatform } = useAccountingPlatforms();
  const { track } = useAnalytics();
  const { formatMessage } = usePlatformIntl();
  const { state } = useLocation<AccountingPlatformStateType>();
  const [platformXeroAccountingSoftwareEnabled] = useFeature<boolean>(
    FeatureFlags.PlatformXeroAccountingSoftwareEnabled,
    false,
    {
      shouldTrack: true,
    },
  );
  const { isExtraSmallScreen } = useBreakpoint();
  const { data: dataAccountMe } = useAccount({ id: 'me' });
  useEffect(() => {
    track('Settings', 'View', {
      PageName: 'accounting-software-sync',
      Intent: 'sync',
      Flow: 'settings',
      EntryPoint: 'settings-page',
      IsConnectedToAccountingSoftware: hasAccountingPlatform,
      ConnectedAccountingSoftware: getAccountingPlatformNameForAnalytics(activeAccountingPlatform?.accountingSlug),
    });
  }, [track, hasAccountingPlatform, activeAccountingPlatform]);

  const renderAccountingPlatformList = React.useCallback(() => {
    const organizationId = getAccessTokenOrganizationId();
    const { syncError, accountingPlatformId } = state || {};
    if (syncError != undefined) {
      window.history.replaceState({}, document.title);
    }
    if (data && organizationId) {
      const accountingPlatforms = getAvailableToConnectAccountingPlatforms(data);
      if (!accountingPlatforms || accountingPlatforms.length === 0) return null;
      const quickBooksAccountingPlatform = accountingPlatforms.find(
        (accountingPlatform) => accountingPlatform.accountingSlug === AccountingPlatformSlug.QuickBooksOnline,
      );
      const quickBooksDesktopAccountingPlatform = accountingPlatforms.find(
        (accountingPlatform) => accountingPlatform.accountingSlug === AccountingPlatformSlug.QuickBooksDesktop,
      );
      const xeroAccountingPlatform = accountingPlatforms.find(
        (accountingPlatform) => accountingPlatform.accountingSlug === AccountingPlatformSlug.Xero,
      );

      return (
        <>
          {quickBooksAccountingPlatform && (
            <AccountingPlatformCardQbo
              accountingPlatform={quickBooksAccountingPlatform}
              organizationId={organizationId}
              syncError={quickBooksAccountingPlatform.id === accountingPlatformId ? syncError : undefined}
            />
          )}
          {quickBooksDesktopAccountingPlatform && (
            <AccountingPlatformCardQbdt
              accountingPlatform={quickBooksDesktopAccountingPlatform}
              organizationId={organizationId}
              syncError={quickBooksDesktopAccountingPlatform.id === accountingPlatformId ? syncError : undefined}
            />
          )}
          {platformXeroAccountingSoftwareEnabled && xeroAccountingPlatform && (
            <AccountingPlatformCardXero
              accountingPlatform={xeroAccountingPlatform}
              organizationId={organizationId}
              syncError={xeroAccountingPlatform.id === accountingPlatformId ? syncError : undefined}
            />
          )}
        </>
      );
    }
    return null;
  }, [data, state, platformXeroAccountingSoftwareEnabled]);

  const extraProviders = useCallback(() => {
    if (dataAccountMe?.company?.inboxEmailAddress) {
      return (
        <Group spacing="s">
          <BillsInboxSettingsSection billsInboxEmail={dataAccountMe.company.inboxEmailAddress} />
        </Group>
      );
    }
    return null;
  }, [dataAccountMe]);

  const accountingPlatformList = renderAccountingPlatformList();

  return (
    <WithLoading isLoading={isLoading}>
      <SettingsPageHeader title={formatMessage('app.settings.companySection.cards.accounting.title')} />
      <Group
        alignItems="stretch"
        height="auto"
        justifyContent="stretch"
        spacing="xl"
        textAlign="start"
        variant="vertical"
        width="auto"
        data-component="AccountingSoftwareSettings"
      >
        <Group variant="vertical" spacing="xl">
          {accountingPlatformList && (
            <Group variant="vertical" spacing="xs">
              <Text as="h3" textStyle="heading3Semi">
                {formatMessage('widgets.accountingPlatform.header')}
              </Text>
              <Text textStyle={isExtraSmallScreen ? 'body3' : 'body2'} color="global.neutral.800">
                {formatMessage('widgets.accountingPlatform.settings.subtitle')}
              </Text>
            </Group>
          )}
          <Group variant="vertical" spacing="xl" hasDivider>
            {accountingPlatformList && (
              <Group variant="vertical" spacing="xl">
                {accountingPlatformList}
              </Group>
            )}
            <EbillsImportSettingsCards extraProviders={extraProviders()} />
          </Group>
        </Group>
      </Group>
    </WithLoading>
  );
};
