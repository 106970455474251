import { Card, Container, Group } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import {
  ApprovalRequirementStatus,
  Bill,
  BillingFeeSetting,
  DeliveryMethod,
  DeliveryPreference,
  FundingSource,
  FundingSourceType,
  FxQuote,
  PaymentFee,
  PaymentIntent,
  SelectedRepaymentOption,
  Vendor,
} from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { forwardRef } from '@melio/platform-utils';

import {
  ReviewAndConfirmAmountSection,
  ReviewAndConfirmFeeSection,
  ReviewAndConfirmPayFromSection,
  ReviewAndConfirmPayToSection,
  ReviewAndConfirmVendorReceiveSection,
} from './sections';
import { ReviewAndConfirmRecurringPaymentSection } from './sections/RecurringPaymentSection';
import { RepaymentSection } from './sections/RepaymentSection/RepaymentSection';
import { RecurringPaymentProps } from './types';

export type ReviewAndConfirmWidgetProps = {
  invoiceNumbers: Bill['invoice']['number'][];
  fundingSource: FundingSource;
  vendorName: Vendor['name'];
  countryDisplayName?: string;
  deliveryMethod: DeliveryMethod;
  memoToVendor?: PaymentIntent['noteToVendor'];
  amount: number;
  paymentFees: PaymentFee[];
  scheduledDate: Date;
  deliveryPreference: DeliveryPreference;
  onEditFundingSource?: VoidFunction;
  onEditRepaymentTerm?: VoidFunction;
  onEditDate?: VoidFunction;
  onEditAmount?: VoidFunction;
  onEditDeliveryMethod?: VoidFunction;
  onEditMemoToVendor?: VoidFunction;
  updatedFields?: string[];
  recurringPayment?: RecurringPaymentProps;
  dueDate?: Date;
  enableLateTag: boolean;
  hideMemoSection?: boolean;
  hideFromSection?: boolean;
  hideFeesSection?: boolean;
  remainingAmount?: number;
  isEditPaymentFlow?: boolean;
  isFinancingPaymentFlow?: boolean;
  orgBillingFeeSettings: BillingFeeSetting[];
  selectedRepaymentOption?: SelectedRepaymentOption;
  approvalRequirementStatus?: ApprovalRequirementStatus;
  vendorId: string;
  quote?: FxQuote;
  isQuoteFetching?: boolean;
};

export const ReviewAndConfirmWidget = forwardRef<ReviewAndConfirmWidgetProps, 'div'>(
  (
    {
      amount,
      invoiceNumbers,
      fundingSource,
      vendorName,
      memoToVendor,
      deliveryMethod,
      countryDisplayName,
      paymentFees,
      orgBillingFeeSettings,
      scheduledDate,
      deliveryPreference,
      updatedFields,
      recurringPayment,
      onEditAmount: _onEditAmount,
      onEditFundingSource: _onEditFundingSource,
      onEditRepaymentTerm: _onEditRepaymentTerm,
      onEditDate: _onEditDate,
      onEditDeliveryMethod: _onEditDeliveryMethod,
      onEditMemoToVendor: _onEditMemoToVendor,
      dueDate,
      isEditPaymentFlow,
      enableLateTag,
      hideMemoSection,
      hideFromSection,
      hideFeesSection,
      remainingAmount,
      isFinancingPaymentFlow,
      selectedRepaymentOption,
      approvalRequirementStatus,
      vendorId,
      quote,
      isQuoteFetching,
      ...props
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _ref // TODO refactor this comp not to be a forwardRef
  ) => {
    const { track } = useAnalytics();
    const editDeliveryMethodIsSupported =
      deliveryMethod.type !== 'international-account' && fundingSource.type !== FundingSourceType.FlexAccount;

    const onEditDeliveryMethod =
      editDeliveryMethodIsSupported && _onEditDeliveryMethod
        ? () => {
            track('ReviewPaymentEditDeliveryMethod', 'Chose', { DeliveryMethodChosen: deliveryMethod.type });
            _onEditDeliveryMethod();
          }
        : undefined;

    const [isPartialPaymentsEnabled] = useFeature<boolean>(FeatureFlags.PartialPaymentsEnabled, false);

    const onEditMemoToVendor =
      _onEditMemoToVendor &&
      (() => {
        track('ReviewPaymentEditVendorNote', 'Chose');
        _onEditMemoToVendor();
      });

    const onEditDate =
      _onEditDate &&
      (() => {
        track('ReviewPaymentEditPaymentDeducted', 'Chose');
        _onEditDate();
      });

    const onEditFundingSource = _onEditFundingSource
      ? () => {
          track('ReviewPaymentEditFundingSource', 'Chose', {
            FundingSourceType: fundingSource.type,
            Cta: 'edit-funding-source',
          });
          _onEditFundingSource();
        }
      : undefined;

    const onEditRepaymentTerm = _onEditRepaymentTerm
      ? () => {
          track('ReviewPaymentEditRepaymentTerm', 'Chose', {
            Cta: 'edit-repayment-term',
          });
          _onEditRepaymentTerm();
        }
      : undefined;

    const onEditAmount = _onEditAmount
      ? () => {
          track('ReviewPaymentEditFundingSource', 'Chose', {
            FundingSourceType: fundingSource.type,
            Cta: 'edit-amount',
          });
          _onEditAmount();
        }
      : undefined;

    const rppsDeliveryMethodDetails = deliveryMethod.type === 'biller-account' ? deliveryMethod.details : null;
    const isRecurring = !!recurringPayment;
    const showRemainingAmountHelperText =
      isPartialPaymentsEnabled && !isRecurring && !!remainingAmount && remainingAmount > 0 && !isEditPaymentFlow;
    const showEditAmount = isPartialPaymentsEnabled && !isRecurring && onEditAmount && !isEditPaymentFlow;
    return (
      <Card
        paddingX="none"
        paddingY="none"
        data-component="ReviewAndConfirmWidget"
        width="full"
        data-testid="review-and-confirm-widget"
        {...props}
      >
        <Container>
          <Group variant="vertical" spacing="none" hasDivider>
            <Container paddingX="m" paddingTop="m" paddingBottom="s">
              <ReviewAndConfirmAmountSection
                amount={amount}
                remainingAmount={remainingAmount}
                lastAmount={recurringPayment?.lastAmount}
                isRecurring={isRecurring}
                onEdit={onEditAmount}
                showEditAmount={showEditAmount}
                showRemainingAmountHelperText={showRemainingAmountHelperText}
                vendorId={vendorId}
                deliveryMethod={deliveryMethod}
                quote={quote}
                isQuoteFetching={isQuoteFetching}
                approvalRequirementStatus={approvalRequirementStatus}
              />
            </Container>
            <Container paddingX="m" paddingY="s">
              <ReviewAndConfirmPayToSection
                vendorName={vendorName}
                invoiceNumbers={invoiceNumbers}
                accountNumber={rppsDeliveryMethodDetails?.accountIdentifier}
              />
            </Container>
            {recurringPayment && (
              <Container paddingX="m" paddingY="s">
                <ReviewAndConfirmRecurringPaymentSection recurringPayment={recurringPayment} />
              </Container>
            )}
            {hideFromSection ? null : (
              <Container paddingX="m" paddingY="s">
                {isFinancingPaymentFlow ? (
                  <RepaymentSection
                    fundingSource={fundingSource}
                    repaymentOption={selectedRepaymentOption}
                    onEditFundingSource={onEditFundingSource}
                    onEditRepaymentTerm={onEditRepaymentTerm}
                  />
                ) : (
                  <ReviewAndConfirmPayFromSection
                    fundingSource={fundingSource}
                    deliveryMethod={deliveryMethod}
                    scheduledDate={scheduledDate}
                    onEditFundingSource={onEditFundingSource}
                    onEditScheduledDate={onEditDate}
                    updatedFields={updatedFields}
                    isRecurringPayment={isRecurring}
                    approvalRequirementStatus={approvalRequirementStatus}
                  />
                )}
              </Container>
            )}
            <Container paddingX="m" paddingTop="s" paddingBottom={hideFeesSection ? 'm' : 's'}>
              <ReviewAndConfirmVendorReceiveSection
                vendorName={vendorName}
                paymentFees={paymentFees}
                deliveryMethod={deliveryMethod}
                countryDisplayName={countryDisplayName}
                fundingSource={fundingSource}
                memoToVendor={memoToVendor}
                deliveryPreference={deliveryPreference}
                onEditDeliveryMethod={onEditDeliveryMethod}
                onEditMemoToVendor={onEditMemoToVendor}
                onEditDeliveryDate={onEditDate}
                updatedFields={updatedFields}
                dueDate={dueDate}
                enableLateTag={enableLateTag}
                hideMemoSection={hideMemoSection}
                recurringPayment={recurringPayment}
              />
            </Container>
            {hideFeesSection ? null : (
              <Container paddingX="m" paddingTop="s" paddingBottom="m">
                <ReviewAndConfirmFeeSection
                  deliveryMethod={deliveryMethod}
                  fundingSource={fundingSource}
                  paymentFees={paymentFees}
                  recurringPayment={recurringPayment}
                  orgBillingFeeSettings={orgBillingFeeSettings}
                />
              </Container>
            )}
          </Group>
        </Container>
      </Card>
    );
  }
);

ReviewAndConfirmWidget.displayName = 'ReviewAndConfirmWidget';
