import { useCreateMutation, useDeleteMutation, useModelApi } from '@melio/api-client';
import { BillsSyncApiClient, BillsSyncStatusEnum, PostBillsSyncRequest } from '@melio/platform-api-axios-client';

import { BillsSyncApiProps } from './types';

export function useDeleteBillSyncConnectionMutation(props: BillsSyncApiProps) {
  return useDeleteMutation(
    () => BillsSyncApiClient.deleteBillsSyncProvider(props.provider),
    [props.queryKey, 'model', props.queryId, 'default']
  );
}

export function useCreateBillsSyncConnectionMutation(props: BillsSyncApiProps) {
  const mutationResult = useCreateMutation(
    (params?: PostBillsSyncRequest) => BillsSyncApiClient.postBillsSyncProvider(props.provider, params),
    [props.queryKey, 'model', props.queryId, 'default']
  );
  return { ...mutationResult };
}

export function useGetBillSyncStatus(props: BillsSyncApiProps) {
  return useModelApi({
    queryKey: props.queryKey,
    queryFn: () => BillsSyncApiClient.getBillsSyncProviderStatus(props.provider),
    id: props.queryId,
    enabled: props.enabled,
    refetchInterval: (data) =>
      data?.syncStatus === (BillsSyncStatusEnum.InProgress || BillsSyncStatusEnum.NotStarted) ? 2000 : false,
  });
}
