import { useModelApi, UseModelApiProps } from '@melio/api-client';
// eslint-disable-next-line no-restricted-imports
import { PostSubscriptionPreviewRequest, SubscriptionsApiClient } from '@melio/platform-api-axios-client';

type QueryFn = () => ReturnType<typeof SubscriptionsApiClient.postSubscriptionsPreview>;

export type UseSubscriptionsPreviewProps = UseModelApiProps<QueryFn> & {
  params: PostSubscriptionPreviewRequest & {
    // TODO: uncomment once preview api is supporting poromo code
    promoCode?: string;
  };
};

export const useSubscriptionsPreview = ({ params, ...rest }: UseSubscriptionsPreviewProps) =>
  useModelApi({
    ...rest,
    id: 'preview',
    queryKey: ['SubscriptionsApi', params],
    queryFn: () => SubscriptionsApiClient.postSubscriptionsPreview(params),
  });
