import { Tier, useMelioIntl, usePlanInfo, usePlansTiers, useSubscriptionPlan } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';

import { useOnboardingStepsTracker } from '../../components';
import { PlansData } from '../../flows';
import { SubscriptionScreen } from './screens';

export type SubscriptionActivityProps = {
  onBack: VoidFunction;
  onClose: VoidFunction;
  onDone: (plansData: PlansData) => void;
  onError?: ARErrorFunction;
};

export const SubscriptionActivity = forwardRef<SubscriptionActivityProps>(
  ({ onDone, onBack, onClose, onError }, ref) => {
    const { steps, currentStepIndex, goToStepById } = useOnboardingStepsTracker();

    useEffect(() => {
      goToStepById('subscription');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStepIndex]);
    const { formatMessage } = useMelioIntl();
    const { getAvailablePlans } = usePlansTiers();
    const arPlan = getAvailablePlans().find(({ tier }) => tier === Tier.FiservApArBasic);
    const planInfo = usePlanInfo(arPlan?.id);
    const { currentPlan } = useSubscriptionPlan();

    if (!arPlan || !planInfo.planName || !currentPlan) {
      onError?.({
        code: 'AR_PLAN_NOT_FOUND',
        message: formatMessage('ar.onboarding.activities.subscription.error.message'),
      });
      return null;
    }

    const onSubmit = () => {
      onDone({ currentPlanId: currentPlan.id, planId: arPlan.id });
    };

    return (
      <SubscriptionScreen
        ref={ref}
        steps={steps}
        currentStepIndex={currentStepIndex}
        onDone={onSubmit}
        onBack={onBack}
        onClose={onClose}
        planInfo={planInfo}
        plan={arPlan}
      />
    );
  }
);

SubscriptionActivity.displayName = 'SubscriptionActivity';
