import { Box } from '@chakra-ui/react';
import { Text, useTheme } from '@melio/penny';

import { TagTheme } from '@/cl/components/Tag/Tag.component.theme';
import { useAppTheme } from '@/cl/theme/theme.hooks';

export const Tag = ({ text, tagText }: { text: string; tagText: string }) => {
  const styles = useAppTheme(TagTheme, {});
  const { colors } = useTheme();

  return (
    <Box data-component="Tag" sx={styles['container']}>
      <Box>{text}</Box>
      <Box padding="1px 4px" backgroundColor={colors.global.neutral['400']} borderRadius="s">
        <Text color="global.neutral.100" textStyle="caption1Semi">
          {tagText}
        </Text>
      </Box>
    </Box>
  );
};
Tag.displayName = 'Tag';
