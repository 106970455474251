import { Table, useTable } from '@melio/penny';
import { Collaborator, FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { useEntitledUsersTableColumns } from './useEntitledUsersTableColumns';

type EntitledUserTableProps = {
  collaborators: Collaborator<'user'>[] | [];
  isCollaboratorsLoading: boolean;
  fundingSourceId: FundingSource['id'];
} & Pick<
  Parameters<typeof useTable<Collaborator>>[0],
  | 'selectedRows'
  | 'onRowSelectionChange'
  | 'onAllRowsSelectionChange'
  | 'rowSelectionTooltips'
  | 'disableRowSelection'
  | 'disableAllRowsSelection'
>;

export const EntitledUserTable = ({
  collaborators,
  isCollaboratorsLoading,
  selectedRows,
  rowSelectionTooltips,
  onRowSelectionChange,
  disableRowSelection,
  onAllRowsSelectionChange,
  disableAllRowsSelection,
  fundingSourceId,
}: EntitledUserTableProps) => {
  const { formatMessage } = useMelioIntl();

  const columns = useEntitledUsersTableColumns(fundingSourceId);

  const tableProps = useTable<Collaborator<'user'>>({
    isHeaderSticky: true,
    isLoading: isCollaboratorsLoading,
    data: collaborators,
    columns,
    getRowId: (row) => row.id,
    selectedRows,
    onRowSelectionChange,
    onAllRowsSelectionChange,
    headerVariant: 'dark',
    rowSelectionTooltips,
    disableRowSelection,
    disableAllRowsSelection,
    getRowSelectionAriaLabelledBy: (rowData) => `user-cell-label-${rowData.index}`,
    getRowSelectionAriaDescribedBy: (rowData) =>
      rowData.isSelectionDisabled ? `user-cell-description-${rowData.index}` : undefined,
    hideHeaderWhileLoading: true,
    allRowsSelectionAriaLabel: formatMessage(
      'widgets.addOrUpdateDeliveryMethod.entitled.fundingSource.modal.table.selectAllRows'
    ),
    captionId: formatMessage(`widgets.addOrUpdateDeliveryMethod.entitled.fundingSource.modal.table.caption`),
  });

  return <Table {...tableProps} />;
};
