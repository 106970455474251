import { useMelioIntl } from '@melio/platform-i18n';
import { object, SchemaOf, string } from 'yup';

import {
  FISERV_ACCOUNT_NUMBER_MAX_LENGTH,
  FISERV_ACCOUNT_NUMBER_MIN_LENGTH,
  ROUTING_NUMBER_LENGTH,
  validateRoutingNumber,
  ValidationError,
} from '../../bank-details/util/bank-account-util';
import { FiservBankAccountDetailsFields } from './types';

export const useVendorBankAccountSchema = () => {
  const { formatMessage } = useMelioIntl();

  return object().shape(
    {
      routingNumber: string()
        .required(formatMessage('widgets.invalidVendorBankAccountModal.form.routingNumber.validation.required'))
        .test('routingNumber', '', (value, testContext) => {
          const routingNumberValidation = validateRoutingNumber(value);

          if (routingNumberValidation.valid) {
            return true;
          }

          if (routingNumberValidation.error === ValidationError.INVALID_CHARACTERS) {
            return testContext.createError({
              message: formatMessage('widgets.invalidVendorBankAccountModal.form.routingNumber.validation.digitsOnly'),
            });
          }

          if (routingNumberValidation.error === ValidationError.INVALID_LENGTH) {
            return testContext.createError({
              message: formatMessage(
                'widgets.invalidVendorBankAccountModal.form.routingNumber.validation.invalidLength',
                {
                  length: ROUTING_NUMBER_LENGTH,
                }
              ),
            });
          }

          return testContext.createError({
            message: formatMessage(
              'widgets.invalidVendorBankAccountModal.form.routingNumber.validation.invalidNumber',
              {
                length: ROUTING_NUMBER_LENGTH,
              }
            ),
          });
        }),
      accountNumber: string()
        .required(formatMessage('widgets.invalidVendorBankAccountModal.form.accountNumber.validation.required'))
        .matches(
          /^[0-9A-Za-z]*$/,
          formatMessage('widgets.invalidVendorBankAccountModal.form.accountNumber.validation.format')
        )
        .test(
          'should-contain-uppercase-chars',
          formatMessage('widgets.invalidVendorBankAccountModal.form.accountNumber.validation.shouldContainUpperCase'),
          (bankAccountNumber) => {
            if (!bankAccountNumber) {
              return true;
            }

            return /[a-z]/.test(bankAccountNumber) ? /[A-Z]/.test(bankAccountNumber) : true;
          }
        )
        .max(
          FISERV_ACCOUNT_NUMBER_MAX_LENGTH,
          formatMessage('widgets.invalidVendorBankAccountModal.form.accountNumber.validation.invalidMaxLength', {
            minLength: FISERV_ACCOUNT_NUMBER_MIN_LENGTH,
            maxLength: FISERV_ACCOUNT_NUMBER_MAX_LENGTH,
          })
        ),
    },
    [['routingNumber', 'accountNumber']]
  ) as SchemaOf<FiservBankAccountDetailsFields>;
};
