import { FormSelectNewOption } from '@melio/penny';
import { MccItem } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMemo } from 'react';

const sortAlphabetically = (a: FormSelectNewOption, b: FormSelectNewOption) => a.label.localeCompare(b.label);

export const useMccOptions = (mccIndustryList: MccItem[], watchGroup: string, mccGroupsSupported: boolean) => {
  const { formatMessage } = useMelioIntl();
  const emptyGroup = formatMessage('utils.mccIndustry.empty.category');

  const groupOptions = useMemo(
    () => getGroupOptions(mccIndustryList, emptyGroup, mccGroupsSupported),
    [mccIndustryList, emptyGroup, mccGroupsSupported]
  );
  const mccOptions = useMemo(
    () => getMccOptions(mccIndustryList, watchGroup, emptyGroup),
    [mccIndustryList, watchGroup, emptyGroup]
  );

  return { groupOptions, mccOptions };
};

const getGroupOptions = (
  mccIndustryList: MccItem[],
  emptyGroupLabel: string,
  cardNetworkSupportsMccGroups: boolean
): FormSelectNewOption[] => {
  if (!cardNetworkSupportsMccGroups) {
    return [];
  }

  const allowedMccList = mccIndustryList.filter((item) => !item.prohibited);
  return [...new Set(allowedMccList.map((item) => item.category || emptyGroupLabel))]
    .map((itemCategory) => ({
      value: itemCategory,
      label: itemCategory,
    }))
    .sort(sortAlphabetically);
};

const getMccOptions = (
  mccIndustryList: MccItem[],
  selectedGroup: string,
  emptyCategoryLabel: string
): FormSelectNewOption[] => {
  const mccFilter = (item: MccItem) => {
    if (item.prohibited) {
      return false;
    }
    if (selectedGroup === emptyCategoryLabel) {
      return item.category === '';
    }
    if (!selectedGroup) {
      return true;
    }
    return item.category === selectedGroup;
  };

  return mccIndustryList
    .filter(mccFilter)
    .map(
      (item) =>
        ({
          value: item.code,
          label: item.subCategory || '',
          testId: item.code,
        } as FormSelectNewOption)
    )
    .sort(sortAlphabetically);
};
