import { useMelioIntl } from '@melio/ar-domain';
import { Modal, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export type MarkInvoiceAsUnpaidScreenProps = {
  onSubmit: () => void;
  onClose: VoidFunction;
  isLoading?: boolean;
  isSaving?: boolean;
  isOpen: boolean;
};

export const MarkInvoiceAsUnpaidScreen = forwardRef<MarkInvoiceAsUnpaidScreenProps>(
  ({ onSubmit, onClose, isOpen, isLoading, isSaving, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    return (
      <Modal
        data-testid="mark-invoice-as-unpaid-modal"
        isLoading={isLoading}
        header={formatMessage('ar.invoiceLifecycle.activities.markInvoiceAsUnpaid.modal.header.text')}
        isOpen={isOpen}
        onClose={() => !isSaving && onClose()}
        secondaryButton={{
          isDisabled: isSaving,
          label: formatMessage('ar.invoiceLifecycle.activities.markInvoiceAsUnpaid.modal.buttons.cancel.label'),
          onClick: onClose,
          variant: 'tertiary',
        }}
        primaryButton={{
          isLoading: isSaving,
          label: formatMessage('ar.invoiceLifecycle.activities.markInvoiceAsUnpaid.modal.buttons.confirm.label'),
          variant: 'primary',
          onClick: onSubmit,
        }}
        {...props}
        ref={ref}
      >
        <Text>{formatMessage('ar.invoiceLifecycle.activities.markInvoiceAsUnpaid.modal.description.title.text')}</Text>
      </Modal>
    );
  }
);
