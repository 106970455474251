import { StatusModal, Text, useToast } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { JoinOrganizationRequest, useJoinOrganizationRequest } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';

type Props = {
  joinOrganizationRequest: JoinOrganizationRequest;
  onClose: VoidFunction;
};

export const CancelJoinOrganizationRequestModal = withAnalyticsContext<Props>(
  ({ joinOrganizationRequest, onClose, setAnalyticsProperties }) => {
    const { formatMessage } = useMelioIntl();
    const { toast } = useToast();
    const { track } = useAnalytics();
    const { cancelJoinOrganizationRequestAccount, isMutating } = useJoinOrganizationRequest({
      id: joinOrganizationRequest.id,
      enabled: false,
    });

    setAnalyticsProperties({
      PageName: 'cancel-request',
      Intent: 'cancel-request',
    });

    useEffect(() => {
      track('User', 'View');
    }, [track]);

    const handleConfirmClick = async () => {
      track('User', 'Click', {
        Cta: 'cancel-request',
      });
      try {
        await cancelJoinOrganizationRequestAccount();
        toast({
          type: 'success',
          title: formatMessage('activities.accountants.firmDashboard.cancelJoinOrganizationRequestModal.toast.success'),
        });
        track('User', 'Status', {
          PageName: 'companies-dashboard',
          Status: 'success',
        });
        onClose();
      } catch (error) {
        toast({
          type: 'error',
          title: formatMessage('activities.accountants.firmDashboard.cancelJoinOrganizationRequestModal.toast.error'),
        });
        track('User', 'Status', {
          PageName: 'companies-dashboard',
          Status: 'failure',
        });
        onClose();
      }
    };

    return (
      <StatusModal
        isOpen
        variant="warning"
        header={formatMessage('activities.accountants.firmDashboard.cancelJoinOrganizationRequestModal.title', {
          companyName: joinOrganizationRequest.companyName,
        })}
        primaryButton={{
          label: formatMessage('activities.accountants.firmDashboard.cancelJoinOrganizationRequestModal.confirm.label'),
          onClick: () => void handleConfirmClick(),
          variant: 'primary',
          isLoading: isMutating,
        }}
        onClose={onClose}
        data-testid="cancel-join-organization-request"
      >
        <Text>
          {formatMessage('activities.accountants.firmDashboard.cancelJoinOrganizationRequestModal.description')}
        </Text>
      </StatusModal>
    );
  }
);
