import { DeliveryPreference, DeliveryPreferenceType } from '@melio/platform-api';

export const isDeliveryPreferenceTypeFast = (type: DeliveryPreference['type']) =>
  type === 'expedited-ach' || type === 'express-check';

export const getFastDeliveryPreference = (deliveryPreferences: DeliveryPreference[]) =>
  deliveryPreferences.find(({ type }) => type === 'expedited-ach' || type === 'express-check');

export const isDeliveryPreferenceTypeCheck = (type: DeliveryPreferenceType): boolean =>
  type === DeliveryPreferenceType.ExpressCheck || type === DeliveryPreferenceType.Check;

export const getRegularDeliveryPreference = (deliveryPreferences: DeliveryPreference[]) =>
  deliveryPreferences.find((option) => !isDeliveryPreferenceTypeFast(option.type));
