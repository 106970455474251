import { Container, ContainerProps, Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

type Props = Pick<ContainerProps, 'paddingBottom'> & { amount: string };

export const TotalAmount = ({ amount, paddingBottom = 'm' }: Props) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Group variant="vertical" spacing="xxs" alignItems="flex-end" role="status" aria-atomic="true">
      <Text textStyle="body4Semi">{formatMessage('activities.addBillV2.lineItems.total')}</Text>
      <Container paddingBottom={paddingBottom}>
        <Text textStyle="body1Semi" data-testid="add-bill-v2-total-amount">
          {amount}
        </Text>
      </Container>
    </Group>
  );
};
