import { useAnalyticsContext } from '@melio/platform-analytics';
import { Loan } from '@melio/platform-api';
import * as _ from 'lodash';

export const useFinancingTabAnalytics = ({
  loans,
  limit,
  balance,
}: {
  loans: Loan[];
  limit?: number;
  balance?: number;
}) => {
  useAnalyticsContext({
    globalProperties: {
      PageName: 'dashboard-financing',
      Flow: 'dashboard',
      Intent: 'view-financing',
      EntryPoint: 'dashboard-financing',
      CountInTab: loans?.length,
      AvailableCredit: balance,
      TotalCredit: limit,
      Status: createLoanStatusCountMapObject(loans),
    },
  });
};

const createLoanStatusCountMapObject = (loans: Loan[]) => {
  const loanStatusCounts = _.chain(loans)
    .groupBy('status')
    .mapValues((loanGroup) => _.sumBy(loanGroup, 'nextInstallmentAmount'))
    .value();

  return loanStatusCounts;
};
