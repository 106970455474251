import { Grid, Group, ListItem, Typography } from '@melio/penny';
import { Bill, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';

import { InvoicesDetails } from './InvoicesDetails';

export type ReviewAndConfirmPayToSectionProps = {
  invoiceNumbers: Bill['invoice']['number'][];
  vendorName: Vendor['name'];
  accountNumber?: string;
};

export const ReviewAndConfirmPayToSection = ({
  invoiceNumbers,
  vendorName,
  accountNumber,
  ...props
}: ReviewAndConfirmPayToSectionProps) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Group variant="vertical" spacing="s" data-component="ReviewAndConfirmPayToSection" {...props}>
      <Typography.SectionLabel label={formatMessage('widgets.reviewAndConfirm.payToSection.title')} />
      <Grid templateColumns="repeat(2, 1fr)" gap="s">
        <ListItem
          data-testid="payment-review-vendor-name"
          labelProps={{
            label: formatMessage('widgets.reviewAndConfirm.payToSection.businessNameField'),
          }}
          mainLabelProps={{
            label: vendorName,
          }}
        />
        <InvoicesDetails invoiceNumbers={invoiceNumbers} />
        {accountNumber && (
          <ListItem
            data-testid="payment-review-biller-account-number"
            labelProps={{
              label: formatMessage('widgets.reviewAndConfirm.payToSection.billerAccountNumber'),
            }}
            mainLabelProps={{
              label: accountNumber.toString(),
            }}
          />
        )}
      </Grid>
    </Group>
  );
};

ReviewAndConfirmPayToSection.displayName = 'ReviewAndConfirmPayToSection';
