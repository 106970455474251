/* eslint-disable react-hooks/exhaustive-deps */
import { EventProperties, useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { Payment } from '@melio/platform-api';
import { addBusinessDays, useDateUtils } from '@melio/platform-utils';

import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';
import { RefundReviewAndConfirmScreen } from '../screens/RefundReviewAndConfirm/RefundReviewAndConfirm.screen';
import { RefundFlowDoneAction } from '../types/types';

type Props = {
  payment: Payment;
  isLoading: boolean;
  isLoadingButton: boolean;
  defaultAnalyticsFields: EventProperties;
  cancelFlow: () => void;
  onFlowDone: (action: RefundFlowDoneAction) => void;
  onConfirmRefund: () => void;
};

export const ReviewAndConfirmStep = ({
  payment,
  isLoading,
  isLoadingButton,
  defaultAnalyticsFields,
  onFlowDone,
  cancelFlow,
  onConfirmRefund,
}: Props) => {
  const { amount, vendorId, fundingSource, deliveryMethod } = payment;
  const { createTrackHandler } = useAnalytics();
  const trackAndHandleClick = createTrackHandler<{
    Cta: 'exit' | 'retry-the-payment' | 'get-a-refund';
  }>('Payment', 'Click');

  useAnalyticsView('Payment', true, true, { ...defaultAnalyticsFields });

  const onCloseClick = () => {
    trackAndHandleClick({ Cta: 'exit', ...defaultAnalyticsFields }, cancelFlow);
  };

  const onRetryClick = () => {
    trackAndHandleClick({ Cta: 'retry-the-payment', ...defaultAnalyticsFields }, onFlowDone('retryPayment'));
  };

  const onSubmitClick = () => {
    trackAndHandleClick({ Cta: 'get-a-refund', ...defaultAnalyticsFields }, onConfirmRefund);
  };

  const { createDate } = useDateUtils();
  return fundingSource ? (
    <RefundReviewAndConfirmScreen
      amount={amount}
      vendorId={vendorId}
      isLoading={isLoading}
      fundingSource={fundingSource}
      isLoadingButton={isLoadingButton}
      deliveryMethod={deliveryMethod}
      maxEstimatedRefundDate={addBusinessDays(createDate(), 5)}
      onRetry={onRetryClick}
      onSubmit={onSubmitClick}
      onClose={onCloseClick}
    />
  ) : (
    <NewSinglePaymentStepLayout isLoading={isLoading} />
  );
};
