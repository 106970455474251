/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { ConsentScreenActivity } from '@melio/ap-activities';

type UserConsentProps = {
  privacyPolicyUrl: string;
  termsOfServiceUrl: string;
  onDone: () => void;
};

export const UserConsent = ({ privacyPolicyUrl, termsOfServiceUrl, onDone }: UserConsentProps) => {
  const { isOpen, onOpen: openConsentModal, onClose: closeConsentModal } = useDisclosure();

  const onDialogDone = () => {
    closeConsentModal();
    onDone();
  };

  React.useEffect(() => {
    openConsentModal();
  }, []);

  return (
    <ConsentScreenActivity
      termsOfServiceUrl={termsOfServiceUrl}
      privacyPolicyUrl={privacyPolicyUrl}
      isOpen={isOpen}
      onClose={onDialogDone}
      onDone={onDialogDone}
    />
  );
};
