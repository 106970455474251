import { useCollectionApi, UseCollectionApiProps, useMelioQueryClient } from '@melio/api-client';
import { JoinOrganizationRequest, JoinOrganizationRequestsApiClient } from '@melio/platform-api-axios-client';

type QueryFN = typeof JoinOrganizationRequestsApiClient.joinOrganizationRequestsGet;
type CreateFn = typeof JoinOrganizationRequestsApiClient.joinOrganizationRequestsCreate;

type UseJoinOrganizationRequestsProps = UseCollectionApiProps<QueryFN, JoinOrganizationRequest>;

export const useJoinOrganizationRequests = ({ ...props }: UseJoinOrganizationRequestsProps = {}) => {
  const queryClient = useMelioQueryClient();

  return useCollectionApi<QueryFN, CreateFn, never, never, JoinOrganizationRequest>({
    ...props,
    queryKey: ['JoinOrganizationRequestsApi'],
    queryFn: JoinOrganizationRequestsApiClient.joinOrganizationRequestsGet,
    createFn: JoinOrganizationRequestsApiClient.joinOrganizationRequestsCreate,
    onCreate: () => queryClient.invalidateQueries('JoinOrganizationRequestsApi', 'collection'),
  });
};

export type { JoinOrganizationRequest, PostJoinOrganizationRequestsRequest } from '@melio/platform-api-axios-client';
export { JoinOrganizationRequestRole, JoinOrganizationRequestStatus } from '@melio/platform-api-axios-client';
