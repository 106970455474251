import {
  ConditionalWrapper,
  Container,
  Group,
  GroupProps,
  NakedButton,
  Text,
  useBreakpoint,
  useBreakpointValue,
} from '@melio/penny';
import { DeliveryMethod, FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';
import { RefundReviewAndConfirmContent } from '../../components/RefundReviewAndConfirmContent/RefunfReviewAndConfirmContent';

export type RefundReviewAndConfirmScreenProps = {
  amount: number;
  vendorId: string;
  isLoading?: boolean;
  fundingSource: FundingSource;
  deliveryMethod?: DeliveryMethod;
  isLoadingButton?: boolean;
  maxEstimatedRefundDate: Date;
  onRetry: VoidFunction;
  onSubmit: VoidFunction;
  onClose: VoidFunction;
};

export const RefundReviewAndConfirmScreen = ({
  amount,
  vendorId,
  isLoading,
  fundingSource,
  deliveryMethod,
  isLoadingButton,
  maxEstimatedRefundDate,
  onSubmit,
  onRetry,
  onClose,
  ...props
}: RefundReviewAndConfirmScreenProps) => {
  const { formatMessage } = useMelioIntl();
  const bottomContentVariant = useBreakpointValue<GroupProps['variant']>({ xs: 'vertical', s: 'horizontal' });
  const bottomContentTextAlign = useBreakpointValue<GroupProps['textAlign']>({ xs: 'center', s: undefined });
  const { isExtraSmallScreen } = useBreakpoint();

  return (
    <NewSinglePaymentStepLayout
      data-component="RefundBillActivity.RefundReviewAndConfirmScreen"
      data-testid="review-and-confirm-activity-review-and-confirm-screen"
      isLoading={isLoading}
      {...props}
      headerContent={
        <NewSinglePaymentStepLayout.Header>
          <NewSinglePaymentStepLayout.CloseButton onClick={onClose} />
        </NewSinglePaymentStepLayout.Header>
      }
      footerContent={
        <NewSinglePaymentStepLayout.Actions>
          <NewSinglePaymentStepLayout.NextButton
            data-testid="refund-button"
            onClick={onSubmit}
            variant="primary"
            label={formatMessage('activities.refundBill.screens.reviewAndConfirmRefund.continue')}
            isLoading={isLoadingButton}
          />
        </NewSinglePaymentStepLayout.Actions>
      }
      bottomContent={
        <Group variant={bottomContentVariant} spacing="xxs" justifyContent="center" textAlign={bottomContentTextAlign}>
          <Text textStyle="body3">
            {formatMessage('activities.refundBill.screens.reviewAndConfirmRefund.retryPayment.description')}
          </Text>
          <ConditionalWrapper
            condition={isExtraSmallScreen}
            wrapper={(children) => <Container justifyContent="center">{children}</Container>}
          >
            <NakedButton
              variant="secondary"
              onClick={onRetry}
              isDisabled={isLoadingButton}
              label={formatMessage('activities.refundBill.screens.reviewAndConfirmRefund.retryPayment.link')}
            />
          </ConditionalWrapper>
        </Group>
      }
    >
      <NewSinglePaymentStepLayout.Title>
        {formatMessage('activities.refundBill.screens.reviewAndConfirmRefund.title')}
      </NewSinglePaymentStepLayout.Title>
      <NewSinglePaymentStepLayout.Content>
        <RefundReviewAndConfirmContent
          amount={amount}
          vendorId={vendorId}
          deliveryMethod={deliveryMethod}
          fundingSource={fundingSource}
          maxEstimatedRefundDate={maxEstimatedRefundDate}
        />
      </NewSinglePaymentStepLayout.Content>
    </NewSinglePaymentStepLayout>
  );
};

RefundReviewAndConfirmScreen.displayName = 'RefundBillActivity.RefundReviewAndConfirmScreen';
