import { BillDetailsFormFields, BillDetailsWidgetAccessibleNew } from '@melio/ap-widgets';
import { AvatarWithDetails, Container, Drawer, useFormSubmissionController } from '@melio/penny';
import { Bill, useFiles, Vendor } from '@melio/platform-api';
import { useSystemMessage, withSystemMessageProvider } from '@melio/platform-utils';
import { useState } from 'react';

export type BillDetailsDrawerScreenProps = {
  bill: Bill;
  vendor: Vendor;
  isOpen: boolean;
  onClose: VoidFunction;
  onSubmit?: VoidFunction;
  onError?: ErrorFunction;
};

export const BillDetailsDrawerScreen = withSystemMessageProvider(
  ({ vendor, bill, isOpen, onClose, onError }: BillDetailsDrawerScreenProps) => {
    const [fileBlob, setFileBlob] = useState<File | undefined | null>();

    const { onSubmissionStateChange, handleSubmit } = useFormSubmissionController<BillDetailsFormFields>();
    const { create: uploadInvoiceFile } = useFiles({ enabled: false });

    const { showMessage } = useSystemMessage();

    const handleFail = (error: PlatformError) => {
      showMessage({ type: 'error', title: error.message });
      onError?.(error);
    };

    const onSubmit = () => {
      if (fileBlob) {
        uploadInvoiceFile(fileBlob).catch(handleFail);
      }
      handleSubmit?.();
    };

    return (
      <Drawer
        data-testid="bill-details-drawer-screen"
        isOpen={isOpen}
        onClose={onClose}
        header={<AvatarWithDetails avatarProps={{ name: vendor.name }} mainLabelProps={{ label: vendor.name }} />}
        body={
          <Container border="regular">
            <BillDetailsWidgetAccessibleNew
              bill={bill}
              onInvoiceChange={setFileBlob}
              isActionable={false}
              onSubmit={onSubmit}
              showOpenBalance
              onSubmissionStateChange={onSubmissionStateChange}
              hasBorder
              hideHeaderTitle={false}
            />
          </Container>
        }
      />
    );
  }
);

BillDetailsDrawerScreen.displayName = 'BatchPaymentsActivity.BillDetailsDrawerScreen';
