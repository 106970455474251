import { Button, Container, Grid, Group, Icon, Layout, NakedButton, Text } from '@melio/penny';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { PageTitle } from '@melio/platform-utils';
import { Navigate, useLocation } from 'react-router-dom';

import { useIsSubscriptionsEnabled, usePlanInfo, usePlansTiers } from '../../../hooks';
import { CrownLarge } from '../../assets/CrownLarge.icon';
import { SectionSettingCard } from '../../components/SectionSettingCard';
import { useSubscriptionRouter } from '../../utils';

export const GraceEndPeriodOwner = withAnalyticsContext(({ setAnalyticsProperties }) => {
  const location = useLocation();
  const { formatMessage } = useMelioIntl();
  const { getFirstPaidPlan } = usePlansTiers();
  const firstPaidPlan = getFirstPaidPlan();
  const { planName, capabilities } = usePlanInfo(firstPaidPlan?.id);
  const { goToSubscriptionPlans, goToSubscriptionCheckout } = useSubscriptionRouter();
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();

  setAnalyticsProperties({
    PageName: 'keep-enjoying-premium-features',
    Flow: 'existing-user-transition',
    Intent: 'existing-user-subscribe',
  });

  useAnalyticsView('Organization', isSubscriptionsEnabled);
  const { track } = useAnalytics();
  if (!isSubscriptionsEnabled) {
    return <Navigate to="/error" />;
  }

  return (
    <Layout variant="6Columns" backgroundColor="white">
      <Container
        paddingX="xxs"
        paddingY="xxs"
        height="full"
        textAlign="center"
        data-testid="subscription-grace-end-period-owner"
      >
        <Grid justifyItems="center" alignContent="center" height="full" textAlign="center" gap="m">
          <CrownLarge />
          <Grid gap="s">
            <PageTitle textStyle="heading2Semi">
              {formatMessage('activities.subscription.graceEndPeriod.owner.title')}
            </PageTitle>
            <Text color="neutral.darkest" data-testid="subscription-grace-end-period-owner-description">
              {formatMessage('activities.subscription.graceEndPeriod.owner.description', { planName })}
            </Text>
          </Grid>

          <SectionSettingCard variant="default">
            <Grid gap="m" textAlign="start">
              <Text textStyle="body4Semi" color="neutral.darkest">
                {formatMessage('activities.subscription.graceEndPeriod.owner.capabilities.title', { planName })}
              </Text>

              <Group as="ul" variant="vertical" spacing="xs">
                {capabilities.list.map((item) => (
                  <Group alignItems="center" spacing="xs" key={item} as="li">
                    <Icon type="checked" size="small" color="brand" />
                    <Text textStyle="body4" color="neutral.darkest">
                      {item}
                    </Text>
                  </Group>
                ))}
              </Group>
            </Grid>
          </SectionSettingCard>

          <Button
            label={formatMessage('activities.subscription.graceEndPeriod.owner.cta')}
            size="large"
            onClick={
              firstPaidPlan
                ? () => {
                    track('Organization', 'Click', {
                      Intent: 'subscribe',
                      Cta: 'start-my-free-trial',
                    });
                    goToSubscriptionCheckout({
                      planId: firstPaidPlan.id,
                      analyticsEntryPoint: 'subscription-existing-users-upgrade-modal',
                      analyticsFlow: 'existing-user-transition',
                    });
                  }
                : undefined
            }
            data-testid="subscription-grace-end-period-owner-cta-button"
          />

          <NakedButton
            label={formatMessage('activities.subscription.graceEndPeriod.owner.seeAllPlans')}
            data-testid="subscription-grace-end-period-owner-plans-button"
            variant="primary"
            size="medium"
            onClick={() => {
              track('Organization', 'Click', {
                Intent: 'see-plans-screen',
                Cta: 'see-all-plans',
              });
              goToSubscriptionPlans({
                returnUrl: location.pathname,
                analyticsEntryPoint: 'subscription-existing-users-upgrade-modal',
                analyticsFlow: 'existing-user-transition',
              });
            }}
          />
        </Grid>
      </Container>
    </Layout>
  );
});
