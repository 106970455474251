import { Container, Group, Pill, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

type PlanCardHeaderProps = {
  title: string;
  description: string;
  isCurrent: boolean;
  titleAs?: 'h3' | 'h4';
};

export const ContextualPlanCardHeader = ({ title, description, isCurrent, titleAs }: PlanCardHeaderProps) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Container>
      <Group alignItems="center" spacing="s" justifyContent="space-between">
        <Text textStyle="heading2Semi" as={titleAs}>
          {title}
        </Text>
        {isCurrent && (
          <Pill
            label={formatMessage('activities.subscription.contextualPlans.card.isCurrent')}
            status="neutral"
            type="secondary"
          />
        )}
      </Group>
      <Text textStyle="body3" color="global.neutral.800">
        {description}
      </Text>
    </Container>
  );
};
