import { CustomersDashboardSortQueryParam, useMelioIntl } from '@melio/ar-domain';
import { Button, Icon, SortDirection, SortDropdownMenu, SortDropdownMenuProps } from '@melio/penny';
import { forwardRef, useBoolean } from '@melio/platform-utils';

import { OnSort, Order, SortField, SortMenuItem } from '../types';

export type SortMenuProps = {
  items: SortMenuItem[];
  onSortChange: OnSort;
  sortParams?: CustomersDashboardSortQueryParam;
};

type OnClickProps = {
  sortBy: SortField;
  defaultSortOrder: Order;
};

const invertSortOrder = (order: Order): Order => (order === 'Asc' ? 'Desc' : 'Asc');
const convertSortOrderToSortDirection = (order: Order): SortDirection => (order === 'Asc' ? 'asc' : 'desc');

export const SortMenu = forwardRef<SortMenuProps>(({ items, onSortChange, sortParams }, ref) => {
  const [isMenuOpen, toggleIsMenuOpen] = useBoolean(false);
  const { formatMessage } = useMelioIntl();

  const selectedItemIndex = items.findIndex(({ sortBy }) => sortBy === sortParams?.field);

  const onSort = ({ sortBy, defaultSortOrder }: OnClickProps) => {
    onSortChange(sortBy, sortParams?.field === sortBy ? invertSortOrder(sortParams.order) : defaultSortOrder);

    setTimeout(() => {
      toggleIsMenuOpen.off();
    }, 1000);
  };

  const sortMenuItems: SortDropdownMenuProps['items'] = items.map(({ label, sortBy, defaultSortOrder }) => ({
    id: sortBy,
    label,
    sortDirection: sortParams?.field === sortBy ? convertSortOrderToSortDirection(sortParams.order) : undefined,
    onClick: () => onSort({ sortBy, defaultSortOrder }),
    dataTestId: `customers-dashboard-sort-${sortBy}`,
  }));

  return (
    <SortDropdownMenu
      items={sortMenuItems}
      selectedItemIndex={selectedItemIndex}
      sortDirection={sortMenuItems[selectedItemIndex]?.sortDirection}
      trigger={
        <Button
          leftElement={<Icon size="small" type="sort" color="inherit" aria-hidden />}
          label={formatMessage('ar.dashboard.activities.customersTable.mobileSortMenu.button.label')}
          variant="tertiary"
          size="medium"
          isFullWidth
          aria-label="icon-button-sort"
          data-testid="customers-mobile-sort-button"
        />
      }
      onOpenChange={toggleIsMenuOpen.toggle}
      isOpen={isMenuOpen}
      title={formatMessage('ar.dashboard.activities.customersTable.mobileSortMenu.title')}
      data-component={SortMenu.displayName}
      data-testid="customers-mobile-sort-menu"
      ref={ref}
    />
  );
});
SortMenu.displayName = 'SortMenu';
