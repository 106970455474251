import { object, SchemaOf } from 'yup';

import { VendorDetailsFormFields, VendorDetailsRequiredFormField } from '../types';
import { useFieldsSchemas } from './useFieldsSchemas.hook';

export const useSchema = (
  requiredFields: { [K in VendorDetailsRequiredFormField]: boolean },
  mccGroupsSupported: boolean
) => {
  const schemas = useFieldsSchemas(mccGroupsSupported);

  return object().shape({
    ...(requiredFields.mcc && mccGroupsSupported ? { group: schemas.group } : {}),
    ...(requiredFields.mcc ? { mcc: schemas.mcc } : {}),
    ...(requiredFields.email ? { email: schemas.email } : {}),
    ...(requiredFields.phone ? { phone: schemas.phone } : {}),
    ...(requiredFields.address
      ? {
          line1: schemas.line1,
          line2: schemas.line2,
          city: schemas.city,
          state: schemas.state,
          stateCode: schemas.stateCode,
          countryCode: schemas.countryCode,
          country: schemas.country,
          postalCode: schemas.postalCode,
        }
      : {}),
  }) as SchemaOf<VendorDetailsFormFields>;
};
