import { getInternationalFormattedIdentifierData, isInternationalDeliveryMethod } from '@melio/ap-domain';
import { DeliveryMethod, DeliveryMethodType } from '@melio/platform-api';

import { useRouter } from '@/hooks/router.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { BasicDeliveryMethodCardProps } from '@/types/deliveryMethod.types';
import { DeliveryMethodIconsEnum } from '@/types/vendors.types';
import { DeliveryMethodCard } from '@/widgets/vendorsDashboard/DeliveryMethod/components/DeliveryMethodCard.component';

export interface InternationalDeliveryMethodProps extends BasicDeliveryMethodCardProps {
  internationalFx: DeliveryMethod | null;
  countryDisplayName?: string;
}

export const InternationalFXDeliveryMethodCard = ({
  internationalFx,
  navigationStateParams,
  countryDisplayName,
}: InternationalDeliveryMethodProps) => {
  const { formatMessage } = usePlatformIntl();
  const { generateVendorInternationalFXAccountLink } = useRouter();

  const identifierInfo =
    internationalFx?.details &&
    isInternationalDeliveryMethod(internationalFx) &&
    getInternationalFormattedIdentifierData(internationalFx, countryDisplayName);

  return (
    <DeliveryMethodCard
      testId="MethodCard-international-fx-account"
      deliveryMethodExists={internationalFx !== null}
      navigationParams={generateVendorInternationalFXAccountLink(navigationStateParams)}
      iconType={DeliveryMethodIconsEnum.INTERNATIONAL_FX}
      displayName={formatMessage('widgets.addOrUpdateDeliveryMethod.internationalFX.title')}
      helperText={formatMessage('widgets.addOrUpdateDeliveryMethod.internationalFx.helper')}
      info={
        identifierInfo
          ? {
              info1: formatMessage('widgets.addOrUpdateDeliveryMethod.internationalFx.info.details', identifierInfo),
              info2: '',
            }
          : {
              info1: formatMessage('widgets.addOrUpdateDeliveryMethod.internationalFX.info'),
              info2: '',
            }
      }
      caption={formatMessage('widgets.addOrUpdateDeliveryMethod.internationalFX.comment')}
      type={DeliveryMethodType.InternationalAccount}
    />
  );
};
