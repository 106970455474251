import { UnilateralRequest, UnilateralRequestStatusEnum } from '@melio/platform-api';

export const getActiveUnilateralRequests = (unilateralRequests: UnilateralRequest[]): UnilateralRequest[] =>
  unilateralRequests.filter(
    (unilateralRequest) =>
      unilateralRequest.status === UnilateralRequestStatusEnum.Created ||
      unilateralRequest.status === UnilateralRequestStatusEnum.Sent,
  );

export const getActiveUnilateralRequestsSortedByCreatedDate = (
  unilateralRequests: UnilateralRequest[],
): UnilateralRequest[] => {
  const activeUnilateralRequests = getActiveUnilateralRequests(unilateralRequests);
  return activeUnilateralRequests?.sort(
    (a: UnilateralRequest, b: UnilateralRequest) => b.history.createdAt.getTime() - a.history.createdAt.getTime(),
  );
};

export const getFirstSentAndLastSentUnilateralRequest = (
  unilateralRequests?: UnilateralRequest[],
): {
  firstSentUnilateralRequest: UnilateralRequest | undefined;
  lastSentUnilateralRequest: UnilateralRequest | undefined;
} => {
  const sortedUnilateralRequests =
    unilateralRequests && getActiveUnilateralRequestsSortedByCreatedDate(unilateralRequests);
  const lastSentUnilateralRequest = sortedUnilateralRequests?.[0];
  const firstSentUnilateralRequest = sortedUnilateralRequests?.[sortedUnilateralRequests.length - 1];
  return { firstSentUnilateralRequest, lastSentUnilateralRequest };
};
