import { Container, Group } from '@melio/penny';
import { PaymentFullyExpanded } from '@melio/platform-api';

import { usePaymentDescription } from '../../../hooks/usePaymentDescription';
import { PaymentDescription } from '../PaymentDescription';
import { PaymentDetailsTop } from '../PaymentDetailsTop';
import { PaymentCanceledPayFromSection } from '../sections/PaymentCanceledPayFromSection';
import { PaymentScheduledBySection } from '../sections/PaymentScheduledBySection';
import { SectionWrapper } from '../sections/SectionWrapper';
import { useSections } from '../sections/useSections';

export const PaymentCanceled = ({ payment }: { payment: PaymentFullyExpanded }) => {
  const description = usePaymentDescription(payment);
  const { createdBy, history } = payment;

  const { showScheduledBySection } = useSections({ payment });

  return (
    <Group variant="vertical" spacing="m" data-testid="pay-dashboard-payment-details-canceled">
      {description ? <PaymentDescription description={description} /> : null}
      <Container data-testid="pay-dashboard-payment-failed">
        <Group variant="vertical" spacing="m">
          <SectionWrapper>
            <PaymentDetailsTop payment={payment} />
          </SectionWrapper>

          {showScheduledBySection ? (
            <SectionWrapper>
              {createdBy ? <PaymentScheduledBySection scheduledDate={history.createdAt} user={createdBy} /> : null}
            </SectionWrapper>
          ) : null}

          <SectionWrapper>
            <PaymentCanceledPayFromSection payment={payment} />
          </SectionWrapper>
        </Group>
      </Container>
    </Group>
  );
};
