import { isSinglePaymentFields } from '@melio/ap-activities';
import { AddBillFormWidgetFields } from '@melio/ap-widgets';
import { useNavigate } from '@melio/platform-utils';

import { useRouter } from '@/hooks/router.hooks';
import { PayDashboardTabs } from '@/types/payDashboard.types';

export const useAddNewBillEvents = (deliveryMethodId?: string, returnUrl?: string) => {
  const navigate = useNavigate();
  const { goToPayDashboard, replaceToPayDashboardTab, navigateToSchedulePayment } = useRouter();

  const onDone = (data: AddBillFormWidgetFields, billId?: string, target?: 'continue' | 'close' | 'markAsPaid') => {
    if (isSinglePaymentFields(data) && billId) {
      switch (target) {
        case 'close':
          if (returnUrl) {
            navigate(returnUrl, { keepToast: true });
          } else replaceToPayDashboardTab(PayDashboardTabs.Inbox, billId, { keepToast: true });
          break;
        case 'continue':
          navigateToSchedulePayment({ billId, deliveryMethodId, returnUrl });
          break;
      }
    }
  };

  const onBack = () => {
    if (returnUrl) {
      return navigate(returnUrl);
    }
    goToPayDashboard();
  };

  const onClose = () => {
    if (returnUrl) {
      return navigate(returnUrl);
    }
    goToPayDashboard();
  };

  return { onDone, onBack, onClose };
};
