import { useState } from 'react';

import { CreateFundingSourceActivityStep } from './types';

const initialStep = 'SELECT_PAYMENT_METHOD_TYPE';

export const useCreateFundingSourceActivityStep = () => {
  const [currentStep, setCurrentStep] = useState<CreateFundingSourceActivityStep>(initialStep);

  const goToPreviousStep = (): void => {
    if (currentStep !== initialStep) {
      setCurrentStep(initialStep);
    }
  };

  return {
    currentStep,
    goToPreviousStep,
    goToStep: setCurrentStep,
  };
};
