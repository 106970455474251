import { Container, Group, Illustration, Layout, Text } from '@melio/penny';
import type { IllustrationProps } from '@melio/penny/dist/types/foundations/Illustration/Illustration.types';
import React from 'react';

type FullScreenLayoutProps = {
  title: string;
  content: string;
  actions: React.ReactNode;
  illustration: IllustrationProps['type'];
};
export const FullScreenLayout = ({ illustration, title, content, actions }: FullScreenLayoutProps) => (
  <Layout variant="full">
    <Container alignItems="center" justifyContent="center" height="full">
      <Group textAlign="center" spacing="m" variant="vertical" width={{ xs: '100%', s: '488px' } as never}>
        <Illustration type={illustration} />
        <Text textStyle="heading3Semi">{title}</Text>
        <Text textStyle="body2">{content}</Text>
        <Group justifyContent="center" spacing="s">
          {actions}
        </Group>
      </Group>
    </Container>
  </Layout>
);
