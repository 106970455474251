import { useMelioQueryClient } from '@melio/api-client';
import { AccountsApiClient } from '@melio/platform-api-axios-client';

import { useModel, UseModelProps } from '../../api-client';

type QueryFn = typeof AccountsApiClient.getAccountsAccountId;
type UpdateFn = typeof AccountsApiClient.patchAccountsAccountId;
type CreateFn = typeof AccountsApiClient.postAccounts;

export type UseAccountProps = UseModelProps<QueryFn, UpdateFn, CreateFn>;

export const useAccount = ({ onUpdate, ...props }: UseAccountProps = {}) => {
  const queryClient = useMelioQueryClient();

  return useModel({
    ...props,
    queryKey: 'AccountsApi',
    queryFn: AccountsApiClient.getAccountsAccountId,
    createFn: AccountsApiClient.postAccounts,
    updateFn: AccountsApiClient.patchAccountsAccountId,
    onUpdate: (...args) => {
      void queryClient.invalidateQueries('invoicePreviewEmail' as never);
      void queryClient.invalidateQueries('invoicePreviewPDF' as never);
      void onUpdate?.(...args);
    },
  });
};

export type AccountModel = ReturnType<typeof useAccount>;
