import { Box } from '@chakra-ui/react';
import { DeliveryMethod } from '@melio/platform-api';
import { SettingsPageHeader } from '@melio/platform-utils';

import { usePlatformIntl } from '@/translations/Intl';
import { DeliveryMethodsList } from '@/widgets/settings-page/ReceivingMethodsPage/DeliveryMethodsList.widget';

interface ReceivingMethodsSettingProps {
  deliveryMethods: DeliveryMethod[] | undefined;
}

export const ReceivingMethodsSettingsPage = ({ deliveryMethods }: ReceivingMethodsSettingProps) => {
  const { formatMessage } = usePlatformIntl();
  return (
    <Box>
      <SettingsPageHeader
        title={formatMessage('app.settings.BillingSection.cards.receivingMethods.title')}
        subTitle={formatMessage('app.settings.BillingSection.cards.receivingMethods.description')}
      />
      <DeliveryMethodsList deliveryMethods={deliveryMethods} />
    </Box>
  );
};
