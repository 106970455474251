import { Box, Flex } from '@chakra-ui/react';
import { PartnerGroupEnum } from '@melio/partner-tools';
import { Container, Grid, Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { SubscriptionBillingCycleEnum } from '../../../../api';
import { usePartnerGroup } from '../../../../hooks';
import { roundFinalPrice } from './PlanCardPrice.utils';

export type PlanCardPriceProps = {
  isFreePlan: boolean;
  selectedBillingCycle: SubscriptionBillingCycleEnum;
  finalPrice: number;
  priceBeforeDiscount?: number;
  unitPrice?: number;
};

export const PlanCardPrice = ({
  isFreePlan,
  selectedBillingCycle,
  finalPrice,
  priceBeforeDiscount,
  unitPrice,
}: PlanCardPriceProps) => {
  const { formatCurrency, formatMessage } = useMelioIntl();
  const partnerGroup = usePartnerGroup();

  const finalPriceRounded = roundFinalPrice(finalPrice);

  return (
    <Grid>
      <Container>
        {!!priceBeforeDiscount && (
          <Text textStyle="body2" color="global.neutral.600">
            <s data-testid="plan-card-price-before-discount">
              {formatCurrency(priceBeforeDiscount, undefined, { notation: 'compact' })}
            </s>
          </Text>
        )}
      </Container>

      <Container>
        <Group variant="vertical" spacing="xxxs">
          <Group alignItems="center" spacing="xxs">
            <Text textStyle="display2Semi" data-testid="plan-card-price">
              {formatCurrency(finalPriceRounded).replace(/\.00$/, '')}
            </Text>
            <Box as="span" textStyle="body2Semi" hidden={isFreePlan}>
              {formatMessage('activities.subscription.plans.planCard.price.perMonth')}
            </Box>
          </Group>

          {partnerGroup !== PartnerGroupEnum.FISERV ? (
            <Container paddingTop="s">
              <Flex direction="column">
                {!isFreePlan && (
                  <Text textStyle="body3" data-testid="plan-card-unit-price">
                    {formatMessage('activities.subscription.plans.planCard.price.description.excessUser', {
                      unitPrice: formatCurrency(unitPrice ?? 0, undefined, { notation: 'compact' }),
                    })}
                  </Text>
                )}
                <Text textStyle="body3" color="global.neutral.800">
                  {formatMessage(
                    `activities.subscription.plans.planCard.price.description.${
                      isFreePlan ? 'free' : selectedBillingCycle
                    }`
                  )}
                </Text>
              </Flex>
            </Container>
          ) : null}
        </Group>
      </Container>
    </Grid>
  );
};
