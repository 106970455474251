import { useRoleText } from '@melio/ap-domain';
import { Form, MultiSelectOption } from '@melio/penny';
import { useCollaborators } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { isEmpty, sortBy } from 'lodash';

import { useFormContext } from '../../FormContext';

const useSpecificSchedulersOptions = () => {
  const { getRoleText } = useRoleText();

  return useCollaborators<MultiSelectOption>({
    expand: ['user'],
    select(res) {
      return res
        .filter(({ approvalActions: { canApprovePayments } }) => !canApprovePayments)
        .map((collaborator) => ({
          label: `${collaborator.user?.firstName} ${collaborator.user?.lastName}`,
          value: collaborator.userId,
          'data-testid': `specific-collaborator-${collaborator.userId}`,
          pillProps: {
            label: getRoleText(collaborator.roleUniqueName),
            status: 'neutral',
          },
        }));
    },
  });
};

export const CollaboratorsSelector = ({ index }: { index: number }) => {
  const { formatMessage } = useMelioIntl();
  const { registerField } = useFormContext();

  const field = registerField(`criteria.conditions.${index}.condition.configuration.ids`);

  const { data, isLoading } = useSpecificSchedulersOptions();
  return !isEmpty(data) ? (
    <Form.MultiSelect
      {...registerField(`criteria.conditions.${index}.condition.configuration.ids`)}
      aria-label="specific collaborators"
      data-testid="specific-collaborators-list"
      placeholder={formatMessage(
        'activities.create-approval-workflows.conditions.scheduler.options.specific.placeholder'
      )}
      options={sortBy(data, 'label')}
      isDisabled={isLoading}
      error={
        field.error
          ? { message: formatMessage('activities.create-approval-workflows.conditions.scheduler.errors.required') }
          : undefined
      }
    />
  ) : (
    <Form.SelectNew
      {...registerField(`criteria.conditions.${index}.condition.configuration.ids`)}
      aria-label="specific collaborators"
      data-testid="specific-collaborators-list-empty-data"
      placeholder={formatMessage(
        'activities.create-approval-workflows.conditions.scheduler.options.specific.placeholder'
      )}
      options={[]}
      emptyState={formatMessage('activities.create-approval-workflows.conditions.scheduler.options.empty-data')}
      isDisabled={isLoading}
      error={
        field.error
          ? { message: formatMessage('activities.create-approval-workflows.conditions.scheduler.errors.required') }
          : undefined
      }
    />
  );
};
