/**
 *
 * @param finalPrice  - The final price to be rounded
 * @returns if the final price is divisible by 0.05, it returns the final price,
 * otherwise it returns the rounded final price to the nearest number with one decimal place
 */
export const roundFinalPrice = (finalPrice: number) => {
  if ((finalPrice * 100) % 5 === 0) {
    return finalPrice;
  }

  return Math.round(finalPrice * 10) / 10;
};
