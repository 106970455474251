import { WorkflowCriteria } from '@melio/platform-api';

import { Condition, ConditionFormatter } from '../types';
import { useWorkflowCollaboratorsConditionFormatter } from './useWorkflowCollaboratorsConditionFormatter';
import { useWorkflowNumericConditionFormatter } from './useWorkflowNumericConditionFormatter';
import { useWorkflowVendorsConditionFormatter } from './useWorkflowVendorsConditionFormatter';

type AllTypes = Condition['condition']['type'];
export type CriteriaFormatter = {
  [key in AllTypes]: ConditionFormatter<Condition>;
};
export function useWorkflowCriteriaConditionFormatter({ criteria }: { criteria: WorkflowCriteria }) {
  const { format: workflowNumericConditionFormatter } = useWorkflowNumericConditionFormatter();
  const { format: workflowCollaboratorsConditionFormatter } = useWorkflowCollaboratorsConditionFormatter();
  const { format: workflowVendorsConditionFormatter } = useWorkflowVendorsConditionFormatter({ criteria });

  function format(condition: Condition, index: number) {
    const templateKey = index
      ? 'activities.settings.approval-workflow-settings.conditions.condition.nth'
      : ('activities.settings.approval-workflow-settings.conditions.condition.first' as const);

    switch (condition.attribute) {
      case 'payment.amount':
        return workflowNumericConditionFormatter({ condition, templateKey });
      case 'payment.createdBy':
        return workflowCollaboratorsConditionFormatter({ condition, templateKey });
      case 'payment.vendor':
        return workflowVendorsConditionFormatter({ condition, templateKey });
    }
  }
  return { format };
}
