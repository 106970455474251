import { apiClient, CountryInternationalDeliveryDetails } from '@melio/javascript-sdk';

import { useCollectionApi, UseCollectionApiProps } from '../../core';
import { InternationalCountriesDetailsCollection } from './types';

type UseInternationalCountriesDetailsProps = UseCollectionApiProps<CountryInternationalDeliveryDetails>;

export const useInternationalCountriesDetails = (
  props: UseInternationalCountriesDetailsProps = {}
): InternationalCountriesDetailsCollection => {
  const client = apiClient.internationalCountries();
  const query = useCollectionApi<CountryInternationalDeliveryDetails>({
    ...props,
    queryKey: 'international-countries-details',
    queryFn: client.fetch,
  });

  return query;
};
