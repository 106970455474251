import { usePlaidAuth } from '@melio/ap-widgets';
import { NakedButton } from '@melio/penny';
import { Card, FundingSource } from '@melio/platform-api';

import { usePlatformIntl } from '@/translations/Intl';

type AddFundingSourceButtonProps = {
  fundingType: FundingSource['type'];
  cardType?: Card['type'];
  onClick: (label: string) => void;
};

export const AddFundingSourceButton = ({ fundingType, cardType, onClick }: AddFundingSourceButtonProps) => {
  const label = `+ ${useGetLabel(fundingType, cardType)}`;
  return (
    <NakedButton
      data-testid={`add-${fundingType as string}-funding-source-button`}
      data-component="AddFundingSourceButton"
      variant="secondary"
      onClick={() => onClick(label)}
      label={label}
    />
  );
};

export const useGetLabel = (fundingType: FundingSource['type'], cardType?: Card['type']): string => {
  const { formatMessage } = usePlatformIntl();
  const { enabled: isConnectingWithPlaid } = usePlaidAuth({ isReceivingMethodFlow: false });

  if (fundingType === 'bank-account') {
    return isConnectingWithPlaid
      ? formatMessage('widgets.paymentMethods.existing.bank.connectAnother')
      : formatMessage('widgets.paymentMethods.existing.bank.addAnother');
  } else if (cardType === 'credit') {
    return formatMessage('widgets.paymentMethods.existing.credit.addAnother');
  } else if (cardType === 'debit') {
    return formatMessage('widgets.paymentMethods.existing.debit.addAnother');
  } else if (fundingType === 'card') {
    return formatMessage('widgets.paymentMethods.existing.card.addAnother');
  }
  console.log(`Label for ${fundingType}:${cardType} not implemented`);
  return '';
};
