import { useAccountingPlatformName } from '@melio/ap-widgets';
import { useAnalyticsContext } from '@melio/platform-analytics';
import { useAccountingPlatforms } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useDateUtils } from '@melio/platform-utils';
import { isEmpty } from 'lodash';
import { useSearchParams } from 'react-router-dom';

import { getBillsTabItemAmount, getBillsTabItemVendorId, isPartiallyPaid } from './BillsTab.utils';
import { getBillsTabOrigin } from './components/BillsTabOriginCell';
import { BillStatus, getBillsTabStatus } from './components/BillsTabStatusCell';
import { BillsTabItem, SelectedInboxItemType } from './types';

export const useBillsTabAnalytics = ({
  billItems,
  rowSelections,
  areAllSelected,
}: {
  billItems: BillsTabItem[];
  rowSelections: SelectedInboxItemType[];
  areAllSelected: boolean;
}) => {
  const { formatMessage } = useMelioIntl();
  const { activeAccountingPlatform } = useAccountingPlatforms();
  const accountingPlatformName = useAccountingPlatformName(activeAccountingPlatform?.accountingSlug);
  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get('search');
  const { createDate, isDatePartEqual } = useDateUtils();
  const todayDate = createDate();

  const getBIEventBillStatusName = (origin: BillStatus): string => {
    switch (origin) {
      case BillStatus.Overdue:
        return 'Overdue';
      case BillStatus.DueToday:
        return 'DueToday';
      case BillStatus.DueDateInNext7Days:
        return 'dueNext7Days';
      default:
        return 'DueLater';
    }
  };

  useAnalyticsContext({
    globalProperties: {
      PageName: 'dashboard-bills',
      Flow: 'dashboard',
      CountInTab: billItems.length,
      BillsSelected: rowSelections.filter((row) => row.type === 'bill').map(({ id }) => id),
      PaymentRequestsSelected: rowSelections.filter((row) => row.type === 'paymentRequest').map(({ id }) => id),
      MultiSelection: areAllSelected,
      SearchValue: searchTerm,
      TPV: billItems.reduce((total, item) => total + (getBillsTabItemAmount(item) ?? 0), 0),
      PartialPayments: billItems.reduce<number>((total, item) => total + (isPartiallyPaid(item) ? 1 : 0), 0),
      BillCreatedOrigin: billItems.reduce<Record<string, number>>((originObj, item) => {
        const origin = getBillsTabOrigin(item, accountingPlatformName, formatMessage).originLabel;
        return { ...originObj, [origin]: (originObj[origin] ?? 0) + 1 };
      }, {}),
      billStatus: billItems.reduce<Record<string, number>>((originObj, item) => {
        const origin = item.payload.dueDate
          ? getBillsTabStatus({
              dueDate: item.payload.dueDate,
              todayDate,
              isTodayEqualDueDate: isDatePartEqual(item.payload.dueDate, todayDate),
            })
          : BillStatus.DueLater;
        return { ...originObj, [getBIEventBillStatusName(origin)]: (originObj[origin] ?? 0) + 1 };
      }, {}),
      UniqueVendors: billItems
        ? Object.keys(
            billItems.reduce<Record<string, number>>((vendorsObj, item) => {
              const vendorId = getBillsTabItemVendorId(item);
              if (!vendorId) {
                return vendorsObj;
              }
              return { ...vendorsObj, [vendorId]: (vendorsObj[vendorId] ?? 0) + 1 };
            }, {})
          )
        : 0,
      IsSync: isEmpty(accountingPlatformName) ? false : true,
      AccountingSoftwareType: isEmpty(accountingPlatformName) ? null : accountingPlatformName,
    },
  });
};
