import { Box } from '@chakra-ui/react';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useConfig } from '@melio/platform-provider';
import { useShowSubscriptionChangePlanButton } from '@melio/subscriptions';

import { DemoLoginAccountUrl } from '@/cl/components/DemoLoginAccountUrl/DemoLoginAccountUrl.component';
import { TopNavProps } from '@/cl/layouts/app-layout/topnav.layout.types';
import { TopNavSubscriptionChangePlanButton } from '@/cl/layouts/app-layout/topnav.subscriptionChangePlanButton.component';
import { useAppTheme } from '@/cl/theme/theme.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { AccountSelectWidget } from '@/widgets/AccountSelect/AccountSelect.widget';
import { BackToPartner } from '@/widgets/BackToPartner/BackToPartner.widget';
import { OrganizationSelectWidget } from '@/widgets/OrganizationSelect/OrganizationSelect.widget';
import { TopNavLayoutTheme } from './topnav.layout.theme';

export const TopLayout = (props: TopNavProps) => {
  const {
    TopNavIcon,
    TopNavSmallIcon,
    BackToPartnerIcon,
    showOrgSwitcher = true,
    SyncNowIcon,
    SyncMigrationProgressIndication,
  } = props;
  const [multiOrgUi] = useFeature<boolean>(FeatureFlags.MultiOrgUI, false);
  const [useOrganizationsEndpointForCompaniesList] = useFeature<boolean>(
    FeatureFlags.UseOrganizationsEndpointForCompaniesList,
    false,
  );
  const styles = useAppTheme(TopNavLayoutTheme, {});
  const config = useConfig();
  const { formatMessage } = usePlatformIntl();
  const shouldShowSwitcher = multiOrgUi && showOrgSwitcher;

  const shouldShowSubscriptionChangePlanButton = useShowSubscriptionChangePlanButton();

  return (
    <Box
      as="header"
      aria-label={formatMessage('app.topnav.ariaLabel')}
      role="banner"
      data-testid="top-nav-container"
      data-component={'top-nav'}
      sx={styles['container']}
    >
      {TopNavSmallIcon && (
        <Box data-testid="top-nav-small-logo" sx={styles['smallIconContainer']}>
          <TopNavSmallIcon />
        </Box>
      )}
      {BackToPartnerIcon && <BackToPartner BackIcon={BackToPartnerIcon!} />}
      {shouldShowSwitcher &&
        (useOrganizationsEndpointForCompaniesList ? <OrganizationSelectWidget /> : <AccountSelectWidget />)}
      {SyncMigrationProgressIndication && <SyncMigrationProgressIndication />}
      {SyncNowIcon && <SyncNowIcon />}
      {!TopNavSmallIcon && (
        <Box data-testid="top-nav-logo" sx={styles['iconContainer']}>
          <TopNavIcon sx={styles['centerIcon']} />
        </Box>
      )}
      {shouldShowSubscriptionChangePlanButton && <TopNavSubscriptionChangePlanButton />}
      {!config.production && <DemoLoginAccountUrl />}
    </Box>
  );
};
