import { FieldValues, UseMelioFormResults } from '@melio/penny';
import { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Path } from 'react-hook-form';

export const useTriggerFormValidationFields = <T extends FieldValues>({
  watch,
  trigger,
  formState,
}: UseMelioFormResults<T>) => {
  type Field = Path<T>;
  type Fields = Array<Field>;

  const [lastStepTriggeredFields, setLastStepLastStepTriggeredFields] = useState<Fields>([]);
  const watchTriggeredFields = watch(lastStepTriggeredFields);
  // hold last triggered fields keys in order to track change in form state
  const triggeredFieldsKey = [...lastStepTriggeredFields, ...watchTriggeredFields].join('');
  // Trigger validations on every field change (after the first attempt)
  useEffect(() => {
    void trigger(lastStepTriggeredFields);
  }, [triggeredFieldsKey, lastStepTriggeredFields, trigger]);

  return async (fields: Fields) => {
    await trigger(fields);
    setLastStepLastStepTriggeredFields(fields);

    const filteredErrors = fields.reduce<Partial<Record<Field, boolean>>>((acc, field) => {
      if (formState.errors[field]) {
        acc[field] = true;
      }
      return acc;
    }, {});

    return Object.keys(filteredErrors).length === 0;
  };
};
