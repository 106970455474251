import { useBill, useFile } from '@melio/platform-api';

export const usePaymentFlowBillDetails = ({ billId }: { billId?: string }) => {
  const { data: bill, isLoading: isBillLoading } = useBill({
    enabled: Boolean(billId),
    id: billId || 'usePaymentFlowBillDetailsBillId',
    params: { expand: 'none' },
  });

  const fileId = bill?.invoice.fileId;
  const { data: file, isLoading: isFileLoading } = useFile({
    enabled: Boolean(fileId),
    id: fileId || 'usePaymentFlowBillDetailsFileId',
  });

  return {
    bill,
    file,
    isLoading: isBillLoading || isFileLoading,
  };
};
