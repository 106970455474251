import { PURPOSE_PAYMENT_OPTIONS } from '../../components/international-payment/payment-purpose/screens/payment-purpose-form/paymentPurposeForm.utils';

export const getDefaultPaymentPurposeFormFields = (paymentPurpose?: string) => {
  const paymentPurposeOption = PURPOSE_PAYMENT_OPTIONS.find((value) => paymentPurpose?.startsWith(value)) || '';

  return {
    paymentPurpose: paymentPurposeOption,
    purposeDescription: paymentPurposeOption ? paymentPurpose?.split(paymentPurposeOption)[1]?.trim() : '',
  };
};
