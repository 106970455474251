import { Container, NakedButton, SectionBanner, SectionBannerProps, Text } from '@melio/penny';
import { useUserPreferences } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useCallback } from 'react';

import { PromotionFlags, PromotionType } from '../types';

type AccountingPlatformSyncPromotionBannerProps = {
  'data-testid': string;
  promoType: PromotionType;
  icon: SectionBannerProps['icon'];
  onDismiss(): void;
  onConfirm(): void;
};
export const PromotionBanner = ({
  'data-testid': dataTestId,
  promoType,
  icon,
  onDismiss,
  onConfirm,
}: AccountingPlatformSyncPromotionBannerProps) => {
  const { formatMessage } = useMelioIntl();

  const { create: createUserPreferences } = useUserPreferences({
    enabled: false,
  });

  const onDismissHandler = useCallback(async () => {
    onDismiss();
    await createUserPreferences({
      userPreference: { key: PromotionFlags[promoType].userPreferenceKey, value: 'true' },
    });
  }, [createUserPreferences, onDismiss, promoType]);

  return (
    <SectionBanner
      variant="neutral"
      icon={icon}
      data-testid={dataTestId}
      onClose={onDismissHandler}
      showCloseIcon
      title={formatMessage(`activities.promotions.${promoType}.title`)}
      description={
        <Container>
          <Text textStyle="body3">
            {formatMessage(`activities.promotions.${promoType}.description`)}{' '}
            <NakedButton
              variant="secondary"
              data-testid={`${dataTestId}-cta`}
              label={formatMessage(`activities.promotions.${promoType}.cta`)}
              onClick={onConfirm}
            />
          </Text>
        </Container>
      }
    />
  );
};
