import { DeliveryPreferenceType, ServicesApiClient } from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useQuery } from 'react-query';

import { PostPaymentCalendarRequest } from './types';
import { preparePaymentCalendarRequest } from './utils';

export type UsePaymentCalendarProps = Pick<
  PostPaymentCalendarRequest,
  'startDate' | 'endDate' | 'amount' | 'isFinancing' | 'currency'
> & {
  fundingSourceId: string | undefined;
  vendorId: string | undefined;
  deliveryMethodId: string | undefined;
  deliveryPreference: DeliveryPreferenceType | undefined;
};

export const usePaymentCalendar = (props: UsePaymentCalendarProps) => {
  const { fundingSourceId, vendorId, deliveryMethodId, deliveryPreference } = props;

  return useQuery({
    enabled: Boolean(fundingSourceId && vendorId && deliveryMethodId && deliveryPreference),
    queryKey: ['ServicesApi', 'PaymentCalendar', props],
    queryFn: async () => {
      if (fundingSourceId && vendorId && deliveryMethodId && deliveryPreference) {
        const result = await ServicesApiClient.postServicesPaymentCalendar(
          preparePaymentCalendarRequest({
            ...props,
            fundingSourceId,
            vendorId,
            deliveryMethodId,
            deliveryPreference,
          })
        );
        return result.data.data;
      }
      return;
    },
  });
};
