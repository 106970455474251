/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useSetRecoilState } from 'recoil';
import { LocalStorageKeys } from '@melio/local-storage';
import { Button } from '@melio/penny';
import { useNavigate } from '@melio/platform-utils';

import { createNewDemoAccount } from '@/api/auth-demo/auth-demo.api';
import { AbsoluteCenter } from '@/cl/components/AbsoluteCenter/AbsoluteCenter.component';
import { WithLoading } from '@/hoc/withLoading.hoc';
import { usePartnerConfig } from '@/hooks/partners';
import { usePartnerLocalStorage } from '@/hooks/partners/usePartnerLocalStorage';
import { useRouter } from '@/hooks/router.hooks';
import { useClearSession, useResetAccessToken } from '@/hooks/session.hooks';
import { useAppRedirectData } from '@/hooks/useAppRedirect.hooks';
import { useGoogleSSO } from '@/hooks/useGoogleSSO';
import { appAccountUrlSelector } from '@/store/app/app.model';
import { generateAccountUrl } from '@/utils/generateAccountUrl';

type AuthDemoRouteProps = {
  accountId?: string;
};

export const AuthDemoRoute = ({ accountId = 'new' }: AuthDemoRouteProps) => {
  const [status, setStatus] = React.useState<'success' | 'error' | undefined>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { partnerConfig, partnerName } = usePartnerConfig();
  const { goToAppRedirect, goToSessionExpired } = useRouter();
  const resetAccessToken = useResetAccessToken();
  const clearSession = useClearSession();
  const setAccountUrl = useSetRecoilState(appAccountUrlSelector);
  const { isLoggedIn: isGoogleLoggedIn, signIn, isLoading: isGoogleSSOLoading } = useGoogleSSO();
  const localStorage = usePartnerLocalStorage();
  const { setAppRedirectData } = useAppRedirectData();
  const navigate = useNavigate();
  const getAccessToken = React.useCallback(async (): Promise<{
    accessToken?: string;
    refreshToken?: string;
    accountId?: string;
  }> => {
    if (isGoogleLoggedIn) {
      const {
        data: { data },
      } = await createNewDemoAccount(partnerName, accountId!);
      if (data.accessToken) {
        return {
          accountId: data.accountId,
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
        };
      } else {
        throw new Error(`could not get accessToken for demo route`);
      }
    } else {
      return {};
    }
  }, [partnerConfig, accountId, isGoogleLoggedIn]);

  const startDemo = React.useCallback(async () => {
    try {
      setLoading(true);
      if (localStorage.getItem(LocalStorageKeys.accessToken)) {
        await clearSession();
      }
      const { accessToken, refreshToken, accountId } = await getAccessToken();
      if (accessToken) {
        resetAccessToken(accessToken, refreshToken);
        accountId && setAccountUrl(generateAccountUrl({ accountId, partnerName }));
        setLoading(false);
        setStatus('success');
      }
    } catch (e) {
      console.error(e);
      setStatus('error');
    } finally {
      setLoading(false);
    }
  }, [setLoading, clearSession, resetAccessToken, localStorage]);

  React.useEffect(() => {
    startDemo();
  }, [isGoogleLoggedIn]);

  React.useEffect(() => {
    if (status === 'success') {
      setAppRedirectData({ isFirstTimeLogin: accountId === 'new' });
      if (partnerName === 'deployments-demo') {
        navigate('/embedded-experience');
      } else {
        goToAppRedirect();
      }
    } else if (status === 'error') {
      goToSessionExpired();
    }
  }, [status, navigate]);

  return (
    <WithLoading isLoading={loading || isGoogleSSOLoading} isAbsoluteCenter>
      {!isGoogleLoggedIn && (
        <AbsoluteCenter>
          <Button onClick={() => signIn()} label={'Sign in with Google'} />
        </AbsoluteCenter>
      )}
    </WithLoading>
  );
};
