import { Box } from '@chakra-ui/react';
import { AddPlaidAccountWidget, PlaidEventData } from '@melio/ap-widgets';
import { LoadingContainer } from '@melio/penny';
import { PlaidAccountData } from '@melio/platform-api';
import { forwardRef, useBoolean } from '@melio/platform-utils';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';

type BankIntegrationPlaidLinkingScreenProps = {
  onClose: VoidFunction;
  onError?: ErrorFunction;
  onSuccess: (data: PlaidAccountData) => void;
  onEvent: (data: PlaidEventData) => void;
  isLoading: boolean;
  plaidToken: string;
};

export const BankIntegrationPlaidLinkingScreen = forwardRef<BankIntegrationPlaidLinkingScreenProps, 'iframe'>(
  ({ onClose, onError, onSuccess, onEvent, isLoading: isRequestLoading, plaidToken, ...props }, ref) => {
    const [isPlaidUiLoading, loading] = useBoolean(true);
    const isLoading = isRequestLoading || isPlaidUiLoading || !plaidToken;

    return (
      <LoadingContainer isLoading={isLoading}>
        <NewSinglePaymentStepLayout
          data-component="AddBankAccountPlaidActivity.BankIntegrationPlaidLinkingScreen"
          data-testid="add-bank-account-plaid-activity-bank-integration-plaid-linking-screen"
          paddingContent={{ lineHeight: 'none' }}
          {...props}
          ref={ref}
        >
          <NewSinglePaymentStepLayout.Content>
            <Box hidden={isLoading}>
              <AddPlaidAccountWidget
                onSuccess={onSuccess}
                onExit={onClose}
                onLoad={loading.off}
                onError={onError}
                onEvent={onEvent}
                plaidToken={plaidToken}
              />
            </Box>
          </NewSinglePaymentStepLayout.Content>
        </NewSinglePaymentStepLayout>
      </LoadingContainer>
    );
  }
);

BankIntegrationPlaidLinkingScreen.displayName = 'AddBankAccountPlaidActivity.BankIntegrationPlaidLinkingScreen';
