import { Spinner } from '@chakra-ui/react';
import { FormattedMessage, useMelioIntl } from '@melio/ar-domain';
import { Avatar, Button, Container, Form, Group, Icon, Pill, Text, useMelioForm } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import { date, object, SchemaOf } from 'yup';

import { ScheduledDateField, useEstimatedDeliveryDate } from '../../../components';
import { ScheduledDateDetails } from '../../types';
import { getClosestBusinessDay } from '../../utils';

const useSchema = () => {
  const { formatMessage } = useMelioIntl();

  return object().shape({
    scheduledDate: date().required(formatMessage('ar.guestPayment.activities.scheduledDate.required.label')),
  }) as SchemaOf<ScheduledDateDetails>;
};

export type PayByBankAccountScreenProps = {
  onSubmit: ({ scheduledDate }: { scheduledDate: Date }) => void;
  fundingSourceName?: string;
  isSaving: boolean;
  amount: number;
  logo?: string;
};

export const PayByBankAccountScreen = forwardRef<PayByBankAccountScreenProps>(
  ({ logo, fundingSourceName, onSubmit, isSaving, amount }, ref) => {
    const minScheduledDate = getClosestBusinessDay();
    const { track } = useAnalytics();
    const { formatDate, formatCurrency, formatMessage } = useMelioIntl();
    const { formProps, registerField, setValue, watch } = useMelioForm<ScheduledDateDetails>({
      onSubmit,
      schema: useSchema(),
      isSaving,
      defaultValues: { scheduledDate: minScheduledDate },
    });

    const handleOnSubmit = () => {
      track('PaymentRequest', 'Click', {
        Intent: 'pay-invoice',
        Cta: 'pay',
      });
      return onSubmit({ scheduledDate: watch('scheduledDate') });
    };

    return (
      <Group variant="vertical" spacing="l" data-testid="pay-by-bank-account-screen" ref={ref}>
        <Group variant="vertical" spacing="m">
          <Group variant="vertical" spacing="xxs">
            <Text textStyle="body2Semi">
              <FormattedMessage id="ar.guestPayment.payByBank.form.title.text" />
            </Text>
            <Text textStyle="body3">
              <FormattedMessage id="ar.guestPayment.payByBank.form.description.text" />
            </Text>
          </Group>
          <Container border="regular" paddingX="m" paddingY="m">
            {isSaving ? (
              <Group justifyContent="center">
                <Spinner />
              </Group>
            ) : (
              <Group alignItems="center">
                {logo ? <Avatar name={fundingSourceName || ''} src={logo} /> : <Icon type="bank" />}
                <Group spacing="none" variant="vertical">
                  <Text textStyle="body2Semi">
                    <FormattedMessage id="ar.guestPayment.payByBank.accountCard.subTitle.text" />
                  </Text>
                  <Text color="global.neutral.800" textStyle="body4">
                    {fundingSourceName}
                  </Text>
                </Group>
              </Group>
            )}
          </Container>
          <Form data-component="ScheduledDateForm" {...formProps}>
            <Group variant="vertical" spacing="xs-s" width="full">
              <ScheduledDateField
                fundingSourceType="bank-account"
                scheduledDate={watch('scheduledDate')}
                minScheduledDate={minScheduledDate}
                setScheduledDate={(scheduledDate: Date) => setValue('scheduledDate', scheduledDate)}
                {...registerField('scheduledDate')}
              />
              <Pill
                status="neutral"
                type="secondary"
                label={formatMessage('ar.guestPayment.activities.scheduledDate.estimation.label', {
                  date: formatDate(useEstimatedDeliveryDate(watch('scheduledDate'), 'bank-account')),
                })}
              />
            </Group>
          </Form>
        </Group>
        <Button
          isLoading={isSaving}
          data-testid="add-bank-submit-button"
          size="large"
          label={formatMessage('ar.guestPayment.activities.cardHolder.form.buttons.submit.text', {
            amount: formatCurrency(amount),
          })}
          onClick={handleOnSubmit}
        />
      </Group>
    );
  }
);
PayByBankAccountScreen.displayName = 'PayByBankAccountScreen';
