import { useNavigate } from 'react-router-dom';
import { getDeliveryMethodIconKey } from '@melio/ap-domain';
import { VirtualCardInfoModal } from '@melio/ap-widgets';
import { IconKey, NakedButton } from '@melio/penny';
import { DeliveryMethodType, VirtualCardAccountDeliveryMethod } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';

import { useRouter } from '@/hooks/router.hooks';
import { useDisclosure } from '@/hooks/useDisclosure';
import { usePlatformIntl } from '@/translations/Intl';
import { BasicDeliveryMethodCardProps } from '@/types/deliveryMethod.types';
import { DeliveryMethodCard } from '@/widgets/vendorsDashboard/DeliveryMethod/components/DeliveryMethodCard.component';
export interface UnmanagedVirtualCardDeliveryMethodWidgetProps extends BasicDeliveryMethodCardProps {
  unmanagedVirtualCard: VirtualCardAccountDeliveryMethod | null;
}

export const UnmanagedVirtualCardDeliveryMethodWidget = ({
  unmanagedVirtualCard,
  navigationStateParams,
}: UnmanagedVirtualCardDeliveryMethodWidgetProps) => {
  const { formatMessage, formatDateWithTime } = usePlatformIntl();
  const { settings } = useConfig();
  const { generateVendorVirtualCardLink } = useRouter();
  const navigate = useNavigate();
  const { isOpen: isVirtualCardModalOpen, onOpen, onClose } = useDisclosure();

  const routeToAddVirtualCardForm = generateVendorVirtualCardLink(navigationStateParams);
  const modalEntryButtonText = formatMessage(
    'widgets.addOrUpdateDeliveryMethod.unmanagedVirtualCard.info.noCardAdditionalInfo.action',
  );
  const shouldShowModal = !unmanagedVirtualCard && modalEntryButtonText;
  const modalEntryPoint = shouldShowModal ? (
    <NakedButton variant="secondary" onClick={onOpen} label={modalEntryButtonText} />
  ) : null;

  return (
    <>
      <DeliveryMethodCard
        deliveryMethodExists={unmanagedVirtualCard !== null}
        displayName={formatMessage('widgets.addOrUpdateDeliveryMethod.unmanagedVirtualCard.title')}
        iconType={
          unmanagedVirtualCard
            ? (getDeliveryMethodIconKey(unmanagedVirtualCard, settings.icons.deliveryMethod) as IconKey)
            : 'single-use-card'
        }
        helperText={formatMessage('widgets.addOrUpdateDeliveryMethod.unmanagedVirtualCard.feeText')}
        caption={formatMessage('widgets.addOrUpdateDeliveryMethod.unmanagedVirtualCard.info.noCardAdditionalInfo', {
          link: modalEntryPoint,
        })}
        type={DeliveryMethodType.VirtualCard}
        navigationParams={routeToAddVirtualCardForm}
        info={
          unmanagedVirtualCard
            ? {
                info1: unmanagedVirtualCard.details.accountEmail,
                info2: formatMessage(
                  'widgets.addOrUpdateDeliveryMethod.unmanagedVirtualCard.info.secondaryAdditionalInfo',
                  {
                    updatedAt: formatDateWithTime(unmanagedVirtualCard?.history.updatedAt),
                  },
                ),
              }
            : undefined
        }
      />
      {!unmanagedVirtualCard && isVirtualCardModalOpen && (
        <VirtualCardInfoModal
          onClose={onClose}
          addVirtualCardHandler={() => navigate(routeToAddVirtualCardForm.path)}
        />
      )}
    </>
  );
};
