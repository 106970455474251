import { useRoleText } from '@melio/ap-domain';
import { Form } from '@melio/penny';
import { useApprovalWorkflowRoles } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMemo } from 'react';

import { useFormContext } from '../../FormContext';

export function RolesSelector({ index }: { index: number }) {
  const { formatMessage } = useMelioIntl();
  const { registerField } = useFormContext();
  const { getRoleText } = useRoleText();

  const { data: approvalRoles, isLoading } = useApprovalWorkflowRoles();

  const nonApproverRoles = useMemo(() => approvalRoles?.paymentApprovalRoles?.nonApprovers ?? [], [approvalRoles]);

  const field = registerField(`criteria.conditions.${index}.condition.configuration.roles`);

  const options = useMemo(
    () =>
      nonApproverRoles.map((roleUniqueName) => ({
        value: roleUniqueName,
        label: formatMessage('activities.create-approval-workflows.conditions.scheduler.options.specificRoles.option', {
          roleUniqueName: getRoleText(roleUniqueName),
        }),
        'data-testid': `specific-role-${roleUniqueName}`,
      })),
    [nonApproverRoles, formatMessage, getRoleText]
  );

  return (
    <Form.MultiSelect
      {...field}
      aria-label="specific roles"
      data-testid="specific-roles-list"
      placeholder={formatMessage(
        'activities.create-approval-workflows.conditions.scheduler.options.specificRoles.placeholder'
      )}
      options={options}
      isDisabled={isLoading}
      isLoading={isLoading}
      error={
        field.error
          ? {
              message: formatMessage(
                'activities.create-approval-workflows.conditions.scheduler.errors.specificRoles.required'
              ),
            }
          : undefined
      }
    ></Form.MultiSelect>
  );
}
