import {
  useApprovalWorkflowRoles,
  WorkflowCollaboratorsConditionTypeEnum,
  WorkflowCollaboratorsFilterRolesByEnum,
  WorkflowNumericConditionConfigurationOperatorEnum,
  WorkflowNumericConditionTypeEnum,
  WorkflowPaymentAmountCondition,
  WorkflowPaymentAmountConditionAttributeEnum,
  WorkflowPaymentCreatedByCondition,
  WorkflowPaymentCreatedByConditionAttributeEnum,
  WorkflowPaymentVendorCondition,
  WorkflowPaymentVendorConditionAttributeEnum,
  WorkflowVendorsConditionTypeEnum,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export type ConditionType =
  | WorkflowNumericConditionTypeEnum
  | WorkflowCollaboratorsConditionTypeEnum
  | WorkflowVendorsConditionTypeEnum;

export type CriteriaOptionsType = {
  label: string;
  value: ConditionType;
  condition: WorkflowPaymentAmountCondition | WorkflowPaymentCreatedByCondition | WorkflowPaymentVendorCondition;
};

export const useConditionsList = () => {
  const { formatMessage } = useMelioIntl();
  const { data: approvalRoles } = useApprovalWorkflowRoles({
    suspense: true,
  });

  const nonApproverRoles = approvalRoles?.paymentApprovalRoles?.nonApprovers ?? [];

  return [
    {
      label: formatMessage('activities.create-approval-workflows.conditions.criteria.workflowNumericCondition.label'),
      value: WorkflowNumericConditionTypeEnum.WorkflowNumericCondition,
      condition: {
        attribute: WorkflowPaymentAmountConditionAttributeEnum.PaymentAmount,
        condition: {
          type: WorkflowNumericConditionTypeEnum.WorkflowNumericCondition,
          configuration: {
            operator: WorkflowNumericConditionConfigurationOperatorEnum.GreaterEqual,
            value: 0,
          },
        },
      },
    },
    {
      label: formatMessage(
        'activities.create-approval-workflows.conditions.criteria.workflowCollaboratorsCondition.label'
      ),
      description: formatMessage(
        'activities.create-approval-workflows.conditions.criteria.workflowCollaboratorsCondition.description'
      ),
      value: WorkflowCollaboratorsConditionTypeEnum.WorkflowCollaboratorsCondition,
      condition: {
        attribute: WorkflowPaymentCreatedByConditionAttributeEnum.PaymentCreatedBy,
        condition: {
          type: WorkflowCollaboratorsConditionTypeEnum.WorkflowCollaboratorsCondition,
          configuration: {
            filterBy: WorkflowCollaboratorsFilterRolesByEnum.Role,
            roles: nonApproverRoles,
          },
        },
      },
    },
    {
      label: formatMessage('activities.create-approval-workflows.conditions.criteria.vendor.label'),
      value: WorkflowVendorsConditionTypeEnum.WorkflowVendorsCondition,
      condition: {
        attribute: WorkflowPaymentVendorConditionAttributeEnum.PaymentVendor,
        condition: {
          type: WorkflowVendorsConditionTypeEnum.WorkflowVendorsCondition,
          configuration: {
            ids: [],
          },
        },
      },
    },
  ];
};
