import { VisuallyHidden } from '@chakra-ui/react';
import { Modal, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import noop from 'lodash/noop';

import { useSubscriptionMe } from '../../../api';
import { useIsSubscriptionsEnabled, useSubscription } from '../../../hooks';
import { useReactivatePlan } from '../../../hooks/useReactivatePlan';

export type ReactivatePlanModalProps = {
  isOpen: boolean;
  onClose: () => void;
  variant: 'before-scheduling' | 'cancel-pending-change';
};

export const ReactivatePlanModal = ({ isOpen, onClose, variant }: ReactivatePlanModalProps) => {
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const subscription = useSubscription();
  const { formatMessage } = useMelioIntl();
  const { isMutating: isUpdatingSubscription } = useSubscriptionMe({
    enabled: false,
  });
  const reactivatePlan = useReactivatePlan({ subscription, onDone: onClose });

  if (!isSubscriptionsEnabled) {
    return null;
  }

  const getDescription = () => {
    switch (variant) {
      case 'before-scheduling':
        return formatMessage('activities.subscription.reactivationPlanModal.description.beforeScheduling');
      case 'cancel-pending-change':
        return formatMessage('activities.subscription.reactivationPlanModal.description.cancelPendingChange');
    }
  };

  return (
    <Modal
      data-testid="reactivate-plan-modal"
      closeButtonAriaLabel={formatMessage('activities.subscription.reactivationPlanModal.close')}
      isOpen={isOpen}
      onClose={isUpdatingSubscription ? noop : onClose}
      header={formatMessage('activities.subscription.reactivationPlanModal.title')}
      primaryButton={{
        isLoading: isUpdatingSubscription,
        label: formatMessage('activities.subscription.reactivationPlanModal.reactivate'),
        onClick: reactivatePlan,
        variant: 'primary',
      }}
      secondaryButton={{
        isDisabled: isUpdatingSubscription,
        label: formatMessage('activities.subscription.reactivationPlanModal.cancel'),
        onClick: onClose,
        variant: 'tertiary',
      }}
    >
      <Text>{getDescription()}</Text>
      <VisuallyHidden role="status" aria-live="polite">
        {isUpdatingSubscription && formatMessage('activities.subscription.reactivationPlanModal.loading')}
      </VisuallyHidden>
    </Modal>
  );
};
