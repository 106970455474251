import { isInternationalDeliveryMethod } from '@melio/ap-activities/src/utils/pay-flow/deliveryMethods';
import { getInternationalFormattedIdentifierData } from '@melio/ap-domain';
import { DeliveryMethod, DeliveryMethodType, FeeType } from '@melio/platform-api';

import { useRouter } from '@/hooks/router.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { BasicDeliveryMethodCardProps } from '@/types/deliveryMethod.types';
import { DeliveryMethodIconsEnum } from '@/types/vendors.types';
import { Fee } from '@/widgets/Fee/Fee.widget';
import { DeliveryMethodCard } from '@/widgets/vendorsDashboard/DeliveryMethod/components/DeliveryMethodCard.component';

export interface InternationalDeliveryMethodProps extends BasicDeliveryMethodCardProps {
  international: DeliveryMethod | null;
}

export const InternationalDeliveryMethodCard = ({
  international,
  navigationStateParams,
}: InternationalDeliveryMethodProps) => {
  const { formatMessage } = usePlatformIntl();
  const { generateVendorInternationalAccountLink } = useRouter();

  const identifierInfo =
    international?.details &&
    isInternationalDeliveryMethod(international) &&
    getInternationalFormattedIdentifierData(international);

  return (
    <DeliveryMethodCard
      deliveryMethodExists={international !== null}
      navigationParams={generateVendorInternationalAccountLink(navigationStateParams)}
      iconType={DeliveryMethodIconsEnum.INTERNATIONAL}
      displayName={formatMessage('widgets.addOrUpdateDeliveryMethod.international.title')}
      helperText={formatMessage('widgets.addOrUpdateDeliveryMethod.international.feeText', {
        internationalFee: <Fee feeType={FeeType.International} suffix="fee" />,
      })}
      info={
        identifierInfo
          ? {
              info1: formatMessage('widgets.addOrUpdateDeliveryMethod.international.info', identifierInfo),
              info2: '',
            }
          : undefined
      }
      caption={formatMessage('widgets.addOrUpdateDeliveryMethod.international.comment')}
      type={DeliveryMethodType.InternationalAccount}
      addMethodAriaLabel={formatMessage('widgets.addOrUpdateDeliveryMethod.international.add.ariaLabel', {
        fee: <Fee feeType={FeeType.International} />,
      })}
    />
  );
};
