import { Payment, usePayment } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSetDocumentTitle } from '@melio/platform-sdk';
import React, { ComponentProps } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { PaymentScheduledSuccessfullyActivity } from '../../PaymentScheduled';
import { PaymentFlowDoneAction } from '../../types';
import { PaymentFlowLoader } from '../components/PaymentFlowLoader';
import { PaymentFlowActivity } from '../PaymentFlowActivity/PaymentFlowActivity';
import { PaymentFlowFormFields } from '../types';
import { useSubmitErrorHandler } from '../util/useSubmitErrorHandler/useSubmitErrorHandler';
import { useDefaultDeliveryMethod } from './utils/useDefaultDeliveryMethod';

type EditPaymentActivityProps = {
  paymentId: string;
  isLoading: boolean;
  onClose: VoidFunction;
  onDone: (id: Payment['id'], action: PaymentFlowDoneAction, isPendingApproval: boolean) => void;
};

export const EditPaymentActivity = ({ paymentId, isLoading, onClose, onDone }: EditPaymentActivityProps) => {
  const { formatMessage } = useMelioIntl();
  const title = formatMessage('activities.paymentFlow.form.header.title.editPayment');
  useSetDocumentTitle(title);
  const navigate = useNavigate();
  const [addDeliveryDateToCreateAndUpdate] = useFeature<boolean>(
    FeatureFlags.AddDeliveryDateToCreateAndUpdatePayment,
    false
  );

  const {
    data: payment,
    _mutations: {
      update: { error: submitError },
    },
    update: updatePayment,
    isMutating: isUpdatingPayment,
    isLoading: isPaymentLoading,
  } = usePayment({
    id: paymentId || 'EditPaymentActivityPaymentId',
    onUpdateError: useSubmitErrorHandler(),
  });

  const defaultValues: ComponentProps<typeof PaymentFlowActivity>['defaultValues'] = {
    vendorId: payment?.vendorId,
    deliveryMethodId: useDefaultDeliveryMethod(payment)?.deliveryMethod?.id,
    fundingSourceId: payment?.fundingSourceId,
    amountToPay: payment?.amount?.toString(),
    deliveryDate: useDefaultDeliveryMethod(payment)?.deliveryDate,
    deliveryPreference: payment?.deliveryPreferenceType || undefined,
    vendorEmail: payment?.vendor?.contact.email,
    noteToVendor: payment?.note,
  };

  const onSubmit = (data: PaymentFlowFormFields) =>
    updatePayment({
      amount: Number(data.amountToPay),
      scheduledDate: data.scheduleDate?.toISOString(),
      deliveryDate: addDeliveryDateToCreateAndUpdate ? data.deliveryDate?.toISOString() : undefined,
      fundingSourceId: data.fundingSourceId || void 0,
      deliveryMethodId: data.deliveryMethodId || void 0,
      deliveryPreferenceType: data.deliveryPreference || void 0,
      note: data.noteToVendor || void 0,
      paymentPurpose: data.paymentPurpose,
    }).then(() => navigate('success'));

  if (isLoading || isPaymentLoading) {
    return <PaymentFlowLoader />;
  }

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <PaymentFlowActivity
            defaultValues={defaultValues}
            payment={payment}
            submitError={submitError}
            billId={payment?.bills?.[0]?.id}
            title={title}
            isSubmitting={isUpdatingPayment}
            onSubmit={onSubmit}
            onClose={onClose}
          />
        }
      />
      <Route
        path="/success"
        element={
          payment && (
            <PaymentScheduledSuccessfullyActivity
              paymentsIds={[payment.id]}
              onClose={onClose}
              onDone={(action: PaymentFlowDoneAction) => onDone(payment.id, action, false)}
              isEditPaymentFlow
            />
          )
        }
      />
    </Routes>
  );
};
