/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Group } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useNotificationPreferences } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { SettingsPageHeader } from '@melio/platform-utils';
import { SectionHeader } from '@melio/platform-utils';

import { SettingsCardIdEnum } from '@/cl/components/SettingsCard/SettingsCard.component';
import { SettingsSectionContainer } from '@/cl/components/SettingsSectionContainer/SettingsSectionContainer.component';
import { WithLoading } from '@/hoc/withLoading.hoc';
import { NotificationItem } from './NotificationItem.widget';
import { useNotificationGroups } from './useNotificationGroups';

export const NotificationPreferences = () => {
  const { track } = useAnalytics();
  const { formatMessage } = useMelioIntl();
  const { isLoading, data: notificationPreferences, update: updatePreferences } = useNotificationPreferences();
  const groups = useNotificationGroups();

  useEffect(() => {
    track(`Settings`, 'View', { PageName: SettingsCardIdEnum.NotificationPreferences });
  }, []);

  const handleChange = (option: (typeof groups)[0]['options'][0], enabled: boolean) => {
    updatePreferences({
      ...notificationPreferences,
      [option.key]: enabled,
    });
    track('NotificationUpdate', 'Chose', {
      NotificationName: option.key,
      NewState: enabled,
      OldState: !enabled,
    });
  };

  return (
    <WithLoading isLoading={isLoading}>
      <SettingsPageHeader
        title={formatMessage('app.settings.companySection.cards.notificationPreferences.title')}
        subTitle={formatMessage('app.settings.companySection.cards.notificationPreferences.description')}
      />
      <Group data-component="NotificationsSettings" spacing="xl" variant="vertical">
        {groups.map((group) => (
          <SettingsSectionContainer key={group.title}>
            <Group spacing="s" variant="vertical">
              <SectionHeader title={group.title} titleAs="h3" />
              <Group spacing="s" variant="vertical" hasDivider>
                {group.options.map((option) => (
                  <NotificationItem
                    key={option.key}
                    notificationName={option.key}
                    title={option.label}
                    onChange={(enabled) => handleChange(option, enabled)}
                    enabled={notificationPreferences?.[option.key] ?? false}
                  />
                ))}
              </Group>
            </Group>
          </SettingsSectionContainer>
        ))}
      </Group>
    </WithLoading>
  );
};

NotificationPreferences.displayName = 'NotificationPreferences';
