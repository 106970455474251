import { usePermissions } from '@melio/platform-permissions';

export const useCanPayWithFinancing = () => {
  const { can } = usePermissions();

  const canReadRepaymentTerms = can({
    subject: 'financing:repaymentTerms',
    action: 'read',
  });

  return {
    canReadRepaymentTerms,
  };
};
