import {
  GuestPayorUserTypes,
  useGuestPayorPaymentRequestDetails,
  useGuestPayorUserDetailsByEmail,
} from '@melio/ar-domain';
import { useAnalytics } from '@melio/platform-analytics';
import { useConfig } from '@melio/platform-provider';
import { useDebounceCallback } from '@melio/platform-utils';
import React, { useCallback } from 'react';
import * as Yup from 'yup';

type UseExistingUserEmailProps = {
  paymentRequestLink: string;
  isNavigateApUsersToDashboardEnabled?: boolean;
};

type UseExistingUserEmailReturnType = {
  onSignInClick: () => void;
  onEmailChangeHandler: (email: string) => void;
  isLoading: boolean;
  isSignInBannerAvailable: boolean;
  userType: GuestPayorUserTypes | undefined;
  currentUserEmail: string | undefined;
};

export const EMAIL_CHANGED_DEBOUNCE_TIME = 400;

export const useExistingUserEmail = ({
  paymentRequestLink,
  isNavigateApUsersToDashboardEnabled,
}: UseExistingUserEmailProps): UseExistingUserEmailReturnType => {
  const [currentUserEmail, setCurrentUserEmail] = React.useState<string | undefined>();
  const { data: paymentRequestData } = useGuestPayorPaymentRequestDetails({ paymentRequestLink });

  const { data: userDetails, isLoading: isLoadingUserDetails } = useGuestPayorUserDetailsByEmail({
    email: currentUserEmail as string,
    paymentRequestLink,
    enabled: !!currentUserEmail && !!isNavigateApUsersToDashboardEnabled,
  });

  const onEmailChangeHandler = useDebounceCallback((email: string) => {
    const isValidEmail = Yup.string().email().isValidSync(email);
    setCurrentUserEmail(isValidEmail ? email : undefined);
  }, EMAIL_CHANGED_DEBOUNCE_TIME);

  const config = useConfig();
  const { generateDeepLinkToPaymentRequestPayDashboard } = config.settings.guestPaymentFlow ?? {};
  const redirectToPayDashboardUrl =
    generateDeepLinkToPaymentRequestPayDashboard && paymentRequestData
      ? generateDeepLinkToPaymentRequestPayDashboard(paymentRequestData.paymentRequestId, {
          paymentRequestDetails: { ref: paymentRequestLink },
          vendorDetails: { name: paymentRequestData.payeeDetails.companyName },
        })
      : undefined;
  const shouldRedirectToPayDashboard = userDetails?.userType === GuestPayorUserTypes.REGISTERED_AND_MATCHED;
  const isSignInBannerAvailable = Boolean(
    isNavigateApUsersToDashboardEnabled && shouldRedirectToPayDashboard && !!redirectToPayDashboardUrl
  );
  const { track } = useAnalytics();

  const onSignInClick = useCallback(() => {
    track('PaymentRequest', 'Click', { PageName: 'payment-request-sign-in', Cta: 'sign-in' });

    if (redirectToPayDashboardUrl) window.location.assign(redirectToPayDashboardUrl);
  }, [redirectToPayDashboardUrl, track]);

  return {
    onSignInClick,
    onEmailChangeHandler,
    isLoading: isLoadingUserDetails,
    isSignInBannerAvailable,
    userType: userDetails?.userType,
    currentUserEmail,
  };
};
