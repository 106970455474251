import { Container, Group, SectionBanner, Table, useTable } from '@melio/penny';
import { Payment, PaymentDate } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { PaymentLateApprovalTableMeta } from '../types';
import { usePaymentsLateApprovalTableColumns } from '../usePaymentsLateApprovalTableColumns';

type Props = {
  lateApprovalPayments: Payment[];
  approvedPayments: Payment[];
  lateApprovalPaymentsDates: PaymentDate[];
};
export const PaymentsLateApprovalModalBody = ({
  lateApprovalPayments,
  approvedPayments,
  lateApprovalPaymentsDates,
}: Props) => {
  const [lateApprovalPayment, ...restLateApprovalPayments] = lateApprovalPayments;
  const [lateApprovalPaymentDates, ...restLateApprovalPaymentsDates] = lateApprovalPaymentsDates;

  const hasSingleLateApprovalPayment =
    !!lateApprovalPayment &&
    restLateApprovalPayments.length === 0 &&
    !!lateApprovalPaymentDates &&
    restLateApprovalPaymentsDates.length === 0;

  return hasSingleLateApprovalPayment ? (
    <SingleLateApprovalPaymentBody
      lateApprovalPayment={lateApprovalPayment}
      lateApprovalPaymentDates={lateApprovalPaymentDates}
      approvedPayments={approvedPayments}
    />
  ) : (
    <MultipleLateApprovalPaymentBody
      lateApprovalPayments={lateApprovalPayments}
      approvedPayments={approvedPayments}
      lateApprovalPaymentsDates={lateApprovalPaymentsDates}
    />
  );
};

const SingleLateApprovalPaymentBody = ({
  lateApprovalPayment,
  lateApprovalPaymentDates,
  approvedPayments,
}: {
  lateApprovalPayment: Payment;
  lateApprovalPaymentDates: PaymentDate;
  approvedPayments: Payment[];
}) => {
  const { formatMessage, formatDate } = useMelioIntl();

  return (
    <Container data-testid="single-late-approval-payment-body">
      <SectionBanner
        variant="warning"
        data-testid="single-late-approval-payment-section-banner"
        description={formatMessage('activities.paymentsLateApprovalModal.banner.single', {
          currentDebitDate: formatDate(lateApprovalPayment.scheduledDate, { dateStyle: 'medium' }),
          newDebitDate: formatDate(lateApprovalPaymentDates.minScheduledDate, { dateStyle: 'medium' }),
          newDeliverByDate: formatDate(lateApprovalPaymentDates.deliveryDate, { dateStyle: 'medium' }),
          numberOfApprovedPayments: approvedPayments.length,
          totalNumberOfPayments: approvedPayments.length + 1,
        })}
      />
    </Container>
  );
};

const MultipleLateApprovalPaymentBody = ({
  lateApprovalPayments,
  approvedPayments,
  lateApprovalPaymentsDates,
}: {
  lateApprovalPayments: Payment[];
  approvedPayments: Payment[];
  lateApprovalPaymentsDates: PaymentDate[];
}) => {
  const { formatMessage } = useMelioIntl();

  const columns = usePaymentsLateApprovalTableColumns();
  const tableProps = useTable({
    isHeaderSticky: true,
    headerVariant: 'dark',
    data: lateApprovalPayments,
    columns,
    meta: {
      lateApprovalPaymentsDates: lateApprovalPayments.reduce<PaymentLateApprovalTableMeta['lateApprovalPaymentsDates']>(
        (acc, payment, index) => {
          const currentPaymentDates = lateApprovalPaymentsDates[index];
          if (!currentPaymentDates) {
            throw new Error('No payment dates found');
          }

          acc[payment.id] = {
            debitDate: currentPaymentDates.minScheduledDate,
            deliveryDate: currentPaymentDates.deliveryDate,
          };

          return acc;
        },
        {}
      ),
    },
  });

  return (
    <Group spacing="m" variant="vertical" data-testid="multiple-late-approval-payments-body">
      <SectionBanner
        variant="warning"
        data-testid="multiple-late-approval-payments-section-banner"
        description={formatMessage('activities.paymentsLateApprovalModal.banner.multiple', {
          numberOfApprovedPayments: approvedPayments.length,
          totalNumberOfPayments: approvedPayments.length + lateApprovalPayments.length,
          remainingNumberOfPaymentsToApprove: lateApprovalPayments.length,
        })}
      />

      <Table {...tableProps} />
    </Group>
  );
};
