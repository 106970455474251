import { getInternationalFormattedIdentifierData, getManagedAccountDeliveryMethodType } from '@melio/ap-domain';
import { Group, Table, Text } from '@melio/penny';
import { formatAddress, Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export const PaymentsTabDeliveryMethodCell = ({ payment }: { payment: Payment }) => {
  const { deliveryMethod } = usePaymentDeliveryMethod(payment);
  const { formatMessage } = useMelioIntl();

  return (
    <Table.Cell
      data-testid={`payments-tab-row-${payment.id}-delivery-method-cell`}
      placeholder={formatMessage('activities.batchPayments.screens.paymentIntentsTable.deliveryMethodCell.missing')}
    >
      {deliveryMethod && (
        <Group variant="vertical" spacing="xxs" alignItems="flex-start">
          <Text textStyle="body3">{deliveryMethod.type}</Text>
          {deliveryMethod.details && (
            <Text textStyle="body4" color="global.neutral.900" shouldSupportEllipsis>
              {deliveryMethod.details}
            </Text>
          )}
        </Group>
      )}
    </Table.Cell>
  );
};

export const usePaymentDeliveryMethod = (payment: Payment) => {
  const { formatMessage } = useMelioIntl();
  if (!payment.deliveryMethod) {
    return { deliveryMethod: null };
  }

  let details: string;
  let type: string;

  const isRtpDeliveryPreferenceType = payment.deliveryPreferenceType === 'rtp';
  if (isRtpDeliveryPreferenceType) {
    type = formatMessage(`activities.payDashboard.paymentsTab.table.cells.deliveryPreference.type.rtp`);
  } else {
    let dmType = payment.deliveryMethod.type;
    if (payment.deliveryMethod.type === 'managed-account') {
      dmType = getManagedAccountDeliveryMethodType(payment.deliveryMethod.details);
    }
    type = formatMessage(`activities.payDashboard.paymentsTab.table.cells.deliveryMethod.type.${dmType}`);
  }

  switch (payment.deliveryMethod.type) {
    case 'bank-account':
      details = formatMessage(`activities.payDashboard.paymentsTab.table.cells.deliveryMethod.details.accountNumber`, {
        accountNumber: payment.deliveryMethod.details.accountNumber.slice(-4),
      });
      break;
    case 'card':
      details = formatMessage(`activities.payDashboard.paymentsTab.table.cells.deliveryMethod.details.cardNumber`, {
        cardNumber: payment.deliveryMethod.details.lastFourDigits,
      });
      break;
    case 'virtual-account':
      details = formatMessage('activities.payDashboard.paymentsTab.table.cells.deliveryMethod.details.virtualAccount');
      break;
    case 'paper-check':
      details = formatMessage('activities.payDashboard.paymentsTab.table.cells.deliveryMethod.details.paperCheck', {
        address: formatAddress(payment.deliveryMethod.details.address),
      });
      break;
    case 'international-account':
      details = formatMessage(
        'activities.payDashboard.paymentsTab.table.cells.deliveryMethod.details.internationalAccount',
        getInternationalFormattedIdentifierData(payment.deliveryMethod)
      );
      break;
    default:
      details = '';
  }

  return { deliveryMethod: { type, details } };
};
