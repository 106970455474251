import { Box } from '@chakra-ui/react';
import { Pill } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export const PremiumBadge = () => {
  const { formatMessage } = useMelioIntl();

  return (
    <Box display="flex" alignItems="center" justifyContent="center" width="fit-content" data-testid="premium-badge">
      <Pill
        label={formatMessage('widgets.taxAndReportsSettings.taxFormSection.eFilling.badge')}
        status="brand"
        type="secondary"
      />
    </Box>
  );
};
