import { Box } from '@chakra-ui/react';
import { Typography } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export const FormTitle = () => {
  const { formatMessage } = useMelioIntl();
  return (
    <Box mb="m">
      <Typography.Label label={formatMessage('activities.manageCollaborators.screens.userDetailsForm.title')} />
    </Box>
  );
};
