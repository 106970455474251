import { forwardRef } from '@melio/platform-utils';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';

export type ReviewAndConfirmLoadingScreenProps = {
  onBack?: VoidFunction;
  onClose?: VoidFunction;
};

export const ReviewAndConfirmLoadingScreen = forwardRef<ReviewAndConfirmLoadingScreenProps, 'div'>(
  ({ onBack, onClose, ...props }, ref) => (
    <NewSinglePaymentStepLayout
      data-component="ReviewAndConfirmActivity.ReviewAndConfirmLoadingScreen"
      data-testid="review-and-confirm-activity-review-and-confirm-loading-screen"
      {...props}
      ref={ref}
      isLoading
    />
  )
);

ReviewAndConfirmLoadingScreen.displayName = 'ReviewAndConfirmActivity.ReviewAndConfirmLoadingScreen';
