import { Button, ButtonProps, Divider, Grid } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import type { Plan } from '../../../../api';
import { usePlanInfo } from '../../../../hooks';
import { PlanConfig } from '../types';
import { ContextualFeaturesList } from './ContextualFeaturesList';
import { ContextualPlanCardHeader } from './ContextualPlanCardHeader';
import { ContextualPlanPriceSection } from './ContextualPlanPriceSection';

type ContextualPlanCardProps = {
  upsellContext: PlanConfig;
  plan: Plan;
  isCurrentPlan?: boolean;
  titleAs?: 'h3' | 'h4';
  onClick: () => void;
  ariaHasPopup?: ButtonProps['aria-haspopup'];
};

export const ContextualPlanCard = ({
  upsellContext,
  plan,
  isCurrentPlan,
  titleAs,
  onClick,
  ariaHasPopup,
}: ContextualPlanCardProps) => {
  const { formatMessage } = useMelioIntl();
  const { planName } = usePlanInfo(plan.id);

  return (
    <Grid border="basic.light" borderRadius="l" gridTemplateRows="subgrid" gridRow="auto / span 5" p="m" gap="m">
      <ContextualPlanCardHeader
        title={planName}
        description={formatMessage(`activities.subscription.plans.${plan.tier}.description`)}
        isCurrent={!!isCurrentPlan}
        titleAs={titleAs}
      />
      <ContextualPlanPriceSection
        price={plan.cycles['monthly'].basePrice}
        additionalUserPrice={plan.cycles['monthly'].unitPrice || 0}
      />
      <Divider />
      <ContextualFeaturesList
        featureCapabilities={upsellContext.capabilities}
        shouldShowAllFeaturesLink={upsellContext.showAllFeaturesLink}
      />
      {upsellContext.actionButtonLabel && (
        <Button
          data-testid={`contextual-paywall-card-${isCurrentPlan ? 'current' : 'upgrade'}-button`}
          isFullWidth
          label={upsellContext.actionButtonLabel}
          onClick={onClick}
          variant={isCurrentPlan ? 'tertiary' : 'primary'}
          aria-haspopup={ariaHasPopup}
        />
      )}
    </Grid>
  );
};
