import { useToast } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { JoinOrganizationRequest, useJoinOrganizationRequest } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';

import { ResendConfirmationModalScreen } from './screens';

type Props = {
  joinOrganizationRequest: JoinOrganizationRequest;
  onClose: VoidFunction;
};

export const ResendJoinOrganizationRequestModalActivity = withAnalyticsContext<Props>(
  ({ joinOrganizationRequest, onClose, setAnalyticsProperties }) => {
    const { formatMessage } = useMelioIntl();
    const { track } = useAnalytics();
    const { toast } = useToast();
    const { resendJoinOrganizationRequestAccount, isMutating } = useJoinOrganizationRequest({
      id: joinOrganizationRequest.id,
      enabled: false,
    });

    setAnalyticsProperties({
      PageName: 'resend-request',
      Intent: 'resend-request',
    });

    useEffect(() => {
      track('User', 'View');
    }, [track]);

    const handleConfirmClick = async () => {
      track('User', 'Click', {
        Cta: 'resend-request',
      });

      try {
        await resendJoinOrganizationRequestAccount();
        toast({
          type: 'success',
          title: formatMessage(`activities.resendJoinOrganizationRequestModal.toast.success`),
        });
        track('User', 'Status', {
          PageName: 'companies-dashboard',
          Status: 'success',
        });
        onClose();
      } catch (error) {
        toast({
          type: 'error',
          title: formatMessage(`activities.resendJoinOrganizationRequestModal.toast.error`),
        });
        track('User', 'Status', {
          PageName: 'companies-dashboard',
          Status: 'failure',
        });
        onClose();
      }
    };

    const handleClose = () => {
      track('User', 'Click', {
        Cta: 'exit',
      });
      onClose();
    };

    return (
      <ResendConfirmationModalScreen
        isOpen
        companyName={joinOrganizationRequest.companyName}
        isLoading={isMutating}
        onClose={handleClose}
        onDone={handleConfirmClick}
      />
    );
  }
);
