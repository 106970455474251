import {
  Button,
  ButtonProps,
  Container,
  Divider,
  Group,
  Icon,
  IconButton,
  IconKey,
  Text,
  useBreakpoint,
} from '@melio/penny';
import { useNavigate } from 'react-router-dom';

import { SystemMessageDisplay } from '../system-message';

export const SettingsPageHeader = ({
  title,
  subTitle,
  primaryButton,
  secondaryButton,
  badge,
}: {
  title?: string;
  subTitle?: string;
  primaryButton?: ButtonProps & { dataTestId?: string; icon?: IconKey };
  secondaryButton?: ButtonProps & { dataTestId?: string; icon?: IconKey };
  badge?: JSX.Element;
}) => {
  const { isExtraSmallScreen } = useBreakpoint();
  const navigate = useNavigate();

  const buttons = (
    <Group>
      {secondaryButton ? (
        <Button
          isDisabled={secondaryButton.isDisabled}
          leftElement={
            secondaryButton.icon ? (
              <Icon size="small" type={secondaryButton.icon} color="inherit" aria-hidden />
            ) : undefined
          }
          onClick={secondaryButton.onClick}
          label={secondaryButton.label}
          data-testid={secondaryButton.dataTestId}
          variant={secondaryButton.variant}
        />
      ) : undefined}
      {primaryButton ? (
        <Button
          leftElement={
            primaryButton.icon ? <Icon size="small" type={primaryButton.icon} color="inherit" aria-hidden /> : undefined
          }
          onClick={primaryButton.onClick}
          label={primaryButton.label}
          data-testid={primaryButton.dataTestId}
        />
      ) : undefined}
    </Group>
  );

  return isExtraSmallScreen ? (
    <Container paddingBottom="none">
      <Group variant="vertical" spacing="s">
        <Group spacing="s" alignItems="center">
          <IconButton
            icon="arrow-left"
            aria-label="Go back"
            variant="naked"
            size="medium"
            onClick={() => navigate('/settings')}
          />
          <Text as="h2" textStyle="heading1Semi">
            {title}
          </Text>
        </Group>
        <Divider />
        {buttons}
        <SystemMessageDisplay />
      </Group>
    </Container>
  ) : (
    <Container paddingBottom="s">
      <Group variant="vertical" spacing="none">
        <Group spacing="s" justifyContent="space-between" alignItems="center">
          <Group>
            <Text as="h2" textStyle="heading2Semi">
              {title}
            </Text>
            {badge}
          </Group>
          {buttons}
        </Group>
        <Text textStyle="body2" color="global.neutral.800">
          {subTitle}
        </Text>
      </Group>
    </Container>
  );
};
