import { useCatalogItems, useSearchParams } from '@melio/ar-domain';
import { forwardRef, useDebounceCallback } from '@melio/platform-utils';
import { useMemo } from 'react';

import { CatalogItemsTableScreen, CatalogItemsTableScreenProps } from './screens';

type SearchParams = {
  searchTerm: string;
};

type CatalogItemsTableActivityProps = {
  onError?: ARErrorFunction;
  onEditItem: CatalogItemsTableScreenProps['onEditItem'];
  onDeleteItem: CatalogItemsTableScreenProps['onDeleteItem'];
  onAddItem: VoidFunction;
};

export const CatalogItemsTableActivity = forwardRef<CatalogItemsTableActivityProps>(
  ({ onEditItem, onAddItem, onError, onDeleteItem, ...props }, ref) => {
    const { data, isLoading, isFetching } = useCatalogItems({ onError });

    const [{ searchTerm = '' }, _setParams] = useSearchParams<SearchParams>();
    const setParams = useDebounceCallback(_setParams, 200);

    const filteredData = useMemo(
      () =>
        (data ?? []).filter(
          (item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()) || item.id === searchTerm
        ),
      [data, searchTerm]
    );

    const sortedData = useMemo(() => filteredData.sort((a, b) => a.name.localeCompare(b.name)), [filteredData]);

    return (
      <CatalogItemsTableScreen
        searchTerm={searchTerm}
        setSearchTerm={(value) => setParams({ searchTerm: value || undefined })}
        isLoading={isLoading || isFetching}
        catalogItems={sortedData}
        onDeleteItem={onDeleteItem}
        onEditItem={onEditItem}
        onAddItem={onAddItem}
        {...props}
        ref={ref}
      />
    );
  }
);
