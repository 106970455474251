import { Button, useToast } from '@melio/penny';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { useOrganizations } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { Logger } from '@melio/platform-logger';
import React, { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { FullScreenLayout } from '../../components/FullScreenLayout/FullScreenLayout';
import { RedirectTarget } from '../../consts';

type Variant = 'activated' | 'require-activation';
const useClientCreatedSuccessfullyAnalytics = () => {
  useAnalyticsView('Organization', true, true, { Intent: 'client-added' });

  const { track } = useAnalytics();

  const trackClick = (intent: 'go-to-dashboard' | 'setup-client-account') =>
    track('Organization', 'Click', { Cta: intent, Intent: intent });

  return { trackClick };
};

export const ClientPlanAssignedSuccessfully = withAnalyticsContext<{
  onDone(params: { firmClientOrgId: string; returnTo: RedirectTarget }): Promise<void>;
}>(({ onDone, setAnalyticsProperties }) => {
  const { formatMessage } = useMelioIntl();
  const { id: firmClientOrgId = '' } = useParams<{ id: string }>();
  const { data: organizations = [] } = useOrganizations();
  const firmClientOrg = organizations.find(({ id }) => id === firmClientOrgId);
  const companyName = firmClientOrg?.name || '';
  const [searchParams] = useSearchParams();
  const isActivated = searchParams.get('activated') === 'true';

  const [isRedirectingToDashboard, setIsRedirectingToDashboard] = useState(false);
  const [isLoadingSwitchOrganization, setIsLoadingSwitchOrganization] = useState(false);
  const { toast } = useToast();

  const variant: Variant = isActivated ? 'activated' : 'require-activation';
  const pageName = formatMessage(`activities.accountants.firmClientPlanAssignSuccess.${variant}.title`);

  setAnalyticsProperties({ PageName: pageName, Flow: 'subscription-accountant' });
  const { trackClick } = useClientCreatedSuccessfullyAnalytics();

  const handleRedirectToDashboard = async () => {
    try {
      trackClick('go-to-dashboard');
      setIsRedirectingToDashboard(true);
      await onDone({ firmClientOrgId, returnTo: RedirectTarget.ClientDashboard });
    } catch (error: unknown) {
      toast({
        type: 'error',
        title: formatMessage(
          'activities.accountants.activities.firmClientPlanAssignSuccess.redirect-to-client-dashboard.error'
        ),
      });
      Logger.log(`Error while trying redirect to melio dashboard`);
      setIsRedirectingToDashboard(false);
    } finally {
      // Should keep isLoading=true (location.href may be done before it actually redirects)
    }
  };

  const handleSwitchOrganization = async () => {
    try {
      trackClick('setup-client-account');
      setIsLoadingSwitchOrganization(true);
      await onDone({ firmClientOrgId, returnTo: RedirectTarget.SetupClient });
    } catch (error: unknown) {
      toast({
        type: 'error',
        title: formatMessage('activities.accountants.activities.firmClientPlanAssignSuccess.switch-org.error'),
      });
      Logger.log(`Error while trying switch to client organization ${(error as Error).message}`);
    } finally {
      setIsLoadingSwitchOrganization(false);
    }
  };

  const switchOrganizationButtonProps = {
    onClick: handleSwitchOrganization,
    isLoading: isLoadingSwitchOrganization,
    isDisabled: !firmClientOrgId,
  };

  const redirectToDashboardButtonProps = { onClick: handleRedirectToDashboard, isLoading: isRedirectingToDashboard };

  return (
    <FullScreenLayout
      title={formatMessage(`activities.accountants.firmClientPlanAssignSuccess.${variant}.title`)}
      content={formatMessage(`activities.accountants.firmClientPlanAssignSuccess.${variant}.content`, {
        clientName: companyName,
      })}
      illustration={isActivated ? 'celebration' : 'success'}
      actions={
        <>
          <Button
            isFullWidth={false}
            data-testid="client-created-actions-secondary"
            variant="tertiary"
            label={formatMessage(`activities.accountants.firmClientPlanAssignSuccess.${variant}.action.secondary`)}
            {...(isActivated ? redirectToDashboardButtonProps : switchOrganizationButtonProps)}
          />
          <Button
            isFullWidth={false}
            data-testid="client-created-actions-primary"
            variant="primary"
            label={formatMessage(`activities.accountants.firmClientPlanAssignSuccess.${variant}.action.primary`)}
            {...(isActivated ? switchOrganizationButtonProps : redirectToDashboardButtonProps)}
          />
        </>
      }
    />
  );
});
