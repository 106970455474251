import { ARInvoice } from '@melio/ar-domain';
import { PillProps } from '@melio/penny';

export const InvoiceActions = {
  send: 'send',
  remind: 'remind',
  edit: 'edit',
  delete: 'delete',
  previewPDF: 'previewPDF',
  download: 'download',
  copy: 'copy',
  cancel: 'cancel',
  markAsPaid: 'markAsPaid',
  updateMarkAsPaid: 'updateMarkAsPaid',
  markAsUnpaid: 'markAsUnpaid',
  previewInvoice: 'previewInvoice',
} as const;

export const DisplayStatusLabelMap: Record<ARInvoice['displayStatus'], PillProps['status']> = {
  draft: 'neutral',
  inProcess: 'informative',
  open: 'informative',
  overdue: 'warning',
  paid: 'success',
  canceled: 'neutral',
};

type DisplayStatusLabel = Record<ARInvoice['displayStatus'], string>;

export const DisplayStatusLabel: DisplayStatusLabel = {
  draft: 'Draft',
  inProcess: 'In-process',
  overdue: 'Overdue',
  paid: 'Processed',
  open: 'Open',
  canceled: 'Canceled',
};

export type InvoiceActions = (typeof InvoiceActions)[keyof typeof InvoiceActions];

export type InvoiceActionsHandlers = {
  onDeleteInvoice: (id: string) => unknown;
  onMarkInvoiceAsPaid?: (id: string) => unknown;
  onMarkInvoiceAsUnpaid?: (id: string) => unknown;
  onUpdateMarkAsPaid: (id: string) => unknown;
  onSendReminder: (id: string) => unknown;
  onPreviewInvoicePDF: (id: string) => unknown;
  onCancelInvoice: (id: string) => unknown;
  onEditInvoice: (id: string) => unknown;
  onDownloadInvoice: (fileId: string) => unknown;
  onCopyLink: (link: string) => unknown;
  onIssueInvoice: (id: string) => unknown;
  onPreviewInvoice: (id: string) => unknown;
};

export type InvoiceDrawerActionsHandlers = Omit<InvoiceActionsHandlers, 'onPreviewInvoice'>;
