import { Collapsible, Group, Text } from '@melio/penny';
import { SelectedRepaymentOption } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

type InstallmentsListProps = {
  repaymentOption: SelectedRepaymentOption;
};

export const InstallmentsList = ({ repaymentOption }: InstallmentsListProps) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Collapsible
      label={formatMessage('widgets.reviewAndConfirm.repaymentSection.installments.installmentsList.title')}
      secondaryLabel={formatMessage(
        'widgets.reviewAndConfirm.repaymentSection.installments.installmentsList.secondaryTitle'
      )}
      data-testid="repayment-section-installments-list-toggle"
      defaultIsExpanded
    >
      <Group variant="vertical" spacing="s" data-testid="repayment-section-installments-list">
        <Group variant="vertical" spacing="xxs">
          {repaymentOption?.installments.map((installment, index) => (
            <InstallmentListItem key={index} installment={installment} />
          ))}
        </Group>
        <Group variant="vertical" spacing="xxs">
          <TotalAmount principalAmount={repaymentOption.principalAmount} />
          <TotalFees
            totalFeePercent={repaymentOption.totalFeePercent}
            totalFeesAmount={repaymentOption.totalFeesAmount}
          />
        </Group>
      </Group>
    </Collapsible>
  );
};

const InstallmentListItem = ({ installment }: { installment: SelectedRepaymentOption['installments'][0] }) => {
  const { formatCurrency, formatDate } = useMelioIntl();

  return (
    <Group variant="horizontal" justifyContent="space-between">
      <Text textStyle="body4">{formatDate(installment.scheduledDate, { dateStyle: 'medium' })}</Text>
      <Text textStyle="body4">{formatCurrency(installment.amount)}</Text>
    </Group>
  );
};

const TotalAmount = ({ principalAmount }: { principalAmount: number }) => {
  const { formatMessage, formatCurrency } = useMelioIntl();

  return (
    <Group variant="horizontal" justifyContent="space-between">
      <Text textStyle="body4Semi">
        {formatMessage('widgets.reviewAndConfirm.repaymentSection.installments.installmentsList.totalAmount')}
      </Text>
      <Text textStyle="body4Semi">{formatCurrency(principalAmount)}</Text>
    </Group>
  );
};

const TotalFees = ({ totalFeePercent, totalFeesAmount }: { totalFeePercent: number; totalFeesAmount: number }) => {
  const { formatMessage, formatCurrency } = useMelioIntl();

  return (
    <Group variant="horizontal" justifyContent="space-between">
      <Text textStyle="body4">
        {formatMessage('widgets.reviewAndConfirm.repaymentSection.installments.installmentsList.totalFees', {
          percent: totalFeePercent,
        })}
      </Text>
      <Text textStyle="body4">{formatCurrency(totalFeesAmount)}</Text>
    </Group>
  );
};
