import { Box } from '@chakra-ui/react';
import { ServiceChargeCatalog } from '@melio/ar-domain';
import { Table } from '@melio/penny';

import { useServiceChargeActionsItems } from '../hooks';

type ServiceChargeActionCellProps = {
  serviceCharge: ServiceChargeCatalog;
  onEdit: (serviceChargeId: string) => unknown;
  onDelete: (serviceChargeId: string) => unknown;
};

const handleCellClick = (e: React.MouseEvent<HTMLDivElement>) => {
  e.stopPropagation();
};

export const ServiceChargeActionCell = ({ serviceCharge, onEdit, onDelete }: ServiceChargeActionCellProps) => {
  const actions = useServiceChargeActionsItems({ serviceCharge, onEdit, onDelete });

  return (
    <Box onClick={handleCellClick} height="full">
      <Table.ActionsCell options={actions} data-testid={`service-charge-actions-cell-${serviceCharge.id}`} />
    </Box>
  );
};
