/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { compact } from 'lodash';
import { useSetRecoilState } from 'recoil';
import { ActionsDropdownMenu, ActionsDropdownMenuItemProps, Divider, StatusBar } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useInboxItems, usePayment } from '@melio/platform-api';
import { usePermissions } from '@melio/platform-permissions';
import { sumBy } from '@melio/platform-utils';

import { deleteBillAndPayment } from '@/api/bills/bills.api';
import { cancelSubscriptionById } from '@/api/billSubscriptions.api';
import { useDisclosure } from '@/hooks/useDisclosure';
import { payDashboardSelectedCardSelector } from '@/store/PayDashboard/PayDashboards.model';
import { usePlatformIntl } from '@/translations/Intl';
import { PaymentType, SelectedEntity } from '@/types/payDashboard.types';
import { PaymentDetailsProps } from '@/widgets/pay-dashboard/payment-details/PaymentDetails.types';
import { usePaymentScheduledByProps } from '../../hooks/usePaymentScheduledByProps';
import { CancelPaymentDialog } from '../general/CancelPaymentDialog';
import { PayFromSection } from '../general/PayFromSection';
import { PaymentDetailsSection } from '../general/PaymentDetailsSection';
import { PaymentDetailsTop } from '../general/PaymentDetailsTop';
import { PaymentScheduledBySection } from '../general/PaymentScheduledBySection';
import { VendorReceivesSection } from '../general/VendorReceivesSection/VendorReceivesSection';
import { PaymentScheduledActionAlert } from './PaymentScheduledActionAlert';

export const PaymentScheduled = ({
  payment,
  onPaymentEdit,
  isActionsEnabled,
}: PaymentDetailsProps & { onPaymentEdit: () => void }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { formatMessage } = usePlatformIntl();
  const { isOpen: isCancelPaymentDialogOpen, onOpen: openCancelDialog, onClose: closeCancelDialog } = useDisclosure();
  const { createTrackHandler } = useAnalytics();
  const { delete: deletePayment } = usePayment({ id: payment.id, enabled: false });
  const { refetch: refetchInboxItems } = useInboxItems({ enabled: false });
  const trackMenuClicked = createTrackHandler('PayDashboardScheduledAction', 'Chose');
  const [selectedEntity, setSelectedEntity] = React.useState<SelectedEntity>(null);
  const setSelectedCard = useSetRecoilState(payDashboardSelectedCardSelector);
  const { can } = usePermissions();
  const { showPaymentScheduledBySection, ...props } = usePaymentScheduledByProps(payment);
  const canDeletePaymentBills = payment.bills
    ? payment.bills.every((bill) =>
        can({
          subject: 'bill',
          action: 'delete',
          subjectData: {
            createdById: bill.createdById,
            vendor: {
              createdById: bill.vendor?.createdById,
              managedBy: bill.vendor?.managedBy,
            },
          },
        }),
      )
    : true;
  const hasPaymentUpdatePermission = can({
    subject: 'payment',
    action: 'update',
    subjectData: {
      createdById: payment.createdById,
      fundingSourceId: payment.fundingSourceId,
      vendor: {
        createdById: payment.vendor?.createdById,
        managedBy: payment.vendor?.managedBy,
      },
      payment: {
        origin: payment.origin,
      },
    },
  });
  const hasDeleteBillAndPaymentPermissions =
    can({
      subject: 'payment',
      action: 'delete',
      subjectData: {
        createdById: payment.createdById,
        fundingSourceId: payment.fundingSourceId,
        vendor: {
          createdById: payment.vendor?.createdById,
          managedBy: payment.vendor?.managedBy,
        },
        payment: {
          origin: payment.origin,
        },
      },
    }) && canDeletePaymentBills;
  const hasBillSubscriptionCancelPermission =
    can({ subject: 'billSubscription', action: 'cancel' }) && hasDeleteBillAndPaymentPermissions;

  const {
    fundingSource,
    vendor,
    deliveryMethod,
    timeline,
    scheduledDate,
    note,
    maxEstimatedDelivery,
    estimatedDelivery,
    bills,
  } = payment;

  const analyticsEventProps = {
    BillsTotalAmount: bills && sumBy(bills, (bill) => bill.amount),
    NumberOfBills: bills?.length,
  };

  const onEditClicked = React.useCallback(() => {
    setIsMenuOpen(false);
    trackMenuClicked({
      ...analyticsEventProps,
      ActionType: 'Edit',
    });
    onPaymentEdit();
  }, [onPaymentEdit]);

  const onCancelClicked = React.useCallback(
    (selectedEntity: SelectedEntity) => () => {
      setIsMenuOpen(false);
      trackMenuClicked({
        ...analyticsEventProps,
        ActionType: 'Remove',
      });
      setSelectedEntity(selectedEntity);
      openCancelDialog();
    },
    [openCancelDialog, trackMenuClicked],
  );

  const deleteEntity = async () => {
    if (selectedEntity === 'subscription') {
      await cancelSubscriptionById(payment!.subscriptionOccurrence!.billSubscriptionId);
    } else if (paymentType === 'recurringPayment' && payment.bills?.length) {
      await deleteBillAndPayment(payment.bills[0].id);
    } else {
      await deletePayment();
    }
    setSelectedCard({});
    await refetchInboxItems();
  };

  const getOrigin = (selectedEntity: SelectedEntity, paymentType: PaymentType) => () => {
    if (selectedEntity === 'subscription') {
      return 'billSubscription';
    }
    if (paymentType === 'recurringPayment') {
      return 'recurringPayment';
    }
    return 'singlePayment';
  };
  const paymentType = (payment.subscriptionOccurrenceId ? 'recurringPayment' : 'singlePayment') as PaymentType;

  const origin = useMemo(getOrigin(selectedEntity, paymentType), [selectedEntity, paymentType]);

  const cancelPaymentDialogBodyMessage = formatMessage(
    `widgets.paymentDetails.cancelPaymentDialog.${origin}.body${
      deliveryMethod?.type === 'virtual-card' ? 'VirtualCard' : ''
    }`,
    {
      vendorName: vendor.name,
    },
  );

  const actionBaseMenuItems = [
    hasPaymentUpdatePermission && {
      label: formatMessage(`widgets.paymentDetails.scheduled.menu.items.${paymentType}.edit`),
      onClick: onEditClicked,
      dataTestId: 'scheduled-payment-details-menu-edit',
    },
    hasDeleteBillAndPaymentPermissions && {
      label: formatMessage(`widgets.paymentDetails.scheduled.menu.items.${paymentType}.cancel`),
      onClick: onCancelClicked('payment'),
      dataTestId: 'scheduled-payment-details-menu-cancel',
      variant: 'critical',
    },
  ] as ActionsDropdownMenuItemProps[];

  const actionMenuItems =
    paymentType === 'singlePayment'
      ? compact(actionBaseMenuItems)
      : compact([
          ...actionBaseMenuItems,
          hasBillSubscriptionCancelPermission && {
            label: formatMessage('widgets.paymentDetails.scheduled.menu.items.recurringPayment.cancelAllPayments'),
            onClick: onCancelClicked('subscription'),
            dataTestId: 'scheduled-payment-details-menu-cancel-all',
            variant: 'critical',
          },
        ] as ActionsDropdownMenuItemProps[]);

  return (
    <>
      <StatusBar variant={'informative'} content={formatMessage('widgets.paymentDetails.scheduled.title')} />

      <Box py={'32px'} data-testid="pay-dashboard-payment-scheduled">
        <PaymentDetailsSection gridGap={'24px'}>
          <PaymentScheduledActionAlert payment={payment} />
          <PaymentDetailsTop
            payment={payment}
            isActionsEnabled={isActionsEnabled}
            menu={
              actionMenuItems.length ? (
                <ActionsDropdownMenu
                  label={formatMessage('widgets.paymentDetails.scheduled.menu.button')}
                  data-testid="scheduled-payment-details-menu"
                  items={actionMenuItems}
                  isOpen={isMenuOpen}
                  onOpenChange={setIsMenuOpen}
                />
              ) : null
            }
          />
        </PaymentDetailsSection>
        {showPaymentScheduledBySection && (
          <PaymentDetailsSection gridGap={'24px'}>
            <PaymentScheduledBySection {...props} />
          </PaymentDetailsSection>
        )}
        <PaymentDetailsSection gridGap={'24px'}>
          <PayFromSection fundingSource={fundingSource} scheduledDate={scheduledDate} deliveryMethod={deliveryMethod} />
          <Divider />
          <VendorReceivesSection
            vendorName={vendor.name}
            deliveryMethod={deliveryMethod}
            estimatedDelivery={estimatedDelivery}
            maxEstimatedDelivery={maxEstimatedDelivery}
            processedTimeline={payment.processedTimeline}
            memoToVendor={note}
            timeline={timeline}
            scheduledDate={payment.scheduledDate}
          />
        </PaymentDetailsSection>
        <CancelPaymentDialog
          isOpen={isCancelPaymentDialogOpen}
          onClose={closeCancelDialog}
          deleteEntity={deleteEntity}
          origin={origin}
          bodyMessage={cancelPaymentDialogBodyMessage}
        />
      </Box>
    </>
  );
};
