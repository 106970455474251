import { Container, Group, Illustration } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';

type Props = {
  onTryAgain: VoidFunction;
  onCancel: VoidFunction;
};

export const AccountingPlatformSyncErrorScreen = ({ onTryAgain, onCancel }: Props) => {
  const { formatMessage } = useMelioIntl();

  return (
    <NewSinglePaymentStepLayout
      data-component="AccountingPlatformConnectActivity.SyncErrorScreen"
      data-testid="accounting-platform-connect-sync-error-screen"
      footerContent={
        <NewSinglePaymentStepLayout.Actions>
          <Container width="full">
            <Group variant="vertical">
              <NewSinglePaymentStepLayout.NextButton
                data-testid="accounting-platform-sync-error-try-again"
                label={formatMessage('activities.accountingPlatformConnectCallback.screens.syncError.tryAgain')}
                onClick={onTryAgain}
              />
              <NewSinglePaymentStepLayout.SecondaryNextButton
                data-testid="accounting-platform-sync-error-cancel"
                label={formatMessage('activities.accountingPlatformConnectCallback.screens.syncError.cancel')}
                onClick={onCancel}
              />
            </Group>
          </Container>
        </NewSinglePaymentStepLayout.Actions>
      }
    >
      <Container paddingTop="xxxl">
        <Group variant="vertical" alignItems="center">
          <Illustration type="pay-zero" size="large" />
          <NewSinglePaymentStepLayout.Title paddingTop="xl">
            {formatMessage('activities.accountingPlatformConnectCallback.screens.syncError.title')}
          </NewSinglePaymentStepLayout.Title>
          <NewSinglePaymentStepLayout.Description>
            {formatMessage('activities.accountingPlatformConnectCallback.screens.syncError.description')}
          </NewSinglePaymentStepLayout.Description>
        </Group>
      </Container>
    </NewSinglePaymentStepLayout>
  );
};
