import { FormattedMessage, InvoiceExpandedSummary, useMelioIntl } from '@melio/ar-domain';
import { Container, Group, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { getMappedServiceCharges } from '../utils';
import { InvoiceLineItemsDetails } from './InvoiceLineItemsDetails';

type SummaryLineProps = {
  name: string;
  calculateValue: number;
  id: string;
  rate?: number;
};

const SummaryLine = ({ name, rate, calculateValue, id }: SummaryLineProps) => {
  const { formatCurrency, formatPercents } = useMelioIntl();

  return (
    <Group justifyContent="space-between" data-testid={`summary-line-group-${id}`}>
      <Group spacing="xs">
        <Text data-testid="summary-line-name">{name}</Text>
        <Text data-testid="summary-line-rate-text">
          {rate ? (
            <FormattedMessage
              id="ar.dashboard.activities.InvoiceDrawer.body.summary.summaryLine.rate.text"
              values={{
                rate: formatPercents(rate),
              }}
            />
          ) : null}
        </Text>
      </Group>
      <Text data-testid="summary-line-calculate-value-text">{formatCurrency(calculateValue)}</Text>
    </Group>
  );
};

type PaymentInvoiceDetailsProps = {
  expandedInvoice: InvoiceExpandedSummary;
};

export const InvoicePaymentSummeryDetails = forwardRef<PaymentInvoiceDetailsProps>(
  (
    {
      expandedInvoice: {
        invoiceLineItems,
        invoiceServiceCharges,
        discount,
        taxRate,
        summary: {
          subTotal,
          discount: summeryDiscount,
          taxRate: summaryTaxRate,
          serviceCharges: summaryInvoiceServiceCharges,
          totalAmount,
        },
      },
    },
    ref
  ) => {
    const { formatCurrency, formatMessage } = useMelioIntl();
    const hasTaxRate = taxRate && summaryTaxRate?.calculateValue;

    return (
      <Group variant="vertical" spacing="m" ref={ref}>
        <Text as="h3" textStyle="heading3Semi">
          <FormattedMessage id="ar.dashboard.activities.InvoiceDrawer.body.summary.title" />
        </Text>
        <Container border="regular" paddingY="m" paddingX="m" ref={ref}>
          <Group hasDivider spacing="m" variant="vertical">
            <InvoiceLineItemsDetails invoiceLineItems={invoiceLineItems} />
            <Group spacing="s" variant="vertical">
              <SummaryLine
                id="subtotal"
                name={formatMessage('ar.dashboard.activities.InvoiceDrawer.body.summary.summaryLine.subtotal.label')}
                calculateValue={subTotal}
              />
              {discount ? (
                <SummaryLine
                  rate={discount.type === 'percentage' ? discount.value : undefined}
                  name={discount.name}
                  id={discount.id}
                  calculateValue={summeryDiscount?.calculateValue ? summeryDiscount.calculateValue * -1 : 0}
                />
              ) : null}
              {hasTaxRate ? (
                <SummaryLine
                  name={taxRate.name}
                  rate={taxRate.rate}
                  id={taxRate.id}
                  calculateValue={summaryTaxRate.calculateValue}
                />
              ) : null}
              {getMappedServiceCharges({ invoiceServiceCharges, summaryInvoiceServiceCharges }).map((serviceCharge) => (
                <SummaryLine key={serviceCharge.id} {...serviceCharge} />
              ))}
            </Group>
            <Group justifyContent="space-between">
              <Text textStyle="body2Semi">
                <FormattedMessage id="ar.dashboard.activities.InvoiceDrawer.body.summary.summaryLine.total.label" />
              </Text>
              <Text textStyle="body2Semi">{formatCurrency(totalAmount)}</Text>
            </Group>
          </Group>
        </Container>
      </Group>
    );
  }
);
