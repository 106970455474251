import { useEffect, useMemo, useState } from 'react';

export function useDebounce<T>(value: T, delay = 100): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay);
    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);
  return debouncedValue;
}

// Debounce with deep equals
export function useDebouncedObject<T extends object>(value: T, delay?: number): T {
  const debouncedValue = useDebounce(JSON.stringify(value), delay);
  return useMemo(() => JSON.parse(debouncedValue) as T, [debouncedValue]);
}
