import { DeliveryPreference, PaymentIntent } from '@melio/platform-api';
import { useDateUtils } from '@melio/platform-utils';
import { uniq, uniqWith } from 'lodash';

export const getFundingSourceIdIfAllEqual = (paymentIntents: PaymentIntent[]): string | undefined => {
  const uniqeFundingSourceIds = uniq(paymentIntents.map((paymentIntent) => paymentIntent.fundingSourceId));
  return uniqeFundingSourceIds.length === 1 && uniqeFundingSourceIds[0] ? uniqeFundingSourceIds[0] : undefined;
};

export const useGetDeductionDateIfAllEqual = () => {
  const { isEqualDate } = useDateUtils();
  return {
    getDeductionDateIfAllEqual: (paymentIntents: PaymentIntent[]) => {
      const uniqeDates = uniqWith(
        paymentIntents.map((paymentIntent) => paymentIntent.effectiveScheduledDate || paymentIntent.scheduledDate),
        (date1, date2) => (!date1 && !date2) || (!!date1 && !!date2 && isEqualDate(date1, date2))
      );

      return uniqeDates.length === 1 && uniqeDates[0] ? uniqeDates[0] : undefined;
    },
  };
};

export const filterUnsupportedDeliveryPreferencesForBatchPaymentsFlow = (
  deliveryPreferences: Array<DeliveryPreference> | undefined,
  isRtpPayorEnabledForBatchPayments: boolean
) =>
  deliveryPreferences?.filter(({ supported, type }) => {
    if (!supported) {
      return false;
    }
    return !(!isRtpPayorEnabledForBatchPayments && type === 'rtp');
  });
