import {
  InboxItemBill,
  InboxItemPaymentRequest,
  InboxItemScannedInvoice,
  useUserPreference,
} from '@melio/platform-api';
import { convertDateToStartDay, useDateUtils } from '@melio/platform-utils';
import { useMemo } from 'react';

import {
  curDatePreferenceKey as billsTabLastSeenCurDatePreferenceKey,
  useNewBillsTabItems,
} from './useNewBillsTabItems';

const DAY = 24 * 60 * 60 * 1000;
const WEEK = 7 * DAY;

export const useNewOverdueBills = ({
  overdueBills,
}: {
  overdueBills: (InboxItemBill | InboxItemScannedInvoice | InboxItemPaymentRequest)[];
}) => {
  const { data: billsTabLastSeen, isLoading } = useUserPreference({
    id: billsTabLastSeenCurDatePreferenceKey,
  });

  return useMemo(() => {
    if (isLoading) {
      return { bills: [] };
    }

    let newOverdueBills = overdueBills;
    if (billsTabLastSeen?.value) {
      const billsTabLastSeenTime = (billsTabLastSeen.value as unknown as Date).getTime();
      newOverdueBills = overdueBills.filter(
        (overdueBill) =>
          overdueBill.payload.history.createdAt.getTime() > billsTabLastSeenTime ||
          (overdueBill.payload.dueDate && overdueBill.payload.dueDate.getTime() > billsTabLastSeenTime)
      );
    }
    return { bills: newOverdueBills };
  }, [isLoading, billsTabLastSeen, overdueBills]);
};

export const useNewOverdueIn7DaysBills = () => {
  const { newBillsTabItems } = useNewBillsTabItems();
  const { createDate } = useDateUtils();

  return useMemo(() => {
    const startDay = convertDateToStartDay(createDate()).getTime();
    const inWeek = startDay + WEEK;
    const bills = newBillsTabItems.filter(
      (inboxItem) =>
        inboxItem.payload.dueDate &&
        inboxItem.payload.dueDate.getTime() > startDay &&
        inboxItem.payload.dueDate.getTime() <= inWeek
    );

    return { bills };
  }, [newBillsTabItems, createDate]);
};

export const useNewOverdueInMoreThan7DaysBills = () => {
  const { newBillsTabItems } = useNewBillsTabItems();
  const { createDate } = useDateUtils();

  return useMemo(() => {
    const startDay = convertDateToStartDay(createDate()).getTime();
    const inWeek = startDay + WEEK;
    const bills = newBillsTabItems.filter(
      (inboxItem) => !inboxItem.payload.dueDate || inboxItem.payload.dueDate.getTime() > inWeek
    );

    return { bills };
  }, [newBillsTabItems, createDate]);
};
