import { TaxPayerInfoCheckbox, TaxPayerInfoForm, TaxPayerInfoFormProps } from '@melio/ap-widgets';
import { Container, Group } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';

type TaxPayerInfoProps = {
  isContractor?: boolean;
  onChangeIsContractor: (value: boolean) => void;
  taxPayerInfoFormProps: TaxPayerInfoFormProps['taxPayerInfoFormProps'];
  onFileUpload: TaxPayerInfoFormProps['handleFileUpload'];
  onDeleteInvalidFile: TaxPayerInfoFormProps['onDeleteInvalidFile'];
  isLoading?: boolean;
};

export const TaxPayerInfo = ({
  isContractor,
  onChangeIsContractor,
  taxPayerInfoFormProps,
  onFileUpload,
  onDeleteInvalidFile,
  isLoading,
}: TaxPayerInfoProps) => {
  const { createTrackHandler } = useAnalytics();

  const trackEvent = createTrackHandler('Vendor', 'Click');

  const handleChange = () => {
    trackEvent({
      PageName: 'add-a-vendor',
      Flow: 'vendor',
      Intent: 'add-a-vendor',
      Cta: 'mark-1099-contractor',
    });

    onChangeIsContractor(!isContractor);
  };

  const handleFileUpload = (file: File) => {
    trackEvent({
      PageName: 'add-a-vendor',
      Flow: 'vendor-1099',
      Intent: 'add-a-vendor',
      IsContractor: isContractor,
      TaxIdType: 'pdf',
      Cta: 'upload-w9',
    });

    return onFileUpload(file);
  };

  return (
    <Container paddingTop="l" data-testid="add-vendor-details-tax-payer-info" overflow="visible">
      <Group spacing="xl" variant="vertical">
        <TaxPayerInfoCheckbox onChange={handleChange} isContractor={isContractor} isUpdating={isLoading} />
        {isContractor && (
          <TaxPayerInfoForm
            handleFileUpload={handleFileUpload}
            onDeleteInvalidFile={onDeleteInvalidFile}
            taxPayerInfoFormProps={taxPayerInfoFormProps}
            isLoading={isLoading}
            radioGroupVariant="vertical"
            isBannerCompact={false}
          />
        )}
      </Group>
    </Container>
  );
};
