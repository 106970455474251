import { Box } from '@chakra-ui/react';
import { DashboardCustomer } from '@melio/ar-domain';
import { Table } from '@melio/penny';

import { useCustomerActionsDropdownMenuItems } from '../../hooks';
import { CustomerActions } from '../../types';

type CustomerActionCellProps = {
  row: DashboardCustomer;
  meta?: CustomerActions;
};

const handleCellClick = (e: React.MouseEvent<HTMLDivElement>) => {
  e.stopPropagation();
};

export const CustomerActionCell = ({ row: customer, meta }: CustomerActionCellProps) => {
  const actions = useCustomerActionsDropdownMenuItems({ customer, customerActions: meta });
  return (
    <Box onClick={handleCellClick} height="full">
      <Table.ActionsCell options={actions} data-testid={`customer-actions-cell-${customer.id}`} />
    </Box>
  );
};
