import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Icon, Text, useTheme } from '@melio/penny';
import { FeeType } from '@melio/platform-api';

import { usePlatformIntl } from '@/translations/Intl';
import { Fee } from '@/widgets/Fee/Fee.widget';
import { PayBillCardHeader } from './CardHeader.widget';

export const CreditCardCard = () => {
  const { formatMessage } = usePlatformIntl();
  const currentTheme = useTheme();

  const titles = [
    formatMessage('widgets.payDashboardCards.creditCardCard.conditions.creditCard.title', {
      cardFee: <Fee feeType={FeeType.Credit} />,
    }),
    formatMessage('widgets.payDashboardCards.creditCardCard.conditions.achTransfer.title', {
      achFee: <Fee feeType={FeeType.Ach} />,
    }),
    formatMessage('widgets.payDashboardCards.creditCardCard.conditions.deferPayments.title'),
    formatMessage('widgets.payDashboardCards.creditCardCard.conditions.rewards.title'),
  ];

  const getConditions = () => {
    return titles.map((title, i) => {
      return (
        <Box key={i} sx={{ display: 'flex', gap: 'xs', marginBottom: '20px', alignItems: 'center' }}>
          <Box sx={{ color: currentTheme.colors.global.neutral['900'], display: 'inline-flex' }}>
            <Icon type="checked" color="inherit" />
          </Box>
          <Text textStyle="body3Semi">{title}</Text>
        </Box>
      );
    });
  };

  return (
    <Flex direction={'column'}>
      <PayBillCardHeader
        title={formatMessage('widgets.payDashboardCards.creditCardCard.title')}
        description={formatMessage('widgets.payDashboardCards.creditCardCard.description')}
      />

      {getConditions()}
    </Flex>
  );
};
