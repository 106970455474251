import {
  calculateRecurringEndDateByNumOfOccurrences,
  getNumberOfOccurrences,
  getRecurringFieldsToShow,
} from '@melio/ap-domain';
import { Control, Form, useWatch } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { addBusinessDays, useDateUtils } from '@melio/platform-utils';

import { AddBillV2FormValues, AddBillV2RecurringEndBy, RegisterFieldFn } from '../../../../types';
import { formFrequencyToIntervalType } from '../../../../utils';
import { RecurringInfoSection } from '../../../RecurringInfoSection';

type Props = {
  formControl: Control<AddBillV2FormValues>;
  registerField: RegisterFieldFn;
};

export const RecurringBillFormFields = ({ formControl, registerField }: Props) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const { createDate } = useDateUtils();
  const {
    settings: {
      newBillExperience: { isRecurringPaymentImprovementsEnabled },
    },
  } = useConfig();
  const [recurringEndBy, recurringStartDate, amount, recurringEndDate, frequency, recurringOccurrences] = useWatch({
    control: formControl,
    name: ['recurringEndBy', 'recurringStartDate', 'amount', 'recurringEndDate', 'frequency', 'recurringOccurrences'],
  });

  const endByAvailableValues = isRecurringPaymentImprovementsEnabled
    ? [AddBillV2RecurringEndBy.NO_END_DATE, AddBillV2RecurringEndBy.DATE, AddBillV2RecurringEndBy.OCCURRENCES]
    : [AddBillV2RecurringEndBy.DATE, AddBillV2RecurringEndBy.OCCURRENCES];

  const endByOptions = endByAvailableValues.map((endBy) => ({
    label: formatMessage(`activities.addBillV2.billForm.recurringEndBy.values.${endBy}`),
    value: endBy,
    testId: endBy,
  }));

  const billSubscriptionIntervalType = formFrequencyToIntervalType(frequency);

  const numOfOccurrences =
    billSubscriptionIntervalType && recurringStartDate
      ? getNumberOfOccurrences({
          paymentFrequency: billSubscriptionIntervalType,
          numOfOccurrences: undefined,
          startDate: recurringStartDate,
          endDate: recurringEndDate || undefined,
        })
      : undefined;

  const lastPaymentDate =
    billSubscriptionIntervalType && recurringOccurrences && recurringStartDate
      ? calculateRecurringEndDateByNumOfOccurrences({
          paymentFrequency: billSubscriptionIntervalType,
          startDate: recurringStartDate,
          numOfOccurrences: Number(recurringOccurrences),
        })
      : undefined;

  const recurringFieldsToShow = recurringEndBy
    ? getRecurringFieldsToShow(recurringEndBy, isRecurringPaymentImprovementsEnabled)
    : undefined;
  return (
    <>
      <Form.DateField
        {...registerField('recurringStartDate')}
        toggleDatePickerAriaLabel={formatMessage(
          'activities.addBillV2.billForm.recurringStartDate.toggleDatePickerAriaLabel'
        )}
        minDate={createDate()}
        isRequired
        excludeHolidays
        data-testid="add-bill-v2-recurring-start-date-picker"
        labelProps={{ label: formatMessage('activities.addBillV2.billForm.recurringStartDate.placeholder') }}
        colSpan={8}
        isTypable
      />
      <Form.SelectNew
        {...registerField('recurringEndBy')}
        isRequired
        data-testid="add-bill-v2-recurring-end-by-picker"
        options={endByOptions}
        colSpan={8}
        labelProps={{ label: formatMessage('activities.addBillV2.billForm.recurringEndBy.placeholder') }}
        shouldHideClearButton
      />
      {recurringFieldsToShow?.endDate && (
        <Form.DateField
          {...registerField('recurringEndDate')}
          toggleDatePickerAriaLabel={formatMessage(
            'activities.addBillV2.billForm.recurringEndDate.toggleDatePickerAriaLabel'
          )}
          isRequired
          minDate={recurringStartDate ? addBusinessDays(recurringStartDate, 1) : createDate()}
          excludeHolidays
          data-testid="add-bill-v2-recurring-end-date-picker"
          labelProps={{ label: formatMessage('activities.addBillV2.billForm.recurringEndDate.placeholder') }}
          helperTextProps={
            numOfOccurrences
              ? {
                  label: formatMessage('activities.addBillV2.billForm.recurringEndDate.helperText', {
                    numOfOccurrences,
                  }),
                }
              : undefined
          }
          colSpan={8}
          isTypable
        />
      )}
      {recurringFieldsToShow?.numberOfOccurrences && (
        <Form.TextField
          {...registerField('recurringOccurrences')}
          data-testid="add-bill-v2-recurring-occurrences-field"
          isRequired
          type="number"
          colSpan={8}
          labelProps={{ label: formatMessage('activities.addBillV2.billForm.recurringOccurrences.placeholder') }}
          helperTextProps={
            lastPaymentDate
              ? {
                  label: formatMessage('activities.addBillV2.billForm.recurringOccurrences.helperText', {
                    lastPaymentDate: formatDate(lastPaymentDate, {
                      dateStyle: 'medium',
                    }),
                  }),
                }
              : undefined
          }
        />
      )}
      {recurringFieldsToShow?.lastAmount && (
        <Form.AmountField
          {...registerField('lastAmount')}
          data-testid="add-bill-v2-last-amount-field"
          labelProps={{ label: formatMessage('activities.addBillV2.billForm.recurringLastPaymentAmount.placeholder') }}
          placeholder={amount ? `$${amount}` : undefined}
          colSpan={8}
        />
      )}
      <Form.ContentBox colSpan={16}>
        <RecurringInfoSection />
      </Form.ContentBox>
    </>
  );
};
