import { useIsMobile } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';

import { Filter, OnSort, SortParams } from '../types';
import { FiltersToolBar } from './FiltersToolBar';
import { FiltersToolBarMobile } from './FiltersToolBar.mobile';

export type InvoiceTableToolbarProps = {
  isLoading?: boolean;
  onFilter: (filter: Filter) => void;
  invoiceStatus: Filter;
  search?: string;
  onSearch: (value: string) => void;
  onSort: OnSort;
  sortParams?: SortParams;
};

export const InvoiceTableToolbar = forwardRef<InvoiceTableToolbarProps>(
  ({ onFilter, invoiceStatus, isLoading, search, onSearch, onSort, sortParams, ...props }, ref) => {
    const orderedFilters: Filter[] = ['all', 'draft', 'open', 'overdue', 'paid', 'canceled'];
    const isMobile = useIsMobile();

    return !isMobile ? (
      <FiltersToolBar
        data-component={InvoiceTableToolbar.displayName}
        selectedFilter={invoiceStatus}
        onSelectFilter={onFilter}
        search={search}
        onSearch={onSearch}
        filters={orderedFilters}
        {...props}
        ref={ref}
      />
    ) : (
      <FiltersToolBarMobile
        data-component={InvoiceTableToolbar.displayName}
        selectedFilter={invoiceStatus}
        onSelectFilter={onFilter}
        search={search}
        onSearch={onSearch}
        onSort={onSort}
        sortParams={sortParams}
        filters={orderedFilters}
        {...props}
        ref={ref}
      />
    );
  }
);
InvoiceTableToolbar.displayName = 'InvoiceTableToolbar';
