import { usePlansTiers, useSubscription } from '../../../../hooks';
import { EditablePlanCardFiserv } from './EditablePlanCardFiserv';
import { FreePlanCardFiserv } from './FreePlanCardFiserv';

export const SubscriptionPlansSelectFiserv = () => {
  const subscription = useSubscription();

  const { getIsFreePlan } = usePlansTiers();

  const isFreePlan = subscription && getIsFreePlan(subscription.planId);

  return isFreePlan ? <FreePlanCardFiserv /> : <EditablePlanCardFiserv />;
};
