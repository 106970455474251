import { useMelioIntl } from '@melio/ar-domain';
import { useAnalyticsView } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';

import { CustomerFormDialog, CustomerFormDialogProps } from '../components';

export type CreateCustomerScreenProps = Pick<
  CustomerFormDialogProps,
  'onSubmit' | 'isSaving' | 'isOpen' | 'onClose' | 'defaultValues'
> & { error?: ARPlatformError };

export const CreateCustomerScreen = forwardRef<CreateCustomerScreenProps>((props, ref) => {
  const { formatMessage } = useMelioIntl();

  useAnalyticsView('Customer', true, true, {
    ProductName: 'ar',
    PageName: 'add-customer',
    TabName: 'customers',
    Flow: 'add-customer',
    Intent: 'create-customer',
  });

  return (
    <CustomerFormDialog
      header={formatMessage('ar.modals.activities.customerManagement.modal.create.header')}
      primaryButtonLabel={formatMessage('ar.modals.activities.customerManagement.modal.create.buttons.primary.label')}
      secondaryButtonLabel={formatMessage(
        'ar.modals.activities.customerManagement.modal.create.buttons.secondary.label'
      )}
      data-testid="create-customer-modal"
      ref={ref}
      {...props}
    />
  );
});
