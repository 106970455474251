import { usePayment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { useDevice, useSystemMessage } from '@melio/platform-utils';
import { Buffer } from 'buffer';
import { useEffect, useRef } from 'react';

export const useDownloadPaymentConfirmation = () => {
  const paymentConfirmationIframe = useRef<HTMLIFrameElement>(null);
  const {
    settings: { partnerProductName },
  } = useConfig();
  const paymentConfirmationURL = useRef<string>('');
  const { downloadPaymentConfirmation } = usePayment();
  const { showMessage } = useSystemMessage();
  const { isDesktop } = useDevice();
  const { formatMessage } = useMelioIntl();

  useEffect(
    () => () => {
      if (paymentConfirmationIframe.current) {
        document.body.removeChild(paymentConfirmationIframe.current);
        URL.revokeObjectURL(paymentConfirmationURL.current);
      }
    },
    []
  );

  const download = async (paymentId: string) => {
    try {
      const res = await downloadPaymentConfirmation(paymentId);
      const blob = new Blob([Buffer.from(res.data.data.base64, 'base64')], { type: 'application/pdf' });
      const fileName = `${partnerProductName}-Receipt-${paymentId}.pdf`;
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;

      if (!isDesktop) {
        if (navigator.share) {
          const file = new File([blob], fileName, { type: 'application/pdf' });
          try {
            await navigator.share({
              files: [file],
            });
          } catch (shareError) {
            // Suppress AbortError when user cancels the share action
            if ((shareError as DOMException).name !== 'AbortError') {
              throw shareError;
            }
          }
        } else {
          link.click();
        }
      } else {
        link.click();
      }

      paymentConfirmationURL.current = url;
    } catch (error) {
      showMessage({
        type: 'error',
        title: formatMessage(
          'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentConfirmation.download.error'
        ),
      });
    }
  };

  const print = async (paymentId: string) => {
    try {
      const res = await downloadPaymentConfirmation(paymentId);
      const blob = new Blob([Buffer.from(res.data.data.base64, 'base64')], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);

      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = url;
      document.body.appendChild(iframe);

      iframe.onload = () => {
        iframe.contentWindow?.print();
      };

      (paymentConfirmationIframe as React.MutableRefObject<HTMLIFrameElement | null>).current = iframe;
      paymentConfirmationURL.current = url;
    } catch (error) {
      showMessage({
        type: 'error',
        title: formatMessage(
          'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentConfirmation.print.error'
        ),
      });
    }
  };

  return { download, print };
};
