import { PaymentModel, usePayment } from '@melio/platform-api';
import { useState } from 'react';

export const useVoidAndRefundData = (
  paymentId: string
): {
  payment?: PaymentModel['data'];
  isLoading: boolean;
  isVoidAndRefundError: boolean;
  isUpdatingPayment: boolean;
  voidAndRefund: PaymentModel['refund'];
  setIsVoidAndRefundError: React.Dispatch<React.SetStateAction<boolean>>;
} => {
  const [isVoidAndRefundError, setIsVoidAndRefundError] = useState(false);
  const { data: payment, isLoading: isLoadingPayment, voidAndRefund, isMutating } = usePayment({ id: paymentId });

  return {
    payment,
    isLoading: isLoadingPayment,
    isVoidAndRefundError,
    isUpdatingPayment: isMutating,
    voidAndRefund,
    setIsVoidAndRefundError,
  };
};
