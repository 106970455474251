import { useAnalyticsContext, withAnalyticsContext } from '@melio/platform-analytics';
import { OrganizationBeneficialOwner, useAccount, useOrganizationBeneficialOwners } from '@melio/platform-api';
import { useCallback } from 'react';

import { parseDateOfBirthToISO } from '../../../../../utils/pay-flow/dateOfBirth';
import { DeliveryMethodFlowOrigin } from '../../../../delivery-methods/types';
import { SubmissionTargetType } from '../../types';
import { AddOrganizationBeneficialOwnerFormWidgetFields, AddOrganizationBeneficialOwnersScreen } from './screens';

type AddOrganizationBeneficialOwnersActivityProps = {
  onDone: (owners: OrganizationBeneficialOwner[], target?: SubmissionTargetType) => void;
  onClose: VoidFunction;
  onBack: VoidFunction;
  origin: DeliveryMethodFlowOrigin;
};

export const AddOrganizationBeneficialOwnersActivity = withAnalyticsContext(
  ({ onClose, onDone, onBack, origin }: AddOrganizationBeneficialOwnersActivityProps) => {
    const { batchCreate, isLoading, isMutating } = useOrganizationBeneficialOwners({ enabled: false });
    const { data: me } = useAccount({ id: 'me' });

    useAnalyticsContext({ globalProperties: { PageName: 'company-ownership', Intent: 'add-delivery-method' } });

    const onDoneHandler = useCallback(
      async (createData: AddOrganizationBeneficialOwnerFormWidgetFields[], target?: 'continue' | 'close') => {
        const parsedData = createData.map((owner) => ({
          ...owner,
          dateOfBirth: parseDateOfBirthToISO(owner.dateOfBirth),
        }));
        const owners = await batchCreate(parsedData);
        onDone(owners, target);
      },
      [onDone, batchCreate]
    );

    return (
      <AddOrganizationBeneficialOwnersScreen
        isLoading={isLoading}
        isSaving={isMutating}
        onDone={onDoneHandler}
        onClose={onClose}
        onBack={onBack}
        organizationName={me?.company.name || ''}
        origin={origin}
      />
    );
  }
);

AddOrganizationBeneficialOwnersActivity.displayName = 'AddOrganizationBeneficialOwnersActivity';
