import { RecommendedVendor } from '@melio/platform-api';

export type RankedVendor = RecommendedVendor & { rank: number };

export const addRanksToVendors = (r: RecommendedVendor[]) => r.map((r, i) => ({ ...r, rank: i }));

export const recommendationsToVendors = (recommendations: RankedVendor[]) =>
  recommendations.map((r) => {
    const { countryCode, ...address } = r.address;
    return {
      name: r.name,
      contact: {
        address,
      },
      businessDirectoryExternalId: r.type === 'business' ? r.id : undefined,
    };
  });

export const recommendationToAnalyticsEvents = (recommendations: RankedVendor[]) =>
  recommendations.map((r) => ({
    ...(r.type === 'business' ? { BusinessId: r.id } : { VendorId: r.id }),
    VendorName: r.name,
    EntityType: r.type,
    rank: r.rank,
    address: r.address,
  }));
