import { FormDialog, FormWidgetProps, useMelioIntl } from '@melio/ar-domain';
import { Form, useMelioForm } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import * as yup from 'yup';

export type InvoiceNoteFormFields = {
  noteOnInvoice: string;
};

const useValidationSchema = () => {
  const { formatMessage } = useMelioIntl();

  return yup.object().shape({
    noteOnInvoice: yup
      .string()
      .required(
        formatMessage(
          'ar.settings.flows.invoiceSettings.sections.editInvoiceNote.modal.fields.noteOnInvoice.validations.required.text'
        )
      ),
  }) as yup.SchemaOf<InvoiceNoteFormFields>;
};

export type EditInvoiceNoteDialogScreenProps = Pick<
  FormWidgetProps<InvoiceNoteFormFields>,
  'defaultValues' | 'isSaving' | 'onSubmit'
> & {
  onClose: VoidFunction;
  isOpen: boolean;
  isLoading: boolean;
};
export const EditInvoiceNoteDialogScreen = forwardRef<EditInvoiceNoteDialogScreenProps>(
  ({ isOpen, onSubmit, defaultValues, isSaving, isLoading, onClose, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const { registerField, watch, ...rest } = useMelioForm<InvoiceNoteFormFields>({
      schema: useValidationSchema(),
      isSaving,
      defaultValues,
      onSubmit,
      subscribeToDefaultValuesChanges: true,
    });

    return (
      <FormDialog
        closeButtonAriaLabel={formatMessage(
          'ar.settings.flows.invoiceSettings.sections.editInvoiceNote.modal.closeButtonAriaLabel'
        )}
        data-component={EditInvoiceNoteDialogScreen.displayName}
        isOpen={isOpen}
        size="large"
        isLoading={isLoading}
        onClose={() => !isSaving && onClose()}
        header={formatMessage('ar.settings.flows.invoiceSettings.sections.editInvoiceNote.modal.title.text')}
        description={formatMessage('ar.settings.flows.invoiceSettings.sections.editInvoiceNote.modal.subtitle.text')}
        primaryButton={{
          label: formatMessage('ar.settings.flows.invoiceSettings.sections.editInvoiceNote.modal.primaryButton.label'),
        }}
        secondaryButton={{
          label: formatMessage(
            'ar.settings.flows.invoiceSettings.sections.editInvoiceNote.modal.secondaryButton.label'
          ),
        }}
        useFormResults={rest}
        {...props}
        ref={ref}
      >
        <Form.TextArea
          labelProps={{
            label: formatMessage(
              'ar.settings.flows.invoiceSettings.sections.editInvoiceNote.modal.fields.noteOnInvoice.label'
            ),
          }}
          placeholder={formatMessage(
            'ar.settings.flows.invoiceSettings.sections.editInvoiceNote.modal.fields.noteOnInvoice.placeholder.text'
          )}
          {...registerField('noteOnInvoice')}
          data-private
          maxChars={500}
        />
      </FormDialog>
    );
  }
);
EditInvoiceNoteDialogScreen.displayName = 'EditInvoiceNoteDialogScreen';
