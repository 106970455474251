import { useAccount } from '@melio/ar-domain';
import { useAnalyticsView } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';

import { OnBeforeDone, ShareMode } from '../../types';
import { IssueInvoiceScreen } from './screens';
import { useIssueInvoiceActivity } from './utils';

export type IssueInvoiceActivityProps = {
  invoiceId: string;
  onDone: (id: string) => void;
  onBeforeDone?: OnBeforeDone;
  onClose: VoidFunction;
  onError?: ARErrorFunction;
  onUploadCompanyLogo?: VoidFunction;
  shareMode: ShareMode;
  onEditInvoice: (id: string) => unknown;
};

export const IssueInvoiceActivity = forwardRef<IssueInvoiceActivityProps>(
  (
    {
      onDone,
      onClose,
      onError,
      invoiceId,
      onBeforeDone,
      onUploadCompanyLogo,
      shareMode,
      onEditInvoice,
      ...props
    }: IssueInvoiceActivityProps,
    ref
  ) => {
    const {
      isLoading,
      isSaving,
      submitHandler,
      submitTarget,
      customer,
      invoice,
      paymentRequestLink,
      handleCopyPaymentLink,
      handleBackClick,
    } = useIssueInvoiceActivity({
      invoiceId,
      onDone,
      onError,
      onBeforeDone,
      shareMode,
      onClose,
      onEditInvoice,
    });
    const { data: account, isLoading: isAccountLoading } = useAccount({ id: 'me', onError });

    useAnalyticsView('Invoice', true, true, {
      ProductName: 'ar',
      PageName: 'send-invoice',
      Flow: 'send-invoice',
      Intent: 'send-invoice',
    });

    return (
      <IssueInvoiceScreen
        isLoading={isLoading || isAccountLoading}
        invoiceId={invoiceId}
        onClose={onClose}
        onSubmit={(formData, _, submitTarget) => submitHandler(formData, submitTarget)}
        defaultValues={{ email: customer?.email }}
        isSubmitting={isSaving}
        submitTarget={submitTarget}
        onBack={handleBackClick}
        onUploadCompanyLogo={onUploadCompanyLogo}
        shouldDisplayUploadCompanyLogoBanner={!account?.company.logoId}
        paymentRequestLink={paymentRequestLink}
        onCopyPaymentLink={handleCopyPaymentLink}
        customerName={invoice?.customer?.companyName}
        {...props}
        ref={ref}
      />
    );
  }
);
