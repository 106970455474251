import {
  ARFeeDetails,
  FormattedMessage,
  FundingSourceType,
  PayorPaymentRequestDetails,
  useMelioIntl,
} from '@melio/ar-domain';
import { Group, Link, Pill, SectionBannerProps, Text } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { FundingSourceSelection, PaymentProcessingIndicator, PaymentRequestDetailsHeader } from '../components';
import { useFormattedFee } from '../hooks';
import { GuestPayorFundingSourceTypes } from '../types';
import { GuestPaymentLayout } from './GuestPayment.layout';

const PaymentMethodTitle = ({ title, feeDetails }: { title: string; feeDetails?: ARFeeDetails }) => {
  const { getFeeLabel } = useFormattedFee();

  return (
    <Group alignItems="center" spacing="xxs">
      <Text textStyle="body1Semi">{title}</Text>
      {feeDetails && <Pill status="neutral" type="secondary" label={getFeeLabel(feeDetails)} />}
    </Group>
  );
};

export type PaymentLayoutProps = {
  isLoading: boolean;
  paymentRequestDetails?: PayorPaymentRequestDetails;
  onViewInvoice: VoidFunction;
  onSelectFundingSource: (fundingSource: FundingSourceType) => void;
  selectedFundingSource?: GuestPayorFundingSourceTypes;
  notificationProps?: SectionBannerProps;
  children: React.ReactNode;
  isPaymentFormLoading: boolean;
  isPaymentProcessing: boolean;
};

export const PaymentLayout = withAnalyticsContext<PaymentLayoutProps>(
  forwardRef(
    (
      {
        setAnalyticsProperties,
        notificationProps,
        onViewInvoice,
        selectedFundingSource,
        onSelectFundingSource,
        paymentRequestDetails,
        isLoading,
        children,
        isPaymentFormLoading,
        isPaymentProcessing,
      },
      ref
    ) => {
      const { track } = useAnalytics();
      const { formatMessage } = useMelioIntl();
      const { privacyPolicyUrl, termsOfServiceUrl } = useConfig().settings;
      const { isAchAllowed, isCardAllowed, customPayInstructions } =
        paymentRequestDetails?.invoice.paymentOptions || {};

      const getAnalyticsPaymentMethodType = (fundingSource: GuestPayorFundingSourceTypes | undefined) =>
        fundingSource === 'card' ? 'card' : 'ach';
      const handleFundingSourceSelection = (fundingSource: 'card' | 'bank-account') => {
        track('PaymentRequest', 'Click', {
          Intent: 'choose-payment-method',
          PaymentMethodType: getAnalyticsPaymentMethodType(fundingSource),
          Cta: fundingSource === 'card' ? 'card' : 'ach',
        });
        onSelectFundingSource(fundingSource);
      };

      const getAvailablePaymentOptionsString = () => {
        if (!paymentRequestDetails) return null;

        const options = [];

        if (isAchAllowed) options.push('ach');
        if (isCardAllowed) options.push('card');
        if (customPayInstructions) options.push('custom');

        return options.join('_');
      };

      const handleOnViewInvoice = () => {
        track('PaymentRequest', 'Click', {
          PageName: 'payment-request',
          Intent: 'view-invoice-file',
          Cta: 'view',
        });
        onViewInvoice();
      };

      setAnalyticsProperties({
        PageName: 'payment-request',
        PaymentMethodShown: getAvailablePaymentOptionsString(),
        PaymentMethodType: getAnalyticsPaymentMethodType(selectedFundingSource),
        Intent: 'pay-invoice',
      });

      return (
        <GuestPaymentLayout
          isLoading={isLoading}
          data-component={PaymentLayout.displayName}
          ref={ref}
          notificationProps={notificationProps}
        >
          {isPaymentProcessing && <PaymentProcessingIndicator />}
          <div {...(isPaymentProcessing && { style: { display: 'none' } })}>
            <Group variant="vertical">
              {!!paymentRequestDetails && (
                <Group variant="vertical" spacing="l" hasDivider>
                  <PaymentRequestDetailsHeader
                    payeePaymentRequestDetails={paymentRequestDetails.payeeDetails}
                    invoicePaymentRequestDetails={paymentRequestDetails.invoice}
                    onViewInvoice={handleOnViewInvoice}
                    isViewInvoiceEnabled={isPaymentFormLoading}
                  />
                  {isCardAllowed && isAchAllowed ? (
                    <Group variant="vertical" spacing="l">
                      <FundingSourceSelection
                        fees={paymentRequestDetails.fees}
                        selectedFundingSource={selectedFundingSource}
                        onCardSelected={() => handleFundingSourceSelection('card')}
                        onBankSelected={() => handleFundingSourceSelection('bank-account')}
                      />
                      <Outlet />
                    </Group>
                  ) : (
                    <Group variant="vertical" spacing="l">
                      {isCardAllowed ? (
                        <PaymentMethodTitle
                          title={formatMessage('ar.guestPayment.paymentMethods.payByCard.title.text')}
                          feeDetails={paymentRequestDetails.fees?.card}
                        />
                      ) : isAchAllowed ? (
                        <PaymentMethodTitle
                          title={formatMessage('ar.guestPayment.paymentMethods.payByBank.title.text')}
                          feeDetails={paymentRequestDetails.fees?.ach}
                        />
                      ) : null}
                      {children}
                    </Group>
                  )}
                </Group>
              )}
              {(isCardAllowed || isAchAllowed) && (
                <Text textStyle="body4" color="global.neutral.800">
                  <FormattedMessage
                    id="ar.guestPayment.payment.termsAndPolicyDisclaimer.text"
                    values={{
                      termsOfService: (
                        <Link
                          color="secondary"
                          href={termsOfServiceUrl}
                          label={formatMessage('ar.guestPayment.payment.termsAndPolicyDisclaimer.terms.label')}
                          variant="inline"
                          newTab
                          onClick={() => track('PaymentRequest', 'Click', { Cta: 'terms-of-service' })}
                        />
                      ),
                      privacyPolicy: (
                        <Link
                          color="secondary"
                          href={privacyPolicyUrl}
                          label={formatMessage('ar.guestPayment.payment.termsAndPolicyDisclaimer.privacyPolicy.label')}
                          variant="inline"
                          newTab
                          onClick={() => track('PaymentRequest', 'Click', { Cta: 'privacy-policy' })}
                        />
                      ),
                    }}
                  />
                </Text>
              )}
            </Group>
          </div>
        </GuestPaymentLayout>
      );
    }
  )
);

PaymentLayout.displayName = 'PaymentLayout';
