import { ReviewAndConfirmAmountSection } from '@melio/ap-widgets';
import { Card, Container, Group } from '@melio/penny';
import { DeliveryMethod, FundingSource } from '@melio/platform-api';

import { RefundToSection } from './RefundToSection/RefundToSection';

export type RefundReviewAndConfirmScreenProps = {
  amount: number;
  vendorId: string;
  fundingSource: FundingSource;
  deliveryMethod?: DeliveryMethod;
  maxEstimatedRefundDate: Date;
};

export const RefundReviewAndConfirmContent: React.FC<RefundReviewAndConfirmScreenProps> = ({
  amount,
  vendorId,
  fundingSource,
  deliveryMethod,
  maxEstimatedRefundDate,
  ...props
}) => (
  <Card paddingX="none" paddingY="none" data-component="ReviewAndConfirmRefund" width="full" {...props}>
    <Container>
      <Group variant="vertical" spacing="none" hasDivider>
        <Container paddingX="m" paddingY="m">
          <ReviewAndConfirmAmountSection
            amount={amount}
            title="activities.refundBill.screens.reviewAndConfirmRefund.refundAmount.label"
            vendorId={vendorId}
            deliveryMethod={deliveryMethod}
          />
        </Container>
        <Container paddingX="m" paddingY="m">
          <RefundToSection fundingSource={fundingSource} maxEstimatedRefundDate={maxEstimatedRefundDate} />
        </Container>
      </Group>
    </Container>
  </Card>
);

RefundReviewAndConfirmContent.displayName = 'RefundReviewAndConfirmContent';
