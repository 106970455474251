import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import _compact from 'lodash/compact';
import { useRecoilValue } from 'recoil';
import { DashboardNavigation } from '@melio/ap-activities/src/components/PayDashboard/components/DashboardHeader/DashboardNavigation';
import { Container, Group, useBreakpoint } from '@melio/penny';
import { useAccount } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { usePermissions } from '@melio/platform-permissions';
import { usePartnerFeature } from '@melio/platform-provider';
import { useConfig } from '@melio/platform-provider';
import { useSetDocumentTitle } from '@melio/platform-sdk';
import { PageTitle, SystemMessageProvider } from '@melio/platform-utils';
import { SystemMessageDisplay } from '@melio/platform-utils/system-message';
import { useSubscriptionPermissions } from '@melio/subscriptions';

import { SettingsCardIdEnum, SettingsCardProps } from '@/cl/components/SettingsCard/SettingsCard.component';
import { ScreenSplitLayout } from '@/cl/layouts/screen-split-layout/screenSplitLayout.component';
import { SettingsNestedPages } from '@/consts/SettingsConsts';
import { WithLoading } from '@/hoc/withLoading.hoc';
import { usePartnerConfig } from '@/hooks/partners';
import { useRouter } from '@/hooks/router.hooks';
import { useActiveScreen } from '@/hooks/useActiveScreen';
import { useHasAccessToFirm } from '@/hooks/useHasAccessToFirm.hooks';
import { useIsOrganizationInMsnOnly } from '@/hooks/useIsOrganizationInMsnOnly.hooks';
import { SettingsGroupEnum } from '@/partnersConfig.types';
import { ScreensEnum } from '@/store/app/app.types';
import {
  settingsSelectedSettingsPageSelector,
  settingsState,
  useSettingsSelectedSettingsPage,
} from '@/store/Settings/Settings.model';
import { usePlatformIntl } from '@/translations/Intl';
import { useShouldShowDeliveryMethods } from '@/utils/receivingMethods.utils';
import { SettingsGroupWidget } from '@/widgets/settings-page/SettingsHomePage/SettingsGroup.widget';
import { SettingsProvider } from './SettingsProvider';

const SettingsLayoutRoute = () => {
  const { isExtraSmallScreen } = useBreakpoint();
  const [accountManagerAccessCollaboratorsEnabled] = useFeature<boolean>(
    FeatureFlags.AccountManagerAccessCollaborators,
    false,
  );
  const [notificationPreferencesEnabled] = useFeature<boolean>(FeatureFlags.NotificationPreferences, true);
  const [displayReceivingMethodSettingItem] = useFeature<boolean>(FeatureFlags.DisplayReceivingMethodsItem, false);
  const [isApprovalWorkflowsFeatureEnabled] = useFeature(FeatureFlags.ApprovalWorkflows, false, {
    shouldTrack: true,
  });
  const [isAccountingSoftwareEnabled] = useFeature<boolean>(FeatureFlags.IsAccountingSoftwareEnabled, true);
  const {
    settings: { isPaymentApprovalLimitEnabled },
  } = useConfig();
  const hasAccessToFirm = useHasAccessToFirm();

  const { can } = usePermissions();

  const { hasAccessToSubscriptionSettings } = useSubscriptionPermissions();

  useActiveScreen(ScreensEnum.settings);
  const { formatMessage } = usePlatformIntl();
  const {
    goToSettingsProfile,
    goToSettingsCompany,
    goToSettingsNotificationPreferences,
    goToSettingsAccountSoftware,
    goToSettingsPaymentMethods,
    goToSettingsBilling,
    goToSettingsSupport,
    goToSettingsCollaborators,
    goToReceivingMethods,
    goToInvoiceItems,
    goToSettingsWorkflows,
    goToSettingsSubscriptionPlans,
    goToInvoiceEmailNotifications,
    goToInvoicePreferences,
    goToSettingsTaxAndReports,
  } = useRouter();

  const selectedSettingsPage = useRecoilValue(settingsSelectedSettingsPageSelector);

  const isPureMSNOrganization = useShouldShowDeliveryMethods();

  const isShowReceivingMethodsItem = useIsOrganizationInMsnOnly();

  const approvalWorkflowsEnabled =
    isApprovalWorkflowsFeatureEnabled && can({ action: 'read', subject: 'approvalWorkflow' });

  const [isArEnabled] = useFeature(FeatureFlags.ARDashboard, false); //todo - in fiserv it should be per user according to the user plan

  const [isInvoiceSettingsEnabled] = useFeature(FeatureFlags.ARSettingsInvoicePreferences, false);

  const [isTaxAndReportsFeatureEnabled] = usePartnerFeature('File1099SettingsTaxAndReports', false);
  const hasTaxAndReportsPermissions =
    can({ subject: 'report:tax', action: 'create' }) || can({ subject: 'report:payment', action: 'create' });
  const isTaxAndReportsEnabled = isTaxAndReportsFeatureEnabled && hasTaxAndReportsPermissions;

  const {
    partnerConfig: { getSettingsConfig },
  } = usePartnerConfig();

  const settingGroupTypeToTitle: Record<SettingsGroupEnum, string> = useMemo(() => {
    return {
      [SettingsGroupEnum.COMPANY]: formatMessage('app.settings.companySection.title'),
      [SettingsGroupEnum.PAYMENTS]: formatMessage('app.settings.BillingSection.title'),
      [SettingsGroupEnum.AR]: formatMessage('app.settings.arSection.title'),
      [SettingsGroupEnum.SUPPORT]: formatMessage('app.settings.SupportSection.title'),
    };
  }, [formatMessage]);

  const getSettingCardProps: (settingCardType: SettingsCardIdEnum) => SettingsCardProps | null = useCallback(
    (settingCardType: SettingsCardIdEnum) => {
      switch (settingCardType) {
        case SettingsCardIdEnum.ProfileSettings:
          return {
            id: SettingsCardIdEnum.ProfileSettings,
            iconType: 'user-id',
            content: formatMessage('app.settings.companySection.cards.profile.title'),
            onClick: goToSettingsProfile,
            isSelected: selectedSettingsPage === SettingsNestedPages.PROFILE,
            cardName: 'profile',
          };
        case SettingsCardIdEnum.CompanySettings:
          return hasAccessToFirm
            ? {
                id: SettingsCardIdEnum.CompanySettings,
                iconType: 'company',
                content: formatMessage('app.settings.companySection.cards.company.title'),
                onClick: goToSettingsCompany,
                isSelected: selectedSettingsPage === SettingsNestedPages.COMPANY,
                cardName: 'company',
              }
            : null;
        case SettingsCardIdEnum.CollaboratorsSetting:
          return accountManagerAccessCollaboratorsEnabled &&
            hasAccessToFirm &&
            (can({ subject: 'collaborator', action: 'read' }) || can({ subject: 'invitation', action: 'read' }))
            ? {
                id: SettingsCardIdEnum.CollaboratorsSetting,
                iconType: 'user-add',
                content: formatMessage('app.settings.companySection.cards.collaborators.title'),
                onClick: goToSettingsCollaborators,
                isSelected: selectedSettingsPage === SettingsNestedPages.COLLABORATORS,
                cardName: 'collaborators',
              }
            : null;
        case SettingsCardIdEnum.WorkflowsSetting:
          return approvalWorkflowsEnabled && hasAccessToFirm && !isPaymentApprovalLimitEnabled
            ? {
                id: SettingsCardIdEnum.WorkflowsSetting,
                iconType: 'flow',
                content: formatMessage('app.settings.companySection.cards.workflows.title'),
                onClick: goToSettingsWorkflows,
                isSelected: selectedSettingsPage === SettingsNestedPages.WORKFLOWS,
                cardName: 'approval-workflows',
              }
            : null;
        case SettingsCardIdEnum.NotificationPreferences:
          return notificationPreferencesEnabled && hasAccessToFirm
            ? {
                id: SettingsCardIdEnum.NotificationPreferences,
                iconType: 'notification',
                content: formatMessage('app.settings.companySection.cards.notificationPreferences.title'),
                onClick: goToSettingsNotificationPreferences,
                isSelected: selectedSettingsPage === SettingsNestedPages.NOTIFICATION_PREFERENCES,
                cardName: 'notification-preferences',
              }
            : null;
        case SettingsCardIdEnum.AccountingSoftwareSync:
          return isAccountingSoftwareEnabled &&
            hasAccessToFirm &&
            (can({ subject: 'accountingPlatform:sync', action: 'create' }) ||
              can({ subject: 'accountingPlatform', action: 'delete' }))
            ? {
                id: SettingsCardIdEnum.AccountingSoftwareSync,
                iconType: 'refresh',
                content: formatMessage('app.settings.companySection.cards.accounting.title'),
                onClick: goToSettingsAccountSoftware,
                isSelected: selectedSettingsPage === SettingsNestedPages.ACCOUNT_SOFTWARE,
                cardName: 'accounting-software',
              }
            : null;
        case SettingsCardIdEnum.PaymentMethodsSetting:
          return hasAccessToFirm
            ? {
                id: SettingsCardIdEnum.PaymentMethodsSetting,
                iconType: 'wallet',
                content: formatMessage('app.settings.BillingSection.cards.methods.title'),
                onClick: goToSettingsPaymentMethods,
                isSelected: selectedSettingsPage === SettingsNestedPages.PAYMENT_METHODS,
                cardName: 'payment-methods',
              }
            : null;
        case SettingsCardIdEnum.ReceivingMethodsSettings:
          return hasAccessToFirm &&
            (isShowReceivingMethodsItem || isArEnabled || isPureMSNOrganization) &&
            displayReceivingMethodSettingItem
            ? {
                id: SettingsCardIdEnum.ReceivingMethodsSettings,
                iconType: 'money-receive',
                content: formatMessage('app.settings.BillingSection.cards.receivingMethods.title'),
                onClick: goToReceivingMethods,
                isSelected: selectedSettingsPage === SettingsNestedPages.RECEIVING_METHODS,
                cardName: 'receiving-methods',
              }
            : null;
        case SettingsCardIdEnum.InvoiceItems:
          return {
            id: SettingsCardIdEnum.InvoiceItems,
            iconType: 'invoices',
            content: formatMessage('app.settings.BillingSection.cards.invoiceItems.title'),
            onClick: goToInvoiceItems,
            isSelected: selectedSettingsPage === SettingsNestedPages.INVOICE_ITEMS,
            cardName: 'invoice-items',
          };
        case SettingsCardIdEnum.InvoiceSettings:
          return isInvoiceSettingsEnabled && isArEnabled
            ? {
                id: SettingsCardIdEnum.InvoiceSettings,
                iconType: 'settings',
                content: formatMessage('app.settings.BillingSection.cards.invoiceSettings.title'),
                onClick: goToInvoicePreferences,
                isSelected: selectedSettingsPage === SettingsNestedPages.INVOICE_SETTINGS,
                cardName: 'invoice-settings',
              }
            : null;
        case SettingsCardIdEnum.InvoiceEmailNotifications:
          return isArEnabled
            ? {
                id: SettingsCardIdEnum.InvoiceEmailNotifications,
                iconType: 'notification',
                content: formatMessage('app.settings.BillingSection.cards.invoiceEmailNotifications.tab.title'),
                onClick: goToInvoiceEmailNotifications,
                isSelected: selectedSettingsPage === SettingsNestedPages.INVOICE_EMAIL_NOTIFICATIONS,
                cardName: SettingsNestedPages.INVOICE_EMAIL_NOTIFICATIONS,
              }
            : null;
        case SettingsCardIdEnum.SubscriptionPlansSettings:
          return hasAccessToSubscriptionSettings()
            ? {
                id: SettingsCardIdEnum.SubscriptionPlansSettings,
                iconType: 'crown',
                content: formatMessage(`app.settings.BillingSection.cards.subscriptionPlans.title`),
                onClick: goToSettingsSubscriptionPlans,
                isSelected: selectedSettingsPage === SettingsNestedPages.SUBSCRIPTION_PLANS,
                cardName: 'subscription-plans',
                analyticsProps: {
                  Cta: 'plans',
                  PageName: 'settings',
                  Intent: 'move-to-plans-screen',
                },
              }
            : null;
        case SettingsCardIdEnum.BillingSetting:
          return hasAccessToFirm
            ? {
                id: SettingsCardIdEnum.BillingSetting,
                iconType: 'billing-settings',
                content: formatMessage('app.settings.BillingSection.cards.billing.title'),
                onClick: goToSettingsBilling,
                isSelected: selectedSettingsPage === SettingsNestedPages.BILLING,
                cardName: 'billing',
              }
            : null;
        case SettingsCardIdEnum.SupportSetting:
          return {
            id: SettingsCardIdEnum.SupportSetting,
            iconType: 'chat',
            content: formatMessage('app.settings.SupportSection.cards.support.title'),
            onClick: goToSettingsSupport,
            isSelected: selectedSettingsPage === SettingsNestedPages.SUPPORT,
            cardName: 'support',
          };
        case SettingsCardIdEnum.TaxAndReportsSettings:
          return isTaxAndReportsEnabled
            ? {
                id: SettingsCardIdEnum.TaxAndReportsSettings,
                iconType: 'file-move',
                content: formatMessage('app.settings.companySection.cards.taxAndReports.title'),
                onClick: goToSettingsTaxAndReports,
                isSelected: selectedSettingsPage === SettingsNestedPages.TAX_AND_REPORTS,
                cardName: 'tax-and-reports',
              }
            : null;
      }
    },
    [
      formatMessage,
      goToSettingsProfile,
      selectedSettingsPage,
      hasAccessToFirm,
      goToSettingsCompany,
      accountManagerAccessCollaboratorsEnabled,
      can,
      goToSettingsCollaborators,
      approvalWorkflowsEnabled,
      isPaymentApprovalLimitEnabled,
      isAccountingSoftwareEnabled,
      goToSettingsWorkflows,
      notificationPreferencesEnabled,
      goToSettingsNotificationPreferences,
      goToSettingsAccountSoftware,
      goToSettingsPaymentMethods,
      isShowReceivingMethodsItem,
      isArEnabled,
      isTaxAndReportsEnabled,
      isPureMSNOrganization,
      displayReceivingMethodSettingItem,
      goToReceivingMethods,
      goToInvoiceItems,
      isInvoiceSettingsEnabled,
      goToInvoicePreferences,
      goToInvoiceEmailNotifications,
      hasAccessToSubscriptionSettings,
      goToSettingsSubscriptionPlans,
      goToSettingsBilling,
      goToSettingsSupport,
      goToSettingsTaxAndReports,
    ],
  );

  const settingsConfig = useMemo(
    () => getSettingsConfig(isArEnabled, isTaxAndReportsEnabled),
    [getSettingsConfig, isArEnabled, isTaxAndReportsEnabled],
  );

  const settingsGroups = useMemo(() => {
    return _compact(
      settingsConfig.map((settingsGroup) => {
        const { type, items } = settingsGroup;

        const title = settingsConfig.length > 1 ? settingGroupTypeToTitle[type] : undefined;
        const groupItems = _compact(items.map((item) => getSettingCardProps(item)));

        if (groupItems.length === 0) return null;

        return {
          title,
          type,
          settings: groupItems,
        };
      }),
    );
  }, [settingsConfig, settingGroupTypeToTitle, getSettingCardProps]);

  const groupsSettings = settingsGroups.flatMap((x) => x.settings);
  const selectedSettingCard = groupsSettings.find((setting) => setting.isSelected) || groupsSettings[0];
  const documentTitle = formatMessage('app.document.title.settings', { cardName: selectedSettingCard.content });

  useSetDocumentTitle(documentTitle);

  return (
    <Container overflow="initial" paddingTop={!isExtraSmallScreen && settingsGroups.length === 1 ? 'xxs' : 'none'}>
      <Group variant="vertical" as="nav" spacing="l">
        {settingsGroups.map((group: { title?: string; settings: SettingsCardProps[]; type?: SettingsGroupEnum }) => (
          <SettingsGroupWidget key={group.title} title={group.title} settings={group.settings} type={group.type} />
        ))}
      </Group>
    </Container>
  );
};

const SecondScreenProvider = ({ children }: { children: React.ReactNode }) => {
  const { isExtraSmallScreen } = useBreakpoint();

  const Wrapper = isExtraSmallScreen ? SystemMessageProvider : React.Fragment;

  return <Wrapper>{children}</Wrapper>;
};

export const SettingsScreen = () => {
  const location = useLocation();
  const { isExtraSmallScreen } = useBreakpoint();
  const selectedPage = useRecoilValue(settingsState);
  const setSelectedSettingsPage = useSettingsSelectedSettingsPage();
  const { isLoading } = useAccount({ id: 'me' });
  const { formatMessage } = usePlatformIntl();
  const {
    settings: { isEmbeddedExperience },
  } = useConfig();
  const [isAriaHidden, setIsAriaHidden] = useState(false);

  useEffect(
    () => setIsAriaHidden(isExtraSmallScreen && !!selectedPage.selectedSettingsPage),
    [selectedPage, isExtraSmallScreen],
  );

  useEffect(() => {
    const routeFound = Object.values(SettingsNestedPages).some((page) => {
      if (new RegExp(`^/settings/${page}(/.*)?$`).test(location.pathname)) {
        setSelectedSettingsPage(page);
        return true;
      }
    });
    if (!routeFound) {
      if (isExtraSmallScreen) setSelectedSettingsPage(null);
      else setSelectedSettingsPage(SettingsNestedPages.PROFILE);
    }
  }, [location.pathname, isExtraSmallScreen, setSelectedSettingsPage]);

  const secondScreen = (
    <WithLoading isLoading={isLoading}>
      <SecondScreenProvider>
        <Box flexDirection="column" position="absolute" width="100%" height="auto" maxWidth="100%" overflowX="initial">
          <Container
            overflow="initial"
            paddingX={isExtraSmallScreen ? 's' : 'none'}
            paddingY={isExtraSmallScreen ? 's' : 'none'}
          >
            <Outlet />
          </Container>
        </Box>
      </SecondScreenProvider>
    </WithLoading>
  );

  const settingsHeader = (
    <Container
      overflow="initial"
      paddingY={isExtraSmallScreen ? 'xs' : 'none'}
      paddingX={isExtraSmallScreen ? 's' : 'none'}
      aria-hidden={isAriaHidden}
    >
      <Group variant="vertical" justifyContent="center" alignItems="flex-start" spacing="s">
        <PageTitle textStyle="heading1Semi">{formatMessage('app.settings.title')}</PageTitle>
      </Group>
    </Container>
  );

  return (
    <SettingsProvider>
      <Container
        backgroundColor="white"
        height="full"
        overflow="initial"
        paddingX={isExtraSmallScreen ? 'none' : 'l'}
        paddingY={isExtraSmallScreen ? 'none' : 'l'}
      >
        {isEmbeddedExperience && (
          <Container paddingBottom={isExtraSmallScreen ? 's' : 'l'} aria-hidden={isAriaHidden} overflow="visible">
            <DashboardNavigation isHidden={isAriaHidden} />
          </Container>
        )}
        <Group data-testid="settings-screen" variant="vertical" height="full" spacing={isExtraSmallScreen ? 's' : 'l'}>
          {!isExtraSmallScreen && <SystemMessageDisplay data-testid="settings-notification" />}
          {settingsHeader}
          <ScreenSplitLayout
            variant="settings"
            {...(isExtraSmallScreen && { showScreenMobile: selectedPage.selectedSettingsPage ? 'second' : 'first' })}
            firstScreen={
              <Container overflow="initial" paddingRight={isExtraSmallScreen ? 'none' : 'l'}>
                <SettingsLayoutRoute />
              </Container>
            }
            secondScreen={secondScreen}
            hideSecondScreen={isExtraSmallScreen && !selectedPage.selectedSettingsPage}
          />
        </Group>
      </Container>
    </SettingsProvider>
  );
};
