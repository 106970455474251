export enum ExportFormat {
  CSV = 'CSV',
  IIF = 'IIF',
  QIF = 'QIF',
}

export type ExportFileFormWidgetFields = {
  fileFormatRadioValue: ExportFormat;
  toDate: Date;
  fromDate: Date;
  includePayments: boolean;
};

export const DAY = 24 * 60 * 60 * 1000;
