import { useModelApi, UseModelApiProps } from '@melio/api-client';
import { BillsApiClient } from '@melio/platform-api-axios-client';

type Props = UseModelApiProps<typeof BillsApiClient.getBillsBillIdAttachments>;
export const useBillAttachments = (props: Props) => {
  const query = useModelApi({
    ...props,
    queryKey: ['GetBillsBillIdAttachmentsApi', props.id],
    queryFn: BillsApiClient.getBillsBillIdAttachments,
  });

  return {
    ...query,
  };
};
