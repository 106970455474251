/* eslint-disable react-hooks/exhaustive-deps */
import { PlaidAccountData } from '@melio/platform-api';
import { useBoolean } from '@melio/platform-utils';
import { throttle } from 'lodash';
import { useMemo } from 'react';
import { PlaidLinkOnEvent, usePlaidLink } from 'react-plaid-link';

import { usePlaidLinkToken } from './usePlaidLinkToken';

export type UsePlaidProps = {
  onSuccess: (data: PlaidAccountData) => void;
  onExit?: VoidFunction;
  onLoad?: VoidFunction;
  onError?: ARErrorFunction;
};

export const usePlaid = ({ onError, onSuccess, onLoad, onExit }: UsePlaidProps) => {
  const handleError = (error?: ARPlatformError) => {
    onError?.(error ?? { code: '500', message: 'Could not create a bank account' });
    onExit?.();
  };
  const { data, isFetching } = usePlaidLinkToken({ onError: handleError });

  const [isPlaidLoading, isPlaidLoadingState] = useBoolean(true);

  const target = usePlaidLink({
    token: data?.linkToken || null,
    onLoad: () => {
      isPlaidLoadingState.off();
      onLoad?.();
    },
    onExit,
    onSuccess: (public_token, metadata) => onSuccess({ public_token, ...metadata } as PlaidAccountData),
    // plaid sends duplicate error events we need to throttle in order to not get multiple toast messages
    onEvent: throttle<PlaidLinkOnEvent>(
      (name, data) => {
        switch (name) {
          case 'EXIT':
            if (data.error_code) {
              return handleError();
            } else {
              onExit?.();
            }
            break;
          case 'ERROR':
            return handleError();
        }
      },
      100,
      { trailing: false }
    ),
  });
  const isLoading = useMemo(() => isFetching || isPlaidLoading, [isFetching, isPlaidLoading]);
  return useMemo(() => ({ ...target, isLoading }), [target, isLoading]);
};
