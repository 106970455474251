import { object, TestConfig } from 'yup';

import { useAccountNumberSchema, useRoutingNumberSchema } from '../../utils/aba-schema';

export type BankAccountFormModel = {
  routingNumber: string;
  accountNumber: string;
};

export type CustomValidation = TestConfig<Partial<BankAccountFormModel>>;

type UseAchValidationSchema = {
  customValidation?: CustomValidation;
};

export const useAchValidationSchema = (props?: UseAchValidationSchema) => {
  const basicSchema = object().shape({
    routingNumber: useRoutingNumberSchema(),
    accountNumber: useAccountNumberSchema(),
  });

  return props?.customValidation ? basicSchema.test(props.customValidation) : basicSchema;
};
