import { Collapsible, Container, Group, Icon, Text, Typography } from '@melio/penny';
import { Loan } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { InstallmentsTable } from './InstallmentsTable';

const NextInstallmentSection = ({ loan }: { loan: Loan }) => {
  const { formatMessage, formatDate } = useMelioIntl();

  if (loan.status !== 'active' || !loan.nextInstallmentDate) {
    return null;
  }

  return (
    <Group data-testid="next-installment-section" variant="vertical" spacing="xxs">
      <Typography.Label label={formatMessage('widgets.paymentDetails.payFrom.loan.installments.title')} />
      <Text>{formatDate(loan.nextInstallmentDate, { dateStyle: 'medium' })}</Text>
    </Group>
  );
};

const TotalFeesSection = ({ loan }: { loan: Loan }) => {
  const { formatMessage, formatCurrency } = useMelioIntl();

  return (
    <Group variant="vertical" spacing="xxs">
      <Group variant="horizontal" justifyContent="space-between">
        <Text textStyle="body3Semi">
          {formatMessage('widgets.paymentDetails.payFrom.loan.installments.table.totalAmount')}
        </Text>
        <Text textStyle="body3Semi">{formatCurrency(loan.fees.totalAmount || 0)}</Text>
      </Group>
      <Group variant="horizontal" justifyContent="space-between">
        <Text textStyle="body3">
          {formatMessage('widgets.paymentDetails.payFrom.loan.installments.table.totalFees', {
            percent: loan.fees.financingTotalFeePercent,
          })}
        </Text>
        <Text textStyle="body3">{formatCurrency(loan.fees.financingTotalFee || 0)}</Text>
      </Group>
    </Group>
  );
};

export const LoanDetails = ({ loan }: { loan: Loan }) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Group>
      <Icon size="large" type="scheduled" />
      <Group variant="vertical">
        <NextInstallmentSection loan={loan} />
        <Collapsible
          label={formatMessage('widgets.paymentDetails.payFrom.loan.installments.table.title')}
          data-testid="repayment-section-installments-list-toggle"
          defaultIsExpanded
        >
          <InstallmentsTable loan={loan} />
        </Collapsible>
        <Container paddingRight="s">
          <TotalFeesSection loan={loan} />
        </Container>
      </Group>
    </Group>
  );
};
