import { Container } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';
import { PaymentFlowDoneAction } from '../../../../types';

type NotifyVendorsActionProps = {
  onDone: (action: PaymentFlowDoneAction) => void;
  onNotifyVendors: VoidFunction;
  vendorsIdsWithoutEmail: Vendor['id'][];
  isNewSuccessScreen?: boolean;
};

export const NotifyVendorsAction = ({
  onDone,
  onNotifyVendors,
  vendorsIdsWithoutEmail,
  isNewSuccessScreen,
}: NotifyVendorsActionProps) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const notifyVendorProps = {
    onClick: () => {
      track('Payment', 'Click', {
        PageName: 'payment-scheduled',
        Intent: 'notify-my-vendors',
        Cta: 'notify-my-vendors',
      });
      onNotifyVendors();
    },
    'data-testid': 'notify-vendors-button',
    label: formatMessage(
      `activities.paymentScheduled.screens.paymentScheduled.notify.${
        vendorsIdsWithoutEmail.length > 1 ? 'multipleVendors' : 'singleVendor'
      }`
    ),
  };

  const continueProps = {
    onClick: () => {
      track('Payment', 'Click', {
        PageName: 'payment-scheduled',
        Intent: 'go-to-dashboard',
        Cta: 'go-to-dashboard',
      });
      onDone('goToDashboard');
    },
    label: formatMessage('activities.paymentScheduled.screens.paymentScheduled.continue'),
  };

  if (isNewSuccessScreen) {
    return (
      <>
        <NewSinglePaymentStepLayout.NextButton {...continueProps} variant="tertiary" size="medium" />
        <NewSinglePaymentStepLayout.NextButton {...notifyVendorProps} size="medium" />
      </>
    );
  }

  return (
    <>
      <NewSinglePaymentStepLayout.NextButton {...notifyVendorProps} />
      <Container justifyContent="center">
        <NewSinglePaymentStepLayout.NakedNextButton {...continueProps} />
      </Container>
    </>
  );
};
