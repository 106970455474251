import { CalculatedField, InvoiceServiceCharge } from '@melio/ar-domain';

export const getMappedServiceCharges = ({
  summaryInvoiceServiceCharges,
  invoiceServiceCharges,
}: {
  summaryInvoiceServiceCharges: Array<CalculatedField>;
  invoiceServiceCharges: Array<InvoiceServiceCharge>;
}) =>
  invoiceServiceCharges.map((charge) => {
    const matchingSummaryCharge = summaryInvoiceServiceCharges.find((summaryCharge) => charge.id === summaryCharge.id);

    return {
      name: charge.name,
      id: charge.id,
      rate: charge.type === 'percentage' ? charge.value : undefined,
      calculateValue: matchingSummaryCharge?.calculateValue || 0,
    };
  });
