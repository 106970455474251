import { Form, FormSelectProps } from '@melio/penny';
import { AccountingPlatformItem } from '@melio/platform-api';

import { useAccountingPlatformItemSelect } from '../../hooks/useAccountingPlatformItemSelect';

export type AccountingPlatformItemSelectProps = {
  value?: AccountingPlatformItem['id'];
  'data-testid'?: string;
} & Omit<FormSelectProps, 'loadOptions' | 'formatCreateLabel' | 'onCreateOption' | 'options' | 'emptyState'>;

export const AccountingPlatformItemSelect = ({
  'aria-label': rawAriaLabel,
  'data-testid': testId = 'account-platform-item-select',
  labelProps,
  ...props
}: AccountingPlatformItemSelectProps) => {
  const { isFetched, options } = useAccountingPlatformItemSelect({ testIdPrefix: testId });

  const label = labelProps ? { labelProps, 'aria-label': rawAriaLabel ?? '' } : { 'aria-label': rawAriaLabel ?? '' };
  return (
    <Form.Select
      data-component="AccountingPlatformItemSelect"
      data-testid={testId}
      options={options}
      isLoading={!isFetched}
      emptyState={undefined}
      {...props}
      {...label}
    />
  );
};
