import { Box } from '@chakra-ui/react';
import { AccountSwitcherWidget, AccountSwitcherWidgetsProps } from '@melio/ap-widgets';
import { useAnalytics } from '@melio/platform-analytics';
import { useAccountsWithLogoUrl, useAccountWithLogoUrl } from '@melio/platform-api';

import { useSwitchAccount } from '@/hooks/useSwitchAccount.hooks';

export const AccountSelectWidget = () => {
  const { track } = useAnalytics();
  const { switchAccount } = useSwitchAccount();
  const { data: accountWithLogoId, isFetching: isAccountWithLogoLoading } = useAccountWithLogoUrl({ id: 'me' });
  const { data: accounts = [], isLoading: isAccountsLoading } = useAccountsWithLogoUrl();

  const onSelectHandler: AccountSwitcherWidgetsProps['onSelect'] = async (account) => {
    track('SwitchCompanyFromCompanyBar', 'Chose');
    if (account.id === accountWithLogoId?.id) {
      return;
    }
    await switchAccount(account.id);
  };

  return (
    <Box>
      {!isAccountWithLogoLoading && !isAccountsLoading && accountWithLogoId && (
        <AccountSwitcherWidget selectedId={accountWithLogoId.id} data={accounts} onSelect={onSelectHandler} />
      )}
    </Box>
  );
};
