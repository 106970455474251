import { useMelioIntl } from '@melio/platform-i18n';
import { usePermissions } from '@melio/platform-permissions';
import { useConfig } from '@melio/platform-provider';

export const useManageApprovalsButtonLabel = () => {
  const { formatMessage } = useMelioIntl();
  const {
    settings: { isPaymentApprovalLimitEnabled },
  } = useConfig();

  const { can } = usePermissions();
  const canCreateWorkflows = can({ action: 'create', subject: 'approvalWorkflow' });

  const getManageWorkflowsLinkLabel = () => {
    if (isPaymentApprovalLimitEnabled && !canCreateWorkflows) {
      return formatMessage('activities.payDashboard.approvalsTab.viewApprovalWorkflows');
    }
    return formatMessage('activities.payDashboard.approvalsTab.manageApprovalWorkflows');
  };

  return { buttonLabel: getManageWorkflowsLinkLabel() };
};
