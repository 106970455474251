import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils';

type MessageKey = Parameters<ReturnType<typeof useMelioIntl>['formatMessage']>[0];

type Props = {
  successLabel: MessageKey;
  errorLabel: MessageKey;
  isInfo?: boolean;
  onSuccess?: VoidFunction;
  onError?: VoidFunction;
};

export const useAsyncActionWithToast = ({ errorLabel, successLabel, isInfo, onError, onSuccess }: Props) => {
  const { showMessage } = useSystemMessage();
  const { formatMessage } = useMelioIntl();

  const doAction = async (action: () => Promise<unknown>) => {
    try {
      await action();
      showMessage({
        type: isInfo ? 'informative' : 'success',
        title: formatMessage(successLabel),
      });
      onSuccess?.();
    } catch (error) {
      showMessage({ type: 'error', title: formatMessage(errorLabel) });
      onError?.();
    }
  };

  return doAction;
};
