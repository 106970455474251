import { useAnalytics } from '@melio/platform-analytics';
import { FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils/system-message';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Plan, SubscriptionBillingCycleEnum, useSubscriptionMe } from '../../../api';
import { useSubscriptionContext } from '../../../context';
import { usePartnerGroups, usePlanInfo, useRedirects, useSubscription } from '../../../hooks';

type UseUpgradeSubscriptionProps = {
  planId: Plan['id'];
  returnUrl?: string;
  onClose: () => void;
  onDismiss?: () => void;
  billingCycle: SubscriptionBillingCycleEnum;
};

export const useUpgradeSubscription = ({
  planId,
  returnUrl,
  onClose,
  onDismiss,
  billingCycle,
}: UseUpgradeSubscriptionProps) => {
  const { track } = useAnalytics();
  const subscription = useSubscription();
  const { isFiservPartner } = usePartnerGroups();
  const navigate = useNavigate();
  const { goToSuccessPage, goToSettingsPage } = useRedirects();
  const { showMessage } = useSystemMessage();
  const { formatMessage } = useMelioIntl();
  const { planName } = usePlanInfo(planId);
  const [upgradeError, setUpgradeError] = useState(false);

  const { update: updateSubscription, isMutating: isUpgradingSubscription } = useSubscriptionMe({ enabled: false });

  const {
    contextualPaywall: { paywallProps },
  } = useSubscriptionContext();

  const navigateOnSuccess = () => {
    if (returnUrl) {
      return navigate(returnUrl);
    }

    isFiservPartner ? goToSettingsPage() : goToSuccessPage();
  };

  const successHandler = () => {
    navigateOnSuccess();

    if (isFiservPartner) {
      const showMessageMethod = paywallProps?.showSystemMessageHandler || showMessage;

      showMessageMethod({
        type: 'success',
        title: formatMessage('activities.subscription.upgradeModal.systemMessage.success', {
          planName,
        }),
        dataTestId: 'subscription-upgrade-success',
      });
    }

    onClose();
  };

  const errorHandler = () => {
    setUpgradeError(true);
  };

  const trackUpgradeStatus = ({ statusType }: { statusType: 'success' | 'failure' }) => {
    track('Organization', 'Status', {
      Flow: 'subscription',
      Intent: 'upgrade-plan',
      StatusType: statusType,
      Cta: 'subscribe',
      NumberOfChosenSeats: subscription?.measurementInfo.quantity,
      PlanId: planId,
      PlanName: planName.toLowerCase(),
      BillingCycle: subscription?.planCyclePeriod,
      ...(statusType === 'failure' && { ErrorType: formatMessage('activities.subscription.upgradeModal.error') }),
    });
  };

  const upgradeSubscription = (fundingSourceId?: FundingSource['id']) => {
    track('Organization', 'Click', {
      Intent: 'confirm-upgrade',
      Cta: 'confirm-upgrade',
      fundingSourceField: fundingSourceId,
    });

    void updateSubscription({
      planId,
      planCyclePeriod: billingCycle,
      ...(fundingSourceId !== subscription?.fundingSourceId && { fundingSourceId }),
    })
      .then(() => {
        trackUpgradeStatus({ statusType: 'success' });

        successHandler();
      })
      .catch(() => {
        trackUpgradeStatus({ statusType: 'failure' });

        errorHandler();
      });
  };

  const dismiss = (closeType: string) => {
    onDismiss?.();
    onClose();
    track('Organization', 'Click', {
      Intent: closeType,
      Cta: closeType,
    });
  };

  return { upgradeError, upgradeSubscription, isUpgradingSubscription, dismiss };
};
