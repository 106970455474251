import { Group, Text, useBreakpoint } from '@melio/penny';
import { Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { PaymentSummary, SummaryTypeEnum } from '../../../../PaymentSummary';

type Props = {
  vendor: Vendor;
  linkToUnpaid?: string;
};

const PAYMENTS_SUM_FOR_VENDOR_WITH_MISSING_INFO = 0;
const PAYMENTS_COUNT_FOR_VENDOR_WITH_MISSING_INFO = 0;

export const MissingVendorInfoDrawerPaymentsOverview = ({ vendor, linkToUnpaid }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { isExtraSmallScreen } = useBreakpoint();
  const { paymentsOverview } = vendor;

  return (
    <Group
      variant="vertical"
      spacing="m"
      data-testid="missing-vendor-info-drawer-payments-overview"
      data-component="MissingVendorInfoDrawerPaymentsOverview"
    >
      <Group justifyContent="space-between">
        <Text as="h3" textStyle="heading3Semi">
          {formatMessage('widgets.paymentOverview.title')}
        </Text>
      </Group>
      <Group
        as="ul"
        spacing="m"
        hasDivider={!isExtraSmallScreen}
        variant={isExtraSmallScreen ? 'vertical' : 'horizontal'}
      >
        <PaymentSummary
          summaryType={SummaryTypeEnum.OPEN_BALANCE}
          sum={paymentsOverview?.unpaid.sum}
          count={paymentsOverview?.unpaid.count}
          vendorName={vendor.name}
          link={linkToUnpaid}
          showNotAvailable={vendor.eBillStatus === 'pending' && paymentsOverview?.unpaid.sum === 0}
          tooltipText={
            vendor.eBillStatus === 'pending'
              ? formatMessage('widgets.paymentOverview.openBalance.tooltip.pendingEBill')
              : undefined
          }
          currency={vendor.currency}
        />
        <PaymentSummary
          summaryType={SummaryTypeEnum.SCHEDULED}
          sum={PAYMENTS_SUM_FOR_VENDOR_WITH_MISSING_INFO}
          count={PAYMENTS_COUNT_FOR_VENDOR_WITH_MISSING_INFO}
          currency={vendor.currency}
        />
        <PaymentSummary
          summaryType={SummaryTypeEnum.PAID}
          sum={PAYMENTS_SUM_FOR_VENDOR_WITH_MISSING_INFO}
          count={PAYMENTS_COUNT_FOR_VENDOR_WITH_MISSING_INFO}
          currency={vendor.currency}
        />
      </Group>
    </Group>
  );
};

MissingVendorInfoDrawerPaymentsOverview.displayName = 'MissingVendorInfoDrawerPaymentsOverview';
