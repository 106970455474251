import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { BatchPaymentsActivity, PaymentFlowDoneAction } from '@melio/ap-activities';
import { useBills } from '@melio/platform-api';

import { Loader } from '@/cl/components/Loader/Loader.component';
import { getSessionStorageItem } from '@/utils/sessionStorage.utils';
import { useRouter } from '../../hooks/router.hooks';
import { openInNewTab } from './externalEntries.utils';

export const ExternalEntryBatchPaymentsScreen = ({ billOriginIds }: { billOriginIds: string[] }) => {
  const { generateNPEDashboardLink, generateExternalEntriesFallbackErrorLink } = useRouter();
  const { state } = useLocation();
  const { returnUrl } = (state as { returnUrl?: string }) || {};
  const externalOrigin = getSessionStorageItem('externalOrigin');

  const navigate = useNavigate();

  const { data: bills, isLoading: isBillsDataLoading } = useBills({
    params: { search: { 'bill.originId': billOriginIds, 'bill.origin': 'intuit' } },
  });

  const handleDone = (_paymentIds: string[], action: PaymentFlowDoneAction = 'openInNewTabDashboard') => {
    if (action === 'openInNewTabDashboard') {
      openInNewTab(generateNPEDashboardLink());
    }
  };

  const handleBack = () => {
    if (returnUrl) {
      navigate(returnUrl);
    } else {
      openInNewTab(generateNPEDashboardLink());
    }
  };

  if (billOriginIds.length === 0) {
    return <Navigate to={generateExternalEntriesFallbackErrorLink()} />;
  }

  if (isBillsDataLoading) {
    return <Loader isAbsoluteCenter />;
  }

  if (bills && bills?.length > 0) {
    return (
      <BatchPaymentsActivity
        billIds={bills.map((bill) => bill.id)}
        onDone={handleDone}
        onBack={handleBack}
        externalOrigin={externalOrigin}
      />
    );
  }

  return <Navigate to={generateExternalEntriesFallbackErrorLink()} />;
};
