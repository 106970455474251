import { useState } from 'react';

import { AddBankAccountMicroDepositsActivityProps, AddBankAccountMicroDepositsStep } from './types';

export const useAddBankAccountMicroDepositsStep = ({
  onFirstStepBack,
}: {
  onFirstStepBack: AddBankAccountMicroDepositsActivityProps['onBack'];
}) => {
  const [currentStep, goToStep] = useState<AddBankAccountMicroDepositsStep>('ADD_ACH_MICRO_DEPOSITS');

  const goToPreviousStep = () => {
    currentStep === 'ACH_MICRO_DEPOSITS_PENDING_VERIFICATION' ? goToStep('ADD_ACH_MICRO_DEPOSITS') : onFirstStepBack();
  };

  return { currentStep, goToStep, goToPreviousStep };
};
