import { BrandSymbol, BrandSymbolKey, Card, Container, Group, Icon, ListItem, Loader } from '@melio/penny';
import { AccountingPlatform, AccountingPlatformSlug } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export type AddBillMethodSelectionWidgetProps = {
  onConnect: (testId: string, accountingPlatformSlug: AccountingPlatformSlug) => void;
  isLoading?: boolean;
  accountingPlatform: AccountingPlatform;
  shouldShowRightArrow?: boolean;
  isDisabled?: boolean;
};

export const AccountingPlatformCard = ({
  isLoading,
  accountingPlatform,
  onConnect,
  shouldShowRightArrow = false,
  isDisabled,
  ...props
}: AddBillMethodSelectionWidgetProps) => {
  const { formatMessage } = useMelioIntl();
  const accountingPlatformTextMapping = {
    [AccountingPlatformSlug.QuickBooksOnline]: {
      component: 'ConnectWithQuickbooksCard',
      testId: 'sync-with-quickbooks-card',
      analyticsCta: 'sync-with-quickbooks',
      icon: 'quickbooks' as BrandSymbolKey,
      mainLabel: formatMessage('widgets.addBillMethodSelection.connectQuickbooks.content'),
      description: formatMessage('widgets.addBillMethodSelection.connectQuickbooks.helperText'),
    },
    [AccountingPlatformSlug.QuickBooksDesktop]: {
      component: 'ConnectWithQuickbooksDesktopCard',
      testId: 'sync-with-quickbooks-desktop-card',
      analyticsCta: 'sync-with-quickbooks-desktop',
      icon: 'quickbooks' as BrandSymbolKey,
      mainLabel: formatMessage('widgets.addBillMethodSelection.connectQuickbooksDesktop.content'),
      description: formatMessage('widgets.addBillMethodSelection.connectQuickbooksDesktop.helperText'),
    },
    [AccountingPlatformSlug.Xero]: {
      component: 'ConnectWithXeroCard',
      testId: 'sync-with-xero-card',
      analyticsCta: 'sync-with-xero',
      icon: 'xero-logo' as BrandSymbolKey,
      mainLabel: formatMessage('widgets.addBillMethodSelection.connectXero.content'),
      description: formatMessage('widgets.addBillMethodSelection.connectXero.helperText'),
    },
    [AccountingPlatformSlug.QuickBooksDesktopInApp]: null,
    [AccountingPlatformSlug.FreshBooks]: null,
    [AccountingPlatformSlug.DynamicsBusinessCentral]: null,
  };

  const textMapping = accountingPlatformTextMapping[accountingPlatform.accountingSlug];

  if (!textMapping) {
    return null;
  }

  const LoadingCard = (
    <Card>
      <Container paddingY="s" alignItems="center" justifyContent="center">
        <Loader />
      </Container>
    </Card>
  );

  if (isLoading) {
    return LoadingCard;
  }

  return (
    <Card
      onClick={() => onConnect(textMapping.analyticsCta, accountingPlatform.accountingSlug)}
      data-component={textMapping.component}
      data-testid={textMapping.testId}
      isDisabled={isDisabled}
      {...props}
    >
      <Group>
        <Group.Item shrink={0}>
          <Container width="fit-content" alignItems="center" height="full">
            <BrandSymbol isDisabled={isDisabled} type={textMapping.icon} />
          </Container>
        </Group.Item>
        <Group.Item grow={1}>
          <Container>
            <ListItem
              isDisabled={isDisabled}
              descriptionProps={{ label: textMapping.description }}
              mainLabelProps={{
                label: textMapping.mainLabel,
                variant: 'bold',
              }}
            />
          </Container>
        </Group.Item>
        {shouldShowRightArrow && (
          <Group.Item shrink={0}>
            <Container width="fit-content" alignItems="center" height="full">
              <Icon isDisabled={isDisabled} type="chevron-right" />
            </Container>
          </Group.Item>
        )}
      </Group>
    </Card>
  );
};

AccountingPlatformCard.displayName = 'AccountingPlatformCard';
