import React from 'react';
import { datadogRum } from '@datadog/browser-rum';
import noop from 'lodash/noop';

const DashboardMonitoring = React.createContext<{ onReady: VoidFunction }>({ onReady: noop });
export const useDashboardMonitoring = () => React.useContext(DashboardMonitoring);

export function DashboardMonitoringProvider({ children }: { children: React.ReactNode }) {
  const wasReadinessReported = React.useRef(false);

  const events = React.useMemo(
    () => ({
      onReady: () => {
        if (wasReadinessReported.current) return;
        datadogRum.addTiming('dashboard_ready');
        wasReadinessReported.current = true;
      },
    }),
    [],
  );

  return <DashboardMonitoring.Provider value={events}>{children}</DashboardMonitoring.Provider>;
}
