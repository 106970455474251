import { getIsChangingDeliveryMethodIsDisabled, RECURRING_NUM_OF_OCCURRENCES_REGEX } from '@melio/ap-domain';
import { FormWidgetProps } from '@melio/ap-widgets';
import { Form, useMelioForm, useWatch } from '@melio/penny';
import { BillSubscriptionEndPolicyEnum, useDeliveryMethod, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useDateUtils, useDebounce } from '@melio/platform-utils';
import { addDays } from 'date-fns';
import { useEffect, useState } from 'react';

import { MonitoredAction } from '../../../../../monitoring';
import { EditableData, ExpandedBillSubscription } from '../../../types';
import {
  getReleventFields,
  useDeliveryMethodsOptions,
  useEndDate,
  useEndPolicy,
  useFundingSourcesOptions,
  useNumOfOccurrences,
  useRecurringFrequencies,
} from './EditBillSubscriptionForm.utils';
import { EditBillSubscriptionFormFields } from './types';
import { useEditBillSubscriptionSchema } from './useEditBillSubscriptionSchema';

const DEBOUNCE = 500;

export type EditBillSubscriptionFormProps = FormWidgetProps<EditBillSubscriptionFormFields> & {
  uneditablePaymentsNumber?: number;
  onChangeField: (changes: EditableData) => void;
  onCreateFundingSource: VoidFunction;
  onCreateDeliveryMethod: VoidFunction;
  billSubscriptionManagedBy: ExpandedBillSubscription['managedBy'];
  billSubscriptionInvoiceNumber: ExpandedBillSubscription['invoiceNumber'];
  billSubscriptionId: ExpandedBillSubscription['id'];
  vendor: ExpandedBillSubscription['vendor'];
};

export const EditBillSubscriptionForm = ({
  uneditablePaymentsNumber,
  onSubmit,
  defaultValues,
  isSaving,
  onSubmissionStateChange,
  vendor,
  onChangeField,
  billSubscriptionManagedBy,
  billSubscriptionId,
  billSubscriptionInvoiceNumber,
  onCreateFundingSource,
  onCreateDeliveryMethod,
  ...props
}: EditBillSubscriptionFormProps) => {
  const { formatMessage } = useMelioIntl();
  const { routeReady } = useMonitoring<MonitoredAction>();
  const [endPolicy, setEndPolicy] = useState(defaultValues?.endPolicy);

  const { setValue, watch, formProps, registerField, control } = useMelioForm<EditBillSubscriptionFormFields>({
    onSubmit,
    schema: useEditBillSubscriptionSchema(endPolicy, uneditablePaymentsNumber),
    defaultValues,
    isSaving,
    onSubmissionStateChange,
  });

  const recurringFrequenciesOptionsProps = useRecurringFrequencies();
  const endPolicyOptionsProps = useEndPolicy();

  const { createDate } = useDateUtils();

  const watchEndPolicy = watch('endPolicy');
  const watchStartDate = watch('startDate');
  const watchEndDate = watch('endDate');
  const watchIntervalType = watch('intervalType');
  const watchNumOfOccurrences = watch('numOfOccurrences');
  const watchDeliveryMethodId = watch('deliveryMethodId');
  const watchFundingSourceId = watch('fundingSourceId');

  const relevantFields = getReleventFields(endPolicy);

  const watchFormValues = useWatch({
    control,
  });
  const debouncedWatchFormValues = useDebounce(watchFormValues, DEBOUNCE);

  useEffect(() => {
    onChangeField(debouncedWatchFormValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedWatchFormValues]);

  useEffect(() => {
    if (watchEndPolicy === BillSubscriptionEndPolicyEnum.EndDate) {
      setEndPolicy(BillSubscriptionEndPolicyEnum.EndDate);
      setValue('numOfOccurrences', null);
    } else if (watchEndPolicy === BillSubscriptionEndPolicyEnum.NumOfOccurrences) {
      setEndPolicy(BillSubscriptionEndPolicyEnum.NumOfOccurrences);
      setValue('endDate', null);
    } else if (watchEndPolicy === BillSubscriptionEndPolicyEnum.NoEndDate) {
      setEndPolicy(BillSubscriptionEndPolicyEnum.NoEndDate);
      setValue('numOfOccurrences', null);
      setValue('endDate', null);
      setValue('lastAmount', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchEndPolicy]);

  const { helperText: endDateHelperText } = useEndDate({
    paymentFrequency: watchIntervalType,
    startDate: watchStartDate,
    endDate: watchEndDate || undefined,
    managedBy: billSubscriptionManagedBy,
    uneditablePaymentsNumber,
  });

  const { helperText: numOfOccurrencesHelperText } = useNumOfOccurrences({
    uneditablePaymentsNumber,
    paymentFrequency: watchIntervalType,
    startDate: watchStartDate,
    managedBy: billSubscriptionManagedBy,
    numOfOccurrences: watchNumOfOccurrences || undefined,
  });

  const { options: deliveryMethodsOptions, isLoading: isDeliveryMethodsLoading } = useDeliveryMethodsOptions({
    vendor: vendor as Vendor,
  });

  const { isLoading: isLoadingFundingSources, options: fundingSourcesOptions } = useFundingSourcesOptions({
    startDate: watchStartDate,
    amount: debouncedWatchFormValues.amount,
    vendorId: vendor.id,
    deliveryMethodId: watchDeliveryMethodId,
    fundingSourceId: watchFundingSourceId,
    billSubscriptionId,
    billSubscriptionInvoiceNumber,
  });

  const { data: currentDeliveryMethod } = useDeliveryMethod({ id: defaultValues?.deliveryMethodId });

  const canUpdatePaymentMethod = !billSubscriptionManagedBy;
  const canUpdateDeliveryMethod =
    !vendor.isManaged &&
    !billSubscriptionManagedBy &&
    currentDeliveryMethod &&
    !getIsChangingDeliveryMethodIsDisabled(currentDeliveryMethod);

  return (
    <Form
      size="small"
      data-component="EditBillSubscriptionForm"
      data-testid="edit-bill-subscription-form"
      columns={2}
      ref={routeReady}
      {...formProps}
      {...props}
    >
      <Form.AmountField
        labelProps={{
          label: formatMessage('activities.editBillSubscription.screens.editBillSubscription.form.billAmount.label'),
        }}
        {...registerField('amount')}
      />
      <Form.Select
        labelProps={{
          label: formatMessage('activities.editBillSubscription.screens.editBillSubscription.form.intervalType.label'),
        }}
        {...registerField('intervalType')}
        options={recurringFrequenciesOptionsProps}
        emptyState={undefined}
      />
      <Form.DateField
        {...registerField('startDate')}
        minDate={addDays(createDate(), 1)}
        excludeHolidays
        labelProps={{
          label: formatMessage('activities.editBillSubscription.screens.editBillSubscription.form.startDate.label'),
        }}
        maxDate={watchEndDate || undefined}
      />
      <Form.Select
        labelProps={{
          label: formatMessage('activities.editBillSubscription.screens.editBillSubscription.form.endPolicy.label'),
        }}
        {...registerField('endPolicy')}
        options={endPolicyOptionsProps}
        emptyState={undefined}
      />
      <Form.DateField
        {...registerField('endDate')}
        minDate={watchStartDate || createDate()}
        isHidden={!relevantFields.includes('endDate')}
        excludeHolidays
        labelProps={{
          label: formatMessage('activities.editBillSubscription.screens.editBillSubscription.form.endDate.label'),
        }}
        helperTextProps={{ label: endDateHelperText }}
      />
      <Form.TextField
        labelProps={{
          label: formatMessage(
            'activities.editBillSubscription.screens.editBillSubscription.form.numOfOccurrences.label'
          ),
        }}
        placeholder={formatMessage(
          'activities.editBillSubscription.screens.editBillSubscription.form.numOfOccurrences.placeholder'
        )}
        isHidden={!relevantFields.includes('numOfOccurrences')}
        maskProps={{ mask: RECURRING_NUM_OF_OCCURRENCES_REGEX }}
        helperTextProps={{ label: numOfOccurrencesHelperText }}
        {...registerField('numOfOccurrences')}
      />
      <Form.AmountField
        labelProps={{
          label: formatMessage('activities.editBillSubscription.screens.editBillSubscription.form.lastAmount.label'),
        }}
        {...registerField('lastAmount')}
        isHidden={!relevantFields.includes('lastAmount')}
      />
      <Form.Select
        labelProps={{
          label: formatMessage('activities.editBillSubscription.screens.editBillSubscription.form.fundingSource.label'),
        }}
        {...registerField('fundingSourceId')}
        options={fundingSourcesOptions}
        emptyState={undefined}
        isReadOnly={!canUpdatePaymentMethod}
        isLoading={isLoadingFundingSources}
        creatableOption={{
          label: formatMessage(
            'activities.editBillSubscription.screens.editBillSubscription.form.fundingSource.create'
          ),
          onClick: onCreateFundingSource,
          shouldDisplay: () => true,
        }}
      />
      <Form.Select
        labelProps={{
          label: formatMessage(
            'activities.editBillSubscription.screens.editBillSubscription.form.deliveryMethod.label'
          ),
        }}
        {...registerField('deliveryMethodId')}
        options={deliveryMethodsOptions}
        emptyState={undefined}
        isReadOnly={!canUpdateDeliveryMethod}
        isLoading={isDeliveryMethodsLoading}
        creatableOption={{
          label: formatMessage(
            'activities.editBillSubscription.screens.editBillSubscription.form.deliveryMethod.create'
          ),
          onClick: onCreateDeliveryMethod,
          shouldDisplay: () => true,
        }}
      />
      <Form.TextField
        colSpan={2}
        labelProps={{
          label: formatMessage('activities.editBillSubscription.screens.editBillSubscription.form.memoToVendor.label'),
        }}
        {...registerField('memoToVendor')}
      />
    </Form>
  );
};
