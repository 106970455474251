import { InvoiceImageProps } from '@/cl/components/InvoiceImage/InvoiceImage.component';
import { ComponentThemeType } from '@/cl/theme/theme.hooks';

export const InvoiceImageTheme: ComponentThemeType<InvoiceImageProps> = (currentTheme, props) => ({
  baseStyle: {
    container: {
      width: '176px',
      height: { xs: '100%', s: '224px' } as never,
      position: 'relative',
    },
    imageContainer: {
      position: 'relative',
      border: 'basic.light',
      borderRadius: '8px',
      h: 'fit-content',
      overflow: 'hidden',
      boxShadow: '0px 5px 15px 0px rgba(33, 33, 36, 0.1)',
      cursor: props.editMode || props.file?.url ? 'pointer' : 'initial',
      maxHeight: '100%',
      maxWidth: '100%',
      display: 'flex',
      justifyContent: 'center',
      _hover: {
        boxShadow: '0px 5px 15px 0px rgba(33, 33, 36, 0.2)',
      },
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: props.file ? 'space-between' : 'center',
      marginTop: '16px',
    },
    iconContainer: {
      position: 'absolute',
      top: '16px',
      right: '16px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      height: '20px',
      padding: '0px 3px',
      borderRadius: '4px',
      backgroundColor: currentTheme.colors.global.neutral['600'],
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '20px',
      color: currentTheme.colors.global.neutral['100'],
      visibility: props.editMode ? 'hidden' : 'visible',
      _groupHover: {
        backgroundColor: currentTheme.colors.global.neutral['800'],
      },
    },
  },
});
