import { useCustomer, useMelioIntl } from '@melio/ar-domain';
import { StatusModal, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export type DeleteCustomerModalActivityProps = {
  isOpen: boolean;
  customerId?: string;
  onClose: VoidFunction;
  onDone: (name: string) => void;
  onError?: ARErrorFunction;
};

export const DeleteCustomerModalActivity = forwardRef<DeleteCustomerModalActivityProps>(
  ({ isOpen, customerId, onClose, onDone: _onDone, onError, ...props }, ref) => {
    const {
      data: customer,
      isLoading,
      delete: deleteCustomer,
      isMutating: isDeletingCustomer,
    } = useCustomer({ id: customerId, onError });
    const { formatMessage } = useMelioIntl();
    const onDeleteCustomer = () => {
      const onDone = () => _onDone(customer?.companyName ?? '');
      deleteCustomer().then(onDone).catch(onError);
    };

    return (
      <StatusModal
        variant="alert"
        header={formatMessage('ar.dashboard.activities.deleteCustomer.confirmationModal.header')}
        isOpen={isOpen}
        isLoading={isLoading}
        onClose={() => !isDeletingCustomer && onClose()}
        secondaryButton={{
          label: formatMessage('ar.dashboard.activities.deleteCustomer.confirmationModal.buttons.secondary.label'),
          onClick: onClose,
          variant: 'tertiary',
          isDisabled: isDeletingCustomer,
        }}
        primaryButton={{
          label: formatMessage('ar.dashboard.activities.deleteCustomer.confirmationModal.buttons.primary.label'),
          onClick: onDeleteCustomer,
          variant: 'primary',
          isLoading: isDeletingCustomer,
        }}
        data-testid="delete-customer-modal"
        data-component={DeleteCustomerModalActivity.displayName}
        {...props}
        ref={ref}
      >
        <Text>
          {formatMessage('ar.dashboard.activities.deleteCustomer.confirmationModal.description', {
            customerName: customer?.companyName,
          })}
        </Text>
      </StatusModal>
    );
  }
);
DeleteCustomerModalActivity.displayName = 'DeleteCustomerModal';
