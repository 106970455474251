import { Account, CompanyLegalInfoFields, PatchAccountsAccountIdRequest, TaxIdTypeEnum } from '@melio/platform-api';
import { identity, pickBy } from 'lodash';

type Company = NonNullable<PatchAccountsAccountIdRequest['company']>;

export const buildLegalCompanyData = (
  account: Account | void,
  {
    legalCompanyName,
    legalAddress,
    taxInfoType,
    taxInfoIdentifier,
    phoneNumber,
    industry,
    businessType,
    taxIdEinOverride,
  }: CompanyLegalInfoFields & { taxIdEinOverride?: string }
) => {
  const companyData: Partial<Company> = {};

  // Shouldn't happen...
  if (!account) {
    return {};
  }

  if (!account.company.legalName) {
    companyData.legalName = legalCompanyName;
  }

  if (!account.company.phoneNumber) {
    companyData.phoneNumber = phoneNumber;
  }

  if (
    !account.company.legalAddress?.line1 ||
    !account.company.legalAddress.city ||
    !account.company.legalAddress.state ||
    !account.company.legalAddress.postalCode
  ) {
    // type mismatch between `Company['address']` and `AddressValidation`
    companyData.legalAddress = legalAddress as Company['address'];
  }

  if (!account.company.industry?.name) {
    companyData.industry = industry;
  }

  if (taxIdEinOverride || !account.company.taxInfo?.type || !account.company.taxInfo.identifier) {
    // overriding taxId only if should override (EIN only) or if taxId is not present
    companyData.taxInfo = taxIdEinOverride
      ? {
          type: TaxIdTypeEnum.Ein,
          identifier: taxIdEinOverride,
        }
      : {
          type: taxInfoType,
          identifier: account.company.taxInfo?.identifier ? undefined : taxInfoIdentifier,
        };
  } else if (account.company.taxInfo.type !== taxInfoType) {
    companyData.taxInfo = {
      type: taxInfoType,
    };
  }

  if (!account.company.businessType) {
    companyData.businessType = businessType;
  }

  return pickBy(companyData, identity) as Partial<Company>;
};
