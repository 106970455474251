import { useMelioIntl } from '@melio/ar-domain';
import { Modal, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export type RemoveCustomPaymentDetailsModalScreenProps = {
  isOpen: boolean;
  isSubmitting: boolean;
  onSubmit: VoidFunction;
  onClose: VoidFunction;
  onCloseModal: VoidFunction;
};

export const RemoveCustomPaymentDetailsModalScreen = forwardRef<RemoveCustomPaymentDetailsModalScreenProps>(
  ({ isOpen, onCloseModal, onSubmit, onClose, isSubmitting }, ref) => {
    const { formatMessage } = useMelioIntl();

    return (
      <Modal
        closeButtonAriaLabel={formatMessage(
          'ar.settings.flows.invoiceSettings.sections.paymentOptions.removeCustomPaymentDetails.modal.closeButtonAriaLabel'
        )}
        isOpen={isOpen}
        onClose={() => !isSubmitting && onCloseModal()}
        primaryButton={{
          variant: 'primary',
          label: formatMessage(
            'ar.settings.flows.invoiceSettings.sections.paymentOptions.removeCustomPaymentDetails.modal.primaryButton.label'
          ),
          isLoading: isSubmitting,
          onClick: onSubmit,
        }}
        secondaryButton={{
          variant: 'tertiary',
          label: formatMessage(
            'ar.settings.flows.invoiceSettings.sections.paymentOptions.removeCustomPaymentDetails.modal.secondaryButton.label'
          ),
          isDisabled: isSubmitting,
          onClick: onClose,
        }}
        data-testid="remove-invoice-custom-payment-details-modal"
        data-component={RemoveCustomPaymentDetailsModalScreen.displayName}
        header={formatMessage(
          'ar.settings.flows.invoiceSettings.sections.paymentOptions.removeCustomPaymentDetails.modal.title.text'
        )}
        ref={ref}
      >
        <Text>
          {formatMessage(
            'ar.settings.flows.invoiceSettings.sections.paymentOptions.removeCustomPaymentDetails.modal.description.text'
          )}
        </Text>
      </Modal>
    );
  }
);

RemoveCustomPaymentDetailsModalScreen.displayName = 'RemoveCustomPaymentDetailsModalScreen';
