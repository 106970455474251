import { useBreakpointValue } from '@chakra-ui/react';
import { Group, Illustration, IllustrationProps } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-provider';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';

export type ProcessingRefundScreenProps = {
  vendorName: string;
  onDone: VoidFunction;
};

export const ProcessingRefundScreen = ({ onDone, vendorName }: ProcessingRefundScreenProps) => {
  const { formatMessage } = useMelioIntl();

  // This is a temporary solution, we should solve the responsive sizing in one of two ways: 1. Component level responsiveness OR 2. Layout level responsiveness for components
  const illustrationSize = useBreakpointValue<IllustrationProps['size']>({ xs: 'small', s: 'small', m: 'large' });

  return (
    <NewSinglePaymentStepLayout
      data-component="VoidAndRefundActivity.ProcessingRefundScreen"
      data-testid="void-check-activity-refund-processing-screen"
      footerContent={
        <NewSinglePaymentStepLayout.Actions>
          <NewSinglePaymentStepLayout.NextButton
            onClick={onDone}
            data-testid="track-void-and-refund-button"
            label={formatMessage('activities.voidAndRefund.screens.processingRefund.continue')}
          />
        </NewSinglePaymentStepLayout.Actions>
      }
    >
      <Group justifyContent="center">
        <Illustration type="pending" size={illustrationSize} />
      </Group>
      <NewSinglePaymentStepLayout.Title>
        {formatMessage('activities.voidAndRefund.screens.processingRefund.title')}
      </NewSinglePaymentStepLayout.Title>
      <NewSinglePaymentStepLayout.Description>
        {formatMessage('activities.voidAndRefund.screens.processingRefund.description', {
          vendorName,
        })}
      </NewSinglePaymentStepLayout.Description>
    </NewSinglePaymentStepLayout>
  );
};

ProcessingRefundScreen.displayName = 'VoidAndRefundActivity.ProcessingRefundScreen';
