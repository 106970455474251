import { ReactElement } from 'react';
import { NakedButton } from '@melio/penny';
import { AccountingPlatformSlug } from '@melio/platform-api';

import { AccountingPlatformCard as AccountingPlatformCardCl } from '@/cl/components/AccountingPlatformCard/AccountingPlatformCard.component';
import { usePlatformIntl } from '@/translations/Intl';
import { useZendesk } from '@/utils/zendesk';

type Props = {
  logo: ReactElement | null;
  syncDate?: string;
  errorText?: string;
  isLoading: boolean;
  companyName?: string | null;
  accountingPlatformName: string;
  accountingPlatformSlug?: AccountingPlatformSlug;
  dialogWillBeShown?: boolean;
  onReconnectedClicked: () => void;
};

export const AccountingPlatformUnlinkedCard = ({
  logo,
  syncDate,
  errorText,
  isLoading,
  companyName,
  accountingPlatformName,
  accountingPlatformSlug,
  dialogWillBeShown,
  onReconnectedClicked,
}: Props) => {
  const { formatMessage } = usePlatformIntl();
  const { showZendesk } = useZendesk();
  const title = formatMessage('widgets.accountingPlatform.card.type.unlinked.title', { accountingPlatformName });
  const isQuickBooksDesktopAccountingPlatform = accountingPlatformSlug === AccountingPlatformSlug.QuickBooksDesktop;
  const buttons = [
    {
      text: isQuickBooksDesktopAccountingPlatform
        ? formatMessage('widgets.accountingPlatform.card.buttons.resetConnection')
        : formatMessage('widgets.accountingPlatform.card.buttons.reconnect'),
      onClicked: onReconnectedClicked,
      isLoading,
      dataTestId: 'accounting-platform-connect-card-reconnect-button',
      ariaHasPopup: dialogWillBeShown && ('dialog' as const),
    },
  ];

  const descriptions = [
    formatMessage('widgets.accountingPlatform.card.type.unlinked.description', {
      chat: (
        <NakedButton
          variant="secondary"
          onClick={showZendesk}
          size="large"
          label={formatMessage('widgets.accountingPlatform.card.type.unlinked.links.reachOut')}
        />
      ),
      companyName,
      accountingPlatformName,
    }),
  ];

  return (
    <AccountingPlatformCardCl
      title={title}
      descriptionList={descriptions}
      buttons={buttons}
      logo={logo}
      errorText={errorText}
      lastSync={syncDate ? formatMessage('widgets.accountingPlatform.date.sync', { syncDate: syncDate }) : undefined}
      accountingPlatformSlug={accountingPlatformSlug}
    />
  );
};
