import { useEffect, useState } from 'react';

type AwaitedElementResult<K extends keyof HTMLElementTagNameMap> = {
  element: HTMLElementTagNameMap[K] | null;
  isAwaiting: boolean;
};

export const useWaitForElement = <K extends keyof HTMLElementTagNameMap>({
  selector,
  timeout = 250,
  enabled = true,
}: {
  selector: string;
  timeout?: number;
  enabled?: boolean;
}): AwaitedElementResult<K> => {
  const [state, setState] = useState<AwaitedElementResult<K>>({ element: null, isAwaiting: true });
  const isAwaiting = state.isAwaiting;

  useEffect(() => {
    if (!enabled) {
      return;
    }

    if (isAwaiting && document.querySelector(selector)) {
      setState({ element: document.querySelector(selector), isAwaiting: false });
      return;
    }

    const observer = new MutationObserver(() => {
      if (isAwaiting && document.querySelector(selector)) {
        observer.disconnect();
        setState({ element: document.querySelector(selector), isAwaiting: false });
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    const timeoutId = setTimeout(() => {
      if (isAwaiting) {
        setState({ element: null, isAwaiting: false });
      }
    }, timeout);

    return () => {
      observer.disconnect();
      clearTimeout(timeoutId);
    };
  }, [enabled, isAwaiting, selector, timeout]);

  return state;
};
