import { LoadingContainer } from '@melio/penny';

import { useSubscriptionBilling } from '../../../api';
import { EmptySubscriptionBillingHistory } from './EmptySubscriptionBillingHistory.widget';
import { SubscriptionBillingHistoryTable } from './SubscriptionBillingHistoryTable.widget';

export const SubscriptionBillingHistory = () => {
  const { data: subscriptionBilling, isLoading: isSubscriptionBillingLoading } = useSubscriptionBilling();
  const hasHistory = subscriptionBilling?.history && subscriptionBilling.history.length > 0;

  return (
    <LoadingContainer isLoading={isSubscriptionBillingLoading}>
      {!hasHistory && <EmptySubscriptionBillingHistory />}
      {hasHistory && <SubscriptionBillingHistoryTable />}
    </LoadingContainer>
  );
};
