import { useParams } from 'react-router-dom';
import { useAnalyticsContext } from '@melio/platform-analytics';
import { convertCentsToDollars } from '@melio/platform-utils';

import { useVendorEnrichedByIdQuery } from '@/hooks/vendors.hooks';

export const useVendorDrawerAnalytics = () => {
  const { vendorId, tab } = useParams();
  const { data: vendor } = useVendorEnrichedByIdQuery(vendorId);
  const { paymentsOverview } = vendor || {};

  useAnalyticsContext({
    globalProperties: {
      PageName: 'vendor-details',
      Intent: 'view-vendor',
      VendorId: vendorId,
      Flow: `dashboard-${tab}`,
      EntryPoint: `dashboard-${tab}`,
      IsContractor: Boolean(vendor?.contractor),
      UnpaidAmount: {
        Count: paymentsOverview?.unpaid.count,
        Amount: paymentsOverview ? convertCentsToDollars(paymentsOverview?.unpaid, ['sum'])?.sum : 0,
      },
      Payments: {
        Scheduled: {
          Count: paymentsOverview?.scheduled.count,
          Amount: paymentsOverview ? convertCentsToDollars(paymentsOverview?.scheduled, ['sum'])?.sum : 0,
        },
        Paid: {
          Count: paymentsOverview?.paid.count,
          Amount: paymentsOverview ? convertCentsToDollars(paymentsOverview?.paid, ['sum'])?.sum : 0,
        },
      },
      EBillStatus: vendor?.eBillStatus,
      AutoPayStatus: vendor?.autoPayStatus,
    },
  });
};
