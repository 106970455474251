import { zIndex } from '@/cl/layouts/layout.config';
import { ComponentThemeType } from '@/cl/theme/theme.hooks';

type ScreenSplitLayoutThemeProps = {
  screenGapY?: number;
  screenGapX?: number;
  showScreenMobile?: 'first' | 'second';
};

export const ScreenSplitLayoutTheme: ComponentThemeType<ScreenSplitLayoutThemeProps> = (
  currentTheme,
  { screenGapY = 0, showScreenMobile },
) => {
  return {
    baseStyle: {
      container: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        overflow: 'hidden',
      },
      firstScreenContainer: {
        position: 'relative',
        minHeight: '100%',
        paddingTop: { xs: '16px', s: `${screenGapY}px` } as never,
        padding: '16px',
        overflowY: 'scroll',
        width: { xs: '100%', s: '40%' } as never,
        display: { xs: !showScreenMobile || showScreenMobile === 'first' ? 'block' : 'none', s: 'block' } as never,
        borderRight: 'basic.light',
        backgroundColor: currentTheme.colors.global.neutral['200'],
      },
      firstScreen: {
        margin: '0 auto',
        width: { xs: 'auto', s: '400px' } as never,
        marginBottom: `${screenGapY}px`,
      },
      secondScreenWithConfirmationBarContainer: {
        minH: '100%',
        flex: 1,
        flexDirection: 'column',
        display: 'flex',
        width: { xs: '100%', s: '60%' } as never,
      },
      secondScreenContainer: {
        paddingTop: { xs: 0, s: `${screenGapY}px` } as never,
        position: 'relative',
        width: 'full',
        overflowY: 'scroll',
        flex: 1,
        display: 'flex',
        backgroundColor: currentTheme.colors.global.neutral['100'],
      },
      secondScreen: {
        margin: '0 auto',
        width: { xs: 'auto', s: '624px' } as never,
        padding: { xs: '24px 16px', s: 0 } as never,
        marginBottom: `${screenGapY}px`,
      },
      confirmationBarContainer: {
        position: { xs: 'sticky', s: 'initial' } as never,
        bottom: { xs: 0, s: 'initial' } as never,
        height: { xs: '144px', s: '70px' } as never,
        width: 'full',
        boxShadow: `0px -5px 15px rgba(33, 33, 36, 0.05)`,
        zIndex: zIndex.confirmationBarContainer,
      },
      secondScreenFooter: {
        width: 'full',
        boxShadow: `0px -5px 15px rgba(33, 33, 36, 0.05)`,
        zIndex: zIndex.secondScreenFooter,
        backgroundColor: currentTheme.colors.global.neutral['100'],
      },
    },
    variants: {
      settings: {
        container: {
          paddingBottom: { s: 'l' } as never,
        },
        firstScreenContainer: {
          padding: 0,
          paddingTop: 0,
          flexShrink: 0,
          backgroundColor: currentTheme.colors.global.neutral['100'],
          borderRight: 'none',
          overflowY: { xs: 'auto', s: 'scroll' } as never,
          width: { xs: '100%', s: 'fit-content' } as never,
        },
        firstScreen: {
          margin: { xs: '0 auto', s: '0' } as never,
          width: { xs: 'auto', s: 'fit-content' } as never,
        },
        secondScreenWithConfirmationBarContainer: {
          paddingLeft: { s: 'xxxl' } as never,
          overflow: 'hidden',
        },
        secondScreenContainer: {
          paddingTop: '0',
          paddingRight: { s: 's-m' } as never,
        },
        secondScreen: {
          width: '100%',
          padding: 0,
        },
        confirmationBarContainer: {
          boxShadow: 'none',
          height: { xs: '144px', s: 'fit-content' } as never,
        },
      },
    },
  };
};
