import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';

export const EditBillSubscriptionLoadingScreen = () => (
  <NewSinglePaymentStepLayout
    data-component="EditBillSubscriptionActivity.EditBillSubscriptionScreen"
    data-testid="edit-bill-subscription-activity-edit-bill-subscription-screen"
    isLoading
  />
);

EditBillSubscriptionLoadingScreen.displayName = 'EditBillSubscriptionActivity.EditBillSubscriptionScreen';
