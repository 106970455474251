import { Button, Container, Group } from '@melio/penny';
import { SubscriptionBillingCycleEnum } from '@melio/subscriptions/src/api';
import React from 'react';

import { FIRM_DISCOUNT_PERCENTAGE } from '../../../../consts';
import { SubscriptionBillingPayor } from '../../../../types';
import { FirmClientSubscriptionReviewAndConfirmActivity } from '../../../FirmClientSubscriptionReviewAndConfirm';
import { AssignFirmClientPlanFormStepComponent } from '../../types';
import { useAssignFirmClientPlanFormContext } from '../AssignFirmClientPlanForm/AssignFirmClientPlanFormFormContext';

export const AssignFirmClientPlanReviewAndConfirmStep: AssignFirmClientPlanFormStepComponent = ({
  submitLabel,
  onContinue,
}) => {
  const { watch, submitButtonProps } = useAssignFirmClientPlanFormContext();

  const planId = watch('subscriptionPlanId');
  const isFirmBilled = watch('whoPays') === SubscriptionBillingPayor.AccountingFirm;

  if (!planId) {
    return <></>;
  }
  return (
    <Group spacing="m" variant="vertical" width="full">
      <FirmClientSubscriptionReviewAndConfirmActivity
        planId={planId}
        fundingSourceId={isFirmBilled ? watch('fundingSourceId') : null}
        planCycle={SubscriptionBillingCycleEnum.Monthly}
        discountPercentage={FIRM_DISCOUNT_PERCENTAGE}
        organizationId={watch('organizationId')}
      />
      <Container justifyContent="flex-end">
        <Button variant="primary" size="medium" label={submitLabel} {...submitButtonProps} onClick={onContinue} />
      </Container>
    </Group>
  );
};
