import { Box } from '@chakra-ui/react';
import { _createFormFieldInput, SelectNew, SelectNewOption, SelectNewProps } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export type SelectInputProps = SelectNewProps<string, SelectNewOption<string>>;

export const SelectInput = _createFormFieldInput(
  forwardRef<SelectInputProps, 'input'>(({ options, value, id, ...props }, ref) => (
    <Box data-component="Select" data-value={value} data-testid={`form-field-select-${id ?? ''}`}>
      <SelectNew {...props} id={id} value={value} options={normalizeOptions(options)} ref={ref} />
    </Box>
  ))
);
SelectInput.displayName = 'SelectInput';

const normalizeOptions = (options: SelectInputProps['options']) =>
  isSelectNewOptions(options)
    ? options.map(normalizeOption)
    : options.map((section) => ({ ...section, options: section.options.map(normalizeOption) }));

const normalizeOption = (option: SelectNewOption<string>) => ({ ...option, testId: option.value });

const isSelectNewOptions = (options: SelectInputProps['options']): options is SelectNewOption<string>[] =>
  typeof options[0] === 'object' && 'value' in options[0] && 'label' in options[0];
