import React from 'react';

import { usePayments } from './usePayments';

export const useUserHasPayments = (options?: { enabled: boolean }) => {
  const { enabled = true } = options || {};
  const { data: payments, isLoading } = usePayments({
    params: { limit: 1, expand: 'none', sort: [{ field: 'createdAt', order: 'desc' }] },
    enabled,
  });

  const userHasPayments = React.useMemo(() => (payments ? Boolean(payments.length) : false), [payments]);

  return { userHasPayments, isLoading };
};
