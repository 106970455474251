import { useDisclosure } from '@chakra-ui/react';
import { Button, Icon } from '@melio/penny';
import { useDemoRequests } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useConfig, useMelioIntl } from '@melio/platform-provider';
import React, { useEffect, useState } from 'react';

import { DemoRequestActivity } from '../demo-request';

export type DemoRequestButtonActivityProps = {
  organizationId: number | string;
  email: string;
  helpCenterLink: string;
  hideAfterFirstUse?: boolean;
};

export const DemoRequestButtonActivity: React.VFC<DemoRequestButtonActivityProps> = ({
  organizationId,
  email,
  helpCenterLink,
  hideAfterFirstUse,
}) => {
  const { formatMessage } = useMelioIntl();
  const [showDemoRequestButton, setShowDemoRequestButton] = useState<boolean>(false);
  const { data: demoRequests, isLoading } = useDemoRequests();
  const { isOpen: showDemoRequestModal, onClose, onOpen: onClick } = useDisclosure();
  const [isDemoRequestEnabled] = useFeature(FeatureFlags.RequestADemo, false);
  const {
    settings: { showDemoRequestIcon },
  } = useConfig();

  useEffect(() => {
    setShowDemoRequestButton(!(demoRequests?.length && hideAfterFirstUse) && !isLoading);
  }, [isLoading, demoRequests, hideAfterFirstUse]);

  return isDemoRequestEnabled ? (
    <>
      {showDemoRequestButton && (
        <Button
          variant="secondary"
          leftElement={
            showDemoRequestIcon ? <Icon size="small" type="calendar" color="inherit" aria-hidden /> : undefined
          }
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          style={{ backgroundColor: 'white' }}
          label={formatMessage('widgets.demoRequest.button')}
          data-testid="open-demo-request"
          onClick={onClick}
        />
      )}
      {showDemoRequestModal && (
        <DemoRequestActivity
          organizationId={organizationId}
          email={email}
          helpCenterLink={helpCenterLink}
          pageEntryPoint="dashboard"
          onClose={onClose}
        />
      )}
    </>
  ) : (
    <></>
  );
};
DemoRequestButtonActivity.displayName = 'DemoRequestButtonActivity';
