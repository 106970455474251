import { LockedVendorIcon } from '@melio/ap-widgets';
import { Container, Group, Icon, Pill, Text, Typography } from '@melio/penny';
import { Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { BillListItemOriginSection } from '../../BillsTab/components/BillsTabListItem';
import { PaymentsTabListItemProps } from '../../BillsTab/types';
import { PaymentMultipleBillsOrigin } from './PaymentsTabBillsOriginCell';
import { usePaymentsTabListItem } from './usePaymentsTabListItem';

export const PaymentsTabListItem = ({ payment }: PaymentsTabListItemProps) => {
  const { formatDate, formatMessage } = useMelioIntl();
  const {
    isVendorNotPermitted,
    formattedAmount,
    deliveryDate,
    amountDescription,
    vendorDescription,
    isFinancingEnabled,
    deliveryMethod,
    statusLabel,
    status,
  } = usePaymentsTabListItem(payment);

  return (
    <Container data-testid="payments-tab-list-item" overflow="initial" paddingX="m">
      <Group variant="vertical" spacing="xxs">
        <Group justifyContent="space-between">
          <Group variant="horizontal" spacing="xxs" alignItems="center">
            {isVendorNotPermitted ? <LockedVendorIcon /> : null}
            <Typography.MainLabel
              label={payment.vendor?.name ?? ''}
              variant="bold"
              aria-label={payment.vendor?.name ?? ''}
            />
          </Group>
          <Group variant="horizontal" alignItems="center" spacing="xxxs">
            <Text textStyle="body2Semi">{formattedAmount}</Text>
            <Icon type="chevron-right" size="small" />
          </Group>
        </Group>
        <Text shouldSupportEllipsis color="neutral.darkest" textStyle="body3">
          {vendorDescription}
        </Text>
        <Group justifyContent="space-between">
          {payment.estimatedDelivery && (
            <Text color="global.neutral.900" textStyle="body3">
              {deliveryDate}
            </Text>
          )}
          {amountDescription}
        </Group>
        <Group variant="vertical" spacing="xxs">
          <Text color="global.neutral.900" textStyle="body3">
            {formatMessage('activities.payDashboard.paymentsTab.listItem.debitDate', {
              debitDate: formatDate(payment.scheduledDate, { dateStyle: 'medium' }),
            })}
          </Text>
          <PaymentBillsOriginRow payment={payment} />
          {isFinancingEnabled && payment?.loan && (
            <Container width="full">
              <Text textStyle="body3" color="global.neutral.900">
                {formatMessage('activities.payDashboard.paymentsTab.table.cells.debitDate.installments', {
                  installmentNumber: payment.loan.nextInstallmentNumber ?? 1,
                  totalInstallments: payment.loan.installments.length,
                })}
              </Text>
            </Container>
          )}
          {deliveryMethod && (
            <Group
              justifyContent="flex-start"
              alignItems="center"
              spacing="xs"
              data-testid="payments-tab-list-item-delivery-method"
            >
              <Text color="global.neutral.800">
                <Group alignItems="center" justifyContent="center">
                  <Icon type="bank-out" size="small" color="inherit" />
                </Group>
              </Text>
              <Text color="global.neutral.900" textStyle="body3" shouldSupportEllipsis>
                {formatMessage('activities.payDashboard.paymentsTab.table.cells.deliveryMethod.title')}:
              </Text>
              <Text color="global.neutral.900" textStyle="body3" shouldSupportEllipsis>
                {deliveryMethod.type}
              </Text>
              {deliveryMethod.details ? (
                <Group alignItems="center" spacing="none" data-testid="payments-tab-list-item-delivery-method-details">
                  <Text color="global.neutral.900" textStyle="body3" shouldSupportEllipsis>
                    ({deliveryMethod.details}
                  </Text>
                  <Text color="global.neutral.900" textStyle="body3">
                    )
                  </Text>
                </Group>
              ) : null}
            </Group>
          )}
          <Container paddingTop="xxs">
            <Pill type="secondary" label={statusLabel} status={status} />
          </Container>
        </Group>
      </Group>
    </Container>
  );
};

const PaymentBillsOriginRow = ({ payment }: { payment: Payment }) => {
  const { bills } = payment;
  if (!bills || bills?.length === 0) {
    return null;
  }

  if (bills.length > 1) {
    return <PaymentMultipleBillsOrigin bills={bills} showIcon />;
  }

  const [bill] = bills;
  if (!bill) {
    return null;
  }

  return <BillListItemOriginSection billsTabItem={{ type: 'bill', payload: bill }} />;
};
