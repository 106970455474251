import { Box } from '@chakra-ui/react';
import {
  BaseMenu,
  Button,
  Container,
  Group,
  Icon,
  Layout,
  Link,
  SectionBanner,
  SelectableDropdownMenu,
  Text,
  useBreakpoint,
  useToast,
} from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { JoinOrganizationRequest, Organization, useAccount } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { PageTitle } from '@melio/platform-utils';
import { ReactNode, useEffect, useState } from 'react';

import { useAccountantsRoutes } from '../../utils/useAccountantsRoutes';
import { JoinOrganizationRequestCard } from './components/cards/JoinOrganizationRequestCard';
import { NoAccessOrganizationCard } from './components/cards/NoAccessOrganizationCard';
import { OrganizationCard } from './components/cards/OrganizationCard';
import { FirmDashboardEmptyStateGuard } from './components/FirmDashboardEmptyState.guard';
import { CancelJoinOrganizationRequestModal } from './components/modals/CancelJoinOrganizationRequestModal';
import { ResendJoinOrganizationRequestModal } from './components/modals/ResendJoinOrganizationRequestModal';
import { TopPlanSelectionBanner } from './components/TopPlanSelectionBanner/TopPlanSelectionBanner';
import { useHiddenClientsDropdown } from './hooks/useHiddenClientsDropdown';
import { DisplayMode, useFirmDashboard } from './useFirmDashboard';

type Props = {
  goToSettingsSubscriptionPlansAndRefresh: VoidFunction;
  onSwitchOrganization: ({
    organizationId,
    skipPayDashboardRedirect,
  }: {
    organizationId: string;
    skipPayDashboardRedirect?: boolean;
  }) => Promise<void>;
};
export const FirmDashboardActivity = withAnalyticsContext<Props>(
  ({ setAnalyticsProperties, goToSettingsSubscriptionPlansAndRefresh, onSwitchOrganization }) => {
    const { track } = useAnalytics();
    const { toast } = useToast();
    const { formatMessage } = useMelioIntl();
    const { isExtraSmallScreen } = useBreakpoint();
    const { goToClientCreationOptions, goToSupportPage } = useAccountantsRoutes();
    const {
      isLoading,
      isLoadingExpanded,
      isPaymentsOverviewLoading,
      clientsToShow,
      joinOrganizationRequestsToShow,
      clientsWithoutPermissions,
      expiredRequests,
      pendingRequests,
      setIsSwitching,
      hasHiddenClients,
      updateClient,
      setDisplayMode,
      displayMode,
      error,
      isExpandedClientsFetchedSuccessfully,
      eligibleClientsForAssignPlan,
    } = useFirmDashboard();
    const hiddenClientsDropdown = useHiddenClientsDropdown(displayMode);
    const { data: firmAccount } = useAccount({ id: 'me' });
    const [resendRequestEntity, setResendRequestEntity] = useState<JoinOrganizationRequest>();
    const [cancelRequestEntity, setCancelRequestEntity] = useState<JoinOrganizationRequest>();

    setAnalyticsProperties({
      PageName: 'my-clients',
      Flow: 'manage-my-clients',
      Intent: 'manage-my-clients',
      NumberOfClients: clientsToShow.length,
      RequestsStatus: {
        pending: pendingRequests.length,
        expired: expiredRequests.length,
      },
    });

    useEffect(() => {
      if (isExpandedClientsFetchedSuccessfully) {
        track('User', 'View');
      }
    }, [isExpandedClientsFetchedSuccessfully, track]);

    const onAddButtonClick = () => {
      track('User', 'Click', {
        Intent: 'add-client',
        Cta: 'add-client',
      });
      goToClientCreationOptions();
    };

    const onSwitchToClient = async (organizationId: string) => {
      setIsSwitching(true);
      track('User', 'Click', {
        Intent: 'choose-client',
        Cta: 'choose-client',
      });
      try {
        await onSwitchOrganization({ organizationId });
      } catch (error) {
        toast({
          type: 'error',
          title: formatMessage('activities.accountants.firmDashboard.switchOrganization.toast.error'),
        });
      }
    };

    const onChangeClientPlan = async (organizationId: string) => {
      setIsSwitching(true);
      try {
        await onSwitchOrganization({ organizationId, skipPayDashboardRedirect: true });
        goToSettingsSubscriptionPlansAndRefresh();
        setIsSwitching(false);
      } catch (error) {
        setIsSwitching(false);
        toast({
          type: 'error',
          title: formatMessage('activities.accountants.firmDashboard.changeClientPlan.toast.error'),
        });
      }
    };

    const onToggleHiddenState = async (organization: Organization) => {
      try {
        await updateClient({ id: organization.id, data: { isHidden: !organization.isHidden } });
      } catch (error) {
        toast({
          type: 'error',
          title: formatMessage('screens.serverError.title'),
        });
      }
    };

    return (
      <Layout variant="full" paddingContent={['none', null, 'l']} data-testid="accountants-dashboard">
        <Group variant="vertical" spacing="m">
          <Container paddingTop={['m', null, '0'] as never} paddingX={['m', null, '0'] as never} overflow="initial">
            <Group justifyContent="space-between" spacing="m">
              <Group variant="vertical" spacing="xs">
                <PageTitle textStyle={['heading2Semi', null, 'heading1Semi'] as never}>
                  {formatMessage('activities.accountants.firmDashboard.title')}
                </PageTitle>
                <Text textStyle="body3" color="semantic.text.secondary">
                  {firmAccount?.company.name}
                </Text>
              </Group>
              <Group variant="vertical" alignItems="flex-end">
                <Group variant="horizontal" spacing={['xs', null, 's'] as never} alignItems="center">
                  <Button
                    leftElement={<Icon size="small" type="add" color="inherit" aria-hidden />}
                    data-testid="add-company-button"
                    size={isExtraSmallScreen ? 'small' : 'medium'}
                    variant="primary"
                    label={formatMessage('activities.accountants.firmDashboard.addClientButton.label')}
                    onClick={onAddButtonClick}
                  />
                </Group>
                {hasHiddenClients && (
                  <SelectableDropdownMenu
                    items={hiddenClientsDropdown.items}
                    dropdownTestId="hidden-clients-dropdown-container"
                    trigger={
                      <BaseMenu.ActionTrigger
                        data-testid="hidden-clients-dropdown-trigger"
                        label={hiddenClientsDropdown.selectedItem.label}
                      />
                    }
                    isOpen={hiddenClientsDropdown.isOpen}
                    onOpenChange={hiddenClientsDropdown.setIsOpen}
                    selectedItemValue={hiddenClientsDropdown.selectedItem.value}
                    onSelect={(item) => {
                      setDisplayMode(item.value as DisplayMode);
                      hiddenClientsDropdown.setIsOpen(false);
                    }}
                  />
                )}
              </Group>
            </Group>
          </Container>
          {error && (
            <SectionBanner
              variant="critical"
              title={formatMessage('activities.accountants.firmDashboard.general.error.title')}
              description={formatMessage('activities.accountants.firmDashboard.general.error.description', {
                code: error.code,
                message: error.message,
                contactSupportLink: (
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      goToSupportPage();
                    }}
                    label={formatMessage(
                      'activities.accountants.firmDashboard.general.error.description.support.link.label'
                    )}
                    href="" // default is prevented
                  />
                ),
              })}
            />
          )}
          {eligibleClientsForAssignPlan.length ? (
            <Container>
              <TopPlanSelectionBanner clients={eligibleClientsForAssignPlan} />
            </Container>
          ) : null}
          <Container overflow="initial">
            <FirmDashboardEmptyStateGuard
              isLoading={isLoading}
              isEmpty={!clientsToShow.length && !joinOrganizationRequestsToShow.length}
            >
              <CardsGroup>
                {clientsToShow.map((client) => (
                  <CardContainer key={`organization_card_${client.organization.id}`}>
                    <OrganizationCard
                      client={client}
                      isPaymentsOverviewLoading={isPaymentsOverviewLoading}
                      isPaymentsOverviewLoadingExpanded={isLoadingExpanded}
                      onChangeClientPlans={() => void onChangeClientPlan(client.organization.id)}
                      onToggleHiddenState={() => void onToggleHiddenState(client.organization)}
                      onClick={() => void onSwitchToClient(client.organization.id)}
                    />
                  </CardContainer>
                ))}
                {clientsWithoutPermissions.map((client) => (
                  <CardContainer key={`join_organization_accounting_software_card_${client.organization.id}`}>
                    <NoAccessOrganizationCard organization={client.organization} />
                  </CardContainer>
                ))}
                {joinOrganizationRequestsToShow.map((joinOrganizationRequest) => (
                  <CardContainer key={`join_organization_request_card_${joinOrganizationRequest.id}`}>
                    <JoinOrganizationRequestCard
                      joinOrganizationRequest={joinOrganizationRequest}
                      onRequestCancel={() => setCancelRequestEntity(joinOrganizationRequest)}
                      onRequestResend={() => setResendRequestEntity(joinOrganizationRequest)}
                    />
                  </CardContainer>
                ))}
                {resendRequestEntity ? (
                  <ResendJoinOrganizationRequestModal
                    joinOrganizationRequest={resendRequestEntity}
                    onClose={() => setResendRequestEntity(undefined)}
                  />
                ) : null}
                {cancelRequestEntity ? (
                  <CancelJoinOrganizationRequestModal
                    joinOrganizationRequest={cancelRequestEntity}
                    onClose={() => setCancelRequestEntity(undefined)}
                  />
                ) : null}
              </CardsGroup>
            </FirmDashboardEmptyStateGuard>
          </Container>
        </Group>
      </Layout>
    );
  }
);

const CardsGroup = ({ children }: { children: ReactNode }) => (
  <Box sx={{ '& > *': { flexWrap: 'wrap' } }}>
    <Group width="full" justifyContent="space-between">
      {children}
    </Group>
  </Box>
);
const CardContainer = ({ children }: { children: ReactNode }) => (
  <Group.Item basis={['100%', null, 'calc(50% - 8px)'] as never}>{children}</Group.Item>
);
