import { _IconIndicator, Group, NakedButton, PopoverProps } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

type AttachInvoiceWarningProps = {
  popoverProps?: PopoverProps;
};

export const AttachInvoiceWarning = ({ popoverProps }: AttachInvoiceWarningProps) => {
  const { formatMessage } = useMelioIntl();
  const indicatorProps = popoverProps ? { popover: popoverProps } : {};
  const addInvoiceButtonText = formatMessage(
    'activities.batchPayments.screens.paymentIntentsTable.deliveryMethodSubCell.missingInvoicePopover.popoverActionLabel'
  );

  return (
    <Group spacing="xs" alignItems="center" justifyContent="space-between" width="full">
      <NakedButton
        label={addInvoiceButtonText}
        variant="secondary"
        onClick={popoverProps?.actionProps.onClick || void 0}
        data-testid="sub-delivery-method-attach-invoice"
      />
      <_IconIndicator data-testid="sub-delivery-method-cell-icon" variant="warning" {...indicatorProps} />
    </Group>
  );
};
