import { ApiError } from '@melio/api-client';
import { Link, SectionBanner } from '@melio/penny';
import { useConfig, useMelioIntl } from '@melio/platform-provider';

import { SubscriptionErrorCode } from '../../../api';

type SubscriptionCheckoutErrorBannerProps = {
  error: ApiError | null;
};

export const SubscriptionCheckoutErrorBanner = ({ error }: SubscriptionCheckoutErrorBannerProps) => {
  const { formatMessage } = useMelioIntl();
  const {
    settings: {
      vex: { zdSupportFormUrl },
    },
  } = useConfig();

  const isZipCodeError = (error?.errorCode as SubscriptionErrorCode) === 'ZipCodeDoesntMatchState';

  return (
    <>
      {isZipCodeError && (
        <SectionBanner
          data-testid="subscription-checkout-error-banner"
          variant="critical"
          isCompact
          description={formatMessage('activities.subscription.zipCodeMismatchState.banner.description')}
          action={
            <Link
              newTab
              href={zdSupportFormUrl}
              size="medium"
              label={formatMessage('activities.subscription.zipCodeMismatchState.banner.action')}
            />
          }
        />
      )}
    </>
  );
};
