import { IndustryApiClient } from '@melio/platform-api-axios-client';

import { useModel, UseModelProps } from '../../api-client';

export type UseIndustryProps = UseModelProps<typeof IndustryApiClient.getIndustries>;

export const useIndustries = (props: UseIndustryProps = {}) =>
  useModel({
    ...props,
    id: 'industry-api',
    queryKey: 'IndustryApi',
    queryFn: IndustryApiClient.getIndustries,
  });

export type IndustryModel = ReturnType<typeof useIndustries>;
