import { AccountingPlatformsApiClient, ApiKeys } from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useQuery } from 'react-query';

export function useAccountingPlatformSyncVersionQuery() {
  return useQuery({
    queryKey: [ApiKeys.AccountingPlatformsApi, 'sync-version'],
    queryFn: () => AccountingPlatformsApiClient.syncVersion(),
  });
}
