import { ActionsDropdownMenuItemProps } from '@melio/penny';
import {
  AccountingPlatform,
  BankAccount,
  BankAccountVerifiedStatusEnum,
  Company,
  DeliveryMethod,
  DeliveryMethod as DeliveryMethodAPI,
  DeliveryMethodType,
  useAccount,
  useDeliveryMethods,
} from '@melio/platform-api';
import { usePermissions } from '@melio/platform-permissions';

import { usePlatformIntl } from '@/translations/Intl';

export const isCompanyLegalInfoDone = (companyInfo?: Company) => {
  return !!(
    companyInfo?.legalName &&
    companyInfo?.legalAddress &&
    companyInfo?.taxInfo?.identifier &&
    companyInfo?.taxInfo.type &&
    companyInfo?.contactFirstName &&
    companyInfo?.contactLastName &&
    companyInfo?.phoneNumber
  );
};

export const useACHDeliveryMethodExists = () => {
  const { data: deliveryMethods } = useDeliveryMethods({ isGetOwn: true });

  return deliveryMethods?.some((dm) => dm.type === DeliveryMethodType.BankAccount);
};

export const useShouldShowDeliveryMethods = () => {
  const { data: userInfo } = useAccount({ id: 'me' });
  const { data: deliveryMethods } = useDeliveryMethods({ isGetOwn: true });

  const hasDeliveryMethod = !!deliveryMethods?.filter(
    (deliveryMethod) => deliveryMethod?.type !== DeliveryMethodType.VirtualCard,
  ).length;

  return (
    hasDeliveryMethod ||
    (!!userInfo?.ownedVendorId && !!userInfo?.ownedVendorHandle && isCompanyLegalInfoDone(userInfo?.company))
  );
};

export const isVerifiedStatus = (status?: BankAccountVerifiedStatusEnum): boolean => {
  const VERIFIED_METHODS: BankAccountVerifiedStatusEnum[] = [
    BankAccountVerifiedStatusEnum.SoftVerified,
    BankAccountVerifiedStatusEnum.PlaidVerified,
    BankAccountVerifiedStatusEnum.MicroDepositVerified,
  ];

  return status ? VERIFIED_METHODS.includes(status) : false;
};

export const getAccountNumber4digits = (bankAccount: Partial<BankAccount> | null | undefined): string =>
  bankAccount?.accountNumber?.slice(-4) || '';

export const getDeliveryMethodName = (deliveryMethod?: DeliveryMethod) => {
  const accountType =
    deliveryMethod?.details && deliveryMethod.type === DeliveryMethodType.BankAccount
      ? deliveryMethod?.details.accountType
      : '';
  const accountTypeCapitalize = accountType.charAt(0).toUpperCase() + accountType.slice(1);

  const accountNumber4digits = getAccountNumber4digits(deliveryMethod?.details as BankAccount);

  const deliveryInfo = deliveryMethod?.details && deliveryMethod ? `(...${accountNumber4digits})` : '';

  return `${accountTypeCapitalize} account ${deliveryInfo}`;
};

export const isManualBankAccountNotVerified = (deliveryMethod?: DeliveryMethod) =>
  deliveryMethod &&
  deliveryMethod.type === DeliveryMethodType.BankAccount &&
  (deliveryMethod.details.verifiedStatus === BankAccountVerifiedStatusEnum.NotVerified ||
    deliveryMethod.details.verifiedStatus === BankAccountVerifiedStatusEnum.PlaidNotVerified);

type UseReceivingMethodActions = {
  deliveryMethod: DeliveryMethodAPI;
  onDelete: VoidFunction;
  onEditLabel: VoidFunction;
  dataTestIdPrefix: string;
  onEditSyncDetails: (deliveryMethodId: string) => void;
  goViewDeliveryMethod: (deliveryMethodId: string) => void;
  activeAccountingPlatform?: AccountingPlatform;
};

export const useReceivingMethodActions = ({
  deliveryMethod,
  onDelete,
  onEditLabel,
  onEditSyncDetails,
  goViewDeliveryMethod,
  activeAccountingPlatform,
}: UseReceivingMethodActions) => {
  const { formatMessage } = usePlatformIntl();
  const { can } = usePermissions();

  const viewAction = {
    label: formatMessage('widgets.receivingMethodsSettings.receivingMethodItem.accountActions.view.label'),
    onClick: () => goViewDeliveryMethod(deliveryMethod.id),
    dataTestId: 'view-receiving-method-action',
  };

  const updateAccountAction = {
    label: formatMessage('widgets.receivingMethodsSettings.receivingMethodItem.accountActions.editAction.label'),
    onClick: onEditLabel,
    dataTestId: 'update-account-receiving-method-action',
  };

  const deleteAction = {
    label: formatMessage('widgets.receivingMethodsSettings.receivingMethodItem.accountActions.delete.label'),
    variant: 'critical' as const,
    onClick: onDelete,
    dataTestId: 'delete-receiving-method-action',
  };

  const editSyncDetails = {
    label: formatMessage('widgets.receivingMethodsSettings.receivingMethodItem.accountActions.editSyncDetails.label'),
    onClick: () => onEditSyncDetails(deliveryMethod.id),
    dataTestId: 'edit-sync-receiving-method-action',
  };

  let actions: ActionsDropdownMenuItemProps[] = [];

  if (can({ action: 'update', subject: 'deliveryMethod' })) {
    actions.push(updateAccountAction);
  }

  if (deliveryMethod.type === DeliveryMethodType.BankAccount && !deliveryMethod?.details.plaidAccount) {
    actions.push(viewAction);
  }

  if (activeAccountingPlatform) {
    actions.push(editSyncDetails);
  }

  if (can({ action: 'delete', subject: 'deliveryMethod' })) {
    actions.push(deleteAction);
  }

  return actions;
};
