import { Group, Text } from '@melio/penny';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { getPaymentAnalyticsFields } from '../../../../utils';
import { AddVendorBankDetailsActivity, AddVendorBankDetailsActivityProps } from '../../../delivery-methods';

type Props = Omit<AddVendorBankDetailsActivityProps, 'bottomContent'> & {
  payment: Payment;
};

export const AddBankDetailsStep = withAnalyticsContext<Props>(
  ({ payment, setAnalyticsProperties, onClose: _onClose, onBack: _onBack, onDone: _onDone, ...props }) => {
    const { formatMessage } = useMelioIntl();
    const { createTrackHandler } = useAnalytics();

    setAnalyticsProperties({
      ...getPaymentAnalyticsFields(payment),
      Intent: 'add-ach-delivery-method-details',
      EntryPoint: 'dashboard-payment',
      Flow: 'void-and-resend-check',
      PageName: 'add-bank-details-for-vendor',
      EligibilityType: null,
    });

    useAnalyticsView('Payment', true, true);

    const trackAndHandleClick = createTrackHandler<{ Cta: 'exit' | 'back' | 'continue' }>('Payment', 'Click');

    const onClose = () => trackAndHandleClick({ Cta: 'exit' }, _onClose);
    const onBack = () => trackAndHandleClick({ Cta: 'back' }, _onBack);
    const onDone: Props['onDone'] = (...args) => trackAndHandleClick({ Cta: 'continue' }, () => _onDone(...args));

    return (
      <AddVendorBankDetailsActivity
        {...props}
        onDone={onDone}
        onClose={onClose}
        onBack={onBack}
        bottomContent={
          <Group textAlign="center">
            <Text textStyle="caption1" color="global.neutral.900">
              {formatMessage('activities.voidAndResend.steps.addBankDetails.bottomContent')}
            </Text>
          </Group>
        }
      />
    );
  }
);
