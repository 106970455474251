import { DomesticWireFormFields } from '@melio/ap-widgets';
import { useToast } from '@melio/penny';
import { Traits, useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { DomesticWireDeliveryMethod, useDeliveryMethods, useVendor } from '@melio/platform-api';

import { AddVendorDomesticWireDetailsScreen } from './screens';
import { AddVendorDomesticWireDetailsActivityProps, SubmissionTargetType } from './types';

export const AddVendorDomesticWireDetailsActivity = withAnalyticsContext<AddVendorDomesticWireDetailsActivityProps>(
  ({ vendorId, onBack: onFirstStepBack, onClose, onError, onDone, origin = 'payment' }) => {
    const collection = useDeliveryMethods({ vendorId, enabled: false });
    const vendorModel = useVendor({ id: vendorId });
    const vendorName = vendorModel.data?.name || '';
    const { toast } = useToast();

    const handleFail = (error: PlatformError) => {
      toast({ type: 'error', title: error.message });
      onError?.(error);
    };

    const { createTrackHandler, setTraits } = useAnalytics();
    const submitHandler = createTrackHandler<{ Status: 'failed' | 'succeeded' }>(
      'AddVendorDomesticWireDetails',
      'Saved'
    );
    const setDeliveryMethodTraits = () => {
      const traits: Traits = { added_delivery_method: true };

      setTraits(traits);
    };

    const createDeliveryMethod = (data: DomesticWireFormFields) =>
      collection.create({
        type: 'domestic-wire-account',
        details: {
          accountNumber: data.accountNumber,
          routingNumber: data.routingNumber,
          address: {
            city: data.city,
            state: data.state,
            postalCode: data.postalCode,
          },
        },
      });

    const createDeliveryMethodAndSubmitTrack = (data: DomesticWireFormFields, target?: SubmissionTargetType) => {
      createDeliveryMethod(data)
        .then((data) => {
          submitHandler({ Status: 'succeeded' });
          setDeliveryMethodTraits();
          onDone(data as DomesticWireDeliveryMethod, target);
        })
        .catch((...args) => submitHandler({ Status: 'failed' }, () => handleFail(...args)));
    };

    return (
      <AddVendorDomesticWireDetailsScreen
        onBack={onFirstStepBack}
        onClose={onClose}
        onDone={(data, _event, target) => createDeliveryMethodAndSubmitTrack(data, target)}
        isSaving={collection.isMutating}
        origin={origin}
        vendorName={vendorName}
      />
    );
  }
);

AddVendorDomesticWireDetailsActivity.displayName = 'AddVendorDomesticWireDetailsActivity';
