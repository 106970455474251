import { LoadingContainer } from '@melio/penny';
import { useFundingSources } from '@melio/platform-api';

import { usePartnerGroups, useSubscription } from '../../../hooks';
import { SubscriptionAddPaymentMethodCard } from './SubscriptionAddPaymentMethodCard';
import { SubscriptionPaymentMethodCard } from './SubscriptionPaymentMethodCard';

type SubscriptionPaymentMethodProps = {
  onChange?: () => void;
};

export const SubscriptionPaymentMethod = ({ onChange }: SubscriptionPaymentMethodProps) => {
  const subscription = useSubscription();
  const { data: fundingSources, isFetching: isFetchingFundingSources } = useFundingSources();
  const selectedFundingSource = fundingSources?.find(
    (fundingSource) => fundingSource.id === subscription?.fundingSourceId
  );
  const { isFiservPartner } = usePartnerGroups();
  const shouldAllowChangeFundingSource = !isFiservPartner || (fundingSources && fundingSources?.length > 1);

  return (
    <LoadingContainer isLoading={isFetchingFundingSources} data-testid="subscription-payment-method">
      {selectedFundingSource ? (
        <SubscriptionPaymentMethodCard
          onChange={onChange}
          fundingSource={selectedFundingSource}
          shouldAllowChangeFundingSource={shouldAllowChangeFundingSource}
        />
      ) : (
        <SubscriptionAddPaymentMethodCard />
      )}
    </LoadingContainer>
  );
};
