import { FormWidgetProps, isValidOptionalPhone } from '@melio/ap-widgets';
import { Form, useMelioForm } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { object, SchemaOf, string } from 'yup';

export type OwnerDetailsFormWidgetFields = {
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  phone: string;
};

type Props = FormWidgetProps<OwnerDetailsFormWidgetFields> & {
  defaultFields?: Partial<OwnerDetailsFormWidgetFields>;
};

export const useSchema = () => {
  const { formatMessage } = useMelioIntl();

  return object().shape({
    contactFirstName: string()
      .trim()
      .required(formatMessage('activities.addCompany.screens.ownerDetails.form.firstName.validation.required')),
    contactLastName: string()
      .trim()
      .required(formatMessage('activities.addCompany.screens.ownerDetails.form.lastName.validation.required')),
    contactEmail: string()
      .trim()
      .email(formatMessage('activities.addCompany.screens.ownerDetails.form.email.validation.required'))
      .required(formatMessage('activities.addCompany.screens.ownerDetails.form.email.validation.required')),
    phone: string()
      .required(formatMessage('activities.addCompany.screens.ownerDetails.form.phone.validation.required'))
      .test(
        'valid-phone',
        formatMessage('activities.addCompany.screens.ownerDetails.form.phone.validation.required'),
        isValidOptionalPhone
      ),
  }) as SchemaOf<OwnerDetailsFormWidgetFields>;
};

export const OwnerDetailsFormWidget = ({
  onSubmit,
  isSaving,
  onSubmissionStateChange,
  defaultFields,
  ...props
}: Props) => {
  const defaultValues = {
    contactFirstName: defaultFields?.contactFirstName || '',
    contactLastName: defaultFields?.contactLastName || '',
    contactEmail: defaultFields?.contactEmail || '',
    phone: defaultFields?.phone || '',
  };

  const { formatMessage } = useMelioIntl();

  const { formProps, registerField } = useMelioForm<OwnerDetailsFormWidgetFields>({
    onSubmit,
    schema: useSchema(),
    defaultValues,
    isSaving,
    onSubmissionStateChange,
    subscribeToDefaultValuesChanges: true,
  });

  return (
    <Form data-component="OwnerDetailsFormWidget" {...props} columns={2} {...formProps}>
      <Form.TextField
        labelProps={{ label: formatMessage('activities.addCompany.screens.ownerDetails.form.firstName.label') }}
        {...registerField('contactFirstName')}
      />
      <Form.TextField
        labelProps={{ label: formatMessage('activities.addCompany.screens.ownerDetails.form.lastName.label') }}
        {...registerField('contactLastName')}
      />
      <Form.TextField
        colSpan={2}
        labelProps={{ label: formatMessage('activities.addCompany.screens.ownerDetails.form.email.label') }}
        {...registerField('contactEmail')}
      />
      <Form.PhoneField
        colSpan={2}
        labelProps={{ label: formatMessage('activities.addCompany.screens.ownerDetails.form.phone.label') }}
        {...registerField('phone')}
      />
    </Form>
  );
};

OwnerDetailsFormWidget.displayName = 'OwnerDetailsFormWidget';
