/* eslint-disable react-hooks/exhaustive-deps */
import { useAnalytics } from '@melio/platform-analytics';
import { Collaborator, RoleUniqueNames } from '@melio/platform-api';
import { countBy } from 'lodash';
import { useEffect } from 'react';

import type { CollaboratorAction, InvitationAction } from '../../types';

type Props = { collaborators: Collaborator[]; isLoadingData: boolean };
export const useManageCollaboratorAnalytics = ({ collaborators, isLoadingData }: Props) => {
  const { track } = useAnalytics();

  const actionEventProp = {
    PageName: 'collaborators-setting',
  };
  useEffect(() => {
    if (!isLoadingData) {
      track('Settings', 'View', {
        PageName: 'collaborators',
        Flow: 'user-roles',
        Intent: 'view-users-in-org',
        CountInTab: collaborators.length,
        Roles: { ...countBy(collaborators, ({ roleUniqueName }) => roleUniqueName) },
      });
    }
  }, [isLoadingData]);

  const clickAddCollaborator = () => {
    track('Settings', 'Click', {
      PageName: 'collaborators',
      Flow: 'user-roles',
      Intent: 'add-user',
      Cta: 'add-user',
      CountInTab: collaborators.length,
      Roles: { ...countBy(collaborators, ({ roleUniqueName }) => roleUniqueName) },
    });
  };

  const clickAddAccountingFirm = () => {
    track('Settings', 'Click', {
      PageName: 'collaborators',
      Flow: 'user-roles',
      Intent: 'add-external-firm',
      Cta: 'add-external-firm',
      CountInTab: collaborators.length,
    });
  };

  const clickCollaboratorAction = ({
    action,
    collaboratorRoleUniqueName,
  }: {
    action: CollaboratorAction;
    collaboratorRoleUniqueName?: RoleUniqueNames | null;
  }) => {
    const commonProps = {
      ManagedUserRole: collaboratorRoleUniqueName,
      InvitationStatus: 'approved',
    };
    track('Settings', 'Click', {
      ...actionEventProp,
      ...commonProps,
      Cta: action === 'edit' ? 'edit-user-role' : action === 'delete' ? 'remove-user' : 'make-owner',
      Intent: 'manage-collaborators',
    });
    track('Settings', 'View', {
      ...commonProps,
      PageName:
        action === 'edit'
          ? 'edit-role-for-collaborator'
          : action === 'delete'
          ? 'are-you-sure-you-want-to-remove-collaborator-from-company'
          : 'make-as-owner',
      Intent: 'manage-collaborators',
    });
  };

  const clickInvitationAction = ({
    action,
    collaboratorRoleUniqueName,
    approvalAmountThreshold,
  }: {
    action: InvitationAction;
    collaboratorRoleUniqueName?: RoleUniqueNames | null;
    approvalAmountThreshold?: number | null;
  }) => {
    const commonProps = {
      ManagedUserRole: collaboratorRoleUniqueName,
      TreshholdAmount: approvalAmountThreshold,
      InvitationStatus: 'pending',
    };
    track('Settings', 'Click', {
      ...actionEventProp,
      ...commonProps,
      Cta: action === 'resend' ? 'Resend invitation' : 'Remove user',
      Intent: 'actions-on-collaborator',
    });
    if (action === 'cancel') {
      track('Settings', 'View', {
        ...commonProps,
        PageName: 'are-you-sure-you-want-to-remove-collaborator-from-company',
        Intent: 'cancel-invitation',
      });
    }
  };

  return {
    clickAddCollaborator,
    clickAddAccountingFirm,
    clickCollaboratorAction,
    clickInvitationAction,
  };
};
