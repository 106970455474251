/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { AxiosError } from 'axios';
import {
  OAuthAccountCreated,
  OAuthAccountInfo,
  OAuthAccountNotCreated,
  OAuthAccountNotCreatedReason,
  OAuthAccountStatus,
  OAuthSelectedOrgId,
  OAuthUserConsent,
} from '@melio/platform-api';

import { WithLoading } from '@/hoc/withLoading.hoc';
import { useOAuthRegister } from '@/hooks/auth.hooks';
import { usePartnerConfig } from '@/hooks/partners';
import { OAuthReasonsScreen } from './OAuthReasons.screen';

type OAuthRegisterScreenParams = {
  encryptedProviderData: string;
  onSuccess: (data: OAuthAccountCreated) => void;
  onError: (error: AxiosError<unknown, unknown>) => void;
};

export const OAuthRegisterScreen = ({
  encryptedProviderData: _encryptedProviderData,
  onSuccess,
  onError,
}: OAuthRegisterScreenParams) => {
  const [currentReasons, setCurrentReasons] = React.useState<OAuthAccountNotCreatedReason[] | null>(null);
  const [encryptedProviderData, setEncryptedProviderData] = React.useState<string>(_encryptedProviderData);

  const { partnerName } = usePartnerConfig();

  const onOAuthRegisterSuccessHandler: Parameters<typeof useOAuthRegister>[0]['onSuccess'] = React.useCallback(
    async (data) => {
      if (data.status !== OAuthAccountStatus.Created) {
        const { providerData: providerDataApi, reasons } = data.info as OAuthAccountNotCreated;
        setCurrentReasons(reasons);
        setEncryptedProviderData(providerDataApi);
      } else {
        onSuccess(data.info as OAuthAccountCreated);
      }
    },
    [partnerName, setEncryptedProviderData, onSuccess],
  );

  const { data, isLoading, oAuthRegister } = useOAuthRegister({
    onSuccess: onOAuthRegisterSuccessHandler,
    onError,
  });

  const sendOAuthRegisterRequest = React.useCallback(
    ({
      accountInfo,
      selectedOrgId,
      userConsent,
    }: {
      accountInfo: OAuthAccountInfo;
      selectedOrgId: OAuthSelectedOrgId;
      userConsent: OAuthUserConsent;
    }) => {
      oAuthRegister({
        partnerName: partnerName!,
        providerData: encryptedProviderData,
        accountInfo,
        selectedOrgId,
        userConsent,
      });
    },
    [oAuthRegister, partnerName, encryptedProviderData],
  );

  React.useEffect(() => {
    sendOAuthRegisterRequest({
      accountInfo: {},
      selectedOrgId: undefined,
      userConsent: undefined,
    });
  }, []);

  return (
    <WithLoading isLoading={isLoading} isAbsoluteCenter>
      {currentReasons ? (
        <OAuthReasonsScreen
          {...(data?.info as OAuthAccountNotCreated)}
          reasons={currentReasons}
          onReasonsDone={sendOAuthRegisterRequest}
        />
      ) : (
        <></>
      )}
    </WithLoading>
  );
};
