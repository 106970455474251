import { NakedButton } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

type Props = { onClick: VoidFunction; isDisabled?: boolean };
export const AddAction = ({ onClick, isDisabled }: Props) => {
  const { formatMessage } = useMelioIntl();
  return (
    <NakedButton
      data-testid="approval-action-add-step"
      variant="secondary"
      label={formatMessage('activities.create-approval-workflows.actions.addNew.button')}
      isDisabled={isDisabled}
      onClick={() => onClick()}
    />
  );
};
