import { useMelioIntl } from '@melio/ar-domain';
import { Button, Group, Icon, IconButton, SearchBar } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export type CustomersTableToolbarProps = {
  onCreateCustomer?: VoidFunction;
  onImportCustomers: VoidFunction;
  search?: string;
  onSearch: (value: string) => void;
  isCustomersImportEnabled: boolean;
};

export const CustomersTableToolbar = forwardRef<CustomersTableToolbarProps>(
  ({ isCustomersImportEnabled, onImportCustomers, onCreateCustomer, search, onSearch, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    return (
      <Group
        data-component={CustomersTableToolbar.displayName}
        width="full"
        justifyContent="space-between"
        data-testid="customers-table-toolbar"
        {...props}
        ref={ref}
      >
        <SearchBar
          value={search}
          onChange={(e) => onSearch(e.target.value)}
          placeholder={formatMessage('ar.dashboard.activities.customersTable.searchInput.placeholder')}
          label={formatMessage('ar.dashboard.activities.customersTable.searchInput.label')}
          onClear={() => onSearch('')}
          aria-label={formatMessage('ar.dashboard.activities.customersTable.searchInput.aria-label')}
          instructionsText={formatMessage('ar.dashboard.activities.customersTable.searchInput.instructions')}
        />
        <Group spacing="s" variant="horizontal">
          <IconButton icon="upload" onClick={isCustomersImportEnabled ? onImportCustomers : undefined} />
          <Button
            variant="tertiary"
            onClick={onCreateCustomer}
            label={formatMessage('ar.dashboard.activities.customersTable.newCustomer.button.label')}
            leftElement={<Icon size="small" type="add" color="inherit" aria-hidden />}
            data-testid="new-customer-button"
          />
        </Group>
      </Group>
    );
  }
);
CustomersTableToolbar.displayName = 'CustomersTableToolbar';
