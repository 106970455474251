import { CompanyType, Organization } from '@melio/platform-api-axios-client';

import { OrganizationFullyExpanded } from './types';

export const getOrganizationsPaymentsStatuses = (organizations: OrganizationFullyExpanded[]) => {
  const paymentsStatusInitialValues = {
    bills: 0,
    pending: 0,
    scheduled: 0,
    failed: 0,
  };

  return organizations.reduce((acc, organization) => {
    const paymentsOverview = organization.billsAndPaymentsOverview;
    return {
      bills: acc.bills + paymentsOverview.unpaid.count,
      pending: acc.pending + paymentsOverview.pending.count,
      scheduled: acc.scheduled + paymentsOverview.scheduled.count,
      failed: acc.failed + paymentsOverview.failed.count,
    };
  }, paymentsStatusInitialValues);
};

export const isAccountingFirmOrganization = (organization: Organization) =>
  organization.companyType === CompanyType.AccountingFirm;
