import { usePlaidAuth } from '@melio/ap-widgets';
import { AccountIntegrationType } from '@melio/platform-provider';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { AddBankAccountActivityProps, AddBankAccountActivityStep } from './types';

export const BANK_INTEGRATION_TO_STEP: Record<AccountIntegrationType, AddBankAccountActivityStep> = {
  plaid: 'ADD_ACH_PLAID',
  deposits: 'ADD_ACH_MICRO_DEPOSITS',
};

export const useAddBankAccountActivityStep = ({
  onFirstStepBack,
  isReceivingMethodFlow,
}: {
  onFirstStepBack: AddBankAccountActivityProps['onBack'];
  isReceivingMethodFlow?: boolean;
}) => {
  const [searchParams] = useSearchParams();

  const { enabled: shouldForcePlaidAuth } = usePlaidAuth({ isReceivingMethodFlow });

  const calculateDefaultStep = () => {
    if (shouldForcePlaidAuth) {
      return BANK_INTEGRATION_TO_STEP.plaid;
    }
    return searchParams.has('integrationType')
      ? BANK_INTEGRATION_TO_STEP[searchParams.get('integrationType') as AccountIntegrationType]
      : 'ADD_ACH_INTEGRATION_SELECTION';
  };
  const defaultStep = calculateDefaultStep();
  const [currentStep, goToStep] = useState<AddBankAccountActivityStep>(defaultStep);

  const goToPreviousStep = () => {
    if (currentStep === 'ADD_ACH_INTEGRATION_SELECTION' || shouldForcePlaidAuth) {
      onFirstStepBack();
    } else {
      goToStep('ADD_ACH_INTEGRATION_SELECTION');
    }
  };

  return { currentStep, goToStep, goToPreviousStep };
};
