import { useDeliveryMethodFee } from '@melio/ap-widgets';
import { FormattedMessage } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';

export const useDescriptionText = () => {
  const { getFee } = useDeliveryMethodFee('bank-account');
  const {
    settings: { unilateralRequest },
  } = useConfig();

  if (unilateralRequest.announceFees) {
    const achFee = getFee('bank-account');
    const checkFee = getFee('paper-check');

    if (achFee && checkFee) {
      return (
        <FormattedMessage
          id="activities.requestVendorUnilateralDetails.screens.requestVendorUnilateralDetails.descriptionWithFees"
          values={{ achFee, checkFee }}
        />
      );
    }
  }

  return (
    <FormattedMessage id="activities.requestVendorUnilateralDetails.screens.requestVendorUnilateralDetails.description" />
  );
};
