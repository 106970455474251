import { Container, Group, Layout, LoadingContainer } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';

import { FlowHeader } from '../../components/FlowHeader/FlowHeader.component';
import { useAccountantsRoutes } from '../../utils/useAccountantsRoutes';
import { OptionSelectionCard } from './components/OptionSelectionCard';

type ClientCreationOptionsProps = {
  onClose: VoidFunction;
  intuitConnect: {
    loginToQuickBooks: () => void;
    isLoading: boolean;
  };
};

export const ClientCreationOptions = withAnalyticsContext<ClientCreationOptionsProps>(
  ({ onClose, intuitConnect, setAnalyticsProperties }) => {
    const { formatMessage } = useMelioIntl();
    const { track } = useAnalytics();
    const { goToAddNewClient, goToInviteExistingClient } = useAccountantsRoutes();

    setAnalyticsProperties({
      PageName: 'add-client',
      Flow: 'manage-my-clients',
      Intent: 'manage-my-clients',
    });

    useEffect(() => {
      track('User', 'View');
    }, [track]);

    const trackOnNewClientAction = (flow: string) => {
      track('User', 'Click', {
        Cta: 'add-client',
        Intent: 'add-client',
        AddClientMethod: flow,
      });
    };

    return (
      <Layout variant="full">
        <LoadingContainer isLoading={intuitConnect.isLoading}>
          <Container justifyContent="center" height="full">
            <Group spacing="m" variant="vertical" width={{ xs: '100%', s: '800px' } as never}>
              <FlowHeader
                title={formatMessage('activities.accountants.clientCreationOptions.header.text')}
                onClose={onClose}
              />
              <OptionSelectionCard
                icon="user-add"
                testId="firm-new-client"
                title={formatMessage('activities.accountants.clientCreationOptions.option.newClient.header.label')}
                description={formatMessage(
                  'activities.accountants.clientCreationOptions.option.newClient.description.label'
                )}
                onClick={() => {
                  trackOnNewClientAction('manually');
                  goToAddNewClient();
                }}
              />
              <OptionSelectionCard
                icon="email"
                testId="firm-invite-client"
                title={formatMessage('activities.accountants.clientCreationOptions.option.inviteClient.header.label')}
                description={formatMessage(
                  'activities.accountants.clientCreationOptions.option.inviteClient.description.label'
                )}
                onClick={() => {
                  trackOnNewClientAction('connect-with-exising-smb');
                  goToInviteExistingClient();
                }}
              />
              <OptionSelectionCard
                icon="quickbooks"
                testId="firm-import-qbo"
                title={formatMessage('activities.accountants.clientCreationOptions.option.importQBO.header.label')}
                description={formatMessage(
                  'activities.accountants.clientCreationOptions.option.importQBO.description.label'
                )}
                onClick={() => {
                  trackOnNewClientAction('qb-import');
                  intuitConnect.loginToQuickBooks();
                }}
              />
            </Group>
          </Container>
        </LoadingContainer>
      </Layout>
    );
  }
);
