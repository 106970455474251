import { ComponentThemeType } from '@/cl/theme/theme.hooks';

export type ListType = 'groupedLists' | 'payCardList';

export const GrouppedListTheme: ComponentThemeType = (currentTheme) => ({
  baseStyle: {
    container: {
      gridGap: '32px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    group: {
      width: '100%',
      gridGap: '8px',
      display: 'flex',
      flexDirection: 'column',
    },
    list: {
      display: 'flex',
      flexDirection: 'column',
      gridGap: '16px',
    },
    groupTitle: {
      color: currentTheme.colors.global.neutral['900'],
    },
  },
  sizes: {
    small: {
      groupTitle: {
        ...currentTheme.textStyles.body4Semi,
      } as never,
    },
    medium: {
      groupTitle: {
        ...currentTheme.textStyles.body2Semi,
      } as never,
    },
    large: {
      groupTitle: {
        ...currentTheme.textStyles.heading2Semi,
      } as never,
    },
  },
});
