import { useDisclosure } from '@chakra-ui/react';
import { useReceivingMethods } from '@melio/ar-domain';
import { useCallback, useRef } from 'react';

import { OnBeforeDone, OnBeforeDoneOptions } from '../types';

type UseReceivingMethodsDialogActionsProps = {
  onOpen?: VoidFunction;
  onClose?: VoidFunction;
  onDone?: VoidFunction;
  onError?: ARErrorFunction;
};

// this hook shares the "onDone" and "onClose" functions between IssueInvoiceActivity and AddReceivingMethodModalActivity
// we need to get those from IssueInvoiceActivity using `onAddReceivingMethod` and pass them to AddReceivingMethodModalActivity
// we use a ref and not a state in order to avoid re-rendering the component
export const useReceivingMethodsDialogActions = ({
  onOpen,
  onClose,
  onDone,
  onError,
}: UseReceivingMethodsDialogActionsProps = {}) => {
  const { data } = useReceivingMethods({ onError });

  const disclosure = useDisclosure({ onOpen, onClose });

  const onAddReceivingMethodRef = useRef<OnBeforeDoneOptions>();

  const handleAddReceivingMethod: OnBeforeDone = useCallback(
    (onDone, onClose) => {
      onAddReceivingMethodRef.current = { onDone, onClose };
      if (data?.length) void postAddReceivingMethod();
      else disclosure.onOpen();
    },
    [data?.length ?? 0] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const postAddReceivingMethod = useCallback(async () => {
    await onAddReceivingMethodRef.current?.onDone();
    onDone?.();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onCloseReceivingMethodModal = useCallback(() => {
    onAddReceivingMethodRef.current?.onClose?.();
    disclosure.onClose();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    onDone: postAddReceivingMethod,
    onClose: onCloseReceivingMethodModal,
    onOpen: handleAddReceivingMethod,
    isOpen: disclosure.isOpen,
  };
};
