import { getIsBillHasPaymentsInProgress, useBillActions } from '@melio/ap-domain';
import { ActionBar, ActionsDropdownMenu, ActionsDropdownMenuProps, Button, NakedButton } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { Bill, PaymentRequest, useBills, usePaymentRequests } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { sumBy } from '@melio/platform-utils';
import _compact from 'lodash/compact';
import React, { useState } from 'react';

import { useActivitiesNavigate } from '../../../../../utils';
import { BILL_SELECTION_FOOTER_REVIEW_AND_PAY_BUTTON_ID } from '../consts';
import { SelectedInboxItemType } from '../types';

export type BillItemsSelectionFooterProps = {
  billItems: SelectedInboxItemType[];
  totalCount: number;
  onDeleteBillItems: () => void;
  getBillIdsToReviewAndPay: ({
    paymentRequests,
    bills,
  }: {
    paymentRequests: PaymentRequest[];
    bills: Bill[];
  }) => Promise<string[] | null>;
  onCancel: VoidFunction;
};

export const BillItemsSelectionFooter: React.FC<BillItemsSelectionFooterProps> = ({
  billItems,
  onDeleteBillItems,
  getBillIdsToReviewAndPay,
  totalCount,
  onCancel,
}) => {
  const { track } = useAnalytics();
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);
  const [isReviewAndPaySubmitting, setIsReviewAndPaySubmitting] = useState(false);
  const [isBatchDeleteBillsEnabled] = useFeature(FeatureFlags.BatchDeleteBills, false);
  const { formatMessage, formatCurrency } = useMelioIntl();
  const { navigateToBatchPayment } = useActivitiesNavigate();
  const { getBillActions } = useBillActions();

  const billIds = billItems.filter((item) => item.type === 'bill').map((item) => item.id);
  const paymentRequestIds = billItems.filter((item) => item.type === 'paymentRequest').map((item) => item.id);

  const { data: bills = [], isLoading: isLoadingBills } = useBills({
    enabled: billIds.length > 0,
    params: { search: { 'bill.id': billIds }, expand: ['payments', 'vendor'] },
  });
  const { data: paymentRequests = [], isLoading: isLoadingPaymentRequests } = usePaymentRequests({
    enabled: paymentRequestIds.length > 0,
    params: { search: { 'paymentRequest.id': paymentRequestIds }, expand: 'vendor' },
  });

  const billsTotalBalance = sumBy(bills, (bill) => bill.balance);
  const paymentRequestsTotalBalance = sumBy(paymentRequests, (paymentRequest) => paymentRequest.totalAmount);
  const totalBalance = billsTotalBalance + paymentRequestsTotalBalance;

  const handleReviewAndPay = async () => {
    track('Dashboard', 'Click', {
      Intent: 'pay-bills',
      Cta: 'review-and-pay',
      BillsAmount: billsTotalBalance,
      BillsSelected: billIds,
      PaymentRequestsAmount: paymentRequestsTotalBalance,
      PaymentRequestsSelected: paymentRequestIds,
    });

    setIsReviewAndPaySubmitting(true);

    const selectedBillIds = await getBillIdsToReviewAndPay({ paymentRequests, bills });

    setIsReviewAndPaySubmitting(false);

    if (selectedBillIds) {
      navigateToBatchPayment({ billIds: selectedBillIds });
    }
  };

  const handleDeleteBills = () => {
    track('Dashboard', 'Click', {
      Intent: 'delete-bill',
      Cta: 'delete-bills',
      BillsAmount: billsTotalBalance,
      BillsSelected: billIds,
      PaymentRequestsAmount: paymentRequestsTotalBalance,
      PaymentRequestsSelected: paymentRequestIds,
    });
    setIsMoreMenuOpen(false);
    onDeleteBillItems();
  };

  const handleCancel = () => {
    track('Dashboard', 'Click', {
      Cta: 'cancel',
      BillsAmount: billsTotalBalance,
      BillsSelected: billIds,
      PaymentRequestsAmount: paymentRequestsTotalBalance,
      PaymentRequestsSelected: paymentRequestIds,
    });

    onCancel();
  };

  const undeletableBills =
    bills?.filter((bill) => getIsBillHasPaymentsInProgress(bill) || !getBillActions(bill).actions.delete) ?? [];

  const isLoading = isLoadingBills || isLoadingPaymentRequests;
  const selectedItemsCount = bills.length + paymentRequests.length;

  return (
    <ActionBar
      id="bills-selection-footer"
      isLoading={isLoading}
      isOpen={billItems.length > 0}
      summaryItems={[
        {
          label: formatMessage('activities.payDashboard.billsTab.selectionFooter.allBills'),
          value: totalCount.toString(),
          testId: 'bills-selection-footer-all-bills-count',
        },
        {
          label: formatMessage('activities.payDashboard.billsTab.selectionFooter.selectedBills'),
          value: selectedItemsCount.toString(),
          testId: 'bills-selection-footer-bills-count',
        },
        {
          label: formatMessage('activities.payDashboard.billsTab.selectionFooter.totalAmount'),
          value: formatCurrency(totalBalance),
          testId: 'bills-selection-footer-total-amount',
        },
      ]}
      actions={_compact([
        {
          component: NakedButton,
          testId: 'bills-selection-footer-cancel-btn',
          props: {
            label: formatMessage('activities.payDashboard.billsTab.selectionFooter.cancel.label'),
            'aria-label': formatMessage('activities.payDashboard.billsTab.selectionFooter.cancel.ariaLabel', {
              totalAmount: formatCurrency(totalBalance),
              count: selectedItemsCount,
            }),
            onClick: handleCancel,
            variant: 'invert',
            isDisabled: isReviewAndPaySubmitting,
          },
        },
        isBatchDeleteBillsEnabled
          ? {
              component: ActionsDropdownMenu,
              testId: 'bills-selection-footer-more-btn',
              props: {
                variant: 'inverted',
                isOpen: isMoreMenuOpen,
                onOpenChange: setIsMoreMenuOpen,
                triggerAriaLabel: formatMessage('activities.payDashboard.billsTab.selectionFooter.moreMenu.ariaLabel', {
                  totalAmount: formatCurrency(totalBalance),
                  billsCount: selectedItemsCount,
                }),
                isDisabled: isReviewAndPaySubmitting,
                label: formatMessage('activities.payDashboard.billsTab.selectionFooter.moreMenu.label'),
                items: [
                  {
                    label: formatMessage('activities.payDashboard.billsTab.selectionFooter.batchDelete.label'),
                    onClick: handleDeleteBills,
                    variant: 'critical',
                    dataTestId: 'bills-selection-footer-delete-btn',
                    disabled: {
                      isDisabled: paymentRequests.length === 0 && undeletableBills.length === billIds.length,
                      message: formatMessage('activities.payDashboard.billsTab.selectionFooter.deleteDisabledMessage'),
                    },
                    'aria-label': formatMessage(
                      'activities.payDashboard.billsTab.selectionFooter.batchDelete.ariaLabel',
                      {
                        totalAmount: formatCurrency(totalBalance),
                        billsCount: selectedItemsCount,
                      }
                    ),
                  },
                ] as ActionsDropdownMenuProps['items'],
              },
            }
          : null,
        {
          component: Button,
          testId: 'bills-selection-footer-pay-btn',
          props: {
            id: BILL_SELECTION_FOOTER_REVIEW_AND_PAY_BUTTON_ID,
            label: formatMessage('activities.payDashboard.billsTab.selectionFooter.reviewAndPay.label'),
            onClick: handleReviewAndPay,
            'aria-label': formatMessage('activities.payDashboard.billsTab.selectionFooter.reviewAndPay.ariaLabel', {
              totalAmount: formatCurrency(totalBalance),
              billsCount: selectedItemsCount,
            }),
            isLoading: isReviewAndPaySubmitting,
            isDisabled: isReviewAndPaySubmitting,
          },
        },
      ])}
    />
  );
};
