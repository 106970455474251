import { Container, Grid, Illustration, Layout, Link, Text } from '@melio/penny';
import { useAccount } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { PageTitle } from '@melio/platform-utils';
import { Navigate } from 'react-router-dom';

import { useIsSubscriptionsEnabled } from '../../../hooks';

export const GraceEndPeriodNonOwner = () => {
  const { formatMessage } = useMelioIntl();
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const { data: accountData } = useAccount({ id: 'me', enabled: isSubscriptionsEnabled });

  const {
    settings: {
      vex: { zdSupportFormUrl },
    },
  } = useConfig();

  if (!isSubscriptionsEnabled) {
    return <Navigate to="/error" />;
  }

  const companyName = accountData?.company.name;
  const owner = accountData?.organizationOwner;
  const ownerName = `${owner?.firstName || ''} ${owner?.lastName || ''}`;

  return (
    <Layout variant="6Columns" backgroundColor="white">
      <Container
        paddingX="xxs"
        paddingY="xxs"
        height="full"
        textAlign="center"
        data-testid="subscription-grace-end-period-non-owner"
      >
        <Grid justifyItems="center" alignContent="center" height="full" textAlign="center" gap="s">
          <Illustration size="large" type="blocked" />
          <Grid gap="s">
            <PageTitle textStyle="heading2Semi">
              {formatMessage('activities.subscription.graceEndPeriod.nonOwner.title')}
            </PageTitle>

            <Text color="neutral.darkest" data-testid="subscription-grace-end-period-non-owner-description">
              {formatMessage('activities.subscription.graceEndPeriod.nonOwner.description', {
                companyName,
                ownerName,
              })}
            </Text>
            <Text>
              {formatMessage('activities.subscription.graceEndPeriod.nonOwner.contact', {
                linkToSupport: (
                  <Link
                    data-testid="subscription-grace-end-period-non-owner-support-link"
                    href={zdSupportFormUrl}
                    color="default"
                    label={formatMessage('activities.subscription.graceEndPeriod.nonOwner.linkToSupportText')}
                  />
                ),
              })}
            </Text>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};
