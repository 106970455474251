import { Link, useBreakpoint } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { PayDashboardEmptyState } from '../../PayDashboardEmptyState';
import { PayDashboardClearedState } from '../../PayDashboardEmptyState/PayDashboardClearedState';
import { WORKFLOW_SETTINGS_RELPATH } from '../consts';

export const ApprovalsTabEmptyStateV1 = ({ onLinkClick }: { onLinkClick: VoidFunction }) => {
  const { formatMessage } = useMelioIntl();
  const { isExtraSmallScreen } = useBreakpoint();

  if (isExtraSmallScreen) {
    return (
      <PayDashboardClearedState
        title={formatMessage('activities.payDashboard.approvalsTab.emptyState.title')}
        aria-label={formatMessage('activities.payDashboard.approvalsTab.emptyState.ariaLabel')}
        description={formatMessage('activities.payDashboard.approvalsTab.emptyState.link.description', {
          link: (
            <Link
              variant="inline"
              data-testid="approvals-tab-empty-state-manage-link"
              href={WORKFLOW_SETTINGS_RELPATH}
              label={formatMessage('activities.payDashboard.approvalsTab.emptyState.viewApprovalsLink')}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onLinkClick();
              }}
            />
          ),
        })}
        illustrationSrc="pay-zero"
      />
    );
  }

  return (
    <PayDashboardEmptyState
      title={formatMessage('activities.payDashboard.approvalsTab.emptyState.title')}
      aria-label={formatMessage('activities.payDashboard.approvalsTab.emptyState.ariaLabel')}
      description={formatMessage('activities.payDashboard.approvalsTab.emptyState.link.description', {
        link: (
          <Link
            variant="inline"
            data-testid="approvals-tab-empty-state-manage-link"
            href={WORKFLOW_SETTINGS_RELPATH}
            label={formatMessage('activities.payDashboard.approvalsTab.emptyState.manageApprovalsLink')}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onLinkClick();
            }}
          />
        ),
      })}
      getAnimationData={() => import('../../../assets/approval.lottie.json')}
    />
  );
};
