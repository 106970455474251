import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { getAdditionalWatchPropsStorageKey } from '../../hooks/useFormSessionStorage';
import { SESSION_STORAGE_FORM_DATA_KEY } from '../NewFirmClient/components/NewClientForm/NewClientForm';

export const EmptyNewFirmClient = () => {
  useEffect(() => {
    sessionStorage.removeItem(SESSION_STORAGE_FORM_DATA_KEY);
    sessionStorage.removeItem(getAdditionalWatchPropsStorageKey(SESSION_STORAGE_FORM_DATA_KEY));
  }, []);

  return <Navigate to="/accountants/client/new" replace />;
};
