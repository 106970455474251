import { PaymentsSortQueryParam } from '@melio/platform-api';

import { PayDashboardSortingType } from '../../types';

export const convertPaymentsTabSortToApiSort = ({
  id,
  sortDirection,
}: NonNullable<PayDashboardSortingType['sortingState']>): PaymentsSortQueryParam[] => {
  if (id === 'vendorName') {
    return [{ field: 'vendor.name', order: sortDirection }];
  }
  if (id === 'debitDate') {
    return [
      { field: 'scheduledDate', order: sortDirection },
      { field: 'vendor.name', order: 'asc' },
    ];
  }
  if (id === 'deliveryDate') {
    return [
      { field: 'estimatedDelivery', order: sortDirection },
      { field: 'vendor.name', order: 'asc' },
    ];
  }
  if (id === 'amount') {
    return [
      { field: 'amount', order: sortDirection },
      { field: 'vendor.name', order: 'asc' },
    ];
  }
  return [{ field: 'updatedAt', order: sortDirection }];
};
