/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
import { isEbill, useBillActions, useIsNewBillExperienceEnabled } from '@melio/ap-domain';
import { BillDetailsWidgetAccessibleNew as BillDetailsWidget } from '@melio/ap-widgets';
import {
  ActionsDropdownMenu,
  ActionsDropdownMenuItemProps,
  Button,
  Drawer,
  Group,
  Loader,
  Text,
  useBreakpoint,
  useBreakpointValue,
} from '@melio/penny';
import { useAnalytics, useAnalyticsContext, withAnalyticsContext } from '@melio/platform-analytics';
import { Bill, useAccountingPlatforms, useBill } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { SystemMessageProvider, useLocation as useLocationFromUtils, useSystemMessage } from '@melio/platform-utils';
import { compact } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { createSearchParams, useLocation, useSearchParams } from 'react-router-dom';

import { useActivitiesNavigate, useMonitorTiming } from '../../../../utils';
import { AddBillV2OriginEnum } from '../../../add-bill/AddBillV2Form/types';
import { PayDashboardTabs } from '../../types';
import { useEditBill } from './hooks/useEditBill';

export type BillsDrawerProps = {
  id: string;
  onClose: () => void;
  onDeleteBillClick: ({ id }: { id: string }) => void;
  onMarkAsPaidClick: ({ id }: { id: string }) => void;
  onEditBill: ({ id }: { id: string; returnUrl?: string }) => void;
};

export const BillDrawer = (props: BillsDrawerProps) => (
  <SystemMessageProvider>
    <BillDrawerContent {...props} />
  </SystemMessageProvider>
);

const BillDrawerContent = withAnalyticsContext(
  ({ id, onClose, onDeleteBillClick, onMarkAsPaidClick, onEditBill }: BillsDrawerProps) => {
    const { track } = useAnalytics();
    const [searchParams, setSearchParams] = useSearchParams({});
    const isEditMode = Boolean(searchParams.get('edit'));
    const { formatCurrency, formatMessage } = useMelioIntl();
    const { isNewBillExperienceEnabled } = useIsNewBillExperienceEnabled();
    const { showMessage } = useSystemMessage();
    const [isClosing, setIsClosing] = useState(false);
    const { pathname, search } = useLocation();
    const shouldReturnFocus = useRef(!!document.activeElement && document.activeElement !== document.body);
    const location = useLocationFromUtils<{ id: string; origin: AddBillV2OriginEnum }>();
    const isAfterEdit = location?.state?.origin === AddBillV2OriginEnum.Edit;
    const isMobile = useBreakpointValue({ xs: true, s: false }, { ssr: false } as never);
    const [isLineItemsEnabledOnMobile] = useFeature<boolean>(FeatureFlags.IsLineItemsEnabledOnMobile, true);
    const isEditActionEnabled = isMobile ? isLineItemsEnabledOnMobile : true;

    const {
      data: bill,
      isLoading: isWaitingForBill,
      isFetching: isFetchingBill,
    } = useBill({ id, refetchOnMount: 'always' });
    const { activeAccountingPlatform } = useAccountingPlatforms();
    const { getBillActions } = useBillActions();
    const legacyRouteReady = useMonitorTiming('bill_drawer_ready');
    const { routeReady } = useMonitoring();
    const triggerMonitoring = (el: HTMLDivElement) => {
      legacyRouteReady(el);
      routeReady(el);
    };

    useAnalyticsContext({
      globalProperties: {
        PageName: isEditMode ? 'bill-edit' : 'bill-view',
        EntryPoint: 'view-bill',
        FileAttached: !!bill?.invoice.fileId,
        BillId: id,
        Amount: bill?.amount,
        Currency: bill?.vendor?.currency as string,
        UsdAmount: bill?.amount && bill?.amount * (bill?.usdToForeignRate ?? 1),
      },
    });

    useEffect(() => {
      if (isWaitingForBill) {
        return;
      }
      track('Bill', 'View', {
        Intent: isEditMode ? 'edit-bill' : 'view-bill',
        FileAttached: !!bill?.invoice.number,
        Amount: bill?.amount,
        isPartial: bill?.balance && bill?.amount && bill.amount > bill.balance && bill.balance > 0,
        Note: bill?.note,
        VendorEmail: bill?.vendor?.contact.email,
        DueDate: bill?.dueDate,
      });
    }, [isWaitingForBill]);

    const exitEditMode = () => {
      setSearchParams((params) => {
        params.delete('edit');
        return params;
      });
    };

    const handleClose = () => {
      exitEditMode();
      onClose();
    };

    const onEditSuccess = (shouldClose?: boolean) => {
      exitEditMode();

      if (shouldClose) {
        setIsClosing(true);
      }

      showMessage({
        type: 'informative',
        title: formatMessage('activities.payDashboard.billsTab.editBill.toast.successMessage'),
      });
    };

    const onEditError = () => {
      track('Bill', 'Status', { Intent: 'edit-bill', Status: 'failure' });
      showMessage({
        type: 'error',
        title: formatMessage('activities.payDashboard.billsTab.editBill.toast.errorMessage'),
      });
    };

    const { onSubmissionStateChange, submitButtonProps, setFile, handleSubmit, isSaving, reset } = useEditBill({
      id,
      onEditSuccess,
      onEditError,
    });

    useEffect(() => {
      if (!isEditMode) {
        reset?.();
      }
    }, [isEditMode]);

    useEffect(() => {
      if (isAfterEdit) {
        showMessage({
          type: 'success',
          title: formatMessage('activities.addBillV2.billForm.edit.success.toast', {
            amount: formatCurrency(bill?.amount || 0, bill?.currency || 'USD'),
            vendorName: bill?.vendor?.name || '',
          }),
        });
      }
    }, [isAfterEdit]);

    const handleEditBillClick = () => {
      const returnUrl = `${pathname}?${createSearchParams(search).toString()}`;
      if (isNewBillExperienceEnabled) {
        onEditBill({ id, returnUrl });
      } else {
        setSearchParams({ edit: 'true' });
      }
    };

    const handleCancelEditClick = () => {
      exitEditMode();
    };

    const handleDeleteBillClick = () => {
      if (id) {
        onDeleteBillClick({ id });
      }
    };

    const handleMarkAsPaidClick = () => {
      onMarkAsPaidClick({ id });
    };

    const handleCloseClick = () => {
      track('Bill', 'Click', {
        Intent: 'exit',
        Cta: 'exit',
      });
      setIsClosing(true);
    };

    const shouldShowFooter = !!bill && getBillActions(bill).hasActions;

    return (
      <Drawer
        isOpen={!isClosing && (!!bill || isWaitingForBill)}
        shouldReturnFocus={shouldReturnFocus.current}
        onClose={handleCloseClick}
        onCloseComplete={handleClose}
        closeButtonAriaLabel={formatMessage('activities.payDashboard.drawer.header.bill.closeButtonAriaLabel')}
        body={
          bill && !isFetchingBill ? (
            <BillDetailsWidget
              ref={triggerMonitoring}
              bill={bill}
              onInvoiceChange={setFile}
              onSubmissionStateChange={onSubmissionStateChange}
              onSubmit={handleSubmit}
              isEditMode={isEditMode}
              isSaving={isSaving}
              showOpenBalance
              activeAccountingPlatform={activeAccountingPlatform}
              hideHeaderTitle
            />
          ) : (
            <Loader aria-live="polite" />
          )
        }
        header={
          <Text as="h2" textStyle="heading2Semi">
            {formatMessage('activities.payDashboard.drawer.header.bill.title')}
          </Text>
        }
        footer={
          shouldShowFooter ? (
            <BillDrawerFooter
              bill={bill}
              isEditMode={isEditMode}
              isEditActionEnabled={isEditActionEnabled}
              onEditClick={handleEditBillClick}
              onCancelEditClick={handleCancelEditClick}
              submitButtonProps={submitButtonProps}
              onDeleteClick={handleDeleteBillClick}
              onMarkAsPaidClick={handleMarkAsPaidClick}
            />
          ) : null
        }
        data-testid="bill-drawer"
      />
    );
  }
);

type ActionBarSubmitButtonProps = {
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick: VoidFunction;
};
export type BillDrawerFooterPropsType = {
  bill: Bill;
  onEditClick: () => void;
  onDeleteClick: () => void;
  onCancelEditClick: () => void;
  submitButtonProps?: ActionBarSubmitButtonProps;
  isEditMode?: boolean;
  isEditActionEnabled?: boolean;
  onMarkAsPaidClick: () => void;
};

export const BillDrawerFooter = ({
  bill,
  onEditClick,
  onDeleteClick,
  onCancelEditClick,
  isEditMode,
  submitButtonProps,
  isEditActionEnabled,
  onMarkAsPaidClick,
}: BillDrawerFooterPropsType) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { track } = useAnalytics();
  const { isExtraSmallScreen } = useBreakpoint();
  const { formatMessage } = useMelioIntl();
  const { getBillActions } = useBillActions();
  const { actions } = getBillActions(bill);
  const { navigateToSchedulePayment, generateNPEDashboardLink } = useActivitiesNavigate();

  const handleCancelEditClick = () => {
    track('Bill', 'Click', {
      Intent: 'edit-bill',
      Cta: 'cancel',
    });
    onCancelEditClick();
  };

  const handleSubmitEditClick = () => {
    track('Bill', 'Click', {
      Intent: 'edit-bill',
      Cta: 'save',
    });
    submitButtonProps?.onClick();
  };

  if (isEditMode) {
    return (
      <Group justifyContent="space-between" width="full" data-testid="bills-tab-drawer-footer">
        <Button
          variant="tertiary"
          label={formatMessage('activities.payDashboard.drawer.footer.bill.editMode.cancel')}
          onClick={handleCancelEditClick}
          data-testid="bill-drawer-footer-cancel-button"
        />
        <Button
          {...submitButtonProps}
          variant="primary"
          label={formatMessage('activities.payDashboard.drawer.footer.bill.editMode.save')}
          onClick={handleSubmitEditClick}
          data-testid="bill-drawer-footer-save-button"
        />
      </Group>
    );
  }

  const actionItems: ActionsDropdownMenuItemProps[] = compact([
    actions.newPayment && {
      label: formatMessage('activities.payDashboard.drawer.footer.bill.pay'),
      onClick: () => {
        track('Bill', 'Click', {
          Intent: 'pay',
          Cta: 'pay',
        });

        isEbill(bill) && bill.eBillAmountDue != null && bill.eBillAmountDue <= 0
          ? navigateToSchedulePayment({
              vendorId: bill.vendorId,
              returnUrl: generateNPEDashboardLink(PayDashboardTabs.Bills),
            })
          : navigateToSchedulePayment({ billId: bill.id, returnUrl: generateNPEDashboardLink(PayDashboardTabs.Bills) });
      },
      dataTestId: 'bill-drawer-footer-pay-button',
    },
    actions.edit &&
      isEditActionEnabled && {
        label: formatMessage('activities.payDashboard.drawer.footer.bill.edit'),
        onClick: () => {
          track('Bill', 'Click', {
            Intent: 'view-bill-actions',
            Cta: 'edit-bill',
          });
          onEditClick();
        },
        dataTestId: 'bill-drawer-edit-action',
      },
    actions.markAsPaid && {
      label: formatMessage('activities.payDashboard.drawer.footer.bill.markAsPaid'),
      onClick: () => {
        track('Bill', 'Click', {
          Intent: 'mark-as-paid',
          Cta: 'more-mark-as-paid',
        });
        onMarkAsPaidClick();
      },
      dataTestId: 'bill-drawer-mark-paid-action',
    },
    actions.delete && {
      label: formatMessage('activities.payDashboard.drawer.footer.bill.delete'),
      onClick: () => {
        track('Bill', 'Click', {
          Intent: 'view-bill-actions',
          Cta: 'delete-bill',
        });
        onDeleteClick();
      },
      variant: 'critical',
      dataTestId: 'bill-drawer-delete-action',
    },
  ]);

  const [primaryItem, ...actionMenuItems] = actionItems;
  const hasMenuItems = actionMenuItems.length > 0;

  return (
    <Group
      justifyContent={hasMenuItems ? 'space-between' : 'flex-end'}
      width="full"
      data-testid="bills-tab-drawer-footer"
      spacing={isExtraSmallScreen ? 's' : 'm'}
    >
      {hasMenuItems ? (
        <ActionsDropdownMenu
          isFullWidthTrigger={isExtraSmallScreen}
          data-testid="bill-drawer-footer-bill-actions-dropdown-menu"
          items={actionMenuItems}
          label={formatMessage('activities.payDashboard.drawer.footer.bill.more')}
          isOpen={isMenuOpen}
          onOpenChange={setIsMenuOpen}
        />
      ) : null}
      {primaryItem ? (
        <Button
          isFullWidth={isExtraSmallScreen}
          variant="primary"
          data-testid={primaryItem.dataTestId}
          onClick={() => primaryItem.onClick?.()}
          label={primaryItem.label}
        />
      ) : null}
    </Group>
  );
};
