import { forwardRef } from '@melio/platform-utils';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';

export type CompleteLegalInfoLoadingScreenProps = {
  onBack?: VoidFunction;
  onClose?: VoidFunction;
};

export const CompleteLegalInfoLoadingScreen = forwardRef<CompleteLegalInfoLoadingScreenProps, 'div'>(
  ({ onBack, onClose, ...props }, ref) => (
    <NewSinglePaymentStepLayout
      data-component="CompleteLegalInfoActivity.CompleteLegalInfoLoadingScreen"
      data-testid="complete-legal-info-activity-complete-legal-info-loading-screen"
      {...props}
      ref={ref}
      isLoading
    />
  )
);

CompleteLegalInfoLoadingScreen.displayName = 'CompleteLegalInfoActivity.CompleteLegalInfoLoadingScreen';
