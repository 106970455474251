import { Box, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { Avatar } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';

import { AvatarDropdownMenuComponentTheme } from '@/cl/components/AvatarDropdownMenu/AvatarDropdownMenu.component.theme';
import {
  DropdownMenuItem,
  DropdownMenuItemComponent,
} from '@/cl/components/AvatarDropdownMenu/DropdownMenuItemComponent/DropdownMenuItem.component';
import { useAppTheme } from '@/cl/theme/theme.hooks';

export type AvatarDropdownMenuProps = {
  userName: string;
  items: DropdownMenuItem[];
};

export const AvatarDropdownMenu = ({ userName, items }: AvatarDropdownMenuProps) => {
  const styles = useAppTheme(AvatarDropdownMenuComponentTheme, {});
  const { track } = useAnalytics();

  const onAvatarClick = () => {
    track('OpenAvatar', 'Chose');
  };

  return (
    <Box sx={styles['container']} data-testid="left-nav-avatar-container">
      <Menu>
        <MenuButton
          as={Avatar}
          name={userName}
          sx={styles['avatar']}
          tabIndex={0}
          role="group"
          onClick={onAvatarClick}
          data-testid="left-layout-avatar-menu"
        />
        <MenuList sx={styles['menuList']}>
          {items.map((item, i) => (
            <DropdownMenuItemComponent key={i} data-testid={`actions-dropdown-menu-item-${i}`} {...item} />
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};
