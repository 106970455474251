import { MessageKey, useMelioIntl } from '@melio/ar-domain';
import { useMemo } from 'react';

import { TabsNames } from '../types';

type UseScreenLabelsProps = {
  shouldSend?: boolean;
};

export const useScreenLabels = ({ shouldSend = false }: UseScreenLabelsProps = {}) => {
  const { formatMessage } = useMelioIntl();

  const headerText = formatMessage('ar.invoiceLifecycle.activities.issueInvoice.form.header.shareInvoice.label');
  const primaryButtonLabel = useMemo(() => formatMessage(getMessages(shouldSend).primary), [formatMessage, shouldSend]);
  const secondaryButtonLabel = useMemo(() => formatMessage(getMessages().secondary), [formatMessage]);
  const tertiaryButtonLabel = useMemo(() => formatMessage(getMessages().tertiary), [formatMessage]);

  const tabs = useMemo<{ name: TabsNames; label: string }[]>(
    () => [
      { name: 'PDF', label: formatMessage(`ar.invoiceLifecycle.activities.issueInvoice.preview.tabs.PDF.name`) },
      { name: 'Email', label: formatMessage(`ar.invoiceLifecycle.activities.issueInvoice.preview.tabs.Email.name`) },
    ],
    [formatMessage]
  );

  return {
    tabs,
    headerText,
    primaryButtonLabel,
    secondaryButtonLabel,
    tertiaryButtonLabel,
  };
};

type Keys = 'primary' | 'secondary' | 'tertiary';

const getMessages = (shouldSend?: boolean): Record<Keys, MessageKey> => ({
  primary: shouldSend
    ? 'ar.invoiceLifecycle.activities.issueInvoice.form.footer.buttons.primary.shareInvoice'
    : 'ar.invoiceLifecycle.activities.issueInvoice.form.footer.buttons.primary.done',
  secondary: 'ar.invoiceLifecycle.activities.issueInvoice.form.footer.buttons.editInvoice',
  tertiary: 'ar.invoiceLifecycle.activities.issueInvoice.form.footer.buttons.cancel',
});
