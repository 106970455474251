import { PatchBillSubscriptionsRequest } from '@melio/javascript-sdk';
import { BillSubscription, PostBillSubscriptionsRequest } from '@melio/platform-api-axios-client';
import { convertCentsToDollars, convertDollarsToCents } from '@melio/platform-utils';

import { BillSubscriptionOptions } from './types';

const billSubscriptionAmountFields = ['amount', 'lastAmount'];

export const convertBillSubscriptionCentsToDollars = (data: BillSubscription) =>
  convertCentsToDollars(data, billSubscriptionAmountFields);

export const convertPatchBillSubscriptionDollarsToCents = (data: PatchBillSubscriptionsRequest) =>
  convertDollarsToCents(data, billSubscriptionAmountFields);

export const convertPostBillSubscriptionDollarsToCents = (data: PostBillSubscriptionsRequest) =>
  convertDollarsToCents(data, billSubscriptionAmountFields);

export const convertBillSubscriptionOptionsCentsToDollars = (data: BillSubscriptionOptions) =>
  convertCentsToDollars(data, ['eligibleFundingSources[].maxAmount', 'eligibleFundingSources[].minAmount']);
