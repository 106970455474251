import { LoadingContainer } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { ModelError, useAccount, useEBillsSubscription, useVendor, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useSystemMessage } from '@melio/platform-utils';
import { useEffect, useRef } from 'react';

import { MonitoredAction } from '../../../../monitoring';
import { getSubmitErrorMessageKey } from '../apiErrorsUtils';
import { EBillsSusbscriptionFormScreen } from './form/EBillsSusbscriptionForm.screen';
import { EbillsSubscriptionFormFields } from './form/types';

export const EBillsSubscriptionFormActivity = withAnalyticsContext<{
  vendorId: Vendor['id'];
  onDone: () => void;
  onClose: () => void;
}>(({ vendorId, onDone, onClose, setAnalyticsProperties }) => {
  const { data: vendor, isLoading: isVendorLoading, isError: isVendorError } = useVendor({ id: vendorId });
  const { data: account, isLoading: isAccountLoading, isError: isAccountError } = useAccount({ id: 'me' });
  const hasError = useRef<boolean>(false);
  const {
    data: ebillsSubscriptionOptions,
    isLoading: isEbillsSubscriptionOptionsLoading,
    isMutating: isCreatingEBillsSubscription,
    isError: isEbillsSubscriptionOptionsError,
    createEBillsSubscription,
  } = useEBillsSubscription({
    id: vendorId,
  });
  const { showMessage } = useSystemMessage();
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const { startAction } = useMonitoring<MonitoredAction>();
  const loadingRef = useRef<HTMLDivElement>(null);

  setAnalyticsProperties({
    PageName: 'subscribe-to-ebills',
    Intent: 'subscribe-to-ebills',
    Flow: 'ebills',
    VendorId: vendorId,
  });

  const receivedError = isVendorError || isAccountError || isEbillsSubscriptionOptionsError;
  useEffect(() => {
    if (!hasError.current && receivedError) {
      hasError.current = true;
      showMessage({
        title: formatMessage('activities.ebillsSubscriptionForm.form.errors.fetching'),
        type: 'error',
        dataTestId: 'ebills-subscription-error',
      });
      return onClose();
    }
  }, [receivedError, hasError, onClose, showMessage, formatMessage]);

  useEffect(() => {
    if (!vendor) {
      return;
    }
    track('Vendor', 'View');
  }, [track, vendor]);

  const isLoading =
    !vendor ||
    !account ||
    !ebillsSubscriptionOptions ||
    isVendorLoading ||
    isAccountLoading ||
    isEbillsSubscriptionOptionsLoading;

  useEffect(() => {
    if (!loadingRef.current) {
      return;
    }
    if (isLoading) {
      loadingRef.current.focus();
    }
  }, [isLoading]);

  if (isLoading) {
    return <LoadingContainer isLoading tabIndex={0} ref={loadingRef} />;
  }

  const handleSubmit = async (formData: EbillsSubscriptionFormFields) => {
    track('Vendor', 'Click', { Cta: 'subscribe', RecevingOption: formData.receivingOption });
    startAction('ebills_subscription_form_submit');
    try {
      await createEBillsSubscription({
        vendorId,
        ebillsSubscription: {
          ...formData,
          fields: Object.entries(formData.fields).map(([name, value]) => ({ name, value })),
        },
      });
      track('Vendor', 'Status', { Status: 'success' });
      onDone();
    } catch (error) {
      showMessage({
        title: formatMessage(getSubmitErrorMessageKey(error as ModelError)),
        type: 'error',
        dataTestId: 'ebills-subscription-error',
      });
      track('Vendor', 'Status', { Status: 'failure' });
    }
  };

  const handleClose = () => {
    track('Vendor', 'Click', { Cta: 'exit' });
    onClose();
  };

  return (
    <EBillsSusbscriptionFormScreen
      vendor={vendor}
      account={account}
      onSubmit={handleSubmit}
      onClose={handleClose}
      isSaving={isCreatingEBillsSubscription}
      ebillsSubscriptionOptions={ebillsSubscriptionOptions}
    />
  );
});
