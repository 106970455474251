import { FailedToCollectPaymentActivity } from '@melio/ap-activities';

import { useRouter } from '@/hooks/router.hooks';
import { PayDashboardTabs } from '@/types/payDashboard.types';

export const RescheduleFailedPaymentScreen = ({ paymentId }: { paymentId: string }) => {
  const { replaceToPayDashboardTab } = useRouter();

  const onDoneHandler = () => {
    replaceToPayDashboardTab(PayDashboardTabs.Paid, paymentId);
  };

  const onClose = () => {
    replaceToPayDashboardTab(PayDashboardTabs.Inbox, paymentId);
  };

  return (
    <FailedToCollectPaymentActivity paymentId={paymentId} onBack={onClose} onClose={onClose} onDone={onDoneHandler} />
  );
};
