import { ApiKeys, BillSubscriptionsApiClient } from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useQuery, UseQueryOptions } from 'react-query';

import { BillSubscriptionOptions } from './types';
import { convertBillSubscriptionOptionsCentsToDollars } from './utils';

type BillSubscriptionOptionsParams = Parameters<typeof BillSubscriptionsApiClient.postBillSubscriptionOptions>[0];

export type UseBillSubscriptionOptionsProps = Omit<UseQueryOptions<BillSubscriptionOptions>, 'queryKey' | 'queryFn'> & {
  params: BillSubscriptionOptionsParams;
};

export const useBillSubscriptionOptions = ({ params, ...props }: UseBillSubscriptionOptionsProps) =>
  useQuery<BillSubscriptionOptions>({
    ...props,
    queryKey: [ApiKeys.BillSubscriptionsApi, params],
    select: convertBillSubscriptionOptionsCentsToDollars,
    queryFn: async () => {
      const res = await BillSubscriptionsApiClient.postBillSubscriptionOptions(params);
      return res.data.data;
    },
  });
