import { ActionsDropdownMenu, ActionsDropdownMenuProps, Container, Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import { useState } from 'react';

import { BillDetailsHeaderProps } from '../types';

export const BillDetailsHeader = forwardRef<BillDetailsHeaderProps, 'div'>(
  ({ defaultIsOpen, shouldShowActions = true, onDelete, onMarkAsPaid, enableEditBill, ...props }, ref) => {
    const [isMenuOpen, setIsMenuOpen] = useState(!!defaultIsOpen);
    const { formatMessage } = useMelioIntl();
    const filteredItems = [
      onMarkAsPaid && {
        label: formatMessage('widgets.billDetails.header.actions.markAsPaid'),
        onClick: onMarkAsPaid,
      },
      enableEditBill && {
        label: formatMessage('widgets.billDetails.header.actions.edit'),
        onClick: enableEditBill,
      },
      onDelete && {
        label: formatMessage('widgets.billDetails.header.actions.delete'),
        variant: 'critical',
        onClick: onDelete,
      },
    ].filter((item) => item !== undefined) as ActionsDropdownMenuProps['items'];

    return (
      <Group data-component="BillDetailsHeader" ref={ref} {...props}>
        <Container paddingY="xxs">
          <Text textStyle="heading2Semi">{formatMessage('widgets.billDetails.header.title')}</Text>
        </Container>
        {shouldShowActions && (
          <ActionsDropdownMenu
            items={filteredItems}
            label={formatMessage('widgets.billDetails.header.actions.buttonLabel')}
            isOpen={isMenuOpen}
            onOpenChange={setIsMenuOpen}
          />
        )}
      </Group>
    );
  }
);

BillDetailsHeader.displayName = 'BillDetailsHeader';
