/* eslint-disable react-hooks/exhaustive-deps */
import { useShowMemoToVendor } from '@melio/ap-widgets';
import { DeliveryMethod } from '@melio/platform-api';
import { useCallback, useState } from 'react';

import { ActionToPerform, getConditionalSteps, GetConditionalStepType } from '../utils/scheduleFlowStepsUtils';
import { RecurringPaymentFlowActivityStep } from './types';

const initialStep: RecurringPaymentFlowActivityStep = 'FUNDING_SOURCE_SELECTION';
const lastStep: RecurringPaymentFlowActivityStep = 'RECURRING_PAYMENT_SCHEDULED';

export type GetRecurringPaymentConditionalStepsCallback = Omit<
  Partial<GetConditionalStepType>,
  'deliveryMethodIdFromState'
>;
export const useRecurringPaymentFlowActivityStep = ({
  onFirstStepBack,
  actionToPerform,
  selectedDeliveryMethod,
  isDeliveryMethodSelectionStepRequired,
}: {
  onFirstStepBack: VoidFunction;
  actionToPerform: ActionToPerform;
  selectedDeliveryMethod?: DeliveryMethod | null;
  isDeliveryMethodSelectionStepRequired: boolean;
}) => {
  const [currentStep, setCurrentStep] = useState<RecurringPaymentFlowActivityStep>(initialStep);
  const { shouldDisplayMemoToVendor } = useShowMemoToVendor();

  const getConditionalStepsCallback = useCallback(
    (props: GetRecurringPaymentConditionalStepsCallback = {}) =>
      getConditionalSteps({
        actionToPerform,
        isDeliveryMethodSelectionStepVisited: isDeliveryMethodSelectionStepRequired,
        deliveryMethod: selectedDeliveryMethod,
        shouldDisplayMemoToVendor,
        ...props,
      }),
    [isDeliveryMethodSelectionStepRequired, actionToPerform, selectedDeliveryMethod, shouldDisplayMemoToVendor]
  );

  const goToPreviousStep = (): void => {
    if (currentStep === initialStep) {
      return onFirstStepBack();
    }

    if (currentStep === lastStep) {
      // shouldn't happen
      return;
    }

    const { hasMemoToVendorStep, hasPaymentPurposeStep, hasDeliveryMethodStep } = getConditionalStepsCallback();

    const previousStepMap: Omit<
      Record<RecurringPaymentFlowActivityStep, RecurringPaymentFlowActivityStep>,
      typeof initialStep | typeof lastStep
    > = {
      DELIVERY_METHOD_SELECTION: 'FUNDING_SOURCE_SELECTION',
      PAYMENT_PURPOSE: hasDeliveryMethodStep ? 'DELIVERY_METHOD_SELECTION' : 'FUNDING_SOURCE_SELECTION',
      MEMO_TO_VENDOR: hasPaymentPurposeStep
        ? 'PAYMENT_PURPOSE'
        : hasDeliveryMethodStep
        ? 'DELIVERY_METHOD_SELECTION'
        : 'FUNDING_SOURCE_SELECTION',
      RECURRING_REVIEW_AND_CONFIRM: hasMemoToVendorStep
        ? 'MEMO_TO_VENDOR'
        : hasPaymentPurposeStep
        ? 'PAYMENT_PURPOSE'
        : hasDeliveryMethodStep
        ? 'DELIVERY_METHOD_SELECTION'
        : 'FUNDING_SOURCE_SELECTION',
      COMPLETE_LEGAL_INFO: 'RECURRING_REVIEW_AND_CONFIRM',
    };

    setCurrentStep(previousStepMap[currentStep]);
  };

  const removeStep = (step: RecurringPaymentFlowActivityStep, steps: RecurringPaymentFlowActivityStep[]) => {
    const index = steps.indexOf(step);
    if (index !== -1) {
      steps.splice(index, 1);
    }
  };

  const calculateStepNumber = (currentStep: RecurringPaymentFlowActivityStep): number => {
    const DEFAULT_STEPS_ORDER: RecurringPaymentFlowActivityStep[] = [
      'FUNDING_SOURCE_SELECTION',
      'DELIVERY_METHOD_SELECTION',
      'PAYMENT_PURPOSE',
      'MEMO_TO_VENDOR',
      'RECURRING_REVIEW_AND_CONFIRM',
    ];

    const { hasDeliveryMethodStep, hasMemoToVendorStep, hasPaymentPurposeStep } = getConditionalStepsCallback();

    if (!hasDeliveryMethodStep) {
      removeStep('DELIVERY_METHOD_SELECTION', DEFAULT_STEPS_ORDER);
    }
    if (!hasPaymentPurposeStep) {
      removeStep('PAYMENT_PURPOSE', DEFAULT_STEPS_ORDER);
    }
    if (!hasMemoToVendorStep) {
      removeStep('MEMO_TO_VENDOR', DEFAULT_STEPS_ORDER);
    }

    return DEFAULT_STEPS_ORDER.indexOf(currentStep) + 1;
  };

  return {
    currentStep,
    getConditionalStepsCallback,
    goToStep: setCurrentStep,
    goToPreviousStep,
    calculateStepNumber,
  };
};
