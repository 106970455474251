import { Vendor } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';

import { PaymentActionStateEnum } from '../PaymentsScheduled.consts';

export function usePaymentActionState({
  vendorsIdsWithoutEmail,
  externalOrigin,
  autoPayCapable,
  eBillCapable,
  isManagedAccountDeliveryMethod,
}: {
  isManagedAccountDeliveryMethod: boolean;
  vendorsIdsWithoutEmail: Vendor['id'][];
  externalOrigin?: string | null;
  autoPayCapable: boolean;
  eBillCapable: boolean;
}): PaymentActionStateEnum {
  const {
    settings: {
      payment: { allowNotifyingVendor },
    },
  } = useConfig();

  if (eBillCapable) {
    return PaymentActionStateEnum.EBILL_CAPABLE_PAYMENT_SCHEDULED;
  }

  if (autoPayCapable) {
    return PaymentActionStateEnum.AUTO_PAY_CAPABLE_PAYMENT_SCHEDULED;
  }

  if (externalOrigin) {
    return PaymentActionStateEnum.EXTERNAL_PAYMENT_SCHEDULED;
  }

  if (vendorsIdsWithoutEmail.length && !isManagedAccountDeliveryMethod && allowNotifyingVendor) {
    return PaymentActionStateEnum.NOTIFY_VENDORS;
  }

  return PaymentActionStateEnum.PAYMENT_SCHEDULED;
}
