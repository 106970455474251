import { FormWidgetProps } from '@melio/ap-widgets';
import { Form, useMelioForm } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { object, SchemaOf, string } from 'yup';

import { MAX_INVOICE_NUMBER_LENGTH } from '../../utils/consts';

export type InvoiceNumberSchema = {
  invoiceNumber?: string;
};

const useSchema = (): SchemaOf<InvoiceNumberSchema> => {
  const { formatMessage } = useMelioIntl();

  return object().shape({
    invoiceNumber: string().max(
      MAX_INVOICE_NUMBER_LENGTH,
      formatMessage('activities.setInvoiceNumberModal.form.fields.invoiceNumber.validations.maxLength', {
        num: MAX_INVOICE_NUMBER_LENGTH,
      })
    ),
  });
};

export type SetInvoiceNumberFormProps = FormWidgetProps<InvoiceNumberSchema> & {
  invoiceNumber?: string;
};

export const SetInvoiceNumberForm = forwardRef<SetInvoiceNumberFormProps, 'form'>(
  ({ defaultValues, isSaving, onSubmit, onSubmissionStateChange, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const { formProps, registerField } = useMelioForm<InvoiceNumberSchema>({
      onSubmit,
      schema: useSchema(),
      isSaving,
      defaultValues,
      onSubmissionStateChange,
    });

    return (
      <Form data-component="SetInvoiceNumberForm" {...props} {...formProps} ref={ref}>
        <Form.TextField
          autoFocus
          labelProps={{ label: formatMessage('activities.setInvoiceNumberModal.form.fields.invoiceNumber.label') }}
          placeholder={formatMessage('activities.setInvoiceNumberModal.form.fields.invoiceNumber.placeholder')}
          {...registerField('invoiceNumber')}
        />
      </Form>
    );
  }
);

SetInvoiceNumberForm.displayName = 'SetInvoiceNumberForm';
