import { Box } from '@chakra-ui/react';
import { AddBillMethodSelectionWidget } from '@melio/ap-widgets';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { AccountingPlatform, AccountingPlatformSlug } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';

type AddBillScreenProps = {
  includeConnectAccountingPlatformOption?: boolean;
  availablePlatformsToConnect?: AccountingPlatform[];
  isLoading?: boolean;
  isAccountingPlatformConnecting?: boolean;
  onBack: VoidFunction;
  onClose: VoidFunction;
  onAddManualBill: VoidFunction;
  onUploadABill: (file: File[]) => void;
  onConnectAccountingPlatform?: (accountingPlatformSlug: AccountingPlatformSlug) => void;
};

export const AddBillScreen = withAnalyticsContext<AddBillScreenProps>(
  forwardRef(
    (
      {
        setAnalyticsProperties,
        includeConnectAccountingPlatformOption,
        availablePlatformsToConnect,
        isLoading,
        isAccountingPlatformConnecting,
        onBack,
        onClose,
        onAddManualBill,
        onUploadABill,
        onConnectAccountingPlatform,
        ...props
      },
      ref
    ) => {
      const { formatMessage } = useMelioIntl();
      setAnalyticsProperties({
        PageName: 'how-to-add-a-bill',
        Flow: 'add-a-bill',
        Intent: 'add-bill',
        IsSynced: !!(includeConnectAccountingPlatformOption && onConnectAccountingPlatform),
      });

      useAnalyticsView('AddBill', true, true);

      const { track } = useAnalytics();

      const handleClose = () => {
        track('Bill', 'Click', {
          Cta: 'exit',
        });
        onClose();
      };

      return (
        <NewSinglePaymentStepLayout
          data-component="AddBillActivity.AddBillScreen"
          data-testid="add-bill-activity-add-bill-screen"
          {...props}
          isLoading={isLoading}
          ref={ref}
          headerContent={
            <NewSinglePaymentStepLayout.Header>
              <NewSinglePaymentStepLayout.CloseButton onClick={handleClose} />
              <NewSinglePaymentStepLayout.BackButton onClick={onBack} />
            </NewSinglePaymentStepLayout.Header>
          }
        >
          <NewSinglePaymentStepLayout.Title>
            {formatMessage('activities.addBill.screens.addBill.title')}
          </NewSinglePaymentStepLayout.Title>
          <NewSinglePaymentStepLayout.Description>
            {formatMessage('activities.addBill.screens.addBill.description')}
          </NewSinglePaymentStepLayout.Description>
          <NewSinglePaymentStepLayout.Content>
            <Box width="full">
              <AddBillMethodSelectionWidget
                onAddManualBill={onAddManualBill}
                onUploadABill={onUploadABill}
                includeSyncOption={includeConnectAccountingPlatformOption}
                includePlatformsToSync={availablePlatformsToConnect}
                isAccountingPlatformConnecting={isAccountingPlatformConnecting}
                onConnectAccountingPlatform={onConnectAccountingPlatform}
              />
            </Box>
          </NewSinglePaymentStepLayout.Content>
        </NewSinglePaymentStepLayout>
      );
    }
  )
);

AddBillScreen.displayName = 'AddBillActivity.AddBillScreen';
