import { Group, Link, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { Logger } from '@melio/platform-logger';
import { useDateUtils } from '@melio/platform-utils';
import { addDays } from 'date-fns';
import { useState } from 'react';

import { useSubscriptionMe } from '../../../api';
import { usePartnerGroups, usePendingSubscription, useSubscription } from '../../../hooks';
import { ReactivatePlanModal } from '../ReactivatePlanModal/ReactivatePlanModal';

export const PendingSubscriptionPlanChange = () => {
  const { isMutating: isUpdatingSubscription } = useSubscriptionMe({ enabled: false });
  const [showReactivateModal, setShowReactivateModal] = useState(false);
  const { isReactivationAllowed, isPendingPlanFree, pendingPlanInfo } = usePendingSubscription();
  const { formatMessage, formatDate } = useMelioIntl();
  const { isFiservPartner } = usePartnerGroups();
  const subscription = useSubscription();
  const isReactivationButtonVariant = isPendingPlanFree && !isFiservPartner;

  const { createDate } = useDateUtils();

  if (!subscription) {
    Logger.log('PendingSubscriptionPlanChange - subscription is not exists');

    return null;
  }

  const reactivateSubscriptionText = !isReactivationButtonVariant
    ? formatMessage('activities.settings.pendingSubscriptionPlanChange.title.reactivatePlan.description', {
        date: formatDate(addDays(createDate(subscription.endDate), 1), {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        }),
        planName: pendingPlanInfo.planName,
      })
    : formatMessage('activities.settings.pendingSubscriptionPlanChange.title.reactivateFromFreePlan.description', {
        date: formatDate(subscription?.endDate, {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        }),
        planName: pendingPlanInfo.planName,
      });
  return (
    <>
      <ReactivatePlanModal
        variant="cancel-pending-change"
        isOpen={showReactivateModal}
        onClose={() => setShowReactivateModal(false)}
      />
      {isReactivationAllowed && (
        <Group spacing="xs" variant="vertical" data-testid="pending-plan-change">
          <Text textStyle="body3" color="global.neutral.800">
            {reactivateSubscriptionText}
          </Text>
          {!isReactivationButtonVariant && (
            <Link
              data-testid="pending-subscription-reactivate-plan-link"
              isDisabled={isUpdatingSubscription}
              label={formatMessage('activities.settings.pendingSubscriptionPlanChange.title.reactivatePlan.submit')}
              href="#"
              color="secondary"
              onClick={() => setShowReactivateModal(true)}
              isBold
              size="medium"
              variant="standalone"
            />
          )}
        </Group>
      )}
    </>
  );
};
