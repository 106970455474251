import { isValidOptionalPhone } from '@melio/ap-widgets';
import { useMelioIntl } from '@melio/platform-i18n';
import { string } from 'yup';

export const useFieldsSchemas = (mccGroupsSupported: boolean) => {
  const { formatMessage } = useMelioIntl();

  return {
    email: string()
      .required(formatMessage('activities.fundingSources.vendorDetailsForm.fields.email.validation.required'))
      .email(formatMessage('activities.fundingSources.vendorDetailsForm.fields.email.validation.required')),
    phone: string()
      .required(formatMessage('activities.fundingSources.vendorDetailsForm.fields.phone.validation.required'))
      .test(
        'valid-phone',
        formatMessage('activities.fundingSources.vendorDetailsForm.fields.phone.validation.required'),
        isValidOptionalPhone
      ),
    line1: string().required(
      formatMessage('activities.fundingSources.vendorDetailsForm.fields.line1.validation.required')
    ),
    line2: string(),
    city: string().required(
      formatMessage('activities.fundingSources.vendorDetailsForm.fields.city.validation.required')
    ),
    state: string().required(
      formatMessage('activities.fundingSources.vendorDetailsForm.fields.state.validation.required')
    ),
    stateCode: string().required(),
    country: string().required(
      formatMessage(`activities.fundingSources.vendorDetailsForm.fields.country.validation.required`)
    ),
    countryCode: string().required(),
    postalCode: string().required(
      formatMessage('activities.fundingSources.vendorDetailsForm.fields.postalCode.validation.required')
    ),
    group: string().required(
      formatMessage('activities.fundingSources.vendorDetailsForm.fields.category.validation.required')
    ),
    mcc: string().required(
      formatMessage(
        mccGroupsSupported
          ? 'activities.fundingSources.vendorDetailsForm.fields.subcategory.validation.required'
          : 'activities.fundingSources.vendorDetailsForm.fields.category.validation.required'
      )
    ),
  };
};
