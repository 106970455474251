import {
  BillGroupItem,
  GroupItem,
  GroupItemType,
  PaymentGroupItem,
  PaymentRequestGroupItem,
} from '@/types/payDashboard.types';
import { PaidCard } from '@/widgets/pay-dashboard/cards/paid-card/PaidCard.widget';
import { BillPayCard } from '@/widgets/pay-dashboard/cards/pay-card/BillPayCard';
import { FailedPaymentPayCard } from '@/widgets/pay-dashboard/cards/pay-card/FailedPaymentPayCard';
import { ScannedInvoiceCard } from '@/widgets/pay-dashboard/cards/scanned-invoice-card/ScannedInvoiceCard';
import { ScheduledCard } from '@/widgets/pay-dashboard/cards/scheduled-card/ScheduledCard.widget';
import {
  getBillCardBaseProps,
  getPaymentCardBaseProps,
  getPaymentRequestCardBaseProps,
  getScannedInvoiceCardBaseProps,
} from '@/widgets/pay-dashboard/cards/types/PaymentCard.types';
import { PaymentRequestPayCard } from './pay-card/PaymentRequestPayCard';

export const ItemCard = (props: { item: GroupItem }) => {
  const item = props.item;
  switch (item.type) {
    case GroupItemType.SCANNED_INVOICE:
      return <ScannedInvoiceCard {...getScannedInvoiceCardBaseProps(item)} />;
    case GroupItemType.PAID:
      return <PaidCard {...getPaymentCardBaseProps(item as PaymentGroupItem)} />;
    case GroupItemType.SCHEDULED:
      return (
        <ScheduledCard
          {...getPaymentCardBaseProps(item as PaymentGroupItem)}
          isVirtualDeliverymethod={item.isVirtualDeliverymethod}
        />
      );
    case GroupItemType.FAILED:
      return <FailedPaymentPayCard {...getPaymentCardBaseProps(item as PaymentGroupItem)} />;
    case GroupItemType.UNPAID:
      return <BillPayCard {...getBillCardBaseProps(item as BillGroupItem)} />;
    case GroupItemType.PAYMENT_REQUEST:
      return <PaymentRequestPayCard {...getPaymentRequestCardBaseProps(item as PaymentRequestGroupItem)} />;
  }
};

ItemCard.displayName = 'ItemCard';
