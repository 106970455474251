import React from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';

import { useRouter } from '@/hooks/router.hooks';
import { useActiveScreen } from '@/hooks/useActiveScreen';
import { useIsNewDashboardEnabled } from '@/hooks/useIsNewDashboardEnabled';
import { PayDashboardScreen } from '@/screens/pay-dashboard/PayDashboard.screen';
import { ActiveFlowEnum, ScreensEnum } from '@/store/app/app.types';

export const PayDashboardRoute = () => {
  useActiveScreen(ScreensEnum.payDashboard, ActiveFlowEnum.payDashboard);

  return (
    <Routes>
      <Route index element={<Navigate replace to="inbox" />} />
      <Route path=":tab" element={<PayDashboardRouteElement />}>
        {/* draft/:scannedInvoiceId - backward compatibility - redirecting to /inbox/:inboxItemId*/}
        <Route path="draft/:scannedInvoiceId" element={<PayDashboardRouteElement />} />
        <Route path=":inboxItemId" element={<PayDashboardRouteElement />}>
          {/* paymentIntentId/payment/:paymentId - backward compatibility - redirecting to /:tab/:inboxItemId*/}
          <Route path="payment/:paymentId" element={<PayDashboardRouteElement />} />
        </Route>
      </Route>
    </Routes>
  );
};

const PayDashboardRouteElement = () => {
  const { generateNPEDashboardLink } = useRouter();
  const { paymentId, inboxItemId, scannedInvoiceId } = useParams();

  const shouldRedirectToNewDashboard = useIsNewDashboardEnabled();

  if (!shouldRedirectToNewDashboard) {
    return <PayDashboardScreen />;
  }

  if (scannedInvoiceId) {
    return <Navigate replace to={generateNPEDashboardLink(`bills/${scannedInvoiceId}`)} />;
  }
  if (paymentId) {
    return <Navigate replace to={generateNPEDashboardLink(`payments/${paymentId}`)} />;
  }
  if (inboxItemId) {
    const tabName = inboxItemId.startsWith('pymnt_') ? 'payments' : 'bills';
    return <Navigate replace to={generateNPEDashboardLink(`${tabName}/${inboxItemId}`)} />;
  }

  return <Navigate replace to={generateNPEDashboardLink('vendors')} />;
};
