import { Flex } from '@chakra-ui/react';
import { PromotionalModal, Text } from '@melio/penny';
import React, { FC } from 'react';

import { useEngagementModal } from './hooks/useEngagementModal';
import { EngagementEventsEnum } from './types';

export const EngagementModal: FC<{ eventName: EngagementEventsEnum; pathPrefix?: string }> = ({ eventName }) => {
  const { modalMessage, onCloseHandler, primaryButton, secondaryButton, modalMessageDescriptions, combinedButton } =
    useEngagementModal({
      eventName,
    });

  if (!modalMessage?.imageUrl || !modalMessageDescriptions?.description) {
    return null;
  }

  return (
    <PromotionalModal
      onClose={onCloseHandler}
      isOpen
      asset={<img src={modalMessage?.imageUrl} alt="page not found illustration" />}
      header={modalMessage.header as string}
      primaryButton={combinedButton ? combinedButton : secondaryButton}
      secondaryButton={combinedButton ? undefined : primaryButton}
    >
      <Flex justifyContent="space-between">
        <Flex direction="column">
          <Text>{modalMessageDescriptions.description}</Text>
          <Text textStyle="body4">{modalMessageDescriptions?.subDescription}</Text>
        </Flex>
      </Flex>
    </PromotionalModal>
  );
};
