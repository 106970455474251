import { ServiceChargeCatalog, useMelioIntl } from '@melio/ar-domain';
import { Modal, SelectOption, useFormSubmissionController } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { ServiceChargeForm, ServiceChargeFormFields } from '../components';

export type CreateServiceChargeModalScreenProps = {
  isOpen: boolean;
  isLoading?: boolean;
  isSaving?: boolean;
  selectOptions: SelectOption[];
  selectedServiceCharge?: ServiceChargeCatalog;
  onClose: () => void;
  onSubmit: (formValues: ServiceChargeFormFields) => Promise<void>;
  onChangeServiceCharge: (selectedId: string) => void;
  onCreateServiceCharge: (name: string) => string;
};

export const CreateServiceChargeModalScreen = forwardRef<CreateServiceChargeModalScreenProps>(
  (
    {
      isOpen,
      isLoading,
      isSaving,
      selectOptions,
      selectedServiceCharge,
      onClose,
      onSubmit,
      onChangeServiceCharge,
      onCreateServiceCharge,
      ...props
    },
    ref
  ) => {
    const { onSubmissionStateChange, submitButtonProps, cancelButtonProps } =
      useFormSubmissionController<ServiceChargeFormFields>();
    const { formatMessage } = useMelioIntl();

    return (
      <Modal
        header={formatMessage('ar.invoiceLifecycle.activities.serviceChargeCatalog.modal.header.text')}
        isOpen={isOpen}
        isLoading={isLoading}
        onClose={onClose}
        secondaryButton={{
          ...cancelButtonProps,
          label: formatMessage('ar.invoiceLifecycle.activities.serviceChargeCatalog.modal.buttons.secondary.label'),
          onClick: onClose,
          variant: 'tertiary',
        }}
        primaryButton={{
          label: formatMessage('ar.invoiceLifecycle.activities.serviceChargeCatalog.modal.buttons.primary.label'),
          variant: 'primary',
          ...submitButtonProps,
        }}
        data-testid="create-service-charge-modal-screen"
        {...props}
        ref={ref}
      >
        <ServiceChargeForm
          onSubmit={onSubmit}
          onSubmissionStateChange={onSubmissionStateChange}
          isSaving={isSaving}
          selectOptions={selectOptions}
          selectedServiceChargeData={selectedServiceCharge}
          onChangeSelectedServiceCharge={onChangeServiceCharge}
          onCreateServiceCharge={onCreateServiceCharge}
          size="large"
        />
      </Modal>
    );
  }
);
CreateServiceChargeModalScreen.displayName = 'CreateServiceChargeModalScreen';
