import { useResendInvitation } from '@melio/platform-api';

import { useAsyncActionWithToast } from './hooks/useAsyncActionWithToast';
import { Invitation } from './types';

export const useResendInvitationFlow = () => {
  const { resend } = useResendInvitation();

  const actionWithToast = useAsyncActionWithToast({
    successLabel: 'activities.manageCollaborators.messages.invitation.resend.success',
    errorLabel: 'activities.manageCollaborators.messages.invitation.resend.error',
  });

  const resendInvitation = async (invitation: Invitation) => {
    await actionWithToast(() => resend(invitation.id));
  };

  return {
    resendInvitation,
  };
};
