import React from 'react';
import { Outlet } from 'react-router-dom';

import { ScreenSplitLayout } from '@/cl/layouts/screen-split-layout/screenSplitLayout.component';
import { useIsMobile } from '@/hooks/useIsMobile';
import { useSelectedVendor } from '@/hooks/vendors.hooks';
import { DataComponentEnum } from '@/types/vendors.types';
import { VendorsList } from '@/widgets/vendorsDashboard/VendorsList/VendorsList.widget';

export const VendorsPage = () => {
  const { selectedVendorId } = useSelectedVendor();
  const isMobile = useIsMobile();

  return (
    <ScreenSplitLayout
      {...(isMobile && { showScreenMobile: selectedVendorId ? 'second' : 'first' })}
      firstScreen={<VendorsList />}
      secondScreen={<Outlet />}
      data-component={DataComponentEnum.VENDORS_PAGE}
    />
  );
};
