import { useBreakpointValue } from '@chakra-ui/react';
import { extractNameAndAccountDigits } from '@melio/ap-widgets';
import { Group, Illustration, IllustrationProps } from '@melio/penny';
import { FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';

export type SuccessBillingScreenProps = {
  fundingSource: FundingSource;
  onDone: VoidFunction;
};

export const SuccessBillingScreen = ({ fundingSource, onDone }: SuccessBillingScreenProps) => {
  const { formatMessage } = useMelioIntl();

  const illustrationSize = useBreakpointValue<IllustrationProps['size']>({ xs: 'small', s: 'small', m: 'large' });
  const accountNameAndDigits = extractNameAndAccountDigits(fundingSource);
  const isUnverifiedBankAccount = fundingSource.type === 'bank-account' && !fundingSource.isVerified;
  const descriptionKey = isUnverifiedBankAccount
    ? 'activities.billingFee.screens.success.description.unverified'
    : 'activities.billingFee.screens.success.description';
  const description = formatMessage(descriptionKey, {
    paymentMethod: accountNameAndDigits.accountName,
    last4Digits: accountNameAndDigits.accountNumber,
  });

  return (
    <NewSinglePaymentStepLayout
      data-component="BillingFeeActivity.SuccessBillingScreen"
      data-testid="billing-fee-activity-success-screen"
      footerContent={
        <NewSinglePaymentStepLayout.Actions>
          <NewSinglePaymentStepLayout.NextButton
            onClick={onDone}
            data-testid="done-button"
            label={formatMessage('activities.billingFee.screens.success.done')}
          />
        </NewSinglePaymentStepLayout.Actions>
      }
    >
      <Group justifyContent="center">
        <Illustration type="success" size={illustrationSize} />
      </Group>
      <NewSinglePaymentStepLayout.Title>
        {formatMessage('activities.billingFee.screens.success.title')}
      </NewSinglePaymentStepLayout.Title>
      <NewSinglePaymentStepLayout.Description>{description}</NewSinglePaymentStepLayout.Description>
    </NewSinglePaymentStepLayout>
  );
};

SuccessBillingScreen.displayName = 'BillingFeeActivity.SuccessBillingScreen';
