import { PaymentReviewLineItem } from '@melio/ap-widgets';
import { DeliveryMethod as DeliveryMethodPlatform } from '@melio/platform-api';
import { PaymentTimelineItem } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';

import {
  getDeliveryMethodIconKey,
  useGetDeliveryMethodDisplayText,
  useGetDeliveryMethodHelperText,
  useGetDeliveryMethodLabelText,
} from '@/utils/deliveryMethods.utils';

type Props = {
  vendorName: string;
  deliveryMethod: DeliveryMethodPlatform;
  timeline: Array<PaymentTimelineItem>;
  countryDisplayName?: string;
  helperText: string | undefined;
};

export const DeliveryMethod = ({
  vendorName,
  deliveryMethod,
  timeline,
  countryDisplayName,
  helperText: overrideHelperText,
}: Props) => {
  const { settings } = useConfig();
  const helperText = useGetDeliveryMethodHelperText(deliveryMethod, timeline, countryDisplayName);

  return (
    <PaymentReviewLineItem
      data-testid="delivery-method-check-scheduled-label"
      contentTestId="delivery-method-details"
      labelProps={{ label: useGetDeliveryMethodLabelText(deliveryMethod as never, timeline) }} // TODO: solve type miismatch
      mainLabelProps={{ label: useGetDeliveryMethodDisplayText(deliveryMethod, vendorName) }}
      icon={{ type: getDeliveryMethodIconKey(deliveryMethod, settings.icons.deliveryMethod) }}
      descriptionProps={{
        label: overrideHelperText || helperText,
      }}
    />
  );
};
