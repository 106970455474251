import { useCallback } from 'react';
import { atom, selector, useSetRecoilState } from 'recoil';
import { CardParams } from '@melio/platform-api';

import { SettingsNestedPages } from '@/consts/SettingsConsts';
import { SettingsStoreType } from '@/store/Settings/Settings.types';

export const settingsState = atom<SettingsStoreType>({
  key: 'settingsState',
  default: {},
});

export const settingsSelectedSettingsPageSelector = selector({
  key: 'settingsSelectedSettingPage',
  get: ({ get }) => {
    const settings = get(settingsState);
    return settings.selectedSettingsPage;
  },
});

export const useSettingsSelectedSettingsPage = () => {
  const setSelectedPage = useSetRecoilState(settingsState);
  return useCallback(
    (page: SettingsNestedPages | null) =>
      setSelectedPage((state) => {
        return { ...state, selectedSettingsPage: page };
      }),
    [setSelectedPage],
  );
};

export const tabapayDetailsSelector = selector({
  key: 'tabapayDetails',
  get: ({ get }) => {
    const settings = get(settingsState);
    return settings.tabapayDetails;
  },
});

export const useTabapayDetailsSelector = () => {
  const setTabapayDetails = useSetRecoilState(settingsState);
  return (tabapay?: CardParams) =>
    setTabapayDetails((state) => {
      return { ...state, tabapayDetails: tabapay };
    });
};
