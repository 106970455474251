import { useEffect } from 'react';
import { Container, Group } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { usePermissions } from '@melio/platform-permissions';
import { SettingsPageHeader } from '@melio/platform-utils';

import { SettingsCardIdEnum } from '@/cl/components/SettingsCard/SettingsCard.component';
import { SettingsSectionContainer } from '@/cl/components/SettingsSectionContainer/SettingsSectionContainer.component';
import { usePlatformIntl } from '@/translations/Intl';
import { PaymentsReportSection } from '@/widgets/settings-page/common';
import { TaxFormSection } from './TaxFormSection.widget';

export const TaxAndReportsPage = () => {
  const { track } = useAnalytics();
  const { formatMessage } = usePlatformIntl();
  const { can } = usePermissions();
  const shouldShowPaymentsReportSection = can({ subject: 'report:payment', action: 'create' });
  const shouldShowTaxFormSection = can({ subject: 'report:tax', action: 'create' });

  useEffect(() => {
    track('Settings', 'View', {
      PageName: SettingsCardIdEnum.TaxAndReportsSettings,
      Flow: 'reporting',
      Intent: 'reporting',
    });
  }, [track]);

  return (
    <>
      <SettingsPageHeader
        title={formatMessage('app.settings.companySection.cards.taxAndReports.screen.title')}
        subTitle={formatMessage('app.settings.companySection.cards.taxAndReports.screen.description')}
      />
      <Container data-component="TaxAndReportsComponent">
        {shouldShowTaxFormSection && (
          <SettingsSectionContainer data-testid="tax-form-section">
            <Group>
              <TaxFormSection />
            </Group>
          </SettingsSectionContainer>
        )}

        {shouldShowPaymentsReportSection && (
          <SettingsSectionContainer data-testid="payments-report-section">
            <Group>
              <PaymentsReportSection />
            </Group>
          </SettingsSectionContainer>
        )}
      </Container>
    </>
  );
};

TaxAndReportsPage.displayName = 'TaxAndReportsPage';
