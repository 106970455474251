import { usePermissions } from '@melio/platform-permissions';
import { useCallback } from 'react';

import { useSubscriptionContext } from '../context';
import { useIsSubscriptionsEnabled } from './useIsSubscriptionsEnabled';

export const useSubscriptionPermissions = () => {
  const { can } = usePermissions();
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const {
    gracePeriod: { isEligibleForGracePeriod, isTodayInGracePeriod },
  } = useSubscriptionContext();

  const hasAccessToUpdatePlan = useCallback(() => {
    if (!isSubscriptionsEnabled) {
      return false;
    }

    return (
      can({ action: 'update', subject: 'subscriptions:me' }) && can({ action: 'delete', subject: 'subscriptions:me' })
    );
  }, [isSubscriptionsEnabled, can]);

  const hasAccessToSubscriptionSettings = useCallback(() => {
    if (!isSubscriptionsEnabled) {
      return false;
    }
    if (isEligibleForGracePeriod && isTodayInGracePeriod) {
      return false;
    }
    const canReadSubscription = can({ action: 'read', subject: 'subscriptions:me' });
    const canReadBillings = can({ action: 'read', subject: 'subscriptions:billings' });

    return canReadSubscription && hasAccessToUpdatePlan() && canReadBillings;
  }, [isSubscriptionsEnabled, can, isEligibleForGracePeriod, isTodayInGracePeriod, hasAccessToUpdatePlan]);

  return { hasAccessToSubscriptionSettings, hasAccessToUpdatePlan };
};
