import { Group, StatusIconSolid, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export const PaymentsLateApprovalModalHeader = () => {
  const { formatMessage } = useMelioIntl();

  return (
    <Group spacing="s">
      <StatusIconSolid size="large" variant="warning" aria-hidden />

      <Text as="h2" textStyle="heading2Semi">
        {formatMessage('activities.paymentsLateApprovalModal.header')}
      </Text>
    </Group>
  );
};
