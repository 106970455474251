import { Container, Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { isNil } from 'lodash';

import { PlanInfo } from '../../../../hooks/usePlanInfo';

type AdditionalSeatFeeProps = {
  unitPrice?: number;
  nextUnlimitedSeatsPlanInfo: PlanInfo;
};

export type PlanCardFeeFooterProps = {
  transactionFee?: string;
  additionalSeatFee?: AdditionalSeatFeeProps;
};

const AdditionalSeatFee = ({ unitPrice, nextUnlimitedSeatsPlanInfo }: AdditionalSeatFeeProps) => {
  const { formatMessage, formatCurrency } = useMelioIntl();

  const canAddSeats = !isNil(unitPrice);

  return (
    <Group variant="horizontal" justifyContent="space-between">
      <Text as="div" textStyle="body3" color="global.neutral.900">
        {formatMessage('activities.subscription.plans.planCard.additionalSeats')}
      </Text>
      {canAddSeats ? (
        <Text as="div" textStyle="body3" color="global.neutral.900">
          {formatCurrency(unitPrice ?? 0, 'USD')}
          {formatMessage('activities.subscription.plans.planCard.price.perMonth')}
        </Text>
      ) : (
        <Text as="div" textStyle="body3" color="global.neutral.900">
          {formatMessage('activities.subscription.upgradeModal.title', {
            planName: nextUnlimitedSeatsPlanInfo.planName,
          })}
        </Text>
      )}
    </Group>
  );
};

export const PlanCardFeeFooter = ({ transactionFee, additionalSeatFee }: PlanCardFeeFooterProps) => {
  const { formatMessage } = useMelioIntl();

  if (!transactionFee && !additionalSeatFee) {
    return null;
  }

  return (
    <Container>
      <Container paddingBottom="xs">
        <Text as="h3" textStyle="body3Semi">
          {formatMessage('activities.subscription.plans.planCard.fees')}
        </Text>
      </Container>

      {additionalSeatFee && <AdditionalSeatFee {...additionalSeatFee} />}
      {transactionFee}
    </Container>
  );
};
