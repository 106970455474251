import { Table, Text } from '@melio/penny';
import { Loan } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { LoansTabItem } from '../types';
type StatusList = Loan['status'][];
export const FinancingTabDebitDateCell = ({ loansTabItem }: { loansTabItem: LoansTabItem }) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const {
    id,
    loan: { status, nextInstallmentDate },
  } = loansTabItem;

  return (
    <Table.Cell
      placeholder={formatMessage('activities.payDashboard.financingTab.table.cells.notAvailable')}
      data-testid={`financing-tab-row-${id}-debit-date-cell`}
    >
      {status === 'completed' ? (
        <Text data-testid="financing-tab-debit-date-cell" textStyle="body3" color="global.neutral.600">
          {formatMessage('activities.payDashboard.financingTab.table.cells.debitDate.completed')}
        </Text>
      ) : (
        (['active', 'failed'] as StatusList).includes(status) &&
        nextInstallmentDate && (
          <Text data-testid="financing-tab-debit-date-cell" textStyle="body3">
            {formatDate(nextInstallmentDate, { dateStyle: 'medium' })}
          </Text>
        )
      )}
    </Table.Cell>
  );
};
