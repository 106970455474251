/* eslint-disable react-hooks/exhaustive-deps */
import { useMelioIntl, usePlaid } from '@melio/ar-domain';
import { PlaidAccountData } from '@melio/platform-api';
import { FC, useEffect } from 'react';

export type AddPlaidAccountProps = {
  onExit: VoidFunction;
  onError: ARErrorFunction;
  onSuccess: (data: PlaidAccountData) => void;
  onLoadToken: (isLoading: boolean) => void;
};

export const AddPlaidAccount: FC<AddPlaidAccountProps> = ({ onSuccess, onError: _onError, onExit, onLoadToken }) => {
  const { formatMessage } = useMelioIntl();

  const onError = () => {
    _onError({ code: '500', message: formatMessage('ar.guestPayment.errors.plaid.token.status.500.text') });
    onExit();
  };

  const { open, ready, isLoading: isLoadingToken } = usePlaid({ onError, onSuccess, onExit });

  useEffect(() => {
    if (ready) open();
  }, [ready, open]);

  useEffect(() => onLoadToken(isLoadingToken), [isLoadingToken]);

  return null;
};
