import { useMelioIntl } from '@melio/platform-i18n';
import { EMAIL_REGEX } from '@melio/platform-utils';
import { boolean, object, SchemaOf, string } from 'yup';

import { InviteFirmClientFormFields } from '../types';

export const emailIsValid = (email?: string) => (email ? EMAIL_REGEX.test(email) : false);

export const useInviteFirmClientFormSchema = () => {
  const { formatMessage } = useMelioIntl();

  const requiredFieldMessage = formatMessage(
    'activities.accountants.inviteClient.form.fields.validation.required.label'
  );
  const invalidEmailAddressMessage = formatMessage(
    'activities.accountants.inviteClient.form.fields.clientEmailAddress.validation.format.label'
  );

  return object().shape({
    clientEmailAddress: string()
      .required(requiredFieldMessage)
      .test('validAddress', invalidEmailAddressMessage, emailIsValid),
    clientFirstName: string().required(requiredFieldMessage),
    clientLastName: string().required(requiredFieldMessage),
    companyName: string().required(requiredFieldMessage),
    includeMyEmailInCC: boolean().optional().nullable(),
  }) as unknown as SchemaOf<InviteFirmClientFormFields>;
};
