import { Link, SectionBanner } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

export const EndOfYearCheckBanner = ({
  link,
  promoteFastCheck,
  isFastCheck,
}: {
  link: string;
  promoteFastCheck: boolean;
  isFastCheck?: boolean;
}) => {
  const { formatMessage } = useMelioIntl();
  const isPromotedFastCheck = promoteFastCheck && isFastCheck;
  const checkBannerDescription = isPromotedFastCheck
    ? 'activities.deliveryDate.screens.deliveryDate.endOfYearCheck.fastCheckDescription'
    : 'activities.deliveryDate.screens.deliveryDate.endOfYearCheck.singleCheckDescription';
  return (
    <SectionBanner
      data-testid="delivery-screen-end-of-year-check-banner"
      description={formatMessage(checkBannerDescription, {
        learnMoreLink: (
          <Link
            href={link}
            label={formatMessage('activities.deliveryDate.screens.deliveryDate.endOfYearCheck.learnMoreLink')}
            newTab
          />
        ),
      })}
      title={formatMessage('activities.deliveryDate.screens.deliveryDate.endOfYearCheck.title')}
      variant={isPromotedFastCheck ? 'warning' : 'informative'}
    />
  );
};
