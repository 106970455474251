import React from 'react';
import { OAuthAccountInfo, OAuthAccountNotCreatedReason } from '@melio/platform-api';

import { MissingAccountDataForm, MissingAccountDataFormFields } from './components/MissingAccountDataForm';

type MissingAccountDataFormProps = {
  reasons: OAuthAccountNotCreatedReason[];
  onDone: (accountInfo: OAuthAccountInfo) => void;
};

export const MissingAccountData = ({ reasons, onDone }: MissingAccountDataFormProps) => {
  const onDoneHandler = React.useCallback(
    async (data: MissingAccountDataFormFields) => {
      const { firstName, lastName, dateOfBirth, companyName, address } = data;

      const isAddressFilled =
        address && Object.keys(address).some((it) => !!address[it as keyof MissingAccountDataFormFields['address']]);

      onDone({
        user: {
          ...(firstName && { firstName }),
          ...(lastName && { lastName }),
          ...(dateOfBirth && { dateOfBirth }),
        },
        company: {
          ...(companyName && { name: companyName, legalName: companyName }),
          ...(isAddressFilled && { address, legalAddress: address }),
        },
      });
    },
    [onDone],
  );

  return <MissingAccountDataForm onDone={onDoneHandler} reasons={reasons} />;
};
