import { SectionBanner } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export function ReusingFailedBankAccountError() {
  const { formatMessage } = useMelioIntl();
  return (
    <SectionBanner
      data-testid="reusing-failed-bank-account-error"
      variant="critical"
      title={formatMessage(
        'activities.editVendorBankDetails.screens.editVendorBankDetails.attemptToUseKnownFailedBankAccountError.title'
      )}
      description={formatMessage(
        'activities.editVendorBankDetails.screens.editVendorBankDetails.attemptToUseKnownFailedBankAccountError.description'
      )}
    />
  );
}
