import React, { useEffect } from 'react';

import { useDashboardMonitoring } from '@/screens/pay-dashboard/DashboardMonitoring';
import { PayDashboardTabGroups, PayDashboardTabs } from '@/types/payDashboard.types';
import { PaidCardList } from '@/widgets/pay-dashboard/cards/paid-card/PaidCardList.widget';
import { UnpaidCardList } from '@/widgets/pay-dashboard/cards/pay-card/UnpaidCardList.widget';
import { ScheduleCardList } from '@/widgets/pay-dashboard/cards/scheduled-card/ScheduledCardList.widget';

type PayListActiveTabProps = {
  activeTab: PayDashboardTabs;
  groups: PayDashboardTabGroups;
};

export const PayListActiveTab = ({ activeTab, groups }: PayListActiveTabProps) => {
  const { onReady } = useDashboardMonitoring();

  useEffect(() => onReady(), [onReady]);

  switch (activeTab) {
    case PayDashboardTabs.Inbox:
      return <UnpaidCardList groups={groups[PayDashboardTabs.Inbox]} />;
    case PayDashboardTabs.Scheduled:
      return <ScheduleCardList groups={groups[PayDashboardTabs.Scheduled]} />;
    case PayDashboardTabs.Paid:
      return <PaidCardList groups={groups[PayDashboardTabs.Paid]} />;
    default:
      return null;
  }
};
