import { useState } from 'react';

import { LinkBankAccountActivityProps, LinkBankAccountStep } from './types';

export const useLinkBankAccountSteps = ({
  onFirstStepBack,
}: {
  onFirstStepBack: LinkBankAccountActivityProps['onBack'];
}) => {
  const [currentStep, goToStep] = useState<LinkBankAccountStep>('LINK_BANK_ACCOUNT');

  return { currentStep, goToStep, onFirstStepBack };
};
