import { Container, IconButton, Text, useToast } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { Logger } from '@melio/platform-logger';
import { useKeydownEvent } from '@melio/platform-utils';
import React, { useState } from 'react';

import { LeaveConfirmationModal } from '../../activities/LeaveConfirmationModal/LeaveConfirmationModal.activity';

export type FlowHeaderCta = 'exit' | 'escape';

type NewClientHeaderProps = {
  title: string;
  leaveConfirmationModal?: {
    title: string;
    description: string;
    cancelButtonText: string;
  };
  onClose: () => void;
};

export const FlowHeader: React.FC<NewClientHeaderProps> = ({ title, leaveConfirmationModal, onClose }) => {
  const { formatMessage } = useMelioIntl();
  const [isLeaveConfirmModalOpened, setIsLeaveConfirmModalOpened] = useState<boolean>(false);
  const [isLeaving, setIsLeaving] = useState<boolean>(false);
  const { toast } = useToast();
  const { track } = useAnalytics();

  const handleClose = () => {
    try {
      onClose();
      setIsLeaving(true);
    } catch (error: unknown) {
      toast({
        type: 'error',
        title: formatMessage(
          'activities.accountants.activities.clientCreatedSuccessfullyScreen.redirect-to-client-dashboard.error'
        ),
      });
      Logger.log(`Error while trying redirect to melio dashboard`);
      setIsLeaving(false);
    } finally {
      // Should keep isLoading=true (location.href may be done before it actually redirects)
    }
  };

  const getOnCloseHandler = (cta: FlowHeaderCta) => () => {
    track('Organization', 'Click', { Cta: cta, Intent: 'exit' });
    leaveConfirmationModal ? setIsLeaveConfirmModalOpened(true) : handleClose();
  };

  useKeydownEvent({ key: 'Escape', handler: getOnCloseHandler('escape') });

  return (
    <Container justifyContent="space-between" alignItems="center" paddingBottom="m">
      <Text textStyle="heading1Semi">{title}</Text>

      <IconButton
        data-testid="flow-header-close"
        aria-label="icon-button-prev"
        icon="close"
        onClick={getOnCloseHandler('exit')}
        size="medium"
        variant="primary"
      />
      {leaveConfirmationModal && (
        <LeaveConfirmationModal
          isOpen={isLeaveConfirmModalOpened}
          title={leaveConfirmationModal.title}
          description={leaveConfirmationModal.description}
          okButtonText={formatMessage('activities.accountants.leaveConfirmation.okButton.text')}
          cancelButtonText={leaveConfirmationModal.cancelButtonText}
          onDone={handleClose}
          onCancel={() => setIsLeaveConfirmModalOpened(false)}
          isLeaving={isLeaving}
        />
      )}
    </Container>
  );
};
