import { useMelioQueryClient, useModelApi, UseModelApiProps, useUpdateMutation } from '@melio/api-client';
import { JoinOrganizationRequest, JoinOrganizationRequestsApiClient } from '@melio/platform-api-axios-client';

export type UseJoinOrganizationRequestProps = UseModelApiProps<
  typeof JoinOrganizationRequestsApiClient.joinOrganizationRequestGet,
  JoinOrganizationRequest
>;

export const useJoinOrganizationRequest = (props: UseJoinOrganizationRequestProps = {}) => {
  const queryClient = useMelioQueryClient();
  const queryKey = 'JoinOrganizationRequestsApi';
  const query = useModelApi<
    typeof JoinOrganizationRequestsApiClient.joinOrganizationRequestGet,
    never,
    never,
    never,
    JoinOrganizationRequest
  >({
    ...props,
    queryKey,
    queryFn: JoinOrganizationRequestsApiClient.joinOrganizationRequestGet,
  });

  const cancelJoinOrganizationRequestAccount = useUpdateMutation<
    typeof JoinOrganizationRequestsApiClient.joinOrganizationRequestsCancel,
    JoinOrganizationRequest,
    undefined
  >(JoinOrganizationRequestsApiClient.joinOrganizationRequestsCancel, query.queryKey, {
    onSuccess: () => queryClient.invalidateQueries(queryKey, 'collection'),
  });

  const resendJoinOrganizationRequestAccount = useUpdateMutation<
    typeof JoinOrganizationRequestsApiClient.joinOrganizationRequestsResend,
    JoinOrganizationRequest,
    undefined
  >(JoinOrganizationRequestsApiClient.joinOrganizationRequestsResend, query.queryKey, {
    onSuccess: () => queryClient.invalidateQueries(queryKey, 'collection'),
  });

  return {
    ...query,
    cancelJoinOrganizationRequestAccount: () =>
      cancelJoinOrganizationRequestAccount.createCallback(props.id as string)(undefined),
    resendJoinOrganizationRequestAccount: () =>
      resendJoinOrganizationRequestAccount.createCallback(props.id as string)(undefined),
  };
};

export type JoinOrganizationRequestModel = ReturnType<typeof useJoinOrganizationRequest>;
