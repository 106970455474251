import { useModelApi, UseModelApiProps } from '@melio/api-client';
import { NotificationPreferencesApiClient } from '@melio/platform-api-axios-client';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getNotificationPreferences = (_id: string) => NotificationPreferencesApiClient.getNotificationPreferences();
const setNotificationPreferences = (
  _id: string,
  params: Parameters<typeof NotificationPreferencesApiClient.setNotificationPreferences>[0]
) => NotificationPreferencesApiClient.setNotificationPreferences(params);

export type UseNotificationPreferencesProps = UseModelApiProps<typeof getNotificationPreferences>;

export const useNotificationPreferences = (props: UseNotificationPreferencesProps = {}) =>
  useModelApi({
    ...props,
    id: 'fake_id',
    queryKey: 'NotificationPreferencesApi',
    queryFn: getNotificationPreferences,
    updateFn: setNotificationPreferences,
  });

export type NotificationPreferenceModel = ReturnType<typeof useNotificationPreferences>;
