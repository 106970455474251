import { Group, Illustration, Text } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';

type PendingVerificationScreenProps = {
  onDone: VoidFunction;
};

export const PendingVerificationScreen = forwardRef<PendingVerificationScreenProps, 'div'>(
  ({ onDone, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    useAnalyticsView('VerifyDepositDetailsWhatNext');

    return (
      <NewSinglePaymentStepLayout
        data-component="AddBankAccountMicroDepositsActivity.PendingVerificationScreen"
        data-testid="add-bank-account-micro-deposits-activity-pending-verification-screen"
        {...props}
        ref={ref}
        footerContent={
          <NewSinglePaymentStepLayout.Actions>
            <NewSinglePaymentStepLayout.NextButton
              onClick={onDone}
              label={formatMessage('activities.addBankAccountMicroDeposits.screens.pendingVerification.continue')}
            />
          </NewSinglePaymentStepLayout.Actions>
        }
      >
        <Group justifyContent="center">
          <Illustration type="pending" size="large" />
        </Group>
        <NewSinglePaymentStepLayout.Title>
          {formatMessage('activities.addBankAccountMicroDeposits.screens.pendingVerification.title')}
        </NewSinglePaymentStepLayout.Title>
        <NewSinglePaymentStepLayout.Description>
          {formatMessage('activities.addBankAccountMicroDeposits.screens.pendingVerification.description')}
        </NewSinglePaymentStepLayout.Description>
        <NewSinglePaymentStepLayout.Content>
          <Group variant="vertical" alignItems="center" textAlign="center">
            <Text textStyle="body2Semi">
              {formatMessage('activities.addBankAccountMicroDeposits.screens.pendingVerification.content.subtitle')}
            </Text>
            <Text>
              {formatMessage('activities.addBankAccountMicroDeposits.screens.pendingVerification.content.description')}
            </Text>
          </Group>
        </NewSinglePaymentStepLayout.Content>
      </NewSinglePaymentStepLayout>
    );
  }
);

PendingVerificationScreen.displayName = 'AddBankAccountMicroDepositsActivity.PendingVerificationScreen';
