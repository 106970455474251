import { PlanCycle, SubscriptionBillingCycleEnum } from '@melio/platform-api-axios-client';
import Big from 'big.js';

type PredefinedDiscountPricing = {
  discountPercentage: number;
  priceBeforeDiscount?: number;
  finalPrice: number;
  selectedBillingCycle: SubscriptionBillingCycleEnum;
  unitPrice?: number;
};

const roundNumber = (value: Big): Big => {
  const base = value.gte(100) ? new Big(10) : new Big(1);
  return value.div(base).round().mul(base);
};

export function usePredefinedDiscountedPlanPricing({
  discountPercentage,
  planCycle,
}: {
  discountPercentage?: number;
  planCycle: PlanCycle;
}): PredefinedDiscountPricing;
export function usePredefinedDiscountedPlanPricing({
  discountPercentage,
  planCycle,
}: {
  discountPercentage?: number;
  planCycle?: PlanCycle;
}): PredefinedDiscountPricing | undefined;
export function usePredefinedDiscountedPlanPricing({
  discountPercentage,
  planCycle,
}: {
  discountPercentage?: number;
  planCycle?: PlanCycle;
}): PredefinedDiscountPricing | undefined {
  if (!planCycle) {
    return;
  }
  const { unitPrice, basePrice: finalPrice } = planCycle;
  const priceBeforeDiscount = discountPercentage
    ? roundNumber(new Big(finalPrice).div(1 - discountPercentage / 100)).toNumber()
    : undefined;

  return {
    discountPercentage: discountPercentage || 0,
    priceBeforeDiscount,
    finalPrice,
    selectedBillingCycle: SubscriptionBillingCycleEnum.Monthly,
    unitPrice,
  };
}
