import {
  ARCustomerTypeEnum,
  ARErrorCode,
  FormDialog,
  FormDialogProps,
  FormDialogWidgetProps,
  useMelioIntl,
  useRiskMtlSchemaValidations,
} from '@melio/ar-domain';
import { Form, useMelioForm } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { useEffect, useMemo } from 'react';
import { object, SchemaOf, string } from 'yup';
export type CustomerFormFields = {
  customerName: string;
  email: string;
  customerType: ARCustomerTypeEnum;
  phone?: string;
};

const useSchema = () => {
  const { formatMessage } = useMelioIntl();
  const isValidOptionalPhone = (phone?: string | null) => !phone || phone.length === 10;
  const { customerName } = useRiskMtlSchemaValidations({ customerName: true });

  return object().shape({
    customerName: customerName(),
    email: string()
      .email(formatMessage('ar.modals.activities.customerManagement.form.email.validation.general.label'))
      .required(formatMessage('ar.modals.activities.customerManagement.form.email.required.label')),
    customerType: string()
      .oneOf(Object.values(ARCustomerTypeEnum))
      .required(formatMessage('ar.modals.activities.customerManagement.form.customerType.required.label')),
    phone: string()
      .nullable()
      .when('customerType', {
        is: 'consumer',
        then: (schema) =>
          schema.test(
            'valid-phone',
            formatMessage('ar.modals.activities.customerManagement.form.phone.validation.format'),
            isValidOptionalPhone
          ),
      }),
  }) as SchemaOf<CustomerFormFields>;
};

export type CustomerFormDialogProps = FormDialogWidgetProps<
  CustomerFormFields,
  {
    header: string;
    primaryButtonLabel: string;
    secondaryButtonLabel: string;
    error?: ARPlatformError;
  }
>;

export const CustomerFormDialog = forwardRef<CustomerFormDialogProps>(
  (
    { onSubmit, defaultValues: _defaultValues, isSaving, primaryButtonLabel, secondaryButtonLabel, error, ...props },
    ref
  ) => {
    const defaultValues = useMemo<NonNullable<CustomerFormDialogProps['defaultValues']>>(
      () => ({ customerType: 'business', customerName: '', email: '', ..._defaultValues }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [JSON.stringify(_defaultValues)]
    );

    const { registerField, watch, setError, ...useFormResults } = useMelioForm({
      schema: useSchema(),
      onSubmit,
      defaultValues,
      isSaving,
      subscribeToDefaultValuesChanges: true,
    });
    const { formatMessage } = useMelioIntl();
    const customerType = watch('customerType');
    const shouldShowPhoneField = customerType === ARCustomerTypeEnum.Consumer;

    useEffect(() => {
      if (error?.errorCode === ARErrorCode.CustomerEmailNotUnique) {
        setError('email', {
          message: formatMessage('ar.modals.activities.customerManagement.form.email.validation.nonUniqueEmail.label'),
        });
      }
    }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

    const bannerProps = useMemo<FormDialogProps['banner']>(
      () => ({
        icon: 'info-fill',
        variant: 'neutral',
        description: formatMessage('ar.modals.activities.customerManagement.form.required.email.banner.label', {
          customerName: defaultValues.customerName,
        }),
      }),
      [defaultValues.customerName, formatMessage]
    );

    return (
      <FormDialog
        useFormResults={useFormResults}
        size="small"
        primaryButton={{ label: primaryButtonLabel }}
        secondaryButton={{ label: secondaryButtonLabel }}
        banner={defaultValues.customerName && !defaultValues.email ? bannerProps : undefined}
        {...props}
        ref={ref}
      >
        <Form.RadioGroup
          {...registerField('customerType')}
          labelProps={{
            label: formatMessage('ar.modals.activities.customerManagement.form.customerType.label'),
            tooltipProps: {
              label: formatMessage('ar.modals.activities.customerManagement.form.customerType.tooltip.label'),
            },
          }}
          options={[
            {
              value: ARCustomerTypeEnum.Business,
              mainLabelProps: {
                label: formatMessage('ar.modals.activities.customerManagement.form.customerType.option.business.label'),
              },
            },
            {
              value: ARCustomerTypeEnum.Consumer,
              mainLabelProps: {
                label: formatMessage('ar.modals.activities.customerManagement.form.customerType.option.consumer.label'),
              },
            },
          ]}
        />
        <Form.TextField
          {...registerField('customerName')}
          labelProps={{ label: formatMessage('ar.modals.activities.customerManagement.form.customerName.label') }}
        />
        <Form.TextField
          {...registerField('email')}
          labelProps={{ label: formatMessage('ar.modals.activities.customerManagement.form.email.label') }}
        />
        <Form.PhoneField
          {...registerField('phone')}
          labelProps={{ label: formatMessage('ar.modals.activities.customerManagement.form.phone.label') }}
          isHidden={!shouldShowPhoneField}
        />
      </FormDialog>
    );
  }
);
