import { BillSubscriptionIntervalTypeEnum, SubscriptionOccurrence } from '@melio/platform-api';

import { PayCardTagStatus } from '@/cl/components/PayCard/PayCard.component';
import { FormatMessage } from '@/translations/Intl';

export const getPaymentCardRecurringText = (
  isRecurringPaymentsEnabled: boolean,
  formatMessage: FormatMessage,
  subscriptionOccurrence?: SubscriptionOccurrence | null,
) => {
  const subscription = subscriptionOccurrence?.billSubscription;
  if (!isRecurringPaymentsEnabled || !subscription?.numOfOccurrences) return '';

  const frequency = intervalTimeConverter[subscription.intervalType];
  if (!frequency) return '';

  return formatMessage('widgets.scheduleCard.recurringPaymentText', {
    frequency,
    numOfOccurrences: subscription.numOfOccurrences,
    occurrenceNumber: subscriptionOccurrence!.occurrenceNumber,
  });
};

export const intervalTimeConverter: { [key in BillSubscriptionIntervalTypeEnum]?: string } = {
  [BillSubscriptionIntervalTypeEnum.Weekly]: 'Weekly',
  [BillSubscriptionIntervalTypeEnum.Monthly]: 'Monthly',
  [BillSubscriptionIntervalTypeEnum.Yearly]: 'Yearly',
};

export const getPaymentActivityRecurringText = (
  formatMessage: FormatMessage,
  subscriptionOccurrence?: SubscriptionOccurrence | null,
) => {
  const subscription = subscriptionOccurrence?.billSubscription;
  if (!subscription?.numOfOccurrences) return '';
  const frequency = intervalTimeConverter[subscription.intervalType];
  if (!frequency) return '';

  return formatMessage('widgets.payDetailsScreen.recurringPaymentText', {
    frequency,
    numOfOccurrences: subscription.numOfOccurrences,
    occurrenceNumber: subscriptionOccurrence!.occurrenceNumber,
  });
};

export const isLastPayment = (subscriptionOccurrence?: SubscriptionOccurrence | null) => {
  const subscription = subscriptionOccurrence?.billSubscription;
  return subscriptionOccurrence && subscription?.numOfOccurrences === subscriptionOccurrence.occurrenceNumber;
};

export const getFinalPaymentTag = (formatMessage: FormatMessage) => ({
  tagText: formatMessage('widgets.payCard.badgeByType.finalPayment'),
  status: 'informative' as PayCardTagStatus,
});
