import React from 'react';
import { useFeeCatalog } from '@melio/platform-api';
import {
  DeliveryMethod,
  DeliveryMethodType,
  FeeCatalogValueTypeEnum,
  FeeType,
  FundingSource,
} from '@melio/platform-api';

import { usePlatformIntl } from '@/translations/Intl';
import {
  FeeByTypeProps as FeeBaseProps,
  FeeTypeByDeliveryMethod,
  getFeeTypeByFundingSource,
  roundFee,
} from '@/utils/fees.utils';

type FeeProps = Omit<FeeBaseProps, 'fees' | 'amount' | 'freeText'> & {
  amount?: number;
  feeType?: FeeType;
} & (
    | {
        type?: 'fundingSource';
        source?: FundingSource;
      }
    | {
        type?: 'deliveryMethod';
        source?: DeliveryMethod;
      }
  );

export const Fee = ({ feeType, type, source, amount = 1, prefix, suffix }: FeeProps) => {
  const { data: fees = [] } = useFeeCatalog();
  const { formatMessage, formatCurrency } = usePlatformIntl();

  const calcFeeType = feeType
    ? feeType
    : type === 'deliveryMethod'
    ? FeeTypeByDeliveryMethod[source!.type as DeliveryMethodType]
    : getFeeTypeByFundingSource(source as FundingSource);

  if (!calcFeeType) {
    return <>unsupported</>;
  }

  const catalogFee = fees.find((it) => it.feeType === calcFeeType);
  if (!catalogFee) {
    return <>{formatMessage('app.noFee.label')}</>;
  }

  const feeValue =
    catalogFee.valueType === FeeCatalogValueTypeEnum.Fixed
      ? formatCurrency(catalogFee.value)
      : `${roundFee(catalogFee.value * amount)}%`;

  return <>{`${prefix ? `${prefix} ` : ''}${feeValue}${suffix ? ` ${suffix}` : ''}`}</>;
};
