import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { WithLoading } from '@/hoc/withLoading.hoc';
import { RedirectType, useAppRedirectLink } from '@/hooks/useAppRedirect.hooks';

export const AppRedirectRoute = () => {
  const { link, type, isLoading } = useAppRedirectLink();
  const navigate = useNavigate();

  useEffect(() => {
    if (type === RedirectType.External) {
      window.location.href = link;
    }
    if (link) {
      navigate(link, { replace: true });
    }
  }, [link, navigate, type]);

  return <WithLoading isLoading={!link || isLoading} isAbsoluteCenter />;
};
