import { Box } from '@chakra-ui/react';
import { Form, Group, Text } from '@melio/penny';
import { useCollaborators } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { useFormContext } from '../FormContext';

function createArrayFromNumber(number: number): Array<number> {
  return Array.from(Array(number).keys()).map((x) => x + 1);
}

export function ApprovalAction() {
  const { formatMessage } = useMelioIntl();
  const { registerField, setValue, getValues } = useFormContext();
  const { data } = useCollaborators({
    expand: ['user'],
    suspense: true,
    select(res) {
      return res.filter(({ approvalActions: { canApprovePayments } }) => canApprovePayments);
    },
  });
  const options = createArrayFromNumber(Math.min(data?.length ?? Number.MAX_SAFE_INTEGER, 3)).map(
    (numberOfApprovers) => ({
      label: formatMessage('activities.create-approval-workflows.actions.approval.roles.title', {
        numberOfApprovers,
      }),
      value: String(numberOfApprovers),
      description: formatMessage('activities.create-approval-workflows.actions.approval.roles.description', {
        numberOfApprovers,
      }),
    })
  );

  const numberOfApproversFieldName = 'actions.0.configuration.numberOfApprovers';

  const field = registerField(numberOfApproversFieldName);

  return (
    <Group variant="horizontal" alignItems="center">
      <Box>
        <Text>{formatMessage('activities.create-approval-workflows.actions.approval.label')}</Text>
      </Box>
      <Box width="280px">
        <Form.Select
          {...field}
          value={String(getValues(numberOfApproversFieldName) || 0)}
          placeholder={formatMessage('activities.create-approval-workflows.actions.approval.placeholder')}
          aria-label="number of approvers"
          size="small"
          isRequired
          data-testid="number-of-approvers"
          emptyState={undefined}
          error={
            field.error
              ? {
                  message: formatMessage('activities.create-approval-workflows.actions.approval.errors.required'),
                }
              : undefined
          }
          onChange={(value) => setValue('actions.0.configuration.numberOfApprovers', Number(value.target.value))}
          options={options}
        ></Form.Select>
      </Box>
    </Group>
  );
}
