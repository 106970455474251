import { RoleUniqueNames } from '@melio/platform-api';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import { useIsSubscriptionsEnabled } from '@melio/subscriptions';
import { useCallback } from 'react';

export const useRoleDescription = () => {
  const { formatMessage } = useMelioIntl();
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();

  const getRoleDescription = useCallback(
    (roleUniqueName: RoleUniqueNames) => {
      if (isSubscriptionsEnabled && roleUniqueName === 'melio-accountant') {
        return formatMessage(
          `activities.manageCollaborators.screens.userDetailsForm.permissions.melio-accountant.withSubscription`
        );
      }

      return formatMessage(
        `activities.manageCollaborators.screens.userDetailsForm.permissions.${roleUniqueName}` as MessageKey
      );
    },
    [isSubscriptionsEnabled, formatMessage]
  );

  return { getRoleDescription };
};
