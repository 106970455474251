import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TaxpayerIdentificationRequestStatusEnum, Vendor } from '@melio/platform-api';

export const useW9StatusBanner = (vendor?: Vendor) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const taxPayerIdentificationRequestStatusCompleted =
    vendor?.lastTaxpayerIdentificationRequest?.status === TaxpayerIdentificationRequestStatusEnum.Completed;

  const isW9StatusBannerVisible =
    searchParams.get('showW9StatusBanner') === 'true' && taxPayerIdentificationRequestStatusCompleted;

  const setIsW9StatusBannerVisible = useCallback(
    (visible: boolean) =>
      setSearchParams(
        (params) => {
          if (visible) {
            params.set('showW9StatusBanner', 'true');
          } else {
            params.delete('showW9StatusBanner');
          }

          return params;
        },

        { replace: true },
      ),

    [setSearchParams],
  );

  return { isW9StatusBannerVisible, setIsW9StatusBannerVisible };
};
