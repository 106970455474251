/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  AccountingPlatform,
  AccountingPlatformSlug,
  Bill,
  BillLineItemLabel,
  BillLineItemResponse,
  PatchBillsBillIdRequest,
  PatchBillsBillIdRequestLineItem,
  PatchBillsBillIdRequestLineItemLabel,
} from '@melio/platform-api';

import {
  isItemBasedLineItem,
  mapFormLineItemToApiLineItem,
  mapXeroFormLineItemToApiLineItem,
} from '../add-bill/AddBillV2/utils';
import {
  AddBillV2DFormInitialValues,
  AddBillV2FormLineItem,
  AddBillV2FormValuesResult,
  BillLineItemType,
  BillLineItemType as FormBillLineItemType,
  CategoryBasedBillLineItem as FormCategoryBasedBillLineItem,
  ItemBasedBillLineItem as FormItemBasedBillLineItem,
  XeroSyncedBillLineItem as FormXeroSyncedBillLineItem,
} from '../add-bill/AddBillV2Form/types';
import { createEmptyXeroSyncedLineItem } from '../add-bill/AddBillV2Form/utils';

export function mapApiBillToFormBySoftwareAccount(bill: Bill, activeAccountingPlatform?: AccountingPlatform) {
  if (activeAccountingPlatform?.accountingSlug === AccountingPlatformSlug.Xero) {
    return mapApiBillToXeroForm(bill);
  }

  return mapApiBillToForm(bill);
}

export function mapApiBillToForm({ lineItems, invoice, note, ...bill }: Bill): AddBillV2DFormInitialValues {
  const items = (lineItems || []).map((line, index) => mapApiLineItemToForm(line, index + 1));
  if (items.length == 0) {
    items.push({
      amount: bill.amount?.toString(),
      externalCategoryId: bill.categoryId,
      description: '',
      type: BillLineItemType.CATEGORY,
    });
  }
  return {
    ...bill,
    amount: bill.amount?.toString(),
    noteToSelf: note || undefined,
    invoiceNumber: invoice.number || undefined,
    invoiceDate: invoice.date || undefined,
    fileId: invoice.fileId,
    externalLabelId: bill.externalLabelId ?? undefined,
    lineItems: items,
  };
}

export function mapApiBillToXeroForm({ lineItems, invoice, note, ...bill }: Bill): AddBillV2DFormInitialValues {
  const items = (lineItems || []).map((line, index) => mapApiLineItemToXeroForm(line, index + 1));

  if (items.length == 0) {
    const xeroItem = createEmptyXeroSyncedLineItem({
      amount: bill.amount?.toString(),
      externalCategoryId: bill.categoryId,
      description: '',
    });
    items.push(xeroItem);
  }
  return {
    ...bill,
    amount: bill.amount?.toString(),
    noteToSelf: note || undefined,
    invoiceNumber: invoice.number || undefined,
    invoiceDate: invoice.date || undefined,
    fileId: invoice.fileId,
    externalLabelId: bill.externalLabelId ?? undefined,
    lineItems: items,
  };
}

export function mapApiLineItemToForm(line: BillLineItemResponse, order: number): AddBillV2FormLineItem {
  if (isItemBasedLineItem(line)) {
    return {
      id: line.id,
      lineItemId: line.id,
      type: FormBillLineItemType.ITEM as const,
      amount: line.amount?.toString(),
      quantity: line.quantity?.toString(),
      unitPrice: line.unitPrice?.toString(),
      description: line.description,
      externalItemId: line.externalItemId,
      externalLabelId: line?.labels?.[0]?.externalLabelId ?? '',
      order,
    } as FormItemBasedBillLineItem;
  } else {
    return {
      id: line.id,
      lineItemId: line.id,
      type: FormBillLineItemType.CATEGORY as const,
      amount: line.amount?.toString(),
      description: line.description,
      externalCategoryId: line.externalCategoryId,
      externalLabelId: line?.labels?.[0]?.externalLabelId ?? '',
      order,
    } as FormCategoryBasedBillLineItem;
  }
}

export function mapApiLineItemToXeroForm(line: BillLineItemResponse, order: number): AddBillV2FormLineItem {
  return {
    id: line.id,
    lineItemId: line.id,
    order,
    type: line.externalItemId ? (FormBillLineItemType.ITEM as const) : (FormBillLineItemType.CATEGORY as const),
    amount: line.amount?.toString(),
    quantity: line.quantity?.toString(),
    unitPrice: line.unitPrice?.toString(),
    description: line.description ?? '',
    externalItemId: line.externalItemId ?? null,
    externalCategoryId: line.externalCategoryId ?? null,
  } as FormXeroSyncedBillLineItem;
}
export function mapFormBillToPatchApi(
  formValues: AddBillV2FormValuesResult,
  apiValues?: Bill,
  activeAccountingPlatform?: AccountingPlatform
): PatchBillsBillIdRequest {
  const { dueDate, lineItems, invoiceNumber, invoiceDate, noteToSelf, fileId, categoryId, ...bill } = formValues;
  const mapped: PatchBillsBillIdRequestLineItem[] = [];
  mapped.push(...mapFormLineItemsToPatchAPI(lineItems, mapped.length, activeAccountingPlatform, apiValues));
  mapped.push(...getDeletedLineItems(formValues, apiValues));
  const shouldUpdateCategoryId = categoryId !== lineItems[0]?.externalCategoryId;
  return {
    ...bill,
    note: noteToSelf,
    dueDate: dueDate?.toISOString(),
    amount: parseFloat(bill.amount!),
    invoice: {
      number: invoiceNumber,
      ...(invoiceDate ? { date: invoiceDate } : {}),
      fileId: fileId || null,
    },
    lineItems: mapped,
    categoryId: shouldUpdateCategoryId ? lineItems[0]?.externalCategoryId : categoryId,
  };
}

function mapFormLineItemLabelsToPatchAPI(
  labels?: BillLineItemLabel[],
  initialBillLineItemLabelId?: string
): PatchBillsBillIdRequestLineItemLabel[] {
  return (labels || []).map((label) =>
    initialBillLineItemLabelId
      ? {
          id: initialBillLineItemLabelId,
          operation: 'update',
          body: label,
        }
      : {
          operation: 'create',
          body: label,
        }
  );
}

function mapFormLineItemsToPatchAPI(
  lines: AddBillV2FormLineItem[],
  startIndex: number,
  activeAccountingPlatform?: AccountingPlatform,
  initialBillValue?: Bill
): PatchBillsBillIdRequestLineItem[] {
  return (lines || [])
    .filter((l) => l.amount != null)
    .map((line, arrIndex) => {
      const initialLineItem = initialBillValue?.lineItems?.find((l) => l.id === line.id);
      const initialBillLineItemLabelId = initialLineItem?.labels?.[0]?.id;
      return line.id
        ? {
            id: line.id,
            operation: 'update',
            body: {
              ...mapFormLineItemToApiLineItemBySoftwareAccount(
                line,
                startIndex + arrIndex + 1,
                activeAccountingPlatform
              ),
              labels: mapFormLineItemLabelsToPatchAPI(line.labels, initialBillLineItemLabelId),
            },
          }
        : {
            operation: 'create',
            body: mapFormLineItemToApiLineItemBySoftwareAccount(
              line,
              startIndex + arrIndex + 1,
              activeAccountingPlatform
            ),
          };
    });
}

export function mapFormLineItemToApiLineItemBySoftwareAccount(
  line: AddBillV2FormLineItem,
  order: number,
  activeAccountingPlatform?: AccountingPlatform
) {
  if (activeAccountingPlatform?.accountingSlug === AccountingPlatformSlug.Xero) {
    return mapXeroFormLineItemToApiLineItem(line, order);
  }
  return mapFormLineItemToApiLineItem(line, order);
}

function getDeletedLineItems(
  formValues: AddBillV2FormValuesResult,
  apiValues?: Bill
): PatchBillsBillIdRequestLineItem[] {
  const apiLineItemIds = apiValues?.lineItems?.map((l) => l.id) || [];
  const lineItems = formValues.lineItems?.filter((l) => !!l.id).map((l) => l.id) || [];
  return apiLineItemIds.filter((id) => !lineItems.includes(id)).map((id) => ({ id, operation: 'delete' }));
}
