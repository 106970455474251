import { useCancelInvitation } from '@melio/platform-api';
import { ComponentProps, useState } from 'react';

import { useAsyncActionWithToast } from './hooks/useAsyncActionWithToast';
import { DeleteCollaboratorModalScreen } from './screens/modals/DeleteCollaborator/DeleteCollaborator.modal-screen';
import type { Invitation } from './types';

export const useCancelInvitationModal = () => {
  const [state, setModalState] = useState<{
    isOpen: boolean;
    invitation: Invitation | null;
  }>({
    isOpen: false,
    invitation: null,
  });
  const { cancel: cancelInvitation, isCanceling } = useCancelInvitation({
    id: state.invitation?.id,
    enabled: !!state.invitation?.id,
  });

  const openModal = (invitation: Invitation) => setModalState({ isOpen: true, invitation });
  const closeModal = () => setModalState({ isOpen: false, invitation: null });

  const actionWithToast = useAsyncActionWithToast({
    successLabel: 'activities.manageCollaborators.messages.invitation.cancel.success',
    errorLabel: 'activities.manageCollaborators.messages.invitation.cancel.error',
    onSuccess: closeModal,
    isInfo: true,
  });

  const submit: ComponentProps<typeof DeleteCollaboratorModalScreen>['onDone'] = async () => {
    await actionWithToast(cancelInvitation);
  };

  return {
    openModal,
    closeModal,
    state,
    submit,
    isCanceling,
  };
};
