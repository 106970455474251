import { Bill, Vendor } from '@melio/platform-api';

import { PayDashboardTabSortableColumn } from '../../types';

export type VendorTabAmountCellAmounts = {
  [vendorId: Vendor['id']]: string;
};

export type VendorsTableMeta = {
  unpaidBills: Bill[];
  onAddNewBillClick: (vendorId: string) => void;
  onEditVendorClick: (vendorId: string) => void;
  onViewVendorClick: (vendorId: string) => void;
  onDeleteVendorClick: (vendor: Vendor) => void;
  onNewPaymentClick: (vendorId: string, amount?: string) => void;
  onAmountChange: (vendor: Vendor, value: string) => void;
  amounts: VendorTabAmountCellAmounts;
  getItemAriaLabelContext: (vendor: Vendor) => string;
  rowSelections: string[];
};

export const CELLS_IDS = {
  VENDOR_NAME: 'vendorName',
  UNPAID_BILLS: 'unpaidBills',
  LAST_PAID: 'lastPaid',
  OPEN_BALANCE: 'openBalance',
  ACTION_BUTTON: 'actionButton',
  MORE_ACTIONS: 'moreActions',
} as const;

export type VendorsTabSortableCells = Extract<
  (typeof CELLS_IDS)[keyof typeof CELLS_IDS],
  typeof CELLS_IDS.VENDOR_NAME | typeof CELLS_IDS.LAST_PAID | typeof CELLS_IDS.OPEN_BALANCE
>;

export type VendorsTabSortableColumn = PayDashboardTabSortableColumn<VendorsTabSortableCells>;
