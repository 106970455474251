import { PaymentFeeDetails, usePaymentFeesDetails } from '@melio/ap-domain';
import { Divider, Group, Text } from '@melio/penny';
import { DeliveryPreferenceType } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useDebounce } from '@melio/platform-utils';
import _ from 'lodash';

import { SectionHeader } from '../SectionHeader';

export function FeesSection({
  amount,
  deliveryMethodId,
  deliveryPreferenceType,
  fundingSourceId,
  lastAmount,
}: {
  amount: string;
  deliveryMethodId: string;
  fundingSourceId: string;
  deliveryPreferenceType: DeliveryPreferenceType;
  lastAmount?: string;
}) {
  const { formatMessage } = useMelioIntl();
  const debouncedAmount = useDebounce(amount, 500);
  const debouncedLastAmount = useDebounce(lastAmount, 500);

  const feesDetails = usePaymentFeesDetails({
    amount: Number(debouncedAmount),
    deliveryPreferenceType,
    deliveryMethodId,
    fundingSourceId,
    lastAmount: !_.isEmpty(debouncedLastAmount) ? Number(debouncedLastAmount) : undefined,
  });
  if (feesDetails.isLoading || !feesDetails.feeItems?.length) {
    return null;
  }

  return (
    <Group width="full" variant="vertical" spacing="s" data-testid="fees-section">
      <SectionHeader>{formatMessage('activities.paymentFlow.form.content.fees.title')}</SectionHeader>

      <Group as="ul" variant="vertical" spacing="s">
        {feesDetails.feeItems.map((fee) => (
          <FeeItem key={fee.type} {...fee} />
        ))}

        <Divider aria-hidden="true" />

        <Group as="li" justifyContent="space-between">
          <Group variant="vertical" spacing="xxxs">
            <Text as="p" textStyle="body2Semi">
              {formatMessage('widgets.reviewAndConfirm.feesSection.totalFeesLabel')}
            </Text>
            <Text as="p" textStyle="body4" color="global.neutral.900">
              {feesDetails.total.description}
            </Text>
          </Group>
          <Text textStyle="body2Semi" data-testid="fees-section-total-amount">
            {feesDetails.total.amount}
          </Text>
        </Group>
      </Group>
    </Group>
  );
}

const FeeItem = ({ amount, description, helperText, type }: PaymentFeeDetails) => {
  const { formatCurrency } = useMelioIntl();

  return (
    <Group as="li" justifyContent="space-between" data-testid={`fee-item-${type}`}>
      <Group variant="vertical" spacing="xxs">
        <Text>{description}</Text>
        {helperText && (
          <Text textStyle="body4" color="global.neutral.900">
            {helperText}
          </Text>
        )}
      </Group>
      <Text textStyle="body2" data-testid={`fee-item-amount-${type}`}>
        {formatCurrency(amount)}
      </Text>
    </Group>
  );
};
