import { usePayDashboardPagination } from '../components/Pagination';
import { sortPaymentsByCreatedAt } from '../utils/sort.utils';
import { useGetNewPayments } from './useGetNewPayments';

export const useNewPaymentsTabItems = () => {
  const { pageSize } = usePayDashboardPagination();

  return useGetNewPayments({
    preferenceKey: 'payDashboardPaymentsTabLastSeen',
    sortPaymentsBy: sortPaymentsByCreatedAt,
    paymentsParams: {
      limit: pageSize,
      expand: 'none',
      sort: [
        {
          field: 'createdAt',
          order: 'desc',
        },
      ],
    },
  });
};
