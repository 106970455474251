import {
  checkCountryRiskLevel,
  getIsChangingDeliveryMethodIsDisabled,
  isFXCurrency,
  isTransferRequireForCurrency as isTransferFieldsRequireForCurrency,
} from '@melio/ap-domain';
import { Currency, getCountries } from '@melio/international-payments-utils';
import {
  DeliveryMethod,
  DeliveryMethodType,
  InternationalAccountIdentifierTypeEnum,
  Payment,
  Vendor,
} from '@melio/platform-api';

const getSkipDeliveryMethodStep = (
  actionToPerform: 'schedule' | 'edit',
  isDeliveryMethodSelectionStepVisited: boolean,
  deliveryMethod?: DeliveryMethod | null,
  deliveryMethodIdFromState?: string
) => {
  const isVirtualAccount = deliveryMethod?.type === DeliveryMethodType.VirtualAccount;

  if (deliveryMethod && getIsChangingDeliveryMethodIsDisabled(deliveryMethod)) {
    return true;
  }

  const isScheduledButNotVisited = actionToPerform === 'schedule' && !isDeliveryMethodSelectionStepVisited;
  const skipWhenNewDmCreatedInVendorDashboard = deliveryMethodIdFromState && isScheduledButNotVisited;
  const skipWhenDmExistOnVendor = deliveryMethod && !isVirtualAccount && isScheduledButNotVisited;

  return skipWhenNewDmCreatedInVendorDashboard || skipWhenDmExistOnVendor;
};

export const getSkipVendorEmail = (vendor?: Vendor) => vendor?.isManaged;

export const getVendorEmail = (vendor?: Vendor) => vendor?.contact?.email || '';

export type ActionToPerform = 'schedule' | 'edit';
export type GetConditionalStepType = {
  actionToPerform: ActionToPerform;
  isDeliveryMethodSelectionStepVisited: boolean;
  deliveryMethod?: DeliveryMethod | null;
  deliveryMethodIdFromState?: string;
  isFinancingSelected?: boolean;
  shouldDisplayMemoToVendor: (deliveryMethod: DeliveryMethod) => boolean;
  hasInvoice?: boolean;
  vendorId?: string;
  paymentsData?: Payment[];
};

export const getConditionalSteps = ({
  actionToPerform,
  isDeliveryMethodSelectionStepVisited,
  deliveryMethodIdFromState,
  deliveryMethod,
  isFinancingSelected,
  paymentsData,
  vendorId,
  hasInvoice = false,
  shouldDisplayMemoToVendor,
}: GetConditionalStepType) => {
  const skipDeliveryMethodStep = getSkipDeliveryMethodStep(
    actionToPerform,
    isDeliveryMethodSelectionStepVisited,
    deliveryMethod,
    deliveryMethodIdFromState
  );
  const hasMemoToVendorStep = !deliveryMethod || shouldDisplayMemoToVendor(deliveryMethod);
  const isInternationalDM = deliveryMethod?.type === DeliveryMethodType.InternationalAccount;

  const hasSelectDeliveryDateStep = () => {
    if (!isInternationalDM) {
      return true;
    }

    return !isFXCurrency(deliveryMethod?.details?.currency);
  };

  const hasPaymentPurposeStep = () => {
    if (!isInternationalDM) {
      return false;
    }

    const identifierType = deliveryMethod?.details?.identifierType;
    const currency = deliveryMethod?.details?.currency as Currency;

    if (identifierType !== InternationalAccountIdentifierTypeEnum.V2) {
      return true;
    }
    const bankCountryCode = deliveryMethod?.details.identifierDetails.bankCountryCode;
    const country = getCountries().find((c) => c.code === bankCountryCode);
    if (checkCountryRiskLevel({ country, paymentsData, hasInvoice, vendorId })) {
      return true;
    }

    return isTransferFieldsRequireForCurrency(currency);
  };

  return {
    hasDeliveryMethodStep: !skipDeliveryMethodStep,
    hasMemoToVendorStep,
    hasPaymentPurposeStep: hasPaymentPurposeStep(),
    hasRepaymentTermsStep: isFinancingSelected,
    hasSelectDeliveryDateStep: hasSelectDeliveryDateStep(),
  };
};
