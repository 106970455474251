import { FlagKey } from '@melio/penny';
import { MessageKey } from '@melio/platform-i18n';

export type CurrencyDetails = { label: MessageKey; flag: FlagKey; sign: string; currencyCode: string };

export type CurrencyDictionaryType = Record<string, CurrencyDetails>;

export const currencyDictionary: CurrencyDictionaryType = {
  USD: {
    label: 'currencies.USD.label',
    flag: 'US',
    sign: '$',
    currencyCode: 'USD',
  },
  CAD: {
    label: 'currencies.CAD.label',
    flag: 'CA',
    sign: '$',
    currencyCode: 'CAD',
  },
  EUR: {
    label: 'currencies.EUR.label',
    flag: 'EU',
    sign: '€',
    currencyCode: 'EUR',
  },
  GBP: {
    label: 'currencies.GBP.label',
    flag: 'GB',
    sign: '£',
    currencyCode: 'GBP',
  },
  INR: {
    label: 'currencies.INR.label',
    flag: 'IN',
    sign: '₹',
    currencyCode: 'INR',
  },
  CNY: {
    label: 'currencies.CNY.label',
    flag: 'CN',
    sign: '¥',
    currencyCode: 'CNY',
  },
  HKD: {
    label: 'currencies.HKD.label',
    flag: 'HK',
    sign: '$',
    currencyCode: 'HKD',
  },
  PHP: {
    label: 'currencies.PHP.label',
    flag: 'PH',
    sign: '₱',
    currencyCode: 'PHP',
  },
  PLN: {
    label: 'currencies.PLN.label',
    flag: 'PL',
    sign: 'zł',
    currencyCode: 'PLN',
  },
  BRL: {
    label: 'currencies.BRL.label',
    flag: 'BR',
    sign: 'R$',
    currencyCode: 'BRL',
  },
  AUD: {
    label: 'currencies.AUD.label',
    flag: 'AU',
    sign: '$',
    currencyCode: 'AUD',
  },
  ILS: {
    label: 'currencies.ILS.label',
    flag: 'IL',
    sign: '₪',
    currencyCode: 'ILS',
  },
  SEK: {
    label: 'currencies.SEK.label',
    flag: 'SE',
    sign: 'kr',
    currencyCode: 'SEK',
  },
  SGD: {
    label: 'currencies.SGD.label',
    flag: 'SG',
    sign: '$',
    currencyCode: 'SGD',
  },
  KRW: {
    label: 'currencies.KRW.label',
    flag: 'KR',
    sign: '₩',
    currencyCode: 'KRW',
  },
  GEL: {
    label: 'currencies.GEL.label',
    flag: 'GE',
    sign: '₾',
    currencyCode: 'GEL',
  },
  MXN: {
    label: 'currencies.MXN.label',
    flag: 'MX',
    sign: '$',
    currencyCode: 'MXN',
  },
};

export const defaultCurrencyDetails: CurrencyDetails = {
  label: 'currencies.USD.label',
  flag: 'US',
  sign: '$',
  currencyCode: 'USD',
};

export const getCurrencyDetails = (currency?: string) =>
  currency ? currencyDictionary[currency] : defaultCurrencyDetails;

export function getSupportedCurrenciesDefs(
  currencyDictionary: Record<string, CurrencyDetails>,
  supportedCurrencies: string[] = []
): CurrencyDetails[] {
  return Object.entries(currencyDictionary)
    .filter(([key]) => supportedCurrencies.includes(key))
    .map(([, value]) => value)
    .sort((a, b) => a.currencyCode.localeCompare(b.currencyCode));
}
