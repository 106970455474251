import { useLocation } from '@melio/platform-utils';
import { Fragment } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useGracePreiodEndPaywall } from '../hooks/useGracePeriodEndPaywall';
import { useIsSubscriptionsEnabledBase } from '../hooks/useIsSubscriptionsEnabledBase';
import {
  ContextualPaywallModalActivity,
  GracePeriodModalActivity,
  OnboardingPaywallModalActivity,
  VipWelcomeModalActivity,
} from '../ui';
import {
  ACCOUNTING_CLIENT_SUBSCRIPTION_PLAN_SELECTED_ROUTE,
  NOT_SUBSCRIBED_ORGS_ALLOWED_ROUTES,
} from '../ui/constants';
import { useSubscriptionContext } from './SubscriptionContext';

export const SubscriptionAppFlows = () => {
  const { pathname } = useLocation();
  const {
    accountingClientSelectedSubscriptionPlan,
    subscription,
    gracePeriod: {
      isEligibleForGracePeriod,
      isTodayInGracePeriod,
      isOrgCreatedBeforeGracePeriodStart,
      isUserCreatedBeforeGracePeriodStart,
      isEligibleAndGracePeriodEnded,
    },
  } = useSubscriptionContext();
  const isSubscriptionsEnabled = useIsSubscriptionsEnabledBase();

  const { graceEndRedirectRoute, shouldShowGracePeriodEndPaywall } = useGracePreiodEndPaywall();

  const isAccountingClientPlanSelectedByFirm = accountingClientSelectedSubscriptionPlan && !subscription;
  if (
    isAccountingClientPlanSelectedByFirm &&
    !NOT_SUBSCRIBED_ORGS_ALLOWED_ROUTES.some((route) => route.startsWith(pathname))
  ) {
    return <Navigate to={ACCOUNTING_CLIENT_SUBSCRIPTION_PLAN_SELECTED_ROUTE} />;
  }

  const WithSubscriptionActivities =
    isOrgCreatedBeforeGracePeriodStart && isUserCreatedBeforeGracePeriodStart ? (
      <VipWelcomeModalActivity />
    ) : (
      <Fragment />
    );

  const WithoutSubscriptionActivities =
    isEligibleForGracePeriod && isTodayInGracePeriod ? (
      <GracePeriodModalActivity />
    ) : (
      <OnboardingPaywallModalActivity />
    );

  const shouldRenderModals =
    isSubscriptionsEnabled && !isAccountingClientPlanSelectedByFirm && !isEligibleAndGracePeriodEnded;

  return (
    <>
      <Outlet />
      {isSubscriptionsEnabled && shouldShowGracePeriodEndPaywall && <Navigate to={graceEndRedirectRoute} replace />}
      {shouldRenderModals && (
        <>
          <ContextualPaywallModalActivity />
          {subscription ? WithSubscriptionActivities : WithoutSubscriptionActivities}
        </>
      )}
    </>
  );
};
