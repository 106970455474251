import { PaymentReviewLineItem } from '@melio/ap-widgets';
import { Group, Typography } from '@melio/penny';
import { Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { useFailureDescriptionProps } from '../useFailureDescriptionProps';

export const PayBySection = ({ payment }: { payment: Payment }) => {
  const { formatMessage } = useMelioIntl();
  const fundingSource = payment.fundingSource;
  const { nickname, displayName } = fundingSource || {};

  const { isFailure, descriptionProps } = useFailureDescriptionProps({ payment });

  return (
    <Group variant="vertical">
      <Typography.SectionLabel
        label={formatMessage('activities.payDashboard.drawer.body.loan.active.payBy.sectionLabel')}
      />
      <PaymentReviewLineItem
        labelProps={{ label: formatMessage('activities.payDashboard.drawer.body.loan.active.payBy.lineItem.label') }}
        mainLabelProps={{
          label: nickname || displayName || '',
        }}
        {...(isFailure && { descriptionProps })}
        icon={{ type: 'bank' }}
        isInvalid={isFailure}
      />
    </Group>
  );
};
