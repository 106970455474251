import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { FundingSource } from '@melio/platform-api';

import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';
import { SuccessBillingScreen } from '../screens/SuccessBilling/SuccessBilling.screen';

type Props = {
  fundingSource?: FundingSource;
  completeFlow: VoidFunction;
};

export const SuccessBillingStep = ({ fundingSource, completeFlow }: Props) => {
  const { createTrackHandler } = useAnalytics();
  const trackAndHandleClick = createTrackHandler<{
    Cta: 'back-to-billing-settings';
  }>('PaymentMethod', 'Click');

  useAnalyticsView('payment-method-for-check-fees-changed');

  const onDone = () => {
    trackAndHandleClick({ Cta: 'back-to-billing-settings' }, () => completeFlow());
  };

  if (!fundingSource) {
    return <NewSinglePaymentStepLayout />;
  }

  return <SuccessBillingScreen fundingSource={fundingSource} onDone={onDone} />;
};
