import { Address } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';
import { useCallback } from 'react';
import SmartyStreetsSDK from 'smartystreets-javascript-sdk';

export type AddressExtractSuggestion = {
  city: string;
  secondary: string;
  state: string;
  streetLine: string;
  zipcode: string;
};

const useSmartyStreetsClient = () => {
  const SmartyStreetsCore = SmartyStreetsSDK.core;
  const config = useConfig();
  const credentials = new SmartyStreetsCore.SharedCredentials(config.services.smartystreets.apiKey);

  return SmartyStreetsCore.buildClient.usStreet(credentials);
};

export const useExtractAddress = () => {
  const client = useSmartyStreetsClient();

  const searchStreetAddress = useCallback(
    async (searchQuery: string): Promise<Address[]> => {
      if (!searchQuery) {
        return [];
      }

      const { lookups } = await client.send(new SmartyStreetsSDK.usStreet.Lookup(searchQuery));

      return (
        lookups[0]?.result?.map(
          (lookup) =>
            ({
              line1: lookup.deliveryLine1,
              line2: lookup.deliveryLine2,
              city: lookup.components.cityName,
              state: lookup.components.state,
              postalCode: lookup.components.zipCode,
            } as Address)
        ) ?? []
      );
    },
    [client]
  );

  const addressExtract = useCallback(
    async (searchQuery?: string | null): Promise<Address[]> => {
      if (searchQuery) {
        const result = await searchStreetAddress(searchQuery);
        return result;
      }

      return [];
    },
    [searchStreetAddress]
  );

  return { addressExtract };
};
