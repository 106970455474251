import { Group, Typography } from '@melio/penny';
import { Loan } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { InstallmentsTable } from '../../../../payment-drawer/components/payment-details/sections/loan-section/components/InstallmentsTable';

export const InstallmentsSection = ({ loan }: { loan: Loan }) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Group variant="vertical">
      <Typography.SectionLabel
        label={formatMessage('activities.payDashboard.drawer.body.loan.active.installments.sectionLabel')}
      />
      <InstallmentsTable loan={loan} />
    </Group>
  );
};
