import { useMelioIntl } from '@melio/ar-domain';
import { StatusModal, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export type CannotDeleteCustomerModalProps = {
  isOpen: boolean;
  customerName: string;
  onClose: VoidFunction;
};

export const CannotDeleteCustomerModal = forwardRef<CannotDeleteCustomerModalProps>(
  ({ isOpen, customerName, onClose, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    return (
      <StatusModal
        variant="alert"
        header={formatMessage('ar.dashboard.activities.deleteCustomer.errorModal.header')}
        isOpen={isOpen}
        onClose={onClose}
        secondaryButton={{
          label: formatMessage('ar.dashboard.activities.deleteCustomer.errorModal.buttons.primary.label'),
          onClick: onClose,
          variant: 'tertiary',
        }}
        data-testid="cannot-delete-customer-modal"
        data-component={CannotDeleteCustomerModal.displayName}
        {...props}
        ref={ref}
      >
        <Text>
          {formatMessage('ar.dashboard.activities.deleteCustomer.errorModal.description', {
            customerName,
          })}
        </Text>
      </StatusModal>
    );
  }
);
CannotDeleteCustomerModal.displayName = 'CannotDeleteCustomerModal';
