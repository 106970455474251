import { Box } from '@chakra-ui/react';
import { useLocation } from '@melio/platform-utils';
import { SubscriptionChangePlanButton } from '@melio/subscriptions';

import { TopNavLayoutTheme } from '@/cl/layouts/app-layout/topnav.layout.theme';
import { useAppTheme } from '@/cl/theme/theme.hooks';

export const TopNavSubscriptionChangePlanButton = () => {
  const styles = useAppTheme(TopNavLayoutTheme, {});
  const location = useLocation();

  return (
    <Box sx={styles['cta']}>
      <SubscriptionChangePlanButton data-testid="top-nav-change-subscription-button" returnUrl={location.pathname} />
    </Box>
  );
};
