import { useRoleText } from '@melio/ap-domain';
import { Group, Icon, Pill, Text } from '@melio/penny';
import { Collaborator, useApprovalWorkflows } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { isCollaboratorBlocked } from '@melio/platform-utils';
import { isNull } from 'lodash';

import { useAllowedActions } from '../useAllowedActions';
import { useCollaboratorsApprovalLimit } from './hooks/useCollaboratorsApprovalLimit';

type Props = { actor: Collaborator<'user'>; collaborator: Collaborator<'user'> };
export const CollaboratorListItem = ({ actor, collaborator }: Props) => {
  const { canViewPaymentApprovalLimit } = useAllowedActions();

  const isBlocked = isCollaboratorBlocked(collaborator);
  return (
    <Group justifyContent="space-between" width="full">
      <Group data-testid={`collaborator-list-item-container-${collaborator.id}`} variant="vertical" spacing="xxs">
        <NameSection actor={actor} collaborator={collaborator} isBlocked={isBlocked} />

        <Group variant="vertical" spacing="xs">
          <Group variant="vertical" spacing="xxxs">
            <EmailSection collaborator={collaborator} />

            {!isBlocked && canViewPaymentApprovalLimit ? <ApprovalLimitSection collaborator={collaborator} /> : null}
          </Group>

          <RoleSection actor={actor} collaborator={collaborator} isBlocked={isBlocked} />
        </Group>
      </Group>

      <Icon type="chevron-right" size="small" />
    </Group>
  );
};

const NameSection = ({
  actor,
  collaborator,
  isBlocked,
}: Pick<Props, 'actor' | 'collaborator'> & { isBlocked: boolean }) => {
  const { formatMessage } = useMelioIntl();

  const fullName = `${collaborator.user.firstName} ${collaborator.user.lastName}`;
  return (
    <Group spacing="xxxs" data-testid="collaborator-list-item-name-section">
      <Text
        textStyle="body3Semi"
        color={isBlocked ? 'global.neutral.800' : 'global.neutral.1000'}
        shouldSupportEllipsis
      >
        {fullName}
      </Text>

      {collaborator.id === actor.id ? (
        <Text textStyle="body3Semi" color="global.neutral.1000">
          {`${formatMessage('activities.settings.collaborators.table.cells.user.actor')}`}
        </Text>
      ) : null}
    </Group>
  );
};

const EmailSection = ({ collaborator }: Pick<Props, 'collaborator'>) => (
  <Text
    textStyle="body3"
    color="global.neutral.800"
    shouldSupportEllipsis
    data-testid="collaborator-list-item-email-section"
  >
    {collaborator.user.email}
  </Text>
);

const ApprovalLimitSection = ({ collaborator }: Pick<Props, 'collaborator'>) => {
  const { formatMessage, formatCurrency } = useMelioIntl();

  const { data: approvalWorkflows } = useApprovalWorkflows();
  const { getApprovalThresholdValue } = useCollaboratorsApprovalLimit({
    approvalWorkflows,
    userId: collaborator.userId,
  });

  const approvalThreshold = getApprovalThresholdValue();
  return !isNull(approvalThreshold) ? (
    <Group
      alignItems="center"
      width="full"
      data-testid="collaborator-list-item-approval-limit-section"
      spacing="xs"
      hasDivider
      dividerProps={{ role: 'presentation' }}
    >
      <Text textStyle="body3" color="global.neutral.800">
        {formatMessage('activities.settings.collaborators.table.headers.approvalLimit')}
      </Text>

      <Text textStyle="body3" color="global.neutral.1000">
        {formatMessage('activities.settings.collaborators.table.cells.approvalLimit.full', {
          amount: (
            <Text textStyle="body3Semi" color="inherit">
              {formatCurrency(approvalThreshold)}
            </Text>
          ),
        })}
      </Text>
    </Group>
  ) : null;
};

const RoleSection = ({
  actor,
  collaborator,
  isBlocked,
}: Pick<Props, 'actor' | 'collaborator'> & { isBlocked: boolean }) => {
  const { formatMessage } = useMelioIntl();
  const { getRoleText } = useRoleText();

  return !isBlocked ? (
    <Pill
      type={collaborator.id === actor.id ? 'primary' : 'secondary'}
      status="neutral"
      label={getRoleText(collaborator.roleUniqueName)}
      data-testid="collaborator-list-item-role-section"
    />
  ) : (
    <Text textStyle="body3" color="global.neutral.700">
      {formatMessage('activities.settings.collaborators.table.cells.role.inactive')}
    </Text>
  );
};
