import {
  DeliveryMethodType,
  DeliveryPreferenceType,
  Payment,
  PaymentProcessStatusEnum,
  PaymentStatusEnum,
  Vendor,
} from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';
import { addDays } from 'date-fns';
import { useCallback } from 'react';

export const usePaymentProcessingInfo = () => {
  const { virtualCardExpiryPeriodInDays } = useConfig().settings;

  const isPaymentProcessedByCapitalOne = useCallback(
    (deliveryType: DeliveryMethodType | DeliveryPreferenceType | undefined) =>
      //Today that's the only indicator, in the future we could add a better one if needed;
      deliveryType === 'virtual-card' && !!virtualCardExpiryPeriodInDays,
    [virtualCardExpiryPeriodInDays]
  );

  const isPaymentProcessedByFiserv = (vendor: Vendor) => vendor.managedBy === 'fiserv';

  const calculateExpiryDate = (scheduledDate: Date): Date => addDays(scheduledDate, virtualCardExpiryPeriodInDays);

  const isCompletedPaymentStillProcessing = (payment: Payment) => {
    if (payment.status !== PaymentStatusEnum.Completed) {
      throw new Error('payment status is not completed');
    }

    if (!isPaymentProcessedByCapitalOne(payment.deliveryPreferenceType ?? payment.deliveryMethod?.type)) {
      return false;
    }

    return payment.deliverStatus !== PaymentProcessStatusEnum.Settled;
  };

  return {
    isPaymentProcessedByCapitalOne,
    isPaymentProcessedByFiserv,
    calculateExpiryDate,
    virtualCardExpiryPeriodInDays,
    isCompletedPaymentStillProcessing,
  };
};

export type PaymentProcessingInfo = ReturnType<typeof usePaymentProcessingInfo>;
