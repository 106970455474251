import { forwardRef, HTMLTag } from '@melio/platform-utils';
import { ComponentType, isValidElement, ReactElement } from 'react';
import { Outlet } from 'react-router-dom';

import { HOCReturnType } from './types';

export function withOutlet<T, TTag extends HTMLTag = 'div'>(UI: ComponentType<T>): HOCReturnType<T, TTag>;
export function withOutlet(UI: ReactElement): ReactElement;

export function withOutlet<T, TTag extends HTMLTag = 'div'>(UI: ComponentType<T> | ReactElement) {
  if (isValidElement(UI)) {
    return addOutlet(UI);
  }
  return forwardRef<T, TTag>((props, ref) => addOutlet(<UI {...props} ref={ref} />));
}

function addOutlet(UI: ReactElement): ReactElement {
  return (
    <>
      {UI}
      <Outlet />
    </>
  );
}
