import { useMelioIntl, useServiceChargeCatalog } from '@melio/ar-domain';
import { useToast } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { pick } from 'lodash';

import { ServiceChargeFormDialogScreen, ServiceChargeFormFields } from './screens';

type EditServiceChargeDialogActivityProps = {
  serviceChargeId: string;
  onDone: (id: string) => unknown;
  onClose: VoidFunction;
  onError?: ARErrorFunction;
};

export const EditServiceChargeDialogActivity = forwardRef<EditServiceChargeDialogActivityProps>(
  ({ serviceChargeId, onDone: onSuccess, onClose, onError, ...props }, ref) => {
    const {
      data: serviceCharge,
      update,
      isMutating,
      isLoading,
    } = useServiceChargeCatalog({ id: serviceChargeId, onError });
    const { formatMessage } = useMelioIntl();
    const { toast } = useToast();

    const onSubmit = (values: ServiceChargeFormFields) =>
      update(values)
        .then((updatedServiceCharge) => {
          onSuccess(updatedServiceCharge.id);
          toast({
            type: 'success',
            title: formatMessage('ar.settings.activities.addServiceCharge.toast.onEdit.label', {
              name: updatedServiceCharge.name,
            }),
          });
        })
        .catch(onError);

    return (
      <ServiceChargeFormDialogScreen
        data-testid="edit-service-charge-dialog"
        isOpen
        defaultValues={pick(serviceCharge, ['name', 'type', 'value'])}
        variant="edit"
        isSaving={isMutating}
        isLoading={isLoading}
        onClose={onClose}
        onSubmit={onSubmit}
        {...props}
        ref={ref}
      />
    );
  }
);
EditServiceChargeDialogActivity.displayName = 'EditServiceChargeActivity';
