import { Form, Group, OnSubmissionStateChange, useMelioForm } from '@melio/penny';
import { Bill } from '@melio/platform-api';

import { PaymentFlowFormFields } from '../../../../payment-flow';
import { EbillAmountDropdown } from '../../../../payment-flow/PaymentFlowActivity/PaymentFlowForm/components/AmountInput/components/EbillAmountDropdown';
import { useEditAmountFormSchema } from '../useEditAmountFormSchema';

export type EditPaymentAmountFormProps = {
  defaultValues?: Partial<PaymentFlowFormFields>;
  onSubmit: (data: PaymentFlowFormFields) => void;
  onSubmissionStateChange: OnSubmissionStateChange<PaymentFlowFormFields>;
  bill: Bill;
  isSaving?: boolean;
};

export const EditPaymentAmountForm = ({
  onSubmit,
  onSubmissionStateChange,
  bill,
  isSaving,
}: EditPaymentAmountFormProps) => {
  const form = useMelioForm<PaymentFlowFormFields>({
    onSubmit,
    onSubmissionStateChange,
    isSaving,
    defaultValues: { amountToPay: bill.amount.toString() },
    schema: useEditAmountFormSchema(),
  });

  return (
    <Group variant="vertical">
      <Form {...form.formProps}>
        <EbillAmountDropdown
          form={form}
          bill={bill}
          onChange={(amountToPay) => {
            form.setValue('amountToPay', amountToPay);
          }}
          shouldHideViewEbill
        />
      </Form>
    </Group>
  );
};
