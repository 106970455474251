import { Route, Routes } from 'react-router-dom';
import { useAccountantsRoutes } from '@melio/accountants';
import { SubscriptionGraceRoutes } from '@melio/subscriptions';

import { useSwitchOrganization } from '@/hooks/useSwitchOrganization.hooks';

export const SubscriptionsGraceRouter = () => {
  const { switchOrganization } = useSwitchOrganization();
  const { goToAssignClientPlan } = useAccountantsRoutes();

  return (
    <Routes>
      <Route
        path="*"
        element={
          <SubscriptionGraceRoutes
            switchOrganization={({
              organizationId,
              skipPayDashboardRedirect,
            }: {
              organizationId: string;
              skipPayDashboardRedirect?: boolean;
            }) => switchOrganization({ organizationId, skipPayDashboardRedirect, isAccountingFirm: false })}
            goToAssignClientPlan={({ accountingClientId }) =>
              goToAssignClientPlan({ accountingClientId, refresh: true })
            }
          />
        }
      />
    </Routes>
  );
};
