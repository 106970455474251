import { Form, useMelioForm, UseMelioFormResults } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { WithAnalyticsContextProps } from '@melio/platform-analytics/src/hooks/withAnalyticsContext';
import { useMelioIntl } from '@melio/platform-i18n';
import { Plan, Tier, usePlanInfo } from '@melio/subscriptions';
import { useEffect } from 'react';

import { CollapsibleCardSection } from '../../../../components/CollapsibleCardSection/CollapsibleCardSection.component';
import { useSubscriptionFundingSource } from '../../../../hooks';
import { useGetFormSessionStorage, useSetFormSessionStorage } from '../../../../hooks/useFormSessionStorage';
import { useGetAccountingFirmClientPlanByTier } from '../../../../hooks/useGetAccountingFirmClientPlanByTier';
import { useGetSearchParamsValues } from '../../../../hooks/useGetSearchParamsValues';
import { SubscriptionBillingPayor } from '../../../../types';
import { useNewFirmClientFormSchema, useNewFirmClientStepManager } from '../../hooks';
import { NewFirmClientFormFields, NewFirmClientFormSteps } from '../../types';
import { NewFirmClientFormFieldsFormContext } from './NewFirmClientFormFieldsFormContext';

type NewFirmClientFormProps = {
  onSubmit: (data: NewFirmClientFormFields) => Promise<void>;
  isSaving: boolean;
};

type AdditionalWatchPropsFromStorage = { activeStep: NewFirmClientFormSteps };
export const SESSION_STORAGE_FORM_DATA_KEY = 'new_firm_client';

type NewFirmClientFormAnalyticsCta = 'invite-client' | 'invite-client-uncheck' | 'confirm-and-add-client';
type NewFirmClientFormAnalyticsIntent = NewFirmClientFormAnalyticsCta | 'submit';

const useNewFirmClientFormAnalytics = ({
  activeStep,
  watch,
  setAnalyticsProperties,
  currentPlan,
}: {
  activeStep: NewFirmClientFormSteps;
  watch: UseMelioFormResults<NewFirmClientFormFields>['watch'];
  formState: UseMelioFormResults<NewFirmClientFormFields>['formState'];
  setAnalyticsProperties: WithAnalyticsContextProps['setAnalyticsProperties'];
  currentPlan?: Plan | undefined;
}) => {
  const currentPlanInfo = usePlanInfo(currentPlan?.id);
  const { track } = useAnalytics();
  const hasPaymentMethod = !!watch('fundingSourceId');
  setAnalyticsProperties({ IsPMExist: hasPaymentMethod, Intent: activeStep });

  useEffect(() => {
    let analyticsProps = {};

    if (activeStep === NewFirmClientFormSteps.BillingDetails) {
      analyticsProps = {
        Intent: 'add-client-billing-details',
      };
    } else if (activeStep === NewFirmClientFormSteps.PlanSelection) {
      analyticsProps = {
        PageName: 'plan-selection',
        Intent: 'choose-plan',
        Flow: 'subscription-accountant-create-new-client',
        PlanId: currentPlan?.id || 'post-onboarding',
        CurrentPlan: currentPlanInfo?.planName?.toLowerCase() || 'post-onboarding',
      };
    }

    track('Organization', 'View', { PageName: activeStep, Intent: 'add-client-basic-details', ...analyticsProps });
  }, [activeStep, currentPlan?.id, currentPlanInfo?.planName, track]);

  const trackClick = (action: NewFirmClientFormAnalyticsCta, intent: NewFirmClientFormAnalyticsIntent = action) =>
    track('Organization', 'Click', { Cta: action, Intent: intent });

  return { trackClick };
};

export const NewFirmClientForm = withAnalyticsContext<NewFirmClientFormProps>(
  ({ onSubmit, isSaving, setAnalyticsProperties }) => {
    const { formatMessage } = useMelioIntl();
    const { fundingSourceId: subscriptionFundingSourceId } = useSubscriptionFundingSource();
    const defaultAccountingFirmClientPlan = useGetAccountingFirmClientPlanByTier(Tier.MelioAccountingClientCore);

    const {
      resumedFormValues,
      additionalPropValues: { activeStep },
    } = useGetFormSessionStorage<NewFirmClientFormFields, AdditionalWatchPropsFromStorage>(
      SESSION_STORAGE_FORM_DATA_KEY
    );

    const { getParamsValues } = useGetSearchParamsValues(['fundingSourceId']);
    const { fundingSourceId: fundingSourceIdFromUrl } = getParamsValues();

    const form = useMelioForm<NewFirmClientFormFields>({
      isSaving,
      onSubmit,
      schema: useNewFirmClientFormSchema(),
      defaultValues: {
        businessDBA: '',
        businessName: '',
        clientEmailAddress: '',
        clientFirstName: '',
        clientLastName: '',
        shouldInviteClient: false,
        subscriptionPlanId: defaultAccountingFirmClientPlan?.id || '',
        ...resumedFormValues,
        whoPays: fundingSourceIdFromUrl
          ? SubscriptionBillingPayor.AccountingFirm
          : resumedFormValues.whoPays || SubscriptionBillingPayor.Client,
        fundingSourceId: fundingSourceIdFromUrl || resumedFormValues.fundingSourceId || subscriptionFundingSourceId,
      },
      subscribeToDefaultValuesChanges: true,
    });

    const {
      formProps,
      watch,
      formState,
      submitButtonProps: { onClick: onSubmitForm },
    } = form;

    const { steps, expandedStep, expandSelectedStep, goToNextStep, focusErrorStep } = useNewFirmClientStepManager({
      defaultActiveId: activeStep as NewFirmClientFormSteps,
      isFirmBilled: watch('whoPays') === SubscriptionBillingPayor.AccountingFirm,
    });

    const handleSubmit = () => {
      trackClick('confirm-and-add-client', 'submit');
      onSubmitForm();
    };

    const { trackClick } = useNewFirmClientFormAnalytics({
      activeStep: activeStep as NewFirmClientFormSteps,
      watch,
      formState,
      setAnalyticsProperties,
      currentPlan: defaultAccountingFirmClientPlan,
    });

    const handleContinue = () => {
      goToNextStep();
    };

    const onExpandChange = (expanded: boolean, expandedStepId: NewFirmClientFormSteps) => {
      if (expanded) {
        expandSelectedStep(expandedStepId);
      }
    };

    useSetFormSessionStorage<NewFirmClientFormFields, AdditionalWatchPropsFromStorage>(
      SESSION_STORAGE_FORM_DATA_KEY,
      watch,
      { activeStep: (expandedStep || NewFirmClientFormSteps.BasicDetails) as NewFirmClientFormSteps }
    );

    return (
      <NewFirmClientFormFieldsFormContext.Provider value={form}>
        <Form {...formProps}>
          {steps.map(
            ({ stepId, title, description, isExpanded, isDisabled, isFilled, component: StepFromComponent }) => {
              const isSubmitStep = steps[steps.length - 1]?.stepId === stepId;
              const submitLabel = isSubmitStep
                ? formatMessage(`activities.accountants.newClient.form.submitButton.label`)
                : formatMessage(`activities.accountants.newClient.form.continueButton.label`);

              return (
                <CollapsibleCardSection
                  key={stepId}
                  id={stepId}
                  title={title}
                  description={description}
                  isExpanded={isExpanded}
                  isDisabled={isDisabled}
                  isFilled={isFilled}
                  onExpandChange={(expanded) => onExpandChange(expanded, stepId)}
                >
                  <StepFromComponent
                    submitLabel={submitLabel}
                    focusErrorStep={(error: boolean) => focusErrorStep(stepId, error)}
                    onContinue={isSubmitStep ? handleSubmit : handleContinue}
                    isSaving={isSaving}
                  />
                </CollapsibleCardSection>
              );
            }
          )}
        </Form>
      </NewFirmClientFormFieldsFormContext.Provider>
    );
  }
);
