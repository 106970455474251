import { StatusModal, Text } from '@melio/penny';
import { BillProvider } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { useProviderDisplayProps } from '../hooks/useProviderDisplayProps';

type Props = {
  onConfirm: VoidFunction;
  onCancel: VoidFunction;
  provider: BillProvider;
};

export const DisconnectProviderModal = ({ onConfirm, onCancel, provider }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { displayName } = useProviderDisplayProps(provider);

  return (
    <StatusModal
      isOpen
      variant="alert"
      onClose={onCancel}
      header={formatMessage('activities.eBillsImport.settings.confirmDisconnectModal.title', { provider: displayName })}
      primaryButton={{ label: 'Yes, disconnect it', variant: 'critical', onClick: onConfirm }}
      secondaryButton={{ label: 'Cancel', variant: 'tertiary', onClick: onCancel }}
    >
      <Text>
        {formatMessage(
          `activities.eBillsImport.settings.providers.${provider.name}.confirmDisconnectModal.description`
        )}
      </Text>
    </StatusModal>
  );
};
