import { isBusinessTypeAllowedForFX } from '@melio/ap-domain';
import { useAccount } from '@melio/platform-api';

export const useGetAllowedFXBusinessType = () => {
  const { data: account } = useAccount({ id: 'me' });
  const { businessType } = account?.company || {};

  return {
    isAllowedBusinessType: isBusinessTypeAllowedForFX(businessType),
    businessType,
  };
};
