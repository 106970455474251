import { AddressSearchWidget } from '@melio/ap-widgets';
import { Button, createNumberMask, Form, UseMelioFormResults } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { FinancingApplicationOwnerDetailsForm } from '../types';
import { TaxIdField } from './commonFormFields/TaxIdField';

export type AdditionalOwnerFormFieldsProps = {
  form: UseMelioFormResults<FinancingApplicationOwnerDetailsForm>;
  index: number;
  onClickRemove: VoidFunction;
};
export const AdditionalOwnerFormFields = ({ form, index, onClickRemove }: AdditionalOwnerFormFieldsProps) => {
  const { formatMessage } = useMelioIntl();

  return (
    <>
      <Form.TextField
        {...form.registerField(`additionalOwners.${index}.firstName`)}
        labelProps={{
          label: formatMessage(
            'activities.financingApplication.applicationFormScreen.ownershipDetails.additionalOwner.firstName.label'
          ),
        }}
        colSpan={6}
      />
      <Form.TextField
        {...form.registerField(`additionalOwners.${index}.lastName`)}
        labelProps={{
          label: formatMessage(
            'activities.financingApplication.applicationFormScreen.ownershipDetails.additionalOwner.lastName.label'
          ),
        }}
        colSpan={6}
      />
      <Form.TextField
        {...form.registerField(`additionalOwners.${index}.email`)}
        labelProps={{
          label: formatMessage(
            'activities.financingApplication.applicationFormScreen.ownershipDetails.additionalOwner.email.label'
          ),
        }}
        colSpan={12}
      />
      <AddressSearchWidget
        {...form.registerField(`additionalOwners.${index}.address`)}
        error={
          form.formState.errors.address?.line1
            ? {
                message: formatMessage(
                  'activities.financingApplication.applicationFormScreen.ownershipDetails.address.validation.required'
                ),
              }
            : undefined
        }
        labelProps={{
          label: formatMessage('activities.financingApplication.applicationFormScreen.ownershipDetails.address.label'),
        }}
        colSpan={12}
      />
      <Form.TextField
        {...form.registerField(`additionalOwners.${index}.ownershipPercentage`)}
        placeholder={formatMessage(
          'activities.financingApplication.applicationFormScreen.ownershipDetails.additionalOwner.ownershipPercentage.placeholder'
        )}
        labelProps={{
          label: formatMessage(
            'activities.financingApplication.applicationFormScreen.ownershipDetails.additionalOwner.ownershipPercentage.label'
          ),
        }}
        maskProps={{
          mask: createNumberMask({
            suffix: '%',
            prefix: '',
            thousandsSeparatorSymbol: '',
          }),
        }}
        colSpan={12}
      />
      <Form.DateField
        {...form.registerField(`additionalOwners.${index}.dateOfBirth`)}
        placeholder={formatMessage(
          'activities.financingApplication.applicationFormScreen.ownershipDetails.additionalOwner.dateOfBirth.placeholder'
        )}
        labelProps={{
          label: formatMessage(
            'activities.financingApplication.applicationFormScreen.ownershipDetails.additionalOwner.dateOfBirth.label'
          ),
        }}
        colSpan={6}
        excludeHolidays={false}
        weekDays={[0, 1, 2, 3, 4, 5, 6]}
      />
      <TaxIdField
        {...form.registerField(`additionalOwners.${index}.ssn`)}
        placeholder={formatMessage(
          'activities.financingApplication.applicationFormScreen.ownershipDetails.additionalOwner.ssn.placeholder'
        )}
        labelProps={{
          label: formatMessage(
            'activities.financingApplication.applicationFormScreen.ownershipDetails.additionalOwner.ssn.label'
          ),
        }}
        colSpan={12}
      />
      <Form.ContentBox colSpan={12}>
        <Button
          isFullWidth
          data-testid={`remove-additional-owner-${index}`}
          label={formatMessage(
            'activities.financingApplication.applicationFormScreen.ownershipDetails.additionalOwner.remove.cta'
          )}
          onClick={onClickRemove}
          size="medium"
          variant="tertiary"
        />
      </Form.ContentBox>
    </>
  );
};
