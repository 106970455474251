import React from 'react';
import { Box, useBreakpointValue } from '@chakra-ui/react';
import { Button } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { useAppTheme } from '@/cl/theme/theme.hooks';
import { useIsMobile } from '@/hooks/useIsMobile';
import { ConfirmationBarTheme } from './ConfirmationBar.component.theme';

export type ConfirmationBarButtonProps = {
  text?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick: VoidFunction;
};

type ConfirmationBarProps = {
  submitButtonProps: ConfirmationBarButtonProps;
  cancelButtonProps: ConfirmationBarButtonProps;
};

export const ConfirmationBar = forwardRef<ConfirmationBarProps, 'div'>(
  ({ submitButtonProps, cancelButtonProps }, ref) => {
    const style = useAppTheme(ConfirmationBarTheme, {});
    const isMobile = useIsMobile();
    const buttonSize = useBreakpointValue({ xs: 'large', s: 'medium' } as never, { ssr: false } as never);

    const { text: submitText, ...restSubmitProps } = submitButtonProps;
    const { text: cancelText, ...restCancelProps } = cancelButtonProps;

    return (
      <Box data-testid="confirmation-bar-container" __css={style['container']} ref={ref}>
        <Button
          data-testid="cancel-button"
          size={buttonSize}
          variant="tertiary"
          isFullWidth={isMobile}
          {...restCancelProps}
          label={cancelText}
        />
        <Button
          data-testid="submit-button"
          size={buttonSize}
          variant="primary"
          isFullWidth={isMobile}
          {...restSubmitProps}
          label={submitText}
        />
      </Box>
    );
  },
);

ConfirmationBar.displayName = 'ConfirmationBar';
