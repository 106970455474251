import { CountryCode, Currency, FieldName, getStateOrProvinceInfo } from '@melio/international-payments-utils';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import { object, SchemaOf, string, StringSchema } from 'yup';

import { useFxCommonValidations } from '../add-fx-business-details/utils/formUtils';
import {
  getRequireFieldByKey as getRequireFieldByKey,
  getRequireFieldsByCurrency,
} from './fxBankDetailsForm/fxBankDetailsForm.utils';
import { FxVendorAccountFieldsFormFields } from './fxBankDetailsForm/types';

const ONLY_NUMBERS_PATTERN = /^(?![0-9]+$).*$/;

const useValidationRulesForField = (requireFieldsKey: FieldName) => {
  const { formatMessage } = useMelioIntl();
  const addFieldValidationSchema = (foreignCurrency: Currency, schema: StringSchema<string | undefined>) => {
    if (!foreignCurrency) {
      return schema;
    }
    const requiredFields = getRequireFieldsByCurrency(foreignCurrency);
    const field = getRequireFieldByKey(requiredFields, requireFieldsKey);
    field &&
      (schema = schema.required(
        formatMessage(
          `activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.${requireFieldsKey}.required` as MessageKey
        )
      ));

    field?.maxLength &&
      (schema = schema.max(
        field.maxLength,
        formatMessage(
          `activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.${requireFieldsKey}.length` as MessageKey,
          { length: field.maxLength }
        )
      ));

    field?.minLength &&
      (schema = schema.min(
        field.minLength,
        formatMessage(
          `activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.${requireFieldsKey}.length` as MessageKey,
          { length: field.minLength }
        )
      ));
    if (field?.minLength && field?.maxLength) {
      if (field?.minLength !== field?.maxLength) {
        schema = schema.min(
          field.minLength,
          formatMessage(
            `activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.${requireFieldsKey}.length.range` as MessageKey,
            { min: field.minLength, max: field.maxLength }
          )
        );
      }
    }
    field?.validationRegexp &&
      (schema = schema.matches(
        field.validationRegexp,
        formatMessage(
          `activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.${requireFieldsKey}.notValid` as MessageKey
        )
      ));

    return schema;
  };
  return { addFieldValidationSchema };
};

const useValidationRulesForStateAndProvince = () => {
  const { formatMessage } = useMelioIntl();
  const addStateValidationSchema = (countryCode: string, schema: StringSchema<string | undefined>) => {
    if (!countryCode) {
      return schema;
    }
    const stateOrProvinceInfo = getStateOrProvinceInfo(countryCode as CountryCode);
    if (stateOrProvinceInfo.required) {
      const messageKey =
        stateOrProvinceInfo.type === 'state'
          ? 'activities.fxDeliveryMethodActivity.screens.VendorLegalDetailsForm.state.required'
          : 'activities.fxDeliveryMethodActivity.screens.VendorLegalDetailsForm.province.required';

      return schema.required(formatMessage(messageKey));
    }
    return schema;
  };
  return { addStateValidationSchema };
};

export const useVendorAccountFormSchema = () => {
  const { formatMessage } = useMelioIntl();
  const { addStateValidationSchema } = useValidationRulesForStateAndProvince();
  const { countryCodeSchema } = useFxCommonValidations();
  return object().shape({
    foreignCurrency: string()
      .nullable()
      .required(
        formatMessage('activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.foreignCurrency.required')
      ),
    fxCountrySelection: string()
      .nullable()
      .required(
        formatMessage('activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.fxCountrySelection.required')
      ),

    //Require Fields
    accountHolderName: string().when(
      'foreignCurrency',
      useValidationRulesForField('accountHolderName').addFieldValidationSchema
    ),
    institutionNumber: string().when(
      'foreignCurrency',
      useValidationRulesForField('institutionNumber').addFieldValidationSchema
    ),
    transitNumber: string().when(
      'foreignCurrency',
      useValidationRulesForField('transitNumber').addFieldValidationSchema
    ),
    accountNumber: string().when(
      'foreignCurrency',
      useValidationRulesForField('accountNumber').addFieldValidationSchema
    ),
    accountType: string().when('foreignCurrency', useValidationRulesForField('accountType').addFieldValidationSchema),
    bsbCode: string().when('foreignCurrency', useValidationRulesForField('bsbCode').addFieldValidationSchema),
    iban: string().when('foreignCurrency', useValidationRulesForField('iban').addFieldValidationSchema),
    ifscCode: string().when('foreignCurrency', useValidationRulesForField('ifscCode').addFieldValidationSchema),
    bankCode: string()
      .when('foreignCurrency', useValidationRulesForField('bankCode').addFieldValidationSchema)
      .typeError(formatMessage(`activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.bankCode.notValid`)),
    branchCode: string().when('foreignCurrency', useValidationRulesForField('branchCode').addFieldValidationSchema),
    email: string().when('foreignCurrency', useValidationRulesForField('email').addFieldValidationSchema),
    clabe: string().when('foreignCurrency', useValidationRulesForField('clabe').addFieldValidationSchema),
    sortCode: string().when('foreignCurrency', useValidationRulesForField('sortCode').addFieldValidationSchema),
    bic: string().when('foreignCurrency', useValidationRulesForField('bic').addFieldValidationSchema),
    cnpj: string().when('foreignCurrency', useValidationRulesForField('cnpj').addFieldValidationSchema),

    legalBusinessName: string()
      .required(
        formatMessage(`activities.fxDeliveryMethodActivity.screens.VendorLegalDetailsForm.legalBusinessName.required`)
      )
      .matches(
        ONLY_NUMBERS_PATTERN,
        formatMessage(
          'activities.fxDeliveryMethodActivity.screens.VendorLegalDetailsForm.legalBusinessName.numbersOnly'
        )
      ),
    line1: string()
      .required(formatMessage(`activities.fxDeliveryMethodActivity.screens.VendorLegalDetailsForm.line1.required`))
      .nullable(),
    line2: string(),
    city: string().required(
      formatMessage(`activities.fxDeliveryMethodActivity.screens.VendorLegalDetailsForm.city.required`)
    ),
    country: countryCodeSchema,
    state: string().when('country', addStateValidationSchema).nullable(),

    countryCode: string(),
    postalCode: string().required(
      formatMessage(`activities.fxDeliveryMethodActivity.screens.VendorLegalDetailsForm.postalCode.required`)
    ),
  }) as unknown as SchemaOf<FxVendorAccountFieldsFormFields>;
};
