import noop from 'lodash/noop';
import React from 'react';

import { DisabledPayWithFinancingCard } from './DisabledPayWithFinancingCard';
import { PayWithFinancingCardComponent } from './PayWithFinancingCardContent';
import { PayWithFinancingCardProps } from './types';

export const PayWithFinancingCard: React.VFC<PayWithFinancingCardProps> = ({
  onClick = noop,
  isDisabled = false,
  isSelected = false,
  availableCredit,
  disabledReason,
  testId,
  learnMore,
}) => {
  if (isDisabled && disabledReason) {
    return (
      <DisabledPayWithFinancingCard
        disabledReason={disabledReason}
        isSelected={isSelected}
        availableCredit={availableCredit}
        learnMore={learnMore}
        testId={testId}
        onClick={onClick}
      />
    );
  }

  return (
    <PayWithFinancingCardComponent
      onClick={onClick}
      isSelected={isSelected}
      isDisabled={isDisabled}
      availableCredit={availableCredit}
    />
  );
};

PayWithFinancingCard.displayName = 'PayWithFinancingCard';
