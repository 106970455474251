import {
  Button,
  Container,
  Form,
  Group,
  Icon,
  Link,
  RadioOption,
  SectionBanner,
  Text,
  useBreakpoint,
} from '@melio/penny';
import { TaxpayerInfo } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { useSystemMessage } from '@melio/platform-utils';
import noop from 'lodash/noop';
import { useEffect, useState } from 'react';

import { TaxPayerInfoSectionTitle } from './components/TaxPayerInfoSectionTitle';
import { TaxPayerFileUpload } from './TaxPayerFileUpload';
import { W9SubmissionMethod } from './types';
import { useTaxPayerInfoForm } from './useTaxPayerInfoForm';

type FileType = File | null | TaxpayerInfo['file'];

export type TaxPayerInfoFormProps = {
  handleSubmitRequest?: () => void;
  handleFileUpload: (file: File) => Promise<TaxpayerInfo['file']> | void;
  handleFileDelete?: () => void;
  onDeleteInvalidFile?: () => void;
  taxPayerInfo?: TaxpayerInfo;
  isLoading?: boolean;
  taxPayerInfoFormProps: ReturnType<typeof useTaxPayerInfoForm>;
  radioGroupVariant?: 'vertical' | 'horizontal';
  isBannerCompact?: boolean;
  onSuccessUploadW9File?: () => void;
  onSelectAndUploadFile?: () => void;
};

export const TaxPayerInfoForm = ({
  handleSubmitRequest,
  handleFileUpload,
  handleFileDelete = noop,
  onDeleteInvalidFile,
  taxPayerInfo,
  isLoading,
  taxPayerInfoFormProps,
  radioGroupVariant,
  isBannerCompact,
  onSuccessUploadW9File,
  onSelectAndUploadFile,
}: TaxPayerInfoFormProps) => {
  const { isExtraSmallScreen } = useBreakpoint();
  const [isInvalidFile, setIsInvalidFile] = useState(false);
  const [file, setFile] = useState<FileType>(null);
  const { formatMessage } = useMelioIntl();
  const { showMessage } = useSystemMessage();
  const {
    settings: { tax1099LearnMoreLink },
  } = useConfig();

  const { watch, formProps, registerField } = taxPayerInfoFormProps;

  const selectedW9SubmissionMethod = watch('W9SubmissionMethod');

  const options: RadioOption[] = [
    {
      mainLabelProps: {
        label: formatMessage('widgets.taxPayerInfo.form.radioGroup.email'),
        pillProps: {
          label: formatMessage('widgets.taxPayerInfo.form.radioGroup.email.pill.label'),
          status: 'brand',
          type: 'secondary',
        },
      },
      value: W9SubmissionMethod.Email,
    },
    {
      mainLabelProps: {
        label: formatMessage('widgets.taxPayerInfo.form.radioGroup.pdf'),
      },
      value: W9SubmissionMethod.Pdf,
    },
  ];

  const handleSuccessUploadW9File = (file: FileType) => {
    setFile(file);
    showMessage({
      type: 'success',
      title: formatMessage('widgets.taxPayerInfo.uploadW9.toast.success'),
      dataTestId: 'vendor-tax-details-upload-w9-success',
    });

    onSuccessUploadW9File?.();
  };

  const onFailUploadW9File = () =>
    showMessage({
      type: 'error',
      title: formatMessage('widgets.taxPayerInfo.uploadW9.toast.fail'),
      dataTestId: 'vendor-tax-details-upload-w9-fail',
    });

  const handleSelectAndUploadFile = (newFile: File | null) => {
    onSelectAndUploadFile?.();
    if (!newFile) {
      return;
    }

    // 4mb
    if (newFile.size > 4 * 1024 * 1024) {
      setFile(newFile);
      return setIsInvalidFile(true);
    }

    setIsInvalidFile(false);
    void uploadW9File(newFile);
  };

  const uploadW9File = async (file: File) => {
    try {
      const uploadedFile = await handleFileUpload(file);
      if (uploadedFile) {
        handleSuccessUploadW9File(uploadedFile);
      } else {
        setFile(file);
      }
    } catch {
      onFailUploadW9File();
    }
  };

  useEffect(() => {
    if (taxPayerInfo?.file) {
      setFile({ ...taxPayerInfo?.file, name: taxPayerInfo?.file?.fileName ?? undefined });
    } else {
      setFile(null);
    }
  }, [taxPayerInfo]);

  const handleDeleteInvalidFile = () => {
    setFile(null);
    setIsInvalidFile(false);
    onDeleteInvalidFile?.();
  };

  const renderContent = () => {
    if (taxPayerInfo?.file) {
      return (
        <TaxPayerFileUpload
          onDelete={handleFileDelete}
          onSelectFile={handleSelectAndUploadFile}
          value={file}
          isInvalidFile={isInvalidFile}
          isLoading={isLoading}
        />
      );
    }

    return (
      <Form gap="l" data-testid="taxpayer-info-form" {...formProps}>
        <Form.RadioGroup
          {...registerField('W9SubmissionMethod')}
          options={options}
          variant={radioGroupVariant}
          aria-label="Taxpayer submission form type"
          isDisabled={isLoading}
        />
        {selectedW9SubmissionMethod === W9SubmissionMethod.Pdf && (
          <TaxPayerFileUpload
            onDelete={handleDeleteInvalidFile}
            onSelectFile={handleSelectAndUploadFile}
            value={file}
            isInvalidFile={isInvalidFile}
            isLoading={isLoading}
          />
        )}
        {selectedW9SubmissionMethod === W9SubmissionMethod.Email && (
          <Group variant={isExtraSmallScreen ? 'vertical' : 'horizontal'} justifyContent="space-between" spacing="s">
            <SectionBanner
              isCompact={isBannerCompact}
              description={
                <Text textStyle={isBannerCompact && isExtraSmallScreen ? 'body4' : 'body3'}>
                  {formatMessage('widgets.taxPayerInfo.info.label', {
                    learnMoreLink: (
                      <Link
                        variant="inline"
                        color="inherit"
                        href={tax1099LearnMoreLink}
                        label={formatMessage('widgets.taxPayerInfo.info.link')}
                        newTab
                      />
                    ),
                  })}
                </Text>
              }
            />
            {handleSubmitRequest && (
              <Button
                variant="tertiary"
                leftElement={<Icon size="small" type="send" color="inherit" aria-hidden />}
                isLoading={isLoading}
                label={formatMessage('widgets.taxPayerInfo.form.submit.request')}
                data-testid="taxpayer-request-btn"
                onClick={handleSubmitRequest}
              />
            )}
          </Group>
        )}
      </Form>
    );
  };

  return (
    <Container overflow="initial">
      <Group
        data-testid="taxpayer-form"
        spacing="m"
        hasDivider={!isExtraSmallScreen}
        variant={isExtraSmallScreen ? 'vertical' : 'horizontal'}
      >
        <Group width="full" variant="vertical" spacing="s">
          <TaxPayerInfoSectionTitle />
          {renderContent()}
        </Group>
      </Group>
    </Container>
  );
};

TaxPayerInfoForm.displayName = 'TaxPayerInfoForm';
