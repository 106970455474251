import { Divider, Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { FeeItemProps } from '@melio/platform-utils';

import { FeeItem } from './FeeItem';

export type FeesDetailsProps = {
  total: {
    amount: string;
    description: string;
  };
  feeItems: FeeItemProps[];
};

export function FeesDetails({ feeItems, total }: FeesDetailsProps) {
  const { formatMessage } = useMelioIntl();

  return (
    <Group variant="vertical" spacing="m">
      {feeItems.length ? (
        <>
          <Group variant="vertical" spacing="s">
            {feeItems.map((fee) => (
              <FeeItem key={fee.type} {...fee} />
            ))}
          </Group>
          <Divider />
        </>
      ) : null}
      <Group justifyContent="space-between">
        <Group variant="vertical" spacing="none">
          <Text as="p" textStyle="body1">
            {formatMessage('widgets.reviewAndConfirm.feesSection.totalFeesLabel')}
          </Text>
          <Text
            as="p"
            data-testid="review-and-confirm-total-fee-description"
            textStyle="body4"
            color="global.neutral.900"
          >
            {total.description}
          </Text>
        </Group>
        <Text data-testid="review-and-confirm-total-fee-amount" textStyle="body1">
          {total.amount}
        </Text>
      </Group>
    </Group>
  );
}
