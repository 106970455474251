import { Card, Container, Loader } from '@melio/penny';
import { FinancingEligibilityStatus, RepaymentTerms } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import noop from 'lodash/noop';
import React from 'react';

import { FinancingApplicationFlowCard } from './FinancingApplicationFlowCard/FinancingApplicationFlowCard.widget';
import {
  FALLBACK_REASON_TEXT as FINANCING_GENERAL_ERROR_TEXT,
  getFinancingCardTestId,
  getIneligibilityText,
  useIneligibilityLearnMore,
} from './FinancingCard.utils';
import { DisabledPayWithFinancingCard } from './PayWithFinancingCard/DisabledPayWithFinancingCard';
import { PayWithFinancingCard } from './PayWithFinancingCard/PayWithFinancingCard.widget';

export type FinancingCardProps = {
  onClick?: VoidFunction;
  isSelected?: boolean;
  repaymentTerms?: RepaymentTerms;
  shouldAllowFinancingOption?: boolean;
  isLoading?: boolean;
  isError?: boolean;
};

export const FinancingCard: React.VFC<FinancingCardProps> = ({
  onClick = noop,
  shouldAllowFinancingOption,
  isSelected,
  repaymentTerms,
  isLoading,
  isError,
}) => {
  const { formatMessage } = useMelioIntl();
  const [isPlatformFinancingApplicationFlowEnabled] = useFeature<boolean>(
    FeatureFlags.PlatformFinancingApplicationFlow,
    false
  );

  const isDeclined = repaymentTerms?.status === FinancingEligibilityStatus.Declined;
  const isIneligibleAndDidNotApply =
    repaymentTerms?.status === FinancingEligibilityStatus.Ineligible && !repaymentTerms.appliedToProvider;
  const learnMore = useIneligibilityLearnMore({ reason: repaymentTerms?.reason ?? null });

  if (isError) {
    return <DisabledPayWithFinancingCard disabledReason={formatMessage(FINANCING_GENERAL_ERROR_TEXT)} />;
  }

  if (!shouldAllowFinancingOption || isDeclined || isIneligibleAndDidNotApply) {
    return null;
  }

  if (isLoading || !repaymentTerms) {
    return (
      <Card data-testid="financing-card-loader">
        <Container paddingTop="s" paddingBottom="s">
          <Loader />
        </Container>
      </Card>
    );
  }

  const { status, reason, appliedToProvider } = repaymentTerms;
  const isEligibile = status === FinancingEligibilityStatus.Eligible;
  const isDisabled = !isEligibile;
  const testId = getFinancingCardTestId({ status, reason });
  const disabledReason = isDisabled ? getIneligibilityText({ reason, status }) : undefined;

  if (appliedToProvider) {
    return (
      <PayWithFinancingCard
        onClick={onClick}
        testId={testId}
        isSelected={isSelected}
        isDisabled={isDisabled}
        disabledReason={disabledReason && formatMessage(disabledReason as MessageKey)}
        learnMore={learnMore}
        availableCredit={repaymentTerms.balance}
      />
    );
  }

  if (isPlatformFinancingApplicationFlowEnabled && !appliedToProvider) {
    return <FinancingApplicationFlowCard onClick={onClick} isSelected={isSelected} isDisabled={isDisabled} />;
  }

  return null;
};

FinancingCard.displayName = 'FinancingCard';
