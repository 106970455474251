/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { createPdfFile } from '@melio/platform-utils';

import { downloadAccountReceiptByPaymentId, downloadVendorReceiptByPaymentId } from '@/api/payments/payments.api';
import { WithLoading } from '@/hoc/withLoading.hoc';
import { useAuthTokenFromActionToken } from '@/hooks/auth.hooks';
import { usePartnerConfig } from '@/hooks/partners';
import { useRouter } from '@/hooks/router.hooks';
import { useResetAccessToken } from '@/hooks/session.hooks';
import {
  useVendorActionAccountEmail,
  useVendorActionAccountName,
  useVendorActionPaymentId,
  useVendorActionUnilateralRequestId,
  useVendorActionVendorId,
} from '@/store/VendorActions/VendorAction.model';

export enum VendorActionEnum {
  AcceptPayment = 'acceptPayment',
  DownloadReceipt = 'vendorDownloadReceipt',
  UpgradePayment = 'upgradePayment',
  AcceptPaymentWithMsnEnrollment = 'acceptPaymentWithMsnEnrollment',
}

export enum AccountActionEnum {
  DownloadReceipt = 'downloadReceipt',
}

interface AcceptPaymentActionType {
  action: VendorActionEnum.AcceptPayment | VendorActionEnum.AcceptPaymentWithMsnEnrollment;
  paymentId?: string;
  vendorId: string;
  unilateralRequestId?: string;
  newUnilateralVariant: boolean;
}

interface VendorDownloadReceiptActionType {
  action: VendorActionEnum.DownloadReceipt;
  paymentId: string;
  isMtl: boolean;
}
interface DownloadReceiptActionType {
  action: AccountActionEnum.DownloadReceipt;
  paymentId: string;
  isMtl: boolean;
}

interface UpgradePaymentActionType {
  action: VendorActionEnum.UpgradePayment;
  paymentId: string;
  vendorId: string;
}

type ActionDataType =
  | DownloadReceiptActionType
  | AcceptPaymentActionType
  | UpgradePaymentActionType
  | VendorDownloadReceiptActionType;

const AuthActionRouteComponent = ({ token }: { token: string }) => {
  const { goToUnilateral, goToUpgradePayment, goToNewUnilateral } = useRouter();

  const {
    partnerConfig: { partnerProductName },
  } = usePartnerConfig();

  const { isLoading, isError, data } = useAuthTokenFromActionToken(token);
  const setPaymentId = useVendorActionPaymentId();
  const setVendorId = useVendorActionVendorId();
  const setAccountName = useVendorActionAccountName();
  const setAccountEmail = useVendorActionAccountEmail();
  const setUnilateralRequestId = useVendorActionUnilateralRequestId();
  const resetAccessToken = useResetAccessToken();

  const getAccountNameFromToken = (token: string) => {
    return jwtDecode<{
      account: {
        accountName: string;
      };
      claims: {
        paymentId: string;
        vendorId: string;
      };
      user: { email: string };
      accountId: string;
      organization: { companyName: string };
    }>(token);
  };

  /**
  Set accountName & paymentId + route
   */
  const setVendorDataAndRoute = ({
    token,
    vendorId,
    paymentId,
    unilateralRequestId,
    route,
  }: {
    token: string;
    paymentId?: string;
    vendorId?: string;
    unilateralRequestId?: string;
    route: () => void;
  }) => {
    const { account, user } = getAccountNameFromToken(token);
    setAccountName(account.accountName);
    setAccountEmail(user.email);
    if (vendorId) setVendorId(vendorId);
    if (paymentId) setPaymentId(paymentId);
    if (unilateralRequestId) setUnilateralRequestId(unilateralRequestId);
    route();
  };

  // Invoked when data converted from action token is changes
  useEffect(() => {
    if (data) {
      resetAccessToken(data.accessToken, data.refreshToken!);
      const { actionData } = jwtDecode<{
        actionData: ActionDataType;
        accountId: string;
      }>(token);

      switch (actionData.action) {
        case VendorActionEnum.AcceptPaymentWithMsnEnrollment: {
          if (actionData.paymentId) {
            setVendorDataAndRoute({
              token: data.accessToken,
              paymentId: actionData.paymentId,
              vendorId: actionData.vendorId,
              unilateralRequestId: actionData.unilateralRequestId,
              route: () => goToNewUnilateral(actionData.paymentId as string),
            });
          }
          break;
        }
        case VendorActionEnum.AcceptPayment: {
          setVendorDataAndRoute({
            token: data.accessToken,
            paymentId: actionData.paymentId,
            vendorId: actionData.vendorId,
            unilateralRequestId: actionData.unilateralRequestId,
            route: () => goToUnilateral(),
          });
          break;
        }
        case VendorActionEnum.UpgradePayment: {
          setVendorDataAndRoute({
            token: data.accessToken,
            paymentId: actionData.paymentId,
            vendorId: actionData.vendorId,
            route: goToUpgradePayment,
          });
          break;
        }
        case AccountActionEnum.DownloadReceipt: {
          downloadAccountReceiptByPaymentId(actionData.paymentId, actionData.isMtl).then((pdfData) => {
            createPdfFile(pdfData, `${partnerProductName}-Receipt-${actionData.paymentId}`);
          });
          break;
        }
        case VendorActionEnum.DownloadReceipt: {
          downloadVendorReceiptByPaymentId(actionData.paymentId, actionData.isMtl).then((pdfData) => {
            createPdfFile(pdfData, `${partnerProductName}-Receipt-${actionData.paymentId}`);
          });
          break;
        }
        default:
      }
    }
  }, [data]);

  if (isError) {
    return <div>Error</div>;
  }
  return <WithLoading isLoading={isLoading} />;
};

export const AuthActionRoute = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');

  if (!token) {
    return <div>No token provided</div>;
  }

  return <AuthActionRouteComponent token={token} />;
};
