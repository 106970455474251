/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useAnalytics } from '@melio/platform-analytics';
import { useAccount } from '@melio/platform-api';

import { supportApi } from '@/api/apiClients';
import { usePartnerConfig } from '@/hooks/partners';

type zendeskUserData = {
  email: string;
  firstName: string;
  lastName: string;
  orgId?: string;
  id: string;
  registrationFlow?: string;
};

const isZendeskWidget = (_instance: unknown): _instance is ZendeskWidget => {
  return !!window?.zE;
};

const onForethoughtWidgetClosed = (e: MessageEvent<{ event: string }>) => {
  if (e.data.event === 'forethoughtWidgetClosed') {
    window?.Forethought?.('widget', 'hide');
  }
};

const setZendeskData = (user: zendeskUserData, onError?: () => void) => {
  try {
    // sending user data to support
    const zendesk = window.zE!;
    const { email, firstName, lastName, orgId, id, registrationFlow } = user;

    // We can set the prefill fields only on the old widget api
    if (!useNewWidgetApi) {
      zendesk('webWidget', 'prefill', {
        name: { value: `${firstName} ${lastName}` },
        email: { value: email },
      });
    }

    const tags = [orgId ? `orgId-${orgId}` : '', `accountId-${id}`];
    if (registrationFlow) {
      tags.push(registrationFlow);
    }
    // custom tags
    if (useNewWidgetApi) {
      zendesk('messenger:set', 'conversationTags', tags);
    } else {
      zendesk('webWidget', 'chat:addTags', tags);
    }
  } catch (error) {
    // disable chat if cannot send user data
    onError?.();
  }
};

const showZendeskChat = () => {
  if (!window?.zE) {
    return;
  }

  // If we're using the new zendesk widget api, no need to handle forethought
  if (useNewWidgetApi) {
    window.zE('messenger', 'open');
    return;
  }

  const forethought = window.Forethought;

  if (!forethought) {
    return;
  }
  //livechat.isChatting
  const isChatting = window.zE('webWidget:get', 'chat:isChatting') as boolean;
  if (isChatting) {
    // show zendesk chat if there is an active session
    // livechat.window.show();
    window.zE('webWidget', 'open');
  } else {
    // showing forethought at start - they will open zendesk in their flow
    // we first open it and then show to avoid floating button in the screen
    forethought('widget', 'open');
    forethought('widget', 'show');
  }
};

const hideZendesk = () => {
  const zendesk = window?.zE;
  const forethought = window?.Forethought;

  // If we're using the new widget api, don't use the legacy widget api
  if (useNewWidgetApi && zendesk) {
    zendesk('messenger', 'hide');
    return;
  }

  if (forethought) {
    forethought('widget', 'hide');
  }

  if (zendesk) {
    zendesk('webWidget', 'hide');
  }
};

const injectForethought = (user: zendeskUserData, forethoughtApiKey: string, workflow: string) => {
  const { email, firstName, lastName } = user;
  const forethoughtBotScriptId = 'forethought-bot-script';
  // script already loaded (just for safety)
  if (document.getElementById(forethoughtBotScriptId)) return;

  const script = document.createElement('script');
  script.id = forethoughtBotScriptId;
  script.src = 'https://solve-widget.forethought.ai/embed.js';
  script.type = 'application/javascript';
  script.setAttribute('data-api-key', forethoughtApiKey);
  script.setAttribute('data-ft-current_page_url', 'window.location.href');
  script.setAttribute('data-ft-verbose', 'false');
  script.setAttribute('data-ft-name', `${firstName} ${lastName}`);
  script.setAttribute('data-ft-email', email);
  script.setAttribute('data-ft-workflow', workflow);
  script.setAttribute('data-ft-tag', '');
  script.setAttribute('data-ft-tag_2', '');
  script.setAttribute('data-ft-dummy', '');

  // hide forethought UI
  script.onload = () => window?.Forethought?.('widget', 'hide');

  // hiding forethought button after closing its window
  window.addEventListener('message', onForethoughtWidgetClosed);

  document.body.appendChild(script);
};

//we need to save it here because we can use this hook multiple time but it's will load only one time
let zendeskEnabled = false;

// On our new widgets we'll use the new widget api
let useNewWidgetApi = false;

export const useZendesk = () => {
  const zendeskRef = React.useRef({
    attemptsToInit: 8,
  });
  const { data: account } = useAccount({ id: 'me' });
  const { track } = useAnalytics();
  const { partnerConfig } = usePartnerConfig();
  useNewWidgetApi = partnerConfig.config.zendesk.useNewWidgetApi as boolean;

  const setData = React.useCallback((user: zendeskUserData) => {
    const onError = () => {
      zendeskEnabled = false;
    };
    setZendeskData(user, onError);
  }, []);

  const logUserIntoZendesk = React.useCallback(() => {
    const zendesk = window.zE;
    if (!isZendeskWidget(zendesk)) {
      return;
    }

    zendesk('messenger', 'loginUser', async (callback: (token: string) => void) => {
      try {
        const {
          data: { token },
        } = await supportApi.issueZendeskToken();
        callback(token);
      } catch (e: unknown) {
        track('Support', 'zendeskGetTokenError', { error: (e as Error).message.toString() });
      }
    });
  }, []);

  const initZendesk = React.useCallback(
    ({ user }: { user: zendeskUserData }) => {
      const zendesk = window.zE;

      if (!zendesk && zendeskRef.current.attemptsToInit > 0) {
        // waiting for zendesk object
        zendeskRef.current.attemptsToInit -= 1;

        setTimeout(() => initZendesk({ user }), 500);

        return;
      }
      if (!zendesk) {
        return;
      }
      if (useNewWidgetApi) {
        zendesk('messenger:on', 'open', () => logUserIntoZendesk());
      }
      zendeskEnabled = true;
      // save user data for chat session
      setData(user);

      // inject forethought script to extend chat
      if (!useNewWidgetApi) {
        injectForethought(
          user,
          partnerConfig.config.services.forethoughtApiKey,
          partnerConfig.config.services.zendeskWorkflow,
        );
      }
    },
    [partnerConfig, setData, useNewWidgetApi],
  );

  React.useEffect(() => {
    if (!partnerConfig.config.services.zendeskKey || !account) {
      return;
    }
    const { user: accountUser, id } = account;
    const user: zendeskUserData = {
      email: accountUser.email!,
      firstName: accountUser.firstName,
      id,
      lastName: accountUser.lastName,
    };
    const zendeskScriptId = 'ze-snippet';
    const scriptElement = document.getElementById(zendeskScriptId);
    if (scriptElement) {
      return;
    }
    const script = document.createElement('script');
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${partnerConfig.config.services.zendeskKey}`;
    script.id = zendeskScriptId;

    // handle zendesk init
    script.onload = () => initZendesk({ user });

    document.head.insertAdjacentHTML('beforeend', `<style>iframe#launcher { display: none; }</style>`);

    const { headerColor, resultListsColor, themeColor, title, logo } = partnerConfig.config.zendesk;
    // remove full screen button from chat
    window.zESettings = {
      webWidget: {
        navigation: {
          popoutButton: {
            enabled: false,
          },
        },
        chat: {
          concierge: {
            avatarPath: logo,
          },
          title: {
            '*': title,
          },
        },
        color: {
          theme: themeColor,
          resultLists: resultListsColor,
          header: headerColor,
        },
      },
    };

    document.body.appendChild(script);
    //hide the zendesk launcher button
    document.head.insertAdjacentHTML('beforeend', `<style>iframe#launcher { display: none; }</style>`);
    return () => {
      window.removeEventListener('message', onForethoughtWidgetClosed);
    };
  }, [partnerConfig.config, account]);

  const showZendesk = React.useCallback(() => {
    if (!zendeskEnabled) {
      return;
    }
    track('Support', 'openChatSupport', { userId: account?.id, email: account?.user?.email! });
    showZendeskChat();
  }, [track, account]);

  //call it when user logout
  const logoutChat = React.useCallback(() => {
    //reset data
    setData({
      email: '',
      firstName: '',
      id: '',
      lastName: '',
      orgId: '',
      registrationFlow: '',
    });

    hideZendesk();
  }, [hideZendesk, setData]);

  return {
    showZendesk,
    hideZendesk,
    logoutChat,
  };
};
