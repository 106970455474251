import { Payment, usePayments, UsePaymentsProps, useUserPreference, useUserPreferences } from '@melio/platform-api';
import { maxBy, noop } from 'lodash';
import { useMemo } from 'react';

type UseGetNewPaymentsParams = {
  preferenceKey: string;
  filterPaymentsFn?: (payment: Payment) => boolean;
  sortPaymentsBy: (payment: Payment) => Date;
  paymentsParams?: UsePaymentsProps['params'];
};

export const useGetNewPayments = (params: UseGetNewPaymentsParams) => {
  const { preferenceKey, filterPaymentsFn, sortPaymentsBy, paymentsParams } = params;

  const { create } = useUserPreferences({ enabled: false });

  const { data: allPayments = [], isLoading: isLoadingPayments } = usePayments({
    refetchOnMount: 'always',
    params: paymentsParams,
  });

  const { data: tabLastSeen, isLoading: isLoadingTabLastSeen } = useUserPreference({
    id: preferenceKey,
  });

  const isLoading = isLoadingPayments || isLoadingTabLastSeen;

  const newPayments = useMemo(() => {
    const payments = filterPaymentsFn ? allPayments.filter(filterPaymentsFn) : allPayments;

    const newPayments = !tabLastSeen
      ? payments
      : payments.filter((payment) => (tabLastSeen.value as unknown as Date) < sortPaymentsBy(payment));
    const newestPayment = maxBy(newPayments, sortPaymentsBy);

    const markAsSeen = () => {
      if (newestPayment) {
        create({
          userPreference: { key: preferenceKey, value: newestPayment.history.updatedAt.toISOString() },
        });
      }
    };

    return {
      newPayments,
      markAsSeen,
    };
  }, [tabLastSeen, allPayments, filterPaymentsFn, sortPaymentsBy, create, preferenceKey]);

  if (isLoading) {
    return {
      newPayments: [],
      markAsSeen: noop,
    };
  }

  return newPayments;
};
