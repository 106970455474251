/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useLocation } from 'react-router-dom';
import { isEqual } from 'lodash';
import { useRecoilValue } from 'recoil';
import { useFormSubmissionController } from '@melio/penny';
import { Action, EventProperties, useAnalytics } from '@melio/platform-analytics';

import {
  confirmationBarCancelButtonPropsSelector,
  confirmationBarIsOpenSelector,
  confirmationBarSubmitButtonPropsSelector,
  useSetConfirmationBarCancelButtonProps,
  useSetConfirmationBarSubmitButtonProps,
  useShowConfirmationBar as showConfirmationBarRecoil,
} from '@/store/ConfirmationBar/ConfirmationBar.model';
import { ConfirmationBarModelButtonPorps } from '@/store/ConfirmationBar/ConfirmationBar.types';

type BarSubmissionEvents = {
  primaryButton: {
    eventName: string;
    action: Action;
    properties?: EventProperties;
  };
  SecondaryButton: {
    eventName: string;
    action: Action;
    properties?: EventProperties;
  };
};
type UseConfirmationBarSubmissionControllerProps<T> = {
  submitButtonProps: ConfirmationBarModelButtonPorps;
  cancelButtonProps: ConfirmationBarModelButtonPorps;
  onPrimaryClick?: VoidFunction;
  onSecondaryClick?: VoidFunction;
  defaultValues?: T;
  eventProps?: BarSubmissionEvents;
};

type UseConfirmationBarSubmissionControllerOptions = {
  resetOnSecondary?: boolean;
  onCancelCb?: () => void;
};

let buttonsCallbacks = {
  submit: {
    onClick: () => {},
  },
  cancel: {
    onClick: () => {},
  },
};

export const useConfirmationBarSubmissionController = <T extends { [key: string]: unknown }>(
  {
    submitButtonProps,
    cancelButtonProps,
    onPrimaryClick,
    onSecondaryClick,
    defaultValues,
    eventProps,
  }: UseConfirmationBarSubmissionControllerProps<T>,
  { resetOnSecondary, onCancelCb }: UseConfirmationBarSubmissionControllerOptions = {},
) => {
  const { track } = useAnalytics();
  const {
    submitButtonProps: submitButtonPropsForm,
    cancelButtonProps: cancelButtonPropsForm,
    reset,
    formState,
    ...rest
  } = useFormSubmissionController<T>();

  const isOpen = useRecoilValue(confirmationBarIsOpenSelector);
  const showConfirmationBar = showConfirmationBarRecoil();
  const setSubmitButtonState = useSetConfirmationBarSubmitButtonProps();
  const setCancelButtonState = useSetConfirmationBarCancelButtonProps();

  const isFormDirty = React.useCallback(
    (newFormData: T, validateFields?: (keyof T)[]) => {
      if (!defaultValues) {
        return false;
      }

      const keys = validateFields?.length ? validateFields : Object.keys(newFormData);

      return keys.filter((key) => !isEqual(newFormData[key], defaultValues[key])).length > 0;
    },
    [defaultValues],
  );

  buttonsCallbacks.submit = {
    onClick: () => {
      if (eventProps) {
        const dirtyFieldsKeys = Object.keys(formState?.dirtyFields || {});
        const { eventName, action, properties } = eventProps.primaryButton;
        track(eventName, action, { ...properties, EditedFields: dirtyFieldsKeys });
      }
      onPrimaryClick?.();
      submitButtonPropsForm?.onClick();
    },
  };

  buttonsCallbacks.cancel = {
    onClick: () => {
      if (eventProps) {
        const { eventName, action, properties } = eventProps.SecondaryButton;
        track(eventName, action, properties);
      }
      onSecondaryClick?.();
      if (resetOnSecondary) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        reset?.(defaultValues as any);
      }
      onCancelCb?.();
      showConfirmationBar(false);
    },
  };

  React.useEffect(() => {
    setSubmitButtonState(submitButtonProps);
    setCancelButtonState(cancelButtonProps);
  }, [submitButtonProps, cancelButtonProps]);

  return {
    ...rest,
    formState,
    isOpen,
    showConfirmationBar,
    setSubmitButtonState,
    setCancelButtonState,
    reset,
    isFormDirty,
  };
};

export const useConfirmationBarUI = () => {
  const isOpen = useRecoilValue(confirmationBarIsOpenSelector);
  const submitButtonProps = useRecoilValue(confirmationBarSubmitButtonPropsSelector);
  const cancelButtonProps = useRecoilValue(confirmationBarCancelButtonPropsSelector);

  // Hiding confirmation bar when route path is changed
  const showConfirmationBar = showConfirmationBarRecoil();
  const location = useLocation();

  React.useEffect(() => {
    showConfirmationBar(false);
  }, [location]);

  return {
    isOpen,
    submitButtonProps,
    cancelButtonProps,
    buttonsCallbacks,
  };
};
