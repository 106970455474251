import { useAccount } from '@melio/platform-api';
import { useLocation } from '@melio/platform-utils';
import { useMemo } from 'react';

import { useSubscriptionContext } from '../context/SubscriptionContext';
import { NOT_SUBSCRIBED_ORGS_ALLOWED_ROUTES } from '../ui/constants';

export const useGracePreiodEndPaywall = () => {
  const { data: dataAccountMe, isLoading: isAccountLoading } = useAccount({ id: 'me' });

  const graceEndRedirectRoute = useMemo(() => {
    if (isAccountLoading || !dataAccountMe) {
      return '';
    }
    const isOwner = dataAccountMe.user.email === dataAccountMe.organizationOwner?.email;
    if (dataAccountMe.isAccountingFirmMember && dataAccountMe.companyType !== 'accounting-firm') {
      return '/subscription/grace/select-client-plan';
    } else if (isOwner) {
      return '/subscription/grace/select-your-plan';
    } else {
      return '/subscription/grace/plan-is-required';
    }
  }, [isAccountLoading, dataAccountMe]);

  const {
    gracePeriod: { isEligibleAndGracePeriodEnded },
  } = useSubscriptionContext();
  const location = useLocation();

  const isRouteAllowed = NOT_SUBSCRIBED_ORGS_ALLOWED_ROUTES.some((route) => location.pathname.startsWith(route));
  const shouldShowGracePeriodEndPaywall = isEligibleAndGracePeriodEnded && !isRouteAllowed;

  return {
    graceEndRedirectRoute,
    shouldShowGracePeriodEndPaywall,
  };
};
