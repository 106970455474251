import { AddVendorFormFields, AddVendorFormWidgetProps } from '@melio/ap-widgets';
import { Button, Group, useBreakpointValue, useFormSubmissionController } from '@melio/penny';
import { useConfig, useMelioIntl } from '@melio/platform-provider';

import { NewSinglePaymentStepLayout } from '../../NewSinglePaymentStepLayout';

export enum SubmitTarget {
  CLOSE = 'close',
  CONTINUE = 'continue',
}

type Props = {
  onClose: VoidFunction;
  managed?: AddVendorFormWidgetProps['managed'];
  onSubmit?: (submitTarget: SubmitTarget.CONTINUE | SubmitTarget.CLOSE) => void;
  submitButtonProps?: ReturnType<typeof useFormSubmissionController<AddVendorFormFields>>['submitButtonProps'];
  isButtonsDisabled?: boolean;
  shouldSendRequestToContractor?: boolean;
};

export const FooterContent = ({
  onClose,
  managed: initialManaged,
  submitButtonProps,
  onSubmit,
  isButtonsDisabled,
  shouldSendRequestToContractor,
}: Props) => {
  const {
    settings: {
      vendor: { collectedDetails: vendorCollectedDetails },
    },
  } = useConfig();
  const { formatMessage } = useMelioIntl();
  const isMobile = useBreakpointValue({ xs: true, s: false });
  const shouldSkipSelectDeliveryMethodsStep = initialManaged || vendorCollectedDetails === 'extended';
  const groupItemFlexProps = isMobile ? { grow: 1, shrink: 1, basis: 1 } : { grow: 1 };
  const groupSecondItemFlexProps = isMobile
    ? { grow: shouldSendRequestToContractor ? 2 : 1, shrink: 1, basis: 1 }
    : { grow: 1 };

  const nextButtonLabel = shouldSendRequestToContractor
    ? formatMessage(
        'activities.addVendor.screens.addVendorDetails.addVendorButton.continue.shouldSendRequestToContractor'
      )
    : formatMessage('activities.addVendor.screens.addVendorDetails.addVendorButton.continue');

  const nextButtonAriaLabel = shouldSendRequestToContractor
    ? formatMessage(
        'activities.addVendor.screens.addVendorDetails.addVendorButton.continue.shouldSendRequestToContractor.ariaLabel'
      )
    : formatMessage('activities.addVendor.screens.addVendorDetails.addVendorButton.continue.ariaLabel');

  if (shouldSkipSelectDeliveryMethodsStep) {
    return (
      <NewSinglePaymentStepLayout.Footer>
        {!isMobile && (
          <Button
            variant="tertiary"
            onClick={onClose}
            label={formatMessage('activities.addVendor.screens.addVendorDetails.addVendorButton.cancel')}
            size="medium"
            data-testid="layout-close-button"
          />
        )}
        <Group width={isMobile ? 'full' : undefined}>
          <Group.Item {...groupItemFlexProps}>
            <NewSinglePaymentStepLayout.SecondaryNextButton
              {...submitButtonProps}
              size="medium"
              isFullWidth
              onClick={() => onSubmit?.(SubmitTarget.CLOSE)}
              data-testid={`${SubmitTarget.CLOSE}-button`}
              label={formatMessage('activities.addVendor.screens.addVendorDetails.addVendorButton.close')}
              loadingText={formatMessage('app.loading')}
              isDisabled={isButtonsDisabled}
            />
          </Group.Item>
          <Group.Item {...groupSecondItemFlexProps}>
            <NewSinglePaymentStepLayout.NextButton
              {...submitButtonProps}
              size="medium"
              isFullWidth
              onClick={() => onSubmit?.(SubmitTarget.CONTINUE)}
              data-testid={`${SubmitTarget.CONTINUE}-button`}
              label={nextButtonLabel}
              loadingText={formatMessage('app.loading')}
              isDisabled={isButtonsDisabled}
              aria-label={nextButtonAriaLabel}
            />
          </Group.Item>
        </Group>
      </NewSinglePaymentStepLayout.Footer>
    );
  }

  return (
    <NewSinglePaymentStepLayout.Footer justifyContent="flex-end">
      <Group width={isMobile ? 'full' : undefined}>
        <Group.Item {...groupItemFlexProps}>
          <Button
            variant="tertiary"
            onClick={onClose}
            label={formatMessage('activities.addVendor.screens.addVendorDetails.addVendorButton.cancel')}
            size="medium"
            data-testid="layout-close-button"
            isFullWidth
          />
        </Group.Item>
        <Group.Item {...groupSecondItemFlexProps}>
          <NewSinglePaymentStepLayout.NextButton
            {...submitButtonProps}
            size="medium"
            isFullWidth
            onClick={() => onSubmit?.(SubmitTarget.CONTINUE)}
            data-testid={`${SubmitTarget.CONTINUE}-button`}
            label={nextButtonLabel}
            isDisabled={isButtonsDisabled}
            aria-label={nextButtonAriaLabel}
          />
        </Group.Item>
      </Group>
    </NewSinglePaymentStepLayout.Footer>
  );
};
