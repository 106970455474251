import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';

import { useOnboardingStepsTracker } from '../../components';
import { OwnershipDetailsScreen } from './screens';

export type OwnershipDetailsActivityProps = {
  onClose: VoidFunction;
  onDone: VoidFunction;
  onBack: VoidFunction;
};

export const OwnershipDetailsActivity = forwardRef<OwnershipDetailsActivityProps>(({ ...props }, ref) => {
  const { steps, currentStepIndex, goToStepById } = useOnboardingStepsTracker();

  useEffect(() => {
    goToStepById('ownership-details');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStepIndex]);

  return <OwnershipDetailsScreen steps={steps} currentStepIndex={currentStepIndex} {...props} ref={ref} />;
});

OwnershipDetailsActivity.displayName = 'OwnershipDetailsActivity';
