import { isVendorDirectoryDetailsCompleted } from '@melio/ap-widgets';
import { Vendor } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useConfig } from '@melio/platform-provider';
import { useMemo } from 'react';

import { MissingVendorDirectoryDetailsBannerProps } from './types';

type Props = {
  vendors?: Vendor[];
  onEditClick: VoidFunction;
};

export const useMissingVendorDirectoryDetailsBannerProps = ({
  vendors,
  onEditClick,
}: Props): MissingVendorDirectoryDetailsBannerProps => {
  const {
    settings: {
      vendor: { requiredDirectoryFields },
    },
  } = useConfig();

  const [isCollectMissingVendorInfoEnabled] = useFeature<boolean>(FeatureFlags.CollectMissingVendorInfoEnabled, false);

  return useMemo(() => {
    if (!vendors?.length || vendors?.length > 1 || !vendors?.[0]) {
      return;
    }

    const shouldShowBanner = !isVendorDirectoryDetailsCompleted({
      vendor: vendors[0],
      isCollectMissingVendorInfoEnabled,
      requiredDirectoryFields,
    });

    return shouldShowBanner
      ? {
          vendorName: vendors[0]?.name,
          onEditClick,
        }
      : undefined;
  }, [isCollectMissingVendorInfoEnabled, onEditClick, requiredDirectoryFields, vendors]);
};
