import { Button, Form, Group, useMelioForm } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { useInviteFirmClientFormSchema } from './hooks/useInviteFirmClientFormSchema';
import { InviteFirmClientFormFields } from './types';

type Props = {
  isSaving: boolean;
  onSubmit: (data: InviteFirmClientFormFields) => Promise<void>;
  onClose: VoidFunction;
};

export const InviteFirmClientForm = ({ isSaving, onSubmit, onClose }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { formProps, registerField, submitButtonProps } = useMelioForm<InviteFirmClientFormFields>({
    isSaving,
    onSubmit,
    schema: useInviteFirmClientFormSchema(),
    defaultValues: {
      clientEmailAddress: '',
      clientFirstName: '',
      clientLastName: '',
      includeMyEmailInCC: false,
    },
    subscribeToDefaultValuesChanges: true,
  });

  return (
    <>
      <Form {...formProps} columns={2}>
        <Form.TextField
          colSpan={1}
          isRequired
          labelProps={{
            label: formatMessage('activities.accountants.inviteClient.form.fields.clientFirstName.label'),
          }}
          {...registerField('clientFirstName')}
        />
        <Form.TextField
          colSpan={1}
          isRequired
          labelProps={{
            label: formatMessage('activities.accountants.inviteClient.form.fields.clientLastName.label'),
          }}
          {...registerField('clientLastName')}
        />
        <Form.TextField
          colSpan={2}
          isRequired
          labelProps={{
            label: formatMessage('activities.accountants.inviteClient.form.fields.clientCompanyName.label'),
          }}
          {...registerField('companyName')}
        />
        <Form.TextField
          colSpan={2}
          isRequired
          labelProps={{
            label: formatMessage('activities.accountants.inviteClient.form.fields.clientEmail.label'),
          }}
          {...registerField('clientEmailAddress')}
        />
        <Form.Checkbox
          colSpan={2}
          label={formatMessage('activities.accountants.inviteClient.form.fields.includeMyEmailInCC.label')}
          {...registerField('includeMyEmailInCC')}
        />
      </Form>
      <Group spacing="m" justifyContent="flex-end">
        <Button
          data-testid="invite-client-back-button"
          variant="secondary"
          size="medium"
          label={formatMessage('activities.accountants.inviteClient.form.actions.back.label')}
          onClick={onClose}
        />
        <Button
          data-testid="invite-client-ok-button"
          variant="primary"
          size="medium"
          label={formatMessage('activities.accountants.inviteClient.form.actions.sendInvite.label')}
          {...submitButtonProps}
        />
      </Group>
    </>
  );
};
