import React, { useCallback } from 'react';
import { Box } from '@chakra-ui/react';
import { OriginFlow } from '@melio/platform-analytics';

import { EmptyStateList } from '@/cl/components/EmptyStateList/EmptyStateList.component';
import { LabelCopy } from '@/cl/components/LabelCopy/LabelCopy.component';
import { useCopyEmailAnalytics } from '@/hooks/analytics/copyEmailAnalytics';
import { usePartnerConfig } from '@/hooks/partners';
import { useRouter } from '@/hooks/router.hooks';
import { usePlatformIntl } from '@/translations/Intl';

type BillsInboxEmptyStatePromoProps = {
  billsInboxEmail: string;
};

export const BillsInboxEmptyStatePromo = ({ billsInboxEmail }: BillsInboxEmptyStatePromoProps) => {
  const { goToAddNewBill } = useRouter();
  const { partnerConfig } = usePartnerConfig();
  const { AddBillIcon } = partnerConfig.icons;
  const { formatMessage } = usePlatformIntl();
  const { trackCopyEmailCopy, trackCopyEmailLink } = useCopyEmailAnalytics('PayDashboardToggleBarInbox');
  const onAddBillIconClick = useCallback(() => goToAddNewBill({ originFlow: OriginFlow.BillPage }), [goToAddNewBill]);
  return (
    <EmptyStateList
      testId="bills-inbox-empty-state"
      title={formatMessage('widgets.billsInbox.emptyStatePromo.title')}
      description={formatMessage('widgets.billsInbox.emptyStatePromo.description', {
        emailLabel: (
          <Box padding="4px 0 24px 0">
            <LabelCopy
              testId="bills-inbox-email-link"
              align="center"
              label={billsInboxEmail}
              link={`mailto:${billsInboxEmail}`}
              successMessage={formatMessage('widgets.billsInbox.successCopyToast')}
              errorMessage={formatMessage('widgets.billsInbox.errorCopyToast')}
              onCopy={trackCopyEmailCopy}
              onLinkClick={trackCopyEmailLink}
            />
          </Box>
        ),
      })}
      icon={AddBillIcon}
      button={{
        text: formatMessage('widgets.emptyPayList.addBillButton'),
        onClick: onAddBillIconClick,
      }}
    />
  );
};

BillsInboxEmptyStatePromo.displayName = 'BillsInboxEmptyStatePromo';
