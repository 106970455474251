import { Navigate, useLocation } from 'react-router-dom';
import { PaymentFlowDoneAction, ScheduleSinglePaymentActivity } from '@melio/ap-activities';
import { useBills } from '@melio/platform-api';

import { Loader } from '@/cl/components/Loader/Loader.component';
import { SchedulePaymentRouteParamsType, useRouter } from '@/hooks/router.hooks';
import { getSessionStorageItem } from '@/utils/sessionStorage.utils';
import { openInNewTab } from './externalEntries.utils';

export const ExternalEntrySchedulePaymentScreen = ({ billOriginId }: { billOriginId?: string }) => {
  const { generateNPEDashboardLink, generateExternalEntriesFallbackErrorLink } = useRouter();
  const { state } = useLocation();
  const { deliveryMethodId } = (state as SchedulePaymentRouteParamsType) || {};
  const externalOrigin = getSessionStorageItem('externalOrigin');
  const { data: bills, isLoading: isBillsDataLoading } = useBills({
    params: { search: { 'bill.originId': billOriginId, 'bill.origin': 'intuit' } },
  });

  const handleDone = (_paymentId: string, action: PaymentFlowDoneAction) => {
    if (action === 'openInNewTabDashboard') {
      openInNewTab(generateNPEDashboardLink());
    }
  };

  if (!billOriginId) {
    return <Navigate to={generateExternalEntriesFallbackErrorLink()} />;
  }

  if (isBillsDataLoading) {
    return <Loader isAbsoluteCenter />;
  }

  if (bills?.length === 1) {
    return (
      <ScheduleSinglePaymentActivity
        billId={bills[0].id}
        onDone={handleDone}
        onClose={() => {}}
        onBack={() => {}}
        deliveryMethodId={deliveryMethodId}
        externalOrigin={externalOrigin}
      />
    );
  }

  return <Navigate to={generateExternalEntriesFallbackErrorLink()} />;
};
