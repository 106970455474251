import { useGuestPayorFundingSources } from '@melio/ar-domain';
import { TBTFormWidgetFields, useTbt } from '@melio/form-controls';
import { CardParams } from '@melio/platform-api';
import { useBoolean } from '@melio/platform-utils';

import { CardHolderDetails, CardHolderDetailsFormFields } from '../../types';

type UseAddCardFundingSourceProps = {
  onError?: ARErrorFunction;
  onAddCardSuccess: (id: string) => void;
};

export const useAddCardFundingSource = ({ onAddCardSuccess, onError }: UseAddCardFundingSourceProps) => {
  const [isVerifying, verifying] = useBoolean(false);
  const { tokenize } = useTbt();

  const fundingSourcesCollection = useGuestPayorFundingSources({ enabled: false });

  const addCard = async (params: CardHolderDetailsFormFields) => {
    const { cardExpiration, cardNumber, cardVerificationCode, ...cardHolderDetails } = params;
    const cardParams = await verifyCard({ cardExpiration, cardNumber, cardVerificationCode });
    return createAccount(cardHolderDetails, cardParams);
  };

  const createAccount = async (cardHolderDetails: CardHolderDetails, cardParams: CardParams) => {
    try {
      const { firstName, lastName, email, ...address } = cardHolderDetails;
      const details = { ...cardParams, address, cardOwner: { firstName, lastName } };
      const { id } = await fundingSourcesCollection.create({ type: 'card', details });
      onAddCardSuccess(id);
    } catch (error) {
      onError?.(error as ARPlatformError);
    }
  };

  const verifyCard = (data: TBTFormWidgetFields) => {
    verifying.on();
    return (async () => {
      try {
        const cardParams = await tokenize(data);
        const { cardBin, tabapayToken } = cardParams;
        await fundingSourcesCollection.verifyCard({ cardBin, tabapayToken, tokenProvider: 'basistheory' });
        return new Promise<CardParams>((resolve) => resolve(cardParams));
      } catch (error) {
        onError?.(error as ARPlatformError);
        throw error;
      } finally {
        verifying.off();
      }
    })();
  };

  return { addCard, isCreating: fundingSourcesCollection.isMutating || isVerifying };
};
