import { FeeType, PaymentFee } from '@melio/platform-api';

const payorFees = [
  'ach',
  'ach-to-check',
  'credit',
  'debit',
  'domestic-wire',
  'expedited-ach',
  'expedited-ach-vendor',
  'express-check',
  'international',
  'rtp-organization',
] as const;

type PayorFeeType = Extract<FeeType, (typeof payorFees)[number]>;
export type PayorPaymentFee = Override<PaymentFee, { type: PayorFeeType }>;

export const isPayorPaymentFee = (fee: PaymentFee): fee is PayorPaymentFee =>
  payorFees.some((payorFee) => payorFee === fee.type);

export const sumPaymentFees = (fees: PaymentFee[]): number =>
  fees.reduce((totalFees, fee) => totalFees + fee.amount, 0);
