import { useMelioIntl } from '@melio/platform-i18n';
import { sumBy } from 'lodash';

import { PaymentDetails } from './types';

export const useTitles = (
  payments: PaymentDetails[],
  isEditPaymentFlow: boolean,
  failedPaymentsSchedulingCount: number
) => {
  const { formatMessage, formatCurrency } = useMelioIntl();

  const getTitle = (payments: PaymentDetails[], isEditPaymentFlow: boolean) => {
    const isMultiplePayments = payments.length > 1;
    const shouldIncludeErrors = failedPaymentsSchedulingCount > 0;
    const totalPaymentsScheduling = payments.length + failedPaymentsSchedulingCount;

    if (isMultiplePayments) {
      if (shouldIncludeErrors) {
        return formatMessage(
          'activities.paymentScheduledSuccessfully.screens.paymentScheduled.title.multiplePayments.scheduledPartialSuccess',
          {
            quantity: totalPaymentsScheduling,
            successQuantity: payments.length,
          }
        );
      }
      return formatMessage('activities.paymentScheduledSuccessfully.screens.paymentScheduled.title.multiplePayments', {
        quantity: payments.length,
      });
    }

    if (isEditPaymentFlow) {
      return formatMessage('activities.paymentScheduledSuccessfully.screens.paymentScheduled.title.updatedPayment');
    }

    if (shouldIncludeErrors) {
      return formatMessage(
        'activities.paymentScheduledSuccessfully.screens.paymentScheduled.title.multiplePayments.scheduledPartialSuccess',
        {
          quantity: totalPaymentsScheduling,
          successQuantity: payments.length,
        }
      );
    }

    return formatMessage('activities.paymentScheduledSuccessfully.screens.paymentScheduled.title.singlePayment');
  };

  const getSubTitle = (payments: PaymentDetails[]) => {
    if (payments.length > 1) {
      const paymentsAmount = sumBy(payments, (payment) => payment.paymentAmount);

      return formatMessage(
        'activities.paymentScheduledSuccessfully.screens.paymentScheduled.subTitle.multiplePayments',
        {
          paymentsAmount: formatCurrency(paymentsAmount),
        }
      );
    }

    return null;
  };

  const getPaymentFailureDetails = () => {
    const isMultiplePayments = failedPaymentsSchedulingCount > 1;

    const paymentFailureTitle = formatMessage(
      isMultiplePayments
        ? 'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentSchedulingFailure.title.multiplePayments'
        : 'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentSchedulingFailure.title.singlePayment',
      { quantity: failedPaymentsSchedulingCount }
    );

    const paymentFailureSubTitle = formatMessage(
      isMultiplePayments
        ? 'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentSchedulingFailure.subtitle.multiplePayments'
        : 'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentSchedulingFailure.subtitle.singlePayment'
    );

    const paymentFailureCta = formatMessage(
      isMultiplePayments
        ? 'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentSchedulingFailure.cta.multiplePayments'
        : 'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentSchedulingFailure.cta.singlePayment'
    );

    return {
      paymentFailureTitle,
      paymentFailureSubTitle,
      paymentFailureCta,
    };
  };

  const { paymentFailureTitle, paymentFailureSubTitle, paymentFailureCta } = getPaymentFailureDetails();

  return {
    title: getTitle(payments, isEditPaymentFlow),
    subTitle: getSubTitle(payments),
    paymentFailureTitle,
    paymentFailureSubTitle,
    paymentFailureCta,
  };
};
