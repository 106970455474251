import { convertFxAmountToUsdAmount } from '@melio/ap-domain';
import { Loader, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

import { FxCurrencyExchangeRate } from '../../../../../add-bill';

export const FxSubtitle = ({
  fxRate,
  isLoadingFxRate,
  currency,
  paymentAmount,
}: {
  fxRate: FxCurrencyExchangeRate;
  isLoadingFxRate: boolean;
  paymentAmount: number;
  currency?: string;
}) => {
  const { formatMessage, formatCurrency } = useMelioIntl();

  if (isLoadingFxRate || !fxRate) {
    return <Loader />;
  }

  const fxAmountInUsd = convertFxAmountToUsdAmount({
    fxRate,
    paymentAmount,
    currency,
  });

  return (
    <Text textStyle="body3" data-testid="fx-payment-amount-in-usd">
      {formatMessage('activities.fundingSourceSelection.screens.fundingSourceSelection.title.fxAmountInUsd', {
        amount: formatCurrency(fxAmountInUsd, 'USD'),
      })}
    </Text>
  );
};
