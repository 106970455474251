import { Container, IconKey, LoadingContainer, useBreakpoint } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useApprovalWorkflows } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { SettingsPageHeader } from '@melio/platform-utils';
import { Suspense, useEffect } from 'react';

import { ApprovalWorkflowCreatedModal } from './components/ApprovalWorkflowCreatedModal';
import { ApprovalWorkflowsList, OnActionProps } from './components/ApprovalWorkflowsList';
import { PremiumBadgeWithPopover } from './components/PremiumBadgeWithPopover';

export const ApprovalWorkflowsSettings = withAnalyticsContext<{
  onAction: (props: OnActionProps) => void;
}>(({ setAnalyticsProperties, onAction }) => {
  const { formatMessage } = useMelioIntl();
  const { data: workflows, isLoading } = useApprovalWorkflows({
    refetchOnMount: 'always',
    useErrorBoundary: true,
  });
  const { track } = useAnalytics();
  const { isExtraSmallScreen } = useBreakpoint();

  setAnalyticsProperties({
    PageName: 'payment-approval-workflow',
    Flow: 'approval-workflow',
    CountInTab: workflows?.length,
    WorkflowId: workflows?.map((workflow) => workflow.id),
  });

  useEffect(() => {
    if (!isLoading) {
      track('Settings', 'View', { Intent: 'approval-workflow', EntryPoint: 'Invite', PageName: 'workflows-setting' });
    }
  }, [isLoading, track]);

  const primaryButtonProps = {
    label: formatMessage('activities.settings.approval-workflow-settings.add-workflow'),
    icon: 'add' as IconKey,
    onClick: () => {
      track('Settings', 'Click', { Intent: 'add-approval-workflow', Cta: 'add-workflow' });
      onAction({ action: 'add' });
    },
    dataTestId: 'add-workflow-button',
  };

  return (
    <Container width="full" paddingX="none" data-testid="workflow-settings-root" data-component="WorkflowsSettings">
      <SettingsPageHeader
        title={formatMessage('app.settings.companySection.cards.workflows.screen.title')}
        primaryButton={isExtraSmallScreen ? undefined : primaryButtonProps}
        badge={
          <PremiumBadgeWithPopover
            featureName="approvalWorkflows"
            featureDescription={formatMessage('activities.subscription.featureName')}
          />
        }
      />
      <Suspense fallback={<LoadingContainer isLoading />}>
        <ApprovalWorkflowsList onAction={onAction} />
      </Suspense>
      <ApprovalWorkflowCreatedModal />
    </Container>
  );
});
