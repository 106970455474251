import { useMelioIntl } from '@melio/ar-domain';
import { Form, Group, Modal, Text, useFormSubmissionController, useMelioForm } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { object, SchemaOf, string } from 'yup';

import { GoodsReceivedFormFields } from './types';

export type GoodsReceivedModalScreenProps = {
  isOpen: boolean;
  isSaving?: boolean;
  onClose: VoidFunction;
  onDone: (isGoodsReceived: boolean) => void;
  amountThreshold: number;
  currency?: string;
};

const useSchema = () =>
  object().shape({
    goodsReceivedRadioValue: string()
      .default('yes')
      .oneOf(Object.values(['yes', 'no']))
      .required(),
  }) as SchemaOf<GoodsReceivedFormFields>;

export const GoodsReceivedModalScreen = forwardRef<GoodsReceivedModalScreenProps>(
  ({ isOpen, isSaving, onClose, onDone, amountThreshold, currency }, ref) => {
    const { formatMessage, formatCurrency } = useMelioIntl();
    const { onSubmissionStateChange, submitButtonProps } = useFormSubmissionController<GoodsReceivedFormFields>();
    const { formProps, registerField } = useMelioForm<GoodsReceivedFormFields>({
      isSaving,
      schema: useSchema(),
      defaultValues: { goodsReceivedRadioValue: 'yes' },
      onSubmit: ({ goodsReceivedRadioValue }) => onDone(goodsReceivedRadioValue === 'yes'),
      onSubmissionStateChange,
    });

    return (
      <Modal
        ref={ref}
        isOpen={isOpen}
        onClose={onClose}
        data-testid="guest-payment-goods-received-modal"
        header={formatMessage('ar.guestPayment.goodsReceivedModal.header')}
        primaryButton={{
          variant: 'primary',
          label: formatMessage('ar.guestPayment.goodsReceivedModal.continue.label'),
          ...submitButtonProps,
        }}
      >
        <Group variant="vertical">
          <Text>
            {formatMessage('ar.guestPayment.goodsReceivedModal.description', {
              amount: formatCurrency(amountThreshold, currency),
            })}
          </Text>
          <Form data-component="GoodsReceivedForm" {...formProps}>
            <Form.RadioGroup
              aria-label="goods received"
              variant="horizontal"
              options={[
                {
                  mainLabelProps: { label: formatMessage('ar.guestPayment.goodsReceivedModal.option.yes.label') },
                  value: 'yes',
                },
                {
                  mainLabelProps: { label: formatMessage('ar.guestPayment.goodsReceivedModal.option.no.label') },
                  value: 'no',
                },
              ]}
              {...registerField('goodsReceivedRadioValue')}
            />
          </Form>
        </Group>
      </Modal>
    );
  }
);
GoodsReceivedModalScreen.displayName = 'GoodsReceivedModalScreen';
