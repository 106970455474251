import { useAnalytics } from '@melio/platform-analytics';

export const useCardFundingSourceFormAnalytics = () => {
  const { track } = useAnalytics();

  const trackCardHolderDetailsClickOrFocus = (Cta: string) => {
    track('Organization', 'Click', {
      Intent: 'fill-card-holder-details',
      Cta,
    });
  };

  const trackCardDetailsFocus = (Cta: string) => {
    track('Organization', 'Click', {
      Intent: 'fill-card-details',
      Cta,
    });
  };

  return {
    trackCardHolderDetailsClickOrFocus,
    trackCardDetailsFocus,
  };
};
