import { UnilateralForm, UnilateralFormFields } from '@melio/ap-widgets';
import { useFormSubmissionController } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { FundingSource, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';
import { useDescriptionText } from './utils';

export type AddVendorUnilateralDetailsScreenProps = {
  isSaving: boolean;
  isLoading?: boolean;
  fundingSourceType?: FundingSource['type'];
  vendorName: Vendor['name'];
  defaultValues?: Partial<UnilateralFormFields>;
  onBack: VoidFunction;
  onClose: VoidFunction;
  onDone: (data: UnilateralFormFields) => void;
};

export const AddVendorUnilateralDetailsScreen: React.FC<AddVendorUnilateralDetailsScreenProps> = forwardRef<
  AddVendorUnilateralDetailsScreenProps,
  'div'
>(({ isSaving, isLoading, fundingSourceType, vendorName, defaultValues, onBack, onClose, onDone, ...props }, ref) => {
  const { formatMessage } = useMelioIntl();
  const { onSubmissionStateChange, cancelButtonProps, submitButtonProps } =
    useFormSubmissionController<UnilateralFormFields>();

  useAnalyticsView('AskVendorDeliveryDetails');

  return (
    <NewSinglePaymentStepLayout
      data-component="AddVendorUnilateralDetailsActivity.AddVendorUnilateralDetailsScreen"
      data-testid="add-vendor-unilateral-details-activity-add-vendor-unilateral-details-screen"
      {...props}
      isLoading={isLoading}
      ref={ref}
      headerContent={
        <NewSinglePaymentStepLayout.Header>
          <NewSinglePaymentStepLayout.BackButton {...cancelButtonProps} onClick={onBack} />
          <NewSinglePaymentStepLayout.CloseButton onClick={onClose} />
        </NewSinglePaymentStepLayout.Header>
      }
      footerContent={
        <NewSinglePaymentStepLayout.Actions>
          <NewSinglePaymentStepLayout.NextButton
            {...submitButtonProps}
            label={formatMessage('activities.addVendorUnilateralDetails.screens.addVendorUnilateralDetails.continue')}
          />
        </NewSinglePaymentStepLayout.Actions>
      }
      bottomContent={
        <NewSinglePaymentStepLayout.BottomTextContent textAlign="start">
          {formatMessage('activities.addVendorUnilateralDetails.screens.addVendorUnilateralDetails.footNotes', {
            vendorName,
          })}
        </NewSinglePaymentStepLayout.BottomTextContent>
      }
    >
      <NewSinglePaymentStepLayout.Title>
        {formatMessage('activities.addVendorUnilateralDetails.screens.addVendorUnilateralDetails.title')}
      </NewSinglePaymentStepLayout.Title>
      <NewSinglePaymentStepLayout.Description>
        {useDescriptionText(vendorName, fundingSourceType)}
      </NewSinglePaymentStepLayout.Description>
      <NewSinglePaymentStepLayout.Content>
        <UnilateralForm
          onSubmit={onDone}
          isSaving={isSaving}
          defaultValues={defaultValues}
          onSubmissionStateChange={onSubmissionStateChange}
        />
      </NewSinglePaymentStepLayout.Content>
    </NewSinglePaymentStepLayout>
  );
});

AddVendorUnilateralDetailsScreen.displayName = 'AddVendorUnilateralDetailsActivity.AddVendorUnilateralDetailsScreen';
