import { useDisclosure } from '@chakra-ui/react';
import { StatusModal, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { PaymentStatusEnum, usePayments, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useCallback } from 'react';

import { MonitoredAction } from '../../../monitoring';

export const useEffectedPaymentsDisclaimerModal = (vendorId: Vendor['id']) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data, isLoading } = usePayments({
    params: {
      search: {
        'vendor.id': vendorId,
        'payment.status': [
          PaymentStatusEnum.Blocked,
          PaymentStatusEnum.Failed,
          PaymentStatusEnum.InProgress,
          PaymentStatusEnum.Scheduled,
        ],
      },
    },
  });

  return { isLoading, showDisclaimer: !!data?.length, isOpen, onOpen, onClose };
};

type Props = { isOpen: boolean; onClose: VoidFunction; onConfirm: VoidFunction };
export const EffectedPaymentsDisclaimerModal = ({ isOpen, onClose, onConfirm }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { startAction, endAction } = useMonitoring<MonitoredAction>();
  const endActionMonitoring = useCallback(() => {
    endAction('edit_vendor_bank_details');
  }, [endAction]);

  const confirmLabel = formatMessage(
    'activities.editVendorDeliveryMethod.modal.effectedPaymentsDisclaimerModal.confirm'
  );
  const description = formatMessage(
    'activities.editVendorDeliveryMethod.modal.effectedPaymentsDisclaimerModal.description'
  );

  const { createTrackHandler } = useAnalytics();
  const trackAndHandleClick = createTrackHandler<{ Cta: 'confirm' }>('Payment', 'Click');

  const onClick = () => {
    startAction('effected_payments_disclaimer');
    trackAndHandleClick({ Cta: 'confirm' }, () => onConfirm());
  };

  return (
    <StatusModal
      // TODO: add modal missing header
      // https://meliorisk.atlassian.net/browse/ME-57684
      header=""
      ref={endActionMonitoring}
      variant="informative"
      data-testid="effected-payments-disclaimer-modal"
      isOpen={isOpen}
      onClose={onClose}
      primaryButton={{ label: confirmLabel, variant: 'primary', onClick }}
    >
      <Text>{description}</Text>
    </StatusModal>
  );
};
