import { useMelioQueryClient, useModelApi, UseModelApiProps, useUpdateMutation } from '@melio/api-client';
import { ApiKeys, BillsApiClient } from '@melio/platform-api-axios-client';

import { Bill as LegacyBill, BillsApiGetBillsRequest } from './types';
import { convertBillCentsToDollars, prepareBillParams, prepareBillParamsForUpdate } from './utils';

export type UseBillProps = UseModelApiProps<typeof BillsApiClient.getBillsBillId, LegacyBill> & {
  params?: Pick<BillsApiGetBillsRequest, 'expand'>;
};

export const useBill = ({ params, ...props }: UseBillProps = {}) => {
  const entityType = 'BillsApi';
  const queryClient = useMelioQueryClient();
  const query = useModelApi<
    typeof BillsApiClient.getBillsBillId,
    typeof BillsApiClient.patchBillsBillId,
    typeof BillsApiClient.deleteBillsBillId,
    typeof BillsApiClient.postBills,
    LegacyBill
  >({
    ...props,
    queryKey: ['BillsApi', params],

    queryFn: (id) => BillsApiClient.getBillsBillId(id, params?.expand),
    select: convertBillCentsToDollars,

    createFn: (data) => BillsApiClient.postBills(data, params?.expand),
    prepareCreateParams: prepareBillParams,

    updateFn: (id, data) => BillsApiClient.patchBillsBillId(id, data, params?.expand),
    prepareUpdateParams: prepareBillParamsForUpdate,

    deleteFn: BillsApiClient.deleteBillsBillId,
    onDelete: () => queryClient.removeQueries(entityType, 'model'),
    onCreate: (bill) => {
      void queryClient.invalidateQueries(ApiKeys.VendorsApi, 'model', bill.vendorId);
    },
  });

  const markAsPaidMutation = useUpdateMutation(BillsApiClient.postBillsBillIdMarkAsPaid, query.queryKey, {
    prepareParams: prepareBillParams,
  });

  return {
    ...query,
    markAsPaid: markAsPaidMutation.createCallback(props.id as string),
    /**
     * @deprecated for debugging purposes only
     */
    _mutations: {
      ...query._mutations,
      markAsPaid: markAsPaidMutation,
    },
  };
};

export type BillModel = ReturnType<typeof useBill>;
