import { Card, CardProps, Group, Icon, IconsMap, Typography } from '@melio/penny';

export type FundingSourceTypeCardProps = CardProps & {
  label: string;
  icon: keyof Pick<IconsMap, 'credit-card' | 'bank'>;
};

export const FundingSourceTypeCard = ({ label, icon, ...props }: FundingSourceTypeCardProps) => (
  <Card {...props} variant="flat" data-testid={`funding-source-type-card-${icon}`}>
    <Group variant="vertical" justifyContent="flex-start" spacing="xs">
      <Icon type={icon} data-testid={`funding-source-type-icon-${icon}`} />
      <Typography.MainLabel variant="bold" label={label} data-testid={`funding-source-type-label-${icon}`} />
    </Group>
  </Card>
);
