import { Box } from '@chakra-ui/react';
import { Pill, Text } from '@melio/penny';
import { Organization } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { CompanyAvatar } from '../../CompanyAvatar';
import { PaymentsOverview } from '../../PaymentsOverview';
import { CardLayout } from '../common/CardLayout';

type Props = {
  organization: Organization;
};

export const NoAccessOrganizationCard = ({ organization }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { id, name, logoUrl } = organization;
  const companyName = name || '';
  return (
    <CardLayout
      data-testid={`organization-card-${id}`}
      top={
        <>
          <CompanyAvatar size="medium" src={logoUrl} name={companyName} />
          <Text textStyle="body2Semi" shouldSupportEllipsis>
            {companyName}
          </Text>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: 'xs',
              marginRight: 's',
              flex: '1',
              whiteSpace: 'nowrap',
            }}
            data-testid="badge-pending"
          >
            <Pill
              status="neutral"
              isReadOnly
              label={formatMessage('widgets.joinOrganizationRequestCard.badge.pending')}
            />
          </Box>
        </>
      }
      bottom={
        <PaymentsOverview
          paymentsOverview={{
            unpaid: {
              count: 0,
            },
            scheduled: {
              count: 0,
            },
            failed: {
              count: 0,
            },
            pending: {
              count: 0,
            },
            paid: {
              count: 0,
            },
          }}
          isDisabled={false}
        />
      }
    />
  );
};
