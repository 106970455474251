import { ExternalLayout } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { ModelError, useAutoPayment, useVendor, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage, useWizardSimple, withSystemMessageProvider } from '@melio/platform-utils';
import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import { AutoPaymentActivationFormActivity } from '../AutoPaymentActivationForm';
import { AutoPaymentActivationFormFields } from '../AutoPaymentActivationForm/screens/AutoPaymentActivation/AutoPaymentActivationForm/types';
import { AutoPaymentAction, AutoPaymentActivationSuccessScreen } from '../screens';
import { AutoPaymentAmountDetails } from '../screens/AutoPaymentActivationSuccess/useAutoPaymentActivationSuccessMessages.hook';
import { getSubmitErrorMessageKey } from '../utils';

type WizardSteps = 'form' | 'success';
const locationsMap = {
  form: 'form',
  success: 'success',
};

export const AutoPaymentEditFlowActivity = withSystemMessageProvider(({ vendorId }: { vendorId: Vendor['id'] }) => {
  const [autoPaymentAmountDetails, setAutoPaymentAmountDetails] = useState<AutoPaymentAmountDetails>();
  const {
    data: autoPayment,
    isLoading,
    updateAutoPayment,
    isMutating,
  } = useAutoPayment({
    vendorId,
    options: {
      onUpdate: ({ paymentAmountType, fixedAmount }) => goToSuccessPage({ fixedAmount, paymentAmountType }),
      onUpdateError: (error) => onUpdateFailed(error as ModelError),
    },
    enabled: true,
    cacheTime: 0,
  });
  const { data: vendor } = useVendor({ id: vendorId });
  const { formatMessage } = useMelioIntl();
  const { showMessage } = useSystemMessage();
  const { track } = useAnalytics();

  const { goNext, cancelFlow } = useWizardSimple<WizardSteps>({
    flowName: 'auto-payment-edit',
    firstStep: 'form',
    locationsMap,
    navigationSequence: ['form', 'success'],
    cancelUrlFallback: '/',
  });

  const goToSuccessPage = ({ paymentAmountType, fixedAmount }: AutoPaymentAmountDetails) => {
    setAutoPaymentAmountDetails({
      fixedAmount,
      paymentAmountType,
    });

    goNext();
  };

  const onDone = ({
    deliveryDateType,
    fixedAmount,
    fundingSourceId,
    maxAmount,
    paymentAmountType,
    scheduleDaysBefore,
    shouldNotifyOnScheduled,
    shouldNotifyOnSent,
  }: AutoPaymentActivationFormFields) => {
    const request = {
      deliveryDateType,
      fundingSourceId,
      paymentAmountType,
      shouldNotifyOnScheduled,
      shouldNotifyOnSent,
      scheduleDaysBefore: scheduleDaysBefore ? Number(scheduleDaysBefore) : null,
      fixedAmount: fixedAmount ? Number(fixedAmount) : null,
      maxAmount: maxAmount ? Number(maxAmount) : null,
    };

    updateAutoPayment(request);
  };

  const onUpdateFailed = (error: ModelError) => {
    track('Dashboard', 'Status', {
      flow: 'dashboard',
      intent: 'update-autopay',
      Status: 'failure',
    });

    showMessage({
      title: formatMessage(getSubmitErrorMessageKey(error)),
      type: 'error',
    });
  };

  const onDoneSuccessPage = () => {
    track('Dashboard', 'Status', {
      flow: 'dashboard',
      intent: 'update-autopay',
      Status: 'success',
    });

    cancelFlow();
  };

  if (!vendor || isLoading) {
    return <ExternalLayout isLoading />;
  }

  return (
    <Routes>
      <Route
        path={locationsMap.form}
        element={
          <AutoPaymentActivationFormActivity
            vendorId={vendorId}
            onDone={onDone}
            onClose={cancelFlow}
            isMutating={isMutating}
            autoPayment={autoPayment}
            action={AutoPaymentAction.Edit}
          />
        }
      />
      <Route
        path={locationsMap.success}
        element={
          <AutoPaymentActivationSuccessScreen
            vendorName={vendor.name}
            onDone={onDoneSuccessPage}
            autoPaymentAmountDetails={autoPaymentAmountDetails}
            action={AutoPaymentAction.Edit}
          />
        }
      />
    </Routes>
  );
});
