import { AddressSearchWidget, IndustryTypeSelectWidget } from '@melio/ap-widgets';
import {
  Button,
  CollapsibleCard,
  Divider,
  Form,
  Group,
  NakedButton,
  Typography,
  UseMelioFormResults,
  useWatch,
} from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { ApplyForFinancingBusinessType, Industry } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';
import type { FormState, Path } from 'react-hook-form';

import { useBusinessTypeOptions } from '../hooks/useBusinessTypeOptions';
import { FinancingApplicationBusinessDetailsForm } from '../types';

export type Props = {
  onOpen: VoidFunction;
  onCollapse: VoidFunction;
  onSubmit: VoidFunction;
  isSubmitted: boolean;
  isExpanded: boolean;
  form: UseMelioFormResults<FinancingApplicationBusinessDetailsForm>;
  isEditMode?: boolean;
  onClickEdit: VoidFunction;
};

export const BusinessDetailsForm = ({
  isExpanded,
  onOpen,
  onCollapse,
  isSubmitted,
  form,
  isEditMode,
  onClickEdit,
  onSubmit,
}: Props) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const businessTypeOptions = useBusinessTypeOptions();

  const { formState, registerField, control, setValue } = form;
  const watchedBusinessType = useWatch({
    control,
    name: 'businessType',
  });

  const formatFormFieldsErrors = (formState: FormState<FinancingApplicationBusinessDetailsForm>) =>
    Object.fromEntries(Object.entries(formState.errors).map(([key, value]) => [key, value.message || '']));

  useEffect(() => {
    if (Object.keys(formState.errors).length) {
      track('PaymentMethod', 'Status', {
        PageName: 'business-details',
        Flow: 'financing',
        Intent: 'continue',
        Cta: 'apply',
        FinancingExperience: 'application',
        Status: 'MissingFields',
        ErrorDetails: formatFormFieldsErrors(formState),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.errors]);

  const onExpandChange = (isExpanded: boolean) => {
    if (isSubmitted) {
      isExpanded ? onOpen() : onCollapse();
      return;
    }

    return;
  };

  const getIsViewModeForField = (
    fieldId:
      | Path<Omit<FinancingApplicationBusinessDetailsForm, 'legalBusinessAddress' | 'businessIndustry'>>
      | 'legalBusinessAddress'
  ) => !form.formState.errors[fieldId] && !isEditMode;

  const setBusinessIndursty = (industryName: FinancingApplicationBusinessDetailsForm['businessIndustry']) => {
    setValue('businessIndustry', industryName);
  };

  return (
    <CollapsibleCard
      data-testid="business-details-form-card"
      onExpandChange={onExpandChange}
      isExpanded={isExpanded}
      title={formatMessage('activities.financingApplication.applicationFormScreen.businessDetails.title')}
      description={formatMessage('activities.financingApplication.applicationFormScreen.businessDetails.description')}
      status={isSubmitted && !isEditMode ? 'success' : undefined}
    >
      <Form {...form.formProps}>
        <Group variant="horizontal" justifyContent="space-between" spacing="m">
          <Typography.SectionLabel
            label={formatMessage(
              'activities.financingApplication.applicationFormScreen.businessDetails.detailsSectionTitle'
            )}
          />
          {!isEditMode && (
            <NakedButton
              label={formatMessage('activities.financingApplication.applicationFormScreen.businessDetails.editButton')}
              data-testid="edit-business-details-button"
              variant="secondary"
              onClick={onClickEdit}
            />
          )}
        </Group>
        <Form.TextField
          {...registerField('companyName')}
          labelProps={{
            label: formatMessage(
              'activities.financingApplication.applicationFormScreen.businessDetails.companyNameField.label'
            ),
          }}
          isViewMode={getIsViewModeForField('companyName')}
        />
        <Form.TextField
          {...registerField('legalBusinessName')}
          labelProps={{
            label: formatMessage(
              'activities.financingApplication.applicationFormScreen.businessDetails.legalBusinessName.label'
            ),
          }}
          isViewMode={getIsViewModeForField('legalBusinessName')}
        />
        <AddressSearchWidget
          {...registerField('legalBusinessAddress')}
          labelProps={{
            label: formatMessage(
              'activities.financingApplication.applicationFormScreen.businessDetails.legalBusinessAddress.label'
            ),
          }}
          error={
            formState.errors.legalBusinessAddress?.line1 || !!formState.errors.legalBusinessAddress
              ? {
                  message: formatMessage(
                    'activities.financingApplication.applicationFormScreen.businessDetails.legalBusinessAddress.validation.required'
                  ),
                }
              : undefined
          }
          isViewMode={getIsViewModeForField('legalBusinessAddress')}
        />
        <Form.PhoneField
          {...registerField('ownerPhone')}
          labelProps={{
            label: formatMessage(
              'activities.financingApplication.applicationFormScreen.businessDetails.ownerPhone.label'
            ),
          }}
          isViewMode={getIsViewModeForField('ownerPhone')}
        />
        <Form.ContentBox>
          <Divider />
        </Form.ContentBox>
        <Form.ContentBox>
          <Typography.SectionLabel
            label={formatMessage(
              'activities.financingApplication.applicationFormScreen.businessDetails.addDetailsSection.title'
            )}
          />
        </Form.ContentBox>
        <Form.AmountField
          {...registerField('annualRevenue')}
          placeholder={formatMessage(
            'activities.financingApplication.applicationFormScreen.businessDetails.annualRevenue.placeholder'
          )}
          labelProps={{
            label: formatMessage(
              'activities.financingApplication.applicationFormScreen.businessDetails.annualRevenue.label'
            ),
            tooltipProps: { label: 'Gross annual revenue as reported on annual tax returns' },
          }}
          isViewMode={isSubmitted && !isEditMode}
        />
        <IndustryTypeSelectWidget
          {...registerField('businessIndustry')}
          onCreateOption={({ value }) => {
            setBusinessIndursty(value as unknown as Industry);
          }}
          onChange={({ target }) => {
            setBusinessIndursty(target.value as unknown as Industry);
          }}
          isViewMode={isSubmitted && !isEditMode}
          placeholder={formatMessage(
            'activities.financingApplication.applicationFormScreen.businessDetails.businessIndustry.placeholder'
          )}
          error={
            form.formState.errors.businessIndustry
              ? {
                  message: formatMessage(
                    'activities.financingApplication.applicationFormScreen.businessDetails.businessIndustry.validation.required'
                  ),
                }
              : undefined
          }
          labelProps={{
            label: formatMessage(
              'activities.financingApplication.applicationFormScreen.businessDetails.businessIndustry.label'
            ),
          }}
          isRequired
        />
        <Form.Select
          isViewMode={isSubmitted && !isEditMode}
          {...registerField('businessType')}
          placeholder={formatMessage(
            'activities.financingApplication.applicationFormScreen.businessDetails.businessType.placeholder'
          )}
          emptyState={undefined}
          labelProps={{
            label: formatMessage(
              'activities.financingApplication.applicationFormScreen.businessDetails.businessType.label'
            ),
          }}
          options={businessTypeOptions}
        />
        {!!watchedBusinessType && watchedBusinessType !== ApplyForFinancingBusinessType.SoleProprietorship && (
          <Form.SecuredTextField
            {...registerField('employerId')}
            isViewMode={isSubmitted && !isEditMode}
            isRequired
            data-private
            labelProps={{
              label: formatMessage(
                'activities.financingApplication.applicationFormScreen.businessDetails.employerId.label'
              ),
            }}
          />
        )}
        <Button
          {...form.submitButtonProps}
          data-testid="save-and-continue-button"
          onClick={onSubmit}
          label={formatMessage(
            'activities.financingApplication.applicationFormScreen.businessDetails.submitButton.label'
          )}
        />
      </Form>
    </CollapsibleCard>
  );
};
