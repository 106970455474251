import React, { useState } from 'react';
import { HStack, Stack } from '@chakra-ui/react';
import { ActionsDropdownMenu, Currency, Text } from '@melio/penny';
import { sanitizeId } from '@melio/platform-analytics';
import { useBill, useInboxItems } from '@melio/platform-api';
import { Payment } from '@melio/platform-api';
import { MessageKey } from '@melio/platform-i18n';
import { usePermissions } from '@melio/platform-permissions';
import { usePartnerFeature } from '@melio/platform-provider';
import { useSystemMessage } from '@melio/platform-utils';

import { useRouter } from '@/hooks/router.hooks';
import { FormattedMessage, usePlatformIntl } from '@/translations/Intl';
import { PayDashboardTabs } from '@/types/payDashboard.types';
import { getPaymentDetailsTitlePostfix } from './PaymentDetailsTop.utils';

type PaymentDetailsTitleProps = {
  payment: Payment;
  menu?: React.ReactNode;
  isActionsEnabled: boolean;
};

export const PaymentDetailsTop = ({ payment, menu, isActionsEnabled }: PaymentDetailsTitleProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { refetch: refetchInboxItems } = useInboxItems({ enabled: false });
  const { formatMessage } = usePlatformIntl();
  const { replaceToPayDashboardTab } = useRouter();
  const { showMessage } = useSystemMessage();
  const [isMarkAsPaidEnabled] = usePartnerFeature('MarkAsPaid', false);
  const { can } = usePermissions();
  const hasUpdatePaymentBillsPermissions = payment.bills?.length
    ? payment.bills.every((bill) =>
        can({
          subject: 'bill',
          action: 'update',
          subjectData: {
            createdById: bill.createdById,
            vendor: {
              createdById: bill.vendor?.createdById,
              managedBy: bill.vendor?.managedBy,
            },
          },
        }),
      )
    : true;

  const { markAsPaid } = useBill({ id: payment.bills?.[0].id, enabled: false });

  const isMarkedAsPaid = isMarkAsPaidEnabled && payment.markedAsPaid;
  const titlePostfix = getPaymentDetailsTitlePostfix(payment);

  const clickOnMarkAsUnpaid = async () => {
    try {
      await markAsPaid({ isPaid: false });
      refetchInboxItems();
      replaceToPayDashboardTab(PayDashboardTabs.Inbox, payment.bills?.[0].id);
      showMessage({
        type: 'success',
        title: formatMessage('widgets.billDetails.markAsPaidBillDialog.toast.unmarkSuccess'),
      });
    } catch (e) {
      showMessage({
        type: 'error',
        title: formatMessage('widgets.billDetails.markAsPaidBillDialog.toast.unmarkError'),
      });
    }
  };

  return (
    <Stack
      flexDirection="column"
      gridGap={6}
      justifyContent={'space-between'}
      alignItems={'start'}
      width={'full'}
      spacing={0}
    >
      <Stack
        flexDirection="column"
        gridGap={1}
        justifyContent={'space-between'}
        alignItems={'start'}
        width={'full'}
        spacing={0}
      >
        <HStack w={'full'} justifyContent={'space-between'}>
          <Text textStyle="heading2Semi">{formatMessage('widgets.paymentDetails.paymentActivity')}</Text>
          {isMarkedAsPaid && hasUpdatePaymentBillsPermissions && isActionsEnabled && (
            <ActionsDropdownMenu
              data-testid="payment-completed-details-menu"
              label={formatMessage('widgets.billDetails.menu.button')}
              items={[
                {
                  label: formatMessage('widgets.paymentDetails.completed.action.markAsUnpaid'),
                  onClick: () => clickOnMarkAsUnpaid(),
                  dataTestId: 'payment-completed-details-menu-mark-as-unpaid',
                },
              ]}
              isOpen={isMenuOpen}
              onOpenChange={setIsMenuOpen}
            />
          )}
          {isActionsEnabled && menu}
        </HStack>

        <Text textStyle="body4" color="global.neutral.800">
          {formatMessage('widgets.paymentDetails.melioPaymentId', { paymentId: sanitizeId(payment.id) })}
        </Text>
      </Stack>
      <HStack w="full" justifyContent={'space-between'} alignItems="center">
        <Text data-testid="payment-details-top-subtitle" textStyle="body2Semi">
          <FormattedMessage id={`widgets.paymentDetails.${titlePostfix}` as MessageKey} />
        </Text>
        <Currency data-testid="payment-details-top-currency-amount" value={payment.amount} />
      </HStack>
    </Stack>
  );
};
