import { UnorderedList } from '@chakra-ui/react';
import { useAccountingPlatformName } from '@melio/ap-widgets';
import { PromotionalModal, Text, useBreakpoint } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useAccountingPlatforms } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useConfig, useMelioIntl } from '@melio/platform-provider';
import { useEffect } from 'react';

import { useVendorsTabData } from '../VendorsTab/useVendorsTabData';

type FirstTimeExperienceModalProps = {
  onClose: () => void;
  isOpen: boolean;
};

export const FirstTimeExperienceModal = withAnalyticsContext<FirstTimeExperienceModalProps>(
  ({ isOpen, onClose, setAnalyticsProperties }) => {
    const config = useConfig();
    const { track } = useAnalytics();
    const { isExtraSmallScreen } = useBreakpoint();
    const {
      assetType,
      imageUrl: firstTimeExperienceImageUrl,
      mobileImageUrl: firstTimeExperienceMobileImageUrl,
      videoLink,
    } = config.settings.newPayExperience.firstTimeExperience;
    const { data: vendorsResult, isLoading: isVendorTabDataLoading } = useVendorsTabData({ searchTerm: null });
    const { data: vendors = [] } = vendorsResult ?? {};
    const { activeAccountingPlatform, isLoading: accountingPlatformLoading } = useAccountingPlatforms();
    const activeAccountingPlatformName = useAccountingPlatformName(activeAccountingPlatform?.accountingSlug);
    const isExistingUser = vendors?.length > 0;
    const {
      primaryButtonText,
      tertiaryButtonText,
      headerText,
      mobileHeaderText,
      descriptionText,
      mobileDescriptionText,
    } = useFirstTimeExperienceTexts({
      isExistingUser,
      activeAccountingPlatformName,
    });

    setAnalyticsProperties({
      PageName: 'fte-guide',
      Flow: isExistingUser ? 'fte-existing-user' : 'fte-new-user',
      Intent: 'fte-guide',
      EntryPoint: 'login-page',
      media: assetType === 'image' ? 'image' : 'video',
    });

    useEffect(() => {
      isOpen && !isVendorTabDataLoading && track('Dashboard', 'View');
    }, [isOpen, isVendorTabDataLoading, track]);

    const handleOnClose = (buttonText: string) => {
      track('Dashboard', 'Click', {
        Cta: buttonText,
      });
      onClose();
    };

    const asset =
      assetType === 'image' ? (
        <img
          src={isExtraSmallScreen ? firstTimeExperienceMobileImageUrl : firstTimeExperienceImageUrl}
          alt="first time experience illustration"
        />
      ) : (
        <iframe src={videoLink} style={{ width: '100%', aspectRatio: '16/9' }} allowFullScreen></iframe>
      );

    const isDataLoading = isVendorTabDataLoading || accountingPlatformLoading;
    return (
      <PromotionalModal
        data-testid="pay-dashboard-first-time-experience-modal"
        isOpen={isOpen}
        isLoading={isDataLoading}
        onClose={onClose}
        asset={asset}
        header={isExtraSmallScreen ? mobileHeaderText : headerText}
        primaryButton={{
          label: primaryButtonText,
          variant: 'primary',
          onClick: () => handleOnClose(primaryButtonText),
        }}
        secondaryButton={{
          label: tertiaryButtonText,
          variant: 'tertiary',
          onClick: () => handleOnClose(primaryButtonText),
        }}
        assetAspectRatio={assetType === 'video' ? '16 / 9' : undefined}
      >
        <Text>{isExtraSmallScreen ? mobileDescriptionText : descriptionText}</Text>
      </PromotionalModal>
    );
  }
);

const getUserType = ({
  isExistingUser,
  hasConnectedAccountingPlatform,
  isSyncedUserModeEnabled,
}: {
  isExistingUser: boolean;
  hasConnectedAccountingPlatform: boolean;
  isSyncedUserModeEnabled: boolean;
}) => {
  if (hasConnectedAccountingPlatform && isSyncedUserModeEnabled) {
    return 'syncedUser';
  }

  if (isExistingUser) {
    return 'existingUser';
  }

  return 'newUser';
};

const useFirstTimeExperienceTexts = ({
  isExistingUser,
  activeAccountingPlatformName,
}: {
  isExistingUser: boolean;
  activeAccountingPlatformName: string;
}) => {
  const { formatMessage } = useMelioIntl();
  const [isPayDashboardTourEnabled] = useFeature<boolean>(FeatureFlags.IsPayDashboardTourEnabled, false);
  const [isSyncedUserModeEnabled] = useFeature<boolean>(
    FeatureFlags.FTEChangesForOrgsConnectedToAccountingPlatform,
    false
  );
  const hasConnectedAccountingPlatform = !!activeAccountingPlatformName;
  const labelByUserType = getUserType({ isExistingUser, hasConnectedAccountingPlatform, isSyncedUserModeEnabled });

  const headerText = formatMessage(`activities.payDashboard.firstTimeExperience.${labelByUserType}.modal.header`);
  const mobileHeaderText = formatMessage(
    `activities.payDashboard.firstTimeExperience.${labelByUserType}.modal.header.mobile`
  );

  const mobileDescriptionListText =
    labelByUserType === 'syncedUser'
      ? formatMessage('activities.payDashboard.firstTimeExperience.syncedUser.modal.description.mobile.list', {
          0: (
            <li>
              {formatMessage('activities.payDashboard.firstTimeExperience.syncedUser.modal.description.mobile.list.0')}
            </li>
          ),
          1: (
            <li>
              {formatMessage('activities.payDashboard.firstTimeExperience.syncedUser.modal.description.mobile.list.1', {
                activeAccountingPlatformName,
              })}
            </li>
          ),
          2: (
            <li>
              {formatMessage('activities.payDashboard.firstTimeExperience.syncedUser.modal.description.mobile.list.2')}
            </li>
          ),
          3: (
            <li>
              {formatMessage('activities.payDashboard.firstTimeExperience.syncedUser.modal.description.mobile.list.3')}
            </li>
          ),
        })
      : formatMessage(`activities.payDashboard.firstTimeExperience.${labelByUserType}.modal.description.mobile.list`, {
          0: (
            <li>
              {formatMessage(
                `activities.payDashboard.firstTimeExperience.${labelByUserType}.modal.description.mobile.list.0`
              )}
            </li>
          ),
          1: (
            <li>
              {formatMessage(
                `activities.payDashboard.firstTimeExperience.${labelByUserType}.modal.description.mobile.list.1`
              )}
            </li>
          ),
          2: (
            <li>
              {formatMessage(
                `activities.payDashboard.firstTimeExperience.${labelByUserType}.modal.description.mobile.list.2`
              )}
            </li>
          ),
        });
  const mobileDescriptionText = formatMessage(
    `activities.payDashboard.firstTimeExperience.${labelByUserType}.modal.description.mobile`,
    { list: <UnorderedList marginX={6}>{mobileDescriptionListText}</UnorderedList> }
  );

  const descriptionListText =
    labelByUserType === 'syncedUser'
      ? formatMessage('activities.payDashboard.firstTimeExperience.syncedUser.modal.description.list', {
          0: (
            <li>{formatMessage('activities.payDashboard.firstTimeExperience.syncedUser.modal.description.list.0')}</li>
          ),
          1: (
            <li>
              {formatMessage('activities.payDashboard.firstTimeExperience.syncedUser.modal.description.list.1', {
                activeAccountingPlatformName,
              })}
            </li>
          ),
          2: (
            <li>{formatMessage('activities.payDashboard.firstTimeExperience.syncedUser.modal.description.list.2')}</li>
          ),
          3: (
            <li>{formatMessage('activities.payDashboard.firstTimeExperience.syncedUser.modal.description.list.3')}</li>
          ),
        })
      : formatMessage(`activities.payDashboard.firstTimeExperience.${labelByUserType}.modal.description.list`, {
          0: (
            <li>
              {formatMessage(`activities.payDashboard.firstTimeExperience.${labelByUserType}.modal.description.list.0`)}
            </li>
          ),
          1: (
            <li>
              {formatMessage(`activities.payDashboard.firstTimeExperience.${labelByUserType}.modal.description.list.1`)}
            </li>
          ),
          2: (
            <li>
              {formatMessage(`activities.payDashboard.firstTimeExperience.${labelByUserType}.modal.description.list.2`)}
            </li>
          ),
        });
  const descriptionText = formatMessage(
    `activities.payDashboard.firstTimeExperience.${labelByUserType}.modal.description`,
    { list: <UnorderedList marginX={6}>{descriptionListText}</UnorderedList> }
  );

  const primaryButtonText = isPayDashboardTourEnabled
    ? formatMessage(`activities.payDashboard.firstTimeExperience.${labelByUserType}.modal.button.label.withTour`)
    : formatMessage(`activities.payDashboard.firstTimeExperience.${labelByUserType}.modal.button.label.withoutTour`);

  const tertiaryButtonText = formatMessage(`activities.payDashboard.firstTimeExperience.modal.cancelButton.label`);
  return {
    primaryButtonText,
    tertiaryButtonText,
    headerText,
    mobileHeaderText,
    mobileDescriptionText,
    descriptionText,
  };
};
