import { useAccountingPlatformName } from '@melio/ap-widgets';
import { Button, Group } from '@melio/penny';
import { useAccountingPlatforms } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';

type FirstBankAccountProps = {
  onClose: VoidFunction;
  onDone: VoidFunction;
};

export const FirstBankAccount = forwardRef<FirstBankAccountProps, 'div'>(({ onClose, onDone, ...props }, ref) => {
  const { formatMessage } = useMelioIntl();

  const { activeAccountingPlatform, isLoading: isActiveAccountingPlatformLoading } = useAccountingPlatforms();

  const accountingPlatformName = useAccountingPlatformName(activeAccountingPlatform?.accountingSlug);

  return (
    <NewSinglePaymentStepLayout
      data-component="LinkBankAccount"
      data-testid="link-bank-account-screen"
      isLoading={isActiveAccountingPlatformLoading}
      {...props}
      ref={ref}
      headerContent={
        <NewSinglePaymentStepLayout.Header>
          <NewSinglePaymentStepLayout.CloseButton onClick={onClose} />
        </NewSinglePaymentStepLayout.Header>
      }
    >
      <NewSinglePaymentStepLayout.Title>
        {formatMessage('activities.FirstBankAccount.screens.isFirstBankAccount.title', {
          platformName: accountingPlatformName,
        })}
      </NewSinglePaymentStepLayout.Title>
      <NewSinglePaymentStepLayout.Description>
        {formatMessage('activities.linkBankAccount.screens.linkBankAccount.description', {
          platformLabel: accountingPlatformName,
        })}
      </NewSinglePaymentStepLayout.Description>
      <NewSinglePaymentStepLayout.Content>
        <Group variant="vertical" width="full">
          <Button
            size="large"
            label={formatMessage('activities.linkBankAccount.screens.linkBankAccount.button')}
            variant="primary"
            onClick={onDone}
          />
        </Group>
      </NewSinglePaymentStepLayout.Content>
    </NewSinglePaymentStepLayout>
  );
});
