import { FormSelectNewOption } from '@melio/penny';
import { AccountingPlatformItem, useAccountingPlatformItems, useAccountingPlatforms } from '@melio/platform-api';

export type ItemOption = FormSelectNewOption<AccountingPlatformItem['id']>;

/**
 * Hook to get accounting platform items and map them to options for the select component.
 */
export const useAccountingPlatformItemSelect = (options?: {
  testIdPrefix?: string;
}): { isFetched: boolean; options: ItemOption[]; items?: AccountingPlatformItem[] } => {
  const { activeAccountingPlatform, hasAccountingPlatform, ...accountingPlatformsQuery } = useAccountingPlatforms();
  const itemsQuery = useAccountingPlatformItems({
    accountingPlatformId: activeAccountingPlatform?.id,
    enabled: hasAccountingPlatform,
  });

  const itemOptions = itemsToOptions(itemsQuery.data, options?.testIdPrefix || '');

  const isFetched = accountingPlatformsQuery.isFetched && itemsQuery.isFetched;

  return {
    isFetched,
    options: itemOptions,
  };
};

function itemsToOptions(items: AccountingPlatformItem[] = [], testIdPrefix: string): ItemOption[] {
  return items
    ? items.map(({ id, name }) => ({
        label: name,
        value: id,
        testId: `${testIdPrefix}-option-${id}`,
      }))
    : [];
}
