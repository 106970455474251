import { ModalProps, StatusModal, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';

type AmountsMismatchModalProps = Pick<ModalProps, 'isOpen'> & {
  onAdd: VoidFunction;
  onRemove: VoidFunction;
  mismatchedValue: string;
  billId?: string;
};

export const AmountsMismatchModal = ({
  isOpen,
  onAdd,
  onRemove,
  mismatchedValue,
  billId,
}: AmountsMismatchModalProps) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();

  useEffect(() => {
    if (isOpen) {
      track('Bill', 'Status', {
        PageName: 'issue-scanning-line-items',
        Intent: 'upload-bill',
        Flow: 'bill-upload',
        ErrorType: 'amount-mismatch',
        BillId: billId,
      });
    }
  }, [isOpen, billId, track]);

  const handleAdd = () => {
    track('Bill', 'Click', {
      PageName: 'issue-scanning-line-items',
      Intent: 'upload-bill',
      Flow: 'bill-upload',
      Cta: 'auto-add-the-difference',
    });
    onAdd();
  };
  const handleRemove = () => {
    track('Bill', 'Click', {
      PageName: 'issue-scanning-line-items',
      Intent: 'upload-bill',
      Flow: 'bill-upload',
      Cta: 'remove-line-items',
    });
    onRemove();
  };
  return (
    <StatusModal
      variant="warning"
      isOpen={isOpen}
      onClose={onAdd}
      data-testid="amounts-mismatch-modal"
      header={formatMessage('widgets.ReviewScannedInvoice.amountsMismatchModal.title')}
      primaryButton={{
        onClick: handleAdd,
        label: formatMessage('widgets.ReviewScannedInvoice.amountsMismatchModal.addButtonText'),
        variant: 'primary',
      }}
      secondaryButton={{
        onClick: handleRemove,
        label: formatMessage('widgets.ReviewScannedInvoice.amountsMismatchModal.removeButtonText'),
        variant: 'secondary',
      }}
    >
      <Text>
        {formatMessage('widgets.ReviewScannedInvoice.amountsMismatchModal.content', { diff: mismatchedValue })}
      </Text>
    </StatusModal>
  );
};
