import { RefObject, useCallback, useEffect, useState } from 'react';

export const useScrollToIfVisible = <T extends HTMLDivElement>(ref: RefObject<T>, isVisible?: boolean) => {
  const [shouldScroll, setShouldScroll] = useState(false);
  const scroll = useCallback(() => {
    ref?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [ref]);

  useEffect(() => {
    if (shouldScroll) {
      scroll();
      setShouldScroll(false);
    }
  }, [isVisible, scroll, shouldScroll]);

  const scrollTo = () => {
    setShouldScroll(true);
  };

  return scrollTo;
};
