import { Currency } from '@melio/international-payments-utils';
import { Container, Loader } from '@melio/penny';
import { useAnalyticsContext } from '@melio/platform-analytics';
import {
  InternationalAccountDeliveryMethod,
  OrganizationVerifiedPersona,
  OrganizationVerifiedPersonaTypeEnum,
  useVendor,
  useVerifiedPersonas,
  Vendor,
} from '@melio/platform-api';
import { useEffect, useState } from 'react';

import { DeliveryMethodFlowOrigin } from '../../delivery-methods/types';
import { AddFxDeliveryMethodBusinessDetailsActivity } from './steps/add-fx-business-details';
import { FxVendorAccountActivity } from './steps/fx-vendor-account';
import { FxVendorAccountFieldsFormFields } from './steps/fx-vendor-account/fxBankDetailsForm';
import { AddFxDeliveryMethodAnalyticsDataType, InternationalFxStepsEnum, SubmissionTargetType } from './types';

const useFxDMSteps = (verifiedPersonas: OrganizationVerifiedPersona[] | undefined) => {
  const [currentStep, setCurrentStep] = useState<InternationalFxStepsEnum>();

  useEffect(() => {
    if (verifiedPersonas) {
      const isFxAccountHolder = verifiedPersonas.some((verifiedPerson) =>
        verifiedPerson.types.includes(OrganizationVerifiedPersonaTypeEnum.FxAccountHolder)
      );
      if (isFxAccountHolder) {
        setCurrentStep(InternationalFxStepsEnum.VendorAccount);
      } else {
        setCurrentStep(InternationalFxStepsEnum.Ubo);
      }
    }
  }, [verifiedPersonas]);
  return { currentStep, setCurrentStep };
};

export type AddFxDeliveryMethodActivityProps = {
  vendorId: Vendor['id'];
  onBack: VoidFunction;
  onClose: VoidFunction;
  onError?: ErrorFunction;
  onDone: (data: InternationalAccountDeliveryMethod, target?: SubmissionTargetType) => void;
  origin?: DeliveryMethodFlowOrigin;
  fxBankDetailsDefaultValues?: Partial<FxVendorAccountFieldsFormFields>;
};

export const AddFxDeliveryMethodActivity = ({
  vendorId,
  onBack: onFirstStepBack,
  onClose,
  fxBankDetailsDefaultValues,
  onDone,
}: AddFxDeliveryMethodActivityProps) => {
  const [analyticsData, setAnalyticsData] = useState<AddFxDeliveryMethodAnalyticsDataType>();
  useAnalyticsContext({ globalProperties: { DeliveryMethodType: 'fx', ...analyticsData } });

  const onVendorAccountDone = (data: InternationalAccountDeliveryMethod) => {
    setAnalyticsData({
      foreignCurrency: data.details.currency,
      fxCountrySelection: data.details.address.countryCode,
    });
    onDone(data);
  };

  const { data: vendor, isLoading: isVendorLoading } = useVendor({ id: vendorId });
  const { data: verifiedPersonas, isLoading: isUboLoading } = useVerifiedPersonas();
  const { currentStep, setCurrentStep } = useFxDMSteps(verifiedPersonas);

  const onUboDone = () => {
    setCurrentStep(InternationalFxStepsEnum.VendorAccount);
  };

  const isLoading = isUboLoading || isVendorLoading;
  if (isLoading || !currentStep) {
    return (
      <Container height="full" alignItems="center" justifyContent="center">
        <Loader />
      </Container>
    );
  }

  const _fxBankDetailsDefaultValues = {
    ...fxBankDetailsDefaultValues,
    foreignCurrency: fxBankDetailsDefaultValues?.foreignCurrency || (vendor?.currency as Currency | undefined),
  };

  switch (currentStep) {
    default:
    case InternationalFxStepsEnum.Ubo:
      return <AddFxDeliveryMethodBusinessDetailsActivity onDone={onUboDone} onClose={onClose} />;
    case InternationalFxStepsEnum.VendorAccount:
      return (
        <FxVendorAccountActivity
          fxBankDetailsDefaultValues={_fxBankDetailsDefaultValues}
          onBack={onFirstStepBack}
          onClose={onClose}
          vendorId={vendorId}
          onDone={onVendorAccountDone}
          vendorName={vendor?.name}
          shouldShowFailedCreationBanner={false}
        />
      );
  }
};
