import { useMelioIntl } from '@melio/ar-domain';
import { useAnalyticsView } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';

import { CustomerFormDialog, CustomerFormDialogProps } from '../components';

export type UpdateCustomerScreenProps = Pick<
  CustomerFormDialogProps,
  'onSubmit' | 'isLoading' | 'isSaving' | 'isOpen' | 'onClose' | 'defaultValues'
> & { error?: ARPlatformError };

export const UpdateCustomerScreen = forwardRef<UpdateCustomerScreenProps>((props, ref) => {
  const { formatMessage } = useMelioIntl();

  useAnalyticsView('Customer', true, true, {
    ProductName: 'ar',
    PageName: 'edit-customer-details',
    Flow: 'edit-customer',
    Intent: 'edit-customer',
  });

  return (
    <CustomerFormDialog
      header={formatMessage('ar.modals.activities.customerManagement.modal.edit.header')}
      primaryButtonLabel={formatMessage('ar.modals.activities.customerManagement.modal.edit.buttons.primary.label')}
      secondaryButtonLabel={formatMessage('ar.modals.activities.customerManagement.modal.edit.buttons.secondary.label')}
      data-testid="update-customer-modal"
      ref={ref}
      {...props}
    />
  );
});
