import { Container, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export const EmptySearchResults = () => {
  const { formatMessage } = useMelioIntl();
  return (
    <Container textAlign="center">
      <Text textStyle="body3" color="global.neutral.800">
        {formatMessage('activities.batchPayments.screens.paymentIntentsTable.fundingSourceSelectCell.search.noResults')}
      </Text>
    </Container>
  );
};
