import { useAnalytics } from '@melio/platform-analytics';
import { RoleUniqueNames } from '@melio/platform-api';

export const useEditCollaboratorAnalytics = () => {
  const { track } = useAnalytics();

  const commonEventProps = {
    Intent: 'edit-role',
    PageName: 'edit-role-for-collaborator',
  };
  const submit = ({ collaboratorNewRoleUniqueName }: { collaboratorNewRoleUniqueName: RoleUniqueNames }) =>
    track('Setting', 'Click', {
      ...commonEventProps,
      Cta: 'apply-role',
      ManagedUserRole: collaboratorNewRoleUniqueName,
    });

  const close = (reason?: string) => (reason ? track('Setting', 'Click', { ...commonEventProps, Cta: reason }) : null);

  return {
    submit,
    close,
  };
};
