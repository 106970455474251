import { Group, SelectionCard } from '@melio/penny';
import { ContextualOnboardingButtons, useConfig, useMelioIntl } from '@melio/platform-provider';
import { JSX } from 'react';

import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';

type ContextualOnboardingScreenProps = {
  onQuickBooksOnlineClick: VoidFunction;
  onGmailSyncClick?: VoidFunction;
  onFirstPaymentClick: VoidFunction;
  onExitClick: (isClose?: boolean) => void;
};

export const ContextualOnboardingScreen = ({
  onQuickBooksOnlineClick,
  onGmailSyncClick,
  onFirstPaymentClick,
  onExitClick,
}: ContextualOnboardingScreenProps) => {
  const { formatMessage } = useMelioIntl();
  const {
    settings: { contextualOnboarding },
  } = useConfig();

  const buttons: Record<ContextualOnboardingButtons, JSX.Element | undefined> = {
    [ContextualOnboardingButtons.QuickBooksOnline]: (
      <SelectionCard
        data-testid="contextual-onboarding-screen-quickbooks-button"
        icon="quickbooks"
        key="quickbooks"
        mainLabelProps={{ label: formatMessage('screens.contextualOnboarding.quickbooksButton.label') }}
        descriptionProps={{ label: formatMessage('screens.contextualOnboarding.quickbooksButton.description') }}
        onClick={onQuickBooksOnlineClick}
      />
    ),
    [ContextualOnboardingButtons.GmailSync]: onGmailSyncClick ? (
      <SelectionCard
        data-testid="contextual-onboarding-screen-gmail-sync-button"
        icon="gmail"
        key="gmail-sync"
        mainLabelProps={{ label: formatMessage('screens.contextualOnboarding.gmailSyncButton.label') }}
        descriptionProps={{ label: formatMessage('screens.contextualOnboarding.gmailSyncButton.description') }}
        onClick={onGmailSyncClick}
      />
    ) : undefined,
    [ContextualOnboardingButtons.FirstPayment]: (
      <SelectionCard
        data-testid="contextual-onboarding-screen-first-payment-button"
        icon="pay"
        key="firstPayment"
        mainLabelProps={{ label: formatMessage('screens.contextualOnboarding.firstPayment.label') }}
        descriptionProps={{ label: formatMessage('screens.contextualOnboarding.firstPayment.description') }}
        onClick={onFirstPaymentClick}
      />
    ),
  };

  return (
    <NewSinglePaymentStepLayout
      data-component="ContextualOnboardingScreen"
      data-testid="contextual-onboarding-screen"
      headerContent={
        <NewSinglePaymentStepLayout.Header>
          <NewSinglePaymentStepLayout.CloseButton
            data-testid="contextual-onboarding-screen-exit-button"
            onClick={() => onExitClick(true)}
          />
        </NewSinglePaymentStepLayout.Header>
      }
    >
      <NewSinglePaymentStepLayout.Content>
        <Group variant="vertical" spacing="xxl">
          <Group variant="vertical">
            <NewSinglePaymentStepLayout.Title>
              {formatMessage('screens.contextualOnboarding.header.title')}
            </NewSinglePaymentStepLayout.Title>
            <NewSinglePaymentStepLayout.Description>
              {formatMessage('screens.contextualOnboarding.header.description')}
            </NewSinglePaymentStepLayout.Description>
          </Group>
          <Group variant="vertical" data-testid="contextual-onboarding-buttons">
            {contextualOnboarding.buttons.filter((button) => !!buttons[button]).map((button) => buttons[button])}
          </Group>
          <NewSinglePaymentStepLayout.NakedNextButton
            data-testid="contextual-onboarding-screen-skip-button"
            label={formatMessage('screens.contextualOnboarding.button')}
            onClick={() => onExitClick(false)}
          />
        </Group>
      </NewSinglePaymentStepLayout.Content>
    </NewSinglePaymentStepLayout>
  );
};

ContextualOnboardingScreen.displayName = 'ContextualOnboardingScreen';
