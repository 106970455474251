import { useMelioQueryClient, useModelApi, UseModelApiProps, useUpdateMutation } from '@melio/api-client';
import { AccountingPlatformsApiClient } from '@melio/platform-api-axios-client';

export type UseAccountingPlatformVerifyQuickBooksDesktop = UseModelApiProps<
  typeof AccountingPlatformsApiClient.verifyQuickbooksDesktopConnection
> & {
  accountingPlatformId?: string;
};

export const useAccountingPlatformVerifyQuickBooksDesktop = (props: UseAccountingPlatformVerifyQuickBooksDesktop) => {
  const { accountingPlatformId } = props;
  const queryClient = useMelioQueryClient();
  const onSuccess = () => queryClient.invalidateQueries('AccountingPlatformsApi');
  const query = useModelApi<typeof AccountingPlatformsApiClient.verifyQuickbooksDesktopConnection>({
    ...props,
    queryKey: ['AccountingPlatformsApi', 'verifyQuickBooksDesktopConnection', accountingPlatformId],
    queryFn: () => AccountingPlatformsApiClient.verifyQuickbooksDesktopConnection(accountingPlatformId as string),
  });

  const verifyQuickBooksDesktop = useUpdateMutation(
    AccountingPlatformsApiClient.verifyQuickbooksDesktopConnection,
    query.queryKey,
    {
      onSuccess,
    }
  );
  const verifyQuickBooksDesktopData = verifyQuickBooksDesktop.data;

  return {
    ...query,
    verifyQuickBooksDesktop: verifyQuickBooksDesktop.createCallback(props.id as string),
    verifyQuickBooksDesktopData,
  };
};
