import { ApiKeys, InternationalApiClient } from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useQuery } from 'react-query';

export const useInternationalCurrencies = () =>
  useQuery({
    queryKey: [ApiKeys.InternationalApi, 'getInternationalCurrencies'],
    queryFn: async () => {
      const result = await InternationalApiClient.getInternationalCurrencies();
      return result.data.data;
    },
  });
