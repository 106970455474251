import { NakedButton, SectionBanner, Text } from '@melio/penny';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { useFundingSources } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useState } from 'react';

import { AddPaymentMethodDrawer } from '../../components/PaymentMethodDrawer/PaymentMethodDrawer.activity';
import { AnalyticsPaymentDrawerActionType } from '../../types';

type AccountantsAddPaymentMethodBannerProps = {
  isDrawerDefaultOpen?: boolean;
  isChangingDisabled: boolean;
  fundingSourceId: string | null;
  setFundingSourceId: (fundingSourceId: string | null) => void;
  onDrawerClose?: VoidFunction;
};

export const PaymentMethodBanner = withAnalyticsContext<AccountantsAddPaymentMethodBannerProps>(
  ({
    isDrawerDefaultOpen,
    isChangingDisabled,
    fundingSourceId,
    setFundingSourceId,
    onDrawerClose,
    setAnalyticsProperties,
  }) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(!!isDrawerDefaultOpen);
    const { formatMessage } = useMelioIntl();
    const { track } = useAnalytics();
    const { data: fundingSources } = useFundingSources();

    setAnalyticsProperties({
      PageName: 'add-payment-method',
      Flow: 'create-a-new-client-account',
      NumberPMExist: fundingSources?.length || 0,
    });

    useAnalyticsView('Organization', isDrawerOpen, true, {
      Intent: 'add-client-billing-details',
    });

    const toggleAddFundingSourceDrawer = ({
      isOpen,
      action,
    }: {
      isOpen: boolean;
      action?: AnalyticsPaymentDrawerActionType;
    }) => {
      setIsDrawerOpen(isOpen);

      if (action !== 'continue') {
        track('Organization', 'Click', { Cta: action, Intent: action });
      }
    };

    const onSelectFundingSource = (fundingSourceId: string | null) => {
      setFundingSourceId(fundingSourceId);
      onDrawerClose?.();

      const fundingSource = fundingSources?.find((fundingSource) => fundingSource.id === fundingSourceId);

      track('Organization', 'Click', {
        Cta: 'continue',
        ChosenPaymentMethod: fundingSource?.type,
        Intent: 'invite–client',
      });
    };

    const handleClose = (action?: AnalyticsPaymentDrawerActionType) => {
      toggleAddFundingSourceDrawer({ isOpen: false, action });
      onDrawerClose?.();
    };

    const bannerText = fundingSourceId
      ? 'activities.accountants.paymentMethodCard.existingPaymentMethod.label'
      : 'activities.accountants.paymentMethodCard.addPaymentMethod.label';

    const linkText = fundingSourceId
      ? 'activities.accountants.paymentMethodCard.existingPaymentMethod.change.link'
      : 'activities.accountants.paymentMethodCard.addPaymentMethod.link';

    const Link = !isChangingDisabled && (
      <NakedButton
        data-testid="change-payment-method-link"
        label={formatMessage(linkText)}
        size="medium"
        variant="primary"
        onClick={() => toggleAddFundingSourceDrawer({ isOpen: true })}
      />
    );

    return (
      <>
        <SectionBanner
          isCompact
          action={Link}
          description={<Text textStyle="body3">{formatMessage(bannerText)}</Text>}
          icon="error"
          size="small"
          variant="neutral"
        />
        <AddPaymentMethodDrawer
          fundingSourceId={fundingSourceId}
          isOpen={isDrawerOpen}
          setFundingSourceId={onSelectFundingSource}
          onClose={handleClose}
        />
      </>
    );
  }
);
