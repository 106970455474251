import { usePaymentIntents } from '@melio/platform-api';
import { useLocation, useNavigate, useOneTimeEffect } from '@melio/platform-utils';

import { NewSinglePaymentStepLayout } from '../../NewSinglePaymentStepLayout';

type InitPaymentFlowActivityStepProps = {
  paymentId: string;
  paymentIntentId?: string;
  startActivity: () => void;
};

export const InitPaymentFlowActivityStep = ({
  paymentId,
  paymentIntentId,
  startActivity,
}: InitPaymentFlowActivityStepProps) => {
  const { createFromPayment } = usePaymentIntents({ enabled: false });
  const navigate = useNavigate();
  const location = useLocation();

  useOneTimeEffect(
    () => {
      const createPaymentIntent = async () => {
        const paymentIntent = await createFromPayment({ paymentId });
        const updatedLoc = location.pathname.replace(paymentId, `${paymentId}/${paymentIntent.id}`);
        navigate(updatedLoc, { replace: true, state: location.state });
      };
      createPaymentIntent();
    },
    () => !paymentIntentId,
    [paymentId, paymentIntentId]
  );

  useOneTimeEffect(
    () => {
      startActivity();
    },
    () => !!paymentIntentId,
    [paymentIntentId]
  );

  return <NewSinglePaymentStepLayout isLoading />;
};
