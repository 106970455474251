import { AccountingPlatformParseAuthParamsResponseData } from '@melio/platform-api';

export enum AccountingPlatformSyncConnectionErrorEnum {
  AccountPlatformConnectToDifferentMelioAccount,
  GeneralError,
  AccessDenied,
  InvalidSubscription,
  InvalidState,
}

export enum SyncFlowEnum {
  Setting,
  NewBill,
  PayBoard,
  ContextualOnboarding,
  AccountingFirmAddClient,
}

export type SyncAppStateType = {
  flow: SyncFlowEnum;
  isQuickBooksDesktop: boolean;
  accountingPlatformId?: string;
};

export type ExternalOrganizations = AccountingPlatformParseAuthParamsResponseData['companies'];

export const enum ActivityStepsEnum {
  Initial = 'initial',
  SelectOrganization = 'select-organization',
  ConnectToQuickBooksDesktop = 'connect-to-quickbooks-desktop',
  ConfirmConnect = 'confirm-connect',
  Sync = 'sync',
  SyncError = 'sync-error',
}

export type Steps =
  | ActivityStepsEnum.Initial
  | ActivityStepsEnum.SelectOrganization
  | ActivityStepsEnum.ConnectToQuickBooksDesktop
  | ActivityStepsEnum.ConfirmConnect
  | ActivityStepsEnum.Sync
  | ActivityStepsEnum.SyncError;

export type InitialStepProps = {
  isQuickBooksDesktop?: boolean;
  isMultiOrgsAvailableToConnect?: boolean;
  isRegistrableQuickBooksDesktopOrganizationExists?: boolean;
};

export type ConnectionScreenCtaType =
  | 'cancel'
  | 'verify-connection'
  | 'download-file'
  | 'copy-password'
  | 'connecting-melio-and-qbdt-guide'
  | 'connection-guide';
