import { Tier } from '@melio/platform-api-axios-client';

export const TIERS_ORDER = {
  [Tier.MelioBasic]: 0,
  [Tier.MelioPlus]: 1,
  [Tier.MelioAccountingClientCore]: 1,
  [Tier.MelioPro]: 2,
  [Tier.MelioAccountingClientBoost]: 2,
  [Tier.MelioVip]: 3,
  [Tier.FiservApBasic]: 0,
  [Tier.FiservApPro]: 1,
  [Tier.FiservApArBasic]: 2,
  [Tier.FiservApArPro]: 3,
};
