import { Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { usePartnerGroups } from '../../../../hooks';

type ContextualPlanPriceSectionProps = {
  price: number;
  additionalUserPrice: number;
};

export const ContextualPlanPriceSection = ({ price, additionalUserPrice }: ContextualPlanPriceSectionProps) => {
  const { isFiservPartner } = usePartnerGroups();
  const { formatCurrency, formatMessage } = useMelioIntl();

  return (
    <Group variant="vertical">
      {price > 0 ? (
        <Group variant="vertical" spacing="xs">
          <Group alignItems="center" spacing="xxs">
            <Text textStyle="heading3Semi">{formatCurrency(price, undefined, { notation: 'compact' })}</Text>
            <Text textStyle="body3">{formatMessage(`activities.subscription.contextualPlans.billing.title`)}</Text>
          </Group>
          <Text textStyle="body3">
            {formatMessage(`activities.subscription.contextualPlans.billing.additionalUsers`, {
              additionalUserPrice: formatCurrency(additionalUserPrice, undefined, { notation: 'compact' }),
            })}
          </Text>
        </Group>
      ) : (
        <>
          <Text textStyle="heading3Semi">
            {formatMessage('activities.subscription.contextualPlans.billing.price.free')}
          </Text>

          {!isFiservPartner && (
            <Text textStyle="body4" color="global.neutral.800">
              {formatMessage(`activities.subscription.contextualPlans.billing.description.free`)}
            </Text>
          )}
        </>
      )}
    </Group>
  );
};
