import { useModelApi } from '@melio/api-client';
// eslint-disable-next-line no-restricted-imports
import { SubscriptionsApiClient } from '@melio/platform-api-axios-client';

export const useSubscriptionBilling = () =>
  useModelApi({
    id: 'billing',
    queryKey: ['SubscriptionsApi'],
    queryFn: () => SubscriptionsApiClient.getSubscriptionsBillings(),
  });
