import { ComponentThemeType } from '@/cl/theme/theme.hooks';

export const FooterTheme: ComponentThemeType = () => ({
  baseStyle: {
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '16px',
    },
    leftContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    rightContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
  },
});
