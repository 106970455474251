import { Container, Group, Layout, useToast } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { Logger } from '@melio/platform-logger';
import { useSubscription } from '@melio/subscriptions';
import { useSubscriptionMe } from '@melio/subscriptions/src/api';

import { useCreateAccountantFirmClient } from '../../api/entities';
import { FlowHeader } from '../../components/FlowHeader/FlowHeader.component';
import { SubscriptionBillingPayor } from '../../types';
import { useAccountantsRoutes } from '../../utils/useAccountantsRoutes';
import { NewFirmClientForm } from './components/NewClientForm/NewClientForm';
import { NewFirmClientFormFields } from './types';

export const NewFirmClient = withAnalyticsContext<{ onClose: VoidFunction }>(({ onClose, setAnalyticsProperties }) => {
  setAnalyticsProperties({
    PageName: 'create-a-new-client-account',
    Flow: 'create-a-new-client-account',
  });
  const { formatMessage } = useMelioIntl();
  const { mutateAsync: createAccountantFirmClient, isLoading: isLoadingCreateAccountFirmClient } =
    useCreateAccountantFirmClient();
  const { toast } = useToast();
  const { goToClientCreatedSuccessScreen } = useAccountantsRoutes();
  const firmSubscription = useSubscription();
  const { update: updateSubscription, isMutating: isSubscriptionMutating } = useSubscriptionMe({ enabled: false });
  const { track } = useAnalytics();

  const handleSubmit = async (data: NewFirmClientFormFields) => {
    const {
      businessName,
      businessDBA,
      clientEmailAddress,
      clientFirstName,
      clientLastName,
      subscriptionPlanId,
      shouldInviteClient,
      whoPays,
      fundingSourceId,
    } = data;

    if (!subscriptionPlanId) {
      return;
    }

    const eventProps = {
      PlanId: subscriptionPlanId,
      FundingSourceId: fundingSourceId,
      BillingCycle: 'monthly',
    };

    try {
      if (fundingSourceId && !firmSubscription?.fundingSourceId) {
        await updateSubscription({ fundingSourceId });
      }
      const response = await createAccountantFirmClient({
        company: {
          name: businessName,
          legalName: businessDBA,
        },
        ...(shouldInviteClient &&
          clientFirstName &&
          clientLastName &&
          clientEmailAddress && {
            user: {
              email: clientEmailAddress,
              firstName: clientFirstName,
              lastName: clientLastName,
            },
          }),
        subscription: {
          planId: subscriptionPlanId,
          fundingSourceId: whoPays === SubscriptionBillingPayor.AccountingFirm ? fundingSourceId : undefined,
        },
      });
      track('Organization', 'Status', {
        StatusType: 'success',
        ...eventProps,
      });
      goToClientCreatedSuccessScreen({
        invited: shouldInviteClient,
        orgId: response.data.organizationId,
        company: businessName,
      });
    } catch (error: unknown) {
      track('Organization', 'Status', {
        StatusType: 'failure',
        ...eventProps,
      });
      toast({
        type: 'error',
        title: formatMessage('activities.accountants.activities.newFirmClient.create.error'),
      });
      Logger.log(`Error while trying to create new client.`);
    }
  };

  return (
    <Layout variant="full">
      <Container justifyContent="center" height="full">
        <Group spacing="m" variant="vertical" width={{ xs: '100%', s: '800px' } as never}>
          <FlowHeader
            title={formatMessage('activities.accountants.newClient.form.header.text')}
            leaveConfirmationModal={{
              title: formatMessage('activities.accountants.inviteClient.leaveConfirmation.title'),
              description: formatMessage('activities.accountants.inviteClient.leaveConfirmation.description'),
              cancelButtonText: formatMessage(
                'activities.accountants.inviteClient.leaveConfirmation.cancelButton.text'
              ),
            }}
            onClose={onClose}
          />
          <NewFirmClientForm
            onSubmit={handleSubmit}
            isSaving={isLoadingCreateAccountFirmClient || isSubscriptionMutating}
          />
        </Group>
      </Container>
    </Layout>
  );
});
