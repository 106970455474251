import { useCreateMutation, useMelioQueryClient, useModelApi } from '@melio/api-client';
import {
  MelioPromise,
  PostVendorsVendorIdTaxpayerIdentificationRequestsRequest,
  TaxpayerIdentificationRequest,
  TaxpayerIdentificationRequestsApiClient,
  Vendor,
} from '@melio/platform-api-axios-client';

export type UseTaxPayerInfoProps = { vendorId: Vendor['id'] };

export const useTaxPayerInfoDetailsRequest = ({ vendorId, ...props }: UseTaxPayerInfoProps) => {
  const queryClient = useMelioQueryClient();

  const query = useModelApi({
    ...props,
    queryKey: ['TaxpayerIdentificationRequestsApi', vendorId],
    queryFn: async () => Promise.resolve({ data: {} }) as MelioPromise<TaxpayerIdentificationRequest>,
    createFn: (
      params: PostVendorsVendorIdTaxpayerIdentificationRequestsRequest & {
        vendorId?: Vendor['id'];
      }
    ) => {
      const { vendorId: vendorIdFromParams, ...restParams } = params;

      return TaxpayerIdentificationRequestsApiClient.postVendorsVendorIdTaxpayerIdentificationRequests(
        vendorIdFromParams ?? vendorId,
        restParams
      );
    },
    onCreate: () => queryClient.invalidateQueries('VendorsApi', 'model', vendorId),
  });

  const resendTaxPayerInfoRequest = useCreateMutation(
    (taxpayerIdentificationRequestId: string) =>
      // eslint-disable-next-line max-len
      TaxpayerIdentificationRequestsApiClient.postVendorsVendorIdTaxpayerIdentificationRequestsTaxpayerIdentificationRequestIdResend(
        vendorId,
        taxpayerIdentificationRequestId
      ),
    query.queryKey,
    {
      onSuccess: () => queryClient.invalidateQueries('VendorsApi', 'model', vendorId),
    }
  );

  const cancelTaxPayerInfoRequest = useCreateMutation(
    (taxpayerIdentificationRequestId: string) =>
      // eslint-disable-next-line max-len
      TaxpayerIdentificationRequestsApiClient.postVendorsVendorIdTaxpayerIdentificationRequestsTaxpayerIdentificationRequestIdCancel(
        vendorId,
        taxpayerIdentificationRequestId
      ),
    query.queryKey,
    {
      onSuccess: () => queryClient.invalidateQueries('VendorsApi', 'model', vendorId),
    }
  );

  return {
    ...query,
    resend: resendTaxPayerInfoRequest.mutateAsync,
    cancel: cancelTaxPayerInfoRequest.mutateAsync,
  };
};
