import { billSubscriptionsApi } from '@/api/apiClients';
import { getApiHeaders } from '@/api/utilities';

export const cancelSubscriptionById = (id: string) =>
  billSubscriptionsApi
    .postBillSubscriptionBillSubscriptionIdCancel(
      {
        billSubscriptionId: id,
      },
      getApiHeaders(),
    )
    .then((res) => {
      return res.data.data.id;
    });
