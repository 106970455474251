import { useMelioIntl } from '@melio/ar-domain';
import { Group, Link, Text } from '@melio/penny';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

export const GetStartedPrivacyPolicy = forwardRef((_props, ref) => {
  const { formatMessage } = useMelioIntl();
  const { privacyPolicyUrl } = useConfig().settings;

  return (
    <Group>
      <Text ref={ref} textStyle="body4">
        {formatMessage('ar.onboarding.components.getStarted.privacyPolicy.text', {
          privacyPolicyLink: (
            <Link
              color="secondary"
              href={privacyPolicyUrl}
              label={formatMessage('ar.onboarding.components.getStarted.privacyPolicy.link.text')}
              newTab
            />
          ),
        })}
      </Text>
    </Group>
  );
});
