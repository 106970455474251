import { Container, Divider, Group, IconButton } from '@melio/penny';

import { useSubscriptionRouter } from '../../utils';

export const SubscriptionSelectFundingSourceHeader = () => {
  const { goToSubscriptionPlansSettings } = useSubscriptionRouter();

  return (
    <>
      <Container paddingX="s" paddingY="s">
        <Group justifyContent="flex-end" width="full">
          <IconButton
            icon="close"
            variant="naked"
            size="medium"
            aria-label="Go back"
            onClick={goToSubscriptionPlansSettings}
          />
        </Group>
      </Container>
      <Divider />
    </>
  );
};
