import { VendorDetailsFormFields } from '@/types/vendors.types';

export const getUpdateVendorPayload = (data: VendorDetailsFormFields) => {
  const { companyName, fullName, email, phone, accountNumber } = data;

  return {
    name: companyName || '',
    contact: {
      name: fullName || '',
      email: email || '',
      phoneNumber: phone || '',
    },
    accountNumber: accountNumber || null,
  };
};
