import { Modal, Text } from '@melio/penny';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePermissions } from '@melio/platform-permissions';
import { ComponentProps } from 'react';

import { InvitationForm } from './forms/InvitationForm';

type Props = {
  isOpen: boolean;
  onDone: ComponentProps<typeof InvitationForm>['onSubmit'];
  onClose: VoidFunction;
  isSaving?: boolean;
  companyName: string;
} & Pick<ComponentProps<typeof InvitationForm>, 'actor' | 'maxAllowedThreshold' | 'availableRoleOptions'>;

export const CreateInvitationModalScreen = ({
  isOpen,
  onDone,
  onClose,
  isSaving,
  companyName,
  actor,
  maxAllowedThreshold,
  availableRoleOptions,
  ...props
}: Props) => {
  const { formatMessage } = useMelioIntl();

  const { can } = usePermissions();
  const [isApprovalWorkflowsFeatureEnabled] = useFeature(FeatureFlags.ApprovalWorkflows, false, {
    shouldTrack: true,
  });

  const isApprovalWorkflowsEnabled =
    isApprovalWorkflowsFeatureEnabled && can({ action: 'create', subject: 'approvalWorkflow' });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      data-testid="manage-collaborators-activity-create-invitation-modal-screen"
      header={
        <Text as="h3" textStyle="heading2Semi">
          {formatMessage('activities.manageCollaborators.screens.invitationModal.title', {
            companyName,
          })}
        </Text>
      }
      {...props}
    >
      <InvitationForm
        showApprovalSection={isApprovalWorkflowsEnabled}
        actor={actor}
        maxAllowedThreshold={maxAllowedThreshold}
        onSubmit={onDone}
        isSaving={isSaving}
        availableRoleOptions={availableRoleOptions}
      />
    </Modal>
  );
};
