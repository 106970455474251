import { _IconIndicator, Group, Table, Text } from '@melio/penny';
import { DeliveryMethod, PaymentIntent } from '@melio/platform-api';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';

import { useGetDeliveryMethodWarning } from '../../PaymentIntentsTable.screen.utils';
import { useDeliveryMethodOptions } from '../DeliveryMethodSelectCell/DeliveryMethodSelectCell.utils';

type DeliveryMethodCellProps = {
  deliveryMethod: DeliveryMethod;
  paymentIntent: PaymentIntent;
  onOpenPaymentPurposeModal?: VoidFunction;
};

export const DeliveryMethodCell = forwardRef<DeliveryMethodCellProps, 'div'>(
  ({ deliveryMethod, paymentIntent, onOpenPaymentPurposeModal, ...props }, ref) => {
    const { getOptionToDisplayOnSelectLabel } = useDeliveryMethodOptions();

    const { warningInfo } = useGetDeliveryMethodWarning({
      selectedDeliveryMethodId: deliveryMethod.id,
      paymentIntent,
      onOpenPaymentPurposeModal,
    });

    const indicatorProps = warningInfo.tooltipProps
      ? { tooltip: warningInfo.tooltipProps }
      : warningInfo.popoverProps
      ? { popover: warningInfo.popoverProps }
      : {};
    return (
      <Table.Cell {...props} ref={ref}>
        <Group spacing="xs" alignItems="center" justifyContent="space-between" width="full">
          <Text color="inherit" textStyle="inline" shouldSupportEllipsis>
            {getOptionToDisplayOnSelectLabel(deliveryMethod)}
          </Text>
          {warningInfo.shouldShowWarning && (
            <_IconIndicator
              data-testid="delivery-method-cell-icon"
              variant={warningInfo.tooltipProps ? 'informative' : 'warning'}
              {...indicatorProps}
            />
          )}
        </Group>
      </Table.Cell>
    );
  }
);

DeliveryMethodCell.displayName = 'DeliveryMethodCell';
