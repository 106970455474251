import { useGetFundingSourceContent, useGetFundingSourceHelperText } from '@melio/ap-domain';
import { getFundingSourceImageProps, PaymentReviewLineItem, useFundingSourceIconKey } from '@melio/ap-widgets';
import { Group } from '@melio/penny';
import { FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';

export type VoidDetailsSectionProps = {
  tracingNumber?: number;
  fundingSource: FundingSource;
  maxEstimatedRefundDate: Date;
};

export const VoidDetailsSection: React.FC<VoidDetailsSectionProps> = ({
  tracingNumber,
  fundingSource,
  maxEstimatedRefundDate,
  ...props
}) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const getFsIcon = useFundingSourceIconKey(fundingSource);
  const fundingSourceImage = getFundingSourceImageProps(fundingSource);
  const fundingSourceIconType = !fundingSourceImage && getFsIcon;
  const lineItemIcon = fundingSourceImage
    ? { imageIcon: fundingSourceImage }
    : fundingSourceIconType
    ? { icon: { type: fundingSourceIconType } }
    : null;

  return (
    <Group variant="vertical" spacing="s" data-component="VoidDetailsSection" {...props}>
      <Group variant="vertical" spacing="m" hasDivider>
        <Group variant="vertical">
          <PaymentReviewLineItem
            labelProps={{
              label: formatMessage('activities.voidAndRefund.screens.confirmVoidAndRefund.checkNumber.label'),
            }}
            mainLabelProps={{
              label: tracingNumber?.toString() || '',
            }}
            icon={{ type: 'paper-check' }}
            contentTestId="refund-to-funding-source"
          />
          <PaymentReviewLineItem
            labelProps={{
              label: formatMessage('activities.voidAndRefund.screens.confirmVoidAndRefund.refundTo.label'),
            }}
            mainLabelProps={{
              label: useGetFundingSourceContent(fundingSource),
            }}
            descriptionProps={{ label: useGetFundingSourceHelperText(fundingSource) }}
            {...lineItemIcon}
            contentTestId="refund-to-funding-source"
          />
          <PaymentReviewLineItem
            labelProps={{
              label: formatMessage('activities.voidAndRefund.screens.confirmVoidAndRefund.refundDate.label'),
            }}
            mainLabelProps={{
              label: formatDate(maxEstimatedRefundDate, {
                dateStyle: 'medium',
              }),
            }}
            descriptionProps={{
              label: formatMessage('activities.voidAndRefund.screens.confirmVoidAndRefund.refundDate.description'),
            }}
            icon={{ type: 'calendar-move' }}
            contentTestId="refund-to-scheduled-date"
          />
        </Group>
      </Group>
    </Group>
  );
};

VoidDetailsSection.displayName = 'VoidDetailsSection';
