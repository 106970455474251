import { Button } from '@melio/penny';

export type PlanCardButtonProps = {
  isSaving?: boolean;
  isDisabled?: boolean;
  label: string;
  variant: 'primary' | 'tertiary';
  onClick: () => void;
};

export const PlanCardButton = ({ isSaving, isDisabled, label, onClick, variant }: PlanCardButtonProps) => (
  <Button
    data-testid="subscription-plan-card-button"
    label={label}
    isDisabled={isDisabled}
    isLoading={isSaving}
    variant={variant}
    onClick={onClick}
  />
);
