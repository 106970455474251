import { NakedButton } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { usePlansTiers, useSubscription } from '../../../hooks';
import { useSubscriptionRouter } from '../../utils';

export type SubscriptionChangePlanButtonProps = {
  returnUrl: string;
};

export const SubscriptionChangePlanButton = ({ returnUrl, ...rest }: SubscriptionChangePlanButtonProps) => {
  const subscription = useSubscription();
  const { getIsFreePlan } = usePlansTiers();
  const { formatMessage } = useMelioIntl();
  const { goToSubscriptionPlans } = useSubscriptionRouter();

  const label =
    !subscription || getIsFreePlan(subscription?.planId)
      ? formatMessage('subscriptions.topNavBar.upgradeFromFreeOrNoSubscription.title')
      : formatMessage('subscriptions.topNavBar.changePlanFromPaidSubscription.title');
  return (
    <NakedButton {...rest} variant="secondary" label={label} onClick={() => goToSubscriptionPlans({ returnUrl })} />
  );
};
