import { Group, Icon, Typography } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

type Props = {
  isDisabled?: boolean;
  availableCredit?: number;
};

export const AvailableCredit = ({ availableCredit, isDisabled = false }: Props) => {
  const { formatMessage, formatCurrency } = useMelioIntl();

  return (
    <Group variant="vertical" spacing="xxs" alignItems="flex-end">
      <Group spacing="xxs">
        <Icon size="small" type="info" isDisabled={isDisabled} />
        <Typography.Label
          label={formatMessage('widgets.fundingSources.selectFundingSourceCard.payWithFinancing.availableCreditLabel')}
          isDisabled={isDisabled}
        />
      </Group>
      <Typography.MainLabel
        label={
          availableCredit
            ? formatCurrency(availableCredit)
            : formatMessage(
                'widgets.fundingSources.selectFundingSourceCard.payWithFinancing.availableCreditDisabledLabel'
              )
        }
        variant="bold"
        isDisabled={isDisabled}
        data-testid="pay-with-financing-card-available-credit"
      />
    </Group>
  );
};
