import { Avatar, Group, Text, Typography } from '@melio/penny';
import { useDateUtils } from '@melio/platform-utils';

import { usePlatformIntl } from '@/translations/Intl';

type Props = {
  firstName?: string | null;
  lastName?: string | null;
  role: string;
  roleVariant?: 'neutral' | 'secondary';
  createdAt: Date;
};

export const PaymentScheduledBySection = ({ firstName, lastName, role, roleVariant = 'neutral', createdAt }: Props) => {
  const { formatMessage, formatDateWithTime } = usePlatformIntl();
  const { createDate } = useDateUtils();
  const fullName = [firstName, lastName].join(' ').trim();
  const formattedCreatedAtDate = formatDateWithTime(createDate(createdAt.toISOString()));

  return (
    <Group variant="vertical" data-testid="payment-scheduled-by-section">
      <Text textStyle="body4SemiUpper" color="global.neutral.800">
        {formatMessage('widgets.paymentDetails.paymentScheduledBy.title')}
      </Text>
      <Group>
        <Avatar name={fullName} />
        <Group.Item grow={1}>
          <Group variant="vertical" spacing="xxs">
            <Typography.MainLabel
              label={fullName}
              data-testid="payment-scheduled-by-section-full-name"
              variant="bold"
              pillProps={{ label: role, status: 'neutral', ...(roleVariant === 'secondary' && { type: 'secondary' }) }}
            />
            <Typography.Description
              data-testid="payment-scheduled-by-section-description"
              label={formatMessage('widgets.paymentDetails.paymentScheduledBy.date', {
                createdAt: formattedCreatedAtDate,
              })}
            />
          </Group>
        </Group.Item>
      </Group>
    </Group>
  );
};
