import { useRecoilValue } from 'recoil';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';

import { GroupItemCard } from '@/cl/components/PayCard/PayCard.component';
import { payDashboardQuerySearchSelector } from '@/store/PayDashboard/PayDashboards.model';
import { usePlatformIntl } from '@/translations/Intl';
import { GroupItemType } from '@/types/payDashboard.types';
import { getFinalPaymentTag, getPaymentCardRecurringText, isLastPayment } from '@/utils/billSubscription.utils';
import { BasePaymentCardProps } from '@/widgets/pay-dashboard/cards/types/PaymentCard.types';

export const ScheduledCard = ({
  payment,
  vendorName,
  isVirtualDeliverymethod,
}: BasePaymentCardProps & { isVirtualDeliverymethod?: boolean }) => {
  const { formatMessage, formatDate } = usePlatformIntl();
  const [isRecurringPaymentsEnabled] = useFeature<boolean>(FeatureFlags.RecurringPayments, false);

  const highlightedText = useRecoilValue(payDashboardQuerySearchSelector);
  if (!payment) {
    return <></>;
  }

  const { amount, scheduledDate, maxEstimatedDelivery, subscriptionOccurrence } = payment;

  const recurringText = getPaymentCardRecurringText(isRecurringPaymentsEnabled, formatMessage, subscriptionOccurrence);

  const scheduledText = `${formatMessage('widgets.scheduleCard.scheduleDatePrefix')} ${formatDate(scheduledDate, {
    dateStyle: 'medium',
  })}`;

  const etaText = `${formatMessage('widgets.scheduleCard.etaDatePrefix')} ${formatDate(maxEstimatedDelivery, {
    dateStyle: 'medium',
  })}`;
  const footerText = isVirtualDeliverymethod ? formatMessage('widgets.scheduleCard.pendingVendorFooterText') : etaText;

  const tag = recurringText && isLastPayment(subscriptionOccurrence) ? getFinalPaymentTag(formatMessage) : null;

  return (
    <GroupItemCard
      itemType={GroupItemType.SCHEDULED}
      topTag={tag}
      vendorName={vendorName}
      amount={amount}
      dateText={scheduledText}
      recurringText={recurringText}
      bills={payment.bills}
      footerText={footerText}
      highlightedText={highlightedText}
    />
  );
};

ScheduledCard.displayName = 'ScheduledCard';
