import { DeliveryPreferenceType } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';

import { usePaymentSchedulingPreference } from './usePaymentSchedulingPreference';

export const useEstimatedDeliveryDateLabel = ({
  minDeliveryDate,
  maxDeliveryDate,
  deliveryPreferenceType,
}: {
  minDeliveryDate: Date;
  maxDeliveryDate: Date;
  deliveryPreferenceType?: DeliveryPreferenceType | null;
}) => {
  const { isByDeliveryDate } = usePaymentSchedulingPreference();
  const { formatMessage, formatDateTimeRange, formatDate } = useMelioIntl();

  if (deliveryPreferenceType === 'rtp') {
    return {
      dateLabel: formatMessage('widgets.deliveryDate.estimate.label.rtp'),
    };
  }

  const dateLabel = isByDeliveryDate
    ? formatDate(minDeliveryDate, { dateStyle: 'medium' })
    : formatDateTimeRange(minDeliveryDate, maxDeliveryDate, {
        dateStyle: 'medium',
      });

  return {
    dateLabel,
  };
};
