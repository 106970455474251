import { Logger } from '@melio/platform-logger';

import { usePlanInfo, usePlansTiers, useSubscriptionPlan } from '../../../../hooks';
import { FeatureRequest } from '../../../../utils/planFeatures.utils';
import { useContextualConfig } from './useContextualConfig';

export type UseContextualPlansProps = FeatureRequest;

export const useContextualPlans = (feature: FeatureRequest) => {
  const { currentPlan } = useSubscriptionPlan();
  const { getFirstPlanWithFeature } = usePlansTiers(); // TODO:  should be fixed - need to validate that the "upgradePlan" contains all current plan features

  const upgradePlan = getFirstPlanWithFeature(feature);
  const { planName: upgradePlanName } = usePlanInfo(upgradePlan.id);

  const contextualConfig = useContextualConfig({ featureName: feature.featureName, upgradePlanName });

  if (!currentPlan) {
    const error = 'currentPlan not found';
    Logger.log(error, 'error');
    throw new Error(error);
  }

  return {
    title: contextualConfig.title,
    description: contextualConfig.description,
    currentContextualPlan: contextualConfig.plans.current,
    upgradeContextualPlan: contextualConfig.plans.upgrade,
    currentPlan,
    upgradePlan,
  };
};
