import { Bill } from '@melio/platform-api';
import { usePermissions } from '@melio/platform-permissions';
import { usePartnerFeature } from '@melio/platform-provider';
import { useCallback } from 'react';

import { isEbill } from '../../functions';

export const getIsBillHasPaymentsInProgress = (bill: Bill) =>
  bill.payments?.some(
    (payment) =>
      payment.collectStatus === 'sent' || payment.collectStatus === 'cleared' || payment.collectStatus === 'settled'
  );

export const useBillActions = () => {
  const [isMarkAsPaidEnabled] = usePartnerFeature('MarkAsPaid', false);
  const { can } = usePermissions();

  const getBillActions = useCallback(
    (bill: Bill) => {
      const billSubjectData = {
        createdById: bill.createdById,
        vendor: {
          createdById: bill.vendor?.createdById,
          managedBy: bill.vendor?.managedBy,
        },
      };

      const paymentSubjectData = {
        vendor: {
          createdById: bill.vendor?.createdById,
          managedBy: bill.vendor?.managedBy,
        },
      };

      const canUpdateBill = can({
        subject: 'bill',
        action: 'update',
        subjectData: billSubjectData,
      });
      const canDeleteBill = can({
        subject: 'bill',
        action: 'delete',
        subjectData: billSubjectData,
      });
      const canCreatePayment = can({
        subject: 'payment',
        action: 'create',
        subjectData: paymentSubjectData,
      });
      const actions = {
        edit: !isEbill(bill) && canUpdateBill,
        delete: !isEbill(bill) && canDeleteBill,
        markAsPaid: isMarkAsPaidEnabled && canUpdateBill,
        newPayment: canCreatePayment,
      };

      return {
        actions,
        hasActions: Object.values(actions).some(Boolean),
      };
    },
    [can, isMarkAsPaidEnabled]
  );

  return {
    getBillActions,
  };
};
