import { Container, Form, Group, Text, useMelioForm } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { Plan, SubscriptionBillingCycleEnum } from '../../../../../../../api';
import { useCyclesPricing } from '../../../../../../../hooks';
import { getAnnualPlanPricePerMonth } from '../../../../../../utils';

type SubscriptionBillingCycleProps = {
  selectedPlan: Plan;
  isSubscribing: boolean;
  selectedBillingCycle: SubscriptionBillingCycleEnum;
  onBillingCycleChange: (newCycle: SubscriptionBillingCycleEnum) => void;
};

export const SubscriptionBillingCycle = ({
  selectedPlan,
  isSubscribing,
  selectedBillingCycle,
  onBillingCycleChange,
}: SubscriptionBillingCycleProps) => {
  const { formatPercents, formatCurrency, formatMessage } = useMelioIntl();
  const { registerField } = useMelioForm({
    onSubmit: () => null,
  });

  const monthlyPlanPricePerMonth = selectedPlan.cycles.monthly.basePrice;
  const monthlyPlanPricePerMonthLabel = formatMessage('activities.subscription.checkout.billingCycle.pricePerMonth', {
    price: formatCurrency(monthlyPlanPricePerMonth),
  });

  const annualPlanPricePerMonth = getAnnualPlanPricePerMonth({ plan: selectedPlan, type: 'base' });
  const annualPlanPricePerMonthLabel = formatMessage('activities.subscription.checkout.billingCycle.pricePerMonth', {
    price: formatCurrency(annualPlanPricePerMonth),
  });

  const { getAnnualPlanSavingPercents } = useCyclesPricing();

  const annualPlanSavingPercents = getAnnualPlanSavingPercents();

  return (
    <Group variant="vertical" spacing="none">
      <Container paddingBottom="s">
        <Text as="h2" textStyle="body2Semi" color="global.neutral.800">
          {formatMessage('activities.subscription.checkout.billingCycle.title')}
        </Text>
      </Container>

      <Container border="regular" paddingX="m" paddingY="m" width="full">
        <Form.RadioGroup
          {...registerField('checkoutBillingCycle')}
          width="full"
          isDisabled={isSubscribing}
          variant="horizontal"
          aria-label={formatMessage('activities.subscription.checkout.billingCycle.title')}
          value={selectedBillingCycle}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onBillingCycleChange(e.target.value as SubscriptionBillingCycleEnum)
          }
          options={[
            {
              mainLabelProps: { label: formatMessage('activities.subscription.checkout.billingCycle.monthly.label') },
              descriptionProps: { label: monthlyPlanPricePerMonthLabel },
              value: SubscriptionBillingCycleEnum.Monthly,
            },
            {
              mainLabelProps: {
                label: formatMessage('activities.subscription.checkout.billingCycle.annual.label'),
                pillProps: annualPlanSavingPercents
                  ? {
                      label: formatMessage('activities.subscription.checkout.billingCycle.annualSavingBadge', {
                        savingPercent: formatPercents(annualPlanSavingPercents, { divide: true }),
                      }),
                      status: 'success',
                      type: 'secondary',
                    }
                  : undefined,
              },
              descriptionProps: { label: annualPlanPricePerMonthLabel },
              value: SubscriptionBillingCycleEnum.Annual,
            },
          ]}
        />
      </Container>
    </Group>
  );
};
