import { Box } from '@chakra-ui/react';
import { Button, Container, Form, Group, Illustration, useFormSubmissionController, useMelioForm } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { SystemMessageDisplay } from '@melio/platform-utils';
import { object, SchemaOf, string } from 'yup';

import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';
import { ExternalOrganizations } from '../../types';

type SelectOrganizationFormFields = {
  externalOrganizationId?: string;
};

type Props = {
  isSubmitting: boolean;
  externalOrganizations: ExternalOrganizations;
  accountingPlatformName: string;
  onClose: VoidFunction;
  onSubmitOrganization: (externalOrganizationId: string) => void;
};

const useSchema = () => {
  const { formatMessage } = useMelioIntl();

  return object().shape({
    externalOrganizationId: string().required(
      formatMessage('activities.accountingPlatformConnectCallback.screens.selectOrganization.select.error')
    ),
  }) as SchemaOf<SelectOrganizationFormFields>;
};

export const AccountingPlatformSelectOrganizationScreen = ({
  isSubmitting,
  externalOrganizations = [],
  accountingPlatformName,
  onClose,
  onSubmitOrganization,
}: Props) => {
  const { formatMessage } = useMelioIntl();
  const onSubmit = (data: SelectOrganizationFormFields) => {
    if (data.externalOrganizationId) {
      onSubmitOrganization(data.externalOrganizationId);
    }
  };
  const { submitButtonProps, onSubmissionStateChange } = useFormSubmissionController<SelectOrganizationFormFields>();
  const { formProps, registerField } = useMelioForm<SelectOrganizationFormFields>({
    defaultValues: {},
    onSubmit,
    onSubmissionStateChange,
    schema: useSchema(),
  });

  return (
    <NewSinglePaymentStepLayout
      data-component="AccountingPlatformConnectActivity.SelectOrganizationScreen"
      data-testid="accounting-platform-connect-select-organization-screen"
      headerContent={
        <NewSinglePaymentStepLayout.Header>
          <Box />
          <NewSinglePaymentStepLayout.CloseButton onClick={onClose} />
        </NewSinglePaymentStepLayout.Header>
      }
    >
      <Container paddingTop="xxl">
        <Group variant="vertical" alignItems="center">
          <Illustration type="sync-user" size="small" />
          <NewSinglePaymentStepLayout.Title paddingTop="xl">
            {formatMessage('activities.accountingPlatformConnectCallback.screens.selectOrganization.title', {
              accountingPlatformName,
            })}
          </NewSinglePaymentStepLayout.Title>
          <SystemMessageDisplay data-testid="register-organization" />
          <Container paddingY="xxl">
            <Form data-component="SelectOrganizationForm" size="large" {...formProps}>
              <Form.Select
                {...registerField('externalOrganizationId')}
                options={externalOrganizations.map(({ companyName = '', externalId = '' }) => ({
                  label: companyName,
                  value: externalId,
                }))}
                autoFocus
                labelProps={{
                  label: formatMessage(
                    'activities.accountingPlatformConnectCallback.screens.selectOrganization.select.label',
                    { accountingPlatformName }
                  ),
                }}
                placeholder={formatMessage(
                  'activities.accountingPlatformConnectCallback.screens.selectOrganization.select.placeholder'
                )}
                emptyState={undefined}
                helperTextProps={{
                  label: formatMessage(
                    'activities.accountingPlatformConnectCallback.screens.selectOrganization.select.helperText'
                  ),
                }}
              />
            </Form>
          </Container>
          <Button
            {...submitButtonProps}
            size="large"
            data-testid="accounting-platform-organization-connect"
            label={formatMessage('activities.accountingPlatformConnectCallback.screens.selectOrganization.cta')}
            isLoading={isSubmitting}
            isFullWidth={false}
          />
        </Group>
      </Container>
    </NewSinglePaymentStepLayout>
  );
};
