import { Box, Flex } from '@chakra-ui/react';
import { Container, Text } from '@melio/penny';

import { NoResultsFoundTheme } from '@/cl/components/NoResultsFound/NoResultsFound.component.theme';
import { useAppTheme } from '@/cl/theme/theme.hooks';

type NoResultsFoundProps = {
  text: string;
  icon: JSX.Element;
};
export const NoResultsFound = ({ text, icon }: NoResultsFoundProps) => {
  const styles = useAppTheme(NoResultsFoundTheme, {});

  return (
    <Flex sx={styles['container']} data-component="NoResultsFound">
      <Box sx={styles['icon']}>{icon}</Box>
      <Container textAlign="center">
        <Text textStyle="body4Semi" color="global.neutral.800">
          {text}
        </Text>
      </Container>
    </Flex>
  );
};
