import { Container, Group, Icon, NakedButton, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';

type ContextualFeaturesListProps = {
  featureCapabilities: string[];
  shouldShowAllFeaturesLink: boolean;
};

export const ContextualFeaturesList = ({
  featureCapabilities,
  shouldShowAllFeaturesLink,
}: ContextualFeaturesListProps) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const {
    settings: { subscriptions },
  } = useConfig();

  const onSeeAllFeaturesClick = () => {
    track('Organization', 'Click', {
      Intent: 'all-features-list',
      Flow: 'subscription',
      Cta: 'all-features-list',
    });
    window.open(subscriptions?.links.seeAllFeatures);
  };

  return (
    <Container>
      <Group as="ul" variant="vertical" spacing="none">
        {featureCapabilities.map((item) => (
          <Container key={item} paddingBottom="xs" as="li">
            <Group alignItems="center" spacing="xs" key={item}>
              <Icon type="checked" size="small" color="brand" aria-hidden="true" />
              <Text textStyle="body4" color="global.neutral.900">
                {item}
              </Text>
            </Group>
          </Container>
        ))}
      </Group>

      {shouldShowAllFeaturesLink && subscriptions?.links.seeAllFeatures && (
        <Container paddingY="xs" paddingX="xs">
          <NakedButton
            label={formatMessage('activities.subscription.contextualPlans.card.seeAllFeatures')}
            variant="secondary"
            onClick={onSeeAllFeaturesClick}
            // @ts-expect-error TODO ask penny to support NakedButton small size
            size="small"
            fontWeight="400"
          />
        </Container>
      )}
    </Container>
  );
};
