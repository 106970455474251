import { RateSection } from '@melio/ap-domain';
import { Loader, Text } from '@melio/penny';
import { FxQuote } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export const FxAmountSection = ({
  fxAmountInUsd,
  quote,
  isQuoteFetching,
  isApprovalFlow,
}: {
  fxAmountInUsd?: number;
  quote?: FxQuote;
  isQuoteFetching?: boolean;
  isApprovalFlow?: boolean;
}) => {
  const { formatMessage, formatCurrency } = useMelioIntl();

  if (isQuoteFetching) {
    return <Loader />;
  }
  return (
    <>
      {fxAmountInUsd && (
        <Text data-testid="payment-review-amount-in-usd" color="global.neutral.900" textStyle="body3">
          {formatMessage('widgets.reviewAndConfirm.amountSection.amountInUsd', {
            amount: formatCurrency(fxAmountInUsd, 'USD'),
          })}
        </Text>
      )}
      {quote && (
        <RateSection
          quote={quote}
          message="widgets.reviewAndConfirm.amountSection.rate"
          isApprovalFlow={isApprovalFlow}
        />
      )}
    </>
  );
};
