import { useAnalytics } from '@melio/platform-analytics';
import { SubscriptionBillingCycleEnum } from '@melio/platform-api-axios-client';

import { usePartnerGroups } from '../usePartnerGroups';

export const useCreateSubscriptionMarketingTrack = ({
  cycle = 'monthly',
  isEligibleForFreeTrial,
  isFreePlan,
  planName,
}: {
  planName: string;
  isFreePlan: boolean;
  cycle?: SubscriptionBillingCycleEnum;
  isEligibleForFreeTrial?: boolean;
}) => {
  const { trackMarketing } = useAnalytics();
  const { isFiservPartner } = usePartnerGroups();

  const trackCreateSubscriptionMarketingEvent = () => {
    if (isFiservPartner) {
      return;
    }

    const cycleName = cycle === SubscriptionBillingCycleEnum.Monthly ? 'monthly' : 'yearly';
    const eventNamePostfix = isFreePlan
      ? ''
      : `-${isEligibleForFreeTrial ? 'free-trial' : 'subscription'}_${cycleName}`;

    const eventName = `selected-plan_${planName.toLowerCase()}${eventNamePostfix}`;

    trackMarketing(eventName);
  };

  return { trackCreateSubscriptionMarketingEvent };
};
