import { useState } from 'react';
import { Box } from '@chakra-ui/react';
import { usePaymentProcessingInfo } from '@melio/ap-widgets';
import { ActionsDropdownMenu, ActionsDropdownMenuItemProps, Container, Divider, StatusBar } from '@melio/penny';
import { useInboxItems, usePayment } from '@melio/platform-api';
import { usePermissions } from '@melio/platform-permissions';

import { useDisclosure } from '@/hooks/useDisclosure';
import { usePlatformIntl } from '@/translations/Intl';
import { PaymentDetailsProps } from '@/widgets/pay-dashboard/payment-details/PaymentDetails.types';
import { usePaymentScheduledByProps } from '../../hooks/usePaymentScheduledByProps';
import { CancelPaymentDialog } from '../general/CancelPaymentDialog';
import { PayFromSection } from '../general/PayFromSection';
import { PaymentDetailsSection } from '../general/PaymentDetailsSection';
import { PaymentDetailsTop } from '../general/PaymentDetailsTop';
import { PaymentScheduledBySection } from '../general/PaymentScheduledBySection';
import { PaymentTimelineTrackerSection } from '../general/PaymentTimelineTracker/PaymentTimelineTrackerSection';
import { VendorReceivesSection } from '../general/VendorReceivesSection/VendorReceivesSection';

export const PaymentInProgress = ({ payment, isActionsEnabled }: PaymentDetailsProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { delete: deletePayment } = usePayment({ id: payment.id, enabled: false });
  const { isPaymentProcessedByCapitalOne } = usePaymentProcessingInfo();
  const isPaymentProcessedExternally = isPaymentProcessedByCapitalOne(payment.deliveryMethod?.type);
  const { refetch: refetchInboxItems } = useInboxItems({ enabled: false });
  const paymentIsCancellable = payment.paymentActions?.cancel?.eligible;
  const { isOpen: isCancelPaymentDialogOpen, onOpen: openCancelDialog, onClose: closeCancelDialog } = useDisclosure();

  const { can } = usePermissions();

  const { formatMessage } = usePlatformIntl();
  const {
    fundingSource,
    vendor,
    deliveryMethod,
    timeline,
    scheduledDate,
    note,
    maxEstimatedDelivery,
    estimatedDelivery,
  } = payment;
  const { showPaymentScheduledBySection, ...props } = usePaymentScheduledByProps(payment);

  const canDeletePaymentBills = payment.bills
    ? payment.bills.every((bill) =>
        can({
          subject: 'bill',
          action: 'delete',
          subjectData: {
            createdById: bill.createdById,
            vendor: {
              createdById: bill.vendor?.createdById,
              managedBy: bill.vendor?.managedBy,
            },
          },
        }),
      )
    : true;

  const hasDeleteBillAndPaymentPermissions =
    can({
      subject: 'payment',
      action: 'delete',
      subjectData: {
        createdById: payment.createdById,
        fundingSourceId: payment.fundingSourceId,
        vendor: {
          createdById: payment.vendor?.createdById,
          managedBy: payment.vendor?.managedBy,
        },
        payment: {
          origin: payment.origin,
        },
      },
    }) && canDeletePaymentBills;

  const isCancelActionAllowed =
    hasDeleteBillAndPaymentPermissions && isPaymentProcessedExternally && paymentIsCancellable;

  const actionMenuItems = isCancelActionAllowed
    ? [
        {
          label: formatMessage(`widgets.paymentDetails.inProgress.menu.items.cancel`),
          onClick: () => openCancelDialog(),
          dataTestId: 'in-progress-payment-details-menu-cancel',
          variant: 'critical' as ActionsDropdownMenuItemProps['variant'],
        },
      ]
    : [];

  const paymentDeleteFunc = async () => {
    await deletePayment();
    await refetchInboxItems();
  };

  const cancelDialogBodyMessage = formatMessage('widgets.paymentDetails.cancelPaymentDialog.inProgressPayment.body', {
    vendorName: vendor.name,
  });

  return (
    <>
      <StatusBar variant={'neutral'} content={formatMessage('widgets.paymentDetails.inProgress.title')} />

      <Box py={'32px'} data-testid="pay-dashboard-payment-in-progress">
        <PaymentTimelineTrackerSection payment={payment}>
          <Container paddingY="l">
            <Divider />
          </Container>
        </PaymentTimelineTrackerSection>
        <PaymentDetailsSection>
          <PaymentDetailsTop
            payment={payment}
            isActionsEnabled={isActionsEnabled}
            menu={
              actionMenuItems.length ? (
                <ActionsDropdownMenu
                  label={formatMessage('widgets.paymentDetails.scheduled.menu.button')}
                  data-testid="in-progress-payment-details-menu"
                  items={actionMenuItems}
                  isOpen={isMenuOpen}
                  onOpenChange={setIsMenuOpen}
                />
              ) : null
            }
          />
        </PaymentDetailsSection>
        {showPaymentScheduledBySection && (
          <PaymentDetailsSection gridGap={'24px'}>
            <PaymentScheduledBySection {...props} />
          </PaymentDetailsSection>
        )}
        <PaymentDetailsSection gridGap={'24px'}>
          <PayFromSection fundingSource={fundingSource} scheduledDate={scheduledDate} deliveryMethod={deliveryMethod} />
          <Divider />
          <VendorReceivesSection
            vendorName={vendor.name}
            deliveryMethod={deliveryMethod}
            estimatedDelivery={estimatedDelivery}
            maxEstimatedDelivery={maxEstimatedDelivery}
            processedTimeline={payment.processedTimeline}
            memoToVendor={note}
            timeline={timeline}
            scheduledDate={scheduledDate}
          />
        </PaymentDetailsSection>
        <CancelPaymentDialog
          isOpen={isCancelPaymentDialogOpen}
          onClose={closeCancelDialog}
          deleteEntity={paymentDeleteFunc}
          origin="inProgressPayment"
          bodyMessage={cancelDialogBodyMessage}
        />
      </Box>
    </>
  );
};
