import { SystemMessage, SystemMessageProvider, useSystemMessage } from '@melio/platform-utils';

import { AddVendor } from './AddVendor';
import { AddVendorActivityProps } from './types';

export const AddVendorActivity = (props: AddVendorActivityProps) => {
  const { showMessage } = useSystemMessage();

  const handleCreateVendorSuccess = (message?: SystemMessage) => {
    if (message) {
      showMessage(message);
    }
  };

  return (
    <SystemMessageProvider>
      <AddVendor {...props} onCreateVendorSuccess={handleCreateVendorSuccess} />
    </SystemMessageProvider>
  );
};
