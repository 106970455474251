import { Container, Pagination, useBreakpoint } from '@melio/penny';
import { PaginationResponsePagination, UsePaginatedCollection } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';

import { usePayDashboardPagination } from './PayDashboardPaginationProvider';

export const PayDashboardPagination = ({
  paginatedCollection,
  isVisible,
}: {
  paginatedCollection: UsePaginatedCollection<{ data: unknown[]; pagination: PaginationResponsePagination }>;
  isVisible: boolean;
}) => {
  const { formatMessage } = useMelioIntl();
  const { isExtraSmallScreen: isMobile } = useBreakpoint();
  const { resetToFirstPage, setPreviousPage, setNextPage, currentPage, pageSize } = usePayDashboardPagination();

  const { data: paginationResult, fetchPreviousPage, fetchNextPage, fetchFirstPage } = paginatedCollection;
  const { data, pagination } = paginationResult ?? {};

  useEffect(() => {
    // in case we delete the only item in the last page, we need to fetch the first page
    if (data?.length === 0 && pagination?.totalCount && pagination.totalCount > 0) {
      resetToFirstPage();
      fetchFirstPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleFetchPreviousPage = () => {
    setPreviousPage();
    fetchPreviousPage();
  };

  const handleFetchNextPage = () => {
    setNextPage();
    fetchNextPage();
  };

  const handlePageChange = (newPageNumber: number) => {
    if (newPageNumber > currentPage) {
      handleFetchNextPage();
    } else {
      handleFetchPreviousPage();
    }
  };

  const paginationIndex = getPaginationIndex(currentPage, pageSize, pagination?.totalCount ?? 0);

  return isVisible ? (
    <Container justifyContent={isMobile ? 'flex-start' : 'flex-end'} overflow="initial">
      <Pagination
        data-testid="pay-dashboard-pagination-controls"
        pageSize={pageSize}
        totalItems={pagination?.totalCount ?? 0}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        ariaLabels={{
          chevronLeftLabel: formatMessage('activities.payDashboard.pagination.button.ariaLabel', {
            startIndex: paginationIndex.startIndex,
            endIndex: paginationIndex.endIndex,
            totalResults: pagination?.totalCount ?? 0,
            chevronDirection: formatMessage('activities.payDashboard.pagination.button.previous.ariaLabel'),
          }),
          chevronRightLabel: formatMessage('activities.payDashboard.pagination.button.ariaLabel', {
            startIndex: paginationIndex.startIndex,
            endIndex: paginationIndex.endIndex,
            totalResults: pagination?.totalCount ?? 0,
            chevronDirection: formatMessage('activities.payDashboard.pagination.button.next.ariaLabel'),
          }),
        }}
      />
    </Container>
  ) : null;
};

export const getPaginationIndex = (currentPage: number, pageSize: number, totalItems: number) => {
  const getStartIndex = (pageSize: number, currentPage: number, totalItems: number): number => {
    const totalPages = getTotalPages(totalItems, pageSize);
    const pageForCalculation = Math.min(currentPage, totalPages);
    return Math.max(1, 1 + pageSize * (pageForCalculation - 1));
  };

  const getEndIndex = (pageSize: number, currentPage: number, totalItems: number): number => {
    const pageForCalculation = Math.max(currentPage, 1);
    return Math.min(pageSize * pageForCalculation, totalItems);
  };

  const getTotalPages = (totalItems: number, pageSize: number): number => Math.ceil(totalItems / pageSize);

  return {
    startIndex: getStartIndex(pageSize, currentPage, totalItems),
    endIndex: getEndIndex(pageSize, currentPage, totalItems),
  };
};
