import React, { useCallback, useState } from 'react';
import { Box, Flex, HStack, Select, Stack, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { Card, Text } from '@melio/penny';

import { useRouter } from '@/hooks/router.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { VendorBillsTableBill, VendorBillsTableProps } from '@/types/vendors.types';
import { PaymentsClassificationFixtures } from '@/utils/fixtures/PaymentsClassification.fixtures';
import { TaxReportModal } from '@/widgets/PaymentClassification/CreateTaxReport.widget';

export const PaymentsClassificationScreen = () => {
  const { goToPayDashboard } = useRouter();
  const data = [];

  const onClose = useCallback(() => {
    goToPayDashboard();
  }, [goToPayDashboard]);
  return data.length === 0 ? <TaxReportModal isOpen={true} onClose={onClose} /> : <PaymentsClassification />;
};

const PaymentsClassification = () => {
  const { formatMessage } = usePlatformIntl();
  const data = PaymentsClassificationFixtures;
  const title = formatMessage('widgets.paymentClassification.title');
  const subtitle = formatMessage('widgets.paymentClassification.subtitle', { formYear: '2021' });

  return (
    <Flex width={'100%'} justifyContent={'center'} pt={10} h={'100%'} background={'#fafafa'}>
      <Box width={900}>
        <Box mb={6}>
          <Text as="h2" textStyle="display1Semi">
            {title}
          </Text>
          <Text>{subtitle}</Text>
        </Box>
        <Stack flexDirection="column" gridGap={6} w={'100%'}>
          {data.map((obj) => (
            <VendorBillsTable key={obj.vendor.id} vendor={obj.vendor} bills={obj.bills as VendorBillsTableBill[]} />
          ))}
        </Stack>
      </Box>
    </Flex>
  );
};

const VendorBillsTable = (props: VendorBillsTableProps) => {
  const { formatMessage } = usePlatformIntl();
  const { vendor, bills } = props;
  const dateHeader = formatMessage('widgets.paymentClassification.tableHeaders.date');
  const invoiceNumberHeader = formatMessage('widgets.paymentClassification.tableHeaders.invoiceNumber');
  const amountHeader = formatMessage('widgets.paymentClassification.tableHeaders.amount');
  const classificationHeader = formatMessage('widgets.paymentClassification.tableHeaders.classification');
  const noInvoiceNumber = formatMessage('widgets.paymentClassification.noInvoiceNumber');

  return (
    <Card>
      <Box w={'100%'}>
        <Text as="h2" textStyle="body2Semi">
          {vendor.name}
        </Text>
        <Table style={{ width: '100%' }}>
          <Thead>
            <Tr>
              <Th>{dateHeader}</Th>
              <Th>{invoiceNumberHeader}</Th>
              <Th>{amountHeader}</Th>
              <Th>{classificationHeader}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {bills.map((bill) => (
              <Tr key={bill.id}>
                <Td p={4}>
                  <Text textStyle={'caption1Semi'}>{bill.date}</Text>
                </Td>
                <Td p={4}>
                  {bill.invoiceId && (
                    <HStack alignItems={'center'} justifyContent={'space-between'}>
                      <Text textStyle={'caption1Semi'}>{bill.invoiceId}</Text>
                      <a href={`/pay?status=paid&id=${bill.id}`} target="_blank" rel="noopener noreferrer">
                        link
                      </a>
                    </HStack>
                  )}
                  {!bill.invoiceId && (
                    <Text textStyle="caption1Semi" color="global.neutral.300">
                      {noInvoiceNumber}
                    </Text>
                  )}
                </Td>

                <Td p={4}>
                  <Text textStyle={'caption1Semi'}>{bill.amount}</Text>
                </Td>

                <Td>
                  <CategoryCell billId={bill.id} category={bill.category} />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Card>
  );
};

const CategoryCell = ({ billId, category }: { billId: number; category?: string }) => {
  const { formatMessage } = usePlatformIntl();
  const [currentCategory, setCurrentCategory] = useState(category);

  const placeHolder = formatMessage('widgets.paymentClassification.placeHolder');
  return (
    <Select
      placeholder={placeHolder}
      value={currentCategory}
      onChange={(e) => {
        setCurrentCategory(e.target.value);
        console.log(billId);
        // do submit here
      }}
    >
      <option value={'1099-MISC Rents1'}>1099-MISC Rents1</option>
      <option value={'1099-MISC Rents2'}>1099-MISC Rents2</option>
      <option value={'1099-MISC Rents3'}>1099-MISC Rents3</option>
    </Select>
  );
};
