import { ComponentType } from 'react';

import { forwardRef, HTMLTag } from '../react-utils';
import { SystemMessageProvider } from './SystemMessage';

const getDisplayName = <T,>(component: ComponentType<T>) => component.displayName || component.name || 'Component';

export const withSystemMessageProvider = <T, S extends HTMLTag = 'div'>(UI: ComponentType<T>) => {
  const SystemMessageProviderWrapper = forwardRef<T, S>((props: T, ref) => (
    <SystemMessageProvider>
      <UI {...props} ref={ref} />
    </SystemMessageProvider>
  ));
  SystemMessageProviderWrapper.displayName = `WithSystemMessageProvider(${getDisplayName(UI)})`;
  return SystemMessageProviderWrapper;
};
