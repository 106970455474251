import { ComponentThemeType } from '@/cl/theme/theme.hooks';

export const EmptyStateListTheme: ComponentThemeType = () => ({
  baseStyle: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gridGap: '24px',
      width: '100%',
      alignSelf: 'center',
      marginTop: '80px',
    },
    iconContainer: {
      display: 'flex',
      flexDirection: 'column',
      gridGap: '24px',
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      gridGap: '4px',
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'column',
      gridGap: '24px',
    },
  },
});
