/* eslint-disable @typescript-eslint/no-unused-vars */
import { useAccountingPlatformName } from '@melio/ap-widgets';
import { Button, Group, SelectionCard, useFormSubmissionController } from '@melio/penny';
import { BankAccount, useAccountingPlatforms } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import { useState } from 'react';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';
import { MatchBankAccountFormFields } from '../../../MatchBankAccountForm/MatchBankAccountForm.type';
import { MatchBankAccountForm } from '../../../MatchBankAccountForm/MatchBankAccountForm.widget';

type LinkBankAccountProps = {
  isLoading?: boolean;
  isSaving?: boolean;
  onClose: VoidFunction;
  bankDetails?: BankAccount;
  onCreateNewBankAccount: (newBankAccountName?: string) => Promise<void>;
  onLinkReceivingMethod: (accountingPlatformBankAccountId: string) => Promise<void>;
};

export const LinkBankAccount = forwardRef<LinkBankAccountProps, 'div'>(
  ({ onClose, onCreateNewBankAccount, onLinkReceivingMethod, bankDetails, isLoading, isSaving, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const { activeAccountingPlatform, isLoading: isActiveAccountingPlatformLoading } = useAccountingPlatforms();

    const accountingPlatformName = useAccountingPlatformName(activeAccountingPlatform?.accountingSlug);

    const [shouldCreateNewBankAccount, setCreateNewBankAccount] = useState(false);

    const { submitButtonProps, onSubmissionStateChange } = useFormSubmissionController<MatchBankAccountFormFields>();

    const newBankAccountName = `Bank account ${bankDetails?.accountNumber?.slice(-4) as BankAccount['accountNumber']}`;

    const onSubmit = ({ accountingPlatformBankAccountId }: { accountingPlatformBankAccountId: string }) => {
      if (shouldCreateNewBankAccount) {
        onCreateNewBankAccount?.(newBankAccountName);
      } else {
        onLinkReceivingMethod?.(accountingPlatformBankAccountId);
      }
    };

    return (
      <NewSinglePaymentStepLayout
        data-component="LinkBankAccount"
        data-testid="link-bank-account-screen"
        isLoading={isActiveAccountingPlatformLoading || isLoading}
        {...props}
        ref={ref}
        headerContent={
          <NewSinglePaymentStepLayout.Header>
            <NewSinglePaymentStepLayout.CloseButton onClick={onClose} />
          </NewSinglePaymentStepLayout.Header>
        }
      >
        <NewSinglePaymentStepLayout.Title>
          {formatMessage('activities.linkBankAccount.screens.linkBankAccount.title')}
        </NewSinglePaymentStepLayout.Title>
        <NewSinglePaymentStepLayout.Description>
          {formatMessage('activities.linkBankAccount.screens.linkBankAccount.description', {
            platformLabel: accountingPlatformName,
          })}
        </NewSinglePaymentStepLayout.Description>
        <NewSinglePaymentStepLayout.Content>
          <Group variant="vertical" width="full">
            <SelectionCard
              icon="bank"
              mainLabelProps={{
                label: formatMessage('activities.linkBankAccount.screens.linkBankAccount.bankAccountText', {
                  cardNumber: bankDetails?.accountNumber?.slice(-4),
                }),
              }}
            />

            <MatchBankAccountForm
              newBankAccountName={newBankAccountName}
              onCreateOption={() => setCreateNewBankAccount(true)}
              onSubmit={onSubmit}
              isSaving={isSaving}
              onSubmissionStateChange={onSubmissionStateChange}
              accountingPlatformName={accountingPlatformName}
            />

            <Button
              {...submitButtonProps}
              size="large"
              data-testid="continue-cta"
              label={formatMessage('activities.linkBankAccount.screens.linkBankAccount.button')}
              variant="primary"
            />
          </Group>
        </NewSinglePaymentStepLayout.Content>
      </NewSinglePaymentStepLayout>
    );
  }
);
