import { Modal, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { TaxpayerInfo, useTaxPayerInfo } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils';
import { useEffect } from 'react';

type TaxPayerDeleteAttachmentModalProps = {
  taxpayerInfo?: TaxpayerInfo;
  isOpen: boolean;
  onClose: () => void;
};

export const TaxPayerDeleteAttachmentModal = ({
  taxpayerInfo,
  isOpen,
  onClose,
}: TaxPayerDeleteAttachmentModalProps) => {
  const { formatMessage } = useMelioIntl();

  const { delete: deleteTaxpayerAttachment, isMutating } = useTaxPayerInfo({
    vendorId: taxpayerInfo?.vendorId ?? '',
    taxpayerInfoId: taxpayerInfo?.id ?? '',
    enabled: false,
  });
  const { showMessage } = useSystemMessage();
  const { createTrackHandler, track } = useAnalytics();
  const trackActionClick = createTrackHandler('Vendor', 'Click');

  const onDeleteAttachment = async () => {
    trackActionClick({ Cta: 'delete-file' });
    try {
      await deleteTaxpayerAttachment();
      onClose();
      showMessage({
        type: 'informative',
        title: formatMessage('widgets.taxPayerInfo.deleteModal.toast.success'),
        dataTestId: 'vendor-tax-details-delete-file-success',
      });
    } catch (e) {
      showMessage({
        type: 'error',
        title: formatMessage('widgets.taxPayerInfo.deleteModal.toast.fail'),
        dataTestId: 'vendor-tax-details-delete-file-error',
      });
    }
  };

  useEffect(() => {
    if (isOpen) {
      track('Vendor', 'View', {
        Flow: 'dashboard-vendors',
        Intent: 'delete-w9-file',
        TaxId: 'file',
        PageName: 'delete-w9-file',
      });
    }
  }, [isOpen, track]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        trackActionClick({ Cta: 'exit', TaxId: 'file', Intent: 'delete-w9-file', PageName: 'delete-w9-file' }, () =>
          onClose()
        );
      }}
      isLoading={isMutating}
      data-testid="vendor-delete-attachment-tax-details-modal"
      header={formatMessage('widgets.taxPayerInfo.deleteModal.header')}
      secondaryButton={{
        label: formatMessage('widgets.taxPayerInfo.deleteModal.actions.cancel'),
        variant: 'tertiary',
        onClick: () => {
          trackActionClick({ Cta: 'cancel', TaxId: 'file', Intent: 'delete-w9-file', PageName: 'delete-w9-file' }, () =>
            onClose()
          );
        },
        isLoading: isMutating,
      }}
      primaryButton={{
        label: formatMessage('widgets.taxPayerInfo.deleteModal.actions.delete'),
        variant: 'critical',
        isLoading: isMutating,
        onClick: () => void onDeleteAttachment(),
      }}
    >
      <Text>{formatMessage('widgets.taxPayerInfo.deleteModal.description')}</Text>
    </Modal>
  );
};
