import { SortDirection } from '@melio/penny';
import { useState } from 'react';

import { useTableColumnsSort } from '../../hooks/useTableColumnsSort';
import { usePayDashboardSorting } from '../../PayDashboardSortingProvider';

export const usePayDashboardMobileSort = ({
  onSortChange,
  sortableItems,
}: {
  onSortChange?: VoidFunction;
  sortableItems: { label: string; cellId: string; defaultSortOrder: SortDirection }[];
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const sorting = usePayDashboardSorting();

  const { sortColumn } = useTableColumnsSort({ sorting, onSortChange });

  const onSort = (cellId: string, order: SortDirection, selectedIndex: number) => {
    sortColumn(cellId, order);
    setSelectedItemIndex(selectedIndex);
    onSortChange?.();

    setTimeout(() => {
      setIsMenuOpen(false);
    }, 1000);
  };

  const getSortDirection = (cellId: string): SortDirection | undefined =>
    sorting?.sortingState?.id === cellId ? sorting.sortingState.sortDirection : undefined;

  const sortMenuItems = sortableItems.map(({ label, cellId, defaultSortOrder }) => ({
    label,
    sortDirection: getSortDirection(cellId),
    onClick: (index: number) => onSort(cellId, defaultSortOrder, index),
  }));

  return { sortMenuItems, onSort, isMenuOpen, setIsMenuOpen, selectedItemIndex };
};
