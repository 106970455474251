import { Container } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { FC } from 'react';

import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';
import { RecurringPaymentScheduledScreenProps } from './RecurringPaymentScheduled.types';

type RecurringPaymentScheduledActionButtonsProps = {
  notifyVendor: boolean;
  onDone: RecurringPaymentScheduledScreenProps['onDone'];
  onNotifyVendors: RecurringPaymentScheduledScreenProps['onNotifyVendors'];
  isNewSuccessScreen: boolean;
};

const NotifyVendorBtn = ({
  onNotifyVendors,
  isFullWidth = true,
}: {
  onNotifyVendors: VoidFunction;
  isFullWidth?: boolean;
}) => {
  const { track } = useAnalytics();
  const { formatMessage } = useMelioIntl();
  return (
    <NewSinglePaymentStepLayout.NextButton
      size="medium"
      onClick={() => {
        track('NotifyMyVendor', 'Chose');
        onNotifyVendors();
      }}
      data-testid="notify-vendors-button"
      label={formatMessage('activities.recurringPaymentScheduled.screens.recurringPaymentScheduled.notify')}
      isFullWidth={isFullWidth}
    />
  );
};

export const RecurringPaymentScheduledActionButtons: FC<RecurringPaymentScheduledActionButtonsProps> = ({
  notifyVendor,
  onDone,
  onNotifyVendors,
  isNewSuccessScreen,
}) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();

  const onGoToDashboardClick = () => {
    track('GoToDashboard', 'Chose');
    onDone('goToDashboard');
  };

  const goToDashboardLabel = formatMessage(
    'activities.recurringPaymentScheduled.screens.recurringPaymentScheduled.continue'
  );

  if (notifyVendor) {
    if (isNewSuccessScreen) {
      return (
        <>
          <NotifyVendorBtn onNotifyVendors={onNotifyVendors} isFullWidth={false} />
          <NewSinglePaymentStepLayout.NextButton
            onClick={onGoToDashboardClick}
            variant="tertiary"
            size="medium"
            label={goToDashboardLabel}
          />
        </>
      );
    }
    return (
      <>
        <NotifyVendorBtn onNotifyVendors={onNotifyVendors} />
        <Container justifyContent="center">
          <NewSinglePaymentStepLayout.NakedNextButton
            onClick={onGoToDashboardClick}
            label={goToDashboardLabel}
            size="medium"
          />
        </Container>
      </>
    );
  }

  return (
    <NewSinglePaymentStepLayout.NextButton
      onClick={() => {
        track('GoToDashboard', 'Chose');
        onDone('goToDashboard');
      }}
      label={formatMessage('activities.recurringPaymentScheduled.screens.recurringPaymentScheduled.continue')}
      size="medium"
      isFullWidth={!isNewSuccessScreen}
    />
  );
};
