import {
  addWildcardToRoutes,
  Navigate,
  RouteElement,
  SubscriptionBillingCycleEnum,
  SubscriptionUpgradeModalActivity,
  useNavigate,
  withLocalization,
  withOutlet,
} from '@melio/ar-domain';
import { Route, Routes } from 'react-router-dom';

import {
  BusinessDetailsActivity,
  FinancialInformationActivity,
  GetStartedActivity,
  OwnershipDetailsActivity,
  SubscriptionActivity,
} from '../activities';

export const OnboardingFlow = withLocalization(() => {
  const {
    Paths,
    goToBusinessDetails,
    goToSubscription,
    goToOwnershipsDetails,
    goToGetStarted,
    goToFinancialInformation,
  } = useOnboardingFlow();

  return (
    <Routes>
      <Route index element={<Navigate to={Paths.Home} replace />} />
      <Route path={Paths.Home} element={<GetStartedActivity onClose={() => null} onDone={goToBusinessDetails} />} />
      <Route
        path={Paths.BusinessDetails}
        element={<BusinessDetailsActivity onBack={goToGetStarted} onDone={goToFinancialInformation} />}
      />
      <Route
        path={Paths.FinancialInformation}
        element={<FinancialInformationActivity onBack={goToBusinessDetails} onDone={goToOwnershipsDetails} />}
      />
      <Route
        path={Paths.OwnershipDetails}
        element={
          <OwnershipDetailsActivity
            onBack={() => goToBusinessDetails({ replace: true })}
            onDone={() => goToSubscription({ replace: true })}
            onClose={() => goToGetStarted({ replace: true })}
          />
        }
      />
      <Route
        path={Paths.Subscription}
        element={<SubscriptionRoutes onBack={goToOwnershipsDetails} onClose={goToGetStarted} />}
      />
    </Routes>
  );
});
OnboardingFlow.displayName = 'OnboardingFlow';

type SubscriptionRoutesProps = {
  onBack: VoidFunction;
  onClose: VoidFunction;
};

export const SubscriptionRoutes = withLocalization<SubscriptionRoutesProps>(({ onBack, onClose }) => {
  const { Paths, goToSubscriptionUpgrade, goToSubscriptionHome } = useOnboardingBlaFlow();

  return (
    <Routes>
      <Route
        path={Paths.Home}
        element={withOutlet(
          <SubscriptionActivity onBack={onBack} onClose={onClose} onDone={goToSubscriptionUpgrade} />
        )}
      >
        <Route
          path={Paths.SubscriptionUpgrade}
          element={
            <RouteElement
              component={SubscriptionUpgradeModalActivityWrapper}
              pathToProps={{ currentPlanId: 'currentPlanId', planId: 'planId' }}
              isOpen
              onClose={() => null}
              onDismiss={() => goToSubscriptionHome({ replace: true })}
              billingCycle={SubscriptionBillingCycleEnum.Monthly}
              returnUrl={Paths.Home}
            />
          }
        />
      </Route>
    </Routes>
  );
});

export type PlansData = {
  currentPlanId: string;
  planId: string;
};

const useOnboardingFlow = () => {
  enum Paths {
    Home = '',
    BusinessDetails = 'business-details',
    FinancialInformation = 'financial-information',
    OwnershipDetails = 'ownership-details',
    ReviewAndApproveLDD = 'review-and-approve',
    Subscription = 'subscription',
    SubscriptionUpgrade = 'upgrade/:currentPlanId/:planId',
    SubmitBusinessDetails = 'submit',
  }

  const navigate = useNavigate<Paths>({ withSearchparams: true });

  type Params = Parameters<typeof navigate>;
  const createCallback = (path: Params[0]) => (options?: Params[1]) => navigate(path, options);

  const goToGetStarted = createCallback(Paths.Home);

  const goToBusinessDetails = createCallback(Paths.BusinessDetails);

  const goToFinancialInformation = createCallback(Paths.FinancialInformation);

  const goToOwnershipsDetails = createCallback(Paths.OwnershipDetails);

  const goToReviewAndApproveLDD = createCallback(Paths.ReviewAndApproveLDD);

  const goToSubscription = createCallback(Paths.Subscription);

  const goToSubscriptionUpgrade = ({ currentPlanId, planId }: PlansData) => {
    navigate(Paths.SubscriptionUpgrade, { pathParams: { currentPlanId, planId } });
  };

  return {
    Paths: addWildcardToRoutes(Paths),
    goToBusinessDetails,
    goToOwnershipsDetails,
    goToReviewAndApproveLDD,
    goToSubscription,
    goToSubscriptionUpgrade,
    goToGetStarted,
    goToFinancialInformation,
  };
};

const useOnboardingBlaFlow = () => {
  enum Paths {
    Home = '',
    SubscriptionUpgrade = 'upgrade/:currentPlanId/:planId',
  }

  const navigate = useNavigate<Paths>({ withSearchparams: true });

  type Params = Parameters<typeof navigate>;
  const createCallback = (path: Params[0]) => (options?: Params[1]) => navigate(path, options);

  const goToSubscriptionHome = createCallback(Paths.Home);

  const goToSubscriptionUpgrade = ({ currentPlanId, planId }: PlansData) => {
    navigate(Paths.SubscriptionUpgrade, { pathParams: { currentPlanId, planId } });
  };

  return {
    Paths: addWildcardToRoutes(Paths),
    goToSubscriptionHome,
    goToSubscriptionUpgrade,
  };
};

type SubscriptionUpgradeModalActivityProps = Parameters<typeof SubscriptionUpgradeModalActivity>[0];

type SubscriptionUpgradeModalActivityWrapperProps = Omit<SubscriptionUpgradeModalActivityProps, 'planUpgradeProps'> &
  SubscriptionUpgradeModalActivityProps['planUpgradeProps'];

const SubscriptionUpgradeModalActivityWrapper = ({
  currentPlanId,
  planId,
  billingCycle,
  returnUrl,
  ...rest
}: SubscriptionUpgradeModalActivityWrapperProps) => (
  <SubscriptionUpgradeModalActivity {...rest} planUpgradeProps={{ billingCycle, returnUrl, currentPlanId, planId }} />
);
