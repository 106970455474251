import { AccountAddressParams } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import * as yup from 'yup';
import { object, SchemaOf, string } from 'yup';

import { phoneIsValid } from '../../../utils/validation/phone.valdiation.utils';
import { AdditionalOwnerDetailsForm, FinancingApplicationOwnerDetailsForm } from '../types';

export const useOwnershipDetailsSchema = () => {
  const { formatMessage } = useMelioIntl();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const addressSchema = object()
    .shape({
      line1: string().required(
        formatMessage(
          'activities.financingApplication.applicationFormScreen.ownershipDetails.address.validation.required'
        )
      ),
      line2: string().notRequired(),
      city: string().required(
        formatMessage(
          'activities.financingApplication.applicationFormScreen.ownershipDetails.address.city.validation.required'
        )
      ),
      state: string().required(
        formatMessage(
          'activities.financingApplication.applicationFormScreen.ownershipDetails.address.state.validation.required'
        )
      ),
      postalCode: string().required(
        formatMessage(
          'activities.financingApplication.applicationFormScreen.ownershipDetails.address.postalCode.validation.required'
        )
      ),
    })
    .default(null)
    .required(
      formatMessage(
        'activities.financingApplication.applicationFormScreen.ownershipDetails.address.validation.required'
      )
    ) as SchemaOf<AccountAddressParams | null>;

  const additionalOwnerSchema = yup.object().shape({
    firstName: yup
      .string()
      .matches(
        /^[a-zA-Z]*$/g,
        formatMessage(
          'activities.financingApplication.applicationFormScreen.ownershipDetails.additionalOwner.firstName.validation.minLength'
        )
      )
      .trim()
      .required(
        formatMessage(
          'activities.financingApplication.applicationFormScreen.ownershipDetails.additionalOwner.firstName.validation.required'
        )
      )
      .min(
        3,
        formatMessage(
          'activities.financingApplication.applicationFormScreen.ownershipDetails.additionalOwner.firstName.validation.minLength'
        )
      ),
    lastName: yup
      .string()
      .matches(
        /^[a-zA-Z]+(?:'[a-zA-Z]+)*$/g,
        formatMessage(
          'activities.financingApplication.applicationFormScreen.ownershipDetails.additionalOwner.lastName.validation.minLength'
        )
      )
      .trim()
      .required(
        formatMessage(
          'activities.financingApplication.applicationFormScreen.ownershipDetails.additionalOwner.lastName.validation.required'
        )
      )
      .min(
        2,
        formatMessage(
          'activities.financingApplication.applicationFormScreen.ownershipDetails.additionalOwner.lastName.validation.minLength'
        )
      ),
    email: yup
      .string()
      .email(
        formatMessage(
          'activities.financingApplication.applicationFormScreen.ownershipDetails.additionalOwner.email.validation.invalid'
        )
      )
      .required(
        formatMessage(
          'activities.financingApplication.applicationFormScreen.ownershipDetails.additionalOwner.email.validation.required'
        )
      ),
    ownershipPercentage: yup
      .string()
      .required(
        formatMessage(
          'activities.financingApplication.applicationFormScreen.ownershipDetails.additionalOwner.ownershipPercentage.validation.required'
        )
      ),
    dateOfBirth: yup
      .date()
      .required(
        formatMessage(
          'activities.financingApplication.applicationFormScreen.ownershipDetails.additionalOwner.dateOfBirth.validation.required'
        )
      )
      .typeError(
        formatMessage(
          'activities.financingApplication.applicationFormScreen.ownershipDetails.additionalOwner.dateOfBirth.validation.required'
        )
      ),
    ssn: yup
      .string()
      .required(
        formatMessage(
          'activities.financingApplication.applicationFormScreen.ownershipDetails.additionalOwner.ssn.validation.required'
        )
      ),
    address: addressSchema,
  }) as SchemaOf<AdditionalOwnerDetailsForm>;

  const schema = yup.object().shape({
    firstName: yup
      .string()
      .matches(
        /^[a-zA-Z]*$/g,
        formatMessage(
          'activities.financingApplication.applicationFormScreen.ownershipDetails.firstName.validation.minLength'
        )
      )
      .trim()
      .required(
        formatMessage(
          'activities.financingApplication.applicationFormScreen.ownershipDetails.firstName.validation.required'
        )
      )
      .min(
        3,
        formatMessage(
          'activities.financingApplication.applicationFormScreen.ownershipDetails.firstName.validation.minLength'
        )
      ),
    lastName: yup
      .string()
      .required(
        formatMessage(
          'activities.financingApplication.applicationFormScreen.ownershipDetails.lastName.validation.required'
        )
      )
      .trim()
      .matches(
        /^[a-zA-Z]+(?:'[a-zA-Z]+)*$/g,
        formatMessage(
          'activities.financingApplication.applicationFormScreen.ownershipDetails.lastName.validation.minLength'
        )
      )
      .min(
        3,
        formatMessage(
          'activities.financingApplication.applicationFormScreen.ownershipDetails.lastName.validation.minLength'
        )
      ),
    jobTitle: yup
      .string()
      .min(
        3,
        formatMessage(
          'activities.financingApplication.applicationFormScreen.ownershipDetails.jobTitle.validation.minLength'
        )
      )
      .required(
        formatMessage(
          'activities.financingApplication.applicationFormScreen.ownershipDetails.jobTitle.validation.required'
        )
      ),
    phone: yup
      .string()
      .required(
        formatMessage(
          'activities.financingApplication.applicationFormScreen.ownershipDetails.phone.validation.required'
        )
      )
      .test(
        'phoneIsValid',
        formatMessage(
          'activities.financingApplication.applicationFormScreen.ownershipDetails.phone.validation.invalid'
        ),
        phoneIsValid
      ),
    email: yup
      .string()
      .email(
        formatMessage('activities.financingApplication.applicationFormScreen.ownershipDetails.email.validation.invalid')
      )
      .required(
        formatMessage(
          'activities.financingApplication.applicationFormScreen.ownershipDetails.email.validation.required'
        )
      ),
    dateOfBirth: yup
      .date()
      .required(
        formatMessage(
          'activities.financingApplication.applicationFormScreen.ownershipDetails.dateOfBirth.validation.required'
        )
      )
      .typeError(
        formatMessage(
          'activities.financingApplication.applicationFormScreen.ownershipDetails.dateOfBirth.validation.required'
        )
      ),
    ssn: yup
      .string()
      .required(
        formatMessage('activities.financingApplication.applicationFormScreen.ownershipDetails.ssn.validation.required')
      ),
    address: addressSchema,
    isOwnQuarter: yup
      .boolean()
      .required(
        formatMessage(
          'activities.financingApplication.applicationFormScreen.ownershipDetails.isOwnQuarter.validation.required'
        )
      )
      .oneOf(
        [true],
        formatMessage(
          'activities.financingApplication.applicationFormScreen.ownershipDetails.isOwnQuarter.validation.required'
        )
      ),
    additionalOwners: yup.array().of(additionalOwnerSchema),
  }) as yup.SchemaOf<FinancingApplicationOwnerDetailsForm>;
  return schema;
};
