import { FinancingEligibilityStatus, OrgFinancingEligibility } from '@melio/platform-api';
import { useNavigate } from '@melio/platform-utils';
import { useEffect } from 'react';

export const useFinancingApplicationForbiddenRedirect = (data?: OrgFinancingEligibility) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (data?.status) {
      switch (data?.status) {
        case FinancingEligibilityStatus.Eligible:
          if (data.appliedToProvider) {
            navigate('/pay-dashboard/financing');
          }
          return;
        case FinancingEligibilityStatus.Ineligible:
          navigate('/pay-dashboard');
          return;
        case FinancingEligibilityStatus.Pending:
          navigate('/financing/application/pending');
          return;
        case FinancingEligibilityStatus.Declined:
        case FinancingEligibilityStatus.Suspended:
          navigate('/financing/application/declined');
          return;
        default:
          return;
      }
    }
  }, [data, navigate]);
};
