import { useCustomers, useMelioIntl } from '@melio/ar-domain';
import { useToast } from '@melio/penny';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { ImportCustomersFormFields, ImportCustomersScreen } from './screens';

type ImportCustomersActivityProps = {
  onClose: VoidFunction;
  onDone: VoidFunction;
  isOpen: boolean;
  onError?: ARErrorFunction;
};

export const ImportCustomersActivity = withAnalyticsContext<ImportCustomersActivityProps>(
  forwardRef(({ onDone, onError, onClose, ...props }, ref) => {
    const { fileSizeLimit } = useConfig().settings;
    const { importCustomers, isMutating: isImportingCustomers } = useCustomers({ enabled: false, onError });
    const { toast } = useToast();
    const { formatMessage } = useMelioIntl();

    const handleSubmit = ({ file }: ImportCustomersFormFields) => {
      importCustomers(file)
        .then(({ data }) => {
          const { totalCount, successCount } = data;
          const fullSuccess = totalCount > 0 && successCount === totalCount;
          const partialSuccess = totalCount > 0 && successCount < totalCount;
          const type = fullSuccess ? 'success' : partialSuccess ? 'informative' : 'error';
          const title =
            fullSuccess || partialSuccess
              ? successCount > 1
                ? formatMessage('ar.modals.activities.importCustomers.sectionBannerSummary.title.success.plural.text', {
                    successCount,
                    totalCount,
                  })
                : formatMessage(
                    'ar.modals.activities.importCustomers.sectionBannerSummary.title.success.singular.text',
                    {
                      successCount,
                      totalCount,
                    }
                  )
              : formatMessage('ar.modals.activities.importCustomers.sectionBannerSummary.title.error.text');

          onDone();
          toast({ type, title });
        })
        .catch((err: ARPlatformError) => {
          onError?.(err);
        });
    };

    const onDownloadCSVTemplateFile = () => {
      const csvHeaders = 'companyName,contactEmail,phone_number\n';
      const csvRows = ['Jane LLC,admin@jane.io,8654631529', 'John Doe,john.doe@gmail.com,8654636743'];
      const csvContent = csvHeaders + csvRows.join('\n');
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);

      link.setAttribute('href', url);
      link.setAttribute('download', 'customers_template.csv');
      link.style.visibility = 'hidden';

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    };

    return (
      <ImportCustomersScreen
        data-testid="import-customers-dialog"
        fileSizeLimit={fileSizeLimit}
        onSubmit={handleSubmit}
        isSaving={isImportingCustomers}
        ref={ref}
        onDownloadCSVTemplateFile={onDownloadCSVTemplateFile}
        onClose={onClose}
        {...props}
      />
    );
  })
);
ImportCustomersActivity.displayName = 'UpdateCustomerActivity';
