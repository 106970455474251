import { useMelioQueryClient, useModelApi, UseModelApiProps, useUpdateMutation } from '@melio/api-client';
import { Expand, FundingSourcesApiClient, PostFundingSourcesVerifyRequest } from '@melio/platform-api-axios-client';

import { FundingSource, PatchFundingSourcesRequest, PostFundingSourcesRequest } from './types';
import { convertVerifyParamsDollarsToCents, isMicroDepositsRequest } from './utils';

type QueryFn = typeof FundingSourcesApiClient.getFundingSourcesFundingSourceId;
type CreateFn = typeof FundingSourcesApiClient.postFundingSources;
type DeleteFn = typeof FundingSourcesApiClient.deleteFundingSourcesFundingSourceId;
type UpdateFn = typeof FundingSourcesApiClient.patchFundingSourcesFundingSourceId;

export type UseFundingSourceProps = UseModelApiProps<QueryFn, FundingSource> & {
  params?: { expand?: Expand; shouldDecrypt?: boolean };
};

export const useFundingSource = ({ params, ...props }: UseFundingSourceProps = {}) => {
  const query = useModelApi<
    QueryFn,
    UpdateFn,
    DeleteFn,
    CreateFn,
    FundingSource,
    PatchFundingSourcesRequest,
    PostFundingSourcesRequest
  >({
    ...props,
    queryKey: ['FundingSourcesApi', params],
    queryFn: (id: string) =>
      FundingSourcesApiClient.getFundingSourcesFundingSourceId(id, params?.expand, params?.shouldDecrypt),
    createFn: FundingSourcesApiClient.postFundingSources,
    deleteFn: FundingSourcesApiClient.deleteFundingSourcesFundingSourceId,
    updateFn: FundingSourcesApiClient.patchFundingSourcesFundingSourceId,
  });
  const { invalidateQueries } = useMelioQueryClient();

  const verifyMutation = useUpdateMutation(
    FundingSourcesApiClient.postFundingSourcesFundingSourceIdVerify,
    query.queryKey,
    {
      prepareParams: (params: PostFundingSourcesVerifyRequest): PostFundingSourcesVerifyRequest =>
        isMicroDepositsRequest(params)
          ? {
              type: params.type,
              details: convertVerifyParamsDollarsToCents(params),
            }
          : { type: params.type },
      onSuccess: () => invalidateQueries('PaymentsApi'),
    }
  );

  const linkMutation = useUpdateMutation(
    FundingSourcesApiClient.postLinkAccountingPlatformPaymentAccount,
    query.queryKey
  );

  return {
    ...query,
    verify: verifyMutation.createCallback(props.id as string),
    isVerifying: verifyMutation.isMutating,
    linkAccountingPlatformPaymentAccount: linkMutation.createCallback(props.id as string),
  };
};

export type FundingSourceModel = ReturnType<typeof useFundingSource>;
