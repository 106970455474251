import React from 'react';

import { WithLoading } from '@/hoc/withLoading.hoc';
import { useVendors } from '@/hooks/vendors.hooks';
import { useVendorsDashboardHandleNavigation } from '@/hooks/vendorsDashboard.hooks';
import { EmptyVendorsPage } from '@/screens/vendors/EmptyVendorsPage.screen';
import { VendorsPage } from '@/screens/vendors/VendorsPage.screen';

export const VendorsScreen = () => {
  const { isLoading } = useVendors();
  const { vendorsFiltered } = useVendorsDashboardHandleNavigation();

  return (
    <WithLoading isLoading={isLoading}>
      {vendorsFiltered?.length > 0 ? <VendorsPage /> : <EmptyVendorsPage />}
    </WithLoading>
  );
};
