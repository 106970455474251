import { useCallback } from 'react';
import { createSearchParams, useLocation } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { useInternationalCountryDisplayName } from '@melio/ap-domain';
import { InternationalDeliveryMethod, useGetSupportedInternationalDMs } from '@melio/ap-widgets';
import { Container, Group, Text } from '@melio/penny';
import { useAnalyticsContext, withAnalyticsContext } from '@melio/platform-analytics';
import {
  CreateInternationalAccountDeliveryMethod,
  DeliveryMethodType,
  InternationalAccountIdentifierTypeEnum,
  useAccount,
  useDeliveryMethodTypeOptions,
  Vendor,
  VirtualCardAccountDeliveryMethod,
} from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useConfig } from '@melio/platform-provider';

import { WithLoading } from '@/hoc/withLoading.hoc';
import { usePlatformIntl } from '@/translations/Intl';
import { DeliveryMethodNavigationStateParams } from '@/types/deliveryMethod.types';
import { DataComponentEnum } from '@/types/vendors.types';
import { AchDeliveryMethodCard } from '@/widgets/vendorsDashboard/DeliveryMethod/AchDeliveryMethodCard.widget';
import { DomesticWireDeliveryMethodCard } from '@/widgets/vendorsDashboard/DeliveryMethod/DomesticWireDeliveryMethodCard.widget';
import { InternationalDeliveryMethodCard } from '@/widgets/vendorsDashboard/DeliveryMethod/InternationalDeliveryMethodCard.widget';
import { InternationalFXDeliveryMethodCard } from '@/widgets/vendorsDashboard/DeliveryMethod/InternationalFXDeliveryMethodCard.widget';
import { ManagedDeliveryMethod } from '@/widgets/vendorsDashboard/DeliveryMethod/ManagedDeliveryMethod';
import { PaperCheckDeliveryMethodCard } from '@/widgets/vendorsDashboard/DeliveryMethod/PaperCheckDeliveryMethodCard.widget';
import { UnmanagedVirtualCardDeliveryMethodWidget } from '@/widgets/vendorsDashboard/DeliveryMethod/UnmanagedVirtualCardDeliveryMethodWidget';
import { UnilateralRequestDetails } from '@/widgets/vendorsDashboard/UnilateralRequestDetails/UnilateralRequestDetails.widget';

export interface VendorDrawerDeliveryMethodsProps {
  vendor: Vendor;
}

export const VendorDrawerDeliveryMethods = withAnalyticsContext(({ vendor }: VendorDrawerDeliveryMethodsProps) => {
  const [isNetworkVendorSupportEnabled] = useFeature(FeatureFlags.NetworkVendorSupport, false);
  const { pathname, search } = useLocation();
  const { deliveryMethods } = vendor;
  const {
    settings: { isUnilateralDeliveryMethodSupported },
  } = useConfig();

  const { formatMessage } = usePlatformIntl();

  useAnalyticsContext({
    globalProperties: {
      PageName: 'vendor-details',
      Intent: 'add-delivery-method',
      VendorId: vendor.id,
    },
  });
  const { data: account, isFetching: isAccountLoading } = useAccount({ id: 'me' });
  const { data: deliveryMethodTypeOptions, isFetching: isDeliveryMethodTypeOptionsLoading } =
    useDeliveryMethodTypeOptions({ vendorId: vendor.id });

  const isManagedVendor = isNetworkVendorSupportEnabled && vendor.isManaged;

  const supportedDeliveryMethodByType = deliveryMethodTypeOptions?.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.type]: curr.supported,
    }),
    {} as Record<DeliveryMethodType, boolean>,
  );
  const getDeliveryMethodByType = useCallback(
    (deliveryMethodType: DeliveryMethodType) => {
      if (!deliveryMethods) {
        return null;
      }
      return deliveryMethods.find((deliveryMethod) => deliveryMethod.type === deliveryMethodType) || null;
    },
    [deliveryMethods],
  );

  const { countryDisplayName, isCountriesDetailsLoading } = useInternationalCountryDisplayName(
    getDeliveryMethodByType('international-account') || undefined,
  );

  const supportedInternationalDeliveryMethod = useGetSupportedInternationalDMs({
    vendorCurrency: vendor.currency,
    businessType: account?.company.businessType,
  });

  const getExistInternationalType = (): InternationalDeliveryMethod | null => {
    const internationalDeliveryMethod = getDeliveryMethodByType(
      'international-account',
    ) as CreateInternationalAccountDeliveryMethod | null;
    if (!internationalDeliveryMethod) return null;

    return internationalDeliveryMethod.details.identifierType === InternationalAccountIdentifierTypeEnum.V2
      ? 'international-fx'
      : 'international-account';
  };

  const isSupportInternationalDeliveryMethod = (type: InternationalDeliveryMethod) => {
    const existInternationalMethod = getExistInternationalType();
    if (supportedDeliveryMethodByType?.['international-account']) {
      if (!existInternationalMethod) {
        return supportedInternationalDeliveryMethod.includes(type);
      } else {
        return existInternationalMethod === type;
      }
    }
  };
  const isInternationalEnabled = isSupportInternationalDeliveryMethod('international-account');
  const isInternationalFXEnabled = isSupportInternationalDeliveryMethod('international-fx');

  const getInternationalDeliveryMethod = (type: InternationalDeliveryMethod) =>
    getExistInternationalType() === type ? getDeliveryMethodByType('international-account') : null;

  const isBankAccountEnabled = supportedDeliveryMethodByType?.['bank-account'];
  const isPaperCheckEnabled = supportedDeliveryMethodByType?.['paper-check'];

  const isDomesticWireEnabled = supportedDeliveryMethodByType?.['domestic-wire-account'];
  const isVirtualCardEnabled = supportedDeliveryMethodByType?.['virtual-card'];
  const isUnilateralDeliveryMethodEnabled =
    isUnilateralDeliveryMethodSupported && supportedDeliveryMethodByType?.['virtual-account'];

  const navigationStateParams: DeliveryMethodNavigationStateParams = {
    id: vendor?.id,
    returnUrl: `${pathname}?${createSearchParams(search).toString()}`,
  };

  // TODO: check whether "by you" is a constant text
  // TODO: use toLocaleDateString() for the date format
  return (
    <WithLoading isLoading={isDeliveryMethodTypeOptionsLoading || isAccountLoading || isCountriesDetailsLoading}>
      <Container data-component={DataComponentEnum.DELIVERY_METHODS} data-testid="vendor-delivery-methods">
        <Text as="h3" textStyle="heading3Semi">
          {formatMessage('widgets.vendorDeliveryMethods.title')}
        </Text>
      </Container>
      <Box paddingX="1">
        <Group variant="vertical" spacing="m">
          {isManagedVendor ? (
            <ManagedDeliveryMethod vendor={vendor} deliveryMethods={deliveryMethods} />
          ) : (
            <>
              {isUnilateralDeliveryMethodEnabled ? <UnilateralRequestDetails vendor={vendor} /> : null}
              {isBankAccountEnabled ? (
                <AchDeliveryMethodCard
                  vendor={vendor}
                  ach={getDeliveryMethodByType('bank-account')}
                  navigationStateParams={navigationStateParams}
                />
              ) : null}
              {isDomesticWireEnabled ? (
                <DomesticWireDeliveryMethodCard
                  vendor={vendor}
                  domesticWire={getDeliveryMethodByType('domestic-wire-account')}
                  navigationStateParams={navigationStateParams}
                />
              ) : null}
              {isPaperCheckEnabled ? (
                <PaperCheckDeliveryMethodCard
                  vendor={vendor}
                  check={getDeliveryMethodByType('paper-check')}
                  navigationStateParams={navigationStateParams}
                />
              ) : null}
              {isInternationalEnabled ? (
                <InternationalDeliveryMethodCard
                  international={getInternationalDeliveryMethod('international-account')}
                  navigationStateParams={navigationStateParams}
                />
              ) : null}
              {isInternationalFXEnabled ? (
                <InternationalFXDeliveryMethodCard
                  internationalFx={getInternationalDeliveryMethod('international-fx')}
                  navigationStateParams={navigationStateParams}
                  countryDisplayName={countryDisplayName}
                />
              ) : null}
              {isVirtualCardEnabled ? (
                <UnmanagedVirtualCardDeliveryMethodWidget
                  unmanagedVirtualCard={
                    (getDeliveryMethodByType('virtual-card') as VirtualCardAccountDeliveryMethod) || null
                  }
                  navigationStateParams={navigationStateParams}
                />
              ) : null}
            </>
          )}
        </Group>
      </Box>
    </WithLoading>
  );
});
