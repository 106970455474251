import { useMelioIntl } from '@melio/platform-i18n';

import { CELLS_IDS, VendorsTabSortableCells, VendorsTabSortableColumn } from './types';

export const useVendorsTabSortableColumns = () => {
  const { formatMessage } = useMelioIntl();

  const lastPaidColumn: VendorsTabSortableColumn = {
    label: formatMessage('activities.payDashboard.vendorsTab.table.headers.lastPaid'),
    cellId: CELLS_IDS.LAST_PAID,
    defaultSortOrder: 'desc',
  };

  const vendorNameColumn: VendorsTabSortableColumn = {
    label: formatMessage('activities.payDashboard.vendorsTab.table.headers.vendor'),
    cellId: CELLS_IDS.VENDOR_NAME,
    defaultSortOrder: 'asc',
  };

  const vendorOpenBalance: VendorsTabSortableColumn = {
    label: formatMessage('activities.payDashboard.vendorsTab.table.headers.openBalance'),
    cellId: CELLS_IDS.OPEN_BALANCE,
    defaultSortOrder: 'desc',
  };

  const sortableColumns: { [key in VendorsTabSortableCells]: VendorsTabSortableColumn } = {
    [CELLS_IDS.LAST_PAID]: lastPaidColumn,
    [CELLS_IDS.VENDOR_NAME]: vendorNameColumn,
    [CELLS_IDS.OPEN_BALANCE]: vendorOpenBalance,
  };

  return { sortableColumns };
};
