import { useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import type { OriginFlow } from '@melio/platform-analytics';

export const useOrigin = (): OriginFlow | undefined => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  return useMemo(() => {
    const locationStateOrigin = location.state?.origin as OriginFlow;
    const searchParamOrigin = searchParams.get('origin') as OriginFlow;

    return locationStateOrigin || searchParamOrigin || undefined;
  }, [location, searchParams]);
};
