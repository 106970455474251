import { Box, Flex } from '@chakra-ui/react';
import { Card, NakedButton, Text, useBreakpointValue } from '@melio/penny';

import { PaymentMethodsIcon } from '@/cl/icons/system';
import { useRouter } from '@/hooks/router.hooks';
import { usePlatformIntl } from '@/translations/Intl';

export const EmptyMethodCard = () => {
  const { formatMessage } = usePlatformIntl();
  const descriptionTextStyle = useBreakpointValue({ xs: 'body5Semi', s: 'body4' });
  const { goToAddBillingMethod } = useRouter();

  return (
    <Card variant="flat" paddingX="m" paddingY="m" data-testid="billing-method-card">
      <Flex w="inherit" justify="space-between" align="flex-start">
        <Flex align="center">
          <Flex mr="s">
            <PaymentMethodsIcon />
          </Flex>
          <Flex direction="column">
            <Flex align="center" textStyle="body2Semi" mb="xxs">
              <Box mr="xxs">
                <Text textStyle="body2Semi">
                  {formatMessage('widgets.billingSettings.paymentMethod.card.empty.title')}
                </Text>
              </Box>
            </Flex>
            <Text textStyle={descriptionTextStyle} color="global.neutral.800">
              {formatMessage('widgets.billingSettings.paymentMethod.card.empty.description')}
            </Text>
          </Flex>
        </Flex>
        <NakedButton
          variant="secondary"
          onClick={goToAddBillingMethod}
          data-testid={`add-new-billing-method`}
          label={formatMessage('widgets.billingSettings.paymentMethod.card.empty.cta')}
        />
      </Flex>
    </Card>
  );
};
