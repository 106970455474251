import { FundingSourceEntitlementType, PermissionLevelEnum, RoleUniqueNames } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { array, number, object, SchemaOf, string, StringSchema } from 'yup';

import { FormFields } from '../types';

export const useSchema = ({
  showPaymentApprovalLimit,
}: {
  showPaymentApprovalLimit: boolean;
}): SchemaOf<FormFields> => {
  const { formatMessage, formatCurrency } = useMelioIntl();

  const baseSchema = {
    firstName: string().required(),
    lastName: string().required(),
    email: string().required(),
    roleUniqueName: new StringSchema<RoleUniqueNames>().oneOf(Object.values(RoleUniqueNames)).required(),
    permissionLevel: new StringSchema<PermissionLevelEnum>().oneOf(Object.values(PermissionLevelEnum)).required(),
    entitlementsFundingSourceIds: array().min(1).of(string().required()),
    entitlementsFundingSourceType: new StringSchema<FundingSourceEntitlementType>()
      .oneOf(Object.values(FundingSourceEntitlementType))
      .required(),
  };

  if (showPaymentApprovalLimit) {
    return object().shape({
      ...baseSchema,
      paymentApprovalLimit: number()
        .typeError(
          // a workaround. waiting for a fix from the DS team - when the field is empty, it throws error for "" is not a number - https://meliorisk.atlassian.net/browse/ME-65205
          formatMessage('activities.collaboratorDrawer.body.paymentApprovalLimitSect.input.validation.required')
        )
        .required(
          formatMessage('activities.collaboratorDrawer.body.paymentApprovalLimitSect.input.validation.required')
        )

        .moreThan(
          0.99,
          formatMessage('activities.collaboratorDrawer.body.paymentApprovalLimitSect.input.validation.greaterThan', {
            minValue: formatCurrency(1),
          })
        ),
    });
  }
  return object().shape({ ...baseSchema, paymentApprovalLimit: number().nullable().default(null) });
};
