import { useCollectionApi, UseCollectionApiProps } from '@melio/api-client';
import { ApprovalWorkflowsApiClient } from '@melio/platform-api-axios-client';

export type UseApprovalWorkflowsProps = UseCollectionApiProps<typeof ApprovalWorkflowsApiClient.getApprovalWorkflows>;

export const useApprovalWorkflows = (props: UseApprovalWorkflowsProps = {}) =>
  useCollectionApi({
    ...props,
    queryKey: 'ApprovalWorkflowsApi',
    queryFn: ApprovalWorkflowsApiClient.getApprovalWorkflows,
    deleteFn: ApprovalWorkflowsApiClient.deleteApprovalWorkflowsApprovalWorkflowId,
  });
