import { useMelioIntl } from '@melio/platform-i18n';
import { useMemo } from 'react';

import { useStepManager } from '../../../hooks';
import {
  AssignFirmClientPlanBillingDetailsStep,
  AssignFirmClientPlanSelectionStep,
} from '../components/AssignFirmClientPlanFormSteps';
import { AssignFirmClientPlanReviewAndConfirmStep } from '../components/AssignFirmClientPlanFormSteps/AssignFirmClientPlanReviewAndConfirmStep';
import { AssignFirmClientPlanFormStep, AssignFirmClientPlanFormStepDetails } from '../types';

export const useAssignFirmClientPlanFormStepManager = ({
  defaultActiveId,
  isFirmBilled,
}: {
  defaultActiveId?: AssignFirmClientPlanFormStep;
  isFirmBilled: boolean;
}) => {
  const { formatMessage } = useMelioIntl();

  const stepsData = useMemo(
    () => [
      {
        title: formatMessage(`activities.accountants.newClient.form.billingDetails.title.text`),
        description: formatMessage(`activities.accountants.newClient.form.billingDetails.description.text`),
        stepId: AssignFirmClientPlanFormStep.BillingDetails,
        component: AssignFirmClientPlanBillingDetailsStep,
      },
      {
        title: formatMessage(`activities.accountants.newClient.form.planSelection.title.text`),
        description: formatMessage(`activities.accountants.newClient.form.planSelection.description.text`),
        stepId: AssignFirmClientPlanFormStep.PlanSelection,
        component: AssignFirmClientPlanSelectionStep,
      },
      {
        title: formatMessage(`activities.accountants.newClient.form.reviewAndConfirm.title.text`),
        description: formatMessage(
          isFirmBilled
            ? `activities.accountants.assignPlan.form.reviewAndConfirm.description.text.firmBilled`
            : `activities.accountants.assignPlan.form.reviewAndConfirm.description.text.clientBilled`
        ),
        stepId: AssignFirmClientPlanFormStep.ReviewAndConfirm,
        component: AssignFirmClientPlanReviewAndConfirmStep,
      },
    ],
    [isFirmBilled, formatMessage]
  );

  const { steps, expandedStep, expandSelectedStep, goToNextStep, focusErrorStep } =
    useStepManager<AssignFirmClientPlanFormStepDetails>({
      data: stepsData,
      defaultActiveId,
    });

  return { steps, expandedStep, expandSelectedStep, goToNextStep, focusErrorStep };
};
