import { useInvoices, UseInvoicesProps, useSearchParams } from '@melio/ar-domain';
import { useDebounceCallback } from '@melio/platform-utils';
import { isNil } from 'lodash';
import { useMemo } from 'react';

import { Filter, Order, SortFields, SortParams, UseSortableInvoicesParams } from '../types';

const DEBOUNCE_TIME = 200;

type QueryParams = {
  sortOrder: Order;
  sortBy: SortFields;
  pageNumber: number;
  invoiceStatus?: Filter | null;
  searchTerm: string;
  customerId: string;
};

type InvoiceParams = Omit<NonNullable<UseInvoicesProps['params']>, 'sort'> & {
  sort: SortParams;
};

const useQueryParams = () => {
  const [searchParams, _setSearchParams] = useSearchParams<QueryParams>();

  const setSearchParams = useDebounceCallback<typeof _setSearchParams>(
    (params, options) =>
      _setSearchParams(
        (prev) =>
          Object.entries({ ...prev, ...params }).reduce(
            (acc, [key, value]) => (value === '' ? acc : { ...acc, [key]: value }),
            {} as Partial<QueryParams>
          ),
        options
      ),
    10
  );

  const {
    invoiceStatus = 'all',
    pageNumber = 1,
    searchTerm,
    sortBy = 'createdAt',
    sortOrder = 'Desc',
    customerId,
  } = searchParams;

  return {
    searchParams: useMemo(
      () => ({ sortOrder, sortBy, invoiceStatus: invoiceStatus || undefined, searchTerm, pageNumber, customerId }),
      [invoiceStatus, pageNumber, searchTerm, sortBy, sortOrder, customerId]
    ),
    setSearchParams,
  };
};

export const useInvoicesFiltering = () => {
  const { searchParams, setSearchParams } = useQueryParams();

  const setSearchParamsDebounced = useDebounceCallback(setSearchParams, DEBOUNCE_TIME);

  const { searchTerm, customerId } = searchParams;

  const params: InvoiceParams = {
    searchTerm: customerId ? undefined : searchTerm,
    invoiceStatus: searchParams.invoiceStatus !== 'all' ? searchParams.invoiceStatus || undefined : undefined,
    sort: { field: searchParams.sortBy, order: searchParams.sortOrder },
    customerId: customerId || undefined,
  };

  const { data, isFetching, isLoading, error, pagination } = useInvoices({
    params,
    pageNumber: searchParams.pageNumber,
    onPageChange: (pageNumber: number) => setSearchParams({ pageNumber }),
  });

  return {
    invoices: useMemo(() => data ?? [], [data]),
    pagination,
    isFetching: isFetching || isLoading,
    invoiceStatus: searchParams.invoiceStatus || 'all',
    search: searchParams.searchTerm || '',
    error,
    sortParams: params.sort,
    setParams: (params: UseSortableInvoicesParams) => {
      const { searchTerm, ...rest } = params;
      if (Object.keys(rest).length > 0) setSearchParams(toQueryParams(rest));
      if (!isNil(searchTerm) && searchTerm != searchParams.searchTerm) {
        setSearchParamsDebounced({ searchTerm, customerId: '' });
      }
    },
  };
};

const toQueryParams = ({ invoiceStatus, order, pageNumber, searchTerm, sort }: UseSortableInvoicesParams) =>
  Object.entries({
    invoiceStatus,
    pageNumber,
    searchTerm,
    sortBy: sort,
    sortOrder: order,
  }).reduce((acc, [key, value]) => (!isNil(value) ? { ...acc, [key]: value } : acc), {});
