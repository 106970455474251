import { CompanyType, useAccount, useOrganizationPreferences } from '@melio/platform-api';
import { FeatureFlags, useAnonymousFeature } from '@melio/platform-feature-flags';

export const useArProcessingFeeEnabled = () => {
  const { data: account, isLoading: isLoadingMe } = useAccount({ id: 'me' });
  const { data: orgPreferences, isLoading: isOrgPreferencesLoading } = useOrganizationPreferences();
  const [isArProcessingFeeEnabled, isLoadingArProcessingFeeFF] = useAnonymousFeature(
    FeatureFlags.IsArProcessingFeeEnabled,
    false,
  );
  const isArProcessingFeeExempt =
    orgPreferences?.find((item) => item.key === 'arProcessingFeeExempt')?.value === 'true';

  const shouldDisplayAchTransferFee =
    account?.companyType !== CompanyType.AccountingFirm && !isArProcessingFeeExempt && isArProcessingFeeEnabled;
  return {
    shouldDisplayAchTransferFee,
    isLoading: isLoadingMe || isLoadingArProcessingFeeFF || isOrgPreferencesLoading,
  };
};
