import { useMelioIntl } from '@melio/platform-provider';

export const useDeductionDateDescription = () => {
  const { formatMessage, formatDate } = useMelioIntl();

  const getDescription = (isByDueDate?: boolean, selectedDate?: Date) => {
    if (isByDueDate) {
      return formatMessage(
        'activities.batchPayments.screens.paymentIntentsTable.deductionDateHeaderCell.description.byDueDate'
      );
    }
    if (selectedDate) {
      return formatDate(selectedDate, { dateStyle: 'medium' });
    }
    {
      return formatMessage(
        'activities.batchPayments.screens.paymentIntentsTable.deductionDateHeaderCell.description.multiple'
      );
    }
  };

  return { getDescription };
};
