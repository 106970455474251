import { useEffect, useState } from 'react';

type ApiHookOptions = {
  onError?: ARErrorFunction;
  enabled?: boolean;
};

type ApiHook = (options?: ApiHookOptions) => {
  isLoading: boolean;
  isFetching: boolean;
  isFetched: boolean;
};

type UsePreloadApHooksOptions = {
  onError?: ARErrorFunction;
  delay?: number;
};

/**
 * Custom hook that preloads a set of API hooks after a specified delay.
 * @param hooks - An array of API hooks to preload.
 * @param delay - The delay in milliseconds before enabling the hooks. Default is 2000ms.
 */
export function usePreloadApiHooks(hooks: ApiHook[], { delay = 2000, ...options }: UsePreloadApHooksOptions = {}) {
  const [enabled, setEnabled] = useState(!delay);
  useEffect(() => {
    const timeoutId = setTimeout(() => setEnabled(true), delay);
    return () => clearTimeout(timeoutId);
  }, [delay]);
  const results = hooks.map((hook) => hook({ ...options, enabled }));
  return {
    isLoading: results.some((result) => result.isLoading),
    isFetching: results.some((result) => result.isFetching),
    isFetched: results.every((result) => result.isFetched),
  };
}
