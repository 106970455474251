import { Pill } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useIsFeatureInSubscription, usePlansTiers, useSubscription } from '@melio/subscriptions';
import { BooleanFeatureName } from '@melio/subscriptions/src/api';
import React from 'react';

export const PremiumBadge = ({
  featureName,
  showOnFreePlan,
}: {
  featureName: BooleanFeatureName;
  showOnFreePlan?: boolean;
}) => {
  const { formatMessage } = useMelioIntl();
  const subscription = useSubscription();
  const { getIsFreePlan } = usePlansTiers();
  const isFreePlan = subscription && getIsFreePlan(subscription?.planId);
  const { isEligible: isEligibleToFeature } = useIsFeatureInSubscription({
    featureName,
  });

  return (showOnFreePlan || !isFreePlan) && !isEligibleToFeature ? (
    <Pill
      data-testid="approval-workflow-v2.premium-badge"
      label={formatMessage('activities.subscription.contextualPlans.badge')}
      status="brand"
      type="secondary"
    />
  ) : null;
};
