import React from 'react';
import { Box, Flex, Slide } from '@chakra-ui/react';
import { DemoRequestButtonActivity } from '@melio/ap-activities';
import { useUserHasPayments } from '@melio/platform-api';

import { ConfirmationBar } from '@/cl/components/ConfirmationBar/ConfirmationBar.component';
import { zIndex } from '@/cl/layouts/layout.config';
import { useAppTheme } from '@/cl/theme/theme.hooks';
import { usePartnerConfig } from '@/hooks/partners';
import { useConfirmationBarUI } from '@/hooks/useConfirmationBar';
import { useIsMobile as useSmallScreen } from '@/hooks/useIsMobile';
import { getAccessTokenData } from '@/utils/getAccessTokenData.utils';
import { ScreenSplitLayoutTheme } from './screenSplitLayout.theme';

type ScreenSplitProps = {
  firstScreen: React.ReactNode;
  secondScreen: React.ReactNode;
  showScreenMobile?: 'first' | 'second';
  screenGapY?: number;
  screenGapX?: number;
  secondScreenFooter?: React.ReactNode;
  variant?: 'settings';
  sticky?: boolean;
  hideSecondScreen?: boolean;
};

export const ScreenSplitLayout = (props: ScreenSplitProps) => {
  const {
    firstScreen,
    secondScreen,
    screenGapY = 40,
    screenGapX = 64,
    secondScreenFooter,
    showScreenMobile,
    variant,
    sticky,
    hideSecondScreen = false,
  } = props;

  const {
    isOpen: isConfimationBarOpen,
    buttonsCallbacks: { submit, cancel },
    submitButtonProps: submitButtonPropsState,
    cancelButtonProps: cancelButtonPropsState,
  } = useConfirmationBarUI();
  const isSmallScreen = useSmallScreen();
  const accessTokenData = getAccessTokenData();
  const { partnerConfig } = usePartnerConfig();

  const { userHasPayments, isLoading: isUserHasPaymentsLoading } = useUserHasPayments();

  const style = useAppTheme(ScreenSplitLayoutTheme, { screenGapY, screenGapX, showScreenMobile, variant });

  const submitButtonProps = {
    ...submitButtonPropsState,
    ...submit,
  };

  const cancelButtonProps = {
    ...cancelButtonPropsState,
    ...cancel,
  };

  const secondScreenComponent = (
    <Box
      data-component={'split-screen-second-screen-with-confirmation-container'}
      __css={style['secondScreenWithConfirmationBarContainer']}
    >
      <Box data-component={'split-screen-second-container'} __css={style['secondScreenContainer']}>
        <Box data-component={'split-screen-second'} __css={style['secondScreen']}>
          {secondScreen}
        </Box>
      </Box>
      {isConfimationBarOpen && (
        <Box data-component={'confirmation-bar-container'} __css={style['confirmationBarContainer']}>
          <ConfirmationBar submitButtonProps={submitButtonProps} cancelButtonProps={cancelButtonProps} />
        </Box>
      )}
      {secondScreenFooter && !isConfimationBarOpen && (
        <Box data-component={'second-screen-footer'} __css={style['secondScreenFooter']}>
          {secondScreenFooter}
        </Box>
      )}
    </Box>
  );

  return (
    <Box __css={style['container']}>
      <Box data-component={'split-screen-first-container'} __css={style['firstScreenContainer']}>
        <Box data-component={'split-screen-first'} __css={style['firstScreen']}>
          {firstScreen}
          {isSmallScreen && accessTokenData?.organization && !userHasPayments && !isUserHasPaymentsLoading && (
            <Flex position="fixed" right={0} bottom={0} pr={6} pb={6} mb={12}>
              <DemoRequestButtonActivity
                organizationId={accessTokenData.organization.id}
                email={accessTokenData.user.email}
                helpCenterLink={partnerConfig.config.links.helpCenter}
                hideAfterFirstUse
              />
            </Flex>
          )}
        </Box>
      </Box>
      {hideSecondScreen ? null : isSmallScreen ? (
        <Slide
          in={showScreenMobile === 'second'}
          direction="right"
          style={{ zIndex: zIndex.splitSecondScreenSlide, position: 'absolute', ...(!sticky && { overflow: 'auto' }) }}
          transition={{ enter: { duration: 0.2 }, exit: { duration: 0.2 } }}
        >
          {secondScreenComponent}
        </Slide>
      ) : (
        secondScreenComponent
      )}
    </Box>
  );
};
