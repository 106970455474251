/* eslint-disable react-hooks/exhaustive-deps */
import {
  PaymentScheduledNotifyVendorFormWidget,
  PaymentScheduledNotifyVendorFormWidgetFields,
  PaymentScheduledNotifyVendorFormWidgetProps,
} from '@melio/ap-widgets';
import { Group, Modal, Text, useFormSubmissionController } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-provider';
import { SystemMessageDisplay } from '@melio/platform-utils';
import { useEffect } from 'react';

export type NotifyModalScreenProps = {
  isOpen: boolean;
  isSaving?: boolean;
  vendors: PaymentScheduledNotifyVendorFormWidgetProps['vendors'];
  onClose: VoidFunction;
  onDone: (vendorsContact: PaymentScheduledNotifyVendorFormWidgetFields['vendorsContact']) => void;
};

export const NotifyModalScreen: React.VFC<NotifyModalScreenProps> = ({
  isOpen,
  isSaving,
  vendors,
  onClose,
  onDone,
}) => {
  const { formatMessage } = useMelioIntl();

  const { onSubmissionStateChange, submitButtonProps } =
    useFormSubmissionController<PaymentScheduledNotifyVendorFormWidgetFields>();

  const { track } = useAnalytics();
  useEffect(() => {
    isOpen && track('ScheduleVendorNotification', 'Viewed');
  }, [isOpen]);

  const vendorName = vendors.length === 1 ? vendors[0]?.name : undefined;

  const notifyTitle = vendorName
    ? formatMessage('activities.paymentScheduledNotifyVendor.screens.notify.title.singleVendor', {
        vendorName,
      })
    : formatMessage('activities.paymentScheduledNotifyVendor.screens.notify.title.multipleVendors');

  const notifyDescription = vendorName
    ? formatMessage('activities.paymentScheduledNotifyVendor.screens.notify.description.singleVendor', {
        vendorName,
      })
    : formatMessage('activities.paymentScheduledNotifyVendor.screens.notify.description.multipleVendors');

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      data-testid="payment-scheduled-notify-vendor-modal-activity-notify-modal-screen"
      header={notifyTitle}
      primaryButton={{
        ...submitButtonProps,
        variant: 'primary',
        label: formatMessage('activities.paymentScheduledNotifyVendor.screens.notify.continue'),
      }}
    >
      <Group variant="vertical">
        <Text>{notifyDescription}</Text>
        <SystemMessageDisplay />
        <PaymentScheduledNotifyVendorFormWidget
          vendors={vendors}
          onSubmit={({ vendorsContact }) => onDone(vendorsContact)}
          onSubmissionStateChange={onSubmissionStateChange}
          isSaving={isSaving}
        />
      </Group>
    </Modal>
  );
};

NotifyModalScreen.displayName = 'PaymentScheduledNotifyVendorModalActivity.NotifyModalScreen';
