import { BillSubscription, BillSubscriptionStatus, useFundingSources, Vendor } from '@melio/platform-api';
import { usePermissions } from '@melio/platform-permissions';
import { useConfig } from '@melio/platform-provider';

const mutableBillSubscriptionStatuses: BillSubscriptionStatus[] = [
  BillSubscriptionStatus.Created,
  BillSubscriptionStatus.InProgress,
];

export const useBillSubscriptionActions = (billSubscription?: BillSubscription, vendor?: Vendor) => {
  const { can } = usePermissions();
  const {
    settings: {
      payment: {
        editing: { shouldCheckFundingSourceEntitlements },
      },
    },
  } = useConfig();
  const { data: fundingSources } = useFundingSources({ enabled: shouldCheckFundingSourceEntitlements });

  if (!billSubscription || !vendor) {
    return {
      isEditBillSubscriptionAllowed: false,
      isCancelBillSubscriptionAllowed: false,
    };
  }

  const isEntitlementToFundingSource = shouldCheckFundingSourceEntitlements
    ? !!fundingSources?.find((fundingSource) => fundingSource.id === billSubscription.fundingSourceId)
    : true;

  const canEditBillSubscription = can({
    subject: 'billSubscription',
    action: 'update',
    subjectData: {
      vendor: {
        createdById: vendor.createdById,
        managedBy: vendor.managedBy,
      },
      billSubscription: {
        managedBy: billSubscription.managedBy,
        createdById: billSubscription.createdById,
      },
    },
  });

  const canCancelBillSubscription = can({
    subject: 'billSubscription',
    action: 'cancel',
    subjectData: {
      vendor: {
        createdById: vendor.createdById,
        managedBy: vendor.managedBy,
      },
      billSubscription: {
        managedBy: billSubscription.managedBy,
        createdById: billSubscription.createdById,
      },
    },
  });
  const isBillSubscriptionMutable = mutableBillSubscriptionStatuses.includes(billSubscription.status);

  const isEditBillSubscriptionAllowed =
    isBillSubscriptionMutable && canEditBillSubscription && isEntitlementToFundingSource;

  const isCancelBillSubscriptionAllowed = isBillSubscriptionMutable && canCancelBillSubscription;

  return {
    isEditBillSubscriptionAllowed,
    isCancelBillSubscriptionAllowed,
  };
};
