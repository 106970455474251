import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';

import { PaymentReviewLineItem } from '../PaymentReviewLineItem';

export const DeliverySpeedListItemByPayorWidget = forwardRef<unknown, 'div'>((_, ref) => {
  const { formatMessage } = useMelioIntl();

  return (
    <PaymentReviewLineItem
      data-testid="delivery-speed-list-item-by-payor-widget"
      data-component="DeliverySpeedListItemByPayorWidget"
      labelProps={{
        label: formatMessage(`widgets.deliveryMethodListItemByPayor.deliverySpeed.rtp.label`),
      }}
      icon={{ type: 'calendar-move' }}
      contentTestId="delivery-date-list-item-by-payor"
      mainLabelProps={{
        label: formatMessage(`widgets.deliveryMethodListItemByPayor.deliverySpeed.rtp.mainLabel`),
        pillProps: {
          label: formatMessage('widgets.deliveryMethodListItemByPayor.deliverySpeed.rtp.badgeText'),
          status: 'brand',
        },
      }}
      descriptionProps={{
        label: formatMessage(`widgets.deliveryMethodListItemByPayor.deliverySpeed.rtp.description`),
      }}
      ref={ref}
    />
  );
});
