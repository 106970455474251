import { Group, Modal, Text, useFormSubmissionController } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useVendors, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect, useMemo, useState } from 'react';

import { VendorTabAmountCellAmounts } from '../../types';
import { useVendorsBatchActionsData } from '../../useVendorsBatchActionsData';
import { useVendorsBatchPayments } from '../../useVendorsBatchPayments';
import { VendorsBatchPaymentsAmountsModalBody } from './components/VendorsBatchPaymentsModalBody';
import { VendorsBatchPaymentsModalFooter } from './components/VendorsBatchPaymentsModalFooter';
import { FormFields } from './types';

export type Props = {
  vendorsAmountsMap: VendorTabAmountCellAmounts;
  selectedVendorIds: Vendor['id'][];
  isOpen: boolean;
  onClose: () => void;
};

export const VendorsBatchPaymentsModalActivity = withAnalyticsContext<Props>(
  ({ setAnalyticsProperties, vendorsAmountsMap, selectedVendorIds, isOpen, onClose }) => {
    const { formatMessage } = useMelioIntl();
    const { track } = useAnalytics();
    const [amounts, setAmounts] = useState<VendorTabAmountCellAmounts>({});
    const { selectedVendorsIdsWithEmptyAmounts, isLoadingBills, selectedUnpaidBills, selectedVendorsAmountsMap } =
      useVendorsBatchActionsData({ selectedVendorIds, vendorsAmountsMap, enabled: isOpen });
    const { onSubmissionStateChange, submitButtonProps, cancelButtonProps } = useFormSubmissionController<FormFields>();

    setAnalyticsProperties({
      PageName: 'enter-payment-amount',
      Flow: 'vendor-batch-payment',
      Intent: 'pay-vendor',
      VendorsSelected: selectedVendorsIdsWithEmptyAmounts.length,
    });

    useEffect(() => {
      if (isOpen) {
        track('Payment', 'View', {});
      }
    }, [isOpen, track]);

    const handleClose = () => {
      track('Payment', 'Click', { Cta: 'exit' });
      setAmounts({});
      onClose();
    };

    const { isCreatingBills, handleGoToBatchPayments } = useVendorsBatchPayments({
      amountsToPay: { ...selectedVendorsAmountsMap, ...amounts },
      unpaidBills: selectedUnpaidBills,
      onFailure: handleClose,
    });

    const { data: selectedVendors = [], isLoading: isLoadingVendors } = useVendors({
      enabled: isOpen && selectedVendorIds.length > 0,
      params: { search: { 'vendor.id': selectedVendorIds }, expand: 'none' },
    });
    const vendors = useMemo(
      () => selectedVendors.filter((vendor) => selectedVendorsIdsWithEmptyAmounts.includes(vendor.id)),
      [selectedVendors, selectedVendorsIdsWithEmptyAmounts]
    );

    const handleInputChange = (vendor: Vendor, value?: string) => {
      setAmounts((prevAmounts) => {
        if (!value || +value <= 0) {
          const { [vendor.id]: _, ...newAmounts } = prevAmounts;
          return newAmounts;
        }
        return { ...prevAmounts, [vendor.id]: value };
      });
    };

    const handleSubmit = () => {
      track('Payment', 'Click', { Cta: 'continue' });
      handleGoToBatchPayments();
    };

    const isLoading = isLoadingBills || isLoadingVendors;
    return (
      <Modal
        data-testid="vendors-batch-payments-modal"
        isOpen={isOpen}
        header={formatMessage('activities.payDashboard.vendorsTab.vendorsBatchPaymentsModal.header')}
        onClose={handleClose}
        isLoading={isLoading}
        footer={
          <VendorsBatchPaymentsModalFooter
            submitButtonProps={submitButtonProps}
            cancelButtonProps={{ ...cancelButtonProps, onClick: handleClose }}
          />
        }
      >
        <Group variant="vertical">
          <Text>{formatMessage('activities.payDashboard.vendorsTab.vendorsBatchPaymentsModal.description')}</Text>
          <VendorsBatchPaymentsAmountsModalBody
            vendors={vendors}
            vendorsAmountsMap={amounts}
            onChange={handleInputChange}
            isLoading={isLoading}
            isSaving={isCreatingBills}
            onSubmit={handleSubmit}
            onSubmissionStateChange={onSubmissionStateChange}
          />
        </Group>
      </Modal>
    );
  }
);
