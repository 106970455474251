import { withAnalyticsContext } from '@melio/platform-analytics';
import { useWizardSimple, UseWizardSimpleArgs } from '@melio/platform-utils';
import { Route, Routes } from 'react-router-dom';

import { getPaymentAnalyticsFields } from '../../../utils';
import { NewSinglePaymentStepLayout } from '../../NewSinglePaymentStepLayout';
import { VoidAndRefundSentStep } from './steps/VoidAndRefundSentStep';
import { VoidReviewAndConfirmStep } from './steps/VoidReviewAndConfirmStep';
import { Steps, VoidAndRefundFlowDoneAction } from './types';
import { useVoidAndRefundData } from './useVoidAndRefundData';

const firstStep: Steps = 'review';
const locationsMap: UseWizardSimpleArgs<Steps>['locationsMap'] = {
  review: 'review',
  sent: 'sent',
};

type Props = { paymentId: string; goToDashboard: VoidFunction };

export const VoidAndRefundActivity = withAnalyticsContext<Props>(
  ({ paymentId, goToDashboard, setAnalyticsProperties }) => {
    const { payment, isLoading, isVoidAndRefundError, isUpdatingPayment, voidAndRefund, setIsVoidAndRefundError } =
      useVoidAndRefundData(paymentId);

    const { goNext, cancelFlow, completeFlow } = useWizardSimple<Steps>({
      firstStep,
      flowName: 'void-and-refund-payment',
      locationsMap,
      navigationSequence: ['review', 'sent'],
      cancelUrlFallback: '/',
    });

    if (payment) {
      setAnalyticsProperties({
        ...getPaymentAnalyticsFields(payment),
        Intent: 'void-and-refund-payment',
        EntryPoint: 'dashboard-inbox',
      });
    }

    if (isLoading || !payment) {
      return <NewSinglePaymentStepLayout isLoading />;
    }

    const onConfirmVoidAndRefund = async () => {
      try {
        await voidAndRefund(paymentId);
        goNext();
      } catch (error) {
        if ((error as PlatformError)?.code === '400') {
          setIsVoidAndRefundError(true);
          goNext();
        }
      }
    };

    const onFlowDone = (action: VoidAndRefundFlowDoneAction = 'goToDashboard') => {
      if (action === 'goToDashboard') {
        completeFlow(goToDashboard);
      }
    };

    return (
      <div data-testid="void-and-refund-activity">
        <Routes>
          <Route
            path={locationsMap.review}
            element={
              <VoidReviewAndConfirmStep
                payment={payment}
                isLoading={isLoading}
                isLoadingButton={isUpdatingPayment}
                cancelFlow={cancelFlow}
                onConfirmRefund={onConfirmVoidAndRefund}
              />
            }
          />
          <Route
            path={locationsMap.sent}
            element={
              <VoidAndRefundSentStep
                payment={payment}
                isVoidAndRefundError={isVoidAndRefundError}
                onFlowDone={onFlowDone}
              />
            }
          />
        </Routes>
      </div>
    );
  }
);
