// see http://emailregex.com/
const EMAIL_REGEX =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/;

export const emailIsValid = (email?: string) => (email ? EMAIL_REGEX.test(email) : false);

export const optionalEmailIsValid = (email?: string | null) => (email ? EMAIL_REGEX.test(email) : true);

export const generateEmailDisplayName = (email: string) => {
  if (!email) {
    return '';
  }

  const [user, domain] = email.split('@');

  if (user.length <= 2) {
    return `${user[0]}*@${domain}`;
  }

  const asterisk = '*'.repeat(user.length - 2);
  const newUser = user.replace(/(.{1}).*(.{1})/, '$1' + asterisk + '$2');
  return `${newUser}@${domain}`;
};
