import { Icon } from '@chakra-ui/react';

export const USBankChevronDownIcon = () => (
  <Icon height={'24px'} width={'24px'}>
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="24" viewBox="0 0 21 20" fill="none">
      <path
        d="M5.39057 6.25L10.8606 11.72L16.3306 6.25L17.3906 7.31L11.3906 13.31C11.2499 13.4505 11.0593 13.5293 10.8606 13.5293C10.6618 13.5293 10.4712 13.4505 10.3306 13.31L4.33057 7.31L5.39057 6.25Z"
        fill="#555555"
      />
    </svg>
  </Icon>
);
