import { _createFormFieldInput, Button, FormSelectNewOption, FormSelectNewProps, SelectNew } from '@melio/penny';
import { AccountingPlatformSlug, useAccountingPlatformBankAccounts, useAccountingPlatforms } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import { ChangeEvent, ChangeEventHandler, useEffect, useState } from 'react';

export type AccountingPlatformBankAccountSelectWidgetProps = Omit<FormSelectNewProps, 'options'> & {
  newBankAccountName: string;
  disabledNewOption?: boolean;
  onCreateOption?: (name: string) => void;
};

export const AccountingPlatformBankAccountSelectWidget = _createFormFieldInput(
  forwardRef<AccountingPlatformBankAccountSelectWidgetProps, 'input'>(
    (
      {
        onChange,
        placeholder,
        newBankAccountName,
        onCreateOption,
        isReadOnly,
        isViewMode,
        viewModePlaceholder,
        disabledNewOption = false,
        ...props
      },
      ref
    ) => {
      const [accountingPlatformAccountOptions, setAccountingPlatformAccountOptions] = useState<FormSelectNewOption[]>(
        []
      );
      const [filteredOptions, setFilteredOptions] = useState<FormSelectNewOption[]>([]);
      const [query, setQuery] = useState<string>('');
      const [newOptionWasCreated, setNewOptionWasCreated] = useState(false);
      const { isFetching: accountingPlatformIsLoading, activeAccountingPlatform } = useAccountingPlatforms();
      const {
        data: accountingPlatformBankAccount,
        isFetched,
        isLoading: isLoadingBankAccounts,
      } = useAccountingPlatformBankAccounts();

      const newBankAccountAlreadyExists = accountingPlatformAccountOptions?.find(
        (bankAccountOption) => bankAccountOption.label === newBankAccountName
      );
      const isQuickBooksDesktopAccountingPlatform =
        activeAccountingPlatform?.accountingSlug === AccountingPlatformSlug.QuickBooksDesktop;

      const { formatMessage } = useMelioIntl();

      useEffect(() => {
        if (isFetched && accountingPlatformBankAccount?.length && accountingPlatformBankAccount?.length > 0) {
          setAccountingPlatformAccountOptions(
            accountingPlatformBankAccount?.map((accountingPlatformBankAccount) => ({
              value: accountingPlatformBankAccount?.id,
              label: accountingPlatformBankAccount?.name,
            }))
          );
        }
      }, [accountingPlatformBankAccount, isFetched]);

      useEffect(() => {
        setFilteredOptions(accountingPlatformAccountOptions);
      }, [accountingPlatformAccountOptions]);

      const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setQuery('');
        setFilteredOptions(accountingPlatformAccountOptions);
        onChange?.(event);
      };

      const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
        setFilteredOptions(
          accountingPlatformAccountOptions.filter((accountingPlatformAccountOption) =>
            accountingPlatformAccountOption.label.toLowerCase().includes(e.target.value.toLowerCase())
          )
        );
      };

      const handleCreateOption = () => {
        if (newBankAccountName) {
          setAccountingPlatformAccountOptions((value) => [
            ...value,
            {
              value: newBankAccountName,
              label: newBankAccountName,
            },
          ]);
          onChange?.({ target: { value: newBankAccountName } } as ChangeEvent<HTMLInputElement>);
          onCreateOption?.(newBankAccountName);
          setNewOptionWasCreated(true);
        }
      };
      const shouldDisplayAddButton =
        !isQuickBooksDesktopAccountingPlatform &&
        !disabledNewOption &&
        !newOptionWasCreated &&
        !newBankAccountAlreadyExists;

      return (
        <SelectNew
          data-component="AccountingPlatformAccountSelectWidget"
          ref={ref}
          isLoading={isLoadingBankAccounts || accountingPlatformIsLoading}
          placeholder={placeholder}
          viewModePlaceholder={viewModePlaceholder}
          footer={
            shouldDisplayAddButton && (
              <SelectNew.Footer>
                <Button
                  variant="secondary"
                  data-testid="accounting-platform-select-create-new"
                  isFullWidth
                  onClick={handleCreateOption}
                  label={formatMessage('form.accountingPlatformAccountSelect.createLabel')}
                />
              </SelectNew.Footer>
            )
          }
          emptyState={formatMessage('widgets.fundingSources.reconciliationForm.noResultsMessage', { value: query })}
          {...props}
          onChange={handleChange}
          isViewMode={isViewMode}
          isReadOnly={isReadOnly}
          searchBarProps={{
            value: query,
            onChange: onSearchChange,
            options: filteredOptions,
          }}
          options={accountingPlatformAccountOptions}
        />
      );
    }
  )
);

AccountingPlatformBankAccountSelectWidget.displayName = 'AccountingPlatformBankAccountSelectWidget';
