import { VisuallyHidden } from '@chakra-ui/react';
import { Container } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import React, { forwardRef } from 'react';

import {
  MissingVendorInfoExtendedUnmanagedForm,
  MissingVendorInfoExtendedUnmanagedFormProps,
} from '../MissingVendorInfoExtendedUnmanagedForm/MissingVendorInfoExtendedUnmanagedForm';

export type MissingVendorInfoDrawerDetailsHandler = {
  scrollIntoView: () => void;
};

export const MissingVendorInfoDrawerDetails = forwardRef<
  MissingVendorInfoDrawerDetailsHandler,
  MissingVendorInfoExtendedUnmanagedFormProps
>(({ onSubmit, onSubmissionStateChange, defaultValues, isSaving, vendorId, inlineApiErrorCodes }, ref) => {
  const { track } = useAnalytics();
  const { formatMessage } = useMelioIntl();

  const boxRef = React.useRef<HTMLDivElement>(null);
  React.useImperativeHandle(ref, () => ({
    scrollIntoView: () => {
      boxRef.current?.scrollIntoView({ behavior: 'smooth' });
    },
  }));

  React.useEffect(() => {
    track('EditVendorsDetails', 'Viewed');
  }, [track]);

  return (
    <Container
      overflow="initial"
      ref={boxRef}
      data-testid="missing-vendor-info-details"
      data-component="MissingVendorInfoDrawerDetails"
    >
      <VisuallyHidden role="status" aria-live="polite">
        {formatMessage('widgets.missingVendorInfoDrawer.missingVendorInfoDetails.screenTitle')}
      </VisuallyHidden>
      <MissingVendorInfoExtendedUnmanagedForm
        vendorId={vendorId}
        onSubmit={onSubmit}
        onSubmissionStateChange={onSubmissionStateChange}
        defaultValues={defaultValues}
        isSaving={isSaving}
        size="small"
        inlineApiErrorCodes={inlineApiErrorCodes}
      />
    </Container>
  );
});

MissingVendorInfoDrawerDetails.displayName = 'MissingVendorInfoDrawerDetails';
