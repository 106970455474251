import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';
import { IconButton } from '@melio/penny';
import { FileInfo, ScannedInvoice } from '@melio/platform-api';

import { Loader } from '@/cl/components/Loader/Loader.component';
import { useScannedInvoiceReviewAnalytics } from '@/hooks/analytics/scannedInvoiceReviewAnalytics.jsx';
import { usePlatformIntl } from '@/translations/Intl';

type Props = {
  scannedInvoice: ScannedInvoice;
  fileInfo: FileInfo;
};

export const ScannedInvoiceFilePreview = ({ scannedInvoice, fileInfo }: Props) => {
  const [page, setPage] = useState<number>(0);
  const [, setImageLoading] = useState<boolean>(false);
  const pagesNumber = useMemo(() => fileInfo.previewUrls?.length || 0, [fileInfo]);
  const pageLabel = useMemo(() => `${page + 1} / ${pagesNumber}`, [page, pagesNumber]);
  const isNextDisabled = page + 1 >= pagesNumber;
  const isPreviousDisabled = page === 0;
  const onImageLoaded = useCallback(() => setImageLoading(false), []);
  const { trackOnFilePagination, trackOnFileDownload } = useScannedInvoiceReviewAnalytics({ scannedInvoice });
  const onNextPage = useCallback(() => {
    trackOnFilePagination();
    setPage(page + 1);
  }, [trackOnFilePagination, page]);
  const onPrevPage = useCallback(() => {
    trackOnFilePagination();
    setPage(page - 1);
  }, [trackOnFilePagination, page]);
  useEffect(() => setImageLoading(true), [fileInfo, page]);
  const { formatMessage } = usePlatformIntl();

  return (
    <Flex
      position="relative"
      height="100%"
      width="100%"
      justify="center"
      borderWidth="2px"
      borderStyle="dashed"
      borderColor="global.neutral.400"
      borderRadius="lg"
    >
      <Image
        src={pagesNumber > 0 ? fileInfo.previewUrls[page] : fileInfo.url}
        alt={`invoice-page-${page}`}
        onLoad={onImageLoaded}
        fallback={<Loader />}
        objectFit="contain"
      />
      {pagesNumber > 1 && (
        <Flex
          position="absolute"
          bottom="24px"
          mx="auto"
          gridGap="32px"
          align="center"
          justify="space-between"
          backgroundColor="global.neutral.300"
          borderRadius="l"
        >
          <Box padding={'8px'}>
            <IconButton
              size="medium"
              icon="caret-left"
              onClick={onPrevPage}
              isDisabled={isPreviousDisabled}
              aria-label="prev-page"
              variant="naked"
            />
          </Box>
          <Box textStyle="caption1Semi">
            {formatMessage('widgets.ScannedInvoiceFilePreview.pageCaption', { pageLabel })}
          </Box>
          <Box padding={'8px'}>
            <IconButton
              size="medium"
              icon="caret-right"
              onClick={onNextPage}
              isDisabled={isNextDisabled}
              aria-label="next-page"
              variant="naked"
            />
          </Box>
        </Flex>
      )}
      <Box
        position="absolute"
        right="24px"
        bottom="24px"
        as="a"
        // @ts-ignore
        href={fileInfo.url}
      >
        <IconButton size="small" icon="download" onClick={() => trackOnFileDownload()} aria-label="next-page" />
      </Box>
    </Flex>
  );
};
