import { useInvalidFundingSources } from '@melio/ap-domain';
import { useFundingSourcesIconKeys } from '@melio/ap-widgets';
import { FsTypeOption, FundingSource } from '@melio/platform-api';
import { groupBy } from 'lodash';
import { useMemo } from 'react';

import { FundingSourceSelectionMenuItemProps } from './FundingSourceSelectionMenuItem';

export function useGroupedSources(fundingSources: FundingSource[], paymentFundingSourceTypesOptions?: FsTypeOption[]) {
  const { getFundingSourceTooltip, getIsValidFundingSource } = useInvalidFundingSources(
    paymentFundingSourceTypesOptions
  );
  const fundingSourcesAndIcons = useFundingSourcesIconKeys(fundingSources);

  const idToType = useMemo(
    () => Object.fromEntries(fundingSources.map(({ id, type }) => [id, type] as const)),
    [fundingSources]
  );
  const options: FundingSourceSelectionMenuItemProps[] = fundingSourcesAndIcons.map(({ fundingSource, icon }) => ({
    label: fundingSource.displayName,
    value: fundingSource.id,
    description: fundingSource.nickname,
    icon,
    disabled: {
      isDisabled: !getIsValidFundingSource(fundingSource),
      message: getFundingSourceTooltip(fundingSource),
    },
  }));
  const groupedSources = useMemo(() => groupBy(options, ({ value }) => idToType[value]), [options, idToType]);
  return {
    groupedSources,
  };
}
