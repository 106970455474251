import { compact, get } from 'lodash';
import { NotificationPreferences, useCollaborator } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { usePermissions } from '@melio/platform-permissions';

import { usePartnerConfig } from '@/hooks/partners';
import { PartnerConfig } from '@/partnersConfig.types';
import { usePlatformIntl } from '@/translations/Intl';
import { isAdmin } from '@/utils/user.utils';

export type NotificationPreferenceKey = keyof NotificationPreferences;

type NotificationOption = {
  key: NotificationPreferenceKey;
  label: string;
};

export type NotificationGroup = {
  title: string;
  options: NotificationOption[];
};

const returnNotificationOptionIfSupported = (notificationGroup: NotificationOption, partnerConfig: PartnerConfig) => {
  if (get(partnerConfig.supportedNotificationGroupsOptions, notificationGroup.key, false)) {
    return notificationGroup;
  }
  return false;
};

export const useNotificationGroups: () => NotificationGroup[] = () => {
  const { partnerConfig } = usePartnerConfig();
  const { can } = usePermissions();
  const { formatMessage } = usePlatformIntl();
  const { data: actor } = useCollaborator({ id: 'me' });
  const [isQboUpcomingBillsReminderPartnerships] = useFeature(FeatureFlags.qboUpcomingBillsReminderPartnerships, false);

  const notificationOptions: NotificationOption[] = [
    {
      key: 'billDueReminder',
      label: formatMessage('widgets.notificationPreferencesSettings.paymentNotifications.options.billDueReminder'),
    },
    {
      key: 'paymentByMeScheduled',
      label: formatMessage('widgets.notificationPreferencesSettings.paymentNotifications.options.paymentByMeScheduled'),
    },
    {
      key: 'paymentByMeUpdated',
      label: formatMessage('widgets.notificationPreferencesSettings.paymentNotifications.options.paymentByMeUpdated'),
    },
    {
      key: 'feeReceipt',
      label: formatMessage('widgets.notificationPreferencesSettings.paymentNotifications.options.feeReceipt'),
    },
  ];

  if (isQboUpcomingBillsReminderPartnerships) {
    notificationOptions.push({
      key: 'billQbSynced',
      label: formatMessage('widgets.notificationPreferencesSettings.paymentNotifications.options.billQbSynced'),
    });
  }
  if (actor?.roleUniqueName && isAdmin(actor.roleUniqueName)) {
    notificationOptions.push({
      key: 'paymentByOtherScheduled',
      label: formatMessage(
        'widgets.notificationPreferencesSettings.paymentNotifications.options.paymentByOtherScheduled',
      ),
    });
  }
  const isApprover = can({ subject: 'payment:approvalDecision', action: 'approve' });

  if (isApprover) {
    notificationOptions.push({
      key: 'approvalByMeRequired',
      label: formatMessage('widgets.notificationPreferencesSettings.paymentNotifications.options.approvalByMeRequired'),
    });
  }

  if (!isApprover) {
    notificationOptions.push({
      key: 'approvalByOtherActions',
      label: formatMessage(
        'widgets.notificationPreferencesSettings.paymentNotifications.options.approvalByOtherActions',
      ),
    });
  }

  return [
    {
      title: formatMessage('widgets.notificationPreferencesSettings.paymentNotifications.title'),
      options: compact(notificationOptions.map((option) => returnNotificationOptionIfSupported(option, partnerConfig))),
    },
  ];
};
