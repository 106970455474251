import { Group, NakedButton, Table, Text } from '@melio/penny';
import { Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { LoansTabItem } from '../types';

export const FinancingTabVendorNameCell = ({
  loansTabItem,
  onViewPayment,
}: {
  loansTabItem: LoansTabItem;
  onViewPayment: ({ id }: { id: Payment['id'] }) => void;
}) => {
  const { formatMessage } = useMelioIntl();

  const {
    id,
    vendor: { name: vendorName },
  } = loansTabItem;

  const onClickViewPayment = (event: React.MouseEvent) => {
    event.stopPropagation();
    onViewPayment({ id });
  };

  return (
    <Table.Cell data-testid={`financing-tab-row-${id}-vendor-name-cell`}>
      <Group variant="vertical" alignItems="flex-start" spacing="xxs">
        <Text textStyle="body3">{vendorName}</Text>
        <NakedButton
          shouldSupportEllipsis
          size="medium"
          variant="secondary"
          label={formatMessage('activities.payDashboard.financingTab.table.cells.vendorName.viewPayment')}
          data-testid={`financing-tab-row-${id}-vendor-name-cell-view-payment`}
          link={{ href: '#' }}
          onClick={onClickViewPayment}
          aria-label={formatMessage('activities.payDashboard.table.vendorName.cell.paymentView.link.ariaLabel')}
        />
      </Group>
    </Table.Cell>
  );
};
