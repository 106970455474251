import { useInvoicePreference, useMelioIntl } from '@melio/ar-domain';
import { useToast } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { EditInvoiceNoteDialogScreen, InvoiceNoteFormFields } from './screens';

type EditInvoiceNoteDialogActivityProps = {
  onError?: ARErrorFunction;
  onDone: VoidFunction;
  onClose: VoidFunction;
};

export const EditInvoiceNoteDialogActivity = forwardRef<EditInvoiceNoteDialogActivityProps>(
  ({ onDone, onClose, onError, ...props }, ref) => {
    const { data: invoicePreferences, isFetching, isMutating, update } = useInvoicePreference({ onError });
    const { toast } = useToast();
    const { formatMessage } = useMelioIntl();

    const onSubmit = (values: InvoiceNoteFormFields) => {
      update(values)
        .then(() => {
          onDone();
          toast({
            type: 'success',
            title: formatMessage('ar.settings.flows.invoiceSettings.sections.editInvoiceNote.toast.success.label'),
          });
        })
        .catch((err: ARPlatformError) => {
          toast({
            type: 'error',
            title: formatMessage('ar.settings.flows.invoiceSettings.sections.editInvoiceNote.toast.failure.label'),
          });
          onError?.(err);
        });
    };

    return (
      <EditInvoiceNoteDialogScreen
        data-testid="edit-invoice-note-dialog"
        onSubmit={onSubmit}
        onClose={onClose}
        isOpen
        defaultValues={{ noteOnInvoice: invoicePreferences?.noteOnInvoice ?? '' }}
        isLoading={isFetching}
        isSaving={isMutating}
        ref={ref}
        {...props}
      />
    );
  }
);
