import { DeliveryMethodType, Payment } from '@melio/platform-api';

import {
  isPaymentCompletedRefund,
  isPaymentCompletedVoidAndRefund,
  isPaymentScheduledRefund,
  isPaymentScheduledVoid,
  isPaymentScheduledVoidAndRefund,
} from '../../../utils/pay-flow/Payment.utils';
import { PayDashboardTabs } from '../types';

const getKeyByPaymentStatus = (payment: Payment) => {
  switch (payment.status) {
    case 'completed':
      if (payment.markedAsPaid) {
        return PaymentStatus.MarkAsPaid;
      }
      return PaymentStatus.Paid;
    case 'in-progress':
      return PaymentStatus.inProgress;
    case 'scheduled':
    case 'blocked':
      if (payment.deliveryMethod?.type === DeliveryMethodType.VirtualAccount) {
        return PaymentStatus.PendingVendor;
      } else if (payment.approvalDecisionStatus === 'pending') {
        return PaymentStatus.PendingApproval;
      } else {
        return PaymentStatus.Scheduled;
      }
    case 'failed': {
      {
        const isProcessingVoid = isPaymentScheduledVoid(payment);
        const isProcessingRefund = isPaymentScheduledRefund(payment);
        const isProcessingVoidAndRefund = isPaymentScheduledVoidAndRefund(payment);
        const isCompletedRefund = isPaymentCompletedRefund(payment);
        const isCompletedVoidAndRefund = isPaymentCompletedVoidAndRefund(payment);

        if (isProcessingVoid) {
          return PaymentStatus.VoidInProgress;
        } else if (isProcessingRefund) {
          return PaymentStatus.RefundInProgress;
        } else if (isProcessingVoidAndRefund) {
          return PaymentStatus.VoidAndRefundInProgress;
        } else if (isCompletedRefund) {
          return PaymentStatus.Refunded;
        } else if (isCompletedVoidAndRefund) {
          return PaymentStatus.VoidedAndRefunded;
        } else {
          return PaymentStatus.Failed;
        }
      }
    }
    case 'canceled':
      return PaymentStatus.Canceled;
  }
};

enum PaymentStatus {
  Paid = 'Paid',
  inProgress = 'InProgress',
  PendingApproval = 'PendingApproval',
  PendingVendor = 'PendingVendor',
  Scheduled = 'Scheduled',
  Failed = 'Failed',
  VoidInProgress = 'VoidInProgress',
  RefundInProgress = 'RefundInProgress',
  VoidAndRefundInProgress = 'VoidAndRefundInProgress',
  Refunded = 'Refunded',
  VoidedAndRefunded = 'VoidedAndRefunded',
  MarkAsPaid = 'MarkAsPaid',
  Canceled = 'canceled',
}

export const createPaymentStatusCountMapObject = (payments: Payment[]) => {
  const paymentStatusCounts: { [key in PaymentStatus]: number } = {} as { [key in PaymentStatus]: number };

  for (const status of Object.values(PaymentStatus)) {
    paymentStatusCounts[status] = 0;
  }

  payments.forEach((payment) => {
    const key = getKeyByPaymentStatus(payment);
    paymentStatusCounts[key] += payment.amount;
  });
  return paymentStatusCounts;
};

export const getSyncNowAnalyticsProperties = (selectedTab?: string) => {
  let pageNameSuffix = 'unknown';
  if (selectedTab === PayDashboardTabs.Bills) {
    pageNameSuffix = 'bills';
  } else if (selectedTab === PayDashboardTabs.Payments) {
    pageNameSuffix = 'payments';
  } else if (selectedTab === PayDashboardTabs.Vendors) {
    pageNameSuffix = 'vendors';
  }
  return {
    PageName: `dashboard-${pageNameSuffix}`,
    Flow: 'dashboard',
    Intent: 'accounting-software-sync',
    EntryPoint: `dashboard-${pageNameSuffix}`,
  };
};
