import React from 'react';
import { Group, Icon, Text } from '@melio/penny';

import { usePlatformIntl } from '@/translations/Intl';

export const AchTransferFee = () => {
  const { formatMessage } = usePlatformIntl();
  return (
    <Group data-testid="ach-transfer-fee">
      <Icon type="bank" />
      <Group variant="vertical">
        <Text textStyle="body2Semi">{formatMessage('widgets.receivingMethodsSettings.achBankTransfer.title')}</Text>
        <Text textStyle="body3">
          {formatMessage('widgets.receivingMethodsSettings.achBankTransferFeesSection.description')}
        </Text>
      </Group>
    </Group>
  );
};
