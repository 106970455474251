import { Box } from '@chakra-ui/react';
import { DeliveryMethodList } from '@melio/ap-widgets';
import { Group, Typography } from '@melio/penny';
import {
  Account,
  DeliveryMethodByPayor,
  FreeChecksData,
  FundingSource,
  SupportedDeliveryMethodTypeOption,
  Vendor,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

type DeliveryMethodSelectionContentProps = {
  account?: Account;
  data: DeliveryMethodByPayor[];
  fundingSource?: FundingSource;
  freeChecks?: FreeChecksData;
  selectedId?: DeliveryMethodByPayor['id'];
  vendor?: Vendor;
  domesticDeliveryMethodTypeOptions: SupportedDeliveryMethodTypeOption[];
  internationalDeliveryMethodTypeOptions: SupportedDeliveryMethodTypeOption[];
  onSelect: (deliveryMethodId: DeliveryMethodByPayor['id']) => void;
  onAdd: (onAddArgs: { type: DeliveryMethodByPayor['type']; isInternationalFx?: boolean }) => void;
  onEdit?: (type: Omit<DeliveryMethodByPayor['type'], 'virtual-account'>) => void;
};

export const DeliveryMethodSelectionContent = ({
  domesticDeliveryMethodTypeOptions,
  internationalDeliveryMethodTypeOptions,
  ...defaultListProps
}: DeliveryMethodSelectionContentProps) => {
  const { formatMessage } = useMelioIntl();

  const DomesticDeliveryMethodList = () => (
    <Group variant="vertical">
      <DeliveryMethodList {...defaultListProps} deliveryMethodTypeOptions={domesticDeliveryMethodTypeOptions} />
    </Group>
  );

  const InternationalDeliveryMethodList = () => (
    <Group variant="vertical">
      <DeliveryMethodList {...defaultListProps} deliveryMethodTypeOptions={internationalDeliveryMethodTypeOptions} />
    </Group>
  );

  return internationalDeliveryMethodTypeOptions.length ? (
    <Group variant="vertical" width="full" spacing="m">
      <Group variant="vertical" width="full">
        <Typography.SectionLabel
          as="h3"
          label={formatMessage(
            'activities.deliveryMethodSelection.screens.deliveryMethodSelection.sectionTitles.domestic'
          )}
        />
        <DomesticDeliveryMethodList />
      </Group>
      <Group variant="vertical" width="full">
        <Typography.SectionLabel
          as="h3"
          label={formatMessage(
            'activities.deliveryMethodSelection.screens.deliveryMethodSelection.sectionTitles.international'
          )}
        />
        <InternationalDeliveryMethodList />
      </Group>
    </Group>
  ) : (
    <Box width="100%">
      <DomesticDeliveryMethodList />
    </Box>
  );
};
