import { useRoleText } from '@melio/ap-domain';
import { UserDetails } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { SectionBlock } from './SectionBlock';

type Props = { scheduledDate: Date; user: UserDetails };
export const PaymentScheduledBySection = ({ scheduledDate, user }: Props) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const { getRoleText } = useRoleText();

  return (
    <SectionBlock
      testId="pay-dashboard-payment-details-scheduled-by-section"
      title={{ label: formatMessage('widgets.paymentDetails.scheduledBy.title') }}
      body={{
        label: formatMessage('widgets.paymentDetails.scheduledBy.name', {
          firstName: user.firstName,
          lastName: user.lastName,
        }),
        badgeProps: { label: getRoleText(user.role), type: 'secondary', status: 'neutral' },
      }}
      description={{
        label: formatMessage('widgets.paymentDetails.scheduledBy.subtitle', {
          scheduledDate: formatDate(scheduledDate, { dateStyle: 'medium' }),
        }),
      }}
    />
  );
};
