import { PaymentIntent } from '@melio/platform-api';
import { isSameDay } from 'date-fns';
import { isEqual } from 'lodash';

import { ExtendedPaymentIntent } from '../../../utils/pay-flow/useExtendedPaymentIntent';
import { UpdatedFields } from '../SinglePaymentFlow/types';

export const getDifferenceKeysByValue = (
  originalPaymentIntent: PaymentIntent | ExtendedPaymentIntent,
  updatedPaymentIntent: PaymentIntent | ExtendedPaymentIntent
): UpdatedFields[] => {
  const diff: UpdatedFields[] = [];

  if (!isEqual(originalPaymentIntent.fundingSourceId, updatedPaymentIntent.fundingSourceId)) {
    diff.push('fundingSourceId');
  }
  if (
    originalPaymentIntent.scheduledDate &&
    updatedPaymentIntent.scheduledDate &&
    !isSameDay(originalPaymentIntent.scheduledDate, updatedPaymentIntent.scheduledDate)
  ) {
    diff.push('scheduledDate');
  }
  if (!isEqual(originalPaymentIntent.deliveryMethod, updatedPaymentIntent.deliveryMethod)) {
    diff.push('deliveryMethod');
  }
  if (
    originalPaymentIntent.effectiveDeliveryDate &&
    updatedPaymentIntent.effectiveDeliveryDate &&
    !isSameDay(originalPaymentIntent.effectiveDeliveryDate, updatedPaymentIntent.effectiveDeliveryDate)
  ) {
    diff.push('effectiveDeliveryDate');
  }

  return diff;
};
