import { FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';

export const useFundingSourceHeaderDescription = () => {
  const { formatMessage } = useMelioIntl();

  const getDescription = (fundingSources: FundingSource[], selectedFundingSourceId?: FundingSource['id']) => {
    const selectedFundingSource = fundingSources.find((fs) => fs.id === selectedFundingSourceId);
    const selectedFundingSourceName = selectedFundingSource?.displayName;
    return (
      selectedFundingSourceName ||
      formatMessage(
        'activities.batchPayments.screens.paymentIntentsTable.fundingSourceHeaderSelectCell.description.multiple'
      )
    );
  };

  return { getDescription };
};
