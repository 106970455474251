import { withSystemMessageProvider } from '@melio/platform-utils';

import { MemoToVendorModalScreen } from './screens';
import { MemoToVendorActivityProps } from './types';
import { useMemoToVendor } from './useMemoToVendor';

export type ModalActivityProps = MemoToVendorActivityProps & {
  isOpen: boolean;
};

export const MemoToVendorModalActivity = withSystemMessageProvider(
  ({
    isOpen,
    onClose,
    onDone,
    onError,
    memo,
    vendorId,
    isUpdatingMemo,
    defaultMemo,
    memoValidation,
  }: ModalActivityProps) => {
    const { hideEmailField, vendorName, vendorEmail, isSaving, mode, isLoading, handleDone, memoString } =
      useMemoToVendor({
        vendorId,
        onError,
        onDone,
        memo,
        defaultMemo,
        isUpdatingMemo,
      });

    return (
      <MemoToVendorModalScreen
        hideEmailField={hideEmailField}
        mode={mode}
        isOpen={isOpen}
        vendorName={vendorName}
        isLoading={isLoading}
        isSaving={isSaving}
        memo={memoString}
        email={vendorEmail}
        onClose={onClose}
        onDone={handleDone}
        memoValidation={memoValidation}
      />
    );
  }
);

MemoToVendorModalActivity.displayName = 'MemoToVendorModalActivity';
