/* eslint-disable react-hooks/exhaustive-deps */
import { StatusModal } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { Card } from '@melio/platform-api';
import { MessageKey } from '@melio/platform-i18n';
import { useMelioIntl } from '@melio/platform-provider';
import { useEffect } from 'react';

export type CardValidationModalScreenProps = {
  isOpen: boolean;
  onDone: VoidFunction;
  cardType?: Card['type'];
};

type MessagePart = 'title' | 'content' | 'continue';

const getMessageByCardType = ({ cardType, part }: { cardType?: Card['type']; part: MessagePart }): MessageKey => {
  const map: Record<Card['type'] | 'card', Record<MessagePart, MessageKey>> = {
    credit: {
      title: 'activities.addCardFundingSource.screens.cardValidation.credit.title',
      content: 'activities.addCardFundingSource.screens.cardValidation.credit.content',
      continue: 'activities.addCardFundingSource.screens.cardValidation.credit.continue',
    },
    debit: {
      title: 'activities.addCardFundingSource.screens.cardValidation.debit.title',
      content: 'activities.addCardFundingSource.screens.cardValidation.debit.content',
      continue: 'activities.addCardFundingSource.screens.cardValidation.debit.continue',
    },
    card: {
      title: 'activities.addCardFundingSource.screens.cardValidation.card.title',
      content: 'activities.addCardFundingSource.screens.cardValidation.card.content',
      continue: 'activities.addCardFundingSource.screens.cardValidation.card.continue',
    },
  };

  return map[cardType ?? 'card'][part];
};

export const CardValidationModalScreen: React.VFC<CardValidationModalScreenProps> = ({ isOpen, onDone, cardType }) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  useEffect(() => {
    if (isOpen) {
      track('CCDetailsFailed', 'View');
    }
  }, [isOpen]);

  return (
    <StatusModal
      data-testid="add-card-funding-source-activity-card-validation-modal-screen"
      variant="warning"
      header={formatMessage(getMessageByCardType({ cardType, part: 'title' }))}
      isOpen={isOpen}
      onClose={onDone}
      secondaryButton={{
        label: formatMessage(getMessageByCardType({ cardType, part: 'continue' })),
        variant: 'tertiary',
        onClick: () => {
          track('CCDetailsLinkNewCard', 'Chose');
          onDone();
        },
      }}
    >
      {formatMessage(getMessageByCardType({ cardType, part: 'content' }))}
    </StatusModal>
  );
};

CardValidationModalScreen.displayName = 'AddCardFundingSourceActivity.CardValidationModalScreen';
