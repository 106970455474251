import { useMelioIntl } from '@melio/platform-i18n';

import { BillsTabSortableCells, BillsTabSortableColumn, CELLS_IDS } from './types';

export const useBillsTabSortableColumns = () => {
  const { formatMessage } = useMelioIntl();

  const dueDateColumn: BillsTabSortableColumn = {
    label: formatMessage('activities.payDashboard.billsTab.table.headers.dueDate'),
    cellId: CELLS_IDS.DUE_DATE,
    defaultSortOrder: 'asc',
  };

  const amountColumn: BillsTabSortableColumn = {
    label: formatMessage('activities.payDashboard.billsTab.table.headers.amount'),
    cellId: CELLS_IDS.AMOUNT,
    defaultSortOrder: 'desc',
  };

  const vendorNameColumn: BillsTabSortableColumn = {
    label: formatMessage('activities.payDashboard.billsTab.table.headers.vendor'),
    cellId: CELLS_IDS.VENDOR_NAME,
    defaultSortOrder: 'asc',
  };

  const billColumn: BillsTabSortableColumn = {
    label: formatMessage('activities.payDashboard.billsTab.table.headers.bill'),
    cellId: CELLS_IDS.BILL,
    defaultSortOrder: 'asc',
  };

  const createdAtColumn: BillsTabSortableColumn = {
    label: formatMessage('activities.payDashboard.billsTab.table.headers.createdAt'),
    cellId: CELLS_IDS.CREATED_AT,
    defaultSortOrder: 'desc',
  };

  const sortableColumns: { [key in BillsTabSortableCells]: BillsTabSortableColumn } = {
    [CELLS_IDS.DUE_DATE]: dueDateColumn,
    [CELLS_IDS.VENDOR_NAME]: vendorNameColumn,
    [CELLS_IDS.BILL]: billColumn,
    [CELLS_IDS.CREATED_AT]: createdAtColumn,
    [CELLS_IDS.AMOUNT]: amountColumn,
  };

  return { sortableColumns };
};
