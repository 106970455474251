import { useIsAccountingClient, useIsAccountingFirm } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';

export const useLearnMoreLink = () => {
  const {
    settings: { subscriptions },
  } = useConfig();
  const isAccountingFirm = useIsAccountingFirm();
  const isAccountingClient = useIsAccountingClient();

  const learnMoreLinks = subscriptions?.links.learnMore;
  const learnMoreLink = isAccountingFirm
    ? learnMoreLinks?.accountingFirms
    : isAccountingClient
    ? learnMoreLinks?.accountingClients
    : learnMoreLinks?.smb;

  return learnMoreLink || '';
};
