import { Box } from '@chakra-ui/react';
import { FormattedMessage } from '@melio/ar-domain';
import { Group, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export const FinancialDetailsHeader = forwardRef((_props, ref) => (
  <Box ref={ref}>
    <Group variant="vertical">
      <Text as="h2" textStyle="heading2Semi">
        <FormattedMessage id="ar.onboarding.components.financialInformation.activity.header.text" />
      </Text>
      <Text as="p" textStyle="body2" color="neutral.darker">
        <FormattedMessage id="ar.onboarding.components.financialInformation.activity.subHeader.text" />
      </Text>
    </Group>
  </Box>
));
