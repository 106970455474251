// eslint-disable-next-line no-restricted-imports
import { MutationFunction, useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { ApiError, MutationCallbackOptions, MutationContext, OnMutationSuccess, UseCollectionApiResult } from './types';
import { useOnUpdateError, useOnUpdateMutate } from './util';

type UseNonEntityMutationOptions<TData, TVariables> = MutationCallbackOptions<TData, TVariables> &
  (
    | { updateQuery: true | 'optimistic'; invalidateQuery?: never }
    | { updateQuery?: never; invalidateQuery: true }
    | { updateQuery?: never; invalidateQuery?: never }
  );

export function useNonEntityMutation<TData, TVariables = Partial<TData>>(
  mutationFn: MutationFunction<TData, TVariables>,
  { queryKey }: Pick<UseCollectionApiResult<unknown>, 'queryKey'>,
  props: UseNonEntityMutationOptions<TData, TVariables> = {}
) {
  const queryClient = useQueryClient();

  const onMutate = useOnUpdateMutate<TData, TVariables>(queryKey);
  const onError = useOnUpdateError<TData, TVariables>(queryKey);

  const onSuccess: OnMutationSuccess<TData, TVariables> = async (data: TData) => {
    if (props.invalidateQuery) {
      await queryClient.invalidateQueries(queryKey);
    }

    if (props.updateQuery === true) {
      queryClient.setQueriesData(queryKey, data);
    }

    return Promise.resolve(data);
  };

  const options: UseMutationOptions<TData, ApiError, TVariables, MutationContext<TData>> = {
    onSuccess: (...args) => {
      void onSuccess(...args);
      void props.onSuccess?.(...args);
    },
    onError: (...args) => {
      if (props.updateQuery === 'optimistic') {
        void onError(...args);
      }
      void props.onError?.(...args);
    },
  };

  if (props.updateQuery === 'optimistic') {
    options.onMutate = onMutate;
  }

  return useMutation<TData, ApiError, TVariables, MutationContext<TData>>(queryKey, mutationFn, options);
}
