import { BrandSymbol, Button, Group, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';

import { usePlanInfo, useSubscription } from '../../../hooks';
import { SUBSCRIPTION_SETTINGS_PLANS_ROUTE } from '../../constants';
import { useSubscriptionRouter } from '../../utils';

export const FreePlanCardHeader = () => {
  const { goToSubscriptionPlans } = useSubscriptionRouter();
  const { formatMessage } = useMelioIntl();
  const subscription = useSubscription();
  const { planName } = usePlanInfo(subscription?.planId);
  const { track } = useAnalytics();

  const onClickHandler = () => {
    track('Settings', 'Click', {
      Cta: 'upgrade-plan',
    });

    goToSubscriptionPlans({ returnUrl: SUBSCRIPTION_SETTINGS_PLANS_ROUTE });
  };

  return (
    <Group width="full" justifyContent="space-between" alignItems="center" spacing="s">
      <Group spacing="s" alignItems="center">
        <BrandSymbol type="melio" size="extra-large" />
        <Text textStyle="body2Semi" as="h4">
          {formatMessage('activities.settings.plans.planName', { planName })}
        </Text>
      </Group>

      <Button
        variant="primary"
        data-testid="subscription-basic-plan-upgrade"
        label={formatMessage('activities.settings.plans.upgradePlan')}
        onClick={onClickHandler}
      />
    </Group>
  );
};
