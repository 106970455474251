/* eslint-disable react-hooks/exhaustive-deps */
import { PlaidAccountData } from '@melio/platform-api';
import { useEffect } from 'react';

import { PlaidEventData, usePlaid } from './usePlaid';

export type AddPlaidAccountProps = {
  onSuccess: (data: PlaidAccountData) => void;
  onExit?: VoidFunction;
  onLoad?: VoidFunction;
  onError?: ErrorFunction;
  onEvent: (data: PlaidEventData) => void;
  plaidToken: string;
};

export const AddPlaidAccountWidget = ({
  onSuccess,
  onError,
  onLoad,
  onExit,
  onEvent,
  plaidToken,
}: AddPlaidAccountProps) => {
  const { open, ready, error } = usePlaid({
    onError,
    onSuccess,
    onLoad,
    onExit,
    onEvent,
    plaidToken,
  });

  useEffect(() => {
    if (ready) {
      open();
    }
    if (error) {
      onError?.({ message: error.message, code: '500' });
    }
    //do not add onError to the dependencies array, it causes an infinite loop
  }, [ready, open, error]);

  return <></>;
};

AddPlaidAccountWidget.displayName = 'AddPlaidAccountWidget';
