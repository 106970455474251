import { useUserPreference, useUserPreferences } from '@melio/platform-api';
import { useDateUtils } from '@melio/platform-utils';
import { maxBy, noop } from 'lodash';
import { useMemo } from 'react';

import { useBillsTabItems } from '../components/BillsTab/useBillsTabItems';

const preferenceKey = 'payDashboardBillsTabLastSeen'; //item with the most new createdAt
export const curDatePreferenceKey = 'payDashboardBillsTabLastSeenCurrentDate'; //current time the tab was visited

export const useNewBillsTabItems = () => {
  const { create } = useUserPreferences({ enabled: false });
  const { data: billTabItemsData, isLoading: isBillTabItemsLoading } = useBillsTabItems({
    sort: { id: 'createdAt', sortDirection: 'desc' },
  });
  const { data: billsTabLastSeen, isLoading: isBillsTabLastSeenLoading } = useUserPreference({ id: preferenceKey });
  const isLoading = isBillTabItemsLoading || isBillsTabLastSeenLoading;
  const { createDate } = useDateUtils();

  return useMemo(() => {
    const billItems = billTabItemsData?.data ?? [];

    if (isLoading) {
      return {
        newBillsTabItems: [],
        markAsSeen: noop,
      };
    }

    const newBillsTabItems = !billsTabLastSeen
      ? billItems
      : billItems.filter((item) => item.payload.history.createdAt > (billsTabLastSeen.value as unknown as Date));
    const newestBillsTabItem = maxBy(newBillsTabItems, (item) => item.payload.history.createdAt);

    const markAsSeen = async () => {
      if (newestBillsTabItem) {
        await create({
          userPreference: { key: preferenceKey, value: newestBillsTabItem.payload.history.createdAt.toISOString() },
        });
      }
      await create({
        userPreference: {
          key: curDatePreferenceKey,
          value: createDate().toISOString(),
        },
      });
    };

    return {
      newBillsTabItems,
      markAsSeen,
    };
  }, [billsTabLastSeen, billTabItemsData, isLoading, create, createDate]);
};
