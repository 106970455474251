import { useModelApi, UseModelApiProps } from '@melio/api-client';
import { VendorsApiClient } from '@melio/platform-api-axios-client';

import { AutoPaymentOptions } from './types';
import { convertAutoPaymentOptionsResponseCentsToDollars } from './utils';

export type UseAutoPaymentOptionsProps = UseModelApiProps<
  typeof VendorsApiClient.getAutoPaymentOptions,
  AutoPaymentOptions
> & {
  onError?: () => void;
};

export const useAutoPaymentOptions = (props: UseAutoPaymentOptionsProps) =>
  useModelApi({
    ...props,
    queryKey: ['AutoPayApi', props.id],
    scope: 'AutoPaymentOptions',
    queryFn: (id: string) => VendorsApiClient.getAutoPaymentOptions(id),
    select: convertAutoPaymentOptionsResponseCentsToDollars,
  });
