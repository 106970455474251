import { useUserPreference, useUserPreferences } from '@melio/platform-api';
import { useCallback, useMemo } from 'react';

const lastSeenKey = 'todosLastSeen';

export const useLastSeenTodos = () => {
  const { create } = useUserPreferences({ enabled: false });

  const { data: lastSeen, isLoading } = useUserPreference({
    id: lastSeenKey,
  });

  const lastSeenDate = useMemo(() => (lastSeen?.value ? (lastSeen.value as unknown as Date) : undefined), [lastSeen]);

  const setLastSeen = useCallback(
    async (newestTodoDate?: Date) => {
      if (!isLoading && newestTodoDate && newestTodoDate.getTime() !== lastSeenDate?.getTime()) {
        await create({
          userPreference: { key: lastSeenKey, value: newestTodoDate.toISOString() },
        });
      }
    },
    [create, isLoading, lastSeenDate]
  );

  return { setLastSeen, lastSeenDate, isLoading };
};
