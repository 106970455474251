import { ComponentProps, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { FailedToDeliverACHActivity } from '@melio/ap-activities';
import { useAnalyticsContext } from '@melio/platform-analytics';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';

import { Loader } from '@/cl/components/Loader/Loader.component';
import { RouterComponentWrapper } from '@/hoc/routerComponentWrapper';
import { useOrigin } from '@/hooks/analytics/useOrigin';
import { AddNewBillStateType, useRouter } from '@/hooks/router.hooks';
import { useActiveScreen } from '@/hooks/useActiveScreen';
import { APP_EVENTS, emitAppEvent } from '@/queries/event-emitter-query';
import { RefundPaymentScreen } from '@/screens/refund-payment/RefundPayment.screen';
import { AddBillAndSchedulePaymentScreen } from '@/screens/schedule-payment/AddBillAndSchedulePayment.screen';
import { EditPaymentScreen } from '@/screens/schedule-payment/EditPayment.screen';
import { RescheduleFailedPaymentScreen } from '@/screens/schedule-payment/RescheduleFailedPayment.screen';
import { SchedulePaymentScreen } from '@/screens/schedule-payment/SchedulePayment.screen';
import { SchedulePaymentRequestScreen } from '@/screens/schedule-payment/SchedulePaymentRequest.screen';
import { VoidAndRefundPaymentScreen } from '@/screens/void-and-refund-payment/VoidAndRefundPayment.screen';
import { VoidAndResendPaymentScreen } from '@/screens/void-and-resend-payment/VoidAndResendPayment.screen';
import { ActiveFlowEnum, ScreensEnum } from '@/store/app/app.types';
import { PayDashboardTabs } from '@/types/payDashboard.types';

type RouteState = AddNewBillStateType & { returnUrl?: string; keepToast?: boolean };

export const SchedulePaymentRoute = () => {
  const [isNewPaymentFlowEnabled, isNewPaymentFlowFlagLoading] = useFeature(FeatureFlags.NewPaymentFlow, false);
  useActiveScreen(ScreensEnum.schedulePayment, ActiveFlowEnum.schedulePayment);
  const origin = useOrigin();
  useAnalyticsContext({ globalProperties: origin ? { Origin: origin } : {} });
  const { state } = useLocation();
  const { replaceToPayDashboardTab } = useRouter();
  const { deliveryMethodId, vendorId, file, amount, returnUrl, keepToast, categoryId, files } =
    (state as RouteState) || {};
  const failedToDeliverACHOnComplete = () => {
    replaceToPayDashboardTab(PayDashboardTabs.Paid);
  };

  useEffect(() => {
    return () => {
      emitAppEvent(APP_EVENTS.SCHEDULE_PAYMENT_FLOW_CLOSED);
    };
  }, []);

  if (isNewPaymentFlowFlagLoading) {
    return <Loader isAbsoluteCenter />;
  }

  return (
    <Routes>
      <Route
        path="/new/*"
        element={
          <RouterComponentWrapper
            Component={(props: ComponentProps<typeof AddBillAndSchedulePaymentScreen>) => {
              return isNewPaymentFlowEnabled ? (
                <Navigate
                  to={{ pathname: '/payment/new' }}
                  state={{ vendorId, deliveryMethodId, amount, returnUrl, keepToast }}
                  replace
                />
              ) : (
                <AddBillAndSchedulePaymentScreen {...props} />
              );
            }}
            componentProps={{
              vendorId,
              deliveryMethodId,
              returnUrl,
              file,
              amount,
              origin,
              categoryId,
              files,
            }}
          />
        }
      />
      <Route
        path="/:id"
        element={
          <RouterComponentWrapper
            Component={(props: ComponentProps<typeof SchedulePaymentScreen>) => {
              return isNewPaymentFlowEnabled ? (
                <Navigate
                  to={{ pathname: '/payment/new', search: new URLSearchParams({ billId: props.billId }).toString() }}
                  state={{ returnUrl, keepToast }}
                  replace
                />
              ) : (
                <SchedulePaymentScreen {...props} />
              );
            }}
            componentProps={{
              returnUrl,
              billId: {
                _pathParam: 'id',
              },
            }}
          />
        }
      />
      <Route
        path="payment-request/:id"
        element={
          <RouterComponentWrapper
            Component={SchedulePaymentRequestScreen}
            componentProps={{
              returnUrl,
              paymentRequestId: {
                _pathParam: 'id',
              },
            }}
          />
        }
      />
      <Route
        path="/:id/edit"
        element={
          <RouterComponentWrapper
            Component={(props: ComponentProps<typeof EditPaymentScreen>) => {
              return isNewPaymentFlowEnabled ? (
                <Navigate
                  to={{ pathname: `/payment/${props.paymentId}/edit` }}
                  state={{ returnUrl, keepToast }}
                  replace
                />
              ) : (
                <EditPaymentScreen {...props} />
              );
            }}
            componentProps={{
              returnUrl,
              paymentId: {
                _pathParam: 'id',
              },
            }}
          />
        }
      />
      <Route
        path=":id/retry-collect"
        element={
          <RouterComponentWrapper
            Component={RescheduleFailedPaymentScreen}
            componentProps={{
              paymentId: {
                _pathParam: 'id',
              },
            }}
          />
        }
      />
      <Route
        path=":paymentId/:paymentIntentId?/retry-deliver/*"
        element={
          <RouterComponentWrapper
            Component={FailedToDeliverACHActivity}
            componentProps={{
              paymentId: {
                _pathParam: 'paymentId',
              },
              paymentIntentId: {
                _optionalPathParam: 'paymentIntentId',
              },
              onDone: failedToDeliverACHOnComplete,
            }}
          />
        }
      />
      <Route
        path=":paymentId/refund/*"
        element={
          <RouterComponentWrapper
            Component={RefundPaymentScreen}
            componentProps={{
              paymentId: {
                _pathParam: 'paymentId',
              },
            }}
          />
        }
      />
      <Route
        path=":paymentId/void-and-refund/*"
        element={
          <RouterComponentWrapper
            Component={VoidAndRefundPaymentScreen}
            componentProps={{
              paymentId: {
                _pathParam: 'paymentId',
              },
            }}
          />
        }
      />
      <Route
        path=":paymentId/void-and-resend/*"
        element={
          <RouterComponentWrapper
            Component={VoidAndResendPaymentScreen}
            componentProps={{
              paymentId: {
                _pathParam: 'paymentId',
              },
            }}
          />
        }
      />
    </Routes>
  );
};
