import { forwardRef, HTMLTag } from '@melio/platform-utils';
import { ComponentType, isValidElement, ReactElement } from 'react';

import { LocalizationProvider } from './i18n';
import { HOCReturnType } from './types';

export function withLocalization<T, TTag extends HTMLTag = 'div'>(UI: ComponentType<T>): HOCReturnType<T, TTag>;
export function withLocalization(UI: ReactElement): ReactElement;

export function withLocalization<T, TTag extends HTMLTag = 'div'>(UI: ComponentType<T> | ReactElement) {
  if (isValidElement(UI)) {
    return addLocalizationProvider(UI);
  }
  return forwardRef<T, TTag>((props, ref) => addLocalizationProvider(<UI {...props} ref={ref} />));
}

function addLocalizationProvider(UI: ReactElement): ReactElement {
  return <LocalizationProvider>{UI}</LocalizationProvider>;
}
