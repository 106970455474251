import { Box } from '@chakra-ui/react';
import { InvoiceDiscount } from '@melio/ar-domain';
import { Table } from '@melio/penny';

import { useDiscountActionsItems } from '../hooks';

type DiscountActionCellProps = {
  discount: InvoiceDiscount;
  onEdit: (discountId: string) => unknown;
  onDelete: (discountId: string) => unknown;
};

const handleCellClick = (e: React.MouseEvent<HTMLDivElement>) => {
  e.stopPropagation();
};

export const DiscountActionCell = ({ discount, onEdit, onDelete }: DiscountActionCellProps) => {
  const actions = useDiscountActionsItems({ discount, onEdit, onDelete });

  return (
    <Box onClick={handleCellClick} height="full">
      <Table.ActionsCell options={actions} data-testid={`discount-actions-cell-${discount.id}`} />
    </Box>
  );
};
