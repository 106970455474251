import { Button, Icon } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

type Props = {
  onClick: VoidFunction;
};

export const SchedulePaymentMainButton = ({ onClick }: Props) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Button
      data-testid="new-dashboard-schedule-payment-cta"
      label={formatMessage('activities.payDashboard.mainCta.SchedulePayment.label')}
      leftElement={<Icon size="small" type="add" color="inherit" aria-hidden />}
      onClick={onClick}
    />
  );
};
