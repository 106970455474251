import { ApiKeys, GetVendorsResponse } from '@melio/platform-api-axios-client';

// eslint-disable-next-line no-restricted-imports
import { usePaginatedCollectionApi, UsePaginatedCollectionOptions } from '../../core/usePaginatedCollectionApi';
import { Vendor, VendorsListParams } from './types';
import { createGetVendorsQueryFn } from './utils';

export type UsePaginatedVendorsProps = UsePaginatedCollectionOptions<
  Override<GetVendorsResponse, { data: Vendor[] }>
> & { params?: VendorsListParams };

export const usePaginatedVendors = ({ params, ...props }: UsePaginatedVendorsProps) =>
  usePaginatedCollectionApi(
    [ApiKeys.VendorsApi, params],
    async (context) => {
      const fn = createGetVendorsQueryFn({
        ...params,
        cursor: context.pageParam?.cursor ?? params?.cursor,
      });
      const response = await fn();
      const data = response?.data.data as Vendor[];
      const pagination = response?.data.pagination;

      return { data, pagination };
    },
    props
  );

export type PaginatedVendorCollection = ReturnType<typeof usePaginatedVendors>;
