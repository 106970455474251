import { PCIComplienceInfo, TabaPayIframe } from '@melio/ap-widgets';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { Card, CardParams } from '@melio/platform-api';
import { forwardRef, useBoolean } from '@melio/platform-utils';
import React from 'react';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';
import { CreateCardDetailsScreenTitle } from '../shared/CreateCardDetailsScreenTitle';

export type TabapayCreateCardDetailsScreenProps = {
  onBack: VoidFunction;
  onClose: VoidFunction;
  onDone: (params: CardParams) => void;
  onError?: ErrorFunction;
  cardType?: Card['type'];
};

export const TabapayCreateCardDetailsScreen: React.VFC<TabapayCreateCardDetailsScreenProps> = forwardRef<
  TabapayCreateCardDetailsScreenProps,
  'div'
>(({ onBack, onClose, onDone, onError, cardType, ...props }, ref) => {
  const [isLoading, loading] = useBoolean(true);
  useAnalyticsView('AddCCDetails', !isLoading);

  const { createTrackHandler } = useAnalytics();
  const trackSubmit = createTrackHandler<{ Status?: 'succeeded' | 'failed' }>('AddCCDetails', 'Submitted');

  return (
    <NewSinglePaymentStepLayout
      data-component="AddCardFundingSourceActivity.TabapayCreateCardDetailsScreen"
      data-testid="tabapay-add-card-funding-source-activity-create-card-details-screen"
      ref={ref}
      {...props}
      headerContent={
        <NewSinglePaymentStepLayout.Header>
          <NewSinglePaymentStepLayout.CloseButton onClick={onClose} />
        </NewSinglePaymentStepLayout.Header>
      }
      bottomContent={<PCIComplienceInfo />}
    >
      <NewSinglePaymentStepLayout.Title>
        <CreateCardDetailsScreenTitle cardType={cardType} />
      </NewSinglePaymentStepLayout.Title>
      <NewSinglePaymentStepLayout.Content>
        <TabaPayIframe
          data-testid="tabapay-iframe"
          onLoad={loading.off}
          onSuccess={(...args) => {
            trackSubmit({ Status: 'succeeded' });
            onDone(...args);
          }}
          onError={(...args) => {
            trackSubmit({ Status: 'failed' });
            onError?.(...args);
          }}
          cardType={cardType}
        />
      </NewSinglePaymentStepLayout.Content>
    </NewSinglePaymentStepLayout>
  );
});
TabapayCreateCardDetailsScreen.displayName = 'AddCardFundingSourceActivity.TabapayCreateCardDetailsScreen';
