import { SectionBanner } from '@melio/penny';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { DeliveryMethod, Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { getPaymentAnalyticsFields } from '../../../../utils';
import { EditVendorCheckDetailsActivity, EditVendorCheckDetailsActivityProps } from '../../../delivery-methods';

type Props = Omit<EditVendorCheckDetailsActivityProps, 'title' | 'banner'> & {
  payment: Payment;
  paperCheckDeliveryMethod: DeliveryMethod;
};

export const ConfirmMailingAddressStep = withAnalyticsContext<Props>(
  ({
    setAnalyticsProperties,
    payment,
    onBack: _onBack,
    onClose: _onClose,
    onDone,
    paperCheckDeliveryMethod,
    ...props
  }) => {
    const { formatMessage } = useMelioIntl();
    const { createTrackHandler } = useAnalytics();

    setAnalyticsProperties({
      ...getPaymentAnalyticsFields(payment),
      Intent: 'edit-check-delivery-method-details',
      EntryPoint: 'dashboard-payment',
      Flow: 'void-and-resend-check',
      PageName: 'confirm-or-update-your-vendors-mailing-address',
      EligibilityType: null,
    });

    useAnalyticsView('Payment', true, true);

    const trackAndHandleClick = createTrackHandler<{ Cta: 'exit' | 'back'; IsSameAddress: boolean }>(
      'Payment',
      'Click'
    );

    const onClose = () => trackAndHandleClick({ Cta: 'exit' }, _onClose);
    const onBack = () => trackAndHandleClick({ Cta: 'back' }, _onBack);

    return (
      <EditVendorCheckDetailsActivity
        {...props}
        onDone={onDone}
        onClose={onClose}
        onBack={onBack}
        banner={
          <SectionBanner
            description={formatMessage('activities.voidAndResend.steps.confirmMailingAddress.banner.description')}
            variant="warning"
          />
        }
        title={formatMessage('activities.voidAndResend.steps.confirmMailingAddress.title')}
      />
    );
  }
);
