import { ComponentProps, useState } from 'react';

import { useCreateInvitationAnalytics } from './hooks/analytics/useCreateInvitationAnalytics';
import { useAsyncActionWithToast } from './hooks/useAsyncActionWithToast';
import { usePendingInvitations } from './hooks/usePendingInvitations';
import { CreateInvitationModalScreen } from './screens/modals/InviteOrEdit/CreateInvitation.modal-screen';

export const useCreateInvitationModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { create: createInvitation, isMutating } = usePendingInvitations();
  const analytics = useCreateInvitationAnalytics();

  const actionWithToast = useAsyncActionWithToast({
    successLabel: 'activities.manageCollaborators.messages.invitation.create.success',
    errorLabel: 'activities.manageCollaborators.messages.invitation.create.error',
    onSuccess: () => {
      setIsOpen(false);
      analytics.status('success');
    },
    onError: () => {
      analytics.status('failure');
    },
  });

  const submit: ComponentProps<typeof CreateInvitationModalScreen>['onDone'] = async (data) => {
    await actionWithToast(() =>
      createInvitation({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        roleUniqueName: data.roleUniqueName,
        ...(data.approvalAmountThreshold
          ? { approvalAmountThreshold: Number(data.approvalAmountThreshold), requireApproval: true }
          : { requireApproval: false }),
      })
    );
    analytics.submit({ invitedUserRole: data.roleUniqueName, requireApproval: !!data.approvalAmountThreshold });
  };

  const closeModal = () => {
    setIsOpen(false);
    analytics.close();
  };

  const openModal = () => setIsOpen(true);

  return {
    openModal,
    closeModal,
    isOpen,
    submit,
    isCreating: isMutating,
  };
};
