import { DeliveryMethod, FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';
import { VoidReviewAndConfirmContent } from '../../components/VoidReviewAndConfirmContent/VoidReviewAndConfirmContent';

export type VoidReviewAndConfirmScreenProps = {
  amount: number;
  vendorId: string;
  isLoading?: boolean;
  tracingNumber?: number;
  fundingSource: FundingSource;
  deliveryMethod?: DeliveryMethod;
  isLoadingButton?: boolean;
  organizationName: string;
  maxEstimatedRefundDate: Date;
  onClose: VoidFunction;
  onSubmit: VoidFunction;
};

export const VoidReviewAndConfirmScreen = ({
  amount,
  vendorId,
  isLoading,
  tracingNumber,
  fundingSource,
  deliveryMethod,
  isLoadingButton,
  organizationName,
  maxEstimatedRefundDate,
  onClose,
  onSubmit,
}: VoidReviewAndConfirmScreenProps) => {
  const { formatMessage } = useMelioIntl();

  return (
    <NewSinglePaymentStepLayout
      data-component="VoidAndRefundActivity.VoidReviewAndConfirmScreen"
      data-testid="void-check-activity-review-and-confirm-screen"
      isLoading={isLoading}
      headerContent={
        <NewSinglePaymentStepLayout.Header>
          <NewSinglePaymentStepLayout.CloseButton onClick={onClose} />
        </NewSinglePaymentStepLayout.Header>
      }
      footerContent={
        <NewSinglePaymentStepLayout.Actions>
          <NewSinglePaymentStepLayout.NextButton
            data-testid="void-and-refund-button"
            onClick={onSubmit}
            variant="primary"
            label={formatMessage('activities.voidAndRefund.screens.confirmVoidAndRefund.continue')}
            isLoading={isLoadingButton}
          />
        </NewSinglePaymentStepLayout.Actions>
      }
    >
      <NewSinglePaymentStepLayout.Title>
        {formatMessage('activities.voidAndRefund.screens.confirmVoidAndRefund.title')}
      </NewSinglePaymentStepLayout.Title>
      <NewSinglePaymentStepLayout.Description>
        {formatMessage('activities.voidAndRefund.screens.confirmVoidAndRefund.description', {
          organization: organizationName,
        })}
      </NewSinglePaymentStepLayout.Description>
      <NewSinglePaymentStepLayout.Content>
        <VoidReviewAndConfirmContent
          amount={amount}
          vendorId={vendorId}
          tracingNumber={tracingNumber}
          fundingSource={fundingSource}
          deliveryMethod={deliveryMethod}
          maxEstimatedRefundDate={maxEstimatedRefundDate}
        />
      </NewSinglePaymentStepLayout.Content>
    </NewSinglePaymentStepLayout>
  );
};

VoidReviewAndConfirmScreen.displayName = 'VoidAndRefundActivity.VoidReviewAndConfirmScreen';
