import { forwardRef } from '@melio/platform-utils';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';

export type LoadingScreenProps = {
  onBack?: VoidFunction;
  onClose?: VoidFunction;
};

export const LoadingScreen = forwardRef<LoadingScreenProps, 'div'>(({ onBack, onClose, ...props }, ref) => (
  <NewSinglePaymentStepLayout
    data-component="FundingSourceSelectionActivity.LoadingScreen"
    data-testid="funding-source-selection-activity-loading-screen"
    {...props}
    ref={ref}
    isLoading
  />
));

LoadingScreen.displayName = 'FundingSourceSelectionActivity.LoadingScreen';
