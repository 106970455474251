import { useSubscriptionOrganization } from '../api';
import { usePartnerGroups } from './usePartnerGroups';
import { useSubscription } from './useSubscription';

export const useFreeTrialEligibility = () => {
  const subscription = useSubscription();

  const { isFiservPartner } = usePartnerGroups();
  const { data: subscriptionOrganization, isFetching } = useSubscriptionOrganization({
    enabled: !!subscription && !isFiservPartner,
  });

  const isUsedFreeTrial = subscriptionOrganization?.usedFreeTrial;

  return {
    isEligibleForFreeTrial: !isFiservPartner && (!subscription || !isUsedFreeTrial),
    isFetching,
  };
};
