import { Box } from '@chakra-ui/react';
import { Link, Skeleton, Text } from '@melio/penny';
import { Plan, SubscriptionPreview } from '@melio/platform-api-axios-client';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';

import type { SubscriptionBillingCycleEnum } from '../../../../../api';
import { useSubscriptionPreviewItems } from '../../../../../hooks';

export const SubscriptionCheckoutSummaryDisclaimer = ({
  isLoading,
  preview,
  plan,
  planCycle,
}: {
  isLoading: boolean;
  plan: Plan;
  planCycle: SubscriptionBillingCycleEnum;
  preview?: SubscriptionPreview;
}) => {
  const { formatMessage, formatCurrency, formatDate } = useMelioIntl();
  const { privacyPolicyUrl, termsOfServiceUrl } = useConfig().settings;
  const { freeTrialData } = useSubscriptionPreviewItems({
    subscriptionPreview: preview,
    plan,
  });

  const getDisclaimer = () => {
    const total = isLoading ? (
      <Box width="40px" display="inline-block">
        <Skeleton height="8px" />
      </Box>
    ) : (
      formatCurrency(preview?.amount || 0)
    );

    const termsAndConditions = {
      termsOfService: (
        <Link
          color="inherit"
          href={termsOfServiceUrl}
          label={formatMessage('activities.subscription.checkout.summary.disclaimer.terms')}
          variant="inline"
          newTab
        />
      ),
      privacyPolicy: (
        <Link
          color="inherit"
          href={privacyPolicyUrl}
          label={formatMessage('activities.subscription.checkout.summary.disclaimer.privary')}
          variant="inline"
          newTab
        />
      ),
    };

    if (freeTrialData?.freeBillingItem) {
      return formatMessage(`activities.subscription.checkout.summary.disclaimer.${planCycle}FreeTrial`, {
        total,
        endDate: formatDate(freeTrialData?.freeBillingItem.serviceEndDate, { dateStyle: 'full' }),
        ...termsAndConditions,
      });
    }

    return formatMessage(`activities.subscription.checkout.summary.disclaimer.${planCycle}`, {
      total,
      ...termsAndConditions,
    });
  };

  return (
    <Text textStyle="body4" color="global.neutral.800" data-testid="subscription-checkout-disclaimer">
      {getDisclaimer()}
    </Text>
  );
};
