import { Button, Container, Group, Illustration, Layout, Text, useBreakpoint } from '@melio/penny';

import { ButtonPropsWithTestId } from './PayDashboardEmptyState';

export const PayDashboardClearedState = ({
  title,
  description,
  buttonProps,
  illustrationSrc,
  hidden,
}: {
  title?: string;
  description: string;
  buttonProps?: ButtonPropsWithTestId[];
  illustrationSrc: string;
  'aria-label': string;
  hidden?: boolean;
}) => {
  const { isExtraSmallScreen } = useBreakpoint();

  return (
    <Layout
      variant="6Columns"
      paddingContent={isExtraSmallScreen ? 'm' : 'none'}
      data-testid="pay-dashboard-clear-state"
      aria-live="assertive"
    >
      <Container paddingTop="xxl" hidden={hidden}>
        <Group alignItems="center" variant="vertical">
          <Illustration type={illustrationSrc} size="medium" />
          <Group spacing="m" variant="vertical" textAlign="center">
            {title && <Text textStyle="heading3Semi">{title}</Text>}
            <Text color="global.neutral.800">{description}</Text>
            {buttonProps && buttonProps.length > 0 && (
              <Group>
                {buttonProps.map((buttonProp, index) => (
                  <Button key={index} variant="primary" isFullWidth={isExtraSmallScreen} {...buttonProp} />
                ))}
              </Group>
            )}
          </Group>
        </Group>
      </Container>
    </Layout>
  );
};
