import { Address, useMelioIntl } from '@melio/ar-domain';
import { AddressSearchWidget, AddressSearchWidgetProps, ZIP_CODE_SHORT_MASK } from '@melio/form-controls';
import { Form, SimpleGrid } from '@melio/penny';
import { forwardRef, useBoolean } from '@melio/platform-utils';

import { useBusinessDetailsFormContext } from '../../../utils';
import { AddressKey, FieldNameType } from '../types';

export type BusinessAddressDetailsFormProps = {
  addressKey: AddressKey;
  columns: number;
  addressLabel: string;
  placeholder?: string;
};

export const AddressDetailsFormSection = forwardRef<BusinessAddressDetailsFormProps>(
  ({ addressKey, addressLabel, placeholder, columns, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const { registerField, setValue } = useBusinessDetailsFormContext();

    const FieldName: FieldNameType = {
      line1: `${addressKey}.line1`,
      line2: `${addressKey}.line2`,
      city: `${addressKey}.city`,
      state: `${addressKey}.state`,
      postalCode: `${addressKey}.postalCode`,
    } as const;

    type FieldNameKeys = keyof typeof FieldName;

    const [shouldShowAddressFields, showAddressFields] = useBoolean(false);

    const handleAddressChange: AddressSearchWidgetProps['onChange'] = (event) => {
      const address = event.target.value as unknown as Address | null;

      if (address === null) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - if we pass '' the second search doesn't work properly
        setValue(registerField(FieldName.line1).name, null);
        setValue(registerField(FieldName.line2).name, '');
        setValue(registerField(FieldName.state).name, '');
        setValue(registerField(FieldName.city).name, '');
        setValue(registerField(FieldName.postalCode).name, '');
        showAddressFields.off();
      } else {
        showAddressFields.on();
      }

      const setFormField = (field: FieldNameType[FieldNameKeys]) => {
        const fieldName = field.split('.')[1] as keyof Address;
        if (address?.[fieldName]) {
          setValue(field, address[fieldName], {
            shouldValidate: true,
          });
        }
      };
      setFormField(FieldName.line1);
      setFormField(FieldName.line2);
      setFormField(FieldName.state);
      setFormField(FieldName.city);
      setFormField(FieldName.postalCode);
    };

    return (
      <SimpleGrid columns={columns} spacing="m" {...props} ref={ref}>
        <AddressSearchWidget
          {...registerField(FieldName.line1)}
          formatSelectedValue={(data) => (data.value as unknown as Address).line1}
          labelProps={{
            label: addressLabel,
          }}
          placeholder={placeholder}
          colSpan={4}
          onChange={handleAddressChange}
        />
        <Form.TextField
          colSpan={4}
          isHidden={!shouldShowAddressFields}
          labelProps={{ label: formatMessage('ar.guestPayment.activities.cardHolder.form.fields.city.label') }}
          {...registerField(FieldName.city)}
        />
        {shouldShowAddressFields ? (
          <Form.TextField
            colSpan={2}
            labelProps={{ label: formatMessage('ar.guestPayment.activities.cardHolder.form.fields.state.label') }}
            {...registerField(FieldName.state)}
          />
        ) : null}

        {shouldShowAddressFields ? (
          <Form.TextField
            colSpan={2}
            labelProps={{ label: formatMessage('ar.guestPayment.activities.cardHolder.form.fields.postalCode.label') }}
            maskProps={{ mask: ZIP_CODE_SHORT_MASK }}
            {...registerField(FieldName.postalCode)}
          />
        ) : null}
      </SimpleGrid>
    );
  }
);
AddressDetailsFormSection.displayName = 'AddressDetailsFormSection';
