import { BaseMenu, SearchBar, SearchBarProps, useBaseMenuItem } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export const SearchBarHeader = ({ searchProps }: { searchProps: SearchBarProps }) => {
  const { formatMessage } = useMelioIntl();
  const { ref } = useBaseMenuItem();
  return (
    <BaseMenu.Header>
      <SearchBar
        {...searchProps}
        ref={ref}
        placeholder={formatMessage(
          'activities.batchPayments.screens.paymentIntentsTable.fundingSourceSelectCell.search.placeholder'
        )}
        aria-label="search"
      />
    </BaseMenu.Header>
  );
};
