import { Container, Divider, Group, Icon, Link, Modal, Text, Typography, useBreakpoint } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { useEffect } from 'react';

export type FinancingDetailsModalProps = {
  isOpen: boolean;
  onClose: VoidFunction;
};

export const FinancingDetailsModalWidget: React.VFC<FinancingDetailsModalProps> = ({ isOpen, onClose }) => {
  const { formatMessage } = useMelioIntl();
  const { financingMoreDetails } = useConfig().links;
  const { isExtraSmallScreen: isMobile } = useBreakpoint();

  const { track, createTrackHandler } = useAnalytics();

  const trackAndHandleClick = createTrackHandler<{
    PageName: 'pay-over-time';
    Cta: 'apply-now' | 'dismiss' | 'exit';
  }>('Payment', 'Click', { PageName: 'pay-over-time' });

  useEffect(() => {
    if (isOpen) {
      track('Payment', 'View', {
        PageName: 'pay-over-time',
      });
    }
  }, [isOpen, track]);

  const headerContent = (
    <Container paddingTop={isMobile ? 'none' : 'xxs'}>
      <Group spacing="s" alignItems="flex-start">
        {!isMobile && (
          <Group.Item grow={0}>
            <Container paddingTop="xxs" width="fit-content">
              <Icon type="installments" />
            </Container>
          </Group.Item>
        )}
        <Text as="h2" textStyle="heading2Semi" data-testid="modal-header-text">
          {formatMessage('widgets.fundingSources.selectFundingSourceCard.financing.detailsModal.title')}
        </Text>
      </Group>
    </Container>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => trackAndHandleClick({ Cta: 'exit' }, onClose)}
      data-component="FinancingDetailsModal"
      data-testid="financing-details-modal"
      header={headerContent}
      primaryButton={{
        onClick: () => trackAndHandleClick({ Cta: 'apply-now' }, onClose),
        variant: 'primary',
        label: formatMessage('widgets.fundingSources.selectFundingSourceCard.financing.detailsModal.primaryButton'),
      }}
      secondaryButton={{
        onClick: () => trackAndHandleClick({ Cta: 'dismiss' }, onClose),
        variant: 'secondary',
        label: formatMessage('widgets.fundingSources.selectFundingSourceCard.financing.detailsModal.secondaryButton'),
      }}
    >
      <Group variant="vertical">
        <Text>
          {formatMessage('widgets.fundingSources.selectFundingSourceCard.financing.detailsModal.description')}
        </Text>
        <Group variant="vertical" spacing="m">
          <Divider />

          <Group variant="vertical">
            <Text textStyle="body2Semi">
              {formatMessage('widgets.fundingSources.selectFundingSourceCard.financing.detailsModal.paragraph1.title')}
            </Text>
            <Typography.ParagraphList
              list={[
                {
                  content: formatMessage(
                    'widgets.fundingSources.selectFundingSourceCard.financing.detailsModal.paragraph1.text1'
                  ),
                },
                {
                  content: formatMessage(
                    'widgets.fundingSources.selectFundingSourceCard.financing.detailsModal.paragraph1.text2'
                  ),
                },
                {
                  content: formatMessage(
                    'widgets.fundingSources.selectFundingSourceCard.financing.detailsModal.paragraph1.text3'
                  ),
                },
              ]}
              type="unordered"
            />
          </Group>

          <Divider />

          <Group variant="vertical">
            <Text textStyle="body2Semi">
              {formatMessage('widgets.fundingSources.selectFundingSourceCard.financing.detailsModal.paragraph2.title')}
            </Text>
            <Typography.ParagraphList
              list={[
                {
                  content: formatMessage(
                    'widgets.fundingSources.selectFundingSourceCard.financing.detailsModal.paragraph2.text1'
                  ),
                },
                {
                  content: formatMessage(
                    'widgets.fundingSources.selectFundingSourceCard.financing.detailsModal.paragraph2.text2'
                  ),
                },
                {
                  content: formatMessage(
                    'widgets.fundingSources.selectFundingSourceCard.financing.detailsModal.paragraph2.text3'
                  ),
                },
                {
                  content: formatMessage(
                    'widgets.fundingSources.selectFundingSourceCard.financing.detailsModal.paragraph2.text4'
                  ),
                },
              ]}
              type="ordered"
            />
          </Group>

          <Link
            href={financingMoreDetails}
            label={formatMessage('widgets.fundingSources.selectFundingSourceCard.financing.detailsModal.learnMore')}
            variant="inline"
            newTab
          />
        </Group>
      </Group>
    </Modal>
  );
};

FinancingDetailsModalWidget.displayName = 'FinancingDetailsModalWidget';
