import React from 'react';
import { Box } from '@chakra-ui/react';
import { useMelioIntl } from '@melio/i18n-tools/src/useMelioIntl';
import { Group, Link, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { SectionHeader } from '@melio/platform-utils';

import { LabelCopy } from '@/cl/components/LabelCopy/LabelCopy.component';
import { SettingsCardIdEnum } from '@/cl/components/SettingsCard/SettingsCard.component';

export const PaymentPageSection = ({ link }: { link: string }) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();

  const onCopy = () => {
    track('Settings', 'Click', {
      PageName: SettingsCardIdEnum.CompanySettings,
      Cta: 'copy-link',
    });
  };

  return (
    <Group variant="vertical" width="full" data-testid="payment-page-section">
      <SectionHeader title={formatMessage('widgets.companySettings.paymentPage.title')} />
      <Box>
        <Text>
          {formatMessage('widgets.companySettings.paymentPage.content', {
            link: (
              <Link
                data-testid="company-settings-payment-page-transaction-fees-link"
                color="secondary"
                href="./receiving-methods"
                label={formatMessage('widgets.companySettings.paymentPage.content.link')}
              />
            ),
          })}
        </Text>
        <LabelCopy
          testId="payment-page-link"
          variant="button"
          direction="column"
          size="medium"
          label={link.replace('https://', '')}
          link={link}
          buttonLabel={formatMessage('widgets.companySettings.paymentPage.copyLink')}
          successMessage={formatMessage('widgets.companySettings.paymentPage.successCopyToast')}
          errorMessage={formatMessage('widgets.companySettings.paymentPage.errorCopyToast')}
          onCopy={onCopy}
        />
      </Box>
    </Group>
  );
};
