import {
  InternationalAccountBicSwift,
  InternationalAccountDeliveryMethod,
  InternationalAccountIban,
  Vendor,
} from '@melio/platform-api';

import { DeliveryMethodFlowOrigin } from '../../delivery-methods/types';

export type { Account, InternationalAccountDeliveryMethod } from '@melio/platform-api';

export type SubmissionTargetType = 'continue' | 'close';

export type AddInternationalDeliveryMethodStep =
  | 'INTERNATIONAL_BANK_DETAILS'
  | 'INTERNATIONAL_VENDOR_DETAILS'
  | 'BUSINESS_LEGAL_DETAILS'
  | 'ADD_ORGANIZATION_BENEFICIAL_OWNERS';

export type AddInternationalDeliveryMethodActivityProps = {
  vendorId: Vendor['id'];
  onBack: VoidFunction;
  onClose: VoidFunction;
  onDone: (data: InternationalAccountDeliveryMethod, target?: SubmissionTargetType) => void;
  onError?: ErrorFunction;
  origin?: DeliveryMethodFlowOrigin;
  isLoading?: boolean;
};

export type Subset<K> = {
  [attr in keyof K]?: K[attr] extends object
    ? Subset<K[attr]>
    : K[attr] extends object | null
    ? Subset<K[attr]> | null
    : K[attr] extends object | null | undefined
    ? Subset<K[attr]> | null | undefined
    : K[attr];
};

export type PartialInternationalAccountIban = Subset<InternationalAccountIban>;
export type PartialInternationalAccountBicSwift = Subset<InternationalAccountBicSwift>;

export type AddInternationalDeliveryMethodAnalyticsDataType = {
  DeliveryCountry: string;
  CountryRiskScore?: 'high' | 'low' | 'medium' | null;
  BankIdentificationType: 'IBAN' | 'SWIFT' | null;
};

export type AddFxDeliveryMethodAnalyticsDataType = {
  foreignCurrency: string;
  fxCountrySelection?: string;
};

export const enum InternationalFxStepsEnum {
  Ubo = 'ubo',
  VendorAccount = 'VendorAccount',
}
