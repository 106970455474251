import { Container, Group, Text, useBreakpoint } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { SettingsPageHeader, SystemMessageDisplay } from '@melio/platform-utils';

import { usePlansTiers, useSubscription } from '../../../hooks';
import { SubscriptionPaymentMethod, SubscriptionPlansSelectFiserv } from '../../components';
import { useSubscriptionRouter } from '../../utils';

export const SubscriptionPlansSettingsScreenFiserv = () => {
  const { getIsFreePlan } = usePlansTiers();
  const { formatMessage } = useMelioIntl();
  const subscription = useSubscription();
  const router = useSubscriptionRouter();

  const { isExtraSmallScreen } = useBreakpoint();

  const showPaymentMethod = subscription && !getIsFreePlan(subscription.planId);

  useAnalyticsView('Settings', true, false, {
    PageName: 'fiserv-plans',
    Flow: 'settings',
    Intent: 'view-plans-page',
  });

  return (
    <Container paddingBottom="xl">
      {isExtraSmallScreen && <SystemMessageDisplay data-testid="subscription-plans-settings-notification-mobile" />}
      <Group variant="vertical" data-testid="subscription-plans" spacing="none">
        <SettingsPageHeader
          title={formatMessage('app.settings.BillingSection.cards.subscriptionPlans.title')}
          subTitle={formatMessage('app.settings.BillingSection.cards.subscriptionPlans.description')}
        />
        <Group spacing="xl" variant="vertical">
          <Group spacing="s" variant="vertical">
            <Text as="h3" textStyle="heading3Semi">
              {formatMessage('widgets.subscriptionPlanSettings.title.currentPlan')}
            </Text>
            <SubscriptionPlansSelectFiserv />
          </Group>

          {showPaymentMethod && (
            <Group variant="vertical" spacing="s">
              <Text as="h3" textStyle="heading3Semi">
                {formatMessage('widgets.subscriptionPlanSettingsFiserv.title.billingAccount')}
              </Text>
              <SubscriptionPaymentMethod
                onChange={() => {
                  router.goToPaymentMethodSelect();
                }}
              />
            </Group>
          )}
        </Group>
      </Group>
    </Container>
  );
};
