import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { FeatureFlags } from '@melio/platform-feature-flags';
import { useCallback, useEffect, useState } from 'react';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';
import { CardToCardPromotionBanner } from '../../../../promotions/banners/card-to-card/CardToCardPromotionBanner';
import { QBOSyncPromotionBanner } from '../../../../promotions/banners/qbo/QBOSyncPromotionBanner';
import { PaymentFlowDoneAction } from '../../../../types';

type PaymentScheduledPromotionsProps = {
  flow: 'batch-payments' | 'single-payment' | 'recurring-payment';
  paymentIds: string[];
  promotionName: FeatureFlags | null;
  onDone: (action: PaymentFlowDoneAction) => void;
};

export const PaymentScheduledPromotions = withAnalyticsContext<PaymentScheduledPromotionsProps>(
  ({ flow, paymentIds, promotionName, onDone }) => {
    const [promotionDismissed, setPromotionDismissed] = useState<boolean>(false);

    const onDismissHandler = useCallback(() => {
      setPromotionDismissed(true);
    }, [setPromotionDismissed]);

    const createDoneHandler = useCallback(
      (action: PaymentFlowDoneAction) => () => {
        onDone(action);
      },
      [onDone]
    );

    const { trackMarketing } = useAnalytics();

    const analyticsProperties = {
      Flow: flow,
      PageName: 'payment-scheduled',
      PaymentId: paymentIds,
    };

    useEffect(() => {
      if (!promotionName) {
        return;
      }
      trackMarketing(flow === 'recurring-payment' ? 'pay-bill_recurring-confirm-success' : 'pay-bill_confirm-success', {
        Feature: {
          name: promotionName,
          displayed: true,
        },
      });
    }, [flow, promotionName, trackMarketing]);

    return !promotionName || promotionDismissed ? null : (
      <NewSinglePaymentStepLayout.Content>
        {promotionName === FeatureFlags.PlatformScheduledPaymentAccountingPlatformSyncPromotion && (
          <QBOSyncPromotionBanner
            analyticProperties={analyticsProperties}
            onDone={createDoneHandler('syncQBOAccountingPlatform')}
            onClose={onDismissHandler}
          />
        )}
        {promotionName === FeatureFlags.PlatformScheduledPaymentCardToCardPromotionEnabled && (
          <CardToCardPromotionBanner
            analyticProperties={{ ...analyticsProperties, DeliveryMethodType: 'virtual-card' }}
            onDone={createDoneHandler('goToDashboard')}
            onClose={onDismissHandler}
          />
        )}
      </NewSinglePaymentStepLayout.Content>
    );
  }
);
