import Big from 'big.js';

import { type Plan, SubscriptionBillingCycleEnum, useSubscriptionMe } from '../../../../api';
import {
  usePartnerGroups,
  usePendingSubscription,
  usePlanInfo,
  usePlansTiers,
  useSubscription,
  useSubscriptionGracePeriod,
  useSubscriptionPlan,
  useSubscriptionPromotions,
} from '../../../../hooks';
import { usePlanCardButtonLabel } from './PlanCardButton/usePlanCardButtonLabel';
import { usePlanBadge } from './PlanCardHeader/PlanCardHeader.utils';
import { PlanCardView } from './PlanCardView';

export type PlanCardProps = {
  plan: Plan;
  discount?: number;
  isSelected: boolean;
  selectedBillingCycle: SubscriptionBillingCycleEnum;
  onSelectPlan: (planId: Plan['id'], planName: string) => void;
  isEligibleForFreeTrial: boolean;
  isAccountingClient?: boolean;
};

export const PlanCard = ({
  plan,
  selectedBillingCycle,
  onSelectPlan,
  isSelected,
  isEligibleForFreeTrial,
  isAccountingClient,
}: PlanCardProps) => {
  const subscription = useSubscription();
  const { getPlanPromo } = useSubscriptionPromotions();
  const { getIsCurrentPlan } = useSubscriptionPlan();
  const { getIsMostPopularPlan, getIsFreePlan, getNextPlanWithUnlimitedSeats } = usePlansTiers();
  const { isUpgradedPlan } = useSubscriptionPlan();
  const { isFiservPartner } = usePartnerGroups();
  const { isReactivationAllowed, nextSubscriptionPlanId: pendingPlanId } = usePendingSubscription();
  const { isMutating: isUpdatingSubscription } = useSubscriptionMe({
    enabled: false,
  });
  const { planName, planDescription, capabilities } = usePlanInfo(plan.id);
  const planBadge = usePlanBadge(plan.id);

  const currentBillingCycle = subscription?.planCyclePeriod;
  const isBillingCycleToggledToYearly =
    selectedBillingCycle === SubscriptionBillingCycleEnum.Annual &&
    currentBillingCycle === SubscriptionBillingCycleEnum.Monthly;

  const { isEligibleForGracePeriod, isTodayInGracePeriod, gracePeriodEndDateToDisplay } = useSubscriptionGracePeriod();

  const futureAvailableDate =
    isEligibleForGracePeriod && isTodayInGracePeriod && getIsFreePlan(plan.id) && isAccountingClient
      ? gracePeriodEndDateToDisplay
      : undefined;

  const selectPlanButtonLabel = usePlanCardButtonLabel({
    planId: plan.id,
    isBillingCycleToggledToYearly,
    isEligibleForFreeTrial,
    futureAvailableDate,
  });

  const nextUnlimitedSeatsPlan = getNextPlanWithUnlimitedSeats(plan.id);
  const nextUnlimitedSeatsPlanInfo = usePlanInfo(nextUnlimitedSeatsPlan?.id);
  const cycles = plan.cycles;

  const billingCycle = cycles[selectedBillingCycle];
  if (!billingCycle) {
    return null;
  }

  const isCurrentPlan = getIsCurrentPlan(plan.id);
  const isMostPopularPlan = getIsMostPopularPlan(plan.id);
  const shouldRenderMostPopularVariation = isMostPopularPlan && !isCurrentPlan;
  const shouldRenderCurrentPlanVariation = isCurrentPlan && !isMostPopularPlan;

  let variant: 'primary' | 'tertiary' = 'tertiary';

  if (
    (!subscription && !getIsFreePlan(plan.id)) ||
    (isCurrentPlan && (isBillingCycleToggledToYearly || isReactivationAllowed)) ||
    isUpgradedPlan(plan.id)
  ) {
    variant = 'primary';
  }

  const getIsDisabled = () => {
    if (futureAvailableDate) {
      return true;
    }

    if (isUpdatingSubscription && !isSelected) {
      return true;
    }

    if (isReactivationAllowed) {
      return pendingPlanId === plan.id;
    }

    if (isBillingCycleToggledToYearly && isCurrentPlan && getIsFreePlan(plan.id)) {
      return true;
    }

    return !isBillingCycleToggledToYearly && isCurrentPlan;
  };

  const isFreePlan = getIsFreePlan(plan.id);
  const isDisabled = getIsDisabled();
  const handleSelectPlanClick = () => onSelectPlan(plan.id, planName);

  const discount = getPlanPromo(plan)?.discount;
  const priceBeforeDiscount = discount ? cycles.monthly.basePrice : undefined;

  const { basePrice, unitPrice } =
    selectedBillingCycle === SubscriptionBillingCycleEnum.Annual
      ? {
          basePrice: new Big(billingCycle.basePrice).div(12).toNumber(),
          unitPrice: new Big(billingCycle.unitPrice || 0).div(12).toNumber(),
        }
      : billingCycle;

  const finalPrice = discount ? new Big(basePrice).mul(1 - discount).toNumber() : basePrice;

  return (
    <PlanCardView
      id={plan.id}
      headerProps={{ planBadge, planName, planDescription }}
      priceProps={{ isFreePlan, selectedBillingCycle, priceBeforeDiscount, finalPrice, unitPrice }}
      shouldRenderMostPopularVariation={shouldRenderMostPopularVariation}
      shouldRenderCurrentPlanVariation={shouldRenderCurrentPlanVariation}
      selectPlanButtonProps={{
        isDisabled,
        label: selectPlanButtonLabel,
        variant,
        onClick: handleSelectPlanClick,
        isSaving: isSelected && isUpdatingSubscription,
      }}
      featureListProps={{ capabilities }}
      planFeeProps={
        isFiservPartner
          ? {
              additionalSeatFee: {
                unitPrice,
                nextUnlimitedSeatsPlanInfo,
              },
            }
          : undefined
      }
    />
  );
};
