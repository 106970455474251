import { useEstimatedDeliveryDateLabel } from '@melio/ap-domain';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { DeliveryPreference, DeliveryPreferenceType, Payment, usePaymentIntent } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useState } from 'react';

import { useExtendedPaymentIntent } from '../../../../utils/pay-flow/useExtendedPaymentIntent';
import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';
import { PaymentScheduledNotifyVendorModalActivity } from '../../../PaymentScheduledNotifyVendor/PaymentScheduledNotifyVendor.modal-activity';
import { PaymentScheduledScreen } from '../screens/PaymentScheduled/PaymentScheduled.screen';
import { VoidAndResendFlowDoneAction } from '../types';

export type Props = {
  payment: Payment;
  paymentIntentId: string;
  onDone: (action: VoidAndResendFlowDoneAction) => void;
  onError?: ErrorFunction;
};

export const PaymentScheduledStep = ({ onDone: _onDone, onError, payment, paymentIntentId }: Props) => {
  const { createTrackHandler } = useAnalytics();
  const { formatDate, formatCurrency } = useMelioIntl();
  const trackAndHandleClick = createTrackHandler<{
    Cta: 'back-to-dashboard' | 'notify-vendor';
  }>('Payment', 'Click');
  const [isNotifyVendorModalOpen, setIsNotifyVendorModalOpen] = useState<boolean>(false);
  const { data: basicPaymentIntent, isLoading: isPaymentIntentLoading } = usePaymentIntent({ id: paymentIntentId });
  const paymentIntent = useExtendedPaymentIntent(basicPaymentIntent);

  useAnalyticsView('Payment', true, true);

  const onDone = () => {
    trackAndHandleClick({ Cta: 'back-to-dashboard' }, () => _onDone('goToDashboard'));
  };

  const onNotifyVendor = () => {
    trackAndHandleClick({ Cta: 'notify-vendor' }, () => setIsNotifyVendorModalOpen(true));
  };

  const amount = formatCurrency(payment.amount);
  const vendorName = payment.vendor?.name || '';
  const deliveryPreference = (paymentIntent?.deliveryPreferenceOptions ?? []).find(
    (deliveryPreferenceOption) => deliveryPreferenceOption.type === paymentIntent?.selectedDeliveryPreferenceType
  ) as DeliveryPreference;
  const { dateLabel: dateForCheck } = useEstimatedDeliveryDateLabel({
    minDeliveryDate: deliveryPreference.minDeliveryDate,
    maxDeliveryDate: deliveryPreference.maxDeliveryDate,
  });
  const dateForAch = formatDate(paymentIntent?.effectiveDeliveryDate || payment.deliveryDate, { dateStyle: 'medium' });
  const vendorId = payment.vendorId || '';
  const vendorEmailExists = !!payment.vendor?.contact.email;
  const vendorsIdsWithoutEmail = vendorEmailExists ? [] : [vendorId];
  const date = deliveryPreference.type === DeliveryPreferenceType.Check ? dateForCheck : dateForAch;

  if (isPaymentIntentLoading) {
    return <NewSinglePaymentStepLayout isLoading />;
  }

  return (
    <>
      <PaymentScheduledScreen
        date={date}
        amount={amount}
        vendorName={vendorName}
        onDone={onDone}
        onNotifyVendor={onNotifyVendor}
        shouldShowNotifyVendorButton={!vendorEmailExists}
        deliveryPreferenceType={deliveryPreference.type}
      />
      <PaymentScheduledNotifyVendorModalActivity
        onError={onError}
        isOpen={isNotifyVendorModalOpen}
        onClose={() => setIsNotifyVendorModalOpen(false)}
        onDone={() => {
          setIsNotifyVendorModalOpen(false);
          _onDone('goToDashboard');
        }}
        vendorsIds={vendorsIdsWithoutEmail}
      />
    </>
  );
};
