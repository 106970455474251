import { useMelioIntl } from '@melio/platform-i18n';

import { ApprovalsTabSortableCells, ApprovalsTabSortableColumn, CELLS_IDS } from './types';

export const useApprovalsTabSortableColumns = () => {
  const { formatMessage } = useMelioIntl();

  const vendorNameColumn: ApprovalsTabSortableColumn = {
    label: formatMessage('activities.payDashboard.paymentsTab.table.headers.vendor'),
    cellId: CELLS_IDS.VENDOR_NAME,
    defaultSortOrder: 'asc',
  };

  const debitDateColumn: ApprovalsTabSortableColumn = {
    label: formatMessage('activities.payDashboard.paymentsTab.table.headers.debitDate'),
    cellId: CELLS_IDS.DEBIT_DATE,
    defaultSortOrder: 'asc',
  };

  const amountColumn: ApprovalsTabSortableColumn = {
    label: formatMessage('activities.payDashboard.paymentsTab.table.headers.amount'),
    cellId: CELLS_IDS.AMOUNT,
    defaultSortOrder: 'desc',
  };

  const sortableColumns: { [key in ApprovalsTabSortableCells]: ApprovalsTabSortableColumn } = {
    [CELLS_IDS.DEBIT_DATE]: debitDateColumn,
    [CELLS_IDS.VENDOR_NAME]: vendorNameColumn,
    [CELLS_IDS.AMOUNT]: amountColumn,
  };

  return { sortableColumns };
};
