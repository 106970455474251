import { usePlanInfo } from './usePlanInfo';
import { usePlansTiers } from './usePlansTiers';
import { useSubscription } from './useSubscription';

export const usePendingSubscription = () => {
  const { getIsFreePlan } = usePlansTiers();
  const subscription = useSubscription();
  const nextSubscription = subscription?.nextSubscription;

  const nextSubscriptionPlanId = nextSubscription?.planId;
  const pendingPlanInfo = usePlanInfo(nextSubscriptionPlanId);

  const allowReactivation = subscription && nextSubscriptionPlanId && nextSubscriptionPlanId !== subscription.planId;
  const isPendingPlanFree = allowReactivation && getIsFreePlan(nextSubscriptionPlanId);

  return {
    pendingPlanInfo,
    nextSubscriptionPlanId,
    isReactivationAllowed: allowReactivation,
    isPendingPlanFree,
  };
};
