import { Modal, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';

export type OneTimeMessageModalWidgetProps = {
  isOpen: boolean;
  onDone: VoidFunction;
  isPayVendor: boolean;
};
export const OneTimeMessageModalWidget = forwardRef<OneTimeMessageModalWidgetProps, 'div'>(
  ({ isOpen, onDone, isPayVendor }, ref) => {
    const { formatMessage } = useMelioIntl();

    const header = formatMessage('widgets.messageModal.header');
    const description = formatMessage(
      `widgets.messageModal.${isPayVendor ? 'billsInPayVendorsOnly' : 'billsInMelioComOnly'}`
    );
    const buttonLabel = formatMessage('widgets.messageModal.button');

    return (
      <Modal
        ref={ref}
        header={header}
        primaryButton={{
          label: buttonLabel,
          variant: 'primary',
          onClick: onDone,
        }}
        isOpen={isOpen}
        onClose={onDone}
        data-testid="one-time-message-modal"
      >
        <Text>{description}</Text>
      </Modal>
    );
  }
);

OneTimeMessageModalWidget.displayName = 'OneTimeMessageModalWidget';
