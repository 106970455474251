import { useCollectionApi, UseCollectionApiProps } from '@melio/api-client';
import {
  OrganizationVerifiedPersona,
  PersonasApiClient,
  UpsertOrganizationVerifiedPersonaRequest,
} from '@melio/platform-api-axios-client';
import { updateDateTimeToDateString } from '@melio/platform-utils/src/date-utils/utils';

type QueryFn = typeof PersonasApiClient.getVerifiedPersonas;
type CreateFn = (
  data: UpsertOrganizationVerifiedPersonaRequest[]
) => ReturnType<typeof PersonasApiClient.bulkUpsertVerifiedPersonas>;

export type ListFn = typeof PersonasApiClient.getVerifiedPersonas;

export type UseVerifiedPersonasProps = UseCollectionApiProps<QueryFn, OrganizationVerifiedPersona> & {
  onCreate?: (res: OrganizationVerifiedPersona) => void;
  onCreateError?: ErrorFunction;
};

const prepareCreateParams = (params: UpsertOrganizationVerifiedPersonaRequest[]) =>
  params.map((req) => updateDateTimeToDateString(req, ['dateOfBirth']));

export const useVerifiedPersonas = (props?: UseVerifiedPersonasProps) => {
  const query = useCollectionApi<ListFn, CreateFn, never, never, OrganizationVerifiedPersona>({
    ...props,
    enabled: props?.enabled ?? true,
    queryKey: ['PersonasApi'],
    queryFn: PersonasApiClient.getVerifiedPersonas,
    prepareCreateParams,
    createFn: PersonasApiClient.bulkUpsertVerifiedPersonas,
  });

  return query;
};
