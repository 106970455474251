/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { Group } from '@melio/penny';
import { useAnalytics, useAnalyticsContext, withAnalyticsContext } from '@melio/platform-analytics';
import { useAccountingPlatforms, useBill } from '@melio/platform-api';

import { Loader } from '@/cl/components/Loader/Loader.component';
import { useRouter } from '@/hooks/router.hooks';
import { useIsInternationalSupportForBusiness } from '@/hooks/vendorInternational.hooks';
import { BillPaymentDetailsEmptyState } from '@/widgets/billPaymentDetailsEmptyState/BillPaymentDetailsEmptyState.widget';
import { BillDetails } from '@/widgets/pay-dashboard/bill-details/BillDetails.widget';
import { BillDetailsScreenTop } from '@/widgets/pay-dashboard/bill-details-screen-top/BillDetailsScreenTop.widget';

export const BillDetailsScreen = withAnalyticsContext(({ billId }: { billId: string }) => {
  const { navigateToSchedulePayment } = useRouter();
  const { isInternationalSupportedForBusiness } = useIsInternationalSupportForBusiness();
  const { track } = useAnalytics();
  const billDetailsRef = useRef<HTMLDivElement>(null);

  const { data: bill, isLoading: billIsLoading, refetch: refetchBill } = useBill({ id: billId });
  const { isFetching: accountingPlatformIsLoading, activeAccountingPlatform } = useAccountingPlatforms();

  useEffect(() => {
    refetchBill();
  }, []);

  useAnalyticsContext({ globalProperties: { BillId: billId } });

  const schedulePayment = () => {
    track('PayDashboardSchedulePayment', 'chose', {
      BillId: billId,
    });
    navigateToSchedulePayment({ billId });
  };

  if (billIsLoading || accountingPlatformIsLoading) return <Loader isAbsoluteCenter />;
  if (!bill) return <BillPaymentDetailsEmptyState />;
  return (
    <Group variant="vertical">
      <Box display="flex" gridGap={{ xs: '24px', s: '40px' }} flexDir="column" data-testid="pay-dashboard-bill">
        <BillDetailsScreenTop
          onScheduleClick={schedulePayment}
          bills={[bill]}
          isInternationalSupportedForBusiness={isInternationalSupportedForBusiness}
        />
        <BillDetails
          key={bill?.id}
          bill={bill}
          ref={billDetailsRef}
          activeAccountingPlatform={activeAccountingPlatform}
        />
      </Box>
    </Group>
  );
});
