import { Grid, Illustration, Modal, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { useFreeTrialPeriod, usePlanInfo, useSubscription } from '../../../../../hooks';

export type SubscriptionCancelFreeTrialGoingSoSoonModalScreenProps = {
  isOpen: boolean;
  onClose: () => void;
  onProceedToCancel: () => void;
};

export const SubscriptionCancelFreeTrialGoingSoSoonModalScreen = ({
  isOpen,
  onProceedToCancel,
  onClose,
}: SubscriptionCancelFreeTrialGoingSoSoonModalScreenProps) => {
  const { formatMessage } = useMelioIntl();

  const subscription = useSubscription();
  const { planName } = usePlanInfo(subscription?.planId);

  const { daysReminderBeforeChargingDate } = useFreeTrialPeriod();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={formatMessage('activities.subscription.cancel.freeTrialGoingSoSoon.title')}
      data-testid="subscription-cancel-free-trial-going-so-soon-modal-screen"
      primaryButton={{
        label: formatMessage('activities.subscription.cancel.freeTrialGoingSoSoon.actions.keepMyPlan'),
        onClick: onClose,
        variant: 'primary',
      }}
      secondaryButton={{
        label: formatMessage('activities.subscription.cancel.freeTrialGoingSoSoon.actions.proceedToCancel'),
        onClick: onProceedToCancel,
        variant: 'tertiary',
      }}
    >
      <Grid gap="s" gridTemplateColumns="auto auto">
        <Text>
          {formatMessage('activities.subscription.cancel.freeTrialGoingSoSoon.description', {
            planName,
            daysReminderBeforeFreeTrialEnd: daysReminderBeforeChargingDate,
          })}
        </Text>
        <Illustration type="notification" size="large" />
      </Grid>
    </Modal>
  );
};
