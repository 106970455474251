import { Box } from '@chakra-ui/react';
import { useSetRecoilState } from 'recoil';
import { Button } from '@melio/penny';
import { Bill, BillInfo } from '@melio/platform-api';

import { payDashboardSelectedCardSelector } from '@/store/PayDashboard/PayDashboards.model';
import { usePlatformIntl } from '@/translations/Intl';
import { PayDetailsTop } from '@/widgets/pay-dashboard/pay-details-screen-top/PayDetailsTop.component';

type PayDetailsScreenTopParams = {
  onScheduleClick: VoidFunction;
  bills: (BillInfo | Bill)[];
  isInternationalSupportedForBusiness?: boolean;
};

export const BillDetailsScreenTop = ({
  onScheduleClick,
  bills,
  isInternationalSupportedForBusiness,
}: PayDetailsScreenTopParams) => {
  const { formatMessage } = usePlatformIntl();

  const setSelectedPaymentCard = useSetRecoilState(payDashboardSelectedCardSelector);

  let subTitleText = '';

  if (bills.length > 1) {
    subTitleText = formatMessage('widgets.payDetailsScreen.multipleBills', {
      numberOfBills: bills.length,
    });
  } else if (bills[0]?.invoice.number) {
    subTitleText = formatMessage('widgets.payDetailsScreen.invoiceNumber', {
      invoiceNumber: bills[0]?.invoice.number,
    });
  }

  const vendorName = bills?.[0]?.vendor?.name || '';
  const isInternationalVendor = bills?.[0]?.vendor?.businessGeography === 'international';
  const subTitle = formatMessage('widgets.payDetailsScreen.subTitle', {
    subTitle: subTitleText,
    viewDetails: '',
  });

  const shouldDisableSchedule = isInternationalVendor && !isInternationalSupportedForBusiness;
  return (
    <PayDetailsTop vendorName={vendorName} subTitle={subTitle} onBackClick={() => setSelectedPaymentCard({})}>
      {
        <Box width={'157px'}>
          <Button
            onClick={onScheduleClick}
            isDisabled={shouldDisableSchedule}
            variant={'success'}
            data-testid={'schedule-payment-button'}
            label={formatMessage('widgets.payDetailsScreen.schedulePayment')}
          />
        </Box>
      }
    </PayDetailsTop>
  );
};
