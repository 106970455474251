import { isEqual } from 'lodash';
import { Traits } from '@melio/platform-analytics';
import {
  Account,
  AccountAddressParams,
  Address,
  OrganizationBusinessType,
  PatchAccountsAccountIdRequest,
  PatchAccountsAccountIdRequestCompany,
  PatchAccountsAccountIdRequestUser,
  TaxIdTypeEnum,
} from '@melio/platform-api';

import { CompanyFormDetails } from '@/widgets/settings-page/CompanySettingPage/CompanyForm.widget';

export const getUpdatedParams = (
  originalValues: Account,
  newValues: CompanyFormDetails,
): PatchAccountsAccountIdRequest => {
  const user: PatchAccountsAccountIdRequestUser = {};
  const company: PatchAccountsAccountIdRequestCompany = {
    taxInfo: {
      identifier: undefined,
      type: originalValues.company.taxInfo?.type || null,
    },
  };

  if (newValues.contactFirstName !== originalValues.company.contactFirstName) {
    company.contactFirstName = newValues.contactFirstName;
  }
  if (newValues.contactLastName !== originalValues.company.contactLastName) {
    company.contactLastName = newValues.contactLastName;
  }

  if (newValues.bizName !== originalValues.company.name) {
    company.name = newValues.bizName;
  }
  if (newValues.mobileNumber !== originalValues.company.phoneNumber) {
    company.phoneNumber = newValues.mobileNumber;
  }
  if (newValues.businessType && newValues.businessType !== originalValues.company.businessType) {
    company.businessType = newValues.businessType as OrganizationBusinessType;
  }
  if (newValues.taxIdNumber && newValues.taxIdNumber != originalValues.company.taxInfo?.identifier) {
    company.taxInfo!.identifier = newValues.taxIdNumber;
  }
  if (newValues.taxIdType && newValues.taxIdType !== originalValues.company.taxInfo?.type) {
    company.taxInfo!.type = newValues.taxIdType as TaxIdTypeEnum;
  }
  if (newValues.legalBizName !== originalValues.company.legalName) {
    company.legalName = newValues.legalBizName;
  }
  const newBizAddress = newValues.bizAddress;
  if (!isEqual(newBizAddress, originalValues.company.address)) {
    company.address = newBizAddress as AccountAddressParams;
  }
  if (newValues.suite !== originalValues.company.address.line2) {
    const CompanyAddress = company.address ? company.address : originalValues.company.address;
    company.address = {
      ...(CompanyAddress as AccountAddressParams),
      line2: newValues.suite,
    };
  }
  if (!isEqual(newValues.legalBizAddress, originalValues.company.legalAddress)) {
    company.legalAddress = newValues.legalBizAddress as Address;
  }

  return {
    ...(user ? { user } : {}),
    ...(company ? { company } : {}),
  };
};

export const getTraits = (values: CompanyFormDetails): Traits => {
  const traits: Traits = {};
  const { contactFirstName, contactLastName, mobileNumber, bizName } = values;

  if (bizName) {
    traits.companyName = bizName;
    traits.company = bizName;
    traits.organization = bizName;
  }

  if (mobileNumber) {
    traits.phone = mobileNumber;
  }

  if (contactFirstName) {
    traits.firstName = contactFirstName;
    traits.contactFirstName = contactFirstName;
  }

  if (contactLastName) {
    traits.lastName = contactLastName;
    traits.contactLastName = contactLastName;
  }

  if (contactFirstName && contactLastName) {
    traits.contactName = `${contactFirstName} ${contactLastName}`;
    traits.name = `${contactFirstName} ${contactLastName}`;
  }

  return traits;
};
