import {
  useCreateMutation,
  useMelioQueryClient,
  useModelApi,
  UseModelApiProps,
  useUpdateMutation,
} from '@melio/api-client';
import { DeliveryMethodsApiClient, extractData } from '@melio/platform-api-axios-client';
import { useCallback } from 'react';

import { DeliveryMethod, PatchDeliveryMethodsRequest, PostDeliveryMethodsRequest } from './types';

type QueryFN = typeof DeliveryMethodsApiClient.getDeliveryMethodsDeliveryMethodId;
type UpdateFN = typeof DeliveryMethodsApiClient.patchDeliveryMethodsDeliveryMethodId;
type DeleteFN = typeof DeliveryMethodsApiClient.deleteDeliveryMethodsDeliveryMethodId;
type CreateFN = typeof DeliveryMethodsApiClient.postDeliveryMethods;

export type UseDeliveryMethodProps<T extends DeliveryMethod = DeliveryMethod> = UseModelApiProps<QueryFN, T>;

export const useDeliveryMethod = <T extends DeliveryMethod = DeliveryMethod>(props: UseDeliveryMethodProps<T> = {}) => {
  const queryClient = useMelioQueryClient();
  const query = useModelApi<
    QueryFN,
    UpdateFN,
    DeleteFN,
    CreateFN,
    T,
    PostDeliveryMethodsRequest,
    PatchDeliveryMethodsRequest
  >({
    ...props,
    queryKey: 'DeliveryMethodsApi',
    queryFn: DeliveryMethodsApiClient.getDeliveryMethodsDeliveryMethodId,
    createFn: DeliveryMethodsApiClient.postDeliveryMethods,
    deleteFn: DeliveryMethodsApiClient.deleteDeliveryMethodsDeliveryMethodId,
    updateFn: DeliveryMethodsApiClient.patchDeliveryMethodsDeliveryMethodId,
    onDelete: () => queryClient.invalidateQueries('OrganizationPreferencesApi', 'model', 'isVendorAbsorbedFee'),
    onUpdate: () => queryClient.invalidateQueries('OrganizationPreferencesApi', 'model', 'isVendorAbsorbedFee'),
  });

  const verifyMutation = useCreateMutation(
    DeliveryMethodsApiClient.postDeliveryMethodsVerifyMicroDeposit,
    query.queryKey
  );

  const verifyPlaidAccount = useCreateMutation(DeliveryMethodsApiClient.postDeliveryMethods, query.queryKey);
  const updatePlaidAccount = useUpdateMutation(
    DeliveryMethodsApiClient.patchDeliveryMethodsDeliveryMethodId,
    query.queryKey,
    { onSuccess: () => queryClient.invalidateQueries('OrganizationPreferencesApi', 'model', 'isVendorAbsorbedFee') }
  );

  const getDeliveryMethodUnmaskedAccountNumber = useCallback(
    () =>
      props.id ? DeliveryMethodsApiClient.getDeliveryMethodUnmaskedAccountNumber(props.id).then(extractData) : null,
    [props.id]
  );

  return {
    ...query,
    verify: verifyMutation.mutateAsync,
    verifyPlaidAccount: verifyPlaidAccount.mutateAsync,
    updatePlaidAccount: updatePlaidAccount.createCallback(props.id as string),
    getDeliveryMethodUnmaskedAccountNumber,
  };
};

export type DeliveryMethodModel = ReturnType<typeof useDeliveryMethod>;
