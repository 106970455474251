import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { Payment } from '@melio/platform-api';
import { useEffect } from 'react';

import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';
import { ProcessingRefundScreen } from '../screens/ProcessingRefund/ProcessingRefund.screen';
import { VoidAndRefundFlowDoneAction } from '../types';

type Props = {
  payment: Payment;
  isVoidAndRefundError: boolean;
  onFlowDone: (action: VoidAndRefundFlowDoneAction) => void;
};

export const VoidAndRefundSentStep = ({ isVoidAndRefundError, payment, onFlowDone }: Props) => {
  const { fundingSource } = payment;
  const { track, createTrackHandler } = useAnalytics();
  const trackAndHandleClick = createTrackHandler<{
    Cta: 'back-to-pay';
  }>('VoidAndRefundPayment', 'Click');
  useAnalyticsView('processing-void-and-refund');

  useEffect(() => {
    if (isVoidAndRefundError) {
      track('Payment', 'Error');
    }
  }, [isVoidAndRefundError, track]);

  const onDone = () => {
    trackAndHandleClick({ Cta: 'back-to-pay' }, () => onFlowDone('goToDashboard'));
  };

  if (!fundingSource) {
    return <NewSinglePaymentStepLayout />;
  }

  return <ProcessingRefundScreen vendorName={payment.vendor?.name || ''} onDone={onDone} />;
};
