import { Container, Group, Illustration } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';

type Props = {
  accountingPlatformName?: string;
  isLoadingAccountingPlatform: boolean;
};

export const AccountingPlatformSyncScreen = ({ accountingPlatformName, isLoadingAccountingPlatform }: Props) => {
  const { formatMessage } = useMelioIntl();
  return (
    <NewSinglePaymentStepLayout
      data-component="AccountingPlatformConnectActivity.SyncScreen"
      data-testid="accounting-platform-connect-sync-screen"
      isLoading={isLoadingAccountingPlatform}
    >
      <Container paddingTop="xxxl">
        <Group variant="vertical" alignItems="center">
          <Illustration type="processing" size="large" />
          <NewSinglePaymentStepLayout.Title paddingTop="xl">
            {formatMessage('activities.accountingPlatformConnectCallback.screens.sync.title', {
              accountingPlatformName,
            })}
          </NewSinglePaymentStepLayout.Title>
          <NewSinglePaymentStepLayout.Description>
            {formatMessage('activities.accountingPlatformConnectCallback.screens.sync.description')}
          </NewSinglePaymentStepLayout.Description>
        </Group>
      </Container>
    </NewSinglePaymentStepLayout>
  );
};
