import { LoadingContainer } from '@melio/penny';
import React, { useEffect, useRef } from 'react';

export const PaymentFlowLoader = () => {
  const loadingRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!loadingRef.current) {
      return;
    }

    loadingRef.current.focus();
  }, []);

  return <LoadingContainer isLoading tabIndex={0} ref={loadingRef} />;
};
