import { GoogleAddressInput, InternationalAddress } from '@melio/form-controls';
import { Form, useMelioForm } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useBoolean } from '@melio/platform-utils';
import React, { ComponentProps, useEffect, useMemo, useState } from 'react';

import { getVendorMissingDetailsList } from '../../../BatchPayments/screens/PaymentIntentsTable/PaymentIntentsTable.screen.analytics-utils';
import { useRestrictedCountry } from './hooks/useRestrictedCountry.hook';
import { useSchema } from './hooks/useSchema.hook';
import { VendorDetailsFormFields, VendorDetailsFormProps } from './types';
import { VendorMccFields } from './VendorMccFields';

export const VendorDetailsForm = ({
  onSubmit,
  onSubmissionStateChange,
  isSaving,
  mccIndustryList,
  requiredFormFields,
  cardNetwork,
}: VendorDetailsFormProps) => {
  const { track } = useAnalytics();
  const { formatMessage } = useMelioIntl();
  const [showFullAddress, setShowFullAddress] = useBoolean(false);
  const [hasCityFromAutocomplete, setHasCityFromAutocomplete] = useState(false);
  const { restrictedCountry } = useRestrictedCountry(cardNetwork);
  const requiredFields = useMemo(
    () => ({
      mcc: requiredFormFields.includes('mcc'),
      address: requiredFormFields.includes('address'),
      email: requiredFormFields.includes('email'),
      phone: requiredFormFields.includes('phone'),
    }),
    [requiredFormFields]
  );
  const mccGroupsSupported = useMemo(() => mccIndustryList.some((x) => Boolean(x.category)), [mccIndustryList]);

  const form = useMelioForm<VendorDetailsFormFields>({
    onSubmit,
    schema: useSchema(requiredFields, mccGroupsSupported),
    defaultValues: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      stateCode: '',
      country: '',
      countryCode: '',
      postalCode: '',
    },
    isSaving,
    onSubmissionStateChange,
  });

  useEffect(() => {
    if (Object.values(form.formState.errors).length) {
      track('PaymentMethodOptions', 'Status', {
        PageName: 'add-vendor-details',
        DetailsToFill: getVendorMissingDetailsList(requiredFormFields),
        CardNetwork: cardNetwork,
        Intent: 'add-vendor-business-details',
        ErrorType: 'invalid-values',
        Status: 'failure',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.formState.errors]);

  const setValuesToAddress = (address: InternationalAddress) => {
    form.setValue('line1', address.line1, { shouldValidate: true });
    // if no code is provided, address is not valid and we shouldn't use the name.
    form.setValue('state', address.state.code ? address.state.name : address.state.code, { shouldValidate: true });
    form.setValue('stateCode', address.state.code, { shouldValidate: true });
    form.setValue('city', address.city.name, { shouldValidate: true });
    form.setValue('postalCode', address.postalCode, { shouldValidate: true });
    form.setValue('countryCode', address.country.code, { shouldValidate: true });
    form.setValue('country', address.country.code ? address.country.name : address.country.code, {
      shouldValidate: true,
    });

    setShowFullAddress.on();
    setHasCityFromAutocomplete(!!address.city.name);
  };

  const handleAddressChange: ComponentProps<typeof GoogleAddressInput>['onChange'] = ({ target: { value } }) => {
    setValuesToAddress(value as unknown as InternationalAddress);
  };

  return (
    <Form data-component="VendorDetailsForm" columns={2} size="small" {...form.formProps}>
      {requiredFields.mcc && (
        <VendorMccFields form={form} mccIndustryList={mccIndustryList} mccGroupsSupported={mccGroupsSupported} />
      )}
      {requiredFields.email && (
        <Form.TextField
          size="small"
          colSpan={2}
          {...form.registerField('email')}
          labelProps={{ label: formatMessage('activities.fundingSources.vendorDetailsForm.fields.email.label') }}
          placeholder={formatMessage('activities.fundingSources.vendorDetailsForm.fields.email.placeholder')}
          data-testid="vendor-details-email-input"
        />
      )}
      {requiredFields.phone && (
        <Form.PhoneField
          size="small"
          colSpan={2}
          {...form.registerField('phone')}
          labelProps={{ label: formatMessage('activities.fundingSources.vendorDetailsForm.fields.phone.label') }}
          placeholder={formatMessage('activities.fundingSources.vendorDetailsForm.fields.phone.placeholder')}
          data-testid="vendor-details-phone-input"
        />
      )}
      {requiredFields.address && (
        <>
          <GoogleAddressInput
            restrictedCountry={restrictedCountry}
            colSpan={2}
            labelProps={{ label: formatMessage(`activities.fundingSources.vendorDetailsForm.fields.line1.label`) }}
            placeholder={formatMessage(`activities.fundingSources.vendorDetailsForm.fields.line1.placeholder`)}
            onChange={handleAddressChange}
            {...form.registerField('line1')}
          />
          {showFullAddress ? (
            <>
              <Form.TextField
                colSpan={2}
                labelProps={{ label: formatMessage(`activities.fundingSources.vendorDetailsForm.fields.line2.label`) }}
                {...form.registerField('line2')}
                data-testid="vendor-details-address-line2-input"
              />
              <Form.TextField
                labelProps={{ label: formatMessage(`activities.fundingSources.vendorDetailsForm.fields.city.label`) }}
                {...form.registerField('city')}
                isReadOnly={hasCityFromAutocomplete}
                data-testid="vendor-details-address-city-input"
              />
              <Form.TextField
                labelProps={{ label: formatMessage(`activities.fundingSources.vendorDetailsForm.fields.state.label`) }}
                {...form.registerField('state')}
                isReadOnly
                data-testid="vendor-details-address-state-input"
              />
              <Form.TextField
                labelProps={{
                  label: formatMessage(`activities.fundingSources.vendorDetailsForm.fields.country.label`),
                }}
                {...form.registerField('country')}
                isReadOnly
                data-testid="vendor-details-address-country-input"
              />
              <Form.TextField
                labelProps={{
                  label: formatMessage(`activities.fundingSources.vendorDetailsForm.fields.postalCode.label`),
                }}
                {...form.registerField('postalCode')}
                data-testid="vendor-details-address-postalCode-input"
              />
            </>
          ) : null}
        </>
      )}
    </Form>
  );
};
