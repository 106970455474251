import {
  BillingFeeSetting,
  CompanyType,
  FundingSource,
  useCollaborator,
  useFundingSources,
  useIsMemberOfAccountingFirm,
  useOrganizations,
  useOrgBillingFeeSettings,
} from '@melio/platform-api';

import { getBillingMethod } from './utils';

export const useBillingFeeData = (): {
  firmId?: string;
  userId?: string;
  fundingSources: FundingSource[];
  currentBillingFeeSetting?: BillingFeeSetting;
  firmFundingSourceId?: string;
  currentFundingSourceId?: string;
  isLoading: boolean;
  isUserPartOfFirm: boolean;
  isMethodAssignedByFirm: boolean;
} => {
  const { data: fundingSources = [], isFetching: isFundingSourcesLoading } = useFundingSources({
    params: { include: 'firmBillingFeeMethod' },
  });
  const { data: actor, isLoading: isLoadingActor } = useCollaborator({ id: 'me' });
  const { data: orgBillingFees, isLoading: isLoadingOrgBillingFees } = useOrgBillingFeeSettings();
  const { data: organizations = [] } = useOrganizations();
  const isUserPartOfFirm = useIsMemberOfAccountingFirm();
  const firmId = organizations.find((o) => o.companyType === CompanyType.AccountingFirm)?.id;

  const currentBillingFeeSetting = getBillingMethod(orgBillingFees);
  const currentOrgFundingSource = fundingSources.find(({ id }) => id === currentBillingFeeSetting?.fundingSourceId);
  const firmFundingSource = fundingSources.find(({ organizationId }) => organizationId === firmId);
  const firmFundingSourceId = firmFundingSource?.id;
  const isMethodAssignedByFirm = Boolean(currentBillingFeeSetting?.managedByOrganizationId);
  const currentFundingSourceId =
    currentOrgFundingSource?.id || (isMethodAssignedByFirm ? firmFundingSourceId : undefined);

  return {
    firmId,
    userId: actor?.userId,
    fundingSources,
    currentBillingFeeSetting,
    firmFundingSourceId,
    currentFundingSourceId,
    isLoading: isLoadingOrgBillingFees || isFundingSourcesLoading || isLoadingActor,
    isUserPartOfFirm,
    isMethodAssignedByFirm,
  };
};
