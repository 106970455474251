import { Box, Heading, Text, useTheme, useTimeout } from '@chakra-ui/react';
import { Button, Group, Illustration, Loader, Theme } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { BillProvider } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { useBillProviders } from '../hooks/useBillProviders';
import { useEbillsImportState } from '../hooks/useEbillsImportState';
import { buildRedirectStateEncodedURLSearchParams, useProviderDisplayProps } from '../hooks/useProviderDisplayProps';
import { EbillsImportRedirectStateEntryPoint } from '../types';

const getCustomTheme = (currentTheme: Theme) => ({
  screen: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
  container: {
    justifyContent: 'center',
    flex: 1,
    width: { xs: 'auto', s: '488px' },
    gridGap: '40px',
    display: 'flex',
    flexDir: 'column',
    alignItems: 'center',
    margin: '0 auto',
    padding: { xs: '0 16px', s: 'initial' },
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleContainer: {
    gridGap: '16px',
    display: 'flex',
    flexDir: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
    ...currentTheme.textStyles.heading1Semi,
  },
  subtitle: {
    textAlign: 'center',
    ...currentTheme.textStyles.body3,
  },
  childrenContainer: {
    gridGap: '40px',
    paddingBottom: '20px',
    width: '100%',
    display: 'flex',
    flexDir: 'column',
    alignItems: 'center',
  },
});

export const RedirectPage = () => {
  const [shouldRender, toggleRender] = useState(false);
  const styles = getCustomTheme(useTheme());

  useTimeout(() => {
    toggleRender(true);
  }, 7000);

  if (shouldRender) {
    return <ProviderSplash />;
  }

  return (
    <Box sx={styles.screen}>
      <Box sx={styles.container}>
        <Loader />
      </Box>
    </Box>
  );
};

const ProviderSplash = () => {
  const { redirectState, error } = useEbillsImportState();
  const providers = useBillProviders();
  const provider = providers.find((p) => p.name == redirectState?.provider);

  if (provider) {
    return <FullPageSplash provider={provider} entryPoint={redirectState?.entryPoint} error={error} />;
  }

  return <Navigate to="/404" />;
};

const FullPageSplash = ({
  provider,
  error,
  entryPoint = EbillsImportRedirectStateEntryPoint.Settings,
}: {
  provider: BillProvider;
  error: string | null;
  entryPoint?: EbillsImportRedirectStateEntryPoint;
}) => {
  const styles = getCustomTheme(useTheme());
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const navigate = useNavigate();
  const { redirectURL, displayName } = useProviderDisplayProps(provider, entryPoint);

  useEffect(() => {
    track('Bill', 'View', {
      PageName: error ? `couldn't-connect-your-${provider.name}` : `your-${provider.name}-is-now-connected`,
      Flow: 'dashboard',
      Intent: 'bill-import-connect-account',
      Provider: provider.name,
    });
  }, [track, error, provider.name]);

  const title = error
    ? formatMessage('activities.eBillsImport.authRedirect.error.title')
    : formatMessage('activities.eBillsImport.authRedirect.success.title', { provider: displayName });

  const subtitle = error
    ? formatMessage('activities.eBillsImport.authRedirect.error.subtitle', { provider: displayName })
    : formatMessage(`activities.eBillsImport.authRedirect.success.${provider.name}.subtitle`);

  const icon = error ? (
    <Illustration size="large" type="network-error" />
  ) : (
    <Illustration size="large" type="success" />
  );

  const dashboardUrl = `/pay-dashboard/bills`;

  const trackClick = (cta: string) => {
    track('Bill', 'Click', {
      PageName: error ? `couldn't-connect-your-${provider.name}` : `your-${provider.name}-is-now-connected`,
      Flow: 'dashboard',
      Intent: 'bill-import-connect-account',
      Status: error ? 'error' : 'success',
      Provider: provider.name,
      Cta: cta,
      IsEmptyState: false,
      EntryPoint: entryPoint,
    });
  };

  const successButtonTextMap: Partial<Record<EbillsImportRedirectStateEntryPoint, string>> = {
    [EbillsImportRedirectStateEntryPoint.Onboarding]: formatMessage(
      'activities.eBillsImport.authRedirect.success.cta.onboarding'
    ),
  };

  const successButtonLabel =
    successButtonTextMap[entryPoint] || formatMessage('activities.eBillsImport.authRedirect.success.cta');

  return (
    <Box data-component="FullScreenContainer" sx={styles.screen}>
      <Box sx={styles.container}>
        <Box sx={styles.iconContainer}>{icon}</Box>
        <Box sx={styles.titleContainer}>
          {/* 
         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore*/}
          <Heading sx={styles.title}>{title}</Heading>
          {/* 
         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore*/}
          <Text sx={styles.subtitle}>{subtitle}</Text>
        </Box>
        <Box sx={styles.childrenContainer}>
          <Group justifyContent="center" variant="horizontal" width="fit-content">
            {error ? (
              <>
                <Button
                  onClick={() => {
                    trackClick('go-to-dashboard');
                    navigate(dashboardUrl, { replace: true });
                  }}
                  label={successButtonLabel}
                  isFullWidth
                  variant="secondary"
                />
                <Button
                  label={formatMessage('activities.eBillsImport.authRedirect.error.cta')}
                  onClick={() => {
                    trackClick('try-again');
                    provider.connect(redirectURL);
                  }}
                  isFullWidth
                  variant="primary"
                />
              </>
            ) : (
              <Button
                onClick={() => {
                  trackClick('go-to-dashboard');
                  navigate({
                    pathname: dashboardUrl,
                    // Handle url encoded
                    search: `?${buildRedirectStateEncodedURLSearchParams({ provider: provider.name, entryPoint })}`,
                  });
                }}
                label={successButtonLabel}
              />
            )}
          </Group>
        </Box>
      </Box>
    </Box>
  );
};
