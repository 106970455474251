import { PartnerGroupEnum } from '@melio/partner-tools';

import { usePartnerGroup } from './usePartnerGroup';

export const usePartnerGroups = () => {
  const partnerGroup = usePartnerGroup();

  const isFiservPartner = PartnerGroupEnum.FISERV === partnerGroup;

  return { partnerGroup, isFiservPartner };
};
