import { ActionsDropdownMenuItemProps } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';

import { SubscriptionBillingCycleEnum } from '../../../../api';
import { usePendingSubscription, usePlansTiers, useSubscription } from '../../../../hooks';
import { useSubscriptionRouter } from '../../../utils';

type UseEditablePlanCardActionsProps = {
  onOpenCancelModal: () => void;
  onOpenSwitchBillingCycleModal: () => void;
};

export const useEditablePlanCardActions = ({
  onOpenCancelModal,
  onOpenSwitchBillingCycleModal,
}: UseEditablePlanCardActionsProps) => {
  const { formatMessage } = useMelioIntl();
  const { goToSubscriptionPlans } = useSubscriptionRouter();
  const { isReactivationAllowed } = usePendingSubscription();
  const subscription = useSubscription();
  const { getPlan } = usePlansTiers();
  const { track } = useAnalytics();

  const onManagePlan = () => {
    track('Settings', 'Click', {
      Intent: 'manage-subscription',
      Cta: 'manage-subscription',
    });
    goToSubscriptionPlans({ returnUrl: '/settings/subscription-plans' });
  };

  const managePlanAction = {
    label: formatMessage('activities.settings.editablePlanCard.action.managePlan'),
    dataTestId: 'subscription-plans-manage',
    onClick: onManagePlan,
  };

  const switchBillingCycleAction = {
    label:
      subscription?.planCyclePeriod === SubscriptionBillingCycleEnum.Monthly
        ? formatMessage('activities.settings.editablePlanCard.action.changeBillingCycle.switchToAnnual')
        : formatMessage('activities.settings.editablePlanCard.action.changeBillingCycle.switchToMonthly'),
    dataTestId: 'subscription-plans-switch-billing-cycle',
    onClick: () => {
      const cycle = subscription?.planCyclePeriod === SubscriptionBillingCycleEnum.Monthly ? 'yearly' : 'monthly';
      const intent = `switch-to-${cycle}`;
      track('Settings', 'Click', {
        Intent: intent,
        Cta: intent,
      });
      onOpenSwitchBillingCycleModal();
    },
  };

  const isSwitchCycleAvailable = () => {
    if (!subscription?.planId || subscription?.planCyclePeriod === SubscriptionBillingCycleEnum.Annual) {
      return false;
    }
    const plan = getPlan(subscription?.planId);
    return !!plan?.cycles.annual;
  };

  const cancelPlanAction = {
    label: formatMessage('activities.settings.editablePlanCard.action.removePlan'),
    dataTestId: 'subscription-plans-remove',
    variant: 'critical' as const,
    onClick: () => {
      const intent = 'cancel-my-plan';
      track('Settings', 'Click', {
        Intent: intent,
        Cta: intent,
      });
      onOpenCancelModal();
    },
  };

  const actions: ActionsDropdownMenuItemProps[] = [managePlanAction];

  if (!isReactivationAllowed) {
    if (isSwitchCycleAvailable()) {
      actions.push(switchBillingCycleAction);
    }
    actions.push(cancelPlanAction);
  }

  return actions;
};
