import { Button, Group, useBreakpoint, UseMelioFormResults } from '@melio/penny';

import { FormFields } from '../../types';

type Props = {
  submitLabel: string;
  submitButtonProps?: UseMelioFormResults<FormFields>['submitButtonProps'];
  cancelLabel: string;
  cancelButtonProps?: UseMelioFormResults<FormFields>['cancelButtonProps'] & { onClick: VoidFunction };
};
export const CollaboratorDrawerFooter = ({ submitLabel, submitButtonProps, cancelLabel, cancelButtonProps }: Props) => {
  const { isExtraSmallScreen } = useBreakpoint();

  return (
    <Group data-testid="collaborator-drawer-footer" justifyContent="space-between" width="full">
      <Button
        {...cancelButtonProps}
        data-testid="collaborator-drawer-footer-cancel-button"
        variant="tertiary"
        label={cancelLabel}
        isFullWidth={isExtraSmallScreen}
      />
      <Button
        {...submitButtonProps}
        data-testid="collaborator-drawer-footer-submit-button"
        variant="primary"
        label={submitLabel}
        isFullWidth={isExtraSmallScreen}
      />
    </Group>
  );
};
