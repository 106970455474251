import { Box } from '@chakra-ui/react';
import { TaxRate } from '@melio/ar-domain';
import { Table } from '@melio/penny';

import { useTaxRateActionsItems } from '../hooks';

type TaxRateActionCellProps = {
  taxRate: TaxRate;
  onEdit: (itemId: string) => unknown;
  onDelete: (itemId: string) => unknown;
};

const handleCellClick = (e: React.MouseEvent<HTMLDivElement>) => {
  e.stopPropagation();
};

export const TaxRateActionCell = ({ taxRate, onEdit, onDelete }: TaxRateActionCellProps) => {
  const actions = useTaxRateActionsItems({ taxRate, onEdit, onDelete });

  return (
    <Box onClick={handleCellClick} height="full">
      <Table.ActionsCell options={actions} data-testid={`tax-rate-actions-cell-${taxRate.id}`} />
    </Box>
  );
};
