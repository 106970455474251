import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const VendorNewIcon = (props: IconProps) => (
  <Icon width="20px" height="20px" viewBox="0 0 20 20" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.17119 6.95306C1.93422 6.34746 3.24453 5.61475 5.07657 5.61475C6.90895 5.61475 8.2194 6.34773 8.98237 6.95339M1.17119 6.95306C0.408323 7.55826 0 8.47008 0 9.39746V15.5726C0 16.0145 0.358172 16.3726 0.8 16.3726H7.5002L7.5 15.5L7.5002 14.7726C7.05837 14.7726 1.6 14.7726 1.6 14.7726V9.39746C1.6 8.93432 1.80359 8.49365 2.16548 8.2066C2.72506 7.76244 3.69545 7.21475 5.07657 7.21475C6.45798 7.21475 7.42827 7.76249 7.98779 8.20671C8.12193 8.31311 8.23379 8.43988 8.32152 8.58046C8.55544 8.95528 9.04893 9.0695 9.42375 8.83558C9.79857 8.60166 9.9128 8.10817 9.67887 7.73335C9.49648 7.4411 9.26293 7.17598 8.98237 6.95339"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.07618 3.74571C5.6687 3.74571 6.14903 3.26538 6.14903 2.67286C6.14903 2.08033 5.6687 1.6 5.07618 1.6C4.48365 1.6 4.00332 2.08033 4.00332 2.67286C4.00332 3.26538 4.48365 3.74571 5.07618 3.74571ZM5.07618 5.34571C6.55236 5.34571 7.74903 4.14904 7.74903 2.67286C7.74903 1.19668 6.55236 0 5.07618 0C3.6 0 2.40332 1.19668 2.40332 2.67286C2.40332 4.14904 3.6 5.34571 5.07618 5.34571Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6295 5.6819C13.4069 5.57266 13.1462 5.57284 12.9237 5.68239L7.08358 8.55804C6.81156 8.69198 6.63864 8.96818 6.63698 9.27138L6.59921 16.1795C6.59756 16.4809 6.76542 16.7576 7.03345 16.8954L12.9114 19.917C13.1412 20.0352 13.414 20.035 13.6437 19.9166L19.5041 16.8949C19.7703 16.7577 19.9375 16.4833 19.9375 16.1839V9.27575C19.9375 8.97058 19.7639 8.69199 19.4899 8.55755L13.6295 5.6819ZM18.3375 9.77432V15.6963L13.2766 18.3057L8.20188 15.697L8.23425 9.7749L13.2777 7.29152L18.3375 9.77432Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0686 12.7843L19.4687 10.3296L18.8067 8.87305L13.285 11.3829L8.29074 8.8858L7.5752 10.3169L12.4686 12.7636V19.2054H14.0686V12.7843Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.167 10.6687V13.8701C17.167 14.0564 17.0634 14.2273 16.8982 14.3134L16.1677 14.6943C16.0127 14.7751 15.8267 14.7691 15.6773 14.6785C15.5278 14.5878 15.4365 14.4257 15.4365 14.2509V11.025H16.167V10.6687H17.167Z"
    />
  </Icon>
);
