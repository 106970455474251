import { Box, Flex } from '@chakra-ui/react';
import { Button, Card, Container, Group, Icon, IconButton, Text, useBreakpoint } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { formatAddress } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecommendedVendors } from '../hooks/useRecommendedVendors';
import { useSelectVendorRecommendations } from '../hooks/useSelectRecommendedVendors';
import { recommendationToAnalyticsEvents } from '../utils';

type Props = {
  onClose: VoidFunction;
};

export const RecommendedVendorsCards = (props: Props) => {
  const navigate = useNavigate();
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const { isExtraSmallScreen } = useBreakpoint();

  const { recommendedVendors } = useRecommendedVendors({ shouldFetch: false });
  const { selectVendor, isVendorSelected, saveVendors, isSavingVendors, selectedVendors } =
    useSelectVendorRecommendations(recommendedVendors || []);

  const suggestedVendorsAdded = recommendationToAnalyticsEvents(selectedVendors);

  const onAddNewVendor = () => {
    track('Dashboard', 'Click', {
      Intent: 'add-vendor',
      Cta: 'new-vendor',
      SuggestedVendorsAdded: suggestedVendorsAdded,
    });
    navigate('/vendors/new-vendor');
  };

  const onClose = () => {
    track('Dashboard', 'Click', {
      Intent: 'add-vendor',
      Cta: 'dismiss',
      SuggestedVendorsAdded: suggestedVendorsAdded,
    });
    props.onClose();
  };

  const onSubmit = async () => {
    track('Dashboard', 'Click', {
      Intent: 'add-vendor',
      Cta: 'suggested-vendors',
      SuggestedVendorsAdded: suggestedVendorsAdded,
    });
    await saveVendors();
    props.onClose();
  };

  const tooFewVendors = !recommendedVendors || recommendedVendors.length < 2;

  useEffect(() => {
    if (!tooFewVendors) {
      track('Dashboard', 'View', {
        isEmptyState: true,
        IsSuggestedVendorsShown: true,
        SuggestedVendors: recommendationToAnalyticsEvents(recommendedVendors),
      });
    }
  }, [recommendedVendors, tooFewVendors, track]);

  if (tooFewVendors) {
    return null;
  }

  const shortenedVendorResults = recommendedVendors.slice(0, 4);

  return (
    <Container border="regular" height="full" width="full">
      <Container paddingX="l" paddingY="l">
        <Group spacing="xl" variant="vertical">
          <Group justifyContent="space-between">
            <Group spacing="xs" variant="vertical">
              <Text textStyle="body1Semi">{formatMessage('activities.recommendedVendors.cards.header')}</Text>
              <Text textStyle="body3">{formatMessage('activities.recommendedVendors.cards.description')}</Text>
            </Group>
            <IconButton onClick={onClose} icon="close-mini" />
          </Group>
          <Flex alignItems="center" wrap="wrap" gap="xs-s">
            {shortenedVendorResults.map((r) => (
              <Box minWidth="275px" key={r.id} flex="1">
                <Card
                  isSelected={isVendorSelected(r.id)}
                  onClick={() => selectVendor(r.id)}
                  paddingX="xs-s"
                  paddingY="xs-s"
                  variant="flat"
                >
                  <Group width="full" alignItems="center" justifyContent="space-between">
                    <Group variant="vertical" spacing="xxs">
                      <Text textStyle="body4" color="global.neutral.1000">
                        {r.name}
                      </Text>
                      <Text shouldSupportEllipsis textStyle="body4" color="global.neutral.800">
                        {formatAddress(r.address)}
                      </Text>
                    </Group>
                    {isVendorSelected(r.id) && <Icon color="brand" type="checked" />}
                  </Group>
                </Card>
              </Box>
            ))}
          </Flex>
        </Group>
      </Container>
      <Container paddingY="m" paddingX="m" backgroundColor="light">
        <Group justifyContent="flex-end" width="full">
          <Flex gap="xs-s" width="fit-content" wrap="wrap" justifyContent="flex-end">
            <Box flex={isExtraSmallScreen ? '1 1 100%' : '1 1 auto'}>
              <Button
                isFullWidth
                onClick={onAddNewVendor}
                isDisabled={isSavingVendors}
                size="medium"
                variant="secondary"
                label={formatMessage('activities.recommendedVendors.cards.secondaryButton')}
              />
            </Box>

            <Box flex={isExtraSmallScreen ? '1 1 100%' : '1 1 auto'}>
              <Button
                isFullWidth
                isDisabled={!selectedVendors.length}
                isLoading={isSavingVendors}
                onClick={onSubmit}
                size="medium"
                variant="primary"
                label={formatMessage('activities.recommendedVendors.cards.primaryButton')}
              />
            </Box>
          </Flex>
        </Group>
      </Container>
    </Container>
  );
};
