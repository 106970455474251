import { AvatarGroup, Pill, Text, Tooltip } from '@melio/penny';
// eslint-disable-next-line no-restricted-imports
import { FirmClientExpanded } from '@melio/platform-api-axios-client';
import { useMelioIntl } from '@melio/platform-i18n';
import { isAccountantFirmClientPlanTier, useIsSubscriptionsEnabled } from '@melio/subscriptions';

import { useAccountantsRoutes } from '../../../../../utils/useAccountantsRoutes';
import { isEligibleForAssignPlan } from '../../../utils/isEligibleForAssignPlan';
import { CompanyAvatar } from '../../CompanyAvatar';
import { PaymentsOverview } from '../../PaymentsOverview';
import { CardLayout } from '../common/CardLayout';

type Props = {
  client: FirmClientExpanded;
  isPaymentsOverviewLoading: boolean;
  isPaymentsOverviewLoadingExpanded: boolean;
  onChangeClientPlans: VoidFunction;
  onToggleHiddenState: VoidFunction;
  onClick: VoidFunction;
};

export const OrganizationCard = ({
  client,
  isPaymentsOverviewLoading,
  isPaymentsOverviewLoadingExpanded,
  onChangeClientPlans,
  onToggleHiddenState,
  onClick,
}: Props) => {
  const { formatMessage } = useMelioIntl();
  const { goToAssignClientPlan } = useAccountantsRoutes();
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();

  const { id: accountingClientId, name, logoUrl, billsAndPaymentsOverview } = client.organization;
  const companyName = name || '';

  const subscriptionInfo = isSubscriptionsEnabled ? client.subscriptionInfo : undefined;
  const { subscription, isAwaitingActivation: isSubscriptionAwaitingActivation } = subscriptionInfo || {};
  const isAccountingFirmClientPlan = subscription?.planTier
    ? isAccountantFirmClientPlanTier(subscription.planTier)
    : false;

  const assignClientPlanAction = {
    label: formatMessage('activities.accountants.OrganizationCard.menu.assignClientPlan'),
    onClick: () => goToAssignClientPlan({ accountingClientId }),
    dataTestId: `organization-assign-plan-${accountingClientId}`,
  };
  const manageClientPlanAction = {
    label: formatMessage('activities.accountants.OrganizationCard.menu.manageClientPlan'),
    onClick: onChangeClientPlans,
    dataTestId: `organization-manage-plan-${accountingClientId}`,
  };
  const proceedToCheckoutAction = {
    label: formatMessage('activities.accountants.OrganizationCard.menu.proceedToCheckout'),
    onClick,
    dataTestId: `organization-processed-to-checkout-${accountingClientId}`,
  };

  const getSubscriptionPlanAction = () => {
    if (isSubscriptionsEnabled) {
      if (isAccountingFirmClientPlan) {
        return [manageClientPlanAction];
      }
      if (isSubscriptionAwaitingActivation) {
        // There is no active subscription, and the chosen client plan is waiting for checkout
        return [proceedToCheckoutAction];
      }
      if (isEligibleForAssignPlan(subscription)) {
        // There is no active subscription and no tier (in case of Melio_Basic)
        return [assignClientPlanAction];
      }
    }
    return [];
  };

  return (
    <CardLayout
      data-testid={`organization-card-${accountingClientId}`}
      isMenuDisabled={isPaymentsOverviewLoadingExpanded}
      actions={[
        ...getSubscriptionPlanAction(),
        {
          label: client.organization.isHidden
            ? formatMessage('activities.accountants.OrganizationCard.menu.unhideClient')
            : formatMessage('activities.accountants.OrganizationCard.menu.hideClient'),
          onClick: onToggleHiddenState,
          dataTestId: 'menu-item-toggle-hidden-state',
        },
      ]}
      onClick={onClick}
      top={
        <>
          <CompanyAvatar size="medium" src={logoUrl} name={companyName} />
          <Text textStyle="body2Semi" shouldSupportEllipsis>
            {companyName}
          </Text>
          {subscription?.planTier && (
            <Tooltip
              isEnabled={!isAccountingFirmClientPlan}
              label={formatMessage('activities.accountants.OrganizationCard.planTier.smbTooltip.label')}
              data-testid={`plan-tier-pill-tooltip-${accountingClientId}`}
            >
              <Pill
                label={formatMessage(`activities.subscription.plans.${subscription?.planTier}.title`)}
                status={isAccountingFirmClientPlan ? 'neutral' : 'brand'}
                type={isAccountingFirmClientPlan ? 'primary' : 'secondary'}
                data-testid={`plan-tier-pill-${accountingClientId}`}
              />
            </Tooltip>
          )}
        </>
      }
      bottom={
        <>
          <PaymentsOverview
            isPaymentsOverviewLoading={isPaymentsOverviewLoading}
            paymentsOverview={billsAndPaymentsOverview}
          />
          <AvatarGroup
            items={
              client.firmMembers?.map((member) => ({ name: [member.firstName, member.lastName].join(' ').trim() })) ??
              []
            }
          />
        </>
      }
    />
  );
};
