import { Box } from '@chakra-ui/react';
import { FormWidgetProps } from '@melio/ap-widgets';
import { Form, Group, Text, useMelioForm } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { defaults } from 'lodash';

import { RoleUniqueNames } from '../../../../types';
import { RoleRadioGroup } from './fields/RoleRadioGroup';

export type MakeOwnerCollaboratorFormFields = {
  roleUniqueName: RoleUniqueNames;
};
type Props = FormWidgetProps<MakeOwnerCollaboratorFormFields> & {
  availableRoleOptions: RoleUniqueNames[];
  collaboratorFullName: string;
  ownerFullName: string;
};
export const MakeOwnerCollaboratorForm = ({
  onSubmissionStateChange,
  onSubmit,
  isSaving,
  defaultValues,
  collaboratorFullName,
  ownerFullName,
  availableRoleOptions,
}: Props) => {
  const { formatMessage } = useMelioIntl();
  const { formProps, registerField, watch } = useMelioForm<MakeOwnerCollaboratorFormFields>({
    onSubmit,
    defaultValues: defaults(defaultValues, {
      roleUniqueName: availableRoleOptions[0],
    }),
    isSaving,
    onSubmissionStateChange,
  });

  return (
    <Group spacing="xl" variant="vertical">
      <Box>
        <Form {...formProps} isDisabled={formProps.isDisabled}>
          <Text>
            {formatMessage('activities.manageCollaborators.screens.makeOwnerCollaboratorModal.content', {
              currentOwnerFullName: ownerFullName,
              collaboratorFullName,
            })}
          </Text>
          <RoleRadioGroup
            colSpan={2}
            title={formatMessage('activities.manageCollaborators.screens.makeOwnerCollaboratorModal.role.title')}
            availableRoleOptions={availableRoleOptions}
            selectedRoleUniqueName={watch('roleUniqueName')}
            isDisabled={isSaving}
            {...registerField('roleUniqueName')}
            isRequired
          />
        </Form>
      </Box>
    </Group>
  );
};
