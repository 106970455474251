import { Box } from '@chakra-ui/react';
import { Group, Illustration } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { BillSubscription } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';

import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';
import { PaymentUpdatedActionsButtons } from '../../components/paymentUpdatedActionsButtons';

type PaymentUpdatedScreenProps = {
  billSubscription: BillSubscription;
  onBackToDashboard: VoidFunction;
  onScreenLoad?: () => void;
};

export const PaymentUpdatedScreen = withAnalyticsContext<PaymentUpdatedScreenProps>(
  ({ setAnalyticsProperties, billSubscription, onBackToDashboard, onScreenLoad, ...props }) => {
    const { formatMessage, formatCurrency } = useMelioIntl();

    setAnalyticsProperties({
      PageName: 'payment-updated',
    });

    const { track } = useAnalytics();

    useEffect(() => {
      track('Payment', 'View');
    }, [track]);

    if (!billSubscription?.vendor) {
      return <NewSinglePaymentStepLayout isLoading />;
    }

    const onContainerRefChanged = (ref: HTMLDivElement | null) => {
      if (ref) {
        onScreenLoad?.();
      }
    };
    const amount = formatCurrency(billSubscription.amount);
    const vendorName = billSubscription.vendor.name;

    return (
      <NewSinglePaymentStepLayout
        data-component="EditBillSubscriptionActivity.PaymentUpdatedScreen"
        data-testid="edit-bill-subscription-activity-payment-updated-screen"
        {...props}
        ref={onContainerRefChanged}
        footerContent={
          <NewSinglePaymentStepLayout.Actions>
            <PaymentUpdatedActionsButtons onBackToDashboard={onBackToDashboard} />
          </NewSinglePaymentStepLayout.Actions>
        }
        isCentered
        spacing="l"
      >
        <Group variant="vertical" alignItems="center" spacing="xs">
          <Group justifyContent="center">
            <Illustration type="success" size="medium" />
          </Group>
          <NewSinglePaymentStepLayout.Title>
            <Box textAlign="center">
              {formatMessage('activities.editBillSubscription.screens.paymentUpdated.title')}
            </Box>
          </NewSinglePaymentStepLayout.Title>
          <NewSinglePaymentStepLayout.Description>
            <Box textAlign="center">
              {formatMessage('activities.editBillSubscription.screens.paymentUpdated.description', {
                amount,
                vendorName,
              })}
            </Box>
          </NewSinglePaymentStepLayout.Description>
        </Group>
      </NewSinglePaymentStepLayout>
    );
  }
);

PaymentUpdatedScreen.displayName = 'EditBillSubscriptionActivity.PaymentUpdatedScreen';
