import {
  DeliveryMethod as DeliveryMethodPlatform,
  InternationalAccountDeliveryMethod,
  useInternationalCountriesDetails,
} from '@melio/platform-api';
import { useMemo } from 'react';

export const isInternationalDeliveryMethod = (
  deliveryMethod: DeliveryMethodPlatform | undefined
): deliveryMethod is InternationalAccountDeliveryMethod => deliveryMethod?.type === 'international-account';

export const useInternationalCountryDisplayName = (deliveryMethod: DeliveryMethodPlatform | undefined) => {
  const { data: countriesDetails, isLoading: isCountriesDetailsLoading } = useInternationalCountriesDetails({
    enabled: isInternationalDeliveryMethod(deliveryMethod),
  });

  const countryDisplayName = useMemo(
    () =>
      isInternationalDeliveryMethod(deliveryMethod)
        ? countriesDetails?.find((country) => country.code === deliveryMethod.details.identifierDetails.bankCountryCode)
            ?.name || deliveryMethod.details.identifierDetails.bankCountryCode
        : undefined,
    [countriesDetails, deliveryMethod]
  );

  return { countryDisplayName, isCountriesDetailsLoading };
};
