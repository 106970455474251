import { Container, Group, Icon, Text, Typography } from '@melio/penny';
import { ApprovalWorkflow as ApprovalWorkflowEntity, WorkflowActions, WorkflowCriteria } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { useWorkflowActionsFormatter } from '../formatters/useWorkflowActionsFormatter';
import { useWorkflowCriteriaConditionFormatter } from '../formatters/useWorkflowCriteriaConditionFormatter';
import { ApprovalWorkflowsActionsMenu } from './ApprovalWorkflowsActionsMenu';

function ApprovalWorkflowCardCriteria({ criteria }: { criteria: WorkflowCriteria }) {
  const { format } = useWorkflowCriteriaConditionFormatter({ criteria });
  const { formatMessage } = useMelioIntl();
  return (
    <Group variant="vertical">
      <Group spacing="xxs">
        <Typography.SectionLabel
          label={formatMessage('activities.settings.approval-workflow-settings.conditions.criteria.title')}
        />
        <Icon type="arrow-down" color="default" size="small" />
      </Group>
      <Group variant="vertical" spacing="xs">
        {criteria.conditions.map((condition, index) => (
          <Text data-testid={`approval-workflow-condition-${index}`} key={index} color="inherit" textStyle="inline">
            {format(condition, index)}
          </Text>
        ))}
      </Group>
    </Group>
  );
}

function ApprovalWorkflowCardActions({ actions }: { actions: WorkflowActions[] }) {
  const { formatMessage } = useMelioIntl();
  const { format } = useWorkflowActionsFormatter();

  return (
    <Group variant="vertical">
      <Group spacing="xxs">
        <Typography.SectionLabel label={formatMessage('activities.settings.approval-workflow-settings.action.title')} />
        <Icon type="arrow-down" color="default" size="small" />
      </Group>
      {actions.map((action, index) => (
        <Container key={index} data-testid={`approval-workflow-action-${index}`}>
          {format({ action, index })}
        </Container>
      ))}
    </Group>
  );
}

export type ApprovalWorkflowCardProps = {
  workflow: ApprovalWorkflowEntity;
  onDeleteClick: (workflow: ApprovalWorkflowEntity) => void;
  onEditClick: (workflow: ApprovalWorkflowEntity) => void;
};

export function ApprovalWorkflowCard({ workflow, onDeleteClick, onEditClick }: ApprovalWorkflowCardProps) {
  return (
    <Container border="regular" width="full" paddingX="m" paddingY="m" data-testid="workflow">
      <Group variant="vertical" spacing="m">
        <Group justifyContent="space-between">
          {workflow.criteria && <ApprovalWorkflowCardCriteria criteria={workflow.criteria} />}
          <ApprovalWorkflowsActionsMenu workflow={workflow} onDeleteClick={onDeleteClick} onEditClick={onEditClick} />
        </Group>
        {workflow.actions && <ApprovalWorkflowCardActions actions={workflow.actions} />}
      </Group>
    </Container>
  );
}
