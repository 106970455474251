import { Box } from '@chakra-ui/react';
import { useBillActions } from '@melio/ap-domain';
import { ActionsDropdownMenuItemProps, Table } from '@melio/penny';
import { useAnalytics, useAnalyticsContext } from '@melio/platform-analytics';
import {
  InboxItemBillTypeEnum,
  InboxItemPaymentRequestTypeEnum,
  InboxItemScannedInvoiceTypeEnum,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { compact } from 'lodash';

import { BillsTabItem, SelectedInboxItemType } from '../types';
import { useBillsTabAriaLabelContextForVendor } from '../utils';

export type BillsTabMoreActionsCellProps = {
  billsTabItem: BillsTabItem;
  isDisabled: boolean;
  onDeleteClick: ({ id, type }: SelectedInboxItemType) => void;
  onEditClick: ({ id }: { id: string }) => void;
  onViewClick: ({ id }: { id: string }) => void;
  onRejectClick: ({ id }: { id: string }) => void;
  onMarkAsPaidClick: ({ id }: { id: string }) => void;
};

export const BillsTabMoreActionsCell = ({
  billsTabItem,
  isDisabled,
  onViewClick,
  onEditClick,
  onDeleteClick,
  onRejectClick,
  onMarkAsPaidClick,
}: BillsTabMoreActionsCellProps) => {
  const { formatMessage } = useMelioIntl();

  const options = useMoreMenuOptions({
    billsTabItem,
    onViewClick,
    onEditClick,
    onDeleteClick,
    onRejectClick,
    onMarkAsPaidClick,
  });
  const context = useBillsTabAriaLabelContextForVendor(billsTabItem);

  const hasOptions = options.length > 0;
  const handleCellClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <Box onClick={handleCellClick} height="full">
      <Table.ActionsCell
        data-testid={`bills-tab-row-${billsTabItem.payload.id}-more-actions-dropdown-menu`}
        data-tourid={`pay-dashboard-tour-review-bill-${billsTabItem.payload.id}`}
        aria-label={formatMessage('activities.payDashboard.billsTab.table.cells.actions.actionsMenu.ariaLabel', {
          context,
        })}
        options={options}
        isDisabled={isDisabled || !hasOptions}
      />
    </Box>
  );
};

export const useMoreMenuOptions = ({
  billsTabItem,
  onViewClick,
  onEditClick,
  onDeleteClick,
  onRejectClick,
  onMarkAsPaidClick,
}: Pick<
  BillsTabMoreActionsCellProps,
  'billsTabItem' | 'onViewClick' | 'onEditClick' | 'onDeleteClick' | 'onRejectClick' | 'onMarkAsPaidClick'
>): ActionsDropdownMenuItemProps[] => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const { getBillActions } = useBillActions();

  useAnalyticsContext({
    globalProperties: {
      EntryPoint: 'dashboard-bills',
    },
  });

  const rowId = billsTabItem.payload.id;
  const viewBillOption = {
    label: formatMessage('activities.payDashboard.billsTab.table.cells.actions.actionsMenu.view'),
    onClick: () => {
      track('Dashboard', 'Click', {
        Intent: 'view-bill',
        Cta: 'view-bill',
      });
      onViewClick({ id: rowId });
    },
    dataTestId: 'view-bill-btn',
  };

  if (billsTabItem.type === InboxItemBillTypeEnum.Bill) {
    const { actions: billActions, hasActions: hasBillActions } = getBillActions(billsTabItem.payload);

    return compact([
      hasBillActions && viewBillOption,
      billActions.edit && {
        label: formatMessage('activities.payDashboard.billsTab.table.cells.actions.actionsMenu.edit'),
        onClick: () => {
          track('Dashboard', 'Click', {
            Intent: 'edit-bill',
            Cta: 'edit-bill',
          });
          onEditClick({ id: rowId });
        },
        dataTestId: 'edit-bill-btn',
      },
      billActions.markAsPaid && {
        label: formatMessage('activities.payDashboard.billsTab.table.cells.actions.actionsMenu.markAsPaid'),
        onClick: () => {
          track('Dashboard', 'Click', {
            Intent: 'mark-as-paid',
            Cta: 'more-mark-as-paid',
            OriginAction: 'Pay',
          });
          onMarkAsPaidClick({ id: rowId });
        },
        dataTestId: 'mark-as-paid-bill-btn',
      },
      billActions.delete && {
        label: formatMessage('activities.payDashboard.billsTab.table.cells.actions.actionsMenu.delete'),
        onClick: () => {
          track('Dashboard', 'Click', {
            Intent: 'delete-bill',
            Cta: 'delete-bill',
          });
          onDeleteClick({ id: rowId, type: billsTabItem.type });
        },
        variant: 'critical',
        dataTestId: 'delete-bill-btn',
      },
    ]);
  }
  if (billsTabItem.type === InboxItemPaymentRequestTypeEnum.PaymentRequest) {
    return [
      viewBillOption,
      {
        label: formatMessage('activities.payDashboard.billsTab.table.cells.actions.actionsMenu.reject'),
        onClick: () => {
          track('Dashboard', 'Click', {
            Intent: 'reject-payment-request',
            Cta: 'reject-payment-request',
          });
          onRejectClick({ id: rowId });
        },
        variant: 'critical',
        dataTestId: 'reject-btn',
      },
    ];
  }
  if (billsTabItem.type === InboxItemScannedInvoiceTypeEnum.ScannedInvoice) {
    return [viewBillOption];
  }

  return [];
};
