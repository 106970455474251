import { NakedButton, Table } from '@melio/penny';
import { Collaborator } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { useAllowedActions } from '../../useAllowedActions';

type Props = {
  actor: Collaborator<'user'>;
  collaborator: Collaborator<'user'>;
  onViewClick: (id: Collaborator['id']) => void;
  onEditClick: (id: Collaborator['id']) => void;
};
export const ActionCell = ({ actor, collaborator, onViewClick, onEditClick }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { canEditCollaborator } = useAllowedActions();

  const handleViewClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();

    onViewClick(collaborator.id);
  };
  const handleEditClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();

    onEditClick(collaborator.id);
  };

  const allowEditCollaborator =
    canEditCollaborator({ roleUniqueName: collaborator.roleUniqueName }) && actor.id !== collaborator.id;
  return (
    <Table.Cell textAlign="end">
      <NakedButton
        data-testid={`collaborators-table-row-${collaborator.id}-action-cell`}
        size="medium"
        variant="secondary"
        label={
          allowEditCollaborator
            ? formatMessage('activities.settings.collaborators.table.cells.action.edit')
            : formatMessage('activities.settings.collaborators.table.cells.action.view')
        }
        onClick={allowEditCollaborator ? handleEditClick : handleViewClick}
      />
    </Table.Cell>
  );
};
