import React from 'react';
import { Flex } from '@chakra-ui/react';
import { InteractiveButton, Text } from '@melio/penny';
import { OriginFlow } from '@melio/platform-analytics';

import { useRouter } from '@/hooks/router.hooks';
import { usePlatformIntl } from '@/translations/Intl';

type PayListTabHeaderProps = {
  shouldShowNewPaymentButton: boolean;
};

export const PayListTabHeader = ({ shouldShowNewPaymentButton }: PayListTabHeaderProps) => {
  const { formatMessage } = usePlatformIntl();
  const { goToAddNewBill } = useRouter();

  const onAddNewBillClick = () => {
    goToAddNewBill({ originFlow: OriginFlow.BillPage });
  };

  return (
    <Flex direction={'row'} justifyContent={'space-between'} mb={{ xs: '24px', s: 10 }}>
      <Text as="h3" textStyle="heading1Semi">
        {formatMessage('app.payDashboard.titles.payBill')}
      </Text>
      {shouldShowNewPaymentButton ? (
        <InteractiveButton onClick={onAddNewBillClick} label={formatMessage('app.payDashboard.buttons.newPayment')} />
      ) : null}
    </Flex>
  );
};
