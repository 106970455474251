import { useAnalytics } from '@melio/platform-analytics';
import { Subscription } from '@melio/platform-api-axios-client';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils';

import { useSubscriptionMe } from '../api';
import { usePlanInfo } from './usePlanInfo';

export const useReactivatePlan = ({ subscription, onDone }: { subscription?: Subscription; onDone?: () => void }) => {
  const { update: updateSubscription } = useSubscriptionMe({
    enabled: false,
  });

  const { showMessage } = useSystemMessage();
  const { formatMessage } = useMelioIntl();
  const { planName } = usePlanInfo(subscription?.planId);
  const { track } = useAnalytics();

  return async () => {
    if (!subscription) {
      return;
    }

    track('Organization', 'Click', {
      Intent: 'subscribe',
      Cta: 'reactivate subscription',
      PlanChosenId: subscription?.planId,
      PlanChosen: planName,
      BillingCycle: subscription?.planCyclePeriod,
    });

    try {
      await updateSubscription({ planId: subscription?.planId, planCyclePeriod: subscription?.planCyclePeriod });
      onDone?.();
      showMessage({
        type: 'success',
        title: formatMessage('activities.subscription.reactivatePlan.systemMessage.success'),
      });
    } catch (e) {
      showMessage({
        type: 'error',
        title: formatMessage('activities.subscription.reactivatePlan.systemMessage.error'),
      });
    }
  };
};
