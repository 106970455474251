import { Box } from '@chakra-ui/react';
import { CalendarLegendProps, Container, Form, Group, ListItem, Loader, NakedButton, Text } from '@melio/penny';
import { DeliveryPreference, FundingSource, SelectedRepaymentOption } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useDateUtils } from '@melio/platform-utils';

type PaymentDateFieldProps = {
  dateFieldProps: Omit<React.ComponentProps<typeof Form.DateField>, 'aria-label'>;
  selectedRepaymentTerm?: SelectedRepaymentOption;
  deliveryPreferenceOption?: DeliveryPreference;
  dueDate?: Date;
  minDate?: Date;
  maxDate?: Date;
  testId?: string;
};
export const PaymentDateField = ({ deliveryPreferenceOption, dueDate, ...props }: PaymentDateFieldProps) => {
  const { formatMessage, formatDateTimeRange } = useMelioIntl();
  const { createDate } = useDateUtils();

  const deliveryETA = deliveryPreferenceOption
    ? formatDateTimeRange(deliveryPreferenceOption.minDeliveryDate, deliveryPreferenceOption.maxDeliveryDate, {
        dateStyle: 'medium',
      })
    : undefined;

  const legendItems = [
    {
      label: formatMessage(
        `activities.repaymentTerms.screens.repaymentTerms.RepaymentOptionsCard.paymentDateInput.legend.scheduledDate`
      ),
      variant: 'primary',
    },
    {
      label: formatMessage(
        'activities.repaymentTerms.screens.repaymentTerms.RepaymentOptionsCard.paymentDateInput.legend.dueDate'
      ),
      variant: 'secondary',
    },
  ] as CalendarLegendProps[];

  return (
    <Form.DateField
      legendItems={legendItems}
      secondarySelectedDate={dueDate}
      {...props.dateFieldProps}
      data-testid={props.testId}
      isRequired
      hideClear
      labelProps={{
        label: formatMessage(
          'activities.repaymentTerms.screens.repaymentTerms.RepaymentOptionsCard.paymentDateInput.label'
        ),
      }}
      helperTextProps={
        deliveryETA
          ? {
              label: formatMessage(
                'activities.repaymentTerms.screens.repaymentTerms.RepaymentOptionsCard.paymentDateInput.helper',
                {
                  deliveryETA,
                }
              ),
            }
          : undefined
      }
      excludeHolidays
      // TODO: Add due date
      weekDays={[1, 2, 3, 4, 5]}
      minDate={props.minDate || createDate()}
      maxDate={props.maxDate || createDate()}
    />
  );
};

export const RepaymentStartDate: React.VFC<{ startDate?: string; isLoading?: boolean }> = ({
  startDate,
  isLoading,
}) => {
  const { formatMessage, formatDate } = useMelioIntl();
  if (isLoading) {
    return (
      <Container justifyContent="center" alignItems="center" data-testid="delivery-date-loader">
        <Loader />
      </Container>
    );
  }
  return (
    <Form.ContentBox>
      <ListItem
        descriptionProps={{
          label: formatMessage(
            'activities.repaymentTerms.screens.repaymentTerms.RepaymentOptionsCard.repaymentStartDate.helper'
          ),
        }}
        labelProps={{
          label: formatMessage(
            'activities.repaymentTerms.screens.repaymentTerms.RepaymentOptionsCard.repaymentStartDate.label'
          ),
        }}
        mainLabelProps={{
          label: formatDate(startDate),
        }}
      />
    </Form.ContentBox>
  );
};

type ApprovedBankAccountPickerProps = {
  fundingSources: FundingSource[];
  testId?: string;
  registerFormFieldProps: Omit<React.ComponentProps<typeof Form.Select>, 'options' | 'emptyState' | 'aria-label'>;
  onAddBankAccount: VoidFunction;
};

const mapFundingSourcesToOptions = (fundingSources: FundingSource[]) =>
  fundingSources.map((fs) => ({
    label: fs.displayName,
    value: fs.id,
    testId: `fs-option-${fs.id}`,
  }));
export const ApprovedBankAccountPicker: React.VFC<ApprovedBankAccountPickerProps> = (props) => {
  const { formatMessage } = useMelioIntl();

  const options = mapFundingSourcesToOptions(props.fundingSources);

  if (options.length === 0) {
    return <AddBankAccountButton onAddBankAccount={props.onAddBankAccount} testId={props.testId} />;
  }

  return (
    <Form.Select
      {...props.registerFormFieldProps}
      data-testid={props.testId}
      emptyState={undefined}
      options={options}
      colSpan={2}
      labelProps={{
        label: formatMessage(
          'activities.repaymentTerms.screens.repaymentTerms.RepaymentOptionsCard.repaymentFundingSourcePicker.label'
        ),
        tooltipProps: {
          label: formatMessage(
            'activities.repaymentTerms.screens.repaymentTerms.RepaymentOptionsCard.repaymentFundingSourcePicker.labelTooltip'
          ),
        },
      }}
    />
  );
};

const AddBankAccountButton: React.VFC<{ onAddBankAccount: VoidFunction; testId?: string }> = (props) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Group variant="vertical" spacing="xxs">
      <Text textStyle="body4Semi" color="global.neutral.800">
        {formatMessage(
          'activities.repaymentTerms.screens.repaymentTerms.RepaymentOptionsCard.repaymentFundingSourcePicker.label'
        )}
      </Text>
      <Box>
        <NakedButton
          label={formatMessage(
            'activities.repaymentTerms.screens.repaymentTerms.RepaymentOptionsCard.repaymentFundingSourcePicker.addBankAccount.label'
          )}
          onClick={props.onAddBankAccount}
          data-testid={`${props.testId ?? ''}-add-button`}
          variant="secondary"
        />
      </Box>
    </Group>
  );
};
