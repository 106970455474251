// eslint-disable-next-line import/no-deprecated
import { _createFormFieldInput, Select, SelectOption, SelectProps } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { ChangeEvent } from 'react';

export type TaxRateSelectWidgetProps = Omit<SelectProps, 'options' | 'creatableOption' | 'emptyState'> & {
  onCreateOption: (value: string) => string;
  onChangeOption?: (value: string) => void;
  selectOptions: SelectOption[];
  isLoading?: boolean;
};

export const TaxRateSelectWidget = _createFormFieldInput(
  forwardRef<TaxRateSelectWidgetProps, 'input'>(
    ({ onChange, onCreateOption, onChangeOption, selectOptions, placeholder, ...props }, ref) => {
      const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange?.(event);
        onChangeOption?.(event.target.value);
      };

      const handleCreateOption = (inputValue: string) => {
        const newOptionId = onCreateOption(inputValue);
        onChange?.({ target: { value: newOptionId } } as never);
      };

      return (
        <Select
          aria-label="Select Tax Rate"
          data-component="TaxRateSelect"
          ref={ref}
          {...props}
          options={selectOptions}
          onChange={handleChange}
          placeholder={placeholder}
          creatableOption={{
            label: (value) => `Add "${value}" as a new tax rate`,
            onClick: handleCreateOption,
          }}
          emptyState={{ label: 'No options' }}
        />
      );
    }
  )
);

TaxRateSelectWidget.displayName = 'TaxRatesSelectWidget';
