import React from 'react';

import { ScreenSplitLayout } from '@/cl/layouts/screen-split-layout/screenSplitLayout.component';
import { EmptyVendorRightScreenPlaceholder } from '@/widgets/vendorsDashboard/EmptyVendor/EmptyVendorDetails.widget';
import { EmptyVendorExplanation } from '@/widgets/vendorsDashboard/EmptyVendor/EmptyVendorExplanation.widget';

export const EmptyVendorsPage = () => {
  return (
    <ScreenSplitLayout firstScreen={<EmptyVendorExplanation />} secondScreen={<EmptyVendorRightScreenPlaceholder />} />
  );
};
