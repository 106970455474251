import { SectionBanner } from '@melio/penny';
import { Payment } from '@melio/platform-api';

import { usePlatformIntl } from '@/translations/Intl';

export const RefundCompletedSectionBanner = ({ payment }: { payment: Payment }) => {
  const { formatMessage } = usePlatformIntl();

  return (
    <SectionBanner
      description={formatMessage('widgets.paymentDetails.failed.reason.refundCompleted.description', {
        tracingNumber: payment.tracingNumber,
      })}
      variant="informative"
      data-testid="refund-completed-alert"
    />
  );
};
