import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { RouterComponentWrapper } from '@/hoc/routerComponentWrapper';
import { useIsMsnDashboardDisplay } from '@/hooks/useIsMsnDashboardDisplay.hooks';
import { ViewReceivingMethodBankAccountRoute } from '@/router/routes/ViewReceivingMethodBankAccountRoute.route';
import { AddAchFromReceivingMethodFlowWrapper } from '@/screens/receiving-methods/AddAchFromReceivingMethodFlowWrapper.sceen';
import { EditPlaidBankAccountScreen } from '@/screens/receiving-methods/EditPlaidBankAccount.screen';
import { ReceivingMethodCantFindBankAccountPageScreen } from '@/screens/receiving-methods/ReceivingMethodCantFindBankAccountPage.screen';
import { ReceivingMethodsScreen } from '@/screens/receiving-methods/ReceivingMethods.screen';
import { UpdateReceivingMethodsScreen } from '@/screens/receiving-methods/UpdateReceivingMethod.screen';
import { useACHDeliveryMethodExists } from '@/utils/receivingMethods.utils';
import { ReceivingMethodAchExistsPage } from '@/widgets/settings-page/ReceivingMethodsPage/ReceivingMethodAchExistsPage.screen';
import { ReceivingMethodLinkBankAccountPage } from '@/widgets/settings-page/ReceivingMethodsPage/ReceivingMethodLinkBankAccountPage.widget';

export const ReceivingMethodsRoute = () => {
  const { state } = useLocation();

  const isACHDeliveryMethodExists = useACHDeliveryMethodExists();

  const isMSNPortalVendor = useIsMsnDashboardDisplay();

  const shouldEnableDMVerificationTypeSelection =
    !isACHDeliveryMethodExists || state?.origin?.isOwnedVendorSettings || isMSNPortalVendor;

  return (
    <Routes>
      <Route
        path="add-from-get-paid-settings/select"
        element={
          !shouldEnableDMVerificationTypeSelection ? (
            <Navigate to="/melio/receiving-methods/ach/exists" />
          ) : (
            <ReceivingMethodsScreen />
          )
        }
      />

      <Route path="/ach/exists" element={<ReceivingMethodAchExistsPage />} />
      <Route path="ach/select/:deliveryMethodId" element={<UpdateReceivingMethodsScreen />} />
      <Route path="ach/plaid/:deliveryMethodId" element={<EditPlaidBankAccountScreen />} />
      <Route path="/ach/cant-find/:deliveryMethodId" element={<ReceivingMethodCantFindBankAccountPageScreen />} />
      <Route path="/ach/edit/:deliveryMethodId/bank-details" element={<AddAchFromReceivingMethodFlowWrapper />} />
      <Route path="/ach/link-bank-account/:deliveryMethodId" element={<ReceivingMethodLinkBankAccountPage />} />
      <Route
        path="ach/manual/:id"
        element={
          <RouterComponentWrapper
            Component={ViewReceivingMethodBankAccountRoute}
            componentProps={{ id: { _pathParam: 'id' } }}
          />
        }
      />
    </Routes>
  );
};
