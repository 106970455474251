import { useMelioIntl } from '@melio/ar-domain';
import { Button, Container, Group } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export type ServiceChargesTableToolbarProps = {
  onAdd: VoidFunction;
};

export const ServiceChargesTableToolbar = forwardRef<ServiceChargesTableToolbarProps>(({ onAdd, ...props }, ref) => {
  const { formatMessage } = useMelioIntl();
  return (
    <Container paddingX="xxs" paddingY="xxs" data-component={ServiceChargesTableToolbar.displayName}>
      <Group variant="horizontal" justifyContent="flex-end" {...props} ref={ref}>
        <Button
          variant="tertiary"
          label={formatMessage(
            'ar.settings.activities.serviceChagresTable.components.serviceChagresTableToolbar.addButton.label'
          )}
          onClick={onAdd}
          data-testid="add-service-charge-button"
        />
      </Group>
    </Container>
  );
});
ServiceChargesTableToolbar.displayName = 'ServiceChargesTableToolbar';
