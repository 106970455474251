import { Route, Routes } from 'react-router-dom';

import { NewSinglePaymentStepLayout } from '../../NewSinglePaymentStepLayout';
import { AddBankDetailsStep } from './steps/AddBankDetails.step';
import { ChooseDeliverySpeedStep } from './steps/ChooseDeliverySpeed.step';
import { ChooseHowToResendStep } from './steps/ChooseHowToResend.step';
import { ConfirmMailingAddressStep } from './steps/ConfirmMailingAddress.step';
import { PaymentScheduledStep } from './steps/PaymentScheduled.step';
import { ReviewAndConfirmPaymentStep } from './steps/ReviewAndConfirmPayment.step';
import { VoidAndResendFlowDoneAction } from './types';
import { useVoidAndResendActivity } from './useVoidAndResendActivity';
import { useVoidAndResendWizard } from './useVoidAndResendWizard';

type Props = { paymentId: string; goToDashboard: VoidFunction; onError?: ErrorFunction };

export const VoidAndResendActivity = ({ paymentId, goToDashboard, onError }: Props) => {
  const { locationsMap, cancelFlow, goBack, goNextMap, completeFlow } = useVoidAndResendWizard();

  const {
    payment,
    paymentIntentId,
    handleFail,
    isLoadingPayment,
    isLoadingPaymentIntentFromPayment,
    isUpdatingPaymentIntent,
    handleResendPaperCheck,
    handleSwitchToBankTransfer,
    handleAddBankDetailsDone,
    handleConfirmMailingAddressDone,
    handleChooseDeliverySpeedDone,
    handleEditDate,
    updatedFields,
    paperCheckDeliveryMethod,
    bankAccountDeliveryMethod,
    handleReviewAndConfirmPaymentDone,
    isPaymentMutating,
  } = useVoidAndResendActivity({
    paymentId,
    onError,
    goBack,
    goNextMap,
  });

  const isLoading =
    isLoadingPayment ||
    isLoadingPaymentIntentFromPayment ||
    isUpdatingPaymentIntent ||
    !payment ||
    !paperCheckDeliveryMethod ||
    !paymentIntentId;

  const onFlowDone = (action: VoidAndResendFlowDoneAction = 'goToDashboard') => {
    if (action === 'goToDashboard') {
      completeFlow(goToDashboard);
    }
  };

  if (isLoading) {
    return <NewSinglePaymentStepLayout isLoading />;
  }

  return (
    <Routes>
      <Route
        path={locationsMap.chooseHowToResend}
        element={
          <ChooseHowToResendStep
            isLoading={isLoading}
            onClose={cancelFlow}
            onResendPaperCheck={handleResendPaperCheck}
            onSwitchToBankTransfer={handleSwitchToBankTransfer}
            payment={payment}
            paperCheckDeliveryMethod={paperCheckDeliveryMethod}
            bankAccountDeliveryMethod={bankAccountDeliveryMethod}
          />
        }
      />
      <Route
        path={locationsMap.addBankDetails}
        element={
          <AddBankDetailsStep
            onBack={goBack}
            onClose={cancelFlow}
            vendorId={payment.vendorId}
            onDone={handleAddBankDetailsDone}
            origin="payment"
            onError={handleFail}
            payment={payment}
          />
        }
      />
      <Route
        path={locationsMap.reviewAndConfirmPayment}
        element={
          <ReviewAndConfirmPaymentStep
            onBack={goBack}
            onClose={cancelFlow}
            onDone={handleReviewAndConfirmPaymentDone}
            onError={handleFail}
            payment={payment}
            paymentIntentId={paymentIntentId}
            updatedFields={updatedFields}
            onEditDate={handleEditDate}
            isLoadingButton={isPaymentMutating}
          />
        }
      />
      <Route
        path={locationsMap.confirmMailingAddress}
        element={
          <ConfirmMailingAddressStep
            onBack={goBack}
            onClose={cancelFlow}
            onDone={handleConfirmMailingAddressDone}
            vendorId={payment.vendorId}
            deliveryMethodId={paperCheckDeliveryMethod.id}
            onError={handleFail}
            payment={payment}
            paperCheckDeliveryMethod={paperCheckDeliveryMethod}
          />
        }
      />
      <Route
        path={locationsMap.chooseDeliverySpeed}
        element={
          <ChooseDeliverySpeedStep
            onBack={goBack}
            onClose={cancelFlow}
            paymentIntentId={paymentIntentId}
            onDone={handleChooseDeliverySpeedDone}
            onError={handleFail}
            payment={payment}
          />
        }
      />
      <Route
        path={locationsMap.paymentScheduled}
        element={
          <PaymentScheduledStep
            payment={payment}
            paymentIntentId={paymentIntentId}
            onDone={onFlowDone}
            onError={handleFail}
          />
        }
      />
    </Routes>
  );
};
