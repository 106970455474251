import { ComponentThemeType } from '@/cl/theme/theme.hooks';

export const JoinOrganizationRequestCardTheme: ComponentThemeType<{ isExpired?: boolean }> = (
  currentTheme,
  { isExpired },
) => ({
  baseStyle: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      gap: 's',
    },
    joinOrganizationRequestDetails: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    avatarContainer: {
      borderRadius: '100%',
    },
    companyName: {
      ...currentTheme.textStyles.body2Semi,
      display: 'flex',
      alignItems: 'center',
      marginLeft: 's',
      overflow: 'hidden',
      color: isExpired ? currentTheme.colors.global.neutral['400'] : currentTheme.colors.global.neutral['800'],
    } as never,
    statusBadge: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'xs',
      marginRight: 's',
      flex: '1',
      whiteSpace: 'nowrap',
    },
  },
});
