import { useMtlMessages } from '@melio/ap-domain';
import { Modal, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { UnsupportedModalValues } from '../types';
import { getUnsupportedTypeModalContent } from '../utils/unsupportedTypesUtils';

type UnsupportedFxTypeModalProps = {
  onClose: () => void;
  isOpen: boolean;
  unsupportedTypeModalOpen: {
    isUnsupportedBusinessType: boolean;
    isUnsupportedIndustryType: boolean;
    values?: UnsupportedModalValues;
  };
};

export const UnsupportedFxTypeModal = ({ isOpen, onClose, unsupportedTypeModalOpen }: UnsupportedFxTypeModalProps) => {
  const { formatMessage } = useMelioIntl();
  const { labels } = useMtlMessages();
  const { isUnsupportedBusinessType, isUnsupportedIndustryType, values } = unsupportedTypeModalOpen;
  const { body, header, primaryButton } = getUnsupportedTypeModalContent(
    isUnsupportedBusinessType,
    isUnsupportedIndustryType
  );
  return (
    <Modal
      data-testid="fx-unsupported-type-modal"
      isOpen={isOpen}
      onClose={onClose}
      header={
        <Text data-testid="fx-unsupported-type-modal-header" textStyle="heading2Semi">
          {formatMessage(header)}
        </Text>
      }
      primaryButton={{
        label: formatMessage(primaryButton),
        onClick: onClose,
        variant: 'primary',
      }}
    >
      <Text textStyle="body2" data-testid="fx-unsupported-type-modal-body">
        {formatMessage(body, {
          industryName: values?.businessIndustryName.name,
          businessType: labels.company.businessTypeOption(values?.businessType),
        })}
      </Text>
    </Modal>
  );
};
