import { useCollectionApi, UseCollectionApiProps } from '@melio/api-client';
import { AgreementsApiClient } from '@melio/platform-api-axios-client';

export type UseAgreementsProps = UseCollectionApiProps<typeof AgreementsApiClient.getAgreements>;

export const useAgreements = (props: UseAgreementsProps = {}) =>
  useCollectionApi({
    ...props,
    queryKey: 'AgreementsApi',
    queryFn: AgreementsApiClient.getAgreements,
    createFn: AgreementsApiClient.postAgreements,
    deleteFn: AgreementsApiClient.deleteAgreementsAgreementId,
    updateFn: AgreementsApiClient.patchAgreementsAgreementId,
  });

export type AgreementCollection = ReturnType<typeof useAgreements>;
