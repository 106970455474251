import { Button, Group, useBreakpoint, UseMelioFormResults } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { FormFields } from '../types';

type Props = {
  submitButtonProps?: UseMelioFormResults<FormFields>['submitButtonProps'];
  cancelButtonProps?: UseMelioFormResults<FormFields>['cancelButtonProps'] & { onClick: VoidFunction };
};
export const VendorsBatchPaymentsModalFooter = ({ submitButtonProps, cancelButtonProps }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { isExtraSmallScreen } = useBreakpoint();

  return (
    <Group data-testid="vendors-batch-payments-modal-footer" justifyContent="space-between" width="full">
      <Button
        {...cancelButtonProps}
        data-testid="vendors-batch-payments-modal-footer-cancel-button"
        variant="tertiary"
        label={formatMessage('activities.payDashboard.vendorsTab.vendorsBatchPaymentsModal.footer.cancel')}
        isFullWidth={isExtraSmallScreen}
      />
      <Button
        {...submitButtonProps}
        data-testid="vendors-batch-payments-modal-footer-submit-button"
        variant="primary"
        label={formatMessage('activities.payDashboard.vendorsTab.vendorsBatchPaymentsModal.footer.submit')}
        isFullWidth={isExtraSmallScreen}
      />
    </Group>
  );
};
