import { Payment } from '@melio/platform-api';

import { isPaymentScheduledVoidToRefund } from '../../../../utils/pay-flow/Payment.utils';

export const getPaymentDetailsTitlePostfix = (payment: Payment) => {
  const isRefund = payment?.metadata?.isRefund;
  const isVoidingToRefund = isPaymentScheduledVoidToRefund(payment);
  if (isRefund || isVoidingToRefund) {
    return 'refundPayment';
  }
  return 'fullPayment';
};
