import { useAnalytics } from '@melio/platform-analytics';

export const useLineItemAnalytics = () => {
  const { track } = useAnalytics();

  const trackLineItemAdded = () => {
    track('Bill', 'Click', {
      PageName: 'bill-details',
      Flow: 'advanced',
      Intent: 'add-line-item',
      Cta: 'add-another-line',
    });
  };

  const trackLineItemDeleted = () => {
    track('Bill', 'Click', {
      PageName: 'bill-details',
      Flow: 'advanced',
      Intent: 'delete-line-item',
      Cta: 'delete-line-item',
    });
  };

  return {
    trackLineItemAdded,
    trackLineItemDeleted,
  };
};
