import { useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import { CompanyAvatarWidget } from '@melio/ap-widgets';
import { Card, Pill, Text } from '@melio/penny';
import { Account, JoinOrganizationRequestStatus } from '@melio/platform-api';

import { useAppTheme } from '@/cl/theme/theme.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { PaymentsOverview } from '../PaymentsOverview/PaymentsOverview.component';
import { JoinOrganizationRequestCardTheme } from './JoinOrganizationRequestCard.component.theme';
import { JoinOrganizationRequestCardMenu } from './JoinOrganizationRequestCardMenu.component';

const EMPTY_PAYMENTS_OVERVIEW: Account<'billsAndPaymentsOverview'>['billsAndPaymentsOverview'] = {
  unpaid: {
    count: 0,
  },
  scheduled: {
    count: 0,
  },
  failed: {
    count: 0,
  },
  pending: {
    count: 0,
  },
  paid: {
    count: 0,
  },
};

type StatusBadgeData = { label: string; isExpired?: boolean; testId: string } | undefined;

type JoinOrganizationCardProps = {
  companyName: string;
  status: JoinOrganizationRequestStatus;
  id: string;
  isExpired: boolean;
  onRequestCancel: VoidFunction;
  onRequestResend: VoidFunction;
};

export const JoinOrganizationRequestCard = ({
  companyName,
  status,
  isExpired,
  id,
  onRequestCancel,
  onRequestResend,
}: JoinOrganizationCardProps) => {
  const { formatMessage } = usePlatformIntl();

  const statusBadgeData = useMemo<StatusBadgeData>(() => {
    if (isExpired) {
      return {
        label: formatMessage('widgets.joinOrganizationRequestCard.badge.expired'),
        isExpired: true,
        testId: 'badge-expired',
      };
    }

    if (status === JoinOrganizationRequestStatus.Pending) {
      return {
        label: formatMessage('widgets.joinOrganizationRequestCard.badge.pending'),
        testId: 'badge-pending',
      };
    }

    return undefined;
  }, [isExpired, status, formatMessage]);

  const styles = useAppTheme(JoinOrganizationRequestCardTheme, { isExpired });

  return (
    <Card data-component="join-organization-request-card">
      <Box sx={styles['container']} data-testid={`join-organization-request-card-${id}`}>
        <Box sx={styles['joinOrganizationRequestDetails']}>
          <Box sx={styles['avatarContainer']}>
            <CompanyAvatarWidget size="medium" isDisabled={isExpired} bgColor="default" name={companyName} />
          </Box>
          <Box sx={styles['companyName']}>
            <Text textStyle="inline" color="inherit" shouldSupportEllipsis>
              {companyName}
            </Text>
          </Box>
          {statusBadgeData ? (
            <Box sx={styles['statusBadge']} data-testid={`${statusBadgeData.testId}-${id}`}>
              <Pill status="neutral" isReadOnly isDisabled={isExpired} label={statusBadgeData.label} />
            </Box>
          ) : null}
          <JoinOrganizationRequestCardMenu onRequestCancel={onRequestCancel} onRequestResend={onRequestResend} />
        </Box>
        <PaymentsOverview paymentsOverview={EMPTY_PAYMENTS_OVERVIEW} isDisabled={isExpired} />
      </Box>
    </Card>
  );
};
