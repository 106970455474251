import { Flex, Table, Tbody, Td, Thead, Tr } from '@chakra-ui/react';
import { IconButton, Text } from '@melio/penny';
import { ReceiptInfo } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { ReceiptsTableTheme } from '@/cl/components/ReceiptsTable/ReceiptsTable.component.theme';
import { useAppTheme } from '@/cl/theme/theme.hooks';

export interface ReceiptsTableProps {
  receipts: ReceiptInfo[];
  onDownload: (id: string) => void;
  labels: {
    date: string;
    amount: string;
    receipt: string;
  };
}

export const ReceiptsTable = (props: ReceiptsTableProps) => {
  const styles = useAppTheme(ReceiptsTableTheme, {});
  const { formatCurrency } = useMelioIntl();
  const { receipts, onDownload, labels: headers } = props;

  return (
    <Table sx={styles['table']} variant="unstyled">
      <Thead>
        <Tr sx={styles['headerRow']}>
          {[...Object.values(headers)].map((header, i) => (
            <Td key={header} sx={styles[i === 0 ? 'firstHeaderCell' : 'headerCell']}>
              <Text>{header}</Text>
            </Td>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {receipts.map((row) => (
          <Tr key={row.id} sx={styles['dataRow']}>
            <Td sx={styles['firstDataCell']}>
              <Text textStyle={'body4'}>{row.month}</Text>
            </Td>
            <Td sx={styles['dataCell']}>
              <Text textStyle="body4Semi">{formatCurrency(row.totalAmount)}</Text>
            </Td>
            <Td sx={styles['dataCell']} onClick={() => onDownload(row.id)}>
              <Flex>
                <IconButton aria-label="download" icon="download" size="small" variant="naked" />
              </Flex>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};
