import { getPartnerName } from '@melio/vex-activities/src/shared/partner';

import { usePartnerConfig } from '@/hooks/partners';
import { getSupportQuestionsByIds } from '@/utils/supportQuestions.utils';

export const useSupportQuestions = () => {
  const {
    partnerConfig: { supportQuestionIds },
  } = usePartnerConfig();
  const partnerName = getPartnerName();
  const supportQuestions = getSupportQuestionsByIds(supportQuestionIds, partnerName);

  return {
    supportQuestions,
  };
};
