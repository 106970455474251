import { getFundingSourceIconOrBrandSymbol } from '@melio/ap-widgets';
import { Group, Pill, Text } from '@melio/penny';
import { FundingSource } from '@melio/platform-api';
import React from 'react';

import { useFundingSourceCardProps } from './useFundingSourceCardProps';

export const FundingSourceCard = ({ fundingSource }: { fundingSource: FundingSource }) => {
  const icon = getFundingSourceIconOrBrandSymbol(fundingSource);
  const { title, description, badgeProps } = useFundingSourceCardProps({ fundingSource });

  return (
    <Group alignItems="center" spacing="s" data-testid="funding-source-card">
      {icon}
      <Group variant="vertical" alignItems="flex-start" spacing="xxxs">
        <Group variant="horizontal" spacing="xs">
          <Text data-testid="funding-source-card-title" textStyle="body2Semi">
            {title}
          </Text>
          {badgeProps?.map((props, index) => (
            <Pill {...props} key={index} />
          ))}
        </Group>
        {description && (
          <Text
            data-testid="funding-source-card-description"
            textStyle="body3"
            color="global.neutral.800"
            shouldSupportEllipsis
          >
            {description}
          </Text>
        )}
      </Group>
    </Group>
  );
};
