import { useMelioQueryClient, useModelApi, UseModelApiProps, useUpdateMutation } from '@melio/api-client';
import { ScannedInvoice, ScannedInvoicesApiClient } from '@melio/platform-api-axios-client';
import { invokeFunction } from '@melio/platform-utils';

import { DeleteScannedInvoicesFn, GetScannedInvoiceFn } from './types';
import { convertScannedInvoiceCentsToDollars } from './utils';

export type UseScannedInvoiceProps = UseModelApiProps<
  typeof ScannedInvoicesApiClient.getScannedInvoicesScannedInvoiceId,
  ScannedInvoice
>;

export const useScannedInvoice = (props: UseScannedInvoiceProps = {}) => {
  const queryClient = useMelioQueryClient();

  const query = useModelApi<GetScannedInvoiceFn, never, DeleteScannedInvoicesFn, never, ScannedInvoice>({
    ...props,
    queryKey: ['ScannedInvoicesApi', props.id],
    queryFn: (id: string) => invokeFunction(ScannedInvoicesApiClient.getScannedInvoicesScannedInvoiceId, [id]),
    deleteFn: ScannedInvoicesApiClient.deleteScannedInvoicesScannedInvoiceId,
    onDelete: () => queryClient.invalidateQueries('ScannedInvoicesApi'),
    select: (data) => (data ? convertScannedInvoiceCentsToDollars(data) : data),
  });

  query.data;

  const markAsPaidMutation = useUpdateMutation(
    ScannedInvoicesApiClient.postScannedInvoicesScannedInvoiceIdMarkAsPaid,
    query.queryKey
  );

  return {
    ...query,
    markAsPaid: markAsPaidMutation.createCallback(props.id as string),
  };
};
