import { BrandSymbolKey, IconProps, SelectionCard } from '@melio/penny';

type Props = {
  icon: IconProps['type'] | BrandSymbolKey;
  title: string;
  testId: string;
  description: string;
  onClick: VoidFunction;
};

export const OptionSelectionCard = ({ icon, title, description, testId, onClick }: Props) => (
  <SelectionCard
    icon={icon}
    data-testid={testId}
    mainLabelProps={{
      label: title,
    }}
    descriptionProps={{
      label: description,
    }}
    onClick={onClick}
    variant="default"
  />
);
