import { ARInvoice, useMelioIntl } from '@melio/ar-domain';
import { Group, Icon, Pill, Text, Typography } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { DisplayStatusLabel, DisplayStatusLabelMap, InvoiceActionsHandlers } from '../../../utils';

export type InvoiceTableRowsMobileProps = { invoice: ARInvoice; invoiceActions?: InvoiceActionsHandlers };

export const InvoiceTableRowsMobile = forwardRef<InvoiceTableRowsMobileProps>(
  ({ invoice: { customer, ...invoice }, invoiceActions, ...props }, ref) => {
    const { formatDate, formatCurrency, formatMessage } = useMelioIntl();

    return (
      <Group
        data-testid={`table-mobile-row-${invoice.id}`}
        variant="horizontal"
        justifyContent="space-between"
        {...props}
        ref={ref}
      >
        <Group variant="vertical" spacing="xxs">
          <Typography.MainLabel
            label={customer?.companyName || ''}
            variant="bold"
            data-testid="invoice-customer-name"
          />
          <Group variant="vertical" spacing="xxxs">
            <Text textStyle="body3" color="global.neutral.800" data-testId="invoice-due-date">
              {formatMessage('ar.invoiceLifecycle.activities.invoiceTable.mobile.card.dueDate', {
                dueDate: formatDate(invoice.dueDate),
              })}
            </Text>
            <Text textStyle="body3" color="global.neutral.800" data-testId="invoice-number">
              {invoice.invoiceNumber}
            </Text>
          </Group>
          <Pill
            type="secondary"
            status={DisplayStatusLabelMap[invoice.displayStatus]}
            label={DisplayStatusLabel[invoice.displayStatus]}
            data-testid="invoice-status-label"
            data-cy-status={invoice.displayStatus}
          />
        </Group>
        <Group variant="horizontal" alignItems="center" height="fit-content" spacing="xxxs">
          <Text textStyle="body2Semi" color="global.neutral.900" data-testid="invoice-amount">
            {formatCurrency(invoice.totalAmount)}
          </Text>
          <Icon type="chevron-right" size="small" />
        </Group>
      </Group>
    );
  }
);
InvoiceTableRowsMobile.displayName = 'InvoiceTableListItem';
