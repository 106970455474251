import { Bill } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMemo } from 'react';

export const useBillInvoiceNumberLabel = (bills: Bill[]) => {
  const { formatMessage } = useMelioIntl();

  return useMemo(() => {
    const invoiceNumbers = bills.filter((bill) => bill.invoice.number).map((bill) => bill.invoice.number);
    const missingInvoiceNumberCount = bills.length - invoiceNumbers.length;
    const withoutInvoiceNumberLabel = formatMessage(
      'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.invoiceNumber.withoutInvoice',
      { billsWithoutInvoice: missingInvoiceNumberCount }
    );

    if (bills.length === 1 && invoiceNumbers.length === 0) {
      return '';
    } else if (missingInvoiceNumberCount === 0) {
      return invoiceNumbers.join(', ');
    } else if (missingInvoiceNumberCount === bills.length) {
      return withoutInvoiceNumberLabel;
    }

    return `${invoiceNumbers.join(', ')} + ${withoutInvoiceNumberLabel}`;
  }, [bills, formatMessage]);
};
