import { Address, FormWidgetProps, useMelioIntl } from '@melio/ar-domain';
import { AddressSearchWidget, AddressSearchWidgetProps } from '@melio/form-controls';
import { Form, Group, Pill, useMelioForm } from '@melio/penny';
import { forwardRef, useBoolean } from '@melio/platform-utils';
import { date, object, SchemaOf, string } from 'yup';

import { ScheduledDateField, useEstimatedDeliveryDate } from '../../../components';
import { CardHolderAddressDetails, CardHolderAddressDetailsAndScheduledDate } from '../../types';
import { getClosestBusinessDay } from '../../utils';

const useSchema = () => {
  const { formatMessage } = useMelioIntl();

  return object().shape({
    line1: string()
      .required(formatMessage('ar.guestPayment.activities.cardHolder.form.fields.line1.required.text'))
      .nullable(),
    city: string().required(formatMessage('ar.guestPayment.activities.cardHolder.form.fields.city.required.text')),
    state: string().required(formatMessage('ar.guestPayment.activities.cardHolder.form.fields.state.required.text')),
    postalCode: string()
      .required(formatMessage('ar.guestPayment.activities.cardHolder.form.fields.postalCode.required.text'))
      .matches(/^\d{5}$/, formatMessage('ar.guestPayment.activities.cardHolder.form.fields.postalCode.length.text')),
    scheduledDate: date().required(formatMessage('ar.guestPayment.activities.scheduledDate.required.label')),
  }) as SchemaOf<CardHolderAddressDetailsAndScheduledDate>;
};

const ZIP_CODE_SHORT_MASK = [/\d/, /\d/, /\d/, /\d/, /\d/];

export type CardHolderAddressDetailsAndScheduledDateFormProps =
  FormWidgetProps<CardHolderAddressDetailsAndScheduledDate>;

export const CardHolderAddressDetailsAndScheduledDateForm = forwardRef<
  CardHolderAddressDetailsAndScheduledDateFormProps,
  'form'
>(({ onSubmit, onSubmissionStateChange, isSaving, ...props }, ref) => {
  const minScheduledDate = getClosestBusinessDay();
  const { formatDate, formatMessage } = useMelioIntl();
  const [shouldShowAddressFields, showAddressFields] = useBoolean(false);
  const { formProps, registerField, setValue, watch } = useMelioForm<CardHolderAddressDetailsAndScheduledDate>({
    onSubmit,
    schema: useSchema(),
    isSaving,
    onSubmissionStateChange,
    defaultValues: { scheduledDate: minScheduledDate },
  });

  const handleAddressChange: AddressSearchWidgetProps['onChange'] = (event) => {
    const address = event.target.value as unknown as Address | null;

    if (address === null) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - if we pass '' the second search doesn't work properly
      setValue('line1', null);
      setValue('state', '');
      setValue('city', '');
      setValue('postalCode', '');
      showAddressFields.off();
    } else {
      showAddressFields.on();
    }

    const setFormField = (field: keyof CardHolderAddressDetails) => {
      if (address?.[field]) {
        setValue(field, address[field], {
          shouldValidate: true,
        });
      }
    };

    setFormField('line1');
    setFormField('state');
    setFormField('city');
    setFormField('postalCode');
  };

  return (
    <Form
      data-component="CardHolderAddressDetailsAndScheduledDateForm"
      data-testid="card-holder-address-details-form"
      {...props}
      {...formProps}
      columns={2}
      ref={ref}
    >
      <AddressSearchWidget
        {...registerField('line1')}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - Type of formatSelectedValue will be fixed & data can be string | undefined | Address
        formatSelectedValue={(data): Address | string => (data.value as unknown as Address).line1}
        labelProps={{
          label: formatMessage('ar.guestPayment.activities.cardHolder.form.fields.billingAddress.label'),
        }}
        colSpan={2}
        onChange={handleAddressChange}
      />
      <Form.TextField
        colSpan={2}
        isHidden={!shouldShowAddressFields}
        labelProps={{ label: formatMessage('ar.guestPayment.activities.cardHolder.form.fields.city.label') }}
        {...registerField('city')}
      />
      <Form.TextField
        isHidden={!shouldShowAddressFields}
        labelProps={{ label: formatMessage('ar.guestPayment.activities.cardHolder.form.fields.state.label') }}
        {...registerField('state')}
      />
      <Form.TextField
        labelProps={{ label: formatMessage('ar.guestPayment.activities.cardHolder.form.fields.postalCode.label') }}
        maskProps={{ mask: ZIP_CODE_SHORT_MASK }}
        isHidden={!shouldShowAddressFields}
        {...registerField('postalCode')}
      />
      <Form.ContentBox colSpan={2}>
        <Group variant="vertical" spacing="xs-s" width="full">
          <ScheduledDateField
            fundingSourceType="card"
            scheduledDate={watch('scheduledDate')}
            minScheduledDate={minScheduledDate}
            setScheduledDate={(scheduledDate: Date) => setValue('scheduledDate', scheduledDate)}
            {...registerField('scheduledDate')}
          />
          <Pill
            status="neutral"
            type="secondary"
            label={formatMessage('ar.guestPayment.activities.scheduledDate.estimation.label', {
              date: formatDate(useEstimatedDeliveryDate(watch('scheduledDate'), 'card')),
            })}
          />
        </Group>
      </Form.ContentBox>
    </Form>
  );
});
CardHolderAddressDetailsAndScheduledDateForm.displayName = 'CardHolderAddressDetailsAndScheduledDateForm';
