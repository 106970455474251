import { useCallback, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ImportEbillsStatusIndicatorProvider } from '@melio/ap-activities';
import { PartnerName } from '@melio/platform-api';
import { useAutoCloseToast } from '@melio/platform-utils';
import { SystemMessageProvider } from '@melio/platform-utils/system-message';

import { refreshAccessToken } from '@/api/apiClients';
import { useResetAccessToken } from '@/hooks/session.hooks';
import { appAccessTokenSelector } from '@/store/app/app.model';
import { UnexpectedErrorBoundary } from '@/widgets/UnexpectedErrorBoundary/UnexpectedErrorBoundary.widget';
import { ConfigurationProvider } from './ConfigurationProvider.component';
type Props = {
  partnerName: PartnerName;
};

export const ApConfigurationProvider = ({ partnerName }: Props) => {
  const accessToken = useRecoilValue(appAccessTokenSelector);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onTokenExpired = useCallback(() => refreshAccessToken(), [refreshAccessToken]);
  const location = useLocation();
  const setAccessToken = useResetAccessToken();

  useEffect(() => {
    setAccessToken();
  }, [setAccessToken]);

  useAutoCloseToast(location.pathname, location.state);

  return (
    <ConfigurationProvider partnerName={partnerName} accessToken={accessToken} onTokenExpired={onTokenExpired}>
      <SystemMessageProvider>
        <UnexpectedErrorBoundary>
          <ImportEbillsStatusIndicatorProvider>
            <Outlet />
          </ImportEbillsStatusIndicatorProvider>
        </UnexpectedErrorBoundary>
      </SystemMessageProvider>
    </ConfigurationProvider>
  );
};
