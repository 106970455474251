import { Bill, BillsApiClient, PostBillsRequest } from '@melio/platform-api-axios-client';
import { convertCentsToDollars, convertDollarsToCents } from '@melio/platform-utils';
import { updateDateTimeToDateString } from '@melio/platform-utils/src/date-utils/utils';

import { Bill as LegacyBill } from './types';

export const prepareBillParams = <T extends { amount?: number | null; balance?: number; dueDate?: string }>(src: T) =>
  updateDateTimeToDateString<T>(
    convertDollarsToCents(src, ['amount', 'balance', 'lineItems[].amount', 'lineItems[].unitPrice']),
    ['dueDate']
  );

export const prepareBillParamsForUpdate = <T extends { dueDate?: string }>(src: T) =>
  updateDateTimeToDateString<T>(
    convertDollarsToCents(src, ['amount', 'balance', 'lineItems[].body.amount', 'lineItems[].body.unitPrice']),
    ['dueDate']
  );
export const convertBillCentsToDollars = (data: LegacyBill | Bill) =>
  convertCentsToDollars<LegacyBill | Bill, LegacyBill>(data, [
    'amount',
    'balance',
    'minimumAmount',
    'billerAccountBalance',
    'eBillAmountDue',
    'payments[].amount',
    'payments[].paymentBillsInfo[].paymentAmount',
    'lineItems[].amount',
    'lineItems[].unitPrice',
  ]);

export const prepareBatchCreatePayload = (data: PostBillsRequest[]) => data.map((bill) => prepareBillParams(bill));

export const postBillsBulk = async (payload: PostBillsRequest[]) =>
  await BillsApiClient.postBillsBulk({ bills: payload });
