import { StatusModalProps } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export type UseCannotDeleteBillModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export type UseCannotDeleteBillModalReturnType = StatusModalProps & { 'data-testid': string } & {
  description: string;
};

export const useCannotDeleteBillModal = (props: UseCannotDeleteBillModalProps): UseCannotDeleteBillModalReturnType => {
  const { isOpen, onClose } = props;
  const { formatMessage } = useMelioIntl();

  const header = formatMessage('activities.payDashboard.billsTab.cannotDeleteBillModal.header');
  const cancelLabel = formatMessage('activities.payDashboard.billsTab.cannotDeleteBillModal.closeButton');
  const description = formatMessage('activities.payDashboard.billsTab.cannotDeleteBillModal.description');

  return {
    'data-testid': 'cannot-delete-bill-modal-screen',
    variant: 'alert',
    header,
    description,
    secondaryButton: {
      variant: 'tertiary',
      label: cancelLabel,
      onClick: onClose,
    },
    isOpen,
    onClose,
  };
};
