import { BillInfo, PaymentIntent, useBills, Vendor } from '@melio/platform-api';

type ArrayElement<A> = A extends readonly (infer T)[] ? T : never;
type ExtendedBillPayment = ArrayElement<PaymentIntent['billPayments']> & {
  invoiceNumber: BillInfo['invoice']['number'];
  balance?: BillInfo['balance'];
};
export type ExtendedPaymentIntent = Omit<PaymentIntent, 'billPayments'> & {
  vendorId: Vendor['id'];
  billPayments?: ExtendedBillPayment[];
};

export const useExtendedPaymentIntent = (paymentIntent?: PaymentIntent): ExtendedPaymentIntent | undefined => {
  const billIds = paymentIntent?.billPayments?.map((billPayment) => billPayment.billId);
  const { data: bills } = useBills({
    enabled: !!billIds?.length,
    params: {
      search: { 'bill.id': billIds },
      expand: ['createdBy', 'file', 'payments', 'payments.loan', 'payments.timeline', 'vendor'],
    },
  });

  if (!paymentIntent) {
    return paymentIntent;
  }

  if (!bills) {
    // while fetching bills
    return undefined;
  }
  if (!bills[0]) {
    throw new Error('failed to fetch bills');
  }

  return {
    ...paymentIntent,
    billInfo: bills[0] as BillInfo, // Bill has same properties as BillInfo - this is for backwards compatability when the feature flag is off
    vendorId: bills[0].vendorId,
    billPayments: paymentIntent?.billPayments?.map((billPayment) => ({
      ...billPayment,
      invoiceNumber: bills.find((bill) => bill.id === billPayment.billId)?.invoice.number,
      balance: bills.find((bill) => bill.id === billPayment.billId)?.balance,
    })),
  };
};
