import { VisuallyHidden } from '@chakra-ui/react';
import { Button, Container, Divider, Group, useBreakpoint } from '@melio/penny';
import { FundingSource } from '@melio/platform-api-axios-client';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils/system-message';

import { useSubscriptionMe } from '../../../api';
import { usePartnerGroups, useRedirects, useSubscription, useSubscriptionPlan } from '../../../hooks';
import { useSubscriptionRouter } from '../../utils';

type SubscriptionSelectFundingSourceFooterProps = {
  fundingSources?: FundingSource[];
  selectedFundingSourceId: string;
};

export const SubscriptionSelectFundingSourceFooter = ({
  fundingSources,
  selectedFundingSourceId,
}: SubscriptionSelectFundingSourceFooterProps) => {
  const { formatMessage } = useMelioIntl();
  const { goToSubscriptionPlansSettings } = useSubscriptionRouter();
  const { currentPlan } = useSubscriptionPlan();
  const subscription = useSubscription();
  const { update: updateSubscription, isMutating: isUpdatingSubscription } = useSubscriptionMe({ enabled: false });
  const { goToSettingsPage } = useRedirects();
  const { showMessage } = useSystemMessage();
  const { isFiservPartner } = usePartnerGroups();
  const { isExtraSmallScreen } = useBreakpoint();
  const shouldDisableSaveButton =
    !isFiservPartner && (selectedFundingSourceId === subscription?.fundingSourceId || fundingSources?.length == 0);

  const onUpdateSuccess = () => {
    goToSettingsPage();

    showMessage({
      type: 'success',
      title: formatMessage('activities.subscription.selectPaymentMethod.SystemMessage.success', {}),
      dataTestId: 'subscription-paymentMethod-change-success',
    });
  };

  const onUpdateError = () => {
    goToSettingsPage();

    showMessage({
      type: 'error',
      title: formatMessage('activities.subscription.selectPaymentMethod.SystemMessage.error', {}),
      dataTestId: 'subscription-paymentMethod-change-error',
    });
  };

  const updateFundingSource = (fundingSourceId: string) => {
    if (!currentPlan) {
      return;
    }

    if (fundingSourceId === subscription?.fundingSourceId) {
      goToSettingsPage();
      return;
    }

    void updateSubscription({ fundingSourceId })
      .then(() => {
        onUpdateSuccess();
      })
      .catch(() => {
        onUpdateError();
      });
  };

  const SaveButton = ({ isFullWidth = false }: { isFullWidth?: boolean }) => (
    <Button
      size="medium"
      variant="primary"
      data-testid="subscription-payment-method-select-save-button"
      isDisabled={shouldDisableSaveButton}
      isLoading={isUpdatingSubscription}
      label={formatMessage('activities.subscription.paymentMethod.select.save')}
      onClick={() => updateFundingSource(selectedFundingSourceId)}
      isFullWidth={isFullWidth}
    />
  );

  return isFiservPartner ? (
    <>
      <Divider />
      <VisuallyHidden role="status" aria-live="polite">
        {isUpdatingSubscription && formatMessage('activities.addBillV2.footer.saving')}
      </VisuallyHidden>
      <Container paddingX="m" paddingY="m">
        <Group justifyContent="space-between">
          {!isExtraSmallScreen && (
            <Button
              size="medium"
              data-testid="add-bill-v2-cancel-button"
              variant="tertiary"
              label={formatMessage('activities.addBillV2.footer.buttons.cancel')}
              onClick={goToSubscriptionPlansSettings}
            />
          )}
          <SaveButton isFullWidth={isExtraSmallScreen} />
        </Group>
      </Container>
    </>
  ) : (
    <Container width="full" paddingX="m" paddingY="m" justifyContent="center">
      <SaveButton />
    </Container>
  );
};
