import { useBreakpointValue } from '@chakra-ui/react';
import { extractNameAndAccountDigits } from '@melio/ap-widgets';
import { Group, Illustration, IllustrationProps } from '@melio/penny';
import { FundingSource } from '@melio/platform-api';
import { FormattedCurrency } from '@melio/platform-i18n';
import { useMelioIntl } from '@melio/platform-provider';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';

export type RefundSentScreenProps = {
  amount: number;
  currency?: string;
  fundingSource: FundingSource;
  isLoading?: boolean;
  onDone: VoidFunction;
};

export const RefundSentScreen = ({ fundingSource, amount, currency, onDone, ...props }: RefundSentScreenProps) => {
  const { formatMessage } = useMelioIntl();
  const formattedAmount = <FormattedCurrency amount={amount} currency={currency} />;
  const { accountNumber } = extractNameAndAccountDigits(fundingSource);

  const illustrationSize = useBreakpointValue<IllustrationProps['size']>({ xs: 'small', s: 'small', m: 'large' });

  return (
    <NewSinglePaymentStepLayout
      data-component="RefundBillActivity.RefundSentScreen"
      data-testid="refund-bill-activity-refund-sent-screen"
      footerContent={
        <NewSinglePaymentStepLayout.Actions>
          <NewSinglePaymentStepLayout.NextButton
            onClick={onDone}
            data-testid="track-refund-button"
            label={formatMessage('activities.refundBill.screens.refundSent.continue')}
          />
        </NewSinglePaymentStepLayout.Actions>
      }
      {...props}
    >
      <Group justifyContent="center">
        <Illustration type="sent" size={illustrationSize} />
      </Group>

      <NewSinglePaymentStepLayout.Title>
        {formatMessage('activities.refundBill.screens.refundSent.titleSuccess')}
      </NewSinglePaymentStepLayout.Title>
      <NewSinglePaymentStepLayout.Description>
        {formatMessage('activities.refundBill.screens.refundSent.descriptionSuccess', {
          amount: formattedAmount,
          accountNumber,
        })}
      </NewSinglePaymentStepLayout.Description>
    </NewSinglePaymentStepLayout>
  );
};

RefundSentScreen.displayName = 'RefundBillActivity.RefundSentScreen';
