import { BillProvider, ProvidersProps, useProvider } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { usePermissions } from '@melio/platform-permissions';

export function useBillProviders(providersProps?: ProvidersProps): BillProvider[] {
  const { can } = usePermissions();

  const canUseAmazon = can({ subject: 'billProviders:amazon', action: 'create' });
  const canUseGmail = can({ subject: 'billProviders:gmail', action: 'create' });

  const [eBillsAmazonEnabled] = useFeature(FeatureFlags.EBillsAmazonEnabled, false);
  const [eBillsGmailEnabled] = useFeature(FeatureFlags.EBillsGmailEnabled, false, {
    shouldTrack: true,
  });

  const isAmazonEnabled = eBillsAmazonEnabled && canUseAmazon;
  const isGmailEnabled = eBillsGmailEnabled && canUseGmail;

  const amazonProvider = useProvider('amazon', { ...providersProps?.amazon, enabled: isAmazonEnabled });
  const gmailProvider = useProvider('gmail', { ...providersProps?.gmail, enabled: isGmailEnabled });

  const enabledProviders = [];
  if (isAmazonEnabled) {
    enabledProviders.push(amazonProvider);
  }
  if (isGmailEnabled) {
    enabledProviders.push(gmailProvider);
  }

  return enabledProviders;
}
