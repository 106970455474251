import { Group, Typography } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

type Props = {
  isDisabled?: boolean;
};

export const Title = ({ isDisabled = false }: Props) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Group variant="vertical" spacing="xxs">
      <Typography.MainLabel
        label={formatMessage('widgets.fundingSources.selectFundingSourceCard.payWithFinancing.content')}
        variant="bold"
        isDisabled={isDisabled}
      />
      <Typography.Description
        label={formatMessage('widgets.fundingSources.selectFundingSourceCard.payWithFinancing.provider')}
        isDisabled={isDisabled}
      />
    </Group>
  );
};
