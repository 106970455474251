import { useIsMobile } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';

import { Footer } from '../../../layouts';
import * as types from '../types';
import { useScreenLabels } from '../utils';

type ShareInvoiceFooterProps = {
  isLoading: boolean;
  isSubmitting: boolean;
  handleSubmit?: (target?: types.SubmitTarget) => VoidFunction;
  submitTarget?: types.SubmitTarget;
  shouldSend?: boolean;
};

export const ShareInvoiceDesktopFooter = forwardRef<ShareInvoiceFooterProps>(
  ({ isLoading, shouldSend = false, isSubmitting, handleSubmit, submitTarget }, ref) => {
    const { primaryButtonLabel, secondaryButtonLabel, tertiaryButtonLabel } = useScreenLabels({ shouldSend });
    const isMobile = useIsMobile();

    return !isMobile ? (
      <Footer
        primaryButtonProps={{
          label: primaryButtonLabel,
          isLoading: isLoading || (isSubmitting && submitTarget === 'primary'),
          onClick: handleSubmit?.('primary'),
          isDisabled: isSubmitting && submitTarget !== 'primary',
        }}
        secondaryButtonProps={{
          label: secondaryButtonLabel,
          isLoading: isLoading || (isSubmitting && submitTarget === 'secondary'),
          onClick: handleSubmit?.('secondary'),
          isDisabled: isSubmitting && submitTarget !== 'secondary',
        }}
        tertiaryButtonProps={{
          label: tertiaryButtonLabel,
          onClick: handleSubmit?.('tertiary'),
          isDisabled: isLoading || isSubmitting,
        }}
        ref={ref}
      />
    ) : null;
  }
);

ShareInvoiceDesktopFooter.displayName = 'ShareInvoiceDesktopFooter';

export const ShareInvoiceMobileFooter = forwardRef<ShareInvoiceFooterProps>(
  ({ isLoading, shouldSend = false, isSubmitting, handleSubmit, submitTarget }, ref) => {
    const { primaryButtonLabel } = useScreenLabels({ shouldSend });
    const isMobile = useIsMobile();

    return isMobile ? (
      <Footer
        primaryButtonProps={{
          label: primaryButtonLabel,
          isLoading: isLoading || (isSubmitting && submitTarget === 'primary'),
          onClick: handleSubmit?.('primary'),
          isDisabled: isSubmitting && submitTarget !== 'primary',
        }}
        ref={ref}
      />
    ) : null;
  }
);
ShareInvoiceMobileFooter.displayName = 'ShareInvoiceMobileFooter';
