import { Card, Container, Group, Icon, NakedButton, Text } from '@melio/penny';
import { FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { usePaymentMethodFee } from '../../../hooks';
import { cardProps } from './utils';

export type FundingSourceCardProps = {
  fundingSourceType: FundingSource['type'];
  onClick: VoidFunction;
};

export const FundingSourceEmptyCard = ({ onClick, fundingSourceType }: FundingSourceCardProps) => {
  const { formatMessage } = useMelioIntl();
  const fee = usePaymentMethodFee({ fundingSourceType });

  const getTitle = () => {
    if (fundingSourceType === 'card') {
      return formatMessage('activities.subscription.fundingSourceSelection.empty.card.title');
    }

    if (fundingSourceType === 'bank-account') {
      return formatMessage('activities.subscription.fundingSourceSelection.empty.bank.title');
    }

    return '';
  };

  const getDescription = () => {
    if (fundingSourceType === 'card') {
      return formatMessage('activities.subscription.fundingSourceSelection.empty.card.description', {
        fee,
      });
    }

    if (fundingSourceType === 'bank-account') {
      return formatMessage('activities.subscription.fundingSourceSelection.empty.bank.description', {
        fee,
      });
    }

    return '';
  };

  return (
    <Card
      onClick={onClick}
      data-component="FundingSourceEmptyCard"
      data-testid={`funding-source-empty-card-${fundingSourceType}`}
      {...cardProps}
    >
      <Container alignItems="center" width="full" justifyContent="space-between">
        <Group spacing="s" variant="horizontal" alignItems="center">
          <Icon type={fundingSourceType === 'card' ? 'credit-card' : 'bank'} />
          <Group spacing="xxxs" variant="vertical">
            <Text textStyle="body2Semi">{getTitle()}</Text>
            <Text color="global.neutral.800" textStyle="body4">
              {getDescription()}
            </Text>
          </Group>
        </Group>
        <NakedButton
          variant="secondary"
          onClick={onClick}
          data-testid={`add-new-funding-source-${fundingSourceType}`}
          label={formatMessage('activities.subscription.fundingSourceSelection.empty.action')}
        />
      </Container>
    </Card>
  );
};
