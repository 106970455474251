import { PostOrganizationsRequest } from '@melio/platform-api';

import { CompanyDetailsFormWidgetFields } from './widgets';

type Props = CompanyDetailsFormWidgetFields;

export const buildCreateOwnedOrganizationParams = ({ name, address, aptNumber }: Props): PostOrganizationsRequest => ({
  name,
  address: {
    ...address,
    aptNumber: aptNumber || undefined,
  },
});
