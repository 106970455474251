import { isAddressValid } from '@melio/ap-domain';
import { useAccount } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';

export const useIsInternationalSupportForBusiness = () => {
  const { data: account, isLoading: isAccountLoading } = useAccount({ id: 'me' });
  const [isInternationalDeliveryMethodSupported] = useFeature(
    FeatureFlags.IsInternationalDeliveryMethodSupported,
    false,
  );

  const isAccountAddressValid =
    isAddressValid(account?.company.address) && isAddressValid(account?.company.legalAddress);

  const isInternationalSupportedForBusiness = isInternationalDeliveryMethodSupported && isAccountAddressValid;

  return {
    isLoading: isAccountLoading,
    isInternationalSupportedForBusiness,
  };
};
