import { useMtlSchemaValidations } from '@melio/ap-domain';
import { FundingSourceEntitlementType, PermissionLevelEnum, RoleUniqueNames } from '@melio/platform-api';
import { array, number, object, SchemaOf, string, StringSchema } from 'yup';

import { FormFields } from '../types';

export const useSchema = (): SchemaOf<FormFields> => {
  const { name } = useMtlSchemaValidations();

  return object().shape({
    firstName: name('firstName'),
    lastName: name('lastName'),
    email: string().email().required(),
    permissionLevel: new StringSchema<PermissionLevelEnum>().oneOf(Object.values(PermissionLevelEnum)).required(),
    roleUniqueName: new StringSchema<RoleUniqueNames>().oneOf(Object.values(RoleUniqueNames)).required(),
    paymentApprovalLimit: number()
      .nullable()
      .default(null)
      .transform((currentValue: number) => (isNaN(currentValue) ? null : currentValue)),
    entitlementsFundingSourceIds: array().of(string().required()),
    entitlementsFundingSourceType: new StringSchema<FundingSourceEntitlementType>()
      .oneOf(Object.values(FundingSourceEntitlementType))
      .required(),
  });
};
