import { useIsMobile } from '@melio/ar-domain';
import { Container, Tabs } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { TabsNames } from '../types';
import { useScreenLabels } from '../utils';

type PreviewPanelHeaderProps = {
  activeTab: TabsNames;
  onChangeActiveTab: (tabName: TabsNames) => void;
};

export const PreviewPanelHeader = forwardRef<PreviewPanelHeaderProps>(({ activeTab, onChangeActiveTab }, ref) => {
  const isMobile = useIsMobile();
  const { tabs } = useScreenLabels();

  return !isMobile ? (
    <Container paddingLeft="xxl" paddingTop="xxl" data-component={PreviewPanelHeader.displayName}>
      <Tabs
        variant="default"
        ref={ref}
        activeTab={activeTab}
        onChange={(tab) => onChangeActiveTab(tab as TabsNames)}
        tabs={tabs}
        data-testid="preview-tabs"
      />
    </Container>
  ) : null;
});
PreviewPanelHeader.displayName = 'PreviewPanelHeader';
