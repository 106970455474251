import { ServiceChargeCatalog, useMelioIntl } from '@melio/ar-domain';
import { ActionsDropdownMenuItemProps } from '@melio/penny';

export type UseServiceChargeActionsItemsProps = {
  serviceCharge: ServiceChargeCatalog;
  onEdit: (serviceChargeId: string) => unknown;
  onDelete: (serviceChargeId: string) => unknown;
};

export const useServiceChargeActionsItems = ({
  serviceCharge,
  onEdit,
  onDelete,
}: UseServiceChargeActionsItemsProps): ActionsDropdownMenuItemProps[] => {
  const { formatMessage } = useMelioIntl();

  const editServiceChargeAction = {
    label: formatMessage(
      'ar.settings.activities.serviceChagresTable.components.serviceChagresTable.columns.actions.edit.label'
    ),
    onClick: () => onEdit(serviceCharge.id),
    dataTestId: 'service-charge-edit',
  };

  const deleteServiceChargeAction = {
    label: formatMessage(
      'ar.settings.activities.serviceChagresTable.components.serviceChagresTable.columns.actions.delete.label'
    ),
    onClick: () => onDelete(serviceCharge.id),
    variant: 'critical',
    dataTestId: 'service-charge-delete',
  };

  return [editServiceChargeAction, deleteServiceChargeAction];
};
