import { forwardRef } from '@melio/platform-utils';

import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';

export type RecurringPaymentScheduledLoadingScreenProps = {
  onBack?: VoidFunction;
  onClose?: VoidFunction;
};

export const RecurringPaymentScheduledLoadingScreen = forwardRef<RecurringPaymentScheduledLoadingScreenProps, 'div'>(
  ({ onBack, onClose, ...props }, ref) => (
    <NewSinglePaymentStepLayout
      data-component="RecurringPaymentScheduledActivity.RecurringPaymentScheduledLoadingScreen"
      data-testid="recurring-payment-scheduled-activity-recurring-payment-scheduled-loading-screen"
      {...props}
      ref={ref}
      isLoading
    />
  )
);

RecurringPaymentScheduledLoadingScreen.displayName =
  'RecurringPaymentScheduledActivity.RecurringPaymentScheduledLoadingScreen';
