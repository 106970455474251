import { useAccountingPlatformSyncVersionQuery } from '@melio/platform-api';
import { usePartnerFeature } from '@melio/platform-provider';

export function useInternationalFxFeature() {
  const [internationalFxEnabled] = usePartnerFeature('InternationalFx', false);
  const { data: syncVersion, isLoading: isSyncVersionLoading } = useAccountingPlatformSyncVersionQuery();

  if (!internationalFxEnabled) {
    return false;
  }

  return !isSyncVersionLoading && syncVersion?.data.data.syncVersion !== 1;
}
