import { SectionBanner } from '@melio/penny';
import { Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export const LoanCanceledSectionBanner = ({ payment }: { payment: Payment }) => {
  const { formatMessage } = useMelioIntl();

  const descriptionKey =
    payment.deliveryMethod?.type === 'paper-check'
      ? 'activities.payDashboard.drawer.body.canceled.checkDeliveryMethod.sectionBanner.description'
      : 'activities.payDashboard.drawer.body.canceled.sectionBanner.description';

  return (
    <SectionBanner
      title={formatMessage('activities.payDashboard.drawer.body.canceled.sectionBanner.title')}
      description={formatMessage(descriptionKey)}
      variant="critical"
      data-testid="loan-canceled-alert"
    />
  );
};
