import { Container, Group, Illustration, IllustrationProps, useBreakpointValue } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { NewSinglePaymentStepLayout } from '../../NewSinglePaymentStepLayout';

export const ApplicationProcessingLoader = () => {
  const { formatMessage } = useMelioIntl();

  const illustrationSize = useBreakpointValue<IllustrationProps['size']>({ xs: 'small', s: 'small', m: 'large' });

  return (
    <Container paddingTop="xxxl">
      <NewSinglePaymentStepLayout data-testid="financing-application-processing-loader">
        <Group justifyContent="center">
          <Illustration type="processing" size={illustrationSize} />
        </Group>
        <NewSinglePaymentStepLayout.Title
          data-testid="financing-application-processing-loader-title"
          textAlign="center"
        >
          {formatMessage('activities.financingApplication.applicationProcessingScreen.title')}
        </NewSinglePaymentStepLayout.Title>
        <NewSinglePaymentStepLayout.Description
          data-testid="financing-application-processing-loader-description"
          textAlign="center"
        >
          {formatMessage('activities.financingApplication.applicationProcessingScreen.description')}
        </NewSinglePaymentStepLayout.Description>
      </NewSinglePaymentStepLayout>
    </Container>
  );
};
