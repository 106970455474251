import { FiservDashboardTour } from './FiservDashboardTour/FiservDashboardTour';
import { NewFeaturesTour } from './NewFeaturesTour';
import { PayDashboardTour, Props } from './PayDashboardTour';

export const PayDashboardTourWrapper = (props: Props) => {
  const { activeTab, isFirstTimeExperienceTourtipRunning } = props;

  return (
    <>
      <FiservDashboardTour />
      <PayDashboardTour {...props} />
      {!isFirstTimeExperienceTourtipRunning && <NewFeaturesTour activeTab={activeTab} />}
    </>
  );
};
