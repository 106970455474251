import { forwardRef } from '@melio/platform-utils';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';

export type DeliveryDateLoadingScreenProps = {
  onBack?: VoidFunction;
  onClose?: VoidFunction;
};

export const DeliveryDateLoadingScreen = forwardRef<DeliveryDateLoadingScreenProps, 'div'>(
  ({ onBack, onClose, ...props }, ref) => (
    <NewSinglePaymentStepLayout
      data-component="DeliveryDateActivity.DeliveryDateLoadingScreen"
      data-testid="delivery-date-activity-schedule-date-screen-loading"
      {...props}
      ref={ref}
      isLoading
    />
  )
);

DeliveryDateLoadingScreen.displayName = 'DeliveryDateActivity.DeliveryDateLoadingScreen';
