import {
  useCardExpired,
  useFundingSourcePermissions,
  useInternationalPaymentCardRestrictionsText,
} from '@melio/ap-domain';
import { IconKey, PillProps, SelectionCard, Tooltip } from '@melio/penny';
import {
  CardFundingSource,
  FundingSource,
  useFundingSource,
  useIsPlaidLinkVerificationTokenFetching,
} from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { useMemo } from 'react';

import { useFundingSourceIconKey } from '../../../../hooks/fundingSource.hooks';
import { getFundingSourceImageProps, UnsupportedFundingSourceTypeReasons } from '../../../utils';
import { useFundingSourceCardDisplayText, useFundingSourceCardHelperText } from './FundingSourceCard.utils';

export type FundingSourceCardProps = {
  fundingSource: FundingSource;
  isSelected?: boolean;
  onVerify: VoidFunction;
  onClick?: VoidFunction;
  isDisabled?: boolean;
  isBillingMethod?: boolean;
  isFirmBillingFeeMethod?: boolean;
  disabledReason?: UnsupportedFundingSourceTypeReasons;
  isFundingTypeSupported?: boolean;
  showAddedBy?: boolean;
};

export const FundingSourceCard = forwardRef<FundingSourceCardProps, 'div'>(
  (
    {
      fundingSource,
      isSelected,
      onVerify,
      onClick,
      isDisabled,
      isBillingMethod,
      isFirmBillingFeeMethod = false,
      isFundingTypeSupported,
      disabledReason,
      showAddedBy = false,
      ...props
    },
    ref
  ) => {
    const { getHelperTextProps } = useFundingSourceCardHelperText();
    const { getDisplayText } = useFundingSourceCardDisplayText();
    const { isCardExpired } = useCardExpired();
    const fundingSourceImage = getFundingSourceImageProps(fundingSource);
    const fundingSourceIconType = useFundingSourceIconKey(fundingSource);
    const { formatMessage } = useMelioIntl();
    const { canUpdate } = useFundingSourcePermissions({ fundingSource });
    const [setAsDefaultPaymentMethodEnabled] = useFeature(FeatureFlags.SetAsDefaultPaymentMethodEnabled, false);
    const internationalPaymentRestrictionsLabelProvider = useInternationalPaymentCardRestrictionsText();
    const isPlaidLinkVerificationTokenFetching = useIsPlaidLinkVerificationTokenFetching(fundingSource.id);
    const { isVerifying: isVerifyingFundingSource } = useFundingSource({ id: fundingSource.id, enabled: false });

    const getFundingSourceDisabledReason = (): string | false => {
      switch (disabledReason) {
        case 'internationalPaymentRestrictions':
          return internationalPaymentRestrictionsLabelProvider(fundingSource);
        case 'cardsNotAllowedForManagedVendor':
          return formatMessage(
            'widgets.fundingSources.selectFundingSourceCard.card.disabledReason.cardsNotAllowedForManagedVendor'
          );
        case 'prohibitedMcc':
          return formatMessage('widgets.fundingSources.selectFundingSourceCard.card.disabledReason.prohibitedMcc');
      }
      return false;
    };

    const fundingSourceDisabledReasonMessageKey = getFundingSourceDisabledReason();

    const badgeProps: PillProps[] = useMemo(() => {
      const badgeProps: PillProps[] = [];
      isFirmBillingFeeMethod &&
        badgeProps.push({
          type: 'secondary',
          status: 'neutral',
          label: formatMessage('widgets.billingSettings.paymentMethod.card.firmBadge'),
        });
      setAsDefaultPaymentMethodEnabled &&
        fundingSource.isDefault &&
        badgeProps.push({
          type: 'primary',
          status: 'neutral',
          'aria-label': 'default-funding-source',
          label: formatMessage('widgets.billingSettings.paymentMethod.card.default'),
        });
      return badgeProps;
    }, [isFirmBillingFeeMethod, fundingSource, formatMessage, setAsDefaultPaymentMethodEnabled]);

    const isFundingSourceCardDisabled =
      fundingSource.isBlocked || isDisabled || isCardExpired(fundingSource as CardFundingSource);

    const fundingSourceCard = (
      <SelectionCard
        data-component="FundingSourceCard"
        data-testid={`funding-source-card-${fundingSource.id}`}
        {...props}
        ref={ref}
        {...(fundingSourceImage
          ? { imageIcon: { image: fundingSourceImage, fallbackIcon: fundingSourceIconType as IconKey } }
          : { icon: fundingSourceIconType })}
        mainLabelProps={{
          label: getDisplayText(fundingSource),
          pillProps: badgeProps,
        }}
        {...(fundingSource.nickname ? { labelProps: { label: fundingSource.nickname } } : {})}
        descriptionProps={getHelperTextProps({
          fundingSource,
          onVerify,
          isVerifying: isPlaidLinkVerificationTokenFetching || isVerifyingFundingSource,
          showAddedBy,
          isBillingMethod,
          isFirmBillingFeeMethod,
          canUpdateFundingSource: canUpdate,
        })}
        isSelected={isSelected}
        onClick={onClick}
        isDisabled={isFundingSourceCardDisabled}
      />
    );

    return fundingSourceDisabledReasonMessageKey ? (
      <Tooltip
        data-testid={`funding-source-card-tooltip-${fundingSource.id}`}
        label={fundingSourceDisabledReasonMessageKey}
        shouldAddTriggerFocus={isFundingSourceCardDisabled}
      >
        {fundingSourceCard}
      </Tooltip>
    ) : (
      fundingSourceCard
    );
  }
);

FundingSourceCard.displayName = 'FundingSourceCard';
