import { Group, Table, Text } from '@melio/penny';
import { Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { identity } from 'lodash';

type Props = { vendor: Vendor };
export const VendorCell = ({ vendor }: Props) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Table.Cell data-testid={`vendors-batch-payments-modal-body-row-${vendor.id}-vendor-cell`}>
      <Group variant="vertical" spacing="xxs">
        <Text textStyle="body3Semi" color="global.neutral.1000" shouldSupportEllipsis>
          {vendor.name}
        </Text>

        <Text textStyle="body4" color="global.neutral.800">
          {[vendor.nickname, vendor.accountNumber]
            .filter(identity)
            .join(
              formatMessage(
                'activities.payDashboard.vendorsTab.vendorsBatchPaymentsModal.body.table.cells.vendor.descriptionDivider'
              )
            )}
        </Text>
      </Group>
    </Table.Cell>
  );
};
