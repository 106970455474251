import { Form, useMelioForm } from '@melio/penny';
import { Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import * as yup from 'yup';

import { FormWidgetProps } from '../../types';

export type PaymentScheduledNotifyVendorFormWidgetFields = {
  vendorsContact: { id: string; email?: string }[];
};

const useSchema = () => {
  const { formatMessage } = useMelioIntl();

  const emailSchema = yup
    .string()
    .email(formatMessage('widgets.paymentScheduledNotifyVendorForm.fields.email.validation.format'));

  return yup.object().shape({
    vendorsContact: yup.array(
      yup.object().shape({
        email: emailSchema,
        id: yup.string().required(),
      })
    ),
  });
};

export type PaymentScheduledNotifyVendorFormWidgetProps =
  FormWidgetProps<PaymentScheduledNotifyVendorFormWidgetFields> & {
    vendors: { name: Vendor['name']; id: Vendor['id'] }[];
  };

export const PaymentScheduledNotifyVendorFormWidget = forwardRef<PaymentScheduledNotifyVendorFormWidgetProps, 'form'>(
  ({ vendors, isSaving, onSubmit, onSubmissionStateChange, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const { formProps, registerField } = useMelioForm<PaymentScheduledNotifyVendorFormWidgetFields>({
      onSubmit,
      schema: useSchema(),
      defaultValues: { vendorsContact: vendors.map((vendor) => ({ id: vendor.id, email: '' })) },
      isSaving,
      onSubmissionStateChange,
    });

    return (
      <Form data-component="PaymentScheduledNotifyVendorFormWidget" {...props} {...formProps} ref={ref}>
        {vendors.map((vendor, index) => (
          <Form.TextField
            key={vendor.id}
            type="text"
            labelProps={{
              label: formatMessage('widgets.paymentScheduledNotifyVendorForm.fields.email.label', {
                vendorName: vendor.name,
              }),
            }}
            placeholder={formatMessage('widgets.paymentScheduledNotifyVendorForm.fields.email.placeholder', {
              vendorUsername: vendor.name.toLowerCase().replace(/\s/g, ''),
            })}
            {...registerField(`vendorsContact.${index}.email`)}
            autoFocus={!index}
          />
        ))}
      </Form>
    );
  }
);

PaymentScheduledNotifyVendorFormWidget.displayName = 'PaymentScheduledNotifyVendorFormWidget';
