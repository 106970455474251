import {
  getFundingSourceIconType,
  getFundingSourceImageProps,
  PaymentReviewLineItem,
  useGetFundingSourceLabel,
} from '@melio/ap-widgets';
import { Group } from '@melio/penny';
import { FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { addBusinessDays } from '@melio/platform-utils';

import { SectionBlock } from './SectionBlock';

type Props = { fundingSource: FundingSource; initiatedDate?: Date };
export const PayFromSectionRefund = ({ fundingSource, initiatedDate }: Props) => {
  const { formatMessage, formatDate, formatDateTimeRange } = useMelioIntl();
  const { displayName, nickname, type: fundingSourceType } = fundingSource;

  const fundingSourceImage = getFundingSourceImageProps(fundingSource);
  const fundingSourceIconType = getFundingSourceIconType(fundingSource);
  const fundingSourceLabel = useGetFundingSourceLabel(fundingSource);
  const minDeliveryDate = addBusinessDays(initiatedDate as Date, 3);
  const maxDeliveryDate = addBusinessDays(initiatedDate as Date, 5);

  return (
    <Group data-testid="pay-from-section-refund" variant="vertical" spacing="m">
      <SectionBlock.Title
        label={formatMessage('widgets.paymentDetails.payFrom.refundTitle')}
        testId="pay-from-section-refund-title"
      />

      <PaymentReviewLineItem
        labelProps={{ label: fundingSourceLabel }}
        mainLabelProps={{ label: nickname || displayName }}
        {...(fundingSourceImage ? { imageIcon: fundingSourceImage } : { icon: { type: fundingSourceIconType } })}
      />

      <PaymentReviewLineItem
        labelProps={{ label: formatMessage('widgets.paymentDetails.payFrom.initiatedDate.label') }}
        mainLabelProps={{ label: formatDate(initiatedDate, { dateStyle: 'medium' }) }}
        icon={{ type: 'scheduled' }}
      />

      <PaymentReviewLineItem
        labelProps={{ label: formatMessage('widgets.paymentDetails.payFrom.refundEta.label') }}
        icon={{ type: 'calendar-move' }}
        descriptionProps={
          fundingSourceType === 'bank-account'
            ? { label: formatMessage('widgets.deliveryDateListItemByPayee.bankHelperText') }
            : undefined
        }
        mainLabelProps={{
          label: formatDateTimeRange(minDeliveryDate, maxDeliveryDate, {
            dateStyle: 'medium',
          }),
        }}
      />
    </Group>
  );
};
