import { useDeliveryMethod } from '@melio/platform-api';
import { BankAccount } from '@melio/platform-api';

import { WithLoading } from '@/hoc/withLoading.hoc';
import { useRouter } from '@/hooks/router.hooks';
import { ViewManualBankAccountScreen } from '@/screens/ViewManualBankAccount.screen';

export const ViewReceivingMethodBankAccountRoute = ({ id }: { id: string }) => {
  const { goBack, goToReceivingMethods } = useRouter();
  const { isLoading, error, data } = useDeliveryMethod({ id });

  if (error || (!isLoading && data?.type !== 'bank-account')) {
    goToReceivingMethods();
  }
  const bankAccount = data?.details as BankAccount;

  return (
    <WithLoading isLoading={isLoading}>
      <ViewManualBankAccountScreen
        onClose={goBack}
        onDone={goBack}
        defaultValues={{
          accountNumber: bankAccount?.accountNumber,
          routingNumber: bankAccount?.routingNumber,
        }}
      />
    </WithLoading>
  );
};

ViewReceivingMethodBankAccountRoute.displayName = 'ViewReceivingMethodBankAccountRoute';
