import { SelectableDropdownMenuItem } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useState } from 'react';

import { DisplayMode } from '../useFirmDashboard';

export const useHiddenClientsDropdown = (displayMode: DisplayMode) => {
  const { formatMessage } = useMelioIntl();
  const visibleItem: SelectableDropdownMenuItem = {
    label: formatMessage('activities.accountants.firmDashboard.hiddenClientsDropdown.visible.label'),
    value: 'visible',
    dataTestId: 'menu-item-visible-clients',
  };
  const hiddenItem: SelectableDropdownMenuItem = {
    label: formatMessage('activities.accountants.firmDashboard.hiddenClientsDropdown.hidden.label'),
    value: 'hidden',
    dataTestId: 'menu-item-hidden-clients',
  };
  const [isOpen, setIsOpen] = useState(false);
  const items = [visibleItem, hiddenItem];
  const selectedItem = displayMode === 'hidden' ? hiddenItem : visibleItem;

  return {
    isOpen,
    setIsOpen,
    items,
    visibleItem,
    hiddenItem,
    selectedItem,
  };
};
