import { MicroDepositsVerificationForm, MicroDepositsVerificationFormFields } from '@melio/ap-widgets';
import { Group, Modal, Text, useFormSubmissionController } from '@melio/penny';
import {
  DeliveryMethodVerificationIncorrectErrorCode,
  FundingSourceVerificationIncorrectErrorCode,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';

import { AddBankAccountPlaidActivity } from '../../../AddBankAccountPlaid';

export type VerificationModalScreenProps = {
  isSaving?: boolean;
  isOpen: boolean;
  errorCode?: FundingSourceVerificationIncorrectErrorCode | DeliveryMethodVerificationIncorrectErrorCode;
  onClose: VoidFunction;
  onDone: (data: MicroDepositsVerificationFormFields | null) => void;
  onError?: (error: PlatformError) => void;
  fundingSourceId?: string;
  deliveryMethodId?: string;
  verificationMethod: 'microDeposits' | 'plaid' | null;
};

export const VerificationModalScreen: React.VFC<VerificationModalScreenProps> = ({
  isOpen,
  errorCode,
  isSaving,
  onClose,
  onDone,
  fundingSourceId,
  deliveryMethodId,
  onError,
  verificationMethod,
}) => {
  const { onSubmissionStateChange, submitButtonProps } =
    useFormSubmissionController<MicroDepositsVerificationFormFields>();

  const { formatMessage } = useMelioIntl();

  return verificationMethod === 'plaid' ? (
    <AddBankAccountPlaidActivity
      onBack={onClose}
      onClose={onClose}
      onDone={() => onDone(null)}
      fundingSourceId={fundingSourceId}
      deliveryMethodId={deliveryMethodId}
      onError={onError}
      options={{ shouldAllowNonInstantVerification: true, isReceivingMethodFlow: !!deliveryMethodId }}
    />
  ) : (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      data-testid="micro-deposits-verification-modal-activity-verification-modal-screen"
      header={formatMessage('activities.microDepositsVerification.screens.verification.title')}
      primaryButton={{
        ...submitButtonProps,
        variant: 'primary',
        label: formatMessage('activities.microDepositsVerification.screens.verification.continue'),
      }}
    >
      <Group variant="vertical">
        <Text>{formatMessage('activities.microDepositsVerification.screens.verification.description')}</Text>
        <MicroDepositsVerificationForm
          autoFocus
          onSubmit={onDone}
          onSubmissionStateChange={onSubmissionStateChange}
          isSaving={isSaving}
        />
        {(errorCode === '400' || errorCode === '500') && (
          <Text textStyle="body4Semi" color="global.critical.700" data-testid="verification-error-incorrect">
            {formatMessage('activities.microDepositsVerification.screens.verification.error.incorrect')}
          </Text>
        )}
      </Group>
    </Modal>
  );
};

VerificationModalScreen.displayName = 'MicroDepositsVerificationModalActivity.VerificationModalScreen';
