import { useMelioIntl } from '@melio/platform-i18n';
import { object, SchemaOf, string } from 'yup';

import { CardFundingSourceFormFields } from './types';

export const useSchema = () => {
  const { formatMessage } = useMelioIntl();

  return object().shape({
    city: string().required(formatMessage('activities.subscription.checkout.fundingSourceSelection.city.required')),
    firstName: string()
      .required(formatMessage('activities.subscription.checkout.fundingSourceSelection.firstName.required'))
      .min(2, formatMessage('activities.subscription.checkout.fundingSourceSelection.firstName.length')),
    lastName: string()
      .required(formatMessage('activities.subscription.checkout.fundingSourceSelection.lastName.required'))
      .min(2, formatMessage('activities.subscription.checkout.fundingSourceSelection.lastName.length')),
    line1: string()
      .required(formatMessage('activities.subscription.checkout.fundingSourceSelection.address.required'))
      .nullable(),
    state: string().required(formatMessage('activities.subscription.checkout.fundingSourceSelection.state.required')),
    postalCode: string().required(
      formatMessage('activities.subscription.checkout.fundingSourceSelection.zipcode.required')
    ),
  }) as unknown as SchemaOf<CardFundingSourceFormFields>;
};
