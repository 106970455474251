import { useGuestPayorPaymentRequestDetails } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';

import { CustomPaymentScreen } from './screens';

type CustomPaymentActivityProps = {
  onViewInvoice: VoidFunction;
  paymentRequestLink: string;
  onError?: ARErrorFunction;
};

export const CustomPaymentActivity = forwardRef<CustomPaymentActivityProps>(
  ({ onError, onViewInvoice, paymentRequestLink }, ref) => {
    const { data: paymentRequestDetails } = useGuestPayorPaymentRequestDetails({
      onError,
      paymentRequestLink,
    });

    return (
      <CustomPaymentScreen
        onViewInvoice={onViewInvoice}
        customPayInstructions={paymentRequestDetails?.invoice.paymentOptions.customPayInstructions || undefined}
        ref={ref}
      />
    );
  }
);
CustomPaymentActivity.displayName = 'CustomPaymentActivity';
