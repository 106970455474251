import { FundingSource, useAccountingPlatforms } from '@melio/platform-api';
import { useCallback } from 'react';

import { getIsReconciliationEnabled, getIsReconciliationNeeded } from '../Reconciliation.modal-activity.utils';

export const useReconciliation = (): {
  isReconciliationEnabled: (fundingSource?: FundingSource | undefined) => boolean;
  isReconciliationNeeded: (fundingSource?: FundingSource | undefined) => boolean;
} => {
  const { activeAccountingPlatform } = useAccountingPlatforms({});

  const isReconciliationEnabled = useCallback(
    (fundingSource?: FundingSource) => getIsReconciliationEnabled(activeAccountingPlatform, fundingSource),
    [activeAccountingPlatform]
  );

  const isReconciliationNeeded = useCallback(
    (fundingSource?: FundingSource) => getIsReconciliationNeeded(activeAccountingPlatform, fundingSource),
    [activeAccountingPlatform]
  );

  return { isReconciliationEnabled, isReconciliationNeeded };
};
