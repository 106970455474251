/* eslint-disable react-hooks/exhaustive-deps */
import { Card, CardParams, useFundingSources } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';
import { useBoolean } from '@melio/platform-utils';
import { RefObject, useCallback, useEffect } from 'react';

export const cardParser = (digitsAndBin = '') => {
  const isValid = /\d{10}/.test(digitsAndBin);
  if (!isValid) {
    throw new Error(digitsAndBin);
  }
  return {
    lastFourDigits: digitsAndBin.slice(-4),
    cardBin: digitsAndBin.substring(0, 6),
  };
};

export const eventDataParser = (eventData = '') => {
  const [digitsAndBin = '', expiration = '', tabapayToken = ''] = eventData.split('|');
  Object.entries({ digitsAndBin, expiration, tabapayToken }).forEach(([key, value]) => {
    if (!value) {
      throw new Error(`Missing ${key} data in "${eventData}"`);
    }
  });
  const [, expirationYear = '', expirationMonth = ''] = /^(\d{4})(\d{2})$/.exec(expiration || '') || [];
  if (!expirationMonth || !expirationYear) {
    throw new Error(`Invalid expiration token: ${expiration}`);
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return { digitsAndBin, expirationMonth, expirationYear, tabapayToken, ...cardParser(digitsAndBin) };
};

type TabapayDoneOutgoingMessage = {
  done: {
    clear?: boolean;
  };
};

type UseCardVerificationProps = {
  url: string;
  onSuccess: (data: CardParams) => void;
  onError?: (error: PlatformError) => void;
  onClose?: VoidFunction;
  iframeRef: RefObject<HTMLIFrameElement>;
};

export const useCardVerificationListener = ({
  url,
  onError,
  onSuccess,
  onClose,
  iframeRef,
}: UseCardVerificationProps) => {
  const { verifyCard, isMutating } = useFundingSources({ enabled: false });
  const [isVerifying, verifying] = useBoolean(false);
  const [hasSpinnerInButton, spinnerInButton] = useBoolean(false);

  const verifyTabapayCard = (data: CardParams) =>
    verifyCard({ cardBin: data.cardBin, tabapayToken: data.tabapayToken, tokenProvider: 'tabapay' })
      .then(() => onSuccess(data))
      .catch(onError)
      .finally(verifying.off);

  const receivedMessage = useCallback(
    (event: MessageEvent<string>) => {
      if (!url.startsWith(event.origin)) {
        return;
      }
      if (event?.data === 'Close') {
        return onClose?.();
      }
      if (event?.data === 'ping') {
        return spinnerInButton.on();
      }
      verifying.on();
      const message: TabapayDoneOutgoingMessage = { done: {} }; // format: { eventName: { ...options } }
      checkCard(event.data)
        .then(verifyTabapayCard)
        .catch(onError)
        .finally(() => {
          verifying.off();
          iframeRef.current?.contentWindow?.postMessage(message, '*');
        });
    },
    [iframeRef.current]
  );

  useEffect(() => {
    window.addEventListener('message', receivedMessage, false);
    return () => window.removeEventListener('message', receivedMessage, false);
  }, [receivedMessage]);

  return {
    isVerifying: isMutating || isVerifying,
    hasSpinnerInButton,
  };
};

export const checkCard = (eventData = ''): Promise<CardParams> => {
  try {
    if (eventData.startsWith('Error: ')) {
      throw new Error(eventData);
    }
    const { expirationMonth, expirationYear, tabapayToken, lastFourDigits, cardBin } = eventDataParser(eventData);
    return Promise.resolve({
      tabapayToken,
      cardBin,
      expirationMonth,
      expirationYear,
      lastFourDigits,
      tokenProvider: 'tabapay',
    });
  } catch (e) {
    return Promise.reject((e as Error).message);
  }
};

export const useTabapayConfig = (cardType?: Card['type']) => {
  const { credit, debit, card } = useConfig().services.tabapay;
  switch (cardType) {
    case 'debit':
      return debit || card;
    case 'credit':
      return credit || card;
    default:
      return card;
  }
};
