import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const PaymentMethodsIcon = (props: IconProps) => (
  <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      d="M14 14C14 13.448 14.448 13 15 13C15.552 13 16 13.448 16 14C16 14.552 15.552 15 15 15C14.448 15 14 14.552 14 14Z"
      fill="#212124"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 7.95713V10.23C23.595 10.5783 24 11.2185 24 11.9581V15.972C24 16.7116 23.595 17.3518 23 17.7001V19.973C23 22.1937 21.206 24 19 24H4C1.794 24 0 22.1937 0 19.973V7.95713C0 6.25822 1.055 4.87107 2.536 4.28101L2.518 4.13952C2.38 3.11094 3.037 2.09271 4.045 1.85689L17.521 0.0415683C17.973 -0.0688161 18.553 0.0415682 19.016 0.361683C19.479 0.682801 19.788 1.18756 19.863 1.74751L20.181 4.12981C21.808 4.64058 23 6.15285 23 7.95713ZM4 21.993H19C20.103 21.993 21 21.0869 21 19.973V17.979H15C12.794 17.979 11 16.1788 11 13.9651C11 11.7513 12.794 9.95108 15 9.95108H21V7.95713C21 6.84325 20.103 6 19 6H4C2.897 6 2 6.84325 2 7.95713V19.973C2 21.0869 2.897 21.993 4 21.993ZM18.136 3.99301L17.881 2.01343L4.405 3.89166L4.429 3.99301H18.136ZM13 13.9651C13 15.0719 13.897 15.972 15 15.972H22V11.9581H15C13.897 11.9581 13 12.8582 13 13.9651Z"
      fill="#212124"
    />
  </Icon>
);
