import { useInvoice, useMelioIntl } from '@melio/ar-domain';
import { useToast } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { MarkInvoiceAsUnpaidScreen } from './screens';

export type MarkInvoiceAsUnpaidModalActivityProps = {
  onError?: ARErrorFunction;
  onClose: VoidFunction;
  onDone: VoidFunction;
  invoiceId?: string;
};

export const MarkInvoiceAsUnpaidModalActivity = forwardRef<MarkInvoiceAsUnpaidModalActivityProps>(
  ({ invoiceId, onClose, onError, onDone, ...props }, ref) => {
    const { data: invoice, isLoading, markInvoiceAsUnpaid, isMutating } = useInvoice({ id: invoiceId, onError });
    const { formatMessage } = useMelioIntl();
    const { toast } = useToast();

    const onMarkInvoiceAsUnpaid = () => {
      toast({
        type: 'informative',
        title: formatMessage('ar.dashboard.activities.invoiceTable.toasts.markAsUnpaid.processing.label'),
      });
      void markInvoiceAsUnpaid()
        .then(() => {
          onDone();
          toast({
            type: 'success',
            title: formatMessage('ar.dashboard.activities.markInvoiceAsUnpaid.toasts.success.label', {
              invoiceNumber: invoice?.invoiceNumber,
            }),
          });
        })
        .catch((err: ARPlatformError) => {
          toast({ type: 'error', title: err.message });
          onError?.(err);
        });
    };

    return (
      <MarkInvoiceAsUnpaidScreen
        onClose={onClose}
        onSubmit={onMarkInvoiceAsUnpaid}
        isOpen={!!invoiceId}
        isLoading={isLoading}
        isSaving={isMutating}
        ref={ref}
        {...props}
      />
    );
  }
);

MarkInvoiceAsUnpaidModalActivity.displayName = 'MarkInvoiceAsUnpaidModalActivity';
