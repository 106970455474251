import { useState } from 'react';
import { useBillSubscriptionActions } from '@melio/ap-domain/src/hooks/bill-subscription/useBillSubscriptionActions';
import { ActionsDropdownMenuItemProps } from '@melio/penny';
import { BillSubscription, Vendor } from '@melio/platform-api';

import { useDisclosure } from '@/hooks/useDisclosure';
import { usePlatformIntl } from '@/translations/Intl';

type ActionMenuItem = {
  label: string;
  onClick: () => void;
  variant?: ActionsDropdownMenuItemProps['variant'];
  dataTestId?: string;
};
export const useRecurringPaymentCardActions = ({
  billSubscription,
  vendor,
  onEditBillSubscription,
}: {
  billSubscription: BillSubscription;
  vendor: Vendor;
  onEditBillSubscription: ({ id }: { id: string }) => void;
}) => {
  const { formatMessage } = usePlatformIntl();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleEditBillSubscription = () => {
    onEditBillSubscription({ id: billSubscription.id });
  };

  const { isOpen: isCancelDialogOpen, onOpen: openCancelDialog, onClose: closeCancelDialog } = useDisclosure();

  const { isCancelBillSubscriptionAllowed, isEditBillSubscriptionAllowed } = useBillSubscriptionActions(
    billSubscription,
    vendor,
  );

  const actionMenuItems: ActionMenuItem[] = [];

  if (isEditBillSubscriptionAllowed)
    actionMenuItems.push({
      label: formatMessage('widgets.recurringPayments.button.edit'),
      onClick: handleEditBillSubscription,
      dataTestId: 'recurring-payment-card-edit-button',
    });

  if (isCancelBillSubscriptionAllowed)
    actionMenuItems.push({
      label: formatMessage('widgets.recurringPayments.button.cancel'),
      onClick: openCancelDialog,
      variant: 'critical' as ActionsDropdownMenuItemProps['variant'],
      dataTestId: 'recurring-payment-card-cancel-button',
    });

  return {
    actionMenuItems,
    isCancelDialogOpen,
    closeCancelDialog,
    isMenuOpen,
    setIsMenuOpen,
  };
};
