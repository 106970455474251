import { Skeleton } from '@chakra-ui/react';
import { Group, Text } from '@melio/penny';
import { Organization } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';

type Props = {
  isPaymentsOverviewLoading?: boolean;
  paymentsOverview?: Organization['billsAndPaymentsOverview'];
  isDisabled?: boolean;
};

export const PaymentsOverview = ({
  isPaymentsOverviewLoading = false,
  paymentsOverview,
  isDisabled = false,
}: Props) => {
  const { formatMessage } = useMelioIntl();
  const [isApprovalWorkflowsFeatureEnabled] = useFeature(FeatureFlags.ApprovalWorkflows, false, {
    shouldTrack: true,
  });

  const paymentsOverviewMapping = {
    bills: {
      count: paymentsOverview?.unpaid.count || 0,
      title: formatMessage('activities.accountants.firmDashboard.paymentsOverview.bills.label'),
    },
    ...(isApprovalWorkflowsFeatureEnabled
      ? {
          pending: {
            count: paymentsOverview?.pending.count || 0,
            title: formatMessage('activities.accountants.firmDashboard.paymentsOverview.pending.label'),
          },
        }
      : {}),
    scheduled: {
      count: paymentsOverview?.scheduled.count || 0,
      title: formatMessage('activities.accountants.firmDashboard.paymentsOverview.scheduled.label'),
    },
    failed: {
      count: paymentsOverview?.failed.count || 0,
      title: formatMessage('activities.accountants.firmDashboard.paymentsOverview.failed.label'),
    },
  };

  return (
    <Group variant="horizontal" width="full" justifyContent="space-between">
      {Object.entries(paymentsOverviewMapping).map(([paymentsOverviewType, paymentsOverviewData], i) => (
        <Group
          key={i}
          width={'70px' as never}
          variant="vertical"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing="xxxs"
          data-testid={`payment-overview-info-${paymentsOverviewType}`}
        >
          {isPaymentsOverviewLoading && (
            <>
              <Skeleton
                sx={{
                  height: '10px',
                  width: '30px',
                  marginBottom: '10px',
                }}
              />
              <Skeleton
                sx={{
                  height: '10px',
                  width: '10px',
                }}
              />
            </>
          )}
          {!isPaymentsOverviewLoading && paymentsOverview && (
            <>
              <Text textStyle="caption1" color={isDisabled ? 'global.neutral.400' : 'global.neutral.800'}>
                {paymentsOverviewData.title}
              </Text>
              <Text
                data-testid={`payment-overview-info-${paymentsOverviewType}-value`}
                textStyle="body3"
                color={
                  isDisabled
                    ? 'global.neutral.400'
                    : paymentsOverviewData.count
                    ? paymentsOverviewType === 'failed'
                      ? 'critical.main'
                      : 'neutral.black'
                    : 'global.neutral.800'
                }
              >
                {paymentsOverviewData.count}
              </Text>
            </>
          )}
        </Group>
      ))}
    </Group>
  );
};
