import { ARInvoice, useMelioIntl } from '@melio/ar-domain';
import { Icon, IconKey, Table } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { isDate } from 'lodash';

type Invoice = ARInvoice;

type FieldType<T, TType> = NonNullable<keyof Pick<T, { [K in keyof T]: T[K] extends TType ? K : never }[keyof T]>>;

export type DateCellProps = {
  row: Invoice;
  field: FieldType<Invoice, Date | null | undefined>;
  icon?: IconKey;
};

export const DateCell = forwardRef<DateCellProps>(({ row, field, icon, ...props }, ref) => {
  const { formatDate } = useMelioIntl();
  const date = row[field];
  return (
    <Table.Cell data-component={DateCell.displayName} data-testid={`date-field-${field}`} {...props} ref={ref}>
      {isDate(date) ? (
        <>
          {icon && <Icon color="brand" type={icon} size="small" />}
          {formatDate(date)}
        </>
      ) : (
        '-'
      )}
    </Table.Cell>
  );
});

DateCell.displayName = 'DateCell';
