import { ComponentThemeType } from '@/cl/theme/theme.hooks';

export const ConfirmationBarTheme: ComponentThemeType = (currentTheme) => ({
  baseStyle: {
    container: {
      width: '100%',
      flexDirection: { xs: 'column', s: 'row' } as never,
      alignItems: 'center',
      display: 'flex',
      gap: '16px',
      justifyContent: { xs: 'center', s: 'flex-start' } as never,
      paddingX: { xs: '16px', s: 0 } as never,
      paddingY: { xs: '16px', s: 0 } as never,
      height: { xs: '144px', s: 'fit-content' } as never,
      backgroundColor: currentTheme.colors.global.neutral['100'],
    },
  },
});
