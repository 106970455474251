import { Group } from '@melio/penny';
import React from 'react';

export type PlanSettingContainerProps = {
  children: React.ReactNode;
  dataTestId?: string;
};

export const PlanSettingCard = ({ children, dataTestId }: PlanSettingContainerProps) => (
  <Group variant="vertical" spacing="m" width="full" data-testid={dataTestId}>
    {children}
  </Group>
);
