import { InvoicePreferences, useMelioIntl } from '@melio/ar-domain';
import { Group } from '@melio/penny';
import { forwardRef, SettingsPageHeader } from '@melio/platform-utils';

import { InvoiceFormat, InvoiceNote, InvoicePaymentOptions } from '../components';

export type InvoiceSettingsScreenProps = {
  invoicePreferences: InvoicePreferences;
  onEditInvoiceFormat: VoidFunction;
  onEditInvoiceNote: VoidFunction;
  onRemoveInvoiceNote: VoidFunction;
  onEditCustomPaymentDetails: VoidFunction;
  onRemoveCustomPaymentDetails: VoidFunction;
  onToggleACHPaymentsAllowed: (enabled: boolean) => void;
  onToggleCardPaymentsAllowed: (enabled: boolean) => void;
  isUpdating: boolean;
};

export const InvoiceSettingsScreen = forwardRef<InvoiceSettingsScreenProps>(
  ({
    invoicePreferences,
    onEditInvoiceFormat,
    onRemoveInvoiceNote,
    onEditInvoiceNote,
    onEditCustomPaymentDetails,
    onRemoveCustomPaymentDetails,
    onToggleACHPaymentsAllowed,
    onToggleCardPaymentsAllowed,
    isUpdating,
  }) => {
    const { formatMessage } = useMelioIntl();

    return (
      <>
        <SettingsPageHeader
          title={formatMessage('ar.settings.flows.invoiceSettings.title')}
          subTitle={formatMessage('ar.settings.flows.invoiceSettings.description')}
        />
        <Group variant="vertical" spacing="l" hasDivider>
          <InvoiceFormat
            onEditInvoiceFormat={onEditInvoiceFormat}
            invoiceNumber={Number(invoicePreferences.invoiceNumber)}
            prefix={invoicePreferences.prefix}
          />
          <InvoiceNote
            onRemoveInvoiceNote={onRemoveInvoiceNote}
            onEditInvoiceNote={onEditInvoiceNote}
            note={invoicePreferences.noteOnInvoice}
          />
          <InvoicePaymentOptions
            onRemoveCustomPaymentDetails={onRemoveCustomPaymentDetails}
            onEditCustomPaymentDetails={onEditCustomPaymentDetails}
            customPaymentDetails={invoicePreferences.customPayInstructions}
            isACHPaymentAllowed={invoicePreferences.allowACHPayment}
            isCardPaymentAllowed={invoicePreferences.allowCardPayment}
            onToggleACHPaymentsAllowed={onToggleACHPaymentsAllowed}
            onToggleCardPaymentsAllowed={onToggleCardPaymentsAllowed}
            isUpdating={isUpdating}
          />
        </Group>
      </>
    );
  }
);
InvoiceSettingsScreen.displayName = 'InvoiceSettingsScreen';
