import { Modal } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { ComponentProps } from 'react';

import type { Collaborator } from '../../../types';
import { toFullName } from '../../utils/toFullName';
import { EditCollaboratorForm } from './forms/EditCollaboratorForm';

type EditFormProps = ComponentProps<typeof EditCollaboratorForm>;

type Props = {
  isOpen: boolean;
  onDone: EditFormProps['onSubmit'];
  onClose: (reason: 'exit') => void;
  isSaving?: boolean;
  collaborator?: Collaborator | null;
  hasApprovalWorkflow?: boolean;
} & Pick<EditFormProps, 'availableRoleOptions'>;

export const EditCollaboratorModalScreen = ({
  isOpen,
  onDone,
  onClose,
  isSaving,
  availableRoleOptions,
  collaborator,
  hasApprovalWorkflow = false,
  ...props
}: Props) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose('exit')}
      data-testid="manage-collaborators-activity-edit-collaborator-modal-screen"
      header={formatMessage('activities.manageCollaborators.screens.editCollaboratorModal.title', {
        fullName: toFullName(collaborator?.firstName, collaborator?.lastName),
      })}
      {...props}
    >
      <EditCollaboratorForm
        availableRoleOptions={availableRoleOptions}
        onSubmit={onDone}
        collaborator={collaborator}
        isSaving={isSaving}
        hasApprovalWorkflow={hasApprovalWorkflow}
        defaultValues={{
          email: collaborator?.email ?? undefined,
          firstName: collaborator?.firstName,
          lastName: collaborator?.lastName,
          roleUniqueName: collaborator?.roleUniqueName ?? undefined,
        }}
      />
    </Modal>
  );
};
