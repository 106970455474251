import { ApiError, useMelioQueryClient, useModelApi, UseModelApiProps } from '@melio/api-client';
import {
  ApiKeys,
  BillSubscription,
  BillSubscriptionsApiClient,
  BillSubscriptionsApiGetBillSubscriptionsSubscriptionIdRequest,
  PatchBillSubscriptionsRequest,
  PostBillSubscriptionCancelRequest,
  PostBillSubscriptionsRequest,
} from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useMutation } from 'react-query';

import {
  convertBillSubscriptionCentsToDollars,
  convertPatchBillSubscriptionDollarsToCents,
  convertPostBillSubscriptionDollarsToCents,
} from './utils';

type GetQuery = typeof BillSubscriptionsApiClient.getBillSubscriptionsSubscriptionId;
type PatchQuery = typeof BillSubscriptionsApiClient.patchBillSubscriptionsSubscriptionId;
type CreateQuery = typeof BillSubscriptionsApiClient.postBillBillBillBillSubscriptions;

type UseBillSubscriptionProps = UseModelApiProps<
  typeof BillSubscriptionsApiClient.getBillSubscriptionsSubscriptionId,
  BillSubscription
> & {
  params?: Pick<BillSubscriptionsApiGetBillSubscriptionsSubscriptionIdRequest, 'expand'>;
  onCreateError?: (error: ApiError<string>, payload: PostBillSubscriptionsRequest) => void;
  onUpdateError?: (error: ApiError<string>, payload: PatchBillSubscriptionsRequest) => void;
};

export const useBillSubscription = ({ params, onCreateError, onUpdateError, ...props }: UseBillSubscriptionProps) => {
  const queryClient = useMelioQueryClient();
  const query = useModelApi<GetQuery, PatchQuery, never, CreateQuery, BillSubscription>({
    ...props,
    queryKey: [ApiKeys.BillSubscriptionsApi, params],

    queryFn: (id) => BillSubscriptionsApiClient.getBillSubscriptionsSubscriptionId(id, params?.expand),
    select: convertBillSubscriptionCentsToDollars,

    createFn: (data) => BillSubscriptionsApiClient.postBillBillBillBillSubscriptions(data),
    prepareCreateParams: convertPostBillSubscriptionDollarsToCents,
    onCreateError,

    updateFn: (id, data) => BillSubscriptionsApiClient.patchBillSubscriptionsSubscriptionId(id, data),
    prepareUpdateParams: convertPatchBillSubscriptionDollarsToCents,
    onUpdate: () => {
      void queryClient.invalidateQueries(ApiKeys.VendorsApi);
    },
    onUpdateError,
  });

  const cancelBillSubscriptionMutation = useMutation(
    query.queryKey,
    (data?: PostBillSubscriptionCancelRequest) =>
      BillSubscriptionsApiClient.postBillSubscriptionBillSubscriptionIdCancel(props.id as string, data),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(ApiKeys.BillSubscriptionsApi);
        void queryClient.invalidateQueries(ApiKeys.PaymentsApi);
      },
    }
  );

  return {
    ...query,
    cancel: (data?: PostBillSubscriptionCancelRequest) => cancelBillSubscriptionMutation.mutateAsync(data),
    _mutations: {
      ...query._mutations,
      cancel: cancelBillSubscriptionMutation,
    },
  };
};

export type BillSubscriptionModel = ReturnType<typeof useBillSubscription>;
