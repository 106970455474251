import { useCollectionApi, UseCollectionApiProps } from '@melio/api-client';
import { BusinessesApiClient, extractData } from '@melio/platform-api-axios-client';

export type UseBusinessesProps = UseCollectionApiProps<typeof BusinessesApiClient.getBusinesses> & {
  params: { searchTerm: string };
};

export const useBusinesses = ({ params, ...props }: UseBusinessesProps) => {
  const query = useCollectionApi({
    ...props,
    enabled: props.enabled !== false && !!params.searchTerm,
    queryKey: ['BusinessesApi', params],
    queryFn: () => BusinessesApiClient.getBusinesses(params.searchTerm),
  });

  return {
    ...query,
    fetch: (params: UseBusinessesProps['params']) =>
      BusinessesApiClient.getBusinesses(params.searchTerm).then(extractData),
  };
};

export type BusinessesCollection = ReturnType<typeof useBusinesses>;
