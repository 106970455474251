import { Money } from '@melio/money';
import { Group, Text, TextProps } from '@melio/penny';

export const FeeItem = ({ label, amount, isBold, ...rest }: { label: string; amount: number; isBold?: boolean }) => {
  const textStyle: TextProps['textStyle'] = isBold ? 'body2Semi' : 'body2';
  const formattedAmount = Money.fromNaturalUnit(amount ?? 0, 'USD');

  return (
    <Group data-testid="payment-fee-item" justifyContent="space-between" spacing="xs" {...rest}>
      <Text textStyle={textStyle}>{label}</Text>
      <Text textStyle={textStyle} data-testid="payment-fee-item-value">
        {formattedAmount.toFormat({ style: 'currency' })}
      </Text>
    </Group>
  );
};
