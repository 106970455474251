import { Box } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { Icon, IconKey, NakedButton } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { DeliveryMethod } from '@melio/platform-api';

import { MethodCard } from '@/cl/components/MethodCard/MethodCard.component';
import { useRouter } from '@/hooks/router.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { ReceivingMethodWithActions } from '@/widgets/settings-page/ReceivingMethodsPage/ReceivingMethodWithActions.widget';

interface DeliveryMethodsListProps {
  deliveryMethods: DeliveryMethod[] | undefined;
}

export const DeliveryMethodsList = ({ deliveryMethods }: DeliveryMethodsListProps) => {
  const { formatMessage } = usePlatformIntl();
  const { track } = useAnalytics();
  const { goToAddReceivingMethod } = useRouter();

  const addNewReceivingMethods = () => {
    track('AddReceivingSource', 'Chose');
    goToAddReceivingMethod(true);
  };

  const actionElement = (
    <NakedButton
      variant="secondary"
      onClick={addNewReceivingMethods}
      data-testid="add-receiving-method"
      label={formatMessage('widgets.receivingMethodsSettings.addMethod')}
    />
  );

  return (
    <DeliveryMethodsContainer>
      {!isEmpty(deliveryMethods) &&
        deliveryMethods?.map((deliveryMethod: DeliveryMethod) => {
          return (
            <Box mt={5} pb={5} key={deliveryMethod.id}>
              <ReceivingMethodWithActions deliveryMethod={deliveryMethod} />
            </Box>
          );
        })}

      {isEmpty(deliveryMethods) && (
        <MethodCard
          isDeliveryMethodExists={false}
          actionElement={actionElement}
          icon={<Icon type={'bank' as IconKey} color="inherit" />}
          displayName={formatMessage('widgets.receivingMethodsSettings.bankAccount')}
          helperText={formatMessage('widgets.receivingMethodsSettings.emptyHelperText')}
        />
      )}
    </DeliveryMethodsContainer>
  );
};

const DeliveryMethodsContainer = styled.div`
  margin-top: 1.7rem;
`;
