import { FormDialog, FormInputs, FormWidgetProps, invoiceNumberPaddingLength, useMelioIntl } from '@melio/ar-domain';
import { Form, useMelioForm } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import * as yup from 'yup';

import { InvoiceFormatSummary } from '../components';

const maxPrefixLength = 6;

export type InvoiceFormatFormFields = {
  prefix: string;
  invoiceNumber: number;
};

const useValidationSchema = () => {
  const { formatMessage } = useMelioIntl();

  return yup.object().shape({
    prefix: yup
      .string()
      .max(
        maxPrefixLength,
        formatMessage(
          'ar.settings.flows.invoiceSettings.sections.invoiceFormat.modal.fields.prefix.validations.maxLength.text',
          { length: maxPrefixLength }
        )
      )
      .transform((value: string, originalValue: string) => (!originalValue ? '' : value.toUpperCase())),
    invoiceNumber: yup
      .number()
      .nullable()
      .required(
        formatMessage(
          'ar.settings.flows.invoiceSettings.sections.invoiceFormat.modal.fields.invoiceNumber.validations.required.text'
        )
      )
      .typeError(
        formatMessage(
          'ar.settings.flows.invoiceSettings.sections.invoiceFormat.modal.fields.invoiceNumber.validations.required.text'
        )
      )
      .test(
        'len',
        formatMessage(
          'ar.settings.flows.invoiceSettings.sections.invoiceFormat.modal.fields.invoiceNumber.validations.maxLength.text',
          { length: invoiceNumberPaddingLength }
        ),
        (val) => (val?.toString().length ?? 0) <= invoiceNumberPaddingLength
      ),
  }) as yup.SchemaOf<InvoiceFormatFormFields>;
};

export type EditInvoiceFormatDialogScreenProps = Pick<
  FormWidgetProps<InvoiceFormatFormFields>,
  'defaultValues' | 'isSaving' | 'onSubmit'
> & {
  onClose: VoidFunction;
  isOpen: boolean;
  isLoading: boolean;
};
export const EditInvoiceFormatDialogScreen = forwardRef<EditInvoiceFormatDialogScreenProps>(
  ({ isOpen, onSubmit, defaultValues, isSaving, isLoading, onClose, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const { registerField, watch, ...rest } = useMelioForm<InvoiceFormatFormFields>({
      schema: useValidationSchema(),
      isSaving,
      defaultValues,
      onSubmit,
      subscribeToDefaultValuesChanges: true,
    });

    const prefix = watch('prefix');

    return (
      <FormDialog
        closeButtonAriaLabel={formatMessage(
          'ar.settings.flows.invoiceSettings.sections.invoiceFormat.modal.closeButtonAriaLabel'
        )}
        data-component={EditInvoiceFormatDialogScreen.displayName}
        isOpen={isOpen}
        footer={
          <InvoiceFormatSummary prefix={prefix ? prefix.toUpperCase() : ''} invoiceNumber={watch('invoiceNumber')} />
        }
        size="large"
        columns={2}
        isLoading={isLoading}
        onClose={() => !isSaving && onClose()}
        description={formatMessage('ar.settings.flows.invoiceSettings.sections.invoiceFormat.modal.subtitle.text')}
        header={formatMessage('ar.settings.flows.invoiceSettings.sections.invoiceFormat.modal.title.text')}
        primaryButton={{
          label: formatMessage('ar.settings.flows.invoiceSettings.sections.invoiceFormat.modal.primaryButton.label'),
        }}
        secondaryButton={{
          label: formatMessage('ar.settings.flows.invoiceSettings.sections.invoiceFormat.modal.secondaryButton.label'),
        }}
        useFormResults={rest}
        {...props}
        ref={ref}
      >
        <Form.TextField
          labelProps={{
            label: formatMessage('ar.settings.flows.invoiceSettings.sections.invoiceFormat.modal.fields.prefix.label'),
          }}
          helperTextProps={{
            label: formatMessage(
              'ar.settings.flows.invoiceSettings.sections.invoiceFormat.modal.fields.prefix.validations.maxLength.text',
              { length: maxPrefixLength }
            ),
          }}
          {...registerField('prefix')}
        />
        <FormInputs.NumberField
          aria-label={formatMessage(
            'ar.settings.flows.invoiceSettings.sections.invoiceFormat.modal.fields.invoiceNumber.label'
          )}
          labelProps={{
            label: formatMessage(
              'ar.settings.flows.invoiceSettings.sections.invoiceFormat.modal.fields.invoiceNumber.label'
            ),
          }}
          helperTextProps={{
            label: formatMessage(
              'ar.settings.flows.invoiceSettings.sections.invoiceFormat.modal.fields.invoiceNumber.textHelper'
            ),
          }}
          {...registerField('invoiceNumber')}
        />
      </FormDialog>
    );
  }
);
EditInvoiceFormatDialogScreen.displayName = 'EditInvoiceFormatDialogScreen';
