import { useSearchParams } from 'react-router-dom';

export const useGetSearchParamsValues = (paramsName: string[]) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getParamsValues = () => {
    const paramsObject: Record<string, string> = {};
    paramsName.forEach((key) => {
      const searchParamValue = searchParams.get(key);
      if (searchParamValue) {
        paramsObject[key] = searchParamValue;
      }
    });
    return paramsObject;
  };

  const removeParamsValues = () => {
    setSearchParams({});
  };

  return { getParamsValues, removeParamsValues };
};
