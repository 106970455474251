import { Form, useMelioForm } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import { object, SchemaOf, string } from 'yup';

import { FormWidgetProps } from '../../types';

export type MemoDetails = {
  memo?: string;
  email?: string;
};

export type MemoValidation = {
  memoRegex: RegExp;
  memoErrorMsg: string;
};

const ALWAYS_TRUE = /.*/;

const useSchema = (memoValidation?: MemoValidation): SchemaOf<MemoDetails> => {
  const { memoRegex, memoErrorMsg } = memoValidation || {};
  const { formatMessage } = useMelioIntl();
  const {
    settings: {
      payment: {
        memoToVendor: { maxLength: memoToVendorMaxLength },
      },
    },
  } = useConfig();

  return object().shape({
    memo: string()
      .matches(memoRegex || ALWAYS_TRUE, memoErrorMsg || '')
      .max(
        memoToVendorMaxLength,
        formatMessage('widgets.memoToVendorForm.fields.memo.validation.maxLength', { num: memoToVendorMaxLength })
      ),
    email: string().email(formatMessage('widgets.memoToVendorForm.fields.email.validation.format')),
  });
};

export type MemoToVendorFormProps = FormWidgetProps<MemoDetails> & {
  vendorName: string;
  hideEmailField?: boolean;
  memoValidation?: MemoValidation;
};

export const MemoToVendorForm = forwardRef<MemoToVendorFormProps, 'form'>(
  (
    {
      defaultValues,
      isSaving,
      onSubmit,
      onSubmissionStateChange,
      vendorName,
      hideEmailField,
      memoValidation,
      ...props
    },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();

    const { formProps, registerField } = useMelioForm<MemoDetails>({
      onSubmit,
      schema: useSchema(memoValidation),
      isSaving,
      defaultValues,
      onSubmissionStateChange,
    });

    return (
      <Form data-component="MemoToVendorForm" {...props} {...formProps} ref={ref}>
        <Form.TextField
          labelProps={{ label: formatMessage('widgets.memoToVendorForm.fields.memo.label', { vendorName }) }}
          placeholder={formatMessage('widgets.memoToVendorForm.fields.memo.placeholder')}
          {...registerField('memo')}
          helperTextProps={{ label: formatMessage('widgets.memoToVendorForm.fields.memo.helperText') }}
          onFocus={(e) => e.target.select()}
        />

        {hideEmailField ? null : (
          <Form.TextField
            labelProps={{ label: formatMessage('widgets.memoToVendorForm.fields.email.label', { vendorName }) }}
            placeholder={formatMessage('widgets.memoToVendorForm.fields.email.placeholder', { vendorName })}
            helperTextProps={{ label: formatMessage('widgets.memoToVendorForm.fields.email.helperText') }}
            {...registerField('email')}
          />
        )}
      </Form>
    );
  }
);

MemoToVendorForm.displayName = 'MemoToVendorForm';
