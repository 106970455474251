import { Card } from '@braze/web-sdk';
import { useDateUtils } from '@melio/platform-utils';
import { useCallback, useContext } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { MessagerContext } from '../Provider';
import { DashboardContentCard } from './DashboardContentCard';
import { SplashBannerTopContentCard } from './SplashBannerTopContentCard';
import { BrazeContentCardType } from './types';

type BrazeContentCardProps = {
  cardType: BrazeContentCardType;
};

type DashboardContentCardType = Card & {
  imageUrl?: string;
  title: string;
  description: string;
  url: string;
  linkText: string;
  extras: {
    newTab?: 'true' | 'false';
    mode: 'light' | 'dark';
    isIcon?: 'true' | 'false';
  };
};

type SplashTopContentCardType = Card & {
  imageUrl?: string;
  description: string;
  url: string;
  linkText: string;
  extras: {
    newTab?: 'true' | 'false';
    mode: 'light' | 'dark';
  };
};

export const BrazeContentCard = ({ cardType }: BrazeContentCardProps) => (
  <ErrorBoundary FallbackComponent={() => null}>
    <InnerBrazeContentCard cardType={cardType} />
  </ErrorBoundary>
);

const InnerBrazeContentCard = ({ cardType }: BrazeContentCardProps) => {
  const {
    cards,
    logImpression: logCardImpression,
    logClick: logCardClick,
    logDismissal: logCardDismissal,
  } = useContext(MessagerContext);
  const { createDate } = useDateUtils();

  const isDashboardCard = (card: Card): card is DashboardContentCardType =>
    card.extras['type'] === BrazeContentCardType.DASHBOARD_CONTENT_CARD;

  const isSplashTopCard = (card: Card): card is SplashTopContentCardType =>
    card.extras['type'] === BrazeContentCardType.SPLASH_BANNER_TOP;

  const extractNewestCard = (cards: Card[]) => {
    if (!cards || cards.length === 0) {
      return null;
    }

    const contentCards = cards.filter((card) => card.extras['type'] === cardType);
    if (contentCards.length === 0) {
      return null;
    }
    return contentCards.reduce((newestCard: Card, currentCard) => {
      if (extractCardDate(currentCard) >= extractCardDate(newestCard)) {
        return currentCard;
      }
      return newestCard;
    });
  };

  const extractCardDate = (card: Card): Date =>
    card.updated ?? ('created' in card && card.created ? (card.created as Date) : createDate());

  const card = extractNewestCard(cards);

  const logImpression = useCallback(() => {
    if (card) {
      logCardImpression(card);
    }
  }, [card, logCardImpression]);

  const logClick = useCallback(() => {
    if (card) {
      logCardClick(card);
    }
  }, [card, logCardClick]);

  const logDismissal = useCallback(() => {
    if (card) {
      logCardDismissal(card);
    }
  }, [card, logCardDismissal]);

  if (!card) {
    return null;
  }

  switch (card.extras['type']) {
    case BrazeContentCardType.DASHBOARD_CONTENT_CARD:
      if (!isDashboardCard(card)) {
        return null;
      }

      return (
        <DashboardContentCard
          title={card.title}
          description={card.description}
          mode={card.extras.mode}
          url={card.url}
          imageUrl={card.imageUrl}
          urlText={card.linkText}
          newTab={card.extras.newTab}
          icon={card.extras.isIcon || 'false'}
          onCardView={logImpression}
          onClick={logClick}
          onClose={logDismissal}
        />
      );

    case BrazeContentCardType.SPLASH_BANNER_TOP:
      if (!isSplashTopCard(card)) {
        return null;
      }

      return (
        <SplashBannerTopContentCard
          description={card.description}
          mode={card.extras.mode}
          url={card.url}
          imageUrl={card.imageUrl}
          urlText={card.linkText}
          newTab={card.extras.newTab}
          onCardView={logImpression}
          onClick={logClick}
          onClose={logDismissal}
        />
      );
    default:
      return null;
  }
};
