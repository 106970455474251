import { InvoiceDiscount, InvoiceTaxRate, useCatalogItems, useInvoices } from '@melio/ar-domain';

import { ServiceChargeParams } from '../../../types';
import { useGetPayloadFromFormData } from './line-item-utils';
import { InvoiceFormValues } from './useInvoiceForm';
import { useUpdateCatalogItems } from './useUpdateCatalogItems';

type CreateInvoiceActionsProps = {
  discount?: InvoiceDiscount;
  taxRate?: InvoiceTaxRate;
  serviceCharges?: ServiceChargeParams[];
  onError?: ARErrorFunction;
};

export const useCreateInvoiceActions = ({
  discount,
  taxRate,
  serviceCharges = [],
  onError,
}: CreateInvoiceActionsProps) => {
  const { updateCatalogItems, isSaving: isSavingCatalogItems } = useUpdateCatalogItems();
  const { create, isMutating: isCreatingInvoice } = useInvoices({ enabled: false });
  const { data: catalogItems = [] } = useCatalogItems({ onError });

  const getPayloadFromFormData = useGetPayloadFromFormData();

  const getPayload = (data: InvoiceFormValues, isDraft: boolean) =>
    getPayloadFromFormData(data, catalogItems, isDraft, discount, taxRate, serviceCharges);

  async function createInvoice(formData: InvoiceFormValues, isDraft: boolean) {
    await updateCatalogItems(formData.lineItems);

    return create(getPayload(formData, isDraft));
  }

  return {
    createInvoice,
    isSaving: isCreatingInvoice || isSavingCatalogItems,
  };
};

// exporting for tests only
export const __useUpdateCatalogItems = useUpdateCatalogItems;
