import { usePaymentSchedulingPreference } from '@melio/ap-domain';
import { DeliveryMethod, DeliveryMethodType, Payment, PaymentStatusEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useDateUtils } from '@melio/platform-utils';
import { isSameDay } from 'date-fns';

export const useVendorReceivesTexts = ({
  estimatedDelivery,
  paymentStatus,
  processedTimeline,
  type,
}: {
  estimatedDelivery: Date;
  paymentStatus: PaymentStatusEnum | undefined;
  processedTimeline: Payment['processedTimeline'] | undefined | null;
  type: DeliveryMethod['type'];
}) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const { isByScheduleDate } = usePaymentSchedulingPreference();
  const { createDate } = useDateUtils();

  const getDeliveryDateLabel = (): string => {
    if (isByScheduleDate) {
      return formatMessage('widgets.paymentDetails.vendorReceives.deliveryDate.default.label');
    }

    switch (type) {
      case DeliveryMethodType.PaperCheck:
        return formatMessage('widgets.paymentDetails.vendorReceives.deliveryDate.paperCheck.label');
      case DeliveryMethodType.VirtualAccount:
        return formatMessage('widgets.paymentDetails.vendorReceives.deliveryDate.unilateral.label');
      default:
        return formatMessage('widgets.paymentDetails.vendorReceives.deliveryDate.default.label');
    }
  };

  const getDeliveryDateDescription = (): string => {
    const paymentStatusesWithDescription: PaymentStatusEnum[] = [
      PaymentStatusEnum.Blocked,
      PaymentStatusEnum.InProgress,
      PaymentStatusEnum.Scheduled,
    ];

    const { isLateDeposit } = processedTimeline || {};
    if (isLateDeposit) {
      return formatMessage('utils.deliveryMethod.bankAccount.etaHelperText');
    }

    if (paymentStatus && !paymentStatusesWithDescription.includes(paymentStatus)) {
      return '';
    }

    switch (type) {
      case DeliveryMethodType.PaperCheck: {
        if (isByScheduleDate) {
          return '';
        }

        return formatMessage('utils.deliveryMethod.paperCheck.helperText');
      }
      case DeliveryMethodType.VirtualAccount: {
        if (isByScheduleDate) {
          return formatMessage('utils.deliveryMethod.unilateral.etaHelperText');
        }

        const isDeductionToday = isSameDay(estimatedDelivery, createDate());
        const unilateralDescription = isDeductionToday
          ? formatMessage('utils.deliveryMethod.unilateral.schedulingExtension.helperText')
          : formatMessage(`utils.deliveryMethod.unilateral.estimatedDeliveryDate.helperText`, {
              date: formatDate(estimatedDelivery, { dateStyle: 'medium' }),
            });

        return unilateralDescription;
      }
      default:
        return '';
    }
  };

  return {
    label: getDeliveryDateLabel(),
    description: getDeliveryDateDescription(),
  };
};
