import { useCollectionApi, UseCollectionApiProps } from '@melio/api-client';
import { AllowedMccApiClient, AllowedMccApiGetAllowedMccRequest } from '@melio/platform-api-axios-client';

export type UseAllowedMccProps = UseCollectionApiProps<typeof AllowedMccApiClient.getAllowedMcc> & {
  params: AllowedMccApiGetAllowedMccRequest;
  onError?: () => void;
};

export const useAllowedMcc = ({ params, ...props }: UseAllowedMccProps) =>
  useCollectionApi({
    ...props,
    queryKey: ['AllowedMccApi', params.cardNetwork],
    queryFn: () => AllowedMccApiClient.getAllowedMcc(params.cardNetwork),
  });

export type AllowedMccCollection = ReturnType<typeof useAllowedMcc>;
