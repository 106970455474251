export type SubmitTarget = 'primary' | 'secondary' | 'tertiary';

export const TabsNames = {
  PDF: 'PDF',
  Email: 'Email',
} as const;

export type IssueInvoiceFormFields = {
  email?: string;
  emailMessage?: string;
  shouldSendEmail: SendEmailOptions;
  isAutoRemindersEnabled: boolean;
};

export const SendEmailOptions = { Yes: 'yes', No: 'no' } as const;
export type SendEmailOptions = (typeof SendEmailOptions)[keyof typeof SendEmailOptions];

export type TabsNames = keyof typeof TabsNames;
