import { FormattedMessage, useMelioIntl } from '@melio/ar-domain';
import { Button, Group, NakedButton, Text } from '@melio/penny';
import React from 'react';

export type InvoiceFormatProps = {
  note: string;
  onEditInvoiceNote: VoidFunction;
  onRemoveInvoiceNote: VoidFunction;
};

export const InvoiceNote = ({ note, onEditInvoiceNote, onRemoveInvoiceNote }: InvoiceFormatProps) => {
  const hasNote = !!note;

  return (
    <Group variant="vertical" spacing="l">
      <Group variant="vertical" spacing="xs">
        <Text textStyle="body1Semi" as="h3">
          <FormattedMessage id="ar.settings.flows.invoiceSettings.sections.invoiceNote.title.text" />
        </Text>
        <Text textStyle="body3">
          <FormattedMessage id="ar.settings.flows.invoiceSettings.sections.invoiceNote.subtitle.text" />
        </Text>
      </Group>
      {hasNote ? (
        <EditNote onRemoveInvoiceNote={onRemoveInvoiceNote} onEditInvoiceNote={onEditInvoiceNote} note={note} />
      ) : (
        <AddNoteButton onEditInvoiceNote={onEditInvoiceNote} />
      )}
    </Group>
  );
};

export const AddNoteButton = ({ onEditInvoiceNote }: Pick<InvoiceFormatProps, 'onEditInvoiceNote'>) => {
  const { formatMessage } = useMelioIntl();

  return (
    <NakedButton
      data-testid="add-invoice-note-button"
      variant="secondary"
      onClick={onEditInvoiceNote}
      label={formatMessage('ar.settings.flows.invoiceSettings.sections.invoiceNote.add.nakedButton.label')}
    />
  );
};

export const EditNote = ({ onEditInvoiceNote, onRemoveInvoiceNote, note }: InvoiceFormatProps) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Group variant="vertical" spacing="l">
      <Group variant="vertical" spacing="none">
        <Text textStyle="body4Semi" color="global.neutral.800">
          <FormattedMessage id="ar.settings.flows.invoiceSettings.sections.invoiceNote.editNote.title.text" />
        </Text>
        <Text data-testid="invoice-note-text-value" textStyle="body2">
          {note}
        </Text>
      </Group>
      <Group>
        <Button
          data-testid="remove-invoice-note-button"
          onClick={onRemoveInvoiceNote}
          variant="critical-secondary"
          label={formatMessage('ar.settings.flows.invoiceSettings.sections.invoiceNote.editNote.buttons.remove.text')}
        />
        <Button
          data-testid="edit-invoice-note-button"
          onClick={onEditInvoiceNote}
          variant="secondary"
          label={formatMessage('ar.settings.flows.invoiceSettings.sections.invoiceNote.editNote.buttons.edit.text')}
        />
      </Group>
    </Group>
  );
};
