/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, useDisclosure } from '@chakra-ui/react';
import { includes } from 'lodash';
import noop from 'lodash/noop';
import {
  MicroDepositModalRefHandlers,
  MicroDepositModalWrapper,
  ReconciliationModalActivity,
  useReconciliationModal,
} from '@melio/ap-activities';
import { EntitledFundingSourceModalActivity } from '@melio/ap-activities/src/components/EntitledFundingSourceModal/EntitledFundingSourceModalActivity';
import { useFundingSourceIconOrImage } from '@melio/ap-domain';
import {
  ActionsDropdownMenu,
  Group,
  IconButton,
  Link,
  Pill,
  StatusModal,
  Text,
  Tooltip,
  useBreakpoint,
} from '@melio/penny';
import { sanitizeId, useAnalytics, useAnalyticsContext } from '@melio/platform-analytics';
import { sanitizeStringForAnalytics } from '@melio/platform-analytics';
import {
  BillingFeeSetting,
  Card,
  FundingSource,
  FundingSourceType,
  useCollaborator,
  useCollaborators,
  useFundingSource,
  useIsPlaidLinkVerificationTokenFetching,
  useOrganizationPreference,
} from '@melio/platform-api';
import { PermissionLevelEnum } from '@melio/platform-api/src/entities/collaborators/useCollaborator';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { usePermissions } from '@melio/platform-permissions';
import { useConfig } from '@melio/platform-provider';
import { useSystemMessage } from '@melio/platform-utils';
import { useSubscription } from '@melio/subscriptions';

import { AsyncModalDialog } from '@/cl/components/AsyncModalDialog/AsyncModalDialog.component';
import { MethodCard } from '@/cl/components/MethodCard/MethodCard.component';
import { Tag } from '@/cl/components/Tag/Tag.component';
import { queryClient } from '@/queries/reactQueryClient';
import { usePlatformIntl } from '@/translations/Intl';
import {
  isFundingSourceBlocked,
  isFundingSourceExpiredCard,
  useFundingSourceActions,
  useGetFundingSourceCardHelperText,
} from '@/utils/FundingSources.utils';
import { Fee } from '@/widgets/Fee/Fee.widget';
import { PaymentSourceNicknameModal } from '@/widgets/settings-page/PaymentMethodsSettingsPage/PaymentMethodSetLabel.widget';

export const PaymentMethodWithActions = ({
  fundingSource,
  orgBillingFeeSettings,
  showAddedBy,
}: {
  fundingSource: FundingSource;
  orgBillingFeeSettings: BillingFeeSetting[];
  showAddedBy: boolean;
}) => {
  const [isPlatformUserManagementAdjustmentsFiservEnabled] = useFeature(
    FeatureFlags.PlatformUserManagementAdjustmentsFiserv,
    false,
  );
  const { isExtraSmallScreen } = useBreakpoint();
  const { can } = usePermissions();
  const subscription = useSubscription();
  const isSubscriptionFundingSource = subscription?.fundingSourceId === fundingSource.id;
  const canUpdateEntitlements = can({
    subject: 'collaborator:entitlements:fundingSource',
    action: 'update',
  });
  const { data: actor } = useCollaborator({ id: 'me', enabled: canUpdateEntitlements });
  const { data: collaborators, isLoading: isLoadingCollaborators } = useCollaborators({
    expand: canUpdateEntitlements ? ['user', 'fundingSourceEntitlements'] : ['user'],
    enabled: canUpdateEntitlements,
  });
  const shouldShowEntitledText = canUpdateEntitlements && isPlatformUserManagementAdjustmentsFiservEnabled;

  const usersWithFullPermission =
    collaborators?.filter((collaborator) => collaborator.permissionLevel === PermissionLevelEnum.Full) || [];

  const entitledFundingSource = usersWithFullPermission?.filter((collaborator) => {
    return includes(collaborator.fundingSourceEntitlements?.fundingSourceIds, fundingSource.id);
  });

  const entitlementCollaboratorsId = entitledFundingSource.map((collaborator) => collaborator.id);

  const getDescriptionForFundingSourceEntitlements = ({
    usersWithFullPermission,
    entitlementUsers,
  }: {
    usersWithFullPermission: number;
    entitlementUsers: number;
  }) => {
    if (shouldShowEntitledText) {
      if (entitlementUsers === 1) {
        return formatMessage('widgets.paymentMethods.card.entitlement.description.you.link');
      }
      if (entitlementUsers === usersWithFullPermission) {
        return formatMessage('widgets.paymentMethods.card.entitlement.description.allUsers.link');
      }
      return formatMessage('widgets.paymentMethods.card.entitlement.description.someUsers.link', {
        entitledUsers: entitlementUsers,
        usersWithFullPermission: usersWithFullPermission,
      });
    }
    return undefined;
  };

  const {
    isOpen: isEntitledFundingSourceModalOpen,
    onOpen: onEntitledFundingSourceModalOpen,
    onClose: onEntitledFundingSourceModalClose,
  } = useDisclosure();

  const onClick = (e?: React.MouseEvent) => {
    e?.preventDefault();
    e?.stopPropagation();

    track(`Settings`, 'Click', {
      Product: 'ap',
      PageName: 'payment-methods',
      Flow: 'manage-payment-methods-permission',
      Intent: 'manage-payment-methods-permission',
      Cta: descriptionForFundingSourceEntitlements,
      PaymentMethodType: fundingSource.type,
      CardType: fundingSource.type === FundingSourceType.Card ? (fundingSource.details as Card)?.type : undefined,
      CardIssuer: fundingSource.type === FundingSourceType.Card ? (fundingSource.details as Card)?.network : undefined,
      PaymentMethodId: fundingSource.id,
    });

    onEntitledFundingSourceModalOpen();
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { track } = useAnalytics();
  const { delete: deletePaymentMethod } = useFundingSource({ id: fundingSource.id, enabled: false });
  const { update: updateOrgPreference } = useOrganizationPreference();
  const { isOpen: isOpenDeleteDialog, onOpen: onOpenDeleteDialog, onClose: onCloseDeleteDialog } = useDisclosure();
  const {
    isOpen: isHasBillsDialogOpen,
    onOpen: onOpenHasBillsDialog,
    onClose: onCloseHasBillsDialog,
  } = useDisclosure();
  const {
    isOpen: isBillingFeePmDialogOpen,
    onOpen: onOpenIsBillingFeePmDialog,
    onClose: onCloseIsBillingFeePmDialog,
  } = useDisclosure();
  const { isOpen: isOpenNicknameModal, onOpen: onOpenNicknameModal, onClose: onCloseNicknameModal } = useDisclosure();
  const {
    isReconciliationEnabled,
    shouldShowReconciliationModal,
    onCloseReconciliationFlow,
    onDoneReconciliationFlow,
    runReconciliationFlow,
  } = useReconciliationModal();

  const microDepositRef = React.useRef<MicroDepositModalRefHandlers>(null);
  const { displayName, nickname, type } = fundingSource;
  const { formatMessage } = usePlatformIntl();
  const { showMessage } = useSystemMessage();
  const { fundingSourceId } = useParams();
  const { fundingSourcePolicy, entitledFundingSourceCard } = useConfig().settings;
  const [accountManagerAccessCollaborators] = useFeature<boolean>(
    FeatureFlags.AccountManagerAccessCollaborators,
    false,
  );

  const isPaymentMethodUsedForBillingFee =
    orgBillingFeeSettings.length > 0 && fundingSource.id === orgBillingFeeSettings[0].fundingSourceId;

  useAnalyticsContext({
    globalProperties: {
      fundingSource: type,
      cardType: type === FundingSourceType.Card ? (fundingSource.details as Card)?.type : undefined,
    },
  });

  const openMicroDepositsModal = React.useCallback(() => {
    track('PaymentMethod', 'Click', {
      PageName: 'payment-methods',
      Flow: 'dashboard',
      Intent: 'verify-payment-method',
      PaymentMethodId: fundingSourceId,
      PaymentMethodType: fundingSource?.type,
      Cta: sanitizeStringForAnalytics(
        formatMessage('widgets.paymentMethods.paymentMethodLineItem.bankAccount.unverified.link'),
      ),
    });
    microDepositRef.current?.openModal();
  }, [microDepositRef]);

  React.useEffect(() => {
    if (fundingSource.id === fundingSourceId && !fundingSource.isVerified) {
      openMicroDepositsModal();
    }
  }, [fundingSourceId, fundingSource]);

  const handleNicknameAction = () => {
    track('LabelFundingSource', 'Chose');
    onOpenNicknameModal();
  };

  const handleSetAsDefault = () => {
    track('SetAsDefaultFundingSource', 'Chose', { fundingSourceId: fundingSource.id });
    setFundingSourceAsDefault();
  };

  const handleDeleteAction = () => {
    track('DeleteFundingSource', 'Chose');
    isPaymentMethodUsedForBillingFee ? onOpenIsBillingFeePmDialog() : onOpenDeleteDialog();
  };

  const onEditSyncDetails = () => {
    track('EditFundingSourceSyncDetails', 'Chose', {
      IsAccountingSoftwareSync: true,
      PaymentMethodType:
        fundingSource?.type === 'card' ? (fundingSource.details as Card | null)?.type : fundingSource.type,
    });
    runReconciliationFlow();
  };

  const pmIsBillingFeeMethodTitle = formatMessage('widgets.paymentMethods.isBillingFeePaymentMethodDialog.title');
  const pmIsBillingFeeMethodBtnTxt = formatMessage('widgets.paymentMethods.isBillingFeePaymentMethodDialog.secondary');
  const pmIsBillingFeeMethodContent = formatMessage('widgets.paymentMethods.isBillingFeePaymentMethodDialog.content');

  const handleOnDelete = async () => {
    try {
      track('DeleteFundingSource', 'Submitted');
      await deletePaymentMethod();
      track('DeleteFundingSource', 'Succeeded');
      onCloseDeleteDialog();
      showMessage({
        type: 'informative',
        title: formatMessage('widgets.paymentMethods.deleteDialog.toast', { displayName }),
      });
    } catch (e) {
      onCloseDeleteDialog();
      onOpenHasBillsDialog();
    }
  };

  const setFundingSourceAsDefault = async () => {
    try {
      track('SetAsDefaultFundingSource', 'Submitted', { fundingSourceId: fundingSource.id });
      await updateOrgPreference({ key: 'defaultFundingSourceId', value: sanitizeId(fundingSource.id).toString() });
      track('SetAsDefaultFundingSource', 'Succeeded', { fundingSourceId: fundingSource.id });
      void queryClient.invalidateQueries('FundingSourcesApi');
    } catch (e) {
      showMessage({
        type: 'error',
        title: formatMessage('widgets.paymentMethods.accountActions.setAsDefault.error'),
      });
    }
  };

  const title = formatMessage('widgets.paymentMethods.deleteDialog.title', { displayName });
  const primaryBtnTxt = formatMessage('widgets.paymentMethods.deleteDialog.primary');
  const secondaryBtnTxt = formatMessage('widgets.paymentMethods.deleteDialog.secondary');
  const content = formatMessage('widgets.paymentMethods.deleteDialog.content');

  const deleteSubscriptionFundingSourceTitle = formatMessage(
    'widgets.paymentMethods.deleteSubscriptionFundingSource.title',
  );
  const deleteSubscriptionFundingSourceButtonLabel = formatMessage(
    'widgets.paymentMethods.deleteSubscriptionFundingSource.secondary',
  );
  const deleteSubscriptionFundingSourceContent = formatMessage(
    'widgets.paymentMethods.deleteSubscriptionFundingSource.content',
  );

  const hasBillsTitle = formatMessage('widgets.paymentMethods.hasBillsDialog.title');
  const hasBillsSecondaryBtnTxt = formatMessage('widgets.paymentMethods.hasBillsDialog.secondary');
  const hasBillsContent = formatMessage('widgets.paymentMethods.hasBillsDialog.content');
  const expiredTag = formatMessage('widgets.paymentMethods.expiredTag.text');

  const isExpired = isFundingSourceExpiredCard(fundingSource);
  const isBlocked = isFundingSourceBlocked(fundingSource);
  const [setAsDefaultPaymentMethodEnabled] = useFeature(FeatureFlags.SetAsDefaultPaymentMethodEnabled, false);

  // @ts-ignore - due to miss match between web-platform & platform-app entities although it's the same
  const isReconciliationEnabledForFundingSource = isReconciliationEnabled(fundingSource);
  const dataTestId = `settings-payment-method-${fundingSource.id}-menu`;

  const actions = useFundingSourceActions({
    fundingSource,
    onDelete: handleDeleteAction,
    onEditLabel: handleNicknameAction,
    onSetAsDefault: handleSetAsDefault,
    entitledFundingSourceCard,
    onAssignUsersToFundingSource: onClick,
    isReconciliationEnabledForFundingSource: isReconciliationEnabledForFundingSource,
    dataTestIdPrefix: dataTestId,
    onEditSyncDetails,
  });

  const icon = useFundingSourceIconOrImage(fundingSource);

  const descriptionForFundingSourceEntitlements = getDescriptionForFundingSourceEntitlements({
    usersWithFullPermission: usersWithFullPermission.length,
    entitlementUsers: entitledFundingSource.length,
  });

  const getDisplayName = () => {
    if (isExpired) return <Tag text={displayName} tagText={expiredTag} />;
    if (setAsDefaultPaymentMethodEnabled && fundingSource.isDefault)
      return (
        <Group variant="horizontal" alignItems="center" spacing="xxs">
          {displayName}
          <Pill
            data-testid={`default-funding-source-${fundingSource.id}`}
            status="neutral"
            label={formatMessage('widgets.paymentMethods.default')}
          />
        </Group>
      );
    return displayName;
  };

  const isPlaidLinkVerificationTokenFetching = useIsPlaidLinkVerificationTokenFetching(fundingSource.id);
  const { isVerifying: isVerifyingFundingSource } = useFundingSource({ id: fundingSource.id, enabled: false });

  const onReconciliationSuccess = (successMessage: string) => {
    showMessage({ type: 'success', title: successMessage });
    onDoneReconciliationFlow();
  };

  const entitlementDescription = descriptionForFundingSourceEntitlements
    ? formatMessage('widgets.paymentMethods.card.entitlement.description', {
        fundingSourceType: type,
        link: entitledFundingSourceCard ? (
          <>{descriptionForFundingSourceEntitlements}</>
        ) : (
          <Tooltip label={formatMessage('widgets.paymentMethods.card.entitlement.description.link.tooltip')}>
            <Link
              isBold
              variant="standalone"
              color="secondary"
              size="medium"
              href="#"
              data-testid={`entitlement-${fundingSource.id}-modal-link`}
              onClick={(e) => onClick(e)}
              label={descriptionForFundingSourceEntitlements}
            />
          </Tooltip>
        ),
      })
    : undefined;

  return (
    <Box>
      <MethodCard
        collaborators={collaborators || []}
        description={entitlementDescription}
        isDeliveryMethodExists={true}
        isDisabled={isExpired || isBlocked}
        key={fundingSource.id}
        fundingSourceId={fundingSource.id}
        actionElement={
          actions.length ? (
            <ActionsDropdownMenu
              size="small"
              title={isExtraSmallScreen ? formatMessage('widgets.paymentMethods.accountActions.title') : undefined}
              label={entitledFundingSourceCard ? undefined : 'menu action'}
              data-testid={entitledFundingSourceCard ? undefined : dataTestId}
              items={actions}
              isOpen={isMenuOpen}
              onOpenChange={setIsMenuOpen}
              trigger={
                entitledFundingSourceCard ? (
                  <IconButton
                    data-testid={dataTestId}
                    variant="naked"
                    icon="more-vertical"
                    aria-label={formatMessage('widgets.paymentMethods.options.ariaLabel', { displayName })}
                  />
                ) : undefined
              }
            />
          ) : undefined
        }
        nickname={nickname}
        icon={icon}
        displayName={getDisplayName()}
        helperText={useGetFundingSourceCardHelperText({
          fundingSource,
          fee: (
            <Fee
              type="fundingSource"
              source={fundingSource}
              suffix={formatMessage('widgets.paymentMethods.feeSuffix')}
            />
          ),
          onClick: openMicroDepositsModal,
          isVerifying: isPlaidLinkVerificationTokenFetching || isVerifyingFundingSource,
          showAddedBy:
            showAddedBy && fundingSourcePolicy?.fundingSourceCard?.showCreatedBy && accountManagerAccessCollaborators,
        })}
        type={fundingSource.type}
        testId={`funding-source-card-${fundingSource.id}`}
      />
      {actor && !isLoadingCollaborators && isEntitledFundingSourceModalOpen && (
        <EntitledFundingSourceModalActivity
          fundingSourceId={fundingSource.id}
          actor={actor}
          fundingSourceDisplayName={displayName}
          isOpen={isEntitledFundingSourceModalOpen}
          onClose={onEntitledFundingSourceModalClose}
          entitlementCollaboratorsId={entitlementCollaboratorsId}
        />
      )}
      {!isSubscriptionFundingSource && (
        <AsyncModalDialog
          body={content}
          isOpen={isOpenDeleteDialog}
          cancelText={secondaryBtnTxt}
          confirmText={primaryBtnTxt}
          onClose={onCloseDeleteDialog}
          onConfirm={handleOnDelete}
          title={title}
          type="cancel"
        />
      )}
      {isSubscriptionFundingSource && (
        <StatusModal
          isOpen={isOpenDeleteDialog}
          onClose={onCloseDeleteDialog}
          header={deleteSubscriptionFundingSourceTitle}
          variant="alert"
          secondaryButton={{
            label: deleteSubscriptionFundingSourceButtonLabel,
            onClick: onCloseDeleteDialog,
            variant: 'tertiary',
          }}
        >
          <Text>{deleteSubscriptionFundingSourceContent}</Text>
        </StatusModal>
      )}
      <StatusModal
        isOpen={isBillingFeePmDialogOpen}
        onClose={onCloseIsBillingFeePmDialog}
        header={pmIsBillingFeeMethodTitle}
        variant="cancel"
        secondaryButton={{
          label: pmIsBillingFeeMethodBtnTxt,
          onClick: onCloseIsBillingFeePmDialog,
          variant: 'tertiary',
        }}
      >
        <Text>{pmIsBillingFeeMethodContent}</Text>
      </StatusModal>
      <StatusModal
        isOpen={isHasBillsDialogOpen}
        onClose={onCloseHasBillsDialog}
        header={hasBillsTitle}
        variant="cancel"
        secondaryButton={{ label: hasBillsSecondaryBtnTxt, onClick: onCloseHasBillsDialog, variant: 'tertiary' }}
      >
        <Text>{hasBillsContent}</Text>
      </StatusModal>
      <PaymentSourceNicknameModal
        isOpen={isOpenNicknameModal}
        onClose={onCloseNicknameModal}
        fundingSource={fundingSource}
      />
      <MicroDepositModalWrapper ref={microDepositRef} fundingSourceId={fundingSource.id} />
      {isReconciliationEnabledForFundingSource && (
        <ReconciliationModalActivity
          isOpen={shouldShowReconciliationModal}
          onClose={onCloseReconciliationFlow(noop)}
          onDone={onReconciliationSuccess}
          selectedFundingSourceId={fundingSource.id}
        />
      )}
    </Box>
  );
};
PaymentMethodWithActions.displayName = 'PaymentMethodWithActions';
