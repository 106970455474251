/* eslint-disable react-hooks/exhaustive-deps */
import { KeyboardEventHandler, useCallback, useState } from 'react';

import { useAnalytics } from './useAnalytics';

type KeyDownEventHandler = KeyboardEventHandler<HTMLDivElement> & KeyboardEventHandler<HTMLSelectElement>;

const isAlphaNumeric = '^[A-Za-z0-9]$';
export const useTrackFirstKeyPress = (formName: string, action: string) => {
  const [isFirstKeyPress, setIsFirstKeyPress] = useState(true);

  const { track } = useAnalytics();

  const onFieldKeyDown: KeyDownEventHandler = useCallback(
    (event) => {
      if (isFirstKeyPress && event.key.match(isAlphaNumeric)) {
        setIsFirstKeyPress(false);
        track(formName, action);
      }
    },
    [isFirstKeyPress, track]
  );

  const onFieldFocus = useCallback(() => {
    setIsFirstKeyPress(true);
  }, []);

  return {
    onFieldKeyDown,
    onFieldFocus,
  };
};
