import { useGuestPayorPaymentRequestDetails } from '@melio/ar-domain';
import { SectionBannerProps } from '@melio/penny';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import React, { useCallback, useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { PaymentLayout } from '../layout';
import { GuestPayorFundingSourceTypes } from '../types';

type PaymentsLayoutWrapperProps = {
  paymentRequestLink: string;
  onViewInvoice: VoidFunction;
  goToPayByBank: (id: string) => void;
  goToBank: VoidFunction;
  goToCard: VoidFunction;
  goToCustom: VoidFunction;
  notificationInfo?: SectionBannerProps & { type: 'error' | 'invoice-updated' };
  isPaymentFormLoading: boolean;
  isPaymentProcessing: boolean;
};

export const PaymentsLayoutWrapper = withAnalyticsContext<PaymentsLayoutWrapperProps>(
  forwardRef(
    (
      {
        notificationInfo,
        setAnalyticsProperties,
        goToCard,
        goToBank,
        goToCustom,
        goToPayByBank,
        paymentRequestLink,
        onViewInvoice,
        isPaymentFormLoading,
        isPaymentProcessing,
      },
      ref
    ) => {
      const { data, isLoading } = useGuestPayorPaymentRequestDetails({ paymentRequestLink });
      const params = useParams();

      const [selectedFundingSource, setSelectedFundingSource] = useState<GuestPayorFundingSourceTypes>();
      const handleFundingSourceSelection = useCallback(
        (type?: GuestPayorFundingSourceTypes, fundingSourceId?: string) => {
          setSelectedFundingSource(type);
          if (type == 'bank-account') {
            fundingSourceId ? goToPayByBank(fundingSourceId) : goToBank();
          } else if (type == 'card') goToCard();
          else if (type == 'custom') goToCustom();
        },
        [] // eslint-disable-line react-hooks/exhaustive-deps
      );

      useEffect(() => {
        if (!data) return;

        if (data.invoice.paymentOptions.isAchAllowed && params['fundingSourceId'])
          handleFundingSourceSelection('bank-account', params['fundingSourceId']);
        else if (data.invoice.paymentOptions.isCardAllowed) handleFundingSourceSelection('card');
        else if (data.invoice.paymentOptions.isAchAllowed) handleFundingSourceSelection('bank-account');
        else handleFundingSourceSelection('custom');
      }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

      setAnalyticsProperties({
        ProductName: 'ar',
        Flow: 'payment-request',
        PaymentRequestLink: paymentRequestLink,
        AlertShown: notificationInfo?.type,
      });

      return (
        <PaymentLayout
          notificationProps={notificationInfo}
          onViewInvoice={onViewInvoice}
          paymentRequestDetails={data}
          isLoading={isLoading}
          isPaymentFormLoading={isPaymentFormLoading}
          isPaymentProcessing={isPaymentProcessing}
          onSelectFundingSource={handleFundingSourceSelection}
          selectedFundingSource={selectedFundingSource}
          ref={ref}
        >
          <Outlet />
        </PaymentLayout>
      );
    }
  )
);
PaymentsLayoutWrapper.displayName = 'PaymentsLayoutWrapper';

// create invoice without card and navigate to card -> it should re-navigate to the right place
// create outlet
