import { Container, Group } from '@melio/penny';
import { ApprovalDecision, PaymentFullyExpanded } from '@melio/platform-api';
import { RefObject } from 'react';

import { usePaymentDescription } from '../../../hooks/usePaymentDescription';
import { PaymentDescription } from '../PaymentDescription';
import { PaymentDetailsTop } from '../PaymentDetailsTop';
import { MarkedAsPaidSection } from '../sections/MarkedAsPaidSection';
import { PaidToSection } from '../sections/PaidToSection';
import { PayFromSection } from '../sections/PayFromSection';
import { PaymentApprovalDecisionSection } from '../sections/PaymentApprovalDecisionSection';
import { PaymentScheduledBySection } from '../sections/PaymentScheduledBySection';
import { SectionWrapper } from '../sections/SectionWrapper';
import { useSections } from '../sections/useSections';
import { VendorReceivesSection } from '../sections/VendorReceivesSection';

export const PaymentCompleted = ({
  payment,
  billDetailsRef,
  approvalDecisions,
}: {
  payment: PaymentFullyExpanded;
  billDetailsRef: RefObject<HTMLDivElement>;
  approvalDecisions?: ApprovalDecision[];
}) => {
  const { vendor, scheduledDate, markedAsPaid, createdBy, history } = payment;

  const { showScheduledBySection, showApprovalDecisionSection } = useSections({ payment, approvalDecisions });

  const description = usePaymentDescription(payment);
  return (
    <>
      {description ? <PaymentDescription description={description} /> : null}
      <Container data-testid="pay-dashboard-payment-completed">
        <Group variant="vertical" spacing="m">
          <SectionWrapper>
            <PaymentDetailsTop payment={payment} />
          </SectionWrapper>

          <SectionWrapper>
            <PaidToSection payment={payment} billDetailsRef={billDetailsRef} />
          </SectionWrapper>

          {showApprovalDecisionSection || showScheduledBySection ? (
            <SectionWrapper>
              <Group variant="vertical" hasDivider spacing="m">
                {showApprovalDecisionSection && approvalDecisions ? (
                  <PaymentApprovalDecisionSection approvalDecisions={approvalDecisions} />
                ) : null}

                {showScheduledBySection && createdBy ? (
                  <PaymentScheduledBySection scheduledDate={history.createdAt} user={createdBy} />
                ) : null}
              </Group>
            </SectionWrapper>
          ) : null}

          <SectionWrapper>
            {markedAsPaid ? (
              <MarkedAsPaidSection markedAsPayBy={createdBy} scheduledDate={scheduledDate} />
            ) : (
              <Group variant="vertical" spacing="m">
                <PayFromSection payment={payment} />
                <VendorReceivesSection vendorName={vendor.name} payment={payment} />
              </Group>
            )}
          </SectionWrapper>
        </Group>
      </Container>
    </>
  );
};
