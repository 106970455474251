import { useGuestPayorFundingSource, useGuestPayorPayment, useGuestPayorPaymentRequestDetails } from '@melio/ar-domain';
import { useAnalyticsView } from '@melio/platform-analytics';
import { convertDateToStringRepresentation, forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';

import { PayByBankAccountScreen } from './screens';

type PayByBankActivityProps = {
  onCreatePayment: (paymentId: string) => void;
  onError: ARErrorFunction;
  fundingSourceId: string;
  paymentRequestLink: string;
  goToBankRoute: VoidFunction;
  onFormLoading: (isLoading: boolean) => void;
  setIsPaymentProcessing: (isProcessing: boolean) => void;
};

export const PayByBankActivity = forwardRef<PayByBankActivityProps>(
  (
    {
      paymentRequestLink,
      onFormLoading,
      onCreatePayment,
      onError,
      fundingSourceId,
      goToBankRoute,
      setIsPaymentProcessing,
    },
    ref
  ) => {
    const { data: paymentRequestDetails, isLoading: isLoadingPaymentRequestLink } = useGuestPayorPaymentRequestDetails({
      paymentRequestLink,
    });
    const {
      data: fundingSource,
      isLoading: isLoadingFundingSource,
      isError,
    } = useGuestPayorFundingSource({ id: fundingSourceId });
    const { createPayment, isCreatingPayment } = useGuestPayorPayment({ enabled: false });

    useAnalyticsView('PaymentRequest', true, true, { Intent: 'pay-invoice' });

    const handleOnSubmit = async ({ scheduledDate }: { scheduledDate: Date }) => {
      try {
        setIsPaymentProcessing(true);
        const { paymentId } = await createPayment({
          fundingSourceId,
          paymentRequestLink,
          scheduledDate: convertDateToStringRepresentation(scheduledDate),
        });
        onCreatePayment(paymentId);
      } catch (error) {
        setIsPaymentProcessing(false);
        onError(error as ARPlatformError);
      }
    };

    const isSaving = isLoadingFundingSource || isLoadingPaymentRequestLink || isCreatingPayment;

    useEffect(() => {
      onFormLoading(isSaving);
    }, [isSaving]); // eslint-disable-line react-hooks/exhaustive-deps

    if (isError) {
      goToBankRoute();
    }

    return (
      <PayByBankAccountScreen
        onSubmit={handleOnSubmit}
        fundingSourceName={fundingSource?.displayName}
        logo={fundingSource?.logo}
        isSaving={isSaving}
        amount={paymentRequestDetails?.invoice.totalAmount || 0}
        ref={ref}
      />
    );
  }
);
PayByBankActivity.displayName = 'PayByBankActivity';
