import { PaymentIntent } from '@melio/platform-api';

export const getFinancingDetailsFromPaymentIntent = (paymentIntent: PaymentIntent) => {
  if (
    !!paymentIntent.financingProvider &&
    !!paymentIntent.financingEligibilityToken &&
    !!paymentIntent.financingNumberOfInstallments
  ) {
    return {
      provider: paymentIntent.financingProvider,
      eligibilityToken: paymentIntent.financingEligibilityToken,
      numberOfInstallments: paymentIntent.financingNumberOfInstallments,
    };
  }
  return undefined;
};
