import { useMelioIntl } from '@melio/platform-i18n';
import { SettingsPageHeader } from '@melio/platform-utils';

import { useAllowedActions } from '../useAllowedActions';

type Props = { onAddCollaboratorClick: VoidFunction };
export const Header = ({ onAddCollaboratorClick }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { canInviteCollaborator } = useAllowedActions();

  return (
    <SettingsPageHeader
      title={formatMessage('app.settings.companySection.cards.collaborators.title')}
      subTitle={formatMessage('app.settings.companySection.cards.collaborators.description')}
      primaryButton={
        canInviteCollaborator
          ? {
              dataTestId: 'add-collaborator-button',
              icon: 'add',
              label: formatMessage('activities.settings.collaborators.heading.button'),
              onClick: onAddCollaboratorClick,
            }
          : undefined
      }
    />
  );
};
