/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useCheckFeePromotions } from '@melio/ap-widgets';
import {
  BillingFeeSetting,
  FeesBreakdown,
  FeesBreakdownRequest,
  FeeType,
  hasAchToCheckBillingFeeMethod,
  hasAchToCheckFeeType,
  useFeesBreakdown,
  useFundingSources,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { sumBy } from '@melio/platform-utils';
import { min } from 'lodash';

import { SchedulePaymentIntent } from '../../../../types';

export const getBalanceSum = (paymentIntentsWithDerivatives: SchedulePaymentIntent[]): number =>
  sumBy(
    paymentIntentsWithDerivatives.flatMap(({ bills }) => bills),
    (bill) => bill.balance
  );

export const getFeesSum = (paymentIntentsWithDerivatives: SchedulePaymentIntent[]): number =>
  sumBy(paymentIntentsWithDerivatives, ({ paymentIntent }) =>
    paymentIntent.estimatedFees ? sumBy(paymentIntent.estimatedFees, (fee) => fee.amount) : 0
  );

export const useFreeChecks = (paymentIntentsWithDerivatives: SchedulePaymentIntent[]) => {
  const { freeChecks: freeChecksData, isLoading } = useCheckFeePromotions();

  const totalAchToCheckPayments = paymentIntentsWithDerivatives.filter(({ paymentIntent }) =>
    paymentIntent.estimatedFees?.find(({ type }) => type === FeeType.AchToCheck)
  )?.length;

  const freeChecksToApply = min([freeChecksData.available, totalAchToCheckPayments]);

  return {
    freeChecksToApply,
    isLoading,
  };
};

export const useAmountHeaderFees = (
  paymentIntentsWithDerivatives: SchedulePaymentIntent[]
): { feesBreakdown: FeesBreakdown | undefined; isLoading: boolean } => {
  const { data: fundingSources = [], isLoading: isLoadingFundingSources } = useFundingSources();

  const { data: feesBreakdown, isLoading: isLoadingFeesBreakdown } = useFeesBreakdown({
    params: {
      feesBreakdownParams: paymentIntentsWithDerivatives
        .map(({ paymentIntent, vendor }) => {
          const fundingSource = fundingSources.find((fs) => fs.id === paymentIntent.fundingSourceId);
          const deliveryMethod = vendor.deliveryMethods.find((dm) => dm.id === paymentIntent.deliveryMethodId);

          if (!deliveryMethod || !fundingSource || !paymentIntent.selectedDeliveryPreferenceType) {
            return null;
          }

          return {
            deliveryMethodType: deliveryMethod.type,
            deliveryPreference: paymentIntent.selectedDeliveryPreferenceType,
            fundingSourceType: fundingSource.type,
            paymentAmount: paymentIntent.amountToPay!,
            fundingSourceId: paymentIntent.fundingSourceId,
            deliveryMethodId: paymentIntent.deliveryMethodId,
            isFinancing: !!paymentIntent?.financingEligibilityToken,
          };
        })
        .filter(Boolean) as FeesBreakdownRequest['feesBreakdownParams'],
    },
    enabled: !!fundingSources.length,
  });

  return { feesBreakdown, isLoading: isLoadingFeesBreakdown || isLoadingFundingSources };
};

export const useGetTooltipText = (
  hasBillingFeeMethod: boolean | undefined,
  availableFreeChecks: number | undefined,
  freeCheckDiscount: number | undefined
) => {
  const { formatMessage, formatCurrency } = useMelioIntl();
  if (hasBillingFeeMethod && availableFreeChecks) {
    return formatMessage(
      'activities.batchPayments.screens.paymentIntentsTable.amountHeader.freeCheckBadge.tooltip.hasBillingFeeMethod',
      { available: availableFreeChecks, discount: freeCheckDiscount && formatCurrency(freeCheckDiscount) }
    );
  }

  if (hasBillingFeeMethod) {
    return formatMessage(
      'activities.batchPayments.screens.paymentIntentsTable.amountHeader.totalFees.tooltip.hasBillingFeeMethod'
    );
  }

  if (availableFreeChecks) {
    return formatMessage('activities.batchPayments.screens.paymentIntentsTable.amountHeader.freeCheckBadge.tooltip', {
      available: availableFreeChecks,
      discount: freeCheckDiscount && formatCurrency(freeCheckDiscount),
    });
  }

  return formatMessage('activities.batchPayments.screens.paymentIntentsTable.amountHeader.totalFees.tooltip');
};

export const useGetFeesTooltip = (
  availableFreeChecks: number | undefined,
  feesBreakdown: FeesBreakdown | undefined,
  orgBillingFeeSettings: BillingFeeSetting[]
) => {
  const freeCheckDiscount = feesBreakdown?.feesBreakdown.find((fee) => fee.type === FeeType.AchToCheck)?.discount;

  const orgHasBillingFeeMethod = hasAchToCheckBillingFeeMethod(orgBillingFeeSettings);
  const hasAchToCheckFees = hasAchToCheckFeeType(feesBreakdown?.feesBreakdown);

  const tooltip = useGetTooltipText(
    orgHasBillingFeeMethod && hasAchToCheckFees,
    availableFreeChecks,
    freeCheckDiscount
  );

  return tooltip;
};
