import { Vendor } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { usePermissions } from '@melio/platform-permissions';

export const useVendorNotPermitted = (vendor?: Vendor) => {
  const [isSbbExclusiveUserViewEnabled] = useFeature<boolean>(FeatureFlags.SbbExclusiveUserView, false);
  const { can } = usePermissions();

  if (!vendor) {
    return false;
  }

  const vendorSubjectData = { createdById: vendor.createdById, managedBy: vendor.managedBy };
  const canUpdateVendor = can({ subject: 'vendor', action: 'update', subjectData: vendorSubjectData });
  const canDeleteVendor = can({ subject: 'vendor', action: 'delete', subjectData: vendorSubjectData });

  return isSbbExclusiveUserViewEnabled && !canUpdateVendor && !canDeleteVendor;
};
