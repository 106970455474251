import { Group, Icon, Text, Tracker } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useDateUtils } from '@melio/platform-utils';

import { SubscriptionPreview } from '../../../../../api';
import { useFreeTrialPeriod } from '../../../../../hooks';

export type SubscriptionCheckoutFreeTrialTrackerProps = {
  startChargingDate: SubscriptionPreview['billingItems'][number]['serviceStartDate'];
  chargingAmount: SubscriptionPreview['billingItems'][number]['amountWithoutTax'];
};

type Step = {
  isActive?: boolean;
  dataTestid: string;
  title: string;
  price?: string;
  priceDescription?: string;
  description: string;
  iconType: 'favourite' | 'notification' | 'checked-mini';
};

export const SubscriptionCheckoutFreeTrialTracker = ({
  startChargingDate,
  chargingAmount,
}: SubscriptionCheckoutFreeTrialTrackerProps) => {
  const { formatMessage, formatDate, formatCurrency } = useMelioIntl();
  const { createDate } = useDateUtils();

  const { getReminderDateBeforeCharging } = useFreeTrialPeriod();

  const reminderDate = getReminderDateBeforeCharging(createDate(startChargingDate));

  const steps: Step[] = [
    {
      isActive: true,
      dataTestid: 'tracker-step-today',
      title: formatMessage('activities.subscription.checkout.summary.freeTrialTracker.todayStep.title'),
      price: formatMessage('activities.subscription.checkout.summary.freeTrialTracker.todayStep.price'),
      description: formatMessage('activities.subscription.checkout.summary.freeTrialTracker.todayStep.description'),
      iconType: 'favourite',
    },
    {
      dataTestid: 'tracker-step-reminder',
      title: formatDate(reminderDate, { dateStyle: 'medium' }),
      description: formatMessage('activities.subscription.checkout.summary.freeTrialTracker.reminderStep.description'),
      iconType: 'notification',
    },
    {
      isActive: false,
      dataTestid: 'tracker-step-charged',
      title: formatDate(startChargingDate, { dateStyle: 'medium' }),
      price: formatCurrency(chargingAmount),
      priceDescription: formatMessage(
        'activities.subscription.checkout.summary.freeTrialTracker.chargedStep.priceDescription'
      ),
      description: formatMessage('activities.subscription.checkout.summary.freeTrialTracker.chargedStep.description'),
      iconType: 'checked-mini',
    },
  ];

  return (
    <Tracker status="neutral" variant="vertical" width="full" data-testid="subscription-checkout-free-trial-tracker">
      {steps.map(({ iconType, title, price, priceDescription, description, isActive, dataTestid }) => {
        const titleColor = isActive ? 'global.neutral.1000' : 'global.neutral.900';

        return (
          <Tracker.Step
            key={title}
            isActive={isActive}
            data-testid={dataTestid}
            indicator={<Icon type={iconType} size="small" color={isActive ? 'inverted' : 'default'} />}
          >
            <Group justifyContent="space-between" width="full">
              <Text color={titleColor} textStyle="body3Semi">
                {title}
              </Text>
              {price && (
                <Text color={titleColor} textStyle="body3Semi">
                  {price}
                </Text>
              )}
            </Group>
            <Group justifyContent="space-between" width="full">
              <Text color="global.neutral.700" textStyle="body3">
                {description}
              </Text>
              {priceDescription && (
                <Text color="global.neutral.700" textStyle="body3" data-testid={`${dataTestid}-price-description`}>
                  {priceDescription}
                </Text>
              )}
            </Group>
          </Tracker.Step>
        );
      })}
    </Tracker>
  );
};
