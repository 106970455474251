import { Avatar, AvatarProps } from '@melio/penny';
import { useConfig } from '@melio/platform-provider';
import { useMemo } from 'react';

import { getAvatarBgColorCompatibleWithLegacy } from './utils';

type Props = Pick<AvatarProps, 'size' | 'name' | 'variant' | 'src' | 'isSelected' | 'isDisabled' | 'bgColor'>;

export const CompanyAvatar = ({ size = 'small', variant = 'square', bgColor, ...props }: Props) => {
  const {
    settings: { isMultiColoredAvatarsEnabled },
  } = useConfig();

  const calculatedBgColor = useMemo(
    () =>
      bgColor ??
      getAvatarBgColorCompatibleWithLegacy({
        name: props.name,
        isDefault: !isMultiColoredAvatarsEnabled,
      }),
    [props.name, isMultiColoredAvatarsEnabled, bgColor]
  );

  return <Avatar size={size} variant={variant} bgColor={calculatedBgColor} {...props} />;
};
