import { AgreementType, useAgreements } from '@melio/platform-api';
import { useMemo } from 'react';

import { OneTimeMessageModalActivity } from '../one-time-message-modal';

export type OneTimeMessageActivityProps = {
  userId: string;
};

export const OneTimeMessageActivity = ({ userId }: OneTimeMessageActivityProps) => {
  const { data: agreements } = useAgreements();

  const agreementId = useMemo(
    () => agreements?.find((agreement) => agreement.type === AgreementType.BillsSegregationMessage)?.id,
    [agreements]
  );

  return agreementId ? <OneTimeMessageModalActivity userId={userId} agreementId={agreementId} /> : null;
};

OneTimeMessageActivity.displayName = 'OneTimeMessageActivity';
