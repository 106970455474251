import { useCallback } from 'react';
import { EditPaymentActivity, PaymentFlowDoneAction } from '@melio/ap-activities';
import { useNavigate } from '@melio/platform-utils';

import { usePaymentFlowIntuitConnect } from '@/hooks/accountingPlatforms.hooks';
import { useRouter } from '@/hooks/router.hooks';
import { useIsNewDashboardEnabled } from '@/hooks/useIsNewDashboardEnabled';
import { PayDashboardTabs } from '@/types/payDashboard.types';

export const EditPaymentScreen = ({ paymentId, returnUrl }: { paymentId: string; returnUrl?: string }) => {
  const { replaceToPayDashboardTab, generateNPEDashboardLink } = useRouter();
  const navigate = useNavigate();
  const { loginToAccountPlatformAuth, isLoginToAccountPlatformAuthLoading } = usePaymentFlowIntuitConnect({
    redirectMethod: 'replace',
  });
  const shouldRedirectToNewDashboard = useIsNewDashboardEnabled();

  const handleClose = useCallback(() => {
    if (returnUrl) {
      return navigate(returnUrl);
    }
    replaceToPayDashboardTab(PayDashboardTabs.Scheduled, paymentId);
  }, [paymentId, replaceToPayDashboardTab, navigate, returnUrl]);

  const handleDone = (paymentId: string, action: PaymentFlowDoneAction) => {
    if (action === 'syncQBOAccountingPlatform') {
      return loginToAccountPlatformAuth();
    }
    if (shouldRedirectToNewDashboard) {
      return navigate(generateNPEDashboardLink('payments'));
    }

    replaceToPayDashboardTab(PayDashboardTabs.Scheduled, paymentId);
  };

  return (
    <EditPaymentActivity
      paymentId={paymentId}
      onClose={handleClose}
      onDone={handleDone}
      isLoading={isLoginToAccountPlatformAuthLoading}
    />
  );
};
