import capitalize from 'lodash/capitalize';
import { mixed, object, SchemaOf, string } from 'yup';
import { MIN_PHONE_LENGTH } from '@melio/ap-domain/src/hooks/mtl/useMtlSchemaValidation';
import { SelectNewOption } from '@melio/penny';
import { FormatMessage } from '@melio/platform-i18n';

type LocalMessageKey =
  | 'button.submit'
  | 'cta'
  | 'error'
  | 'intent.label'
  | 'intent.placeholder'
  | 'intent.required'
  | 'issueType.label'
  | 'issueType.option'
  | 'issueType.placeholder'
  | 'issueType.required'
  | 'paymentId.format'
  | 'paymentId.label'
  | 'paymentId.required'
  | 'phoneNumber.format'
  | 'phoneNumber.label'
  | 'phoneNumber.required'
  | 'success'
  | 'title';

export enum IssueType {
  Payment = 'payment',
  Account = 'account',
  General = 'general',
}

export type CallbackFormFields = {
  phoneNumber: string;
  issueType: IssueType;
  paymentId?: string;
  intent: string;
};

type FormatLocalMessage = (key: LocalMessageKey, values?: Record<string, unknown>) => string;

export const bindLocalMessageKey =
  (formatMessage: FormatMessage): FormatLocalMessage =>
  (key: LocalMessageKey, values?: Record<string, unknown>) =>
    formatMessage(`app.settings.SupportSection.contact.requestCallback.${key}`, values);

const isaPositiveNumber = (value: string | undefined) => {
  const numberValue = value && !value.startsWith('0') ? Number(value) : NaN;
  return isNaN(numberValue) ? false : numberValue > 0;
};

const getSchema = (formatMessage: FormatLocalMessage): SchemaOf<CallbackFormFields> =>
  object().shape({
    phoneNumber: string()
      .transform((value) => (value.length === 0 ? undefined : value))
      .min(MIN_PHONE_LENGTH, formatMessage('phoneNumber.format'))
      .required(formatMessage('phoneNumber.required')),
    issueType: mixed<IssueType>()
      .oneOf(Object.values(IssueType))
      .nullable()
      .required(formatMessage('issueType.required')),
    paymentId: string().when('issueType', {
      is: IssueType.Payment,
      then: string()
        .required(formatMessage('paymentId.required'))
        .test('isaPositiveNumber', formatMessage('paymentId.format'), isaPositiveNumber),
      otherwise: string().strip(),
    }),
    intent: string().required(formatMessage('intent.required')),
  });

const getIssueTypeFieldOptions = (formatMessage: FormatLocalMessage): SelectNewOption<IssueType>[] =>
  Object.values(IssueType).map((issueType) => ({
    value: issueType,
    label: formatMessage(`issueType.option`, {
      issueType: capitalize(issueType),
    }),
  }));

const formatIssueTypeForAnalytics = (issueType: IssueType) => {
  if (issueType === null) {
    return 'clear-issue-type';
  }

  return `${issueType}-issue`;
};

const getFormLabels = (formatMessage: FormatLocalMessage) => ({
  phoneNumber: {
    label: formatMessage('phoneNumber.label'),
  },
  issueType: {
    label: formatMessage('issueType.label'),
    placeholder: formatMessage('issueType.placeholder'),
  },
  paymentId: {
    label: formatMessage('paymentId.label'),
  },
  intent: {
    label: formatMessage('intent.label'),
    placeholder: formatMessage('intent.placeholder'),
  },
  submit: {
    label: formatMessage('button.submit'),
  },
});

export const getFormData = (formatMessage: FormatLocalMessage) => {
  const formSchema = getSchema(formatMessage);
  const issueTypeOptions = getIssueTypeFieldOptions(formatMessage);

  return { formSchema, issueTypeOptions, formatIssueTypeForAnalytics, formLabels: getFormLabels(formatMessage) };
};
