import { useState } from 'react';

import { BatchPaymentsActivityProps, BatchPaymentsActivityStep } from './types';

type Step = {
  step: BatchPaymentsActivityStep;
  state?: {
    [key: string]: unknown;
  };
};
export const useBatchPaymentsActivityStep = ({
  onFirstStepBack,
}: {
  onFirstStepBack: BatchPaymentsActivityProps['onBack'];
}) => {
  const [currentStep, _goToStep] = useState<Step>({
    step: 'PAYMENT_INTENTS_TABLE',
    state: {
      keepToast: false,
    },
  });

  const goToPreviousStep = () => {
    if (currentStep.step === 'PAYMENT_INTENTS_TABLE' || currentStep.step === 'FIRST_CREATE_FUNDING_SOURCE') {
      onFirstStepBack();
    } else if (currentStep.step !== 'PAYMENT_SCHEDULED') {
      goToStep('PAYMENT_INTENTS_TABLE');
    }
  };

  function goToStep(step: BatchPaymentsActivityStep, state?: { [key: string]: unknown }) {
    _goToStep({ step, state });
  }

  return { currentStep, goToStep, goToPreviousStep };
};
