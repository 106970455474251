import { Group, Icon, Link, Text, Typography } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useNavigate } from 'react-router-dom';

import { getFinancedPaymentCancelDate } from '../../../../../../../utils/pay-flow/Financing.utils';

export const LoanHeader = withAnalyticsContext(({ payment }: { payment: Payment }) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const navigate = useNavigate();
  const loanHref = `../../financing/${payment.id}`;
  const { track } = useAnalytics();

  return (
    <Group width="full">
      <Group.Item alignSelf="flex-start">
        <Icon size="large" type="installments" />
      </Group.Item>
      <Group.Item grow={1}>
        <Group variant="vertical" spacing="xxs" data-testid="repayment-section-num-of-installments">
          <Group spacing="xxs">
            <Text>
              {formatMessage('widgets.paymentDetails.payFrom.loan.type', {
                numOfInstallments: payment.loan?.installments.length,
              })}
            </Text>
            <Link
              label={formatMessage('widgets.paymentDetails.payFrom.loan.viewDetails')}
              data-testid="loan-section-view-details-btn"
              href={loanHref}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                track('Payment', 'Click', {
                  Intent: 'payment-details',
                  Cta: 'view-financing-details',
                });
                navigate(loanHref);
              }}
            />
          </Group>
          {payment.loan?.status === 'canceled' ? (
            <Typography.Description
              isInvalid
              label={formatMessage('widgets.paymentDetails.payFrom.loan.canceled', {
                date: formatDate(getFinancedPaymentCancelDate(payment), { dateStyle: 'medium' }),
              })}
            />
          ) : (
            <Typography.Description label={formatMessage('widgets.paymentDetails.payFrom.loan.provider')} />
          )}
        </Group>
      </Group.Item>
    </Group>
  );
});
