import { ScannedInvoice, ScannedInvoiceLineItem, ScannedInvoicesApiClient } from '@melio/platform-api-axios-client';
import { convertCentsToDollars } from '@melio/platform-utils';

export const postScannedInvoicesBulkCreate = async (files: File[]) => {
  const { data } = await ScannedInvoicesApiClient.postScannedInvoices(files);

  return data;
};

export const convertScannedInvoicesCentsToDollars = (data: ScannedInvoice[] = []) =>
  data.map(convertScannedInvoiceCentsToDollars);

export const convertScannedInvoiceCentsToDollars = (data: ScannedInvoice) =>
  data.amount ? convertCentsToDollars(data, ['amount']) : data;

export const convertScannedInvoiceLineItemsCentsToDollars = (data: ScannedInvoiceLineItem) =>
  data.amount ? convertCentsToDollars(data, ['amount']) : data;

export const isAcceptedScannedInvoiceFileType = (file: { name: string; type: string }) => {
  const acceptedMimeTypes = ['application/pdf', 'image/jpeg', 'image/png'];

  return acceptedMimeTypes.includes(file.type);
};
