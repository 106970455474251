import React from 'react';
import { useRecoilValue } from 'recoil';

import { GroupItemTheme } from '@/cl/components/PayCard/PayCard.component.theme';
import { ScannedInvoicePayCard } from '@/cl/components/ScannedInvoicePayCard/ScannedInvoicePayCard.component';
import { useAppTheme } from '@/cl/theme/theme.hooks';
import { payDashboardQuerySearchSelector } from '@/store/PayDashboard/PayDashboards.model';
import { GroupItemType } from '@/types/payDashboard.types';
import { BaseScannedInvoiceCardProps } from '@/widgets/pay-dashboard/cards/types/PaymentCard.types';

export const ScannedInvoiceCard = ({ scannedInvoice, vendorName, invoiceNumber }: BaseScannedInvoiceCardProps) => {
  const theme = useAppTheme(GroupItemTheme, { itemType: GroupItemType.SCANNED_INVOICE });
  const highlightedText = useRecoilValue(payDashboardQuerySearchSelector);
  const { dueDate, amount } = scannedInvoice;

  return (
    <ScannedInvoicePayCard
      theme={theme}
      vendorName={vendorName}
      amount={amount}
      dueDate={dueDate ? new Date(dueDate) : undefined}
      invoiceNumber={invoiceNumber}
      highlightedText={highlightedText}
    />
  );
};
ScannedInvoiceCard.displayName = 'ScannedInvoicePayCard';
