import { Container, Group, Illustration } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

type EmptyStateProps = {
  illustrationType: string;
  content: React.ReactNode;
  footer: React.ReactNode;
};

export const EmptyState = forwardRef<EmptyStateProps>(({ illustrationType, content, footer, ...props }, ref) => (
  <Container data-component={EmptyState.displayName} data-testid="empty-state" paddingY="xxxl" {...props} ref={ref}>
    <Group spacing="m" alignItems="center" justifyContent="center" variant="vertical">
      <Group spacing="s" alignItems="center" justifyContent="center" variant="vertical">
        <Illustration type={illustrationType} size="small" />
        {content}
      </Group>
      {footer}
    </Group>
  </Container>
));

EmptyState.displayName = 'EmptyState';
