import { useState } from 'react';
import { AxiosError } from 'axios';
import { useFundingSources } from '@melio/platform-api';
import { PostRefreshParams, useSyncRefresh } from '@melio/platform-api';
export const useSyncOnDemand = () => {
  const { data: fundingSources, isFetching: isFundingSourcesLoading } = useFundingSources();
  const { mutateAsync, isLoading: isSyncLoading, isError, error } = useSyncRefresh();
  const [isSyncDone, setIsSyncDone] = useState(false);

  if (isError) {
    console.log(`failed to run immediate sync ${(error as AxiosError).message}`);
  }

  const triggerSync = async () => {
    if (!isFundingSourcesLoading && !isSyncLoading && fundingSources?.length) {
      const requestParams: PostRefreshParams = {
        data: {
          fundingSourceIds: fundingSources.map((fundingSource) => fundingSource.id),
        },
      };
      await mutateAsync(requestParams);
      setIsSyncDone(true);
    }
  };

  return { isLoading: isSyncLoading, triggerSync, isError, isSyncDone };
};
