import { Card, FundingSource, useFeeCatalog } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

type GetStringByType = (fundingType: FundingSource['type'], cardType?: Card['type']) => string;

export const useFundingSourceFeeValueFormatting = () => {
  const { formatCurrency, formatPercents } = useMelioIntl();

  const { data: feeCatalogEntries, isLoading } = useFeeCatalog();

  const formatFeeValue: GetStringByType = (fundingType, cardType) => {
    if (isLoading) {
      return '';
    }
    let feeCatalogEntry;
    if (fundingType === 'bank-account') {
      feeCatalogEntry = feeCatalogEntries?.find((entry) => entry.feeType === 'ach');
    } else if (cardType == 'debit') {
      feeCatalogEntry = feeCatalogEntries?.find((entry) => entry.feeType === 'debit');
    } else {
      feeCatalogEntry = feeCatalogEntries?.find((entry) => entry.feeType === 'credit');
    }

    if (feeCatalogEntry) {
      return feeCatalogEntry.valueType === 'fixed'
        ? formatCurrency(feeCatalogEntry.value)
        : formatPercents(feeCatalogEntry.value, { divide: true });
    } else {
      return '';
    }
  };

  return { formatFeeValue };
};
