import { BankIntegrationSelection } from '@melio/ap-widgets';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { AccountIntegrationType, useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';

type BankIntegrationSelectionScreenProps = {
  isLoading?: boolean;
  onBack: VoidFunction;
  onClose?: VoidFunction;
  onDone: (type: AccountIntegrationType) => void;
};

export const BankIntegrationSelectionScreen = forwardRef<BankIntegrationSelectionScreenProps, 'div'>(
  ({ isLoading, onBack, onClose, onDone, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    useAnalyticsView('BankFundingVerificationType', !isLoading);
    const { track } = useAnalytics();

    return (
      <NewSinglePaymentStepLayout
        data-component="AddBankAccountActivity.BankIntegrationSelectionScreen"
        data-testid="add-bank-account-activity-bank-integration-selection-screen"
        {...props}
        isLoading={isLoading}
        ref={ref}
        headerContent={
          <NewSinglePaymentStepLayout.Header>
            <NewSinglePaymentStepLayout.CloseButton onClick={onClose} />
          </NewSinglePaymentStepLayout.Header>
        }
      >
        <NewSinglePaymentStepLayout.Title>
          {formatMessage('activities.addBankAccount.screens.bankIntegrationSelection.title')}
        </NewSinglePaymentStepLayout.Title>
        <NewSinglePaymentStepLayout.Description>
          {formatMessage('activities.addBankAccount.screens.bankIntegrationSelection.description')}
        </NewSinglePaymentStepLayout.Description>
        <NewSinglePaymentStepLayout.Content>
          <BankIntegrationSelection
            onSelect={(type) => {
              track('BankFundingVerification', 'Chose', {
                VerificationType: type == 'plaid' ? 'Plaid' : 'MicroDeposit',
              });
              onDone(type);
            }}
          />
        </NewSinglePaymentStepLayout.Content>
      </NewSinglePaymentStepLayout>
    );
  }
);

BankIntegrationSelectionScreen.displayName = 'AddBankAccountActivity.BankIntegrationSelectionScreen';
