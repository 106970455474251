import { CustomersDashboardSortByEnum, CustomersDashboardSortOrderEnum, useMelioIntl } from '@melio/ar-domain';

import { SortField, SortMenuItem } from '../types';

export const useCustomersTableSortableColumns = (): Record<SortField, SortMenuItem> => {
  const { formatMessage } = useMelioIntl();

  return {
    [CustomersDashboardSortByEnum.CompanyName]: {
      label: formatMessage('ar.dashboard.activities.customersTable.columns.customer.label'),
      sortBy: CustomersDashboardSortByEnum.CompanyName,
      defaultSortOrder: CustomersDashboardSortOrderEnum.Asc,
    },
  };
};
