import { useTbt } from '@melio/form-controls';
import { Container, Group, LoadingContainer, NakedButton, Text, useToast } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { CardParams, FundingSource, FundingSourceType, useFundingSources } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { Logger } from '@melio/platform-logger';
import { groupBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { createSearchParams, useLocation } from 'react-router-dom';

import { Plan, SubscriptionBillingCycleEnum } from '../../../../../../api';
import { FundingSourceCard, FundingSourceGroupList } from '../../../../../components';
import { useSubscriptionRouter } from '../../../../../utils/useSubscriptionRouter';
import { CardFundingSourceFormFields } from '../../CardFundingSourceForm/types';
import { AddNewFundingSourceView } from './AddNewFundingSourceView';

export type SubscriptionCheckoutFundingSourceSelectionProps = {
  fundingSourceId: string | null;
  skipSelectedView?: boolean;
  selectedPlan?: Plan;
  selectedBillingCycle?: SubscriptionBillingCycleEnum;
  setFundingSourceId: (id: string) => void;
  onDoneAddingCreditCard?: () => void;
};

type PaymentMethodView = 'selected' | 'all' | 'add-new';

export const SubscriptionPaymentMethod = withAnalyticsContext<SubscriptionCheckoutFundingSourceSelectionProps>(
  ({
    fundingSourceId,
    skipSelectedView,
    selectedPlan,
    selectedBillingCycle,
    setFundingSourceId,
    onDoneAddingCreditCard,
    setAnalyticsProperties,
  }) => {
    const { track, trackMarketing } = useAnalytics();
    const { goToAddBankAccount } = useSubscriptionRouter();
    const { formatMessage } = useMelioIntl();
    const { toast } = useToast();
    const { bt, tokenize } = useTbt();
    const location = useLocation();

    const [fundingSourceType, setFundingSourceType] = useState<FundingSourceType>(FundingSourceType.Card);
    const [view, setView] = useState<PaymentMethodView>('add-new');

    setAnalyticsProperties({
      PageName: 'checkout',
      Flow: 'subscription',
    });

    const {
      data: fundingSources,
      isFetching: isFetchingFundingSources,
      verifyCard,
      create,
      refetch,
    } = useFundingSources();

    const defaultView = skipSelectedView ? 'all' : 'selected';

    const handleSelectFundingSource = useCallback(
      (fundingSource: FundingSource) => {
        setFundingSourceId(fundingSource.id);
        setView(defaultView);
      },
      [defaultView, setFundingSourceId, setView]
    );

    const fundingSource = useMemo(
      () => fundingSources?.find((x) => x.id === fundingSourceId) || fundingSources?.[0],
      [fundingSourceId, fundingSources]
    );

    useEffect(() => {
      if (fundingSource) {
        handleSelectFundingSource(fundingSource);
      }
    }, [fundingSource, handleSelectFundingSource]);

    const [isVerifyingCard, setIsVerifyingCard] = useState(false);

    const trackCreateCardResult = ({
      status: statusType,
      error: errorType,
      fundingSourceId,
    }: {
      status: 'success' | 'failure';
      fundingSourceId?: string;
      error?: string;
    }) => {
      track('PaymentMethod', 'Status', {
        Intent: 'add-fs',
        StatusType: statusType,
        FundingSourceId: fundingSourceId,
        ...(errorType ? { ErrorType: errorType } : {}),
      });

      if (statusType === 'success') {
        trackMarketing('add-card_done-adding-new-card');
      }
    };

    const createNewFundingSourceAndRefetch = async (fields: CardFundingSourceFormFields, cardParams?: CardParams) => {
      if (cardParams) {
        const { firstName, lastName, postalCode, state, line1, city } = fields;
        const address = { postalCode, state, line1, city };
        const details = { ...cardParams, cardOwner: { firstName, lastName }, address };
        const result = await create({ type: 'card', details });

        onDoneAddingCreditCard?.();
        setFundingSourceId(result.id);

        trackCreateCardResult({ status: 'success', fundingSourceId: result.id });

        await refetch();
      }
    };

    const onCreateCardDetailsDone = async (fields: CardFundingSourceFormFields) => {
      const errorMessage = formatMessage('activities.subscription.checkout.addCardFailed');

      if (bt) {
        try {
          const { cardNumber, cardExpiration, cardVerificationCode } = fields;

          setIsVerifyingCard(true);

          const cardParams = await tokenize({
            cardNumber,
            cardExpiration,
            cardVerificationCode,
          });

          try {
            await verifyCard({
              cardBin: cardParams.cardBin,
              tabapayToken: cardParams.tabapayToken,
              tokenProvider: 'basistheory',
            });

            await createNewFundingSourceAndRefetch(fields, cardParams);
          } catch (error) {
            trackCreateCardResult({ status: 'failure', error: errorMessage });

            toast({
              type: 'error',
              title: errorMessage,
            });
          } finally {
            setIsVerifyingCard(false);
          }
        } catch (error) {
          trackCreateCardResult({ status: 'failure', error: errorMessage });

          toast({
            type: 'error',
            title: errorMessage,
          });
          setIsVerifyingCard(false);
        }
      }
    };

    const handleNewCardSelection = () => {
      track('Organization', 'Click', {
        Intent: 'choose-pm',
        Cta: 'credit-debit-card',
      });
      setFundingSourceType(FundingSourceType.Card);
      setView('add-new');
    };

    const navigateToAddBankAccount = () => {
      const searchParams = createSearchParams({
        ...(selectedPlan && { plan: selectedPlan?.id }),
        ...(selectedBillingCycle && { billingCycle: selectedBillingCycle }),
        ...(fundingSourceId && { fundingSourceId }),
      });
      const searchParamsString = searchParams.toString();
      const returnUrl = searchParamsString ? `${location.pathname}?${searchParamsString}` : location.pathname;

      return goToAddBankAccount({ returnUrl });
    };

    const handleNewBankAccountSelection = () => {
      track('Organization', 'Click', {
        Intent: 'choose-pm',
        Cta: 'bank-account',
      });
      setFundingSourceType(FundingSourceType.BankAccount);
      navigateToAddBankAccount();
    };

    const onSubmit = (fields: CardFundingSourceFormFields) => {
      onCreateCardDetailsDone(fields).catch((error) => {
        Logger.log(`Error: ${error as string}`, 'error');
      });
    };

    const fundingSourcesGroupedByType = useMemo(() => groupBy(fundingSources, 'type'), [fundingSources]);

    const getView = () => {
      switch (view) {
        case 'all':
          return (
            <Container border="regular" paddingX="m" paddingY="m">
              <Group variant="vertical" width="full">
                {!skipSelectedView && (
                  <Group justifyContent="flex-end">
                    <NakedButton
                      label={formatMessage('activities.subscription.checkout.fundingSourceSelection.button.close')}
                      variant="secondary"
                      onClick={() => setView('selected')}
                    />
                  </Group>
                )}
                <FundingSourceGroupList
                  fundingSourcesGroupedByType={fundingSourcesGroupedByType}
                  onSelected={handleSelectFundingSource}
                  selectedId={fundingSourceId}
                  onAddBank={navigateToAddBankAccount}
                  onAddCard={() => setView('add-new')}
                />
              </Group>
            </Container>
          );
        case 'add-new':
          return (
            <AddNewFundingSourceView
              fundingSourceType={fundingSourceType}
              onSubmit={onSubmit}
              onAddBank={handleNewBankAccountSelection}
              onAddCard={handleNewCardSelection}
              onClose={() => setView(defaultView)}
              isVerifyingCard={isVerifyingCard}
              hasFundingSources={!!fundingSources?.length}
            />
          );
        default:
          return fundingSource ? (
            <FundingSourceCard fundingSource={fundingSource} onChangeClick={() => setView('all')} />
          ) : null;
      }
    };

    return (
      <Group variant="vertical" spacing="xxxs" data-component="SubscriptionPaymentMethod">
        <Container paddingBottom="s">
          <Text textStyle="body2Semi" color="global.neutral.800">
            {formatMessage('activities.subscription.checkout.paymentMethod.title')}
          </Text>
        </Container>
        <LoadingContainer isLoading={isFetchingFundingSources}>{getView()}</LoadingContainer>
      </Group>
    );
  }
);
