import { Group, Text, useBreakpointValue } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';

import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';
import { FooterContent } from '../../components/FooterContent';
import { HeaderContent } from '../../components/HeaderContent';
import { BusinessSearchForm, BusinessSearchFormProps } from './components/BusinessSearchForm';

type SearchBusinessScreenProps = BusinessSearchFormProps & {
  onClose: VoidFunction;
  stepCompleted: boolean;
};

export const SearchBusinessScreen = withAnalyticsContext<SearchBusinessScreenProps>(
  ({ setAnalyticsProperties, onSelectOption, onCreateOption, onClearField, stepCompleted, onClose, ...props }) => {
    const { formatMessage } = useMelioIntl();
    const isMobile = useBreakpointValue({ xs: true, s: false });

    setAnalyticsProperties({
      PageName: 'search-vendor',
      Intent: 'add-a-vendor',
    });

    const { track } = useAnalytics();

    useEffect(() => {
      track('Vendor', 'View');
    }, [track]);

    return (
      <NewSinglePaymentStepLayout
        data-component="AddVendorActivity.SearchBusinessScreen"
        data-testid="add-vendor-activity-search-business-screen"
        isStickyFooter
        headerContent={<HeaderContent onClose={onClose} />}
        footerContent={<FooterContent onClose={onClose} isButtonsDisabled />}
        {...props}
      >
        {isMobile ? null : (
          <NewSinglePaymentStepLayout.Title textAlign="start" autoFocus={!stepCompleted}>
            {formatMessage('activities.addVendor.screens.addVendorDetails.title')}
          </NewSinglePaymentStepLayout.Title>
        )}
        <NewSinglePaymentStepLayout.Description
          as="p"
          paddingTop={isMobile ? 'xs' : undefined}
          textStyle={isMobile ? 'body3' : undefined}
          textAlign="start"
        >
          {formatMessage('activities.addVendor.searchAndAddVendorDetails.description')}
        </NewSinglePaymentStepLayout.Description>
        <NewSinglePaymentStepLayout.Content>
          <Group variant="vertical" spacing="xs" width="full">
            <Text color="global.neutral.900" textStyle={isMobile ? 'caption1' : 'body4'}>
              {formatMessage('activities.addVendor.searchAndAddVendorDetails.requiredFields')}
            </Text>
            <BusinessSearchForm
              onSelectOption={onSelectOption}
              onCreateOption={onCreateOption}
              onClearField={onClearField}
              stepCompleted={stepCompleted}
            />
          </Group>
        </NewSinglePaymentStepLayout.Content>
      </NewSinglePaymentStepLayout>
    );
  }
);

SearchBusinessScreen.displayName = 'AddVendorActivity.SearchBusinessScreen';
