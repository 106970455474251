import { useMelioIntl } from '@melio/platform-provider';
import { object, string } from 'yup';

export const useCheckValidationSchema = () => {
  const { formatMessage } = useMelioIntl();

  return object().shape({
    printName: string()
      .required(formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayorForm.printName.validation.required'))
      .test(
        'noWhiteSpaceOnly',
        formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayorForm.printName.validation.required'),
        (value = '') => value.trim().length > 0
      ),
    line1: string()
      .required(formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayorForm.line1.validation.required'))
      .nullable(),
    line2: string(),
    city: string().required(
      formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayorForm.city.validation.required')
    ),
    state: string()
      .required(formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayorForm.state.validation.required'))
      .nullable(),
    postalCode: string().required(
      formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayorForm.postalCode.validation.required')
    ),
  });
};
