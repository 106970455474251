import { apiClient, CreateOrganizationBeneficialOwner, OrganizationBeneficialOwner } from '@melio/javascript-sdk';

import { useCollectionApi, UseCollectionApiProps } from '../../core';
import { useBatchCreateMutation } from '../../core/useBatchCreateMutation';
import { useCreateMutation } from '../../core/useCreateMutation';
import { OrganizationBeneficialOwnerCollection } from './types';

type UseOrganizationBeneficialOwnersProps = UseCollectionApiProps<OrganizationBeneficialOwner>;

export const useOrganizationBeneficialOwners = (
  props: UseOrganizationBeneficialOwnersProps = {}
): OrganizationBeneficialOwnerCollection => {
  const client = apiClient.organizationBeneficialOwners();
  const query = useCollectionApi<OrganizationBeneficialOwner, CreateOrganizationBeneficialOwner>({
    ...props,
    queryKey: 'organization-beneficial-owner',
    queryFn: () => client.fetch(),
  });

  const create = useCreateMutation<OrganizationBeneficialOwner, CreateOrganizationBeneficialOwner>(
    (params) => client.create(params),
    query
  );

  const batchCreate = useBatchCreateMutation<OrganizationBeneficialOwner, CreateOrganizationBeneficialOwner[]>(
    ({ data }) => client.batchCreate(data),
    query
  );

  return {
    ...query,
    create,
    batchCreate,
  };
};
