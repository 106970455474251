import { Box } from '@chakra-ui/react';
import { Link, Skeleton, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { SubscriptionBillingCycleEnum, SubscriptionPreview } from '@melio/subscriptions/src/api';

export const FirmClientSubscriptionReviewAndConfirmDisclaimer = ({
  isLoading,
  preview,
  planCycle,
}: {
  isLoading: boolean;
  preview?: SubscriptionPreview;
  planCycle: SubscriptionBillingCycleEnum;
}) => {
  const { formatMessage, formatCurrency } = useMelioIntl();
  const { privacyPolicyUrl, termsOfServiceUrl } = useConfig().settings;
  const getDisclaimer = () => {
    const total = isLoading ? (
      <Box width="40px" display="inline-block">
        <Skeleton height="8px" />
      </Box>
    ) : (
      formatCurrency(preview?.amount || 0)
    );

    const termsAndConditions = {
      termsOfService: (
        <Link
          color="inherit"
          href={termsOfServiceUrl}
          label={formatMessage(
            'activities.accountants.activities.firmClientSubscription.reviewAndConfirm.disclaimer.termsAndConditions'
          )}
          variant="inline"
          newTab
        />
      ),
      privacyPolicy: (
        <Link
          color="inherit"
          href={privacyPolicyUrl}
          label={formatMessage(
            'activities.accountants.activities.firmClientSubscription.reviewAndConfirm.disclaimer.privacyPolicy'
          )}
          variant="inline"
          newTab
        />
      ),
    };

    return formatMessage(
      `activities.accountants.activities.firmClientSubscription.reviewAndConfirm.disclaimer.firmPays`,
      {
        planCycle,
        total,
        ...termsAndConditions,
      }
    );
  };

  return (
    <Text textStyle="body4" color="global.neutral.800" data-testid="subscription-checkout-disclaimer">
      {getDisclaimer()}
    </Text>
  );
};
