/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { AxiosError } from 'axios';
import { PartnerName } from '@melio/platform-api';

import { Loader } from '@/cl/components/Loader/Loader.component';
import {
  CapitalOneAuthMutation,
  CloverAuthMutation,
  FiservAuthMutation,
  MelioAuthMutation,
  StandardAuthMutation,
  useOAuthCallback,
  useOAuthQueryParams,
} from '@/hooks/auth.hooks';
import { usePartnerConfig } from '@/hooks/partners';

type OAuthCallbackScreenParams = {
  onSuccess: (data: NonNullable<ReturnType<typeof useOAuthCallback>['data']>) => void;
  onError: (error: AxiosError<unknown, unknown>) => void;
};

export const OAuthCallbackScreen = ({ onSuccess, onError }: OAuthCallbackScreenParams) => {
  const { partnerName } = usePartnerConfig();

  const { token, orgId, userId, target, targetIdQuery, entryPoint, userRole } = useOAuthQueryParams();

  const { mutate } = useOAuthCallback({
    partnerName,
    onSuccess,
    onError,
  });

  const sendOAuthCallbackRequest = React.useCallback(() => {
    const mutationParams = { token, orgId, target, targetId: targetIdQuery, entryPoint, userRole };
    switch (partnerName) {
      case PartnerName.Melio:
        mutate && (mutate as MelioAuthMutation)(mutationParams);
        break;
      case PartnerName.CapitalOne:
      case PartnerName.Sbb:
        mutate && (mutate as CapitalOneAuthMutation)({ ...mutationParams, partnerName });
        break;
      case PartnerName.Clover:
        mutate && (mutate as CloverAuthMutation)({ ...mutationParams, userId });
        break;
      case PartnerName.FiservXdDemo:
      case PartnerName.FiservFirstFinancial:
      case PartnerName.FiservUsBank:
      case PartnerName.Fiserv:
        mutate && (mutate as FiservAuthMutation)(mutationParams);
        break;
      default:
        mutate && (mutate as StandardAuthMutation)({ ...mutationParams, partnerName });
    }
  }, [partnerName, token, orgId, target, mutate]);

  React.useEffect(() => {
    sendOAuthCallbackRequest();
  }, []);

  return <Loader isAbsoluteCenter />;
};
