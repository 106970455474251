import { ActionsDropdownMenu, ActionsDropdownMenuItemProps, useBreakpoint } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { ApprovalWorkflow } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePermissions } from '@melio/platform-permissions';
import { compact, isEmpty } from 'lodash';
import { useState } from 'react';

export const ApprovalWorkflowsActionsMenu = ({
  workflow,
  onDeleteClick,
  onEditClick,
}: {
  workflow: ApprovalWorkflow;
  onDeleteClick: (workflow: ApprovalWorkflow) => void;
  onEditClick: (workflow: ApprovalWorkflow) => void;
}) => {
  const { items, isMenuOpen, setIsMenuOpen } = useActionsMenu({ workflow, onDeleteClick, onEditClick });

  return !isEmpty(items) ? (
    <ActionsDropdownMenu
      items={items}
      isOpen={isMenuOpen}
      onOpenChange={setIsMenuOpen}
      variant="default"
      size="small"
      data-testid={`workflow-actions-dropdown-menu-${workflow.id}`}
    />
  ) : null;
};

const useActionsMenu = ({
  workflow,
  onDeleteClick,
  onEditClick,
}: {
  workflow: ApprovalWorkflow;
  onDeleteClick: (workflow: ApprovalWorkflow) => void;
  onEditClick: (workflow: ApprovalWorkflow) => void;
}) => {
  const { can } = usePermissions();
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isEditApprovalWorkflowEnabled] = useFeature(FeatureFlags.EditApprovalWorkflowEnabled, false);
  const { isExtraSmallScreen } = useBreakpoint();

  const canDeleteWorkflow = can({ action: 'delete', subject: 'approvalWorkflow', subjectData: workflow });
  const canUpdateWorkflow =
    can({ action: 'update', subject: 'approvalWorkflow', subjectData: workflow }) && isEditApprovalWorkflowEnabled;
  const items: ActionsDropdownMenuItemProps[] = compact([
    canUpdateWorkflow &&
      !isExtraSmallScreen && {
        label: formatMessage('activities.settings.approval-workflow-settings.actions.edit'),
        onClick: () => {
          track('Settings', 'Click', { Intent: 'edit-workflow', Cta: 'more-edit-workflow' });
          onEditClick(workflow);
        },
        variant: 'default',
        dataTestId: 'edit-workflow-btn',
      },
    canDeleteWorkflow && {
      label: formatMessage('activities.settings.approval-workflow-settings.actions.delete'),
      onClick: () => {
        track('Settings', 'Click', { Intent: 'delete-workflow', Cta: 'more-delete-workflow' });
        onDeleteClick(workflow);
      },
      variant: 'critical',
      dataTestId: 'delete-workflow-btn',
    },
  ]);

  return { items, isMenuOpen, setIsMenuOpen };
};
