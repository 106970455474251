import { MouseEventHandler, useState } from 'react';
import { differenceInDays } from 'date-fns';
import _compact from 'lodash/compact';
import { useVendorActions } from '@melio/ap-domain';
import {
  ActionsDropdownMenu,
  ActionsDropdownMenuProps,
  Button,
  Container,
  Group,
  Link,
  Pill,
  SectionBanner,
  Text,
} from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { Vendor, VendorEBillStatusEnum } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useDateUtils } from '@melio/platform-utils';

import { useRouter } from '@/hooks/router.hooks';
import { VendorAutoPaySection } from './components/AutoPaySection';

export type VendorEBillSectionProps = {
  vendor: Vendor;
  onAutoPaymentCancellation: VoidFunction;
  onCancelEBillSubscription: VoidFunction;
};

export const VendorEBillSection = ({
  vendor,
  onCancelEBillSubscription,
  onAutoPaymentCancellation,
}: VendorEBillSectionProps) => {
  const { formatMessage } = useMelioIntl();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const vendorActions = useVendorActions(vendor);
  const { goToSettingsSupport, goToEBillsSubscription, goToAutoPaymentEdit } = useRouter();
  const statusLabelProps = useEBillStatusLabelProps(vendor);
  const [isAutoPayEnabled] = useFeature(FeatureFlags.AutoPay, false);
  const { track, createTrackHandler } = useAnalytics();
  const trackAndHandleClick = createTrackHandler<{
    Cta: 'edit-autopay-settings' | 'cancel-autopay';
    AutoPayStatus: Vendor['autoPayStatus'];
  }>('Vendor', 'Click');

  const isEBillRejected = vendor.eBillStatus === VendorEBillStatusEnum.Rejected;

  const handleContactSupport: MouseEventHandler<HTMLLinkElement> = (e) => {
    track('Vendor', 'Click', {
      Cta: 'contact-support',
      VendorId: vendor.id,
    });
    e.preventDefault();
    e.stopPropagation();
    goToSettingsSupport();
  };

  const handleEBillSubscribeClick = () => {
    track('Vendor', 'Click', {
      Cta: 'subscribe',
      VendorId: vendor.id,
    });
    goToEBillsSubscription(vendor.id);
  };

  const handleEBillSubscriptionReviewClick = () => {
    track('Vendor', 'Click', {
      Cta: 'review-and-try-again',
      VendorId: vendor.id,
    });
    goToEBillsSubscription(vendor.id);
  };

  const handleEBillSubscriptionCancelClick = () => {
    track('Vendor', 'Click', {
      Cta: 'cancel-ebill-subscription',
      VendorId: vendor.id,
    });
    onCancelEBillSubscription();
  };

  const renderActions = () => {
    if (vendor.eBillStatus === VendorEBillStatusEnum.NotCapable) return null;
    if (vendorActions.eBillSubscribe && vendor.eBillStatus === VendorEBillStatusEnum.Capable) {
      return (
        <Button
          variant="secondary"
          label={formatMessage('widgets.vendorDrawer.ebillSection.subscribe')}
          data-testid="widgets-vendorDrawer-ebillSection-subscribe"
          onClick={handleEBillSubscribeClick}
        />
      );
    }

    const actions: ActionsDropdownMenuProps['items'] = _compact([
      isEBillRejected &&
        vendorActions.eBillSubscribe && {
          label: formatMessage('widgets.vendorDrawer.ebillSection.options.tryAgain'),
          dataTestId: 'review-and-try-again-option',
          onClick: handleEBillSubscriptionReviewClick,
        },
      vendorActions.editAutoPay && {
        label: formatMessage('widgets.vendorDrawer.ebillSection.options.editAutoPayment'),
        dataTestId: 'edit-auto-pay-option',
        onClick: () =>
          trackAndHandleClick({ Cta: 'edit-autopay-settings', AutoPayStatus: vendor.autoPayStatus }, () => {
            goToAutoPaymentEdit(vendor.id);
          }),
      },
      vendorActions.cancelAutoPay && {
        label: formatMessage('widgets.vendorDrawer.ebillSection.options.cancelAutoPayment'),
        dataTestId: 'cancel-auto-pay-option',
        variant: 'critical',
        onClick: () =>
          trackAndHandleClick(
            { Cta: 'cancel-autopay', AutoPayStatus: vendor.autoPayStatus },
            onAutoPaymentCancellation,
          ),
      },
      vendorActions.cancelEBillSubscription && {
        label: formatMessage('widgets.vendorDrawer.ebillSection.options.cancelSubscription'),
        dataTestId: 'cancel-subscription-option',
        onClick: handleEBillSubscriptionCancelClick,
        variant: 'critical',
      },
    ]);
    return actions.length ? (
      <ActionsDropdownMenu
        isOpen={isMenuOpen}
        onOpenChange={setIsMenuOpen}
        size="small"
        label="actions"
        data-testid="widgets-vendorDrawer-ebillSection-options"
        items={actions}
      />
    ) : null;
  };

  return (
    <Container overflow="initial">
      <Group variant="vertical">
        <Group variant="vertical">
          <Group variant="horizontal" alignItems="center" justifyContent="space-between">
            <Group spacing="xs" alignItems="center">
              <Text as="h3" textStyle="heading3Semi">
                {formatMessage('widgets.vendorDrawer.ebillSection.title')}
              </Text>
              {statusLabelProps && <Pill {...statusLabelProps} type="secondary" />}
            </Group>
            {renderActions()}
          </Group>
          {!isEBillRejected ? (
            <Text textStyle="body4" as="p">
              {formatMessage('widgets.vendorDrawer.ebillSection.description')}
            </Text>
          ) : (
            <SectionBanner
              variant="critical"
              data-testid="widgets-vendorDrawer-ebillSection-rejectedBanner"
              description={formatMessage('widgets.vendorDrawer.ebillSection.rejectedBanner', {
                link: (
                  <Link
                    href="../../settings/support"
                    label={formatMessage('widgets.vendorDrawer.ebillSection.rejectedBanner.contactSupport')}
                    onClick={handleContactSupport}
                  />
                ),
              })}
            />
          )}
        </Group>
        {!isEBillRejected && isAutoPayEnabled ? <VendorAutoPaySection vendor={vendor} /> : null}
      </Group>
    </Container>
  );
};

const useEBillStatusLabelProps = (vendor: Vendor) => {
  const { formatMessage } = useMelioIntl();
  const { createDate } = useDateUtils();

  if (vendor.eBillStatus === VendorEBillStatusEnum.Capable) {
    return null;
  }

  const trialDaysLeft = vendor.eBillTrialEndDate
    ? differenceInDays(createDate(vendor.eBillTrialEndDate.toISOString()), createDate())
    : null;

  switch (vendor.eBillStatus) {
    case VendorEBillStatusEnum.Pending:
      return { status: 'warning' as const, label: formatMessage('widgets.vendorDrawer.ebillSection.badge.pending') };
    case VendorEBillStatusEnum.Active:
      return trialDaysLeft && trialDaysLeft > 0
        ? {
            status: 'promotional' as const,
            label: formatMessage('widgets.vendorDrawer.ebillSection.badge.trial', { daysLeft: trialDaysLeft }),
            'data-testid': 'widgets-vendorDrawer-ebillSection-badge-trial',
          }
        : {
            status: 'success' as const,
            label: formatMessage('widgets.vendorDrawer.ebillSection.badge.subscribed'),
            'data-testid': 'widgets-vendorDrawer-ebillSection-badge-active',
          };
    case VendorEBillStatusEnum.Rejected:
      return { status: 'critical' as const, label: formatMessage('widgets.vendorDrawer.ebillSection.badge.rejected') };
  }
};
