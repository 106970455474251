import { FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { usePartnerGroups } from '../../../hooks';

export const useSubscriptionPaymentMethodDisplayText = (fundingSource: FundingSource) => {
  const { formatMessage } = useMelioIntl();
  const { isFiservPartner } = usePartnerGroups();

  if (isFiservPartner) {
    return {
      title: fundingSource.displayName || formatMessage('activities.settings.subscriptionPaymentMethod.card.bankTitle'),
      description: fundingSource.nickname,
    };
  }

  const { type: fundingType } = fundingSource;

  if (fundingType === 'bank-account') {
    const lastFourDigits = fundingSource.details.accountNumber.slice(-4);
    return {
      title: formatMessage('activities.settings.subscriptionPaymentMethod.card.bankTitle'),
      description: formatMessage('activities.settings.subscriptionPaymentMethod.card.bankDescription', {
        lastFourDigits,
      }),
    };
  }

  if (fundingType === 'flex-account') {
    return {
      title: formatMessage('activities.settings.subscriptionPaymentMethod.flex.flexTitle'),
      description: formatMessage('activities.settings.subscriptionPaymentMethod.flex.flexDescription'),
    };
  }

  const cardNetwork = fundingSource.details.network;
  const lastFourDigits = fundingSource.details.lastFourDigits;
  return {
    title: cardNetwork.charAt(0).toUpperCase() + cardNetwork.slice(1),
    description: formatMessage('activities.settings.subscriptionPaymentMethod.card.cardDescription', {
      lastFourDigits,
    }),
  };
};
