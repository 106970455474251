import { FormWidgetProps } from '@melio/ap-widgets';
import { useMelioIntl } from '@melio/platform-i18n';
import { FileType, useConfig } from '@melio/platform-provider';
import { pickBy } from 'lodash';
import { mixed, object, SchemaOf } from 'yup';

const schemaFields: Array<keyof InvoiceAttachmentFormFields> = ['invoice'];
export type InvoiceAttachmentFormFields = {
  invoice: File;
};

export type InvoiceAttachmentFormProps = FormWidgetProps<InvoiceAttachmentFormFields>;

export const InvoiceAttachmentSchema = () => {
  const { formatMessage } = useMelioIntl();
  const { fileSizeLimit, fileAllowedFormats } = useConfig().settings;

  return object().shape(
    pickBy(
      {
        invoice: mixed<File>()
          .required(
            formatMessage(
              'activities.internationalPaymentPurpose.screens.internationalPaymentPurpose.paymentPurposeForm.invoice.validation.required'
            )
          )
          .test(
            'is-file-size-below-limit',
            formatMessage(
              'activities.internationalPaymentPurpose.screens.internationalPaymentPurpose.paymentPurposeForm.invoice.validation.sizelimit'
            ),
            (invoice) => {
              if (invoice) {
                return invoice?.size <= fileSizeLimit;
              }
              return true;
            }
          )
          .test(
            'is-file-format-allowed',
            formatMessage('widgets.addInstantBillForm.invoice.validation.fileFormat'),
            (invoice) => {
              if (invoice) {
                return fileAllowedFormats.includes(invoice.type?.split('/')[1] as FileType);
              }
              return true;
            }
          ),
      },
      (_, key) => schemaFields.includes(key as keyof InvoiceAttachmentFormFields)
    )
  ) as unknown as SchemaOf<InvoiceAttachmentFormFields>;
};

export type InvoiceErrorMessage = {
  title: string;
  description: string;
};

export type InvoiceBillDetails = {
  billId: string;
  billNumber: string;
  organizationName?: string;
  billAmount: string;
};
