import { ApiError, useNonEntityMutation } from '@melio/api-client';
// eslint-disable-next-line no-restricted-imports
import { AccountantsFirmClientApiClient } from '@melio/platform-api-axios-client';

export const useRequestAccessToOrganization = ({
  onSuccess,
  onError,
}: {
  onSuccess: VoidFunction;
  onError(error: ApiError): void;
}) =>
  useNonEntityMutation(
    AccountantsFirmClientApiClient.requestAccountAccess,
    ['AccountantsFirmClientApi', 'collection', 'default'],
    {
      onSuccess,
      onError,
    }
  );
