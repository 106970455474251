import { useRoleText } from '@melio/ap-domain';
import { Form, Typography } from '@melio/penny';
import { ComponentProps } from 'react';

import type { RoleUniqueNames } from '../../../../../types';
import { useRoleDescription } from './RoleRadioGroup.utils';

type Props = Omit<ComponentProps<typeof Form.RadioGroup>, 'options' | 'aria-label'> & {
  availableRoleOptions: RoleUniqueNames[];
  selectedRoleUniqueName: RoleUniqueNames;
  title: string;
};
export const RoleRadioGroup = ({ availableRoleOptions, title, selectedRoleUniqueName, ...props }: Props) => {
  const { getRoleText } = useRoleText();
  const { getRoleDescription } = useRoleDescription();

  return (
    <>
      <Form.RadioGroup
        labelProps={{
          label: title,
        }}
        options={availableRoleOptions.map((roleUniqueName) => ({
          value: roleUniqueName,
          mainLabelProps: {
            label: getRoleText(roleUniqueName),
          },
        }))}
        {...props}
      />
      <Form.ContentBox colSpan={props.colSpan}>
        <Typography.Description label={getRoleDescription(selectedRoleUniqueName)} />
      </Form.ContentBox>
    </>
  );
};
