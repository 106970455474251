import { useMelioIntl } from '@melio/platform-i18n';
import { isValidInternationalPhoneNumber } from '@melio/platform-utils';
import * as yup from 'yup';

import { AccountHolderForm } from '../types';
import { useFxCommonValidations } from '../utils/formUtils';

export const useAccountHolderFormSchema = () => {
  const { addressSchema, dateOfBirthSchema, ssnSchema, firstNameSchema, lastNameSchema, emailSchema } =
    useFxCommonValidations();
  const { formatMessage } = useMelioIntl();
  return yup.object().shape({
    firstName: firstNameSchema.trim(),
    lastName: lastNameSchema.trim(),
    dayOfBirth: dateOfBirthSchema,
    phoneNumber: yup
      .string()
      .required(
        formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.phone.required'
        )
      )
      .test(
        'valid-intl-phone',
        formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.personalDetailsCard.phoneNumber.invalid'
        ),
        isValidInternationalPhoneNumber
      ),
    address: addressSchema,
    taxId: ssnSchema.trim(),
    isUbo: yup.boolean().nullable(),
    email: emailSchema.trim(),
  }) as yup.SchemaOf<AccountHolderForm>;
};
