import { apiClient, Invitation } from '@melio/javascript-sdk';
// eslint-disable-next-line no-restricted-imports
import { useIsMutating } from 'react-query';

import { useMelioQueryClient, UseModelApiProps, useNonEntityMutation } from '../../core';
import { createQueryKey } from '../../core/util';
import { InvitationModel } from './types';

export type UseCancelInvitationProps = UseModelApiProps<Invitation>;
type CancelModel = Pick<InvitationModel, 'queryKey'> & { isCanceling: boolean; cancel: () => Promise<Invitation> };

export const useCancelInvitation = (props: UseCancelInvitationProps): CancelModel => {
  const client = apiClient.invitations();
  const queryClient = useMelioQueryClient();

  const queryKey = createQueryKey({
    queryKey: 'invitation',
    role: 'collection',
    scope: 'default',
    id: props.id,
  });

  const { mutateAsync } = useNonEntityMutation<Invitation>(
    () => client.cancel(props.id as string),
    { queryKey },
    {
      invalidateQuery: true,
      onSuccess: () => queryClient.invalidateQueries('SubscriptionsApi'),
    }
  );

  const isCanceling = useIsMutating(queryKey) > 0;

  return {
    queryKey,
    cancel: () => mutateAsync({}),
    isCanceling,
  };
};
