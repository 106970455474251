import { ComponentProps, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { AnyComponent } from './types';
import { withOutlet as _withOutlet } from './withOutlet';

type RouteElementProps<T extends AnyComponent, TPropKeys extends keyof ComponentProps<T>> = Omit<
  ComponentProps<T>,
  TPropKeys
> & {
  component: T;
  pathToProps: Record<string, TPropKeys>;
  withOutlet?: boolean;
};

export const RouteElement = <T extends AnyComponent, TPropKeys extends keyof ComponentProps<T>>({
  component: Component,
  pathToProps,
  withOutlet: hasOutlet,
  ...componentProps
}: RouteElementProps<T, TPropKeys>) => {
  const params = useParams();

  const targetProps = Object.entries(pathToProps).reduce(
    (acc, [pathParam, propName]) => ({ ...acc, ...{ [propName]: params[pathParam], key: params[pathParam] } }),
    {} as Pick<ComponentProps<T>, TPropKeys>
  );

  const combinedProps = { ...componentProps, ...targetProps } as ComponentProps<T>;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const target = useMemo(() => <Component {...combinedProps} />, [JSON.stringify(combinedProps)]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => (hasOutlet ? _withOutlet(target) : target), [target]);
};
