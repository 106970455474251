import { Stack } from '@chakra-ui/react';
import { FileInfo, FileOCRData } from '@melio/platform-api';

import { InvoiceImage } from '@/cl/components/InvoiceImage/InvoiceImage.component';
import { usePlatformIntl } from '@/translations/Intl';

type BillFileProps = {
  editMode: boolean;
  fileUploadedByUser?: { fileInfo: FileInfo; billDetails?: FileOCRData } | null;
  file?: FileInfo | null;
  onFileUploaded: (fileList: FileList | null) => void;
  onFileDeleted: () => void;
  isProcessingFile: boolean;
};

export const BillFile = ({
  editMode,
  fileUploadedByUser,
  file,
  onFileUploaded,
  onFileDeleted,
  isProcessingFile,
}: BillFileProps) => {
  const { formatMessage } = usePlatformIntl();

  const extractInvoiceFile = () => {
    if (!editMode) return file;
    if (fileUploadedByUser) return fileUploadedByUser.fileInfo;
    if (fileUploadedByUser === null) return null;

    return file;
  };

  return (
    <Stack>
      <InvoiceImage
        editMode={editMode}
        file={extractInvoiceFile()}
        buttonsConfig={{
          uploadButton: {
            text: formatMessage('widgets.billDetails.file.upload'),
          },
          deleteButton: {
            text: formatMessage('widgets.billDetails.file.delete'),
          },
          replaceButton: {
            text: formatMessage('widgets.billDetails.file.replace'),
          },
        }}
        onFileUploaded={onFileUploaded}
        onFileDeleted={onFileDeleted}
        isProcessingFile={isProcessingFile}
      />
    </Stack>
  );
};
