import { Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export type TotalFeesProps = { amount: string; description: string };

export const TotalFees = ({ amount, description }: TotalFeesProps) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Group justifyContent="space-between">
      <Group variant="vertical" spacing="none">
        <Text textStyle="body2Semi">
          {formatMessage('activities.editBillSubscription.screens.editBillSubscription.feesSection.totalFees')}
        </Text>
        <Text data-testid="total-fee-description" textStyle="body4" color="global.neutral.900">
          {description}
        </Text>
      </Group>
      <Text data-testid="total-fee-amount" textStyle="body2Semi">
        {amount}
      </Text>
    </Group>
  );
};
