import { EventProperties } from '@melio/platform-analytics';
import { useAccountingPlatforms } from '@melio/platform-api';
import { FeatureFlags } from '@melio/platform-feature-flags';

import { PromotionType } from '../types';
import { usePromotionEnabled } from './usePromotionEnabled';

type UsePromotionsDataReturnType = {
  isLoading: boolean;
  activePromotion: FeatureFlags | null;
  analyticsData: NonNullable<EventProperties['Feature']>;
};

const useQBOSyncPromotionEnabled = () => {
  const { isLoading: isEnabledLoading, enabled: isQBOPromoEnabled } = usePromotionEnabled(PromotionType.QBOSync);
  const { activeAccountingPlatform, isLoading: isAccountingPlatformLoading } = useAccountingPlatforms({
    enabled: isQBOPromoEnabled,
  });

  return {
    isLoading: isEnabledLoading || isAccountingPlatformLoading,
    shouldDisplaySyncPromotion: isQBOPromoEnabled && !activeAccountingPlatform,
  };
};

const useCardToCardPromotionEnabled = () => {
  const { isLoading, enabled: shouldDisplayCardToCardPromotion } = usePromotionEnabled(PromotionType.CardToCard);

  return { isLoading, shouldDisplayCardToCardPromotion };
};

export const usePromotionsData = (): UsePromotionsDataReturnType => {
  const { isLoading: isQBOSyncPromotionLoading, shouldDisplaySyncPromotion } = useQBOSyncPromotionEnabled();
  const { isLoading: isCardToCardPromoLoading, shouldDisplayCardToCardPromotion } = useCardToCardPromotionEnabled();

  const isLoading = isQBOSyncPromotionLoading || isCardToCardPromoLoading;

  const activePromotion: FeatureFlags | null = isLoading
    ? null
    : shouldDisplaySyncPromotion
    ? FeatureFlags.PlatformScheduledPaymentAccountingPlatformSyncPromotion
    : shouldDisplayCardToCardPromotion
    ? FeatureFlags.PlatformScheduledPaymentCardToCardPromotionEnabled
    : null;

  const analyticsData: UsePromotionsDataReturnType['analyticsData'] = [
    FeatureFlags.PlatformScheduledPaymentAccountingPlatformSyncPromotion,
    FeatureFlags.PlatformScheduledPaymentCardToCardPromotionEnabled,
  ].map((name) => ({
    name,
    displayed: name === activePromotion,
  }));

  return {
    isLoading,
    activePromotion,
    analyticsData,
  };
};
