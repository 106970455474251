import { Container, ExternalLayout, Group, Illustration, Text } from '@melio/penny';
import { useVendor, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useCallback } from 'react';

import { MonitoredAction } from '../../../../monitoring';
import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';

export const EBillsSubscriptionSuccessActivity = ({
  vendorId,
  onDone,
}: {
  vendorId: Vendor['id'];
  onDone: () => void;
}) => {
  const { formatMessage } = useMelioIntl();
  const { data: vendor, isLoading } = useVendor({ id: vendorId });
  const { endAction } = useMonitoring<MonitoredAction>();

  const onReady = useCallback(
    (ref) => {
      if (ref) {
        endAction('ebills_subscription_form_submit');
      }
    },
    [endAction]
  );

  if (isLoading) {
    return <ExternalLayout isLoading />;
  }

  return (
    <NewSinglePaymentStepLayout
      ref={onReady}
      data-testid="ebills-subscription-success"
      footerContent={
        <NewSinglePaymentStepLayout.Actions>
          <NewSinglePaymentStepLayout.NextButton
            label={formatMessage('activities.ebillsSubscriptionSuccess.action')}
            onClick={onDone}
            data-testid="ebills-subscription-success-action-button"
          />
        </NewSinglePaymentStepLayout.Actions>
      }
    >
      <Group justifyContent="center">
        <Illustration type="success" size="large" />
      </Group>
      <Group variant="vertical" spacing="s">
        <NewSinglePaymentStepLayout.Title>
          {formatMessage('activities.ebillsSubscriptionSuccess.title')}
        </NewSinglePaymentStepLayout.Title>
        <Container textAlign="center">
          <Text textStyle="body3" as="p">
            {formatMessage('activities.ebillsSubscriptionSuccess.description', { vendorName: vendor?.name })}
          </Text>
        </Container>
      </Group>
    </NewSinglePaymentStepLayout>
  );
};
