import { PaymentIntent, PaymentModel, usePayment } from '@melio/platform-api';
import { useState } from 'react';

export const useRefundData = (
  paymentId: string
): {
  isLoading: boolean;
  payment?: PaymentModel['data'];
  paymentIntent?: PaymentIntent;
  refund: PaymentModel['refund'];
  isUpdatingPayment: boolean;
  vendorBankAccountDMId?: string;
  isRefundError: boolean;
  setIsRefundError: React.Dispatch<React.SetStateAction<boolean>>;
} => {
  const [isRefundError, setIsRefundError] = useState(false);
  const { data: payment, isLoading: isLoadingPayment, refund, isMutating } = usePayment({ id: paymentId });

  return {
    isLoading: isLoadingPayment,
    isRefundError,
    setIsRefundError,
    payment,
    refund,
    isUpdatingPayment: isMutating,
  };
};
