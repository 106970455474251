import { useCollectionApi, UseCollectionApiProps } from '@melio/api-client';
import { DemoRequestsApiClient } from '@melio/platform-api-axios-client';

export type UseDemoRequestsProps = UseCollectionApiProps<typeof DemoRequestsApiClient.getDemoRequests>;

export const useDemoRequests = (props: UseDemoRequestsProps = {}) =>
  useCollectionApi({
    ...props,
    queryKey: 'DemoRequestsApi',
    queryFn: DemoRequestsApiClient.getDemoRequests,
    createFn: DemoRequestsApiClient.postDemoRequest,
  });
