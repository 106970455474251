import { ListItem, ListItemProps } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { getDollarsFromCents, useNavigate } from '@melio/platform-utils';
import React from 'react';

import { SummaryTypeEnum } from './types';

export type PaymentSummaryProps = {
  summaryType: SummaryTypeEnum;
  link?: string;
  sum?: number;
  vendorName?: string;
  count?: number;
  onLinkClick?: () => void;
  showNotAvailable?: boolean;
  tooltipText?: string;
  currency: string | null;
};

export const PaymentSummary = ({
  summaryType,
  link,
  sum = 0,
  count = 0,
  onLinkClick,
  showNotAvailable,
  tooltipText,
  currency,
}: PaymentSummaryProps) => {
  const navigate = useNavigate();
  const { formatMessage, formatCurrency } = useMelioIntl();
  const descriptionLabel = formatMessage(
    `widgets.paymentOverview.${summaryType === SummaryTypeEnum.OPEN_BALANCE ? 'bills' : 'payments'}`,
    { count }
  );
  const actionAriaLabel = formatMessage(`widgets.paymentOverview.${summaryType}.action.ariaLabel`, {
    count,
  });

  const handleClick: React.MouseEventHandler<HTMLLinkElement> = React.useCallback(
    (e) => {
      e.preventDefault();

      onLinkClick?.();
      if (link) {
        navigate(link);
      }
    },
    [link, navigate, onLinkClick]
  );

  const getMainLabelProps = () => ({
    'data-testid': `payment-overview-sum-${summaryType}`,
    label: showNotAvailable
      ? formatMessage('widgets.paymentOverview.openBalance.pendingEBill.notAvailable')
      : formatCurrency(getDollarsFromCents(sum), currency || undefined),
  });

  const getDescriptionProps = (): ListItemProps['descriptionProps'] => {
    const descriptionProps = {
      label: descriptionLabel,
      'data-testid': `payment-overview-sum-${summaryType}-description`,
    };

    if (showNotAvailable) {
      return {
        ...descriptionProps,
        label: formatMessage('widgets.paymentOverview.openBalance.pendingEBill.notAvailable'),
      };
    }

    if (count) {
      return {
        ...descriptionProps,
        link: {
          'data-testid': `payment-summary-view-btn-${summaryType}`,
          label: formatMessage('widgets.paymentOverview.buttonLink'),
          'aria-label': actionAriaLabel,
          href: '#',
          onClick: handleClick,
          color: 'secondary',
        },
      };
    }

    return descriptionProps;
  };

  const label = formatMessage(`widgets.paymentOverview.${summaryType}.label`);

  return (
    <ListItem
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      flex={1}
      as="li"
      data-testid={`payment-overview-sum-${summaryType}-list-item`}
      labelProps={{
        label,
        tooltipProps: tooltipText ? { label: tooltipText } : undefined,
      }}
      mainLabelProps={getMainLabelProps()}
      descriptionProps={getDescriptionProps()}
    />
  );
};
