import { useToast } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { JoinOrganizationRequest, useJoinOrganizationRequest } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';

import { CancelConfirmationModalScreen } from './screens';

type Props = {
  joinOrganizationRequest: JoinOrganizationRequest;
  onClose: VoidFunction;
};

export const CancelJoinOrganizationRequestModalActivity = withAnalyticsContext<Props>(
  ({ joinOrganizationRequest, onClose, setAnalyticsProperties }) => {
    const { formatMessage } = useMelioIntl();
    const { toast } = useToast();
    const { track } = useAnalytics();
    const { cancelJoinOrganizationRequestAccount, isMutating } = useJoinOrganizationRequest({
      id: joinOrganizationRequest.id,
      enabled: false,
    });

    setAnalyticsProperties({
      PageName: 'cancel-request',
      Intent: 'cancel-request',
    });

    useEffect(() => {
      track('User', 'View');
    }, [track]);

    const handleConfirmClick = async () => {
      track('User', 'Click', {
        Cta: 'cancel-request',
      });
      try {
        await cancelJoinOrganizationRequestAccount();
        toast({
          type: 'success',
          title: formatMessage(`activities.cancelJoinOrganizationRequestModal.toast.success`),
        });
        track('User', 'Status', {
          PageName: 'companies-dashboard',
          Status: 'success',
        });
        onClose();
      } catch (error) {
        toast({
          type: 'error',
          title: formatMessage(`activities.cancelJoinOrganizationRequestModal.toast.error`),
        });
        track('User', 'Status', {
          PageName: 'companies-dashboard',
          Status: 'failure',
        });
        onClose();
      }
    };

    return (
      <CancelConfirmationModalScreen
        isOpen
        companyName={joinOrganizationRequest.companyName}
        isLoading={isMutating}
        onClose={onClose}
        onDone={handleConfirmClick}
      />
    );
  }
);
