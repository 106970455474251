import { MobileFilter, useMelioIntl } from '@melio/ar-domain';
import { Container, Group, SearchBar as DSSearchBar } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { useFiltersLabel } from '../hooks';
import { Filter, OnSort, SortParams } from '../types';
import { SortMenuMobile } from './SortMenu.mobile';

export type FilterBarMobileProps = {
  selectedFilter: Filter;
  onSelectFilter: (filter: Filter) => void;
  search?: string;
  onSearch: (value: string) => void;
  onSort: OnSort;
  sortParams?: SortParams;
  filters: Filter[];
};

export const FiltersToolBarMobile = forwardRef<FilterBarMobileProps>(
  ({ onSelectFilter, selectedFilter, onSearch, search, filters, onSort, sortParams, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const filtersLabel = useFiltersLabel();

    return (
      <Group
        ref={ref}
        data-component={FiltersToolBarMobile.displayName}
        variant="horizontal"
        alignItems="center"
        data-testid="pay-dashboard-payments-sub-and-search-tab"
        {...props}
      >
        <Group.Item>
          <DSSearchBar
            value={search}
            onChange={(e) => onSearch(e.target.value)}
            placeholder={formatMessage('ar.dashboard.activities.invoiceTable.mobile.searchInput.placeholder')}
            onClear={() => onSearch('')}
            data-testid="invoice-table-search-input"
            aria-label={formatMessage('ar.dashboard.activities.invoiceTable.mobile.searchInput.aria-label')}
            instructionsText={formatMessage('ar.dashboard.activities.invoiceTable.mobile.searchInput.instructions')}
          />
        </Group.Item>
        <Group.Item>
          <Group allowOverflowX={false} spacing="xs">
            <Container width="fit-content">
              <SortMenuMobile onSort={onSort} sortParams={sortParams} />
            </Container>
            <Container width="fit-content">
              <MobileFilter
                activeFilter={selectedFilter}
                defaultFilter="all"
                options={filters.map((filter) => ({
                  id: filter,
                  label: filtersLabel[filter],
                }))}
                onChange={onSelectFilter}
                title={formatMessage('ar.dashboard.activities.invoiceTable.filters.title')}
              />
            </Container>
          </Group>
        </Group.Item>
      </Group>
    );
  }
);
FiltersToolBarMobile.displayName = 'MobileFiltersToolBar';
