import { useMelioIntl } from '@melio/platform-i18n';

import { useIsFeatureInSubscription } from '../../../../hooks';

export const useAccountingSoftwareSyncDescription = () => {
  const isSyncWithXero = useIsFeatureInSubscription({ featureName: 'accountingPlatformXero' }).isEligible;
  const isSyncWithQuickBooks = useIsFeatureInSubscription({ featureName: 'accountingPlatformQbo' }).isEligible;
  const { formatMessage } = useMelioIntl();

  const getAccountingSoftwareSyncDescription = () => {
    if (isSyncWithXero && isSyncWithQuickBooks) {
      return formatMessage(
        'activities.settings.editablePlanCardFiserv.description.accountingSoftwareSync.quickBooksOrXero'
      );
    }
    if (isSyncWithXero) {
      return formatMessage('activities.settings.editablePlanCardFiserv.description.accountingSoftwareSync.xero');
    }
    if (isSyncWithQuickBooks) {
      return formatMessage('activities.settings.editablePlanCardFiserv.description.accountingSoftwareSync.quickBooks');
    }
    return '';
  };

  return getAccountingSoftwareSyncDescription();
};
