import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { EBILLS_STATE_QUERY_PARAM_KEY } from '../consts';
import { EbillsImportRedirectState } from '../types';

const parseState = (value?: string | null) =>
  value ? (JSON.parse(decodeURI(value)) as EbillsImportRedirectState) : undefined;

const SYNC_STORAGE_KEY = 'last_sync_provider';

// Use in case localStorage is N/A (incognito mode)
let inMemoryEbillsImportRedirectState: EbillsImportRedirectState | undefined;

export const syncProviderStorage = {
  get() {
    try {
      return parseState(localStorage.getItem(SYNC_STORAGE_KEY));
    } catch (e) {
      // Probably incognito
      return inMemoryEbillsImportRedirectState;
    }
  },
  set(state: EbillsImportRedirectState) {
    try {
      localStorage.setItem(SYNC_STORAGE_KEY, JSON.stringify(state));
    } catch (e) {
      // Probably incognito
      inMemoryEbillsImportRedirectState = state;
    }
  },
  remove() {
    try {
      localStorage.removeItem(SYNC_STORAGE_KEY);
    } catch (e) {
      // Probably incognito
      inMemoryEbillsImportRedirectState = undefined;
    }
  },
};

export const useEbillsImportState = (): {
  redirectState?: EbillsImportRedirectState;
  searchParamState?: EbillsImportRedirectState;
  removeRedirectStateFromUrl: VoidFunction;
  error: string | null;
} => {
  const [searchParams, setSearchParams] = useSearchParams();

  const removeRedirectStateFromUrl = useCallback(() => {
    const mutableSearchParams = new URLSearchParams(searchParams);
    mutableSearchParams.delete(EBILLS_STATE_QUERY_PARAM_KEY);
    setSearchParams(mutableSearchParams);
  }, [searchParams, setSearchParams]);

  // Using memo because parsing object creates new object each time
  const searchParamState = useMemo(() => parseState(searchParams.get(EBILLS_STATE_QUERY_PARAM_KEY)), [searchParams]);
  const redirectState = useMemo(() => searchParamState || syncProviderStorage.get(), [searchParamState]);

  return {
    redirectState,
    searchParamState,
    removeRedirectStateFromUrl,
    error: searchParams.get('error'),
  };
};
