import { TourtipSteps } from '@melio/penny';
import { InboxItemScannedInvoiceTypeEnum, useProvider } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useWaitForElement } from '@melio/platform-utils';

import { createTourIdSelector } from '../../../../../utils';
import { PayDashboardTabs } from '../../../types';
import { useBillsTabItems } from '../../BillsTab/useBillsTabItems';
import { useSyncNowStep } from '../useSyncNowStep';

export const useBillsTabTourSteps = ({ enabled }: { enabled: boolean }) => {
  const { formatMessage } = useMelioIntl();
  const { data: inboxData, isLoading: isLoadingInboxItems } = useBillsTabItems({ searchTerm: null, enabled });
  const { status: gmailSyncState } = useProvider('gmail', { enabled });

  const firstInboxItemToReviewId =
    inboxData?.data?.find((item) => item.type === InboxItemScannedInvoiceTypeEnum.ScannedInvoice)?.payload.id ?? '';

  const reviewBillStepTarget = createTourIdSelector(`pay-dashboard-tour-review-bill-${firstInboxItemToReviewId}`);
  const reviewBillStep = firstInboxItemToReviewId
    ? {
        target: reviewBillStepTarget,
        title: formatMessage('activities.payDashboard.bills-tour.step.bill.review.title'),
        description: formatMessage('activities.payDashboard.bills-tour.step.bill.review.content'),
      }
    : undefined;
  const { isLoading: isLoadingSyncNowStep, step: syncNowStep } = useSyncNowStep({ enabled });

  // @ts-expect-error should update penny type to NonEmptyArray<T> = [T, ...T[]] | [...T[], T];
  const steps: TourtipSteps = [
    ...(reviewBillStep ? [reviewBillStep] : []),
    {
      target: createTourIdSelector(`pay-dashboard-tab-${PayDashboardTabs.Bills}`),
      title: formatMessage('activities.payDashboard.bills-tour.step.bills.tab.title'),
      description: formatMessage('activities.payDashboard.bills-tour.step.bills.tab.content'),
    },
    {
      target: createTourIdSelector(`pay-dashboard-tab-${PayDashboardTabs.Vendors}`),
      title: formatMessage('activities.payDashboard.bills-tour.step.vendors.tab.title'),
      description: formatMessage('activities.payDashboard.bills-tour.step.vendors.tab.content'),
    },
    {
      target: createTourIdSelector(`pay-dashboard-tab-${PayDashboardTabs.Payments}`),
      title: formatMessage('activities.payDashboard.bills-tour.step.payments.tab.title'),
      description: formatMessage('activities.payDashboard.bills-tour.step.payments.tab.content'),
    },
    { ...syncNowStep, nextButtonLabel: formatMessage('activities.payDashboard.bills-tour.step.done.label') },
  ];

  const isLoading = isLoadingInboxItems || gmailSyncState === 'in-progress' || isLoadingSyncNowStep;

  const { isAwaiting: isAwaitingItemToReviewElement } = useWaitForElement({
    selector: reviewBillStepTarget,
    enabled: enabled && !!reviewBillStep && !isLoading,
  });

  return {
    isLoading: reviewBillStep ? isAwaitingItemToReviewElement : isLoading,
    steps,
  };
};
