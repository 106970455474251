import React from 'react';
import { NewSinglePaymentStepLayout } from '@melio/ap-activities';

export interface FullScreenLayoutProps {
  children: React.ReactNode;
  onClickClose: () => void;
}

export const FullScreenWizard = ({ children, onClickClose }: FullScreenLayoutProps) => {
  return (
    <NewSinglePaymentStepLayout
      headerContent={
        <NewSinglePaymentStepLayout.Header>
          <NewSinglePaymentStepLayout.CloseButton onClick={onClickClose} />
        </NewSinglePaymentStepLayout.Header>
      }
    >
      <NewSinglePaymentStepLayout.Description as="div">{children}</NewSinglePaymentStepLayout.Description>
    </NewSinglePaymentStepLayout>
  );
};
