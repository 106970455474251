import { Container, Group, Modal, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { QBDTIcon } from './icons/QBDT.icon';
import { QBOIcon } from './icons/QBO.icon';
import { XeroIcon } from './icons/Xero.icon';

export type SyncConfirmationModalProps = {
  accountingSlug: 'quickBooksOnline' | 'quickBooksDesktop' | 'xero';
  isOpen: boolean;
  isLoading: boolean;
  onContinue: VoidFunction;
  dialogWillBeShown?: boolean;
  onClose: VoidFunction;
};

export const SyncConfirmationModal = ({
  accountingSlug,
  isOpen,
  isLoading,
  onContinue,
  dialogWillBeShown,
  onClose,
}: SyncConfirmationModalProps) => {
  const { formatMessage } = useMelioIntl();

  let Icon;
  let header;
  let steps = [];
  switch (accountingSlug) {
    case 'xero':
      Icon = XeroIcon;
      header = formatMessage('widgets.syncConfirmationModal.header.xero');
      steps = [
        formatMessage('widgets.syncConfirmationModal.steps.first.xero'),
        formatMessage('widgets.syncConfirmationModal.steps.second.xero'),
        formatMessage('widgets.syncConfirmationModal.steps.third.xero'),
      ];
      break;
    case 'quickBooksOnline':
      Icon = QBOIcon;
      header = formatMessage('widgets.syncConfirmationModal.header.qbo');
      steps = [
        formatMessage('widgets.syncConfirmationModal.steps.first.qbo'),
        formatMessage('widgets.syncConfirmationModal.steps.second.qbo'),
        formatMessage('widgets.syncConfirmationModal.steps.third.qbo'),
      ];
      break;
    case 'quickBooksDesktop':
      Icon = QBDTIcon;
      header = formatMessage('widgets.syncConfirmationModal.header.qbdt');
      steps = [
        formatMessage('widgets.syncConfirmationModal.steps.first.qbdt'),
        formatMessage('widgets.syncConfirmationModal.steps.second.qbdt'),
        formatMessage('widgets.syncConfirmationModal.steps.third.qbdt'),
      ];
      break;
  }

  return (
    <Modal
      data-component="SyncConfirmationModal"
      data-testid="sync-confirmation-modal"
      isOpen={isOpen}
      onClose={onClose}
      header={header}
      primaryButton={{
        onClick: onContinue,
        variant: 'primary',
        label: formatMessage('widgets.syncConfirmationModal.continue'),
        isLoading,
        'aria-haspopup': dialogWillBeShown && ('dialog' as const),
      }}
      secondaryButton={{
        onClick: onClose,
        variant: 'secondary',
        label: formatMessage('widgets.syncConfirmationModal.close'),
      }}
    >
      <Container paddingTop="xs" paddingBottom="m">
        <Group variant="vertical" spacing="m">
          {Icon && <Icon />}
          <Text>{formatMessage('widgets.syncConfirmationModal.description')}</Text>
          <Group variant="vertical" spacing="none">
            <Text>{formatMessage('widgets.syncConfirmationModal.steps')}</Text>
            {steps.map((label) => (
              <Text key={label}>{label}</Text>
            ))}
          </Group>
        </Group>
      </Container>
    </Modal>
  );
};

SyncConfirmationModal.displayName = 'SyncConfirmationModal';
