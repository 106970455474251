import { useServiceChargeCatalogs } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';
import { useMemo } from 'react';

import { ServiceChargesTableScreen } from './screens';

type ServiceChargesTableActivityProps = {
  onError?: ARErrorFunction;
  onEdit: (itemId: string) => unknown;
  onDelete: (itemId: string) => unknown;
  onAdd: VoidFunction;
};

export const ServiceChargesTableActivity = forwardRef<ServiceChargesTableActivityProps>(
  ({ onEdit, onAdd, onError, onDelete, ...props }, ref) => {
    const { data, isLoading, isFetching } = useServiceChargeCatalogs({ onError });

    const sortedData = useMemo(() => {
      if (!data) return [];

      return data.sort((a, b) => a.name.localeCompare(b.name));
    }, [data]);

    return (
      <ServiceChargesTableScreen
        isLoading={isLoading || isFetching}
        serviceCharges={sortedData}
        onAdd={onAdd}
        onEdit={onEdit}
        onDelete={onDelete}
        {...props}
        ref={ref}
      />
    );
  }
);
