import { CardNetwork, FundingSource } from '@melio/platform-api';

export const getFundingSourceTypeById = (
  fundingSources: FundingSource[] | undefined,
  fundingSourceId: string | undefined
) => {
  const selectedFundingSource = fundingSources?.find((fs) => fs.id === fundingSourceId);
  return selectedFundingSource?.type === 'card' ? selectedFundingSource?.details.type : selectedFundingSource?.type;
};

export const getCreditCardFundingSourceNetwork = ({
  fundingSourceId,
  fundingSources,
}: {
  fundingSources: FundingSource[] | undefined;
  fundingSourceId: string | undefined | null;
}): CardNetwork | null => {
  const fundingSource = fundingSources?.find((fs) => fs.id === fundingSourceId);
  if (!fundingSource) {
    return null;
  }

  if (fundingSource.type === 'card') {
    return fundingSource.details.network;
  }

  return null;
};
