import { Group, Image, PromotionalModal, Text, Typography, useBreakpoint } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import React from 'react';

type VirtualCardInfoModalProps = {
  onClose: VoidFunction;
  addVirtualCardHandler: VoidFunction;
};
export const VirtualCardInfoModal = withAnalyticsContext<VirtualCardInfoModalProps>(
  ({ onClose, addVirtualCardHandler, setAnalyticsProperties }) => {
    const { track } = useAnalytics();
    const { formatMessage } = useMelioIntl();
    const config = useConfig();
    const { isExtraSmallScreen } = useBreakpoint();

    const { virtualCardInfoModalDesktopImg: desktopImgUrl = '', virtualCardInfoModalMobileImg: mobileImgUrl = '' } =
      config.settings.assets;

    setAnalyticsProperties({
      PageName: 'whats-a-single-use-virtual-card',
      DeliveryMethodType: 'virtual-card',
    });

    React.useEffect(() => {
      track('DeliveryMethod', 'View', {
        Intent: 'information-page',
      });
    }, [track]);

    const handleOnPrimaryButtonClick = () => {
      track('DeliveryMethod', 'Click', {
        Cta: 'send-a-single-use-virtual-card',
        Intent: 'send-vc-to-vendor',
      });
      addVirtualCardHandler();
    };

    const handleOnCloseButtonClick = () => {
      track('DeliveryMethod', 'Click', {
        Cta: 'close',
        Intent: 'close',
      });
      onClose();
    };

    const handleOnExitButtonClick = () => {
      track('DeliveryMethod', 'Click', {
        Cta: 'exit',
        Intent: 'close',
      });
      onClose();
    };

    const list = (
      <Typography.ParagraphList
        list={[
          {
            title: formatMessage('widgets.deliveryMethods.virtualCardInfoModal.listItem1.title'),
            icon: 'checked',
            content: formatMessage('widgets.deliveryMethods.virtualCardInfoModal.listItem1.description'),
          },
          {
            title: formatMessage('widgets.deliveryMethods.virtualCardInfoModal.listItem2.title'),
            icon: 'checked',
            content: formatMessage('widgets.deliveryMethods.virtualCardInfoModal.listItem2.description'),
          },
        ]}
        type="unordered"
      />
    );

    return (
      <PromotionalModal
        data-testid="virtual-card-sbc-info-modal"
        isOpen
        asset={
          <Image
            objectFit="cover"
            src={isExtraSmallScreen ? mobileImgUrl : desktopImgUrl}
            alt="virtual card info illustration"
          />
        }
        onClose={handleOnExitButtonClick}
        header={formatMessage('widgets.deliveryMethods.virtualCardInfoModal.title')}
        primaryButton={{
          label: formatMessage('widgets.deliveryMethods.virtualCardInfoModal.primaryButton'),
          variant: 'primary',
          onClick: handleOnPrimaryButtonClick,
        }}
        secondaryButton={{
          label: formatMessage('widgets.deliveryMethods.virtualCardInfoModal.secondaryButton'),
          variant: 'secondary',
          onClick: handleOnCloseButtonClick,
        }}
      >
        <Group variant="vertical">
          <Text>{formatMessage('widgets.deliveryMethods.virtualCardInfoModal.subtitle')}</Text>
          {list}
        </Group>
      </PromotionalModal>
    );
  }
);
