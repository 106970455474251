import { withSystemMessageProvider } from '@melio/platform-utils';

import { MemoToVendorScreen } from './screens';
import { MemoToVendorActivityProps } from './types';
import { useMemoToVendor } from './useMemoToVendor';

export type ActivityProps = MemoToVendorActivityProps & {
  onBack: VoidFunction;
  step: number;
  totalSteps: number;
};

export const MemoToVendorActivity = withSystemMessageProvider(
  ({
    onBack,
    onClose,
    onDone,
    onError,
    step,
    totalSteps,
    memo,
    defaultMemo,
    vendorId,
    isUpdatingMemo,
    memoValidation,
  }: ActivityProps) => {
    const { hideEmailField, vendorName, vendorEmail, isSaving, mode, memoString, isLoading, handleDone } =
      useMemoToVendor({
        vendorId,
        isUpdatingMemo,
        defaultMemo,
        memo,
        onError,
        onDone,
      });

    return (
      <MemoToVendorScreen
        hideEmailField={hideEmailField}
        mode={mode}
        vendorName={vendorName}
        isLoading={isLoading}
        isSaving={isSaving}
        step={step}
        totalSteps={totalSteps}
        memo={memoString}
        email={vendorEmail}
        onBack={onBack}
        onClose={onClose}
        onDone={handleDone}
        memoValidation={memoValidation}
      />
    );
  }
);

MemoToVendorActivity.displayName = 'MemoToVendorActivity';
