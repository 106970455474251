import React from 'react';
import { Outlet } from 'react-router-dom';

import { AppLayout } from '@/cl/layouts/app-layout/appLayout.component';

export const OnlyFooterLayout = () => {
  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  );
};
