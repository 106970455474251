import { usePayment } from '@melio/platform-api';

export const usePaymentDrawerData = (paymentId?: string) =>
  usePayment({
    id: paymentId,
    refetchOnMount: 'always',
    params: {
      expand: [
        'vendor',
        'createdBy',
        'deliveryMethod',
        'fundingSource',
        'timeline',
        'fees',
        'subscriptionOccurrence.billSubscription',
        'paymentActions',
        'loan',
        'paymentPending',
        'bills',
        'paymentFailure',
      ],
    },
  });
