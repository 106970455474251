import { Button, Container, Group, Icon, NakedButton, Text } from '@melio/penny';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';

import { useSubscriptionContext } from '../../../../context';
import { usePlanInfo } from '../../../../hooks';

export type AccountingClientSubscriptionPlanSelectedScreenProps = {
  onGoToCheckout: () => void;
  onGoToAllPlans: () => void;
  planId: string;
  firmName: string;
};

export const AccountingClientSubscriptionPlanSelectedComponent =
  withAnalyticsContext<AccountingClientSubscriptionPlanSelectedScreenProps>(
    ({ setAnalyticsProperties, onGoToCheckout, onGoToAllPlans, planId, firmName }) => {
      const { formatMessage } = useMelioIntl();
      const { planName } = usePlanInfo(planId);
      const { clientPlanDiscountPercent } = useSubscriptionContext();

      setAnalyticsProperties({
        PageName: 'accounting-client-plan-selected',
        Flow: 'subscription',
      });

      return (
        <Container
          height="full"
          alignItems="center"
          justifyContent="center"
          data-testid="accounting-client-subscription-plan-selected"
        >
          <Group variant="vertical" alignItems="center">
            <Group variant="vertical" alignItems="center" textAlign="center" spacing="m">
              <Icon color="brand" type="crown" />
              <Text as="h2" textStyle="heading2Semi">
                {formatMessage(`activities.accountingClientSubscriptionPlanSelected.title`, {
                  planName,
                })}
              </Text>
              <Text as="h2" textStyle="body2">
                {formatMessage(`activities.accountingClientSubscriptionPlanSelected.subtitle`, {
                  firmName,
                  planName,
                  clientPlanDiscountPercent,
                })}
              </Text>
            </Group>
            <Button
              data-testid="accounting-client-subscription-plan-selected-checkout"
              onClick={onGoToCheckout}
              variant="primary"
              size="large"
              label={formatMessage('activities.accountingClientSubscriptionPlanSelected.continueButton')}
            />
            <NakedButton
              data-testid="accounting-client-subscription-plan-selected-all-plans"
              onClick={onGoToAllPlans}
              size="medium"
              label={formatMessage('activities.accountingClientSubscriptionPlanSelected.seeAllPlansButton')}
            />
          </Group>
        </Container>
      );
    }
  );
