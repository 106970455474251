import { ConversionUtils } from '@melio/ar-domain';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { GoodsReceivedModalScreen } from './screens';

type GoodsReceivedModalActivityProps = {
  isOpen: boolean;
  isSaving?: boolean;
  onClose: VoidFunction;
  onDone: (isGoodsReceived: boolean) => void;
  currency?: string;
};

export const GoodsReceivedModalActivity = forwardRef<GoodsReceivedModalActivityProps>(
  ({ isOpen, isSaving, onClose, onDone, currency }, ref) => {
    const { goodsReceivedAmountThreshold } = useConfig().settings;
    const amountThreshold = ConversionUtils.fromDollarsToCents(goodsReceivedAmountThreshold);

    return (
      <GoodsReceivedModalScreen
        isOpen={isOpen}
        isSaving={isSaving}
        onClose={onClose}
        onDone={onDone}
        amountThreshold={amountThreshold}
        currency={currency}
        ref={ref}
      />
    );
  }
);
GoodsReceivedModalActivity.displayName = 'GoodsReceivedModalActivity';
