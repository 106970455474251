import { PaymentApprovalDecisionStatusEnum, Vendor, VendorEBillStatusEnum } from '@melio/platform-api';
import { FormattedCurrency, FormattedDate, FormattedMessage } from '@melio/platform-provider';
import { groupBy } from 'lodash';

import { PaymentDetails } from './PaymentsScheduled.types';

export function getPaymentsTitle(payments: PaymentDetails[]) {
  if (payments.length < 1) {
    return;
  }

  const [payment] = payments;

  const isRtpPayment = isRtpDeliveryPreferenceTypePayment(payment);
  if (isRtpPayment) {
    return <FormattedMessage id="activities.paymentScheduled.screens.paymentScheduled.title.rtp" />;
  }

  return (
    <FormattedMessage
      id={`activities.paymentScheduled.screens.paymentScheduled.title.${
        payments.length > 1 ? 'multiplePayments' : 'singlePayment'
      }`}
      values={{
        quantity: payments.length,
      }}
    />
  );
}

export const getSinglePaymentDetails = (payments: PaymentDetails[]) => {
  if (payments.length > 1 || !payments.length) {
    return;
  }
  return payments[0];
};

export const isVendorCapableEBillSubscription = (vendor?: Vendor) =>
  vendor?.eBillStatus === VendorEBillStatusEnum.Capable;

export function getPaymentsDescription(
  payments: PaymentDetails[],
  currency?: string,
  schedulingPreference: 'byScheduleDate' | 'byDeliveryDate' = 'byScheduleDate'
) {
  if (payments.length < 1) {
    return;
  }
  const byDeliveryDate = schedulingPreference === 'byDeliveryDate';
  const paymentsAmount = payments.reduce((sum, { paymentAmount }) => sum + paymentAmount, 0);
  const vendorIds = Object.keys(groupBy(payments, (payment) => payment.vendor.id));
  const scheduledDates = Object.keys(groupBy(payments, (payment) => payment.scheduledDate));

  const firstPayment = payments[0] as PaymentDetails;
  const firstPaymentFormattedScheduledDate = (
    <FormattedDate date={firstPayment.scheduledDate} options={{ dateStyle: 'medium' }} />
  );
  const firstPaymentFormattedDeliveryDate = (
    <FormattedDate date={firstPayment.deliveryDate} options={{ dateStyle: 'medium' }} />
  );

  const formattedAmount = <FormattedCurrency amount={paymentsAmount} currency={currency} />;
  const [payment] = payments;

  const isRtpPayment = isRtpDeliveryPreferenceTypePayment(payment);
  if (isRtpPayment && payment) {
    return (
      <FormattedMessage
        id="activities.paymentScheduled.screens.paymentScheduled.description.singlePayment.rtp"
        values={{
          amount: <FormattedCurrency amount={payment.paymentAmount} currency={currency} />,
          vendorName: payment.vendor.name,
        }}
      />
    );
  }

  if (payments.length === 1) {
    const singlePaymentScheduledDescriptionId = byDeliveryDate
      ? 'activities.paymentScheduled.screens.paymentScheduled.description.singlePayment.byDeliveryDate'
      : 'activities.paymentScheduled.screens.paymentScheduled.description.singlePayment';
    return (
      <FormattedMessage
        id={singlePaymentScheduledDescriptionId}
        values={{
          amount: formattedAmount,
          vendorName: firstPayment.vendor.name,
          scheduledDate: firstPaymentFormattedScheduledDate,
          deliveryDate: firstPaymentFormattedDeliveryDate,
        }}
      />
    );
  }

  if (vendorIds.length === 1) {
    if (scheduledDates.length === 1) {
      const multiplePaymentsSingleVendorPaymentScheduledId = byDeliveryDate
        ? 'activities.paymentScheduled.screens.paymentScheduled.description.multiplePayments.singleVendor.singleDeliveryDate'
        : 'activities.paymentScheduled.screens.paymentScheduled.description.multiplePayments.singleVendor.singleScheduledDate';
      return (
        <FormattedMessage
          id={multiplePaymentsSingleVendorPaymentScheduledId}
          values={{
            amount: formattedAmount,
            vendorName: firstPayment.vendor.name,
            scheduledDate: firstPaymentFormattedScheduledDate,
            deliveryDate: firstPaymentFormattedDeliveryDate,
          }}
        />
      );
    }
    return (
      <FormattedMessage
        id="activities.paymentScheduled.screens.paymentScheduled.description.multiplePayments.singleVendor.multipleScheduledDates"
        values={{
          amount: formattedAmount,
          vendorName: firstPayment.vendor.name,
        }}
      />
    );
  }
  if (scheduledDates.length === 1) {
    const multiplePaymentMultipleVendorsDescription = byDeliveryDate
      ? 'activities.paymentScheduled.screens.paymentScheduled.description.multiplePayments.multipleVendors.singleDeliveryDate'
      : 'activities.paymentScheduled.screens.paymentScheduled.description.multiplePayments.multipleVendors.singleScheduledDate';
    return (
      <FormattedMessage
        id={multiplePaymentMultipleVendorsDescription}
        values={{
          amount: formattedAmount,
          scheduledDate: firstPaymentFormattedScheduledDate,
          deliveryDate: firstPaymentFormattedDeliveryDate,
        }}
      />
    );
  }
  return (
    <FormattedMessage
      id="activities.paymentScheduled.screens.paymentScheduled.description.multiplePayments.multipleVendors.multipleScheduledDates"
      values={{
        amount: formattedAmount,
      }}
    />
  );
}

export const getSecondaryPaymentsDescriptionLabel = (payments: PaymentDetails[]) => {
  const hasPendingPayments = payments.some(
    (payment) => payment.approvalDecisionStatus === PaymentApprovalDecisionStatusEnum.Pending
  );

  if (hasPendingPayments) {
    return 'activities.paymentScheduled.screens.paymentScheduled.secondaryDescription.approvalRequirement';
  }

  return null;
};

export function getNotifyVendorsLabel(vendorsIds: Vendor['id'][]) {
  return (
    <FormattedMessage
      id={`activities.paymentScheduled.screens.paymentScheduled.notify.${
        vendorsIds.length > 1 ? 'multipleVendors' : 'singleVendor'
      }`}
    />
  );
}

export const isRtpDeliveryPreferenceTypePayment = (payment: PaymentDetails | undefined): boolean =>
  !!payment && payment.deliveryPreferenceType === 'rtp';
