/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import qs from 'qs';
import { useSetRecoilState } from 'recoil';
import { LocalStorageKeys } from '@melio/local-storage';
import { Button } from '@melio/penny';
import { PartnerName } from '@melio/platform-api';
import { useLocation } from '@melio/platform-utils';

import { authGoogleCreateNewDemoAccount, getGoogleSSOUrl } from '@/api/auth-demo/auth-demo.api';
import { AbsoluteCenter } from '@/cl/components/AbsoluteCenter/AbsoluteCenter.component';
import { Loader } from '@/cl/components/Loader/Loader.component';
import { usePartnerLocalStorage } from '@/hooks/partners/usePartnerLocalStorage';
import { useRouter } from '@/hooks/router.hooks';
import { useClearSession, useResetAccessToken } from '@/hooks/session.hooks';
import { useAppRedirectData } from '@/hooks/useAppRedirect.hooks';
import { appAccountUrlSelector } from '@/store/app/app.model';
import { generateAccountOpenIdUrl } from '@/utils/generateAccountUrl';
import { getPartnerName } from '@/utils/partner.utils';

export const AuthDemoOpenIdRedirect = ({ accountId = 'new' }: { accountId?: string }) => {
  const partnerName = getPartnerName();
  const localStorage = usePartnerLocalStorage();
  const [isLoading, setIsLoading] = useState(false);
  const signIn = () => {
    setIsLoading(true);
    window.location.href = getGoogleSSOUrl(partnerName, accountId);
  };
  const { goToDemoCallback } = useRouter();

  useEffect(() => {
    const accessToken = localStorage.getItem(LocalStorageKeys.googleAccessToken);
    if (accessToken && accountId !== 'new') {
      goToDemoCallback(accessToken, accountId);
    }
  }, [localStorage]);

  return (
    <AbsoluteCenter>
      <Button onClick={signIn} label={'Sign in with Google with OpenID redirect'} isLoading={isLoading} />
    </AbsoluteCenter>
  );
};

export const AuthDemoOpenIdCallback = () => {
  const partnerName = getPartnerName();
  const [status, setStatus] = React.useState<'success' | 'error' | undefined>();
  const { goToAppRedirect, goToSessionExpired } = useRouter();
  const resetAccessToken = useResetAccessToken();
  const clearSession = useClearSession();
  const setAccountUrl = useSetRecoilState(appAccountUrlSelector);
  const localStorage = usePartnerLocalStorage();
  const { setAppRedirectData } = useAppRedirectData();

  const location = useLocation();
  const { google_access_token: googleAccessToken, account_id: accountId } = qs.parse(location.search.substring(1));

  const startDemo = React.useCallback(
    async (partnerName: string, accountId: string, googleAccessToken: string) => {
      try {
        const {
          data: { data },
        } = await authGoogleCreateNewDemoAccount(googleAccessToken, partnerName as PartnerName, accountId!);
        const { accessToken, refreshToken, accountId: newAccountId } = data;
        if (accessToken && newAccountId) {
          localStorage.setItem(LocalStorageKeys.googleAccessToken, googleAccessToken);
          resetAccessToken(accessToken, refreshToken);
          setAccountUrl(generateAccountOpenIdUrl({ accountId: newAccountId, partnerName: partnerName as PartnerName }));
          setStatus('success');
        }
      } catch (e) {
        localStorage.removeItem(LocalStorageKeys.googleAccessToken);
        await clearSession();
        setStatus('error');
      }
    },
    [clearSession, resetAccessToken, localStorage],
  );

  useEffect(() => {
    if (partnerName && accountId && googleAccessToken) {
      startDemo(partnerName as string, accountId as string, googleAccessToken as string);
    }
  }, [partnerName, accountId, googleAccessToken]);

  useEffect(() => {
    if (status === 'success') {
      setAppRedirectData({ isFirstTimeLogin: accountId === 'new' });
      goToAppRedirect();
    } else if (status === 'error') {
      goToSessionExpired();
    }
  }, [status]);

  return <Loader isAbsoluteCenter />;
};
