import { useState } from 'react';

import { usePlanInfo, usePlansTiers } from '../../../hooks';
import { SubscriptionGraceSwitchPlanConfirmation } from './SubscriptionGraceSwitchPlanConfirmation';
import { SubscriptionGraceTimeToDecideModal } from './SubscriptionGraceTimeToDecideModal';

export type SubscriptionGraceTimeToDecideFlowProps = {
  isOpen: boolean;
  onDismiss: () => void;
};

export const SubscriptionGraceTimeToDecideFlow = ({ isOpen, onDismiss }: SubscriptionGraceTimeToDecideFlowProps) => {
  const [showGraceTimeToDecideModal, setShowGraceTimeToDecideModal] = useState<boolean>(true);
  const [showgraceSwitchPlanConfirmationModal, setShowgraceSwitchPlanConfirmationModal] = useState<boolean>(false);
  const { getFreePlan } = usePlansTiers();
  const freePlan = getFreePlan();
  const { planName } = usePlanInfo(freePlan?.id);

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <SubscriptionGraceTimeToDecideModal
        isOpen={showGraceTimeToDecideModal}
        planName={planName}
        onDismiss={onDismiss}
        onConfirmSwitchSubscriptionPlan={() => {
          {
            setShowGraceTimeToDecideModal(false);
            setShowgraceSwitchPlanConfirmationModal(true);
          }
        }}
      />
      <SubscriptionGraceSwitchPlanConfirmation
        isOpen={showgraceSwitchPlanConfirmationModal}
        planName={planName}
        onDismiss={() => {
          onDismiss();
          setShowgraceSwitchPlanConfirmationModal(false);
          setShowGraceTimeToDecideModal(true);
        }}
      />
    </>
  );
};
