import { Button, Container, Grid, Illustration, Layout, LoadingContainer, Text } from '@melio/penny';
import { useAccount, useOrganizations } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { Logger } from '@melio/platform-logger';
import { PageTitle, useSystemMessage } from '@melio/platform-utils';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';

import { useIsSubscriptionsEnabledBase } from '../../../hooks';

export const GraceEndPeriodForExternalAccountant = ({
  goToAssignClientPlan,
  onSwitchOrganization,
}: {
  goToAssignClientPlan: ({ accountingClientId }: { accountingClientId: string }) => void;
  onSwitchOrganization: (organizationId: string) => Promise<void>;
}) => {
  const [isSwitching, setIsSwitching] = useState<boolean>(false);
  const { formatMessage } = useMelioIntl();
  const { showMessage } = useSystemMessage();
  const isSubscriptionsEnabled = useIsSubscriptionsEnabledBase();
  const { data: accountData } = useAccount({ id: 'me', enabled: isSubscriptionsEnabled });
  const { data: organizations = [], isLoading } = useOrganizations();

  const accountingFirmOrganization = !isLoading
    ? organizations.find((org) => org.companyType === 'accounting-firm')
    : undefined;

  if (!isSubscriptionsEnabled) {
    return <Navigate to="/error" />;
  }

  const companyName = accountData?.company.name;

  const onHandleClick = async () => {
    setIsSwitching(true);
    try {
      const accountingFirmOrganizationId = accountingFirmOrganization?.id;
      const accountingClientId = accountData?.organizationId;
      if (!accountingFirmOrganizationId || !accountingClientId) {
        throw new Error('Missing AccountingFirmOrganizationId or accountingClientId');
      }
      await onSwitchOrganization(accountingFirmOrganizationId);
      goToAssignClientPlan({ accountingClientId });
    } catch (error) {
      setIsSwitching(false);
      showMessage({
        type: 'error',
        title: formatMessage('activities.accountants.firmDashboard.changeClientPlan.toast.error'),
      });
      Logger.log(`Error while trying switch to accounting firm organization: ${(error as Error).message}`);
    }
  };

  return (
    <LoadingContainer isLoading={isSwitching}>
      <Layout data-testid="subscription-grace-end-period-accounting-firm" variant="6Columns" backgroundColor="white">
        <Container
          paddingX="xxs"
          paddingY="xxs"
          height="full"
          textAlign="center"
          data-testid="subscription-grace-end-period-non-owner"
        >
          <Grid justifyItems="center" alignContent="center" height="full" textAlign="center" gap="s">
            <Illustration size="large" type="blocked" />
            <Grid gap="s">
              <PageTitle data-testid="subscription-grace-end-period-accounting-firm-title" textStyle="heading2Semi">
                {formatMessage('activities.subscription.graceEndPeriod.accountingFirm.title')}
              </PageTitle>

              <Text color="neutral.darkest" data-testid="subscription-grace-end-period-non-owner-description">
                {formatMessage('activities.subscription.graceEndPeriod.accountingFirm.description', {
                  companyName,
                })}
              </Text>
              <Container>
                <Button
                  data-testid="subscription-grace-end-period-accounting-firm-button"
                  label={formatMessage('activities.subscription.graceEndPeriod.accountingFirm.action.primary')}
                  onClick={() => void onHandleClick()}
                />
              </Container>
            </Grid>
          </Grid>
        </Container>
      </Layout>
    </LoadingContainer>
  );
};
