import { useState } from 'react';
import { AccountingPlatformSyncConnectionErrorEnum, SyncFlowEnum } from '@melio/ap-activities';
import { SyncConfirmationModal } from '@melio/ap-widgets';
import { AccountingPlatform, AccountingPlatformSlug } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';
import { useSubscriptionFeature } from '@melio/subscriptions';

import { useAccountingPlatformConnect, useAccountingPlatformDisconnect } from '@/hooks/accountingPlatforms.hooks';
import { AccountingPlatformCard } from './AccountingPlatformCard.widget';

type Props = {
  syncError?: AccountingPlatformSyncConnectionErrorEnum;
  organizationId: string;
  accountingPlatform: AccountingPlatform;
};

export const AccountingPlatformCardXero = ({ syncError, organizationId, accountingPlatform }: Props) => {
  const {
    settings: {
      accountingSoftware: { isConnectConfirmationEnabled },
    },
  } = useConfig();

  const { accountingSlug } = accountingPlatform;

  const { isLoading: isLoadingConnect, connectToAccountingPlatform } = useAccountingPlatformConnect({
    flowToReturn: SyncFlowEnum.Setting,
    accountingPlatformId: accountingPlatform.id,
  });

  const { isLoading: isLoadingDisconnect, disconnectAccountingPlatform } = useAccountingPlatformDisconnect({
    accountingPlatformId: accountingPlatform.id,
    accountingSlug,
    organizationId,
  });

  const { tryUseFeature: tryUseConnectXeroFeature, isEligible } = useSubscriptionFeature({
    featureName: 'accountingPlatformXero',
  });

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleCardOnConnectClicked = () => {
    if (isConnectConfirmationEnabled) {
      setIsModalOpen(true);
      return;
    }

    tryUseConnectXeroFeature({ onFeatureIsEligible: connectToAccountingPlatform });
  };

  const handleModalOnContinueClicked = () => {
    tryUseConnectXeroFeature({ onFeatureIsEligible: connectToAccountingPlatform });
    setIsModalOpen(false);
  };

  if (accountingSlug !== AccountingPlatformSlug.Xero) {
    return null;
  }

  const dialogWillBeShown = !isEligible && isConnectConfirmationEnabled;

  return (
    <>
      <AccountingPlatformCard
        accountingPlatform={accountingPlatform}
        onConnectClicked={handleCardOnConnectClicked}
        dialogWillBeShown={dialogWillBeShown}
        onDisconnectClicked={disconnectAccountingPlatform}
        isLoading={isLoadingDisconnect || isLoadingConnect}
        syncError={syncError}
      />
      <SyncConfirmationModal
        accountingSlug={AccountingPlatformSlug.Xero}
        isOpen={isModalOpen}
        isLoading={isLoadingConnect}
        onClose={() => setIsModalOpen(false)}
        onContinue={handleModalOnContinueClicked}
        dialogWillBeShown={dialogWillBeShown}
      />
    </>
  );
};
