import { useFeeCatalog, useFreeChecks } from '@melio/platform-api';

const DEFAULT_WITHOUT_PROMOTION = {
  total: 0,
  available: 0,
};

export const useCheckFeePromotions = () => {
  const { data: feeCatalogEntries, isLoading: isLoadingFeeCatalog } = useFeeCatalog();

  const isChecksPromotionSupported = feeCatalogEntries?.some(
    (entry) => entry.feeType === 'ach-to-check' && entry.promotedFee && entry.promotedFee.value === 0
  );

  const { data: freeChecksData, isLoading: isLoadingFreeChecks } = useFreeChecks({
    enabled: !!isChecksPromotionSupported,
  });

  return {
    isLoading: isLoadingFeeCatalog || isLoadingFreeChecks,
    freeChecks: freeChecksData || DEFAULT_WITHOUT_PROMOTION,
  };
};
