import { Brand, Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';

import { BrandColoredLink } from '../../../BrandColoredLink/BrandColoredLink';

export const TermsAndConditions: React.VFC = () => {
  const { formatMessage } = useMelioIntl();
  const { links: linksConfig } = useConfig();

  const links = {
    privacyPolicy: (
      <BrandColoredLink
        textStyle="caption1"
        href={linksConfig['creditKey.legal.privacyPolicy']}
        label={formatMessage('activities.repaymentTerms.screens.repaymentTerms.TermsAndConditions.privacyPolicy')}
      />
    ),
    termsOfService: (
      <BrandColoredLink
        textStyle="caption1"
        href={linksConfig['creditKey.legal.termsOfService']}
        label={formatMessage('activities.repaymentTerms.screens.repaymentTerms.TermsAndConditions.termsOfService')}
      />
    ),
    creditkey: (
      <BrandColoredLink
        textStyle="caption1"
        href={linksConfig['activities.repaymentTerms.screens.repaymentTerms.TermsAndConditions.creditKey']}
        label={formatMessage('activities.repaymentTerms.screens.repaymentTerms.TermsAndConditions.creditKey')}
      />
    ),
  };

  return (
    <Group data-testid="financing-terms-and-conditions">
      <Brand type="credit-key" variant="neutral" />
      <Text textStyle="caption1" color="global.neutral.900">
        {formatMessage('activities.repaymentTerms.screens.repaymentTerms.TermsAndConditions.label', links)}
      </Text>
    </Group>
  );
};
