import { NotificationBanner } from '@melio/ap-widgets';

import { useAccountCentricViewCase } from '@/hooks/useAccountCentricViewCase';
import { usePlatformIntl } from '@/translations/Intl';
import { AccountCentricViewMessagingModal } from './components/AccountCentricViewMessagingModal.component';

export const AccountCentricViewMessaging = ({ sticky }: { sticky?: boolean }) => {
  const { formatMessage } = usePlatformIntl();
  const { shouldShowBanner } = useAccountCentricViewCase();

  return shouldShowBanner ? (
    <NotificationBanner
      content={formatMessage('widgets.accountCentricViewMessaging.banner.title', {
        link: <AccountCentricViewMessagingModal />,
      })}
      sticky={sticky}
    />
  ) : null;
};
