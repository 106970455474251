import { Logger } from '@melio/platform-logger';
import { isNil } from 'lodash';
import { useEffect } from 'react';

const isDefined = (value: number | undefined | null): value is number => !isNil(value);
export function useNonPositiveAmountsEffect(
  effect: VoidFunction,
  amounts: Array<number | undefined | null> | undefined = []
) {
  useEffect(() => {
    const havingNonPositiveBalance = amounts.filter(isDefined).filter((amount) => amount <= 0);
    if (havingNonPositiveBalance.length > 0) {
      Logger.log(`having ${havingNonPositiveBalance.length} amounts with non positive amounts`);
      effect();
    }
  }, [amounts, effect]);
}
