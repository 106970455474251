import { addDays, getDate, getDay, getDaysInMonth, getMonth, getYear, isSameDay, setDate, subDays } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { Day, Month, WeekDay } from './types';

/**
 * A utility to get a matrix of dates per weekday per a single month.
 * eg:
 * ```
 * [[1,8,15,22,29], [2,9,16,23,30], ...]
 * ```
 * @param year
 * @param month
 * @returns a matrix of weekday => dates
 */
const getMatrix = (year: number, month: Month) => {
  // eslint-disable-next-line no-restricted-syntax
  const date = new Date(year, month, 1);
  const matrix: number[][] = Array.from({ length: 7 }).map(() => []);

  for (let i = 0; i < getDaysInMonth(month); i++) {
    matrix[getDay(setDate(date, i + 1))]?.push(i + 1);
  }

  return matrix;
};

/**
 * Generates and array with the dates of a given week day in the given month
 * For instance:
 * ```
 * [5, 12, 19, 26]
 * ```
 * @param year the year
 * @param month the month (0-11)
 * @param weekday the weekday (0-6)
 * @returns an array with the dates
 */
const getWeekDayInMonth = (year: number, month: Month, weekday: WeekDay) => getMatrix(year, month)[weekday] as number[];

/**
 * Checks is a given date falls on a US holiday
 *
 * @param _date THe date to check
 * @param timezone the timezone to convert
 * @returns
 */
export const isHoliday = (_date: Date, timezone = 'America/New_York') => {
  const date = utcToZonedTime(_date, timezone);
  const year = getYear(date);
  const month = getMonth(date);
  const day = getDate(date);
  const weekday = getDay(date);
  const isFriday = weekday == Day.FRI;
  const isMonday = weekday == Day.MON;

  const weekDaysInMonth = getWeekDayInMonth(year, month, weekday);

  /**
   * A utility to calculate if the date matches the nth weekday of a given month
   *
   * For instance: The 3rd Monday of September
   * @example IsNthWeekday(Month.SEP, Day.Monday, 3)
   * @param _month the month (0-11)
   * @param _weekday the weekday (0-6)
   * @param n the nth instance
   */
  const IsNthWeekday = (_month: Month, _weekday: WeekDay, n: number) =>
    month == _month && weekday == _weekday && weekDaysInMonth[n - 1] == day;

  /**
   * A utility to calculate if the date matches the nth weekday of a given month from the end
   *
   * For instance: The 2nd last Monday of November
   * @example IsNthWeekdayFromEnd(Month.NOV, Day.Monday, 2)
   * @param _month the month (0-11)
   * @param _weekday the weekday (0-6)
   * @param n the nth instance
   */
  const IsNthWeekdayFromEnd = (_month: Month, _weekday: WeekDay, n: number) =>
    month == _month && weekday == _weekday && weekDaysInMonth[weekDaysInMonth.length - n] == day;

  /**
   * A utility to calculate if the date matches a specific holiday date
   * Or if the given holiday falls on a Sunday, use the following Monday
   * Or if the given holiday falls on a Saturday, use the previous Friday
   *
   * For instance: July 4th
   * @example isSpecificDate(Month.JUL, 4);
   * @param the month (0-11)
   * @param the day in month (0-31)
   */
  const isSpecificDate = (_month: Month, _day: number) => {
    // eslint-disable-next-line no-restricted-syntax
    const holiday = new Date(year, _month, _day);

    if (isSameDay(holiday, date)) {
      return true;
    }

    const dayBefore = subDays(holiday, 1);

    if (getMonth(dayBefore) == month && getDate(dayBefore) == day && isFriday) {
      return true;
    }

    const dayAfter = addDays(holiday, 1);

    if (getMonth(dayAfter) == month && getDate(dayAfter) == day && isMonday) {
      return true;
    }

    return false;
  };

  // New Year's Day
  if (isSpecificDate(Month.JAN, 1)) {
    return true;
  }

  // Juneteenth Day
  if (isSpecificDate(Month.JUN, 19)) {
    return true;
  }

  // Independence Day
  if (isSpecificDate(Month.JUL, 4)) {
    return true;
  }

  // Veterans Day
  if (isSpecificDate(Month.NOV, 11)) {
    return true;
  }

  // Christmas Day
  if (isSpecificDate(Month.DEC, 25)) {
    return true;
  }

  // Martin Luther King Jr. Day
  if (IsNthWeekday(Month.JAN, Day.MON, 3)) {
    return true;
  }

  // Presidents Day/Washington's Birthday
  if (IsNthWeekday(Month.FEB, Day.MON, 3)) {
    return true;
  }

  // Labor Day
  if (IsNthWeekday(Month.SEP, Day.MON, 1)) {
    return true;
  }

  // Columbus Day
  if (IsNthWeekday(Month.OCT, Day.MON, 2)) {
    return true;
  }

  // Thanksgiving Day
  if (IsNthWeekday(Month.NOV, Day.THU, 4)) {
    return true;
  }

  // Memorial Day
  if (IsNthWeekdayFromEnd(Month.MAY, Day.MON, 1)) {
    return true;
  }

  return false;
};
