import { MemoDetails, MemoToVendorForm } from '@melio/ap-widgets';
import { useFormSubmissionController } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { useMonitoring } from '@melio/platform-monitoring';
import { useMelioIntl } from '@melio/platform-provider';
import { SystemMessageDisplay } from '@melio/platform-utils';
import { useCallback } from 'react';

import { MonitoredAction } from '../../../../monitoring';
import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';
import { MemoToVendorScreenProps } from './types';

type ScreenProps = MemoToVendorScreenProps & {
  step: number;
  totalSteps: number;
  onBack: VoidFunction;
};

export const MemoToVendorScreen = ({
  hideEmailField,
  vendorName,
  step,
  totalSteps,
  isLoading,
  mode = 'add',
  isSaving,
  memo = '',
  email = '',
  onBack,
  onClose,
  onDone,
  memoValidation,
  ...props
}: ScreenProps) => {
  const { formatMessage } = useMelioIntl();

  const { onSubmissionStateChange, submitButtonProps, cancelButtonProps } = useFormSubmissionController<MemoDetails>();

  const { startAction, endAction } = useMonitoring<MonitoredAction>();
  const endActionMonitoring = useCallback(() => {
    endAction('funding_source_selection');
    endAction('goods_received_flow');
    endAction('vendor_missing_details_flow');
    endAction('mcc_flow');
    endAction('reconciliation_flow');
    endAction('delivery_method_selection');
    endAction('payment_purpose');
    endAction('repayment_terms');
    endAction('delivery_date_selection');
  }, [endAction]);

  const defaultValues = {
    memo,
    email,
  };
  useAnalyticsView('NoteForVendor');

  return (
    <NewSinglePaymentStepLayout
      data-component="MemoToVendorActivity.MemoToVendorScreen"
      data-testid="memo-to-vendor-activity-memo-to-vendor-screen"
      ref={endActionMonitoring}
      {...props}
      isLoading={isLoading}
      headerContent={
        <NewSinglePaymentStepLayout.Header>
          <NewSinglePaymentStepLayout.CloseButton onClick={onClose} />
          <NewSinglePaymentStepLayout.ProgressBar currentStep={step} totalSteps={totalSteps} />
          <NewSinglePaymentStepLayout.BackButton {...cancelButtonProps} onClick={onBack} />
        </NewSinglePaymentStepLayout.Header>
      }
      footerContent={
        <NewSinglePaymentStepLayout.Actions>
          <NewSinglePaymentStepLayout.NextButton
            {...submitButtonProps}
            label={formatMessage('activities.memoToVendor.screens.memoToVendor.continue')}
          />
        </NewSinglePaymentStepLayout.Actions>
      }
    >
      <SystemMessageDisplay />
      <NewSinglePaymentStepLayout.Title>
        {formatMessage(`activities.memoToVendor.screens.memoToVendor.${mode}.title`, {
          name: vendorName,
        })}
      </NewSinglePaymentStepLayout.Title>
      <NewSinglePaymentStepLayout.Description>
        {formatMessage('activities.memoToVendor.screens.memoToVendor.description')}
      </NewSinglePaymentStepLayout.Description>
      <NewSinglePaymentStepLayout.Content>
        <MemoToVendorForm
          onSubmit={({ memo, email }) => {
            startAction('memo_to_vendor');
            onDone(memo, email);
          }}
          onSubmissionStateChange={onSubmissionStateChange}
          isSaving={isSaving}
          vendorName={vendorName}
          defaultValues={defaultValues}
          hideEmailField={hideEmailField}
          memoValidation={memoValidation}
        />
      </NewSinglePaymentStepLayout.Content>
    </NewSinglePaymentStepLayout>
  );
};

MemoToVendorScreen.displayName = 'MemoToVendorActivity.MemoToVendorScreen';
