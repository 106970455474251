import { FormSelectNewOption } from '@melio/penny';
import { useState } from 'react';

export const useStateSelect = (stateOptions: FormSelectNewOption[]) => {
  const [filteredOptions, setFilteredOptions] = useState<FormSelectNewOption[]>(stateOptions);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilteredOptions(stateOptions.filter((city) => city.label.toLowerCase().includes(e.target.value.toLowerCase())));
  };

  return { filteredStateOptions: filteredOptions, handleChange };
};
