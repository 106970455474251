import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';

export const useItemsTabHighlightedRows = (newTabItemsIds: string[], enabled = true, onHighlight?: VoidFunction) => {
  const [highlightedRowIds, setHighlightedRowIds] = useState<string[]>([]);
  const areNewItemsHighlighted = isEqual(highlightedRowIds, newTabItemsIds);

  useEffect(() => {
    if (areNewItemsHighlighted || !enabled) {
      return;
    }

    setHighlightedRowIds(newTabItemsIds);
    onHighlight?.();
    const highlightedRowsTimer = setTimeout(() => {
      setHighlightedRowIds([]);
    }, 3000);

    return () => {
      if (!areNewItemsHighlighted) {
        clearTimeout(highlightedRowsTimer);
      }
    };
  }, [highlightedRowIds, newTabItemsIds, onHighlight, areNewItemsHighlighted, enabled]);

  return highlightedRowIds;
};
