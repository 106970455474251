import { hasRequiredAddressFields, isPOBox } from '@melio/ap-domain';
import { Form, SectionBanner, SelectOption, useMelioForm } from '@melio/penny';
import { useTrackAnalyticsOnFailedFormSubmit } from '@melio/platform-analytics';
import {
  Account,
  Address,
  Company,
  LegalInfoField,
  LegalInfoFields as CompleteLegalInfoFormWidgetFields,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { forwardRef, useDateUtils } from '@melio/platform-utils';
import { isPast, isValid, parse } from 'date-fns';
import { defaults, pickBy } from 'lodash';
import React from 'react';
import { number, object, SchemaOf, string } from 'yup';

import { FormWidgetProps } from '../../../types';
import { AddressSearchWidget, IndustryTypeSelectWidget } from '../../form-controls';
import { CompleteLegalInfoFormWidgetProps as CompleteLegalInfoFormWidgetPropsV2 } from '../types';

export type CompleteLegalInfoFormWidgetProps = FormWidgetProps<CompleteLegalInfoFormWidgetFields> &
  Pick<CompleteLegalInfoFormWidgetPropsV2, 'missingLegalInfoFields' | 'taxIdType'>;

type Industry = Account['company']['industry'];

const buildMaskedTaxIdValue = (taxIdentifier?: string) =>
  taxIdentifier
    ?.split('')
    .map((c: string) => (isNaN(Number(c)) ? '•' : c))
    .join('');

const DATE_MASK = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]; // i.e 12/30/1995
const DATE_REGEX = /\d{2}\/\d{2}\/\d{4}/; // i.e 12/30/1995

export const useSchema = (missingLegalInfoFields: LegalInfoField[]) => {
  const { formatMessage } = useMelioIntl();
  const { createDate } = useDateUtils();

  return object().shape(
    pickBy(
      {
        legalCompanyName: string()
          .nullable()
          .required(formatMessage('_deprecated.widgets.completeLegalInfo.legalCompanyName.validation.required')),
        phoneNumber: string()
          .nullable()
          .required(formatMessage('_deprecated.widgets.completeLegalInfo.phoneNumber.validation.required')),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
        legalAddress: object({
          line1: string(),
          line2: string(),
          city: string(),
          postalCode: string(),
          state: string(),
        })
          .nullable()
          .default(null)
          .required(formatMessage('_deprecated.widgets.completeLegalInfo.legalAddress.validation.required'))
          .test(
            'hasRequiredAddressFields',
            formatMessage(`app.mtl.validations.address.required`),
            (address: Partial<Company['address']> | null) => hasRequiredAddressFields(address)
          )
          .test(
            'isNotPoBox',
            formatMessage(`_deprecated.widgets.completeLegalInfo.legalAddress.validation.poBox`),
            (address: Address) => !isPOBox(address?.line1)
          ),
        taxInfoType: string()
          .nullable()
          .oneOf(Object.values(['SSN', 'EIN', 'ITIN']))
          .required(formatMessage('_deprecated.widgets.completeLegalInfo.taxInfo.type.validation.required')),
        taxInfoIdentifier: string()
          .nullable()
          .required(formatMessage('_deprecated.widgets.completeLegalInfo.taxInfo.identifier.validation.required'))
          .matches(
            /(\d{9})|(\d{3}-\d{2}-\d{4})|(\d{2}-\d{7})/,
            formatMessage('_deprecated.widgets.completeLegalInfo.taxInfo.identifier.validation.format')
          ),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
        industry: object({
          name: string(),
          naicsCode: number().nullable(),
        })
          .nullable()
          .default(null)
          .required(formatMessage('_deprecated.widgets.completeLegalInfo.industry.validation.required')),
        dateOfBirth: string().when('taxInfoType', {
          is: (taxInfoType: string) => taxInfoType === 'ITIN' || taxInfoType === 'SSN',
          then: string()
            .nullable()
            .required(formatMessage('_deprecated.widgets.completeLegalInfo.dateOfBirth.validation.required'))
            .matches(
              DATE_REGEX,
              formatMessage(`widgets.AddOrganizationBeneficialOwnerForm.dateOfBirth.validation.format`)
            )
            .test(
              'validDate',
              formatMessage(`widgets.AddOrganizationBeneficialOwnerForm.dateOfBirth.validation.format`),
              (v) => {
                if (!v) {
                  return false;
                }
                const date = parse(v, 'MM/dd/yyyy', createDate());
                return isValid(date) && isPast(date);
              }
            ),
        }),
      },
      (_, key) => missingLegalInfoFields.includes(key as LegalInfoField)
    )
  ) as SchemaOf<CompleteLegalInfoFormWidgetFields>;
};

const resetLegalAddressIfMissingDetails = (missingLegalInfoFields: LegalInfoField[]) => {
  if (missingLegalInfoFields.includes('legalAddress')) {
    return {
      legalAddress: undefined,
    };
  }

  return {};
};

export const CompleteLegalInfoFormWidget = forwardRef<Omit<CompleteLegalInfoFormWidgetProps, 'companyName'>, 'form'>(
  (
    {
      onSubmit,
      missingLegalInfoFields,
      defaultValues: _defaultValues,
      isSaving,
      onSubmissionStateChange,
      taxIdType,
      ...props
    },
    ref
  ) => {
    const defaultValues = defaults(
      {
        ..._defaultValues,
        taxInfoIdentifier: buildMaskedTaxIdValue(_defaultValues?.taxInfoIdentifier),
        ...resetLegalAddressIfMissingDetails(missingLegalInfoFields),
      },
      {
        taxInfoType: '',
        taxInfoIdentifier: '',
        legalCompanyName: '',
      }
    );

    const { formatMessage } = useMelioIntl();

    const { settings } = useConfig();
    const schema = useSchema(missingLegalInfoFields);
    const { formProps, formState, registerField, watch, setValue } = useMelioForm<CompleteLegalInfoFormWidgetFields>({
      onSubmit,
      schema,
      defaultValues,
      isSaving,
      onSubmissionStateChange,
      subscribeToDefaultValuesChanges: true,
    });

    useTrackAnalyticsOnFailedFormSubmit(formState, 'Organization', 'Status');

    const shouldShowTaxInfoBanner = missingLegalInfoFields.includes('taxInfoType') && !!defaultValues.taxInfoIdentifier;

    const handleIndustryCreatable = (option: SelectOption) => {
      setValue('industry', option.value as unknown as Industry, { shouldValidate: true });
    };

    const isDateOfBirthHidden = () =>
      !missingLegalInfoFields.includes('dateOfBirth') ||
      (missingLegalInfoFields.includes('taxInfoType') && (!watch('taxInfoType') || watch('taxInfoType') === 'EIN')) ||
      taxIdType === 'EIN';

    return (
      <Form data-component="CompleteLegalInfoFormWidget" ref={ref} {...props} {...formProps}>
        {shouldShowTaxInfoBanner && (
          <SectionBanner
            description={formatMessage('_deprecated.widgets.completeLegalInfo.taxInfo.banner', {
              partnerProduct: settings.partnerDisplayName,
            })}
            data-testid="taxInfoBanner"
          />
        )}
        <Form.TextField
          isHidden={!missingLegalInfoFields.includes('legalCompanyName')}
          labelProps={{ label: formatMessage('_deprecated.widgets.completeLegalInfo.legalCompanyName.label') }}
          placeholder={formatMessage('_deprecated.widgets.completeLegalInfo.legalCompanyName.placeholder')}
          helperTextProps={{
            label: formatMessage('_deprecated.widgets.completeLegalInfo.legalCompanyName.helperText'),
          }}
          {...registerField('legalCompanyName')}
        />
        <Form.PhoneField
          isHidden={!missingLegalInfoFields.includes('phoneNumber')}
          labelProps={{ label: formatMessage('_deprecated.widgets.completeLegalInfo.phoneNumber.label') }}
          placeholder={formatMessage('_deprecated.widgets.completeLegalInfo.phoneNumber.placeholder')}
          {...registerField('phoneNumber')}
        />
        <AddressSearchWidget
          isHidden={!missingLegalInfoFields.includes('legalAddress')}
          {...registerField('legalAddress')}
          labelProps={{ label: formatMessage('_deprecated.widgets.completeLegalInfo.legalAddress.label') }}
          placeholder={formatMessage('_deprecated.widgets.completeLegalInfo.legalAddress.placeholder')}
        />
        <Form.RadioGroup
          isHidden={!missingLegalInfoFields.includes('taxInfoType')}
          labelProps={{ label: formatMessage('_deprecated.widgets.completeLegalInfo.taxInfo.type.label') }}
          options={[
            {
              mainLabelProps: { label: formatMessage('_deprecated.widgets.completeLegalInfo.taxInfo.type.ein.label') },
              value: 'EIN',
            },
            {
              mainLabelProps: { label: formatMessage('_deprecated.widgets.completeLegalInfo.taxInfo.type.ssn.label') },
              value: 'SSN',
            },
            {
              mainLabelProps: { label: formatMessage('_deprecated.widgets.completeLegalInfo.taxInfo.type.itin.label') },
              value: 'ITIN',
            },
          ]}
          {...registerField('taxInfoType')}
        />
        <Form.SecuredTextField
          isHidden={
            !missingLegalInfoFields.includes('taxInfoIdentifier') && !missingLegalInfoFields.includes('taxInfoType')
          }
          maxLength={9}
          labelProps={{ label: formatMessage('_deprecated.widgets.completeLegalInfo.taxInfo.identifier.label') }}
          placeholder={formatMessage('_deprecated.widgets.completeLegalInfo.taxInfo.identifier.placeholder')}
          {...registerField('taxInfoIdentifier')}
          isReadOnly={!!defaultValues.taxInfoIdentifier}
          isTextVisible={!!defaultValues.taxInfoIdentifier}
          data-private
        />
        <IndustryTypeSelectWidget
          {...registerField('industry')}
          onCreateOption={handleIndustryCreatable}
          isHidden={!missingLegalInfoFields.includes('industry')}
          labelProps={{ label: formatMessage('_deprecated.widgets.completeLegalInfo.industry.label') }}
          placeholder={formatMessage('_deprecated.widgets.completeLegalInfo.industry.placeholder')}
        />
        <Form.TextField
          {...registerField('dateOfBirth')}
          isHidden={isDateOfBirthHidden()}
          maskProps={{
            mask: DATE_MASK,
            keepCharPositions: false,
            guide: true,
            placeholderChar: ' ',
          }}
          labelProps={{ label: formatMessage('_deprecated.widgets.completeLegalInfo.dateOfBirth.label') }}
          placeholder={formatMessage('_deprecated.widgets.completeLegalInfo.dateOfBirth.placeholder')}
        />
      </Form>
    );
  }
);

CompleteLegalInfoFormWidget.displayName = 'CompleteLegalInfoFormWidget';
