import { Box } from '@chakra-ui/react';
import { FormattedMessage, PDFPreviewer, useMelioIntl } from '@melio/ar-domain';
import { Group, SectionBanner } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { forwardRef, PageTitle, useBoolean } from '@melio/platform-utils';
import { useRef } from 'react';

import { Footer, InvoiceFormLayout } from '../../../layouts';

export type PreviewInvoiceScreenProps = {
  error: ARPlatformError | null;
  onClose: VoidFunction;
  onDownload: VoidFunction;
  previewContent: string;
  isLoading: boolean;
};

export const PreviewInvoiceScreen = forwardRef<PreviewInvoiceScreenProps>(
  ({ isLoading, previewContent, onClose, error, onDownload, ...props }, ref) => {
    const [isBannerDismissed, dismissBanner] = useBoolean();
    const { formatMessage } = useMelioIntl();

    const pdfRef = useRef<HTMLDivElement>(null);
    const printContentRef = useRef<HTMLDivElement>(null);

    useAnalyticsView('Invoice', true, true, {
      ProductName: 'ar',
      PageName: 'preview-invoice',
      Flow: 'manage-invoice',
      Intent: 'preview-invoice',
    });

    const SectionBannerComp = () =>
      error && !isBannerDismissed ? (
        <SectionBanner
          data-testid="preview-invoice-error-banner"
          description={formatMessage('ar.invoiceLifecycle.activities.previewInvoice.failure.text')}
          showCloseIcon
          variant="critical"
          onClose={dismissBanner.on}
        />
      ) : null;

    const printMultiPagePDF = () => {
      if (!printContentRef.current) return;

      // Create a new document fragment
      const fragment = document.createDocumentFragment();

      // Populate fragment with current PDF pages
      const images = document.querySelectorAll('[data-component="PDFPage"] img');
      images.forEach((img) => {
        const imgClone = (img as HTMLImageElement).cloneNode(true) as HTMLImageElement;
        fragment.appendChild(imgClone);
      });

      // Append fragment to printable content
      printContentRef.current.appendChild(fragment);

      window.print();

      // Clean up
      printContentRef.current.innerHTML = '';
    };

    return (
      <>
        <InvoiceFormLayout
          {...{
            sx: {
              '@media print': {
                display: 'none',
              },
            },
          }}
          variant="10Columns"
          isLoading={isLoading}
          onClose={onClose}
          data-component="PreviewInvoiceScreen"
          data-testid="preview-invoice-screen"
          footer={
            <Footer
              primaryButtonProps={{
                label: formatMessage('ar.invoiceLifecycle.activities.previewInvoice.buttons.download.label'),
                isDisabled: !!error,
                onClick: onDownload,
              }}
              secondaryButtonProps={{
                label: formatMessage('ar.invoiceLifecycle.activities.previewInvoice.buttons.print.label'),
                isDisabled: !!error,
                onClick: printMultiPagePDF,
              }}
              tertiaryButtonProps={{
                label: formatMessage('ar.invoiceLifecycle.activities.previewInvoice.buttons.close.label'),
                onClick: onClose,
              }}
            />
          }
          {...props}
          ref={ref}
        >
          <Group variant="vertical" alignItems="flex-start" spacing="l">
            <Group variant="vertical" alignItems="flex-start" width="full">
              <PageTitle textStyle="heading1Semi" data-testid="preview-invoice-header-text">
                <FormattedMessage id="ar.invoiceLifecycle.activities.previewInvoice.title.text" />
              </PageTitle>
              <SectionBannerComp />
            </Group>
            {!error && <PDFPreviewer ref={pdfRef} previewContent={previewContent} />}
          </Group>
        </InvoiceFormLayout>
        <Box
          sx={{
            '@media print': {
              display: 'block',
            },
          }}
          as="div"
          display="none"
          ref={printContentRef}
        ></Box>
      </>
    );
  }
);

PreviewInvoiceScreen.displayName = 'PreviewInvoiceScreen';
