import { BaseMenu, Table } from '@melio/penny';
import { useAccountingPlatforms } from '@melio/platform-api';
import { forwardRef } from '@melio/platform-utils';
import { useState } from 'react';

import { useFundingSourcesSearchBar } from '../../../../../payment-flow/PaymentFlowActivity/PaymentFlowForm/components/FundingSourceInput/useFundingSourceSearchBar';
import { useGetFundingSourceWarning } from '../../PaymentIntentsTable.screen.utils';
import { FundingSourceSelectCellProps } from '../FundingSourceSelectCell/FundingSourceSelectCell';
import { EmptySearchResults } from './EmptySearchResults';
import { FundingSourceSectionedOptions } from './FundingSourceSectionedOptions';
import { FundingSourceSelectTrigger } from './FundingSourceSelectCellTrigger';
import { SearchBarHeader } from './SearchBarHeader';
import { useGroupedSources } from './useGroupedSources';

export const FundingSourceSelectCellV2 = forwardRef<FundingSourceSelectCellProps, 'div'>(
  (
    {
      fundingSources,
      vendor,
      selectedFundingSourceId,
      onOpenReconciliationModal,
      onOpenVendorDetailsModal,
      fundingSourceTypesOptions,
      onAddFundingSourceClick,
      onSelect,
      ...props
    },
    ref
  ) => {
    const {
      props: searchProps,
      show: showSearch,
      resetQuery,
      filtered: filteredFundingSources,
    } = useFundingSourcesSearchBar(fundingSources);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const selectedFundingSource = fundingSources.find((fs) => fs.id === selectedFundingSourceId);

    const { groupedSources } = useGroupedSources(filteredFundingSources, fundingSourceTypesOptions);

    const { activeAccountingPlatform } = useAccountingPlatforms();
    const { popoverProps, shouldShowWarning } = useGetFundingSourceWarning({
      vendor,
      selectedFundingSource,
      activeAccountingPlatform,
      onOpenReconciliationModal,
      onOpenVendorDetailsModal,
    });

    const hasItems = filteredFundingSources.length > 0;

    const trigger = (
      <Table.Cell data-testid="funding-source-select-cell" role="button" ref={ref}>
        <FundingSourceSelectTrigger
          item={
            selectedFundingSource && {
              label: selectedFundingSource.displayName,
              description: selectedFundingSource.nickname,
            }
          }
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          isInvalid={shouldShowWarning}
          popoverProps={popoverProps}
        />
      </Table.Cell>
    );
    return (
      <BaseMenu
        trigger={trigger}
        isOpen={isOpen}
        onOpenChange={(isOpen: boolean) => {
          setIsOpen(isOpen);
          resetQuery();
        }}
        width="297px"
        maxHeight="320px"
        hasItems={hasItems}
        role="listbox"
        disableTypeahead
        header={showSearch && <SearchBarHeader searchProps={searchProps} />}
        hasSections={hasItems}
        ref={ref}
        {...props}
      >
        {filteredFundingSources.length > 0 ? (
          <FundingSourceSectionedOptions
            selectedFundingSourceId={selectedFundingSourceId}
            onSelect={onSelect}
            groupedSources={groupedSources}
          />
        ) : (
          <EmptySearchResults />
        )}
      </BaseMenu>
    );
  }
);

FundingSourceSelectCellV2.displayName = 'FundingSourceSelectCellV2';
