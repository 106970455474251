/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines */
import { Button, Group, NakedButton, SectionBanner, Typography, useFormSubmissionController } from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef, useBoolean } from '@melio/platform-utils';
import React, { useEffect, useRef, useState } from 'react';

import { DeliveryMethodFlowOrigin } from '../../../../../../delivery-methods/types';
import { NewSinglePaymentStepLayout } from '../../../../../../NewSinglePaymentStepLayout';
import { SubmissionTargetType } from '../../../../types';
import {
  AddOrganizationBeneficialOwnerFormWidget,
  AddOrganizationBeneficialOwnerFormWidgetFields,
} from './widgets/AddOrganizationBeneficialOwnerForm';

type AddOrganizationBeneficialOwnersScreenProps = {
  isSaving?: boolean;
  isLoading?: boolean;
  onClose: VoidFunction;
  onBack: VoidFunction;
  onDone: (data: AddOrganizationBeneficialOwnerFormWidgetFields[], target?: SubmissionTargetType) => void;
  organizationName: string;
  origin: DeliveryMethodFlowOrigin;
};

type ManagerType = {
  key: number; // unique key between 0-3, to refer to controllers idx
  hasErrors: boolean;
  isDone: boolean;
};

export const AddOrganizationBeneficialOwnersScreen = forwardRef<AddOrganizationBeneficialOwnersScreenProps, 'div'>(
  ({ isSaving, onClose, onBack, onDone, isLoading, organizationName, origin, ...props }, ref) => {
    const { track } = useAnalytics();
    const target = useRef<SubmissionTargetType | undefined>(undefined);
    const { formatMessage } = useMelioIntl();

    const getOwnerString = (num: number) => `owner_${num}`;

    const controllers = [
      useFormSubmissionController<AddOrganizationBeneficialOwnerFormWidgetFields>(),
      useFormSubmissionController<AddOrganizationBeneficialOwnerFormWidgetFields>(),
      useFormSubmissionController<AddOrganizationBeneficialOwnerFormWidgetFields>(),
      useFormSubmissionController<AddOrganizationBeneficialOwnerFormWidgetFields>(),
    ];

    const [formsManagers, setFormsManagers] = useState<ManagerType[]>([
      {
        key: 0,
        hasErrors: false,
        isDone: false,
      },
    ]);

    const [showErrorBanner, setShowErrorBanner] = useBoolean(false);

    const addOwner = () => {
      const nextOwnerId = [0, 1, 2, 3].find((k) => !formsManagers.map((fm) => fm.key).includes(k));
      if (nextOwnerId !== undefined) {
        setFormsManagers((state) => [
          ...state,
          {
            key: nextOwnerId,
            hasErrors: false,
            isDone: false,
          },
        ]);
      }
    };

    const removeOwnerByIdx = (idx: number) => {
      if (formsManagers.length > 1) {
        setFormsManagers((state) => [...state.slice(0, idx), ...state.slice(idx + 1)]);
      }
    };

    const getCancelButtonProps = () => ({
      isDisabled: formsManagers.some((fm) => controllers[fm.key]?.cancelButtonProps?.isDisabled),
    });

    const onSubmit = (event: React.MouseEvent<HTMLButtonElement>) =>
      formsManagers.forEach((fm) => controllers[fm.key]?.submitButtonProps?.onClick(event));

    const getSubmitButtonProps = () => ({
      isDisabled: formsManagers.some((fm) => controllers[fm.key]?.submitButtonProps?.isDisabled),
      isLoading: formsManagers.some((fm) => controllers[fm.key]?.submitButtonProps?.isLoading),
    });

    const handleActionClicked = ({
      event,
      submissionTarget,
    }: {
      event: React.MouseEvent<HTMLButtonElement>;
      submissionTarget: SubmissionTargetType;
    }) => {
      target.current = submissionTarget;
      onSubmit(event);
    };

    useEffect(() => {
      formsManagers.some((fm) => fm.hasErrors) ? setShowErrorBanner.on() : setShowErrorBanner.off();
    }, [formsManagers, setShowErrorBanner]);

    // Batch done
    useEffect(() => {
      if (formsManagers.every((fm) => fm.isDone)) {
        const rawData = formsManagers.map((fm) => controllers[fm.key]?.getValues?.());
        const filteredData = rawData.filter(
          (owner): owner is AddOrganizationBeneficialOwnerFormWidgetFields => !!owner
        );
        onDone(filteredData, target.current);
      }
    }, [formsManagers]);

    useAnalyticsView('Organization', !isLoading, true);

    const trackClick = (cta: string) =>
      track(`Organization`, 'Click', {
        Cta: cta,
      });

    return (
      <NewSinglePaymentStepLayout
        data-component="AddOrganizationBeneficialOwnersActivity.AddOrganizationBeneficialOwnersScreen"
        data-testid="add-organization-beneficial-owners-activity-add-organization-beneficial-owners-screen"
        isLoading={isLoading}
        {...props}
        ref={ref}
        headerContent={
          <NewSinglePaymentStepLayout.Header>
            <NewSinglePaymentStepLayout.CloseButton
              onClick={() => {
                trackClick('exit');
                onClose();
              }}
            />
            <NewSinglePaymentStepLayout.BackButton {...getCancelButtonProps()} onClick={onBack} />
          </NewSinglePaymentStepLayout.Header>
        }
        footerContent={
          <NewSinglePaymentStepLayout.Actions>
            {origin === 'vendor' ? (
              <Group variant="vertical" spacing="m" width="full">
                <NewSinglePaymentStepLayout.NextButton
                  {...getSubmitButtonProps()}
                  label={formatMessage(
                    `activities.addOrganizationBeneficialOwners.screens.addOrganizationBeneficialOwners.actions.vendorOrigin.continue`
                  )}
                  onClick={(event) => {
                    trackClick('save-details-and-add-a-bill');
                    handleActionClicked({ event, submissionTarget: 'continue' });
                  }}
                />
                <NewSinglePaymentStepLayout.SecondaryNextButton
                  {...getSubmitButtonProps()}
                  onClick={(event) => {
                    trackClick('save-and-close');
                    handleActionClicked({ event, submissionTarget: 'close' });
                  }}
                  variant="tertiary"
                  label={formatMessage(
                    'activities.addOrganizationBeneficialOwners.screens.addOrganizationBeneficialOwners.actions.vendorOrigin.close'
                  )}
                />
              </Group>
            ) : (
              <NewSinglePaymentStepLayout.NextButton
                {...getSubmitButtonProps()}
                label={formatMessage(
                  `activities.addOrganizationBeneficialOwners.screens.addOrganizationBeneficialOwners.actions.paymentOrigin.continue`
                )}
                onClick={(event) => {
                  trackClick('continue');
                  handleActionClicked({ event, submissionTarget: 'continue' });
                }}
              />
            )}
          </NewSinglePaymentStepLayout.Actions>
        }
      >
        <NewSinglePaymentStepLayout.Title>
          {formatMessage(`activities.addOrganizationBeneficialOwners.screens.addOrganizationBeneficialOwners.title`, {
            organization: organizationName,
          })}
        </NewSinglePaymentStepLayout.Title>
        <NewSinglePaymentStepLayout.Description>
          {formatMessage(
            `activities.addOrganizationBeneficialOwners.screens.addOrganizationBeneficialOwners.description`
          )}
        </NewSinglePaymentStepLayout.Description>
        <NewSinglePaymentStepLayout.Content>
          <Group variant="vertical" width="full">
            <Group variant="vertical" width="full" spacing="xxl" data-testid="forms-group">
              {showErrorBanner && formsManagers.length > 1 && (
                <SectionBanner
                  data-testid="section-banner"
                  variant="critical"
                  description={formatMessage(
                    `activities.addOrganizationBeneficialOwners.screens.addOrganizationBeneficialOwners.sectionBanner.invalid`
                  )}
                />
              )}
              {formsManagers.map((fm, idx) => (
                <Group variant="vertical" width="full" spacing="l" key={getOwnerString(fm.key)}>
                  <Typography.SectionLabel
                    label={formatMessage(
                      `activities.addOrganizationBeneficialOwners.screens.addOrganizationBeneficialOwners.section.label`
                    )}
                    tooltipProps={{
                      label: formatMessage(
                        `activities.addOrganizationBeneficialOwners.screens.addOrganizationBeneficialOwners.section.tooltip`
                      ),
                    }}
                  />
                  <AddOrganizationBeneficialOwnerFormWidget
                    data-testid="owner-form"
                    ownerId={getOwnerString(fm.key)}
                    onSubmit={() =>
                      setFormsManagers((state) =>
                        state.map((fm, index) => (index === idx ? { ...fm, isDone: true } : fm))
                      )
                    }
                    isSaving={isSaving}
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    onSubmissionStateChange={controllers[fm.key]!.onSubmissionStateChange}
                    setHasErrors={(hasErrors: boolean) =>
                      setFormsManagers((state) => state.map((fm, index) => (index === idx ? { ...fm, hasErrors } : fm)))
                    }
                  />
                  {formsManagers.length > 1 && (
                    <Group width="auto">
                      <Button
                        data-testid={`remove-${getOwnerString(fm.key)}`}
                        onClick={() => {
                          trackClick('remove-owner');
                          removeOwnerByIdx(idx);
                        }}
                        variant="tertiary"
                        label={formatMessage(
                          `activities.addOrganizationBeneficialOwners.screens.addOrganizationBeneficialOwners.removeOwner`
                        )}
                      />
                    </Group>
                  )}
                </Group>
              ))}
            </Group>
            {!isSaving && formsManagers.length < 4 && (
              <Group width="auto">
                <NakedButton
                  variant="secondary"
                  onClick={() => {
                    trackClick('add-another-owner');
                    addOwner();
                  }}
                  data-testid="add-another-owner"
                  label={formatMessage(
                    `activities.addOrganizationBeneficialOwners.screens.addOrganizationBeneficialOwners.addAnotherOwnerButton`
                  )}
                />
              </Group>
            )}
          </Group>
        </NewSinglePaymentStepLayout.Content>
      </NewSinglePaymentStepLayout>
    );
  }
);

AddOrganizationBeneficialOwnersScreen.displayName =
  'AddOrganizationBeneficialOwnersActivity.AddOrganizationBeneficialOwnersScreen';
