import { InvoiceDiscount, InvoiceTaxRate, useInvoicePreference, useMelioIntl } from '@melio/ar-domain';
import { useToast } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import { useState } from 'react';

import { ServiceChargeParams } from '../../types';
import { CreateInvoiceExitModal } from './components';
import { CreateInvoiceScreen, PaymentOptionsConfirmationScreen } from './screens';
import { CreateInvoiceSubmitTarget, trackInvoiceCreationProperties } from './types';
import { InvoiceFormValues, submitForm, useCreateInvoiceActions, useInvoiceSummary, usePrefetch } from './utils';

export type CreateInvoiceActivityProps = {
  onDone?: (id: string, shouldIssue?: boolean) => unknown;
  onError?: ARErrorFunction;
  taxRate?: InvoiceTaxRate;
  discount?: InvoiceDiscount;
  serviceCharges?: ServiceChargeParams[];
  onSetDiscount?: VoidFunction;
  onRemoveDiscount?: VoidFunction;
  onSetTaxRate?: VoidFunction;
  onAddServiceCharge?: VoidFunction;
  onRemoveServiceCharge?: (index: number) => void;
  onClose: VoidFunction;
  onCreateCustomer: (value: string) => void;
  onUpdateCustomer: (customerId: string) => void;
  customerId?: string;
};

export const CreateInvoiceActivity = forwardRef<CreateInvoiceActivityProps>(
  (
    {
      discount,
      onClose,
      onRemoveDiscount,
      onSetDiscount,
      onSetTaxRate,
      onAddServiceCharge,
      onRemoveServiceCharge,
      onError: _onError,
      taxRate,
      serviceCharges,
      onDone,
      onCreateCustomer,
      onUpdateCustomer,
      customerId,
      ...props
    },
    ref
  ) => {
    const [error, setError] = useState<ARPlatformError>();
    const [submitTarget, setSubmitTarget] = useState<CreateInvoiceSubmitTarget>();
    const [isExitModalOpen, setIsExitModalOpen] = useState(false);
    const [isPaymentOptionsModalOpen, setIsPaymentOptionsModalOpen] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [isValid, setIsValid] = useState(false);

    const { track } = useAnalytics();

    const onError = (err: ARPlatformError) => [setError, _onError].forEach((fn) => fn?.(err)); // eslint-disable-line react-hooks/exhaustive-deps

    // pre-fetch form data
    const { isLoading: isPreFetching } = usePrefetch({ onError });

    const { isLoading: isLoadingInvoicePreferences, data: invoicePreference } = useInvoicePreference();

    const totalAmountLimits = { min: 100, max: 10000000 }; // in dollars minimum: 1, max: 100000

    const { createInvoice, isSaving } = useCreateInvoiceActions({
      discount,
      taxRate,
      serviceCharges,
      onError,
    });

    const { formatMessage } = useMelioIntl();
    const { toast } = useToast();

    const isLoading = [isPreFetching, isLoadingInvoicePreferences].some(Boolean);

    const [lineItems, setLineItems] = useState<InvoiceFormValues['lineItems']>([]);

    const { data: invoiceSummary, isFetching: isFetchingInvoiceSummary } = useInvoiceSummary({
      discount,
      taxRate,
      serviceCharges,
      lineItems,
      onError,
    });

    const handleInvoiceCreation = (data: InvoiceFormValues, isDraft: boolean, onCreate?: (id: string) => void) => {
      createInvoice(data, isDraft)
        .then(({ id }) => {
          onCreate?.(id);
          track('Invoice', 'Status', {
            StatusType: 'success',
            Cta: submitTarget,
          });
        })
        .catch((err: ARPlatformError) => {
          track('Invoice', 'Status', {
            StatusType: 'failure',
            Cta: submitTarget,
          });
          onError(err);
        })
        .finally(() => {
          setSubmitTarget(undefined);
        });
    };

    const onSubmit = (data: InvoiceFormValues, _event?: unknown, target?: CreateInvoiceSubmitTarget) => {
      const currentTarget = target ?? submitTarget ?? 'exitSaveAsDraft';
      setSubmitTarget(currentTarget);
      const isPaymentOptionsSelected = data.isCardAllowed || data.isAchAllowed;
      switch (currentTarget) {
        case 'saveAndContinue':
          if (!isPaymentOptionsSelected && !isPaymentOptionsModalOpen) {
            setIsPaymentOptionsModalOpen(true);
            return;
          }
          void handleInvoiceCreation(data, false, (id: string) => onDone?.(id, true));
          track('Invoice', 'Click', {
            Cta: 'preview-and-send',
          });
          return;

        case 'exitSaveAsDraft':
        case 'saveAsDraft':
          void handleInvoiceCreation(data, true, (id) => {
            onDone?.(id, false);
            toast({
              type: 'success',
              title: formatMessage('ar.invoiceLifecycle.activities.createInvoice.save.success.label'),
            });
            track('Invoice', 'Click', trackInvoiceCreationProperties[currentTarget]);
          });
          return;
      }
    };

    const handleClose = () => {
      setIsExitModalOpen(isDirty && !isSaving);
      if (!isDirty) {
        onClose();
      }
    };

    return (
      <>
        <CreateInvoiceExitModal
          isOpen={isExitModalOpen}
          onCloseModal={() => !submitTarget && setIsExitModalOpen(false)}
          onSubmit={() => {
            submitForm();
            setIsExitModalOpen(isValid);
          }}
          onClose={() => {
            track('Invoice', 'Click', {
              Cta: 'discard',
              Intent: 'exit-flow',
            });
            onClose();
          }}
          isSubmitting={isSaving}
        />
        <PaymentOptionsConfirmationScreen
          isOpen={isPaymentOptionsModalOpen}
          onSubmit={submitForm}
          onCloseModal={() => !isSaving && setIsPaymentOptionsModalOpen(false)}
          onClose={() => setIsPaymentOptionsModalOpen(false)}
          isSaving={isSaving}
        />
        <CreateInvoiceScreen
          isSaving={isSaving ? submitTarget : undefined}
          isLoading={isLoading}
          isLoadingSummary={isFetchingInvoiceSummary}
          onClose={handleClose}
          discount={{ id: discount?.id, onRemove: onRemoveDiscount, onEdit: onSetDiscount }}
          taxRate={{ id: taxRate?.id, onEdit: onSetTaxRate }}
          onSubmit={onSubmit}
          defaultValues={{
            invoiceNumber: `${invoicePreference?.prefix ?? ''}${invoicePreference?.invoiceNumber ?? ''}`,
            note: invoicePreference?.noteOnInvoice || '',
            customPayInstructions: invoicePreference?.customPayInstructions || '',
            isAchAllowed: invoicePreference?.allowACHPayment,
            isCardAllowed: invoicePreference?.allowCardPayment,
          }}
          error={error}
          serviceCharges={{
            onAdd: onAddServiceCharge,
            onRemove: onRemoveServiceCharge,
          }}
          invoiceSummary={invoiceSummary}
          onLineItemsChange={setLineItems}
          onFormStateChange={({ isDirty, isValid }) => {
            setIsDirty(isDirty);
            setIsValid(isValid);
          }}
          onCreateCustomer={onCreateCustomer}
          onUpdateCustomer={onUpdateCustomer}
          customerId={customerId}
          totalAmountLimits={totalAmountLimits}
          {...props}
          ref={ref}
        />
      </>
    );
  }
);
CreateInvoiceActivity.displayName = 'CreateInvoiceActivity';
