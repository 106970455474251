import React, { useEffect, useMemo, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { useMonitorTiming } from '@melio/ap-activities';
import { getIsRppsVendor, getVendorAccountNumber, VendorFormInlineApiErrorCode } from '@melio/ap-widgets';
import { useVendorDirectoryInfoComplete } from '@melio/ap-widgets';
import { TaxPayerInfoCard } from '@melio/ap-widgets';
import { Container, Group, Link, Loader, OnSubmissionStateChange, SectionBanner, Text } from '@melio/penny';
import { BankAccount, BillSubscription, UpdateVendorParams, VendorEBillStatusEnum } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMonitoring } from '@melio/platform-monitoring';
import { useConfig, useMelioIntl } from '@melio/platform-provider';
import { SystemMessageDisplay } from '@melio/platform-utils/system-message';

import { useRouter } from '@/hooks/router.hooks';
import { useIsInternationalSupportForBusiness } from '@/hooks/vendorInternational.hooks';
import { useVendorEnrichedByIdQuery } from '@/hooks/vendors.hooks';
import { VendorDetailsFormFields } from '@/types/vendors.types';
import { RecurringPayments } from '@/widgets/vendorsDashboard/RecurringPayments/RecurringPayments.widget';
import { MissingVendorInfoBannerWidget } from '../MissingVendorInfoBanner/MissingVendorInfoBanner.widget';
import { VendorDrawerDeliveryMethods } from './components/VendorDrawerDeliveryMethods/VendorDrawerDeliveryMethods';
import { VendorDrawerDetails, VendorDrawerDetailsHandler } from './components/VendorDrawerDetails/VendorDrawerDetails';
import {
  VendorDrawerPaymentsOverview,
  VendorDrawerPaymentsOverviewHandler,
} from './components/VendorDrawerPaymentsOverview/VendorDrawerPaymentsOverview';
import { VendorEBillSection } from './components/VendorEBillSection/VendorEBillSection';
import { useW9StatusBanner } from './hooks/useW9StatusBanner';

type VendorDrawerBodyProps = {
  vendorId?: string;
  onSubmissionStateChange: OnSubmissionStateChange<VendorDetailsFormFields>;
  onSubmit: (data: UpdateVendorParams) => void;
  isEditMode: boolean;
  onAutoPaymentCancellation: VoidFunction;
  onCancelEBillSubscription: VoidFunction;
  onEditBillSubscription: ({ id }: { id: string }) => void;
  inlineApiErrorCodes?: VendorFormInlineApiErrorCode[];
  onEditClick: VoidFunction;
};

export const VendorDrawerBody = ({
  vendorId,
  onSubmissionStateChange,
  onSubmit,
  isEditMode,
  onCancelEBillSubscription,
  onAutoPaymentCancellation,
  onEditBillSubscription,
  inlineApiErrorCodes,
  onEditClick,
}: VendorDrawerBodyProps) => {
  const { formatMessage } = useMelioIntl();
  const [isEbillsFFEnabled] = useFeature(FeatureFlags.EBills, false);
  const { generateNPEDashboardLink } = useRouter();

  const {
    settings: {
      vendor: { collectedDetails },
    },
  } = useConfig();

  const { isInternationalSupportedForBusiness } = useIsInternationalSupportForBusiness();
  const VendorPaymentsOverviewRef = React.useRef<VendorDrawerPaymentsOverviewHandler>(null);
  const {
    data: vendor,
    isLoading: isVendorLoading,
    isMutating,
    isFetching: isVendorFetching,
  } = useVendorEnrichedByIdQuery(vendorId, { refetchOnMount: 'always', suspense: true, useErrorBoundary: false });

  const isVendorDirectoryInfoCompleted = useVendorDirectoryInfoComplete(vendor);

  const vendorDetailsRef = useRef<VendorDrawerDetailsHandler>(null);
  const taxPayerInfoRef = useRef<HTMLDivElement>(null);
  const { isW9StatusBannerVisible, setIsW9StatusBannerVisible } = useW9StatusBanner(vendor);

  const closeW9StatusBanner = () => {
    setIsW9StatusBannerVisible(false);
  };

  const scrollToTaxPayerInfo = React.useCallback(() => {
    taxPayerInfoRef?.current?.scrollIntoView({ behavior: 'smooth' });
    setIsW9StatusBannerVisible(false);
  }, [taxPayerInfoRef, setIsW9StatusBannerVisible]);

  useEffect(() => {
    if (isEditMode) {
      vendorDetailsRef.current?.scrollIntoView();
    }
  }, [isEditMode]);

  const defaultValues = useMemo(() => {
    const bankAccount = vendor?.deliveryMethods.find((method) => method.type === 'bank-account')
      ?.details as BankAccount;

    return {
      companyName: vendor?.name! || '',
      accountNumber: vendor && getVendorAccountNumber(vendor),
      address: vendor?.contact.address || null,
      fullName: vendor?.contact.name || '',
      email: vendor?.contact.email || '',
      phone: vendor?.contact.phoneNumber || '',
      nickname: vendor?.nickname || '',
      postalCode: vendor?.contact.address?.postalCode,
      city: vendor?.contact.address?.city,
      state: vendor?.contact.address?.state,
      line1: vendor?.contact.address?.line1,
      line2: vendor?.contact.address?.line2,
      bankRoutingNumber: bankAccount?.routingNumber,
      bankAccountNumber: bankAccount?.accountNumber,
    };
  }, [vendor]);

  const isRppsVendor = vendor && getIsRppsVendor(vendor);
  const [isW9TaxPayerInfoEnabled] = useFeature(FeatureFlags.W9TaxPayerInfo, false);

  const isEligibleForW9 = isW9TaxPayerInfoEnabled && !vendor?.isManaged;

  const legacyRouteReady = useMonitorTiming('vendor_drawer_ready');
  const { routeReady } = useMonitoring();
  const triggerMonitoring = (el: HTMLDivElement) => {
    legacyRouteReady(el);
    routeReady(el);
  };

  if (isVendorLoading) {
    return <Loader />;
  }
  if (!vendor) {
    return (
      <Box
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        data-testid="vendor-not-found-message"
      >
        <Text textStyle="body4" color="global.neutral.800">
          {formatMessage('widgets.singleVendor.body.notFound')}
        </Text>
      </Box>
    );
  }

  const billSubscriptions =
    (vendor?.billSubscriptions?.filter(
      (billSubscription) =>
        billSubscription.nextOccurrence && ['created', 'in-progress', 'done'].includes(billSubscription.status),
    ) as WithRequiredProperty<BillSubscription, 'nextOccurrence'>[]) ?? [];

  const shouldShowMissingInfoBanner = !isVendorDirectoryInfoCompleted && !isEditMode;

  return (
    <Container ref={triggerMonitoring} overflow="initial">
      <Group variant="vertical">
        <SystemMessageDisplay data-testid="vendor-drawer-notification" />
        <Group variant="vertical" spacing="l" hasDivider>
          {shouldShowMissingInfoBanner ? (
            <MissingVendorInfoBannerWidget onEditClick={onEditClick} vendorName={vendor.name} />
          ) : null}
          {isEbillsFFEnabled && vendor.eBillStatus !== VendorEBillStatusEnum.NotCapable && (
            <VendorEBillSection
              vendor={vendor}
              onAutoPaymentCancellation={onAutoPaymentCancellation}
              onCancelEBillSubscription={onCancelEBillSubscription}
            />
          )}
          <Group variant="vertical" spacing="l">
            {isW9StatusBannerVisible && (
              <Container paddingBottom="m">
                <SectionBanner
                  showCloseIcon
                  onClose={closeW9StatusBanner}
                  title={formatMessage('widgets.taxPayerInfo.w9status.sectionBanner.title')}
                  variant="success"
                  size="large"
                  description={formatMessage('widgets.taxPayerInfo.w9status.sectionBanner.description', {
                    vendorName: vendor.contact?.name || vendor.name,
                    viewAction: (
                      <Link
                        href="#"
                        onClick={scrollToTaxPayerInfo}
                        label={formatMessage('widgets.taxPayerInfo.w9status.sectionBanner.action')}
                        variant="inline"
                      />
                    ),
                  })}
                />
              </Container>
            )}
            <VendorDrawerPaymentsOverview
              ref={VendorPaymentsOverviewRef}
              vendor={vendor}
              vendorBills={[]}
              linkToUnpaid={generateNPEDashboardLink(`bills?search=${encodeURIComponent(vendor.name)}`)}
              linkToScheduled={generateNPEDashboardLink(
                `payments?status=scheduled&search=${encodeURIComponent(vendor.name)}`,
              )}
              linkToPaid={generateNPEDashboardLink(`payments?status=paid&search=${encodeURIComponent(vendor.name)}`)}
              isInternationalSupportedForBusiness={isInternationalSupportedForBusiness}
            />
          </Group>
          {vendor && billSubscriptions.length > 0 ? (
            <RecurringPayments
              billSubscriptions={billSubscriptions}
              vendor={vendor}
              onEditBillSubscription={onEditBillSubscription}
            />
          ) : null}
          {collectedDetails !== 'extended' && <VendorDrawerDeliveryMethods vendor={vendor} />}
          <VendorDrawerDetails
            vendorId={vendor.id}
            ref={vendorDetailsRef}
            isEditable={isEditMode}
            onSubmit={onSubmit}
            onSubmissionStateChange={onSubmissionStateChange}
            defaultValues={defaultValues}
            isRPPSVendor={isRppsVendor}
            managedBy={vendor.managedBy}
            initialManagedByType={vendor.initialManagedByType}
            isSaving={isMutating}
            inlineApiErrorCodes={inlineApiErrorCodes}
          />
          {isEligibleForW9 ? (
            <TaxPayerInfoCard
              ref={taxPayerInfoRef}
              isVendorLoading={isVendorLoading || isVendorFetching}
              vendor={vendor}
              onContactorUpdated={scrollToTaxPayerInfo}
            />
          ) : null}
        </Group>
      </Group>
    </Container>
  );
};
