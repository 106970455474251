import { Currency } from '@melio/international-payments-utils';
import { useMemo } from 'react';

import { getRequireFieldsByCurrency } from './fxBankDetailsForm/fxBankDetailsForm.utils';

export const useRequireFields = ({ currency }: { currency?: Currency }) =>
  useMemo(() => {
    const requiredFields = currency ? getRequireFieldsByCurrency(currency) : [];
    return { requiredFields };
  }, [currency]);
