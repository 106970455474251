import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { useActiveScreen } from '@/hooks/useActiveScreen';
import { OrganizationsDashboardScreen } from '@/screens/organizations-dashboard/OrganizationsDashboard.screen';
import { ScreensEnum } from '@/store/app/app.types';

export const AccountsRoute = () => {
  useActiveScreen(ScreensEnum.accounts);

  return (
    <Routes>
      <Route index element={<OrganizationsDashboardScreen />} />
    </Routes>
  );
};
