import { Card, FundingSource, FundingSourceType } from '@melio/platform-api';
import { FundingSourceGroupName } from '@melio/platform-provider';

export type FundingSourceCardListOfListsGroup = { type: FundingSourceGroupName; fundingSources: FundingSource[] };

export const getFundingType = (groupName: FundingSourceGroupName): FundingSourceType => {
  if (groupName == 'bank-account') {
    return 'bank-account';
  }
  if (groupName == 'flex-account') {
    return 'flex-account';
  }
  return 'card';
};

export const getCardType = (type: FundingSourceGroupName): Card['type'] | undefined =>
  ['credit', 'debit'].includes(type) ? (type as Card['type']) : void 0;
