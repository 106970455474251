import { FormWidgetProps, InvoiceLineItem, useMelioIntl } from '@melio/ar-domain';
import { useFieldArray, useMelioForm } from '@melio/penny';
import { createContext, useContext, useMemo } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { FormState } from 'react-hook-form';
import * as yup from 'yup';

import { DueDatePreset, InvoiceFormValuesLineItem } from '../types';
import { createDefaultFormLineItem, parseInvoiceLineItem } from './createDefaultFormLineItem';

export type InvoiceFormValues = {
  lineItems: InvoiceFormValuesLineItem[];
  customerId: string;
  invoiceNumber: string;
  frequency: string;
  note?: string;
  dueDate?: Date;
  dueDatePreset: DueDatePreset;
  isAchAllowed: boolean;
  isCardAllowed: boolean;
  customPayInstructions?: string;
  isAutoRemindersEnabled: boolean;
};

const useLineItemSchema = () => {
  const { formatMessage } = useMelioIntl();
  return yup.object().shape({
    _current: yup.mixed().notRequired(),
    catalogItemId: yup
      .string()
      .nullable()
      .required(formatMessage('ar.invoiceLifecycle.activities.createInvoice.table.catalogItem.required.label')),
    quantity: yup
      .number()
      .nullable()
      .typeError(formatMessage('ar.invoiceLifecycle.activities.createInvoice.table.quantity.required.label'))
      .required(formatMessage('ar.invoiceLifecycle.activities.createInvoice.table.quantity.required.label'))
      .moreThan(0, formatMessage('ar.invoiceLifecycle.activities.createInvoice.table.quantity.min.label')),
    taxable: yup.boolean(),
    price: yup
      .number()
      .nullable()
      .when('catalogItemId', {
        is: (price: string) => ['', null].includes(price),
        then: (schema) => schema.notRequired(),
        otherwise: (schema) =>
          schema
            .typeError(formatMessage('ar.invoiceLifecycle.activities.createInvoice.table.price.required.label'))
            .required(formatMessage('ar.invoiceLifecycle.activities.createInvoice.table.price.required.label'))
            .moreThan(0, formatMessage('ar.invoiceLifecycle.activities.createInvoice.table.price.min.label')),
      }),
  }) as yup.SchemaOf<InvoiceFormValuesLineItem>;
};

const useFormSchema = () => {
  const { formatMessage } = useMelioIntl();
  return yup.object().shape({
    customerId: yup
      .string()
      .nullable()
      .required(formatMessage('ar.invoiceLifecycle.activities.createInvoice.inputs.customerId.required.label')),
    invoiceNumber: yup
      .string()
      .required(formatMessage('ar.invoiceLifecycle.activities.createInvoice.inputs.invoiceNumber.required.label')),
    frequency: yup
      .string()
      .required(formatMessage('ar.invoiceLifecycle.activities.createInvoice.inputs.invoiceFrequency.required.label')),
    dueDate: yup
      .date()
      .nullable()
      .when('dueDatePreset', {
        is: (value: string) => value == DueDatePreset.Custom,
        then: (schema) =>
          schema.required(formatMessage('ar.invoiceLifecycle.activities.createInvoice.inputs.dueDate.required.label')),
      }),
    dueDatePreset: yup
      .string()
      .oneOf(Object.values(DueDatePreset))
      .nullable()
      .required(formatMessage('ar.invoiceLifecycle.activities.createInvoice.inputs.dueDate.required.label')),
    note: yup.string(),
    isAchAllowed: yup.boolean(),
    isCardAllowed: yup.boolean(),
    isAutoRemindersEnabled: yup.boolean(),
    customPayInstructions: yup.string(),
    lineItems: yup.array().of(useLineItemSchema()),
  }) as yup.SchemaOf<InvoiceFormValues>;
};

const _defaultLineItems = [createDefaultFormLineItem()];

const useDefaultValues = ({
  defaultValues,
}: Pick<UseCreateInvoiceFormProps, 'defaultValues'>): FormProps['defaultValues'] => {
  const defaultLineItems = useMemo(
    () => {
      const target = parseDefaultValues(defaultValues);
      return target?.lineItems?.length ? target.lineItems : _defaultLineItems;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(defaultValues)]
  );

  return useMemo<InvoiceFormValues>(
    () => ({
      frequency: 'one-time',
      invoiceNumber: defaultValues?.invoiceNumber || '',
      note: defaultValues?.note || '',
      isAchAllowed: true,
      isCardAllowed: true,
      isAutoRemindersEnabled: true,
      customerId: null as never,
      dueDatePreset: defaultValues?.dueDate ? 'custom' : 'today',
      ...defaultValues,
      lineItems: defaultLineItems,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(defaultValues)]
  );
};

type FormProps = FormWidgetProps<InvoiceFormValues>;
export type UseCreateInvoiceFormProps = Override<FormProps, { defaultValues?: IncomingDefaultValues }>;

export const useInvoiceForm = ({ onSubmit, defaultValues, ...props }: UseCreateInvoiceFormProps) => {
  const form = useMelioForm<InvoiceFormValues>({
    onSubmit,
    schema: useFormSchema(),
    defaultValues: useDefaultValues({ defaultValues }),
    subscribeToDefaultValuesChanges: true,
    ...props,
  });

  const lineItemsFieldArray = useFieldArray({ name: 'lineItems', control: form.control });
  return { ...form, lineItemsFieldArray, defaultValues };
};

export type InvoiceForm = ReturnType<typeof useInvoiceForm>;

export const InvoiceFormContext = createContext<InvoiceForm>({} as InvoiceForm);
export const useInvoiceFormContext = () => useContext<InvoiceForm>(InvoiceFormContext);

export type InvoiceFormState = FormState<InvoiceFormValues>;

const parseDefaultValues = (defaultValues: UseCreateInvoiceFormProps['defaultValues']): FormProps['defaultValues'] => {
  const parseLineItem = (lineItem: InvoiceLineItem) => ({ ...parseInvoiceLineItem(lineItem), _current: lineItem });
  return {
    ...defaultValues,
    lineItems: defaultValues?.lineItems?.map(parseLineItem),
  };
};

export type IncomingDefaultValues = Override<
  NonNullable<FormProps['defaultValues']>,
  { lineItems?: InvoiceLineItem[] }
>;
