import { Container, Group, useBreakpoint } from '@melio/penny';
import { FC } from 'react';

export const SectionWrapper: FC = ({ children }) => {
  const { isExtraSmallScreen } = useBreakpoint();

  return (
    <Group variant="vertical" data-component="PaymentDetailsSection">
      <Container paddingBottom="xs" paddingX={isExtraSmallScreen ? 's' : 'xl'}>
        {children}
      </Container>
    </Group>
  );
};
