import { Box } from '@chakra-ui/react';
import { Form, Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

import { useFormContext } from '../FormContext';

export const AmountCondition: React.FC<{ index: number }> = ({ index }) => {
  const { registerField } = useFormContext();
  const { formatMessage } = useMelioIntl();
  const field = registerField(`criteria.conditions.${index}.condition.configuration.value`);
  return (
    <Group variant="horizontal" alignItems="center">
      <Box>
        <Text>{formatMessage('activities.create-approval-workflows.conditions.amount.label')}</Text>
      </Box>
      <Box width="280px">
        <Form.AmountField
          {...field}
          aria-label="payment amount"
          data-testid="payment-amount"
          error={
            field.error
              ? { message: formatMessage('activities.create-approval-workflows.conditions.amount.errors.required') }
              : undefined
          }
        ></Form.AmountField>
      </Box>
    </Group>
  );
};
