import { Bill, DeliveryMethod, FundingSource, usePayments, Vendor } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useState } from 'react';

import { PaymentScheduledNotifyVendorModalActivity } from '../PaymentScheduledNotifyVendor';
import { PaymentFlowDoneAction } from '../types';
import { PaymentScheduledSuccessfullyActivity } from './NewPaymentScheduled';
import { getVendorsIdsWithoutEmail } from './PaymentScheduled.activity.utils';
import { PaymentScheduledLoadingScreen, PaymentsScheduledScreen } from './screens';
import { PaymentDetails } from './screens/PaymentsScheduled/PaymentsScheduled.types';
import { PaymentScheduledActivityProps } from './types';

export const PaymentScheduledActivity = (props: PaymentScheduledActivityProps) => {
  const [isNewPaymentScheduledSuccessfully] = useFeature(FeatureFlags.NewPaymentScheduledSuccessfully, false);

  if (isNewPaymentScheduledSuccessfully) {
    return <PaymentScheduledSuccessfullyActivity {...props} />;
  }

  return <PaymentScheduledActivityComponent {...props} />;
};

export const PaymentScheduledActivityComponent = ({
  onClose,
  onError,
  onDone,
  paymentsIds,
  externalOrigin,
}: PaymentScheduledActivityProps) => {
  const paymentsCollection = usePayments({
    params: {
      search: { 'payment.id': paymentsIds },
      expand: ['vendor', 'bills', 'deliveryMethod', 'fundingSource'],
    },
  });

  const [isNotifyVendorModalOpen, setIsNotifyVendorModalOpen] = useState<boolean>(false);

  if (!paymentsCollection.data) {
    return <PaymentScheduledLoadingScreen onClose={onClose} />;
  }

  const paymentsDetails: PaymentDetails[] = paymentsCollection.data.map((payment) => ({
    id: payment.id,
    scheduledDate: payment.scheduledDate,
    deliveryDate: payment.estimatedDelivery,
    deliveryMethod: payment.deliveryMethod as DeliveryMethod,
    fundingSource: payment.fundingSource as FundingSource,
    paymentAmount: payment.amount,
    vendor: payment.vendor as Vendor,
    deliveryPreferenceType: payment.deliveryPreferenceType,
    approvalDecisionStatus: payment.approvalDecisionStatus,
    bills: payment.bills as Bill[],
  }));

  const vendorsIdsWithoutEmail = getVendorsIdsWithoutEmail(paymentsDetails);

  return (
    <>
      <PaymentsScheduledScreen
        onClose={() => onDone('goToDashboard')} // TODO: temp solution (https://linear.app/meliopayments/issue/PAR-892/schedule-payment-last-screen-should-not-have-oncancel)
        paymentsDetails={paymentsDetails}
        vendorsIdsWithoutEmail={vendorsIdsWithoutEmail}
        onNotifyVendors={() => setIsNotifyVendorModalOpen(true)}
        onDone={(action: PaymentFlowDoneAction) => onDone(action)}
        externalOrigin={externalOrigin}
      />
      <PaymentScheduledNotifyVendorModalActivity
        onError={onError}
        isOpen={isNotifyVendorModalOpen}
        onClose={() => setIsNotifyVendorModalOpen(false)}
        onDone={() => {
          setIsNotifyVendorModalOpen(false);
          onDone('goToDashboard');
        }}
        vendorsIds={vendorsIdsWithoutEmail}
      />
    </>
  );
};
