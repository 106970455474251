import { Box } from '@chakra-ui/react';
import { StatusModal } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

import { useIsFirmOnClientOrganization, useSubscription } from '../../../../../hooks';

export type SubscriptionCancelPlanManagedByFirmWarningModalScreenProps = {
  isOpen: boolean;
  onClose: () => void;
  onProceedToCancel: () => void;
};

export const SubscriptionCancelPlanManagedByFirmWarningModalScreen = ({
  isOpen,
  onProceedToCancel,
  onClose,
}: SubscriptionCancelPlanManagedByFirmWarningModalScreenProps) => {
  const { formatMessage, formatDate } = useMelioIntl();

  const subscription = useSubscription();

  const formattedNextBillingDate = formatDate(subscription?.nextBillingDate, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  const { isLoading, isFirmOnClientOrganization: isCancelByFirm } = useIsFirmOnClientOrganization();

  const title = formatMessage(
    `activities.subscription.cancelPlanManagedByFirmWarningModal.${
      isCancelByFirm ? 'cancelByFirm' : 'cancelByClient'
    }.title`
  );

  const description = formatMessage(
    `activities.subscription.cancelPlanManagedByFirmWarningModal.${
      isCancelByFirm ? 'cancelByFirm' : 'cancelByClient'
    }.description`,
    { nextBillingDate: formattedNextBillingDate }
  );

  return (
    <StatusModal
      variant="warning"
      isLoading={isLoading}
      isOpen={isOpen}
      onClose={onClose}
      header={title}
      data-testid="subscription-cancel-plan-managed-by-firm-warning-modal-screen"
      primaryButton={{
        label: formatMessage('activities.subscription.cancelPlanManagedByFirmWarningModal.actions.proceedToCancel'),
        onClick: onProceedToCancel,
        variant: 'primary',
      }}
      secondaryButton={{
        label: formatMessage('activities.subscription.cancelPlanManagedByFirmWarningModal.actions.keepMyPlan'),
        onClick: onClose,
        variant: 'tertiary',
      }}
    >
      <Box data-testid="modal-content">{description}</Box>
    </StatusModal>
  );
};
