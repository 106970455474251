import { usePaymentSchedulingPreference } from '@melio/ap-domain';
import { getDeductionDate } from '@melio/ap-widgets';
import { EventProperties, withAnalyticsContext } from '@melio/platform-analytics';
import { DeliveryPreference, PaymentIntent, useBills, useCheckApprovalRequirement } from '@melio/platform-api';
import { useDateUtils } from '@melio/platform-utils';

import { getEarliestBillDueDate } from '../../../utils/getEarliestBillDueDate';
import { DeliveryDateTour } from './components/DeliveryDateTour';
import { DeliveryDateLoadingScreen, ScheduleDateScreen } from './screens';
import { DeliveryDateScreen } from './screens/DeliveryDate/DeliveryDateScreen';
import { DeliverySpeedDateScreen } from './screens/DeliverySpeedDate/DeliverySpeedDate.screen';
import { useDeliveryOptionsWithPromotions, usePaymentIntentWithUpdates } from './utils';

export type DeliveryDateActivityProps = {
  paymentIntentId: PaymentIntent['id'];
  step: number;
  totalSteps: number;
  onBack: VoidFunction;
  onClose: VoidFunction;
  onDone: (type: DeliveryPreference['type'], date: Date) => unknown;
  onError?: ErrorFunction;
  origin?: 'regular' | 'failedToCollect';
  analyticsProperties?: EventProperties;
};

export const DeliveryDateActivity = withAnalyticsContext<DeliveryDateActivityProps>(
  ({
    paymentIntentId: id,
    step,
    totalSteps,
    onDone,
    origin = 'regular',
    setAnalyticsProperties,
    analyticsProperties,
    ...props
  }) => {
    const { createDate } = useDateUtils();
    const { isByDeliveryDate } = usePaymentSchedulingPreference();
    const { paymentIntent, isLoading, isUpdating, update } = usePaymentIntentWithUpdates(id);

    const {
      fundingSource,
      deliveryMethod,
      billInfo,
      scheduledDate,
      effectiveScheduledDate,
      deliveryDate,
      effectiveDeliveryDate,
      deliveryPreferenceOptions,
      selectedDeliveryPreferenceType,
      computedDeliveryPreference,
      billPayments,
      amountToPay,
    } = paymentIntent || {};

    const billIds = billPayments?.map((billPayment) => billPayment.billId);
    const { data: bills } = useBills({ enabled: !!billIds, params: { search: { 'bill.id': billIds } } });

    const { deliveryOptionsWithPromotions, isLoading: isDeliveryOptionsLoading } =
      useDeliveryOptionsWithPromotions(paymentIntent);

    const { data: approvalRequirement } = useCheckApprovalRequirement({
      amount: amountToPay || 0,
      vendorId: billInfo?.vendorId || '',
      enabled: !!billInfo?.vendorId,
    });

    const setSchedulingDate = (schedulingDate: Date) => {
      const paymentIntentUpdateData = {
        ...(isByDeliveryDate ? { deliveryDate: schedulingDate } : { scheduledDate: schedulingDate }),
      };
      update(paymentIntentUpdateData).catch(props.onError);
    };

    const dueDate = billPayments ? (bills ? getEarliestBillDueDate(bills) : undefined) : billInfo?.dueDate;
    const deductionDate = getDeductionDate(scheduledDate, effectiveScheduledDate);
    const currentDeliveryDate = effectiveDeliveryDate || deliveryDate;

    setAnalyticsProperties({
      Intent: isByDeliveryDate ? 'choose-delivery-date' : 'choose-deduction-date',
      ...analyticsProperties,
    });

    const isDeliveryDateLoading =
      isDeliveryOptionsLoading ||
      !deductionDate ||
      !billInfo ||
      !fundingSource ||
      !deliveryMethod ||
      !deliveryPreferenceOptions ||
      !selectedDeliveryPreferenceType ||
      !deliveryOptionsWithPromotions ||
      !dueDate ||
      !currentDeliveryDate ||
      !approvalRequirement;

    if (isDeliveryDateLoading) {
      return <DeliveryDateLoadingScreen {...props} />;
    }

    switch (origin) {
      case 'failedToCollect':
        return (
          <DeliverySpeedDateScreen
            {...props}
            dueDate={dueDate}
            fundingSource={fundingSource}
            deliveryPreferences={deliveryOptionsWithPromotions}
            selectedDeliveryPreferenceType={selectedDeliveryPreferenceType}
            step={step}
            totalSteps={totalSteps}
            onDone={onDone}
          />
        );
      case 'regular':
      default:
        return (
          <>
            <DeliveryDateTour />
            {isByDeliveryDate ? (
              <DeliveryDateScreen
                {...props}
                isLoading={isUpdating || isLoading}
                dueDate={dueDate}
                fundingSource={fundingSource}
                deliveryMethod={deliveryMethod}
                deliveryPreferences={deliveryOptionsWithPromotions}
                selectedDeliveryPreferenceType={selectedDeliveryPreferenceType}
                onSelectDate={setSchedulingDate}
                selectedDate={currentDeliveryDate}
                onDone={onDone}
                step={step}
                totalSteps={totalSteps}
              />
            ) : (
              <ScheduleDateScreen
                {...props}
                minDate={computedDeliveryPreference?.minScheduleDate || createDate()}
                isLoading={isUpdating || isLoading}
                dueDate={dueDate}
                fundingSource={fundingSource}
                deliveryPreferences={deliveryOptionsWithPromotions}
                selectedDeliveryPreferenceType={selectedDeliveryPreferenceType}
                onSelectDate={setSchedulingDate}
                selectedDate={deductionDate}
                onDone={onDone}
                step={step}
                totalSteps={totalSteps}
                vendorEmail={billInfo.vendor.contact.email}
                approvalRequirementStatus={approvalRequirement.approvalRequirementStatus}
              />
            )}
          </>
        );
    }
  }
);

DeliveryDateActivity.displayName = 'DeliveryDateActivity';
