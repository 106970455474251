import React from 'react';
import { FormProps } from '@melio/penny';

export const preventFormSubmissionOnEnter = (): Partial<FormProps> => {
  const handleFormKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return { onKeyDown: handleFormKeyDown };
};
