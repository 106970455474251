import { Button } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect, useState } from 'react';

import { RecommendedVendorsModal } from './components/RecommendedVendorsModal';
import { useRecommendedVendors } from './hooks/useRecommendedVendors';

export const RecommendedVendorsModalCta = () => {
  const { formatMessage } = useMelioIntl();
  const [isModalOpen, toggleModal] = useState(false);
  const { track } = useAnalytics();
  const { recommendedVendors } = useRecommendedVendors({ shouldFetch: false });

  const tooFewVendors = !recommendedVendors || recommendedVendors.length < 2;

  useEffect(() => {
    if (!tooFewVendors) {
      track('Dashboard', 'View', {
        isEmptyState: true,
        IsSuggestedVendorsShown: true,
      });
    }
  }, [tooFewVendors, track]);

  if (tooFewVendors) {
    return null;
  }

  const onClick = () => {
    track('Dashboard', 'Click', {
      Intent: 'add-vendor',
      Cta: 'suggested-vendors',
    });
    toggleModal(true);
  };

  return (
    <>
      <Button
        variant="secondary"
        label={formatMessage(`activities.recommendedVendors.toggleModal`)}
        onClick={onClick}
      />

      <RecommendedVendorsModal
        recommendedVendors={recommendedVendors}
        isOpen={isModalOpen}
        onClose={() => toggleModal(false)}
      />
    </>
  );
};
