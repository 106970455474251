/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines */
import { Form, Icon, useMelioForm } from '@melio/penny';
import { useTrackAnalyticsOnFailedFormSubmit, useTrackFirstKeyPress } from '@melio/platform-analytics';
import { BillSubscriptionIntervalTypeEnum, useVendor } from '@melio/platform-api';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { forwardRef, useDateUtils } from '@melio/platform-utils';
import { defaults } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { AccountingPlatformCategorySelectWidget, VendorSelectWidget } from '../form-controls';
import { useAccountingPlatformBillCategoryLabel } from '../funding-sources';
import { useAddBillFormSchema } from './schema';
import {
  AddBillFormWidgetFields,
  AddBillFormWidgetProps,
  END_DATE_POLICY,
  NUM_OF_OCCURRENCES_END_POLICY,
  recurringEndDateFields,
  recurringNumOfOccurrencesFields,
  SINGLE_PAYMENT,
  singlePaymentFields,
} from './types';
import { calculateEffectiveEndDate, calculateEndDateByNumOfOccurrences, getStartDateHelperText } from './utils';

const NUM_OF_OCCURRENCES_REGEX = [/[1-9]/, /\d/, /\d/, /\d/];

const getDoesBillRequireInvoiceByAmount = (
  billAmount: number,
  shouldRequireInvoice: boolean,
  threshold: number
): boolean => shouldRequireInvoice && billAmount >= threshold;

export const AddBillFormWidget = forwardRef<AddBillFormWidgetProps, 'form'>(
  (
    {
      onSubmit,
      onUpdateFrequency,
      defaultValues: _defaultValues,
      isDisabled,
      isSaving,
      onSubmissionStateChange,
      shouldRequireInvoice = true,
      activeAccountingPlatform,
      supportRecurringPayment = false,
      isInternationalSupportedForRecurringFlow = false,
      ...props
    },
    ref
  ) => {
    const showCategorySelect = !!activeAccountingPlatform;
    const accountingPlatformBillCategoryLabel = useAccountingPlatformBillCategoryLabel(
      activeAccountingPlatform?.accountingSlug
    );
    const defaultValues = defaults(_defaultValues, {
      vendorId: '',
      billAmount: '',
      billNumber: '',
      categoryId: '',
      invoice: '',
      note: '',
      paymentFrequency: SINGLE_PAYMENT,
      endPolicy: END_DATE_POLICY,
    });

    const { formatMessage, formatCurrency, formatDate } = useMelioIntl();
    const { createDate } = useDateUtils();
    const { requireInvoiceForBillsAmountThreshold } = useConfig().settings;

    const [doesBillRequireInvoice, setDoesBillRequireInvoice] = useState<boolean>(false);
    const [fieldsForValidation, setFieldsForValidation] = useState(singlePaymentFields);
    const [isRecurringSupported, setIsRecurringSupported] = useState(false);

    const { formProps, registerField, watch, reset, formState, setValue } = useMelioForm<AddBillFormWidgetFields>({
      onSubmit,
      schema: useAddBillFormSchema(fieldsForValidation),
      defaultValues,
      isSaving,
      onSubmissionStateChange,
    });

    const watchVendorId = watch('vendorId');

    const { data: selectedVendor } = useVendor({ id: watchVendorId });

    useEffect(() => {
      if (!_defaultValues) {
        reset(defaultValues);
      }
    }, [_defaultValues]);
    const watchPaymentFrequency = watch('paymentFrequency');
    const watchEndPolicy = watch('endPolicy');
    const watchStartDate = watch('startDate');
    const watchEndDate = watch('endDate');
    const watchNumOfOccurrences = watch('numOfOccurrences');

    useEffect(() => {
      const isRecurringSupportedForSelectedVendor =
        supportRecurringPayment &&
        (isInternationalSupportedForRecurringFlow || selectedVendor?.businessGeography !== 'international');
      if (!isRecurringSupportedForSelectedVendor) {
        setValue('paymentFrequency', SINGLE_PAYMENT);
      }
      setIsRecurringSupported(isRecurringSupportedForSelectedVendor);
    }, [selectedVendor]);

    useEffect(() => {
      setDoesBillRequireInvoice(
        getDoesBillRequireInvoiceByAmount(
          Number(watch('billAmount')),
          shouldRequireInvoice,
          requireInvoiceForBillsAmountThreshold
        )
      );
    }, [watch('billAmount'), shouldRequireInvoice, requireInvoiceForBillsAmountThreshold]);

    useTrackAnalyticsOnFailedFormSubmit(formState, 'AddBillInfo', 'SavedAndContinued');

    const { onFieldKeyDown: onVendorSelectKeyDown, onFieldFocus: onVendorSelectFocus } = useTrackFirstKeyPress(
      'FillVendorDetails',
      'Submitted'
    );

    const { onFieldKeyDown: onAmountKeyDown, onFieldFocus: onAmountFocus } = useTrackFirstKeyPress(
      'FillBillAmount',
      'Submitted'
    );

    const updateValidations = useCallback(() => {
      if (watchPaymentFrequency !== SINGLE_PAYMENT) {
        if (watchEndPolicy === NUM_OF_OCCURRENCES_END_POLICY) {
          setFieldsForValidation(recurringNumOfOccurrencesFields);
        } else {
          setFieldsForValidation(recurringEndDateFields);
        }
      } else {
        setFieldsForValidation(singlePaymentFields);
      }
    }, [watchPaymentFrequency, watchEndPolicy]);

    useEffect(() => {
      onUpdateFrequency?.(watchPaymentFrequency !== SINGLE_PAYMENT);
    }, [watchPaymentFrequency, onUpdateFrequency]);

    useEffect(() => {
      updateValidations();
    }, [watchPaymentFrequency, watchEndPolicy, updateValidations, watchVendorId]);

    const startDateHelperText = getStartDateHelperText(
      watchPaymentFrequency as BillSubscriptionIntervalTypeEnum,
      watchStartDate,
      formatMessage
    );

    const getTextWithRecurringIcon = (id: MessageKey) =>
      formatMessage(id, { icon: <Icon type="repeat" size="small" /> });

    const endDateHelperText = () => {
      const calculatedEndDate = calculateEffectiveEndDate(watchPaymentFrequency, watchStartDate, watchEndDate);
      return calculatedEndDate
        ? formatMessage('widgets.addBillForm.numOfOccurrences.helperText', {
            date: formatDate(calculatedEndDate, { dateStyle: 'medium' }),
          })
        : '';
    };

    const numOfOccurrencesHelperText = () => {
      const calculatedEndDate = calculateEndDateByNumOfOccurrences(
        watchPaymentFrequency as BillSubscriptionIntervalTypeEnum,
        watchStartDate,
        watchNumOfOccurrences
      );
      return calculatedEndDate
        ? formatMessage('widgets.addBillForm.numOfOccurrences.helperText', {
            date: formatDate(calculatedEndDate, { dateStyle: 'medium' }),
          })
        : '';
    };

    return (
      <Form
        columns={2}
        data-component="AddBillFormWidget"
        ref={ref}
        {...formProps}
        {...props}
        isDisabled={isDisabled || formProps.isDisabled}
      >
        <VendorSelectWidget
          colSpan={2}
          labelProps={{ label: formatMessage('widgets.addBillForm.vendorName.label') }}
          placeholder={formatMessage('widgets.addBillForm.vendorName.placeholder')}
          {...registerField('vendorId')}
          onKeyDown={onVendorSelectKeyDown}
          onFocus={onVendorSelectFocus}
          eventContextName="Bill"
        />
        <Form.AmountField
          colSpan={2}
          labelProps={{ label: formatMessage('widgets.addBillForm.billAmount.label') }}
          {...registerField('billAmount')}
          onKeyDown={onAmountKeyDown}
          onFocus={onAmountFocus}
          {...(doesBillRequireInvoice && {
            helperTextProps: {
              label: formatMessage('widgets.addBillForm.billAmount.helperText', {
                threshold: formatCurrency(requireInvoiceForBillsAmountThreshold, 'USD', {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                }),
              }),
            },
          })}
        />
        {showCategorySelect && (
          <AccountingPlatformCategorySelectWidget
            activeAccountingPlatform={activeAccountingPlatform}
            labelProps={{
              label: accountingPlatformBillCategoryLabel,
            }}
            colSpan={2}
            {...registerField('categoryId')}
          />
        )}
        {doesBillRequireInvoice && (
          <Form.FileUpload
            colSpan={2}
            placeholder={formatMessage('widgets.addBillForm.invoice.placeholder')}
            acceptTypes={['pdf', 'png', 'jpg']}
            {...registerField('invoice')}
            aria-label="invoice"
          />
        )}
        {isRecurringSupported && (
          <Form.SelectNew
            colSpan={2}
            shouldHideClearButton
            labelProps={{ label: formatMessage('widgets.addBillForm.paymentFrequency.label') }}
            {...registerField('paymentFrequency')}
            options={[
              { label: SINGLE_PAYMENT, value: SINGLE_PAYMENT },
              {
                label: getTextWithRecurringIcon('widgets.addBillForm.paymentFrequency.weekly'),
                value: BillSubscriptionIntervalTypeEnum.Weekly,
              },
              {
                label: getTextWithRecurringIcon('widgets.addBillForm.paymentFrequency.monthly'),
                value: BillSubscriptionIntervalTypeEnum.Monthly,
              },
            ]}
          />
        )}
        <Form.TextField
          colSpan={1}
          isHidden={isRecurringSupported}
          labelProps={{ label: formatMessage('widgets.addBillForm.billNumber.label') }}
          placeholder={formatMessage('widgets.addBillForm.billNumber.placeholder')}
          {...registerField('billNumber')}
        />
        <Form.DateField
          colSpan={isRecurringSupported ? 2 : 1}
          isHidden={watchPaymentFrequency !== SINGLE_PAYMENT}
          labelProps={{ label: formatMessage('widgets.addBillForm.dueDate.label') }}
          placeholder={formatMessage('widgets.addBillForm.dueDate.placeholder')}
          {...registerField('dueDate')}
        />
        <Form.DateField
          colSpan={2}
          isHidden={watchPaymentFrequency === SINGLE_PAYMENT}
          labelProps={{ label: formatMessage('widgets.addBillForm.startDate.label') }}
          placeholder={formatMessage('widgets.addBillForm.startDate.placeholder')}
          minDate={createDate()}
          helperTextProps={{ label: startDateHelperText }}
          legendItems={
            watchStartDate && [
              {
                label: formatMessage('widgets.addBillForm.startDate.legendItems.arrivalDate'),
              },
            ]
          }
          excludeHolidays
          {...registerField('startDate')}
        />
        {watchPaymentFrequency !== SINGLE_PAYMENT && (
          <Form.SelectNew
            labelProps={{ label: formatMessage('widgets.addBillForm.endPolicy.label') }}
            {...registerField('endPolicy')}
            options={[
              { label: 'Date', value: END_DATE_POLICY },
              { label: 'Occurrences', value: NUM_OF_OCCURRENCES_END_POLICY },
            ]}
            shouldHideClearButton
            isRequired
          />
        )}
        <Form.DateField
          labelProps={{ label: formatMessage('widgets.addBillForm.endDate.label') }}
          isHidden={watchPaymentFrequency === SINGLE_PAYMENT || watchEndPolicy !== END_DATE_POLICY}
          placeholder={formatMessage('widgets.addBillForm.endDate.placeholder')}
          minDate={watchStartDate || createDate()}
          helperTextProps={{ label: endDateHelperText() }}
          excludeHolidays
          {...registerField('endDate')}
          legendItems={
            watchEndDate && [
              {
                label: formatMessage('widgets.addBillForm.endDate.legendItems.endDate'),
              },
            ]
          }
          isRequired
        />
        <Form.TextField
          labelProps={{ label: formatMessage('widgets.addBillForm.numOfOccurrences.label') }}
          isHidden={watchPaymentFrequency === SINGLE_PAYMENT || watchEndPolicy !== NUM_OF_OCCURRENCES_END_POLICY}
          placeholder={formatMessage('widgets.addBillForm.numOfOccurrences.placeholder')}
          helperTextProps={{ label: numOfOccurrencesHelperText() || '' }}
          maskProps={{ mask: NUM_OF_OCCURRENCES_REGEX }}
          {...registerField('numOfOccurrences')}
          isRequired
        />
        <Form.TextField
          colSpan={2}
          labelProps={{ label: formatMessage('widgets.addBillForm.billNumber.label') }}
          isHidden={!isRecurringSupported}
          placeholder={formatMessage('widgets.addBillForm.billNumber.placeholder')}
          {...registerField('billNumber')}
          data-testid="recurringBillNumber"
          id="recurringBillNumber"
        />
        <Form.TextField
          colSpan={2}
          labelProps={{ label: formatMessage('widgets.addBillForm.note.label') }}
          {...registerField('note')}
          placeholder={formatMessage('widgets.addBillForm.note.placeholder')}
          data-private
        />
      </Form>
    );
  }
);

AddBillFormWidget.displayName = 'AddBillFormWidget';
