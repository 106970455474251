import { DeliveryMethod, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export const useDeliveryDateTooltipText = ({
  vendor,
  deliveryMethod,
}: {
  vendor: Vendor | undefined;
  deliveryMethod: DeliveryMethod | undefined;
}) => {
  const { formatMessage } = useMelioIntl();

  if (!vendor) {
    return {
      tooltipText: formatMessage('activities.paymentFlow.form.content.deliveryDate.tooltip.disabledVendorNotSelected'),
    };
  }

  if (!deliveryMethod) {
    return {
      tooltipText: formatMessage(
        'activities.paymentFlow.form.content.deliveryDate.tooltip.disabledDeliveryMethodNotSelected'
      ),
    };
  }

  return {
    tooltipText: '',
  };
};
