import { FormattedMessage, InvoiceExpandedSummary, useMelioIntl } from '@melio/ar-domain';
import { Group, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';

type InvoiceSummaryTitleProps = {
  expandedInvoice: InvoiceExpandedSummary;
};

export const InvoiceSummaryTitle = forwardRef<InvoiceSummaryTitleProps>(({ expandedInvoice }, ref) => {
  const { formatCurrency } = useMelioIntl();

  return (
    <Group spacing="xxs" variant="vertical" ref={ref}>
      <Group alignItems="center" justifyContent="space-between">
        <Text textStyle="body2Semi">
          <FormattedMessage id="ar.dashboard.activities.InvoiceDrawer.body.summary.title.header.text" />
        </Text>
        <Text textStyle="body2Semi" data-testid="invoice-drawer-total-amount">
          {formatCurrency(expandedInvoice.summary.totalAmount)}
        </Text>
      </Group>
      <Text textStyle="body4" data-testid="invoice-drawer-invoice-number">
        <FormattedMessage
          id="ar.dashboard.activities.InvoiceDrawer.body.summary.title.header.label.text"
          values={{ invoiceNumber: expandedInvoice.invoiceNumber }}
        />
      </Text>
    </Group>
  );
});
