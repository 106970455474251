import { Account as BaseAccount } from '@melio/platform-api';

// FIXME replace when we have a canonical Account entity type
export type Account = Pick<BaseAccount<never, 'logoUrl'>, 'id' | 'logoUrl'> & {
  company: Pick<BaseAccount['company'], 'name'>;
};

export const sortAccounts = (accounts: Account[]) =>
  [...accounts].sort((a, b) => {
    const aCompanyName = a.company.name ?? '';
    const bCompanyName = b.company.name ?? '';

    return aCompanyName.localeCompare(bCompanyName, 'en', { sensitivity: 'base' });
  });
