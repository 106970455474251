import { NotificationsNames, useNotifications } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';
import { useEffect, useState } from 'react';

import { CustomMessageDialog, CustomMessageFormFields, InvoiceEmailNotificationsScreen } from './screens';

export type InvoiceEmailNotificationsActivityProps = {
  onError?: ARErrorFunction;
};

export const InvoiceEmailNotificationsActivity = forwardRef<InvoiceEmailNotificationsActivityProps>(
  ({ onError, ...props }, ref) => {
    const { data, isLoading, isFetching, isMutating, update } = useNotifications({
      onError,
      onUpdateError: onError,
    });

    const [updatingCustomMessageId, setUpdatingCustomMessageId] = useState<NotificationsNames>();

    const [updatingId, setUpdatingId] = useState<NotificationsNames>();
    useEffect(() => {
      if (!isFetching) setUpdatingId(undefined);
    }, [isFetching]);

    const onCustomMessageChange = async ({ customMessage: _customMessage }: CustomMessageFormFields) => {
      const customMessage = _customMessage?.trim() || null;
      if (!updatingCustomMessageId) return;
      const current = data?.find((n) => n.notificationType === updatingCustomMessageId);
      if (customMessage != current?.customMessage)
        await update(updatingCustomMessageId, { customMessage, enabled: current?.enabled ?? false });
      setUpdatingCustomMessageId(undefined);
    };

    return (
      <>
        {updatingCustomMessageId && (
          <CustomMessageDialog
            isOpen
            onClose={() => setUpdatingCustomMessageId(undefined)}
            onSubmit={onCustomMessageChange}
            defaultValues={{
              customMessage: data?.find((n) => n.notificationType === updatingCustomMessageId)?.customMessage || '',
            }}
            isSaving={isMutating}
          />
        )}
        <InvoiceEmailNotificationsScreen
          notifications={data}
          isLoading={isLoading}
          isUpdating={isFetching || isMutating}
          updatingId={updatingId}
          onChangeEnabled={(key, enabled) => {
            setUpdatingId(key);
            void update(key, { enabled, customMessage: null });
          }}
          onClickCustomMessage={setUpdatingCustomMessageId}
          {...props}
          ref={ref}
        />
      </>
    );
  }
);
InvoiceEmailNotificationsActivity.displayName = 'InvoiceEmailNotificationsActivity';
