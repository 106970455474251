import {
  Container,
  Form,
  FormSelectNewOption,
  Group,
  SelectNewOption,
  Text,
  Typography,
  useBreakpoint,
  useMelioForm,
} from '@melio/penny';
import { AutoPaymentAmountTypeEnum, AutoPaymentDeliveryDateTypeEnum, FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';

import { FundingSourceCard } from '../../../../../payment-flow/PaymentFlowActivity/PaymentFlowForm/components/FundingSourceSection/FundingSourceCard/FundingSourceCard';
import { useAutoPaymentActivationFormSchema } from './hooks/useAutoPaymentActivationFormSchema';
import { useAutoPaymentOptionsFormValues } from './hooks/useAutoPaymentOptionsFormValues';
import { AutoPaymentActivationFormFields, AutoPaymentActivationFormProps } from './types';

export const AutoPaymentActivationForm = ({
  vendor,
  autoPaymentOptions,
  fundingSources,
  account,
  isSaving,
  onSubmit,
  onSubmissionStateChange,
  autoPayment,
}: AutoPaymentActivationFormProps) => {
  const { formatMessage, formatCurrency } = useMelioIntl();
  const { isExtraSmallScreen: isMobile } = useBreakpoint();
  const {
    defaultValues,
    autoPaymentFundingSourceOptions,
    deliveryDateTypeOptions,
    scheduleDaysBeforeOptions,
    paymentAmountTypeOptions,
  } = useAutoPaymentOptionsFormValues({ autoPaymentOptions, vendor, fundingSources, autoPayment });

  const { formProps, registerField, watch, setValue, resetField } = useMelioForm<AutoPaymentActivationFormFields>({
    defaultValues,
    isSaving,
    onSubmit,
    onSubmissionStateChange,
    schema: useAutoPaymentActivationFormSchema(autoPaymentOptions),
  });

  const fundingSourceId = watch('fundingSourceId');
  const deliveryDateType = watch('deliveryDateType');
  const paymentAmountType = watch('paymentAmountType');

  const selectedAutoPaymentFundingSource = autoPaymentOptions.autoPaymentFundingSources.find(
    (autoPaymentFundingSource) => autoPaymentFundingSource.fundingSourceId === fundingSourceId
  );

  useEffect(() => {
    if (deliveryDateType !== AutoPaymentDeliveryDateTypeEnum.DaysBeforeDueDate) {
      setValue('scheduleDaysBefore', null);
    } else {
      resetField('scheduleDaysBefore');
    }
    if (paymentAmountType !== AutoPaymentAmountTypeEnum.FixedAmount) {
      setValue('fixedAmount', null);
    } else {
      setValue('maxAmount', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryDateType, paymentAmountType]);

  const isDaysBeforeDueDate = deliveryDateType === AutoPaymentDeliveryDateTypeEnum.DaysBeforeDueDate;
  const isFixedAmount = paymentAmountType === AutoPaymentAmountTypeEnum.FixedAmount;

  const valueRenderer = (option: FormSelectNewOption<FundingSource['id']>) => {
    const fundingSource = fundingSources.find((fundingSource) => fundingSource.id === option.value);

    return fundingSource ? (
      <Container width="full" paddingY="s" data-testid={`funding-source-dropdown-selected-${option.value}`}>
        <FundingSourceCard fundingSource={fundingSource} />
      </Container>
    ) : null;
  };
  const optionRenderer = (option: FormSelectNewOption<FundingSource['id']>) => {
    const fundingSource = fundingSources.find((fundingSource) => fundingSource.id === option.value);
    return fundingSource ? (
      <Container width="full" data-testid={`funding-source-dropdown-item-${option.value}`}>
        <FundingSourceCard fundingSource={fundingSource} />
      </Container>
    ) : null;
  };

  const deliveryDateOptionRender = (option: SelectNewOption<string>) => (
    <Group variant="vertical" alignItems="flex-start" spacing="xxxs">
      <Text textStyle="body3" shouldSupportEllipsis color="inherit">
        {option.label}
      </Text>
      <Typography.Description label={(option as (typeof deliveryDateTypeOptions)[0]).description} />
    </Group>
  );

  const paymentAmountTypeOptionRender = (option: SelectNewOption<string>) => (
    <Group variant="vertical" alignItems="flex-start" spacing="xxxs">
      <Text textStyle="body3" shouldSupportEllipsis color="inherit">
        {option.label}
      </Text>
      <Typography.Description label={(option as (typeof paymentAmountTypeOptions)[0]).description} />
    </Group>
  );

  return (
    <Form {...formProps} data-testid="auto-payment-activation-form">
      <Group variant="vertical" width="full" spacing="l">
        <Form.TextField
          {...registerField('accountNumber')}
          labelProps={{
            label: formatMessage(
              'activities.vendor.autoPaymentActivationForm.screens.activation.form.accountNumber.label'
            ),
          }}
          isReadOnly
        />

        <Form.SelectNew
          {...registerField('fundingSourceId')}
          labelProps={{
            label: formatMessage(
              'activities.vendor.autoPaymentActivationForm.screens.activation.form.fundingSourceId.label'
            ),
          }}
          options={autoPaymentFundingSourceOptions}
          emptyState={undefined}
          valueRenderer={valueRenderer}
          optionRenderer={optionRenderer}
          shouldHideClearButton
        />
        <Group variant={isMobile ? 'vertical' : 'horizontal'} width="full" spacing="l">
          <Group.Item grow={1} basis={2}>
            <Form.SelectNew
              {...registerField('deliveryDateType')}
              labelProps={{
                label: formatMessage(
                  'activities.vendor.autoPaymentActivationForm.screens.activation.form.deliveryDateType.label'
                ),
              }}
              options={deliveryDateTypeOptions}
              optionRenderer={deliveryDateOptionRender}
              emptyState={undefined}
              shouldHideClearButton
            />
          </Group.Item>
          {isDaysBeforeDueDate && (
            <Group.Item grow={1} basis={2}>
              <Form.SelectNew
                {...registerField('scheduleDaysBefore')}
                labelProps={{
                  label: formatMessage(
                    'activities.vendor.autoPaymentActivationForm.screens.activation.form.scheduleDaysBefore.label'
                  ),
                }}
                options={scheduleDaysBeforeOptions}
                emptyState={undefined}
                shouldHideClearButton
              />
            </Group.Item>
          )}
        </Group>
        <Group variant={isMobile ? 'vertical' : 'horizontal'} width="full" spacing="l">
          <Group.Item basis="50%">
            <Form.SelectNew
              {...registerField('paymentAmountType')}
              labelProps={{
                label: formatMessage(
                  'activities.vendor.autoPaymentActivationForm.screens.activation.form.paymentAmountType.label'
                ),
              }}
              options={paymentAmountTypeOptions}
              optionRenderer={paymentAmountTypeOptionRender}
              emptyState={undefined}
              shouldHideClearButton
              menuWidth={isMobile ? 'match-trigger' : '440px'}
              menuMaxWidth="440px"
            />
          </Group.Item>
          <Group.Item basis="50%">
            <Form.AmountField
              {...registerField('fixedAmount')}
              labelProps={{
                label: formatMessage(
                  'activities.vendor.autoPaymentActivationForm.screens.activation.form.fixedAmount.label'
                ),
              }}
              helperTextProps={
                selectedAutoPaymentFundingSource
                  ? {
                      label: formatMessage(
                        'activities.vendor.autoPaymentActivationForm.screens.activation.form.fixedAmount.helperText',
                        {
                          maxFixedAmount: formatCurrency(selectedAutoPaymentFundingSource.maxFixedAmount),
                          minFixedAmount: formatCurrency(selectedAutoPaymentFundingSource.minFixedAmount),
                        }
                      ),
                    }
                  : undefined
              }
              isHidden={!isFixedAmount}
            />
            <Form.AmountField
              {...registerField('maxAmount')}
              labelProps={{
                label: formatMessage(
                  'activities.vendor.autoPaymentActivationForm.screens.activation.form.maxAmount.label'
                ),
              }}
              helperTextProps={{
                label: formatMessage(
                  'activities.vendor.autoPaymentActivationForm.screens.activation.form.maxAmount.helperText'
                ),
              }}
              isHidden={isFixedAmount}
              isRequired={!isFixedAmount}
            />
          </Group.Item>
        </Group>
        <Group variant="vertical" width="full" spacing="l">
          <Group variant="vertical" width="full" spacing="s">
            <Text textStyle="body2Semi">
              {formatMessage(
                'activities.vendor.autoPaymentActivationForm.screens.activation.form.notificationContent.header'
              )}
            </Text>
            <Text
              textStyle="body4"
              color="global.neutral.800"
              data-testid="auto-payment-activation-form-notification-content"
            >
              {formatMessage(
                'activities.vendor.autoPaymentActivationForm.screens.activation.form.notificationContent.label',
                { email: account.user.email }
              )}
            </Text>
          </Group>
          <Group variant="vertical" width="full" spacing="s">
            <Form.Switch
              {...registerField('shouldNotifyOnScheduled')}
              isFullWidth
              label={formatMessage(
                'activities.vendor.autoPaymentActivationForm.screens.activation.form.shouldNotifyOnScheduled.label'
              )}
              aria-label={formatMessage(
                'activities.vendor.autoPaymentActivationForm.screens.activation.form.shouldNotifyOnScheduled.label.aria'
              )}
            />
            <Form.Switch
              {...registerField('shouldNotifyOnSent')}
              isFullWidth
              label={formatMessage(
                'activities.vendor.autoPaymentActivationForm.screens.activation.form.shouldNotifyOnSent.label'
              )}
              aria-label={formatMessage(
                'activities.vendor.autoPaymentActivationForm.screens.activation.form.shouldNotifyOnSent.label.aria'
              )}
            />
          </Group>
        </Group>
      </Group>
    </Form>
  );
};
