import { Group, StatusModal, Text, Typography } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

type Props = {
  isOpen: boolean;
  isLoading?: boolean;
  onDone: VoidFunction;
  onClose: (reason: 'cancel' | 'exit') => void;
  isSaving?: boolean;
  fullName: string;
  companyName: string;
  hasApprovalWorkflow?: boolean;
  isApprover?: boolean;
};

export const DeleteCollaboratorModalScreen = ({
  isOpen,
  onDone,
  onClose,
  isSaving,
  companyName,
  fullName,
  hasApprovalWorkflow = false,
  isApprover = false,
  isLoading = false,
}: Props) => {
  const { formatMessage } = useMelioIntl();

  return (
    <StatusModal
      data-testid="manage-collaborators-activity-delete-collaborator-modal-screen"
      variant="alert"
      isOpen={isOpen}
      isLoading={isLoading}
      onClose={() => onClose('exit')}
      header={formatMessage('activities.manageCollaborators.screens.deleteCollaboratorModal.title', {
        fullName,
        companyName,
      })}
      primaryButton={{
        variant: 'critical',
        label: formatMessage('activities.manageCollaborators.screens.deleteCollaboratorModal.submitBtn'),
        onClick: onDone,
        isDisabled: isSaving,
        isLoading: isSaving,
      }}
      secondaryButton={{
        variant: 'tertiary',
        label: formatMessage('activities.manageCollaborators.screens.deleteCollaboratorModal.cancelBtn'),
        onClick: () => onClose('cancel'),
        isDisabled: isSaving,
        isLoading: isSaving,
      }}
    >
      <DeleteCollaboratorBody fullName={fullName} hasApprovalWorkflow={hasApprovalWorkflow} isApprover={isApprover} />
    </StatusModal>
  );
};

const DeleteCollaboratorBody = ({
  fullName,
  hasApprovalWorkflow,
  isApprover,
}: Pick<Props, 'fullName' | 'hasApprovalWorkflow' | 'isApprover'>) => {
  const { formatMessage } = useMelioIntl();

  if (!hasApprovalWorkflow) {
    return (
      <Text>{formatMessage('activities.manageCollaborators.screens.deleteCollaboratorModal.content.default')}</Text>
    );
  }

  if (!isApprover) {
    return (
      <Text>
        {formatMessage('activities.manageCollaborators.screens.deleteCollaboratorModal.content.deletedNonApprover', {
          fullName,
        })}
      </Text>
    );
  }

  return (
    <Group variant="vertical" spacing="xxs">
      <Text>
        {formatMessage(
          'activities.manageCollaborators.screens.deleteCollaboratorModal.content.deletedApprover.heading',
          {
            fullName,
          }
        )}
      </Text>
      <Typography.ParagraphList
        size="large"
        type="unordered"
        list={[
          {
            content: formatMessage(
              'activities.manageCollaborators.screens.deleteCollaboratorModal.content.deletedApprover.listItemOne'
            ),
          },
          {
            content: formatMessage(
              'activities.manageCollaborators.screens.deleteCollaboratorModal.content.deletedApprover.listItemTwo'
            ),
          },
          {
            content: formatMessage(
              'activities.manageCollaborators.screens.deleteCollaboratorModal.content.deletedApprover.listItemThree'
            ),
          },
        ]}
      />
    </Group>
  );
};
