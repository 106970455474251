import { Container, Form, FormControl, Group, Text, Typography, useMelioForm } from '@melio/penny';
import { InboxEmail } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMemo } from 'react';
import { object, SchemaOf, string } from 'yup';

type ScannedInvoiceEmailDetailsFormFields = {
  vendorEmail: string;
  date: string;
  subject: string;
  content: string;
};

type ScannedInvoiceEmailDetailsProps = {
  inboxEmail: InboxEmail;
};

const useSchema = () =>
  object().shape({
    vendorEmail: string(),
    date: string(),
    subject: string(),
    content: string(),
  }) as SchemaOf<ScannedInvoiceEmailDetailsFormFields>;

export const ScannedInvoiceEmailDetails = ({ inboxEmail }: ScannedInvoiceEmailDetailsProps) => {
  const { formatMessage } = useMelioIntl();

  const defaultValues = useMemo(
    () => ({
      vendorEmail: inboxEmail.sender,
      date: inboxEmail.history.createdAt ? inboxEmail.history.createdAt.toLocaleDateString() : '',
      subject: inboxEmail.subject,
      content: inboxEmail.content,
    }),
    [inboxEmail]
  );

  const { formProps, registerField } = useMelioForm<ScannedInvoiceEmailDetailsFormFields>({
    onSubmit: () => undefined,
    schema: useSchema(),
    defaultValues,
    onSubmissionStateChange: () => undefined,
  });

  return (
    <Container data-component="ScannedInvoiceEmailDetails">
      <Group variant="vertical" spacing="m">
        <Text textStyle="heading3Semi">{formatMessage('widgets.ScannedInvoiceEmailDetails.title')}</Text>
        <Form size="small" isViewMode {...formProps}>
          <Form.TextField
            {...registerField('vendorEmail')}
            labelProps={{ label: formatMessage('widgets.ScannedInvoiceEmailDetails.form.vendorEmail.label') }}
          />
          <Form.TextField
            {...registerField('date')}
            labelProps={{ label: formatMessage('widgets.ScannedInvoiceEmailDetails.form.date.label') }}
          />
          <Form.TextField
            {...registerField('subject')}
            labelProps={{ label: formatMessage('widgets.ScannedInvoiceEmailDetails.form.subject.label') }}
            viewModePlaceholder={formatMessage('widgets.ScannedInvoiceEmailDetails.form.subject.viewModePlaceholder')}
          />
          <FormControl>
            <Typography.Label label={formatMessage('widgets.ScannedInvoiceEmailDetails.form.content.label')} />
            {inboxEmail?.content ? (
              <Text>{inboxEmail.content}</Text>
            ) : (
              <Form.TextField
                {...registerField('content')}
                size="small"
                isViewMode
                placeholder={formatMessage('widgets.ScannedInvoiceEmailDetails.form.content.viewModePlaceholder')}
                aria-label="content"
              />
            )}
          </FormControl>
        </Form>
      </Group>
    </Container>
  );
};
