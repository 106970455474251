import { BillDetailsFormFields, getBillPaidAmount } from '@melio/ap-widgets';
import { useFormSubmissionController } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { FileInfo, useBill, useFiles } from '@melio/platform-api';
import { useDateUtils } from '@melio/platform-utils';
import { Big } from 'big.js';
import { useState } from 'react';

import { useBillsTabItems } from '../../BillsTab/useBillsTabItems';

export const useEditBill = ({
  id,
  onEditSuccess,
  onEditError,
}: {
  id?: string;
  onEditSuccess?: (shouldClose?: boolean) => void;
  onEditError?: () => void;
}) => {
  const { track } = useAnalytics();
  const { isDatePartEqual } = useDateUtils();

  const [file, setFile] = useState<File | undefined | null>();

  const { refetch: refetchBillsTabItems } = useBillsTabItems({ enabled: false });

  const {
    update: updateBill,
    isMutating: isSavingBill,
    data: bill,
  } = useBill({
    id,
  });

  const { onSubmissionStateChange, submitButtonProps, reset } = useFormSubmissionController<BillDetailsFormFields>();
  const { create: uploadInvoiceFile, isMutating: isSavingFile } = useFiles({ enabled: false });
  const isSaving = isSavingBill || isSavingFile;

  const calculateNewBalance = (amount: number) => {
    const paidAmount = bill ? getBillPaidAmount(bill) : 0;
    const newBalance = new Big(amount).minus(paidAmount).toNumber();

    if (amount < paidAmount) {
      throw Error('New amount cannot be less than already paid amount');
    }

    if (amount === 0) {
      throw Error('New amount cannot be less than 0');
    }
    return newBalance;
  };

  const getChangedFields = (data: BillDetailsFormFields) => {
    const changedField: string[] = [];

    if (data.amount != bill?.amount.toString()) {
      changedField.push('amount');
    }
    if (!isDatePartEqual(data.dueDate as Date, bill?.dueDate as Date)) {
      changedField.push('dueDate');
    }
    if (data.invoiceNumber != bill?.invoice.number) {
      changedField.push('invoiceNumber');
    }
    if (data.vendorId != bill?.vendorId) {
      changedField.push('vendorId');
    }
    if (data.note != bill?.note) {
      changedField.push('note');
    }
    if (data.categoryId != bill?.categoryId) {
      changedField.push('categoryId');
    }

    return changedField;
  };

  const handleSubmit = async (data: BillDetailsFormFields) => {
    try {
      let uploadedFile: FileInfo;

      if (file) {
        uploadedFile = await uploadInvoiceFile(file);
      }

      const getFileValueToUpdate = () => {
        if (file === null) {
          return null;
        }
        return uploadedFile?.id;
      };

      const newBalance = calculateNewBalance(+data.amount);
      const changedFields = getChangedFields(data);

      await updateBill({
        amount: +data.amount,
        balance: newBalance,
        dueDate: data.dueDate?.toISOString(),
        invoice: {
          number: data.invoiceNumber,
          fileId: getFileValueToUpdate(),
        },
        vendorId: data.vendorId,
        note: data.note,
        categoryId: data.categoryId,
      });
      refetchBillsTabItems();
      onEditSuccess?.(newBalance === 0);
      track('Bill', 'Status', { Intent: 'edit-bill', Status: 'success', changedFields });
    } catch (e) {
      onEditError?.();
    }
  };

  return {
    onSubmissionStateChange,
    submitButtonProps,
    setFile,
    handleSubmit,
    isSaving,
    reset,
  };
};
