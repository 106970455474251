import { Group, Modal as BaseModal, Text, useBreakpoint } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useCallback } from 'react';

import { ModalCard } from './components/ModalCard';
import { SettingsCard } from './components/SettingsCard';
import { SingleProviderModal } from './components/SingleProviderModal';
import { useBillProviders } from './hooks/useBillProviders';

export const SettingsCards = ({ extraProviders }: { extraProviders?: React.ReactNode }) => {
  const providers = useBillProviders();
  const { formatMessage } = useMelioIntl();
  const { billImportingSubTitle } = useDynamicEbillsImportCopy();
  const { isExtraSmallScreen } = useBreakpoint();

  return (
    <Group variant="vertical" spacing="xl">
      {(Boolean(providers.length) || extraProviders) && (
        <Group variant="vertical" spacing="xs">
          <Text as="h3" textStyle="heading3Semi">
            {formatMessage('activities.eBillsImport.settings.providers.title')}
          </Text>
          <Text textStyle={isExtraSmallScreen ? 'body3' : 'body2'} color="global.neutral.800">
            {billImportingSubTitle}
          </Text>
        </Group>
      )}
      <Group variant="vertical" spacing="xl">
        {providers.map((p, i) => (
          <SettingsCard key={i} provider={p} />
        ))}
        {extraProviders}
      </Group>
    </Group>
  );
};

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const Modal = (props: ModalProps) => {
  const providers = useBillProviders();
  const { formatMessage } = useMelioIntl();

  const header = formatMessage('activities.eBillsImport.modal.title');
  const description = formatMessage('activities.eBillsImport.modal.subtitle');

  const soleProvider = providers.length === 1 ? providers[0] : undefined;

  if (soleProvider) {
    return <SingleProviderModal provider={soleProvider} {...props} />;
  }

  return (
    <BaseModal
      isOpen={props.isOpen}
      header={header}
      onClose={props.onClose}
      footer={
        <Group spacing="m" variant="vertical">
          {providers.map((p) => (
            <ModalCard key={p.name} provider={p} />
          ))}
        </Group>
      }
    >
      <Text>{description}</Text>
    </BaseModal>
  );
};

export const useShouldShowEbillsSyncOption = () => {
  const providers = useBillProviders();
  if (providers.length === 0) {
    return false;
  }
  if (providers.length === 1 && providers[0]?.isConnected) {
    return false;
  }

  return true;
};

export const useDynamicEbillsImportCopy = () => {
  const { formatMessage } = useMelioIntl();
  const providers = useBillProviders();

  const getBillImportingSubTitle = useCallback(() => {
    if (providers.length === 0) {
      return formatMessage('activities.eBillsImport.settings.providers.subtitle');
    }

    if (providers.length === 1) {
      return formatMessage('app.settings.companySection.cards.accounting.singleProvider.description');
    }

    return formatMessage('app.settings.companySection.cards.accounting.multipleProviders.description');
  }, [providers, formatMessage]);

  const importCTA =
    providers.length > 1
      ? 'activities.payDashboard.billsTab.addBillDropdownMenu.connectMultipleAccounts'
      : 'activities.payDashboard.billsTab.addBillDropdownMenu.connectGmail';

  return {
    importCta: formatMessage(importCTA),
    billImportingSubTitle: getBillImportingSubTitle(),
  };
};
