import { Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export const NoOfInstallments: React.VFC<{ noOfInstallments: number; testId: string }> = (props) => {
  const { formatMessage } = useMelioIntl();
  return (
    <Text textStyle="body2Semi" data-testid={props.testId}>
      {formatMessage('activities.repaymentTerms.screens.repaymentTerms.noOfInstallments', {
        noOfInstallments: props.noOfInstallments,
      })}
    </Text>
  );
};
