import { forwardRef, useCallback } from 'react';
import { PaymentSummary, SummaryTypeEnum } from '@melio/ap-widgets';
import { Button, Group, Text, useBreakpoint } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { Bill, Vendor } from '@melio/platform-api';
import { convertCentsToDollars } from '@melio/platform-utils';

import { useRouter } from '@/hooks/router.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { DataComponentEnum } from '@/types/vendors.types';

export interface VendorDrawerPaymentsOverviewProps {
  vendor: Vendor;
  vendorBills: Bill[];
  linkToUnpaid?: string;
  linkToScheduled?: string;
  linkToPaid?: string;
  isInternationalSupportedForBusiness?: boolean;
}

export type VendorDrawerPaymentsOverviewHandler = {
  scrollIntoView: () => void;
};

const MINIMUM_BILLS_TO_BATCH_PAYMENTS = 1;

export const VendorDrawerPaymentsOverview = forwardRef<
  VendorDrawerPaymentsOverviewHandler,
  VendorDrawerPaymentsOverviewProps
>(({ vendor, vendorBills, linkToPaid, linkToScheduled, linkToUnpaid, isInternationalSupportedForBusiness }, _ref) => {
  const {
    generatePayInboxLink,
    generatePayScheduledLink,
    generatePayPaidLink,
    navigateToBatchPayment,
    navigateToSchedulePayment,
  } = useRouter();

  const { formatMessage } = usePlatformIntl();
  const { track } = useAnalytics();
  const { isExtraSmallScreen } = useBreakpoint();
  const { paymentsOverview } = vendor;
  const vendorUnpaidBillsCount = vendorBills.length;

  const isPayUnpaidBillsForVendorDisabled =
    vendor.businessGeography === 'international' ? !isInternationalSupportedForBusiness : false;

  const handlePayUnpaidBillsClick = useCallback(() => {
    const unpaidBillsIds = vendorBills.map((bill) => bill.id);

    track('Vendor', 'Click', {
      VendorId: vendor.id,
      CountUnpaidBills: vendorUnpaidBillsCount,
      CountPaidBills: paymentsOverview?.paid.count,
      UnpaidAmount: paymentsOverview ? convertCentsToDollars(paymentsOverview?.unpaid, ['sum']).sum : 0,
    });

    if (unpaidBillsIds.length) {
      vendorUnpaidBillsCount > MINIMUM_BILLS_TO_BATCH_PAYMENTS
        ? navigateToBatchPayment({ billIds: unpaidBillsIds })
        : navigateToSchedulePayment({ billId: unpaidBillsIds[0] });
    }
  }, [
    vendor,
    track,
    vendorBills,
    vendorUnpaidBillsCount,
    paymentsOverview,
    navigateToBatchPayment,
    navigateToSchedulePayment,
  ]);

  const handleLinkClick = useCallback(() => {
    track('ViewUnpaidBills', 'Chose', {
      VendorId: vendor.id,
      CountUnpaidBills: vendorUnpaidBillsCount,
      CountPaidBills: paymentsOverview?.paid.count,
      UnpaidAmount: paymentsOverview?.unpaid.sum,
    });
    track('Vendor', 'Click', {
      Cta: 'view-bills',
    });
  }, [vendor, track, paymentsOverview, vendorUnpaidBillsCount]);

  return (
    <Group variant="vertical" spacing="m" data-component={DataComponentEnum.PAYMENT_OVERVIEW}>
      <Group justifyContent="space-between">
        <Text as="h3" textStyle="heading3Semi">
          {formatMessage('widgets.paymentOverview.title')}
        </Text>
        {!isExtraSmallScreen && vendorUnpaidBillsCount > 0 && (
          <Button
            data-testid="pay-unpaid-bills-btn"
            onClick={handlePayUnpaidBillsClick}
            variant="success"
            isDisabled={isPayUnpaidBillsForVendorDisabled}
            label={
              vendorUnpaidBillsCount > 1
                ? formatMessage('widgets.paymentOverview.payUnpaidMultipleBillsButton')
                : formatMessage('widgets.paymentOverview.payUnpaidSingleBillButton')
            }
          />
        )}
      </Group>
      <Group
        as="ul"
        spacing="m"
        hasDivider={!isExtraSmallScreen}
        variant={isExtraSmallScreen ? 'vertical' : 'horizontal'}
      >
        <PaymentSummary
          summaryType={SummaryTypeEnum.OPEN_BALANCE}
          sum={paymentsOverview?.unpaid.sum}
          count={paymentsOverview?.unpaid.count}
          vendorName={vendor.name}
          link={linkToUnpaid ?? generatePayInboxLink(vendor.id)}
          onLinkClick={handleLinkClick}
          showNotAvailable={vendor.eBillStatus === 'pending' && paymentsOverview?.unpaid.sum === 0}
          tooltipText={
            vendor.eBillStatus === 'pending'
              ? formatMessage('widgets.paymentOverview.openBalance.tooltip.pendingEBill')
              : undefined
          }
          currency={vendor.currency}
        />
        <PaymentSummary
          summaryType={SummaryTypeEnum.SCHEDULED}
          sum={paymentsOverview?.scheduled.sum}
          count={paymentsOverview?.scheduled.count}
          link={linkToScheduled ?? generatePayScheduledLink(vendor.id)}
          currency={vendor.currency}
        />
        <PaymentSummary
          summaryType={SummaryTypeEnum.PAID}
          sum={paymentsOverview?.paid.sum}
          count={paymentsOverview?.paid.count}
          link={linkToPaid ?? generatePayPaidLink(vendor.id)}
          currency={vendor.currency}
        />
      </Group>
    </Group>
  );
});

VendorDrawerPaymentsOverview.displayName = 'VendorDrawerPaymentsOverview';
