import { SignaturesApiClient } from '@melio/platform-api-axios-client';

type SignaturesApiClientFactoryProps = {
  agreementId?: string;
};

export const useSignaturesApiClient = ({ agreementId }: SignaturesApiClientFactoryProps) => ({
  postSignatures: (data: Parameters<typeof SignaturesApiClient.postSignatures>[1]) =>
    SignaturesApiClient.postSignatures(agreementId as string, data),
  getSignaturesSignatureId: (id: string) => SignaturesApiClient.getSignaturesSignatureId(agreementId as string, id),
  getSignatures: () => SignaturesApiClient.getSignatures(agreementId as string),
  deleteSignaturesSignatureId: (id: string) =>
    SignaturesApiClient.deleteSignaturesSignatureId(agreementId as string, id),
});
