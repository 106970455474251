import { useAccountingPlatforms, useBill, useFile } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSetDocumentTitle } from '@melio/platform-sdk';
import { useSystemMessage } from '@melio/platform-utils';
import { useEffect, useMemo } from 'react';

import { AddBillV2FormActivity } from '../add-bill/AddBillV2Form/AddBillV2Form.activity';
import { AddBillV2FormValuesResult, AddBillV2OriginEnum, AddBillV2SubmitType } from '../add-bill/AddBillV2Form/types';
import { mapApiBillToFormBySoftwareAccount, mapFormBillToPatchApi } from './utils';

type EditBillActivityProps = {
  id: string;
  hideSecondaryButton?: boolean;
  onDone: (bill: AddBillV2FormValuesResult, saveType: AddBillV2SubmitType) => void;
  onBack: () => void;
  onClose: () => void;
  onError?: VoidFunction;
};
export const EditBillActivity = ({
  id,
  hideSecondaryButton,
  onDone,
  onBack,
  onClose,
  onError,
}: EditBillActivityProps) => {
  const { formatMessage } = useMelioIntl();
  useSetDocumentTitle(formatMessage('activities.editBill.pageTitle'));
  const { isLoading, data, update, isMutating } = useBill({ id });
  const { activeAccountingPlatform } = useAccountingPlatforms();
  const { data: fileInfo, isLoading: loadingFile } = useFile({
    id: data?.invoice.fileId || undefined,
    enabled: !!data?.invoice.fileId,
  });

  const initialValues = useMemo(() => {
    if (data) {
      return mapApiBillToFormBySoftwareAccount(data, activeAccountingPlatform);
    }
    return {};
  }, [data, activeAccountingPlatform]);
  const { showMessage } = useSystemMessage();

  useEffect(() => {
    if (!isLoading && !data) {
      showMessage({ type: 'error', title: 'Failed to load the bill' });
      onError?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data]);

  const onSubmit = async (formValues: AddBillV2FormValuesResult, saveType: AddBillV2SubmitType) => {
    try {
      const updatedBill = await update(mapFormBillToPatchApi(formValues, data, activeAccountingPlatform));
      onDone(formValues, saveType);

      return updatedBill;
    } catch (e) {
      showMessage({ type: 'error', title: (e as Error).message || 'Failed to edit the bill' });
    }

    return null;
  };

  return (
    <AddBillV2FormActivity
      onDone={onSubmit}
      onClose={onClose}
      onBack={onBack}
      isLoading={isLoading || loadingFile}
      fileInfo={fileInfo}
      isSaving={isMutating}
      initialValues={initialValues}
      hideSecondaryButton={hideSecondaryButton}
      origin={AddBillV2OriginEnum.Edit}
    />
  );
};

export { AddBillV2SubmitType };
