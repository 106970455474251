import { Box, Flex, Image } from '@chakra-ui/react';
import { Icon, Link, Text, useBreakpoint, useTheme } from '@melio/penny';
import { useEffect, useState } from 'react';

import { useBrazeNavigate } from '../hooks/useBrazeNavigate';

type SplashBannerTopContentCardProps = {
  description: string;
  mode: 'light' | 'dark';
  imageUrl?: string;
  url: string;
  urlText: string;
  newTab?: 'true' | 'false';
  onCardView: VoidFunction;
  onClick: VoidFunction;
  onClose: VoidFunction;
};

export const SplashBannerTopContentCard = ({
  description,
  mode,
  imageUrl,
  url,
  urlText,
  newTab,
  onCardView,
  onClick,
  onClose,
}: SplashBannerTopContentCardProps) => {
  const [showBanner, setShowBanner] = useState<boolean>(true);
  const { colors } = useTheme();
  const { isExtraSmallScreen } = useBreakpoint();
  const { route } = useBrazeNavigate();

  const descriptionTextStyle = isExtraSmallScreen ? 'body4' : 'body3';
  const descriptionTextColor = mode === 'dark' ? 'global.neutral.300' : 'global.neutral.1000';
  const iconColor = mode === 'dark' ? 'inverted' : 'default';
  const linkColor = mode === 'dark' ? 'inverted' : 'default';

  useEffect(() => {
    onCardView();
  }, [onCardView]);

  const iconDimension = '24px';
  const image = imageUrl && (
    <Flex direction="column" height="full" alignItems="flex-end">
      <Image
        src={imageUrl}
        height={iconDimension}
        width={iconDimension}
        minWidth={iconDimension}
        minHeight={iconDimension}
        color={iconColor}
      />
    </Flex>
  );
  const backgroundColor = mode === 'light' ? colors.global.brand['100'] : colors.global.neutral['1000'];

  return showBanner ? (
    <Flex
      width="100%"
      background={backgroundColor}
      minHeight={isExtraSmallScreen ? '64px' : '48px'}
      alignItems="center"
      top="s-m"
      left="s-m"
      padding="12px 16px 12px 16px"
      justifyContent="space-between"
    >
      <Flex height="full" gap="s" alignItems="center" width="100%" justifyContent="center">
        {image}
        <Text textStyle={descriptionTextStyle} color={descriptionTextColor}>
          {`${description} `}
          <b>
            <Text textStyle="body3Semi">
              <Link
                variant="inline"
                href={url}
                label={urlText}
                color={linkColor}
                onClick={(e) => {
                  e.preventDefault();
                  onClick();
                  route(url, newTab === 'true');
                }}
              />
            </Text>
            .
          </b>
        </Text>
      </Flex>
      <Box
        width="s"
        height="s"
        onClick={() => {
          setShowBanner(false);
          onClose();
        }}
        marginRight="s"
        paddingLeft="s"
        cursor="pointer"
        display="flex"
        justifyContent="right"
        justifySelf="stretch"
        alignSelf="center"
        margin={0}
      >
        <Icon type="close-mini" size="small" color={iconColor} />
      </Box>
    </Flex>
  ) : null;
};
