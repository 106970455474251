import React from 'react';
import { useAnalyticsView } from '@melio/platform-analytics';
import { OAuthOrganizations } from '@melio/platform-api';

import { SelectOrganizationForm } from './components/SelectOrganizationForm';

type SelectOrganizationProps = {
  organizations?: OAuthOrganizations;
  onDone: (orgId: number) => void;
};

export const SelectOrganization = ({ organizations, onDone }: SelectOrganizationProps) => {
  useAnalyticsView('OnboardingMultiOrgDetails');

  if (!organizations) {
    return null;
  }

  return <SelectOrganizationForm organizations={organizations} onDone={onDone} />;
};
