/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { BASE_PATH } from '@/api/consts';
import { usePartnerConfig } from '@/hooks/partners';

export const StartRoute = () => {
  const { partnerName } = usePartnerConfig();

  useEffect(() => {
    window.location.href = `${BASE_PATH}/auth/providers/${partnerName}/start`;
  }, []);

  return <></>;
};
