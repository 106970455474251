import { useMelioIntl } from '@melio/ar-domain';
import { Button, Group, NakedButton } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { uniqueId } from 'lodash';
import { createRef as reactCreateRef, RefObject, useState } from 'react';

import { Footer, OnboardingLayout } from '../../../layouts';
import { TrackerStepData } from '../../../utils';
import {
  OwnerDetailsForm,
  OwnerDetailsFormTitle,
  OwnershipDetailsDescription,
  OwnershipDetailsHeader,
} from '../components';
import { BusinessOwnerForm } from '../types';

const MAX_OWNERS = 4;

export type OwnershipDetailsScreenProps = {
  isSaving?: boolean;
  onDone: VoidFunction;
  onBack: VoidFunction;
  onClose: VoidFunction;
  steps: TrackerStepData[];
  currentStepIndex: number;
};

export const OwnershipDetailsScreen = forwardRef<OwnershipDetailsScreenProps>(
  ({ onDone: _onSubmit, isSaving, onBack, onClose, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const [mainOwnerId, setMainOwnerId] = useState<string | null>(null);

    const [formRefs, setFormRefs] = useState<RefWIthId[]>([createRef()]);

    const onSubmit = () => {
      const refs = formRefs.map(({ ref }) => ref.current);
      refs.reverse().forEach((ref) => ref?.submitButtonProps.onClick());
      if (refs.every((ref) => ref?.formState.isValid)) {
        _onSubmit();
      }
    };

    const addForm = () => {
      if (formRefs.length < MAX_OWNERS) {
        setFormRefs([...formRefs, createRef()]);
      }
    };

    const removeForm = (id: string) => {
      if (id === mainOwnerId) {
        setMainOwnerId(null);
      }
      setFormRefs(formRefs.filter(({ id: localId }) => localId != id));
    };

    return (
      <OnboardingLayout
        {...props}
        headerContent={formatMessage('ar.onboarding.layout.header.label')}
        footerContent={
          <Footer
            primaryButtonProps={{
              label: formatMessage('ar.onboarding.screen.ownershipDetails.buttons.continue.label'),
              onClick: onSubmit,
              isLoading: isSaving,
            }}
            secondaryButtonProps={{
              label: formatMessage('ar.onboarding.screen.ownershipDetails.buttons.back.label'),
              onClick: onBack,
            }}
            tertiaryButtonProps={{
              label: formatMessage('ar.onboarding.screen.ownershipDetails.buttons.close.label'),
              onClick: onClose,
            }}
          />
        }
        isStickyFooter
      >
        <Group ref={ref} variant="vertical" spacing="l" width="full">
          <Group variant="vertical" spacing="xs">
            <OwnershipDetailsHeader />
            <OwnershipDetailsDescription />
          </Group>
          <Group hasDivider variant="vertical">
            {formRefs.map(({ id, ref }, index) => (
              <Group key={id} variant="vertical" spacing="s" data-testId={`form-wrapper-${index}`}>
                <OwnerDetailsFormTitle />
                <OwnerDetailsForm
                  ref={ref}
                  isSaving={isSaving}
                  ownerId={id}
                  selectedMainOwnerId={mainOwnerId}
                  setMainOwner={(isSelectedAsMainOwner: boolean) => {
                    isSelectedAsMainOwner ? setMainOwnerId(id) : setMainOwnerId(null);
                  }}
                />
                <Button
                  isDisabled={formRefs.length <= 1 || isSaving}
                  onClick={() => removeForm(id)}
                  label={formatMessage('ar.onboarding.screen.ownershipDetails.buttons.removeOwner.label')}
                  variant="secondary"
                  data-testid="remove-owner-button"
                />
              </Group>
            ))}
          </Group>
          <NakedButton
            variant="secondary"
            isDisabled={formRefs.length >= MAX_OWNERS}
            label={formatMessage('ar.onboarding.screen.ownershipDetails.buttons.addOwner.label')}
            onClick={addForm}
            data-testid="add-owner-button"
          />
        </Group>
      </OnboardingLayout>
    );
  }
);
OwnershipDetailsScreen.displayName = 'OwnershipDetailsScreen';

type RefWIthId = {
  ref: RefObject<BusinessOwnerForm>;
  id: string;
};
const createRef = (): RefWIthId => ({ id: uniqueId('ref'), ref: reactCreateRef<BusinessOwnerForm>() });
