import { ComponentThemeType } from '@/cl/theme/theme.hooks';

export const AccountCardTheme: ComponentThemeType<{ isSelected: boolean }> = (_currentTheme, { isSelected }) => ({
  baseStyle: {
    card: {
      maxHeight: '140px',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      gridGap: '16px',
      width: '100%',
      ...(isSelected && { cursor: 'not-allowed' }),
    },
    companyDetails: {
      display: 'flex',
      alignItems: 'center',
    },
    companyName: {
      marginLeft: '16px',
    } as never,
  },
});
