import { useUserPreference } from '@melio/platform-api';
import { useCallback } from 'react';

const key = 'dismissedRecommendedVendorsCards';

export const useIsRecommendedVendorsCardsDismissed = () => {
  const { create, isFetching, data } = useUserPreference({ id: key });

  const dismissCards = useCallback(() => {
    create({
      userPreference: {
        key,
        value: 'true',
      },
    });
  }, [create]);

  return {
    isDismissed: !isFetching && data?.value === 'true',
    dismissCards,
  };
};
