import { useIsAccountingClient, useUserPreference } from '@melio/platform-api';
import { useDateUtils } from '@melio/platform-utils';
import { differenceInCalendarDays } from 'date-fns';

import { useSubscriptionGracePeriod } from '../../../../hooks';
import { GRACE_PERIOD_LIMITED_TIME_OFFER_PREFERENCE_KEY, GRACE_PERIOD_PREFERENCE_KEY } from '../consts';

export const useShowGracePeriodModals = () => {
  const { isEligibleForGracePeriod, isTodayInGracePeriod, gracePeriodEndDate } = useSubscriptionGracePeriod();
  const isAccountingClient = useIsAccountingClient();
  const { createDate } = useDateUtils();

  const shouldShowGracePeriodModals = isEligibleForGracePeriod && isTodayInGracePeriod;

  const { data: introModalShown } = useUserPreference({
    id: GRACE_PERIOD_PREFERENCE_KEY,
    enabled: shouldShowGracePeriodModals,
  });

  const { data: limitedTimeOfferModalShown } = useUserPreference({
    id: GRACE_PERIOD_LIMITED_TIME_OFFER_PREFERENCE_KEY,
    enabled: shouldShowGracePeriodModals,
  });

  if (!shouldShowGracePeriodModals) {
    return {
      shouldShowIntroductionModal: false,
      shouldShowLimitedTimeOfferModal: false,
    };
  }

  if (!gracePeriodEndDate) {
    throw new Error('Grace period end date is not available');
  }

  const shouldShowIntroductionModal = !introModalShown;

  const today = createDate();
  const isWithinWeekBeforeGracePeriodEnd = differenceInCalendarDays(gracePeriodEndDate, today) <= 7;

  const shouldShowLimitedTimeOfferModal =
    introModalShown && !limitedTimeOfferModalShown && isWithinWeekBeforeGracePeriodEnd;

  return {
    shouldShowGracePeriodModals,
    shouldShowIntroductionModal,
    shouldShowLimitedTimeOfferModal,
    canClaimIntroOffer: !isAccountingClient,
    isWithinWeekBeforeGracePeriodEnd,
  };
};
