import { LoadingContainer, Modal, Text, useFormSubmissionController } from '@melio/penny';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { CardNetwork, MccItem, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useCallback } from 'react';

import { MonitoredAction } from '../../../../monitoring';
import { getVendorMissingDetailsList } from '../../../BatchPayments/screens/PaymentIntentsTable/PaymentIntentsTable.screen.analytics-utils';
import { VendorDetailsFormFields, VendorDetailsFormProps, VendorDetailsModalState } from '../components/types';
import { VendorDetailsForm } from '../components/VendorDetailsForm';

export type VendorDetailsModalScreenProps = {
  vendorDetailsModalState: VendorDetailsModalState;
  onDone: (data: VendorDetailsFormFields) => void;
  onClose: VoidFunction;
  vendorName: Vendor['name'];
  mccIndustryList: MccItem[];
  isLoading?: boolean;
  isSaving?: boolean;
};

export const VendorDetailsModalScreen = withAnalyticsContext<VendorDetailsModalScreenProps>(
  ({
    vendorDetailsModalState,
    onDone,
    onClose,
    isLoading,
    isSaving,
    vendorName,
    mccIndustryList,
    setAnalyticsProperties,
  }) => {
    const { track } = useAnalytics();
    const { formatMessage } = useMelioIntl();
    const { onSubmissionStateChange, submitButtonProps } = useFormSubmissionController<VendorDetailsFormFields>();
    const { startAction, endAction } = useMonitoring<MonitoredAction>();
    const endActionMonitoring = useCallback(() => {
      endAction('funding_source_selection');
      endAction('goods_received_flow');
    }, [endAction]);

    setAnalyticsProperties({
      CardNetwork: vendorDetailsModalState.cardNetwork,
      DetailsToFill: getVendorMissingDetailsList(vendorDetailsModalState.requiredFormFields),
      Intent: 'add-vendor-business-details',
    });

    useAnalyticsView('add-vendor-details', vendorDetailsModalState.isOpen, false, {
      CardNetwork: vendorDetailsModalState.cardNetwork,
      DetailsToFill: getVendorMissingDetailsList(vendorDetailsModalState.requiredFormFields),
      Intent: 'add-vendor-business-details',
    });

    const onSubmit: VendorDetailsFormProps['onSubmit'] = (data) => {
      startAction('vendor_missing_details_flow');

      onDone({
        ...data,
      });

      track('PaymentMethodOptions', 'Click', {
        PageName: 'add-vendor-details',
        CardNetwork: vendorDetailsModalState.cardNetwork,
        DetailsToFill: getVendorMissingDetailsList(vendorDetailsModalState.requiredFormFields),
        Intent: 'add-vendor-business-details',
        Cta: 'save',
      });
    };

    const handleOnClose = () => {
      track('PaymentMethodOptions', 'Click', {
        PageName: 'add-vendor-details',
        CardNetwork: vendorDetailsModalState.cardNetwork,
        DetailsToFill: getVendorMissingDetailsList(vendorDetailsModalState.requiredFormFields),
        Intent: 'add-vendor-business-details',
        Cta: 'exit',
      });

      onClose();
    };

    const header = formatMessage(
      vendorDetailsModalState.cardNetwork === CardNetwork.Amex
        ? 'activities.fundingSourceSelection.screens.vendorDetails.title.amex'
        : 'activities.fundingSourceSelection.screens.vendorDetails.title.visa',
      { vendorName }
    );

    return (
      <Modal
        ref={endActionMonitoring}
        isOpen={vendorDetailsModalState.isOpen}
        isLoading={isLoading}
        onClose={handleOnClose}
        header={
          <Text as="h3" textStyle="heading2Semi">
            {header}
          </Text>
        }
        primaryButton={{
          ...submitButtonProps,
          variant: 'primary',
          label: formatMessage('activities.fundingSourceSelection.screens.vendorDetails.save'),
        }}
        data-testid="vendor-details-modal-screen"
      >
        <LoadingContainer isLoading={isLoading}>
          <VendorDetailsForm
            onSubmit={onSubmit}
            onSubmissionStateChange={onSubmissionStateChange}
            mccIndustryList={mccIndustryList}
            isSaving={isSaving}
            requiredFormFields={vendorDetailsModalState.requiredFormFields}
            cardNetwork={vendorDetailsModalState.cardNetwork}
          />
        </LoadingContainer>
      </Modal>
    );
  }
);
