import { Link, SectionBanner } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';

export const LoanHandledByCreditKeySectionBanner = () => {
  const { formatMessage } = useMelioIntl();
  const { contactCreditKey } = useConfig().links;

  return (
    <SectionBanner
      title={formatMessage('activities.payDashboard.drawer.body.handledByCreditKey.sectionBanner.title')}
      description={formatMessage('activities.payDashboard.drawer.body.handledByCreditKey.sectionBanner.description', {
        contactCKLink: (
          <Link
            href={contactCreditKey}
            label={formatMessage(
              'activities.payDashboard.drawer.body.handledByCreditKey.sectionBanner.description.contactCKLink.label'
            )}
            newTab
          />
        ),
      })}
      variant="warning"
      data-testid="loan-failed-alert"
    />
  );
};
