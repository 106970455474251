import { isEbill } from '@melio/ap-domain';
import { Checkbox, Group, StatusModal } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { ApiError, Payment, PaymentErrorCode, useBillSubscription, usePayment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useSystemMessage } from '@melio/platform-utils';
import { useEffect, useState } from 'react';

import { MonitoredAction } from '../../monitoring';
import { useActivitiesNavigate } from '../../utils';
import { getPaymentAmount } from '../../utils/pay-flow/Payment.utils';
import { PayDashboardTabs } from '../PayDashboard';

export type CancelPaymentModalProps = {
  payment: Payment;
  isOpen: boolean;
  onClose: () => void;
  shouldCancelBillSubscription?: boolean;
};

export const CancelPaymentModal = withAnalyticsContext<CancelPaymentModalProps>(
  ({ payment, isOpen, onClose, shouldCancelBillSubscription, setAnalyticsProperties }) => {
    const { formatMessage, formatCurrency } = useMelioIntl();
    const { isMutating: isUpdatingPayment, cancel: cancelPayment } = usePayment({ id: payment.id, enabled: false });
    const { cancel: cancelBillSubscription, isMutating: isUpdatingBillSubscription } = useBillSubscription({
      id: payment.subscriptionOccurrence?.billSubscriptionId,
      enabled: false,
    });
    const { showMessage } = useSystemMessage();
    const { navigateToTab } = useActivitiesNavigate();
    const [shouldSaveAsBill, setShouldSaveAsBill] = useState(false);
    const { track } = useAnalytics();
    const { startAction } = useMonitoring<MonitoredAction>();

    const paymentAmount = getPaymentAmount(payment.amount, payment.foreignAmount);
    const isPartialPayment =
      payment?.bills?.length === 1 && payment.bills[0] && paymentAmount < payment.bills[0].amount;
    const isEbillPayment = payment?.bills?.length === 1 && payment.bills[0] && isEbill(payment.bills[0]);
    const isRucurringPayment = !!payment.subscriptionOccurrenceId;

    setAnalyticsProperties({
      PageName: 'cancel-payment',
      Intent: 'cancel-payment',
      isEbill: isEbillPayment,
    });

    useEffect(() => {
      if (isOpen) {
        track('Payment', 'View');
      }
    }, [isOpen, track]);

    const getModalPrefixKey = () => {
      if (shouldCancelBillSubscription) {
        return 'billSubscription';
      }
      if (isEbillPayment) {
        return 'eBill';
      }
      if (isPartialPayment) {
        return 'partialPayment';
      }
      if (isRucurringPayment) {
        return 'recurringPayment';
      }
      return 'singlePayment';
    };

    const showPaymentCanceledMessage = () => {
      track('Payment', 'Status', {
        Status: 'success',
        BillStatus: 'deleted',
        ErrorType: `payment-canceled-${payment?.bills?.length ?? '0'}-bills-deleted`,
      });
      showMessage({
        id: 'cancel-payment-success',
        dataTestId: 'cancel-payment-success',
        type: 'informative',
        title: formatMessage('activities.payDashboard.cancelPaymentModal.toasts.paymentCanceled', {
          amount: formatCurrency(payment.amount),
          vendorName: payment.vendor?.name,
        }),
      });
    };

    const showPaymentCanceledAndBillAddedToast = () => {
      track('Payment', 'Status', {
        Status: 'success',
        BillStatus: 'created',
        ErrorType: `payment-canceled-${payment?.bills?.length ?? '0'}-bills-added`,
      });
      showMessage({
        id: 'cancel-payment-bill-added-success',
        dataTestId: 'cancel-payment-bill-added-success',
        type: 'informative',
        title: formatMessage('activities.payDashboard.cancelPaymentModal.toasts.billAdded', {
          amount: formatCurrency(payment.amount),
          vendorName: payment.vendor?.name,
          numberOfBills: payment.bills?.length,
        }),
        action: {
          type: 'button',
          text: formatMessage('activities.payDashboard.cancelPaymentModal.toasts.action', {
            numberOfBills: payment.bills?.length,
          }),
          onAction: (closeToast) => {
            track('Payment', 'Click', {
              Cta: 'view-bill',
            });
            navigateToTab(PayDashboardTabs.Bills, {
              ...(payment.bills?.length === 1 ? { entityId: payment.bills[0]?.id } : {}),
              ...(Number(payment.bills?.length) > 1 && payment.vendor?.name
                ? { urlParams: [{ key: 'search', value: payment.vendor.name }] }
                : {}),
            });
            closeToast();
          },
        },
      });
    };

    const showPaymentCanceledAndEbillReturnedToast = () => {
      track('Payment', 'Status', {
        Status: 'success',
        BillStatus: 'created',
        ErrorType: 'payment-canceled-ebill-added',
      });
      showMessage({
        id: 'cancel-payment-ebill-returned-success',
        dataTestId: 'cancel-payment-ebill-returned-success',
        type: 'informative',
        title: formatMessage('activities.payDashboard.cancelPaymentModal.toasts.ebillReturned', {
          amount: formatCurrency(payment.amount),
          vendorName: payment.vendor?.name,
        }),
        action: {
          type: 'button',
          text: formatMessage('activities.payDashboard.cancelPaymentModal.toasts.eBill.action'),
          onAction: (closeToast) => {
            track('Payment', 'Click', {
              Cta: 'view-bill',
            });
            navigateToTab(PayDashboardTabs.Bills, {
              entityId: payment.bills?.[0]?.id,
            });
            closeToast();
          },
        },
      });
    };

    const showErrorToast = (error: ApiError) => {
      track('Payment', 'Status', {
        Status: 'failure',
        ErrorType: 'someting-went-wrong',
      });

      const errorsMap: Record<string, string> = {
        [PaymentErrorCode.UnauthorizedPayment]: formatMessage(
          'activities.payDashboard.cancelPaymentModal.errors.unauthorized'
        ),
        [PaymentErrorCode.PaymentEditingLocked]: formatMessage(
          'activities.payDashboard.cancelPaymentModal.errors.paymentEditingLocked'
        ),
        [PaymentErrorCode.FailedPaymentEditingLocked]: formatMessage(
          'activities.payDashboard.cancelPaymentModal.errors.failedPaymentEditingLocked'
        ),
      };
      const errorMessage =
        error.errorCode && errorsMap[error.errorCode]
          ? errorsMap[error.errorCode]
          : formatMessage('activities.payDashboard.cancelPaymentModal.toasts.error');

      showMessage({
        id: 'cancel-payment-error',
        dataTestId: 'cancel-payment-error',
        type: 'error',
        title: errorMessage,
      });
    };

    const handleCancelPaymentClick = async () => {
      track('Payment', 'Click', {
        SaveBill: isPartialPayment || isRucurringPayment || isEbillPayment ? null : shouldSaveAsBill,
        Cta: shouldCancelBillSubscription ? 'cancel-all-payments' : 'cancel-payment',
      });

      try {
        if (shouldCancelBillSubscription) {
          startAction('recurring-payment-cancel');
          await cancelBillSubscription({ shouldCancelPayments: true });
          showPaymentCanceledMessage();
        } else {
          startAction('payment_cancel');
          if (shouldSaveAsBill) {
            await cancelPayment();
            showPaymentCanceledAndBillAddedToast();
          } else if (isPartialPayment) {
            await cancelPayment();
            showPaymentCanceledMessage();
          } else if (isEbillPayment) {
            await cancelPayment();
            showPaymentCanceledAndEbillReturnedToast();
          } else {
            await cancelPayment({ shouldCancelBills: true });
            showPaymentCanceledMessage();
          }
        }
      } catch (e) {
        showErrorToast(e as ApiError);
      } finally {
        onClose();
      }
    };

    const handleCloseClick = () => {
      track('Payment', 'Click', {
        Cta: 'exit',
      });
      onClose();
    };

    const isLoading = isUpdatingPayment || isUpdatingBillSubscription;

    return (
      <StatusModal
        isOpen={isOpen}
        variant="cancel"
        header={
          shouldCancelBillSubscription
            ? formatMessage('activities.payDashboard.cancelPaymentModal.billSubscription.title')
            : isEbillPayment
            ? formatMessage('activities.payDashboard.cancelPaymentModal.eBill.title')
            : formatMessage('activities.payDashboard.cancelPaymentModal.title')
        }
        primaryButton={{
          label: shouldCancelBillSubscription
            ? formatMessage('activities.payDashboard.cancelPaymentModal.billSubscription.button.primary')
            : formatMessage('activities.payDashboard.cancelPaymentModal.button.primary'),
          onClick: handleCancelPaymentClick,
          variant: 'primary',
          isLoading,
        }}
        onClose={handleCloseClick}
        data-testid="cancel-payment-modal"
      >
        <Group variant="vertical" spacing="s">
          {formatMessage(`activities.payDashboard.cancelPaymentModal.${getModalPrefixKey()}.body`, {
            numberOfBills: payment.bills?.length,
          })}
          {isPartialPayment || isRucurringPayment || isEbillPayment ? null : (
            <Checkbox
              label={formatMessage('activities.payDashboard.cancelPaymentModal.saveBillCheckbox', {
                numberOfBills: payment.bills?.length,
              })}
              onChange={() => setShouldSaveAsBill((prevValue) => !prevValue)}
              isChecked={shouldSaveAsBill}
              size="large"
              data-testid="cancel-payment-modal-save-bill-checkbox"
            />
          )}
        </Group>
      </StatusModal>
    );
  }
);
