import { PaymentScheduledNotifyVendorFormWidgetFields } from '@melio/ap-widgets';
import { useAnalytics } from '@melio/platform-analytics';
import { BatchUpdateResult, BatchUpdateResultSuccessItem, useVendors, Vendor } from '@melio/platform-api';
import { useSystemMessage, withSystemMessageProvider } from '@melio/platform-utils';
import { useState } from 'react';

import { ConfirmationModalScreen, NotifyLoadingModalScreen, NotifyModalScreen } from './screens';
import { PaymentScheduledNotifyVendorModalActivityProps, PaymentScheduledNotifyVendorModalActivityStep } from './types';

export const PaymentScheduledNotifyVendorModalActivity = withSystemMessageProvider(
  ({ isOpen, onClose, onError, onDone, vendorsIds }: PaymentScheduledNotifyVendorModalActivityProps) => {
    const [currentStep, goToStep] = useState<PaymentScheduledNotifyVendorModalActivityStep>('NOTIFY_VENDOR');
    const [updatedVendors, setUpdatedVendors] = useState<Vendor[]>();
    const vendorsCollection = useVendors({ params: { search: { 'vendor.id': vendorsIds } }, enabled: isOpen });

    const { showMessage } = useSystemMessage();

    const handleFail = (error: PlatformError) => {
      showMessage({ type: 'error', title: error.message });
      onError?.(error);
    };

    const { track } = useAnalytics();

    const onVendorsUpdateDone = (updateResults: BatchUpdateResult<Vendor>) => {
      const successfullyUpdatedVendors = (
        updateResults.filter(
          (vendorUpdateResult) => vendorUpdateResult.status === 'success'
        ) as BatchUpdateResultSuccessItem<Vendor>[]
      ).map((vendorUpdateResult) => vendorUpdateResult.data);

      if (successfullyUpdatedVendors.length) {
        // at least one of the vendors has been updated successfully
        setUpdatedVendors(successfullyUpdatedVendors);
        track('ScheduleVendorNotification', 'Submitted');
        goToStep('NOTIFY_VENDOR_CONFIRMATION');
      } else if (updateResults.length === 1 && updateResults[0]?.status === 'error') {
        // one vendor was sent for update and the update failed
        handleFail(updateResults[0].error);
      } else {
        // more than one vendor was sent for update and all failed to update
        onDone();
      }
    };

    const sendNotificationToVendor = (
      vendorsContact: PaymentScheduledNotifyVendorFormWidgetFields['vendorsContact']
    ) => {
      const vendorsToUpdate = vendorsContact.filter((vendor) => vendor.email);
      if (!vendorsToUpdate.length) {
        return onDone();
      }

      vendorsCollection
        .batchUpdate(vendorsToUpdate.map((vendor) => ({ id: vendor.id, data: { contact: { email: vendor.email } } })))
        .then(onVendorsUpdateDone)
        .catch(handleFail);
    };

    if (!vendorsCollection.data) {
      return <NotifyLoadingModalScreen isOpen={isOpen} onClose={onClose} />;
    }

    return (
      <>
        <NotifyModalScreen
          isOpen={isOpen && currentStep === 'NOTIFY_VENDOR'}
          onClose={onClose}
          vendors={vendorsCollection.data}
          onDone={sendNotificationToVendor}
          isSaving={vendorsCollection.isMutating}
        />
        <ConfirmationModalScreen
          isOpen={isOpen && currentStep === 'NOTIFY_VENDOR_CONFIRMATION'}
          vendors={updatedVendors || []}
          onDone={onDone}
        />
      </>
    );
  }
);
