import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';

import { NewSinglePaymentStepLayout } from '../../NewSinglePaymentStepLayout';
export const PaymentUpdatedActionsButtons = ({
  onBackToDashboard,
  isNewSuccessScreen = false,
}: {
  onBackToDashboard: VoidFunction;
  isNewSuccessScreen?: boolean;
}) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();

  return (
    <NewSinglePaymentStepLayout.NextButton
      isFullWidth={isNewSuccessScreen}
      onClick={() => {
        track('Payment', 'Click', {
          IsRecurring: true,
          Cta: 'back-to-dashboard',
        });

        onBackToDashboard();
      }}
      label={formatMessage('activities.editBillSubscription.screens.paymentUpdated.backToDashboard')}
    />
  );
};
