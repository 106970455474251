import { BillSubscriptionIntervalTypeEnum } from '@melio/platform-api';
import { MessageKey } from '@melio/platform-i18n';
import { DaysInOrder } from '@melio/platform-utils';
import { add, addMonths, addWeeks, differenceInMonths, differenceInWeeks, differenceInYears } from 'date-fns';

import { SINGLE_PAYMENT } from './types';

export const MAX_ALLOWED_OCCURRENCES: { [key in BillSubscriptionIntervalTypeEnum]: number } = {
  [BillSubscriptionIntervalTypeEnum.Weekly]: 104,
  [BillSubscriptionIntervalTypeEnum.Monthly]: 24,
  [BillSubscriptionIntervalTypeEnum.Yearly]: -1, // Not supported
  [BillSubscriptionIntervalTypeEnum.Every2Months]: -1, // Not supported
  [BillSubscriptionIntervalTypeEnum.Every3Months]: -1, // Not supported
  [BillSubscriptionIntervalTypeEnum.Every4Months]: -1, // Not supported
  [BillSubscriptionIntervalTypeEnum.Every6Months]: -1, // Not supported
  [BillSubscriptionIntervalTypeEnum.TwiceAMonth]: -1, // Not supported
  [BillSubscriptionIntervalTypeEnum.Every2Weeks]: -1, // Not supported
  [BillSubscriptionIntervalTypeEnum.Every4Weeks]: -1, // Not supported
};

export const getStartDateHelperText = (
  paymentFrequency: BillSubscriptionIntervalTypeEnum,
  startDate: Date,
  formatMessage: (id: MessageKey, values?: Record<string, unknown>) => string
): string => {
  if (!startDate) {
    return '';
  }
  switch (paymentFrequency) {
    case BillSubscriptionIntervalTypeEnum.Weekly:
      return formatMessage('widgets.addBillForm.startDate.helperText.weekly', {
        weekDay: DaysInOrder[startDate.getDay()] as string,
      });
    case BillSubscriptionIntervalTypeEnum.Monthly: {
      return formatMessage('widgets.addBillForm.startDate.helperText.monthly', {
        day: startDate.getDate(),
      });
    }

    default:
      return '';
  }
};

export const calculateEndDateByNumOfOccurrences = (
  paymentFrequency: BillSubscriptionIntervalTypeEnum,
  startDate?: Date,
  numOfOccurrences?: number
): Date | null => {
  if (!startDate || !numOfOccurrences) {
    return null;
  }
  switch (paymentFrequency) {
    case BillSubscriptionIntervalTypeEnum.Weekly:
      return addWeeks(startDate, numOfOccurrences - 1);
    case BillSubscriptionIntervalTypeEnum.Monthly:
      return addMonths(startDate, numOfOccurrences - 1);
    default:
      return null;
  }
};

const diffFuncMapper: {
  [key in BillSubscriptionIntervalTypeEnum]?: (startDate: Date, endDate: Date, options: object) => number;
} = {
  [BillSubscriptionIntervalTypeEnum.Weekly]: differenceInWeeks,
  [BillSubscriptionIntervalTypeEnum.Monthly]: differenceInMonths,
  [BillSubscriptionIntervalTypeEnum.Yearly]: differenceInYears,
};

const intervalMapper: { [key in BillSubscriptionIntervalTypeEnum]?: string } = {
  [BillSubscriptionIntervalTypeEnum.Weekly]: 'weeks',
  [BillSubscriptionIntervalTypeEnum.Monthly]: 'months',
  [BillSubscriptionIntervalTypeEnum.Yearly]: 'years',
};

export const calculateEffectiveEndDate = (paymentFrequency: string, startDate?: Date, generalEndDate?: Date) => {
  if (!startDate || !generalEndDate || paymentFrequency === SINGLE_PAYMENT) {
    return null;
  }
  const diffFunc = diffFuncMapper[paymentFrequency as BillSubscriptionIntervalTypeEnum];
  if (!diffFunc) {
    throw new Error('Unsupported payment frequency');
  }

  const diff = diffFunc(generalEndDate, startDate, { roundingMethod: 'floor' });

  const interval = intervalMapper[paymentFrequency as BillSubscriptionIntervalTypeEnum];
  if (!interval) {
    throw new Error('Unsupported payment frequency');
  }
  const estimatedEndDate = add(startDate, {
    [interval]: diff,
  });
  return estimatedEndDate;
};
