import { Box } from '@chakra-ui/react';
import { CatalogItem } from '@melio/ar-domain';
import { Table } from '@melio/penny';

import { useCatalogItemActionsItems } from '../hooks';

type CatalogItemActionCellProps = {
  catalogItem: CatalogItem;
  onEditItem: (itemId: string) => unknown;
  onDeleteItem: (itemId: string) => unknown;
};

const handleCellClick = (e: React.MouseEvent<HTMLDivElement>) => {
  e.stopPropagation();
};

export const CatalogItemActionCell = ({ catalogItem, onEditItem, onDeleteItem }: CatalogItemActionCellProps) => {
  const actions = useCatalogItemActionsItems({ catalogItem, onEditItem, onDeleteItem });

  return (
    <Box onClick={handleCellClick} height="full">
      <Table.ActionsCell options={actions} data-testid={`catalog-item-actions-cell-${catalogItem.id}`} />
    </Box>
  );
};
