import { useModelApi, UseModelApiProps } from '@melio/api-client';
// eslint-disable-next-line no-restricted-imports
import { SubscriptionOrganization, SubscriptionOrganizationsApiClient } from '@melio/platform-api-axios-client';

type QueryFn = typeof SubscriptionOrganizationsApiClient.getSubscriptionOrganization;

export type UseSubscriptionOrganizationProps = UseModelApiProps<QueryFn, SubscriptionOrganization>;

export const useSubscriptionOrganization = (props: UseSubscriptionOrganizationProps = {}) =>
  useModelApi({
    ...props,
    id: 'fake_id',
    queryKey: 'SubscriptionOrganizationsApi',
    queryFn: SubscriptionOrganizationsApiClient.getSubscriptionOrganization,
  });
