import { useModelApi, UseModelApiProps } from '@melio/api-client';
import { FilesApiClient } from '@melio/platform-api-axios-client';

type QueryFn = typeof FilesApiClient.getFilesFileIdData;

export type UseFileOCRDataProps = UseModelApiProps<QueryFn>;

export const useFileOCRData = (props: UseFileOCRDataProps) => {
  const query = useModelApi<QueryFn>({
    ...props,
    queryKey: ['FilesApi', 'getOCRData', props.id],
    queryFn: () => FilesApiClient.getFilesFileIdData(props.id as string),
  });

  return {
    ...query,
  };
};
