import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { object, SchemaOf, string } from 'yup';
import { Container, Group, Icon, Link, Text } from '@melio/penny';
import { Form, useMelioForm } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useOrganizationPreferences } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useSystemMessage } from '@melio/platform-utils';

import { usePartnerConfig } from '@/hooks/partners';
import { usePlatformIntl } from '@/translations/Intl';
import { FeesConfirmationModal } from '@/widgets/settings-page/ReceivingMethodsPage/FeesConfirmationModal.widget';

interface CreditCardFeePaymentProps {
  disabled: boolean;
  isVendorAbsorbedFee?: string;
}

export enum CreditCardFeePaymentEnum {
  PAYOR = 'payor',
  VENDOR = 'vendor',
}

const schema = object().shape({
  creditCardFeeStatus: string(),
}) as SchemaOf<{ creditCardFeeStatus?: string }>;

export const CreditCardFeePayment = ({ disabled, isVendorAbsorbedFee }: CreditCardFeePaymentProps) => {
  const { formatMessage } = usePlatformIntl();

  const { showMessage } = useSystemMessage();

  const { partnerConfig } = usePartnerConfig();

  const [shouldDisplayZendesk] = useFeature(FeatureFlags.SwitchToZendeskChat, false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [vendorAbsorbsFeesSetting, setVendorAbsorbsFeesSetting] = useState<boolean>(false);

  const [selectedFeeValue, setSelectedFeeValue] = useState(false);

  const { track } = useAnalytics();

  const collection = useOrganizationPreferences({ enabled: false });

  const url = shouldDisplayZendesk
    ? partnerConfig.features?.receivingMethods?.learnMoreUrl.zendeskLink
    : partnerConfig.features?.receivingMethods?.learnMoreUrl.melioPaymentsLink;

  const { formProps, registerField } = useMelioForm({
    onSubmit: () => null,
    schema,
  });

  const updateServiceFeesPreference = (value: boolean): void => {
    const saveFeeOptionEventName = value
      ? 'MyBusinessPayTheFeeReceivingMethodsSettingPage'
      : 'CustomerPayTheFeeReceivingMethodsSettingPage';
    track(saveFeeOptionEventName, 'Submitted');

    collection
      .create({
        key: 'isVendorAbsorbedFee',
        value: `${value}`,
      })
      .then((data) => {
        showMessage({
          type: 'success',
          title: formatMessage('widgets.receivingMethodsSettings.creditCardFeePayment.updateSuccess'),
        });
        setOpenConfirmationModal(false);
        setVendorAbsorbsFeesSetting(data.value === 'true');
        track('VendorAbsorbedFee', data.value === 'true' ? 'absorb-fee-enabled' : 'absorb-fee-disabled');
        track(saveFeeOptionEventName, 'Succeeded');
      })
      .catch(() => {
        setOpenConfirmationModal(false);
        showMessage({
          type: 'error',
          title: formatMessage('widgets.receivingMethodsSettings.creditCardFeePayment.updateFailed'),
        });
      });
  };

  const selected = vendorAbsorbsFeesSetting ? CreditCardFeePaymentEnum.VENDOR : CreditCardFeePaymentEnum.PAYOR;

  const onChange = (e: React.ChangeEvent<HTMLElement>) => {
    const selection = (e?.target as HTMLInputElement).value;

    if (!selection || selected === selection) {
      return;
    }

    const isVendorAbsorbedFees = selection === CreditCardFeePaymentEnum.VENDOR;

    setOpenConfirmationModal(true);
    setSelectedFeeValue(isVendorAbsorbedFees);

    const eventName = isVendorAbsorbedFees
      ? 'MyBusinessPayTheFeeReceivingMethodsSettingPage'
      : 'CustomerPayTheFeeReceivingMethodsSettingPage';
    track(eventName, 'Chose');
  };

  useEffect(() => {
    setVendorAbsorbsFeesSetting(isVendorAbsorbedFee === 'true');
  }, [isVendorAbsorbedFee]);

  const infoLink = (
    <Link
      variant="inline"
      newTab
      href={String(url)}
      label={formatMessage('widgets.receivingMethodsSettings.creditCardFeePayment.learnMore')}
    />
  );

  return (
    <Box data-testid="credit-card-fee-payment-section">
      <Container paddingBottom="m">
        <Group>
          <Icon type="credit-card" />
          <Text textStyle="body2Semi">
            {formatMessage('widgets.receivingMethodsSettings.creditCardFeePayment.title')}
          </Text>
        </Group>
      </Container>

      {disabled && (
        <Container paddingBottom="m">
          <Text data-testid="credit-card-fee-payment-learn-more">
            {formatMessage('widgets.receivingMethodsSettings.creditCardFeePayment.description', { link: infoLink })}
          </Text>
        </Container>
      )}
      <Form {...formProps}>
        <Form.RadioGroup
          {...registerField('creditCardFeeStatus')}
          variant="vertical"
          isDisabled={disabled}
          onChange={onChange}
          value={selected}
          isViewMode={false}
          options={[
            {
              mainLabelProps: {
                label: formatMessage('widgets.receivingMethodsSettings.creditCardFeePayment.options.payor.title'),
              },
              descriptionProps: {
                label: formatMessage('widgets.receivingMethodsSettings.creditCardFeePayment.options.payor.description'),
              },
              value: CreditCardFeePaymentEnum.PAYOR,
            },
            {
              mainLabelProps: {
                label: formatMessage('widgets.receivingMethodsSettings.creditCardFeePayment.options.vendor.title'),
              },
              descriptionProps: {
                label: formatMessage(
                  'widgets.receivingMethodsSettings.creditCardFeePayment.options.vendor.description',
                ),
              },
              value: CreditCardFeePaymentEnum.VENDOR,
            },
          ]}
          aria-label="credit card fee status"
        />
      </Form>

      <FeesConfirmationModal
        isOpen={openConfirmationModal}
        onClose={() => setOpenConfirmationModal(false)}
        onConfirm={() => updateServiceFeesPreference(selectedFeeValue)}
        title={
          selectedFeeValue
            ? formatMessage('widgets.receivingMethodsSettings.creditCardFeePayment.feesApprovalDialog.vendor.title')
            : formatMessage('widgets.receivingMethodsSettings.creditCardFeePayment.feesApprovalDialog.payor.title')
        }
        description={
          selectedFeeValue
            ? formatMessage(
                'widgets.receivingMethodsSettings.creditCardFeePayment.feesApprovalDialog.vendor.description',
              )
            : formatMessage(
                'widgets.receivingMethodsSettings.creditCardFeePayment.feesApprovalDialog.payor.description',
              )
        }
      />
    </Box>
  );
};
