import { useLocation, useNavigate, useParams } from 'react-router-dom';
import noop from 'lodash/noop';
import { BatchPaymentsActivity, PaymentFlowDoneAction } from '@melio/ap-activities';
import { OriginFlow } from '@melio/platform-analytics';
import { useSystemMessage } from '@melio/platform-utils';

import { usePaymentFlowIntuitConnect } from '@/hooks/accountingPlatforms.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { PayDashboardTabs } from '@/types/payDashboard.types';
import { useRouter } from '../../hooks/router.hooks';

export const BatchPaymentsScreen = () => {
  const { replaceToPayDashboardTab, goToAddNewBill, generateNPEDashboardLink } = useRouter();
  const { showMessage, hideMessage } = useSystemMessage();
  const { formatMessage } = usePlatformIntl();
  const { state } = useLocation();
  const { loginToAccountPlatformAuth, isLoginToAccountPlatformAuthLoading } = usePaymentFlowIntuitConnect({
    redirectMethod: 'replace',
  });

  const { returnUrl } = (state as { returnUrl?: string }) || {};

  const navigate = useNavigate();

  const { ids } = useParams();

  const billIds = ids?.split(',') || [];

  const handleDone = (paymentIds: string[], action: PaymentFlowDoneAction = 'goToDashboard') => {
    const handlers: Record<PaymentFlowDoneAction, (paymentsIds: string[]) => void> = {
      goToDashboard: handleGoToDashboard,
      createNewPayment: handleCreateNewPayment,
      syncQBOAccountingPlatform: loginToAccountPlatformAuth,
      openInNewTabDashboard: noop,
      resolveFailedPayments: noop,
    };

    handlers[action](paymentIds);
  };

  const handleCreateNewPayment = () => {
    goToAddNewBill({ originFlow: OriginFlow.PaymentScheduled });
  };

  const handleGoToDashboard = (paymentIds: string[]) => {
    const toastTitle =
      paymentIds.length > 1
        ? formatMessage('screens.batchPayments.toast.success.multiple.title', { numberOfBills: paymentIds.length })
        : formatMessage('screens.batchPayments.toast.success.single.title');

    if (returnUrl) {
      const toastActionText = formatMessage('screens.batchPayments.toast.success.action', {
        pluralSuffix: paymentIds.length === 1 ? '' : 's',
      });
      navigate(returnUrl, { state: { keepToast: true } });
      showMessage({
        type: 'success',
        title: toastTitle,
        action: {
          type: 'button',
          text: toastActionText,
          onAction: () => {
            navigate(generateNPEDashboardLink('payments'));
            hideMessage();
          },
        },
      });
      return;
    }

    showMessage({
      type: 'success',
      title: toastTitle,
    });
    replaceToPayDashboardTab(PayDashboardTabs.Scheduled, undefined, { keepToast: true });
  };

  const handleBack = () => {
    if (returnUrl) return navigate(returnUrl);
    return replaceToPayDashboardTab(PayDashboardTabs.Inbox);
  };

  if (billIds) {
    return (
      <BatchPaymentsActivity
        billIds={billIds}
        onDone={handleDone}
        onBack={handleBack}
        isLoading={isLoginToAccountPlatformAuthLoading}
      />
    );
  }

  return null;
};
