import { Box } from '@chakra-ui/react';
import { Card, GridItem, Group, Image, LoadingContainer, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import React from 'react';

import { useIsMediumScreen, useIsSmallScreen } from '../../../hooks';

export const FirmDashboardEmptyStateGuard: React.FC<{ isEmpty: boolean; isLoading: boolean }> = ({
  isEmpty,
  isLoading,
  children,
}) => {
  const isSmallScreen = useIsSmallScreen();
  const isMediumScreen = useIsMediumScreen();
  const { formatMessage } = useMelioIntl();
  const config = useConfig();
  const emptyStateAsset = config.settings.accountingFirmDashboard.emptyStateAsset;

  return (
    <LoadingContainer isLoading={isLoading}>
      {isEmpty ? (
        <Box style={{ margin: 'auto', maxWidth: 800 }}>
          <Card paddingX={isSmallScreen ? 'm' : 'xxl'} data-testid="firm-dashboard-empty-state-guard">
            <Group variant={isMediumScreen ? 'vertical' : 'horizontal'} justifyContent="space-between" width="full">
              <Group variant="vertical">
                <Text textStyle="heading2Semi">
                  {formatMessage('activities.accountants.firmDashboard.emptyState.title')}
                </Text>
                <GridItem>{formatMessage('activities.accountants.firmDashboard.emptyState.description')}</GridItem>
              </Group>
              <Image
                height="240"
                objectFit="contain"
                src={emptyStateAsset}
                alt={formatMessage('activities.accountants.firmDashboard.emptyState.description')}
              />
            </Group>
          </Card>
        </Box>
      ) : (
        children
      )}
    </LoadingContainer>
  );
};
