import { NakedButton, SectionBanner } from '@melio/penny';
import { sanitizeStringForAnalytics, useAnalytics } from '@melio/platform-analytics';
import {
  DeliveryMethodType,
  FundingSourceType,
  PaymentFullyExpanded,
  useIsPlaidLinkVerificationTokenFetching,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useDateUtils } from '@melio/platform-utils';
import { addDays } from 'date-fns';
import { RefObject } from 'react';

import { MicroDepositModalRefHandlers } from '../../../../funding-sources';

const maxDaysWaitingForVendorDeliveryMethod = 7;
export const PaymentScheduledActionAlert = ({
  payment,
  microDepositModalRef,
}: {
  payment: PaymentFullyExpanded;
  microDepositModalRef?: RefObject<MicroDepositModalRefHandlers>;
}) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const { createDate } = useDateUtils();
  const { track } = useAnalytics();

  const { fundingSource, deliveryMethod, vendor } = payment;
  const isVerifyingBankAccount = useIsPlaidLinkVerificationTokenFetching(fundingSource.id);

  let alertDescription = '';
  let alertAction;

  if (deliveryMethod.type === DeliveryMethodType.VirtualAccount) {
    alertDescription = formatMessage('widgets.paymentDetails.scheduled.actionRequired.unilateral', {
      vendorName: vendor.name,
      date: formatDate(
        addDays(createDate(payment.scheduledDate.toISOString()), maxDaysWaitingForVendorDeliveryMethod),
        { dateStyle: 'medium' }
      ),
    });
  }

  // payment can't have virtual deliveryMethod and unverified paymentMethod at once (risk wise)
  if (fundingSource.type === FundingSourceType.BankAccount && !fundingSource.isVerified) {
    alertDescription = formatMessage('widgets.paymentDetails.scheduled.actionRequired.verifyBankAccount.text', {
      '4digits': fundingSource.details.accountNumber.slice(-4),
      date: formatDate(createDate(payment.scheduledDate.toISOString()), {
        month: 'short',
        day: 'numeric',
      }),
    });

    if (!microDepositModalRef) {
      throw new Error('microDepositModalRef is required');
    }

    alertAction = (
      <NakedButton
        variant="secondary"
        onClick={() => {
          track('PaymentMethod', 'Click', {
            PageName: 'payment-summary',
            Flow: 'dashboard',
            Intent: 'verify-payment-method',
            PaymentMethodId: payment.fundingSourceId,
            PaymentMethodType: fundingSource?.type,
            Cta: sanitizeStringForAnalytics(
              formatMessage('widgets.paymentMethods.paymentMethodLineItem.bankAccount.unverified.link')
            ),
            EntryPoint: 'banner',
            UserMessage: 'verify-your-bank-account',
          });
          microDepositModalRef?.current?.openModal();
        }}
        label={formatMessage('widgets.paymentDetails.scheduled.actionRequired.verifyBankAccount.link')}
        isDisabled={isVerifyingBankAccount}
        data-testid="payment-scheduled-action-alert-verification-cta"
      />
    );
  }

  return alertDescription ? (
    <SectionBanner
      variant="warning"
      description={alertDescription}
      title={formatMessage('widgets.paymentDetails.scheduled.actionRequired.title')}
      data-testid="payment-scheduled-action-alert-verification-banner"
      action={alertAction}
    />
  ) : null;
};
