import { Group, Icon, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export const Header = () => {
  const { formatMessage } = useMelioIntl();

  return (
    <Group width="full" data-testid="pay-dashboard-financing-tab-drawer-header">
      <Icon type="installments" size="large" />,
      <Group variant="vertical" spacing="xxxs">
        <Text textStyle="body2Semi">{formatMessage('activities.payDashboard.drawer.header.loan.mainLabel')}</Text>
        <Text textStyle="body4">{formatMessage('activities.payDashboard.drawer.header.loan.description')}</Text>
      </Group>
    </Group>
  );
};
