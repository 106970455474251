import React from 'react';
import { Box } from '@chakra-ui/react';
import { IconButton } from '@melio/penny';

import { ContactSummary } from '@/cl/components/ContactSummary/ContactSummary.component';
import { useIsMobile } from '@/hooks/useIsMobile';

type PayDetailsTopProps = {
  vendorName: string;
  subTitle?: string;
  onBackClick: VoidFunction;
};

export const PayDetailsTop: React.FC<PayDetailsTopProps> = ({ vendorName, subTitle, onBackClick, children }) => {
  const isMobile = useIsMobile();

  return (
    <Box display="flex" justifyContent={'space-between'} width={'full'} flexDir={isMobile ? 'column' : 'row'}>
      <Box
        display="flex"
        maxWidth={{ xs: '100%', s: 'calc(100% - 165px)' }}
        mr={'8px'}
        gap="8px"
        justifyContent={'space-between'}
      >
        {isMobile && (
          <IconButton icon="chevron-left" aria-label="Go back" variant="naked" size="medium" onClick={onBackClick} />
        )}

        <ContactSummary title={vendorName} subtitle={subTitle} />
        {isMobile && <Box width="24px" />}
      </Box>
      <Box display="flex" mt={isMobile ? '8px' : '0px'} flexDir="row" alignSelf="center" gap="8px">
        {children}
      </Box>
    </Box>
  );
};
