import { PartnerGroupEnum } from '@melio/partner-tools';
import { useMelioIntl } from '@melio/platform-i18n';

import { Feature } from '../../../../api';
import { usePartnerGroup } from '../../../../hooks';
import { PlanConfig } from '../types';
import {
  currentConfigPerFeatureFiserv,
  currentPlanConfigPerFeature,
  upgradedPlanConfigPerFeature,
  upgradedPlanConfigPerFeatureFiserv,
} from './content';

type UseContextualConfigProps = {
  featureName: Feature;
  upgradePlanName: string;
};

type FeatureUpsellContext = {
  title: string;
  description: string;
  plans: {
    current: PlanConfig;
    upgrade: PlanConfig;
  };
};

// TODO: part of the texts here should be dynamic - the eligibility for trial and the next plan (plus or pro). for now, all of them are static https://meliorisk.atlassian.net/browse/ME-54165
export const useContextualConfig = ({
  featureName,
  upgradePlanName,
}: UseContextualConfigProps): FeatureUpsellContext => {
  const { formatMessage } = useMelioIntl();

  const partnerGroup = usePartnerGroup();

  const current =
    partnerGroup === PartnerGroupEnum.FISERV ? currentConfigPerFeatureFiserv : currentPlanConfigPerFeature;
  const upgraded =
    partnerGroup === PartnerGroupEnum.FISERV ? upgradedPlanConfigPerFeatureFiserv : upgradedPlanConfigPerFeature;

  const featureCurrentPlanConfig = current[featureName];
  const currentPlanConfig = {
    actionButtonLabel:
      'actionButton' in featureCurrentPlanConfig ? formatMessage(featureCurrentPlanConfig.actionButton) : undefined,
    capabilities: featureCurrentPlanConfig.capabilities.map((x) => formatMessage(x)),
    showAllFeaturesLink: false,
  };

  const featureUpgradedPlanConfig = upgraded[featureName];
  const upgradePlanConfig = {
    // TODO: https://meliorisk.atlassian.net/browse/ME-54886 text should be different if eligible for trial
    actionButtonLabel: formatMessage('activities.subscription.contextualPlans.action.upgradeTo', {
      planName: upgradePlanName,
    }),
    capabilities: featureUpgradedPlanConfig.capabilities.map((x) => formatMessage(x)),
    showAllFeaturesLink: true,
  };

  return {
    title: formatMessage(`activities.subscription.contextualPlans.${featureName}.title`, { upgradePlanName }),
    description: formatMessage('activities.subscription.contextualPlans.description'),
    plans: {
      current: currentPlanConfig,
      upgrade: upgradePlanConfig,
    },
  };
};
