import { FormattedMessage, FormDialog, FormWidgetProps, useMelioIntl } from '@melio/ar-domain';
import { Form, Group, Loader, NakedButton, SectionBanner, Text, useMelioForm } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import * as yup from 'yup';

const SUPPORTED_FORMATS = ['text/csv'];

export type ImportCustomersFormFields = {
  file: File | null;
};

const useValidationSchema = (fileSizeLimit: number) => {
  const { formatMessage } = useMelioIntl();

  return yup.object().shape({
    file: yup
      .mixed<File>()
      .nullable()
      .required(formatMessage('ar.modals.activities.importCustomers.modal.form.file.validations.required.text'))
      .test(
        'is-valid-file-type',
        formatMessage('ar.modals.activities.importCustomers.modal.form.file.validations.fileType.text'),
        (value) => (value instanceof File ? SUPPORTED_FORMATS.includes(value.type) : false)
      )
      .test(
        'is-file-size-below-limit',
        formatMessage('ar.modals.activities.importCustomers.modal.form.file.validations.fileSize.text'),
        (file) => {
          if (file) {
            return file.size <= fileSizeLimit;
          }

          return true;
        }
      ),
  }) as yup.SchemaOf<ImportCustomersFormFields>;
};

export type ImportCustomersScreenProps = Pick<FormWidgetProps<ImportCustomersFormFields>, 'isSaving' | 'onSubmit'> & {
  onClose: VoidFunction;
  onDownloadCSVTemplateFile: VoidFunction;
  isOpen: boolean;
  fileSizeLimit: number;
};
export const ImportCustomersScreen = forwardRef<ImportCustomersScreenProps>(
  ({ onSubmit, onDownloadCSVTemplateFile, isSaving, fileSizeLimit, onClose, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const { registerField, setValue, watch, ...rest } = useMelioForm<ImportCustomersFormFields>({
      schema: useValidationSchema(fileSizeLimit),
      isSaving,
      onSubmit,
    });

    return (
      <FormDialog
        closeButtonAriaLabel={formatMessage(
          'ar.settings.flows.invoiceSettings.sections.editInvoiceNote.modal.closeButtonAriaLabel'
        )}
        data-component={ImportCustomersScreen.displayName}
        size="large"
        displayRequiredFieldsText={!isSaving}
        onClose={() => !isSaving && onClose()}
        header={formatMessage('ar.modals.activities.importCustomers.modal.header.text')}
        description={!isSaving ? formatMessage('ar.modals.activities.importCustomers.modal.header.description') : ''}
        primaryButton={{
          label: formatMessage('ar.modals.activities.importCustomers.modal.primaryButton.label'),
        }}
        secondaryButton={{
          label: formatMessage('ar.modals.activities.importCustomers.modal.secondaryButton.label'),
        }}
        useFormResults={rest}
        {...props}
        ref={ref}
      >
        {!isSaving ? (
          <Group variant="vertical" spacing="l">
            <SectionBanner
              description={formatMessage('ar.modals.activities.importCustomers.modal.sectionBanner.text')}
              variant="informative"
            />
            <Form.FileUpload
              onDelete={() => setValue('file', null)}
              helperTextProps={{
                label: formatMessage('ar.modals.activities.importCustomers.modal.form.file.helperText'),
              }}
              labelProps={{
                label: formatMessage('ar.modals.activities.importCustomers.modal.form.file.label'),
              }}
              placeholder={formatMessage('ar.modals.activities.importCustomers.modal.file.placeholder')}
              {...registerField('file')}
            />
            <NakedButton
              onClick={onDownloadCSVTemplateFile}
              variant="secondary"
              data-testid="download-csv-template-file-naked-button"
              label={formatMessage('ar.modals.activities.importCustomers.modal.downloadTemplate.button.label')}
            />
          </Group>
        ) : (
          <Group variant="vertical" alignItems="center">
            <Loader />
            <Group variant="vertical" alignItems="center" spacing="xxs">
              <Text textStyle="body2Semi">
                <FormattedMessage id="ar.modals.activities.importCustomers.modal.loadingState.title.text" />
              </Text>
              <Text textStyle="body3">
                <FormattedMessage id="ar.modals.activities.importCustomers.modal.loadingState.subTitle.text" />
              </Text>
            </Group>
          </Group>
        )}
      </FormDialog>
    );
  }
);
ImportCustomersScreen.displayName = 'ImportCustomersScreen';
