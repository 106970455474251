import { Route, Routes } from 'react-router-dom';

import { AddVendor } from '@/screens/vendors/NewVendor.screen';

export const NewVendorRoute = () => {
  return (
    <Routes>
      <Route path={`/`} element={<AddVendor />} />
    </Routes>
  );
};
