/* eslint-disable max-lines */
import { useDisclosure } from '@chakra-ui/react';
import { usePaymentActions } from '@melio/ap-domain';
import { ActionsDropdownMenu, Button, Group, useBreakpoint } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import {
  BillSubscriptionApprovalDecisionStatusEnum,
  Payment,
  PostApprovalDecisionEnum,
  useBill,
  useMelioQueryClient,
  usePayments,
} from '@melio/platform-api';
import { useMelioIntl, usePartnerFeature } from '@melio/platform-provider';
import { compact } from 'lodash';
import { useContext, useState } from 'react';

import { getPaymentAnalyticsFields } from '../../../utils/analytics/paymentAnalytics';
import { CancelPaymentModal } from '../../cancel-payment-modal';
import { DeletePaymentModal } from '../../delete-payment-modal';
import { MarkAsPaidModal } from '../../mark-as-paid-modal';
import { PaymentDrawerContext } from '../PaymentDrawerContext';

export const Footer = withAnalyticsContext(
  ({
    payment,
    onClose,
    onApprovalDecision,
    isMutating,
  }: {
    payment: Payment;
    onClose: VoidFunction;
    onApprovalDecision: (decision: PostApprovalDecisionEnum) => Promise<void>;
    isMutating: boolean;
  }) => {
    const {
      onRetryFailedToDeliverPayment,
      onRetryFailedToCollectPayment,
      onRefundPayment,
      onVoidAndRefundPayment,
      onVoidAndResendPayment,
      onEditPayment,
      onMarkAsUnpaid,
      onEditBillSubscription,
    } = useContext(PaymentDrawerContext);
    const { formatMessage } = useMelioIntl();
    const { track } = useAnalytics();
    const { isExtraSmallScreen } = useBreakpoint();
    const [isMarkAsPaidEnabled] = usePartnerFeature('MarkAsPaid', false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [selectedEntity, setSelectedEntity] = useState<'payment' | 'subscription' | null>(null);

    const paymentActions = usePaymentActions(payment);

    const queryClient = useMelioQueryClient();
    const { markAsPaid: markAsUnpaid } = useBill({ id: payment.paymentBillsInfo?.[0]?.id });
    const { isMutating: isMutatingPayments } = usePayments({ enabled: false });
    const isPaymentRelatedToBillSubscriptionPendingApproval =
      payment.subscriptionOccurrence?.billSubscription?.approvalDecisionStatus ===
      BillSubscriptionApprovalDecisionStatusEnum.Pending;
    const {
      isOpen: isDeletePaymentModalOpen,
      onOpen: onDeletePaymentModalOpen,
      onClose: onDeletePaymentModalClose,
    } = useDisclosure();
    const {
      isOpen: isCancelPaymentModalOpen,
      onOpen: onCancelPaymentModalOpen,
      onClose: onCancelPaymentModalClose,
    } = useDisclosure();

    const {
      isOpen: isMarkAsPaidModalOpen,
      onOpen: onMarkAsPaidModalOpen,
      onClose: onMarkAsPaidClose,
    } = useDisclosure();

    const handleDeletePayment = () => {
      void queryClient.invalidateQueries('PaymentsApi');
      onClose();
    };

    const handelOnSuccessMarkAsPaid = () => {
      void queryClient.invalidateQueries('PaymentsApi');
    };

    const handleMarkedFailedPaymentAsPaid = () => {
      onMarkAsPaidModalOpen();
    };

    const handleMarkAsUnpaid = async () => {
      await markAsUnpaid({ isPaid: false });
      onClose();
      onMarkAsUnpaid(payment);
    };
    const billSubscriptionId = payment.subscriptionOccurrence?.billSubscriptionId;

    const actionMenuItems = compact([
      paymentActions.actions.approvalDecision && {
        label: formatMessage(
          isPaymentRelatedToBillSubscriptionPendingApproval
            ? 'widgets.paymentDrawer.footer.approveAll'
            : 'widgets.paymentDrawer.footer.approve'
        ),
        onClick: () => {
          track('Payment', 'Click', { Intent: 'payment-details', Cta: 'approve-payment' });
          onApprovalDecision(PostApprovalDecisionEnum.Approved);
        },
        isLoading: isMutatingPayments || isMutating,
        'data-testid': 'payment-btn-approve',
      },
      paymentActions.actions.resolveFailedToCollect && {
        label: formatMessage('widgets.paymentDrawer.footer.resolveAndReschedulePayment'),
        onClick: () => {
          track('Payment', 'Click', { Intent: 'payment-details', Cta: 'failed-to-collect' });
          onRetryFailedToCollectPayment(payment.id);
        },
        'data-testid': 'payment-btn-failed-to-collect',
      },
      paymentActions.actions.resolveFailedToDeliver && {
        label: formatMessage('widgets.paymentDrawer.footer.retryPayment'),
        onClick: () => {
          track('Dashboard', 'Click', {
            Cta: 'retry-payment',
            Flow: 'dashboard',
            EntryPoint: 'dashboard-inbox',
            FailedDestination: 'deliver-failure',
            ...getPaymentAnalyticsFields(payment),
          });
          onRetryFailedToDeliverPayment(payment.id);
        },
        'data-testid': 'payment-btn-failed-to-deliver',
      },
      paymentActions.actions.edit && {
        label: formatMessage('widgets.paymentDrawer.footer.editPayment'),
        onClick: () => {
          track('Payment', 'Click', { Intent: 'payment-details', Cta: 'edit-payment' });
          onEditPayment(payment.id);
        },
        'data-testid': 'payment-btn-edit',
      },
      paymentActions.actions.isEditBillSubscriptionAllowed &&
        onEditBillSubscription &&
        billSubscriptionId && {
          label: formatMessage('widgets.paymentDrawer.footer.editBillSubscription'),
          onClick: () => {
            track('Payment', 'Click', { Intent: 'payment-details', Cta: 'edit-bill-subscription' });
            onEditBillSubscription(billSubscriptionId);
          },
          'data-testid': 'payment-btn-edit-bill-subscription',
        },
      paymentActions.actions.delete && {
        label: formatMessage('widgets.paymentDrawer.footer.cancelPayment'),
        onClick: () => {
          track('Payment', 'Click', { Intent: 'payment-details', Cta: 'delete-payment' });
          setSelectedEntity('payment');
          onDeletePaymentModalOpen();
        },
        variant: 'critical' as const,
        'data-testid': 'payment-btn-delete',
      },
      paymentActions.actions.cancel && {
        label: formatMessage('widgets.paymentDrawer.footer.cancelPayment'),
        onClick: () => {
          track('Payment', 'Click', { Intent: 'payment-details', Cta: 'cancel-payment' });
          setSelectedEntity('payment');
          onCancelPaymentModalOpen();
        },
        variant: 'critical' as const,
        'data-testid': 'payment-btn-cancel',
      },
      paymentActions.actions.cancelBillSubscription &&
        !paymentActions.actions.cancel && {
          label: formatMessage('widgets.paymentDrawer.footer.cancelAllPayments'),
          onClick: () => {
            track('Payment', 'Click', { Intent: 'payment-details', Cta: 'delete-bill-subscription' });
            setSelectedEntity('subscription');
            onDeletePaymentModalOpen();
          },
          variant: 'critical' as const,
          'data-testid': 'payment-btn-delete',
        },
      paymentActions.actions.cancelBillSubscription &&
        paymentActions.actions.cancel && {
          label: formatMessage('widgets.paymentDrawer.footer.cancelAllPayments'),
          onClick: () => {
            track('Payment', 'Click', { Intent: 'payment-details', Cta: 'cancel-all-payments' });
            setSelectedEntity('subscription');
            onCancelPaymentModalOpen();
          },
          variant: 'critical' as const,
          'data-testid': 'payment-btn-delete',
        },
      paymentActions.actions.resolveRefund && {
        label: formatMessage('widgets.paymentDrawer.footer.getRefund'),
        onClick: () => {
          track('Payment', 'Click', { Intent: 'payment-details', Cta: 'get-a-refund' });
          onRefundPayment(payment.id);
        },
        'data-testid': 'payment-btn-get-a-refund',
      },
      paymentActions.actions.markAsUnpaid && {
        label: formatMessage('widgets.paymentDrawer.footer.markAsUnpaid'),
        onClick: () => {
          track('Payment', 'Click', { Intent: 'payment-details', Cta: 'mark-as-unpaid' });
          void handleMarkAsUnpaid();
        },
        'data-testid': 'payment-btn-mark-as-unpaid',
      },
      paymentActions.actions.markAsPaid && {
        label: formatMessage('widgets.paymentDrawer.footer.markAsPaid'),
        onClick: () => {
          track('Payment', 'Click', { Intent: 'payment-details', Cta: 'mark-as-paid' });
          handleMarkedFailedPaymentAsPaid();
        },
        'data-testid': 'payment-btn-mark-as-paid',
      },
      paymentActions.actions.resolveVoidAndResend && {
        label: formatMessage('widgets.paymentDrawer.footer.voidAndResend'),
        onClick: () => {
          track('Payment', 'Click', { Intent: 'payment-details', Cta: 'void-and-resend' });
          onVoidAndResendPayment(payment.id);
        },
        'data-testid': 'payment-btn-void-and-resend',
      },
      paymentActions.actions.resolveVoidAndRefund && {
        label: formatMessage('widgets.paymentDrawer.footer.voidAndRefund'),
        onClick: () => {
          track('Payment', 'Click', { Intent: 'payment-details', Cta: 'void-and-refund' });
          onVoidAndRefundPayment(payment.id);
        },
        'data-testid': 'payment-btn-void-and-refund',
      },
      paymentActions.actions.approvalDecision && {
        label: formatMessage(
          isPaymentRelatedToBillSubscriptionPendingApproval
            ? 'widgets.paymentDrawer.footer.declineAll'
            : 'widgets.paymentDrawer.footer.decline'
        ),
        onClick: () => {
          track('Payment', 'Click', { Intent: 'payment-details', Cta: 'decline-payment' });
          onApprovalDecision(PostApprovalDecisionEnum.Declined);
        },
        variant: 'critical' as const,
        'data-testid': 'payment-btn-decline',
      },
    ]);

    const [primaryItem, ...actionItems] = actionMenuItems;
    const [secondaryItem, ...moreItems] = actionItems;
    const hasMultipleSecondaries = moreItems.length > 0;

    return (
      <Group
        width="full"
        spacing={isExtraSmallScreen ? 's' : 'm'}
        data-testid="pay-dashboard-payments-tab-drawer-footer"
        justifyContent={secondaryItem ? 'space-between' : 'flex-end'}
      >
        {hasMultipleSecondaries ? (
          <ActionsDropdownMenu
            isFullWidthTrigger={isExtraSmallScreen}
            label={formatMessage('widgets.paymentDrawer.footer.more')}
            items={actionItems}
            data-testid="payment-footer-menu"
            isOpen={isMenuOpen}
            onOpenChange={setIsMenuOpen}
          />
        ) : null}
        {!hasMultipleSecondaries && secondaryItem && (
          <Group width={isExtraSmallScreen ? 'full' : 'fit-content'}>
            <Button
              {...secondaryItem}
              variant={secondaryItem.variant === 'critical' ? 'critical-secondary' : 'secondary'}
            />
          </Group>
        )}
        {primaryItem && <Button variant="primary" {...primaryItem} isFullWidth={isExtraSmallScreen} />}
        {isDeletePaymentModalOpen && (
          <DeletePaymentModal
            isOpen={isDeletePaymentModalOpen}
            onClose={onDeletePaymentModalClose}
            payment={payment}
            selectedEntity={selectedEntity}
            onSuccess={handleDeletePayment}
          />
        )}
        {isCancelPaymentModalOpen && (
          <CancelPaymentModal
            isOpen={isCancelPaymentModalOpen}
            onClose={onCancelPaymentModalClose}
            payment={payment}
            shouldCancelBillSubscription={selectedEntity === 'subscription'}
          />
        )}
        {isMarkAsPaidEnabled && (
          <MarkAsPaidModal
            id={payment.id}
            isOpen={isMarkAsPaidModalOpen}
            onClose={onMarkAsPaidClose}
            onSuccess={handelOnSuccessMarkAsPaid}
            type="payment"
          />
        )}
      </Group>
    );
  }
);
