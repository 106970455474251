import { useIsMobile, useMelioIntl } from '@melio/ar-domain';
import { Group, Layout, TabItem as DSTabItem, Tabs } from '@melio/penny';
import { PageTitle } from '@melio/platform-utils';
import { Outlet } from 'react-router-dom';

import { HeaderToolBar, HeaderToolBarMobile } from './components';

export type TabsLayoutProps<T extends string = string> = {
  tabs: TabItem<T>[];
  activeTab: T;
  onChange: (name: T) => void;
  onCreateInvoice: VoidFunction;
  onCreateCustomer: VoidFunction;
  onVisitSupportSettingsPage: VoidFunction;
};

export function TabsLayout<T extends string = string>({
  activeTab,
  onChange,
  tabs,
  onCreateInvoice,
  onCreateCustomer,
  onVisitSupportSettingsPage,
  ...props
}: TabsLayoutProps<T>) {
  const { formatMessage } = useMelioIntl();
  const isMobile = useIsMobile();

  return (
    <Layout variant="full" backgroundColor="white" data-component={TabsLayout.displayName} {...props}>
      <Group variant="vertical">
        <Group spacing="m" variant="horizontal" justifyContent="space-between">
          <PageTitle textStyle="heading1Semi">
            {formatMessage('ar.dashboard.activities.tabsLayout.title.label')}
          </PageTitle>
          {isMobile ? (
            <HeaderToolBarMobile
              onCreateInvoice={onCreateInvoice}
              onCreateCustomer={onCreateCustomer}
              onVisitSupportSettingsPage={onVisitSupportSettingsPage}
            />
          ) : (
            <HeaderToolBar onCreateInvoice={onCreateInvoice} onVisitSupportSettingsPage={onVisitSupportSettingsPage} />
          )}
        </Group>
        <Tabs
          aria-label={formatMessage('ar.dashboard.activities.tabsLayout.tabs.aria-label')}
          tabs={tabs}
          activeTab={activeTab}
          onChange={onChange as never}
        />
        <Outlet />
      </Group>
    </Layout>
  );
}
TabsLayout.displayName = 'TabsLayout';

export type TabItem<T = string> = Override<DSTabItem, { name: T }>;
