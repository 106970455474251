import {
  CatalogItem,
  InvoiceDiscount,
  InvoiceLineItemPost,
  InvoiceTaxRate,
  PostInvoicesRequest,
  PutInvoiceRequest,
  useCatalogItems,
} from '@melio/ar-domain';
import { useDateUtils } from '@melio/platform-utils';
import { addDays } from 'date-fns';
import { pick } from 'lodash';

import { ServiceChargeParams } from '../../../types';
import { DueDatePreset, InvoiceFormValuesLineItem } from '../types';
import { InvoiceFormValues } from './useInvoiceForm';

export function useGetPayloadFromFormData() {
  const getDueDate = useGetDueDate();
  return (
    data: InvoiceFormValues,
    catalogItems: CatalogItem[],
    isDraft?: boolean,
    discount?: InvoiceDiscount,
    taxRate?: InvoiceTaxRate,
    serviceCharges?: ServiceChargeParams[]
  ): PostInvoicesRequest => ({
    currency: 'USD',
    customerId: data.customerId,
    discountId: discount?.id,
    taxRateId: taxRate?.id,
    dueDate: getDueDate(data.dueDatePreset, data.dueDate),
    invoiceNumber: data.invoiceNumber,
    note: data.note,
    invoiceLineItems: data.lineItems.map((item) => normalizeLineItem(item, catalogItems)),
    invoiceServiceCharges: serviceCharges?.map((sc) => sc.serviceChargeCatalogId),
    customPayInstructions: data.customPayInstructions,
    isAchAllowed: data.isAchAllowed,
    isCardAllowed: data.isCardAllowed,
    isDraft,
    isAutoRemindersEnabled: data.isAutoRemindersEnabled,
  });
}

type UpdatePayloadProps = {
  data: InvoiceFormValues;
  serviceCharges?: ServiceChargeParams[];
  taxRate?: InvoiceTaxRate;
  discount?: InvoiceDiscount;
  isDraft?: boolean;
};

export function useGetUpdatePayload({ onError }: { onError?: ARErrorFunction } = {}) {
  const getDueDate = useGetDueDate();
  const { data: catalogItems = [] } = useCatalogItems({ onError });
  return ({ data, discount, taxRate, serviceCharges = [], isDraft }: UpdatePayloadProps): PutInvoiceRequest => ({
    dueDate: getDueDate(data.dueDatePreset, data.dueDate),
    note: data.note,
    lineItems: data.lineItems.map((item) => normalizeLineItem(item, catalogItems)),
    serviceCharges: serviceCharges.map((sc) => pick(sc, 'value', 'name', 'type', 'serviceChargeCatalogId')),
    discount: discount && {
      discountId: discount.id,
      name: discount.name,
      type: discount.type,
      value: discount.value,
    },
    taxRate: taxRate && {
      taxRateId: taxRate.id,
      name: taxRate.name,
      rate: taxRate.rate,
    },
    customPayInstructions: data.customPayInstructions,
    isAchAllowed: data.isAchAllowed,
    isCardAllowed: data.isCardAllowed,
    isDraft,
    isAutoRemindersEnabled: data.isAutoRemindersEnabled,
    customerId: data.customerId,
  });
}

const normalizeLineItem = (lineItem: InvoiceFormValuesLineItem, catalogItems: CatalogItem[]): InvoiceLineItemPost => {
  const catalogItem = catalogItems.find(({ id }) => id === lineItem.catalogItemId);
  return {
    catalogItemId: lineItem.catalogItemId,
    name: lineItem._current?.name ?? catalogItem?.name ?? '',
    quantity: lineItem.quantity,
    price: lineItem.price,
    currency: lineItem._current?.currency ?? catalogItem?.currency ?? 'USD',
    taxable: lineItem.taxable,
  };
};

const useGetDueDate = () => {
  const { createDate } = useDateUtils();
  return (dueDatePreset: DueDatePreset, dueDate?: Date) => {
    const now = createDate();
    switch (dueDatePreset) {
      case 'today':
        return now;
      case '7-days':
        return addDays(now, 7);
      case '14-days':
        return addDays(now, 14);
      case '30-days':
        return addDays(now, 30);
      case 'custom':
        if (!dueDate) {
          throw new Error('Due date is required for custom due date type');
        }
        return dueDate;
    }
  };
};
