import { Loan, Payment, Vendor } from '@melio/platform-api';

export type LoansTabItem = Override<Payment, { loan: Loan; vendor: Vendor }>;

export type LoansTableMeta = {
  onViewClick: ({ id }: { id: LoansTabItem['id'] }) => void;
  onViewPayment: ({ id }: { id: LoansTabItem['id'] }) => void;
};

export const CELLS_IDS = {
  VENDOR_NAME: 'vendorName',
  STATUS: 'status',
  NEXT_DEBIT: 'nextDebit',
  NEXT_INSTALLMENT: 'nextInstallment',
  REMAINING_AMOUNT: 'remainingAmount',
  TOTAL_AMOUNT: 'totalAmount',
  ACTION_BUTTON: 'actionButton',
} as const;

export type FinancingTabSortableCells = Extract<
  (typeof CELLS_IDS)[keyof typeof CELLS_IDS],
  typeof CELLS_IDS.VENDOR_NAME
>;
