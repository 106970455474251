import { VendorDirectory } from '../../types';
import { AddManagedVendorFormProps } from './types';
import { AddFiservManagedVendorForm } from './vendorForms';

const vendorForms: Record<VendorDirectory, React.FC<AddManagedVendorFormProps>> = {
  Fiserv: AddFiservManagedVendorForm,
};

export const AddManagedVendorForm: React.FC<AddManagedVendorFormProps> = ({ managed, onSubmit, ...rest }) => {
  const VendorForm = vendorForms[managed.managedBy];

  return <VendorForm {...rest} managed={managed} onSubmit={onSubmit} />;
};
