import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';
import { VendorAutoPaySetupSection } from './VendorAutoPaySetupSection';
import { VendorEBillSubscriptionSection } from './VendorEBillSubscriptionSection';

type PaymentScheduledUpsellsProps = {
  vendorId: string;
  isEBillCapable: boolean;
  isAutoPaySetupCapable: boolean;
};

export const PaymentScheduledUpsells = ({
  vendorId,
  isEBillCapable,
  isAutoPaySetupCapable,
}: PaymentScheduledUpsellsProps) =>
  isEBillCapable || isAutoPaySetupCapable ? (
    <NewSinglePaymentStepLayout.Content>
      {isAutoPaySetupCapable ? <VendorAutoPaySetupSection vendorId={vendorId} /> : null}
      {isEBillCapable ? <VendorEBillSubscriptionSection vendorId={vendorId} /> : null}
    </NewSinglePaymentStepLayout.Content>
  ) : null;
