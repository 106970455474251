import { useBoolean } from '@chakra-ui/react';
import { Link, SelectionCard } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import {
  DeliveryMethodByPayor,
  formatAddress,
  FreeChecksData,
  FundingSource,
  PaperCheckDeliveryMethod,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';

import { CheckProtectionModalWidget } from '../../../../CheckProtectionModal';
import { useDeliveryMethodCardHelpers } from '../../../cards/DeliveryMethodCard/utils';
import { usePaperCheckDescription } from './usePaperCheckDescription.hook';

export type PaperCheckMethodCardProps = {
  deliveryMethod: PaperCheckDeliveryMethod;
  fundingSource?: FundingSource;
  freeChecks?: FreeChecksData;
  isSelected: boolean;
  onSelect: ((deliveryMethodId: string) => void) | undefined;
  onEdit?: ((type: Omit<DeliveryMethodByPayor['type'], 'virtual-account'>) => void) | undefined;
  isDisabled?: boolean;
};

export const PaperCheckMethodCard = forwardRef<PaperCheckMethodCardProps>(
  ({ deliveryMethod, fundingSource, isSelected, onSelect, onEdit, freeChecks, ...props }, ref) => {
    const [showCheckProtectionModal, { toggle: toggleShowCheckProtectionModal }] = useBoolean(false);
    const { formatMessage } = useMelioIntl();
    const { getIconKey } = useDeliveryMethodCardHelpers(fundingSource);
    const { printName, address } = deliveryMethod.details;

    const { track } = useAnalytics();

    const handleOnSelect = () => {
      track('DeliveryMethod', 'Chose', { DeliveryMethodChosen: deliveryMethod.type });
      onSelect?.(deliveryMethod.id);
    };

    const handleOnEdit = () => {
      track('EditDeliveryMethod', 'Chose', { DeliveryMethodChosen: deliveryMethod.type });
      onEdit?.(deliveryMethod.type);
    };

    const handleOpenModalClick = (e: React.MouseEvent<HTMLLinkElement>) => {
      e.preventDefault();
      e.stopPropagation();
      toggleShowCheckProtectionModal();
    };

    const link = (
      <Link
        label={formatMessage('widgets.deliveryMethods.selectDeliveryMethodCard.paper-check.description.link')}
        href="#"
        onClick={handleOpenModalClick}
        data-testid="delivery-method-card-paper-check-protection-link"
        size="medium"
      />
    );

    const { description } = usePaperCheckDescription(fundingSource, link, freeChecks);

    const actionProps = onEdit
      ? { action: { label: formatMessage('widgets.deliveryMethodCard.edit'), onClick: handleOnEdit } }
      : {};

    return (
      <>
        <SelectionCard
          data-component="PaperCheckMethodCard"
          data-testid={`delivery-method-card-${deliveryMethod.type}`}
          {...props}
          ref={ref}
          icon={getIconKey(deliveryMethod, isSelected)}
          mainLabelProps={{
            label: formatMessage('widgets.deliveryMethods.selectDeliveryMethodCard.paper-check.label', {
              name: printName,
              address: formatAddress(address),
            }),
          }}
          descriptionProps={{ label: description }}
          {...actionProps}
          isSelected={isSelected}
          onClick={handleOnSelect}
        />
        <CheckProtectionModalWidget isOpen={showCheckProtectionModal} onClose={toggleShowCheckProtectionModal} />
      </>
    );
  }
);
