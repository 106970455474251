/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { AccountingPlatformSyncConnectionErrorEnum } from '@melio/ap-activities';
import { Text } from '@melio/penny';
import { AccountingPlatformConnectionStatus, useAccountingPlatform } from '@melio/platform-api';

import { FullScreenContainer } from '@/cl/components/FullScreenContainer/FullScreenContainer.component';
import { Loader } from '@/cl/components/Loader/Loader.component';
import { useAccountingPlatformSync } from '@/hooks/accountingPlatforms.hooks';
import { AccountingPlatformSyncStateType, useRouter } from '@/hooks/router.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { AccountingPlatformErrorMessage } from '@/widgets/accounting-platforms/AccountingPlatformErrorMessage/AccountingPlatformErrorMessage.widget';

export const AccountingPlatformSuccessContainer = () => {
  const { state = {} } = useLocation();
  const { formatMessage } = usePlatformIntl();
  const [hasSyncError, setHasSyncError] = useState(false);
  const { goToAccountingPlatformError, goToPayDashboard } = useRouter();
  const { accountingPlatformId, flowToReturn } = (state as AccountingPlatformSyncStateType) || {};
  const onSyncDone = useCallback(() => {
    goToPayDashboard();
  }, []);
  const { triggerSync } = useAccountingPlatformSync({
    accountingPlatformId,
    onSyncDone,
    onSyncError: () => {
      setHasSyncError(true);
    },
  });
  const { data } = useAccountingPlatform({ id: accountingPlatformId });

  useEffect(() => {
    if (data) {
      if (data.connectionStatus === AccountingPlatformConnectionStatus.InvalidSubscription) {
        goToAccountingPlatformError({
          syncError: AccountingPlatformSyncConnectionErrorEnum.InvalidSubscription,
          flowToReturn: flowToReturn,
        });
      } else {
        triggerSync();
      }
    }
  }, [data, triggerSync]);

  if (hasSyncError) {
    const messageIdPrefix = 'widgets.accountingPlatform.errorMessage';
    return (
      <FullScreenContainer>
        <AccountingPlatformErrorMessage
          flowToReturn={flowToReturn}
          accountingPlatformId={accountingPlatformId}
          withTryAgain={true}
          errorTitle={formatMessage(`${messageIdPrefix}.type.sync.title`)}
          errorMessage={formatMessage(`${messageIdPrefix}.type.sync.description`)}
          onTryAgain={() => {
            setHasSyncError(false);
            triggerSync();
          }}
        />
      </FullScreenContainer>
    );
  }

  return (
    <FullScreenContainer>
      <Box>
        <Loader />
      </Box>
      <Box>
        <Box marginTop={10}>
          <Text textStyle="body3Semi">{formatMessage('widgets.accountingPlatform.sync.title')}</Text>
        </Box>
        <Text textStyle="body3">{formatMessage('widgets.accountingPlatform.sync.description')}</Text>
      </Box>
    </FullScreenContainer>
  );
};
