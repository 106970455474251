import React from 'react';
import { BankAccount, DeliveryMethod, DeliveryMethodType, FeeType, Vendor } from '@melio/platform-api';

import { useRouter } from '@/hooks/router.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { BasicDeliveryMethodCardProps } from '@/types/deliveryMethod.types';
import { DeliveryMethodIconsEnum } from '@/types/vendors.types';
import { Fee } from '@/widgets/Fee/Fee.widget';
import { DeliveryMethodCard } from '@/widgets/vendorsDashboard/DeliveryMethod/components/DeliveryMethodCard.component';

export interface DomesticWireMethodProps extends BasicDeliveryMethodCardProps {
  vendor: Vendor;
  domesticWire: DeliveryMethod | null;
}

export const DomesticWireDeliveryMethodCard = ({
  vendor,
  domesticWire,
  navigationStateParams,
}: DomesticWireMethodProps) => {
  const { formatMessage, formatDate } = usePlatformIntl();
  const { generateVendorDomesticAccountLink } = useRouter();

  const info1 = domesticWire
    ? formatMessage('widgets.addOrUpdateDeliveryMethod.domesticWire.info.info1', {
        companyName: vendor.name,
        bankAccount: (domesticWire.details as BankAccount).accountNumber.slice(-4),
      })
    : undefined;
  const info2 = domesticWire
    ? formatMessage('widgets.addOrUpdateDeliveryMethod.domesticWire.info.info2', {
        updatedDate: formatDate(domesticWire.history.updatedAt),
      })
    : undefined;

  return (
    <DeliveryMethodCard
      deliveryMethodExists={domesticWire !== null}
      navigationParams={generateVendorDomesticAccountLink(navigationStateParams)}
      iconType={DeliveryMethodIconsEnum.BANK}
      displayName={formatMessage('widgets.addOrUpdateDeliveryMethod.domesticWire.title')}
      helperText={formatMessage('widgets.addOrUpdateDeliveryMethod.domesticWire.feeText', {
        domesticWireFee: <Fee feeType={FeeType.DomesticWire} />,
      })}
      info={info1 && info2 ? { info1, info2 } : undefined}
      caption={formatMessage('widgets.addOrUpdateDeliveryMethod.domesticWire.comment', {
        domesticWireFee: <Fee feeType={FeeType.DomesticWire} />,
      })}
      type={DeliveryMethodType.DomesticWireAccount}
    />
  );
};

DomesticWireDeliveryMethodCard.displayName = 'DomesticWireDeliveryMethodCard';
