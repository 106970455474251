import Highlighter from 'react-highlight-words';

type HighlightedTextProps = {
  text: string;
  highlightedText?: string;
};

export const HighlightedText = ({ highlightedText = '', text }: HighlightedTextProps) => (
  <Highlighter searchWords={[highlightedText]} autoEscape textToHighlight={text} />
);
