import { useMelioIntl } from '@melio/ar-domain';
import { Button, Container, Group, SearchBar } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export type TaxRatesTableToolbarProps = {
  searchTerm?: string;
  setSearchTerm: (value: string) => unknown;
  isLoading?: boolean;
  onAdd: VoidFunction;
};

export const TaxRatesTableToolbar = forwardRef<TaxRatesTableToolbarProps>(
  ({ setSearchTerm, searchTerm, onAdd, isLoading, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    return (
      // we need a container with padding for the inputs to not break on focus state that grows the inputs
      <Container paddingX="xxs" paddingY="xxs" data-component={TaxRatesTableToolbar.displayName}>
        <Group variant="horizontal" justifyContent="space-between" {...props} ref={ref}>
          <SearchBar
            label={formatMessage(
              'ar.settings.activities.taxRatesTable.components.taxRatesTableToolbar.searchBar.label'
            )}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            data-testid="search-bar"
          />
          <Button
            variant="tertiary"
            label={formatMessage(
              'ar.settings.activities.taxRatesTable.components.taxRatesTableToolbar.addButton.label'
            )}
            onClick={() => onAdd()}
            data-testid="add-tax-button"
          />
        </Group>
      </Container>
    );
  }
);
TaxRatesTableToolbar.displayName = 'TaxRatesTableToolbar';
