/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';
import { ReconciliationForm, ReconciliationFormFields, useAccountingPlatformName } from '@melio/ap-widgets';
import { Group, Modal, Text } from '@melio/penny';
import { useFormSubmissionController } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useAccountingPlatforms, useInboxItems } from '@melio/platform-api';
import { useSystemMessage } from '@melio/platform-utils';

import { useRouter } from '@/hooks/router.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { PayDashboardTabs } from '@/types/payDashboard.types';

type MarkAsPaidBillDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onMarkAsPaidSubmit: (accountingPlatformPaymentAccountId?: string) => Promise<void>;
  isLoading: boolean;
  hasAccountingPlatform?: boolean;
  amount: number;
};

export const MarkAsPaidDialog = withAnalyticsContext<MarkAsPaidBillDialogProps>(
  ({ isOpen, onClose, onMarkAsPaidSubmit, isLoading, hasAccountingPlatform, amount }) => {
    const {
      activeAccountingPlatform,
      isLoading: isLoadingAccountingPlatforms,
      // error: loadingAccountingPlatformsError,
    } = useAccountingPlatforms();
    const accountingPlatformName = useAccountingPlatformName(activeAccountingPlatform?.accountingSlug);
    const { formatMessage } = usePlatformIntl();
    const { replaceToPayDashboardTab } = useRouter();
    const { refetch: refetchInboxItems } = useInboxItems({ enabled: false });
    const { showMessage } = useSystemMessage();
    const { track } = useAnalytics();
    const { submitButtonProps, onSubmissionStateChange } = useFormSubmissionController<ReconciliationFormFields>();

    useEffect(() => {
      if (isOpen) {
        track('MarkBillAsPaid', 'Viewed', {
          isQB: hasAccountingPlatform,
          AccountingSoftwareBill: accountingPlatformName,
          totalAmount: amount,
        });
      }
    }, [isOpen]);

    const onDialogClose = useCallback(() => {
      track('MarkBillAsPaid', 'chose', {
        isQB: hasAccountingPlatform,
        AccountingSoftwareBill: accountingPlatformName,
        action: 'close',
        totalAmount: amount,
      });
      onClose();
    }, [onClose]);

    const onDialogConfirm = async (accountingPlatformPaymentAccountId?: string) => {
      try {
        track('PayDashboardMarkAsBil', 'Submitted');
        await onMarkAsPaidSubmit(accountingPlatformPaymentAccountId);
        refetchInboxItems();
        replaceToPayDashboardTab(PayDashboardTabs.Paid);
        showMessage({
          type: 'success',
          title: formatMessage('widgets.billDetails.markAsPaidBillDialog.toast.success'),
        });
        track('MarkBillAsPaid', 'Chose', {
          isQB: hasAccountingPlatform,
          AccountingSoftwareBill: accountingPlatformName,
          action: 'markAsPaid',
          totalAmount: amount,
        });
      } catch (error) {
        showMessage({
          type: 'error',
          title: formatMessage('widgets.billDetails.markAsPaidBillDialog.toast.error'),
        });
      } finally {
        onClose();
      }
    };

    const modalDescription = hasAccountingPlatform
      ? formatMessage('widgets.billDetails.markAsPaidBillDialog.syncDescription', {
          accountingPlatformName,
        })
      : formatMessage('widgets.billDetails.markAsPaidBillDialog.description');

    const modalPrimaryButton = hasAccountingPlatform
      ? {
          ...submitButtonProps,
          variant: 'primary' as const,
          label: formatMessage('widgets.billDetails.markAsPaidBillDialog.confirm'),
          isLoading: isLoading,
        }
      : {
          onClick: () => onDialogConfirm(),
          variant: 'primary' as const,
          label: formatMessage('widgets.billDetails.markAsPaidBillDialog.confirm'),
          isLoading: isLoading,
        };

    const modalChildren = hasAccountingPlatform && (
      <ReconciliationForm
        disabledNewOption={true}
        newPaymentAccountName={''}
        onSubmit={(data) => {
          void onDialogConfirm(data.accountingPlatformPaymentAccountId);
        }}
        isSaving={isLoading}
        onSubmissionStateChange={onSubmissionStateChange}
        onCreateOption={() => undefined}
      />
    );

    return (
      <Modal
        isOpen={isOpen}
        onClose={onDialogClose}
        data-testid="mark-as-paid-modal-screen"
        header={formatMessage('widgets.billDetails.markAsPaidBillDialog.title')}
        primaryButton={modalPrimaryButton}
        isLoading={isLoadingAccountingPlatforms}
      >
        <Group variant="vertical">
          <Text>{modalDescription}</Text>
          {modalChildren}
        </Group>
      </Modal>
    );
  },
);
