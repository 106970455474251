import { Group } from '@melio/penny';

import { Plan, SubscriptionBillingCycleEnum } from '../../../../../api';
import { SubscriptionBillingCycle } from './components/SubscriptionBillingCycle';
import { SubscriptionPaymentMethod } from './components/SubscriptionPaymentMethod';

type SubscriptionCheckoutSelectionProps = {
  selectedPlan: Plan;
  isSubscribing: boolean;
  fundingSourceId: string | null;
  setFundingSourceId: (id: string) => void;
  onDoneAddingCreditCard: () => void;
  cycle: SubscriptionBillingCycleEnum;
  setCycle: (cycle: SubscriptionBillingCycleEnum) => void;
};

export const SubscriptionCheckoutSelection = ({
  selectedPlan,
  isSubscribing,
  fundingSourceId,
  setFundingSourceId,
  onDoneAddingCreditCard,
  cycle,
  setCycle,
}: SubscriptionCheckoutSelectionProps) => {
  const selectedPlanHasAnnualBasePrice =
    selectedPlan.cycles.annual?.basePrice || selectedPlan.cycles.annual?.basePrice === 0;

  return (
    <Group variant="vertical" width="full" spacing="l">
      {selectedPlanHasAnnualBasePrice && (
        <SubscriptionBillingCycle
          selectedPlan={selectedPlan}
          isSubscribing={isSubscribing}
          selectedBillingCycle={cycle}
          onBillingCycleChange={setCycle}
        />
      )}
      <SubscriptionPaymentMethod
        fundingSourceId={fundingSourceId}
        setFundingSourceId={setFundingSourceId}
        onDoneAddingCreditCard={onDoneAddingCreditCard}
        selectedPlan={selectedPlan}
        selectedBillingCycle={cycle}
      />
    </Group>
  );
};
