import { useCreateMutation, useMelioQueryClient, useModelApi } from '@melio/api-client';
import { ApiKeys, FileInfo, PaymentsApiClient } from '@melio/platform-api-axios-client';

import { createUploadDocumentsFiles } from '../file/utils';

export const usePaymentRequiredDocuments = (paymentId: string) =>
  useModelApi({
    queryKey: [ApiKeys.PaymentGetRequiredDocumentsApi, paymentId],
    queryFn: () => PaymentsApiClient.getPaymentRequiredDocuments(paymentId),
    id: 'PaymentGetRequiredDocumentsApi',
  });

export const useAttachRequestedDocuments = (paymentId: string) => {
  const queryClient = useMelioQueryClient();
  const onSuccess = () => {
    void queryClient.invalidateQueries(ApiKeys.PaymentGetRequiredDocumentsApi);
  };
  return useCreateMutation(
    attachRequiredDocuments,
    [ApiKeys.PaymentsAttachFilesRequestApi, 'model', paymentId, 'default'],
    {
      onSuccess,
    }
  );
};

type FileInfoWithSize = FileInfo & { size: number | undefined };
type Props = {
  requestId: string;
  fileInfos: FileInfoWithSize[];
};

const attachRequiredDocuments = async (props: Props) =>
  PaymentsApiClient.postAttachFilesRequiredDocuments(props.requestId, {
    files: props.fileInfos.map((fileInfo) => ({
      fileId: fileInfo.id,
      fileName: fileInfo.fileName || '',
      byteSize: fileInfo.size || 0,
    })),
  });

export const uploadFiles = async (files: File[]): Promise<FileInfoWithSize[]> => {
  const fileInfos = await createUploadDocumentsFiles(files);
  return fileInfos.map((fileInfo) => ({
    ...fileInfo,
    size: files.find((file) => file.name === fileInfo.fileName)?.size,
  }));
};
