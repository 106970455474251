import { FieldValues, StatusModal, Text, UseMelioFormResults } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

type Props<T extends FieldValues> = {
  isOpen: boolean;
  onClose: VoidFunction;
  submitButtonProps?: UseMelioFormResults<T>['submitButtonProps'];
  cancelButtonProps?: UseMelioFormResults<T>['cancelButtonProps'] & { onClick: VoidFunction };
};
export const UnsavedChangesModalActivity = <T extends FieldValues>({
  isOpen,
  onClose,
  submitButtonProps,
  cancelButtonProps,
}: Props<T>) => {
  const { formatMessage } = useMelioIntl();

  return (
    <StatusModal
      data-testid="unsaved-changes-modal"
      variant="warning"
      isOpen={isOpen}
      onClose={onClose}
      header={formatMessage('activities.unsavedChangesModal.header')}
      primaryButton={{
        ...submitButtonProps,
        variant: 'primary',
        label: formatMessage('activities.unsavedChangesModal.footer.cta'),
      }}
      secondaryButton={{
        ...cancelButtonProps,
        variant: 'tertiary',
        label: formatMessage('activities.unsavedChangesModal.footer.tertiary'),
      }}
    >
      <Text>{formatMessage('activities.unsavedChangesModal.description')}</Text>
    </StatusModal>
  );
};
