/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { object, SchemaOf, string } from 'yup';
import { AddressSearchWidget } from '@melio/ap-widgets';
import { Form, useMelioForm } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { useVendors } from '@/hooks/vendors.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { DataComponentEnum, VendorDetailsFormFields } from '@/types/vendors.types';
import { optionalEmailIsValid } from '@/utils/email.utils';
import { optionalPhoneIsValid } from '@/utils/phone.utils';
import { VendorDetailsFormInnerProps } from '../types';
import { preventFormSubmissionOnEnter } from '../utils';
import { getUpdateVendorPayload } from './utils';

type RppsVendorDetailsFields = Omit<VendorDetailsFormFields, 'nickname'>;

export const RppsVendorDetailsForm = forwardRef<VendorDetailsFormInnerProps, 'form'>(
  ({ vendorId, defaultValues, isSaving, onSubmit, onSubmissionStateChange, isEditable, ...props }, ref) => {
    const { formatMessage } = usePlatformIntl();

    const useSchema = () => {
      const { formatMessage } = usePlatformIntl();
      const { vendors } = useVendors();

      return object().shape({
        companyName: string()
          .trim()
          .required(formatMessage('widgets.vendorDetails.form.companyName.validation.required'))
          .test(
            'is-company-name-not-exists',
            formatMessage('widgets.vendorDetails.form.companyName.validation.alreadyExists'),
            (companyName) => {
              return (
                vendors?.filter((vendor) => vendor?.name === companyName).length === 0 ||
                companyName === defaultValues?.companyName
              );
            },
          ),
        fullName: string().trim().optional().nullable(),
        email: string()
          .trim()
          .optional()
          .nullable()
          .test(
            'validAddress',
            formatMessage('widgets.vendorDetails.form.email.validation.format'),
            optionalEmailIsValid,
          ),
        phone: string()
          .trim()
          .optional()
          .nullable()
          .test(
            'validPhone',
            formatMessage('widgets.vendorDetails.form.phone.validation.format'),
            optionalPhoneIsValid,
          ),
        accountNumber: string().nullable().required(),
        address: object({
          line1: string(),
          line2: string(),
          city: string(),
          state: string(),
          postalCode: string(),
          countryCode: string(),
        })
          .nullable()
          .default(null)
          .required(),
      }) as SchemaOf<RppsVendorDetailsFields>;
    };

    const { formProps, registerField, reset, formState } = useMelioForm<RppsVendorDetailsFields>({
      onSubmit: (data) => onSubmit(getUpdateVendorPayload(data)),
      schema: useSchema(),
      isSaving,
      defaultValues,
      onSubmissionStateChange,
    });

    useEffect(() => {
      if (!formState.isSubmitting) {
        reset(defaultValues);
      }
    }, [defaultValues]);

    return (
      <Form
        data-component={DataComponentEnum.VENDOR_DETAILS_FORM}
        {...props}
        {...formProps}
        ref={ref}
        isViewMode={!isEditable}
        {...preventFormSubmissionOnEnter()}
      >
        <Form.TextField
          {...registerField('companyName')}
          labelProps={{ label: formatMessage('widgets.vendorDetails.form.companyName.label') }}
          placeholder={formatMessage('widgets.vendorDetails.form.companyName.placeholder')}
          autoFocus={isEditable}
          isTruncated={!isEditable}
        />
        <Form.TextField
          {...registerField('accountNumber')}
          labelProps={{ label: formatMessage('widgets.vendorDetails.form.accountNumber.label') }}
          isTruncated={!isEditable}
          isDisabled={isEditable}
        />
        <AddressSearchWidget
          labelProps={{ label: formatMessage(`widgets.vendorDetails.form.address.label`) }}
          {...registerField('address')}
          isDisabled={isEditable}
        />
        <Form.TextField
          {...registerField('fullName')}
          labelProps={{ label: formatMessage('widgets.vendorDetails.form.name.label') }}
          viewModePlaceholder={formatMessage('widgets.vendorDetails.form.name.viewModePlaceholder')}
          placeholder={formatMessage('widgets.vendorDetails.form.name.placeholder')}
          isTruncated={!isEditable}
        />
        <Form.TextField
          {...registerField('email')}
          labelProps={{ label: formatMessage('widgets.vendorDetails.form.email.label') }}
          viewModePlaceholder={formatMessage('widgets.vendorDetails.form.email.viewModePlaceholder')}
          placeholder={formatMessage('widgets.vendorDetails.form.email.placeholder')}
          isTruncated={!isEditable}
        />
        <Form.PhoneField
          {...registerField('phone')}
          labelProps={{ label: formatMessage('widgets.vendorDetails.form.phone.label') }}
          viewModePlaceholder={formatMessage('widgets.vendorDetails.form.phone.viewModePlaceholder')}
          placeholder={formatMessage('widgets.vendorDetails.form.phone.placeholder')}
        />
      </Form>
    );
  },
);
