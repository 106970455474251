import {
  Bill,
  BillSubscription,
  BillSubscriptionOptions,
  DeliveryMethod,
  FundingSource,
  Payment,
  Vendor,
} from '@melio/platform-api';
import { usePermissions } from '@melio/platform-permissions';
import { useConfig, useMelioIntl } from '@melio/platform-provider';

export const useFrequencySelectorSettings = ({
  bill,
  payment,
  billSubscription,
  selectedVendor,
  selectedDeliveryMethod,
  selectedFundingSource,
  billSubscriptionOptions,
}: {
  bill: Bill | undefined;
  payment: Payment | undefined;
  billSubscription?: BillSubscription;
  selectedVendor: Vendor | undefined;
  selectedDeliveryMethod: DeliveryMethod | undefined;
  selectedFundingSource: FundingSource | undefined;
  billSubscriptionOptions: BillSubscriptionOptions | undefined;
}): {
  isVisible: boolean;
  isDisabled: boolean;
  recurringDisabledText: string | undefined;
} => {
  const { can } = usePermissions();
  const { formatMessage } = useMelioIntl();
  const {
    settings: {
      payment: {
        scheduling: { recurringDeliveryMethodBlackList },
      },
    },
  } = useConfig();

  const isVisible = !bill && !payment && !billSubscription && can({ action: 'create', subject: 'billSubscription' });
  const isDisabled = !selectedVendor || !selectedDeliveryMethod;

  const isDeliveryMethodNotAllowedForRecurring =
    selectedDeliveryMethod && recurringDeliveryMethodBlackList.includes(selectedDeliveryMethod.type);

  const isFundingSourceNotAllowedForRecurring =
    billSubscriptionOptions?.eligibleFundingSources &&
    !billSubscriptionOptions.eligibleFundingSources.find(
      (fundingSource) => fundingSource.id === selectedFundingSource?.id
    );

  const getRecurringDisabledText = () => {
    if (isFundingSourceNotAllowedForRecurring && isDeliveryMethodNotAllowedForRecurring) {
      return formatMessage(
        'activities.paymentFlow.form.content.recurring.recurrenceType.options.recurring.disabled.fundingSourceAndDEliveryMethodNotAllowedForRecurring'
      );
    }
    return isFundingSourceNotAllowedForRecurring
      ? formatMessage(
          'activities.paymentFlow.form.content.recurring.recurrenceType.options.recurring.disabled.fundingSourceNotAllowedForRecurring'
        )
      : isDeliveryMethodNotAllowedForRecurring
      ? formatMessage(
          'activities.paymentFlow.form.content.recurring.recurrenceType.options.recurring.disabled.deliveryMethodNotAllowedForRecurring'
        )
      : undefined;
  };

  return {
    isVisible,
    isDisabled,
    recurringDisabledText: getRecurringDisabledText(),
  };
};
