import { hasFastDeliveryPreferenceOption } from '@melio/ap-widgets';
import { Table, TableCellProps } from '@melio/penny';
import { DeliveryPreference, PaymentIntent } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';

import { DeliverySpeed } from './components/DeliverySpeed';
import { FastSwitch } from './components/FastSwitch';

export type DeliverySpeedCellProps = TableCellProps & {
  fundingSourceId: PaymentIntent['fundingSourceId'];
  deliveryPreferencesOptions: PaymentIntent['deliveryPreferenceOptions'];
  selectedDeliveryPreferenceType: PaymentIntent['selectedDeliveryPreferenceType'];
  onSelectDeliveryPreferenceType?: (type: DeliveryPreference['type']) => void;
};

export const DeliverySpeedCell = forwardRef<DeliverySpeedCellProps, 'div'>(
  (
    {
      fundingSourceId,
      deliveryPreferencesOptions,
      selectedDeliveryPreferenceType,
      onSelectDeliveryPreferenceType,
      ...props
    },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();

    const selectedDeliveryPreference = deliveryPreferencesOptions?.find(
      (deliveryPreference) => deliveryPreference.type === selectedDeliveryPreferenceType
    );
    const hasFastOption = hasFastDeliveryPreferenceOption(deliveryPreferencesOptions);
    const showSwitch = hasFastOption;
    const showDeliverySpeed = !hasFastOption && !!selectedDeliveryPreference && !!fundingSourceId;

    return (
      <Table.Cell
        data-testid="delivery-speed-cell"
        {...props}
        placeholder={formatMessage(
          'activities.batchPayments.screens.paymentIntentsTable.deliverySpeedCell.notAvailable.placeholder'
        )}
        data-component="DeliverySpeedCell"
        ref={ref}
      >
        {showSwitch && (
          <FastSwitch
            deliveryPreferenceOptions={deliveryPreferencesOptions}
            selectedDeliveryPreferenceType={selectedDeliveryPreferenceType}
            onSelectDeliveryPreferenceType={onSelectDeliveryPreferenceType}
          />
        )}
        {showDeliverySpeed && <DeliverySpeed selectedDeliveryPreference={selectedDeliveryPreference} />}
      </Table.Cell>
    );
  }
);

DeliverySpeedCell.displayName = 'DeliverySpeedCell';
