import { Form } from '@melio/penny';
import { WorkflowNumericConditionConfigurationOperatorEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

import { useFormContext } from '../../FormContext';

export const AmountCondition: React.FC<{ index: number }> = ({ index }) => {
  const { formatMessage } = useMelioIntl();
  const { registerField } = useFormContext();
  const amountField = registerField(`criteria.conditions.${index}.condition.configuration.value`);
  const operatorField = registerField(`criteria.conditions.${index}.condition.configuration.operator`);

  const amountConditionData = [
    {
      label: formatMessage('activities.create-approval-workflows.conditions.amount.option.greaterEqual'),
      value: WorkflowNumericConditionConfigurationOperatorEnum.GreaterEqual,
      testId: 'greater-than-amount-condition-type',
    },
    {
      label: formatMessage('activities.create-approval-workflows.conditions.amount.option.belowEqual'),
      value: WorkflowNumericConditionConfigurationOperatorEnum.BelowEqual,
      testId: 'below-than-amount-condition-type',
    },
  ];

  return (
    <>
      <Form.ContentBox colSpan={4} data-testid="amount-condition">
        <Form.Select
          {...operatorField}
          emptyState={{ label: '' }}
          formatSelectedValue={(option) => option.label.toLowerCase()}
          data-testid="amount-condition-type-list"
          size="large"
          aria-label="scheduler"
          options={amountConditionData}
        />
      </Form.ContentBox>
      <Form.ContentBox colSpan={2}>
        <Form.AmountField {...amountField} aria-label="payment amount" data-testid="payment-amount" />
      </Form.ContentBox>
      <Form.ContentBox colSpan={1} />
    </>
  );
};
