import Big from 'big.js';
import { identity } from 'lodash';

export function sumBy<T>(array: T[], iterator: (item: T) => number) {
  return array.reduce((acc, item) => new Big(iterator(item)).plus(acc), new Big(0)).toNumber();
}

export function sum(array: number[]) {
  return sumBy(array, identity);
}
