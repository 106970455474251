import { SectionBanner } from '@melio/penny';
import { FundingSource } from '@melio/platform-api';
import { FormattedMessage } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { identity } from 'lodash';

import { useDeliveryMethodFee } from '../../../utils';

export type UnilateralBannerProps = {
  fundingSource?: FundingSource;
};

export const UnilateralBanner = forwardRef<UnilateralBannerProps>(({ fundingSource, ...props }, ref) => {
  const descriptionText = useDescriptionText(fundingSource?.type);
  if (!descriptionText) {
    return null;
  }
  return (
    <SectionBanner data-component="UnilateralBanner" {...props} ref={ref} description={descriptionText as never} />
  );
});

UnilateralBanner.displayName = 'UnilateralBanner';

export const useHasCatalogFees = (fundingSourceType?: FundingSource['type']) => {
  const { getFee } = useDeliveryMethodFee(fundingSourceType);
  return [getFee('bank-account'), getFee('paper-check')].some(identity);
};

export const useDescriptionText = (fundingSourceType?: FundingSource['type']) => {
  const { getFee } = useDeliveryMethodFee(fundingSourceType);

  const achFee = getFee('bank-account');
  const checkFee = getFee('paper-check');

  if (achFee && checkFee) {
    return (
      <FormattedMessage
        id="widgets.reviewAndConfirm.feesSection.unilateralBanner.descriptionWithFees"
        values={{ achFee, checkFee }}
      />
    );
  } else if (achFee) {
    return (
      <FormattedMessage
        id="widgets.reviewAndConfirm.feesSection.unilateralBanner.descriptionWithAchFee"
        values={{ achFee }}
      />
    );
  } else if (checkFee) {
    return (
      <FormattedMessage
        id="widgets.reviewAndConfirm.feesSection.unilateralBanner.descriptionWithCheckFee"
        values={{ checkFee }}
      />
    );
  } else {
    return null;
  }
};
