import { Drawer, useFormSubmissionController } from '@melio/penny';
import { PermissionLevelEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { last, noop } from 'lodash';
import { useRef, useState } from 'react';

import { CollaboratorDrawerBody } from '../components/body/CollaboratorDrawerBody';
import { CollaboratorDrawerFooter } from '../components/footer/CollaboratorDrawerFooter';
import { CollaboratorDrawerHeader } from '../components/header/CollaboratorDrawerHeader';
import { FormFields } from '../types';
import { useAllowedActions } from '../useAllowedActions';
import { useSchema } from './useSchema';

export type CreateCollaboratorDrawerActivityProps = { onClose: VoidFunction };
export const CreateCollaboratorDrawerActivity = ({ onClose }: CreateCollaboratorDrawerActivityProps) => {
  const { formatMessage } = useMelioIntl();
  const { rolesPermittedToInvite } = useAllowedActions();
  const { onSubmissionStateChange, submitButtonProps, cancelButtonProps } = useFormSubmissionController<FormFields>();
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const schema = useSchema();
  const shouldReturnFocus = useRef(!!document.activeElement && document.activeElement !== document.body);

  return (
    <Drawer
      data-testid="create-collaborator-drawer"
      isOpen={isDrawerOpen}
      shouldReturnFocus={shouldReturnFocus.current}
      onClose={() => setIsDrawerOpen(false)}
      onCloseComplete={onClose}
      isLoading={false}
      closeButtonAriaLabel={formatMessage('activities.collaboratorDrawer.closeButtonAriaLabel')}
      header={<CollaboratorDrawerHeader />}
      body={
        <CollaboratorDrawerBody
          isLoading={false}
          isSaving={false}
          onSubmissionStateChange={onSubmissionStateChange}
          onSubmit={noop}
          schema={schema}
          formFieldsSettings={{
            firstName: { readonly: false, required: true, hidden: false },
            lastName: { readonly: false, required: true, hidden: false },
            email: { readonly: false, required: true, hidden: false },
            permissionLevel: { readonly: false, required: false, hidden: true },
            roleUniqueName: { readonly: false, required: true, hidden: false },
            paymentApprovalLimit: { readonly: false, required: false, hidden: true },
            entitlementsFundingSourceIds: { readonly: false, required: false, hidden: false },
            entitlementsFundingSourceType: { readonly: false, required: false, hidden: false },
          }}
          defaultValues={{
            firstName: '',
            lastName: '',
            email: '',
            permissionLevel: PermissionLevelEnum.Full,
            roleUniqueName: last(rolesPermittedToInvite),
          }}
        />
      }
      footer={
        <CollaboratorDrawerFooter
          submitLabel={formatMessage('activities.collaboratorDrawer.create.footer.cta')}
          submitButtonProps={submitButtonProps}
          cancelLabel={formatMessage('activities.collaboratorDrawer.create.footer.tertiary')}
          cancelButtonProps={{ ...cancelButtonProps, onClick: () => setIsDrawerOpen(false) }}
        />
      }
    />
  );
};
