import { useIsMobile, useMelioIntl } from '@melio/ar-domain';
import { Container, Divider, Group, IconButton, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

type ShareInvoiceHeaderProps = {
  headerText: string;
  onBack: VoidFunction;
};

export const ShareInvoiceHeader = forwardRef<ShareInvoiceHeaderProps>(({ headerText, onBack }, ref) => {
  const isMobile = useIsMobile();

  return isMobile ? (
    <ShareInvoiceMobileHeader headerText={headerText} onBack={onBack} ref={ref} />
  ) : (
    <Container paddingX="m" paddingY="m" backgroundColor="light" data-testid="share-invoice-header" ref={ref} />
  );
});
ShareInvoiceHeader.displayName = 'ShareInvoiceHeader';

const ShareInvoiceMobileHeader = forwardRef<ShareInvoiceHeaderProps>(({ headerText, onBack }, ref) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Group
      variant="vertical"
      spacing="s"
      data-testid="share-invoice-header"
      data-component={ShareInvoiceMobileHeader.displayName}
      ref={ref}
    >
      <Container paddingX="m" paddingY="m" backgroundColor="light" />
      <Container paddingLeft="m">
        <Group variant="horizontal" alignItems="center" spacing="s">
          <IconButton
            icon="arrow-left"
            aria-label={formatMessage('ar.invoiceLifecycle.activities.issueInvoice.form.header.buttons.back.ariaLabel')}
            variant="naked"
            size="medium"
            onClick={onBack}
            data-testid="share-invoice-header-mobile-back-button"
          />
          <Text textStyle="heading1Semi">{headerText}</Text>
        </Group>
      </Container>
      <Divider />
    </Group>
  );
});
ShareInvoiceMobileHeader.displayName = 'ShareInvoiceMobileHeader';
