import { Group, Text, useFormSubmissionController } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';

import { DeliveryMethodFlowOrigin } from '../../../../../../delivery-methods/types';
import { NewSinglePaymentStepLayout } from '../../../../../../NewSinglePaymentStepLayout';
import { SubmissionTargetType } from '../../../../types';
import {
  InternationalVendorDetailsFormFields,
  InternationalVendorDetailsFormReturnedValue,
  InternationalVendorDetailsFormWidget,
  VendorDetailsFormDefaultValues,
} from './widgets/InternationalVendorDetailsForm';

type InternationalVendorDetailsScreenScreenProps = {
  isSaving?: boolean;
  isLoading?: boolean;
  onClose: VoidFunction;
  onBack: VoidFunction;
  onDone: (data: InternationalVendorDetailsFormReturnedValue, target?: SubmissionTargetType) => void;
  organizationName: string;
  origin: DeliveryMethodFlowOrigin;
  isMissingLegalDetails: boolean;
  isMissingOrganizationBeneficialOwnersDetails: boolean;
  defaultValues?: VendorDetailsFormDefaultValues;
};

export const InternationalVendorDetailsScreen = forwardRef<InternationalVendorDetailsScreenScreenProps, 'div'>(
  (
    {
      isSaving,
      onClose,
      onBack,
      onDone,
      isLoading,
      organizationName,
      isMissingLegalDetails,
      isMissingOrganizationBeneficialOwnersDetails,
      origin,
      defaultValues,
      ...props
    },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();
    const { track } = useAnalytics();

    const { submitButtonProps, cancelButtonProps, onSubmissionStateChange, handleSubmit } =
      useFormSubmissionController<InternationalVendorDetailsFormFields>();

    const trackClick = (cta: string) =>
      track(`DeliveryMethod`, 'Click', {
        Cta: cta,
      });

    return (
      <NewSinglePaymentStepLayout
        data-component="InternationalVendorDetailsActivity.InternationalVendorDetailsScreen"
        data-testid="international-vendor-details-activity-international-vendor-details-screen"
        isLoading={isLoading}
        {...props}
        ref={ref}
        headerContent={
          <NewSinglePaymentStepLayout.Header>
            <NewSinglePaymentStepLayout.CloseButton
              onClick={() => {
                trackClick('exit');
                onClose();
              }}
            />
            <NewSinglePaymentStepLayout.BackButton {...cancelButtonProps} onClick={onBack} />
          </NewSinglePaymentStepLayout.Header>
        }
        footerContent={
          <NewSinglePaymentStepLayout.Actions>
            {origin === 'vendor' && !isMissingLegalDetails && !isMissingOrganizationBeneficialOwnersDetails ? (
              <Group variant="vertical" spacing="m" width="full">
                <NewSinglePaymentStepLayout.NextButton
                  {...submitButtonProps}
                  label={formatMessage(
                    `activities.internationalVendorDetails.screens.internationalVendorDetails.actions.vendorOrigin.continue`
                  )}
                  onClick={() => {
                    trackClick('save-details-and-add-a-bill');
                    handleSubmit?.('continue')();
                  }}
                />
                <NewSinglePaymentStepLayout.SecondaryNextButton
                  {...submitButtonProps}
                  onClick={() => {
                    trackClick('save-and-close');
                    handleSubmit?.('close')();
                  }}
                  variant="tertiary"
                  label={formatMessage(
                    'activities.internationalVendorDetails.screens.internationalVendorDetails.actions.vendorOrigin.close'
                  )}
                />
              </Group>
            ) : (
              <NewSinglePaymentStepLayout.NextButton
                {...submitButtonProps}
                label={formatMessage(
                  `activities.internationalVendorDetails.screens.internationalVendorDetails.actions.paymentOrigin.continue`
                )}
                onClick={() => {
                  trackClick('continue');
                  handleSubmit?.('continue')();
                }}
              />
            )}
          </NewSinglePaymentStepLayout.Actions>
        }
        bottomContent={
          <NewSinglePaymentStepLayout.BottomTextContent>
            <Text as="p" textStyle="caption1">
              {formatMessage(`activities.internationalVendorDetails.screens.internationalVendorDetails.description`)}
            </Text>
          </NewSinglePaymentStepLayout.BottomTextContent>
        }
      >
        <NewSinglePaymentStepLayout.Title>
          {formatMessage(`activities.internationalVendorDetails.screens.internationalVendorDetails.title`, {
            organization: organizationName,
          })}
        </NewSinglePaymentStepLayout.Title>
        <NewSinglePaymentStepLayout.Content>
          <InternationalVendorDetailsFormWidget
            onSubmit={onDone}
            isSaving={isSaving}
            onSubmissionStateChange={onSubmissionStateChange}
            defaultValues={defaultValues}
          />
        </NewSinglePaymentStepLayout.Content>
      </NewSinglePaymentStepLayout>
    );
  }
);

InternationalVendorDetailsScreen.displayName = 'InternationalVendorDetailsActivity.InternationalVendorDetailsScreen';
