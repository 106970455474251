import { CSSProperties } from 'react';

import { EmbeddedDemoLayout, EmbeddedDemoLayoutComponent } from '@/screens/embedded-experience-demo/layouts/types';
import { USBankLayout } from '@/screens/embedded-experience-demo/layouts/us-bank';
import { CleanBankLayout } from '@/screens/embedded-experience-demo/layouts/us-bank/CleanBank.layout';

export const EmbeddedDemoLayouts: Record<
  EmbeddedDemoLayout,
  { LayoutComponent: EmbeddedDemoLayoutComponent; headerHeight?: CSSProperties['height'] }
> = {
  [EmbeddedDemoLayout.US_BANK]: { LayoutComponent: USBankLayout, headerHeight: '7.375rem' },
  [EmbeddedDemoLayout.CLEAN_BANK]: { LayoutComponent: CleanBankLayout, headerHeight: '0px' },
};
