/* eslint-disable react-hooks/exhaustive-deps */
import { UseMelioFormResults } from '@melio/penny';
import { CountryInternationalDeliveryDetails, useInternationalBankDetails } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { pickBy } from 'lodash';
import { FocusEventHandler, useEffect, useState } from 'react';
import { object, SchemaOf, string } from 'yup';

import { InternationalBankDetailsFormFields } from './types';

type UseIdentifierValueFormProps = {
  countryCode?: string;
  identifierType: 'swiftCode' | 'ibanCode';
  setIsIdentifierValueValid: (isValid: boolean) => void;
  defaultIdentifier?: string;
} & Pick<UseMelioFormResults<InternationalBankDetailsFormFields>, 'setError' | 'setValue' | 'clearErrors'>;

const IBAN_VALUE_RANGE = {
  MIN: 10,
  MAX: 43,
};
const SWIFT_VALUE_RANGE = {
  MIN: 8,
  MAX: 20,
};

const enum IdentifierValueError {
  REQUIRED = 'required',
  INVALID = 'invalid',
  COUNTRY_NOT_MATCH = 'countryNotMatch',
}

type UseBankIdentifierValueProps = Omit<UseIdentifierValueFormProps, 'identifierType'> & {
  identifierType: CountryInternationalDeliveryDetails['identifierType'] | undefined;
};

const IdentifierTypeToFieldName: Record<
  NonNullable<CountryInternationalDeliveryDetails['identifierType']>,
  UseIdentifierValueFormProps['identifierType']
> = {
  iban: 'ibanCode',
  bicSwift: 'swiftCode',
};

const IdentifierTypeToValueRange = {
  iban: IBAN_VALUE_RANGE,
  bicSwift: SWIFT_VALUE_RANGE,
};

export const useBankIdentifierValue = ({
  clearErrors,
  countryCode,
  setError,
  setValue,
  identifierType,
  setIsIdentifierValueValid,
  defaultIdentifier,
}: UseBankIdentifierValueProps) => {
  const { formatMessage } = useMelioIntl();
  const [identifierValue, setIdentifierValue] = useState(defaultIdentifier);

  const { data, isLoading, isFetched, isFetching } = useInternationalBankDetails({
    params: {
      identifierType: identifierType || 'bicSwift',
      identifierValue: identifierValue || '',
    },
    enabled: !!identifierValue && !!identifierType,
  });

  const { MIN: minRange, MAX: maxRange } = IdentifierTypeToValueRange[identifierType || 'bicSwift'];

  const resetIdentifierValidationData = () => {
    setIdentifierValue('');
  };

  const setIdentifierValueError = (identifierValueError: IdentifierValueError) => {
    const identifierFieldName = identifierType ? IdentifierTypeToFieldName[identifierType] : undefined;

    setIsIdentifierValueValid(false);
    identifierFieldName &&
      setError(identifierFieldName, {
        message: formatMessage(
          `activities.internationalBankDetails.screens.internationalBankDetailsForm.${identifierFieldName}.validation.${identifierValueError}`
        ),
      });
  };

  useEffect(() => {
    if (countryCode && isFetched) {
      if (data) {
        if (data.internationalAddress.countryCode === countryCode) {
          setIsIdentifierValueValid(true);
          identifierType && clearErrors(IdentifierTypeToFieldName[identifierType]);
          if (data.bankName) {
            clearErrors('bankName');
            setValue('bankName', data.bankName);
          }
        } else {
          setIdentifierValueError(IdentifierValueError.COUNTRY_NOT_MATCH);
        }
      } else {
        setIdentifierValueError(IdentifierValueError.INVALID);
      }
    }
  }, [data, isFetched, countryCode, identifierType]);

  const onIdentifierValueBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    const identifierValueInput = event.target.value;
    if (identifierValueInput) {
      if (identifierValueInput.length >= minRange && identifierValueInput.length <= maxRange && countryCode) {
        return setIdentifierValue(identifierValueInput);
      } else {
        setIdentifierValueError(IdentifierValueError.INVALID);
      }
    }
  };

  return {
    onIdentifierValueBlur,
    isLoading,
    isFetching,
    resetIdentifierValidationData,
  };
};

export const BankDetailsSchema = ({
  isIdentifierValueValid,
  selectedCountry,
}: {
  isIdentifierValueValid: boolean;
  selectedCountry?: CountryInternationalDeliveryDetails;
}) => {
  const { formatMessage } = useMelioIntl();
  let schemaFields: Array<keyof InternationalBankDetailsFormFields> = ['selectedInternationalCountries'];
  if (selectedCountry?.identifierType === 'iban') {
    schemaFields = schemaFields.concat(['ibanCode', 'bankName']);
  }
  if (selectedCountry?.identifierType === 'bicSwift') {
    schemaFields = schemaFields.concat(['swiftCode', 'accountNumber', 'bankName']);
  }

  return object().shape(
    pickBy(
      {
        selectedInternationalCountries: object()
          .nullable()
          .required(
            formatMessage(
              'activities.internationalBankDetails.screens.internationalBankDetailsForm.internationalCountrySelection.required'
            )
          ),
        ibanCode: string()
          .required(
            formatMessage(
              'activities.internationalBankDetails.screens.internationalBankDetailsForm.ibanCode.validation.required'
            )
          )
          .test({
            test: () => isIdentifierValueValid,
            message: formatMessage(
              'activities.internationalBankDetails.screens.internationalBankDetailsForm.ibanCode.validation.invalid'
            ),
          }),
        swiftCode: string()
          .required(
            formatMessage(
              'activities.internationalBankDetails.screens.internationalBankDetailsForm.swiftCode.validation.required'
            )
          )
          .test({
            test: () => isIdentifierValueValid,
            message: formatMessage(
              'activities.internationalBankDetails.screens.internationalBankDetailsForm.swiftCode.validation.invalid'
            ),
          }),

        accountNumber: string().required(
          formatMessage(
            'activities.internationalBankDetails.screens.internationalBankDetailsForm.accountNumber.validation.required'
          )
        ),
        bankName: string().required(
          formatMessage(
            'activities.internationalBankDetails.screens.internationalBankDetailsForm.bankName.validation.required'
          )
        ),
      },
      (_, key) => schemaFields.includes(key as keyof InternationalBankDetailsFormFields)
    )
  ) as unknown as SchemaOf<InternationalBankDetailsFormFields>;
};
