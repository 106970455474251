import { useMelioIntl } from '@melio/ar-domain';
import { Group, Illustration, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export const GeneralError = forwardRef((props, ref) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Group variant="vertical" spacing="s" alignItems="center" {...props} ref={ref}>
      <Illustration type="no-items" />
      <Text textStyle="body3">
        {formatMessage('ar.invoiceLifecycle.activities.issueInvoice.errors.generalMessage.description')}
      </Text>
    </Group>
  );
});
