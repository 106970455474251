import { StatusModal, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { ApprovalWorkflow } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { Location, useLocation, useNavigate } from 'react-router-dom';

export const ApprovalWorkflowCreatedModal = () => {
  const location = useLocation() as Location<{ newWorkflowCreated?: boolean; workflowId: ApprovalWorkflow['id'] }>;
  const { track } = useAnalytics();
  const navigate = useNavigate();
  const showWorkflowCreationSuccessMessage = Boolean(location.state?.newWorkflowCreated);
  const { formatMessage } = useMelioIntl();
  const [isApprovalWorkflowsV2] = useFeature<boolean>(FeatureFlags.PlatformApprovalWorkflowsV2, false, {
    shouldTrack: true,
  });

  const onClose = (cta: string) => {
    track('Settings', 'Click', {
      PageName: 'approval-workflow-added',
      Flow: 'add-approval-workflow',
      Intent: 'add-approval-workflow',
      Cta: cta,
    });
    navigate('.', { state: { ...location.state, newWorkflowCreated: undefined }, replace: true });
  };

  return (
    <StatusModal
      isOpen={showWorkflowCreationSuccessMessage}
      header={formatMessage('activities.settings.approval-workflow-settings.created-message.title')}
      onClose={() => onClose('exit')}
      primaryButton={{
        variant: 'primary',
        onClick: () => onClose('got-it'),
        label: formatMessage('activities.settings.approval-workflow-settings.created-message.ok'),
      }}
      variant="success"
      ref={() => {
        track('User', 'Status', {
          PageName: 'workflow-added',
          Flow: isApprovalWorkflowsV2 ? 'approval-workflow-v2' : 'approval-workflow',
          Intent: 'approval-workflow',
          WorkflowId: location.state.workflowId,
          Status: 'success',
        });
      }}
    >
      <Text>{formatMessage('activities.settings.approval-workflow-settings.created-message.content')}</Text>
    </StatusModal>
  );
};
