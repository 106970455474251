import { Button, CollapsibleCard, Divider, Form, FormField, NakedButton, Text, useFieldArray } from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { Fragment } from 'react';

import { FXFormSectionCardBaseProps, OwnershipDetailsForm, UBO_FROM_ACCOUNT_HOLDER_ID } from '../types';
import { OwnerDetailsForm } from './OwnerDetailsForm';

export type OwnershipDetailsCardProps = FXFormSectionCardBaseProps<OwnershipDetailsForm> & {
  verifiedPersonasLength: number;
};

const MAX_ALLOWED_UBOS = 4;
export const FXOwnershipDetailsCard = ({
  form,
  isCompleted,
  onExpandChange,
  isExpanded,
  isDisabled,
  verifiedPersonasLength,
}: OwnershipDetailsCardProps) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'ubos',
  });

  useAnalyticsView('Organization', isExpanded, true, {
    PageName: 'ownership-details',
    Intent: 'set-ownership-details',
    IsUboCollectBefore: verifiedPersonasLength > 0,
  });

  const onRemoveUbo = (index: number) => {
    remove(index);
  };

  const onAddAdditionalOwner = () => {
    append({
      uboId: Date.now().toString(),
      ownerFirstName: '',
      ownerLastName: '',
      address: {
        line1: '',
        postalCode: '',
        state: '',
        city: '',
      },
      ssn: '',
      ownershipPercentage: '',
      dateOfBirth: undefined,
    });
  };

  const onClickSaveAndContinue = () => {
    track('Organization', 'Click', {
      Cta: 'save-and-continue',
      Intent: 'set-ownership-details',
      PageName: 'ownership-details',
      Flow: 'UBO',
      OwnerDetails: fields,
      NumberOfOwner: fields.length,
    });
    form.submitButtonProps.onClick();
  };

  return (
    <CollapsibleCard
      isDisabled={isDisabled || (!isExpanded && !isCompleted)}
      data-testid="fx-ownership-details-card"
      isExpanded={isExpanded}
      status={isCompleted ? 'success' : undefined}
      onExpandChange={onExpandChange}
      title={formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.ownershipDetailsCard.title')}
      description={
        isExpanded
          ? formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.ownershipDetailsCard.subtitle')
          : undefined
      }
    >
      <Text textStyle="body4">
        {formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.ownershipDetailsCard.card.title')}
      </Text>
      <Form {...form.formProps} columns={6}>
        {fields.map((f, i) => (
          <Fragment key={f.id}>
            <OwnerDetailsForm
              form={form}
              index={i}
              onRemove={fields.length > 1 && f.uboId !== UBO_FROM_ACCOUNT_HOLDER_ID ? () => onRemoveUbo(i) : undefined}
            />
            {fields.length > 1 && i < fields.length - 1 && (
              <FormField
                size="small"
                colSpan={6}
                labelProps={{ label: '', isHidden: true }}
                render={() => <Divider />}
              />
            )}
          </Fragment>
        ))}
        {fields.length < MAX_ALLOWED_UBOS && (
          <Form.ContentBox colSpan={6}>
            <NakedButton
              label={formatMessage(
                'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.ownershipDetailsCard.addOwnerButton'
              )}
              onClick={onAddAdditionalOwner}
              variant="secondary"
              data-testid="add-additional-owner"
            />
          </Form.ContentBox>
        )}
      </Form>
      <Button
        label={formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.ownershipDetailsCard.submitButton.label'
        )}
        data-testid="save-and-continue-ubo"
        {...form.submitButtonProps}
        onClick={onClickSaveAndContinue}
      />
    </CollapsibleCard>
  );
};
