import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const SupportIcon = (props: IconProps) => (
  <Icon width="20px" height="20px" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 9.18985C20 4.51021 15.5 0.833344 10 0.833344C4.5 0.833344 0 4.51021 0 9.18985C0 13.8695 4.5 17.5464 10 17.5464H10.3333C11.0833 17.5464 11.8333 17.4628 12.5 17.2957L17.25 19.1341C17.8333 19.3012 18.4167 18.7998 18.3333 18.2149L17.9167 14.2873L18 14.2038C19.25 12.7831 20 11.0283 20 9.18985ZM1.66667 9.18985C1.66667 5.51299 5.33333 2.50464 10 2.50464C14.6667 2.50464 18.3333 5.51299 18.3333 9.18985C18.3333 10.694 17.6667 12.1982 16.4167 13.4517L16.3333 13.5352C16.1667 13.7024 16.1667 13.8695 16.1667 14.1202L16.5 17.045L12.9167 15.5408C12.75 15.4572 12.5 15.4572 12.3333 15.5408C11.6667 15.7915 10.9167 15.8751 10 15.8751C5.33333 15.8751 1.66667 12.8667 1.66667 9.18985Z"
      fill="currentColor"
    />
  </Icon>
);
