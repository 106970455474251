import { useAnalyticsContext } from '@melio/platform-analytics';
import { Payment } from '@melio/platform-api';

const DEFAULT_GLOBAL_PROPERTIES = {
  PageName: 'repayment-summary',
  Flow: 'dashboard',
  Intent: 'loan-details',
  EntryPoint: 'dashboard-financing',
};

export const useLoanDrawerAnalytics = ({ payment }: { payment?: Payment }) =>
  useAnalyticsContext({
    globalProperties: !payment?.loan
      ? DEFAULT_GLOBAL_PROPERTIES
      : {
          ...DEFAULT_GLOBAL_PROPERTIES,
          repaymentStatus: payment.loan.status,
          PaymentId: payment.id,
          VendorId: payment.vendorId,
          DeliveryMethodType: payment.deliveryMethod?.type,
          PaymentAmount: payment.amount,
          BillIds: payment.bills?.map((bill) => bill.id),
          PaymentMethodType: payment.fundingSource?.type,
          CardNetwork: payment.fundingSource?.type === 'card' ? payment.fundingSource.details.network : undefined,
          Amont: {
            repaid:
              payment.loan.fees.totalAmount && payment.loan.remainingAmount
                ? payment.loan.fees.totalAmount - payment.loan.remainingAmount
                : 0,
            remaining: payment.loan.remainingAmount || 0,
          },
          fees: payment.loan.fees.financingTotalFee,
          installments: payment.loan.installments.length,
        },
  });
