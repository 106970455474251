import { apiClient, InternationalBankDetails, PostBankDetailsRequest } from '@melio/javascript-sdk';

import { useModelApi } from '../../core';
import { UseModelApiProps } from '../../core/types';

type UseInternationalBankDetailsProps = UseModelApiProps<InternationalBankDetails> & {
  params: PostBankDetailsRequest;
};

export const useInternationalBankDetails = ({ params, ...props }: UseInternationalBankDetailsProps) => {
  const client = apiClient.internationalBankDetails();
  const query = useModelApi<InternationalBankDetails>({
    ...props,
    queryKey: ['international-bank-details', params],
    queryFn: () => client.getBankDetails(params),
    id: 'international-bank-details',
  });

  return {
    ...query,
  };
};
