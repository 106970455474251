import { useMelioQueryClient, useModelApi } from '@melio/api-client';
import {
  ApplyForFinancingRequest,
  ApplyForFinancingResponseData,
  FinancingApiClient,
  MelioPromise,
} from '@melio/platform-api-axios-client';
import { convertDollarsToCents, getDollarsFromCents } from '@melio/platform-utils';
import { updateDateTimeToDateString } from '@melio/platform-utils/src/date-utils/utils';

type Options = {
  onCreateSuccess?: (data: ApplyForFinancingResponseData) => void;
  onCreateError?: VoidFunction;
};
export type UseApplyForFinancingProps = {
  options?: Options;
};

const prepareCreateParams = (params: ApplyForFinancingRequest) => {
  const { beneficialOwners, ownerDetails, businessDetails } = params;
  const owners = updateDateTimeToDateString(ownerDetails, ['dateOfBirth']);
  const prepearedBeneficialOwners = beneficialOwners?.map((bo) => updateDateTimeToDateString(bo, ['dateOfBirth']));

  return convertDollarsToCents(
    {
      ...params,
      ownerDetails: owners,
      beneficialOwners: prepearedBeneficialOwners,
      businessDetails,
    },
    ['businessDetails.annualRevenue']
  );
};

export const useFinancingApplication = (args?: UseApplyForFinancingProps) => {
  const queryClient = useMelioQueryClient();
  const query = useModelApi({
    id: 'financing-application',
    queryKey: ['FinancingApi', 'financingApplication'],
    queryFn: () => Promise.resolve({ data: {} }) as MelioPromise<ApplyForFinancingResponseData>,
    prepareCreateParams,
    createFn: FinancingApiClient.applyForFinancing,
    enabled: false,
    onCreate: (res) => {
      void queryClient.invalidateQueries('FinancingApi');
      const { status, limit } = res;
      args?.options?.onCreateSuccess?.({
        status,
        limit: limit ? getDollarsFromCents(limit) : 0,
      });
    },
    onCreateError: args?.options?.onCreateError,
  });

  return {
    ...query,
    applyForFinancing: (params: ApplyForFinancingRequest) => query.create(params),
  };
};
