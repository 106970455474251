import { useMelioIntl } from '@melio/platform-i18n';
import { SystemMessageProvider, useSystemMessage } from '@melio/platform-utils';

import { MissingVendorInfoDrawer } from './MissingVendorInfoDrawer';
import { MissingVendorInfoDrawerWidgetProps } from './types';

export const MissingVendorInfoDrawerWidget = (props: MissingVendorInfoDrawerWidgetProps) => {
  const { formatMessage } = useMelioIntl();
  const { showMessage } = useSystemMessage();

  const handleDone = (vendorName: string) => {
    showMessage({
      type: 'success',
      title: formatMessage('widgets.missingVendorInfoDrawer.toast.success', {
        vendorName,
      }),
      id: 'edit-vendor-success',
    });
  };

  return (
    <SystemMessageProvider>
      <MissingVendorInfoDrawer {...props} onDone={handleDone} />
    </SystemMessageProvider>
  );
};
