import { ARInvoice, useMelioIntl } from '@melio/ar-domain';

export const useRowContextDescription = (row: ARInvoice): string => {
  const { formatDate, formatCurrency, formatMessage } = useMelioIntl();

  return formatMessage('ar.dashboard.activities.invoiceTable.rowContext', {
    companyName: row.customer?.companyName || '',
    invoiceNumber: row.invoiceNumber,
    createdAt: formatDate(row.createdAt),
    dueDate: formatDate(row.dueDate),
    lastSeen: row.lastSeen
      ? formatDate(row.lastSeen)
      : formatMessage('ar.dashboard.activities.invoiceTable.rowContext.notSeen'),
    displayStatus: row.displayStatus,
    totalAmount: formatCurrency(row.totalAmount),
  });
};
