import { forwardRef } from '@melio/platform-utils';

import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';

export type PaymentScheduledLoadingScreenProps = {
  onBack?: VoidFunction;
  onClose?: VoidFunction;
};

export const PaymentScheduledLoadingScreen = forwardRef<PaymentScheduledLoadingScreenProps, 'div'>(
  ({ onBack, onClose, ...props }, ref) => (
    <NewSinglePaymentStepLayout
      data-component="PaymentScheduledActivity.PaymentScheduledLoadingScreen"
      data-testid="payment-scheduled-activity-payment-scheduled-loading-screen"
      {...props}
      ref={ref}
      isLoading
    />
  )
);

PaymentScheduledLoadingScreen.displayName = 'PaymentScheduledActivity.PaymentScheduledLoadingScreen';
