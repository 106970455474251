import { identity, pickBy } from 'lodash';

import { AddVendorFormFields } from '../../../../types';

export const getCreateVendorPayload = (data: AddVendorFormFields) => {
  const { companyName, email, phone, fullName, accountNumber } = data;

  const contact = pickBy(
    {
      name: fullName,
      email,
      phoneNumber: phone,
    },
    identity
  );

  return {
    name: companyName ?? '',
    contact,
    accountNumber: accountNumber || undefined,
  };
};
