import { ActionsDropdownMenu, FloatingButton } from '@melio/penny';
import { useAnalytics, useAnalyticsContext, withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useActivitiesNavigate } from '../../../../utils';
import { usePayDashboardTabs } from '../../hooks/usePayDashboardTabs';
import { BillsTabProps } from '../BillsTab/BillsTab';

type MobileActionsMenuProps = {
  onAddNewBill: BillsTabProps['onAddNewBill'];
  onAddNewPayment: () => void;
};

export const MobileActionsMenu = withAnalyticsContext(({ onAddNewPayment, onAddNewBill }: MobileActionsMenuProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { pathname } = useLocation();
  const { track } = useAnalytics();
  const { selectedTab } = usePayDashboardTabs();
  const { formatMessage } = useMelioIntl();
  const { navigateToNewVendor } = useActivitiesNavigate();

  useAnalyticsContext({
    globalProperties: {
      PageName: 'add-item',
      Flow: 'dashboard',
      Intent: 'add-item',
      EntryPoint: selectedTab ? `dashboard-${selectedTab}` : 'dashboard-unkown',
    },
  });

  const handleNewPaymentClick = () => {
    track('Dashboard', 'Click', {
      Cta: 'plus-sign-new-payment',
    });
    onAddNewPayment();
  };

  const handleAddBillClick = () => {
    track('Dashboard', 'Click', {
      Cta: 'plus-sign-add-a-bill',
    });
    onAddNewBill({ returnUrl: pathname });
  };

  const handleAddVendorClick = () => {
    track('Dashboard', 'Click', {
      Cta: 'plus-sign-add-vendor',
    });
    navigateToNewVendor({ returnUrl: pathname });
  };

  return (
    <ActionsDropdownMenu
      items={[
        {
          label: formatMessage('activities.payDashboard.mobileActionsMenu.newPayment'),
          icon: 'pay',
          onClick: handleNewPaymentClick,
          dataTestId: 'pay-dashboard-mobile-actions-menu-new-payment',
        },
        {
          label: formatMessage('activities.payDashboard.mobileActionsMenu.addBill'),
          icon: 'add-bill',
          onClick: handleAddBillClick,
          dataTestId: 'pay-dashboard-mobile-actions-menu-add-bill',
        },
        {
          label: formatMessage('activities.payDashboard.mobileActionsMenu.addVendor'),
          icon: 'user-add',
          onClick: handleAddVendorClick,
          dataTestId: 'pay-dashboard-mobile-actions-menu-add-vendor',
        },
      ]}
      size="medium"
      title={formatMessage('activities.payDashboard.mobile.actionsMenu.title')}
      trigger={
        <FloatingButton
          aria-label={formatMessage('activities.payDashboard.mobileActionsMenu.addButton.ariaLabel')}
          icon="add"
          data-testid="pay-dashboard-mobile-fab"
        />
      }
      isOpen={isMenuOpen}
      onOpenChange={setIsMenuOpen}
    />
  );
});
