import { StatusModal, Text } from '@melio/penny';
import { PaymentRequest, usePaymentRequest } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils';
import React from 'react';

type PaymentRequestRejectModalProps = {
  paymentRequestId: PaymentRequest['id'];
  onClose: VoidFunction;
  onSuccess?: VoidFunction;
  isOpen: boolean;
};

export const PaymentRequestRejectModal = ({
  paymentRequestId,
  isOpen,
  onClose,
  onSuccess,
}: PaymentRequestRejectModalProps) => {
  const { formatMessage } = useMelioIntl();
  const { data: paymentRequest, isLoading, cancel, isUpdating } = usePaymentRequest({ id: paymentRequestId });
  const { showMessage } = useSystemMessage();
  const isModalLoading = isLoading || !paymentRequest;

  const onConfirmClick = async () => {
    try {
      await cancel();
      showMessage({
        type: 'informative',
        title: formatMessage('widgets.paymentRequestDetails.rejectModal.successToast'),
      });
      onSuccess?.();
      onClose?.();
    } catch (e) {
      showMessage({ type: 'error', title: formatMessage('widgets.paymentRequestDetails.rejectModal.failureToast') });
    }
  };

  return (
    <StatusModal
      data-testid="payment-request-reject-modal"
      isOpen={isOpen}
      variant="decline"
      header={formatMessage('widgets.paymentRequestDetails.rejectModal.header')}
      onClose={onClose}
      primaryButton={{
        label: formatMessage('widgets.paymentRequestDetails.rejectModal.primaryButton'),
        variant: 'critical',
        onClick: () => onConfirmClick(),
        isLoading: isUpdating,
      }}
      isLoading={isModalLoading}
    >
      <Text>
        {formatMessage('widgets.paymentRequestDetails.rejectModal.description', {
          vendorName: paymentRequest?.vendor?.name,
        })}
      </Text>
    </StatusModal>
  );
};
