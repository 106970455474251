import { Box } from '@chakra-ui/react';
import { getBiCardType } from '@melio/ap-domain';
import { Group } from '@melio/penny';
import { sanitizeStringForAnalytics, useAnalytics } from '@melio/platform-analytics';
import { Card, FundingSource, FundingSourceType } from '@melio/platform-api';
import { forwardRef } from '@melio/platform-utils';

import {
  FundingSourceTypesOption,
  getDisabledFundingSourceReason,
  getDisabledFundingTypeReasons,
  isFundingSourceDisabled,
  isFundingTypeSupported,
} from '../../../utils';
import { AddFundingSourceCard } from '../AddFundingSourceCard/AddFundingSourceCard.widget';
import { AddFundingSourceButton } from './AddFundingSourceButton';
import { FundingSourceCardList } from './FundingSourceCardList.widget';

type BaseProps = {
  fundingSourceTypesOptions: FundingSourceTypesOption[];
  fundingType: FundingSource['type'];
  cardType?: Card['type'];
  onAdd: (type: FundingSource['type']) => void;
  canAdd: boolean;
  userId?: string;
  disableUpdatingFundingSource?: boolean;
};

type SelectFundingSourceProps = {
  fundingSources: FundingSource[];
  selectedId?: FundingSource['id'];
  onSelect: (fundingSourceId: FundingSource['id']) => void;
  onVerify: (fundingSourceId: FundingSource['id']) => void;
};

export type FundingSourceCardListContainerProps = StrictUnion<BaseProps | (BaseProps & SelectFundingSourceProps)>;
export const restrictedAddableFundingSources: Array<unknown> = [FundingSourceType.FlexAccount];

export const FundingSourceCardListContainer = forwardRef<FundingSourceCardListContainerProps, 'div'>((props, ref) => {
  const {
    fundingSources,
    selectedId,
    fundingType,
    cardType,
    onAdd,
    onSelect,
    onVerify,
    fundingSourceTypesOptions,
    canAdd,
    userId,
    disableUpdatingFundingSource,
    ...rest
  } = props;

  const { track } = useAnalytics();
  const addFundingSource = (ctaLabel: string) => {
    track('PaymentMethod', 'Click', {
      PageName: 'how-do-you-want-to-pay',
      Intent: 'choose-payment-method',
      PaymentMethodType: fundingType,
      CardType: fundingType === FundingSourceType.Card ? getBiCardType(cardType) : undefined,
      Cta: sanitizeStringForAnalytics(ctaLabel),
    });
    onAdd(fundingType);
  };

  const showAddMethodOption = canAdd && !disableUpdatingFundingSource;
  const isFundingSourceAllowedToBeAdded = !restrictedAddableFundingSources.includes(fundingType);

  return props.fundingSources && props.fundingSources.length > 0 ? (
    <Group variant="vertical" spacing="s" data-component="FundingSourceCardListContainer" ref={ref} {...rest}>
      <FundingSourceCardList
        selectedId={selectedId}
        fundingSources={props.fundingSources}
        onSelect={props.onSelect}
        onVerify={props.onVerify}
        isDisabled={(fundingSource: RecursivePartial<FundingSource>) =>
          (disableUpdatingFundingSource && fundingSource.id !== selectedId) ||
          isFundingSourceDisabled(fundingSource, fundingSourceTypesOptions)
        }
        disabledReason={(fundingSource: RecursivePartial<FundingSource>) =>
          getDisabledFundingSourceReason(fundingSource, fundingSourceTypesOptions)
        }
        isFundingTypeSupported={(fundingSource: RecursivePartial<FundingSource>) =>
          fundingSource.type ? isFundingTypeSupported(fundingSource.type, fundingSourceTypesOptions) : false
        }
        userId={userId}
      />
      {showAddMethodOption && isFundingSourceAllowedToBeAdded && (
        <Box>
          <AddFundingSourceButton
            data-testid={`add-funding-source-button-${cardType ?? fundingType}`}
            onClick={addFundingSource}
            fundingType={fundingType}
            cardType={cardType}
            isDisabled={!isFundingTypeSupported(fundingType, fundingSourceTypesOptions)}
          />
        </Box>
      )}
    </Group>
  ) : showAddMethodOption && isFundingSourceAllowedToBeAdded ? (
    <Box ref={ref} {...rest}>
      <AddFundingSourceCard
        data-testid={`add-${cardType ?? fundingType}-funding-source-card`}
        fundingType={fundingType}
        cardType={cardType}
        onClick={addFundingSource}
        isDisabled={!isFundingTypeSupported(fundingType, fundingSourceTypesOptions)}
        disabledReasons={getDisabledFundingTypeReasons(fundingType, fundingSourceTypesOptions)}
      />
    </Box>
  ) : (
    <></>
  );
});
FundingSourceCardListContainer.displayName = 'FundingSourceCardListContainer';
