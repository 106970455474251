/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  AddFxDeliveryMethodActivity,
  AddInternationalDeliveryMethodActivity,
  AddVendorBankDetailsActivity,
  AddVendorCheckDetailsActivity,
  AddVendorDomesticWireDetailsActivity,
  EditVendorBankDetailsActivity,
  EditVendorCheckDetailsActivity,
  EditVendorDomesticWireDetailsActivity,
} from '@melio/ap-activities';
import { AddVendorVirtualCardDetailsActivity } from '@melio/ap-activities/src/components/delivery-methods/AddVendorVirtualCardDetails';
import { OriginFlow, useAnalytics } from '@melio/platform-analytics';
import { DeliveryMethodType, useDeliveryMethods } from '@melio/platform-api';
import { useNavigate } from '@melio/platform-utils';

import { WithLoading } from '@/hoc/withLoading.hoc';
import { useRouter } from '@/hooks/router.hooks';
import { useVendorEnrichedByIdQuery } from '@/hooks/vendors.hooks';
import { APP_EVENTS, emitAppEvent } from '@/queries/event-emitter-query';

type SupportedDeliveryMethodType =
  | 'bank-account'
  | 'domestic-wire-account'
  | 'paper-check'
  | 'international-account'
  | 'virtual-card'; // TODO: how to refer a string union type?
type SubmissionTargetType = 'continue' | 'close';

type VendorDeliveryMethodScreenProps = {
  vendorId: string;
  deliveryMethodType: SupportedDeliveryMethodType;
  returnUrl?: string;
  isInternationalFx?: boolean;
};

const deliveryMethodTypeToAddTrackEventName = (deliveryMethodType: SupportedDeliveryMethodType) => {
  switch (deliveryMethodType) {
    case DeliveryMethodType.BankAccount:
      return 'AddVendorACHDetails';
    case DeliveryMethodType.DomesticWireAccount:
      return 'AddVendorDomesticWireDetails';
    case DeliveryMethodType.PaperCheck:
      return 'AddVendorCheckDetails';
    case DeliveryMethodType.VirtualCard:
      return 'AddVendorVirtualCardDetails';
    case DeliveryMethodType.InternationalAccount:
      return 'AddVendorInternationalDetails';
  }
};

const deliveryMethodTypeToActivities = (
  deliveryMethodType: SupportedDeliveryMethodType,
  isInternationalFx?: boolean,
) => {
  switch (deliveryMethodType) {
    case DeliveryMethodType.BankAccount:
      return {
        AddVendorDeliveryMethodActivity: AddVendorBankDetailsActivity,
        EditVendorDeliveryMethodActivity: EditVendorBankDetailsActivity,
      };
    case DeliveryMethodType.DomesticWireAccount:
      return {
        AddVendorDeliveryMethodActivity: AddVendorDomesticWireDetailsActivity,
        EditVendorDeliveryMethodActivity: EditVendorDomesticWireDetailsActivity,
      };
    case DeliveryMethodType.PaperCheck:
      return {
        AddVendorDeliveryMethodActivity: AddVendorCheckDetailsActivity,
        EditVendorDeliveryMethodActivity: EditVendorCheckDetailsActivity,
      };
    case DeliveryMethodType.VirtualCard:
      return {
        AddVendorDeliveryMethodActivity: AddVendorVirtualCardDetailsActivity,
        EditVendorDeliveryMethodActivity: AddVendorVirtualCardDetailsActivity,
      };
    case DeliveryMethodType.InternationalAccount:
      if (isInternationalFx) {
        return {
          AddVendorDeliveryMethodActivity: AddFxDeliveryMethodActivity,
          EditVendorDeliveryMethodActivity: undefined,
        };
      }
      return {
        AddVendorDeliveryMethodActivity: AddInternationalDeliveryMethodActivity,
        EditVendorDeliveryMethodActivity: undefined,
      };
  }
};

export const VendorDeliveryMethodScreen = ({
  vendorId,
  deliveryMethodType,
  returnUrl,
  isInternationalFx,
}: VendorDeliveryMethodScreenProps) => {
  const { goBack, goToVendor, goToAddNewBill } = useRouter();
  const navigate = useNavigate();

  const { track } = useAnalytics();

  const { data: vendor, isLoading: isVendorLoading } = useVendorEnrichedByIdQuery(vendorId);
  const { data: deliveryMethods, isLoading: isDeliveryMethodsLoading } = useDeliveryMethods({ vendorId });

  React.useEffect(() => {
    if (!isVendorLoading && !vendor) {
      goToVendor(vendorId);
    }
  }, [vendor, isVendorLoading]);

  const handleClose = () => {
    if (returnUrl) {
      navigate(returnUrl);
    } else goToVendor(vendorId);
  };

  const handleContinue = () => {
    goToAddNewBill({
      vendorId,
      deliveryMethodId: deliveryMethodId || undefined,
      originFlow: OriginFlow.VendorsFlow,
    });
  };

  const handleDone = (target?: SubmissionTargetType) => {
    target === 'continue' ? handleContinue() : handleClose();
  };

  const onEditDoneHandler = React.useCallback(() => {
    const deliveryMethod = deliveryMethods?.find((dm) => dm.type === deliveryMethodType);
    if (deliveryMethod) {
      emitAppEvent(APP_EVENTS.VENDOR_DELIVERY_METHODS_UPDATED, { vendorId });
    }
    handleDone();
  }, [vendorId, deliveryMethods, deliveryMethodType]);

  const onAddDoneHandler = React.useCallback(
    (target?: SubmissionTargetType) => {
      track(deliveryMethodTypeToAddTrackEventName(deliveryMethodType), 'Submitted');
      emitAppEvent(APP_EVENTS.VENDOR_DELIVERY_METHODS_UPDATED, { vendorId });
      handleDone(target);
    },
    [vendorId, deliveryMethodType],
  );

  const deliveryMethodId = React.useMemo(() => {
    const deliveryMethod = deliveryMethods?.find((dm) => dm.type === deliveryMethodType);
    return deliveryMethod ? deliveryMethod.id : null;
  }, [deliveryMethods]);

  const { EditVendorDeliveryMethodActivity, AddVendorDeliveryMethodActivity } = deliveryMethodTypeToActivities(
    deliveryMethodType,
    isInternationalFx,
  );

  const commonActivityProps = {
    vendorId,
    onBack: goBack,
    onClose: handleDone,
  };

  return (
    <WithLoading isLoading={isVendorLoading || isDeliveryMethodsLoading} isAbsoluteCenter>
      {deliveryMethodId ? (
        EditVendorDeliveryMethodActivity && (
          <EditVendorDeliveryMethodActivity
            {...commonActivityProps}
            deliveryMethodId={deliveryMethodId}
            onDone={onEditDoneHandler}
            origin={'vendor'}
            isEdit
          />
        )
      ) : (
        <AddVendorDeliveryMethodActivity
          {...commonActivityProps}
          origin={'vendor'}
          onDone={(_, target) => onAddDoneHandler(target)}
        />
      )}
    </WithLoading>
  );
};
