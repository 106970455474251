import { Link, SectionBanner } from '@melio/penny';
import { BankAccount, Loan, Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';

import { getFinancingHandedOffDeadlineDate } from '../../../../../utils/pay-flow/Financing.utils';

export const LoanFailedSectionBanner = ({ payment }: { payment: Override<Payment, { loan: Loan }> }) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const { fundingSource, loan } = payment;
  const { details } = fundingSource || {};
  const { resolveFailedPayment } = useConfig().links;

  return (
    <SectionBanner
      title={formatMessage('activities.payDashboard.drawer.body.failed.sectionBanner.title')}
      description={formatMessage('activities.payDashboard.drawer.body.failed.sectionBanner.description', {
        installmentNumber: loan.nextInstallmentNumber,
        installmentsLength: loan.installments.length,
        bankNumber: (details as BankAccount).accountNumber.slice(-4),
        deadlineDate: formatDate(getFinancingHandedOffDeadlineDate(loan), { dateStyle: 'medium' }),
        learnMoreLink: (
          <Link
            href={resolveFailedPayment}
            label={formatMessage(
              'activities.payDashboard.drawer.body.failed.sectionBanner.description.learnMoreLink.label'
            )}
            newTab
          />
        ),
      })}
      variant="critical"
      data-testid="loan-failed-alert"
    />
  );
};
