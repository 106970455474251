import { Container, Group, Icon, NakedButton, Text, Typography } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-provider';

type InstallmentsAmountProps = {
  numOfInstallments: number;
  onEdit?: VoidFunction;
};

export const InstallmentsAmount = ({ onEdit, numOfInstallments }: InstallmentsAmountProps) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Group>
      <Group.Item shrink={0}>
        <Container width="fit-content" alignItems="center" height="full">
          <Icon size="large" type="installments" />
        </Container>
      </Group.Item>
      <Group.Item grow={1}>
        <InstallmentsAmountContent numOfInstallments={numOfInstallments} />
      </Group.Item>
      <Group.Item alignSelf="flex-start">
        <NakedButton
          variant="secondary"
          onClick={onEdit}
          data-testid="repayment-section-installment-amount-edit-button"
          aria-label="Edit item"
          label={formatMessage('widgets.reviewAndConfirm.repaymentSection.installmentsAmount.edit')}
        />
      </Group.Item>
    </Group>
  );
};

const InstallmentsAmountContent = ({ numOfInstallments }: { numOfInstallments: number }) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Group variant="vertical" spacing="xxs" data-testid="repayment-section-num-of-installments">
      <Text textStyle="body2">
        {formatMessage('widgets.reviewAndConfirm.repaymentSection.installmentsAmount.title', {
          numOfInstallments,
        })}
      </Text>
      <Typography.Description
        label={formatMessage('widgets.reviewAndConfirm.repaymentSection.installmentsAmount.description')}
      />
    </Group>
  );
};
