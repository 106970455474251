import React from 'react';
import { Stack } from '@chakra-ui/react';
import { FileInfo } from '@melio/platform-api';

import { InvoiceImage } from '@/cl/components/InvoiceImage/InvoiceImage.component';

type ScannedInvoiceFileProps = {
  scannedInvoiceFile?: FileInfo | null;
};
export const ScannedInvoiceFile = ({ scannedInvoiceFile }: ScannedInvoiceFileProps) => {
  return (
    <Stack flexDirection="column">
      <InvoiceImage
        editMode={false}
        file={scannedInvoiceFile}
        onFileUploaded={() => {}}
        onFileDeleted={() => {}}
        isProcessingFile={false}
      />
    </Stack>
  );
};
