import { useMelioIntl } from '@melio/platform-i18n';
import { object, SchemaOf, string } from 'yup';

import { MAX_INVOICE_NUMBER_LENGTH } from '../../utils/consts';
import { InvoiceNumberSchema } from './types';

export const useSetInvoiceNumberSchema = (): SchemaOf<InvoiceNumberSchema> => {
  const { formatMessage } = useMelioIntl();

  return object().shape({
    invoiceNumber: string()
      .required(formatMessage('activities.addBill.setInvoiceNumberModal.field.errorMessage'))
      .trim()
      .max(MAX_INVOICE_NUMBER_LENGTH, formatMessage('activities.addBill.setInvoiceNumberModal.field.errorMessage')),
  });
};
