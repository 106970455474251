import { GoogleAddressInput, InternationalAddress } from '@melio/form-controls';
import { Form, useMelioForm } from '@melio/penny';
import { useTrackAnalyticsOnFailedFormSubmit } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef, useBoolean } from '@melio/platform-utils';
import { defaults } from 'lodash';
import React, { ComponentProps, useCallback, useEffect, useRef } from 'react';

import { SubmissionTargetType } from '../../../../../../types';
import {
  InternationalVendorDetailsFormFields,
  InternationalVendorDetailsFormProps,
} from './InternationalVendorDetailsForm.widget.types';
import { useInternationalVendorDetailsFormSchema } from './InternationalVendorDetailsForm.widget.utils';

export const InternationalVendorDetailsFormWidget = forwardRef<InternationalVendorDetailsFormProps, 'form'>(
  ({ onSubmit, defaultValues: _defaultValues, isSaving, onSubmissionStateChange, ...props }, ref) => {
    const [showFullAddress, setShowFullAddress] = useBoolean(false);
    const { formatMessage } = useMelioIntl();
    const countryCode = useRef<string | undefined>(undefined);

    const defaultValues = defaults(_defaultValues, {
      legalBusinessName: '',
      fullName: '',
      line1: '',
      line2: '',
      city: '',
      state: '',
      country: '',
      postalCode: '',
    });

    const handleSubmit = (data: InternationalVendorDetailsFormFields, _: unknown, target?: string | undefined) =>
      onSubmit({ ...data, countryCode: countryCode.current || '' }, target as SubmissionTargetType);

    const { formProps, registerField, formState, setValue } = useMelioForm<InternationalVendorDetailsFormFields>({
      onSubmit: handleSubmit,
      schema: useInternationalVendorDetailsFormSchema(showFullAddress),
      defaultValues,
      isSaving,
      onSubmissionStateChange,
      subscribeToDefaultValuesChanges: true,
    });

    useTrackAnalyticsOnFailedFormSubmit(formState, 'DeliveryMethod', 'Status');

    const setValuesToAddress = useCallback(
      (address: InternationalAddress) => {
        setValue('line1', address.line1, { shouldValidate: true });
        setValue('city', address.city.name, { shouldValidate: true });
        setValue('state', address.state.name, { shouldValidate: true });
        setValue('country', address.country.name, { shouldValidate: true });
        setValue('postalCode', address.postalCode, { shouldValidate: true });
        countryCode.current = address.country.code;

        setShowFullAddress.on();
      },
      [setShowFullAddress, setValue, countryCode]
    );

    const handleAddressChange: ComponentProps<typeof GoogleAddressInput>['onChange'] = ({ target: { value } }) => {
      setValuesToAddress(value as unknown as InternationalAddress);
    };

    useEffect(() => {
      const { postalCode, countryCode, country, city, line1, state } = defaultValues;

      if (postalCode && countryCode && country && city && line1 && state) {
        setValuesToAddress({
          country: {
            name: country,
            code: countryCode,
          },
          state: {
            name: state,
            code: '',
          },
          city: {
            name: city,
            code: '',
          },
          postalCode,
          line1,
          formattedAddress: `${line1}, ${city}, ${state} ${postalCode}, ${country}`,
        });
      }
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Form data-component="InternationalVendorDetailsFormWidget" {...props} {...formProps} columns={2} ref={ref}>
        <Form.TextField
          colSpan={2}
          labelProps={{ label: formatMessage(`widgets.InternationalVendorDetailsForm.legalBusinessName.label`) }}
          helperTextProps={{
            label: formatMessage(`widgets.InternationalVendorDetailsForm.legalBusinessName.helperText`),
          }}
          placeholder={formatMessage(`widgets.InternationalVendorDetailsForm.legalBusinessName.placeholder`)}
          {...registerField('legalBusinessName')}
        />
        <Form.TextField
          colSpan={2}
          labelProps={{ label: formatMessage(`widgets.InternationalVendorDetailsForm.fullName.label`) }}
          placeholder={formatMessage(`widgets.InternationalVendorDetailsForm.fullName.placeholder`)}
          {...registerField('fullName')}
        />
        <GoogleAddressInput
          colSpan={2}
          labelProps={{ label: formatMessage(`widgets.InternationalVendorDetailsForm.line1.label`) }}
          placeholder={formatMessage(`widgets.InternationalVendorDetailsForm.line1.placeholder`)}
          onChange={handleAddressChange}
          {...registerField('line1')}
        />
        {showFullAddress ? (
          <>
            <Form.TextField
              colSpan={2}
              labelProps={{ label: formatMessage(`widgets.InternationalVendorDetailsForm.line2.label`) }}
              placeholder={formatMessage(`widgets.InternationalVendorDetailsForm.line2.placeholder`)}
              {...registerField('line2')}
            />
            <Form.TextField
              labelProps={{ label: formatMessage(`widgets.InternationalVendorDetailsForm.city.label`) }}
              placeholder={formatMessage(`widgets.InternationalVendorDetailsForm.city.placeholder`)}
              {...registerField('city')}
            />
            <Form.TextField
              labelProps={{ label: formatMessage(`widgets.InternationalVendorDetailsForm.state.label`) }}
              placeholder={formatMessage(`widgets.InternationalVendorDetailsForm.state.placeholder`)}
              {...registerField('state')}
            />
            <Form.TextField
              labelProps={{ label: formatMessage(`widgets.InternationalVendorDetailsForm.country.label`) }}
              placeholder={formatMessage(`widgets.InternationalVendorDetailsForm.country.placeholder`)}
              {...registerField('country')}
            />
            <Form.TextField
              labelProps={{ label: formatMessage(`widgets.InternationalVendorDetailsForm.postalCode.label`) }}
              placeholder={formatMessage(`widgets.InternationalVendorDetailsForm.postalCode.placeholder`)}
              {...registerField('postalCode')}
            />
          </>
        ) : null}
      </Form>
    );
  }
);

InternationalVendorDetailsFormWidget.displayName = 'InternationalVendorDetailsFormWidget';
