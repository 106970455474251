import { UseMelioFormResults } from '@melio/penny';
import React, { useContext } from 'react';

import { FormFields } from './types';

export const FormContext = React.createContext<UseMelioFormResults<FormFields> | null>(null);

export const useFormContext = () => {
  const form = useContext(FormContext);
  if (!form) {
    throw new Error('FormContext is not provided');
  }
  return form;
};
