import { Text } from '@melio/penny';
import { ComponentProps } from 'react';

export function SectionHeader({ children, ...props }: Omit<ComponentProps<typeof Text>, 'textStyle' | 'as'>) {
  return (
    <Text {...props} textStyle="heading3Semi" as="h2">
      {children}
    </Text>
  );
}
