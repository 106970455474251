import { useState } from 'react';
import { Box } from '@chakra-ui/react';
import { AvatarImageControl, Button, Container, Group } from '@melio/penny';
import { useAccountWithLogoUrl } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';
import { useSystemMessage } from '@melio/platform-utils';
import { SectionHeader } from '@melio/platform-utils';

import { SettingsSectionContainer } from '@/cl/components/SettingsSectionContainer/SettingsSectionContainer.component';
import { useUploadFile } from '@/hooks/files.hooks';
import { APP_EVENTS, emitAppEvent } from '@/queries/event-emitter-query';
import { usePlatformIntl } from '@/translations/Intl';

export const CompanyLogoUpload = () => {
  const { upload: uploadFile, isUploading: isProcessingFile } = useUploadFile();
  const { data, update: updateAccount } = useAccountWithLogoUrl({ id: 'me' });
  const { fileSizeLimit } = useConfig().settings;
  const { formatMessage } = usePlatformIntl();
  const { showMessage } = useSystemMessage();
  const [selectedFileUrl, setSelectedFileUrl] = useState<string | undefined>(data?.logoUrl ?? '');
  const [isViewMode, setIsViewMode] = useState(true);

  const handleLogoUpload = async (file: File | null) => {
    if (!file) {
      setSelectedFileUrl(undefined);
      await updateAccount({ company: { logoId: null } });
      return emitAppEvent(APP_EVENTS.LOGO_URL, { accountId: data?.id, logoUrl: undefined });
    }
    if (file.size > fileSizeLimit) {
      return showMessage({
        type: 'error',
        title: formatMessage('widgets.billDetails.file.validation.fileSize'),
      });
    }
    try {
      setSelectedFileUrl(URL.createObjectURL(file));
      const fileUploaded = await uploadFile(file);
      const account = await updateAccount({ company: { logoId: fileUploaded.id } });
      emitAppEvent(APP_EVENTS.LOGO_URL, { accountId: account.id, logoUrl: fileUploaded.url });
    } catch (e) {
      setSelectedFileUrl(undefined);
      console.error('CompanyLogoUpload:handleLogoUpload', e);
    } finally {
      setIsViewMode(true);
    }
  };

  return (
    <SettingsSectionContainer>
      <Group spacing="m" variant="vertical">
        <SectionHeader title={formatMessage('widgets.profileSettings.header.companyLogoTitle')} titleAs="h3" />
        <Box display="flex">
          <AvatarImageControl
            isViewMode={isViewMode}
            viewModePlaceholder={data ? `${data.user.firstName} ${data.user.lastName}` : ''}
            deleteButtonText={formatMessage('widgets.profileSettings.header.deleteLogo')}
            isLoading={isProcessingFile}
            value={selectedFileUrl}
            onChange={handleLogoUpload}
            acceptTypes={['png', 'jpg', 'pdf']}
          />
        </Box>
        <Container>
          <Button
            variant={'tertiary'}
            onClick={() => setIsViewMode(!isViewMode)}
            label={formatMessage('widgets.profileSettings.header.buttonText')}
          />
        </Container>
      </Group>
    </SettingsSectionContainer>
  );
};
