import { Form, UseMelioFormResults } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

import { PaymentFlowFormFields } from '../../../types';

type MemoToVendorInputProps = {
  form: UseMelioFormResults<PaymentFlowFormFields>;
};

export const NoteToVendorInput = ({ form }: MemoToVendorInputProps) => {
  const { formatMessage } = useMelioIntl();
  const { registerField } = form;

  return (
    <Form.TextField
      {...registerField('noteToVendor')}
      labelProps={{
        label: formatMessage('activities.paymentFlow.form.content.noteToVendor.label'),
      }}
      helperTextProps={{
        label: formatMessage('activities.paymentFlow.form.content.noteToVendor.helperText'),
      }}
    />
  );
};
