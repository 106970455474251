// TODO: remove this and its test file once this ticket is resolved: https://meliorisk.atlassian.net/browse/ME-7417
// Source code: https://github.com/gregberge/react-merge-refs/blob/main/src/index.tsx
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mergeRefs<T = any>(refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>): React.RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
}
