import { useMelioIntl } from '@melio/ar-domain';
import { Modal, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export type EditInvoiceExitModalProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  onApprove: VoidFunction;
};

export const EditInvoiceExitModal = forwardRef<EditInvoiceExitModalProps>(({ isOpen, onClose, onApprove }, ref) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      primaryButton={{
        variant: 'primary',
        label: formatMessage('ar.invoiceLifecycle.activities.editInvoice.exitModal.buttons.primary'),
        onClick: onApprove,
      }}
      secondaryButton={{
        variant: 'tertiary',
        label: formatMessage('ar.invoiceLifecycle.activities.editInvoice.exitModal.buttons.secondary'),
        onClick: onClose,
      }}
      data-testid="edit-invoice-exit-modal"
      data-component={EditInvoiceExitModal.displayName}
      header={formatMessage('ar.invoiceLifecycle.activities.editInvoice.exitModal.header')}
      ref={ref}
    >
      <Text>{formatMessage('ar.invoiceLifecycle.activities.editInvoice.exitModal.description')}</Text>
    </Modal>
  );
});

EditInvoiceExitModal.displayName = 'EditInvoiceExitModal';
