import { Table } from '@melio/penny';
import {
  DeliveryMethod,
  DeliveryMethodByPayor,
  DeliveryMethodTypeOption,
  FundingSource,
  PaymentIntent,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { useGetDeliveryMethodWarning } from '../../PaymentIntentsTable.screen.utils';
import { getDropdownOptions, getHasAllPossibleTypes, useDeliveryMethodOptions } from './DeliveryMethodSelectCell.utils';

type DeliveryMethodSelectCellProps = {
  paymentIntent: PaymentIntent;
  deliveryMethods: DeliveryMethodByPayor[];
  deliveryMethodTypeOptions: DeliveryMethodTypeOption[];
  selectedId?: DeliveryMethod['id'];
  selectedFundingSource?: FundingSource;
  onSelect: (selectedId: DeliveryMethod['id']) => void;
  onAddDeliveryMethodClick: VoidFunction;
  shouldDisplayStatus?: boolean;
};

export const DeliveryMethodSelectCell = forwardRef<DeliveryMethodSelectCellProps, 'div'>(
  (
    {
      paymentIntent,
      selectedFundingSource,
      deliveryMethods,
      deliveryMethodTypeOptions,
      selectedId,
      onSelect,
      onAddDeliveryMethodClick,
      shouldDisplayStatus,
      ...props
    },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();

    const { getOptionToDisplayOnSelectLabel, getOptionLabel, getOptionIconKey } = useDeliveryMethodOptions();

    const { warningInfo } = useGetDeliveryMethodWarning({
      paymentIntent,
      shouldDisplayStatus,
      selectedDeliveryMethodId: selectedId,
    });

    const options = getDropdownOptions(deliveryMethods, deliveryMethodTypeOptions, selectedId).map(
      (deliveryMethod) => ({
        label: getOptionLabel(deliveryMethod),
        value: deliveryMethod.id,
        icon: getOptionIconKey(deliveryMethod),
      })
    );

    const optionsToDisplayOnSelect = deliveryMethods.map((deliveryMethod) => ({
      label: getOptionToDisplayOnSelectLabel(deliveryMethod),
      value: deliveryMethod.id,
    }));

    const footerAction = {
      label: formatMessage(`activities.batchPayments.screens.paymentIntentsTable.deliveryMethodCell.addAnotherMethod`),
      onClick: onAddDeliveryMethodClick,
      dataTestId: 'add-another-delivery-method',
    };

    const addButton = {
      text: formatMessage(`activities.batchPayments.screens.paymentIntentsTable.deliveryMethodCell.addMethod`),
      onClick: onAddDeliveryMethodClick,
      dataTestId: 'add-another-delivery-method',
    };

    return (
      <Table.SelectCell
        data-testid="delivery-method-select-cell"
        {...props}
        placeholder={formatMessage(
          'activities.batchPayments.screens.paymentIntentsTable.deliveryMethodCell.placeholder'
        )}
        ref={ref}
        options={options}
        optionsToDisplayOnSelect={optionsToDisplayOnSelect}
        value={selectedId}
        onSelect={onSelect}
        {...(!getHasAllPossibleTypes(deliveryMethods) && { footerAction })}
        addButton={addButton}
        isInvalid={warningInfo.shouldShowWarning}
        tooltipProps={warningInfo.tooltipProps}
        popoverProps={warningInfo.popoverProps}
      />
    );
  }
);

DeliveryMethodSelectCell.displayName = 'DeliveryMethodSelectCell';
