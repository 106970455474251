import { AccountAddressParams, ApplyForFinancingRequest } from '@melio/platform-api';

import { FinancingApplicationBusinessDetailsForm, FinancingApplicationOwnerDetailsForm } from './types';

const formatSSNToDigits = (ssn: string) => ssn.replaceAll(/\W/g, '');
export const applicationFormValuesToApplicationCreateParams = ({
  businessDetails,
  ownershipDetails,
}: {
  businessDetails: FinancingApplicationBusinessDetailsForm;
  ownershipDetails: FinancingApplicationOwnerDetailsForm;
}): ApplyForFinancingRequest => ({
  ownerDetails: {
    firstName: ownershipDetails.firstName,
    lastName: ownershipDetails.lastName,
    dateOfBirth: ownershipDetails.dateOfBirth.toISOString(),
    ssn: formatSSNToDigits(ownershipDetails.ssn),
    address: ownershipDetails.address as AccountAddressParams,
    email: ownershipDetails.email,
    phoneNumber: ownershipDetails.phone,
    jobTitle: ownershipDetails.jobTitle,
  },
  beneficialOwners: ownershipDetails.additionalOwners.map((beneficialOwner) => ({
    firstName: beneficialOwner.firstName,
    lastName: beneficialOwner.lastName,
    dateOfBirth: beneficialOwner.dateOfBirth.toISOString(),
    ssn: formatSSNToDigits(beneficialOwner.ssn),
    email: beneficialOwner.email,
    ownershipPercentage: parseInt(beneficialOwner.ownershipPercentage, 10),
    address: beneficialOwner.address as AccountAddressParams,
  })),
  businessDetails: {
    companyName: businessDetails.companyName,
    legalName: businessDetails.legalBusinessName,
    legalAddress: businessDetails.legalBusinessAddress as AccountAddressParams,
    phoneNumber: businessDetails.ownerPhone,
    annualRevenue: parseInt(businessDetails.annualRevenue, 10),
    businessType: businessDetails.businessType,
    industry: businessDetails.businessIndustry,
    taxId: businessDetails.employerId ? formatSSNToDigits(businessDetails.employerId) : undefined,
  },
});
