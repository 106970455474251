import { InboxItem } from '@melio/javascript-sdk';

import { convertBillCentsToDollars } from '../../entities-v2/bill/utils';
import { convertPaymentCentsToDollars } from '../../entities-v2/payment/utils';
import { convertScannedInvoiceCentsToDollars } from '../../entities-v2/scanned-invoice/utils';
import { convertPaymentRequestCentsToDollars } from '../payment-request/utils';

export const convertInboxItemCentsToDollars = (inboxItem: InboxItem): InboxItem => {
  switch (inboxItem.type) {
    case 'bill':
      return {
        ...inboxItem,
        payload: convertBillCentsToDollars(inboxItem.payload),
      };
    case 'paymentRequest':
      return {
        ...inboxItem,
        payload: convertPaymentRequestCentsToDollars(inboxItem.payload),
      };
    case 'payment':
      return {
        ...inboxItem,
        payload: convertPaymentCentsToDollars(inboxItem.payload),
      };
    case 'scannedInvoice':
      return {
        ...inboxItem,
        payload: convertScannedInvoiceCentsToDollars(inboxItem.payload),
      };
    default:
      return inboxItem;
  }
};
