import { Navigate } from 'react-router-dom';
import { useFundingSource } from '@melio/platform-api';
import { BankAccount, FundingSourceType } from '@melio/platform-api';

import { WithLoading } from '@/hoc/withLoading.hoc';
import { useRouter } from '@/hooks/router.hooks';
import { ViewManualBankAccountScreen } from '@/screens/ViewManualBankAccount.screen';

export const ViewManualBankAccountRoute = ({ id }: { id: string }) => {
  const { goBack } = useRouter();
  const { isLoading, error, data } = useFundingSource({ id, params: { shouldDecrypt: true } });

  if (error || (!isLoading && data?.type !== FundingSourceType.BankAccount)) {
    return <Navigate to="/settings/payment-methods" />;
  }
  const bankAccount = data?.details as BankAccount;

  return (
    <WithLoading isLoading={isLoading}>
      <ViewManualBankAccountScreen
        onClose={goBack}
        onDone={goBack}
        defaultValues={{
          accountNumber: bankAccount?.accountNumber,
          routingNumber: bankAccount?.routingNumber,
        }}
      />
    </WithLoading>
  );
};

ViewManualBankAccountRoute.displayName = 'AddBankAccountManuallyScreen';
