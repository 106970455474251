import { isNil, omitBy } from 'lodash';

import { PaymentFlowFormFields } from '../types';

export const usePaymentFlowDefaultValues = ({
  defaultValues,
}: {
  defaultValues: Partial<PaymentFlowFormFields>;
}): PaymentFlowFormFields => {
  const existingDefaults = omitBy(defaultValues, isNil);
  return {
    vendorEmail: defaultValues.vendorEmail || '',
    vendorId: defaultValues.vendorId || '',
    fundingSourceId: defaultValues.fundingSourceId,
    deliveryMethodId: undefined,
    deliveryPreference: undefined,
    paymentPurpose: undefined,
    recurrenceType: defaultValues.recurrenceType || 'one_time',
    ...existingDefaults,
  };
};
