import { useAnalyticsContext } from '@melio/platform-analytics';

export const usePayDashboardAnalytics = ({
  newBillsTabItems,
  newFailedPayments,
  newApprovalsTabItems,
  shouldShowApprovalsTab,
}: {
  newBillsTabItems: number;
  newFailedPayments: number;
  newApprovalsTabItems: number;
  shouldShowApprovalsTab: boolean;
}) => {
  const approvalsData = shouldShowApprovalsTab ? { approvals: newApprovalsTabItems } : {};

  useAnalyticsContext({
    globalProperties: { NewItems: { bills: newBillsTabItems, failedPayments: newFailedPayments, ...approvalsData } },
  });
};
