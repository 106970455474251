import { Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { FeeItemProps, forwardRef } from '@melio/platform-utils';

export const FeeItem = forwardRef<FeeItemProps, 'div'>(({ type, amount, description, helperText, ...props }, ref) => {
  const { formatCurrency } = useMelioIntl();

  return (
    <Group justifyContent="space-between" {...props} ref={ref}>
      <Group variant="vertical" spacing="xxs">
        <Text data-testid={`review-and-confirm-fee-description-${type}`}>{description}</Text>
        {helperText && (
          <Text textStyle="body4" color="global.neutral.900" data-testid={`review-and-confirm-fee-helper-text-${type}`}>
            {helperText}
          </Text>
        )}
      </Group>
      <Text data-testid={`review-and-confirm-fee-amount-${type}`}>{formatCurrency(amount)}</Text>
    </Group>
  );
});

FeeItem.displayName = 'FeeItem';
