import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

const getPhoneUtilInstance = () => PhoneNumberUtil.getInstance();

export const formatDomesticPhoneNumberToIntl = (phoneNum: string) => {
  const parsedPhoneNumber = getPhoneUtilInstance().parseAndKeepRawInput(phoneNum, 'US');
  return getPhoneUtilInstance().format(parsedPhoneNumber, PhoneNumberFormat.E164);
};

export const getNationalNumberFromNumber = (phoneNum: string) => {
  const parsedPhoneNumber = getPhoneUtilInstance().parseAndKeepRawInput(phoneNum);
  return parsedPhoneNumber.getNationalNumber()?.toString() ?? '';
};

export const isValidInternationalPhoneNumber = (phoneNumber?: string) => {
  if (!phoneNumber) {
    return false;
  }

  try {
    const parsedPhoneNumber = getPhoneUtilInstance().parseAndKeepRawInput(phoneNumber);
    const res = getPhoneUtilInstance().isValidNumber(parsedPhoneNumber);
    return res;
  } catch (error) {
    return false;
  }
};

export const getRegionCodeFromPhoneNumber = (phoneNum: string) => {
  const parsedNum = getPhoneUtilInstance().parseAndKeepRawInput(phoneNum);
  return getPhoneUtilInstance().getRegionCodeForNumber(parsedNum);
};
