import { UseMelioFormResults } from '@melio/penny';
import React, { useContext } from 'react';

import { NewFirmClientFormFields } from '../../types';

export const NewFirmClientFormFieldsFormContext =
  React.createContext<UseMelioFormResults<NewFirmClientFormFields> | null>(null);

export const useNewFirmClientFormFieldsFormContext = () => {
  const form = useContext(NewFirmClientFormFieldsFormContext);
  if (!form) {
    throw new Error('FormContext is not provided');
  }
  return form;
};
