/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@chakra-ui/react';
import { Form, Group, Link, Modal, useFormSubmissionController, useMelioForm } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useDemoRequests } from '@melio/platform-api';
import { FormattedMessage, useMelioIntl } from '@melio/platform-i18n';
import { EMAIL_REGEX, forwardRef } from '@melio/platform-utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { object, SchemaOf, string } from 'yup';

import { DemoRequestFormFields, SCHEDULE_EVENING, SCHEDULE_MORNING } from './types';

export type DemoRequestFormWidgetProps = {
  organizationId: number | string;
  email: string;
  isOpen: boolean;
  onDone: (phone?: string) => void;
  onClose: VoidFunction;
  helpCenterLink: string;
  pageEntryPoint?: string;
};

export const DemoRequestFormWidget = forwardRef<DemoRequestFormWidgetProps, 'form'>(
  ({ organizationId, email, isOpen, onDone, onClose, helpCenterLink, pageEntryPoint }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { track } = useAnalytics();
    const [isSigning, setIsSigning] = useState<boolean>(false);
    const { create: createDemoRequest, isLoading: isLoadingDemoRequests } = useDemoRequests();

    useEffect(() => {
      track('Demo', 'Viewed', {
        pageName: 'want-a-walk-through?',
        intent: 'add-details-for-demo',
        cta: 'Submit request',
        pageEntryPoint,
      });
    }, []);

    const onSubmit = useCallback(
      async (data: DemoRequestFormFields) => {
        try {
          if (organizationId && createDemoRequest) {
            setIsSigning(true);
            try {
              await createDemoRequest({
                orgId: `org_${organizationId}`,
                email: watch('email'),
                phone: watch('phone'),
                schedule: watch('schedule'),
              });
            } catch (e) {
              if (Number((e as PlatformError).code) !== 409) {
                throw e;
              }
            }
          }
          track('Demo', 'Clicked', {
            pageName: 'want-a-walk-through?',
            intent: 'schedule-a-demo',
            cta: 'Submit request',
            pageEntryPoint,
            form_details: {
              is_email_Address_filled: !!data.email,
              is_phone_number_filled: !!data.phone,
              when_can_we_reach_out: data.schedule,
            },
          });
        } finally {
          setIsSigning(false);
        }
        onDone(watch('phone'));
      },
      [organizationId, createDemoRequest]
    );

    const demoRequestFormSchema = () =>
      object().shape({
        email: string()
          .required(formatMessage('widgets.demoRequest.formModal.form.validation.missingEmail'))
          .trim()
          .test(
            'validAddress',
            formatMessage('widgets.demoRequest.formModal.form.validation.badEmailFormat'),
            (email?: string | null) => (email ? EMAIL_REGEX.test(email) : false)
          ),
        phone: string()
          .trim()
          .optional()
          .transform((value: string) => value || null) // used for length validation
          .length(10, formatMessage('widgets.demoRequest.formModal.form.validation.badPhoneFormat'))
          .nullable(),
        schedule: string().required(),
      }) as SchemaOf<DemoRequestFormFields>;

    const defaultValues = useMemo(
      () => ({
        phone: '',
        email,
        schedule: SCHEDULE_MORNING,
      }),
      [email]
    );

    const { onSubmissionStateChange, submitButtonProps } = useFormSubmissionController<DemoRequestFormFields>();
    const { formProps, registerField, watch } = useMelioForm<DemoRequestFormFields>({
      onSubmit,
      schema: demoRequestFormSchema(),
      defaultValues,
      onSubmissionStateChange,
    });

    const footer = (
      <Box textStyle="body3" textAlign="center">
        <FormattedMessage
          id="widgets.demoRequest.formModal.footer.text"
          values={{
            helpCenter: (
              <Link
                variant="inline"
                href={helpCenterLink}
                label={formatMessage('widgets.demoRequest.formModal.footer.helpCenter')}
                newTab
              />
            ),
          }}
        />
      </Box>
    );

    return (
      <Modal
        header={formatMessage('widgets.demoRequest.formModal.header')}
        primaryButton={{
          label: formatMessage('widgets.demoRequest.formModal.button'),
          onClick: submitButtonProps?.onClick,
          variant: 'primary',
          isLoading: isLoadingDemoRequests || isSigning,
        }}
        isOpen={isOpen}
        onClose={onClose}
        data-testid="demo-request-modal"
        footer={footer}
      >
        <Group variant="vertical">
          {formatMessage('widgets.demoRequest.formModal.description')}
          <Form
            ref={ref}
            isDisabled={isLoadingDemoRequests || isSigning}
            columns={2}
            data-component="DemoRequestFormWidget"
            {...formProps}
          >
            <Form.TextField
              labelProps={{ label: formatMessage('widgets.demoRequest.formModal.form.emailLabel') }}
              placeholder={formatMessage('widgets.demoRequest.formModal.form.emailPlaceholder')}
              isRequired
              size="small"
              data-testid="form-demo-request-email"
              {...registerField('email')}
            />
            <Form.PhoneField
              labelProps={{ label: formatMessage('widgets.demoRequest.formModal.form.phoneLabel') }}
              placeholder={formatMessage('widgets.demoRequest.formModal.form.phonePlaceholder')}
              helperTextProps={{ label: formatMessage('widgets.demoRequest.formModal.form.phoneHelperText') }}
              {...registerField('phone')}
              data-testid="form-demo-request-phone"
              size="small"
            />
            {watch('phone') && (
              <Form.SelectNew
                colSpan={2}
                shouldHideClearButton
                labelProps={{ label: formatMessage('widgets.demoRequest.formModal.form.scheduleLabel') }}
                {...registerField('schedule')}
                size="small"
                options={[
                  {
                    label: formatMessage('widgets.demoRequest.formModal.form.scheduleMorning'),
                    value: SCHEDULE_MORNING,
                  },
                  {
                    label: formatMessage('widgets.demoRequest.formModal.form.scheduleEvening'),
                    value: SCHEDULE_EVENING,
                  },
                ]}
                data-testid="form-demo-request-schedule"
              />
            )}
          </Form>
        </Group>
      </Modal>
    );
  }
);
DemoRequestFormWidget.displayName = 'DemoRequestFormWidget';
