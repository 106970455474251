import { Form, useMelioForm } from '@melio/penny';
import { Address, US_STATES } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { defaults } from 'lodash';

import { FormWidgetProps } from '../../../../types';
import { AddressSearchWidget, AddressSearchWidgetProps } from '../../../form-controls';
import { CheckAccountFormModel } from '../types';
import { useCheckValidationSchema } from '../useCheckValidationSchema';

export type VendorCheckDetailsPayorFormProps = FormWidgetProps<CheckAccountFormModel>;
export const VendorCheckDetailsPayorForm: React.FC<VendorCheckDetailsPayorFormProps> = forwardRef<
  VendorCheckDetailsPayorFormProps,
  'form'
>(({ onSubmit, defaultValues: _defaultValues, isSaving, onSubmissionStateChange, ...props }, ref) => {
  const { formatMessage } = useMelioIntl();
  const defaultValues = defaults(_defaultValues, {
    printName: '',
    line1: null,
    line2: '',
    city: '',
    state: '',
    postalCode: '',
  });

  const { formProps, registerField, setValue, getValues } = useMelioForm<CheckAccountFormModel>({
    onSubmit,
    schema: useCheckValidationSchema(),
    defaultValues,
    isSaving,
    onSubmissionStateChange,
    subscribeToDefaultValuesChanges: true,
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - types will change in penny
  const handleAddressChange: AddressSearchWidgetProps['onChange'] = (event) => {
    const address = event.target.value as unknown as Address;
    const isFormValueCleared = address === null && getValues('line1');

    if (isFormValueCleared) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - if we pass '' search works properly only after 2nd search input
      setValue('line1', null);
    }

    const setFormField = (field: keyof Omit<CheckAccountFormModel, 'printName'>) => {
      if (address?.[field]) {
        setValue(field, address[field], {
          shouldValidate: true,
        });
      }
    };

    setFormField('line1');
    setFormField('line2');
    setFormField('state');
    setFormField('city');
    setFormField('postalCode');
  };

  return (
    <Form data-component="VendorCheckDetailsPayorForm" {...props} {...formProps} columns={2} ref={ref}>
      <Form.TextField
        colSpan={2}
        labelProps={{ label: formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayorForm.printName.label') }}
        placeholder={formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayorForm.printName.placeholder')}
        helperTextProps={{
          label: formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayorForm.printName.helperText'),
        }}
        {...registerField('printName')}
      />
      <AddressSearchWidget
        {...registerField('line1')}
        colSpan={2}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - Type of formatSelectedValue will be fixed & data can be string | undefiend | Address
        formatSelectedValue={(data): Address | string => (data.value as unknown as Address)?.line1 ?? data.value}
        labelProps={{ label: formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayorForm.line1.label') }}
        placeholder={formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayorForm.line1.placeholder')}
        onChange={handleAddressChange}
      />
      <Form.TextField
        colSpan={2}
        labelProps={{ label: formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayorForm.line2.label') }}
        placeholder={formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayorForm.line2.placeholder')}
        {...registerField('line2')}
      />
      <Form.TextField
        colSpan={2}
        labelProps={{ label: formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayorForm.city.label') }}
        placeholder={formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayorForm.city.placeholder')}
        {...registerField('city')}
      />
      <Form.Select
        labelProps={{ label: formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayorForm.state.label') }}
        placeholder={formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayorForm.state.placeholder')}
        {...registerField('state')}
        options={US_STATES.map((state) => ({
          value: state,
          label: formatMessage(`local.USA.states.${state}`),
          testId: state,
        }))}
        emptyState={{ label: formatMessage('form.select.default.emptyState.label') }}
      />
      <Form.TextField
        labelProps={{ label: formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayorForm.postalCode.label') }}
        placeholder={formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayorForm.postalCode.placeholder')}
        {...registerField('postalCode')}
      />
    </Form>
  );
});

VendorCheckDetailsPayorForm.displayName = 'VendorCheckDetailsPayorForm';
