import { calculateRecurringEndDateByNumOfOccurrences, getNumberOfOccurrences } from '@melio/ap-domain';
import { useAnalytics } from '@melio/platform-analytics';
import {
  ApiError,
  PaymentFee,
  useCheckApprovalRequirement,
  useOrgBillingFeeSettings,
  usePaymentSettings,
} from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { converDateToStringRepresentation, getDollarsFromCents } from '@melio/platform-utils';
import React from 'react';

import { useInternationalCountryDisplayName } from '../../../utils/pay-flow/deliveryMethods';
import { NewSinglePaymentStepLayout } from '../../NewSinglePaymentStepLayout';
import { ReviewAndConfirmScreen } from '../ReviewAndConfirm/screens';
import { usePaymentFeesBreakdown } from '../ReviewAndConfirm/utils';
import { RecurringReviewAndConfirmActivityProps } from './types';

export const RecurringReviewAndConfirmActivity: React.VFC<RecurringReviewAndConfirmActivityProps> = ({
  step,
  totalSteps,
  amount,
  fundingSource,
  deliveryMethod,
  memoToVendor,
  invoiceNumber,
  vendor,
  startDate,
  recurringPayment,
  onEditFundingSource,
  onEditDate,
  onEditDeliveryMethod,
  onEditMemoToVendor,
  onBack,
  onClose,
  onDone: _onDone,
  onError,
  updatedFields,
  isLoadingButton,
}) => {
  const [isBillingFeeSettingsEnabled] = useFeature(FeatureFlags.BillingFees, false);
  const { track } = useAnalytics();

  const { numOfOccurrences, endDate, paymentFrequency } = recurringPayment;

  const occurrencesCount = getNumberOfOccurrences({ paymentFrequency, numOfOccurrences, startDate, endDate });

  const firstPaymentSettingsParams = {
    amountToPay: amount,
    vendorId: vendor.id,
    fundingSourceId: fundingSource.id,
    deliveryMethodId: deliveryMethod.id,
    dueDate: converDateToStringRepresentation(startDate),
    invoicesData: [
      {
        amount,
        invoiceNumber: invoiceNumber || null,
      },
    ],
  };

  const endDateByNumOfOccurrences = calculateRecurringEndDateByNumOfOccurrences({
    paymentFrequency,
    numOfOccurrences: occurrencesCount,
    startDate,
  });

  const paymentSettingsParams =
    occurrencesCount === 1 || !endDateByNumOfOccurrences
      ? [firstPaymentSettingsParams]
      : [
          firstPaymentSettingsParams,
          {
            ...firstPaymentSettingsParams,
            amountToPay: recurringPayment.lastAmount || amount,
            // last payment due date
            dueDate: converDateToStringRepresentation(endDateByNumOfOccurrences),
          },
        ];

  const {
    data: paymentSettings,
    isLoading,
    error: paymentSettingsError,
  } = usePaymentSettings({ payload: paymentSettingsParams });

  const fees = paymentSettings?.map((payment) =>
    payment?.estimatedFees?.map((feeArr: PaymentFee) => ({
      ...feeArr,
      amount: getDollarsFromCents(feeArr.amount),
    }))
  );

  const firstPaymentDeliveryPreference = paymentSettings?.[0]?.deliveryPreferenceOptions?.[0];
  const lastPaymentDeliveryPreference = paymentSettings?.[paymentSettings?.length - 1]?.deliveryPreferenceOptions?.[0];

  const {
    paymentFees,
    isLoading: isFeesLoading,
    error: feesBreakdownError,
  } = usePaymentFeesBreakdown(
    {
      fundingSource,
      deliveryMethod,
      paymentAmount: amount,
      deliveryPreference: firstPaymentDeliveryPreference,
      isFinancing: false,
    },
    fees?.[0]
  );

  const lastAmountFees = usePaymentFeesBreakdown(
    {
      fundingSource,
      deliveryMethod,
      paymentAmount: recurringPayment.lastAmount,
      deliveryPreference: lastPaymentDeliveryPreference,
      isFinancing: false,
    },
    fees?.[1]
  );

  React.useEffect(() => {
    const error: ApiError<string> | null | undefined =
      paymentSettingsError || feesBreakdownError || lastAmountFees.error;
    if (error) {
      onError(error);
      onBack();
    }
  }, [paymentSettingsError, feesBreakdownError, lastAmountFees.error, onError, onBack]);

  const { data: approvalRequirement, isLoading: isApprovalRequirementLoading } = useCheckApprovalRequirement({
    amount,
    vendorId: vendor.id,
  });

  const { data: orgBillingFeeSettings = [], isLoading: isBillingFeeSettingsLoading } = useOrgBillingFeeSettings({
    enabled: isBillingFeeSettingsEnabled,
  });

  const { countryDisplayName, isCountriesDetailsLoading } = useInternationalCountryDisplayName(deliveryMethod);

  const onDone = () => {
    track('Payment', 'Click', {
      IsSpecialDelivery: false,
    });
    _onDone();
  };

  const firstPaymentDeliveryDate = firstPaymentDeliveryPreference?.minDeliveryDate;
  const lastPaymentDeliveryDate = occurrencesCount ? lastPaymentDeliveryPreference?.minDeliveryDate : undefined;

  const recurringPaymentFullDetails = {
    ...recurringPayment,
    numOfOccurrences: occurrencesCount,
    startDate,
    endDate,
    firstPaymentDeliveryDate,
    lastPaymentDeliveryDate,
    lastAmountFees: lastAmountFees.paymentFees,
  };

  const firstPaymentEffectiveScheduledDate = firstPaymentDeliveryPreference?.effectiveScheduleDate;

  return isLoading ||
    !firstPaymentEffectiveScheduledDate ||
    isFeesLoading ||
    lastAmountFees.isLoading ||
    isCountriesDetailsLoading ||
    isApprovalRequirementLoading ||
    isBillingFeeSettingsLoading ? (
    <NewSinglePaymentStepLayout isLoading />
  ) : (
    <ReviewAndConfirmScreen
      step={step}
      totalSteps={totalSteps}
      amount={amount}
      fundingSource={fundingSource}
      deliveryMethod={deliveryMethod}
      memoToVendor={memoToVendor}
      invoiceNumbers={[invoiceNumber]}
      vendorName={vendor.name}
      paymentFees={paymentFees}
      orgBillingFeeSettings={orgBillingFeeSettings}
      scheduledDate={firstPaymentEffectiveScheduledDate}
      deliveryPreference={firstPaymentDeliveryPreference}
      recurringPayment={recurringPaymentFullDetails}
      onEditFundingSource={onEditFundingSource}
      onEditDate={onEditDate}
      onEditDeliveryMethod={onEditDeliveryMethod}
      onEditMemoToVendor={onEditMemoToVendor}
      onBack={onBack}
      onClose={onClose}
      onDone={onDone}
      updatedFields={updatedFields}
      isLoadingButton={isLoadingButton}
      enableLateTag={false}
      countryDisplayName={countryDisplayName}
      approvalRequirementStatus={approvalRequirement?.approvalRequirementStatus}
      vendorId={vendor.id}
      isQuoteFetching={false}
    />
  );
};

RecurringReviewAndConfirmActivity.displayName = 'RecurringReviewAndConfirmActivity';
