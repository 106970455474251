import React from 'react';
import { useSetRecoilState } from 'recoil';
import { PaymentRequestDetailsWidget } from '@melio/ap-widgets';
import { Button, Container, Group } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { PaymentRequest, useInboxItems, usePaymentRequest } from '@melio/platform-api';
import { forwardRef, useSystemMessage } from '@melio/platform-utils';

import { Loader } from '@/cl/components/Loader/Loader.component';
import { useRouter } from '@/hooks/router.hooks';
import { payDashboardSelectedCardSelector } from '@/store/PayDashboard/PayDashboards.model';
import { usePlatformIntl } from '@/translations/Intl';
import { PayDashboardTabs } from '@/types/payDashboard.types';
import { PayDetailsTop } from '@/widgets/pay-dashboard/pay-details-screen-top/PayDetailsTop.component';
import { PaymentRequestRejectModal } from '@/widgets/pay-dashboard/payment-request-details/PaymentRequestRejectModal.widget';

type PaymentRequestDetailsScreenProps = {
  paymentRequestId: PaymentRequest['id'];
};

export const PaymentRequestDetailsScreen = forwardRef<PaymentRequestDetailsScreenProps>((props, ref) => {
  const { paymentRequestId } = props;
  const { track } = useAnalytics();
  const { showMessage } = useSystemMessage();
  const [isRejectModalOpen, setIsRejectModalOpen] = React.useState(false);
  const { replaceToPayDashboardTab, navigateToSchedulePayment } = useRouter();
  const { formatMessage } = usePlatformIntl();
  const { data: paymentRequest, isLoading, approve, isUpdating } = usePaymentRequest({ id: paymentRequestId });
  const setSelectedCard = useSetRecoilState(payDashboardSelectedCardSelector);
  const { refetch: refetchInboxItems } = useInboxItems({ enabled: false });

  if (isLoading || !paymentRequest) {
    return <Loader isAbsoluteCenter />;
  }

  const { invoiceNumber, vendor } = paymentRequest;

  const subTitle = formatMessage('screens.paymentRequestDetails.invoiceNumber', { invoiceNumber });

  const handleRejectSuccess = () => {
    refetchInboxItems();
    replaceToPayDashboardTab(PayDashboardTabs.Inbox, undefined, { keepToast: true });
  };

  const approveAndSchedulePayment = async () => {
    track('PayDashboardSchedulePayment', 'chose', {
      paymentRequestId,
    });
    try {
      const approvedPaymentRequest = await approve();
      if (approvedPaymentRequest.billId) {
        navigateToSchedulePayment({ billId: approvedPaymentRequest.billId });
      }
    } catch (e) {
      console.error(e);
      showMessage({ type: 'error', title: formatMessage('widgets.paymentRequestDetails.approveButton.failureToast') });
    }
  };

  return (
    <Container data-testid="pay-dashboard-payment-request">
      <Group variant="vertical" spacing="l">
        <PayDetailsTop vendorName={vendor?.name || ''} subTitle={subTitle} onBackClick={() => setSelectedCard({})}>
          <Button
            onClick={() => setIsRejectModalOpen(true)}
            variant="tertiary"
            data-testid="reject-button"
            label={formatMessage('screens.paymentRequestDetails.reject')}
          />
          <Button
            onClick={approveAndSchedulePayment}
            isLoading={isUpdating}
            variant="success"
            data-testid="approve-and-schedule-payment-button"
            label={formatMessage('screens.paymentRequestDetails.schedulePayment')}
          />
        </PayDetailsTop>
        <Container ref={ref} border="regular">
          <PaymentRequestDetailsWidget showOpenBalance paymentRequest={paymentRequest} />
        </Container>
      </Group>
      <PaymentRequestRejectModal
        isOpen={isRejectModalOpen}
        onClose={() => setIsRejectModalOpen(false)}
        onSuccess={handleRejectSuccess}
        paymentRequestId={paymentRequestId}
      />
    </Container>
  );
});
