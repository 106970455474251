import { Route, Routes } from 'react-router-dom';

import { SubscriptionTabsEnum, TAB_PREFIX } from '../constants';
import { AccountingClientSubscriptionPlanSelectedActivity } from './AccountingClientSubscriptionPlanSelected';
import { GraceEndPeriodForExternalAccountant } from './GraceEndPeriodForExternalAccountant';
import { GraceEndPeriodNonOwner } from './GraceEndPeriodNonOwner';
import { GraceEndPeriodOwner } from './GraceEndPeriodOwner';
import { SubscriptionSelectFundingSource } from './SubscriptionPaymentMethodSelect/SubscriptionSelectFundingSource.activity';
import { SubscriptionSuccessActivity } from './SubscriptionSuccess';
import { SubscriptionWizardActivity } from './SubscriptionWizard';

export const SubscriptionPlansRoute = () => (
  <>
    <Route path={`/${TAB_PREFIX}/*`} element={<SubscriptionWizardActivity />} />
    <Route path={`/${TAB_PREFIX}/${SubscriptionTabsEnum.Success}/*`} element={<SubscriptionSuccessActivity />} />
    <Route
      path={`/${TAB_PREFIX}/${SubscriptionTabsEnum.PaymentMethodSelect}`}
      element={<SubscriptionSelectFundingSource />}
    />
  </>
);

export const SubscriptionGraceRoutes = ({
  goToAssignClientPlan,
  switchOrganization,
}: {
  goToAssignClientPlan: ({ accountingClientId }: { accountingClientId: string }) => void;
  switchOrganization: ({
    organizationId,
    skipPayDashboardRedirect,
  }: {
    organizationId: string;
    skipPayDashboardRedirect?: boolean | undefined;
  }) => Promise<void>;
}) => (
  <Routes>
    <Route path="/select-your-plan" element={<GraceEndPeriodOwner />} />
    <Route path="/plan-is-required" element={<GraceEndPeriodNonOwner />} />
    <Route
      path="/select-client-plan"
      element={
        <GraceEndPeriodForExternalAccountant
          goToAssignClientPlan={goToAssignClientPlan}
          onSwitchOrganization={(organizationId: string) =>
            switchOrganization({ organizationId, skipPayDashboardRedirect: true })
          }
        />
      }
    />
  </Routes>
);

export const AccountingClientSubscriptionRoutes = () => (
  <>
    <Route
      path={`/${TAB_PREFIX}/accounting-client-plan-selected`}
      element={<AccountingClientSubscriptionPlanSelectedActivity />}
    />
  </>
);
