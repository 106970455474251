import { useBreakpoint } from '@melio/penny';

import { useFiltersNewFailedPayments } from '../../hooks/useGetNewFailedPayments';
import { PaymentChipFilter, PaymentsTabFilter } from './types';
import { useFailedPayments } from './useFailedPayments';

export const usePaymentFilters = () => {
  const { data: failedPayments = [] } = useFailedPayments();
  const { newPayments: newFailedPayments } = useFiltersNewFailedPayments();
  const { isExtraSmallScreen } = useBreakpoint();

  const allFilter: PaymentChipFilter = {
    id: PaymentsTabFilter.All,
    label: 'activities.payDashboard.paymentsTab.filters.all',
  };
  const scheduledFilter: PaymentChipFilter = {
    id: PaymentsTabFilter.Scheduled,
    label: 'activities.payDashboard.paymentsTab.filters.scheduled',
  };
  const inProgressFilter: PaymentChipFilter = {
    id: PaymentsTabFilter.InProgress,
    label: 'activities.payDashboard.paymentsTab.filters.inProgress',
  };
  const paidFilter: PaymentChipFilter = {
    id: PaymentsTabFilter.Paid,
    label: 'activities.payDashboard.paymentsTab.filters.paid',
  };
  const failedFilter: PaymentChipFilter = {
    id: PaymentsTabFilter.Failed,
    label: 'activities.payDashboard.paymentsTab.filters.failed',
    status: newFailedPayments.length > 0 ? 'critical' : undefined,
  };

  const filterOptions = isExtraSmallScreen
    ? [allFilter, failedFilter, scheduledFilter, inProgressFilter, paidFilter]
    : [allFilter, scheduledFilter, inProgressFilter, paidFilter, failedFilter];

  return failedPayments.length > 0
    ? [...filterOptions]
    : filterOptions.filter(({ id }) => id !== PaymentsTabFilter.Failed);
};
