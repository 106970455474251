import { Group, Modal, NakedButton, SectionBanner, Text } from '@melio/penny';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { useAccount } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePermissions } from '@melio/platform-permissions';
import { useDateUtils } from '@melio/platform-utils';
import { differenceInCalendarDays } from 'date-fns';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useSubscriptionGracePeriod } from '../../../hooks';
import { useSubscriptionRouter } from '../../utils';
import { CopyOwnerEmailToClipboradButton } from '../CopyOwnerEmailToClipboradButton';

type GracePeriodBannerDetailsProps = {
  onClose: () => void;
};
export const BannerDetails = withAnalyticsContext<GracePeriodBannerDetailsProps>(
  ({ onClose, setAnalyticsProperties }) => {
    const { gracePeriodEndDate, gracePeriodEndDateToDisplay } = useSubscriptionGracePeriod();
    const { formatMessage, formatDate } = useMelioIntl();
    const { createDate } = useDateUtils();
    const { goToSubscriptionPlans } = useSubscriptionRouter();
    const { pathname } = useLocation();
    const { track } = useAnalytics();
    const { data: dataAccountMe, isLoading: isAccountLoading } = useAccount({ id: 'me' });
    const [isNonOwnerModalOpen, setIsNonOwnerModalOpen] = useState(false);
    const { can } = usePermissions();

    const today = createDate();
    const remainingDaysToSubscribe = gracePeriodEndDate ? differenceInCalendarDays(gracePeriodEndDate, today) : -1;

    const ownerEmail = dataAccountMe?.organizationOwner?.email;
    const companyName = dataAccountMe?.company.name;

    const canUpdateSubscription = can({
      subject: 'subscriptions:me',
      action: 'update',
    });

    setAnalyticsProperties({
      PageName: 'limited-time-offer',
      Intent: 'push-user-to-subscribe',
      Discount: 35,
      DaysToOfferExpired: remainingDaysToSubscribe,
    });

    useAnalyticsView('Dashboard', true, true);

    const handleOnClaim = () => {
      track('Dashboard', 'Click', {
        Cta: 'claim-now',
      });

      if (canUpdateSubscription) {
        goToSubscriptionPlans({
          returnUrl: pathname,
          analyticsFlow: 'existing-user-transition',
          analyticsEntryPoint: 'dashboard-limited-time-offer',
        });
      } else {
        setIsNonOwnerModalOpen(true);
      }
    };

    if (remainingDaysToSubscribe <= 0 || isAccountLoading) {
      return null;
    }

    return (
      <>
        <SectionBanner
          description={formatMessage('activities.subscription.gracePeriodBanner.description', {
            gracePeriodEndDate: formatDate(gracePeriodEndDateToDisplay, { month: 'short', day: 'numeric' }),
            remainingNumberOfDays: remainingDaysToSubscribe,
          })}
          variant="brand"
          data-testid="subscription-grace-period-banner"
          data-role="alert"
          icon="crown"
          showCloseIcon
          size="large"
          action={
            <NakedButton
              label={formatMessage('activities.subscription.gracePeriodBanner.actionLabel')}
              onClick={handleOnClaim}
              data-testid="subscription-grace-period-banner-claim-button"
            />
          }
          isCompact
          onClose={onClose}
        />

        <Modal
          isOpen={isNonOwnerModalOpen}
          onClose={() => setIsNonOwnerModalOpen(false)}
          header={formatMessage('activities.subscription.gracePeriodBanner.nonOwnerModal.title')}
          data-testid="subscription-grace-period-banner-non-owner-modal"
          primaryButton={{
            label: formatMessage('activities.subscription.gracePeriodBanner.nonOwnerModal.closeButton'),
            onClick: () => setIsNonOwnerModalOpen(false),
            variant: 'primary',
          }}
        >
          <Group variant="vertical" spacing="m">
            <Text>
              {formatMessage('activities.subscription.gracePeriodBanner.nonOwnerModal.description', { companyName })}
            </Text>

            <Group variant="horizontal" spacing="xxs" alignItems="center">
              <CopyOwnerEmailToClipboradButton ownerEmail={ownerEmail} />
              <Text color="neutral.darker">{ownerEmail}</Text>
            </Group>
          </Group>
        </Modal>
      </>
    );
  }
);
