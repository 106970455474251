import { useMelioIntl } from '@melio/platform-i18n';

import { BrandColoredLink } from '../../BrandColoredLink/BrandColoredLink';
import { NewSinglePaymentStepLayout } from '../../NewSinglePaymentStepLayout';
import { ApplicationStatus } from '../types';

export const useApplicationApprovalContent = (
  status: ApplicationStatus,
  { onBack, amount }: { amount: number; onBack: VoidFunction }
) => {
  const { title, description } = useApplicationApprovalText(status, amount) || {};
  const illustration = useApplicationApprovalIllustration(status);
  const bottomLink = useApplicationApprovalBottomLink(status);
  const actions = useApplicationApprovalActions(onBack);

  return {
    title,
    description,
    illustration,
    bottomLink,
    actions,
  };
};

const useApplicationApprovalText = (
  status: ApplicationStatus,
  amount: number
): { title: string; description: string } | null => {
  const { formatMessage, formatCurrency } = useMelioIntl();

  switch (status) {
    case ApplicationStatus.Approved:
      return {
        title: formatMessage('activities.financingApplication.applicationStatusScreen.success.title', {
          amount: formatCurrency(amount, 'USD', {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }),
        }),
        description: formatMessage('activities.financingApplication.applicationStatusScreen.success.description'),
      };
    case ApplicationStatus.Pending:
      return {
        title: formatMessage('activities.financingApplication.applicationStatusScreen.pending.title'),
        description: formatMessage('activities.financingApplication.applicationStatusScreen.pending.description'),
      };
    case ApplicationStatus.Declined:
      return {
        title: formatMessage('activities.financingApplication.applicationStatusScreen.declined.title'),
        description: formatMessage('activities.financingApplication.applicationStatusScreen.declined.description'),
      };
    default:
      return null;
  }
};

const useApplicationApprovalIllustration = (status: ApplicationStatus): string | null => {
  switch (status) {
    case ApplicationStatus.Approved:
      return 'success';
    case ApplicationStatus.Pending:
      return 'pending';
    case ApplicationStatus.Declined:
      return 'declined';
    default:
      return null;
  }
};

const useApplicationApprovalBottomLink = (status: ApplicationStatus): string | null => {
  const { formatMessage } = useMelioIntl();

  switch (status) {
    case ApplicationStatus.Pending:
      return formatMessage('activities.financingApplication.applicationStatusScreen.pending.link', {
        link: (
          <BrandColoredLink
            href="https://melio.zendesk.com/hc/en-us/articles/6457841438108-Pay-over-time-with-installments-in-Melio"
            label={formatMessage('activities.financingApplication.applicationStatusScreen.pending.link.learnMore')}
          />
        ),
      });
    default:
      return null;
  }
};

const useApplicationApprovalActions = (onBack: VoidFunction) => {
  const { formatMessage } = useMelioIntl();

  return (
    <NewSinglePaymentStepLayout.NextButton
      data-testid="financing-application-approval-back-button"
      label={formatMessage('activities.financingApplication.applicationStatusScreen.backButton')}
      onClick={onBack}
    />
  );
};
