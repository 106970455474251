import { Route, Routes } from 'react-router-dom';

import { AccountingPlatformSuccessContainer } from '@/components/accounting-software/AccountingPlatformSuccessContainer';
import { AccountingSoftwareErrorContainer } from '@/components/accounting-software/AccountingSoftwareErrorContainer';
import { useActiveScreen } from '@/hooks/useActiveScreen';
import { AccountingPlatformAuthCallbackScreen } from '@/screens/accounting-platform-connect/AccountingPlatformAuthCallback.screen';
import { ScreensEnum } from '@/store/app/app.types';
export const AccountingSoftwareRoute = () => {
  useActiveScreen(ScreensEnum.accountingPlatform);
  return (
    <Routes>
      <Route path={'/auth/callback/*'} element={<AccountingPlatformAuthCallbackScreen />} />
      <Route path={'/error'} element={<AccountingSoftwareErrorContainer />} />
      <Route path={'/sync'} element={<AccountingPlatformSuccessContainer />} />
    </Routes>
  );
};
