/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useParams } from 'react-router-dom';
import { useAnalytics } from '@melio/platform-analytics';

import { PayDashboardTabs } from '@/types/payDashboard.types';

export const usePayDashboardTabView = (defaultTab: PayDashboardTabs) => {
  const { tab } = useParams<{ tab: PayDashboardTabs }>();
  const { track } = useAnalytics();
  React.useEffect(() => {
    let tabEventName = '';
    switch (tab || defaultTab) {
      case PayDashboardTabs.Inbox: {
        tabEventName = 'Inbox';
        break;
      }
      case PayDashboardTabs.Scheduled: {
        tabEventName = 'Scheduled';
        break;
      }
      case PayDashboardTabs.Paid: {
        tabEventName = 'Paid';
        break;
      }
    }

    track(`PayDashboardToggleBar${tabEventName}`, 'Viewed');
  }, [tab]);
};
