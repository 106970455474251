import { withAnalyticsContext } from '@melio/platform-analytics';
import { BillingFeeSettingBillingFeeTypeEnum, FundingSourceType, useOrgBillingFeeSettings } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage, useWizardSimple, UseWizardSimpleArgs } from '@melio/platform-utils';
import { useCallback, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import { NewSinglePaymentStepLayout } from '../../NewSinglePaymentStepLayout';
import { ReplaceBillingFeeSetting } from './components/ReplaceBillingFeeSettingModal';
import { SelectBillingMethodStep } from './steps/SelectBillingMethodStep';
import { SuccessBillingStep } from './steps/SuccessBillingStep';
import { Steps } from './types';
import { useBillingFeeData } from './useBillingFeeData';

type Props = {
  goToSettingsBilling: VoidFunction;
};

const firstStep: Steps = 'select';
const locationsMap: UseWizardSimpleArgs<Steps>['locationsMap'] = {
  select: 'select',
  success: 'success',
};
const restrictedFundingSourcesForCollectingBillingFees: FundingSourceType[] = [FundingSourceType.FlexAccount];

export const BillingFeeActivity = withAnalyticsContext<Props>(({ goToSettingsBilling, setAnalyticsProperties }) => {
  const { showMessage } = useSystemMessage();
  const { formatMessage } = useMelioIntl();

  const {
    firmId,
    userId,
    fundingSources,
    currentFundingSourceId,
    firmFundingSourceId,
    currentBillingFeeSetting,
    isUserPartOfFirm,
    isMethodAssignedByFirm,
    isLoading,
  } = useBillingFeeData();
  const allowedFundingSources = fundingSources.filter(
    (fundingSource) => !restrictedFundingSourcesForCollectingBillingFees.includes(fundingSource.type)
  );
  const {
    update: updateOrgBillingFeeSetting,
    create: createOrgBillingFeeSetting,
    isMutating,
  } = useOrgBillingFeeSettings();

  const [isReplaceModalOpen, setIsReplaceModalOpen] = useState<boolean>(false);

  const { goNext, cancelFlow, completeFlow } = useWizardSimple<Steps>({
    firstStep,
    flowName: 'add-billing-method',
    locationsMap,
    navigationSequence: ['select', 'success'],
    cancelUrlFallback: '/',
  });

  setAnalyticsProperties({ Intent: 'add-billing-method', EntryPoint: 'settings-page' });

  const completeCallback = useCallback(() => {
    completeFlow(goToSettingsBilling);
  }, [completeFlow, goToSettingsBilling]);

  const [selectedFundingSourceId, setSelectedFundingSourceId] = useState('');

  useEffect(() => {
    currentFundingSourceId && setSelectedFundingSourceId(currentFundingSourceId);
  }, [currentFundingSourceId]);

  const selectedFundingSource = allowedFundingSources.find((fs) => fs.id === selectedFundingSourceId);
  const isUserUnableToSeeFirmMethod = !isUserPartOfFirm && isMethodAssignedByFirm;

  const handleNextClick = async () => {
    const paymentMethodReplaced = Boolean(currentFundingSourceId && currentFundingSourceId !== selectedFundingSourceId);

    if (isUserUnableToSeeFirmMethod || paymentMethodReplaced) {
      await handleUpdateBillingFeeSetting();

      return;
    }

    await handleCreateBillingFeeSetting();
  };

  const updateBillingFeeSetting = async () => {
    if (currentBillingFeeSetting?.id) {
      const isFirmMethodSelected = selectedFundingSourceId === firmFundingSourceId;
      const billingFee = {
        fundingSourceId: selectedFundingSourceId,
        isActive: true,
        managedByOrganizationId: isUserPartOfFirm && isFirmMethodSelected ? firmId : null,
      };
      await updateOrgBillingFeeSetting(currentBillingFeeSetting?.id, billingFee);
    }
  };

  const handleReplaceSmbBillingFee = async () => {
    await updateBillingFeeSetting();
    goNext();
  };

  const handleUpdateSmbBillingFee = async () => {
    if (isUserUnableToSeeFirmMethod) {
      setIsReplaceModalOpen(true);
    } else {
      await updateBillingFeeSetting();
      goNext();
    }
  };

  const handleCreateBillingFeeSetting = async () => {
    if (selectedFundingSourceId) {
      const isFirmMethodSelected = selectedFundingSourceId === firmFundingSourceId;
      const billingFee = {
        fundingSourceId: selectedFundingSourceId,
        isActive: true,
        billingFeeType: BillingFeeSettingBillingFeeTypeEnum.AchToCheck,
        managedByOrganizationId: isUserPartOfFirm && isFirmMethodSelected ? firmId : undefined,
      };
      await createOrgBillingFeeSetting(billingFee);
      goNext();
    }
  };

  const handleUpdateBillingFeeSetting = async () => {
    if (selectedFundingSourceId && currentBillingFeeSetting?.id) {
      await handleUpdateSmbBillingFee();
    }
  };

  const onSave = async () => {
    try {
      await handleNextClick();
    } catch (e) {
      showMessage({
        type: 'error',
        title: formatMessage('activities.billingFee.screens.select.toast.error'),
      });
    }
  };

  if (isLoading) {
    return <NewSinglePaymentStepLayout isLoading />;
  }

  return (
    <Routes>
      <Route
        path={locationsMap.select}
        element={
          <>
            <SelectBillingMethodStep
              userId={userId}
              isLoading={isLoading}
              fundingSources={allowedFundingSources}
              isLoadingButton={isMutating}
              firmFundingSourceId={firmFundingSourceId}
              currentFundingSourceId={currentFundingSourceId}
              selectedFundingSourceId={selectedFundingSourceId}
              onSave={onSave}
              cancelFlow={cancelFlow}
              setSelectedFundingSourceId={setSelectedFundingSourceId}
            />
            <ReplaceBillingFeeSetting
              isOpen={isReplaceModalOpen}
              isLoading={isMutating}
              onClose={() => setIsReplaceModalOpen(false)}
              onConfirm={handleReplaceSmbBillingFee}
            />
          </>
        }
      />
      <Route
        path={locationsMap.success}
        element={<SuccessBillingStep fundingSource={selectedFundingSource} completeFlow={completeCallback} />}
      />
    </Routes>
  );
});
