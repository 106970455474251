import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const TeamIcon = (props: IconProps) => (
  <Icon width="20px" height="20px" viewBox="0 0 24 20" fill="currentColor" {...props}>
    <path d="M12 1a1 1 0 011 1v2h2a1 1 0 110 2h-2v2a1 1 0 11-2 0V6H9a1 1 0 110-2h2V2a1 1 0 011-1z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5 9a3.5 3.5 0 100 7 3.5 3.5 0 000-7zM4 12.5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM5.5 17c-2.066 0-3.533.823-4.383 1.498A2.977 2.977 0 000 20.836V22a1 1 0 001 1h9a1 1 0 001-1v-1.164c0-.894-.393-1.763-1.116-2.337C9.034 17.824 7.567 17 5.5 17zm-3.14 3.065C2.955 19.592 3.997 19 5.5 19c1.503 0 2.545.592 3.14 1.065.223.176.36.457.36.771V21H2v-.164c0-.314.137-.595.36-.77zm11.757-1.567C14.967 17.823 16.434 17 18.5 17c2.067 0 3.533.824 4.384 1.499A2.976 2.976 0 0124 20.836V22a1 1 0 01-1 1h-9a1 1 0 01-1-1v-1.164c0-.894.393-1.764 1.117-2.338zM18.5 19c-1.503 0-2.545.592-3.14 1.065a.977.977 0 00-.36.771V21h7v-.164a.976.976 0 00-.36-.77C21.046 19.593 20.004 19 18.5 19zm0-10a3.5 3.5 0 100 7 3.5 3.5 0 000-7zM17 12.5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z"
    />
  </Icon>
);
