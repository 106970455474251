import { VirtualCardForm, VirtualCardFormFields } from '@melio/ap-widgets';
import { Group, useFormSubmissionController } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';
import { DeliveryMethodFlowOrigin } from '../../../types';

export type AddVendorVirtualCardDetailsScreenProps = {
  isSaving: boolean;
  isLoading?: boolean;
  vendorName: Vendor['name'];
  defaultValues?: Partial<VirtualCardFormFields>;
  onBack: VoidFunction;
  onClose: VoidFunction;
  onDone: (
    data: VirtualCardFormFields,
    event?: React.MouseEvent<HTMLButtonElement>,
    target?: 'continue' | 'close'
  ) => void;
  origin: DeliveryMethodFlowOrigin;
  isEdit?: boolean;
};

export const AddVendorVirtualCardDetailsScreen: React.FC<AddVendorVirtualCardDetailsScreenProps> = forwardRef<
  AddVendorVirtualCardDetailsScreenProps,
  'div'
>(({ isSaving, isLoading, vendorName, defaultValues, origin, onBack, onClose, onDone, isEdit, ...props }, ref) => {
  const { formatMessage } = useMelioIntl();
  const { onSubmissionStateChange, cancelButtonProps, submitButtonProps, handleSubmit } =
    useFormSubmissionController<VirtualCardFormFields>();

  useAnalyticsView(isEdit ? 'EditVendorVirtualCardDeliveryDetails' : 'AddVendorVirtualCardDeliveryDetails');

  return (
    <NewSinglePaymentStepLayout
      data-component="AddVendorVirtualCardDetailsActivity.AddVendorVirtualCardDetailsScreen"
      data-testid="add-vendor-virtualCard-details-activity-add-vendor-virtualCard-details-screen"
      {...props}
      isLoading={isSaving}
      ref={ref}
      headerContent={
        <NewSinglePaymentStepLayout.Header>
          <NewSinglePaymentStepLayout.BackButton {...cancelButtonProps} onClick={onBack} />
          <NewSinglePaymentStepLayout.CloseButton onClick={onClose} />
        </NewSinglePaymentStepLayout.Header>
      }
      bottomContent={
        <NewSinglePaymentStepLayout.BottomTextContent>
          {formatMessage('activities.addVendorBankDetails.screens.addVendorBankDetails.footNotes')}
        </NewSinglePaymentStepLayout.BottomTextContent>
      }
      footerContent={
        <NewSinglePaymentStepLayout.Actions>
          {origin === 'vendor' && !isEdit ? (
            <Group variant="vertical" spacing="m" width="full">
              <NewSinglePaymentStepLayout.NextButton
                {...submitButtonProps}
                onClick={handleSubmit?.('continue')}
                label={formatMessage(
                  'activities.addVendorVirtualCardDetails.screens.addVendorVirtualCardDetails.actions.vendorOrigin.continue'
                )}
              />
              <NewSinglePaymentStepLayout.SecondaryNextButton
                {...submitButtonProps}
                onClick={handleSubmit?.('close')}
                variant="tertiary"
                label={formatMessage(
                  'activities.addVendorVirtualCardDetails.screens.addVendorVirtualCardDetails.actions.vendorOrigin.close'
                )}
              />
            </Group>
          ) : (
            <NewSinglePaymentStepLayout.NextButton
              {...submitButtonProps}
              onClick={handleSubmit?.('continue')}
              label={formatMessage(
                'activities.addVendorBankDetails.screens.addVendorBankDetails.actions.paymentOrigin.continue'
              )}
            />
          )}
        </NewSinglePaymentStepLayout.Actions>
      }
    >
      <NewSinglePaymentStepLayout.Title>
        {formatMessage('activities.addVendorVirtualCardDetails.screens.addVendorVirtualCardDetails.title')}
      </NewSinglePaymentStepLayout.Title>
      <NewSinglePaymentStepLayout.Description>
        {formatMessage('activities.addVendorVirtualCardDetails.screens.addVendorVirtualCardDetails.description')}
      </NewSinglePaymentStepLayout.Description>
      <NewSinglePaymentStepLayout.Content>
        <VirtualCardForm
          onSubmit={onDone}
          isSaving={isLoading}
          defaultValues={defaultValues}
          onSubmissionStateChange={onSubmissionStateChange}
        />
      </NewSinglePaymentStepLayout.Content>
    </NewSinglePaymentStepLayout>
  );
});

AddVendorVirtualCardDetailsScreen.displayName = 'AddVendorVirtualCardDetailsActivity.AddVendorVirtualCardDetailsScreen';
