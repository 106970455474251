import { EBillAttachment } from '@melio/ap-widgets';
import { Form, FormSelectNewOption, Group, Text, Typography } from '@melio/penny';
import { Bill, useBillAttachments } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { compact } from 'lodash';
import { useState } from 'react';

import { AmountInputProps } from '../types';
import { BasicAmountInput } from './BasicAmountInput';

type EbillAmountDropdownProps = AmountInputProps & {
  bill: Bill;
  shouldHideViewEbill?: boolean;
};

type EbillAmountDropdownOption = { value: EbillPayOption; label: string; description: string };

export type EbillPayOption = 'amountDue' | 'minimumAmount' | 'accountBalance' | 'customAmount';

export const EbillAmountDropdown = ({ form, onChange, bill, shouldHideViewEbill }: EbillAmountDropdownProps) => {
  const { formatCurrency, formatMessage } = useMelioIntl();
  const [selectedOption, setSelectedOption] = useState<EbillPayOption>('amountDue');
  const { data: attachments, isLoading: isLoadingBillAttachments } = useBillAttachments({ id: bill.id });

  const amounts: Record<EbillPayOption, number> = {
    amountDue: bill.amount,
    minimumAmount: bill.minimumAmount ?? 0,
    accountBalance: bill.billerAccountBalance ?? 0,
    customAmount: bill.amount,
  };

  const options: Array<EbillAmountDropdownOption> = compact([
    {
      value: 'amountDue',
      label: formatMessage('activities.paymentFlow.form.content.amountToPay.ebill.amountDue.label', {
        amount: formatCurrency(amounts['amountDue']),
      }),
      description: formatMessage('activities.paymentFlow.form.content.amountToPay.ebill.amountDue.description'),
    },
    bill.billerAccountBalance
      ? {
          value: 'accountBalance',
          label: formatMessage('activities.paymentFlow.form.content.amountToPay.ebill.accountBalance.label', {
            amount: formatCurrency(amounts['accountBalance']),
          }),
          description: formatMessage(
            'activities.paymentFlow.form.content.amountToPay.ebill.accountBalance.description'
          ),
        }
      : undefined,
    bill.minimumAmount
      ? {
          value: 'minimumAmount',
          label: formatMessage('activities.paymentFlow.form.content.amountToPay.ebill.minimumAmount.label', {
            amount: formatCurrency(amounts['minimumAmount']),
          }),
          description: formatMessage('activities.paymentFlow.form.content.amountToPay.ebill.minimumAmount.description'),
        }
      : undefined,
    {
      value: 'customAmount',
      label: formatMessage('activities.paymentFlow.form.content.amountToPay.ebill.customAmount.label'),
      description: formatMessage('activities.paymentFlow.form.content.amountToPay.ebill.customAmount.description'),
    },
  ]);

  const valueRenderer = (option: FormSelectNewOption<string>) => (
    <Text textStyle="inline" shouldSupportEllipsis color="inherit">
      {option.label}
    </Text>
  );

  const optionRenderer = (option: FormSelectNewOption<string>) => (
    <Group variant="vertical" alignItems="flex-start" spacing="xxxs">
      <Text textStyle="body3" data-testid={`payment-flow-ebill-amount-dropdown-option-${option.value}`}>
        {option.label}
      </Text>
      <Typography.Description label={(option as EbillAmountDropdownOption).description} />
    </Group>
  );

  return (
    <Group width="full" variant="vertical" spacing="s" data-testid="payment-flow-ebill-amount-dropdown-container">
      <Form.SelectNew
        data-testid="payment-flow-ebill-amount-dropdown"
        value={selectedOption}
        labelProps={{ label: formatMessage('activities.paymentFlow.form.content.amountToPay.ebill.label') }}
        control={form.control}
        name={form.registerField('amountToPay').name}
        options={options}
        valueRenderer={valueRenderer}
        optionRenderer={optionRenderer}
        onChange={(event) => {
          onChange(amounts[event.target.value as EbillPayOption].toString());
          setSelectedOption(event.target.value as EbillPayOption);
        }}
        size="large"
        shouldHideClearButton
      />
      {selectedOption === 'customAmount' ? (
        <BasicAmountInput bill={bill} form={form} onChange={onChange} hideHelperText />
      ) : null}

      {!shouldHideViewEbill && (
        <Group alignItems="flex-start">
          <EBillAttachment
            isLoading={isLoadingBillAttachments}
            url={attachments?.[0]?.url ?? ''}
            showFileAttachment={false}
            showViewIcon
          />
        </Group>
      )}
    </Group>
  );
};
