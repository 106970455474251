import { FundingSourceCardListOfLists, FundingSourceTypesOption, PCIComplienceInfo } from '@melio/ap-widgets';
import { Group } from '@melio/penny';
import { Card, FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';

type SelectPaymentMethodTypeScreenProps = {
  isLoading?: boolean;
  onClose: VoidFunction;
  onDone: (type: FundingSource['type'] | Card['type']) => void;
  fundingSourceTypesOptions: FundingSourceTypesOption[];
};
export const SelectPaymentMethodTypeScreen = forwardRef<SelectPaymentMethodTypeScreenProps, 'div'>(
  ({ isLoading, onClose, onDone, fundingSourceTypesOptions, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    return (
      <NewSinglePaymentStepLayout
        data-component="CreateFundingSourceActivity.SelectPaymentMethodTypeScreen"
        data-testid="create-funding-source-activity-select-payment-method-type-screen"
        isLoading={isLoading}
        {...props}
        ref={ref}
        headerContent={
          <NewSinglePaymentStepLayout.Header>
            <NewSinglePaymentStepLayout.CloseButton onClick={onClose} />
          </NewSinglePaymentStepLayout.Header>
        }
      >
        <NewSinglePaymentStepLayout.Title>
          {formatMessage('activities.createFundingSource.screens.selectPaymentMethodType.title')}
        </NewSinglePaymentStepLayout.Title>
        <NewSinglePaymentStepLayout.Description>
          {formatMessage('activities.createFundingSource.screens.selectPaymentMethodType.description')}
        </NewSinglePaymentStepLayout.Description>
        <NewSinglePaymentStepLayout.Content>
          <Group variant="vertical">
            <FundingSourceCardListOfLists onAdd={onDone} fundingSourceTypesOptions={fundingSourceTypesOptions} />
            <PCIComplienceInfo />
          </Group>
        </NewSinglePaymentStepLayout.Content>
      </NewSinglePaymentStepLayout>
    );
  }
);

SelectPaymentMethodTypeScreen.displayName = 'CreateFundingSourceActivity.SelectPaymentMethodTypeScreen';
