export const PaymentsClassificationFixtures = [
  {
    vendor: {
      name: 'vendor1',
      id: 1,
    },
    bills: [
      {
        id: 1,
        date: 'Feb 28, 2022',
        invoiceId: '333',
        amount: '12.34$',
      },
      {
        id: 2,
        date: 'Feb 28, 2022',
        amount: '2.3$',
        category: '1099-MISC Rents3',
      },
    ],
  },
  {
    vendor: {
      name: 'vendor2',
      id: 2,
    },
    bills: [
      {
        id: 3,
        date: 'Feb 28, 2022',
        amount: '15.4$',
        category: '1099-MISC Rents2',
      },
      {
        id: 4,
        date: 'Feb 28, 2022',
        invoiceId: '333',
        amount: '14$',
        category: '1099-MISC Rents1',
      },
    ],
  },
];
