import { useCustomer, useInvoice, useMelioIntl } from '@melio/ar-domain';
import { useToast } from '@melio/penny';
import { useBoolean } from '@melio/platform-utils';
import { useState } from 'react';

import { OnBeforeDone, ShareMode } from '../../../types';
import { IssueInvoiceFormFields, SubmitTarget } from '../types';
import { useSuccessMessage } from './useSuccessMessage';

type Props = {
  invoiceId: string;
  onError?: ARErrorFunction;
  onDone?: (id: string) => unknown;
  onBeforeDone?: OnBeforeDone;
  shareMode: ShareMode;
  onClose?: VoidFunction;
  onEditInvoice?: (id: string) => unknown;
};
export const useIssueInvoiceActivity = ({ invoiceId, onBeforeDone, shareMode, ...props }: Props) => {
  const onError = (error: ARPlatformError) => {
    props.onError?.(error);
    toast({ type: 'error', title: error.message });
  };

  const [submitTarget, setSubmitTarget] = useState<SubmitTarget>();
  const { toast } = useToast();
  const getSuccessMessage = useSuccessMessage();
  const { formatMessage } = useMelioIntl();

  const { shareInvoice, invoice, customer, isLoading, isSaving, paymentRequestLink } = useInvoiceActions({
    invoiceId,
    onError,
  });

  const [isOnBeforeDone, onBeforeDoneState] = useBoolean(false);
  const submitHandler = (formData: IssueInvoiceFormFields, target?: SubmitTarget) => {
    setSubmitTarget(target);

    try {
      switch (target) {
        case 'primary':
          handleSubmitTargetPrimary(formData, target);
          break;
        case 'secondary':
          props.onEditInvoice?.(invoiceId);
          break;
        case 'tertiary':
          props.onClose?.();
          break;
      }
    } catch (error) {
      onError(error as ARPlatformError);
    } finally {
      setSubmitTarget(undefined);
    }
  };

  const copyPaymentLink = async () => {
    if (!paymentRequestLink) throw new Error('Payment link is not available');
    await navigator.clipboard.writeText(paymentRequestLink);
  };

  const handleSubmitTargetPrimary = (formData: IssueInvoiceFormFields, target?: SubmitTarget) => {
    if (onBeforeDone) {
      onBeforeDoneState.on();
      onBeforeDone(() => handleShareInvoice(formData, target), onBeforeDoneState.off);
    } else {
      void handleShareInvoice(formData, target);
    }
  };

  const handleShareInvoice = async (
    { shouldSendEmail, emailMessage }: IssueInvoiceFormFields,
    target?: SubmitTarget
  ) => {
    if (shouldSendEmail === 'yes') {
      await shareInvoice({ sentFrom: 'partner', type: shareMode, emailMessage });
    }

    props.onDone?.(invoiceId);
    toast({
      type: 'success',
      title: getSuccessMessage({ target, shareMode, shouldSendEmail, invoiceNumber: invoice?.invoiceNumber }),
    });
  };

  const handleCopyPaymentLink = async () => {
    try {
      await copyPaymentLink();
      toast({
        type: 'success',
        title: formatMessage('ar.invoiceLifecycle.activities.issueInvoice.toasts.copy.success.label'),
      });
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const handleBackClick = () => {
    if (shareMode === 'createInvoice' || shareMode === 'updateInvoice') {
      props.onEditInvoice?.(invoiceId);
    } else {
      props.onClose?.();
    }
  };

  return {
    customer,
    isLoading,
    isSaving: isOnBeforeDone || isSaving,
    submitHandler,
    submitTarget,
    handleCopyPaymentLink,
    paymentRequestLink,
    isDraft: !invoice?.issuedAt,
    invoice,
    handleBackClick,
  };
};

export const useInvoiceActions = ({ invoiceId, onError }: Pick<Props, 'invoiceId' | 'onError'>) => {
  const { data: invoice, ...invoiceModel } = useInvoice({ id: invoiceId, onError });
  const { data: customer, ...customerModel } = useCustomer({ id: invoice?.customerId, onError });

  return {
    invoice,
    customer,
    isLoading: invoiceModel.isLoading || customerModel.isLoading,
    isSaving: invoiceModel.isMutating || customerModel.isMutating,
    paymentRequestLink: invoice?.paymentRequest?.link,
    shareInvoice: invoiceModel.share,
  };
};
