import { InvoiceDiscount, useMelioIntl } from '@melio/ar-domain';
import { ActionsDropdownMenuItemProps } from '@melio/penny';

export type UseDiscountActionsItemsProps = {
  discount: InvoiceDiscount;
  onEdit: (discountId: string) => unknown;
  onDelete: (discountId: string) => unknown;
};

export const useDiscountActionsItems = ({
  discount,
  onEdit,
  onDelete,
}: UseDiscountActionsItemsProps): ActionsDropdownMenuItemProps[] => {
  const { formatMessage } = useMelioIntl();

  const editDiscountAction = {
    label: formatMessage('ar.settings.activities.discountsTable.components.discountsTable.columns.actions.edit.label'),
    onClick: () => onEdit(discount.id),
    dataTestId: 'discount-edit',
  };

  const deleteDiscountAction = {
    label: formatMessage(
      'ar.settings.activities.discountsTable.components.discountsTable.columns.actions.delete.label'
    ),
    onClick: () => onDelete(discount.id),
    variant: 'critical',
    dataTestId: 'discount-delete',
  };

  return [editDiscountAction, deleteDiscountAction];
};
