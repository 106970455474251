import { useMelioIntl } from '@melio/ar-domain';
import { Link, SectionBannerProps } from '@melio/penny';
import { useEffect, useMemo, useState } from 'react';

import { InvoiceTotalAmountLimits } from '../types';

type UseValidateInvoiceTotalAmountProps = {
  totalAmountLimits?: InvoiceTotalAmountLimits;
  totalAmount?: number;
};

export const useValidateInvoiceTotalAmount = ({
  totalAmountLimits,
  totalAmount,
}: UseValidateInvoiceTotalAmountProps) => {
  const [shouldExpandCustomPayOptions, setShouldExpandCustomPayOptions] = useState(false);
  const { formatMessage } = useMelioIntl();

  useEffect(() => {
    setShouldExpandCustomPayOptions(false);
  }, [totalAmount]);

  const shouldValidateTotalAmount = !!totalAmount && totalAmount > 0 && !!totalAmountLimits;

  const commonBannerProps: SectionBannerProps = {
    description: '',
    variant: 'warning',
    action: (
      <Link
        href="#"
        variant="inline"
        color="secondary"
        label={formatMessage(
          'ar.invoiceLifecycle.activities.createInvoice.form.sections.payOptions.sectionBanner.totalAmountLimit.link'
        )}
        onClick={() => setShouldExpandCustomPayOptions(true)}
      />
    ),
  };

  const getSectionBannerProps = (): SectionBannerProps | undefined => {
    if (!shouldValidateTotalAmount) return undefined;

    if (totalAmount >= totalAmountLimits.max) {
      return {
        ...commonBannerProps,
        title: formatMessage(
          'ar.invoiceLifecycle.activities.createInvoice.form.sections.payOptions.sectionBanner.totalAmountLimit.exceeds.title'
        ),
        description: formatMessage(
          'ar.invoiceLifecycle.activities.createInvoice.form.sections.payOptions.sectionBanner.totalAmountLimit.exceeds.description'
        ),
      };
    } else if (totalAmount < totalAmountLimits.min) {
      return {
        ...commonBannerProps,
        title: formatMessage(
          'ar.invoiceLifecycle.activities.createInvoice.form.sections.payOptions.sectionBanner.totalAmountLimit.below.title'
        ),
        description: formatMessage(
          'ar.invoiceLifecycle.activities.createInvoice.form.sections.payOptions.sectionBanner.totalAmountLimit.below.description'
        ),
      };
    }

    return undefined;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sectionBannerProps: SectionBannerProps | undefined = useMemo(getSectionBannerProps, [
    totalAmount,
    totalAmountLimits,
  ]);

  return { sectionBannerProps, shouldExpandCustomPayOptions };
};
