import { useState } from 'react';

import { AddBillActivityProps, AddBillActivityStep } from './types';

export const useAddBillActivityStep = ({ onFirstStepBack }: { onFirstStepBack: AddBillActivityProps['onBack'] }) => {
  const [currentStep, goToStep] = useState<AddBillActivityStep>('ADD_BILL_METHOD_SELECTION');

  const goToPreviousStep = () => {
    if (currentStep === 'ADD_BILL_METHOD_SELECTION') {
      onFirstStepBack();
    } else {
      goToStep('ADD_BILL_METHOD_SELECTION');
    }
  };

  return { currentStep, goToStep, goToPreviousStep };
};
