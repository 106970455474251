import { useModelApi, UseModelApiProps } from '@melio/api-client';
import {
  ApprovalWorkflowsApiClient,
  GetApprovalWorkflowsApprovalRolesResponseData,
} from '@melio/platform-api-axios-client';

type ListFn = typeof ApprovalWorkflowsApiClient.getApprovalWorkflowsApprovalRoles;

export type UseApprovalWorkflowRolesProps = UseModelApiProps<ListFn, GetApprovalWorkflowsApprovalRolesResponseData>;

export const useApprovalWorkflowRoles = (props: UseApprovalWorkflowRolesProps = {}) =>
  useModelApi({
    ...props,
    id: 'fake_id',
    queryKey: ['ApprovalWorkflowsApi', 'roles'],
    queryFn: ApprovalWorkflowsApiClient.getApprovalWorkflowsApprovalRoles,
  });
