import Big from 'big.js';

import { Plan, SubscriptionBillingCycleEnum } from '../../api';

type PriceType = 'base' | 'unit';

export const getAnnualPlanPricePerMonth = ({ plan, type }: { plan: Plan; type: PriceType }) => {
  const annualCycle = plan.cycles.annual;

  return Number(new Big((type === 'base' ? annualCycle?.basePrice : annualCycle?.unitPrice) || 0).div(12).toFixed(2));
};

export const getPaidSeatsPrice = ({ quantity, unitPrice }: { quantity: number; unitPrice: number }) =>
  Number(new Big(quantity).mul(new Big(unitPrice)).toFixed(2));

export const getSubtotalByCycle = ({
  plan,
  quantity,
  cycle,
}: {
  plan: Plan;
  quantity: number;
  cycle: SubscriptionBillingCycleEnum;
}) => {
  const planCycle = plan.cycles[cycle];
  const unitPrice = planCycle?.unitPrice || 0;

  return Number(
    new Big(unitPrice)
      .mul(quantity - plan.numberOfFreeUsers || 0)
      .add(planCycle?.basePrice || 0)
      .toFixed(2)
  );
};
