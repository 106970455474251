import { Payment } from '@melio/platform-api';

export const getEligibilityType = (payment: Payment) => {
  if (payment.paymentActions?.voidAndResend.eligible && payment.paymentActions?.voidAndRefund.eligible) {
    return 'void-and-refund_void-and-resend';
  }
  if (payment.paymentActions?.voidAndResend.eligible) {
    return 'void-and-resend';
  }
  if (payment.paymentActions?.voidAndRefund.eligible) {
    return 'void-and-refund';
  }
  if (payment.paymentActions?.refund.eligible) {
    return 'get-a-refund';
  }
  if (payment.paymentActions?.retryCollect.eligible || payment.paymentActions?.retryDeliver.eligible) {
    return 'retry';
  }
  return undefined;
};

export const getPaymentAnalyticsFields = (payment: Payment) => {
  const canRetryPayment =
    payment?.paymentActions?.retryCollect?.eligible || payment?.paymentActions?.retryDeliver?.eligible;
  const isCombined = payment?.bills && payment?.bills.length > 1;
  const cardNetwork = payment?.fundingSource?.type === 'card' ? payment?.fundingSource.details.network : null;
  const intent = canRetryPayment ? 'retry-failed-payment' : 'failed-payment';

  return {
    BillIds: payment?.bills?.map((bill) => bill.id),
    Intent: intent,
    IsCombined: isCombined,
    PaymentId: payment?.id,
    PaymentAmount: payment?.amount,
    VendorId: payment?.vendorId,
    DeliveryMethodType: payment?.deliveryMethod?.type,
    PaymentStatus: payment?.status,
    EligibilityType: payment ? getEligibilityType(payment) : undefined,
    Reason: payment?.paymentFailure?.failureReason,
    PaymentMethodType: payment?.fundingSource?.type,
    CardNetwork: cardNetwork,
  };
};
