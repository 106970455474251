import { Link, SectionBanner } from '@melio/penny';
import { SyncMigrationStatusEnum, useSyncMigration } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { useEffect, useState } from 'react';

import { ERROR_INTERVAL, RUNNING_INTERVAL } from './SyncMigrationProgressIndication';

export const SyncMigrationStatusBanner = () => {
  const { formatMessage } = useMelioIntl();
  const { links } = useConfig();
  const [refetchInterval, setRefetchInterval] = useState<number | false>(false);
  const { data: syncData } = useSyncMigration({ refetchInterval });

  useEffect(() => {
    switch (syncData?.status) {
      case SyncMigrationStatusEnum.Error:
        setRefetchInterval(ERROR_INTERVAL); // time to wait to find out if error resolved
        break;
      case SyncMigrationStatusEnum.Running:
        setRefetchInterval(RUNNING_INTERVAL); // time to wait to find out if error occurred
        break;
      case SyncMigrationStatusEnum.Success:
      case SyncMigrationStatusEnum.NotTriggered:
      default:
        setRefetchInterval(false);
        break;
    }
  }, [syncData?.status]);

  return syncData?.status === 'error' ? (
    <SectionBanner
      data-testid="sync-migration-status-banner"
      title={formatMessage('widgets.syncMigrationIndication.statusBanner.title')}
      description={formatMessage('widgets.syncMigrationIndication.statusBanner.text', {
        submitSupportRequestLink: (
          <Link
            variant="inline"
            href={links.submitSupportRequest}
            label={formatMessage('widgets.syncMigrationIndication.statusBanner.link')}
            newTab
          />
        ),
      })}
      variant="warning"
    />
  ) : (
    <></>
  );
};
