import { createQueryKey, useCreateMutation } from '@melio/api-client';
import { OrganizationsApiClient } from '@melio/platform-api-axios-client';

export const useOrganizationsOnBehalf = () => {
  const { mutateAsync, ...rest } = useCreateMutation(
    OrganizationsApiClient.organizationsOnBehalfPost,
    createQueryKey({ queryKey: 'OrganizationsApi', role: 'collection', scope: 'default' })
  );

  return {
    createOrganizationOnBehalf: mutateAsync,
    ...rest,
  };
};

export type {
  PostOrganizationsOnBehalfRequest,
  PostOrganizationsOnBehalfResponse,
} from '@melio/platform-api-axios-client';
