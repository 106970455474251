import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { FundingSource } from '@melio/platform-api';

import { SelectBillingMethodScreen } from '../screens/SelectBillingMethod/SelectBillingMethod.screen';

type Props = {
  userId?: string;
  isLoading: boolean;
  fundingSources: FundingSource[];
  isLoadingButton: boolean;
  firmFundingSourceId?: string;
  currentFundingSourceId?: string;
  selectedFundingSourceId?: string;
  onSave: () => void;
  cancelFlow: () => void;
  setSelectedFundingSourceId: React.Dispatch<React.SetStateAction<string>>;
};

export const SelectBillingMethodStep = ({
  userId,
  isLoading,
  fundingSources,
  isLoadingButton,
  firmFundingSourceId,
  currentFundingSourceId,
  selectedFundingSourceId,
  onSave,
  cancelFlow,
  setSelectedFundingSourceId,
}: Props) => {
  const { createTrackHandler } = useAnalytics();
  const trackAndHandleClick = createTrackHandler<{
    Cta: 'exit' | 'save-billing-method';
  }>('PaymentMethod', 'Click');

  useAnalyticsView('payment-method-for-check-fees');

  const onCloseClick = () => {
    trackAndHandleClick({ Cta: 'exit' }, cancelFlow);
  };

  const onSubmitClick = () => {
    trackAndHandleClick({ Cta: 'save-billing-method' }, onSave);
  };

  const isNextDisabled = !selectedFundingSourceId || selectedFundingSourceId === currentFundingSourceId;

  return (
    <SelectBillingMethodScreen
      userId={userId}
      isLoading={isLoading}
      fundingSources={fundingSources}
      isNextDisabled={isNextDisabled}
      isLoadingButton={isLoadingButton}
      firmBillingFeeMethodId={firmFundingSourceId}
      selectedFundingSourceId={selectedFundingSourceId}
      onClose={onCloseClick}
      onSubmit={onSubmitClick}
      setSelectedFundingSourceId={setSelectedFundingSourceId}
    />
  );
};
