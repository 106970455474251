import { Group, SelectionCard } from '@melio/penny';
import { AccountIntegrationType, useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

type BankIntegrationSelectionProps = {
  onSelect: (type: AccountIntegrationType) => void;
};

export const BankIntegrationSelection = forwardRef<BankIntegrationSelectionProps, 'div'>(
  ({ onSelect, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    return (
      <Group variant="vertical" data-component="BankIntegrationSelection" {...props} ref={ref}>
        <SelectionCard
          data-testid="selection-card-plaid"
          mainLabelProps={{ label: formatMessage('widgets.fundingSources.bankIntegration.types.plaid.label') }}
          descriptionProps={{ label: formatMessage('widgets.fundingSources.bankIntegration.types.plaid.description') }}
          onClick={() => onSelect('plaid')}
        />
        <SelectionCard
          data-testid="selection-card-deposits"
          mainLabelProps={{ label: formatMessage('widgets.fundingSources.bankIntegration.types.deposits.label') }}
          descriptionProps={{
            label: formatMessage('widgets.fundingSources.bankIntegration.types.deposits.description', {
              br: <br />,
            }),
          }}
          onClick={() => onSelect('deposits')}
        />
      </Group>
    );
  }
);

BankIntegrationSelection.displayName = 'BankIntegrationSelection';
