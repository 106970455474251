import { useSearchParams, useTaxRates } from '@melio/ar-domain';
import { forwardRef, useDebounceCallback } from '@melio/platform-utils';
import { useMemo } from 'react';

import { TaxRatesTableScreen, TaxRatesTableScreenProps } from './screens';

type SearchParams = {
  searchTerm: string;
};

type TaxRatesTableActivityProps = {
  onError?: ARErrorFunction;
  onEdit: TaxRatesTableScreenProps['onEdit'];
  onDelete: TaxRatesTableScreenProps['onDelete'];
  onAdd: VoidFunction;
};

export const TaxRatesTableActivity = forwardRef<TaxRatesTableActivityProps>(
  ({ onEdit, onAdd, onError, onDelete, ...props }, ref) => {
    const { data, isLoading, isFetching } = useTaxRates({ onError });

    const [{ searchTerm = '' }, _setParams] = useSearchParams<SearchParams>();
    const setParams = useDebounceCallback(_setParams, 200);

    const filteredData = useMemo(
      () =>
        (data ?? []).filter(
          (item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()) || item.id === searchTerm
        ),
      [data, searchTerm]
    );

    const sortedData = useMemo(
      () =>
        filteredData.sort((a, b) => {
          if (a.isDefault && !b.isDefault) return -1;
          if (!a.isDefault && b.isDefault) return 1;
          return a.name.localeCompare(b.name);
        }),
      [filteredData]
    );

    return (
      <TaxRatesTableScreen
        searchTerm={searchTerm}
        setSearchTerm={(value) => setParams({ searchTerm: value || undefined })}
        isLoading={isLoading || isFetching}
        taxRates={sortedData}
        onDelete={onDelete}
        onEdit={onEdit}
        onAdd={onAdd}
        {...props}
        ref={ref}
      />
    );
  }
);
