import { useDeliveryMethodFee } from '@melio/ap-widgets';
import { FundingSource } from '@melio/platform-api';
import { FormattedMessage } from '@melio/platform-i18n';

export const useDescriptionText = (vendorName: string, fundingSourceType?: FundingSource['type']) => {
  const { getFee } = useDeliveryMethodFee(fundingSourceType);

  const achFee = getFee('bank-account');
  const checkFee = getFee('paper-check');

  if (achFee && checkFee) {
    return (
      <FormattedMessage
        id="activities.addVendorUnilateralDetails.screens.addVendorUnilateralDetails.descriptionWithFees"
        values={{ achFee, checkFee }}
      />
    );
  } else if (achFee) {
    return (
      <FormattedMessage
        id="activities.addVendorUnilateralDetails.screens.addVendorUnilateralDetails.descriptionWithAchFee"
        values={{ achFee }}
      />
    );
  } else if (checkFee) {
    return (
      <FormattedMessage
        id="activities.addVendorUnilateralDetails.screens.addVendorUnilateralDetails.descriptionWithCheckFee"
        values={{ checkFee }}
      />
    );
  } else {
    return (
      <FormattedMessage
        id="activities.addVendorUnilateralDetails.screens.addVendorUnilateralDetails.description"
        values={{ vendorName }}
      />
    );
  }
};
