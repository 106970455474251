import { Form, OnSubmissionStateChange, Table, useMelioForm, useTable } from '@melio/penny';
import { Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { object, SchemaOf, string } from 'yup';

import { VendorTabAmountCellAmounts } from '../../../types';
import { FormContext } from '../FormContext';
import { FormFields } from '../types';
import { useVendorsBatchPaymentsColumns } from '../useVendorsBatchPaymentsColumns';

type Props = {
  vendors: Vendor[];
  vendorsAmountsMap: VendorTabAmountCellAmounts;
  onChange: (vendor: Vendor, value?: string) => void;
  onSubmissionStateChange: OnSubmissionStateChange<FormFields>;
  isSaving: boolean;
  isLoading: boolean;
  onSubmit: (data: FormFields) => void;
};

export const VendorsBatchPaymentsAmountsModalBody = ({
  vendors,
  onChange,
  vendorsAmountsMap,
  onSubmissionStateChange,
  isSaving,
  isLoading,
  onSubmit,
}: Props) => {
  const { formatMessage } = useMelioIntl();
  const form = useMelioForm<FormFields>({
    onSubmit,
    schema: object().shape({
      amounts: object().shape(
        vendors.reduce((acc, vendor) => {
          acc[vendor.id] = string().required(
            formatMessage(
              'activities.payDashboard.vendorsTab.vendorsBatchPaymentsModal.body.table.cells.amount.errors.required'
            )
          );
          return acc;
        }, {} as Record<string, SchemaOf<string>>)
      ),
    }),
    mode: 'onSubmit',
    isSaving,
    isLoading,
    onSubmissionStateChange,
  });

  const columns = useVendorsBatchPaymentsColumns();
  const tableProps = useTable({
    isHeaderSticky: true,
    headerVariant: 'dark',
    data: vendors,
    columns,
    meta: { onChange, vendorsAmountsMap },
  });

  return (
    <FormContext.Provider value={form}>
      <Form {...form.formProps}>
        <Table {...tableProps} />
      </Form>
    </FormContext.Provider>
  );
};
