import { _createFormFieldInput, TextField, TextFieldProps } from '@melio/penny';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

const ZIP_5_LENGHT = 5;
const ZIP_4_LENGTH = 10;

export type ZipCodeFieldProps = Omit<TextFieldProps, 'maskProps'>;

export const ZipCodeField = _createFormFieldInput(
  forwardRef<ZipCodeFieldProps, 'input'>((props, ref) => {
    const config = useConfig();
    const shouldUseMask = config.settings.vendor.forms.shouldUseZipCodeMask;

    const pickMask = (value: string) => {
      if (value?.length > ZIP_4_LENGTH) {
        return [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];
      }

      if (value?.length > ZIP_5_LENGHT) {
        return [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
      }

      return [/\d/, /\d/, /\d/, /\d/, /\d/];
    };

    return (
      <TextField
        data-component="ZipCodeField"
        {...props}
        ref={ref}
        maskProps={shouldUseMask ? { mask: pickMask } : undefined}
      />
    );
  })
);

ZipCodeField.displayName = 'ZipCodeField';
