import { useMelioIntl } from '@melio/platform-i18n';
import { EMAIL_REGEX } from '@melio/platform-utils';
import { useIsSubscriptionsEnabled } from '@melio/subscriptions';
import { boolean, object, SchemaOf, string, StringSchema } from 'yup';

import { SubscriptionBillingPayor } from '../../../types';
import { NewFirmClientFormFields } from '../types';

export const emailIsValid = (email?: string) => (email ? EMAIL_REGEX.test(email) : false);

export const useNewFirmClientFormSchema = () => {
  const { formatMessage } = useMelioIntl();
  const isSubscriptionEnabled = useIsSubscriptionsEnabled();

  const requiredFieldMessage = formatMessage(
    'activities.accountants.newClient.form.basicDetails.fields.validation.required.label'
  );
  const invalidEmailAddressMessage = formatMessage(
    'activities.accountants.newClient.form.clientDetails.fields.emailAddress.validation.format.label'
  );
  const invalidFirmSubscriptionBillingPayor = formatMessage(
    'activities.accountants.newClient.form.billingDetails.fields.whoPays.validation.invalidPayorWithoutFundingSource.label'
  );

  return object().shape({
    businessName: string().required(requiredFieldMessage),
    businessDBA: string().required(requiredFieldMessage),
    shouldInviteClient: boolean().nullable(),
    clientEmailAddress: string().when(
      'shouldInviteClient',
      (shouldInviteClient: boolean, schema: StringSchema<string | undefined>) =>
        shouldInviteClient
          ? schema.required(requiredFieldMessage).test('validAddress', invalidEmailAddressMessage, emailIsValid)
          : schema.optional().nullable()
    ),
    clientFirstName: string().when(
      'shouldInviteClient',
      (shouldInviteClient: boolean, schema: StringSchema<string | undefined>) =>
        shouldInviteClient ? schema.required(requiredFieldMessage) : schema.optional().nullable()
    ),
    clientLastName: string().when(
      'shouldInviteClient',
      (shouldInviteClient: boolean, schema: StringSchema<string | undefined>) =>
        shouldInviteClient ? schema.required(requiredFieldMessage) : schema.optional().nullable()
    ),
    ...(isSubscriptionEnabled && {
      whoPays: string().when('fundingSourceId', (fundingSourceId: string, schema: StringSchema<string | undefined>) =>
        fundingSourceId
          ? schema.required(requiredFieldMessage)
          : schema.test(
              'subscription firm funding source is required for firm to pay',
              invalidFirmSubscriptionBillingPayor,
              (value) => value === SubscriptionBillingPayor.Client
            )
      ),
      fundingSourceId: string().nullable(),
      subscriptionPlanId: string().required(requiredFieldMessage),
    }),
  }) as unknown as SchemaOf<NewFirmClientFormFields>;
};
