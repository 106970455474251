import {
  isJoinOrganizationRequestExpired,
  JoinOrganizationRequestStatus,
  useJoinOrganizationRequests,
} from '@melio/platform-api';
import { useIsSubscriptionsEnabled } from '@melio/subscriptions';
import { partition } from 'lodash';
import { useEffect, useState } from 'react';

import {
  useAccountingFirmClientsExpanded,
  useAccountingFirmClientsNonExpanded,
} from '../../api/entities/client/useAccountingFirmClients';
import { usePatchAccountantFirmClient } from '../../api/entities/client/usePatchAccountantFirmClient';
import { useCanSwitch } from '../../hooks/useCanSwitch';
import { isEligibleForAssignPlan } from './utils/isEligibleForAssignPlan';

export type DisplayMode = 'all' | 'visible' | 'hidden';

export const useFirmDashboard = () => {
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const [isSwitching, setIsSwitching] = useState(false);
  const [displayMode, setDisplayMode] = useState<DisplayMode>('visible');

  const {
    data: clients = [],
    isLoading: isClientsLoading,
    isFetching: isClientsFetching,
    error: clientsError,
  } = useAccountingFirmClientsNonExpanded();
  const {
    data: joinOrganizationRequests = [],
    isLoading: isJoinOrganizationRequestsLoading,
    error: joinOrganizationRequestsError,
  } = useJoinOrganizationRequests();
  const patchClientMutation = usePatchAccountantFirmClient();
  const {
    data: expandedClients = [],
    isLoading: isExpandedClientsLoading,
    isFetching: isExpandedClientsFetching,
    error: expandedClientsError,
    isFetched: isExpandedClientsFetched,
  } = useAccountingFirmClientsExpanded();

  const isExpandedClientsFetchedSuccessfully = isExpandedClientsFetched && !expandedClientsError;

  const hasHiddenClients = clients.some((client) => client.organization.isHidden);
  const { canSwitch } = useCanSwitch();
  const visibleClients = (isExpandedClientsFetchedSuccessfully ? expandedClients : clients).filter((client) =>
    displayMode === 'visible'
      ? !client.organization.isHidden
      : displayMode === 'hidden'
      ? client.organization.isHidden
      : true
  );

  const joinOrganizationRequestsToShow = joinOrganizationRequests
    .filter(({ status }) => status === JoinOrganizationRequestStatus.Pending)
    .filter(() => displayMode === 'all' || displayMode === 'visible');

  const [clientsToShow, clientsWithoutPermissions] = partition(visibleClients, (client) =>
    canSwitch(client.organization)
  );

  const eligibleClientsForAssignPlan =
    isExpandedClientsFetchedSuccessfully && isSubscriptionsEnabled
      ? clientsToShow.filter(
          (client) =>
            !client.subscriptionInfo?.isAwaitingActivation &&
            isEligibleForAssignPlan(client.subscriptionInfo?.subscription)
        )
      : [];

  const isLoading =
    isClientsLoading ||
    isClientsFetching ||
    isExpandedClientsFetching ||
    isJoinOrganizationRequestsLoading ||
    isSwitching ||
    patchClientMutation.isLoading;

  const isLoadingExpanded = isLoading || isExpandedClientsLoading;

  const [expiredRequests, pendingRequests] = partition(joinOrganizationRequestsToShow, (joinOrganizationRequest) =>
    isJoinOrganizationRequestExpired(joinOrganizationRequest)
  );

  useEffect(() => {
    if (!hasHiddenClients && displayMode === 'hidden') {
      setDisplayMode('visible');
    }
  }, [hasHiddenClients, displayMode]);

  return {
    clientsToShow,
    joinOrganizationRequestsToShow,
    clientsWithoutPermissions,
    expiredRequests,
    pendingRequests,
    isLoading,
    isLoadingExpanded,
    isSwitching,
    isPaymentsOverviewLoading: isExpandedClientsLoading,
    setIsSwitching,
    hasHiddenClients,
    updateClient: patchClientMutation.mutateAsync,
    displayMode,
    setDisplayMode,
    error: expandedClientsError || clientsError || joinOrganizationRequestsError,
    isExpandedClientsFetchedSuccessfully,
    eligibleClientsForAssignPlan,
  };
};
