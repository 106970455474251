import { VendorBankDetailsPayorForm, VendorBankDetailsPayorFormFields } from '@melio/ap-widgets';
import { Container, Group, useFormSubmissionController } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { Vendor } from '@melio/platform-api';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { forwardRef } from '@melio/platform-utils';
import React, { ComponentProps } from 'react';

import { MonitoredAction } from '../../../../../monitoring';
import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';

export type CustomValidation = ComponentProps<typeof VendorBankDetailsPayorForm>['customValidation'];

export type EditVendorBankDetailsScreenProps = {
  isLoading?: boolean;
  isSaving: boolean;
  vendorName: Vendor['name'];
  defaultValues?: Partial<VendorBankDetailsPayorFormFields>;
  onBack: VoidFunction;
  customValidation?: CustomValidation;
  onValidation?: ComponentProps<typeof VendorBankDetailsPayorForm>['onValidation'];
  banner?: JSX.Element | null;
  onClose: VoidFunction;
  onDone: (data: VendorBankDetailsPayorFormFields) => void;
  onChange?: () => void;
  disableNext?: boolean;
  texts?: {
    continue?: MessageKey;
  };
};

export const EditVendorBankDetailsScreen: React.FC<EditVendorBankDetailsScreenProps> = forwardRef<
  EditVendorBankDetailsScreenProps,
  'div'
>(
  (
    {
      isLoading,
      isSaving,
      vendorName,
      defaultValues,
      onBack,
      onClose,
      onDone,
      onValidation,
      customValidation,
      banner,
      onChange,
      ...props
    },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();
    const { onSubmissionStateChange, cancelButtonProps, submitButtonProps } =
      useFormSubmissionController<VendorBankDetailsPayorFormFields>();

    const { startAction } = useMonitoring<MonitoredAction>();

    useAnalyticsView('EditVendorBankDetails');
    const continueLabel =
      props.texts?.continue || 'activities.editVendorBankDetails.screens.editVendorBankDetails.continue';

    const onSubmit = (data: VendorBankDetailsPayorFormFields) => {
      startAction('edit_vendor_bank_details');
      onDone(data);
    };

    return (
      <NewSinglePaymentStepLayout
        isLoading={isLoading}
        data-component="EditVendorBankDetailsActivity.EditVendorBankDetailsScreen"
        data-testid="edit-vendor-bank-details-activity-edit-vendor-bank-details-screen"
        {...props}
        ref={ref}
        headerContent={
          <NewSinglePaymentStepLayout.Header>
            <NewSinglePaymentStepLayout.CloseButton onClick={onClose} />
            <NewSinglePaymentStepLayout.BackButton {...cancelButtonProps} onClick={onBack} />
          </NewSinglePaymentStepLayout.Header>
        }
        footerContent={
          <NewSinglePaymentStepLayout.Actions>
            <NewSinglePaymentStepLayout.NextButton
              {...submitButtonProps}
              label={formatMessage(continueLabel)}
              isDisabled={!!props.disableNext}
            />
          </NewSinglePaymentStepLayout.Actions>
        }
      >
        <NewSinglePaymentStepLayout.Title>
          {formatMessage('activities.editVendorBankDetails.screens.editVendorBankDetails.title', { vendorName })}
        </NewSinglePaymentStepLayout.Title>
        <NewSinglePaymentStepLayout.Content>
          <Group spacing="l" variant="vertical" width="full">
            {banner}
            <Container width="full" overflow="initial">
              <VendorBankDetailsPayorForm
                onSubmit={onSubmit}
                customValidation={customValidation}
                onValidation={onValidation}
                isSaving={isSaving}
                defaultValues={defaultValues}
                onSubmissionStateChange={onSubmissionStateChange}
                onChange={onChange}
              />
            </Container>
          </Group>
        </NewSinglePaymentStepLayout.Content>
      </NewSinglePaymentStepLayout>
    );
  }
);

EditVendorBankDetailsScreen.displayName = 'EditVendorBankDetailsActivity.EditVendorBankDetailsScreen';
