import { Box } from '@chakra-ui/react';

import { EmbeddedDemoLayoutComponent } from '@/screens/embedded-experience-demo/layouts/types';

import './style.css';

export const CleanBankLayout: EmbeddedDemoLayoutComponent = ({ children }) => (
  <Box backgroundColor="#F7F7FA" display="flex" flexDirection="column" alignItems="center">
    {children}
  </Box>
);
