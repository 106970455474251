import { useMelioIntl, useNavigate } from '@melio/ar-domain';
import { useMatch, useResolvedPath } from 'react-router-dom';

import { SettingsTab, TabItem } from './types';

export const useTabs = () => {
  const resolvedPathUrl = useResolvedPath('');
  const match = useMatch({ path: `${resolvedPathUrl.pathname}/:tab/*`, end: true });
  const activeTab = match?.params.tab as SettingsTab;

  const { formatMessage } = useMelioIntl();

  const tabs: TabItem[] = [
    {
      name: 'items',
      label: formatMessage('ar.settings.flows.invoiceItems.tabsLayout.tabs.items.label'),
    },
    {
      name: 'tax',
      label: formatMessage('ar.settings.flows.invoiceItems.tabsLayout.tabs.tax.label'),
    },
    {
      name: 'discounts',
      label: formatMessage('ar.settings.flows.invoiceItems.tabsLayout.discount.label'),
    },
    {
      name: 'service-charges',
      label: formatMessage('ar.settings.flows.invoiceItems.tabsLayout.serviceCharges.label'),
    },
  ];

  const handleTabChange = useNavigate<SettingsTab>();

  return { tabs, activeTab, handleTabChange };
};
