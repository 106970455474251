import { FormSelectNewOption } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { DecisionMakerRoles } from '../types';

export const useDecisionMakerRoleOptions = () => {
  const { formatMessage } = useMelioIntl();
  const roleOptions: FormSelectNewOption[] = Object.entries(DecisionMakerRoles).map(([, value]) => ({
    label: formatMessage(
      `activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.decisionMakerDetailsCard.decisionMakerField.option.${value}.label`
    ),
    value,
    testId: `decision-maker-role-${value}`,
  }));

  return roleOptions;
};
