import { TaxRate, useMelioIntl } from '@melio/ar-domain';
import { ActionsDropdownMenuItemProps } from '@melio/penny';

export type UseTaxRateActionsItemsProps = {
  taxRate: TaxRate;
  onEdit: (itemId: string) => unknown;
  onDelete: (itemId: string) => unknown;
};

export const useTaxRateActionsItems = ({
  taxRate,
  onEdit,
  onDelete,
}: UseTaxRateActionsItemsProps): ActionsDropdownMenuItemProps[] => {
  const { formatMessage } = useMelioIntl();

  const editTaxRateAction = {
    label: formatMessage('ar.settings.activities.taxRatesTable.components.taxRatesTable.columns.actions.edit.label'),
    onClick: () => onEdit(taxRate.id),
    dataTestId: 'tax-rate-edit',
  };

  const deleteTaxRateAction = {
    label: formatMessage('ar.settings.activities.taxRatesTable.components.taxRatesTable.columns.actions.delete.label'),
    onClick: () => onDelete(taxRate.id),
    variant: 'critical',
    dataTestId: 'tax-rate-delete',
  };

  return [editTaxRateAction, deleteTaxRateAction];
};
