import { apiClient, Invitation, InvitationStatus, PostInvitationsRequest } from '@melio/javascript-sdk';

import { useCollectionApi, UseCollectionApiProps, useMelioQueryClient } from '../../core';
import { useCreateMutation } from '../../core/useCreateMutation';
import { InvitationCollection } from './types';

export type UseInvitationsProps = UseCollectionApiProps<Invitation> & {
  params?: { status: InvitationStatus };
};

export const useInvitations = ({ params, ...props }: UseInvitationsProps): InvitationCollection => {
  const client = apiClient.invitations();
  const queryClient = useMelioQueryClient();

  const query = useCollectionApi<Invitation>({
    ...props,
    queryKey: ['invitation', params],
    queryFn: () => client.fetch(params),
  });

  const create = useCreateMutation<Invitation, PostInvitationsRequest>(client.create, query, {
    onSuccess: () => queryClient.invalidateQueries('SubscriptionsApi'),
  });

  return {
    ...query,
    create,
  };
};
