import { useDebounce } from '@melio/platform-utils';
import { useEffect, useState } from 'react';

export const useHighlightInvoiceTableRows = ({
  newTabItemsIds,
  isLoading,
}: {
  newTabItemsIds: string[];
  isLoading?: boolean;
}) => {
  const [highlightedRowIds, setHighlightedRowIds] = useState<string[]>(newTabItemsIds);

  const debouncedIsLoading = useDebounce(isLoading, 3000);

  useEffect(() => {
    if (!debouncedIsLoading && highlightedRowIds.length > 0) {
      setHighlightedRowIds([]);
    }
  }, [debouncedIsLoading, highlightedRowIds]);

  return highlightedRowIds;
};
