import { Drawer, Group, LoadingContainer, Text, useFormSubmissionController } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { LinkVendorToDirectoryParams, ModelError, UpdateVendorParams, useVendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { SystemMessageDisplay, useSystemMessage } from '@melio/platform-utils';
import { Suspense, useEffect, useState } from 'react';

import { getErrorsByType } from '../apiErrorsUtils';
import { ErrorTypeToErrorCodesMap } from '../types';
import { MissingVendorInfoExtendedUnmanagedFields } from './components/MissingVendorInfoExtendedUnmanagedForm/types';
import { MissingVendorInfoDrawerBody } from './MissingVendorInfoDrawerBody.widget';
import { MissingVendorInfoDrawerFooter } from './MissingVendorInfoDrawerFooter.widget';
import { MissingVendorInfoDrawerProps } from './types';

const LOADING_CONTAINER_ID = 'VendorDrawerLoadingContainer';

export const MissingVendorInfoDrawer = withAnalyticsContext(
  ({ onClose, vendorId, linkToUnpaidBills, onDone }: MissingVendorInfoDrawerProps) => {
    const { formatMessage } = useMelioIntl();
    const [isClosing, setIsClosing] = useState(false);

    const { data: vendor } = useVendor({ id: vendorId });

    const handleClose = () => {
      onClose();
    };

    const { submitButtonProps, cancelButtonProps, onSubmissionStateChange, onSubmit, apiErrorsCodes } =
      useLinkVendorToDirectory({ vendorId, onClose: handleClose, onDone });

    const { track } = useAnalytics();

    useEffect(() => {
      if (vendor) {
        track('Vendor', 'View', {
          Intent: 'edit-vendor',
          PageName: 'vendor-details',
        });
      }
    }, [track, vendor]);

    const handleCloseClick = () => {
      track('Vendor', 'Click', {
        Cta: 'exit',
      });

      setIsClosing(true);
    };

    return (
      <Drawer
        isOpen={!isClosing}
        onClose={handleCloseClick}
        onCloseComplete={handleClose}
        data-testid="missing-vendor-info-drawer"
        closeButtonAriaLabel={formatMessage('widgets.missingVendorInfoDrawer.closeButtonAriaLabel')}
        closeButtonAriaLabelledBy={LOADING_CONTAINER_ID}
        header={
          <Text as="h2" textStyle="heading2Semi">
            {formatMessage('widgets.missingVendorInfoDrawer.header.title')}
          </Text>
        }
        body={
          <Suspense fallback={<LoadingContainer isLoading id={LOADING_CONTAINER_ID} />}>
            <Group variant="vertical">
              <SystemMessageDisplay />
              <MissingVendorInfoDrawerBody
                vendorId={vendorId}
                onSubmit={onSubmit}
                onSubmissionStateChange={onSubmissionStateChange}
                inlineApiErrorCodes={apiErrorsCodes?.inline}
                loadingContainerId={LOADING_CONTAINER_ID}
                linkToUnpaidBills={linkToUnpaidBills}
              />
            </Group>
          </Suspense>
        }
        footer={
          vendor ? (
            <Suspense fallback={<LoadingContainer isLoading />}>
              <MissingVendorInfoDrawerFooter
                submitButtonProps={submitButtonProps}
                cancelButtonProps={cancelButtonProps}
                onCancelClick={handleCloseClick}
              />
            </Suspense>
          ) : null
        }
      />
    );
  }
);

const useLinkVendorToDirectory = ({
  vendorId,
  onClose,
  onDone,
}: Pick<MissingVendorInfoDrawerProps, 'onClose' | 'onDone' | 'vendorId'>) => {
  const { formatMessage } = useMelioIntl();
  const { showMessage } = useSystemMessage();
  const { track } = useAnalytics();
  const { linkVendorToDirectory } = useVendor({
    id: vendorId,
    enabled: false,
    refetchOnMount: 'always',
  });
  const { onSubmissionStateChange, submitButtonProps, cancelButtonProps, reset } =
    useFormSubmissionController<MissingVendorInfoExtendedUnmanagedFields>();
  const [apiErrorsCodes, setApiErrorsCodes] = useState<Pick<ErrorTypeToErrorCodesMap, 'inline' | 'banner'>>();

  const handleResetForm = () => {
    reset?.();
    setApiErrorsCodes(undefined);
  };

  const onSubmit = async (payload: UpdateVendorParams, throwErrors = false) => {
    setApiErrorsCodes(undefined);

    try {
      if (!vendorId) {
        return;
      }
      track('Vendor', 'Click', { Intent: 'edit-vendor', Cta: 'save' });
      const payloadWithValidatedEmail = {
        ...payload,
        contact: payload.contact ? { ...payload.contact, email: payload.contact.email || null } : {},
      };

      const updatedVendor = await linkVendorToDirectory(payloadWithValidatedEmail as LinkVendorToDirectoryParams);

      onDone(updatedVendor.name);

      track('Vendor', 'Status', { Intent: 'edit-vendor', Status: 'success' });
      onClose();
    } catch (e) {
      const { inline, banner } = getErrorsByType(e as ModelError, false);
      setApiErrorsCodes({ inline, banner });

      if (throwErrors) {
        throw e;
      }

      track('Vendor', 'Status', { Intent: 'edit-vendor', Status: 'failure' });

      if (!inline) {
        if (banner?.[0]) {
          showMessage({
            type: 'error',
            title: formatMessage(`widgets.vendors.apiErrors.${banner[0]}`),
            id: 'edit-vendor-error',
          });
        } else {
          showMessage({
            type: 'error',
            title: formatMessage('widgets.missingVendorInfoDrawer.toast.error', {
              companyName: payload.name,
            }),
            id: 'edit-vendor-error',
          });
        }
      }
    }
  };

  return {
    onSubmissionStateChange,
    submitButtonProps,
    cancelButtonProps,
    onSubmit,
    handleResetForm,
    apiErrorsCodes,
  };
};
