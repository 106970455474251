import React from 'react';
import { Box } from '@chakra-ui/react';
import { Container, Group, Icon, Link, Text } from '@melio/penny';
import { SectionHeader } from '@melio/platform-utils';

import { LabelCopy } from '@/cl/components/LabelCopy/LabelCopy.component';
import { useCopyEmailAnalytics } from '@/hooks/analytics/copyEmailAnalytics';
import { usePartnerConfig } from '@/hooks/partners';
import { usePlatformIntl } from '@/translations/Intl';

export type BillsInboxSettingsDetails = {
  billsInboxEmail: string;
};

export const BillsInboxSettingsSection = ({ billsInboxEmail }: BillsInboxSettingsDetails) => {
  const { formatMessage } = usePlatformIntl();
  const {
    partnerConfig: { billsInbox },
  } = usePartnerConfig();
  const { trackCopyEmailCopy, trackCopyEmailLink } = useCopyEmailAnalytics('SettingsCompany');
  const title = formatMessage('widgets.companySettings.billsInboxSection.title');
  const content = formatMessage('widgets.companySettings.billsInboxSection.content', {
    link: billsInbox?.learnMoreLink && (
      <Link
        variant="inline"
        href={billsInbox?.learnMoreLink}
        label={formatMessage('widgets.companySettings.billsInboxSection.linkText')}
        newTab
      />
    ),
  });

  return (
    <Container border="regular" paddingX="m" paddingY="m" data-testid="pay-inbox-email">
      <Group variant="vertical" width="full">
        <Box
          borderRadius="full"
          backgroundColor="global.neutral.300"
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="40px"
          width="40px"
          aria-hidden
        >
          <Icon color="default" type="email" aria-hidden />
        </Box>
        <SectionHeader title={title} titleAs="h4" />
        <Box>
          <Box marginBottom={6}>
            <Text>{content}</Text>
          </Box>
          {billsInbox?.showEmailLinkTitle && (
            <Text color="global.neutral.700" textStyle="body4Semi">
              {formatMessage('widgets.companySettings.billsInboxSection.emailLink.title')}
            </Text>
          )}
          <LabelCopy
            testId="bills-inbox-email-link"
            variant="button"
            direction="column"
            size="medium"
            label={billsInboxEmail}
            link={`mailto:${billsInboxEmail}`}
            linkColor="secondary"
            buttonLabel={formatMessage('widgets.billsInbox.copyButtonLabel')}
            buttonAriaLabel={formatMessage('widgets.billsInbox.copyButton.ariaLabel', {
              email: billsInboxEmail,
            })}
            successMessage={formatMessage('widgets.billsInbox.successCopyToast')}
            errorMessage={formatMessage('widgets.billsInbox.errorCopyToast')}
            onCopy={trackCopyEmailCopy}
            onLinkClick={trackCopyEmailLink}
          />
        </Box>
      </Group>
    </Container>
  );
};
