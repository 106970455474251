import {
  Card,
  CardType,
  DeliveryMethodType,
  FeeCatalog,
  FeeType,
  FundingSource,
  FundingSourceType,
} from '@melio/platform-api';

export const roundFee = (fee: number) => Math.round(fee * 100) / 100;

export const getFeeTypeByFundingSource = (fundingSource: FundingSource) =>
  fundingSource.type == FundingSourceType.BankAccount
    ? FeeType.Ach
    : (fundingSource?.details as Card).type === CardType.Credit
    ? FeeType.Credit
    : FeeType.Debit;

export const FeeTypeByDeliveryMethod: Record<string, FeeType> = {
  [DeliveryMethodType.BankAccount]: FeeType.Ach,
  [DeliveryMethodType.PaperCheck]: FeeType.Check,
  [DeliveryMethodType.DomesticWireAccount]: FeeType.DomesticWire,
  [DeliveryMethodType.VirtualAccount]: FeeType.Virtual,
};

export type FeeByTypeProps = {
  fees: FeeCatalog[];
  amount: number;
  freeText: string;
  prefix?: string;
  suffix?: string;
};
