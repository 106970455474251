import { DeliveryMethod } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';
import { useCallback } from 'react';

import { usePaymentProcessingInfo } from './paymentProcessingInfo.hook';

export const useShowMemoToVendor = () => {
  const {
    settings: {
      payment: {
        memoToVendor: {
          deliveryMethodBlackList: memoToVendorDeliveryMethodBlackList,
          allowSendingEmailToVendorWhenAddingMemo,
        },
      },
    },
  } = useConfig();
  const { isPaymentProcessedByCapitalOne } = usePaymentProcessingInfo();

  const shouldDisplayMemoToVendor = useCallback(
    (deliveryMethod: DeliveryMethod) => {
      const { type: deliveryMethodType, details: deliveryMethodDetails } = deliveryMethod;

      if (deliveryMethodType === 'managed-account') {
        if (memoToVendorDeliveryMethodBlackList.includes('managed-account')) {
          return false;
        }

        const key = deliveryMethodDetails.deliveryType;

        if (!key || memoToVendorDeliveryMethodBlackList.includes(`managed-account:${key}`)) {
          return false;
        }
      }

      if (
        memoToVendorDeliveryMethodBlackList.includes(deliveryMethodType) ||
        isPaymentProcessedByCapitalOne(deliveryMethodType)
      ) {
        return false;
      }

      return true;
    },
    [isPaymentProcessedByCapitalOne, memoToVendorDeliveryMethodBlackList]
  );

  return {
    shouldDisplayMemoToVendor,
    allowSendingEmailToVendorWhenAddingMemo,
  };
};

export type ShowMemoToVendor = ReturnType<typeof useShowMemoToVendor>;
