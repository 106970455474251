import { Text } from '@melio/penny';

type SectionHeaderProps = {
  title: string;
  titleAs?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
};

export const SectionHeader = ({ title, titleAs = 'h2' }: SectionHeaderProps) => (
  <Text as={titleAs} textStyle="heading3Semi">
    {title}
  </Text>
);

SectionHeader.displayName = 'SectionHeader';
