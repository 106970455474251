import { useState } from 'react';

import { AddBankAccountPlaidActivityProps, AddBankAccountPlaidStep } from './types';

export const useAddBankAccountPlaidStep = ({
  onFirstStepBack,
}: {
  onFirstStepBack: AddBankAccountPlaidActivityProps['onBack'];
}) => {
  const [currentStep, goToStep] = useState<AddBankAccountPlaidStep>('ADD_BANK_ACCOUNT_PLAID');

  const goToPreviousStep = () => {
    currentStep === 'ADD_BANK_ACCOUNT_PLAID_CONFIRMATION' ? goToStep('ADD_BANK_ACCOUNT_PLAID') : onFirstStepBack();
  };

  return { currentStep, goToStep, goToPreviousStep };
};
