import { Payment } from '@melio/platform-api';

export const getIsVoidAndResendEnabled = (payment?: Payment): boolean => {
  const { isFinanced, paymentActions } = payment ?? {};

  if (!paymentActions) {
    return false;
  }

  return !isFinanced && paymentActions?.voidAndResend.eligible;
};
