/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useRecoilValue } from 'recoil';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { useAccountingPlatforms, useInboxItems } from '@melio/platform-api';
import {
  InboxItemBillTypeEnum,
  InboxItemPaymentRequestTypeEnum,
  InboxItemPaymentTypeEnum,
  InboxItemScannedInvoiceTypeEnum,
} from '@melio/platform-api';

import { Loader } from '@/cl/components/Loader/Loader.component';
import { WithLoading } from '@/hoc/withLoading.hoc';
import { usePartnerConfig } from '@/hooks/partners';
import { usePaydashboardEmptyState } from '@/hooks/payDashboard.hooks';
import { BillDetailsScreen } from '@/screens/pay-dashboard/pay-dashboard-details/BillDetails.screen';
import { PaymentDetailsScreen } from '@/screens/pay-dashboard/pay-dashboard-details/PaymentDetails.screen';
import { PaymentRequestDetailsScreen } from '@/screens/pay-dashboard/pay-dashboard-details/PaymentRequestDetails.screen';
import { ScannedInvoiceDetailsScreen } from '@/screens/pay-dashboard/pay-dashboard-details/ScannedInvoiceDetails.screen';
import { payDashboardSelectedCardSelector } from '@/store/PayDashboard/PayDashboards.model';
import { getQboAccountingId } from '@/utils/accountingPlatform.utils';
import { BillPaymentDetailsEmptyState } from '@/widgets/billPaymentDetailsEmptyState/BillPaymentDetailsEmptyState.widget';
import { EmptyCardSwiper } from '@/widgets/pay-dashboard/emptyCards/EmptyCardSwiper.widget';

export const PayDashboardDetailsScreen = withAnalyticsContext(() => {
  const { selectedInboxItemId, selectedInboxItemType } = useRecoilValue(payDashboardSelectedCardSelector);
  const { partnerConfig } = usePartnerConfig();
  const { data: inboxItemsData, isLoading: inboxItemsIsLoading } = useInboxItems({});

  const {
    refetch: accountingPlatformRefetch,
    isFetching: isAccountingPlatformLoading,
    data: accountingPlatformData,
  } = useAccountingPlatforms();

  const isDashboardEmptyStateEnabled = usePaydashboardEmptyState();

  React.useEffect(() => {
    if (inboxItemsData?.data.length === 0) {
      accountingPlatformRefetch();
    }
  }, [inboxItemsData]);

  if (inboxItemsIsLoading) {
    return <Loader isAbsoluteCenter />;
  }

  const showOnboardingSwiper = partnerConfig.showOnboardingSwiper && !isDashboardEmptyStateEnabled;

  if (inboxItemsData?.data.length === 0 && showOnboardingSwiper) {
    return (
      <WithLoading isLoading={isAccountingPlatformLoading} isAbsoluteCenter>
        <EmptyCardSwiper qboAccountingId={getQboAccountingId(accountingPlatformData)} />{' '}
      </WithLoading>
    );
  }

  if (!selectedInboxItemId || !selectedInboxItemType) {
    return <BillPaymentDetailsEmptyState />;
  }

  if (selectedInboxItemType === InboxItemScannedInvoiceTypeEnum.ScannedInvoice) {
    return <ScannedInvoiceDetailsScreen scannedInvoiceId={selectedInboxItemId} />;
  } else if (selectedInboxItemType === InboxItemBillTypeEnum.Bill) {
    return <BillDetailsScreen billId={selectedInboxItemId} />;
  } else if (selectedInboxItemType === InboxItemPaymentTypeEnum.Payment) {
    return <PaymentDetailsScreen paymentId={selectedInboxItemId} />;
  } else if (selectedInboxItemType === InboxItemPaymentRequestTypeEnum.PaymentRequest) {
    return <PaymentRequestDetailsScreen paymentRequestId={selectedInboxItemId} />;
  }

  return <BillPaymentDetailsEmptyState />;
});
