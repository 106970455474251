import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { useRouter } from '@/hooks/router.hooks';
import { useActiveScreen } from '@/hooks/useActiveScreen';
import { useIsNewDashboardEnabled } from '@/hooks/useIsNewDashboardEnabled';
import { useSelectedVendor } from '@/hooks/vendors.hooks';
import { SingleVendorScreen } from '@/screens/vendors/SingleVendor.screen';
import { VendorsScreen } from '@/screens/vendors/Vendors.screen';
import { ActiveFlowEnum, ScreensEnum } from '@/store/app/app.types';
import { vendorsDashboardSelectedVendorIdSelector } from '@/store/Vendors/Vendors.model';

export const VendorsRoute = () => {
  const { generateNPEDashboardLink } = useRouter();
  useActiveScreen(ScreensEnum.vendors, ActiveFlowEnum.vendors);

  const { selectedVendorId } = useSelectedVendor();
  const setSelectedVendor = useSetRecoilState(vendorsDashboardSelectedVendorIdSelector);

  const shouldRedirectToNewDashboard = useIsNewDashboardEnabled();

  return (
    <Routes>
      <Route
        path={'/'}
        element={
          shouldRedirectToNewDashboard ? (
            <Navigate replace to={generateNPEDashboardLink('vendors')} />
          ) : (
            <VendorsScreen />
          )
        }
      >
        <Route
          path={':vendorId'}
          element={
            shouldRedirectToNewDashboard ? (
              <Navigate replace to={generateNPEDashboardLink('vendors/:vendorId')} />
            ) : (
              <SingleVendorScreen vendorId={selectedVendorId} setVendorId={setSelectedVendor} />
            )
          }
        />
      </Route>
    </Routes>
  );
};
