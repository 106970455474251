import {
  BillSubscription,
  DeliveryMethod,
  FundingSource,
  InvoiceDataSourceEntityTypeEnum,
  usePaymentSettings,
  Vendor,
} from '@melio/platform-api';
import { converDateToStringRepresentation, useDateUtils } from '@melio/platform-utils';

import { CreateFundingSourceActivity } from '../../funding-sources';
import { EditableData } from '../types';

export type AddPaymentMethodActivityProps = {
  formState: Pick<EditableData, 'amount' | 'startDate' | 'fundingSourceId' | 'deliveryMethodId'>;
  vendorId: Vendor['id'];
  invoiceNumber: BillSubscription['invoiceNumber'];
  billSubscriptionId: BillSubscription['id'];
  onClose: VoidFunction;
  onError?: ErrorFunction;
  onDone: (fundingSourceId: FundingSource['id']) => void;
};

const DEFAULT_AMOUNT = 0;

export const AddPaymentMethodActivity = ({
  formState,
  vendorId,
  invoiceNumber = null,
  billSubscriptionId,
  onClose,
  onError,
  onDone,
}: AddPaymentMethodActivityProps) => {
  const { amount, startDate, fundingSourceId, deliveryMethodId } = formState;

  const { createDate } = useDateUtils();
  const DEFAULT_DUE_DATE = createDate();
  const amountToPay = amount || DEFAULT_AMOUNT;

  const { data: [paymentSettings] = [] } = usePaymentSettings({
    payload: [
      {
        dueDate: converDateToStringRepresentation(startDate || DEFAULT_DUE_DATE), // TODO: this conversion should be on the API level
        amountToPay,
        fundingSourceId,
        deliveryMethodId,
        vendorId,
        invoicesData: [
          {
            invoiceNumber,
            amount: amountToPay,
            sourceEntity: { id: billSubscriptionId, type: InvoiceDataSourceEntityTypeEnum.BillSubscription },
          },
        ],
      },
    ],
    params: {
      fillWithDefaults: true,
    },
  });

  return (
    <CreateFundingSourceActivity
      onClose={onClose}
      onError={onError}
      onDone={({ id: fundingSourceId }: FundingSource | DeliveryMethod) => {
        onDone(fundingSourceId);
      }}
      fundingSourceTypesOptions={paymentSettings?.fundingSourceTypesOptions}
    />
  );
};
