import { useMemo } from 'react';
import { useAccounts, useIsMemberOfAccountingFirm, useOrganizations } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';

export const useShouldShowCompaniesLink = () => {
  const [multiOrgUi] = useFeature<boolean>(FeatureFlags.MultiOrgUI, false);
  const [shouldUseOrganizationsEndpointForCompaniesList] = useFeature<boolean>(
    FeatureFlags.UseOrganizationsEndpointForCompaniesList,
    false,
  );
  const { data: accounts = [] } = useAccounts();
  const { data: organizations = [] } = useOrganizations();
  const isUserPartOfFirm = useIsMemberOfAccountingFirm();

  const shouldShowCompaniesLink = useMemo(() => {
    if (!multiOrgUi || isUserPartOfFirm) {
      return false;
    }

    if (shouldUseOrganizationsEndpointForCompaniesList) {
      return organizations.length > 1;
    }

    return accounts.length > 1;
  }, [multiOrgUi, shouldUseOrganizationsEndpointForCompaniesList, accounts, organizations, isUserPartOfFirm]);

  return { shouldShowCompaniesLink };
};
