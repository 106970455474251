import { Box } from '@chakra-ui/react';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { PlaidAccountData } from '@melio/platform-api';
import { forwardRef } from '@melio/platform-utils';

import { AddPlaidAccount } from '../components';

export type AddPlaidFundingSourceScreenProps = {
  onClose: VoidFunction;
  onError: ARErrorFunction;
  onSuccess: (data: PlaidAccountData) => void;
  onLoadToken: (isLoading: boolean) => void;
};

const commonAnalyticsProperties = {
  PageName: 'connect-to-plaid',
  Intent: 'connect-to-plaid',
};

export const AddPlaidFundingSourceScreen = forwardRef<AddPlaidFundingSourceScreenProps, 'iframe'>(
  ({ onClose, onError, onSuccess, onLoadToken, children, ...props }, ref) => {
    useAnalyticsView('PaymentRequest', true, true, commonAnalyticsProperties);

    const { track } = useAnalytics();

    const handleOnSuccess = (data: PlaidAccountData) => {
      track('PaymentRequest', 'Status', {
        ...commonAnalyticsProperties,
        StatusType: 'success',
      });
      onSuccess(data);
    };

    const handleOnError: ARErrorFunction = (error) => {
      track('PaymentRequest', 'Status', {
        ...commonAnalyticsProperties,
        StatusType: 'error',
        ErrorType: 'general-error',
      });
      onError(error);
    };

    return (
      <Box data-testid="plaid-modal" data-component={AddPlaidFundingSourceScreen.displayName} {...props} ref={ref}>
        <AddPlaidAccount
          onSuccess={handleOnSuccess}
          onExit={onClose}
          onError={handleOnError}
          onLoadToken={onLoadToken}
        />
      </Box>
    );
  }
);

AddPlaidFundingSourceScreen.displayName = 'AddPlaidFundingSourceScreen';
