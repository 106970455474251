import { AccountingPlatform, AccountingPlatformSlug } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { object, string } from 'yup';

export const useSchema = (activeAccountingPlatformName: string, disabledNewOption?: boolean) => {
  const { formatMessage } = useMelioIntl();
  if (disabledNewOption) {
    return object().shape({
      accountingPlatformPaymentAccountId: string()
        .required(
          formatMessage(
            'widgets.fundingSources.reconciliationForm.fields.accountingPlatformPaymentAccountId.validation.requiredWithoutNewAccount',
            {
              accountingPlatform: activeAccountingPlatformName,
            }
          )
        )
        .nullable(),
    });
  }

  return object().shape({
    accountingPlatformPaymentAccountId: string()
      .required(
        formatMessage(
          'widgets.fundingSources.reconciliationForm.fields.accountingPlatformPaymentAccountId.validation.required',
          {
            accountingPlatform: activeAccountingPlatformName,
          }
        )
      )
      .nullable(),
  });
};

export const useAccountingPlatformName = (accountingSlug?: AccountingPlatform['accountingSlug']): string => {
  const { formatMessage } = useMelioIntl();

  if (!accountingSlug) {
    return '';
  }

  const accountingPlatformName = formatMessage(
    `widgets.fundingSources.reconciliationForm.accountingPlatformsNames.${accountingSlug}.name`
  );

  return accountingPlatformName;
};

export const useAccountingPlatformBillCategoryLabel = (
  accountingSlug?: AccountingPlatform['accountingSlug']
): string => {
  const accountingPlatformName = useAccountingPlatformName(accountingSlug);
  const { formatMessage } = useMelioIntl();

  if (!accountingSlug) {
    return '';
  }

  return accountingSlug === AccountingPlatformSlug.QuickBooksOnline
    ? formatMessage('form.categorySelect.title.quickbooks')
    : formatMessage('form.categorySelect.title.general', { accountingPlatformName });
};
