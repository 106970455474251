import { Modal } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

export type RequestPlanChangeFromAccountantModalScreenProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const RequestPlanChangeFromAccountantModalScreen = ({
  isOpen,
  onClose,
}: RequestPlanChangeFromAccountantModalScreenProps) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={formatMessage('activities.subscription.requestPlanChangeFromAccountantModal.title')}
      data-testid="subscription-request-plan-change-from-accountant-modal-screen"
      primaryButton={{
        label: formatMessage('activities.subscription.requestPlanChangeFromAccountantModal.done'),
        onClick: onClose,
        variant: 'primary',
      }}
    >
      {formatMessage('activities.subscription.requestPlanChangeFromAccountantModal.description')}
    </Modal>
  );
};
