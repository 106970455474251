import { useBoolean } from '@chakra-ui/react';
import { ARIndustry } from '@melio/ar-domain';
import { useEffect, useState } from 'react';

import { useBusinessDetailsFormContext } from '../../../utils';
import { SelectOptionType } from '../types';

type UseFieldVisibilityProps = {
  industries: ARIndustry[];
};

export const useBusinessDetailsState = ({ industries }: UseFieldVisibilityProps) => {
  const { watch } = useBusinessDetailsFormContext();
  const [shouldShowIrsName, showIrsName] = useBoolean(true);
  const [shouldShowDeliveryAddressFields, showDeliveryAddressFields] = useBoolean(false);
  const [industrySubCategoryOptions, setIndustrySubCategoryOptions] = useState<SelectOptionType[]>([]);

  const isBusinessNameConsistent = watch('isBusinessNameConsistent');
  const isDeliveryAddressConsistent = watch('isDeliveryAddressConsistent');
  const industryValue = watch('industry');

  useEffect(() => {
    if (isBusinessNameConsistent) {
      showIrsName.off();
    } else {
      showIrsName.on();
    }
  }, [isBusinessNameConsistent, showIrsName]);

  useEffect(() => {
    if (isDeliveryAddressConsistent) {
      showDeliveryAddressFields.off();
    } else {
      showDeliveryAddressFields.on();
    }
  }, [isDeliveryAddressConsistent, showDeliveryAddressFields]);

  useEffect(() => {
    const industry = industries.find((industry) => industry.id.toString() === industryValue);
    const subIndustries =
      industry?.subIndustries?.map((subIndustry) => ({
        label: subIndustry.name,
        value: subIndustry.code,
      })) || [];
    setIndustrySubCategoryOptions(subIndustries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [industryValue]);

  return { industryValue, industrySubCategoryOptions, shouldShowIrsName, shouldShowDeliveryAddressFields };
};
