import { useToast } from '@melio/penny';
import { FileInfo, PaymentIntent, useBills, useFiles } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import React, { useState } from 'react';

import { InvoiceAttachmentModalScreen } from './screens';
import { InvoiceAttachmentFormFields, InvoiceBillDetails } from './types';

export type InvoiceAttachmentUpdateData = {
  invoice: {
    fileId: string;
  };
};

type InvoiceAttachmentActivityModalProps = {
  onClose: VoidFunction;
  onDone: () => void;
  paymentIntentId: PaymentIntent['id'];
  isOpen: boolean;
  billDetails: InvoiceBillDetails;
};

export const InvoiceAttachmentActivity = (props: InvoiceAttachmentActivityModalProps) => {
  const { isOpen, onDone, onClose, billDetails } = props;
  const { create: createFile } = useFiles({ enabled: false });
  const { update: updateBill } = useBills({ enabled: false });
  const [isSaving, setIsSaving] = useState(false);
  const { formatMessage } = useMelioIntl();
  const { toast } = useToast();

  const onInvoiceUploaded = async ({ invoice }: InvoiceAttachmentFormFields) => {
    if (invoice) {
      setIsSaving(true);
      try {
        const uploadedInvoice: FileInfo = await createFile(invoice);

        if (uploadedInvoice?.id) {
          const data = { invoice: { fileId: uploadedInvoice.id } };
          await updateBill(billDetails.billId, data);
          onDone();
        }
      } catch (err) {
        toast({
          type: 'error',
          title: (err as PlatformError).message || formatMessage('activities.invoiceAttachment.generalError'),
        });
      } finally {
        setIsSaving(false);
      }
    }
  };

  return (
    <InvoiceAttachmentModalScreen
      isOpen={isOpen}
      onClose={onClose}
      onDone={onInvoiceUploaded}
      isSaving={isSaving}
      billDetails={billDetails}
    />
  );
};

InvoiceAttachmentActivity.displayName = 'InvoiceAttachmentActivity';
