import { PropsWithChildren, useRef, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { Button } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';

import { useAppTheme } from '@/cl/theme/theme.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { Swiper, SwiperHandler } from './Swiper.component';
import { SwiperWizardTheme } from './SwiperWizard.component.theme';

type WizardStep = {
  actionText?: string;
  actionCallback?: () => void;
};

type Props = PropsWithChildren<{
  steps: WizardStep[];
  isButtonLoading?: boolean;
}>;

export const SwiperWizard = ({ steps, children, isButtonLoading }: Props) => {
  const swiperRef = useRef<SwiperHandler>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const { formatMessage } = usePlatformIntl();
  const { track } = useAnalytics();

  const styles = useAppTheme(SwiperWizardTheme, {});

  const renderDots = () => {
    return steps.map((step, index) => (
      <Box
        tabIndex={0}
        aria-label={`slider button ${index}`}
        role="checkbox"
        key={index}
        sx={styles[index === activeIndex ? 'activeDot' : 'dot']}
        onClick={() => {
          swiperRef.current?.slideTo(index + 1);
        }}
      />
    ));
  };
  const getActiveActionButton = () => {
    const button = steps[activeIndex];
    if (button && button.actionText) {
      return (
        <Button
          isLoading={isButtonLoading}
          variant="secondary"
          onClick={button.actionCallback}
          label={button.actionText}
        />
      );
    }
    return null;
  };
  const onNextButtonClicked = () => {
    track('PayDashboardCaruseleNext', 'Chose');
    swiperRef.current?.slideNext();
  };
  return (
    <Box data-component={'swiperWizard'} sx={styles['container']}>
      <Box sx={styles['dotContainer']}>{renderDots()}</Box>
      <Box sx={styles['swiperBody']}>
        <Swiper
          loop
          allowTouchMove={false}
          ref={swiperRef}
          speed={750}
          slidesPerView={1}
          onSlideChange={(swiper) => {
            setActiveIndex(swiper.activeIndex > steps.length ? 0 : swiper.activeIndex - 1);
          }}
        >
          {children}
        </Swiper>
      </Box>
      <Box sx={styles['footerContainer']}>
        {getActiveActionButton()}
        <Button
          onClick={onNextButtonClicked}
          variant={'tertiary'}
          isDisabled={isButtonLoading}
          label={formatMessage('componentLibrary.swiperWizard.nextButton')}
        />
      </Box>
    </Box>
  );
};
