import { Loan, Payment } from '@melio/platform-api';
import { addDays } from 'date-fns';

const FINANCING_FAILED_TO_DELIVER_CANCEL_LOAN_NUMBER_OF_DAYS = 14;
const FINANCING_UNDEPOSITED_CHECK_CANCEL_LOAN_NUMBER_OF_DAYS = 20;
const FINANCING_FAILED_TO_COLLECT_HAND_OFF_NUMBER_OF_DAYS = 14;

const getFirstFailedInstallmentDate = (loan?: Loan): Date | undefined =>
  loan?.installments.find((installment) => installment.status === 'failed')?.scheduledDate;

export const getFinancedPaymentFailureDate = (failedFinancedPayment: Payment): Date | undefined =>
  getFirstFailedInstallmentDate(failedFinancedPayment.loan);

export const getFinancingHandedOffDeadlineDate = (failedLoan: Loan) => {
  const firstFailedInstallmentDate = getFirstFailedInstallmentDate(failedLoan);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return addDays(firstFailedInstallmentDate!, FINANCING_FAILED_TO_COLLECT_HAND_OFF_NUMBER_OF_DAYS);
};

export const getFinancingCancelDeadlineDate = (payment: Payment) => {
  const failedDate = payment.scheduledDate;

  return addDays(failedDate, FINANCING_FAILED_TO_DELIVER_CANCEL_LOAN_NUMBER_OF_DAYS);
};

export const getFinancedPaymentCancelDate = (canceledFinancedPayment: Payment): Date | undefined =>
  canceledFinancedPayment.deliveryMethod?.type === 'paper-check'
    ? addDays(canceledFinancedPayment.scheduledDate, FINANCING_UNDEPOSITED_CHECK_CANCEL_LOAN_NUMBER_OF_DAYS)
    : getFinancingCancelDeadlineDate(canceledFinancedPayment);

export const getFinancedPaymentFailureOrCancelDate = (failedOrCanceledFinancedPayment: Payment): Date | undefined =>
  failedOrCanceledFinancedPayment.loan?.status === 'failed'
    ? getFinancedPaymentFailureDate(failedOrCanceledFinancedPayment)
    : getFinancedPaymentCancelDate(failedOrCanceledFinancedPayment);
