import { ApplyForFinancingBusinessType } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export const useBusinessTypeOptions = () => {
  const { formatMessage } = useMelioIntl();

  return [
    {
      label: formatMessage('activities.financingApplication.businessDetailsForm.BusinessType.options.corporation'),
      value: ApplyForFinancingBusinessType.Corporation,
      testId: ApplyForFinancingBusinessType.Corporation,
    },
    {
      label: formatMessage('activities.financingApplication.businessDetailsForm.BusinessType.options.federal'),
      value: ApplyForFinancingBusinessType.Federal,
      testId: ApplyForFinancingBusinessType.Federal,
    },
    {
      label: formatMessage('activities.financingApplication.businessDetailsForm.BusinessType.options.LLC'),
      value: ApplyForFinancingBusinessType.Llc,
      testId: ApplyForFinancingBusinessType.Llc,
    },
    {
      label: formatMessage('activities.financingApplication.businessDetailsForm.BusinessType.options.municipal'),
      value: ApplyForFinancingBusinessType.Municipal,
      testId: ApplyForFinancingBusinessType.Municipal,
    },
    {
      label: formatMessage(
        'activities.financingApplication.businessDetailsForm.BusinessType.options.nonProfitCorporation'
      ),
      value: ApplyForFinancingBusinessType.NonprofitCorporation,
      testId: ApplyForFinancingBusinessType.NonprofitCorporation,
    },
    {
      label: formatMessage('activities.financingApplication.businessDetailsForm.BusinessType.options.partnership'),
      value: ApplyForFinancingBusinessType.Partnership,
      testId: ApplyForFinancingBusinessType.Partnership,
    },
    {
      label: formatMessage(
        'activities.financingApplication.businessDetailsForm.BusinessType.options.soleProprietorship'
      ),
      value: ApplyForFinancingBusinessType.SoleProprietorship,
      testId: ApplyForFinancingBusinessType.SoleProprietorship,
    },
  ];
};
