import {
  apiClient,
  BulkPostPaymentSettingsCalculatorRequest,
  Params,
  PatchPaymentIntentsBulkRequest,
  PaymentIntent,
  PaymentIntentsApiGetPaymentIntentsRequest,
  PostPaymentIntentsFromBillBulkRequest,
  PostPaymentIntentsFromBillRequest,
  PostPaymentIntentsFromPaymentRequest,
  PostPaymentIntentsRequest,
  PostPaymentSettingsCalculatorBulkResponse,
} from '@melio/javascript-sdk';
import { converDateToStringRepresentation } from '@melio/platform-utils';

import { BatchUpdateResult, useCollectionApi, UseCollectionApiProps } from '../../core';
import { useBatchCreateMutation } from '../../core/useBatchCreateMutation';
import { useBatchUpdateMutation } from '../../core/useBatchUpdateMutation';
import { useCreateMutation } from '../../core/useCreateMutation';
import { PaymentIntentCollection } from './types';
import {
  convertBatchCreatePaymentIntentFromBillDollarsToCents,
  convertBatchUpdateParamsDollarsToCents,
  convertBatchUpdateResultsCentsToDollars,
  convertCreateParamsDollarsToCents,
  convertCreatePaymentIntentFromBillDollarsToCents,
  convertGetPaymentSettingsParamDollarsToCents,
  convertGetPaymentSettingsResponseCentsToDollars,
  convertPaymentIntentCentsToDollars,
  convertPaymentIntentsCentsToDollars,
} from './utils';

type UsePaymentIntensProps = UseCollectionApiProps<PaymentIntent> & {
  params?: PaymentIntentsApiGetPaymentIntentsRequest;
  convertCurrency?: boolean;
};

export const usePaymentIntents = ({
  convertCurrency = true,
  ...props
}: UsePaymentIntensProps = {}): PaymentIntentCollection => {
  const client = apiClient.paymentIntents();
  const query = useCollectionApi<PaymentIntent, PostPaymentIntentsRequest>({
    ...props,
    queryKey: ['payment-intent', props.params],
    queryFn: () => client.fetch(props.params),
  });

  const create = useCreateMutation<PaymentIntent, PostPaymentIntentsRequest>(
    (data) => client.create(prepareCreateData(data)),
    query
  );

  const createFromBill = useCreateMutation<PaymentIntent, PostPaymentIntentsFromBillRequest>(
    (data) => client.createFromBill(data),
    query
  );

  const batchCreateFromBill = useBatchCreateMutation<PaymentIntent, PostPaymentIntentsFromBillBulkRequest>(
    ({ data, params }: { data: PostPaymentIntentsFromBillBulkRequest; params?: Params }) =>
      client.batchCreateFromBill(data, params),
    query
  );

  const createFromPayment = useCreateMutation<PaymentIntent, PostPaymentIntentsFromPaymentRequest>(
    (params) => client.createFromPayment(params),
    query
  );

  type UpdateArgs = {
    data: PatchPaymentIntentsBulkRequest;
    params?: Params;
  };

  const update = useBatchUpdateMutation<
    PaymentIntent,
    BatchUpdateResult<PaymentIntent>,
    PatchPaymentIntentsBulkRequest,
    Params
  >(
    ({ data, params }: UpdateArgs) =>
      client.batchUpdate(prepareUpdateData(data), params).then(
        (results) =>
          results.map((result) =>
            result.status === 'error'
              ? {
                  id: result.id,
                  error: { message: result.errorMessage },
                  status: result.status,
                }
              : result
          ) || []
      ),
    query
  );

  const res = {
    ...query,
    create,
    createFromBill,
    batchCreateFromBill,
    createFromPayment,
    update,
    getPaymentSettings: client.getPaymentSettings,
  };

  return convertCurrency ? convertMoneyUnits(res) : res;
};

const convertMoneyUnits = (model: PaymentIntentCollection): PaymentIntentCollection => ({
  ...model,
  data: model.data?.map(convertPaymentIntentCentsToDollars),
  create: (data: PostPaymentIntentsRequest) =>
    model.create(convertCreateParamsDollarsToCents(data)).then(convertPaymentIntentCentsToDollars),
  createFromBill: (data: PostPaymentIntentsFromBillRequest) =>
    model
      .createFromBill(convertCreatePaymentIntentFromBillDollarsToCents(data))
      .then(convertPaymentIntentCentsToDollars),
  createFromPayment: (data: PostPaymentIntentsFromPaymentRequest) =>
    model.createFromPayment(data).then(convertPaymentIntentCentsToDollars),
  batchCreateFromBill: (data: PostPaymentIntentsFromBillBulkRequest, params?: Params) =>
    model
      .batchCreateFromBill(
        {
          ...data,
          paymentIntents: prepareCreateBatchFromBillRequestParam(
            convertBatchCreatePaymentIntentFromBillDollarsToCents(data.paymentIntents)
          ),
        },
        params
      )
      .then(convertPaymentIntentsCentsToDollars),
  update: (data: PatchPaymentIntentsBulkRequest, params?: Params) =>
    model.update(convertBatchUpdateParamsDollarsToCents(data), params).then(convertBatchUpdateResultsCentsToDollars),
  getPaymentSettings: (params) =>
    model.getPaymentSettings(prepareGetSettingsRequestParam(params)).then(prepareGetSettingsResponse),
});

const prepareGetSettingsResponse = (data: PostPaymentSettingsCalculatorBulkResponse['data']) =>
  convertGetPaymentSettingsResponseCentsToDollars(data);

const prepareGetSettingsRequestParam = (param: BulkPostPaymentSettingsCalculatorRequest) =>
  convertGetPaymentSettingsParamDollarsToCents(
    param.map((item) => ({
      ...item,
      ...(item.scheduleDate ? { scheduleDate: converDateToStringRepresentation(item.scheduleDate) } : null),
      ...(item.dueDate ? { dueDate: converDateToStringRepresentation(item.dueDate) } : null),
    }))
  );

const prepareCreateBatchFromBillRequestParam = (
  paymentIntents: PostPaymentIntentsFromBillBulkRequest['paymentIntents']
) =>
  paymentIntents.map((paymentIntent) => ({
    ...paymentIntent,
    ...(paymentIntent.scheduledDate
      ? { scheduledDate: converDateToStringRepresentation(paymentIntent.scheduledDate) }
      : null),
  }));

const prepareUpdateData = (batchUpdate: PatchPaymentIntentsBulkRequest) =>
  batchUpdate.map((item) => ({
    ...item,
    data: {
      ...item.data,
      // XXX hack to get around payment intent expects the scheduledDate to be YYYY-MM-DD and not a standard ISO date string
      // https://linear.app/meliopayments/issue/PLA-439
      scheduledDate:
        typeof item.data.scheduledDate === 'string'
          ? ((item.data.scheduledDate as string).substring(0, 10) as never)
          : converDateToStringRepresentation(item.data.scheduledDate),
      deliveryDate:
        typeof item.data.deliveryDate === 'string'
          ? ((item.data.deliveryDate as string).substring(0, 10) as never)
          : converDateToStringRepresentation(item.data.deliveryDate),
    },
  }));

const prepareCreateData = (params: PostPaymentIntentsRequest) => ({
  ...params,
  billInfo: {
    ...params.billInfo,
    // XXX hack to get around payment intent expects the dueDate to be YYYY-MM-DD and not a standard ISO date string
    // https://linear.app/meliopayments/issue/PLA-439
    dueDate: converDateToStringRepresentation(params.billInfo.dueDate),
  },
});
