import { Box } from '@chakra-ui/react';
import { CompanyAvatarWidget } from '@melio/ap-widgets';
import { Card, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { Account } from '@melio/platform-api';

import { useAppTheme } from '@/cl/theme/theme.hooks';
import { PaymentsOverview } from '../PaymentsOverview/PaymentsOverview.component';
import { AccountCardTheme } from './AccountCard.component.theme';

export type AccountCardProps = {
  companyName: string;
  companyId: string;
  isPaymentsOverviewLoading: boolean;
  paymentsOverview?: Account<'billsAndPaymentsOverview'>['billsAndPaymentsOverview'];
  onClick: VoidFunction;
  isSelected: boolean;
  companyLogo?: string;
};

export const AccountCard = ({
  companyName,
  companyId,
  isPaymentsOverviewLoading,
  paymentsOverview,
  onClick,
  isSelected,
  companyLogo,
}: AccountCardProps) => {
  const styles = useAppTheme(AccountCardTheme, { isSelected: false });
  const { track } = useAnalytics();

  const onCardClicked = () => {
    track('SwitchCompanyFromCompanyPage', 'Chose');
    onClick();
  };

  return (
    <Card onClick={onCardClicked} data-component="company-card">
      <Box sx={styles['container']} data-testid={`company-card-${companyId}`}>
        <Box sx={styles['companyDetails']}>
          <CompanyAvatarWidget size="medium" isSelected={isSelected} src={companyLogo} name={companyName} />
          <Box sx={styles['companyName']}>
            <Text textStyle="body2Semi">{companyName}</Text>
          </Box>
        </Box>
        <PaymentsOverview isPaymentsOverviewLoading={isPaymentsOverviewLoading} paymentsOverview={paymentsOverview} />
      </Box>
    </Card>
  );
};
