import { Group, Popover, StatusIconOutlined, Table, TableCellProps, Text } from '@melio/penny';
import { Bill } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';

export type BillDetailsSubCellProps = TableCellProps & {
  bill: Pick<Bill, 'invoice' | 'paymentRequest'>;
  onViewBillDetailsClick: () => void;
};

export const BillDetailsSubCell = forwardRef<BillDetailsSubCellProps, 'div'>(
  ({ bill, onViewBillDetailsClick, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const shouldShowInvoiceNumberTooltip = bill.invoice.number && bill.paymentRequest;

    return (
      <Table.Cell data-testid="bill-details-sub-cell" {...props} data-component="BillDetailsSubCell" ref={ref}>
        <Group spacing="xs" alignItems="center">
          <Text
            textStyle="body4"
            color={bill.invoice.number ? 'global.neutral.1000' : 'global.neutral.600'}
            shouldSupportEllipsis
          >
            {bill.invoice.number
              ? formatMessage(
                  'activities.batchPayments.screens.paymentIntentsTable.billDetailsCell.billNumber.number',
                  {
                    invoiceNumber: bill.invoice.number,
                  }
                )
              : formatMessage(
                  'activities.batchPayments.screens.paymentIntentsTable.billDetailsCell.billNumber.placeholder'
                )}
          </Text>
          {shouldShowInvoiceNumberTooltip && (
            <Popover
              description={formatMessage(
                'activities.batchPayments.screens.paymentIntentsTable.billDetailsCell.billNumber.tooltip.description'
              )}
              actionProps={{
                onClick: onViewBillDetailsClick,
                label: formatMessage(
                  'activities.batchPayments.screens.paymentIntentsTable.billDetailsCell.billNumber.tooltip.actionLabel'
                ),
              }}
              triggerEvent="hover"
            >
              <StatusIconOutlined variant="informative" size="small" data-testid="invoice-number-tooltip" />
            </Popover>
          )}
        </Group>
      </Table.Cell>
    );
  }
);

BillDetailsSubCell.displayName = 'BillDetailsSubCell';
