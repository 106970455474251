import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const VendorIcon = (props: IconProps) => (
  <Icon width="20px" height="20px" viewBox="0 0 20 20" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.74999 10C10.3608 10 11.6667 8.69416 11.6667 7.08333C11.6667 5.4725 10.3608 4.16667 8.74999 4.16667C7.13916 4.16667 5.83332 5.4725 5.83332 7.08333C5.83332 8.69416 7.13916 10 8.74999 10ZM8.74999 8.33333C9.44035 8.33333 9.99999 7.77369 9.99999 7.08333C9.99999 6.39298 9.44035 5.83333 8.74999 5.83333C8.05963 5.83333 7.49999 6.39298 7.49999 7.08333C7.49999 7.77369 8.05963 8.33333 8.74999 8.33333Z"
    />
    <path d="M8.74999 10C5.90435 10 3.5507 12.1617 3.33581 14.9356C3.30027 15.3945 3.64343 15.7953 4.10229 15.8308C4.56116 15.8664 4.96195 15.5232 4.9975 15.0644C5.14372 13.1769 6.75617 11.6667 8.74999 11.6667C10.7438 11.6667 12.3563 13.1769 12.5025 15.0644C12.538 15.5232 12.9388 15.8664 13.3977 15.8308C13.8566 15.7953 14.1997 15.3945 14.1642 14.9356C13.9493 12.1617 11.5956 10 8.74999 10Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.33333 0H14.1667C16 0 17.5 1.5 17.5 3.25V16.75C17.5 18.5 16 20 14.1667 20H3.33333C1.5 20 0 18.5 0 16.6667V3.25C0 1.5 1.5 0 3.33333 0ZM14.1667 18.3333C15.0833 18.3333 15.8333 17.6667 15.8333 16.75V3.25C15.8333 2.41667 15.0833 1.66667 14.1667 1.66667H3.33333C2.41667 1.66667 1.66667 2.41667 1.66667 3.25V16.75C1.66667 17.5833 2.41667 18.3333 3.33333 18.3333H14.1667Z"
    />
    <path d="M19.1667 2.5C18.6667 2.5 18.3333 2.83333 18.3333 3.33333V5.83333C18.3333 6.33333 18.6667 6.66667 19.1667 6.66667C19.6667 6.66667 20 6.33333 20 5.83333V3.33333C20 2.83333 19.6667 2.5 19.1667 2.5Z" />
    <path d="M18.3333 8.33333C18.3333 7.83333 18.6667 7.5 19.1667 7.5C19.6667 7.5 20 7.83333 20 8.33333V10.8333C20 11.3333 19.6667 11.6667 19.1667 11.6667C18.6667 11.6667 18.3333 11.3333 18.3333 10.8333V8.33333Z" />
    <path d="M19.1667 12.5C18.6667 12.5 18.3333 12.8333 18.3333 13.3333V15.8333C18.3333 16.3333 18.6667 16.6667 19.1667 16.6667C19.6667 16.6667 20 16.3333 20 15.8333V13.3333C20 12.8333 19.6667 12.5 19.1667 12.5Z" />
  </Icon>
);
