import { ComponentThemeType } from '@/cl/theme/theme.hooks';
import { zIndex } from '../layout.config';

export const LeftLayoutTheme: ComponentThemeType<{ splitContactsTab: boolean }> = (
  currentTheme,
  { splitContactsTab },
) => ({
  baseStyle: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      paddingTop: '24px',
      paddingBottom: splitContactsTab ? '16px' : '24px',
      width: '72px',
      borderRight: 'basic.light',
      backgroundColor: currentTheme.colors.global.neutral['100'],
    },
    topContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: splitContactsTab ? '32px' : '28px',
    },
    bottomContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderTop: 'basic.light',
      paddingTop: '24px',
      marginX: '12px',
      gap: splitContactsTab ? '32px' : '20px',
    },
    supportButton: {
      cursor: 'pointer',
    },
    dividerContainer: {
      paddingX: '12px',
    },
    containerMobile: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'global.neutral.100',
      width: '100%',
      height: '100%',
      zIndex: zIndex.mobileContainer,
      top: 0,
      left: 0,
    },
    closeMenuMobile: {
      height: '56px',
      padding: '16px',
    },
    topContainerMobile: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '28px',
      padding: '16px',
      paddingTop: '24px',
    },
    bottomContainerMobile: {
      display: 'flex',
      alignItems: 'center',
      padding: '16px',
      gap: '16px',
    },
    logoutMobile: {
      padding: '8px',
    },
    userEmailMobile: {
      color: 'global.neutral.800',
    },
  },
});
