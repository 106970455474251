import { EventProperties } from '@melio/platform-analytics';
import { FeatureFlags } from '@melio/platform-feature-flags';

export enum PromotionType {
  CardToCard = 'CardToCard',
  QBOSync = 'QBOSync',
}

type Flags = {
  featureFlag: FeatureFlags;
  userPreferenceKey: string;
};

export const PromotionFlags: Record<PromotionType, Flags> = {
  [PromotionType.CardToCard]: {
    featureFlag: FeatureFlags.PlatformScheduledPaymentCardToCardPromotionEnabled,
    userPreferenceKey: 'isC2CPromotionDismissed',
  },
  [PromotionType.QBOSync]: {
    featureFlag: FeatureFlags.PlatformScheduledPaymentAccountingPlatformSyncPromotion,
    userPreferenceKey: 'isSyncPromotionDismissed',
  },
};

export type PromotionProps = {
  analyticProperties: EventProperties;
  onClose(): void;
  onDone(): void;
};
