import { apiClient, PaymentRequest } from '@melio/javascript-sdk';

import { useModelApi, UseModelApiProps } from '../../core';
import { useUpdateMutation } from '../../core/useUpdateMutation';
import { PaymentRequestModel } from './types';
import { convertPaymentRequestCentsToDollars } from './utils';

export const usePaymentRequest = (props: UseModelApiProps<PaymentRequest>): PaymentRequestModel => {
  const client = apiClient.paymentRequests();

  const model = useModelApi<PaymentRequest, void>({
    ...props,
    queryKey: 'payment-request',
    queryFn: () => client.get(props.id as string),
  });

  return convertMoneyUnits({
    ...model,
    cancel: useUpdateMutation<PaymentRequest, void>(async () => client.cancel(props.id as string), model),
    approve: useUpdateMutation<PaymentRequest, void>(async () => client.approve(props.id as string), model),
  });
};

// TODO: https://meliorisk.atlassian.net/browse/ME-19759
const convertMoneyUnits = (model: PaymentRequestModel): PaymentRequestModel => ({
  ...model,
  data: model.data ? convertPaymentRequestCentsToDollars(model.data) : undefined,
});
