/* eslint-disable react-hooks/exhaustive-deps */
import { FundingSource, FundingSourceType } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';
import { useBoolean } from '@melio/platform-utils';
import { useCallback, useEffect, useState } from 'react';

type UseGoodsReceivedProps = { paymentAmount: number };

export const useGoodsReceived = ({ paymentAmount }: UseGoodsReceivedProps) => {
  const [goodsReceived, setGoodsReceived] = useState<boolean>(true);
  const [goodsReceivedFormWasFilled, setGoodsReceivedFormWasFilled] = useState(false);
  const [shouldOpenGoodsReceivedModal, openGoodsReceivedModal] = useBoolean(false);
  const { goodsReceivedAmountThreshold } = useConfig().settings;

  const [disableGoodsReceivedFundingSourcesTypes, setDisableGoodsReceivedFundingSourcesTypes] = useState<
    FundingSourceType[]
  >([]);

  useEffect(() => {
    setDisableGoodsReceivedFundingSourcesTypes(goodsReceived ? [] : [FundingSourceType.Card]);
  }, [goodsReceived]);

  const handleDoneGoodsReceived = (selectedGoodsReceived: boolean) => {
    setGoodsReceived(selectedGoodsReceived);
    setGoodsReceivedFormWasFilled(true);
    openGoodsReceivedModal.off();
  };

  const handleCloseGoodsReceived = (onClose: VoidFunction) => () => {
    onClose();
    openGoodsReceivedModal.off();
  };

  const shouldRunGoodsReceived = useCallback(
    (fundingSource: FundingSource) =>
      !goodsReceivedFormWasFilled && paymentAmount > goodsReceivedAmountThreshold && fundingSource?.type === 'card',
    [goodsReceivedFormWasFilled, shouldOpenGoodsReceivedModal, goodsReceivedAmountThreshold, paymentAmount]
  );

  const runGoodsReceivedFlow = () => {
    openGoodsReceivedModal.on();
  };

  return {
    goodsReceived,
    shouldOpenGoodsReceivedModal,
    shouldRunGoodsReceived,
    runGoodsReceivedFlow,
    disableGoodsReceivedFundingSourcesTypes,
    goodsReceivedFormWasFilled,
    goodsReceivedAmountThreshold,
    handleDoneGoodsReceived,
    handleCloseGoodsReceived,
  };
};
