import { useEffect, useRef } from 'react';
import {
  emitFocusEvent,
  FocusEvents,
  useFocusOnEvent,
} from '@melio/ap-activities/src/components/PayDashboard/FocusSkipToComponent';

export const useAutoFocusMoreActionsButton = (isEditMode: boolean) => {
  const drawerHaveBeenInEditModeAlready = useRef(isEditMode);
  const moreActionsButtonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isEditMode) {
      drawerHaveBeenInEditModeAlready.current = true;
      return;
    }

    if (drawerHaveBeenInEditModeAlready.current && moreActionsButtonRef.current) {
      emitFocusEvent(FocusEvents.VENDOR_DRAWER_MORE_ACTIONS_BUTTON);
    }
  }, [isEditMode]);

  useFocusOnEvent({
    event: FocusEvents.VENDOR_DRAWER_MORE_ACTIONS_BUTTON,
    ref: moreActionsButtonRef,
  });

  return { moreActionsButtonRef };
};
