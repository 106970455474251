import { PaymentsSortQueryParam } from '@melio/platform-api';

import { PayDashboardSortingType } from '../../types';

export const convertApprovalsTabSortToApiSort = ({
  id,
  sortDirection,
}: NonNullable<PayDashboardSortingType['sortingState']>): PaymentsSortQueryParam[] => {
  if (id === 'vendorName') {
    return [{ field: 'vendor.name', order: sortDirection }];
  }
  if (id === 'amount') {
    return [
      { field: 'amount', order: sortDirection },
      { field: 'vendor.name', order: 'asc' },
    ];
  }
  return [
    { field: 'scheduledDate', order: sortDirection },
    { field: 'vendor.name', order: 'asc' },
  ];
};

export const getFooterHeight = () => {
  const footerElement = document.getElementById('payments-selection-footer');
  return footerElement ? footerElement.offsetHeight : 0;
};
