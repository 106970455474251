import { useNonPositiveAmountsEffect } from '@melio/ap-domain';
import { useAnalytics } from '@melio/platform-analytics';
import {
  BillSubscription,
  Payment,
  PaymentApprovalDecisionStatusEnum,
  useBill,
  usePayments,
  useVendor,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils';
import React, { ComponentProps } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import { useActivitiesNavigate } from '../../../utils';
import { PayDashboardTabs } from '../../PayDashboard';
import { PaymentScheduledActivity } from '../../PaymentScheduled';
import { RecurringPaymentScheduledActivity } from '../../RecurringPaymentScheduled';
import { PaymentFlowDoneAction } from '../../types';
import { PaymentFlowLoader } from '../components/PaymentFlowLoader';
import { PaymentFlowActivity } from '../PaymentFlowActivity/PaymentFlowActivity';
import { useSubmitErrorHandler } from '../util/useSubmitErrorHandler/useSubmitErrorHandler';
import { useCreatePaymentSubmit } from './useCreatePaymentSubmit';

type HandleDoneParams = { paymentId: string | undefined; isPendingApproval: boolean; action: PaymentFlowDoneAction };
type CreatePaymentActivityProps = {
  billId?: string;
  vendorId?: string;
  deliveryMethodId?: string;
  amountToPay?: string;
  onLoginToAccountPlatformAuth: VoidFunction;
  returnUrl?: string;
};

export const CreatePaymentActivity = ({
  billId,
  vendorId,
  deliveryMethodId,
  amountToPay,
  returnUrl,
  onLoginToAccountPlatformAuth,
}: CreatePaymentActivityProps) => {
  const { formatMessage } = useMelioIntl();
  const navigate = useNavigate();

  const {
    submit,
    submitting: isSubmitting,
    result: paymentOrSubscription,
    error: submitError,
  } = useCreatePaymentSubmit({
    billId,
    onError: useSubmitErrorHandler(),
  });

  const { data: [payment] = [], isLoading: isPaymentsLoading } = usePayments({
    params: {
      limit: 1,
      expand: 'none',
      sort: [{ field: 'createdAt', order: 'desc' }],
    },
  });

  const { data: bill, isLoading: isBillLoading } = useBill({
    enabled: Boolean(billId),
    id: billId || 'CreatePaymentActivityBillId',
    params: { expand: 'none' },
  });

  const defaultVendorId = bill?.vendorId || vendorId;

  const { data: vendor, isLoading: isVendorLoading } = useVendor({
    enabled: Boolean(defaultVendorId),
    id: defaultVendorId || 'CreatePaymentActivityVendorId',
  });

  const defaultValues: ComponentProps<typeof PaymentFlowActivity>['defaultValues'] = {
    vendorId: vendor?.id,
    vendorEmail: vendor?.contact.email,
    deliveryMethodId,
    amountToPay: bill?.balance.toString() || amountToPay,
    noteToVendor: bill?.invoice.number
      ? formatMessage('activities.paymentFlow.form.content.defaultMemo', {
          invoiceNumber: bill.invoice.number,
        })
      : undefined,
    fundingSourceId: payment?.fundingSourceId,
  };
  const { navigateToTab, navigateToScheduleNewPayment } = useActivitiesNavigate();
  const { track } = useAnalytics();
  const { showMessage } = useSystemMessage();

  const handleClose = () => {
    if (returnUrl) {
      return navigate(returnUrl, { state: { keepToast: true } });
    }

    navigateToTab(PayDashboardTabs.Bills, {
      entityId: billId,
    });
  };

  const handleDone = ({ action, paymentId, isPendingApproval }: HandleDoneParams) => {
    switch (action) {
      case 'goToDashboard':
        return handleGoToDashboard({ paymentId, isPendingApproval });
      case 'createNewPayment':
        return navigateToScheduleNewPayment({});
      case 'syncQBOAccountingPlatform':
        return onLoginToAccountPlatformAuth();
      default:
        break;
    }
  };

  const handleGoToDashboard = ({
    paymentId,
    isPendingApproval,
  }: Pick<HandleDoneParams, 'paymentId' | 'isPendingApproval'>) => {
    if (isPendingApproval) {
      showMessage({
        type: 'informative',
        title: formatMessage('screens.scheduleSinglePayment.toast.success.single.pending.title'),
        dataTestId: 'create-payment-success-message',
        action: paymentId
          ? {
              type: 'button',
              text: formatMessage('screens.scheduleSinglePayment.toast.success.single.action'),
              onAction: (closeToast) => {
                navigateToTab(PayDashboardTabs.Payments, { entityId: paymentId });
                closeToast();
                track('Dashboard', 'Click', {
                  PageName: 'payment-pending-approval',
                  Flow: 'dashboard',
                  Intent: 'view-approvals',
                  Status: 'success',
                  Cta: 'view-payment',
                  PaymentId: paymentId,
                });
              },
            }
          : undefined,
      });
    } else {
      showMessage({
        type: 'success',
        title: formatMessage('screens.scheduleSinglePayment.toast.success.single.title'),
        dataTestId: 'create-payment-success-message',
        action: paymentId
          ? {
              type: 'button',
              text: formatMessage('screens.scheduleSinglePayment.toast.success.single.action'),
              onAction: (closeToast) => {
                navigateToTab(PayDashboardTabs.Payments, { entityId: paymentId });
                closeToast();
              },
            }
          : undefined,
      });
    }

    if (returnUrl) {
      return navigate(returnUrl, { state: { keepToast: true } });
    }

    return navigateToTab(PayDashboardTabs.Payments, { keepToast: true });
  };

  useNonPositiveAmountsEffect(handleClose, [bill?.balance]);

  if (isPaymentsLoading || isBillLoading || isVendorLoading) {
    return <PaymentFlowLoader />;
  }

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <PaymentFlowActivity
            defaultValues={defaultValues}
            billId={billId}
            title={formatMessage('activities.paymentFlow.form.header.title.newPayment')}
            isSubmitting={isSubmitting}
            submitError={submitError}
            onSubmit={(data) => submit(data).then(() => navigate('success', { replace: true }))}
            onClose={handleClose}
          />
        }
      />
      <Route
        path="/success"
        element={
          paymentOrSubscription ? (
            isBillSubscription(paymentOrSubscription) ? (
              <RecurringPaymentScheduledActivity
                billSubscriptionId={paymentOrSubscription.id}
                onClose={handleClose}
                onDone={(action) =>
                  handleDone({
                    action,
                    paymentId: paymentOrSubscription.occurrences?.[0]?.paymentId || undefined,
                    isPendingApproval:
                      paymentOrSubscription.approvalDecisionStatus === PaymentApprovalDecisionStatusEnum.Pending,
                  })
                }
              />
            ) : (
              <PaymentScheduledActivity
                paymentsIds={[paymentOrSubscription.id]}
                onClose={handleClose}
                onDone={(action: PaymentFlowDoneAction) =>
                  handleDone({
                    action,
                    paymentId: paymentOrSubscription.id,
                    isPendingApproval:
                      paymentOrSubscription.approvalDecisionStatus === PaymentApprovalDecisionStatusEnum.Pending,
                  })
                }
              />
            )
          ) : (
            <Navigate to=".." />
          )
        }
      />
    </Routes>
  );
};

const isBillSubscription = (
  paymentOrBillSubscription: Payment | BillSubscription
): paymentOrBillSubscription is BillSubscription => 'intervalType' in paymentOrBillSubscription;
