import { ARIndustry, FormWidgetProps } from '@melio/ar-domain';
import { Form, LoadingContainer } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import {
  BusinessDetailsFormContext,
  BusinessDetailsFormState,
  IncomingDefaultValues,
  useBusinessDetailsForm,
} from '../../../utils';
import { BusinessDetailsFormFields } from '../types';
import { BusinessDetailsFormSection } from './BusinessDetailsFormSection';
import { ContactInformationFormSection } from './ContactInformationFormSection';

export type BusinessDetailsFormProps = Pick<
  FormWidgetProps<BusinessDetailsFormFields>,
  'isDisabled' | 'isSaving' | 'onSubmit' | 'onSubmissionStateChange'
> & {
  defaultValues?: IncomingDefaultValues;
  isLoading?: boolean;
  error?: ARPlatformError;
  onFormStateChange?: (state: BusinessDetailsFormState) => void;
  industries: ARIndustry[];
};

export const BusinessDetailsForm = forwardRef<BusinessDetailsFormProps>(
  (
    {
      onSubmit,
      defaultValues,
      isDisabled,
      isSaving,
      isLoading,
      onSubmissionStateChange,
      error,
      onFormStateChange,
      industries,
      ...props
    },
    ref
  ) => {
    const businessDetailsForm = useBusinessDetailsForm({
      onSubmit,
      isSaving,
      onSubmissionStateChange,
      defaultValues,
    });

    return (
      <BusinessDetailsFormContext.Provider value={businessDetailsForm}>
        <LoadingContainer isLoading={isLoading} ref={ref} {...props}>
          <Form
            {...businessDetailsForm.formProps}
            data-component="BusinessDetailsForm"
            data-testid="business-details-form"
          >
            <BusinessDetailsFormSection industries={industries} />
            <ContactInformationFormSection />
          </Form>
        </LoadingContainer>
      </BusinessDetailsFormContext.Provider>
    );
  }
);
BusinessDetailsForm.displayName = 'BusinessDetailsForm';
