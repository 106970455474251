import { isBrandSymbol } from '@melio/ap-widgets';
import { BrandSymbol, Group, Icon, SelectableDropdownMenuItem, Text, Typography } from '@melio/penny';

export type FundingSourceSelectionMenuItemProps = SelectableDropdownMenuItem & {
  description?: string;
};
export const FundingSourceSelectionMenuItem = ({
  label,
  icon,
  disabled,
  description,
}: FundingSourceSelectionMenuItemProps) => (
  <Group alignItems="center" spacing="s" width="full">
    {icon ? (
      isBrandSymbol(icon) ? (
        <BrandSymbol type={icon} size="medium" />
      ) : (
        <Icon size="large" type={icon} color="default" />
      )
    ) : null}

    <Group variant="vertical" alignItems="flex-start" spacing="xxxs">
      <Text textStyle="body3Semi" shouldSupportEllipsis color="inherit">
        {label}
      </Text>
      {description && <Typography.Description label={description} isDisabled={disabled?.isDisabled} />}
    </Group>
  </Group>
);
