import React from 'react';
import { Button, Group, Text } from '@melio/penny';
import { OriginFlow, useAnalytics } from '@melio/platform-analytics';
import { useUnilateralRequests, Vendor } from '@melio/platform-api';
import { DeliveryMethodType } from '@melio/platform-api';
import { useSystemMessage } from '@melio/platform-utils';

import { MethodCard } from '@/cl/components/MethodCard/MethodCard.component';
import { useDisclosure } from '@/hooks/useDisclosure';
import { usePlatformIntl } from '@/translations/Intl';
import {
  getActiveUnilateralRequests,
  getFirstSentAndLastSentUnilateralRequest,
} from '@/utils/unilateralRequests.utils';
import { UnilateralRequestDetailsModal } from './UnilateralRequestDetailsModal.widget';
import { useUnilateralRequestDetails } from './useUnilateralRequestDetails';

type UnilateralRequestMethodCardProps = {
  vendor: Vendor;
};

export const UnilateralRequestMethodCard = ({ vendor }: UnilateralRequestMethodCardProps) => {
  const { formatMessage, formatDate } = usePlatformIntl();
  const { track } = useAnalytics();
  const { showMessage } = useSystemMessage();

  const {
    isOpen: isUnilateralRequestDetailsModalOpen,
    onOpen: openUnilateralRequestDetailsModal,
    onClose: closeUnilateralRequestDetailsModal,
  } = useDisclosure();

  const { data: unilateralRequests } = useUnilateralRequests({ vendorId: vendor.id });

  const {
    sendUnilateralRequest,
    createVirtualAccountAndSendUnilateralRequest,
    enableSendRequest: isValidForResend,
    vendorVirtualDeliveryMethod,
    isSaving,
  } = useUnilateralRequestDetails({ vendorId: vendor.id });

  const { firstSentUnilateralRequest, lastSentUnilateralRequest } =
    getFirstSentAndLastSentUnilateralRequest(unilateralRequests);

  const hasActiveUnilateralRequest = !!unilateralRequests && !!getActiveUnilateralRequests(unilateralRequests)?.length;

  const showSuccessToast = () =>
    showMessage({
      type: 'success',
      title: formatMessage('widgets.addOrUpdateDeliveryMethod.virtual.toast.success'),
    });
  const showErrorToast = () =>
    showMessage({
      type: 'error',
      title: formatMessage('widgets.addOrUpdateDeliveryMethod.virtual.toast.error'),
    });

  const handleResendClick = () => {
    if (!vendorVirtualDeliveryMethod) {
      showErrorToast();
      throw new Error('no virtual account exists');
    }

    sendUnilateralRequest(vendorVirtualDeliveryMethod.id)
      .then(() => {
        track('VendorsAddVirtualMethod', 'Chose');
        showSuccessToast();
      })
      .catch(() => showErrorToast());
  };

  const handleSendClick = () => {
    if (vendor?.contact.email) {
      (vendorVirtualDeliveryMethod
        ? sendUnilateralRequest(vendorVirtualDeliveryMethod.id)
        : createVirtualAccountAndSendUnilateralRequest({ email: vendor.contact.email })
      )
        .then(() => {
          track('VendorsAddVirtualMethod', 'Chose');
          showSuccessToast();
        })
        .catch(() => showErrorToast());
    } else {
      openUnilateralRequestDetailsModal();
    }
  };

  return (
    <>
      {hasActiveUnilateralRequest ? (
        <MethodCard
          type={DeliveryMethodType.VirtualAccount}
          actionElement={
            <Button
              size="small"
              isDisabled={!isValidForResend}
              isLoading={isSaving}
              label={
                isValidForResend
                  ? formatMessage('widgets.addOrUpdateDeliveryMethod.virtual.resendAction')
                  : formatMessage('widgets.addOrUpdateDeliveryMethod.virtual.disabled')
              }
              onClick={handleResendClick}
            />
          }
          displayName={formatMessage('widgets.addOrUpdateDeliveryMethod.virtual.secondaryTitle')}
          showTooltip={!isValidForResend}
          tooltipText={formatMessage('widgets.addOrUpdateDeliveryMethod.virtual.tooltipText')}
        >
          <Group variant="vertical" spacing="xxxs">
            <Text textStyle="body4">
              {formatMessage('widgets.addOrUpdateDeliveryMethod.virtual.info.info1', {
                createdDate: formatDate(firstSentUnilateralRequest?.history.createdAt, { dateStyle: 'medium' }),
              })}
            </Text>
            <Text textStyle="body4" color="global.neutral.800">
              {formatMessage('widgets.addOrUpdateDeliveryMethod.virtual.info.info2', {
                updatedDate: formatDate(lastSentUnilateralRequest?.history.createdAt, { dateStyle: 'medium' }),
              })}
            </Text>
          </Group>
        </MethodCard>
      ) : (
        <MethodCard
          type={DeliveryMethodType.VirtualAccount}
          actionElement={
            <Button
              size="small"
              isLoading={isSaving}
              isDisabled={isSaving}
              onClick={handleSendClick}
              label={formatMessage('widgets.addOrUpdateDeliveryMethod.virtual.sendAction')}
              aria-label={formatMessage('widgets.addOrUpdateDeliveryMethod.virtual.sendAction.ariaLabel')}
            />
          }
          displayName={formatMessage('widgets.addOrUpdateDeliveryMethod.virtual.title')}
          helperText={formatMessage('widgets.addOrUpdateDeliveryMethod.virtual.comment')}
        />
      )}
      <UnilateralRequestDetailsModal
        vendor={vendor}
        isOpen={isUnilateralRequestDetailsModalOpen}
        origin={OriginFlow.VendorsFlow}
        onClose={closeUnilateralRequestDetailsModal}
      />
    </>
  );
};
