import { Form, Group } from '@melio/penny';
import {
  EbillsSubscriptionOptionsReceivingOption,
  EbillsSubscriptionOptionsReceivingOptionNameEnum,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { compact } from 'lodash';

import { RegisterFormField } from './types';

const useMenuReceivingOptions = ({
  receivingOptions,
  trialPeriodDays,
}: {
  receivingOptions: EbillsSubscriptionOptionsReceivingOption[];
  trialPeriodDays: number | null;
}) => {
  const { formatMessage } = useMelioIntl();
  const hasEbillReceivingOption = receivingOptions.some(
    ({ name }) => name === EbillsSubscriptionOptionsReceivingOptionNameEnum.Ebill
  );
  const hasDualReceivingOption = receivingOptions.some(
    ({ name }) => name === EbillsSubscriptionOptionsReceivingOptionNameEnum.Dual
  );
  const hasTrialReceivingOption = receivingOptions.some(
    ({ name }) => name === EbillsSubscriptionOptionsReceivingOptionNameEnum.Trial
  );

  return compact([
    hasEbillReceivingOption && {
      label: formatMessage('activities.ebillsSubscriptionForm.form.fields.receivingOptions.ebill.label'),
      value: EbillsSubscriptionOptionsReceivingOptionNameEnum.Ebill,
      description: formatMessage('activities.ebillsSubscriptionForm.form.fields.receivingOptions.ebill.description'),
    },
    hasDualReceivingOption && {
      label: formatMessage('activities.ebillsSubscriptionForm.form.fields.receivingOptions.dual.label'),
      value: EbillsSubscriptionOptionsReceivingOptionNameEnum.Dual,
      description: formatMessage('activities.ebillsSubscriptionForm.form.fields.receivingOptions.dual.description'),
    },
    hasTrialReceivingOption &&
      trialPeriodDays && {
        label: formatMessage('activities.ebillsSubscriptionForm.form.fields.receivingOptions.trial.label', {
          trialPeriodDays,
        }),
        value: EbillsSubscriptionOptionsReceivingOptionNameEnum.Trial,
        description: formatMessage('activities.ebillsSubscriptionForm.form.fields.receivingOptions.trial.description'),
      },
  ]);
};

export const EBillsSusbscriptionFormReceivingOptions = ({
  receivingOptions,
  trialPeriodDays,
  registerField,
}: {
  receivingOptions: EbillsSubscriptionOptionsReceivingOption[];
  trialPeriodDays: number | null;
  registerField: RegisterFormField;
}) => {
  const { formatMessage } = useMelioIntl();

  const menuReceivingOptions = useMenuReceivingOptions({ receivingOptions, trialPeriodDays });
  const isSignleReceivingOption = menuReceivingOptions.length === 1;
  const helperText = menuReceivingOptions[0]?.description;

  return (
    <Group variant="vertical" spacing="xxs">
      <Form.SelectNew
        {...registerField('receivingOption')}
        emptyState={{ label: formatMessage('activities.ebillsSubscriptionForm.form.fields.receivingOptions.notFound') }}
        options={menuReceivingOptions}
        labelProps={{
          label: formatMessage('activities.ebillsSubscriptionForm.form.fields.receivingOptions.label'),
        }}
        isRequired
        isReadOnly={isSignleReceivingOption}
        helperTextProps={isSignleReceivingOption && helperText ? { label: helperText } : undefined}
      />
    </Group>
  );
};
