import { MelioConfig, useConfig } from '@melio/platform-provider';

import { AddVendorFormWidgetProps } from '../../types';
import { AddUnmanagedVendorFormExtended, AddUnmanagedVendorFormMinimal } from './presets';

type Preset = MelioConfig['settings']['vendor']['collectedDetails'];

const presets: Record<Preset, React.FC<AddVendorFormWidgetProps>> = {
  minimal: AddUnmanagedVendorFormMinimal,
  extended: AddUnmanagedVendorFormExtended,
};

export const AddUnmanagedVendorForm: React.FC<AddVendorFormWidgetProps> = (props) => {
  const {
    settings: { vendor },
  } = useConfig();

  const Preset = presets[vendor.collectedDetails];
  return <Preset {...props} />;
};
