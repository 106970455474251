/* eslint-disable max-lines */
import {
  getIsChangingDeliveryMethodIsDisabled,
  isDeliveryPreferenceTypeFast,
  usePaymentSchedulingPreference,
} from '@melio/ap-domain';
import { getDeductionDate, isCreditCardFundingSource, isLateDeliveryDate } from '@melio/ap-widgets';
import { CalendarLegendProps, Table, TableColumnDef, useTable } from '@melio/penny';
import {
  Bill,
  BillingFeeSetting,
  DeliveryMethod,
  DeliveryMethodByPayor,
  DeliveryPreference,
  FundingSource,
  PaymentIntent,
  useCollaborator,
} from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { useDateUtils } from '@melio/platform-utils';
import { isSameDay } from 'date-fns';
import { FC, useMemo } from 'react';

import { getEarliestBillDueDate } from '../../../../../../utils/getEarliestBillDueDate';
import { DeliveryDateHeaderCellOption, SchedulePaymentIntent } from '../../../../types';
import { ActionsCell } from '../ActionsCell/ActionsCell';
import { ActionsSubCell } from '../ActionsSubCell/ActionsSubCell';
import { AmountCell } from '../AmountCell/AmountCell';
import { BillDetailsCell } from '../BillDetailsCell/BillDetailsCell';
import { BillDetailsSubCell } from '../BillDetailsCell/BillDetailsSubCell';
import { BillDetailsHeaderCell } from '../BillDetailsHeaderCell/BillDetailsHeaderCell';
import { DeductionDateCell } from '../DeductionDateCell/DeductionDateCell';
import { DeductionDateHeaderCell } from '../DeductionDateHeaderCell/DeductionDateHeaderCell';
import { DeliveryDateCell } from '../DeliveryDateCell/DeliveryDateCell';
import { DeliveryDateHeaderCell } from '../DeliveryDateHeaderCell/DeliveryDateHeaderCell';
import { DeliveryDateSelectCell } from '../DeliveryDateSelectCell/DeliveryDateSelectCell';
import { DeliveryMethodCell } from '../DeliveryMethodCell/DeliveryMethodCell';
import { DeliveryMethodFixedListSelectCell } from '../DeliveryMethodFixedListSelectCell/DeliveryMethodFixedListSelectCell';
import { DeliveryMethodSelectCell } from '../DeliveryMethodSelectCell/DeliveryMethodSelectCell';
import { DeliveryMethodSubCell } from '../DeliveryMethodSubCell/DeliveryMethodSubCell';
import { DeliverySpeedCell } from '../DeliverySpeedCell/DeliverySpeedCell';
import { FundingSourceHeaderSelectCell } from '../FundingSourceHeaderSelectCell/FundingSourceHeaderSelectCell';
import { FundingSourceSelectCell } from '../FundingSourceSelectCell/FundingSourceSelectCell';
import { FundingSourceHeaderSelectCellV2, FundingSourceSelectCellV2 } from '../FundingSourceSelectCellV2';
import {
  filterUnsupportedDeliveryPreferencesForBatchPaymentsFlow,
  getFundingSourceIdIfAllEqual,
  useGetDeductionDateIfAllEqual,
} from './PaymentIntentsTable.utils';
import { Column } from './types';

export type PaymentIntentsTableProps = {
  isToggling?: boolean;
  paymentIntentsWithDerivatives: SchedulePaymentIntent[];
  fundingSources: FundingSource[];
  orgBillingFeeSettings: BillingFeeSetting[];
  onOpenReconciliationModal: (paymentIntent: PaymentIntent) => void;
  onOpenPaymentPurposeModal: (paymentIntent: PaymentIntent) => void;
  onOpenVendorDetailsModal: (paymentIntent: PaymentIntent) => void;
  onOpenInvoiceAttachmentModal: (paymentIntent: PaymentIntent, bill: Bill) => void;
  onAddFundingSourceClick: VoidFunction;
  onAddDeliveryMethodClick: (paymentIntent: PaymentIntent) => void;
  onViewBillDetailsClick: (paymentIntentId: PaymentIntent['id'], bill: Bill) => void;
  onAddMemoToVendorClick: (paymentIntentId: PaymentIntent['id']) => void;
  onSetInvoiceNumberClick: (paymentIntentId: PaymentIntent['id']) => void;
  onRemoveBillsClick: (paymentIntentId: PaymentIntent['id'], billId?: Bill['id']) => void;
  onEditAmountClick: (paymentIntentId: PaymentIntent['id'], bill: Bill) => void;
  onUpdateSingleDeliveryPreferenceType: (
    paymentIntentId: PaymentIntent['id'],
    type: NonNullable<DeliveryPreference['type']>
  ) => void;
  onUpdateSingleFundingSource: (
    paymentIntentId: PaymentIntent['id'],
    fundingSourceId: PaymentIntent['fundingSourceId']
  ) => void;
  onUpdateSingleDeliveryMethod: (paymentIntentId: PaymentIntent['id'], deliveryMethodId: DeliveryMethod['id']) => void;
  onUpdateSingleScheduledDate: (
    paymentIntentId: PaymentIntent['id'],
    date: NonNullable<PaymentIntent['scheduledDate']>
  ) => void;
  onUpdateSingleDeliveryDate: (
    paymentIntentId: PaymentIntent['id'],
    date: NonNullable<PaymentIntent['deliveryDate']>
  ) => void;
  loadingRowIds?: string[];
  shouldDisplayStatus?: boolean;
  onUpdateAllFundingSources: (fundingSource: FundingSource) => void;
  onUpdateAllDeductionDatesToTheSameDate: (date: NonNullable<PaymentIntent['scheduledDate']>) => void;
  onUpdateAllDeductionDatesToTheEarliestDate: VoidFunction;
  onUpdateAllDeductionDatesToArriveByDueDate: VoidFunction;
  onUpdateAllDeliveryDatesToTheDueDate: VoidFunction;
  isByDueDate?: boolean;
  selectedDeliveryDateHeaderCellOption: DeliveryDateHeaderCellOption;
  arePaymentsCombined?: boolean;
  onToggleCombinedPayments: (isChecked: boolean) => void;
  onRowExpand: (paymentIntentId: PaymentIntent['id']) => void;
};

export const PaymentIntentsTable: FC<PaymentIntentsTableProps> = ({
  isToggling,
  paymentIntentsWithDerivatives,
  fundingSources,
  orgBillingFeeSettings,
  onOpenReconciliationModal,
  onViewBillDetailsClick,
  onAddMemoToVendorClick,
  onSetInvoiceNumberClick,
  onRemoveBillsClick,
  onAddFundingSourceClick,
  onAddDeliveryMethodClick,
  onUpdateSingleDeliveryPreferenceType,
  onUpdateSingleFundingSource,
  onUpdateSingleDeliveryMethod,
  onOpenPaymentPurposeModal,
  onOpenVendorDetailsModal,
  onOpenInvoiceAttachmentModal,
  onUpdateSingleScheduledDate,
  onUpdateSingleDeliveryDate,
  loadingRowIds,
  shouldDisplayStatus,
  onUpdateAllFundingSources,
  onUpdateAllDeductionDatesToTheSameDate,
  onUpdateAllDeductionDatesToTheEarliestDate,
  onUpdateAllDeductionDatesToArriveByDueDate,
  onUpdateAllDeliveryDatesToTheDueDate,
  isByDueDate,
  arePaymentsCombined,
  onToggleCombinedPayments,
  onRowExpand,
  selectedDeliveryDateHeaderCellOption,
  onEditAmountClick,
  ...props
}) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const { isByDeliveryDate } = usePaymentSchedulingPreference();
  const { createDate } = useDateUtils();
  const [isRtpPayorEnabledForBatchPayments] = useFeature<boolean>(FeatureFlags.RtpPayorEnabledForBatchPayments, false);
  const [isFundingSourceSelectV2] = useFeature<boolean>(FeatureFlags.BatchPaymentsNewFundingSourceSelect, false);

  const { data: actor } = useCollaborator({ id: 'me' });
  const actorCanApprovePayments = actor?.approvalActions.canApprovePayments;

  const {
    settings: {
      batchPayments: {
        showDeliverySpeedColumn,
        usePreDefinedlDeliveryMethodList,
        showDebitSameDateIndication,
        headerVariant,
      },
    },
  } = useConfig();

  const paymentIntents = paymentIntentsWithDerivatives.map(({ paymentIntent }) => paymentIntent);
  const fundingSourceHeaderSelectedId = getFundingSourceIdIfAllEqual(paymentIntents);

  const PAYOR_DELIVERY_TYPES: Array<DeliveryMethod['type']> = [
    'bank-account',
    'paper-check',
    'virtual-account',
    'international-account',
    'virtual-card',
    'domestic-wire-account',
  ];

  const isDeliveryMethodByPayor = (deliveryMethod: DeliveryMethod): deliveryMethod is DeliveryMethodByPayor =>
    PAYOR_DELIVERY_TYPES.includes(deliveryMethod.type) && !deliveryMethod.isManaged;

  const { getDeductionDateIfAllEqual } = useGetDeductionDateIfAllEqual();
  const tableData = useMemo(
    () =>
      paymentIntentsWithDerivatives.map(({ paymentIntent, vendor, bills }) => ({
        id: paymentIntent.id,
        paymentIntent,
        vendor,
        bills,
        subRows: bills.length > 1 ? bills.map((bill) => ({ ...bill, paymentIntent, vendor })) : undefined,
      })),
    [paymentIntentsWithDerivatives]
  );

  const defaultExpandedRows =
    paymentIntentsWithDerivatives.length === 1 && paymentIntentsWithDerivatives[0]
      ? { [paymentIntentsWithDerivatives[0].paymentIntent.id]: true }
      : undefined;

  const getDeductionDateHeaderCell = () =>
    isByDeliveryDate ? (
      formatMessage('activities.batchPayments.screens.paymentIntentsTable.debitDateHeaderCell.label')
    ) : (
      <DeductionDateHeaderCell
        onSelectDate={onUpdateAllDeductionDatesToTheSameDate}
        onSelectByDueDate={onUpdateAllDeductionDatesToArriveByDueDate}
        minDate={createDate()}
        selectedDate={getDeductionDateIfAllEqual(paymentIntents)}
        isByDueDate={isByDueDate}
      />
    );

  const getDeliveryDateHeaderCell = () =>
    isByDeliveryDate ? (
      <DeliveryDateHeaderCell
        deliveryDateHeaderCellOption={selectedDeliveryDateHeaderCellOption}
        onSelectByDueDate={onUpdateAllDeliveryDatesToTheDueDate}
        onSelectByEarliestDate={onUpdateAllDeductionDatesToTheEarliestDate}
      />
    ) : (
      formatMessage('activities.batchPayments.screens.paymentIntentsTable.deliveryDateCell.header')
    );

  const getDeliveryDateCell = (row: Column) => {
    const { paymentIntent, bills } = row;
    const deliveryPreference = paymentIntent.deliveryPreferenceOptions?.find(
      (option) => option.type === paymentIntent.selectedDeliveryPreferenceType
    );

    const dueDate = getEarliestBillDueDate(bills);
    const isDraft = paymentIntent.status === 'draft';
    const isLate = deliveryPreference && !isDraft && dueDate ? isLateDeliveryDate(deliveryPreference, dueDate) : false;
    const isFast = paymentIntent.selectedDeliveryPreferenceType
      ? isDeliveryPreferenceTypeFast(paymentIntent.selectedDeliveryPreferenceType)
      : false;
    const isDeliveryDateSelectCellDisabled = isFast || !deliveryPreference;

    if (isByDeliveryDate) {
      return (
        <DeliveryDateSelectCell
          isDisabled={isDeliveryDateSelectCellDisabled}
          paymentIntentId={paymentIntent.id}
          selectedDeliveryPreferenceType={paymentIntent.selectedDeliveryPreferenceType}
          effectiveDeliveryDate={paymentIntent.effectiveDeliveryDate}
          effectiveScheduledDate={paymentIntent.effectiveScheduledDate}
          earliestDeliveryDate={deliveryPreference?.earliestDeliveryDate}
          onDeliveryDateSelect={(paymentIntentId, date) => onUpdateSingleDeliveryDate(paymentIntentId, date)}
          isLate={isLate}
          isDeliveryMethodSelected={!!paymentIntent.deliveryMethodId}
        />
      );
    }

    return <DeliveryDateCell deliveryPreference={deliveryPreference} isLate={isLate} />;
  };

  const scheduleDateColumn: TableColumnDef<Column> = {
    id: 'scheduledDate',
    header: getDeductionDateHeaderCell(),
    cell: ({ row }) => {
      const paymentIntentDueDate = getEarliestBillDueDate(row.bills);

      const { deliveryMethodId } = row.paymentIntent;
      const deductionDate =
        deliveryMethodId && getDeductionDate(row.paymentIntent.scheduledDate, row.paymentIntent.effectiveScheduledDate);
      const fundingSource = fundingSources.find((it) => it.id === row.paymentIntent.fundingSourceId);

      const legendItems = [
        {
          label: formatMessage('activities.batchPayments.screens.paymentIntentsTable.scheduledDateCell.legend.dueDate'),
          date: formatDate(paymentIntentDueDate, { dateStyle: 'medium' }),
          variant: 'secondary',
        },
        {
          label: formatMessage(
            `activities.batchPayments.screens.paymentIntentsTable.scheduledDateCell.legend.scheduledDate.label.${
              isCreditCardFundingSource(fundingSource) ? 'creditCardFundingType' : 'default'
            }`
          ),
          date: (
            <DeductionDateCell
              scheduledDate={row.paymentIntent.scheduledDate}
              effectiveScheduledDate={row.paymentIntent.effectiveScheduledDate}
              selectedDeliveryPreferenceType={row.paymentIntent.selectedDeliveryPreferenceType}
            />
          ),
        },
      ].filter((item) => item.date) as CalendarLegendProps[];

      const selectedDeliveryPreferenceType = row.paymentIntent.selectedDeliveryPreferenceType;
      const isDeliveryDateEarliestAsPossible = row.paymentIntent.effectiveScheduledDate
        ? isSameDay(row.paymentIntent.effectiveScheduledDate, createDate())
        : false;

      let tooltipText = null;

      if (!actorCanApprovePayments && row.paymentIntent.requireWithdrawApproval) {
        tooltipText = formatMessage(
          'activities.batchPayments.screens.paymentIntentsTable.deductionDateCell.requiresApprovalBy.tooltip',
          {
            date: formatDate(row.paymentIntent.effectiveScheduledDate ?? undefined, { dateStyle: 'medium' }),
          }
        );
      } else if (showDebitSameDateIndication && isDeliveryDateEarliestAsPossible) {
        tooltipText = formatMessage(
          'activities.batchPayments.screens.paymentIntentsTable.deductionDateCell.debitsSameDate.tooltip',
          {
            date: formatDate(row.paymentIntent.effectiveDeliveryDate ?? undefined, { dateStyle: 'medium' }),
          }
        );
      } else if (selectedDeliveryPreferenceType === 'virtual-card') {
        tooltipText = formatMessage(
          'activities.batchPayments.screens.paymentIntentsTable.scheduledDateCell.virtualCard.info'
        );
      }

      const helperLabelText =
        isDeliveryDateEarliestAsPossible && (actorCanApprovePayments || !row.paymentIntent.requireWithdrawApproval)
          ? formatMessage('activities.batchPayments.screens.paymentIntentsTable.deductionDateCell.debitsSameDate.label')
          : null;

      return (
        <Table.DateCell
          value={deductionDate || void 0}
          displayValue={
            deductionDate && (
              <DeductionDateCell
                scheduledDate={row.paymentIntent.scheduledDate}
                effectiveScheduledDate={row.paymentIntent.effectiveScheduledDate}
                selectedDeliveryPreferenceType={selectedDeliveryPreferenceType}
                tooltipText={tooltipText}
                helperTextLabel={helperLabelText}
              />
            )
          }
          excludeHolidays
          weekDays={[1, 2, 3, 4, 5]}
          minDate={row.paymentIntent.minScheduleDate || createDate()}
          onSelect={(date) => onUpdateSingleScheduledDate(row.paymentIntent.id, date)}
          secondarySelectedDate={paymentIntentDueDate}
          placeholder={formatMessage(
            'activities.batchPayments.screens.paymentIntentsTable.scheduledDateCell.placeholder'
          )}
          legendItems={legendItems}
          data-testid={`${row.paymentIntent.id}-scheduled-date-cell`}
          isReadOnly={isByDeliveryDate}
        />
      );
    },
    size: 's',
  };
  const deliveryDateColumn: TableColumnDef<Column> = {
    id: 'deliveryDate',
    header: getDeliveryDateHeaderCell(),
    cell: ({ row }) => getDeliveryDateCell(row),
    subRowCell: ({ row: subRow }) => {
      const { paymentIntent, ...bill } = subRow;

      return formatMessage('activities.batchPayments.screens.paymentIntentsTable.deliveryDateCell.subRow.dueDate', {
        dueDate: formatDate(bill.dueDate, { dateStyle: 'medium' }),
      });
    },
    size: 's',
  };
  const deliverySpeedColumn: TableColumnDef<Column> = {
    id: 'deliverySpeed',
    header: formatMessage('activities.batchPayments.screens.paymentIntentsTable.deliverySpeedCell.header'),
    cell: ({ row }) => (
      <DeliverySpeedCell
        fundingSourceId={row.paymentIntent.fundingSourceId}
        deliveryPreferencesOptions={filterUnsupportedDeliveryPreferencesForBatchPaymentsFlow(
          row.paymentIntent.deliveryPreferenceOptions,
          isRtpPayorEnabledForBatchPayments
        )}
        selectedDeliveryPreferenceType={row.paymentIntent.selectedDeliveryPreferenceType}
        onSelectDeliveryPreferenceType={(type) => onUpdateSingleDeliveryPreferenceType(row.paymentIntent.id, type)}
        data-testid={`${row.paymentIntent.id}-delivery-speed-cell`}
      />
    ),
    size: 's',
  };

  const deliveryDateColumns: TableColumnDef<Column>[] = showDeliverySpeedColumn
    ? [scheduleDateColumn, deliveryDateColumn, deliverySpeedColumn]
    : [scheduleDateColumn, deliveryDateColumn];

  const scheduleDateColumns: TableColumnDef<Column>[] = showDeliverySpeedColumn
    ? [scheduleDateColumn, deliverySpeedColumn, deliveryDateColumn]
    : [scheduleDateColumn, deliveryDateColumn];

  const dateColumns = isByDeliveryDate ? deliveryDateColumns : scheduleDateColumns;

  function getFundingSourceHeaderSelectCell() {
    const FundingSourceHeaderSelectCellComponent = isFundingSourceSelectV2
      ? FundingSourceHeaderSelectCellV2
      : FundingSourceHeaderSelectCell;
    return (
      <FundingSourceHeaderSelectCellComponent
        fundingSources={fundingSources}
        onSelect={onUpdateAllFundingSources}
        onAddFundingSource={onAddFundingSourceClick}
        selectedFundingSourceId={fundingSourceHeaderSelectedId}
      />
    );
  }

  const columns: TableColumnDef<Column>[] = [
    {
      id: 'billDetails',
      header: (
        <BillDetailsHeaderCell
          paymentIntentsWithDerivatives={paymentIntentsWithDerivatives}
          arePaymentsCombined={arePaymentsCombined}
          onToggleCombinedPayments={onToggleCombinedPayments}
          isToggling={isToggling}
        />
      ),
      cell: ({ row }) =>
        row.vendor && (
          <BillDetailsCell
            vendor={row.vendor}
            bills={row.bills}
            paymentIntent={row.paymentIntent}
            onViewBillDetailsClick={onViewBillDetailsClick}
            data-testid={`${row.paymentIntent.id}-bill-details-cell`}
          />
        ),
      subRowCell: ({ row: subRow }) => {
        const { paymentIntent, ...bill } = subRow;
        return (
          <BillDetailsSubCell
            bill={bill}
            onViewBillDetailsClick={() => onViewBillDetailsClick(paymentIntent.id, bill)}
          />
        );
      },
      size: 240,
      isPinnedToLeft: true,
    },
    {
      id: 'fundingSource',
      header: getFundingSourceHeaderSelectCell(),
      cell: ({ row }) => {
        const FundingSourceSelectCellComponent = isFundingSourceSelectV2
          ? FundingSourceSelectCellV2
          : FundingSourceSelectCell;
        return (
          row.vendor && (
            <FundingSourceSelectCellComponent
              vendor={row.vendor}
              onOpenReconciliationModal={() => onOpenReconciliationModal(row.paymentIntent)}
              onOpenVendorDetailsModal={() => onOpenVendorDetailsModal(row.paymentIntent)}
              fundingSources={fundingSources}
              onAddFundingSourceClick={onAddFundingSourceClick}
              selectedFundingSourceId={row.paymentIntent.fundingSourceId || void 0}
              onSelect={(fundingSourceId) => onUpdateSingleFundingSource(row.paymentIntent.id, fundingSourceId)}
              data-testid={`${row.paymentIntent.id}-funding-source-select-cell`}
              fundingSourceTypesOptions={row.paymentIntent.fundingSourceTypesOptions}
            />
          )
        );
      },
      size: 's',
    },
    {
      id: 'deliveryMethod',
      size: 's',
      header: formatMessage('activities.batchPayments.screens.paymentIntentsTable.deliveryMethodCell.header'),
      cell: ({ row }) => {
        const selectedDeliveryMethod = row.vendor?.deliveryMethods.find(
          (deliveryMethod) => deliveryMethod.id === row.paymentIntent.deliveryMethodId
        );

        const deliveryMethodsByPayor = row.vendor?.deliveryMethods.filter(isDeliveryMethodByPayor);
        const selectedFundingSource = fundingSources.find(
          (fundingSource) => fundingSource.id === row.paymentIntent.fundingSourceId
        );

        const onSelect = (deliveryMethodId: DeliveryMethod['id']) => {
          onUpdateSingleDeliveryMethod(row.paymentIntent.id, deliveryMethodId);
        };

        if (usePreDefinedlDeliveryMethodList) {
          return (
            <DeliveryMethodFixedListSelectCell
              vendor={row.vendor}
              paymentIntent={row.paymentIntent}
              selectedId={row.paymentIntent.deliveryMethodId || void 0}
              deliveryMethods={deliveryMethodsByPayor}
              onSelect={onSelect}
            />
          );
        }

        if (selectedDeliveryMethod && getIsChangingDeliveryMethodIsDisabled(selectedDeliveryMethod)) {
          return (
            <DeliveryMethodCell
              deliveryMethod={selectedDeliveryMethod}
              data-testid={`${row.paymentIntent.id}-delivery-method-cell`}
              paymentIntent={row.paymentIntent}
              onOpenPaymentPurposeModal={() => onOpenPaymentPurposeModal(row.paymentIntent)}
            />
          );
        }

        return (
          deliveryMethodsByPayor && (
            <DeliveryMethodSelectCell
              paymentIntent={row.paymentIntent}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              deliveryMethods={deliveryMethodsByPayor}
              selectedId={row.paymentIntent.deliveryMethodId || void 0}
              selectedFundingSource={selectedFundingSource}
              onSelect={onSelect}
              onAddDeliveryMethodClick={() => onAddDeliveryMethodClick(row.paymentIntent)}
              shouldDisplayStatus={shouldDisplayStatus}
              data-testid={`${row.paymentIntent.id}-delivery-method-select-cell`}
              deliveryMethodTypeOptions={row.paymentIntent.deliveryMethodTypeOptions || []}
            ></DeliveryMethodSelectCell>
          )
        );
      },
      subRowCell: ({ row: subRow }) => {
        const { paymentIntent, vendor, ...bill } = subRow;
        const selectedDeliveryMethod = vendor?.deliveryMethods.find(
          (deliveryMethod) => deliveryMethod.id === paymentIntent.deliveryMethodId
        );
        return (
          selectedDeliveryMethod && (
            <DeliveryMethodSubCell
              deliveryMethod={selectedDeliveryMethod}
              data-testid={`${paymentIntent.id}-sub-delivery-method-cell`}
              paymentIntent={paymentIntent}
              bill={bill}
              onOpenAttachInvoiceModal={() => onOpenInvoiceAttachmentModal(paymentIntent, bill)}
            />
          )
        );
      },
    },
    ...dateColumns,
    {
      id: 'amountToPay',
      size: 140,
      textAlign: 'end',
      header: formatMessage('activities.batchPayments.screens.paymentIntentsTable.amountCell.header'),
      cell: ({ row }) => (
        <AmountCell orgBillingFeeSettings={orgBillingFeeSettings} paymentIntent={row.paymentIntent} bills={row.bills} />
      ),
      subRowCell: ({ row: subRow }) => {
        const { paymentIntent, ...bill } = subRow;
        return <Table.AmountCell value={bill.balance} data-testid={`table-sub-row-amount-to-pay-cell-${bill.id}`} />;
      },
    },
    {
      id: 'action',
      size: 48,
      cell: ({ row }) => {
        const selectedDeliveryMethod = row.vendor?.deliveryMethods.find(
          (deliveryMethod) => deliveryMethod.id === row.paymentIntent.deliveryMethodId
        );
        return (
          <ActionsCell
            isRemovable={paymentIntents?.length > 1}
            paymentIntentWithDerivatives={row}
            selectedDeliveryMethod={selectedDeliveryMethod}
            onViewBillDetailsClick={onViewBillDetailsClick}
            onAddMemoToVendorClick={onAddMemoToVendorClick}
            onSetInvoiceNumberClick={onSetInvoiceNumberClick}
            onRemoveBillsClick={onRemoveBillsClick}
            onEditAmountClick={onEditAmountClick}
          />
        );
      },
      subRowCell: ({ row: subRow }) => {
        const { paymentIntent, ...bill } = subRow;

        return (
          <ActionsSubCell
            paymentIntent={paymentIntent}
            bill={bill}
            onViewBillDetailsClick={onViewBillDetailsClick}
            onSetInvoiceNumberClick={onSetInvoiceNumberClick}
            onRemoveBillsClick={onRemoveBillsClick}
          />
        );
      },
    },
  ];

  const tableProps = useTable({
    isLoading: isToggling,
    data: tableData,
    columns,
    defaultExpandedRows,
    onRowExpandChange: ({ rowId, isExpanded }) => {
      if (isExpanded) {
        onRowExpand(rowId);
      }
    },
    headerVariant,
  });
  return <Table {...tableProps} {...props} loadingRowIds={loadingRowIds} />;
};

PaymentIntentsTable.displayName = 'PaymentIntentsTable';
