import {
  getFastestDeliveryPreferenceOption,
  isDeliveryPreferenceTypeFast,
  useDeliveryPreferenceOption,
} from '@melio/ap-domain';
import { Card, Form, Group, useMelioForm, UseMelioFormProps } from '@melio/penny';
import { DeliveryPreference, FundingSource } from '@melio/platform-api';
import React from 'react';
import { object } from 'yup';
import * as yup from 'yup';

import { DeliveryDateFormFields } from '../types';
import { ButtonDatePicker } from './ButtonDatePicker';

export type MultipleDeliveryOptionsFormProps = UseMelioFormProps<DeliveryDateFormFields> & {
  minDate: Date;
  fundingSource: FundingSource;
  deliveryPreferences: DeliveryPreference[];
  onSelectDate: (date: Date) => void;
};
const useSchema = () =>
  object().shape({
    deliveryDate: yup.date().required(),
    deliveryPreferenceType: yup.string().required(),
  }) as yup.SchemaOf<DeliveryDateFormFields>;

export const MultipleDeliveryOptionsForm = ({
  minDate,
  fundingSource,
  deliveryPreferences,
  onSelectDate,
  onSubmissionStateChange,
  onSubmit,
  isLoading,
  defaultValues,
}: MultipleDeliveryOptionsFormProps) => {
  const { getOption } = useDeliveryPreferenceOption(fundingSource);
  const { registerField, formProps, watch, setValue } = useMelioForm({
    schema: useSchema(),
    onSubmit,
    onSubmissionStateChange,
    defaultValues,
    isSaving: isLoading,
  });

  const selectedDeliveryPreferenceType = watch('deliveryPreferenceType');
  const isDateSelectionDisabled = isLoading || isDeliveryPreferenceTypeFast(selectedDeliveryPreferenceType);
  const fastestOption = getFastestDeliveryPreferenceOption(deliveryPreferences);

  return (
    <Card data-testid="multiple-delivery-options-card">
      <Group width="full">
        <Group.Item grow={1}>
          <Form data-testid="delivery-date-multiple-delivery-options-form" {...formProps}>
            <Form.RadioGroup
              {...registerField('deliveryPreferenceType')}
              options={deliveryPreferences.map((deliveryPreference) => getOption(deliveryPreference, fastestOption))}
              variant="vertical"
              aria-label="delivery preference type"
            />
          </Form>
        </Group.Item>
        <ButtonDatePicker
          selectedDeliveryDate={watch('deliveryDate') || minDate}
          minDate={minDate}
          onDateChange={(date: Date) => {
            onSelectDate(date);
            setValue('deliveryDate', date);
          }}
          isDisabled={isDateSelectionDisabled}
        />
      </Group>
    </Card>
  );
};
