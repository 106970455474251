/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useRecoilValue } from 'recoil';

import { GroupedLists } from '@/cl/components/GroupedLists/GroupedLists.component';
import { NoResultsFound } from '@/cl/components/NoResultsFound/NoResultsFound.component';
import { usePartnerConfig } from '@/hooks/partners';
import { useSelectFirstCardFromGroup } from '@/hooks/payDashboard.hooks';
import { payDashboardQuerySearchSelector } from '@/store/PayDashboard/PayDashboards.model';
import { usePlatformIntl } from '@/translations/Intl';
import { PayDashboardScheduledTabGroup, PayDashboardSectionEnum, PaymentGroupItem } from '@/types/payDashboard.types';
import { sortDates } from '@/utils/dates.util';

const sortGroupItemsBySectionName = (section: PayDashboardSectionEnum) => {
  switch (section) {
    case PayDashboardSectionEnum.pendingInfo:
      return (cardA: PaymentGroupItem, cardB: PaymentGroupItem) => {
        return sortDates(cardA.payment?.scheduledDate, cardB.payment?.scheduledDate, 'desc');
      };
    case PayDashboardSectionEnum.thisWeek:
    case PayDashboardSectionEnum.thisMonth:
    case PayDashboardSectionEnum.later:
      return (cardA: PaymentGroupItem, cardB: PaymentGroupItem) =>
        sortDates(cardA.payment?.scheduledDate, cardB.payment?.scheduledDate, 'asc');
  }
};

export const ScheduleCardList = ({ groups }: { groups: PayDashboardScheduledTabGroup[] }) => {
  const { formatMessage } = usePlatformIntl();
  const { partnerConfig } = usePartnerConfig();
  const { NoBillsFoundIcon } = partnerConfig.icons;
  const queryString = useRecoilValue(payDashboardQuerySearchSelector);

  const getTitleBySectionName = (section: PayDashboardSectionEnum) => {
    switch (section) {
      case PayDashboardSectionEnum.pendingInfo:
        return formatMessage(`app.payDashboard.scheduleListSection.sections.pendingInfo`);
      case PayDashboardSectionEnum.thisWeek:
        return formatMessage(`app.payDashboard.scheduleListSection.sections.thisWeek`);
      case PayDashboardSectionEnum.thisMonth:
        return formatMessage(`app.payDashboard.scheduleListSection.sections.thisMonth`);
      case PayDashboardSectionEnum.later:
      default:
        return formatMessage(`app.payDashboard.scheduleListSection.sections.later`);
    }
  };

  const sortedGroupsWithTitles = React.useMemo(
    () =>
      groups.map((group) => ({
        ...group,
        title: getTitleBySectionName(group.section),
        items: [...group.items].sort(sortGroupItemsBySectionName(group.section)),
      })),
    [groups],
  );

  useSelectFirstCardFromGroup(sortedGroupsWithTitles);

  return sortedGroupsWithTitles.length === 0 ? (
    <NoResultsFound
      text={
        queryString
          ? formatMessage('widgets.emptySearchResult.billDescription')
          : formatMessage('widgets.emptySearchResult.scheduleBillDescription')
      }
      icon={<NoBillsFoundIcon />}
    />
  ) : (
    <GroupedLists groups={sortedGroupsWithTitles} size="small" listType="payCardList" />
  );
};
