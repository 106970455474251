/* eslint-disable react-hooks/exhaustive-deps */
import { MemoDetails, MemoToVendorForm } from '@melio/ap-widgets';
import { Group, Modal, Text, useFormSubmissionController } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-provider';
import { SystemMessageDisplay } from '@melio/platform-utils';
import { useEffect } from 'react';

import { MemoToVendorScreenProps } from './types';

type ModalScreenProps = MemoToVendorScreenProps & {
  isOpen: boolean;
};

export const MemoToVendorModalScreen = ({
  hideEmailField,
  isLoading,
  isOpen,
  vendorName,
  memo = '',
  email = '',
  isSaving,
  onClose,
  onDone,
  mode = 'add',
  memoValidation,
  ...props
}: ModalScreenProps) => {
  const { formatMessage } = useMelioIntl();

  const { onSubmissionStateChange, submitButtonProps } = useFormSubmissionController<MemoDetails>();

  const defaultValues = {
    memo,
    email,
  };

  const { track } = useAnalytics();
  useEffect(() => {
    isOpen && track('NoteForVendor', 'Viewed');
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      isLoading={isLoading}
      onClose={onClose}
      data-testid="memo-to-vendor-modal-activity-memo-to-vendor-modal-screen"
      header={
        <Text as="h3" textStyle="heading2Semi">
          {formatMessage(`activities.memoToVendor.screens.memoToVendor.${mode}.title`, {
            name: vendorName,
          })}
        </Text>
      }
      primaryButton={{
        ...submitButtonProps,
        variant: 'primary',
        label: formatMessage(`activities.memoToVendor.screens.memoToVendor.${mode}.done`),
      }}
      secondaryButton={{
        label: formatMessage('activities.memoToVendor.screens.memoToVendor.cancel'),
        variant: 'tertiary',
        onClick: onClose,
      }}
      {...props}
    >
      <Group variant="vertical">
        <Text>{formatMessage('activities.memoToVendor.screens.memoToVendor.description')}</Text>
        <SystemMessageDisplay />
        <MemoToVendorForm
          onSubmit={({ memo, email }) => onDone(memo, email)}
          onSubmissionStateChange={onSubmissionStateChange}
          isSaving={isSaving}
          vendorName={vendorName}
          defaultValues={defaultValues}
          hideEmailField={hideEmailField}
          memoValidation={memoValidation}
        />
      </Group>
    </Modal>
  );
};

MemoToVendorModalScreen.displayName = 'MemoToVendorModalActivity.MemoToVendorModalScreen';
