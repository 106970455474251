import { useAnalytics } from '@melio/platform-analytics';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useDebouncedObject } from '@melio/platform-utils';
import { useCallback, useEffect, useMemo } from 'react';

import { isBank, isContainsEmail, isPartialName, isWhitelist } from './utils';

export const useVendorNameConstraints = ({
  isFormSubmitted,
  vendorName,
  isManaged,
  vendorNameFreeTextInputProps,
  vendorNameFieldErrorType,
  disabled,
}: {
  isFormSubmitted: boolean;
  vendorName?: string;
  isManaged?: boolean;
  vendorNameFreeTextInputProps?: { isBlurred: boolean };
  vendorNameFieldErrorType?: string;
  disabled?: boolean;
}): { vendorNameBannerDescription: string | undefined; vendorNameConstraintType: string } => {
  const [vendorNameConstraintsEnabled] = useFeature(FeatureFlags.PlatformVendorNameConstraints, false);
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();

  const isFreeTextInput = !!vendorNameFreeTextInputProps;
  const isFreeTextInputBlurred = !!vendorNameFreeTextInputProps?.isBlurred;

  const shouldSkip = !vendorName || isManaged || disabled || vendorNameFieldErrorType || isWhitelist(vendorName);

  const constraintTypeBannerDescriptions: Record<string, string> = {
    bank: formatMessage('form.vendorSelect.constraints.bank'),
    'single-word': formatMessage('form.vendorSelect.constraints.singleWord'),
  };

  const vendorNameConstraintType = useMemo(() => {
    if (shouldSkip) {
      return undefined;
    }

    if (isBank(vendorName)) {
      return 'bank';
    }

    if (isFreeTextInput && !isFreeTextInputBlurred) {
      return undefined;
    }

    if (isContainsEmail(vendorName)) {
      return 'not-contains-email';
    }

    if (isPartialName(vendorName)) {
      return 'single-word';
    }

    return undefined;
  }, [shouldSkip, vendorName, isFreeTextInput, isFreeTextInputBlurred]);

  const trackConstraintEvent = useCallback(
    ({ constraintType, searchValue }: { constraintType?: string; searchValue?: string }) => {
      track('Vendor', 'View', {
        VendorType: 'local',
        Feature: {
          name: FeatureFlags.PlatformVendorNameConstraints,
          type: constraintType,
          displayed: vendorNameConstraintsEnabled,
        },
        SearchValue: searchValue,
      });
    },
    [vendorNameConstraintsEnabled, track]
  );

  const currentConstraintType = vendorNameFieldErrorType || vendorNameConstraintType;
  // Using short debounce to avoid multiple track-events (when dropdown mode) or longer debounce in order to avoid incorrect banners as long as the user is typing (when free text input mode)
  const debouncedValues = useDebouncedObject(
    {
      constraintType:
        currentConstraintType === 'not-contains-email' && !isFormSubmitted ? undefined : currentConstraintType,
      searchValue: vendorName?.trim(),
    },
    isFreeTextInput ? 500 : 20
  );

  useEffect(() => {
    if (debouncedValues.constraintType) {
      trackConstraintEvent(debouncedValues);
    }
  }, [debouncedValues, trackConstraintEvent]);

  const { constraintType } = debouncedValues;

  const vendorNameBannerDescription =
    vendorNameConstraintsEnabled && constraintType ? constraintTypeBannerDescriptions[constraintType] : undefined;

  return {
    vendorNameBannerDescription,
    vendorNameConstraintType: constraintType || '',
  };
};
