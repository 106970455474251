import { Container, IconButton, useBreakpoint } from '@melio/penny';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useCallback, useState } from 'react';

import { TodosDrawerActivity } from '../../../todos-drawer';
import { useEnrichedTodos } from '../../../todos-drawer/hooks/useEnrichedTodos';
import { useTodosEnabled } from '../../../todos-drawer/hooks/useTodosEnabled';

export const TodosWidgetButton = () => {
  const { isEnabled } = useTodosEnabled();

  if (isEnabled) {
    return <TodosButton />;
  }

  return null;
};

const TodosButton = () => {
  const { isExtraSmallScreen } = useBreakpoint();
  const { formatMessage } = useMelioIntl();
  const { newItemsAfterLastSeenCount, totalCount } = useEnrichedTodos();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [todosNewIndicationsEnabled] = useFeature(FeatureFlags.TodosNewIndications, false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDrawerOpen(true);
  };
  const handleDrawerClose = useCallback(() => setIsDrawerOpen(false), []);

  const ariaLabel = todosNewIndicationsEnabled
    ? formatMessage('widgets.todosButton.ariaLabel', {
        count: newItemsAfterLastSeenCount,
      })
    : formatMessage('widgets.todosButton.totalCount.ariaLabel', {
        count: totalCount,
      });

  const status = todosNewIndicationsEnabled
    ? newItemsAfterLastSeenCount === 0
      ? undefined
      : 'critical'
    : totalCount === 0
    ? undefined
    : 'critical';

  return (
    <>
      <Container data-testid="todos-button-container" overflow="initial" width="fit-content">
        <IconButton
          icon="to-do"
          onClick={handleClick}
          variant={isExtraSmallScreen ? 'naked' : 'primary'}
          data-testid="todos-button"
          aria-label={ariaLabel}
          status={status}
        />
      </Container>

      <TodosDrawerActivity isOpen={isDrawerOpen} onClose={handleDrawerClose} />
    </>
  );
};
