import { DeliveryMethod, useInternationalCountriesDetails } from '@melio/platform-api';
import { useMemo } from 'react';

import { isInternationalDeliveryMethod } from '../../functions';

export const useInternationalCountryDisplayName = (deliveryMethod: DeliveryMethod | undefined) => {
  const { data: countriesDetails, isLoading: isCountriesDetailsLoading } = useInternationalCountriesDetails({
    enabled: isInternationalDeliveryMethod(deliveryMethod),
  });

  const countryDisplayName = useMemo(
    () =>
      isInternationalDeliveryMethod(deliveryMethod)
        ? countriesDetails?.find((country) => country.code === deliveryMethod.details.identifierDetails.bankCountryCode)
            ?.name || deliveryMethod.details.identifierDetails.bankCountryCode
        : undefined,
    [countriesDetails, deliveryMethod]
  );

  return { countryDisplayName, isCountriesDetailsLoading };
};
