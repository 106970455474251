import { Vendor } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';

import { UnilateralRequestMethodCard } from './UnilateralRequestMethodCard.widget';

type UnilateralRequestDetailsProps = {
  vendor: Vendor;
};
export const UnilateralRequestDetails = ({ vendor }: UnilateralRequestDetailsProps) => {
  const {
    settings: { isUnilateralDeliveryMethodSupported },
  } = useConfig();

  return isUnilateralDeliveryMethodSupported ? <UnilateralRequestMethodCard vendor={vendor} /> : null;
};
