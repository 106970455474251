import { Group, Text, useFormSubmissionController } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { CountryInternationalDeliveryDetails } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import React, { useState } from 'react';

import { NewSinglePaymentStepLayout } from '../../../../../../NewSinglePaymentStepLayout';
import { CreationBadRequestBanner } from '../../../../components';
import { UnsupportedInternationalCountryModal } from '../UnsupportedInternationalCountryModal';
import { InternationalBankDetailsForm, InternationalBankDetailsFormFields } from './InternationalBankDetailsForm';

export const identifierToBankIdentificationType = (identifierType: 'iban' | 'bicSwift' | null | undefined) => {
  switch (identifierType) {
    case 'iban':
      return 'IBAN';
    case 'bicSwift':
      return 'SWIFT';
    default:
      return null;
  }
};

type InternationalBankDetailsScreenProps = {
  countriesDetails: CountryInternationalDeliveryDetails[];
  isSaving?: boolean;
  isLoading?: boolean;
  defaultValues?: Partial<InternationalBankDetailsFormFields>;
  vendorName: string;
  onBack: VoidFunction;
  onClose: VoidFunction;
  onDone: (data: InternationalBankDetailsFormFields) => void;
  shouldShowFailedCreationBanner: boolean;
};

export const InternationalBankDetailsScreen = forwardRef<InternationalBankDetailsScreenProps, 'div'>(
  (
    {
      defaultValues,
      countriesDetails,
      onBack,
      shouldShowFailedCreationBanner,
      onClose,
      onDone,
      isLoading,
      isSaving,
      vendorName,
      ...props
    },
    ref
  ) => {
    const { track } = useAnalytics();
    const { formatMessage } = useMelioIntl();
    const { onSubmissionStateChange, cancelButtonProps, submitButtonProps } =
      useFormSubmissionController<InternationalBankDetailsFormFields>();
    const [selectedCountryDetails, setSelectedCountryDetails] = useState<
      CountryInternationalDeliveryDetails | undefined
    >(undefined);

    const trackClick = (cta: string) =>
      track(`DeliveryMethod`, 'Click', {
        Cta: cta,
        DeliveryCountry: selectedCountryDetails?.name,
        CountryRiskScore: selectedCountryDetails?.risk,
        IsSupported: selectedCountryDetails?.isSupported,
        BankIdentificationType: identifierToBankIdentificationType(selectedCountryDetails?.identifierType),
      });

    return (
      <NewSinglePaymentStepLayout
        data-component="InternationalBankDetailsActivity.InternationalBankDetailsScreen"
        data-testid="international-bank-details-activity-international-bank-details-screen"
        isLoading={isLoading}
        {...props}
        ref={ref}
        headerContent={
          <NewSinglePaymentStepLayout.Header>
            <NewSinglePaymentStepLayout.CloseButton
              onClick={() => {
                trackClick('exit');
                onClose();
              }}
            />
            <NewSinglePaymentStepLayout.BackButton {...cancelButtonProps} onClick={onBack} />
          </NewSinglePaymentStepLayout.Header>
        }
        footerContent={
          selectedCountryDetails?.isSupported ? (
            <NewSinglePaymentStepLayout.Actions>
              <NewSinglePaymentStepLayout.NextButton
                {...submitButtonProps}
                onClick={(event?: React.MouseEvent<HTMLButtonElement>, target?: string) => {
                  trackClick('continue');
                  submitButtonProps?.onClick(event, target);
                }}
                label={formatMessage('activities.internationalBankDetails.screens.internationalBankDetails.continue')}
              />
            </NewSinglePaymentStepLayout.Actions>
          ) : undefined
        }
        bottomContent={
          selectedCountryDetails?.isSupported ? (
            <NewSinglePaymentStepLayout.BottomTextContent>
              <Text textStyle="caption1">
                {formatMessage('activities.internationalBankDetails.screens.internationalBankDetails.description')}
              </Text>
            </NewSinglePaymentStepLayout.BottomTextContent>
          ) : undefined
        }
      >
        <NewSinglePaymentStepLayout.Title>
          {formatMessage('activities.internationalBankDetails.screens.internationalBankDetails.title', {
            vendorName,
          })}
        </NewSinglePaymentStepLayout.Title>
        <NewSinglePaymentStepLayout.Content>
          <Group spacing="l" variant="vertical" width="full">
            {shouldShowFailedCreationBanner && <CreationBadRequestBanner />}
            <InternationalBankDetailsForm
              countriesDetailsOptions={countriesDetails}
              defaultValues={defaultValues}
              onSubmissionStateChange={onSubmissionStateChange}
              onSubmit={onDone}
              onSelectedCountryChange={setSelectedCountryDetails}
              selectedCountry={selectedCountryDetails}
            />
          </Group>
          <UnsupportedInternationalCountryModal countryDetails={selectedCountryDetails} />
        </NewSinglePaymentStepLayout.Content>
      </NewSinglePaymentStepLayout>
    );
  }
);

InternationalBankDetailsScreen.displayName = 'InternationalBankDetailsActivity.InternationalBankDetailsScreen';
