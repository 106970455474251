import { Payment } from '@melio/platform-api';

import { getPaymentAnalyticsFields } from '../../../utils/analytics/paymentAnalytics';
import { Steps } from './types/types';

export const getDefaultAnalyticsFields = (step: Steps, payment: Payment) => {
  const defaultFields = {
    ...getPaymentAnalyticsFields(payment),
    Intent: 'refund-failed-delivery',
    EntryPoint: 'dashboard-inbox',
  };

  if (step === 'review') {
    return {
      ...defaultFields,
      PageName: 'review-and-confirm-refund',
    };
  }

  return {
    ...defaultFields,
    PageName: 'your-refund-on-the-way',
  };
};
