import { Payment, PaymentApprovalDecisionStatusEnum } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useConfig } from '@melio/platform-provider';
import { useCallback } from 'react';

import { usePaymentsLateApprovals } from './usePaymentsLateApprovals';

export const usePaymentsLateApprovalsEnabled = () => {
  const [isApproveLatePaymentEnabled] = useFeature(FeatureFlags.ApproveLatePayment, false);
  const {
    settings: {
      paymentLateApproval: { debitDatePassedIndicationEnabled, debitDateTodayIndicationEnabled },
    },
  } = useConfig();
  const getPaymentsLateApproval = usePaymentsLateApprovals();

  return useCallback(
    ({ payments }: { payments: Payment[] }) => {
      const paymentsLateApproval = getPaymentsLateApproval({ payments });
      return payments.reduce((acc, payment) => {
        const paymentLateApproval = paymentsLateApproval[payment.id];

        const isDebitDateAfterToday = !!(
          paymentLateApproval?.isDebitDateAfterToday && debitDatePassedIndicationEnabled
        );
        const isDebitDateToday = !!(paymentLateApproval?.isDebitDateToday && debitDateTodayIndicationEnabled);

        acc[payment.id] = {
          isEnabled:
            isApproveLatePaymentEnabled &&
            payment.approvalDecisionStatus === PaymentApprovalDecisionStatusEnum.Pending &&
            (isDebitDateAfterToday || isDebitDateToday),
        };
        return acc;
      }, {} as { [paymentId: string]: { isEnabled: boolean } });
    },
    [
      debitDatePassedIndicationEnabled,
      debitDateTodayIndicationEnabled,
      getPaymentsLateApproval,
      isApproveLatePaymentEnabled,
    ]
  );
};
