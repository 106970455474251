import { FormDialog, FormInputs, useMelioIntl } from '@melio/ar-domain';
import { useMelioForm } from '@melio/penny';
import { MccItem } from '@melio/platform-api';
import { forwardRef } from '@melio/platform-utils';
import { object, SchemaOf, string } from 'yup';

import { useMccOptions } from '../hooks';

export type IndustrySelectionVisaModalScreenProps = {
  isOpen: boolean;
  isSavingIndustry: boolean;
  isLoadingMccList: boolean;
  onSave: (mcc: string) => void;
  onClose: VoidFunction;
  mccIndustryList: MccItem[];
  vendorCompanyName: string;
};

type IndustrySelectionForm = { mcc: string };

const useSchema = () => {
  const { formatMessage } = useMelioIntl();
  return object().shape({
    mcc: string().required(formatMessage(`ar.guestPayment.industrySelectionModal.category.validation`)),
  }) as SchemaOf<IndustrySelectionForm>;
};

export const IndustrySelectionVisaModalScreen = forwardRef<IndustrySelectionVisaModalScreenProps>(
  ({ isOpen, isSavingIndustry, isLoadingMccList, onSave, onClose, mccIndustryList, vendorCompanyName }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { clearErrors, setValue, registerField, watch, ...rest } = useMelioForm<IndustrySelectionForm>({
      onSubmit: ({ mcc }) => onSave(mcc),
      isSaving: isSavingIndustry,
      schema: useSchema(),
    });
    const { mccOptions } = useMccOptions({ mccIndustryList, selectedGroup: '', isMCCGroupsSupported: false });

    return (
      <FormDialog
        ref={ref}
        isOpen={isOpen}
        onClose={onClose}
        isLoading={isLoadingMccList}
        data-testid="industry-selection-modal"
        data-component="IndustrySelectionVisaForm"
        header={formatMessage('ar.guestPayment.industrySelectionModal.header')}
        description={formatMessage(`ar.guestPayment.industrySelectionModal.visa.description`, {
          companyName: vendorCompanyName,
        })}
        primaryButton={{
          variant: 'primary',
          label: formatMessage('ar.guestPayment.industrySelectionModal.submit.label'),
        }}
        useFormResults={rest}
      >
        <FormInputs.Select
          shouldHideClearButton
          data-testid="industry-selection-modal-mcc-select"
          options={mccOptions}
          labelProps={{ label: formatMessage(`ar.guestPayment.industrySelectionModal.category.label`) }}
          placeholder={formatMessage(`ar.guestPayment.industrySelectionModal.category.placeholder`)}
          {...registerField('mcc')}
        />
      </FormDialog>
    );
  }
);
IndustrySelectionVisaModalScreen.displayName = 'IndustrySelectionVisaModalScreen';
