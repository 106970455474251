import { Container, Group, Icon, Text } from '@melio/penny';

import { PlanCapabilities } from '../../../../hooks';

export type PlanCardFeaturesListProps = { capabilities: PlanCapabilities };

export const PlanCardFeaturesList = ({ capabilities }: PlanCardFeaturesListProps) => (
  <Group spacing="xs" variant="vertical">
    <Container paddingBottom="xxs">
      <Text as="h3" textStyle="body3Semi">
        {capabilities.title}
      </Text>
    </Container>

    <Group as="ul" variant="vertical" spacing="xs">
      {capabilities.list.map((item) => (
        <Group alignItems="center" spacing="xs" key={item} as="li">
          <Icon type="checked" size="small" color="brand" />
          <Text textStyle="body3" color="global.neutral.900">
            {item}
          </Text>
        </Group>
      ))}
    </Group>
  </Group>
);
