import { useModelApi, UseModelApiProps } from '@melio/api-client';
import { SyncApiClient } from '@melio/platform-api-axios-client';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getSyncMigration = (_id: string) => SyncApiClient.getSyncMigration();

export type UseSyncMigrationProps = UseModelApiProps<typeof getSyncMigration>;

export const useSyncMigration = (props?: UseSyncMigrationProps) =>
  useModelApi({
    ...props,
    id: 'sync-api',
    queryKey: 'SyncApi',
    queryFn: getSyncMigration,
    cacheTime: Infinity,
    staleTime: Infinity,
  });
