/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Box, Flex, HStack } from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import { Button, Icon, InteractiveButton, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';

import { FormSearch } from '@/cl/components/FormSearch/FormSearch.component';
import { useRouter } from '@/hooks/router.hooks';
import { vendorsDashboardSearchQuerySelector } from '@/store/Vendors/Vendors.model';
import { usePlatformIntl } from '@/translations/Intl';
import { DataComponentEnum } from '@/types/vendors.types';
import { VendorsSortedCardList } from './VendorsSortedCardList.widget';

export const VendorsList = () => {
  const { formatMessage } = usePlatformIntl();
  const { goToAddNewVendor } = useRouter();
  const { track } = useAnalytics();
  const [searchQuery, setSearchQuery] = useRecoilState(vendorsDashboardSearchQuerySelector);
  const [splitContactsTab] = useFeature(FeatureFlags.SplitContactsTab, true);

  const onClickHandler = React.useCallback(() => {
    goToAddNewVendor();
    track('VendorDashboardAddVendor', 'Chose');
  }, [goToAddNewVendor]);

  const onSearch = (searchString: string) => {
    setSearchQuery(searchString);
  };

  const newVendorButton = splitContactsTab ? (
    <InteractiveButton label={formatMessage('widgets.newVendor.newButton')} onClick={onClickHandler} />
  ) : (
    <Button
      leftElement={<Icon size="small" type="add" color="inherit" aria-hidden />}
      onClick={onClickHandler}
      label={formatMessage('widgets.newVendor.newButton')}
    />
  );

  return (
    <Flex data-component={DataComponentEnum.VENDORS_LIST} direction={'column'} margin={'auto'} height={'full'}>
      <HStack alignItems={'center'} justifyContent={'space-between'} overflow="hidden">
        <Text as="h2" textStyle="heading1Semi">
          {formatMessage('widgets.vendorsList.title')}
        </Text>
        {newVendorButton}
      </HStack>
      <Box mt="40px">
        <FormSearch
          onSearch={onSearch}
          placeholder={formatMessage('widgets.vendorsList.searchPlaceholder')}
          defaultValue={searchQuery}
        />
      </Box>
      <VendorsSortedCardList />
    </Flex>
  );
};
