/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react';
import { createSearchParams, useLocation } from 'react-router-dom';
import { Box, Stack } from '@chakra-ui/react';
import { Container, Text, useBreakpoint } from '@melio/penny';
import { useAnalyticsContext, withAnalyticsContext } from '@melio/platform-analytics';
import {
  DeliveryMethod,
  DeliveryMethodType,
  useDeliveryMethodTypeOptions,
  Vendor,
  VirtualCardAccountDeliveryMethod,
} from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useConfig } from '@melio/platform-provider';

import { WithLoading } from '@/hoc/withLoading.hoc';
import { usePlatformIntl } from '@/translations/Intl';
import { DeliveryMethodNavigationStateParams } from '@/types/deliveryMethod.types';
import { DataComponentEnum } from '@/types/vendors.types';
import { AchDeliveryMethodCard } from '@/widgets/vendorsDashboard/DeliveryMethod/AchDeliveryMethodCard.widget';
import { DomesticWireDeliveryMethodCard } from '@/widgets/vendorsDashboard/DeliveryMethod/DomesticWireDeliveryMethodCard.widget';
import { ManagedDeliveryMethod } from '@/widgets/vendorsDashboard/DeliveryMethod/ManagedDeliveryMethod';
import { PaperCheckDeliveryMethodCard } from '@/widgets/vendorsDashboard/DeliveryMethod/PaperCheckDeliveryMethodCard.widget';
import { UnmanagedVirtualCardDeliveryMethodWidget } from '@/widgets/vendorsDashboard/DeliveryMethod/UnmanagedVirtualCardDeliveryMethodWidget';
import { UnilateralRequestDetails } from '../UnilateralRequestDetails/UnilateralRequestDetails.widget';
import { InternationalDeliveryMethodCard } from './InternationalDeliveryMethodCard.widget';

export interface DeliveryMethodsProps {
  vendor: Vendor;
  deliveryMethods: DeliveryMethod[];
}

export const VendorDeliveryMethods = withAnalyticsContext(({ vendor, deliveryMethods }: DeliveryMethodsProps) => {
  const [isNetworkVendorSupportEnabled] = useFeature(FeatureFlags.NetworkVendorSupport, false);
  const { pathname, search } = useLocation();

  const {
    settings: { isUnilateralDeliveryMethodSupported },
  } = useConfig();
  const { isExtraSmallScreen } = useBreakpoint();

  const { formatMessage } = usePlatformIntl();

  useAnalyticsContext({
    globalProperties: {
      PageName: 'vendor-details',
      Intent: 'add-delivery-method',
      VendorId: vendor.id,
    },
  });

  const { data: deliveryMethodTypeOptions, isFetching: isDeliveryMethodTypeOptionsLoading } =
    useDeliveryMethodTypeOptions({ vendorId: vendor.id });

  const isManagedVendor = isNetworkVendorSupportEnabled && vendor.isManaged;

  const supportedDeliveryMethodByType = deliveryMethodTypeOptions?.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.type]: curr.supported,
    }),
    {} as Record<DeliveryMethodType, boolean>,
  );

  const isBankAccountEnabled = supportedDeliveryMethodByType?.['bank-account'];
  const isPaperCheckEnabled = supportedDeliveryMethodByType?.['paper-check'];
  const isInternationalEnabled = supportedDeliveryMethodByType?.['international-account'];
  const isDomesticWireEnabled = supportedDeliveryMethodByType?.['domestic-wire-account'];
  const isVirtualCardEnabled = supportedDeliveryMethodByType?.['virtual-card'];
  const isUnilateralDeliveryMethodEnabled =
    isUnilateralDeliveryMethodSupported && supportedDeliveryMethodByType?.['virtual-account'];

  const getDeliveryMethodByType = useCallback(
    (deliveryMethodType: DeliveryMethodType) => {
      if (!deliveryMethods) {
        return null;
      }
      return deliveryMethods.find((deliveryMethod) => deliveryMethod.type === deliveryMethodType) || null;
    },
    [deliveryMethods, vendor],
  );

  const navigationStateParams: DeliveryMethodNavigationStateParams = {
    id: vendor?.id,
    returnUrl: `${pathname}?${createSearchParams(search).toString()}`,
  };

  // TODO: check whether "by you" is a constant text
  // TODO: use toLocaleDateString() for the date format
  return (
    <WithLoading isLoading={isDeliveryMethodTypeOptionsLoading}>
      <Box
        padding={isExtraSmallScreen ? '16px' : '32px 40px'}
        border="basic.light"
        borderRadius={'8px'}
        data-component={DataComponentEnum.DELIVERY_METHODS}
        data-testid="vendor-delivery-methods"
      >
        <Container paddingBottom="m">
          <Text as="h2" textStyle="heading2Semi">
            {formatMessage('widgets.vendorDeliveryMethods.title')}
          </Text>
        </Container>
        <Stack flexDirection="column" gap={'16px'} spacing={0}>
          {isManagedVendor ? (
            <ManagedDeliveryMethod vendor={vendor} deliveryMethods={deliveryMethods} />
          ) : (
            <>
              {isUnilateralDeliveryMethodEnabled ? <UnilateralRequestDetails vendor={vendor} /> : null}
              {isBankAccountEnabled ? (
                <AchDeliveryMethodCard
                  vendor={vendor}
                  ach={getDeliveryMethodByType('bank-account')}
                  navigationStateParams={navigationStateParams}
                />
              ) : null}
              {isDomesticWireEnabled ? (
                <DomesticWireDeliveryMethodCard
                  vendor={vendor}
                  domesticWire={getDeliveryMethodByType('domestic-wire-account')}
                  navigationStateParams={navigationStateParams}
                />
              ) : null}
              {isPaperCheckEnabled ? (
                <PaperCheckDeliveryMethodCard
                  vendor={vendor}
                  check={getDeliveryMethodByType('paper-check')}
                  navigationStateParams={navigationStateParams}
                />
              ) : null}
              {isInternationalEnabled ? (
                <InternationalDeliveryMethodCard
                  international={getDeliveryMethodByType('international-account')}
                  navigationStateParams={navigationStateParams}
                />
              ) : null}
              {isVirtualCardEnabled ? (
                <UnmanagedVirtualCardDeliveryMethodWidget
                  unmanagedVirtualCard={
                    (getDeliveryMethodByType('virtual-card') as VirtualCardAccountDeliveryMethod) || null
                  }
                  navigationStateParams={navigationStateParams}
                />
              ) : null}
            </>
          )}
        </Stack>
      </Box>
    </WithLoading>
  );
});
