import { useNonEntityMutation } from '@melio/api-client';
// eslint-disable-next-line no-restricted-imports
import {
  AccountantsFirmClientApiClient,
  PostCreateFirmClientRequest,
  PostCreateFirmClientResponse,
} from '@melio/platform-api-axios-client';

export const useCreateAccountantFirmClient = () =>
  useNonEntityMutation<
    typeof AccountantsFirmClientApiClient.createAccountantFirmClient,
    PostCreateFirmClientRequest,
    PostCreateFirmClientResponse
  >(AccountantsFirmClientApiClient.createAccountantFirmClient, ['AccountantsFirmClientApi', 'collection', 'default']);
