import { Bill } from '@melio/platform-api';
import { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const usePaymentFlowNavigation = () => {
  const [search] = useSearchParams();
  const navigate = useNavigate();

  return useMemo(
    () => ({
      toRoot: (options?: { search?: URLSearchParams }) => {
        const searchParams = new URLSearchParams({
          ...Object.fromEntries(search.entries()),
          ...Object.fromEntries(options?.search?.entries() || []),
        }).toString();
        navigate({
          pathname: '',
          search: searchParams,
        });
      },
      toDeliveryMethod: () => navigate({ pathname: 'delivery-method', search: search.toString() }),
      toFundingSource: () => navigate({ pathname: 'funding-source', search: search.toString() }),
      toBill: (bill: Bill | undefined) => {
        const pathname = bill ? `bill/edit` : 'bill/add';
        navigate({ pathname, search: search.toString() });
      },
      toLegal: () => navigate({ pathname: 'legal', search: search.toString() }),
    }),
    [navigate, search]
  );
};
