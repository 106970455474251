import { useUserPreference } from '@melio/platform-api';
import { usePermissions } from '@melio/platform-permissions';
import { useState } from 'react';

import { useSubscriptionRouter } from '../../utils';
import { RequestSubscriptionUpgradeModal } from '../RequestSubscriptionUpgradeModal';
import { GRACE_PERIOD_LIMITED_TIME_OFFER_PREFERENCE_KEY, GRACE_PERIOD_PREFERENCE_KEY } from './consts';
import { GraceLimitedTimeOfferModal } from './GraceLimitedTimeOfferModal.screen';
import { GracePeriodIntroductionModalScreen } from './GracePeriodIntroductionModal.screen';
import { useShowGracePeriodModals } from './hooks/useShowGracePeriodModals';

export const GracePeriodModalActivity = () => {
  const [isRequestSubscriptionUpgradeModalOpen, setIsRequestSubscriptionUpgradeModalOpen] = useState(false);
  const { can } = usePermissions();
  const { goToSubscriptionPlans } = useSubscriptionRouter();
  const {
    isFetching: isUserPreferenceFetching,
    isMutating: isUserPreferenceMutating,
    create: createUserPreference,
  } = useUserPreference();
  const {
    shouldShowGracePeriodModals,
    shouldShowIntroductionModal,
    shouldShowLimitedTimeOfferModal,
    canClaimIntroOffer,
    isWithinWeekBeforeGracePeriodEnd,
  } = useShowGracePeriodModals();

  const isUserPreferenceFetchingOrMutating = isUserPreferenceFetching || isUserPreferenceMutating;

  const canUpdateSubscription = can({
    subject: 'subscriptions:me',
    action: 'update',
  });

  const claimOffer = () => {
    if (canUpdateSubscription) {
      return goToSubscriptionPlans({
        analyticsEntryPoint: 'subscription-inroduction-modal',
        analyticsFlow: 'existing-user-transitioning',
      });
    }

    setIsRequestSubscriptionUpgradeModalOpen(true);
  };

  const onRequestSubscriptionUpgradeModalDismiss = () => {
    setIsRequestSubscriptionUpgradeModalOpen(false);
  };

  const onIntroductionModalClose = () => {
    void createUserPreference({ userPreference: { key: GRACE_PERIOD_PREFERENCE_KEY, value: 'true' } });

    if (isWithinWeekBeforeGracePeriodEnd) {
      void createUserPreference({
        userPreference: { key: GRACE_PERIOD_LIMITED_TIME_OFFER_PREFERENCE_KEY, value: 'true' },
      });
    }
  };

  const onLimitedTimeOfferModalClose = () => {
    void createUserPreference({
      userPreference: { key: GRACE_PERIOD_LIMITED_TIME_OFFER_PREFERENCE_KEY, value: 'true' },
    });
  };

  if (!shouldShowGracePeriodModals || isUserPreferenceFetchingOrMutating) {
    return null;
  }

  return (
    <>
      {!shouldShowLimitedTimeOfferModal && shouldShowIntroductionModal && (
        <GracePeriodIntroductionModalScreen
          onDismiss={onIntroductionModalClose}
          claimOffer={claimOffer}
          canClaimOffer={canClaimIntroOffer}
        />
      )}
      {!shouldShowIntroductionModal && shouldShowLimitedTimeOfferModal && (
        <GraceLimitedTimeOfferModal
          onDismiss={onLimitedTimeOfferModalClose}
          onChoosePlanNonOwner={() => setIsRequestSubscriptionUpgradeModalOpen(true)}
        />
      )}
      {isRequestSubscriptionUpgradeModalOpen && (
        <RequestSubscriptionUpgradeModal isOpen onDismiss={onRequestSubscriptionUpgradeModalDismiss} />
      )}
    </>
  );
};
