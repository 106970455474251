import { OrganizationBeneficialOwner } from '@melio/platform-api';
import { useDateUtils } from '@melio/platform-utils';

import { FinancingApplicationOwnerDetailsForm } from '../types';

export const useOwnershipDetailsFormDefaultValues = ({
  beneficialOwners = [],
}: {
  beneficialOwners?: OrganizationBeneficialOwner[];
}): RecursivePartial<FinancingApplicationOwnerDetailsForm> => {
  const { createDate } = useDateUtils();
  if (beneficialOwners?.length === 1) {
    const beneficialOwner = (beneficialOwners as [OrganizationBeneficialOwner])[0];
    return {
      firstName: beneficialOwner.firstName ?? '',
      lastName: beneficialOwner.lastName ?? '',
      ssn: beneficialOwner.taxId ?? '',
      jobTitle: beneficialOwner.jobTitle ?? '',
      email: beneficialOwner.email ?? '',
      dateOfBirth: createDate(beneficialOwner.dateOfBirth).toISOString() ?? undefined,
      phone: beneficialOwner.phoneNumber ?? '',
      additionalOwners: [],
    };
  }

  return {
    firstName: '',
    lastName: '',
    ssn: '',
    jobTitle: '',
    email: '',
    dateOfBirth: undefined,
    phone: '',
    additionalOwners: [],
  };
};
