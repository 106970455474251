import { Group, Icon, Illustration, Modal, Text, useBreakpointValue } from '@melio/penny';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePermissions } from '@melio/platform-permissions';

import { useSubscriptionGracePeriod } from '../../../hooks';
import { useSubscriptionRouter } from '../../utils/useSubscriptionRouter';

type GraceLimitedTimeOfferModalProps = {
  onChoosePlanNonOwner: () => void;
  onDismiss: () => void;
};

export const GraceLimitedTimeOfferModal = withAnalyticsContext<GraceLimitedTimeOfferModalProps>(
  ({ onDismiss, onChoosePlanNonOwner, setAnalyticsProperties }) => {
    setAnalyticsProperties({
      PageName: 'limited-time-offer',
      Flow: 'existing-user-transition',
      Intent: 'subscription-introduction',
      Discount: '35%',
    });
    const { goToSubscriptionPlans } = useSubscriptionRouter();
    const { track } = useAnalytics();
    const { formatMessage, formatDate } = useMelioIntl();
    const { gracePeriodEndDateToDisplay } = useSubscriptionGracePeriod();
    const { can } = usePermissions();
    const isMobileOrTablet = useBreakpointValue({ s: true, m: false });

    const canUpdateSubscription = can({
      subject: 'subscriptions:me',
      action: 'update',
    });

    useAnalyticsView('Organization', true, true);

    const onExit = () => {
      track('Organization', 'Click', {
        Intent: 'exit',
        Cta: 'exit',
      });
      onDismiss();
    };

    const onClose = () => {
      track('Organization', 'Click', {
        Intent: 'exit',
        Cta: 'maybe-later',
      });
      onDismiss();
    };

    const onChoosePlan = () => {
      track('Organization', 'Click', {
        Intent: 'choose-plan',
        Cta: 'choose-plan',
      });

      if (canUpdateSubscription) {
        onDismiss();
        goToSubscriptionPlans({
          analyticsFlow: 'existing-user-transition',
          analyticsEntryPoint: 'grace-limited-time-offer-modal',
        });
      } else {
        onDismiss();
        onChoosePlanNonOwner();
      }
    };

    return (
      <Modal
        isOpen
        size="small"
        header={
          <Group variant="horizontal" spacing="s" alignItems="center">
            <Icon type="discount" color="brand" size="large" />
            <Text textStyle="heading2Semi">
              {formatMessage('activities.subscription.graceLimitedTimeOfferModal.header')}
            </Text>
          </Group>
        }
        onClose={onExit}
        primaryButton={{
          label: formatMessage('activities.subscription.graceLimitedTimeOfferModal.choosePlan'),
          variant: 'primary',
          onClick: onChoosePlan,
        }}
        secondaryButton={{
          label: formatMessage('activities.subscription.graceLimitedTimeOfferModal.maybeLater'),
          variant: 'secondary',
          onClick: onClose,
        }}
        data-testid="grace-limited-time-offer-modal"
      >
        <Group variant={isMobileOrTablet ? 'vertical' : 'horizontal'} spacing="l">
          <Group.Item basis="70%" order={isMobileOrTablet ? 1 : 0}>
            <Group variant="vertical">
              <Text textStyle="heading1Semi">
                {formatMessage('activities.subscription.graceLimitedTimeOfferModal.title')}
              </Text>
              <Text>
                {formatMessage('activities.subscription.graceLimitedTimeOfferModal.description', {
                  gracePeriodEnd: formatDate(gracePeriodEndDateToDisplay, {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                  }),
                })}
              </Text>
            </Group>
          </Group.Item>
          <Group.Item basis="30%" alignSelf="center">
            <Illustration size="large" type="gift" />
          </Group.Item>
        </Group>
      </Modal>
    );
  }
);
