import { Box } from '@chakra-ui/react';
import { Form, NakedButton } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { useSubscriptionFeature } from '@melio/subscriptions';

import { LineItemsHeader } from './LineItemsHeader';

type LineItemsPaywallWrapperProps = {
  isHidden?: boolean;
  isLineItemsExpanded: boolean;
  onExpandLineItems: VoidFunction;
  title: string;
};
export const LineItemsPaywallWrapper = forwardRef<LineItemsPaywallWrapperProps>(
  ({ isHidden = false, isLineItemsExpanded, onExpandLineItems, title, children, ...props }, ref) => {
    const { tryUseFeature, isEligible } = useSubscriptionFeature({ featureName: 'lineItems' });
    const { formatMessage } = useMelioIntl();

    const isExpanded = isLineItemsExpanded && isEligible;

    return isHidden ? null : (
      <Form.ContentBox colSpan={16} {...props} ref={ref}>
        <LineItemsHeader title={title} />
        {!isExpanded && (
          <NakedButton
            data-testid="expand-line-items-button"
            variant="secondary"
            label={formatMessage('activities.addBillV2.lineItems.expand.label')}
            onClick={() => tryUseFeature({ onFeatureIsEligible: onExpandLineItems })}
          />
        )}
        <Box display={isExpanded ? undefined : 'none'}>{children}</Box>
      </Form.ContentBox>
    );
  }
);
