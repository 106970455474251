import { Form, useMelioForm } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';

import { InvoiceNumberSchema, SetInvoiceNumberFormProps } from './types';
import { useSetInvoiceNumberSchema } from './useSetInvoiceNumberSchema';

export const SetInvoiceNumberForm = forwardRef<SetInvoiceNumberFormProps, 'form'>(
  ({ defaultValues, isSaving, onSubmit, onSubmissionStateChange, onError, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const { formProps, registerField } = useMelioForm<InvoiceNumberSchema>({
      onSubmit,
      schema: useSetInvoiceNumberSchema(),
      isSaving,
      defaultValues,
      onSubmissionStateChange,
    });

    useEffect(() => {
      if (registerField('invoiceNumber').error && onError) {
        onError();
      }
    }, [onError, registerField]);

    return (
      <Form data-component="SetInvoiceNumberForm" {...props} {...formProps} ref={ref}>
        <Form.TextField
          labelProps={{ label: formatMessage('activities.addBill.setInvoiceNumberModal.field.label') }}
          {...registerField('invoiceNumber')}
          isRequired
        />
      </Form>
    );
  }
);

SetInvoiceNumberForm.displayName = 'SetInvoiceNumberForm';
