import { DomesticWireForm, DomesticWireFormFields } from '@melio/ap-widgets';
import { Container, Group, useFormSubmissionController } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import React, { ComponentProps } from 'react';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';

export type EditVendorDomesticWireDetailsScreenProps = {
  isLoading?: boolean;
  isSaving: boolean;
  vendorName: Vendor['name'];
  defaultValues?: Partial<DomesticWireFormFields>;
  onBack: VoidFunction;
  onValidation?: ComponentProps<typeof DomesticWireForm>['onValidation'];
  banner?: JSX.Element | null;
  onClose: VoidFunction;
  onDone: (data: DomesticWireFormFields) => void;
};

export const EditVendorDomesticWireDetailsScreen: React.FC<EditVendorDomesticWireDetailsScreenProps> = forwardRef<
  EditVendorDomesticWireDetailsScreenProps,
  'div'
>(
  (
    { isLoading, isSaving, vendorName, defaultValues, onBack, onClose, onDone, onValidation, banner, ...props },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();
    const { onSubmissionStateChange, cancelButtonProps, submitButtonProps } =
      useFormSubmissionController<DomesticWireFormFields>();

    useAnalyticsView('EditVendorDomesticWireDetails');

    return (
      <NewSinglePaymentStepLayout
        isLoading={isLoading}
        data-component="EditVendorDomesticWireDetailsActivity.EditVendorDomesticWireDetailsScreen"
        data-testid="edit-vendor-domestic-wire-details-activity-edit-vendor-domestic-wire-details-screen"
        {...props}
        ref={ref}
        headerContent={
          <NewSinglePaymentStepLayout.Header>
            <NewSinglePaymentStepLayout.CloseButton onClick={onClose} />
            <NewSinglePaymentStepLayout.BackButton {...cancelButtonProps} onClick={onBack} />
          </NewSinglePaymentStepLayout.Header>
        }
        footerContent={
          <NewSinglePaymentStepLayout.Actions>
            <NewSinglePaymentStepLayout.NextButton
              {...submitButtonProps}
              label={formatMessage(
                'activities.editVendorDomesticWireDetails.screens.editVendorDomesticWireDetails.continue'
              )}
            />
          </NewSinglePaymentStepLayout.Actions>
        }
        bottomContent={
          <NewSinglePaymentStepLayout.BottomTextContent>
            {formatMessage('activities.editVendorDomesticWireDetails.screens.editVendorDomesticWireDetails.footNotes', {
              vendorName,
            })}
          </NewSinglePaymentStepLayout.BottomTextContent>
        }
      >
        <NewSinglePaymentStepLayout.Title>
          {formatMessage('activities.editVendorDomesticWireDetails.screens.editVendorDomesticWireDetails.title', {
            vendorName,
          })}
        </NewSinglePaymentStepLayout.Title>
        <NewSinglePaymentStepLayout.Content>
          <Group spacing="l" variant="vertical" width="full">
            <Container width="full">
              <DomesticWireForm
                onSubmit={onDone}
                onValidation={onValidation}
                isSaving={isSaving}
                defaultValues={defaultValues}
                onSubmissionStateChange={onSubmissionStateChange}
              />
            </Container>
          </Group>
        </NewSinglePaymentStepLayout.Content>
      </NewSinglePaymentStepLayout>
    );
  }
);

EditVendorDomesticWireDetailsScreen.displayName =
  'EditVendorDomesticWireDetailsActivity.EditVendorDomesticWireDetailsScreen';
