import { TableColumnDef } from '@melio/penny';
import { Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMemo } from 'react';

import { AmountCell } from './components/cells/AmountCell';
import { VendorCell } from './components/cells/VendorCell';
import { VendorsBatchPaymentsTableMeta } from './types';

export const useVendorsBatchPaymentsColumns = () => {
  const { formatMessage } = useMelioIntl();

  return useMemo<TableColumnDef<Vendor, VendorsBatchPaymentsTableMeta>[]>(() => {
    const vendorCell: TableColumnDef<Vendor, VendorsBatchPaymentsTableMeta> = {
      id: 'vendor',
      size: 'm',
      header: formatMessage('activities.payDashboard.vendorsTab.vendorsBatchPaymentsModal.body.table.headers.vendor'),
      cell: ({ row }) => <VendorCell vendor={row} />,
    };
    const amountCell: TableColumnDef<Vendor, VendorsBatchPaymentsTableMeta> = {
      id: 'amount',
      size: 140,
      header: formatMessage('activities.payDashboard.vendorsTab.vendorsBatchPaymentsModal.body.table.headers.amount'),
      textAlign: 'end',
      cell: ({ row, meta }) => (meta ? <AmountCell vendor={row} onChange={meta.onChange} /> : null),
    };

    return [vendorCell, amountCell];
  }, [formatMessage]);
};
