import { useAnalytics } from '@melio/platform-analytics';
import {
  ApiError,
  Payment,
  PaymentDate,
  PaymentErrorCode,
  PostApprovalDecisionEnum,
  usePayments,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils';
import { useCallback } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useMutation } from 'react-query';

import { useEnrichedTodos, useTodosEnabled } from '../../todos-drawer';
import { useLatePayments } from './useLatePayments';

export const useBatchPaymentApprovalDecision = () => {
  const { showMessage, hideMessage } = useSystemMessage();
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const { batchApprovalDecision: batchApprovalDecisionMutate } = usePayments({ enabled: false });
  const { isEnabled: isTodosEnabled } = useTodosEnabled();
  const { refetch: refetchTodos } = useEnrichedTodos({ enabled: false });
  const { getLateAndOnTimePayments } = useLatePayments();

  const { isLoading, mutateAsync } = useMutation(
    async ({
      payments,
      decision,
      reason,
      etaDatesApprovedByTheUser,
    }: {
      payments: Payment[];
      decision: PostApprovalDecisionEnum;
      reason?: string;
      etaDatesApprovedByTheUser?: PaymentDate[];
    }) => {
      const { onTimePayments, latePayments } = await getLateAndOnTimePayments({
        payments,
        etaDatesApprovedByTheUser,
      });
      if (onTimePayments.length > 0) {
        await batchApprovalDecisionMutate({
          ids: onTimePayments.map((p) => p.id),
          approvalDecision: { decision, reason },
        });
        track('Dashboard', 'Status', {
          PageName: decision === 'approved' ? 'payment-approved' : 'payment-declined',
          Intent: 'view-approvals',
          Status: 'success',
        });
      }
      return { approvedPayments: onTimePayments, latePayments };
    }
  );

  const batchApprovalDecision = useCallback(
    async (
      payments: Payment[],
      decision: PostApprovalDecisionEnum,
      reason?: string,
      etaDatesApprovedByTheUser?: PaymentDate[]
    ): Promise<{ approvedPayments: Payment[]; latePayments: Payment[] }> => {
      try {
        const res = await mutateAsync({ payments, decision, reason, etaDatesApprovedByTheUser });

        if (isTodosEnabled) {
          refetchTodos();
        }
        return res;
      } catch (e) {
        const error = e as ApiError;
        const errorsMap: Record<string, string> = {
          [PaymentErrorCode.UnauthorizedPayment]: formatMessage(
            'activities.payDashboard.approvalsTab.errors.unauthorized'
          ),
          [PaymentErrorCode.PaymentEditingLocked]: formatMessage(
            'activities.payDashboard.approvalsTab.errors.paymentEditingLocked'
          ),
          [PaymentErrorCode.FailedPaymentEditingLocked]: formatMessage(
            'activities.payDashboard.approvalsTab.errors.failedPaymentEditingLocked'
          ),
        };
        const errorMessage =
          error.errorCode && errorsMap[error.errorCode]
            ? errorsMap[error.errorCode]
            : decision === PostApprovalDecisionEnum.Approved
            ? formatMessage('activities.payDashboard.approvalsTab.approveErrorToast')
            : formatMessage('activities.payDashboard.approvalsTab.declineErrorToast');

        showMessage({
          type: 'error',
          title: errorMessage,
          id: 'payment-approval-decision-error',
          dataTestId: 'payment-approval-decision-error',
        });

        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const showSuccessMessage = useCallback(
    ({
      ids,
      decision,
      onToastActionClick,
    }: {
      ids: string[];
      decision: PostApprovalDecisionEnum;
      onToastActionClick?: VoidFunction;
    }) => {
      const action = onToastActionClick
        ? {
            type: 'link' as const,
            text: formatMessage('activities.payDashboard.approvalsTab.approvalSuccessToastAction', {
              count: ids.length,
            }),
            onAction: () => {
              onToastActionClick();
              hideMessage();
            },
          }
        : undefined;

      showMessage({
        type: 'success',
        title: formatMessage(
          decision === PostApprovalDecisionEnum.Approved
            ? 'activities.payDashboard.approvalsTab.approveSuccessToast'
            : 'activities.payDashboard.approvalsTab.declineSuccessToast',
          { count: ids.length }
        ),
        id: 'payment-approval-decision-success',
        dataTestId: 'payment-approval-decision-success',
        action,
      });
    },
    [showMessage, formatMessage, hideMessage]
  );

  return { batchApprovalDecision, showSuccessMessage, isMutating: isLoading };
};
