import { Button, Container, Group, Illustration, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';

import { useSearchTerm } from '../../hooks/useSearchTerm';

export const EmptySearchResult = ({ onClear }: { onClear: VoidFunction }) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const { searchTerm, clearSearchTerm } = useSearchTerm();

  const handleClear = () => {
    clearSearchTerm();
    track('Dashboard', 'Click', { Intent: 'search', Cta: 'clear-search', SearchValue: null });
    onClear();
  };

  return (
    <Container data-testid="pay-dashboard-no-search-result" paddingY="xxxl">
      <Group spacing="s" alignItems="center" justifyContent="center" variant="vertical">
        <Illustration type="no-items" size="small" />
        <Text aria-live="assertive">
          {formatMessage('activities.payDashboard.search.noResult.body', { searchTerm })}
        </Text>
        <Button
          data-testid="pay-dashboard-clear-search-no-search-result"
          variant="tertiary"
          size="large"
          onClick={handleClear}
          label={formatMessage('activities.payDashboard.search.noResult.clear')}
        />
      </Group>
    </Container>
  );
};
