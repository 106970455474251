import { useMelioIntl } from '@melio/ar-domain';
import { Group, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';

type PaymentInfoRowProps = {
  label: string;
  value: string;
};

const PaymentInfoRow: React.FC<PaymentInfoRowProps> = ({ label, value }) => (
  <Group spacing="xxs">
    <Text textStyle="body2Semi">{label}:</Text>
    <Text textStyle="body2">{value}</Text>
  </Group>
);

export type PaymentConfirmationSummaryProps = {
  companyName: string;
  payee: string;
  invoiceNumber: string;
  paymentMethod: string;
};

export const PaymentConfirmationSummary = forwardRef<PaymentConfirmationSummaryProps>(
  ({ companyName, payee, invoiceNumber, paymentMethod }, ref) => {
    const { formatMessage } = useMelioIntl();

    return (
      <Group ref={ref} variant="vertical" spacing="xs">
        <PaymentInfoRow
          label={formatMessage('ar.guestPayment.successfulPayment.paymentInfo.payor.label')}
          value={companyName}
        />
        <PaymentInfoRow
          label={formatMessage('ar.guestPayment.successfulPayment.paymentInfo.payee.label')}
          value={payee}
        />
        <PaymentInfoRow
          label={formatMessage('ar.guestPayment.successfulPayment.paymentInfo.invoiceNumber.label')}
          value={invoiceNumber}
        />
        <PaymentInfoRow
          label={formatMessage('ar.guestPayment.successfulPayment.paymentInfo.paymentMethod.label')}
          value={paymentMethod}
        />
      </Group>
    );
  }
);

PaymentConfirmationSummary.displayName = 'PaymentConfirmationSummary';
