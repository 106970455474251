import { Group, Table, Text } from '@melio/penny';
import { Payment } from '@melio/platform-api';

export const PaymentsTabScheduledByCell = ({ payment }: { payment: Payment }) => (
  <Table.Cell>
    <Group variant="vertical" spacing="xxs" data-testid={`payments-tab-row-${payment.id}-scheduled-by-cell`}>
      <Text textStyle="body3">{getSchedulerFullName(payment)}</Text>
    </Group>
  </Table.Cell>
);

export const getSchedulerFullName = (payment: Payment) => {
  const schedulerFirstName = payment.createdBy?.firstName ?? '';

  return payment.createdBy?.lastName ? `${schedulerFirstName} ${payment.createdBy.lastName}` : schedulerFirstName;
};
