import { useMelioIntl } from '@melio/platform-i18n';
import { keyBy } from 'lodash';

import { AutoPaymentActivationFormProps } from '../types';

export const useAutoPaymentOptionsFormValues = ({
  autoPaymentOptions,
  vendor,
  fundingSources,
  autoPayment,
}: {
  autoPaymentOptions: AutoPaymentActivationFormProps['autoPaymentOptions'];
  vendor: AutoPaymentActivationFormProps['vendor'];
  fundingSources: AutoPaymentActivationFormProps['fundingSources'];
  autoPayment?: AutoPaymentActivationFormProps['autoPayment'];
}) => {
  const { formatMessage } = useMelioIntl();
  const { autoPaymentFundingSources, deliveryDateTypes, paymentAmountTypes } = autoPaymentOptions;
  const indexedFundingSources = keyBy(fundingSources, 'id');

  const autoPaymentFundingSourceOptions = autoPaymentFundingSources.map(({ fundingSourceId }) => ({
    label: indexedFundingSources[fundingSourceId]?.displayName ?? fundingSourceId,
    value: fundingSourceId,
  }));

  const deliveryDateTypeOptions = deliveryDateTypes.map((type) => ({
    label: formatMessage(
      `activities.vendor.autoPaymentActivationForm.screens.activation.form.deliveryDateType.${type}.label`
    ),
    description: formatMessage(
      `activities.vendor.autoPaymentActivationForm.screens.activation.form.deliveryDateType.${type}.description`
    ),
    value: type,
    testId: `option-deliveryDateType-${type}`,
  }));

  const scheduleDaysBeforeOptions = [1, 2, 3, 4, 5].map((days) => ({
    label: formatMessage(
      `activities.vendor.autoPaymentActivationForm.screens.activation.form.scheduleDaysBefore.options.label`,
      { days }
    ),
    value: days.toString(),
  }));

  const paymentAmountTypeOptions = paymentAmountTypes.map((type) => ({
    label: formatMessage(
      `activities.vendor.autoPaymentActivationForm.screens.activation.form.paymentAmountType.${type}.label`
    ),
    description: formatMessage(
      `activities.vendor.autoPaymentActivationForm.screens.activation.form.paymentAmountType.${type}.description`
    ),
    value: type,
    testId: `option-paymentAmountType-${type}`,
  }));

  const {
    deliveryDateType,
    fundingSourceId,
    paymentAmountType,
    shouldNotifyOnScheduled,
    shouldNotifyOnSent,
    fixedAmount,
    maxAmount,
    scheduleDaysBefore,
  } = autoPayment || {};

  return {
    autoPaymentFundingSourceOptions,
    deliveryDateTypeOptions,
    scheduleDaysBeforeOptions,
    paymentAmountTypeOptions,
    defaultValues: {
      accountNumber: vendor.accountNumber,
      fundingSourceId: fundingSourceId ?? autoPaymentFundingSourceOptions[0]?.value,
      deliveryDateType: deliveryDateType ?? deliveryDateTypeOptions[0]?.value,
      scheduleDaysBefore: scheduleDaysBefore?.toString() ?? scheduleDaysBeforeOptions[0]?.value,
      paymentAmountType: paymentAmountType ?? paymentAmountTypeOptions[0]?.value,
      maxAmount: maxAmount?.toString() ?? null,
      fixedAmount: fixedAmount?.toString() ?? null,
      shouldNotifyOnScheduled: shouldNotifyOnScheduled ?? false,
      shouldNotifyOnSent: shouldNotifyOnSent ?? false,
    },
  };
};
