/* eslint-disable no-restricted-syntax */
import { isBusinessDay, nextBusinessDay } from '@melio/melio-business-days';
import { convertDateToMidDay } from '@melio/platform-utils';

export const getClosestBusinessDay = (): Date => {
  const midDayDateInUTC = convertDateToMidDay(new Date().toUTCString());
  const closestBusinessDay = isBusinessDay(midDayDateInUTC) ? midDayDateInUTC : nextBusinessDay(midDayDateInUTC);

  return convertDateToMidDay(closestBusinessDay);
};
