import { AddManagedVendorForm } from './components/AddManagedVendorForm';
import { AddUnmanagedVendorForm } from './components/AddUnmanagedVendorForm';
import { AddVendorFormWidgetProps } from './types';

export const AddVendorFormWidget = ({
  isVendorSelected,
  isEmailRequired,
  emailRequiredMessage,
  ...props
}: AddVendorFormWidgetProps) =>
  props.managed ? (
    <AddManagedVendorForm {...props} managed={props.managed} isVendorSelected={isVendorSelected} />
  ) : (
    <AddUnmanagedVendorForm {...props} isEmailRequired={isEmailRequired} emailRequiredMessage={emailRequiredMessage} />
  );

AddVendorFormWidget.displayName = 'AddVendorFormWidget';
