import { Box, Center, Image } from '@chakra-ui/react';
import { Container, Group, Icon, Link, Modal, Text } from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useCallback } from 'react';

import dmImage from './images/dm.svg';
import fsImage from './images/fs.svg';
import payBillsImage from './images/payBills.svg';
import { AgreementTypeEnum } from './types';

export type ConsentScreenActivityProps = {
  termsOfServiceUrl: string;
  privacyPolicyUrl: string;
  isOpen: boolean;
  onClose: VoidFunction;
  onDone: VoidFunction;
};
export const ConsentScreenActivity = ({
  termsOfServiceUrl,
  privacyPolicyUrl,
  isOpen,
  onClose,
  onDone,
}: ConsentScreenActivityProps) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();

  const title = formatMessage('widgets.consentScreen.title');
  const illustration = <Image w={170} h={10} src={payBillsImage} alt="payBills" />;
  const header = formatMessage('widgets.consentScreen.header', { illustration, title });

  useAnalyticsView('Consent');

  const onDoneWithAnalytics = useCallback(() => {
    track('Consent', 'Click', {
      Page: title.toLowerCase().replaceAll(' ', '_'),
      Intent: 'Accept consent terms',
    });
    onDone();
  }, [track, onDone, title]);

  return (
    <Modal
      header={header}
      primaryButton={{
        label: formatMessage('widgets.consentScreen.button'),
        variant: 'primary',
        onClick: onDoneWithAnalytics,
      }}
      footer={
        <Container textAlign="center">
          <Text textStyle="body4">
            {formatMessage('widgets.consentScreen.note', {
              termsOfService: <Link href={termsOfServiceUrl} label={AgreementTypeEnum.termsOfService} newTab />,
              privacyPolicy: <Link href={privacyPolicyUrl} label={AgreementTypeEnum.privacyPolicy} newTab />,
            })}
          </Text>
        </Container>
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      <Group variant="vertical">
        <Text>{formatMessage('widgets.consentScreen.subtitle')}</Text>
        <Center>
          <Group variant="vertical">
            <Image w={100} h={100} src={fsImage} alt="card" />
            <Container textAlign="start">
              <Text>{formatMessage('widgets.consentScreen.fs')}</Text>
            </Container>
          </Group>
          <Box w={59} textAlign="left">
            <Icon type="arrow-right" color="default" />
          </Box>
          <Group variant="vertical">
            <Image w={100} h={100} src={dmImage} alt="check" />
            <Container textAlign="start">
              <Text>{formatMessage('widgets.consentScreen.dm')}</Text>
            </Container>
          </Group>
        </Center>
      </Group>
    </Modal>
  );
};
