import {
  AmountField,
  Container,
  Control,
  Form,
  FormField,
  FormLineItems,
  Group,
  Text,
  TextField,
  useBreakpoint,
  useWatch,
} from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { AddBillV2FormValues, RegisterFieldResult } from '../../../types';
import { DeleteLineItem } from '../DeleteLineItem';
import { DeleteLineItemMobile } from '../DeleteLineItemMobile';

type Props = {
  onClickRemoveLineItem: VoidFunction | null;
  index: number;
  descriptionFieldProps: RegisterFieldResult;
  amountFieldProps: RegisterFieldResult;
  hasLineItems: boolean;
  formControl: Control<AddBillV2FormValues>;
  currency?: string;
  shouldAutoFocus?: boolean;
};

export const UnSyncedLineItem = ({
  amountFieldProps,
  descriptionFieldProps,
  hasLineItems,
  currency,
  index,
  formControl,
  onClickRemoveLineItem,
  shouldAutoFocus = true,
}: Props) => {
  const { isExtraSmallScreen: isMobile } = useBreakpoint();
  const { formatCurrency, formatMessage } = useMelioIntl();

  const naturalIndex = index + 1;
  const shouldShowAmountCurrency = currency && currency !== 'USD';
  const [description, amount] = useWatch({
    control: formControl,
    name: [`nonSyncedLineItems.${index}.description`, `nonSyncedLineItems.${index}.amount`],
  });
  const deleteButtonAriaLabel = formatMessage('activities.addBillV2.lineItems.unsynced.delete.ariaLabel', {
    index: naturalIndex,
    description: description || undefined,
    amount: amount ? formatCurrency(parseFloat(amount), currency) : undefined,
  });

  if (isMobile) {
    return (
      <FormLineItems.MobileListItem index={index}>
        <Group variant="vertical" spacing="m">
          <Form.TextField
            {...descriptionFieldProps}
            autoFocus={shouldAutoFocus && hasLineItems}
            data-testid={`add-bill-v2-unsynced-line-item-description-${index}`}
            labelProps={{ label: formatMessage('activities.addBillV2.lineItems.unsynced.description.label') }}
            aria-label={formatMessage('activities.addBillV2.lineItems.unsynced.description.ariaLabel', {
              index: naturalIndex,
            })}
          />
          <Form.AmountField
            {...amountFieldProps}
            isRequired
            decimalScale={2}
            data-testid={`add-bill-v2-unsynced-line-item-amount-${index}`}
            labelProps={{ label: formatMessage('activities.addBillV2.lineItems.unsynced.amount.placeholder') }}
            aria-label={formatMessage('activities.addBillV2.lineItems.unsynced.amount.ariaLabel', {
              index: naturalIndex,
            })}
            currency={currency}
            endElement={
              shouldShowAmountCurrency ? (
                <Container paddingX="s">
                  <Text color="global.neutral.800">{currency}</Text>
                </Container>
              ) : undefined
            }
          />
          {onClickRemoveLineItem && (
            <DeleteLineItemMobile
              IndexNumber={index}
              label={formatMessage('activities.addBillV2.lineItems.removeItem.label')}
              onClick={onClickRemoveLineItem}
              testId="add-bill-v2-nonsynced-line-item-delete"
            />
          )}
        </Group>
      </FormLineItems.MobileListItem>
    );
  }

  return (
    <FormLineItems.Row>
      <FormLineItems.Cell size="xs" data-testid="add-bill-v2-unsynced-line-item-number">
        <FormField labelProps={{ label: '', isHidden: true }} render={() => <>{index + 1}</>} />
      </FormLineItems.Cell>
      <FormLineItems.Cell size="xxl">
        <FormField
          {...descriptionFieldProps}
          data-testid={`add-bill-v2-unsynced-line-item-description-${index}`}
          labelProps={{
            label: formatMessage('activities.addBillV2.lineItems.unsynced.description.label'),
            isHidden: true,
          }}
          size="small"
          render={(formFieldProps) => (
            <TextField
              {...formFieldProps}
              autoFocus={shouldAutoFocus && hasLineItems}
              aria-label={formatMessage('activities.addBillV2.lineItems.unsynced.description.ariaLabel', {
                index: naturalIndex,
              })}
            />
          )}
        />
      </FormLineItems.Cell>
      <FormLineItems.Cell size="m">
        <FormField
          data-testid={`add-bill-v2-unsynced-line-item-amount-${index}`}
          labelProps={{
            label: formatMessage('activities.addBillV2.lineItems.unsynced.amount.placeholder'),
            isHidden: true,
          }}
          {...amountFieldProps}
          isRequired
          size="small"
          render={(formFieldProps) => (
            <AmountField
              {...formFieldProps}
              decimalScale={2}
              aria-label={formatMessage('activities.addBillV2.lineItems.unsynced.amount.ariaLabel', {
                index: naturalIndex,
              })}
              currency={currency}
              endElement={
                shouldShowAmountCurrency ? (
                  <Container paddingX="s">
                    <Text color="global.neutral.800">{currency}</Text>
                  </Container>
                ) : undefined
              }
            />
          )}
        />
      </FormLineItems.Cell>
      {onClickRemoveLineItem && (
        <FormLineItems.Cell size={48}>
          <DeleteLineItem
            ariaLabel={deleteButtonAriaLabel}
            onClick={onClickRemoveLineItem}
            IndexNumber={index}
            testId="add-bill-v2-nonsynced-line-item-delete"
          />
        </FormLineItems.Cell>
      )}
    </FormLineItems.Row>
  );
};
