import {
  AccountingPlatform,
  BillSubscriptionEndPolicyEnum,
  BillSubscriptionIntervalTypeEnum,
} from '@melio/platform-api';

import { FormWidgetProps } from '../../types';

type BaseFields = {
  vendorId: string;
  billAmount: string;
  billNumber: string;
  paymentFrequency: string;
  invoice?: File;
  note?: string;
  categoryId?: string | null;
};

type SinglePaymentOnlyFields = {
  dueDate: Date;
};

export type RecurringBaseFields = {
  startDate: Date;
  endPolicy: BillSubscriptionEndPolicyEnum;
};

type RecurringNumOfOccurrencesOnlyFields = {
  numOfOccurrences?: number;
};

type RecurringEndDateOnlyFields = {
  endDate?: Date;
};

type RecurringEndPolicyOnlyFields = {
  lastAmount?: string;
};

export type SinglePaymentFields = BaseFields & SinglePaymentOnlyFields;
type RecurringFields = BaseFields & RecurringBaseFields;
type RecurringWithEndPolicyFields = RecurringFields & RecurringEndPolicyOnlyFields;
export type RecurringNumOfOccurrencesFields = RecurringWithEndPolicyFields & RecurringNumOfOccurrencesOnlyFields;
export type RecurringEndDateFields = RecurringWithEndPolicyFields & RecurringEndDateOnlyFields;

const baseFields = ['vendorId', 'billAmount', 'billNumber', 'categoryId', 'invoice', 'note', 'paymentFrequency'];
export const singlePaymentFields = baseFields.concat(['dueDate']);
export const recurringNumOfOccurrencesFields = baseFields.concat(['startDate', 'endPolicy', 'numOfOccurrences']);
export const recurringEndDateFields = baseFields.concat(['startDate', 'endPolicy', 'endDate']);

export type AddBillFormWidgetFields = SinglePaymentFields | RecurringNumOfOccurrencesFields | RecurringEndDateFields;

export type AddBillFormWidgetProps = FormWidgetProps<AddBillFormWidgetFields> & {
  shouldRequireInvoice?: boolean;
  activeAccountingPlatform?: AccountingPlatform;
  supportRecurringPayment?: boolean;
  isInternationalSupportedForRecurringFlow?: boolean;
  onUpdateFrequency?: (isRecurring: boolean) => void;
};

export const SINGLE_PAYMENT = 'One-time payment';
export const END_DATE_POLICY = 'end-date';
export const NUM_OF_OCCURRENCES_END_POLICY = 'num-of-occurrences';
export const NO_END_DATE = 'no-end-date';

const isBillSubscriptionIntervalTypeEnum = (frequency: string): frequency is BillSubscriptionIntervalTypeEnum =>
  Object.values(BillSubscriptionIntervalTypeEnum).includes(frequency as BillSubscriptionIntervalTypeEnum);

export const isRecurringFrequency = isBillSubscriptionIntervalTypeEnum;
