import { TabItem as DSTabItem } from '@melio/penny';

export const SettingsTab = {
  Items: 'items',
  Discounts: 'discounts',
  ServiceCharges: 'service-charges',
  Tax: 'tax',
} as const;
export type SettingsTab = (typeof SettingsTab)[keyof typeof SettingsTab];

export type TabItem = Override<DSTabItem, { name: SettingsTab }>;
