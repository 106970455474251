import React from 'react';
import { Stack } from '@chakra-ui/react';
import { useAnalytics } from '@melio/platform-analytics';
import { ScannedInvoice } from '@melio/platform-api';
import { useLocation } from '@melio/platform-utils';

import { useRouter } from '@/hooks/router.hooks';
import { ScannedInvoiceDetailsBody } from '@/widgets/pay-dashboard/scanned-invoice-details/components/ScannedInvoiceDetailsBody';
import { ScannedInvoiceDetailsHeader } from '@/widgets/pay-dashboard/scanned-invoice-details/components/ScannedInvoiceDetailsHeader';
import { ScannedInvoiceEmailDetails } from '@/widgets/pay-dashboard/scanned-invoice-details/components/ScannedInvoiceEmailDetails';

type ScannedInvoiceDetailsProps = {
  scannedInvoice: ScannedInvoice;
};

export const ScannedInvoiceDetails = ({ scannedInvoice }: ScannedInvoiceDetailsProps) => {
  const { goToReviewScannedInvoice } = useRouter();
  const { pathname } = useLocation();
  const { track } = useAnalytics();

  if (!scannedInvoice) {
    return null;
  }

  const onReviewBill = () => {
    track('PayDashboardInboxReviewBill', 'Chose', {
      ScannedInvoiceId: scannedInvoice.id,
    });
    goToReviewScannedInvoice({ scannedInvoiceId: scannedInvoice.id, returnUrl: pathname });
  };

  const { vendorName, invoiceNumber } = scannedInvoice;

  return (
    <Stack flexDirection="column" gridGap={'40px'} spacing={0} data-testid="pay-dashboard-scanned-invoice">
      <ScannedInvoiceDetailsHeader
        vendorName={vendorName || undefined}
        invoiceNumber={invoiceNumber || undefined}
        onClick={onReviewBill}
      />
      <ScannedInvoiceDetailsBody scannedInvoice={scannedInvoice} />
      {scannedInvoice.inboxEmail && <ScannedInvoiceEmailDetails inboxEmail={scannedInvoice.inboxEmail} />}
    </Stack>
  );
};
