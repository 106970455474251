import { DomesticWireForm, DomesticWireFormFields } from '@melio/ap-widgets';
import { Group, useFormSubmissionController } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';
import { DeliveryMethodFlowOrigin } from '../../../types';

export type AddVendorDomesticWireDetailsScreenProps = {
  isLoading?: boolean;
  isSaving: boolean;
  vendorName: Vendor['name'];
  defaultValues?: Partial<DomesticWireFormFields>;
  onBack: VoidFunction;
  onClose: VoidFunction;
  onDone: (
    data: DomesticWireFormFields,
    event?: React.MouseEvent<HTMLButtonElement>,
    target?: 'continue' | 'close'
  ) => void;
  origin: DeliveryMethodFlowOrigin;
};

export const AddVendorDomesticWireDetailsScreen: React.FC<AddVendorDomesticWireDetailsScreenProps> = forwardRef<
  AddVendorDomesticWireDetailsScreenProps,
  'div'
>(({ isLoading, isSaving, vendorName, defaultValues, onBack, onClose, onDone, origin, ...props }, ref) => {
  const { formatMessage } = useMelioIntl();
  const { onSubmissionStateChange, cancelButtonProps, submitButtonProps, handleSubmit } =
    useFormSubmissionController<DomesticWireFormFields>();

  useAnalyticsView('AddVendorDomesticWireDetails');

  return (
    <NewSinglePaymentStepLayout
      isLoading={isLoading}
      data-component="AddVendorDomesticWireDetailsActivity.AddVendorDomesticWireDetailsScreen"
      data-testid="add-vendor-domestic-wire-details-activity-add-vendor-domestic-wire-details-screen"
      {...props}
      ref={ref}
      headerContent={
        <NewSinglePaymentStepLayout.Header>
          <NewSinglePaymentStepLayout.BackButton {...cancelButtonProps} onClick={onBack} />
          <NewSinglePaymentStepLayout.CloseButton onClick={onClose} />
        </NewSinglePaymentStepLayout.Header>
      }
      footerContent={
        <NewSinglePaymentStepLayout.Actions>
          {origin === 'vendor' ? (
            <Group variant="vertical" spacing="m" width="full">
              <NewSinglePaymentStepLayout.NextButton
                {...submitButtonProps}
                onClick={handleSubmit?.('continue')}
                label={formatMessage(
                  'activities.addVendorDomesticWireDetails.screens.addVendorDomesticWireDetails.actions.vendorOrigin.continue'
                )}
              />
              <NewSinglePaymentStepLayout.SecondaryNextButton
                {...submitButtonProps}
                onClick={handleSubmit?.('close')}
                variant="tertiary"
                label={formatMessage(
                  'activities.addVendorDomesticWireDetails.screens.addVendorDomesticWireDetails.actions.vendorOrigin.close'
                )}
              />
            </Group>
          ) : (
            <NewSinglePaymentStepLayout.NextButton
              {...submitButtonProps}
              onClick={handleSubmit?.('continue')}
              label={formatMessage(
                'activities.addVendorDomesticWireDetails.screens.addVendorDomesticWireDetails.actions.paymentOrigin.continue'
              )}
            />
          )}
        </NewSinglePaymentStepLayout.Actions>
      }
      bottomContent={
        <NewSinglePaymentStepLayout.BottomTextContent>
          {formatMessage('activities.addVendorDomesticWireDetails.screens.addVendorDomesticWireDetails.footNotes', {
            vendorName,
          })}
        </NewSinglePaymentStepLayout.BottomTextContent>
      }
    >
      <NewSinglePaymentStepLayout.Title>
        {formatMessage('activities.addVendorDomesticWireDetails.screens.addVendorDomesticWireDetails.title', {
          vendorName,
        })}
      </NewSinglePaymentStepLayout.Title>
      <NewSinglePaymentStepLayout.Content>
        <DomesticWireForm
          onSubmit={onDone}
          isSaving={isSaving}
          defaultValues={defaultValues}
          onSubmissionStateChange={onSubmissionStateChange}
        />
      </NewSinglePaymentStepLayout.Content>
    </NewSinglePaymentStepLayout>
  );
});

AddVendorDomesticWireDetailsScreen.displayName =
  'AddVendorDomesticWireDetailsActivity.AddVendorDomesticWireDetailsScreen';
