import { FormWidgetProps } from '@melio/ap-widgets';
import { Form, Group, Text, useMelioForm } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef, useDateUtils } from '@melio/platform-utils';
import { useState } from 'react';
import { boolean, date, object, SchemaOf, string } from 'yup';

import { DAY, ExportFileFormWidgetFields, ExportFormat } from './createReport.types';

type ExportFileFormatProps = FormWidgetProps<ExportFileFormWidgetFields> & { includeMultipleFormats: boolean };

const schema = object().shape({
  fileFormatRadioValue: string().default(ExportFormat.CSV).oneOf(Object.values(ExportFormat)).required(),
  fromDate: date().required(),
  toDate: date().required(),
  includePayments: boolean().required(),
}) as SchemaOf<ExportFileFormWidgetFields>;

const useReportLabels = () => {
  const { formatMessage } = useMelioIntl();
  const fromLabel = formatMessage('widgets.createReportModal.form.startDate.label');
  const toLabel = formatMessage('widgets.createReportModal.form.endDate.label');
  const formatsLabel = formatMessage('widgets.createReportModal.form.formatsLabel');
  const csvLabel = formatMessage('widgets.createReportModal.form.formats.csv');
  const iifLabel = formatMessage('widgets.createReportModal.form.formats.iif');
  const qifLabel = formatMessage('widgets.createReportModal.form.formats.qif');
  const includePaymentsLabel = formatMessage('widgets.createReportModal.form.includePaymentsLabel');
  return { fromLabel, toLabel, formatsLabel, csvLabel, iifLabel, qifLabel, includePaymentsLabel };
};

export const CreateReportFormatWidget = forwardRef<ExportFileFormatProps, 'form'>(
  ({ defaultValues, onSubmit, onSubmissionStateChange, includeMultipleFormats, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { createDate } = useDateUtils();

    const toggleEndDatePickerAriaLabel = formatMessage('widgets.createReportModal.form.endDate.openPicker.ariaLabel');
    const clearEndDateAriaLabel = formatMessage('widgets.createReportModal.form.endDate.clear.ariaLabel');
    const toggleStartDatePickerAriaLabel = formatMessage(
      'widgets.createReportModal.form.startDate.openPicker.ariaLabel'
    );
    const clearStartDateAriaLabel = formatMessage('widgets.createReportModal.form.startDate.clear.ariaLabel');
    const [minToDate, setMinToDate] = useState<Date>(defaultValues?.fromDate ?? createDate());
    const { fromLabel, toLabel, formatsLabel, csvLabel, iifLabel, qifLabel, includePaymentsLabel } = useReportLabels();

    const { formProps, registerField, setValue, watch } = useMelioForm<ExportFileFormWidgetFields>({
      onSubmit,
      schema,
      defaultValues,
      onSubmissionStateChange,
    });

    const onFromChange = (fromDate: Date) => {
      // eslint-disable-next-line no-restricted-syntax
      const toDate = new Date(watch('toDate'));
      // eslint-disable-next-line no-restricted-syntax
      const newMinToDate = new Date(fromDate.getTime() + DAY);
      setMinToDate(newMinToDate);
      if (newMinToDate.getTime() >= toDate.getTime()) {
        setValue('toDate', newMinToDate);
      }
      setValue('fromDate', fromDate);
    };

    return (
      <Group variant="vertical" spacing="l">
        <Text textStyle="body2">{formatMessage('widgets.createReportModal.description')}</Text>
        <Form data-component="CreateFormatForm" {...props} {...formProps} ref={ref}>
          <Group variant="vertical" spacing="l">
            <Form.Checkbox
              {...registerField('includePayments')}
              label={includePaymentsLabel}
              aria-label={includePaymentsLabel}
            />
            <Group variant="vertical" spacing="xs">
              <Text color="global.neutral.900" textStyle="body4">
                {formatMessage('widgets.createReportModal.form.requiredFields')}
              </Text>
              <Group>
                <Form.DateField
                  data-testid="report-form-start-date-picker"
                  labelProps={{ label: fromLabel }}
                  {...registerField('fromDate')}
                  excludeHolidays={false}
                  onChange={(date) => {
                    if (date) {
                      onFromChange(date);
                    }
                  }}
                  weekDays={[0, 1, 2, 3, 4, 5, 6]}
                  clearDateAriaLabel={clearStartDateAriaLabel}
                  toggleDatePickerAriaLabel={toggleStartDatePickerAriaLabel}
                  legendItems={[
                    {
                      label: formatMessage('activities.addBillV2.billForm.calendar.legend.today'),
                      variant: 'today',
                    },
                  ]}
                  hideClear
                />
                <Form.DateField
                  data-testid="report-form-end-date-picker"
                  labelProps={{ label: toLabel }}
                  {...registerField('toDate')}
                  minDate={minToDate}
                  excludeHolidays={false}
                  weekDays={[0, 1, 2, 3, 4, 5, 6]}
                  clearDateAriaLabel={clearEndDateAriaLabel}
                  toggleDatePickerAriaLabel={toggleEndDatePickerAriaLabel}
                  legendItems={[
                    {
                      label: formatMessage('activities.addBillV2.billForm.calendar.legend.today'),
                      variant: 'today',
                    },
                  ]}
                  hideClear
                />
              </Group>
            </Group>
          </Group>
          {includeMultipleFormats && (
            <Form.RadioGroup
              labelProps={{ label: formatsLabel }}
              {...registerField('fileFormatRadioValue')}
              isDisabled={false}
              options={[
                {
                  mainLabelProps: { label: csvLabel },
                  value: ExportFormat.CSV,
                },
                {
                  mainLabelProps: { label: iifLabel },
                  value: ExportFormat.IIF,
                },
                {
                  mainLabelProps: { label: qifLabel },
                  value: ExportFormat.QIF,
                },
              ]}
            />
          )}
        </Form>
      </Group>
    );
  }
);

CreateReportFormatWidget.displayName = 'CreateFormatForm';
