import { Form } from '@melio/penny';
import { Collaborator } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { useFormContext } from '../../../FormContext';

function createArrayFromNumber(number: number): Array<number> {
  return Array.from(Array(number).keys()).map((x) => x + 1);
}

type Props = { approvers: Collaborator[]; isDisabled: boolean };
export const ApprovalActionByRoles = ({ approvers, isDisabled }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { registerField, getValues } = useFormContext();

  const fieldName = `actions.0.configuration.numberOfApprovers` as const;
  const field = registerField(fieldName);

  const options = createArrayFromNumber(Math.min(approvers.length ?? Number.MAX_SAFE_INTEGER, 3)).map(
    (numberOfApprovers) => ({
      label: formatMessage('activities.create-approval-workflows.actions.approval.rolesV2.title', {
        numberOfApprovers,
      }),
      value: String(numberOfApprovers),
      description: formatMessage('activities.create-approval-workflows.actions.approval.rolesV2.description', {
        numberOfApprovers,
      }),
    })
  );

  return (
    <Form.Select
      {...field}
      value={String(getValues(fieldName))}
      aria-label="number of approvers"
      isDisabled={isDisabled}
      data-testid="approval-action-by-roles"
      emptyState={undefined}
      formatSelectedValue={(option) => option.label.toLowerCase()}
      error={
        field.error
          ? { message: formatMessage('activities.create-approval-workflows.actions.approval.errors.required') }
          : undefined
      }
      options={options}
    />
  );
};
