import { forwardRef } from '@melio/platform-utils';
import { compact, noop } from 'lodash';

import { BillDetailsWidget } from '../BillDetails';
import { PaymentRequestDetailsWidget } from '../PaymentRequestDetails';
import { Carousel } from './components/Carousel';
import { DashboardDetailsCarouselWidgetProps } from './DashboardDetailsCarousel.widget.types';

export const DashboardDetailsCarouselWidget = forwardRef<DashboardDetailsCarouselWidgetProps, 'div'>(
  ({ inboxItems, ...rest }, ref) => {
    const elements = inboxItems.map((inboxItem, i) => {
      if (inboxItem.type === 'bill') {
        return (
          <BillDetailsWidget
            bill={inboxItem.payload}
            onSubmit={noop}
            onSubmissionStateChange={noop}
            onInvoiceChange={noop}
            key={`${inboxItem.type}-${inboxItem.payload.id}`}
          />
        );
      }
      if (inboxItem.type === 'paymentRequest') {
        return <PaymentRequestDetailsWidget paymentRequest={inboxItem.payload} key={i} />;
      }

      return null;
    });

    return (
      <Carousel
        {...rest}
        data-component="DashboardDetailsCarouselWidget"
        elements={compact(elements)}
        initialElementIndex={elements.length - 1}
        ref={ref}
      />
    );
  }
);

DashboardDetailsCarouselWidget.displayName = 'DashboardDetailsCarouselWidget';
