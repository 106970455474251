import { useCallback } from 'react';
import { createSearchParams, NavigateOptions, useNavigate } from 'react-router-dom';

type QueryParams = {
  [key: string]: string;
};

type NavigationProps = {
  newPath: string;
  newUrlQueryParam?: QueryParams;
  options?: NavigateOptions;
};

export const useNavigationWithQueryParams = () => {
  const navigate = useNavigate();

  const handleNavigationWithQueryParams = useCallback(
    ({ newPath, newUrlQueryParam = {}, options }: NavigationProps) => {
      const searchParams = new URLSearchParams(window.location.search);
      const existingParams = Object.fromEntries(searchParams);
      const updatedSearchParams = createSearchParams({ ...existingParams, ...newUrlQueryParam });
      navigate(`${newPath}?${updatedSearchParams.toString()}`, { ...options });
    },
    [navigate]
  );

  return { handleNavigationWithQueryParams };
};
