import { Route, Routes, useSearchParams } from 'react-router-dom';

import { ExternalEntriesFallbackError } from '@/screens/external-entries/ExternalEntriesFallbackError';
import { ExternalEntryBatchPaymentsScreen } from '@/screens/external-entries/ExternalEntryBatchPayments.screen';
import { ExternalEntrySchedulePaymentScreen } from '@/screens/external-entries/ExternalEntrySchedulePayment.screen';
import { getSessionStorageItem, setSessionStorageItem } from '@/utils/sessionStorage.utils';

export const ExternalEntriesRoute = () => {
  const [searchParams] = useSearchParams();
  const externalOrigin = searchParams.get('externalOrigin') ?? '';
  const currentExternalOrigin = getSessionStorageItem('externalOrigin');
  if (!currentExternalOrigin) {
    setSessionStorageItem('externalOrigin', externalOrigin);
  }

  const billIds = searchParams.get('billIds');
  const billIdsArray = billIds ? billIds.split(',') : [];

  return (
    <Routes>
      <Route path="/single" element={<ExternalEntrySchedulePaymentScreen billOriginId={billIdsArray[0]} />} />
      <Route path="/batch" element={<ExternalEntryBatchPaymentsScreen billOriginIds={billIdsArray} />} />
      <Route path="/fallback-error" element={<ExternalEntriesFallbackError />} />
    </Routes>
  );
};
