import { useUserPreference } from '@melio/platform-api';

import { PayDashboardTabs } from '../../types';
import { PAY_DASHBOARD_TOUR_PREFERENCE_KEY } from './consts';
import { DashboardTourSteps } from './pay-dashboard-tabs-tours-steps/types';
import { useBillsTabTourSteps } from './pay-dashboard-tabs-tours-steps/useBillsTabTourSteps';
import { useVendorsTabTourSteps } from './pay-dashboard-tabs-tours-steps/useVendorsTabTourSteps';

export const useDashboardTourSteps = ({ enabled: _enabled, tab }: { enabled: boolean; tab: PayDashboardTabs }) => {
  const { data: userPreference, isFetching: isFetchingUserPreference } = useUserPreference({
    id: PAY_DASHBOARD_TOUR_PREFERENCE_KEY,
    enabled: _enabled,
  });

  const enabled = _enabled && userPreference?.value === 'true';

  const tabTourStepsMap: Partial<Record<PayDashboardTabs, DashboardTourSteps>> = {
    [PayDashboardTabs.Vendors]: useVendorsTabTourSteps({ enabled: enabled && tab === PayDashboardTabs.Vendors }),
    [PayDashboardTabs.Bills]: useBillsTabTourSteps({
      enabled: enabled && tab === PayDashboardTabs.Bills,
    }),
  };

  const { steps, isLoading } = tabTourStepsMap[tab] ?? {};

  return {
    steps,
    isLoading: isFetchingUserPreference || !!isLoading,
  };
};
