import React from 'react';
import { useDisclosure as useDisclosureChakra } from '@chakra-ui/react';

export const useDisclosure = () => {
  const { isOpen, onOpen: onOpenChakra, onClose: onCloseChakra } = useDisclosureChakra();

  const isMounted = React.useRef(true);

  React.useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const onOpen = () => {
    isMounted.current && onOpenChakra();
  };

  const onClose = () => {
    isMounted.current && onCloseChakra();
  };

  return {
    isOpen,
    onOpen,
    onClose,
  };
};
