import { usePaymentSchedulingPreference } from '@melio/ap-domain';
import { BaseMenu, CalendarLegendProps, DatePicker, IconButton, useBreakpoint } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useState } from 'react';

export const ButtonDatePicker = ({
  selectedDeliveryDate,
  minDate,
  isDisabled,
  onDateChange,
}: {
  selectedDeliveryDate: Date;
  minDate: Date;
  isDisabled: boolean;
  onDateChange: (date: Date) => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { formatMessage } = useMelioIntl();
  const { isExtraSmallScreen: isMobile } = useBreakpoint();
  const { maxDeliveryDate } = usePaymentSchedulingPreference();

  const triggerComponent = isMobile ? (
    <IconButton
      size="large"
      icon="calendar"
      aria-label="open-calendar"
      data-testid="delivery-date-edit-date-picker-icon"
    />
  ) : (
    <BaseMenu.ActionTrigger
      size="small"
      icon="calendar"
      label={formatMessage('activities.deliveryDate.screens.deliveryDate.editDate')}
      aria-label="open-calendar"
      data-testid="delivery-date-edit-date-picker-button"
    />
  );

  const legendItems = [
    {
      label: formatMessage(`activities.deliveryDate.screens.deliveryDate.legend.deliveryDate`),
      variant: 'primary',
    },
  ] as CalendarLegendProps[];

  return (
    <DatePicker
      legendItems={legendItems}
      trigger={triggerComponent}
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      size="fit-content"
      isDisabled={isDisabled}
      onSelect={onDateChange}
      value={selectedDeliveryDate}
      minDate={minDate}
      maxDate={maxDeliveryDate}
      weekDays={[1, 2, 3, 4, 5]}
      excludeHolidays
    />
  );
};
