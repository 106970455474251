import { getCentsFromDollars } from '@melio/platform-utils';
import { format } from 'date-fns';

import { PostPaymentCalendarRequest, PostPaymentCalendarRequestBase } from './types';

export const preparePaymentCalendarRequest = ({
  startDate,
  endDate,
  currency,
  amount,
  ...rest
}: PostPaymentCalendarRequest): PostPaymentCalendarRequestBase => ({
  startDate: format(startDate, 'yyyy-MM-dd'),
  endDate: format(endDate, 'yyyy-MM-dd'),
  currency,
  ...(amount && { amount: getCentsFromDollars(amount, currency) }),
  ...rest,
});
