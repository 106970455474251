import { useDateUtils } from '@melio/platform-utils';
import { addDays, differenceInCalendarDays } from 'date-fns';

const DAYS_REMINDER_BEFORE_CHARGING_DAY = 7;

export const useFreeTrialPeriod = () => {
  const { createDate } = useDateUtils();
  const today = createDate();

  const getReminderDateBeforeCharging = (startChargingDate: Date) =>
    addDays(startChargingDate, DAYS_REMINDER_BEFORE_CHARGING_DAY * -1);

  const getIsReminderDatePassed = (startChargingDate: Date) =>
    getRemainingDaysForFreeTrial(startChargingDate) <= DAYS_REMINDER_BEFORE_CHARGING_DAY;

  const getRemainingDaysForFreeTrial = (startChargingDate: Date) => differenceInCalendarDays(startChargingDate, today);

  return {
    daysReminderBeforeChargingDate: DAYS_REMINDER_BEFORE_CHARGING_DAY,
    getReminderDateBeforeCharging,
    getIsReminderDatePassed,
    getRemainingDaysForFreeTrial,
  };
};
