import { useCheckFeePromotions, useDeliveryMethodFee } from '@melio/ap-widgets';
import { useMelioIntl } from '@melio/platform-i18n';

export const usePaperCheckDescription = (): { description: string; isLoading: boolean } => {
  const { formatMessage } = useMelioIntl();
  const { getFee } = useDeliveryMethodFee('bank-account');
  const { freeChecks: freeChecksData, isLoading } = useCheckFeePromotions();

  const fee = getFee('paper-check');

  if (!fee) {
    return { description: formatMessage('app.noFee.label'), isLoading: false };
  }

  if (isLoading) {
    return { description: '', isLoading: true };
  }

  const freeChecksPromotionEnabled = !!freeChecksData?.total;
  const freeChecksPromotionMessage = freeChecksPromotionEnabled ? 'withChecksPromotion' : 'noChecksPromotion';
  const description = formatMessage(`widgets.addOrUpdateDeliveryMethod.check.feeText.${freeChecksPromotionMessage}`, {
    achToCheckFee: fee,
    monthlyFreeChecksPromotion: freeChecksData?.total,
  });

  return {
    description,
    isLoading,
  };
};
