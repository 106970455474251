import { Group } from '@melio/penny';
import { BillingFeeSetting, FundingSource } from '@melio/platform-api';
import { forwardRef } from '@melio/platform-utils';

import { PaymentMethodWithActions } from '@/widgets/settings-page/PaymentMethodsSettingsPage/PaymentMethodWithActions.widget';

export type FundingSourceCardListProps = {
  fundingSources: FundingSource[];
  orgBillingFeeSettings: BillingFeeSetting[];
  userId?: string;
};

export const FundingSourceCardList = forwardRef<FundingSourceCardListProps, 'div'>(
  ({ fundingSources, orgBillingFeeSettings, userId, ...rest }, ref) => {
    return (
      <Group
        variant="vertical"
        data-component="FundingSourceCardList"
        data-testid="funding-source-card-list"
        ref={ref}
        {...rest}
      >
        {fundingSources.map((fs) => (
          <PaymentMethodWithActions
            orgBillingFeeSettings={orgBillingFeeSettings}
            fundingSource={fs}
            key={fs.id}
            showAddedBy={fs.createdById !== userId}
          />
        ))}
      </Group>
    );
  },
);
FundingSourceCardList.displayName = 'FundingSourceCardList';
