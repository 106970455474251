import { Logger } from '@melio/platform-logger';
import { useCallback } from 'react';

import { Tier } from '../api';
import { useSubscriptionContext } from '../context';
import { usePlansTiers } from './usePlansTiers';
import { useSubscription } from './useSubscription';

export const useSubscriptionPlan = () => {
  const { getPlanTier } = usePlansTiers();

  const { plans } = useSubscriptionContext();

  const subscription = useSubscription();

  const isUpgradedPlan = useCallback(
    (planId: string) => subscription && getPlanTier(planId) > getPlanTier(subscription.planId),
    [subscription, getPlanTier]
  );
  const isDowngradedPlan = useCallback(
    (planId: string) => subscription && getPlanTier(planId) < getPlanTier(subscription.planId),
    [subscription, getPlanTier]
  );

  const currentPlan = subscription ? plans.find((plan) => plan.id === subscription.planId) : null;

  const getIsCurrentPlan = useCallback(
    (planId: string) => !!subscription && planId === subscription.planId,
    [subscription]
  );

  if (subscription && !currentPlan) {
    const error = `currentPlan not found. planId: ${subscription.planId}`;
    Logger.log(error, 'error');
    throw new Error(error);
  }

  return {
    getIsCurrentPlan,
    currentPlan,
    isUpgradedPlan,
    isDowngradedPlan,
    isVip: currentPlan?.tier === Tier.MelioVip,
  };
};
