/* eslint-disable react-hooks/exhaustive-deps */
import {
  CountryInternationalDeliveryDetails,
  CreateInternationalAccountDeliveryMethod,
  InternationalAccountDeliveryMethod,
  OrganizationBeneficialOwner,
  useDeliveryMethods,
} from '@melio/platform-api';
import { merge } from 'lodash';
import { useCallback, useRef, useState } from 'react';

import { SubmissionTargetType } from '../../delivery-methods/AddVendorBankDetails/types';
import { InternationalBankDetailsFormFields } from './steps/international-bank-details/screens';
import { InternationalVendorDetailsFormReturnedValue } from './steps/international-vendor-details/screens/InternationalVendorDetails/widgets/InternationalVendorDetailsForm';
import { Account, PartialInternationalAccountBicSwift, PartialInternationalAccountIban } from './types';
import { useAddInternationalDeliveryMethodStep } from './useAddInternationalDeliveryMethodStep';
import { getAddInternationalDeliveryMethodDefaultValues } from './utils';

export const useAddInternationalDeliveryMethodHandlers = ({
  vendorId,
  onDone,
  onFirstStepBack,
  isMissingLegalDetails,
  isMissingOrganizationBeneficialOwnersDetails,
  organizationBeneficialOwners,
}: {
  vendorId: string;
  onDone: (data: InternationalAccountDeliveryMethod, target?: SubmissionTargetType) => void;
  onFirstStepBack: VoidFunction;
  isMissingLegalDetails: boolean;
  isMissingOrganizationBeneficialOwnersDetails: boolean;
  organizationBeneficialOwners?: OrganizationBeneficialOwner[];
}) => {
  const { create: createDeliveryMethod } = useDeliveryMethods({
    vendorId,
    enabled: false,
  });
  const [identifierType, setIdentifierType] = useState<'iban' | 'bicSwift' | null>(null);
  const defaultCreateData: PartialInternationalAccountIban | PartialInternationalAccountBicSwift = {
    currency: 'USD',
  };
  const [ibanCreateData, setIbanCreateData] = useState<PartialInternationalAccountIban>(
    defaultCreateData as PartialInternationalAccountIban
  );
  const [bicSwiftCreateData, setBicSwiftCreateData] = useState<PartialInternationalAccountBicSwift>(
    defaultCreateData as PartialInternationalAccountBicSwift
  );
  const [defaultCountryDetails, setDefaultCountryDetails] = useState<CountryInternationalDeliveryDetails | undefined>(
    undefined
  );
  const [vendorCountryName, setVendorCountryName] = useState<string | undefined>();

  const doneTarget = useRef<SubmissionTargetType | undefined>(undefined);
  const [shouldShowFailedCreationBanner, setShouldShowFailedCreationBanner] = useState<boolean>(false);

  const handleDone = useCallback(async () => {
    try {
      const deliveryMethod = await createDeliveryMethod({
        type: 'international-account',
        ...(identifierType === 'iban' ? { details: ibanCreateData } : { details: bicSwiftCreateData }),
      } as CreateInternationalAccountDeliveryMethod);

      onDone(deliveryMethod as InternationalAccountDeliveryMethod, doneTarget.current);
    } catch (error) {
      if ((error as PlatformError)?.code === '400') {
        setShouldShowFailedCreationBanner(true);
        goToInitialStep();
      }
    }
  }, [createDeliveryMethod, identifierType, ibanCreateData, bicSwiftCreateData, onDone]);

  const {
    currentStep,
    goToInitialStep,
    goToPreviousStep,
    handleBankDetailsNextStep,
    handleVendorDetailsNextStep,
    handleBusinessInfoNextStep,
    handleOwnersNextStep,
  } = useAddInternationalDeliveryMethodStep({
    onFirstStepBack,
    isMissingLegalDetails,
    isMissingOrganizationBeneficialOwnersDetails,
    organizationBeneficialOwners,
    handleDone,
  });

  const { vendorDetailsDefaultValues, bankDetailsDefaultValues } = getAddInternationalDeliveryMethodDefaultValues({
    deliveryMethodCreateData: identifierType === 'iban' ? ibanCreateData : bicSwiftCreateData,
    vendorCountryName,
    defaultCountryDetails,
  });

  const updateCreateData = (data: PartialInternationalAccountIban | PartialInternationalAccountBicSwift) => {
    if (identifierType === 'iban') {
      setIbanCreateData((prev) => merge(prev, data));
    } else {
      setBicSwiftCreateData((prev) => merge(prev, data));
    }
  };

  const onBankDetailsDone = ({
    ibanCode: iban,
    swiftCode: bicSwift,
    accountNumber,
    bankName,
    selectedInternationalCountries: { code: bankCountryCode },
  }: InternationalBankDetailsFormFields) => {
    if (iban) {
      setIdentifierType('iban');
      updateCreateData({
        identifierType: 'iban',
        identifierDetails: {
          bankName,
          iban,
          bankCountryCode,
        },
      });
    } else {
      setIdentifierType('bicSwift');
      updateCreateData({
        identifierType: 'bicSwift',
        identifierDetails: {
          bankName,
          bicSwift,
          accountNumber,
          bankCountryCode,
        },
      });
    }
    handleBankDetailsNextStep();
  };

  const onVendorDetailsDone = useCallback(
    (
      {
        legalBusinessName: companyName,
        fullName: bankAccountHolderName,
        line1,
        city,
        state,
        country,
        countryCode,
        postalCode,
      }: InternationalVendorDetailsFormReturnedValue,
      target?: SubmissionTargetType
    ) => {
      if (identifierType === 'iban') {
        updateCreateData({
          companyName,
          address: { line1, city, state, postalCode, countryCode },
          identifierDetails: {
            bankAccountHolderName,
          },
        });
      } else {
        updateCreateData({
          companyName,
          address: { line1, city, state, postalCode, countryCode },
          identifierDetails: {
            bankAccountHolderName,
          },
        });
      }
      setVendorCountryName(country);
      doneTarget.current = target;
      handleVendorDetailsNextStep();
    },
    [handleVendorDetailsNextStep, updateCreateData, identifierType, setVendorCountryName]
  );

  const onBusinessInfoDone = useCallback(
    (account: Account, target?: SubmissionTargetType) => {
      doneTarget.current = target;
      handleBusinessInfoNextStep(account);
    },
    [handleBusinessInfoNextStep]
  );

  const onOwnersDone = useCallback(
    (_owners: OrganizationBeneficialOwner[], target?: SubmissionTargetType) => {
      doneTarget.current = target;
      handleOwnersNextStep();
    },
    [handleOwnersNextStep]
  );

  return {
    currentStep,
    goToPreviousStep,
    onBankDetailsDone,
    onVendorDetailsDone,
    onBusinessInfoDone,
    onOwnersDone,
    vendorDetailsDefaultValues,
    bankDetailsDefaultValues,
    setDefaultCountryDetails,
    shouldShowFailedCreationBanner,
    setShouldShowFailedCreationBanner,
  };
};
