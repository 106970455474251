import { Card, Divider, Group } from '@melio/penny';

import { CurrentPlanExtendedDetails } from './CurrentPlanExtendedDetails';
import { FreePlanCardHeaderFiserv } from './FreePlanCardHeaderFiserv';

export const FreePlanCardFiserv = () => (
  <Card variant="flat" paddingX="m" paddingY="m" data-testid="subscription-basic-plan">
    <Group width="full" variant="vertical" spacing="m" alignItems="flex-start">
      <FreePlanCardHeaderFiserv />
      <Divider />
      <CurrentPlanExtendedDetails />
    </Group>
  </Card>
);
