import { isDeliveryPreferenceTypeFast } from '@melio/ap-widgets';
import { DeliveryPreference } from '@melio/platform-api';

const buildEarliestDeliveryPreference = (deliveryPreference: DeliveryPreference): DeliveryPreference => ({
  ...deliveryPreference,
  effectiveScheduleDate: deliveryPreference.minScheduleDate,
  minDeliveryDate: deliveryPreference.earliestDeliveryDate,
});

export const buildDeliveryPreferencesWithEarliestFastDates = (
  deliveryPreferences: DeliveryPreference[]
): DeliveryPreference[] =>
  deliveryPreferences.map((deliveryPreference) => {
    const isFast = isDeliveryPreferenceTypeFast(deliveryPreference.type);
    return isFast ? buildEarliestDeliveryPreference(deliveryPreference) : deliveryPreference;
  });
