import { Link, SectionBanner } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

type SubscriptionPaidByFirmSectionBannerProps = {
  switchToFirmOrganizationPlansSettings: (organizationId: string) => void;
  planName: string;
  isExternalAccountant: boolean;
  firmOrganizationId?: string;
};

export const SubscriptionPaidByFirmSectionBanner = ({
  switchToFirmOrganizationPlansSettings,
  isExternalAccountant,
  firmOrganizationId,
  planName,
}: SubscriptionPaidByFirmSectionBannerProps) => {
  const { formatMessage } = useMelioIntl();

  const title = formatMessage(
    isExternalAccountant
      ? 'widgets.subscriptionPlanSettings.paidByFirmSectionBanner.firmView.title'
      : 'widgets.subscriptionPlanSettings.paidByFirmSectionBanner.clientView.title'
  );

  const description =
    isExternalAccountant && firmOrganizationId
      ? formatMessage('widgets.subscriptionPlanSettings.paidByFirmSectionBanner.firmView.description', {
          goToFirmSettingsLink: (
            <Link
              href="#"
              data-testid="section-banner-go-to-firm-settings"
              label={formatMessage(
                'widgets.subscriptionPlanSettings.paidByFirmSectionBanner.firmView.description.goToFirmSettingsLink'
              )}
              onClick={(e) => {
                e.preventDefault();
                switchToFirmOrganizationPlansSettings(firmOrganizationId);
              }}
            />
          ),
        })
      : formatMessage('widgets.subscriptionPlanSettings.paidByFirmSectionBanner.clientView.description', {
          currentPlan: planName,
        });

  return (
    <SectionBanner
      data-testid={`${isExternalAccountant ? 'firm' : 'client'}-view-paid-by-firm-section-banner`}
      variant="informative"
      title={title}
      description={description}
    />
  );
};
