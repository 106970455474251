import { useMelioIntl } from '@melio/platform-i18n';
import { filter } from 'lodash';
import { useCallback } from 'react';

import { Plan, Tier } from '../api';
import { getIsFeatureInPlan } from '../utils/planFeatures.utils';

export type PlanCapabilities = {
  title: string;
  list: Array<string>;
};

const RAW_CAPABILITIES_PER_TIER = {
  [Tier.MelioBasic]: [
    'freeAchPayments',
    'basicAccountingSync',
    'manageBills',
    'dashboard',
    'pmDmMatching',
    'fx',
    'recurringAndPartial',
    'mobile',
    'ar',
  ],
  [Tier.MelioPlus]: [
    'freeAchPayments',
    'syncWithAccountingSoftware',
    'batchPayments',
    'combinedPayment',
    'advancedUserRoles',
    'approvalFlows',
    'vendorStorage',
    'lineItems',
    'premiumSupport',
  ],
  [Tier.MelioPro]: [
    'freeAchPayments',
    'twoDayDelivery',
    'advancedApprovalWorkflows',
    'qbdtSync',
    'advancedLineItems',
    'phoneSupport',
  ],
  [Tier.MelioVip]: [],
  [Tier.FiservApBasic]: [
    'payAnyBillerOrVendor',
    'payByBankTransferOrCard',
    'deliverPaymentsElectronicallyOrByCheck',
    'batchPayments',
    'numberOfFreeMembers',
  ],
  [Tier.FiservApPro]: ['allAPBasicCapabilities', 'syncWithAccountingSoftware', 'numberOfFreeMembers'],
  [Tier.FiservApArBasic]: [
    'allAPBasicCapabilities',
    'getPaidByAchAndCard',
    'createAndTrackInvoices',
    'numberOfFreeMembers',
  ],
  [Tier.FiservApArPro]: [
    'allAPBasicCapabilities',
    'getPaidByAchAndCard',
    'createAndTrackInvoices',
    'numberOfFreeMembers',
  ],
  [Tier.MelioAccountingClientCore]: [
    'freeAchPayments',
    'syncWithAccountingSoftware',
    'batchPayments',
    'combinedPayment',
    'advancedUserRoles',
    'approvalFlows',
    'vendorStorage',
    'lineItems',
    'premiumSupport',
  ],
  [Tier.MelioAccountingClientBoost]: [
    'freeAchPayments',
    'twoDayDelivery',
    'advancedApprovalWorkflows',
    'qbdtSync',
    'advancedLineItems',
    'phoneSupport',
  ],
} as const;

export const usePlanCapabilities = () => {
  const { formatMessage } = useMelioIntl();

  const getPlanCapabilities = useCallback(
    (plan: Plan) => {
      const hasQuickbooks = getIsFeatureInPlan(plan, { featureName: 'accountingPlatformQbo' }).isEligible;
      const hasXero = getIsFeatureInPlan(plan, { featureName: 'accountingPlatformXero' }).isEligible;
      const hasBatchPayments = getIsFeatureInPlan(plan, { featureName: 'batchPayments' }).isEligible;
      const freeAchPaymentsLimit = getIsFeatureInPlan(plan, {
        featureName: 'freeAchPayments',
        requirements: { totalUnits: 0 },
      }).quota.freeUnitsLimit;

      const getHasCapability = (capability: string) => {
        switch (capability) {
          case 'syncWithAccountingSoftware': {
            return hasQuickbooks && hasXero;
          }
          case 'batchPayments': {
            return hasBatchPayments;
          }
        }

        return true;
      };

      const getMessageParams = (capability: string) => {
        switch (capability) {
          case 'numberOfFreeMembers': {
            return { userSeats: plan.numberOfFreeUsers };
          }
          case 'freeAchPayments': {
            return { freeAchPaymentsLimit };
          }
        }

        return;
      };

      return filter(RAW_CAPABILITIES_PER_TIER[plan.tier], getHasCapability).map((item) =>
        formatMessage(`activities.subscription.plans.capabilities.${item}`, getMessageParams(item))
      );
    },
    [formatMessage]
  );

  return { getPlanCapabilities };
};
