import { usePlatformIntl } from '@/translations/Intl';

export const useManagedAchMessages = () => {
  const { formatMessage } = usePlatformIntl();
  const displayName = formatMessage('widgets.addOrUpdateDeliveryMethod.managedAch.title');
  const helperText = formatMessage('widgets.addOrUpdateDeliveryMethod.managedAch.feeText');
  const additionalInfo = formatMessage('widgets.addOrUpdateDeliveryMethod.managedAch.info.additionalInfo');

  return { displayName, helperText, additionalInfo };
};
