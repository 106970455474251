import { getSecondDueDateForTwiceAMonth } from '@melio/ap-domain';
import { Grid, Group, ListItem, Typography } from '@melio/penny';
import { BillSubscriptionIntervalTypeEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';
import { DaysInOrder } from '@melio/platform-utils';
import { isSameDay } from 'date-fns';

import { RecurringPaymentProps } from '../../types';

export type ReviewAndConfirmRecurringPaymentSectionProps = {
  recurringPayment: RecurringPaymentProps;
};

const useRecurringPaymentFrequencyLabel = (paymentFrequency: BillSubscriptionIntervalTypeEnum, startDate: Date) => {
  const { formatMessage, formatDate } = useMelioIntl();

  const messageId = `widgets.reviewAndConfirm.recurringPaymentSection.frequencyField.${paymentFrequency}` as const;
  if (
    paymentFrequency === BillSubscriptionIntervalTypeEnum.Weekly ||
    paymentFrequency === BillSubscriptionIntervalTypeEnum.Every2Weeks ||
    paymentFrequency === BillSubscriptionIntervalTypeEnum.Every4Weeks
  ) {
    return formatMessage(messageId, {
      weekDay: DaysInOrder[startDate.getDay()],
    });
  }

  if (
    paymentFrequency === BillSubscriptionIntervalTypeEnum.Monthly ||
    paymentFrequency === BillSubscriptionIntervalTypeEnum.Every2Months ||
    paymentFrequency === BillSubscriptionIntervalTypeEnum.Every3Months ||
    paymentFrequency === BillSubscriptionIntervalTypeEnum.Every4Months ||
    paymentFrequency === BillSubscriptionIntervalTypeEnum.Every6Months
  ) {
    return formatMessage(messageId, {
      day: startDate.getDate(),
    });
  }

  if (paymentFrequency === BillSubscriptionIntervalTypeEnum.TwiceAMonth) {
    const day = startDate.getDate();
    const secondDay = getSecondDueDateForTwiceAMonth(startDate).getDate();
    const firstDayToDisplay = Math.min(day, secondDay);
    const secondDayToDisplay = Math.max(day, secondDay);

    return formatMessage(messageId, {
      day: firstDayToDisplay,
      secondDay: secondDayToDisplay,
    });
  }

  if (paymentFrequency === BillSubscriptionIntervalTypeEnum.Yearly) {
    return formatMessage(messageId, {
      day: startDate.getDate(),
      month: formatDate(startDate, {
        month: 'short',
      }),
    });
  }
  return '';
};

export const ReviewAndConfirmRecurringPaymentSection = ({
  recurringPayment,
  ...props
}: ReviewAndConfirmRecurringPaymentSectionProps) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const { paymentFrequency, numOfOccurrences, startDate, endDate, lastPaymentDeliveryDate } = recurringPayment;

  const frequencyFieldMainLabel = useRecurringPaymentFrequencyLabel(paymentFrequency, startDate);
  const endDateFieldDescription =
    endDate && lastPaymentDeliveryDate && !isSameDay(endDate, lastPaymentDeliveryDate)
      ? {
          descriptionProps: {
            label: formatMessage('widgets.reviewAndConfirm.recurringPaymentSection.endDateField.finalPayment', {
              date: formatDate(lastPaymentDeliveryDate, {
                dateStyle: 'medium',
              }),
            }),
          },
        }
      : {};

  return (
    <Group variant="vertical" spacing="s" data-component="ReviewAndConfirmRecurringPaymentSection" {...props}>
      <Typography.SectionLabel label={formatMessage('widgets.reviewAndConfirm.recurringPaymentSection.title')} />
      <Grid templateColumns="repeat(2, 1fr)" gap="s" data-testid="ReviewAndConfirmRecurringPaymentSectionGrid">
        <ListItem
          data-testid="payment-review-payment-frequency"
          labelProps={{
            label: formatMessage('widgets.reviewAndConfirm.recurringPaymentSection.frequencyField'),
          }}
          mainLabelProps={{
            label: frequencyFieldMainLabel,
          }}
        />
        <ListItem
          data-testid="payment-review-num-of-occurrences"
          labelProps={{
            label: numOfOccurrences
              ? formatMessage('widgets.reviewAndConfirm.recurringPaymentSection.numOfOccurrencesField')
              : formatMessage('widgets.reviewAndConfirm.recurringPaymentSection.numOfOccurrencesField.noEndDate.label'),
          }}
          mainLabelProps={{
            label: numOfOccurrences
              ? numOfOccurrences.toString()
              : formatMessage(
                  'widgets.reviewAndConfirm.recurringPaymentSection.numOfOccurrencesField.noEndDate.content'
                ),
          }}
        />
        <ListItem
          labelProps={{
            label: formatMessage('widgets.reviewAndConfirm.recurringPaymentSection.startDateField'),
          }}
          mainLabelProps={{ label: formatDate(startDate, { dateStyle: 'medium' }) }}
        />
        <ListItem
          data-testid="payment-review-end-date"
          labelProps={{
            label: formatMessage('widgets.reviewAndConfirm.recurringPaymentSection.endDateField'),
          }}
          mainLabelProps={{
            label: numOfOccurrences
              ? formatDate(endDate || lastPaymentDeliveryDate, {
                  dateStyle: 'medium',
                })
              : formatMessage('widgets.reviewAndConfirm.recurringPaymentSection.endDateField.noEndDate'),
          }}
          {...endDateFieldDescription}
        />
      </Grid>
    </Group>
  );
};

ReviewAndConfirmRecurringPaymentSection.displayName = 'ReviewAndConfirmRecurringPaymentSection';
