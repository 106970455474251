import React from 'react';
import { SyncFlowEnum } from '@melio/ap-activities';

import { useIntuitConnect } from '@/hooks/accountingPlatforms.hooks';
import { useRouter } from '@/hooks/router.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { ErrorPage } from '@/widgets/error-page/ErrorPage.widget';

type AccountingPlatformErrorMessageProps = {
  withTryAgain?: boolean;
  errorTitle: string;
  errorMessage: string;
  flowToReturn?: SyncFlowEnum;
  accountingPlatformId?: string;
  onTryAgain?: () => void;
};

export const AccountingPlatformErrorMessage = ({
  errorTitle,
  withTryAgain,
  errorMessage,
  flowToReturn,
  accountingPlatformId,
  onTryAgain,
}: AccountingPlatformErrorMessageProps) => {
  const { formatMessage } = usePlatformIntl();
  const { goToAccountingPlatform, goToPayDashboard } = useRouter();
  const { isLoading, loginToQuickBooks } = useIntuitConnect(
    flowToReturn || SyncFlowEnum.PayBoard,
    accountingPlatformId,
  );
  const messageIdPrefix = 'widgets.accountingPlatform.errorMessage';

  const onCancelClicked = React.useCallback(() => {
    if (flowToReturn === SyncFlowEnum.NewBill || flowToReturn === SyncFlowEnum.PayBoard) {
      goToPayDashboard();
    } else {
      goToAccountingPlatform({});
    }
  }, [flowToReturn, goToPayDashboard, goToAccountingPlatform]);

  return (
    <ErrorPage
      title={errorTitle}
      message={errorMessage}
      dividerText={formatMessage(`${messageIdPrefix}.divider.or`)}
      primaryButton={
        withTryAgain
          ? {
              text: formatMessage(`${messageIdPrefix}.button.sync`),
              onClick: onTryAgain || loginToQuickBooks,
              isLoading,
            }
          : undefined
      }
      secondaryButton={{
        text: formatMessage(`${messageIdPrefix}.button.cancel`),
        onClick: onCancelClicked,
      }}
    />
  );
};
