import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { Payment } from '@melio/platform-api';
import { addBusinessDays, useDateUtils } from '@melio/platform-utils';

import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';
import { VoidReviewAndConfirmScreen } from '../screens/VoidReviewAndConfirm/VoidReviewAndConfirm.screen';

type Props = {
  payment: Payment;
  isLoading: boolean;
  isLoadingButton: boolean;
  cancelFlow: () => void;
  onConfirmRefund: () => void;
};

export const VoidReviewAndConfirmStep = ({
  payment,
  isLoading,
  isLoadingButton,
  cancelFlow,
  onConfirmRefund,
}: Props) => {
  const { amount, fundingSource, vendor } = payment;
  const { createTrackHandler } = useAnalytics();
  const trackAndHandleClick = createTrackHandler<{
    Cta: 'exit' | 'confirm-void-and-refund';
  }>('Payment', 'Click');

  useAnalyticsView('Payment');

  const onCloseClick = () => {
    trackAndHandleClick({ Cta: 'exit' }, cancelFlow);
  };

  const onSubmitClick = () => {
    trackAndHandleClick({ Cta: 'confirm-void-and-refund' }, onConfirmRefund);
  };

  const { createDate } = useDateUtils();
  return fundingSource ? (
    <VoidReviewAndConfirmScreen
      amount={amount}
      vendorId={payment.vendorId}
      isLoading={isLoading}
      tracingNumber={payment.tracingNumber}
      fundingSource={fundingSource}
      deliveryMethod={payment.deliveryMethod}
      isLoadingButton={isLoadingButton}
      organizationName={vendor?.name || ''}
      maxEstimatedRefundDate={addBusinessDays(createDate(), 5)}
      onClose={onCloseClick}
      onSubmit={onSubmitClick}
    />
  ) : (
    <NewSinglePaymentStepLayout isLoading={isLoading} />
  );
};
