import React from 'react';

import { useActiveScreen } from '@/hooks/useActiveScreen';
import { PaymentsClassificationScreen } from '@/screens/PaymentClassification.screen';
import { ScreensEnum } from '@/store/app/app.types';

export const PaymentsClassificationRoute = () => {
  useActiveScreen(ScreensEnum.paymentsClassification);
  return <PaymentsClassificationScreen />;
};
