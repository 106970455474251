import { useUpdateCollaborator } from '@melio/platform-api';
import { usePermissions } from '@melio/platform-permissions';
import { ComponentProps, useState } from 'react';

import { useEditCollaboratorAnalytics } from './hooks/analytics/useEditCollaboratorAnalytics';
import { useAsyncActionWithToast } from './hooks/useAsyncActionWithToast';
import { EditCollaboratorModalScreen } from './screens/modals/InviteOrEdit/EditCollaborator.modal-screen';
import type { Collaborator, RoleUniqueNames } from './types';

export const useEditCollaboratorModal = () => {
  const [state, setModalState] = useState<{
    isOpen: boolean;
    collaborator: Collaborator | null;
    availableRoleOptions: RoleUniqueNames[];
  }>({
    isOpen: false,
    collaborator: null,
    availableRoleOptions: [],
  });
  const { can, roles } = usePermissions();
  const { update: updateCollaborator, isUpdating } = useUpdateCollaborator({
    id: state.collaborator?.id,
    enabled: !!state.collaborator?.id,
  });

  const analytics = useEditCollaboratorAnalytics();

  const openModal = (collaborator: Collaborator) =>
    setModalState({
      isOpen: true,
      collaborator,
      availableRoleOptions: roles.filter((roleUniqueName) =>
        can({
          subject: 'collaborator',
          action: 'update',
          subjectData: { roleUniqueName },
        })
      ),
    });
  const closeModal = (reason?: 'exit') => {
    setModalState({ isOpen: false, collaborator: null, availableRoleOptions: [] });
    analytics.close(reason);
  };

  const actionWithToast = useAsyncActionWithToast({
    successLabel: 'activities.manageCollaborators.messages.collaborator.update.success',
    errorLabel: 'activities.manageCollaborators.messages.collaborator.update.error',
    onSuccess: () => closeModal(),
  });

  const submit: ComponentProps<typeof EditCollaboratorModalScreen>['onDone'] = async (data) => {
    await actionWithToast(() =>
      updateCollaborator({
        roleUniqueName: data.roleUniqueName,
      })
    );
    analytics.submit({ collaboratorNewRoleUniqueName: data.roleUniqueName });
  };

  return {
    openModal,
    closeModal,
    state,
    submit,
    isUpdating,
  };
};
