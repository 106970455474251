// eslint-disable-next-line no-restricted-imports
import { MutationFunction, useMutation } from 'react-query';

import { UseCollectionApiResult, useMelioQueryClient } from '.';
import { ApiError } from './types';

export function useBatchUpdateMutation<TData extends { id: string }, TUpdateResult, UBatchData, UBatchParams = unknown>(
  mutationFn: MutationFunction<TUpdateResult, { data: UBatchData; params?: UBatchParams }>,
  { queryKey }: UseCollectionApiResult<TData>
) {
  const queryClient = useMelioQueryClient();

  const onSuccess = () => queryClient.invalidateQueries(queryKey[0]);

  const mutation = useMutation<TUpdateResult, ApiError, { data: UBatchData; params?: UBatchParams }>(
    queryKey,
    mutationFn,
    {
      onSuccess,
    }
  );

  return (data: UBatchData, params?: UBatchParams) => mutation.mutateAsync({ data, params });
}
