import { AccountsApiClient } from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useMutation, useQueryClient } from 'react-query';

export const useUpdateCollaboratorsFundingSourceEntitlements = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, isError } = useMutation(
    AccountsApiClient.patchCollaboratorEntitlementsFundingSources,
    {
      onSuccess: () => queryClient.invalidateQueries(['collaborator']),
    }
  );

  return {
    isError,
    update: mutateAsync,
    isUpdating: isLoading,
  };
};
