import { FormatDateOptions } from 'react-intl';

import { useMelioIntl } from './useMelioIntl';

type FormattedDateProps = {
  date: Date;
  options?: FormatDateOptions;
};

export const FormattedDate = ({ date, options }: FormattedDateProps) => <>{useMelioIntl().formatDate(date, options)}</>;
