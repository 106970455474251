import { Vendor } from '@melio/platform-api';
import { useMemo } from 'react';

import { PaymentFlowDoneAction } from '../../../../types';
import { AutoPayActions } from '../components/AutoPayActions';
import { EBillActions } from '../components/EBillActions';
import { ExternalEntryAction } from '../components/ExternalEntryAction';
import { NotifyVendorsAction } from '../components/NotifyVendorsAction';
import { PaymentScheduledAction } from '../components/PaymentScheduledAction';
import { PaymentActionStateEnum } from '../PaymentsScheduled.consts';
import { PaymentDetails } from '../PaymentsScheduled.types';
import { usePaymentActionState } from './usePaymentActionState';

type UsePaymentScheduledActionButtonsProps = {
  onDone: (action: PaymentFlowDoneAction) => void;
  onNotifyVendors: VoidFunction;
  vendorsIdsWithoutEmail: Vendor['id'][];
  externalOrigin?: string | null;
  autoPayCapable: boolean;
  eBillCapable: boolean;
  isNewSuccessScreen?: boolean;
  paymentsDetails: PaymentDetails[];
  showResolvePaymentsButton: boolean;
};

export const PaymentScheduledActionButtons = ({
  onDone,
  onNotifyVendors,
  vendorsIdsWithoutEmail,
  paymentsDetails,
  externalOrigin,
  autoPayCapable,
  eBillCapable,
  isNewSuccessScreen,
  showResolvePaymentsButton,
}: UsePaymentScheduledActionButtonsProps): JSX.Element => {
  const isManagedAccountDeliveryMethod = paymentsDetails.some(
    (paymentDetails) => paymentDetails.deliveryMethod.type === 'managed-account'
  );

  const currentActionState = usePaymentActionState({
    vendorsIdsWithoutEmail,
    externalOrigin,
    autoPayCapable,
    eBillCapable,
    isManagedAccountDeliveryMethod,
  });

  const MAP_CONTENT_BY_STATE = useMemo(
    () => ({
      [PaymentActionStateEnum.NOTIFY_VENDORS]: (
        <NotifyVendorsAction
          onDone={onDone}
          onNotifyVendors={onNotifyVendors}
          vendorsIdsWithoutEmail={vendorsIdsWithoutEmail}
          isNewSuccessScreen={isNewSuccessScreen}
        />
      ),
      [PaymentActionStateEnum.EXTERNAL_PAYMENT_SCHEDULED]: (
        <ExternalEntryAction onDone={onDone} externalOrigin={externalOrigin} isNewSuccessScreen={isNewSuccessScreen} />
      ),
      [PaymentActionStateEnum.PAYMENT_SCHEDULED]: (
        <PaymentScheduledAction onDone={onDone} showResolvePaymentsButton={showResolvePaymentsButton} />
      ),
      [PaymentActionStateEnum.AUTO_PAY_CAPABLE_PAYMENT_SCHEDULED]: <AutoPayActions onDone={onDone} />,
      [PaymentActionStateEnum.EBILL_CAPABLE_PAYMENT_SCHEDULED]: <EBillActions onDone={onDone} />,
    }),
    [externalOrigin, onDone, onNotifyVendors, vendorsIdsWithoutEmail, isNewSuccessScreen, showResolvePaymentsButton]
  );

  return MAP_CONTENT_BY_STATE[currentActionState];
};
