import React from 'react';
import { DesignSystemProvider } from '@melio/penny';
import { PartnerName } from '@melio/platform-api';
import { FeatureFlags, useAnonymousFeature } from '@melio/platform-feature-flags';
import { LocalizationProvider } from '@melio/platform-i18n';
import { ConfigProvider } from '@melio/platform-provider/src/ConfigProvider';

import { usePartnerConfig } from '@/hooks/partners';
import { UnderMaintenancePage } from '@/widgets/UnderMaintenancePage/UnderMaintenancePage.widget';

type Props = {
  children: React.ReactNode;
  partnerName: PartnerName;
};

export const UnderMaintenanceBoundary = ({ partnerName, children }: Props) => {
  const [isUnderMaintenance] = useAnonymousFeature(FeatureFlags.UnderMaintenance, false);
  const {
    partnerConfig: { config, translations, theme, partnerProductName },
  } = usePartnerConfig(partnerName);

  if (isUnderMaintenance) {
    return (
      <ConfigProvider config={config}>
        <LocalizationProvider translations={translations}>
          <DesignSystemProvider theme={theme}>
            <UnderMaintenancePage partnerDisplayName={partnerProductName} />
          </DesignSystemProvider>
        </LocalizationProvider>
      </ConfigProvider>
    );
  }
  return <>{children}</>;
};
