import { ARCustomerTypeEnum, useCustomers } from '@melio/ar-domain';
import { useAnalytics } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import { useState } from 'react';

import { CreateCustomerScreen } from './screens';

type CreateCustomerActivityProps = {
  onDone: (id: string, customerName: string) => unknown;
  onClose: VoidFunction;
  isOpen: boolean;
  onError?: ARErrorFunction;
  customerName?: string;
};

export const CreateCustomerActivity = forwardRef<CreateCustomerActivityProps>(
  ({ onDone, onError: _onError, onClose, customerName, ...props }, ref) => {
    const { create, isMutating } = useCustomers({ enabled: false });

    const { track } = useAnalytics();

    const [error, setError] = useState<ARPlatformError>();
    const onError = (err: ARPlatformError) => [setError, _onError].forEach((fn) => fn?.(err));

    return (
      <CreateCustomerScreen
        defaultValues={{ customerName }}
        onSubmit={({ customerName, email, customerType, phone }) => {
          track('Customer', 'Click', {
            ProductName: 'ar',
            PageName: 'add-customer',
            Flow: 'add-customer',
            Intent: 'save-customer',
            Cta: 'save',
          });
          create({
            companyName: customerName,
            contactName: customerName,
            contactEmail: email,
            contactType: customerType,
            contactPhone: customerType === ARCustomerTypeEnum.Consumer ? phone : undefined,
          })
            .then((customer) => {
              track('Customer', 'Status', {
                StatusType: 'success',
                ProductName: 'ar',
                PageName: 'add-customer',
                Flow: 'add-customer',
                Intent: 'save-customer',
                Cta: 'save',
              });
              onDone(customer.id, customer.companyName);
            })
            .catch((err: ARPlatformError) => {
              track('Customer', 'Status', {
                StatusType: 'failure',
                ProductName: 'ar',
                PageName: 'add-customer',
                Flow: 'add-customer',
                Intent: 'save-customer',
                Cta: 'save',
              });
              onError(err);
            });
        }}
        onClose={() => {
          track('Customer', 'Click', {
            ProductName: 'ar',
            PageName: 'add-customer',
            Flow: 'add-customer',
            TabName: 'customers',
            Intent: 'exit-flow',
            Cta: 'cancel/close',
          });
          onClose();
        }}
        isSaving={isMutating}
        error={error}
        ref={ref}
        {...props}
      />
    );
  }
);
