import { useMelioIntl } from '@melio/platform-i18n';

import { useDateUtils } from './useDateUtils';

export const useFormatExpirationDate = () => {
  const { formatDate } = useMelioIntl();
  const { createDate } = useDateUtils();

  const formatExpirationDate = ({ month, year }: { month: string; year: string }) => {
    const expirationDate = createDate(`${year}-${month}-01`);

    return formatDate(expirationDate, {
      month: 'numeric',
      year: '2-digit',
    });
  };

  return { formatExpirationDate };
};
