import React from 'react';
import { Box } from '@chakra-ui/react';
import { Group, IconButton, Text } from '@melio/penny';
import { CreateMultipleInvoicesResults } from '@melio/platform-api';
import { MessageKey } from '@melio/platform-i18n';

import { UploadIndicatorTitleTheme } from '@/cl/components/UploadIndicator/UploadIndicator.component.theme';
import { useAppTheme } from '@/cl/theme/theme.hooks';
import { usePlatformIntl } from '@/translations/Intl';

type UploadIndicatorTitleProps = {
  onToggle: () => void;
  onClose: () => void;
  isMinimized: boolean;
  isUploading: boolean;
  multipleInvoices: CreateMultipleInvoicesResults;
};

export const UploadIndicatorTitleComponent = ({
  onToggle,
  onClose,
  isMinimized,
  isUploading,
  multipleInvoices,
}: UploadIndicatorTitleProps) => {
  const { formatMessage } = usePlatformIntl();
  const style = useAppTheme(UploadIndicatorTitleTheme, {});

  const { success: createdInvoices } = multipleInvoices?.results?.data || {};

  const getTitleText = (): string => {
    let id: string = 'uploads';
    let count = createdInvoices?.length ?? 0;

    if (isUploading) {
      id = 'uploading';
      count = multipleInvoices?.files?.length ?? 0;
    }
    if (createdInvoices && !createdInvoices?.length) {
      id = 'failed';
      count = multipleInvoices?.files?.length ?? 0;
    }

    return formatMessage(`widgets.uploadIndicator.${id}` as MessageKey, { count });
  };

  return (
    <Box data-testid="upload-indicator-title" css={style.uploadIndicatorTitle}>
      <Group alignItems="center" spacing="none">
        <Text>{getTitleText()}</Text>
        <IconButton
          icon={isMinimized ? 'chevron-up' : 'chevron-down'}
          variant="naked"
          onClick={onToggle}
          aria-label="button"
          size="medium"
        />
      </Group>
      <IconButton icon="close" variant="naked" onClick={onClose} aria-label="button" size="small" />
    </Box>
  );
};
