import { Form, FormSelectProps, SelectOption } from '@melio/penny';
import {
  AccountingPlatformSlug,
  useAccountingPlatformPaymentAccounts,
  useAccountingPlatforms,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import { ChangeEvent, useEffect, useState } from 'react';

export type AccountingPlatformAccountSelectWidgetProps = Omit<
  FormSelectProps,
  'onChange' | 'options' | 'emptyState'
> & {
  newPaymentAccountName: string;
  disabledNewOption?: boolean;
  labelProps?: { label: string };
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  onCreateOption: (newOption: string) => void;
};

export const AccountingPlatformAccountSelectWidget = forwardRef<AccountingPlatformAccountSelectWidgetProps, 'input'>(
  (
    {
      onChange,
      placeholder,
      newPaymentAccountName,
      onCreateOption,
      isReadOnly,
      isViewMode,
      viewModePlaceholder,
      disabledNewOption = false,
      ...props
    },
    ref
  ) => {
    const [accountingPlatformAccountOptions, setAccountingPlatformAccountOptions] = useState<SelectOption[]>([]);
    const [newOptionWasCreated, setNewOptionWasCreated] = useState(false);
    const { activeAccountingPlatform, isFetched: isAccountingPlatformFetched } = useAccountingPlatforms({});
    const { data: accountingPlatformPaymentAccounts, isFetched: isAccountingPlatformPaymentAccountsFetched } =
      useAccountingPlatformPaymentAccounts({
        accountingPlatformId: activeAccountingPlatform?.id as string,
        enabled: !!activeAccountingPlatform,
      });
    const newPaymentAccountAlreadyExists = accountingPlatformAccountOptions?.find(
      (paymentAccountOption) => paymentAccountOption.label === newPaymentAccountName
    );
    const isFetched = isAccountingPlatformFetched && isAccountingPlatformPaymentAccountsFetched;
    const isQuickBooksDesktopAccountingPlatform =
      activeAccountingPlatform?.accountingSlug === AccountingPlatformSlug.QuickBooksDesktop;

    const { formatMessage } = useMelioIntl();

    useEffect(() => {
      if (isFetched) {
        if (accountingPlatformPaymentAccounts && accountingPlatformPaymentAccounts.length > 0) {
          setAccountingPlatformAccountOptions(
            accountingPlatformPaymentAccounts?.map((accountingPlatformPaymentAccount) => ({
              value: accountingPlatformPaymentAccount?.id,
              label: accountingPlatformPaymentAccount?.name,
              testId: accountingPlatformPaymentAccount?.id,
            }))
          );
        }
      }
    }, [accountingPlatformPaymentAccounts, isFetched]);

    const isLoading = !isFetched;

    const handleCreateOption = () => {
      if (newPaymentAccountName) {
        setAccountingPlatformAccountOptions((value) => [
          ...value,
          {
            value: newPaymentAccountName,
            label: newPaymentAccountName,
            testId: newPaymentAccountName,
          },
        ]);
        onChange?.({ target: { value: newPaymentAccountName } } as ChangeEvent<HTMLSelectElement>);
        onCreateOption(newPaymentAccountName);
        setNewOptionWasCreated(true);
      }
    };

    const handleInputChange = (inputValue?: string) => {
      onChange?.({ target: { value: inputValue } } as ChangeEvent<HTMLSelectElement>);
    };

    return (
      // The ariaLabel and labelProps props are mutually exclusive, meaning that either labelProps or ariaLabel exists, never both simultaneously
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <Form.Select
        {...props}
        data-component="AccountingPlatformAccountSelectWidget"
        ref={ref}
        emptyState={{ label: formatMessage('widgets.fundingSources.reconciliationForm.emptyStateMessage') }}
        options={accountingPlatformAccountOptions}
        isLoading={isLoading}
        placeholder={placeholder}
        viewModePlaceholder={viewModePlaceholder}
        isReadOnly={isReadOnly}
        isViewMode={isViewMode}
        onInputChange={handleInputChange}
        creatableOption={{
          label: () => formatMessage('form.accountingPlatformAccountSelect.createLabel'),
          shouldDisplay: () =>
            !isQuickBooksDesktopAccountingPlatform &&
            !disabledNewOption &&
            !newOptionWasCreated &&
            !newPaymentAccountAlreadyExists,
          onClick: handleCreateOption,
        }}
      />
    );
  }
);

AccountingPlatformAccountSelectWidget.displayName = 'AccountingPlatformAccountSelectWidget';
