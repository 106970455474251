import { useModelApi, UseModelApiProps } from '@melio/api-client';
import {
  ApprovalRequirement,
  PostPaymentSettingsCheckApprovalRequirementRequest,
  ServicesApiClient,
} from '@melio/platform-api-axios-client';
import { getCentsFromDollars } from '@melio/platform-utils';

const checkApprovalRequirement = (
  _id: string,
  { amount, vendorId }: PostPaymentSettingsCheckApprovalRequirementRequest
) =>
  ServicesApiClient.postServicesPaymentSettingsCheckApprovalRequirement({
    amount: getCentsFromDollars(amount),
    vendorId,
  });

export type UseCheckApprovalRequirementProps = UseModelApiProps<typeof checkApprovalRequirement, ApprovalRequirement> &
  PostPaymentSettingsCheckApprovalRequirementRequest;

export const useCheckApprovalRequirement = ({ amount, vendorId, ...props }: UseCheckApprovalRequirementProps) =>
  useModelApi({
    ...props,
    id: 'fake_id',
    queryKey: ['ServicesApi', 'CheckApprovalRequirement', amount],
    queryFn: (id) => checkApprovalRequirement(id, { amount, vendorId }),
  });
