import { Flex } from '@chakra-ui/react';
import { Button, Container, Group, IconButton, Illustration, Layout, Text } from '@melio/penny';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect, useState } from 'react';
import Confetti from 'react-confetti';

import { usePlanInfo, useRedirects, useSubscription } from '../../../hooks';

export const SubscriptionSuccessActivity = withAnalyticsContext<{ shouldRunConfetti?: boolean }>(
  ({ setAnalyticsProperties, shouldRunConfetti = true }) => {
    const { formatMessage } = useMelioIntl();
    const subscription = useSubscription();
    const { planName } = usePlanInfo(subscription?.planId);
    const { goToReturnUrlOrDashboard } = useRedirects();
    setAnalyticsProperties({
      PageName: `welcome-melio-${planName.toLowerCase()}`,
      Flow: 'subscription',
      Intent: 'finish-subscription',
    });
    const { track } = useAnalytics();

    useAnalyticsView('Dashboard');
    const onClose = (closeType: string) => {
      track('Dashboard', 'Click', {
        Intent: closeType,
        Cta: closeType,
      });
      goToReturnUrlOrDashboard();
    };

    const [runConfetti, setRunConfetti] = useState(false);
    useEffect(() => {
      setTimeout(() => {
        setRunConfetti(true);
      }, 800);
    }, []);

    return (
      <Layout
        variant="6Columns"
        data-testid="subscription-success-page-activity"
        header={{
          content: (
            <Flex height="72px" flexDir="row" width="full" justifyContent="flex-end" alignItems="center" px="m">
              <IconButton
                aria-label="close"
                icon="close"
                variant="naked"
                size="medium"
                onClick={() => onClose('close')}
              />
            </Flex>
          ),
        }}
      >
        {shouldRunConfetti && (
          <Confetti
            run={runConfetti}
            width={window.innerWidth}
            height={window.innerHeight}
            gravity={0.04}
            numberOfPieces={1200}
            recycle={false}
            colors={['#BDBDFF', '#7849FF', '#F6F2FD', '#370DB6', '#FFE814', '#FED9B7']}
            confettiSource={{ x: window.innerWidth / 2, y: window.innerHeight / 2 - 10, w: 10, h: 10 }}
            initialVelocityX={{
              min: -10,
              max: 10,
            }}
            initialVelocityY={{
              min: -5,
              max: -20,
            }}
            drawShape={(ctx) => {
              const shape = Math.random() < 0.5 ? 'square' : 'rectangle';
              if (shape === 'square') {
                const padding = 5;
                const rectWidth = 10;
                const rectHeight = 10;
                const rectX = -5;
                const rectY = -5;
                ctx.clearRect(rectX - padding, rectY - padding, rectWidth + 2 * padding, rectHeight + 2 * padding);
                ctx.fillRect(rectX, rectY, rectWidth, rectHeight);
                // ctx.fillRect(-5, -5, 10, 10);
              } else {
                const padding = 5;
                const rectWidth = 15;
                const rectHeight = 10;
                const rectX = -7.5;
                const rectY = -5;
                ctx.clearRect(rectX - padding, rectY - padding, rectWidth + 2 * padding, rectHeight + 2 * padding);
                ctx.fillRect(rectX, rectY, rectWidth, rectHeight);
                // ctx.fillRect(-7.5, -5, 15, 10);
              }
            }}
          />
        )}
        <Container paddingX="xxs" paddingY="xxs" height="full" textAlign="center">
          <Group variant="vertical" spacing="s" justifyContent="center" alignItems="center" height="full">
            <Group variant="horizontal">
              {/* @ts-expect-error TODO ask penny to support zIndex on Illustration/Group */}
              <Illustration size="large" type="celebration" zIndex="10" />
            </Group>

            <Group variant="vertical" spacing="xxs">
              <Text textStyle="heading2Semi">
                {formatMessage('activities.subscription.welcome.title', { planName })}
              </Text>
              <Text textStyle="body2" color="global.neutral.900">
                {formatMessage('activities.subscription.welcome.description', { planName })}
              </Text>
            </Group>

            <Container paddingTop="xs">
              <Button
                variant="primary"
                label={formatMessage('activities.subscription.welcome.getStarted')}
                onClick={() => onClose('go-to-dashboard')}
                size="large"
                data-testid="subscription-success-page-activity-go-to-dashboard-button"
              />
            </Container>
          </Group>
        </Container>
      </Layout>
    );
  }
);
