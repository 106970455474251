import { Icon } from '@chakra-ui/react';

export const CrownLarge = (additionalProps = {}) => (
  <Icon width="44px" height="44px" viewBox="0 0 48 48" fill="none" {...additionalProps}>
    <path
      d="M20.4536 22.1756L22.8596 14.8967C23.2546 13.7011 24.7454 13.7011 25.1404 14.8967L27.5464 22.1756L32.1032 18.729C33.0528 18.0107 34.2734 19.0356 33.9456 20.2758L31.1058 31.0152C30.9512 31.5998 30.477 32 29.9392 32H18.0609C17.523 32 17.0488 31.5998 16.8942 31.0152L14.0545 20.2758C13.7265 19.0356 14.9472 18.0107 15.8968 18.7289L20.4536 22.1756Z"
      fill="#7849FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 24C2 11.8497 11.8497 2 24 2C36.1503 2 46 11.8497 46 24C46 36.1503 36.1503 46 24 46C11.8497 46 2 36.1503 2 24ZM24 6C14.0589 6 6 14.0589 6 24C6 33.9411 14.0589 42 24 42C33.9411 42 42 33.9411 42 24C42 14.0589 33.9411 6 24 6Z"
      fill="#7849FF"
    />
  </Icon>
);
