import { Collaborator, CollaboratorAction } from '../../types';

type Props = {
  actor?: Collaborator;
  collaborator: Collaborator;
};

export function getCollaboratorActions({ collaborator, actor }: Props): CollaboratorAction[] {
  if (!actor || collaborator.id === actor.id) {
    return [];
  }

  return collaborator.allowedActions;
}
