import { RoleUniqueNames } from '@melio/platform-api';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import { useCallback } from 'react';

export const useRoleText = () => {
  const { formatMessage } = useMelioIntl();

  const getRoleText = useCallback(
    (roleUniqueName: string | null | undefined) => {
      if (roleUniqueName && Object.values(RoleUniqueNames).includes(roleUniqueName as RoleUniqueNames)) {
        return formatMessage(`activities.manageCollaborators.roles.${roleUniqueName}` as MessageKey);
      }
      return formatMessage('activities.manageCollaborators.roles.null');
    },
    [formatMessage]
  );

  return { getRoleText };
};
