/* eslint-disable react-hooks/exhaustive-deps */
import { EventProperties, useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { Payment } from '@melio/platform-api';

import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';
import { RefundProcessingScreen } from '../screens/RefundProcessing/RefundProcessing.screen';
import { RefundSentScreen } from '../screens/RefundSent/RefundSent.screen';
import { RefundFlowDoneAction } from '../types/types';

type Props = {
  payment: Payment;
  isLoading: boolean;
  isRefundError: boolean;
  defaultAnalyticsFields: EventProperties;
  onFlowDone: (action: RefundFlowDoneAction) => void;
};

export const RefundSentStep = ({ isRefundError, payment, isLoading, defaultAnalyticsFields, onFlowDone }: Props) => {
  const { amount, fundingSource } = payment;
  const { createTrackHandler } = useAnalytics();
  const trackAndHandleClick = createTrackHandler<{
    Cta: 'back-to-pay';
  }>('Payment', 'Click');

  useAnalyticsView('Payment', true, true, { ...defaultAnalyticsFields });

  const onDone = () => {
    trackAndHandleClick({ Cta: 'back-to-pay', ...defaultAnalyticsFields }, onFlowDone('goToDashboard'));
  };

  if (!fundingSource) {
    return <NewSinglePaymentStepLayout isLoading={isLoading} />;
  }

  return isRefundError ? (
    <RefundProcessingScreen amount={amount} onDone={onDone} />
  ) : (
    <RefundSentScreen amount={amount} fundingSource={fundingSource} onDone={onDone} />
  );
};
