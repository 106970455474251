import { Container, Group, StatusBar } from '@melio/penny';
import { Loan, Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { InstallmentsSection } from '../sections/InstallmentsSection';
import { LoanCanceledSectionBanner } from '../sections/LoanCanceledSectionBanner';
import { LoanProviderSection } from '../sections/LoanProviderSection';
import { PayBySection } from '../sections/PayBySection';
import { RepaymentSection } from '../sections/RepaymentSection';
import { SectionWrapper } from '../sections/SectionWrapper';

export const LoanCanceled = ({ payment }: { payment: Override<Payment, { loan: Loan }> }) => {
  const { formatMessage } = useMelioIntl();

  return (
    <>
      <StatusBar
        variant="critical"
        content={formatMessage('activities.payDashboard.drawer.body.loan.canceled.title')}
      />
      <Container paddingY="m">
        <Group variant="vertical" hasDivider spacing="m">
          <SectionWrapper>
            <Group variant="vertical" spacing="m">
              <LoanCanceledSectionBanner payment={payment} />
              <RepaymentSection payment={payment} />
            </Group>
          </SectionWrapper>
          <SectionWrapper>
            <InstallmentsSection loan={payment.loan} />
          </SectionWrapper>
          <SectionWrapper>
            <PayBySection payment={payment} />
          </SectionWrapper>
          <SectionWrapper>
            <LoanProviderSection />
          </SectionWrapper>
        </Group>
      </Container>
    </>
  );
};
