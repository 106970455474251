import {
  OrgFinancingEligibility,
  RepaymentTerms,
  SelectedRepaymentOption,
  SelectRepaymentTermRequest,
} from '@melio/platform-api-axios-client';
import { convertCentsToDollars, convertDateTimeToDateString, convertDollarsToCents } from '@melio/platform-utils';

export const convertFinancingRepaymentTermsResponseCentsToDollars = (repaymentTerms: RepaymentTerms) =>
  convertCentsToDollars(repaymentTerms, [
    'options[].paymentAmount',
    'options[].feeAmount',
    'options[].installmentAmount',
    'balance',
    'limit',
  ]);

export const convertSelectedRepaymentTermResponseCentsToDollars = (selectedRepaymentOption: SelectedRepaymentOption) =>
  convertCentsToDollars(selectedRepaymentOption, [
    'totalAmount',
    'principalAmount',
    'totalFeesAmount',
    'installments[].amount',
  ]);

export const convertOrgEligibilityResponseCentsToDollars = (orgFinancingEligibility: OrgFinancingEligibility) =>
  convertCentsToDollars(orgFinancingEligibility, ['limit', 'balance']);

const prepareScheduleDate = <T extends { scheduleDate: string | Date }>(data: T) =>
  convertDateTimeToDateString(data, ['scheduleDate']);

export type SelectRepaymentTermParams = Omit<SelectRepaymentTermRequest, 'scheduleDate'> & {
  scheduleDate: Date;
};

export const prepareSelectRepaymentOptionParams = (params: SelectRepaymentTermParams) => {
  const preparedData = convertDollarsToCents(params, ['paymentAmount']);
  return prepareScheduleDate(preparedData);
};
