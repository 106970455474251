import { Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';

import { BrandColoredLink } from '../../BrandColoredLink/BrandColoredLink';

export const FinancingApplicationFlowTitle = () => {
  const { formatMessage } = useMelioIntl();
  const { links: linksConfig } = useConfig();

  return (
    <Group alignItems="center" variant="vertical" textAlign="center">
      <Text textStyle="heading1Semi">
        {formatMessage('activities.financingApplication.applicationFormScreen.title')}
      </Text>
      <Text>{formatMessage('activities.financingApplication.applicationFormScreen.subtitle')}</Text>

      <Text textStyle="body3">
        {formatMessage('activities.financingApplication.applicationFormScreen.poweredBy', {
          learnMore: (
            <BrandColoredLink
              href={linksConfig['activities.financingApplication.screens.applicationForm.title.learnMore']}
              label="learn more"
            />
          ),
        })}
      </Text>
    </Group>
  );
};
