import { useAccount, useFiles, useMelioIntl } from '@melio/ar-domain';
import { useToast } from '@melio/penny';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import { useState } from 'react';

import { UploadCompanyLogoModalScreen } from './screens';

export type UploadCompanyLogoModalActivityProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  onDone: (id: string) => void;
  onError?: ARErrorFunction;
};

export const UploadCompanyLogoModalActivity = forwardRef<UploadCompanyLogoModalActivityProps>(
  ({ isOpen, onDone, onClose, onError, ...props }, ref) => {
    const [logoFile, setLogoFile] = useState<File | null>(null);
    const { create: createFile, isMutating: isUploadingFile } = useFiles({ enabled: false });
    const { update: updateAccount, isMutating: isUpdatingAccount } = useAccount({
      id: 'me',
      enabled: false,
      onError,
    });
    const { fileSizeLimit } = useConfig().settings;
    const { formatMessage } = useMelioIntl();
    const { toast } = useToast();

    const handleLogoUpload = (logoToUpload: File | null) => {
      setLogoFile(logoToUpload);
    };

    const handleSave = async () => {
      try {
        if (logoFile) {
          if (logoFile.size > fileSizeLimit) {
            toast({
              type: 'error',
              title: formatMessage('ar.invoiceLifecycle.activities.uploadCompanyLogo.modal.errors.fileSizeLimit'),
            });
          } else {
            const uploadedFile = await createFile(logoFile);
            await updateAccount({ company: { logoId: uploadedFile.id } });
            onDone(uploadedFile.id);
          }
        }
      } catch (err) {
        onError?.(err as ARPlatformError);
      }
    };

    return (
      <UploadCompanyLogoModalScreen
        isOpen={isOpen}
        isSaving={isUploadingFile || isUpdatingAccount}
        isSaveDisabled={!logoFile}
        onClose={onClose}
        onLogoUpload={handleLogoUpload}
        onSave={handleSave}
        {...props}
        ref={ref}
      />
    );
  }
);
UploadCompanyLogoModalActivity.displayName = 'UploadCompanyLogoModal';
