import { useDisclosure } from '@chakra-ui/react';
import { OneTimeMessageModalWidget } from '@melio/ap-widgets';
import { useSignatures } from '@melio/platform-api';
import { useCallback, useEffect } from 'react';

import { billsInMelioComOnly, billsInPayVendorsOnly } from './users.objects';

export type OneTimeMessageModalActivityProps = {
  userId: string;
  agreementId: string;
};

export const OneTimeMessageModalActivity = ({ userId, agreementId }: OneTimeMessageModalActivityProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: signatures, create: sign } = useSignatures({ agreementId });

  const isInMelioComOnly = Number(userId) in billsInMelioComOnly;
  const isInPayVendorsOnly = Number(userId) in billsInPayVendorsOnly;

  useEffect(() => {
    if (
      (isInMelioComOnly || isInPayVendorsOnly) &&
      signatures &&
      !signatures.find((signature) => signature.userId.toString() === userId)
    ) {
      onOpen();
    }
  }, [signatures, userId, isInMelioComOnly, isInPayVendorsOnly, onOpen]);

  const onDone = useCallback(async () => {
    onClose();
    await sign({ agree: 'yes' });
  }, [sign, onClose]);

  return <OneTimeMessageModalWidget isOpen={isOpen} onDone={onDone} isPayVendor={isInPayVendorsOnly} />;
};

OneTimeMessageModalActivity.displayName = 'OneTimeMessageModalActivity';
