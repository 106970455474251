/* eslint-disable react-hooks/exhaustive-deps */
import { FormWidgetProps } from '@melio/ap-widgets';
import { Form, FormSelectNewOption, useMelioForm } from '@melio/penny';
import { useAnalytics, useTrackAnalyticsOnFailedFormSubmit } from '@melio/platform-analytics';
import { CountryInternationalDeliveryDetails } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { defaults, omit } from 'lodash';
import { useEffect, useRef, useState } from 'react';

import { BankDetailsSchema, useBankIdentifierValue } from './internationalBankDetailsForm.utils';
import { InternationalBankDetailsFormFields } from './types';

type InternationalBankDetailsFormProps = FormWidgetProps<InternationalBankDetailsFormFields> & {
  countriesDetailsOptions: CountryInternationalDeliveryDetails[];
  onSelectedCountryChange: (selectedCountry: CountryInternationalDeliveryDetails) => void;
  selectedCountry?: CountryInternationalDeliveryDetails;
};

export const InternationalBankDetailsForm = forwardRef<InternationalBankDetailsFormProps, 'form'>(
  (
    {
      onSelectedCountryChange,
      selectedCountry,
      onSubmit,
      defaultValues: _defaultValues,
      isSaving,
      onSubmissionStateChange,
      countriesDetailsOptions,
      ...props
    },
    ref
  ) => {
    const { track } = useAnalytics();
    const { formatMessage } = useMelioIntl();
    const [isIdentifierValueValid, setIsIdentifierValueValid] = useState(false);
    const [isBankDataFetching, setIsBankDataFetching] = useState(false);
    const defaultValues = defaults(_defaultValues, {
      bankName: '',
      accountNumber: '',
      swiftCode: '',
      ibanCode: '',
    });
    const isFormResettable = useRef<boolean>(false);

    const { formProps, registerField, setValue, formState, setError, clearErrors, watch, resetField } =
      useMelioForm<InternationalBankDetailsFormFields>({
        onSubmit,
        schema: BankDetailsSchema({ selectedCountry, isIdentifierValueValid }),
        defaultValues,
        isSaving,
        isLoading: isBankDataFetching,
        onSubmissionStateChange,
        subscribeToDefaultValuesChanges: true,
      });

    const watchedSelectedCountry = watch('selectedInternationalCountries');

    /**
     * clear all fields except selectedInternationalCountries
     *    - ignore cached values of iban, bicSwift & bankName by setting defaultValue to ''
     *    - reset cached identifier validations
     *    - enable form to be resettable
     */
    const resetForm = () => {
      const formFieldToClear = omit(defaultValues, 'selectedInternationalCountries');
      Object.keys(formFieldToClear).forEach((key) => {
        resetField(key as keyof InternationalBankDetailsFormFields, { defaultValue: '' });
        clearErrors(key as keyof InternationalBankDetailsFormFields);
      });
      resetIdentifierValidationData();
      isFormResettable.current = true;
    };

    useEffect(() => {
      if (watchedSelectedCountry) {
        track(`DeliveryMethod`, 'Click', {
          Intent: 'choose-vendor-country',
          SearchValue: watchedSelectedCountry.name,
          CountryRiskScore: watchedSelectedCountry.risk,
          IsSupported: watchedSelectedCountry.isSupported,
          Cta: 'search-results',
        });
      }

      // reset form on country change, except first render of default country
      if (
        watchedSelectedCountry?.name !== defaultValues.selectedInternationalCountries?.name ||
        isFormResettable.current
      ) {
        resetForm();
      }

      onSelectedCountryChange(watchedSelectedCountry);
    }, [watchedSelectedCountry, isFormResettable]);

    useTrackAnalyticsOnFailedFormSubmit(
      formState,
      'DeliveryMethod',
      'Status',
      selectedCountry && {
        DeliveryCountry: selectedCountry.name,
        CountryRiskScore: selectedCountry.risk,
        IsSupported: selectedCountry.isSupported,
      }
    );

    const { onIdentifierValueBlur, isLoading, isFetching, resetIdentifierValidationData } = useBankIdentifierValue({
      countryCode: selectedCountry?.code,
      clearErrors,
      setValue,
      setError,
      identifierType: selectedCountry?.identifierType,
      setIsIdentifierValueValid,
      defaultIdentifier:
        defaultValues.selectedInternationalCountries?.identifierType === 'iban'
          ? _defaultValues?.ibanCode
          : _defaultValues?.swiftCode,
    });

    useEffect(() => {
      setIsBankDataFetching(isFetching);
    }, [isFetching]);

    const shouldShowIban = selectedCountry?.isSupported && selectedCountry?.identifierType === 'iban';
    const shouldShowSwift = selectedCountry?.isSupported && selectedCountry?.identifierType === 'bicSwift';

    const countriesOptions: FormSelectNewOption[] = countriesDetailsOptions.map(
      (internationalOption) =>
        ({
          value: internationalOption,
          label: internationalOption.name,
          // Enforce the option type to be non-string.
          // Will be resolved once this is done - https://meliorisk.atlassian.net/browse/ME-68530
        } as unknown as FormSelectNewOption)
    );
    return (
      <Form
        data-component="internationalBankDetails.InternationalBankDetailsForm"
        isLoading={isLoading}
        ref={ref}
        {...props}
        {...formProps}
      >
        <Form.SelectNew
          options={countriesOptions}
          {...registerField('selectedInternationalCountries')}
          labelProps={{
            label: formatMessage(
              'activities.internationalBankDetails.screens.internationalBankDetailsForm.internationalCountrySelection.label'
            ),
          }}
          placeholder={formatMessage(
            'activities.internationalBankDetails.screens.internationalBankDetailsForm.internationalCountrySelection.placeHolder'
          )}
        />
        <Form.TextField
          labelProps={{
            label: formatMessage(
              'activities.internationalBankDetails.screens.internationalBankDetailsForm.ibanCode.label'
            ),
          }}
          placeholder={formatMessage(
            'activities.internationalBankDetails.screens.internationalBankDetailsForm.ibanCode.placeholder'
          )}
          {...registerField('ibanCode')}
          onBlur={onIdentifierValueBlur}
          isHidden={!shouldShowIban}
        />
        <Form.TextField
          labelProps={{
            label: formatMessage(
              'activities.internationalBankDetails.screens.internationalBankDetailsForm.swiftCode.label'
            ),
          }}
          placeholder={formatMessage(
            'activities.internationalBankDetails.screens.internationalBankDetailsForm.swiftCode.placeholder'
          )}
          {...registerField('swiftCode')}
          onBlur={onIdentifierValueBlur}
          isHidden={!shouldShowSwift}
        />
        <Form.TextField
          labelProps={{
            label: formatMessage(
              'activities.internationalBankDetails.screens.internationalBankDetailsForm.accountNumber.label'
            ),
          }}
          placeholder={formatMessage(
            'activities.internationalBankDetails.screens.internationalBankDetailsForm.accountNumber.placeholder'
          )}
          {...registerField('accountNumber')}
          isHidden={!shouldShowSwift}
        />
        <Form.TextField
          labelProps={{
            label: formatMessage(
              'activities.internationalBankDetails.screens.internationalBankDetailsForm.bankName.label'
            ),
          }}
          placeholder={formatMessage(
            'activities.internationalBankDetails.screens.internationalBankDetailsForm.bankName.placeholder'
          )}
          {...registerField('bankName')}
          isHidden={!shouldShowIban && !shouldShowSwift}
        />
      </Form>
    );
  }
);

InternationalBankDetailsForm.displayName = 'internationalBankDetails.InternationalBankDetailsForm';
