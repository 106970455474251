import { useMelioIntl } from '@melio/platform-i18n';
import { useIsSubscriptionsEnabled } from '@melio/subscriptions';
import { useMemo } from 'react';

import { useStepManager } from '../../../hooks';
import {
  NewClientBasicDetailsStep,
  NewClientBillingDetailsStep,
  NewClientPlanSelectionStep,
} from '../components/NewClientFormSteps';
import { NewClientPlanReviewAndConfirmStep } from '../components/NewClientFormSteps/NewClientPlanReviewAndConfirmStep';
import { NewFirmClientFormStepDetails, NewFirmClientFormSteps } from '../types';

export const useNewFirmClientStepManager = ({
  defaultActiveId,
  isFirmBilled,
}: {
  defaultActiveId?: NewFirmClientFormSteps;
  isFirmBilled: boolean;
}) => {
  const { formatMessage } = useMelioIntl();
  const isSubscriptionEnabled = useIsSubscriptionsEnabled();

  const stepsData = useMemo(() => {
    const basicSteps = [
      {
        title: formatMessage(`activities.accountants.newClient.form.basicDetails.title.text`),
        description: formatMessage(`activities.accountants.newClient.form.basicDetails.description.text`),
        stepId: NewFirmClientFormSteps.BasicDetails,
        component: NewClientBasicDetailsStep,
      },
    ];

    const subscriptionSteps = isSubscriptionEnabled
      ? [
          {
            title: formatMessage(`activities.accountants.newClient.form.billingDetails.title.text`),
            description: formatMessage(`activities.accountants.newClient.form.billingDetails.description.text`),
            stepId: NewFirmClientFormSteps.BillingDetails,
            component: NewClientBillingDetailsStep,
          },
          {
            title: formatMessage(`activities.accountants.newClient.form.planSelection.title.text`),
            description: formatMessage(`activities.accountants.newClient.form.planSelection.description.text`),
            stepId: NewFirmClientFormSteps.PlanSelection,
            component: NewClientPlanSelectionStep,
          },
          {
            title: formatMessage(`activities.accountants.newClient.form.reviewAndConfirm.title.text`),
            description: formatMessage(
              isFirmBilled
                ? `activities.accountants.newClient.form.reviewAndConfirm.description.text.firmBilled`
                : `activities.accountants.newClient.form.reviewAndConfirm.description.text.clientBilled`
            ),
            stepId: NewFirmClientFormSteps.ReviewAndConfirm,
            component: NewClientPlanReviewAndConfirmStep,
          },
        ]
      : [];

    return [...basicSteps, ...subscriptionSteps];
  }, [isFirmBilled, formatMessage, isSubscriptionEnabled]);

  const { steps, expandedStep, expandSelectedStep, goToNextStep, focusErrorStep } =
    useStepManager<NewFirmClientFormStepDetails>({
      data: stepsData,
      defaultActiveId,
    });

  return { steps, expandedStep, expandSelectedStep, goToNextStep, focusErrorStep };
};
