import { withAnalyticsContext } from '@melio/platform-analytics';
import { CardParams, useFundingSources } from '@melio/platform-api';
import { useState } from 'react';

import { ReconciliationModalActivity } from '../Reconciliation';
import { CardHolderDetailsScreen, CardValidationModalScreen, TabapayCreateCardDetailsScreen } from './screens';
import { useAddCardFundingSourceActivityCallbacks } from './shared/useAddCardFundingSourceActivityCallbacks';
import { AddCardFundingSourceActivityProps } from './types';
import { useAddCardFundingSourceActivityStep } from './useAddCardFundingSourceActivityStep';
export const TabapayAddCardFundingSourceActivity = withAnalyticsContext<AddCardFundingSourceActivityProps>(
  ({ onClose, onDone, onBack: onFirstStepBack, onError, cardType }) => {
    const { currentStep, goToPreviousStep, goToStep } = useAddCardFundingSourceActivityStep({ onFirstStepBack });
    const [cardDetailsParams, setCardDetailsParams] = useState<CardParams>();
    const collection = useFundingSources({ enabled: false });

    const {
      showVerificationErrorDialog,
      handleSubmit,
      isCardValidationModalOpen,
      onCardValidationModalDone,
      reconciliation,
      fundingSourceId,
    } = useAddCardFundingSourceActivityCallbacks({ vaulting: 'Tabapay', cardType, onError, cardDetailsParams, onDone });

    const {
      onCloseReconciliationFlow,
      onDoneReconciliationFlow,
      shouldShowReconciliationModal,
      isReconciliationNeeded,
    } = reconciliation;

    if (currentStep == 'ADD_CARD_HOLDER_DETAILS') {
      return (
        <>
          <CardHolderDetailsScreen
            onDone={handleSubmit}
            onClose={onClose}
            onBack={goToPreviousStep}
            isSaving={collection.isMutating}
          />
          {fundingSourceId && isReconciliationNeeded && (
            <ReconciliationModalActivity
              isOpen={shouldShowReconciliationModal}
              onClose={onCloseReconciliationFlow(onClose)}
              onDone={onDoneReconciliationFlow}
              selectedFundingSourceId={fundingSourceId}
              isNewFundingSource
            />
          )}
        </>
      );
    }

    return (
      <>
        <TabapayCreateCardDetailsScreen
          cardType={cardType}
          onClose={onClose}
          onBack={goToPreviousStep}
          onError={showVerificationErrorDialog}
          onDone={(data) => {
            setCardDetailsParams(data);
            goToStep('ADD_CARD_HOLDER_DETAILS');
          }}
        />
        <CardValidationModalScreen
          isOpen={isCardValidationModalOpen}
          onDone={onCardValidationModalDone}
          cardType={cardType}
        />
      </>
    );
  }
);

TabapayAddCardFundingSourceActivity.displayName = 'AddCardFundingSourceActivity';
