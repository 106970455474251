/**
 * @deprecated use packages/ap/domain/src/functions/delivery-preference.ts
 */

import { DeliveryPreference, PaymentIntent } from '@melio/platform-api';
import { isAfter } from 'date-fns';

export const isDeliveryPreferenceTypeFast = (type: DeliveryPreference['type']) =>
  type === 'expedited-ach' || type === 'express-check' || type === 'rtp';

export const hasFastDeliveryPreferenceOption = (
  deliveryPreferencesOptions: PaymentIntent['deliveryPreferenceOptions']
): boolean => !!getFastDeliveryPreferenceOptionType(deliveryPreferencesOptions);

export const getFastDeliveryPreferenceOptionType = (
  deliveryPreferencesOptions: PaymentIntent['deliveryPreferenceOptions']
) => deliveryPreferencesOptions?.find((option) => isDeliveryPreferenceTypeFast(option.type))?.type;

export const isLateDeliveryDate = ({ minDeliveryDate }: DeliveryPreference, dueDate: Date) =>
  isAfter(minDeliveryDate, dueDate);
