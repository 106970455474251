import { useCollectionApi, UseCollectionApiProps } from '@melio/api-client';
import { AccountingPlatformsApiClient } from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useMutation, useQueryClient } from 'react-query';

type CreateQueryFn = typeof AccountingPlatformsApiClient.createAccountingPlatformsPaymentAccount;

export type UseAccountingPlatformPaymentAccountsProps = UseCollectionApiProps<
  typeof AccountingPlatformsApiClient.getAccountingPlatformsPaymentAccountsById
> & { accountingPlatformId?: string; noInvalidations?: boolean };

export const useAccountingPlatformPaymentAccounts = (props?: UseAccountingPlatformPaymentAccountsProps) => {
  const queryClient = useQueryClient();
  const query = useCollectionApi<typeof AccountingPlatformsApiClient.getAccountingPlatformsPaymentAccountsById>({
    ...props,
    queryKey: ['AccountingPlatformsApi', 'getAccountingPlatformsPaymentAccountsById', props?.accountingPlatformId],
    queryFn: () =>
      AccountingPlatformsApiClient.getAccountingPlatformsPaymentAccountsById(props?.accountingPlatformId as string),
  });

  const create = useMutation(
    query.queryKey,
    (data: Parameters<CreateQueryFn>[1]) =>
      AccountingPlatformsApiClient.createAccountingPlatformsPaymentAccount(props?.accountingPlatformId as string, data),
    {
      onSuccess: () => queryClient.invalidateQueries(query.queryKey),
    }
  );

  return {
    ...query,
    create: create.mutateAsync,
  };
};
