// eslint-disable-next-line no-restricted-imports
import { MutationFunction, useMutation } from 'react-query';

import { ApiError, MutationCallbackOptions, MutationContext, UseModelApiResult } from './types';
import { useMelioQueryClient } from './useMelioQueryClient';

type Options = {
  onSuccess?: () => PromiseLike<void> | void;
  onError?: MutationCallbackOptions<void, void>['onError'];
};

export function useDeleteMutation<T>(
  mutationFn: MutationFunction<void, void>,
  query: Pick<UseModelApiResult<T>, 'queryKey'>,
  options: Options = {}
) {
  const queryClient = useMelioQueryClient();
  const [entity] = query.queryKey;

  const onSuccess = () => {
    void queryClient.invalidateQueries(entity, 'collection');
    queryClient.setQueriesData([entity, 'model'], undefined);
    void options.onSuccess?.();
  };

  const mutation = useMutation<void, ApiError, void, MutationContext<void>>(query.queryKey, mutationFn, {
    onSuccess,
    onError: options.onError,
  });

  return mutation.mutateAsync;
}
