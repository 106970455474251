import { WorkflowPaymentAmountCondition } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { ConditionFormatter } from '../types';

export function useWorkflowNumericConditionFormatter() {
  const { formatMessage, formatCurrency } = useMelioIntl();

  const format: ConditionFormatter<WorkflowPaymentAmountCondition> = ({ condition, templateKey }) =>
    formatMessage(templateKey, {
      attribute: formatMessage(
        `activities.settings.approval-workflow-settings.conditions.fields.${condition.attribute}`
      ),
      operator: formatMessage(
        `activities.settings.approval-workflow-settings.conditions.numeric.operators.${condition.condition.configuration.operator}`
      ),
      value: formatCurrency(condition.condition.configuration.value),
    });

  return { format };
}
