import { TourtipStep } from '@melio/penny/dist/types/components/dataDisplay/Tourtip/Tourtip.types';
import { useMelioIntl } from '@melio/platform-i18n';

import { createTourIdSelector } from '../../../../../utils';
import { useVendorsTabData } from '../../VendorsTab/useVendorsTabData';

type NonEmptyArray<T> = [T, ...T[]];

export const useFiservDashboardTourSteps = ({ enabled }: { enabled: boolean }) => {
  const { formatMessage } = useMelioIntl();
  const { isLoading: isVendorTabLoading, data: vendorsResult } = useVendorsTabData({
    searchTerm: null,
    enabled,
  });
  const { data: vendors = [] } = vendorsResult ?? {};
  const [vendor] = vendors;
  const isExistingUser = !!vendor;

  const steps: NonEmptyArray<TourtipStep> = isVendorTabLoading
    ? [
        {
          target: createTourIdSelector('pay-dashboard-tab-vendors'),
          title: formatMessage('fiserv.activities.payDashboard.tour.existingUser.vendorsTab.step1.title'),
          description: formatMessage('fiserv.activities.payDashboard.tour.existingUser.vendorsTab.step1.content'),
          nextButtonLabel: formatMessage('fiserv.activities.payDashboard.tour.done.label'),
        },
      ]
    : isExistingUser
    ? [
        {
          target: createTourIdSelector('pay-dashboard-tab-vendors'),
          title: formatMessage('fiserv.activities.payDashboard.tour.existingUser.vendorsTab.step1.title'),
          description: formatMessage('fiserv.activities.payDashboard.tour.existingUser.vendorsTab.step1.content'),
        },
        {
          target: createTourIdSelector(`vendors-tab-action-cell-${vendor.id}`),
          title: formatMessage('fiserv.activities.payDashboard.tour.existingUser.vendorsTab.step2.title'),
          description: formatMessage('fiserv.activities.payDashboard.tour.existingUser.vendorsTab.step2.content'),
        },
        {
          target: createTourIdSelector('pay-dashboard-tab-bills'),
          title: formatMessage('fiserv.activities.payDashboard.tour.existingUser.billsTab.title'),
          description: formatMessage('fiserv.activities.payDashboard.tour.existingUser.billsTab.content'),
        },
        {
          target: createTourIdSelector('pay-dashboard-tab-approvals'),
          title: formatMessage('fiserv.activities.payDashboard.tour.existingUser.approvalsTab.title'),
          description: formatMessage('fiserv.activities.payDashboard.tour.existingUser.approvalsTab.content'),
        },
        {
          target: createTourIdSelector('pay-dashboard-tab-payments'),
          title: formatMessage('fiserv.activities.payDashboard.tour.existingUser.paymentsTab.title'),
          description: formatMessage('fiserv.activities.payDashboard.tour.existingUser.paymentsTab.content'),
        },
        {
          target: createTourIdSelector('sync-now-button'),
          title: formatMessage('fiserv.activities.payDashboard.tour.existingUser.sync.title'),
          description: formatMessage('fiserv.activities.payDashboard.tour.existingUser.sync.content'),
          nextButtonLabel: formatMessage('fiserv.activities.payDashboard.tour.done.label'),
        },
      ]
    : [
        {
          target: createTourIdSelector('pay-dashboard-tab-vendors'),
          title: formatMessage('fiserv.activities.payDashboard.tour.newUser.vendorsTab.title'),
          description: formatMessage('fiserv.activities.payDashboard.tour.newUser.vendorsTab.content'),
        },
        {
          target: createTourIdSelector('pay-dashboard-tab-bills'),
          title: formatMessage('fiserv.activities.payDashboard.tour.newUser.billsTab.title'),
          description: formatMessage('fiserv.activities.payDashboard.tour.newUser.billsTab.content'),
        },
        {
          target: createTourIdSelector('pay-dashboard-tab-approvals'),
          title: formatMessage('fiserv.activities.payDashboard.tour.newUser.approvalsTab.title'),
          description: formatMessage('fiserv.activities.payDashboard.tour.newUser.approvalsTab.content'),
        },
        {
          target: createTourIdSelector('pay-dashboard-tab-payments'),
          title: formatMessage('fiserv.activities.payDashboard.tour.newUser.paymentsTab.title'),
          description: formatMessage('fiserv.activities.payDashboard.tour.newUser.paymentsTab.content'),
        },
        {
          target: createTourIdSelector('sync-now-button'),
          title: formatMessage('fiserv.activities.payDashboard.tour.newUser.sync.title'),
          description: formatMessage('fiserv.activities.payDashboard.tour.newUser.sync.content'),
          nextButtonLabel: formatMessage('fiserv.activities.payDashboard.tour.done.label'),
        },
      ];

  return {
    steps,
    isLoading: isVendorTabLoading,
    isExistingUser,
  };
};
