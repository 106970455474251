import { useMelioIntl } from '@melio/ar-domain';
import { Form } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { useBusinessDetailsFormContext } from '../../../utils';
import { FormSection } from './FormSection';

export const ContactInformationFormSection = forwardRef(({ ...props }, ref) => {
  const { formatMessage } = useMelioIntl();

  const { registerField } = useBusinessDetailsFormContext();

  return (
    <FormSection
      ref={ref}
      title={formatMessage('ar.onboarding.activities.businessDetails.form.sections.contactInformation.label')}
      data-testid="form-section-contact-information"
      {...props}
      columns={4}
    >
      <Form.TextField
        placeholder={formatMessage('ar.onboarding.activities.businessDetails.form.inputs.businessName.placeHolder')}
        {...registerField('email')}
        colSpan={2}
        labelProps={{
          label: formatMessage('ar.onboarding.activities.businessDetails.contactInformation.form.inputs.email.label'),
        }}
      />
      <Form.PhoneField
        colSpan={2}
        {...registerField('contactNumber')}
        labelProps={{
          label: formatMessage(
            'ar.onboarding.activities.businessDetails.contactInformation.form.inputs.phoneNumber.label'
          ),
        }}
        placeholder={formatMessage(
          'ar.onboarding.activities.businessDetails.contactInformation.form.inputs.phoneNumber.placeHolder'
        )}
      />
      <Form.TextField
        colSpan={4}
        {...registerField('websiteUrl')}
        labelProps={{
          label: formatMessage('ar.onboarding.activities.businessDetails.contactInformation.form.inputs.website.label'),
        }}
        placeholder={formatMessage(
          'ar.onboarding.activities.businessDetails.contactInformation.form.inputs.website.placeHolder'
        )}
      />
    </FormSection>
  );
});

ContactInformationFormSection.displayName = 'ContactInformationFormSection';
