/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { EventProperties, OriginFlow, useAnalytics } from '@melio/platform-analytics';

import { NakedModal } from '@/cl/components/NakedModal/NakedModal.component';
import { usePartnerConfig } from '@/hooks/partners';
import { usePlatformIntl } from '@/translations/Intl';

type MelioTutorialVideoModalProps = {
  isOpen: boolean;
  onModalClose: () => void;
  origin: OriginFlow;
};

export const MelioTutorialVideoModal = ({ isOpen, onModalClose, origin }: MelioTutorialVideoModalProps) => {
  const { track } = useAnalytics();
  const { formatMessage } = usePlatformIntl();
  const { partnerConfig } = usePartnerConfig();
  const tutorialVideoSrc = partnerConfig.config.tutorialVideo;

  const analyticProperties: EventProperties = {
    Origin: origin,
    Cta: 'how-it-works',
    EntryPoint: 'settings-page',
    Flow: 'settings',
    Intent: 'video-how-it-works',
    PageName: 'support-settings',
  };
  React.useEffect(() => {
    if (isOpen) {
      track('HowItWorks', 'Viewed', analyticProperties);
    }
  }, [isOpen]);

  return (
    <NakedModal isOpen={isOpen} onClose={onModalClose} size={'4xl'}>
      <iframe
        data-testid="how-it-works-video-iframe"
        title={formatMessage('widgets.tutorialVideo.title')}
        style={{
          width: '100%',
          aspectRatio: '16/9',
        }}
        src={`${tutorialVideoSrc}?autoplay=1&muted=1`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
        allowFullScreen
      />
    </NakedModal>
  );
};
