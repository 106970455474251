import {
  CreatePaymentParameters,
  PaymentBillDetails,
  PaymentCreationError,
  PaymentCreationResult,
  PaymentCreationSuccess,
  usePayments,
} from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { convertDateTimeToDateString } from '@melio/platform-utils';
import { useState } from 'react';

import { getDefaultMemo } from './BatchPayments.activity.utils';
import { PaymentSchedulingOutcome, SchedulePaymentIntent } from './types';

const getCreatePaymentPayload = (
  extendedPaymentIntent: SchedulePaymentIntent,
  shouldIncludeDeliveryDate: boolean
): CreatePaymentParameters | undefined => {
  const { paymentIntent, vendor } = extendedPaymentIntent;

  const vendorId = vendor.id;

  const {
    fundingSourceId,
    deliveryMethodId,
    noteToVendor,
    effectiveScheduledDate: scheduledDate,
    effectiveDeliveryDate: deliveryDate,
    billPayments,
    selectedDeliveryPreferenceType: deliveryPreference,
    requireWithdrawApproval,
    requireDepositApproval,
    paymentPurpose,
  } = paymentIntent;

  if (!fundingSourceId || !billPayments || !deliveryMethodId || !deliveryPreference || !scheduledDate || !vendorId) {
    return undefined;
  }

  const paymentBillsInfo = billPayments.map(
    (billPayment): PaymentBillDetails => ({
      id: billPayment.billId,
      paymentAmount: billPayment.amount,
    })
  );

  const createPaymentParameters: CreatePaymentParameters = {
    scheduledDate,
    deliveryDate: shouldIncludeDeliveryDate ? deliveryDate?.toISOString() : undefined,
    deliveryMethodId,
    fundingSourceId,
    vendorId,
    noteToVendor: noteToVendor || getDefaultMemo(extendedPaymentIntent),
    deliveryPreference,
    paymentBillsInfo,
    requireWithdrawApproval,
    requireDepositApproval,
    paymentPurpose,
  };

  return createPaymentParameters;
};

export type PaymentSchedulingResult = {
  confirmedPaymentIds: string[];
  failedPaymentIntentIds: string[];
  failedPaymentIntents: SchedulePaymentIntent[];
  containsSuccessfulPaymentsScheduling: boolean;
  allFailed: boolean;
};

const isPaymentCreationSuccess = (item: PaymentCreationResult): item is PaymentCreationSuccess =>
  item.status === 'success';

const isPaymentCreationFailed = (item: PaymentCreationResult): item is PaymentCreationError => item.status === 'error';

const parseSchedulingResults = (paymentSchedulingOutcomes: PaymentSchedulingOutcome[]): PaymentSchedulingResult => {
  const confirmedPaymentIds = paymentSchedulingOutcomes
    .map((item) => item.schedulingResult)
    .filter(isPaymentCreationSuccess)
    .map((result) => result.data.id);

  const failedPaymentIntents = paymentSchedulingOutcomes
    .filter((result) => isPaymentCreationFailed(result.schedulingResult))
    .map((result) => result.scheduledIntent);

  const allFailed =
    paymentSchedulingOutcomes.length != 0 && failedPaymentIntents.length === paymentSchedulingOutcomes.length;

  const failedPaymentIntentIds = failedPaymentIntents.map((intent) => intent.paymentIntent.id);
  return {
    confirmedPaymentIds,
    failedPaymentIntentIds,
    failedPaymentIntents,
    containsSuccessfulPaymentsScheduling: confirmedPaymentIds.length > 0 && !allFailed,
    allFailed,
  };
};

export const useSubmitBatchPayments = () => {
  const [paymentSchedulingResult, setPaymentSchedulingResult] = useState<PaymentSchedulingResult>(
    parseSchedulingResults([])
  );

  const { batchCreate: createBatchPayments } = usePayments({ enabled: false });
  const [addDeliveryDateToCreateAndUpdate] = useFeature<boolean>(
    FeatureFlags.AddDeliveryDateToCreateAndUpdatePayment,
    false
  );
  const createPayments = async ({ paymentIntents }: { paymentIntents?: SchedulePaymentIntent[] }) => {
    if (!paymentIntents) {
      return;
    }

    const isDefined = (value: unknown): value is CreatePaymentParameters => !!value;
    const prepareSchedulingDates = (data: CreatePaymentParameters) =>
      convertDateTimeToDateString(data, ['scheduledDate', 'deliveryDate']);

    const payments = paymentIntents
      .map((paymentIntent) => getCreatePaymentPayload(paymentIntent, addDeliveryDateToCreateAndUpdate))
      .filter(isDefined)
      .map(prepareSchedulingDates);

    const batchCreatedPaymentsResult: PaymentCreationResult[] = await createBatchPayments({ payments });

    const paymentIntentsSchedulingResponses: PaymentSchedulingOutcome[] = paymentIntents.map((paymentIntent, index) => {
      const paymentCreationResult = batchCreatedPaymentsResult[index] || ({} as PaymentCreationResult);

      return {
        scheduledIntent: paymentIntent,
        schedulingResult: paymentCreationResult,
      };
    });

    const paymentSchedulingResult = parseSchedulingResults(paymentIntentsSchedulingResponses);
    setPaymentSchedulingResult(paymentSchedulingResult);
    return { ...paymentSchedulingResult };
  };

  return { createPayments, paymentResults: { ...paymentSchedulingResult } };
};
