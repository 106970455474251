import { EventProperties } from '@melio/platform-analytics';

import { SinglePaymentFlowActivityProps, SinglePaymentFlowActivityStep } from './types';

export const getAdditionalAnalytics = (
  actionToPerform: SinglePaymentFlowActivityProps['actionToPerform'],
  currentStep: SinglePaymentFlowActivityStep,
  analyticsProperties?: EventProperties
) =>
  actionToPerform === 'schedule' &&
  ['DELIVERY_METHOD_SELECTION', 'DELIVERY_DATE_SELECTION', 'REVIEW_AND_CONFIRM'].includes(currentStep)
    ? analyticsProperties
    : {};
