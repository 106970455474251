import { useAnalytics } from '@melio/platform-analytics';
import { useCallback, useEffect } from 'react';

import { SuccessfulQboConnectScreen } from './screens';

export type SuccessfulQboConnectActivityProps = {
  onClick: VoidFunction;
};
export const SuccessfulQboConnectActivity = ({ onClick }: SuccessfulQboConnectActivityProps) => {
  const { track } = useAnalytics();

  const onClickWithAnalytics = useCallback(() => {
    track('SyncAccountingSoftware', 'Click', {
      Cta: 'explore-dashboard',
      PageName: 'connected-to-quickbooks-online',
      Flow: 'onboarding',
      Intent: 'connect-accounting-software',
      AccountingSoftwareType: 'quickbooks-online',
    });
    onClick();
  }, [onClick, track]);

  useEffect(() => {
    track('SyncAccountingSoftware', 'View', {
      PageName: 'connected-to-quickbooks-online',
      Flow: 'onboarding',
      Intent: 'connect-accounting-software',
      AccountingSoftwareType: 'quickbooks-online',
    });
  }, [track]);

  return <SuccessfulQboConnectScreen onClick={onClickWithAnalytics} />;
};
