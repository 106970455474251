import { BankAccountFormModel, MicroDepositsForm } from '@melio/ap-widgets';
import { useFormSubmissionController } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { BankAccount, useDeliveryMethod } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import { useParams } from 'react-router-dom';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';

type AddBankAccountScreenProps = {
  isLoading?: boolean;
  isSaving?: boolean;
  onBack: VoidFunction;
  onClose?: VoidFunction;
  onDone: (data: BankAccountFormModel) => void;
};

export const AddBankAccountScreen = forwardRef<AddBankAccountScreenProps, 'div'>(
  ({ isLoading, isSaving, onBack, onClose, onDone, ...props }, ref) => {
    const { onSubmissionStateChange, cancelButtonProps, submitButtonProps } =
      useFormSubmissionController<BankAccountFormModel>();

    const { formatMessage } = useMelioIntl();
    const { deliveryMethodId: updateDeliveryMethodId } = useParams();

    const { data } = useDeliveryMethod({ id: updateDeliveryMethodId, enabled: false });

    const bankAccount = data?.details as BankAccount;

    useAnalyticsView('VerifyDepositDetails', !isLoading);

    return (
      <NewSinglePaymentStepLayout
        data-component="AddBankAccountMicroDepositsActivity.AddBankAccountScreen"
        data-testid="add-bank-account-micro-deposits-activity-add-bank-account-screen"
        {...props}
        isLoading={isLoading}
        ref={ref}
        headerContent={
          <NewSinglePaymentStepLayout.Header>
            <NewSinglePaymentStepLayout.CloseButton {...cancelButtonProps} onClick={onClose} />
          </NewSinglePaymentStepLayout.Header>
        }
        footerContent={
          <NewSinglePaymentStepLayout.Actions>
            <NewSinglePaymentStepLayout.NextButton
              {...submitButtonProps}
              label={
                updateDeliveryMethodId
                  ? formatMessage('activities.addBankAccountMicroDeposits.screens.addBankAccount.linkBankAccount')
                  : formatMessage('activities.addBankAccountMicroDeposits.screens.addBankAccount.continue')
              }
            />
          </NewSinglePaymentStepLayout.Actions>
        }
      >
        <NewSinglePaymentStepLayout.Title>
          {updateDeliveryMethodId
            ? formatMessage('activities.addBankAccountMicroDeposits.screens.addBankAccount.verifyTitle')
            : formatMessage('activities.addBankAccountMicroDeposits.screens.addBankAccount.title')}
        </NewSinglePaymentStepLayout.Title>
        <NewSinglePaymentStepLayout.Description>
          {formatMessage('activities.addBankAccountMicroDeposits.screens.addBankAccount.description')}
        </NewSinglePaymentStepLayout.Description>
        <NewSinglePaymentStepLayout.Content>
          <MicroDepositsForm
            onSubmit={onDone}
            defaultValues={{
              accountNumber: bankAccount?.accountNumber ?? '',
              routingNumber: bankAccount?.routingNumber ?? '',
            }}
            onSubmissionStateChange={onSubmissionStateChange}
            isSaving={isSaving}
          />
        </NewSinglePaymentStepLayout.Content>
      </NewSinglePaymentStepLayout>
    );
  }
);

AddBankAccountScreen.displayName = 'AddBankAccountMicroDepositsActivity.AddBankAccountScreen';
