import { useMelioIntl } from '@melio/platform-i18n';

import { Plan, Tier } from '../api';
import { useSubscriptionContext } from '../context';
import { useSubscription } from './useSubscription';
import { useSubscriptionGracePeriod } from './useSubscriptionGracePeriod';

const LOYALTY_USERS_PROMO = {
  code: 'LOYALTY25',
  discount: 0.25,
};

const WELCOME_USERS_PROMO = {
  code: 'WELCOME15',
  discount: 0.15,
};

export const useSubscriptionPromotions = () => {
  const subscription = useSubscription();
  const { formatMessage, formatPercents } = useMelioIntl();
  const { isEligibleForGracePeriod, isTodayInGracePeriod } = useSubscriptionGracePeriod();

  const {
    gracePeriod: { isOrgCreatedBeforeGracePeriodStart },
  } = useSubscriptionContext();

  const isOrgCreatedAfterLaunch = !isOrgCreatedBeforeGracePeriodStart;

  const getPlanPromo = (plan: Plan) => {
    if (plan.tier === Tier.MelioPlus || plan.tier === Tier.MelioPro) {
      if (isEligibleForGracePeriod && isTodayInGracePeriod) {
        return LOYALTY_USERS_PROMO;
      }
      if (!subscription && isOrgCreatedAfterLaunch) {
        return WELCOME_USERS_PROMO;
      }
    }

    return;
  };

  const getPromotionBanner = () => {
    if (isEligibleForGracePeriod && isTodayInGracePeriod) {
      return formatMessage('activities.subscription.plans.selection.promotionBanner.existingUser.description', {
        discountPercent: formatPercents(LOYALTY_USERS_PROMO.discount),
      });
    }
    if (!subscription && isOrgCreatedAfterLaunch) {
      return formatMessage('activities.subscription.plans.selection.promotionBanner.newUser.description', {
        discountPercent: formatPercents(WELCOME_USERS_PROMO.discount),
      });
    }

    return;
  };

  return { getPlanPromo, getPromotionBanner };
};
