import { InboxItem, InboxItemsApiGetInboxItemsRequest } from '@melio/javascript-sdk';
import { ApiKeys, GetInboxItemsResponse, InboxItemsApiClient } from '@melio/platform-api-axios-client';

import { usePaginatedCollectionApi, UsePaginatedCollectionOptions } from '../../core/usePaginatedCollectionApi';
import { convertInboxItemCentsToDollars } from './utils';

export type UseInboxItemsProps<TData = InboxItem> = UsePaginatedCollectionOptions<
  Override<GetInboxItemsResponse, { data: TData[] }>
> & {
  params?: InboxItemsApiGetInboxItemsRequest;
};

const defaultExpand = [
  'bill.file',
  'bill.vendor',
  'paymentRequest.vendor',
  'paymentRequest.file',
  'payment.bills',
  'payment.subscriptionOccurrence.billSubscription',
  'payment.vendor',
];
export const useInboxItems = <TData = InboxItem>({ params, ...props }: UseInboxItemsProps<TData>) =>
  usePaginatedCollectionApi(
    [ApiKeys.InboxItemsApi, params],
    async (context) => {
      const { data: dataResult } = await InboxItemsApiClient.getInboxItems(
        params?.expand ?? defaultExpand,
        params?.searchTerm,
        params?.inboxItemTypes,
        params?.sort,
        params?.limit,
        context.pageParam?.cursor ?? params?.cursor
      );

      const data = dataResult.data as InboxItem[];
      const pagination = dataResult.pagination;

      return {
        data: data.map(convertInboxItemCentsToDollars) as TData[],
        pagination,
      };
    },
    props
  );
