import { BillSubscriptionApprovalDecisionStatusEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { FormattedMessage } from '@melio/platform-provider';

import { BillSubscriptionDescriptionPropsOld } from './RecurringPaymentScheduled.types';

export function useBillSubscriptionDescription(billSubscription: BillSubscriptionDescriptionPropsOld) {
  const { formatMessage, formatCurrency, formatDate } = useMelioIntl();

  const formattedScheduledDate = formatDate(billSubscription.scheduledDate, { dateStyle: 'medium' });

  const formattedAmount = formatCurrency(billSubscription.amount, billSubscription.currency);

  const intervalType = formatMessage(
    `activities.recurringPaymentScheduled.screens.recurringPaymentScheduled.intervalType.${billSubscription.intervalType}`
  );

  const description = formatMessage(
    'activities.recurringPaymentScheduled.screens.recurringPaymentScheduled.description',
    {
      amount: formattedAmount,
      vendorName: billSubscription.vendor.name ?? 'your vendor',
      scheduledDate: formattedScheduledDate,
      intervalType,
    }
  );

  return description;
}

export function getBillSubscriptionSecondaryDescription(billSubscription: BillSubscriptionDescriptionPropsOld) {
  if (billSubscription.approvalDecisionStatus === BillSubscriptionApprovalDecisionStatusEnum.Pending) {
    return (
      <FormattedMessage id="activities.recurringPaymentScheduled.screens.recurringPaymentScheduled.secondaryDescription" />
    );
  }

  return null;
}
