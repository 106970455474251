import { Box } from '@chakra-ui/react';
import { CSSObject } from '@emotion/styled';
import { IFrame, LoadingContainer } from '@melio/penny';
import { Card, CardParams } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef, useBoolean } from '@melio/platform-utils';
import { useImperativeHandle, useRef } from 'react';

import { useCardVerificationListener, useTabapayConfig } from './TabaPayIframe.utils';

export type TabaPayIframeProps = {
  onSuccess: (params: CardParams) => void;
  onError?: ErrorFunction;
  width?: CSSObject['width'];
  height?: CSSObject['height'];
  onClose?: VoidFunction;
  onLoad?: VoidFunction;
  cardType?: Card['type'];
};

const runAll =
  (...args: (VoidFunction | undefined)[]) =>
  () =>
    args.forEach((fn) => fn?.());

export const TabaPayIframe = forwardRef<TabaPayIframeProps, 'iframe'>(
  ({ onSuccess, onClose, onError, onLoad, cardType, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const [isLoading, loading] = useBoolean(true);
    const innerRef = useRef<HTMLIFrameElement>(null);

    useImperativeHandle(ref, () => innerRef.current as HTMLIFrameElement);

    const { url, iframeHeight, iframeWidth } = useTabapayConfig(cardType);

    const { isVerifying, hasSpinnerInButton } = useCardVerificationListener({
      onError,
      onSuccess,
      url,
      onClose,
      iframeRef: innerRef,
    });

    const dimensions: CSSObject = {
      width: props.width || iframeWidth,
      height: props.height || iframeHeight,
    };

    // allow forced loading state for storybook
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const isForcedLoading = !!props.forceIsLoading;

    const isPending = isLoading || (!hasSpinnerInButton && isVerifying) || isForcedLoading;

    return (
      <LoadingContainer isLoading={isPending}>
        {/* 
         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore*/}
        <Box __css={dimensions} display="flex" alignItems="center" justifyContent="center" margin="auto">
          <IFrame
            data-testid="tabapay-iframe"
            data-component="TabaPayIframe"
            src={url}
            title={formatMessage('widgets.tabapay.iframeLabel')}
            frameBorder="0"
            scrolling="no"
            width="100%"
            height="100%"
            onLoad={runAll(onLoad, loading.off)}
            {...props}
            ref={innerRef}
          />
        </Box>
      </LoadingContainer>
    );
  }
);
TabaPayIframe.displayName = 'TabaPayIframe';
