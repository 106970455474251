import { useBreakpointValue } from '@chakra-ui/react';
import { Group, Illustration, IllustrationProps } from '@melio/penny';
import { FormattedCurrency } from '@melio/platform-i18n';
import { useMelioIntl } from '@melio/platform-provider';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';

export type RefundProcessingScreenProps = {
  amount: number;
  currency?: string;
  isLoading?: boolean;
  onDone: VoidFunction;
};

export const RefundProcessingScreen = ({ amount, currency, onDone, ...props }: RefundProcessingScreenProps) => {
  const { formatMessage } = useMelioIntl();
  const formattedAmount = <FormattedCurrency amount={amount} currency={currency} />;

  const illustrationSize = useBreakpointValue<IllustrationProps['size']>({ xs: 'small', s: 'small', m: 'large' });

  return (
    <NewSinglePaymentStepLayout
      data-component="RefundBillActivity.RefundProcessingScreen"
      data-testid="refund-bill-activity-refund-processing-screen"
      {...props}
      footerContent={
        <NewSinglePaymentStepLayout.Actions>
          <NewSinglePaymentStepLayout.NextButton
            onClick={onDone}
            data-testid="track-refund-button"
            label={formatMessage('activities.refundBill.screens.refundSent.continue')}
          />
        </NewSinglePaymentStepLayout.Actions>
      }
    >
      <Group justifyContent="center">
        <Illustration type="sent" size={illustrationSize} />
      </Group>
      <NewSinglePaymentStepLayout.Title>
        {formatMessage('activities.refundBill.screens.refundSent.titleFailure')}
      </NewSinglePaymentStepLayout.Title>
      <NewSinglePaymentStepLayout.Description>
        {formatMessage('activities.refundBill.screens.refundSent.descriptionFailure', {
          amount: formattedAmount,
        })}
      </NewSinglePaymentStepLayout.Description>
    </NewSinglePaymentStepLayout>
  );
};

RefundProcessingScreen.displayName = 'RefundBillActivity.RefundProcessingScreen';
