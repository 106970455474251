import { useGetFundingSourceContent } from '@melio/ap-domain';
import { FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';

import { PaymentReviewLineItem } from '../../../../PaymentReviewLineItem';

type RepaymentFromProps = {
  fundingSource: FundingSource;
};

export const RepaymentFrom = ({ fundingSource }: RepaymentFromProps) => {
  const { formatMessage } = useMelioIntl();
  const { settings } = useConfig();

  return (
    <PaymentReviewLineItem
      labelProps={{ label: formatMessage('widgets.reviewAndConfirm.repaymentSection.repaymentFrom.title') }}
      mainLabelProps={{
        label: useGetFundingSourceContent(fundingSource),
      }}
      icon={{ type: settings.icons.fundingSource.bank }}
      contentTestId="repayment-section-repayment-from"
    />
  );
};
