import { Form, useMelioForm } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { FileType, useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';

import { InvoiceAttachmentFormFields, InvoiceAttachmentFormProps, InvoiceAttachmentSchema } from '../../types';

export const InvoiceAttachmentForm = forwardRef<InvoiceAttachmentFormProps, 'form'>(
  ({ onSubmit, onSubmissionStateChange, isSaving, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { fileSizeLimit, fileAllowedFormats } = useConfig().settings;
    const componentPrefix = 'activities.invoiceAttachment.screens.invoiceAttachment.fileUpload';

    const { formProps, registerField, setError, clearErrors, watch } = useMelioForm<InvoiceAttachmentFormFields>({
      isSaving,
      onSubmit,
      schema: InvoiceAttachmentSchema(),
      onSubmissionStateChange,
    });

    const watchedInvoiceFile = watch('invoice');

    useEffect(() => {
      let errorMessage = '';
      if (watchedInvoiceFile) {
        const fileType = watchedInvoiceFile.type?.split('/')[1] as FileType;
        if (fileType && !fileAllowedFormats.includes(fileType)) {
          errorMessage = formatMessage('widgets.billDetails.file.validation.fileFormat');
        } else if (watchedInvoiceFile.size > fileSizeLimit) {
          errorMessage = formatMessage('widgets.billDetails.file.validation.fileSize');
        }
      }

      if (errorMessage) {
        setError('invoice', { message: errorMessage });
      } else {
        clearErrors('invoice');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchedInvoiceFile]);

    return (
      <Form data-component="invoice-attachment.InvoiceAttachmentForm" {...props} {...formProps} ref={ref}>
        <Form.FileUpload
          data-testid="invoice-attachment-field"
          labelProps={{
            label: formatMessage(`${componentPrefix}.title`),
            tooltipProps: {
              label: formatMessage(`${componentPrefix}.tooltip`),
            },
          }}
          placeholder={formatMessage(`${componentPrefix}.placeholder`)}
          acceptTypes={fileAllowedFormats}
          {...registerField('invoice')}
        />
      </Form>
    );
  }
);
InvoiceAttachmentForm.displayName = 'invoice-attachment.InvoiceAttachmentForm';
