import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, IconProps, Slide } from '@chakra-ui/react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Avatar, Container, Divider, Group, IconButton, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useAccount } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';

import { AvatarDropdownMenu } from '@/cl/components/AvatarDropdownMenu/AvatarDropdownMenu.component';
import { IconLabel } from '@/cl/components/IconLabel/IconLabel.component';
import { TabLink } from '@/cl/components/TabLink/TabLink.component';
import { SettingsIcon, SupportIcon } from '@/cl/icons/system';
import { LogoutIcon } from '@/cl/icons/system/logout.icon';
import { useAppTheme } from '@/cl/theme/theme.hooks';
import { useRouter } from '@/hooks/router.hooks';
import { useClearSession } from '@/hooks/session.hooks';
import { useIsMobile as useSmallScreen } from '@/hooks/useIsMobile';
import { appSelectedActiveScreenSelector } from '@/store/app/app.model';
import { ScreensEnum } from '@/store/app/app.types';
import { mobileSideNavState } from '@/store/PayDashboard/PayDashboards.model';
import { usePlatformIntl } from '@/translations/Intl';
import { useZendesk } from '@/utils/zendesk';
import { zIndex } from '../layout.config';
import { LeftLayoutTheme } from './leftLayout.theme';

export type LeftLayoutIconId =
  | 'Companies'
  | 'Clients'
  | 'Team'
  | 'Pay'
  | 'GetPaid'
  | 'Vendors'
  | 'Customers'
  | 'Settings'
  | 'Expenses';

export type LeftLayoutIconLink = {
  id: LeftLayoutIconId;
  Icon: (props: IconProps) => JSX.Element;
  href: string;
  linkText: string;
  isActive?: boolean;
  isExternal?: boolean;
  isHidden?: boolean;
};

export type LeftLayoutProps = {
  links: LeftLayoutIconLink[][];
  userName: string;
  onSupportClicked?: () => void;
};

export const LeftLayout = ({ links, userName, onSupportClicked }: LeftLayoutProps) => {
  const { track } = useAnalytics();
  const navigate = useNavigate();
  const [splitContactsTab] = useFeature(FeatureFlags.SplitContactsTab, true);
  const styles = useAppTheme(LeftLayoutTheme, { splitContactsTab });
  const { formatMessage } = usePlatformIntl();
  const clearSession = useClearSession();
  const { logoutChat } = useZendesk();
  const isSmallScreen = useSmallScreen();
  const activeScreen = useRecoilValue(appSelectedActiveScreenSelector);
  const [shouldShowMobileSideNav, setShouldShowMobileSideNav] = useRecoilState(mobileSideNavState);
  const { goToLogoutSuccessful, generateSettingsProfileTabLink, generateSettingsTabLink } = useRouter();
  const { data: account } = useAccount({ id: 'me' });

  const settingsTabLink: LeftLayoutIconLink = {
    id: 'Settings',
    Icon: SettingsIcon,
    href: isSmallScreen ? generateSettingsTabLink() : generateSettingsProfileTabLink(),
    linkText: formatMessage('app.navigation.settings'),
    isActive: activeScreen === ScreensEnum.settings,
  };

  const onTabLinkClick = (tabId: string) => {
    track('Dashboard', 'Click', { Cta: tabId });
    isSmallScreen && setShouldShowMobileSideNav(false);
  };

  const getLinks = () =>
    links.map((list, groupIndex) => (
      <React.Fragment key={groupIndex}>
        {groupIndex > 0 && groupIndex < links.length && (
          <Box sx={styles['dividerContainer']} key={groupIndex}>
            <Divider />
          </Box>
        )}
        {list
          .filter((link) => !link.isHidden)
          .map((link, index) => (
            <TabLink key={index} link={link} onClick={() => onTabLinkClick(link.id)} />
          ))}
      </React.Fragment>
    ));

  const getMobileLinks = () =>
    links.reverse().map((list, groupIndex) => (
      <Group variant="vertical" key={groupIndex}>
        {list
          .filter((link) => !link.isHidden)
          .map((link, index) => (
            <TabLink key={index} link={link} onClick={() => onTabLinkClick(link.id)} />
          ))}
      </Group>
    ));

  const handleLogoutClick = async () => {
    await clearSession();
    logoutChat();
    goToLogoutSuccessful();
  };

  const dropdownMenuItems = [
    {
      label: formatMessage('widgets.avatarDropdown.profileSettings'),
      onClick: () => {
        navigate(generateSettingsProfileTabLink());
      },
    },
    {
      label: formatMessage('widgets.avatarDropdown.logout'),
      onClick: handleLogoutClick,
    },
  ];

  if (isSmallScreen) {
    return (
      <Slide
        in={shouldShowMobileSideNav}
        direction="left"
        style={{ zIndex: zIndex.sidebar }}
        transition={{ enter: { duration: 0.2 }, exit: { duration: 0.2 } }}
      >
        <Box
          as={'nav'}
          role="navigation"
          aria-label={formatMessage('app.navigation.ariaLabel')}
          data-component={'left-nav'}
          sx={styles['containerMobile']}
        >
          <Box sx={styles['closeMenuMobile']}>
            <IconButton
              icon="close"
              aria-label="Close menu"
              variant="naked"
              size="medium"
              onClick={() => setShouldShowMobileSideNav(false)}
            />
          </Box>
          <Box data-component={'left-nav'} sx={styles['topContainerMobile']}>
            <Group variant="vertical" width="full">
              {getMobileLinks()}
              {onSupportClicked ? (
                <Box
                  onClick={() => {
                    onSupportClicked();
                    setShouldShowMobileSideNav(false);
                  }}
                  sx={styles['supportButton']}
                  data-testid="nav.support"
                >
                  <IconLabel Icon={SupportIcon} label={formatMessage('app.navigation.support')} aria-label="support" />
                </Box>
              ) : null}
            </Group>
          </Box>
          <Box flexGrow={1} />
          <Box sx={styles['bottomContainerMobile']}>
            <Avatar name={userName} />
            <Group variant="vertical" spacing="none">
              <Text textStyle="body2Semi">{userName}</Text>
              <Box textStyle="body4" sx={styles['userEmailMobile']}>
                {account?.user.email}
              </Box>
            </Group>
          </Box>
          <Divider />
          <Container>
            <Box data-testid={`left-layout-menu-logout`} sx={styles['logoutMobile']} onClick={handleLogoutClick}>
              <IconLabel Icon={LogoutIcon} label={formatMessage('widgets.avatarDropdown.logout')} />
            </Box>
          </Container>
        </Box>
      </Slide>
    );
  }

  return (
    <Box
      as="nav"
      aria-label={formatMessage('app.navigation.ariaLabel')}
      role="navigation"
      data-testid="left-nav-container"
      data-component={'left-nav'}
      sx={styles['container']}
    >
      <Box data-component={'left-nav'} sx={styles['topContainer']}>
        {getLinks()}
      </Box>
      <Box sx={styles['bottomContainer']}>
        {splitContactsTab && <TabLink link={settingsTabLink} onClick={() => onTabLinkClick(settingsTabLink.id)} />}
        {onSupportClicked ? (
          <Box
            onClick={onSupportClicked}
            sx={styles['supportButton']}
            data-testid="nav.support"
            title="Support"
            data-tourid="support-button"
          >
            <IconLabel Icon={SupportIcon} label={formatMessage('app.navigation.support')} aria-label="support" />
          </Box>
        ) : null}

        <AvatarDropdownMenu userName={userName} items={dropdownMenuItems} />
      </Box>
    </Box>
  );
};
