import { UseMelioFormResults } from '@melio/penny';
import { ApprovalWorkflowRequest } from '@melio/platform-api';
import React, { useContext } from 'react';

export const FormContext = React.createContext<UseMelioFormResults<ApprovalWorkflowRequest> | null>(null);

export const useFormContext = () => {
  const form = useContext(FormContext);
  if (!form) {
    throw new Error('FormContext is not provided');
  }

  return form;
};
