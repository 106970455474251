import { CardFundingSource } from '@melio/platform-api';
import { useDateUtils } from '@melio/platform-utils';
import { useCallback } from 'react';

export const useCardExpired = () => {
  const { createDate } = useDateUtils();

  const isCardExpired = useCallback(
    (fundingSource: CardFundingSource) => {
      const { expirationMonth: month, expirationYear: year } = fundingSource.details;
      const currMonth = createDate().getMonth() + 1;
      const currYear = Number(createDate().getFullYear().toString());
      return Number(year) < currYear || (Number(year) === currYear && Number(month) < currMonth);
    },
    [createDate]
  );

  return { isCardExpired };
};
