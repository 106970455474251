import { ActionsDropdownMenu, ActionsDropdownMenuItemProps, Card, Container, Group } from '@melio/penny';
import * as React from 'react';

const HEIGHT = '174px';

type Props = {
  'data-testid'?: string;
  onClick?: VoidFunction;
  top: React.ReactNode;
  bottom: React.ReactNode;
  actions?: ActionsDropdownMenuItemProps[];
  isMenuDisabled?: boolean;
};

export const CardLayout = ({ actions = [], top, bottom, onClick, 'data-testid': testId, isMenuDisabled }: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <Container border="regular" style={{ maxHeight: HEIGHT }}>
      <Card data-testid={testId} onClick={onClick}>
        <Container style={{ minHeight: HEIGHT }}>
          <Group width="full" variant="vertical" spacing="s">
            <Group variant="horizontal" width="full" alignItems="center" justifyContent="space-between">
              <Group variant="horizontal" width="full" alignItems="center" justifyContent="flex-start" spacing="s-m">
                {top}
              </Group>
              {actions.length ? (
                <ActionsDropdownMenu
                  isOpen={isOpen}
                  isDisabled={isMenuDisabled}
                  onOpenChange={setIsOpen}
                  isFullWidthTrigger
                  items={actions}
                  size="small"
                />
              ) : null}
            </Group>
            {bottom}
          </Group>
        </Container>
      </Card>
    </Container>
  );
};
