import { useIsMobile } from '@melio/ar-domain';
import { Container } from '@melio/penny';
import { forwardRef, PageTitle } from '@melio/platform-utils';

import { useScreenLabels } from '../utils';

export const FormPanelHeader = forwardRef((_, ref) => {
  const isMobile = useIsMobile();
  const { headerText } = useScreenLabels();

  return !isMobile ? (
    <Container paddingLeft="xl" paddingTop="xxl" data-component={FormPanelHeader.displayName} ref={ref}>
      <PageTitle textStyle="heading1Semi">{headerText}</PageTitle>
    </Container>
  ) : null;
});
FormPanelHeader.displayName = 'FormPanelHeader';
