import { EmptyState, FormattedMessage, TaxRate } from '@melio/ar-domain';
import { Container, Group, NakedButton, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { TaxRatesTable, TaxRatesTableToolbar } from '../components';

export type TaxRatesTableScreenProps = {
  searchTerm?: string;
  setSearchTerm: (value: string) => unknown;
  isLoading?: boolean;
  taxRates: TaxRate[];
  onEdit: (itemId: string) => unknown;
  onDelete: (itemId: string) => unknown;
  onAdd: VoidFunction;
};

export const TaxRatesTableScreen = forwardRef<TaxRatesTableScreenProps>(
  ({ setSearchTerm, searchTerm, taxRates, isLoading, onEdit, onDelete, onAdd, ...props }, ref) => (
    <Group variant="vertical" {...props} ref={ref}>
      <TaxRatesTableToolbar onAdd={onAdd} isLoading={isLoading} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      <TaxRatesTable taxRates={taxRates} onEdit={onEdit} onDelete={onDelete} isLoading={isLoading} />
      {!isLoading && taxRates.length === 0 && (
        <EmptyState
          illustrationType="no-items"
          content={
            <Container textAlign="center">
              <Text textStyle="body4">
                <FormattedMessage id="ar.settings.activities.taxRatesTable.components.taxRatesTable.emptyState.search.text" />
              </Text>
            </Container>
          }
          footer={<NakedButton data-testid="clean-search-button" label="Clear all" onClick={() => setSearchTerm('')} />}
        />
      )}
    </Group>
  )
);
