import { usePaymentFee, useTotalFees } from '@melio/ap-domain';
import { Group, Text } from '@melio/penny';
import { BillingFeeSetting, DeliveryMethod, FundingSource, PaymentFee } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { RecurringPaymentProps } from '../../types';
import { FeesDetails } from './FeesDetails';
import { UnilateralBanner, useHasCatalogFees } from './UnilateralBanner';

export type ReviewAndConfirmFeeSectionProps = {
  paymentFees: PaymentFee[];
  recurringPayment?: RecurringPaymentProps;
  fundingSource?: FundingSource;
  deliveryMethod?: DeliveryMethod;
  orgBillingFeeSettings: BillingFeeSetting[];
};

export const ReviewAndConfirmFeeSection = forwardRef<ReviewAndConfirmFeeSectionProps>(
  ({ paymentFees, orgBillingFeeSettings, fundingSource, deliveryMethod, recurringPayment, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const feesDetails = usePaymentFee(paymentFees);
    const { amount: totalFeesAmount, description: totalFeesDescription } = useTotalFees({
      transactionFees: feesDetails || [],
      orgBillingFeeSettings,
      recurringLastAmountFees: recurringPayment?.lastAmountFees,
    });

    const hasCatalogFees = useHasCatalogFees(fundingSource?.type);

    return (
      <Group
        variant="vertical"
        spacing="s"
        data-component="ReviewAndConfirmFeeSection"
        data-testid="review-and-confirm-fee-section"
        {...props}
        ref={ref}
      >
        <Text textStyle="body4SemiUpper" color="global.neutral.900">
          {formatMessage('widgets.reviewAndConfirm.feesSection.title')}
        </Text>
        {deliveryMethod?.type === 'virtual-account' && fundingSource?.type !== 'card' && hasCatalogFees ? (
          <UnilateralBanner fundingSource={fundingSource} data-testid="unilateral-banner" />
        ) : (
          <Group variant="vertical" spacing="m" hasDivider>
            {feesDetails && (
              <FeesDetails
                total={{
                  amount: totalFeesAmount,
                  description: totalFeesDescription,
                }}
                feeItems={feesDetails}
              />
            )}
          </Group>
        )}
      </Group>
    );
  }
);

ReviewAndConfirmFeeSection.displayName = 'ReviewAndConfirmFeeSection';
