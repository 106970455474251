import { isFXCurrency } from '@melio/ap-domain';
import { TableRowSelectionState } from '@melio/penny';
import { Vendor } from '@melio/platform-api';
import { useCallback, useState } from 'react';

import { VendorTabAmountCellAmounts } from '../types';

export const usePayVendor = ({
  onRowSelectionChange,
}: {
  onRowSelectionChange?: (rowSelectionState: TableRowSelectionState<Vendor>) => void;
}) => {
  const [amounts, setAmounts] = useState<VendorTabAmountCellAmounts>({});

  const onAmountChange = useCallback(
    (vendor: Vendor, value: string) => {
      onRowSelectionChange?.({
        rowId: vendor.id,
        isSelected: !!value && !isFXCurrency(vendor.currency),
        rowData: vendor,
      });

      setAmounts((prevAmounts) => {
        if (!value || +value <= 0) {
          const { [vendor.id]: _, ...newAmounts } = prevAmounts;
          return newAmounts;
        }
        return { ...prevAmounts, [vendor.id]: value };
      });
    },
    [onRowSelectionChange]
  );

  return {
    amounts,
    onAmountChange,
  };
};
