import { createContext, useContext, useState } from 'react';

import { PayDashboardSortingType } from './types';

const Context = createContext<PayDashboardSortingType | null>(null);
export const usePayDashboardSorting = () => useContext(Context);

export const PayDashboardSortingProvider = ({
  children,
  defaultSort,
}: {
  children: React.ReactNode;
  defaultSort?: PayDashboardSortingType['sortingState'];
}) => {
  const [sortedColumn, sortColumn] = useState<PayDashboardSortingType['sortingState']>(defaultSort);

  return (
    <Context.Provider value={{ setSortState: sortColumn, sortingState: sortedColumn }}>{children}</Context.Provider>
  );
};
