import { ModelError } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';
import { useRef } from 'react';

import {
  getErrorTypesByApiError,
  VENDOR_INVALID_ACCOUNT_NUMBER_ERROR_CODE,
  VENDOR_INVALID_ZIP_CODE_ERROR_CODE,
} from '../../apiErrorsUtils';
import { AddVendorFormFields } from './types';

const MAX_ATTEMPTS_WITH_INVALID_ZIP_CODE = 2;
const MAX_ATTEMPTS_WITH_INVALID_ACCOUNT_NUMBER = 2;

type ErrorsCountByErrorType = Record<
  typeof VENDOR_INVALID_ZIP_CODE_ERROR_CODE | typeof VENDOR_INVALID_ACCOUNT_NUMBER_ERROR_CODE,
  number
>;

type SwitchAddVendorFormData = Pick<AddVendorFormFields, 'companyName' | 'accountNumber' | 'postalCode'>;

type Props = {
  onSwitch: (data: SwitchAddVendorFormData) => void;
};

export const useSwitchToUnmanagedForm = ({ onSwitch }: Props) => {
  const initialAttemptsCounts = {
    [VENDOR_INVALID_ZIP_CODE_ERROR_CODE]: 0,
    [VENDOR_INVALID_ACCOUNT_NUMBER_ERROR_CODE]: 0,
  };

  const {
    settings: {
      vendor: {
        createVendor: { shouldSwitchToExtendedFormOnApiErrors },
      },
    },
  } = useConfig();

  const attemptsCountByErrorType = useRef<ErrorsCountByErrorType>(initialAttemptsCounts);

  const increaseAttemptsCountForSpecificCode = (code: keyof typeof initialAttemptsCounts) => {
    attemptsCountByErrorType.current = {
      ...attemptsCountByErrorType.current,
      [code]: attemptsCountByErrorType.current[code] + 1,
    };
  };

  const increaseAttemptsCount = (error: ModelError) => {
    const errorTypesDictionary = getErrorTypesByApiError(error);

    if (errorTypesDictionary[VENDOR_INVALID_ZIP_CODE_ERROR_CODE]) {
      increaseAttemptsCountForSpecificCode(VENDOR_INVALID_ZIP_CODE_ERROR_CODE);
    }
    if (errorTypesDictionary[VENDOR_INVALID_ACCOUNT_NUMBER_ERROR_CODE]) {
      increaseAttemptsCountForSpecificCode(VENDOR_INVALID_ACCOUNT_NUMBER_ERROR_CODE);
    }
  };

  const resetAttemptsCount = () => {
    attemptsCountByErrorType.current = {
      ...initialAttemptsCounts,
    };
  };

  const shouldSwitchToUnmanaged = (error: ModelError) => {
    if (!shouldSwitchToExtendedFormOnApiErrors) {
      return false;
    }

    increaseAttemptsCount(error);

    const zipCodeAttemptsWithErrorCount = attemptsCountByErrorType.current[VENDOR_INVALID_ZIP_CODE_ERROR_CODE];
    const accountNumberAttemptsWithErrorCount =
      attemptsCountByErrorType.current[VENDOR_INVALID_ACCOUNT_NUMBER_ERROR_CODE];

    return (
      zipCodeAttemptsWithErrorCount >= MAX_ATTEMPTS_WITH_INVALID_ZIP_CODE ||
      accountNumberAttemptsWithErrorCount >= MAX_ATTEMPTS_WITH_INVALID_ACCOUNT_NUMBER
    );
  };

  const onSwitchToUnmanaged = (formData: SwitchAddVendorFormData) => {
    onSwitch(formData);
    resetAttemptsCount();
  };

  return {
    shouldSwitchToUnmanaged,
    onSwitchToUnmanaged,
    resetAttemptsCount,
  };
};
