import { Group, SectionBanner, StatusModal, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { isAfter, isBefore, isSameDay, startOfDay } from 'date-fns';

import { DeliveryMethodDetailsDiff } from '../../../../types';

type Props = {
  vendorName: string;
  isOpen: boolean;
  isSubmitting: boolean;
  onClose: VoidFunction;
  onSubmit: () => void;
  deliveryMethodDiff: DeliveryMethodDetailsDiff;
};

export const formatDate = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    timeZone: 'utc',
  };

  return date.toLocaleDateString('en-US', options);
};

const useModalDescription = ({ debitByDate, deliveryByDate }: DeliveryMethodDetailsDiff) => {
  const { formatMessage } = useMelioIntl();

  if (isAfter(deliveryByDate.new, deliveryByDate.old) && isSameDay(debitByDate.new, debitByDate.old)) {
    return formatMessage('activities.paymentFlow.form.content.deliveryMethodChangedModal.sameDebitLaterDelivery', {
      fromDeliveryDate: formatDate(deliveryByDate.old),
      toDeliveryDate: formatDate(deliveryByDate.new),
      debitDate: formatDate(debitByDate.old),
    });
  }

  if (
    isBefore(startOfDay(debitByDate.new), startOfDay(debitByDate.old)) &&
    isSameDay(deliveryByDate.new, deliveryByDate.old)
  ) {
    return formatMessage('activities.paymentFlow.form.content.deliveryMethodChangedModal.earlyDebitSameDelivery', {
      fromDebitDate: formatDate(debitByDate.old),
      toDebitDate: formatDate(debitByDate.new),
      deliveryDate: formatDate(deliveryByDate.old),
    });
  }

  if (
    isBefore(startOfDay(debitByDate.new), startOfDay(debitByDate.old)) &&
    !isSameDay(deliveryByDate.new, deliveryByDate.old)
  ) {
    return formatMessage('activities.paymentFlow.form.content.deliveryMethodChangedModal.earlyDebitDifferentDelivery', {
      fromDebitDate: formatDate(debitByDate.old),
      toDebitDate: formatDate(debitByDate.new),
      fromDeliveryDate: formatDate(deliveryByDate.old),
      toDeliveryDate: formatDate(deliveryByDate.new),
    });
  }

  if (
    isAfter(startOfDay(debitByDate.new), startOfDay(debitByDate.old)) &&
    isSameDay(deliveryByDate.new, deliveryByDate.old)
  ) {
    return formatMessage('activities.paymentFlow.form.content.deliveryMethodChangedModal.laterDebitSameDelivery', {
      fromDebitDate: formatDate(debitByDate.old),
      toDebitDate: formatDate(debitByDate.new),
      deliveryDate: formatDate(deliveryByDate.old),
    });
  }

  return '';
};

const useModalHeader = ({
  deliveryMethodDiff,
  vendorName,
}: {
  deliveryMethodDiff: DeliveryMethodDetailsDiff;
  vendorName: string;
}) => {
  const { formatMessage } = useMelioIntl();
  const { deliveryMethod } = deliveryMethodDiff;

  if (deliveryMethod.old.details.deliveryType === 'check' && deliveryMethod.new.details.deliveryType === 'ach') {
    return formatMessage('activities.paymentFlow.form.content.deliveryMethodChangedModal.checkToAch', {
      vendorName,
    });
  }

  if (deliveryMethod.old.details.deliveryType === 'ach' && deliveryMethod.new.details.deliveryType === 'check') {
    return formatMessage('activities.paymentFlow.form.content.deliveryMethodChangedModal.achToCheck', {
      vendorName,
    });
  }

  return '';
};

export const DeliveryMethodChangedModal = ({
  isOpen,
  onClose,
  onSubmit,
  deliveryMethodDiff,
  vendorName,
  isSubmitting,
}: Props) => {
  const { formatMessage } = useMelioIntl();
  const description = useModalDescription(deliveryMethodDiff);
  const headerDescription = useModalHeader({
    deliveryMethodDiff,
    vendorName,
  });

  return (
    <StatusModal
      data-testid="delivery-method-changed-modal"
      header={formatMessage('activities.paymentFlow.form.content.deliveryMethodChangedModal.header')}
      variant="warning"
      isOpen={isOpen}
      onClose={onClose}
      primaryButton={{
        isLoading: isSubmitting,
        label: formatMessage('activities.paymentFlow.form.content.deliveryMethodChangedModal.confirmChange'),
        onClick: () => onSubmit(),
        variant: 'primary',
      }}
      secondaryButton={{
        isDisabled: isSubmitting,
        label: formatMessage('activities.paymentFlow.form.content.deliveryMethodChangedModal.editPayment'),
        onClick: onClose,
        variant: 'tertiary',
      }}
    >
      <Group variant="vertical">
        <Text>{headerDescription}</Text>
        <SectionBanner hideIcon description={description} variant="warning" />
      </Group>
    </StatusModal>
  );
};
