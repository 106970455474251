/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useAnalytics } from '@melio/platform-analytics';

export const useCopyEmailAnalytics = (viewContext: string) => {
  const { track } = useAnalytics();

  const trackCopyEmailCopy = React.useCallback(() => {
    track(`${viewContext}CopyEmailCopy`, 'Chose');
  }, [viewContext]);

  const trackCopyEmailLink = React.useCallback(() => {
    track(`${viewContext}CopyEmailLink`, 'Chose');
  }, [viewContext]);

  const trackCopyEmailClose = React.useCallback(() => {
    track(`${viewContext}CopyEmailClose`, 'Chose');
  }, [viewContext]);

  return {
    trackCopyEmailCopy,
    trackCopyEmailLink,
    trackCopyEmailClose,
  };
};
