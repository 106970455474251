import { InAppMessageButton, ModalMessage } from '@braze/web-sdk';
import * as brazeSDK from '@braze/web-sdk';

export {
  ControlMessage,
  InAppMessageButton as EngagementModalButton,
  InAppMessage,
  ModalMessage,
} from '@braze/web-sdk';

export type UserEngagement = {
  id?: string;
  contactInfo: {
    contactFirstName?: string;
    contactLastName?: string;
  };
  email: string;
  partnerName: string;
};

export type AccessTokenPayloadType = {
  accountId: string;
  user: UserPayloadType;
  organization?: OrganizationPayloadType;
  partnerName: string;
  iat: number;
  partnerId?: number;
};

export type UserPayloadType = {
  id?: string;
  email: string;
  isGuest?: boolean;
  registrationFlow?: string;
  createdAt: Date;
};

export type OrganizationPayloadType = {
  id: string;
  createOrigin?: string;
  contactFirstName?: string;
  contactLastName?: string;
  companyName?: string;
};

export type EngagementContextType = {
  logEngagementCustomEvent: (eventName: EngagementEventsEnum) => void;
  engagementModalSubscriptionEventHandler: (cb: (inAppMessage?: ModalMessage) => void) => void;
  logEngagementModalImpression: (message?: ModalMessage) => void;
  resetEngagementModal: () => void;
  logEngagementModalClick: (inAppMessage: ModalMessage) => boolean;
  logEngagementModalButtonClick: (button: InAppMessageButton, inAppMessage: ModalMessage) => boolean;
};

export enum ActionHandlerTypeEnum {
  PRIMARY_BUTTON,
  SECONDARY_BUTTON,
}

export type BrazeSDKType = typeof brazeSDK;

export type UseEngagementModalProps = {
  eventName: EngagementEventsEnum;
};

export enum EngagementEventsEnum {
  PAY_DASHBOARD_TOGGLE_BAR_INBOX_VIEWED = 'PayDashboardToggleBarInbox-Viewed',
  ADD_BILL_OPTIONS_VIEWED = 'AddBillOptions-Viewed',
  DASHBOARD_VIEWED = 'Dashboard-Viewed',
}

type ModalButtonProps = {
  label: string;
  onClick?: VoidFunction;
  isDisabled?: boolean;
  isLoading?: boolean;
};

export enum ModalButtonsPropsEnum {
  PRIMARY_BUTTON = 'primaryButton',
  SECONDARY_BUTTON = 'secondaryButton',
  COMBINED_BUTTON = 'combinedButton',
}

export type ModalButtons = {
  [ModalButtonsPropsEnum.PRIMARY_BUTTON]?: ModalButtonProps & {
    variant: 'tertiary' | 'secondary';
  };
  [ModalButtonsPropsEnum.SECONDARY_BUTTON]?: ModalButtonProps & {
    variant: 'primary' | 'critical';
  };
  [ModalButtonsPropsEnum.COMBINED_BUTTON]?:
    | (ModalButtonProps & {
        variant: 'primary' | 'critical';
      })
    | undefined;
};

export type SplitModalMessage = {
  description: string;
  subDescription?: string;
};

export enum ModalMessageSeparator {
  SUB_DESCRIPTION = '__subDescription__',
}

export enum ModalMessageExtrasEnum {
  CLICK_BEHAVIOR_BUTTON_01 = 'clickBehaviorButton01',
  CLICK_BEHAVIOR_BUTTON_02 = 'clickBehaviorButton02',
}

export enum ModalButtonsBehaviorEnum {
  NEW_TAB = 'newTab',
  SAME_TAB = 'sameTab',
}
