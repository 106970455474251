import { BaseMenu } from '@melio/penny';
import type { FundingSource } from '@melio/platform-api';
import { FundingSourceType } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { Dictionary } from 'lodash';
import { Fragment } from 'react';

import { FundingSourceSelectionMenuItem, FundingSourceSelectionMenuItemProps } from './FundingSourceSelectionMenuItem';

export const FundingSourceSectionedOptions = ({
  onSelect,
  selectedFundingSourceId,
  groupedSources,
}: {
  onSelect: (selectFundingSourceId: FundingSource['id']) => void;
  selectedFundingSourceId?: string;
  groupedSources: Dictionary<FundingSourceSelectionMenuItemProps[]>;
}) => {
  const { formatMessage } = useMelioIntl();
  return (
    <>
      {[FundingSourceType.BankAccount, FundingSourceType.FlexAccount, FundingSourceType.Card]
        .filter((type) => (groupedSources[type]?.length ?? 0) > 0)
        .map((type, idx) => (
          <Fragment key={`section-${type}`}>
            {idx != 0 ? <BaseMenu.Divider /> : null}
            <BaseMenu.Section
              label={formatMessage(
                `activities.batchPayments.screens.paymentIntentsTable.fundingSourceSelectCell.section.types.${type}.label`
              )}
              data-testid={`funding-source-selection-section-${type}`}
            >
              {groupedSources[type]?.map((item, idx) => (
                <BaseMenu.SelectionItem
                  key={item.value}
                  isSelected={item.value === selectedFundingSourceId}
                  data-testid={`funding-source-option-${type}-${idx}`}
                  role="option"
                  onClick={() => {
                    onSelect(item.value);
                  }}
                  disabled={item.disabled}
                >
                  <FundingSourceSelectionMenuItem {...item} />
                </BaseMenu.SelectionItem>
              ))}
            </BaseMenu.Section>
          </Fragment>
        ))}
    </>
  );
};
