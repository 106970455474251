import { useEffect } from 'react';
import { LocalStorageKeys } from '@melio/local-storage';

import { removePartnerTokens } from '@/api/utilities';
import { usePartnerLocalStorage } from '@/hooks/partners/usePartnerLocalStorage';
import { useRouter } from '@/hooks/router.hooks';

export const UnauthorizedScreen = () => {
  const { goToAuth, goToSessionExpired } = useRouter();
  const localStorage = usePartnerLocalStorage();

  useEffect(() => {
    const hadAccessToken = !!localStorage.getItem(LocalStorageKeys.accessToken);
    if (hadAccessToken) {
      goToSessionExpired();
    } else {
      goToAuth();
    }
    return removePartnerTokens();
  }, [goToAuth, goToSessionExpired, localStorage]);

  return null;
};
