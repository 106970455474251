import { CollapsibleCard, Group } from '@melio/penny';
import {
  DeliveryPreference,
  FundingSource,
  PaymentIntent,
  RepaymentOption,
  SelectedRepaymentOption,
} from '@melio/platform-api';

import { SelectedRepaymentOptionFormSchema } from '../types';
import { InstallmentPrice } from './InstallmentPrice';
import { NoOfInstallments } from './NoOfInstallments.component';
import { SelectedCardContent } from './SelectedRepaymentOptionContent';

export type RepaymentOptionCardProps = {
  option: RepaymentOption;
  onSelect: (numOfInstallments: number) => void;
  isSelected?: boolean;
  repaymentFundingSources?: FundingSource[];
  paymentIntent?: PaymentIntent;
  selectedRepaymentTerm?: SelectedRepaymentOption;
  onPressContinue: (data: SelectedRepaymentOptionFormSchema) => void;
  selectedDate?: Date;
  setSelectedDate: (date?: Date) => void;
  deliveryPreferenceOption?: DeliveryPreference;
  onAddBankAccount: VoidFunction;
  isLoadingSelectRepaymentTerms?: boolean;
};
export const RepaymentOptionCard: React.VFC<RepaymentOptionCardProps> = ({
  selectedRepaymentTerm,
  option,
  onSelect,
  isSelected,
  repaymentFundingSources,
  paymentIntent,
  onPressContinue,
  setSelectedDate,
  selectedDate,
  deliveryPreferenceOption,
  onAddBankAccount,
  isLoadingSelectRepaymentTerms,
}) => (
  <CollapsibleCard
    isExpanded={isSelected}
    isSelected={isSelected}
    header={
      <Group>
        <Group.Item grow={1}>
          <NoOfInstallments
            noOfInstallments={option.numOfInstallments ?? 0}
            testId={`repayment-terms-${option.numOfInstallments ?? 0}-num-of-installments`}
          />
        </Group.Item>
        <Group.Item shrink={0}>
          <InstallmentPrice
            testId={`repayment-terms-${option.numOfInstallments ?? 0}-installments-price`}
            totalPaymentAmount={option.paymentAmount ?? 0}
            installmentAmount={option.installmentAmount ?? 0}
            feeAmount={option.feeAmount ?? 0}
            feePercent={option.feePercent ?? 0}
          />
        </Group.Item>
      </Group>
    }
    onExpandChange={(isExpended) => {
      if (isExpended) {
        onSelect(option.numOfInstallments ?? 0);
      }
    }}
    data-testid={`repayment-terms-card-${option.numOfInstallments ?? 0}`}
  >
    <SelectedCardContent
      isDisplayed={isSelected}
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
      deliveryPreferenceOption={deliveryPreferenceOption}
      paymentIntent={paymentIntent}
      selectedRepaymentTerm={selectedRepaymentTerm}
      testId={`repayment-terms-${option.numOfInstallments ?? 0}-selected-content`}
      repaymentFundingSources={repaymentFundingSources ?? []}
      onSubmit={onPressContinue}
      onAddBankAccount={onAddBankAccount}
      isLoadingSelectRepaymentTerms={isLoadingSelectRepaymentTerms}
    />
  </CollapsibleCard>
);
