import { Table } from '@melio/penny';
import { Bill, DeliveryMethod, PaymentIntent } from '@melio/platform-api';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';

import { useInvoiceFileWarning } from '../../PaymentIntentsTable.screen.utils';
import { AttachInvoiceWarning } from './components/AttachInvoiceWarning';

type DeliveryMethodSubCellProps = {
  deliveryMethod: DeliveryMethod;
  paymentIntent: PaymentIntent;
  bill: Bill;
  onOpenAttachInvoiceModal?: VoidFunction;
};

export const DeliveryMethodSubCell = forwardRef<DeliveryMethodSubCellProps, 'div'>(
  ({ deliveryMethod, paymentIntent, bill, onOpenAttachInvoiceModal, ...props }, ref) => {
    const invoiceFileWarning = useInvoiceFileWarning({
      bill,
      onOpenAttachInvoiceModal,
      internationalCountryCode:
        deliveryMethod.type === 'international-account'
          ? deliveryMethod.details.identifierDetails.bankCountryCode
          : undefined,
    });

    return (
      <Table.Cell {...props} ref={ref}>
        {invoiceFileWarning.shouldShowWarning && <AttachInvoiceWarning {...invoiceFileWarning} />}
      </Table.Cell>
    );
  }
);

DeliveryMethodSubCell.displayName = 'DeliveryMethodSubCell';
