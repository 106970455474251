import { convertFxAmountToUsdAmount, getDMCurrency, isFXCurrency } from '@melio/ap-domain';
import { Container, Currency, Group, Icon, NakedButton, SectionBanner, Text } from '@melio/penny';
import { ApprovalRequirementStatus, DeliveryMethod, FxQuote } from '@melio/platform-api';
import { MessageKey } from '@melio/platform-i18n';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { FxAmountSection } from './FxAmountSection';

export type ReviewAndConfirmAmountSectionProps = {
  amount: number;
  title?: MessageKey;
  remainingAmount?: number;
  lastAmount?: number;
  onEdit?: VoidFunction;
  isRecurring?: boolean;
  showRemainingAmountHelperText?: boolean;
  showEditAmount?: boolean;
  vendorId: string;
  deliveryMethod?: DeliveryMethod;
  quote?: FxQuote;
  isQuoteFetching?: boolean;
  approvalRequirementStatus?: ApprovalRequirementStatus;
};

export const ReviewAndConfirmAmountSection = forwardRef<ReviewAndConfirmAmountSectionProps>(
  (
    {
      amount,
      title,
      remainingAmount,
      lastAmount,
      onEdit,
      showEditAmount,
      showRemainingAmountHelperText,
      isRecurring,
      vendorId,
      deliveryMethod,
      quote,
      isQuoteFetching,
      approvalRequirementStatus,
      ...props
    },
    ref
  ) => {
    const { formatMessage, formatCurrency } = useMelioIntl();
    const currency = getDMCurrency(deliveryMethod);

    const fxAmountInUsd = quote && convertFxAmountToUsdAmount({ paymentAmount: amount, fxRate: quote, currency });
    const isFxPayment = isFXCurrency(currency);

    return (
      <Group>
        <Group.Item grow={1}>
          <Group variant="vertical" spacing="s" data-component="ReviewAndConfirmAmountSection" {...props} ref={ref}>
            <Text textStyle="body4SemiUpper" color="global.neutral.900">
              {formatMessage(title || 'widgets.reviewAndConfirm.amountSection.title')}
            </Text>
            <Group variant="vertical" spacing="xxs">
              <Group variant="vertical" spacing={approvalRequirementStatus ? 's' : 'xs'}>
                {isRecurring && <Icon type="repeat" />}
                {isFxPayment && !!approvalRequirementStatus && (
                  <SectionBanner
                    data-testid="fx-payment-rate-approval-banner"
                    description={formatMessage('widgets.reviewAndConfirm.amountSection.rate.approval', {
                      amount: quote?.amount,
                      currencySymbol: quote?.foreignCurrency,
                    })}
                    size="small"
                    variant="neutral"
                    icon="info-fill"
                  />
                )}
                <Currency currency={currency} value={amount} data-testid="payment-review-amount" />
                <Group spacing={approvalRequirementStatus ? 'xxs' : 'xs'} variant="vertical">
                  {isFxPayment && (
                    <FxAmountSection
                      fxAmountInUsd={fxAmountInUsd}
                      quote={quote}
                      isQuoteFetching={isQuoteFetching}
                      isApprovalFlow={!!approvalRequirementStatus}
                    />
                  )}
                </Group>
              </Group>
              {isRecurring && lastAmount && (
                <Text textStyle="body4" data-testid="payment-review-remaining-amount" color="global.neutral.800">
                  {formatMessage('widgets.reviewAndConfirm.amountSection.lastAmount', {
                    remainingAmount: formatCurrency(lastAmount),
                  })}
                </Text>
              )}
              {showRemainingAmountHelperText && remainingAmount && (
                <Text textStyle="body4" data-testid="payment-review-remaining-amount">
                  {formatMessage('widgets.reviewAndConfirm.amountSection.helperText', {
                    remainingAmount: formatCurrency(remainingAmount),
                  })}
                </Text>
              )}
            </Group>
          </Group>
        </Group.Item>
        {showEditAmount && !isFxPayment && (
          <Group.Item shrink={0}>
            <Container width="fit-content" alignItems="center" height="full">
              <NakedButton
                variant="secondary"
                onClick={onEdit}
                data-testid="review-and-confirm-amount-section-edit-button"
                aria-label="Edit item"
                label={formatMessage('widgets.reviewAndConfirm.amountSection.edit')}
              />
            </Container>
          </Group.Item>
        )}
      </Group>
    );
  }
);

ReviewAndConfirmAmountSection.displayName = 'ReviewAndConfirmAmountSection';
