import { ARCustomer, InvoiceExpandedSummary, InvoiceTimeline, useMelioIntl } from '@melio/ar-domain';
import { Drawer, LoadingContainer } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';

import { InvoiceDrawerActionsHandlers } from '../../../utils';
import { DrawerFooter, DrawerHeader, InvoiceSummary } from '../components';

export type InvoiceDrawersActivityProps = {
  customer?: ARCustomer;
  expandedInvoice?: InvoiceExpandedSummary;
  invoiceTimeline?: InvoiceTimeline;
  isOpen: boolean;
  isLoading: boolean;
  onCloseButtonClick: () => void;
  onClose: VoidFunction;
  invoiceActions: InvoiceDrawerActionsHandlers;
};

const LOADING_CONTAINER_ID = 'invoice-drawer-screen';

export const InvoiceDrawerScreen = forwardRef<InvoiceDrawersActivityProps>(
  (
    {
      invoiceActions,
      onCloseButtonClick,
      expandedInvoice,
      customer,
      invoiceTimeline,
      isLoading,
      isOpen,
      onClose: onCloseComplete,
    },
    ref
  ) => {
    const { track } = useAnalytics();
    useEffect(() => {
      if (isOpen && !isLoading) {
        track('Invoice', 'View', {
          ProductName: 'ar',
          PageName: 'dashboard-drawer',
          Flow: 'manage-invoice',
          Intent: 'invoice-drawer',
        });
      }
    }, [isLoading, isOpen, track]);

    const isLoadingDrawer = isLoading || !(expandedInvoice && customer && invoiceTimeline);
    const { formatMessage } = useMelioIntl();

    return (
      <Drawer
        isOpen={isOpen}
        onClose={onCloseButtonClick}
        onCloseComplete={onCloseComplete}
        body={
          <LoadingContainer isLoading={isLoadingDrawer} id={LOADING_CONTAINER_ID}>
            {!isLoadingDrawer && (
              <InvoiceSummary
                expandedInvoice={expandedInvoice}
                invoiceTimeline={invoiceTimeline}
                companyName={customer.companyName || ''}
                invoiceActions={invoiceActions}
              />
            )}
          </LoadingContainer>
        }
        header={<DrawerHeader />}
        footer={
          !isLoadingDrawer ? <DrawerFooter expandedInvoice={expandedInvoice} invoiceActions={invoiceActions} /> : null
        }
        // When the drawer is loading the aria label will come from the loading container,
        // and when loading is complete the aria label will come from the close button.`
        closeButtonAriaLabelledBy={isLoadingDrawer ? LOADING_CONTAINER_ID : undefined}
        closeButtonAriaLabel={formatMessage('ar.dashboard.activities.InvoiceDrawer.body.closeButton.ariaLabel', {
          loadState: !isLoadingDrawer
            ? formatMessage('ar.dashboard.activities.InvoiceDrawer.body.closeButton.loadState')
            : '',
        })}
        data-testid="invoice-drawer-screen"
        ref={ref}
      />
    );
  }
);
InvoiceDrawerScreen.displayName = 'InvoiceDrawerScreen';
