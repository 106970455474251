import { ComponentProps, VFC } from 'react';
import { useParams } from 'react-router-dom';

import { AnyComponent } from './types';

export function withRouteId<T extends AnyComponent, TPropName extends keyof ComponentProps<T>>(
  /**
   * The component to wrap.
   */
  Comp: T,
  /**
   * The property name on the component to assign the route "id" parameter to.
   * e.g. 'invoiceId' for <Comp invoiceId="123" />
   */
  propName: TPropName,
  /**
   * The path parameter name to use (e.g. 'id' for '/invoices/:id').
   * Defaults to 'id'.
   */
  pathParam = 'id'
) {
  const Target: VFC<Omit<ComponentProps<T>, TPropName>> = (props) => {
    const params = useParams();
    const targetProps = { ...props, ...{ [propName]: params[pathParam] }, key: params[pathParam] } as ComponentProps<T>;
    return <Comp {...targetProps} />;
  };
  Target.displayName = `withRouteId(${Comp.displayName ?? Comp.name})`;
  return Target;
}
