import { Button, Container, Form, Group, Link, SectionBanner } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { Plan } from '@melio/subscriptions';
import React from 'react';

import { FIRM_DISCOUNT_PERCENTAGE } from '../../../../consts';
import { useFocusStepOnError } from '../../../../hooks/useFocusStepOnError';
import { FirmClientSubscriptionPlanSelectionActivity } from '../../../FirmClientSubscriptionPlanSelection';
import { NewFirmClientFormFields, StepFormProps } from '../../types';
import { useNewFirmClientFormFieldsFormContext } from '../NewClientForm/NewFirmClientFormFieldsFormContext';

const stepFields: Array<keyof NewFirmClientFormFields> = ['subscriptionPlanId'];

export const NewClientPlanSelectionStep: React.FC<StepFormProps> = ({
  submitLabel,
  focusErrorStep,
  onContinue,
  isSaving,
}) => {
  const { formState, setValue, watch, registerField, submitButtonProps } = useNewFirmClientFormFieldsFormContext();
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();

  useFocusStepOnError<NewFirmClientFormFields>({
    formState,
    stepFields,
    focusErrorStep,
  });

  const validationErrorMessage = formState.errors.subscriptionPlanId?.message;

  const verifyPlanSelection = () => {
    onContinue();
  };

  const handleSelectSubscriptionPlanId = ({ id: planId, tier }: Plan) => {
    track('Organization', 'Click', {
      PageName: 'plan-selection',
      Flow: 'subscription-accountants',
      Cta: 'subscribe',
      Intent: 'subscribe',
      PlanChosenId: planId,
      PlanChosen: tier,
      BillingCycle: 'monthly',
    });
    setValue('subscriptionPlanId', planId);
  };

  const formattedDiscount = String(FIRM_DISCOUNT_PERCENTAGE);
  return (
    <Group spacing="m" variant="vertical" width="full">
      <Form.TextField isRequired type="hidden" {...registerField('subscriptionPlanId')} isHidden />
      {validationErrorMessage && (
        <SectionBanner
          data-testid="plan-selection-validation-error"
          description={validationErrorMessage}
          variant="critical"
        />
      )}
      <SectionBanner
        title={formatMessage('activities.accountants.newClient.form.plan.discount.banner.title', {
          discount: formattedDiscount,
        })}
        description={formatMessage('activities.accountants.newClient.form.plan.discount.banner.description', {
          discount: formattedDiscount,
        })}
        variant="brand"
        icon="discount"
      />
      <FirmClientSubscriptionPlanSelectionActivity
        selectedSubscriptionPlanId={watch('subscriptionPlanId')}
        onSelect={handleSelectSubscriptionPlanId}
        isSaving={isSaving}
        discountPercentage={FIRM_DISCOUNT_PERCENTAGE}
      />
      <Link
        label={formatMessage('activities.accountants.newClient.form.plan.learn-more')}
        href="#"
        color="secondary"
        size="medium"
      />
      <Container justifyContent="flex-end">
        <Button
          variant="primary"
          size="medium"
          label={submitLabel}
          {...submitButtonProps}
          onClick={verifyPlanSelection}
        />
      </Container>
    </Group>
  );
};
