import { Checkbox, Container, Group, Link, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';

type TaxPayerInfoCheckboxProps = {
  isContractor?: boolean;
  isUpdating?: boolean;
  onChange: () => void;
};

export const TaxPayerInfoCheckbox = ({ isContractor, onChange, isUpdating }: TaxPayerInfoCheckboxProps) => {
  const { formatMessage } = useMelioIntl();
  const {
    settings: { contractorLearnMoreLink },
  } = useConfig();

  return (
    <Container overflow="initial">
      <Group spacing="xs" variant="vertical">
        <Group spacing="xs" alignItems="center">
          <Checkbox
            label={formatMessage('widgets.taxPayerInfo.header.checkboxLabel')}
            isDisabled={isUpdating}
            data-testid="taxpayer-info-contractor-checkbox"
            isChecked={isContractor}
            onChange={onChange}
          />
        </Group>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Text pl="1.8rem" textStyle="body3" color="global.neutral.800">
          {formatMessage('widgets.taxPayerInfo.header.caption', {
            learnMoreLink: (
              <Text textStyle="body3" color="global.brand.700">
                <Link
                  variant="inline"
                  href={contractorLearnMoreLink}
                  label={formatMessage('widgets.taxPayerInfo.header.caption.link')}
                  newTab
                />
              </Text>
            ),
          })}
        </Text>
      </Group>
    </Container>
  );
};

TaxPayerInfoCheckbox.displayName = 'TaxPayerInfoCheckbox';
