import { Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePermissions } from '@melio/platform-permissions';

export const usePaymentNotPermittedText = (payment: Payment) => {
  const { formatMessage } = useMelioIntl();
  const { can } = usePermissions();

  const subjectData = {
    createdById: payment.createdById,
    fundingSourceId: payment.fundingSourceId,
    vendor: {
      createdById: payment.vendor?.createdById,
      managedBy: payment.vendor?.managedBy,
    },
    payment: {
      origin: payment.origin,
    },
  };

  const canUpdate = can({
    subject: 'payment',
    action: 'update',
    subjectData,
  });

  const canDelete = can({
    subject: 'payment',
    action: 'delete',
    subjectData,
  });

  const isPaymentNotPermitted = !canUpdate && !canDelete && payment.origin === 'fiserv';

  if (isPaymentNotPermitted) {
    return formatMessage('widgets.paymentDetails.description.notPermitted.unmanaged', {
      fullName: [payment.createdBy?.firstName, payment.createdBy?.lastName].join(' ').trim(),
    });
  }

  return null;
};
