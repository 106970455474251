import { apiClient, FeesBreakdown, FeesBreakdownRequest } from '@melio/javascript-sdk';

import { useModelApi, UseModelApiProps } from '../../core';
import { convertFeesBreakdownCentsToDollars, convertFeesBreakdownRequestCentsToDollars } from './utils';

type UseFeesBreakdownProps = UseModelApiProps<FeesBreakdown> & {
  params: FeesBreakdownRequest;
};

export const useFeesBreakdown = ({ params, ...props }: UseFeesBreakdownProps) => {
  const client = apiClient.feesBreakdown();

  const query = useModelApi<FeesBreakdown>({
    ...props,
    queryKey: ['fees-breakdown', params],
    queryFn: () => client.getFeesBreakdown(convertFeesBreakdownRequestCentsToDollars(params)),
    select: convertFeesBreakdownCentsToDollars,
    id: 'fees-breakdown',
  });

  return {
    ...query,
  };
};
