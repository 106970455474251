import { OrganizationExpanded } from '@melio/platform-api/src/entities-v2/organizations/types';
import { usePermissions } from '@melio/platform-permissions';

export const useCanSwitch = () => {
  const { can } = usePermissions();
  const canSwitch = (organization?: OrganizationExpanded | null) => {
    if (!organization) {
      return false;
    }
    return can({
      subject: 'auth:switchOrganization',
      action: 'create',
      subjectData: { permission: organization.permissionLevel },
    });
  };
  return { canSwitch };
};
