import { ReviewAndConfirmAmountSection } from '@melio/ap-widgets';
import { Card, Container, Group } from '@melio/penny';
import { DeliveryMethod, FundingSource } from '@melio/platform-api';

import { VoidDetailsSection } from './VoidDetailsSection';

export type VoidReviewAndConfirmContentProps = {
  amount: number;
  vendorId: string;
  tracingNumber?: number;
  fundingSource: FundingSource;
  deliveryMethod?: DeliveryMethod;
  maxEstimatedRefundDate: Date;
};

export const VoidReviewAndConfirmContent: React.FC<VoidReviewAndConfirmContentProps> = ({
  amount,
  vendorId,
  tracingNumber,
  fundingSource,
  deliveryMethod,
  maxEstimatedRefundDate,
  ...props
}) => (
  <Card paddingX="none" paddingY="none" data-component="ConfirmVoidAndRefund" width="full" {...props}>
    <Container>
      <Group variant="vertical" spacing="none" hasDivider>
        <Container paddingX="m" paddingY="m">
          <ReviewAndConfirmAmountSection
            amount={amount}
            title="activities.voidAndRefund.screens.confirmVoidAndRefund.refundAmount.label"
            vendorId={vendorId}
            deliveryMethod={deliveryMethod}
          />
        </Container>
        <Container paddingX="m" paddingY="m">
          <VoidDetailsSection
            tracingNumber={tracingNumber}
            fundingSource={fundingSource}
            maxEstimatedRefundDate={maxEstimatedRefundDate}
          />
        </Container>
      </Group>
    </Container>
  </Card>
);

VoidReviewAndConfirmContent.displayName = 'VoidReviewAndConfirmContent';
