import { AccountingPlatformSyncConnectionErrorEnum } from '@melio/ap-activities';
import { getAccountingPlatformNameForAnalytics } from '@melio/ap-domain';
import { useAccountingPlatformName } from '@melio/ap-widgets';
import { BrandSymbol, BrandSymbolKey, Link } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useAccount } from '@melio/platform-api';
import { AccountingPlatform, AccountingPlatformConnectionStatus, AccountingPlatformSlug } from '@melio/platform-api';

import { AccountingPlatformCard as AccountingPlatformCardCl } from '@/cl/components/AccountingPlatformCard/AccountingPlatformCard.component';
import { useFormatSyncDate } from '@/hooks/date.hooks';
import { usePartnerConfig } from '@/hooks/partners';
import { usePlatformIntl } from '@/translations/Intl';
import { AccountingPlatformConnectedCard } from './AccountingPlatformConnectedCard.widget';
import { AccountingPlatformErrorCard } from './AccountingPlatformErrorCard.widget';
import { AccountingPlatformUnlinkedCard } from './AccountingPlatformUnlinkedCard.widget';

type Props = {
  isLoading: boolean;
  syncError?: AccountingPlatformSyncConnectionErrorEnum;
  accountingPlatform: AccountingPlatform;
  onConnectClicked: () => void;
  onDisconnectClicked: () => Promise<void>;
  dialogWillBeShown?: boolean;
};

export const AccountingPlatformCard = ({
  isLoading,
  syncError,
  accountingPlatform,
  onConnectClicked,
  onDisconnectClicked,
  dialogWillBeShown,
}: Props) => {
  const { formatMessage } = usePlatformIntl();
  const { track } = useAnalytics();
  const { data: account } = useAccount({ id: 'me' });
  const { formatDate } = useFormatSyncDate();
  const {
    id,
    accountingSlug,
    lastCompletionTime,
    accountingCompanyName,
    connectionStatus: connectionStatusAccountingPlatform,
  } = accountingPlatform;
  const userName = account ? `${account.user.firstName} ${account.user.lastName}` : '';
  const companyName = account?.company?.name;
  const accountingPlatformName = useAccountingPlatformName(accountingSlug);
  const accountingPlatformNameForAnalytics = getAccountingPlatformNameForAnalytics(accountingSlug);
  const { partnerConfig } = usePartnerConfig();

  const handleConnectClicked = () => {
    track('Settings', 'Click', {
      Flow: 'settings',
      Intent: 'sync',
      PageName: 'accounting-software-sync',
      EntryPoint: 'settings-page',
      Cta: `${accountingPlatformNameForAnalytics}-connect`,
    });
    return onConnectClicked();
  };

  const handleReConnectClicked = () => {
    track('Settings', 'Click', {
      Flow: 'settings',
      Intent: 'sync',
      PageName: 'accounting-software-sync',
      EntryPoint: 'settings-page',
      Cta: `${accountingPlatformNameForAnalytics}-reconnect`,
    });
    return onConnectClicked();
  };

  const handleDisconnectClicked = () => {
    track('SyncAccountingSoftware', 'Click', {
      Flow: 'settings',
      Intent: 'disconnect-accounting-software',
      PageName: `disconnect-${accountingPlatformNameForAnalytics}-sync`,
      EntryPoint: 'settings-page',
      Cta: `${accountingPlatformNameForAnalytics}-disconnect`,
      IsConnectedToAccountingPlatform: true,
    });
    return onDisconnectClicked();
  };

  const renderCardByType = () => {
    let connectionStatus = connectionStatusAccountingPlatform;
    switch (syncError) {
      case AccountingPlatformSyncConnectionErrorEnum.AccessDenied:
        connectionStatus = AccountingPlatformConnectionStatus.InvalidCredentials;
        break;
      case AccountingPlatformSyncConnectionErrorEnum.GeneralError:
      case AccountingPlatformSyncConnectionErrorEnum.InvalidState:
        connectionStatus = AccountingPlatformConnectionStatus.Error;
        break;

      default:
        break;
    }

    const getLogoBySlug = () => {
      const type = {
        [AccountingPlatformSlug.QuickBooksOnline]: 'quickbooks',
        [AccountingPlatformSlug.QuickBooksDesktop]: 'quickbooks',
        [AccountingPlatformSlug.Xero]: 'xero-logo',
      }[accountingSlug as string] as BrandSymbolKey;

      if (!type) {
        return null;
      }

      return <BrandSymbol type={type} size="extra-large" aria-hidden />;
    };

    const logo = getLogoBySlug();

    const errorAccountDifferentMessage =
      syncError === AccountingPlatformSyncConnectionErrorEnum.AccountPlatformConnectToDifferentMelioAccount
        ? formatMessage(
            'widgets.accountingPlatform.card.type.error.type.accountPlatformConnectToDifferentMelioAccount.alert',
            {
              accountingPlatformName,
              support: (
                <Link
                  href={`mailto:${partnerConfig.supportEmail}`}
                  label={formatMessage(`widgets.accountingPlatform.errorMessage.links.support`)}
                  newTab
                />
              ),
            },
          )
        : undefined;
    switch (connectionStatus) {
      case AccountingPlatformConnectionStatus.Connected: {
        return (
          <AccountingPlatformConnectedCard
            organizationId={account?.organizationId}
            accountingPlatformId={id}
            isLoading={isLoading}
            logo={logo}
            onDisconnectClicked={handleDisconnectClicked}
            dialogWillBeShown={dialogWillBeShown}
            errorText={errorAccountDifferentMessage}
            title={formatMessage(`widgets.accountingPlatform.card.type.connected.title`, {
              accountingPlatformName,
            })}
            descriptionList={[
              formatMessage(`widgets.accountingPlatform.card.type.connected.description`, {
                accountingPlatformName,
                accountingCompanyName: accountingCompanyName || '',
                VendorPaymentsExperience: userName,
              }),
            ]}
            lastCompletionTime={lastCompletionTime}
            accountingPlatformSlug={accountingSlug}
          />
        );
      }
      case AccountingPlatformConnectionStatus.Unlinked: {
        return (
          <AccountingPlatformUnlinkedCard
            logo={logo}
            syncDate={formatDate(lastCompletionTime || undefined)}
            errorText={errorAccountDifferentMessage}
            isLoading={isLoading}
            companyName={companyName}
            accountingPlatformName={accountingPlatformName}
            accountingPlatformSlug={accountingSlug}
            dialogWillBeShown={dialogWillBeShown}
            onReconnectedClicked={handleReConnectClicked}
          />
        );
      }
      case AccountingPlatformConnectionStatus.NotEligibleToSyncPay:
      case AccountingPlatformConnectionStatus.InvalidCredentials:
      case AccountingPlatformConnectionStatus.Error: {
        return (
          <AccountingPlatformErrorCard
            name={accountingPlatformName}
            logo={logo}
            isLoading={isLoading}
            accountingPlatformId={id}
            organizationId={account?.organizationId}
            dialogWillBeShown={dialogWillBeShown}
            accountingPlatformSlug={accountingSlug}
            onConnectedClicked={handleConnectClicked}
            onDisconnectClicked={handleDisconnectClicked}
          />
        );
      }
      case AccountingPlatformConnectionStatus.InvalidSubscription: {
        return (
          <AccountingPlatformConnectedCard
            accountingPlatformId={id}
            organizationId={account?.organizationId}
            isLoading={isLoading}
            logo={logo}
            onDisconnectClicked={handleDisconnectClicked}
            dialogWillBeShown={dialogWillBeShown}
            title={accountingPlatformName}
            descriptionList={[
              formatMessage(`widgets.accountingPlatform.card.type.error.type.invalidSubscription.description`, {
                accountingPlatformName,
              }),
              formatMessage(`widgets.accountingPlatform.card.type.error.type.invalidSubscription.description2`, {
                accountingPlatformName,
              }),
            ]}
            errorText={formatMessage(`widgets.accountingPlatform.card.type.error.type.invalidSubscription.alert`, {
              accountingPlatformName,
            })}
            lastCompletionTime={lastCompletionTime}
            accountingPlatformSlug={accountingSlug}
          />
        );
      }

      case AccountingPlatformConnectionStatus.Disconnected:
      default: {
        const buttons = [
          {
            text: formatMessage('widgets.accountingPlatform.card.buttons.connect'),
            onClicked: handleConnectClicked,
            isLoading,
            dataTestId: `accounting-platform-${accountingSlug}-connect-card-button`,
            ariaHasPopup: dialogWillBeShown && ('dialog' as const),
          },
        ];
        return (
          <AccountingPlatformCardCl
            title={accountingPlatformName}
            titleAs="h4"
            descriptionList={[
              formatMessage(`widgets.accountingPlatform.card.type.initial.description`, {
                accountingPlatformName,
              }),
            ]}
            buttons={buttons}
            logo={logo}
            errorText={errorAccountDifferentMessage}
            accountingPlatformSlug={accountingSlug}
          />
        );
      }
    }
  };

  return renderCardByType();
};
