import { useInvoiceDiscount, useMelioIntl } from '@melio/ar-domain';
import { useToast } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { pick } from 'lodash';

import { DiscountFormDialogScreen, DiscountFormFields } from './screens';

type EditDiscountDialogActivityProps = {
  discountId: string;
  onDone: (id: string) => unknown;
  onClose: VoidFunction;
  onError?: ARErrorFunction;
};

export const EditDiscountDialogActivity = forwardRef<EditDiscountDialogActivityProps>(
  ({ onDone: onSuccess, discountId, onClose, onError, ...props }, ref) => {
    const { data: InvoiceDiscount, update, isMutating, isLoading } = useInvoiceDiscount({ id: discountId, onError });

    const { formatMessage } = useMelioIntl();
    const { toast } = useToast();

    const onSubmit = (values: DiscountFormFields) =>
      update(values)
        .then((updatedDiscount) => {
          onSuccess(updatedDiscount.id);
          toast({
            type: 'success',
            title: formatMessage('ar.settings.activities.addDiscount.toast.onEdit.label', {
              name: updatedDiscount.name,
            }),
          });
        })
        .catch(onError);

    return (
      <DiscountFormDialogScreen
        data-testid="edit-discount-dialog"
        isOpen
        variant="edit"
        defaultValues={pick(InvoiceDiscount, ['name', 'type', 'value'])}
        isSaving={isMutating}
        isLoading={isLoading}
        onClose={onClose}
        onSubmit={onSubmit}
        {...props}
        ref={ref}
      />
    );
  }
);
EditDiscountDialogActivity.displayName = 'EditDiscountDialogActivity';
