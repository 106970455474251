import React from 'react';
import { object, SchemaOf, string } from 'yup';
import { masks, useMtlMessages, useMtlSchemaValidations } from '@melio/ap-domain';
import { FormWidgetProps } from '@melio/ap-widgets';
import { Form, useMelioForm } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export type ProfileFormDetails = {
  email?: string;
  firstName?: string;
  lastName?: string;
  mobileNumber?: string;
  dateOfBirth?: string;
  role?: string;
};

const useSchema = ({
  mtlKycUpliftEnabled,
  hasMobileNumber,
  hasDateOfBirth,
}: {
  mtlKycUpliftEnabled: boolean;
  hasMobileNumber: boolean;
  hasDateOfBirth: boolean;
}): SchemaOf<ProfileFormDetails> => {
  const { name, dateOfBirth, phoneNumber } = useMtlSchemaValidations();

  return object().shape({
    email: string().email().required(),
    firstName: name('firstName'),
    lastName: name('lastName'),
    mobileNumber: mtlKycUpliftEnabled && hasMobileNumber ? phoneNumber() : string(),
    dateOfBirth: mtlKycUpliftEnabled && hasDateOfBirth ? dateOfBirth() : string(),
    role: string().trim().required(),
  });
};

type ProfileFormProps = FormWidgetProps<ProfileFormDetails> & {
  isEditable?: boolean;
  hideRole?: boolean;
  mtlKycUpliftEnabled: boolean;
};

export const ProfileForm = forwardRef<ProfileFormProps, 'form'>(
  (
    { defaultValues, isSaving, onSubmit, onSubmissionStateChange, isEditable, hideRole, mtlKycUpliftEnabled, ...props },
    ref,
  ) => {
    const {
      labels: { user: userLabels },
      placeholders,
      viewModePlaceholders,
    } = useMtlMessages();
    const { formProps, registerField } = useMelioForm<ProfileFormDetails>({
      onSubmit,
      schema: useSchema({
        mtlKycUpliftEnabled,
        hasMobileNumber: !!defaultValues?.mobileNumber,
        hasDateOfBirth: !!defaultValues?.dateOfBirth,
      }),
      isSaving,
      defaultValues,
      mode: 'onChange',
      onSubmissionStateChange,
    });

    return (
      <Form data-component="ProfileForm" size={'small'} {...props} {...formProps} ref={ref}>
        <Form.TextField
          type="text"
          labelProps={{ label: userLabels.email }}
          {...registerField('email')}
          onFocus={(e) => e.target.select()}
          isViewMode={!isEditable}
          isDisabled={isEditable}
        />
        <Form.TextField
          type="text"
          labelProps={{ label: userLabels.firstName }}
          {...registerField('firstName')}
          onFocus={(e) => e.target.select()}
          isViewMode={!isEditable}
          placeholder={placeholders.firstName}
          viewModePlaceholder={viewModePlaceholders.firstName}
        />
        <Form.TextField
          type="text"
          labelProps={{ label: userLabels.lastName }}
          {...registerField('lastName')}
          onFocus={(e) => e.target.select()}
          isViewMode={!isEditable}
          placeholder={placeholders.lastName}
          viewModePlaceholder={viewModePlaceholders.lastName}
        />
        <Form.PhoneField
          {...registerField('mobileNumber')}
          type="text"
          isHidden={!mtlKycUpliftEnabled}
          labelProps={{ label: userLabels.mobileNumber }}
          onFocus={(e) => e.target.select()}
          isViewMode={!isEditable}
          placeholder={placeholders.phoneNumber}
          viewModePlaceholder={viewModePlaceholders.phoneNumber}
        />
        <Form.TextField
          {...registerField('dateOfBirth')}
          type="text"
          isHidden={!mtlKycUpliftEnabled}
          labelProps={{ label: userLabels.dateOfBirth }}
          onFocus={(e) => e.target.select()}
          isViewMode={!isEditable}
          placeholder={placeholders.dateOfBirth}
          viewModePlaceholder={viewModePlaceholders.dateOfBirth}
          maskProps={{
            mask: masks.dateOfBirth,
            keepCharPositions: false,
            guide: true,
            placeholderChar: ' ',
          }}
        />
        {!hideRole ? (
          <Form.TextField
            type="text"
            labelProps={{ label: userLabels.role }}
            {...registerField('role')}
            onFocus={(e) => e.target.select()}
            isViewMode={!isEditable}
            isDisabled={isEditable}
          />
        ) : null}
      </Form>
    );
  },
);

ProfileForm.displayName = 'ProfileForm';
