/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { FieldError, FieldValues, FormState } from 'react-hook-form';

import { EventProperties } from '../types';
import { useAnalytics } from './useAnalytics';

export const useTrackAnalyticsOnFailedFormSubmit = <T extends FieldValues>(
  formState: FormState<T>,
  analyticsName: string,
  analyticsAction: string,
  analyticsData?: EventProperties
) => {
  const { track } = useAnalytics();
  useEffect(() => {
    if (formState.isValid || formState.submitCount == 0) {
      return;
    }

    const errorFields = formState.errors && Object.keys(formState.errors);
    const errorMessages =
      formState.errors &&
      Object.values(formState.errors)
        .map((field) => field as FieldError)
        .map((field) => field.message);

    const extraData = {
      StatusType: 'Failure',
      retryNumber: formState.submitCount,
      ...(errorFields && errorFields.length > 0 && { errorFields }),
      ...(errorMessages && errorMessages.length > 0 && { errorMessages }),
      ...analyticsData,
    };

    track(analyticsName, analyticsAction, extraData);
  }, [formState.isSubmitted, formState.submitCount]);
};
