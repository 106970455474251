import { datadogRum } from '@datadog/browser-rum';
import { useCallback, useRef } from 'react';

/*
  Deprecated. use `useMonitoring` instead.
 */
export const useMonitorTiming = (metricName: string) => {
  const isTimingReported = useRef(false);
  const nowRef = useRef(performance.now());

  return useCallback<(el: HTMLDivElement) => void>(() => {
    if (!isTimingReported.current) {
      datadogRum.addTiming(metricName);
      datadogRum.addAction(metricName, {
        timestamp: Date.now(),
        trigger_duration: performance.now() - nowRef.current,
      });
      isTimingReported.current = true;
    }
  }, [metricName]);
};
