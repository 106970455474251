import { Pill, Table } from '@melio/penny';
import { Loan } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { LoansTabItem } from '../types';

export const FinancingTabStatusCell = ({ loansTabItem }: { loansTabItem: LoansTabItem }) => {
  const { label, status } = useLoanStatus(loansTabItem.loan);

  return (
    <Table.Cell data-testid={`financing-tab-row-${loansTabItem.id}-status-cell`}>
      <Pill type="secondary" label={label} status={status} data-testid="financing-tab-status-cell" />
    </Table.Cell>
  );
};

export const useLoanStatus = (loan: Loan) => {
  const { formatMessage } = useMelioIntl();
  let label;
  let status;

  switch (loan.status) {
    case 'active':
      label = formatMessage('activities.payDashboard.financingTab.table.cells.status.active');
      status = 'informative' as const;
      break;
    case 'completed':
      label = formatMessage('activities.payDashboard.financingTab.table.cells.status.completed');
      status = 'success' as const;
      break;
    case 'failed':
      label = formatMessage('activities.payDashboard.financingTab.table.cells.status.failed');
      status = 'critical' as const;
      break;
    case 'handled-by-credit-key':
      label = formatMessage('activities.payDashboard.financingTab.table.cells.status.handledByCreditKey');
      status = 'warning' as const;
      break;
    case 'canceled':
      label = formatMessage('activities.payDashboard.financingTab.table.cells.status.canceled');
      status = 'critical' as const;
      break;
    default: {
      label = formatMessage('activities.payDashboard.financingTab.table.cells.status.canceled');
      status = 'critical' as const;
    }
  }

  return { label, status };
};
