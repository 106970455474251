import { Payment, PaymentIntent, usePaymentIntents } from '@melio/platform-api';
import { useOneTimeEffect } from '@melio/platform-utils';
import { useState } from 'react';

export type UseCreatePaymentIntentFromPaymentParams = {
  paymentId: Payment['id'];
  onError?: ErrorFunction;
};

export const useCreatePaymentIntentFromPayment = (params: UseCreatePaymentIntentFromPaymentParams) => {
  const { paymentId, onError } = params;

  const [paymentIntentId, setPaymentIntentId] = useState<PaymentIntent['id'] | undefined>();
  const [fundingSourceTypesOptions, setFundingSourceTypesOptions] = useState<
    PaymentIntent['fundingSourceTypesOptions'] | undefined
  >();

  const { createFromPayment, isMutating: isCreatingPaymentIntentId } = usePaymentIntents({ enabled: false });
  useOneTimeEffect(
    () => {
      void (async () => {
        if (paymentId) {
          try {
            const paymentIntent = await createFromPayment({
              paymentId,
            });
            setFundingSourceTypesOptions(paymentIntent.fundingSourceTypesOptions);
            setPaymentIntentId(paymentIntent.id);
          } catch (e) {
            onError?.(e as PlatformError);
          }
        }
      })();
    },
    () => !!paymentId && !paymentIntentId,
    [paymentId, paymentIntentId]
  );

  return {
    paymentIntentId,
    fundingSourceTypesOptions,
    isLoading: isCreatingPaymentIntentId,
  };
};
