import axios from 'axios';

import { EncodeJwtResponse } from './google.api.types';

const axiosInstance = axios.create();

export const verifyGoogleJWT = async (idToken: string) => {
  const res = await axiosInstance.post(`https://www.googleapis.com/oauth2/v3/tokeninfo`, undefined, {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  });

  return res.data as EncodeJwtResponse;
};
