/* eslint-disable react/display-name */
import React from 'react';
import { Box } from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import { useAnalytics } from '@melio/platform-analytics';

import { useAppTheme } from '@/cl/theme/theme.hooks';
import { useMultiSelect, useSelectedGroupItemCard } from '@/hooks/payDashboard.hooks';
import { payDashboardItemSelectionsSelector } from '@/store/PayDashboard/PayDashboards.model';
import {
  GroupItem,
  GroupItemType,
  PayDashboardInboxTabGroup,
  PayDashboardPaidTabGroup,
  PayDashboardScheduledTabGroup,
  PaymentRequestGroupItem,
} from '@/types/payDashboard.types';
import { GroupItemCard } from '@/widgets/pay-dashboard/cards/GroupItemCard';
import { useTrackBatchSelection } from '@/widgets/pay-dashboard/cards/useTrackBatchSelection';
import { GrouppedListTheme, ListType } from './GroupedLists.component.theme';

type Group = (PayDashboardInboxTabGroup | PayDashboardScheduledTabGroup | PayDashboardPaidTabGroup) & {
  title: string;
  items: GroupItem[];
};

type GroupedListsProps = {
  listType?: ListType;
  size?: 'extra-small' | 'small' | 'medium' | 'large';
  ref?: React.ForwardedRef<HTMLDivElement>;
  groups: Group[];
};

// Please wrap groups prop with useMemo n oreder to re-render this component only when needed
const GroupedListsBase = ({ groups, size, listType = 'groupedLists', ref, ...props }: GroupedListsProps) => {
  const styles = useAppTheme(GrouppedListTheme, { size, listType });

  const { isSelected, onSelect } = useSelectedGroupItemCard();
  const { trackBatchSelection } = useTrackBatchSelection();
  const [itemSelections] = useRecoilState(payDashboardItemSelectionsSelector);

  const { isMultiSelectStarted, getIsMultiSelectAllowed } = useMultiSelect();
  const { track } = useAnalytics();

  const trackBatchSelectEvent = (item: GroupItem) => {
    if (!isMultiSelectStarted) {
      return;
    }

    if (
      item.type === GroupItemType.PAID ||
      item.type === GroupItemType.SCHEDULED ||
      item.type === GroupItemType.FAILED
    ) {
      const payment = item.payment;
      const firstBill = payment.bills?.[0];
      const dueDate = firstBill?.dueDate;
      if (!itemSelections.includes(payment.id)) {
        trackBatchSelection({
          PaymentId: payment.id,
          VendorId: firstBill?.vendorId,
          Amount: payment.amount,
          DueDate: dueDate,
          selected: itemSelections.length,
          action: 'add',
        });
      }
      return;
    }

    if (item.type === GroupItemType.UNPAID) {
      const bill = item.bill;
      if (!itemSelections.includes(bill.id)) {
        trackBatchSelection({
          BillId: bill.id,
          VendorId: bill.vendorId,
          Amount: bill.amount,
          DueDate: bill.dueDate,
          selected: itemSelections.length,
          action: 'add',
        });
      }
      return;
    }
    if (item.type === GroupItemType.PAYMENT_REQUEST) {
      const paymentRequest = (item as PaymentRequestGroupItem).paymentRequest;
      if (!itemSelections.includes(paymentRequest.id)) {
        trackBatchSelection({
          PaymentRequestId: paymentRequest.id,
          VendorId: paymentRequest.vendorId,
          Amount: paymentRequest.totalAmount,
          DueDate: paymentRequest.dueDate,
          selected: itemSelections.length,
          action: 'add',
        });
      }
      return;
    }
  };

  const onGroupItemCard = (item: GroupItem) => {
    let eventProps = {};
    if (
      item.type === GroupItemType.PAID ||
      item.type === GroupItemType.SCHEDULED ||
      item.type === GroupItemType.FAILED
    ) {
      const payment = item.payment;
      const isCombined = payment?.bills && payment.bills.length > 1;
      eventProps = {
        ItemType: 'payment',
        IsCombined: isCombined,
      };
    } else {
      eventProps = {
        ItemType: 'bill',
      };
    }
    trackBatchSelectEvent(item);
    track('Dashboard', 'Clicked', eventProps);
    return onSelect(item);
  };

  return (
    <Box data-component="GroupedLists" sx={styles.container} ref={ref} {...props}>
      {groups.map((group, i) => (
        <Box data-component="List" key={i} sx={styles['group']}>
          <Box sx={styles['groupTitle']}>{group.title}</Box>
          <Box sx={styles['list']}>
            {group.items.map((it, j) => (
              <GroupItemCard
                item={it}
                isSelected={isSelected(it)}
                onClick={onGroupItemCard}
                key={`group_${i}_item_${j}_${group.title}`}
                isInactive={isMultiSelectStarted && !getIsMultiSelectAllowed(it)}
              />
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

// Casting due to generic typings issue
export const GroupedLists = React.memo(
  GroupedListsBase,
  (prev, actual) => prev.groups === actual.groups,
) as typeof GroupedListsBase;
