import { useMelioIntl } from '@melio/platform-i18n';
import { useIsSubscriptionsEnabled } from '@melio/subscriptions';
import { object, SchemaOf, string, StringSchema } from 'yup';

import { SubscriptionBillingPayor } from '../../../types';
import { AssignFirmClientPlanFormFields } from '../types';

export const useAssignFirmClientPlanFormSchema = () => {
  const { formatMessage } = useMelioIntl();
  const isSubscriptionEnabled = useIsSubscriptionsEnabled();

  const requiredFieldMessage = formatMessage(
    'activities.accountants.newClient.form.basicDetails.fields.validation.required.label'
  );
  const invalidFirmSubscriptionBillingPayor = formatMessage(
    'activities.accountants.newClient.form.billingDetails.fields.whoPays.validation.invalidPayorWithoutFundingSource.label'
  );

  return object().shape({
    ...(isSubscriptionEnabled && {
      whoPays: string().when('fundingSourceId', (fundingSourceId: string, schema: StringSchema<string | undefined>) =>
        fundingSourceId
          ? schema.required(requiredFieldMessage)
          : schema.test(
              'subscription firm funding source is required for firm to pay',
              invalidFirmSubscriptionBillingPayor,
              (value) => value === SubscriptionBillingPayor.Client
            )
      ),
      fundingSourceId: string().nullable(),
      subscriptionPlanId: string().required(requiredFieldMessage),
      organizationId: string().required(),
    }),
  }) as unknown as SchemaOf<AssignFirmClientPlanFormFields>;
};
