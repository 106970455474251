import { BaseModal, Container, Text, useBreakpoint } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useLocation } from '@melio/platform-utils';

import {
  useFreeTrialEligibility,
  useIsSubscriptionsEnabled,
  useRedirects,
  useSubscribeToFreePlan,
  useSubscription,
} from '../../../hooks';
import { NOT_SUBSCRIBED_ORGS_ALLOWED_ROUTES } from '../../constants';
import { SubscriptionPlansActivity, SubscriptionPlansVariant } from '../SubscriptionPlans';

export const OnboardingPaywallModalActivity = () => {
  const { formatMessage } = useMelioIntl();
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const subscription = useSubscription();
  const { isExtraSmallScreen } = useBreakpoint();
  const { pathname } = useLocation();
  const { subscribeToFreePlan, isSubscribingToFreePlan } = useSubscribeToFreePlan();
  const { goToReturnUrlOrDashboard } = useRedirects();
  const { isEligibleForFreeTrial, isFetching: isFetchingFreeTrialEligibilityData } = useFreeTrialEligibility();

  const modalProps = { maxW: '1100px' };

  const handleOnClose = () => {
    if (isSubscribingToFreePlan) {
      return;
    }

    if (!subscription) {
      void subscribeToFreePlan();
    } else {
      goToReturnUrlOrDashboard();
    }
  };

  if (!isSubscriptionsEnabled) {
    return null;
  }

  const isOpen = !subscription && !NOT_SUBSCRIBED_ORGS_ALLOWED_ROUTES.includes(pathname);

  return (
    <BaseModal
      {...modalProps}
      isOpen={isOpen}
      onClose={handleOnClose}
      data-testid="onboarding-paywall-activity"
      size="large"
      isLoading={isFetchingFreeTrialEligibilityData}
    >
      <BaseModal.Header>
        <Container paddingTop={isExtraSmallScreen ? 'none' : 'xxs'}>
          <Text as="h2" textStyle="heading2Semi">
            {formatMessage(
              isEligibleForFreeTrial
                ? 'activities.subscription.plans.titleWhenEligibleForFreeTrial'
                : 'activities.subscription.plans.title'
            )}
          </Text>
        </Container>
      </BaseModal.Header>
      <BaseModal.Body>
        <SubscriptionPlansActivity returnUrl={pathname} variant={SubscriptionPlansVariant.MODAL} />
      </BaseModal.Body>
    </BaseModal>
  );
};
