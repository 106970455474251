import { useCollectionApi, UseCollectionApiProps, useNonEntityMutation } from '@melio/api-client';
import {
  ApiKeys,
  PaymentRequest,
  PaymentRequestsApiClient,
  PostPaymentRequestsApproveBulkRequest,
  PostPaymentRequestsCancelBulkRequest,
} from '@melio/platform-api-axios-client';
import { AxiosPromise } from 'axios';

import { convertPaymentRequestCentsToDollars } from '../../entities/payment-request/utils';
import { PaymentRequestsApiGetPaymentRequestsRequest } from './types';

const queryFn = ({ expand, search }: PaymentRequestsApiGetPaymentRequestsRequest = {}): ReturnType<
  typeof PaymentRequestsApiClient.getPaymentRequests
> => PaymentRequestsApiClient.getPaymentRequests(expand, JSON.stringify(search));

export type UsePaymentRequestsProps = UseCollectionApiProps<typeof queryFn, PaymentRequest> & {
  params?: PaymentRequestsApiGetPaymentRequestsRequest;
};

export const usePaymentRequests = ({ params, ...props }: UsePaymentRequestsProps) => {
  const query = useCollectionApi<typeof queryFn>({
    ...props,
    queryKey: [ApiKeys.PaymentRequestsApi, params],
    queryFn: () => queryFn(params),
    select: (data) => data.map(convertPaymentRequestCentsToDollars),
  });

  const reject = useNonEntityMutation(
    (request: PostPaymentRequestsCancelBulkRequest) =>
      PaymentRequestsApiClient.postPaymentRequestsCancelBulk(request).then(
        (res) => convertMoneyUnits(res).data
      ) as unknown as AxiosPromise<PaymentRequest[]>,
    [ApiKeys.PaymentRequestsApi, 'collection', 'default', 'postPaymentRequestsCancelBulk']
  );

  const approve = useNonEntityMutation(
    (request: PostPaymentRequestsApproveBulkRequest) =>
      PaymentRequestsApiClient.postPaymentRequestsApproveBulk(request).then(
        (res) => convertMoneyUnits(res).data
      ) as unknown as AxiosPromise<PaymentRequest[]>,
    [ApiKeys.PaymentRequestsApi, 'collection', 'default', 'postPaymentRequestsApproveBulk']
  );

  return {
    ...query,
    reject,
    approve,
  };
};

const convertMoneyUnits = (res: { data: { data: PaymentRequest[] } }) => ({
  ...res,
  data: {
    ...res.data,
    data: res.data.data.map(convertPaymentRequestCentsToDollars),
  },
});
