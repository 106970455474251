import { SectionBanner } from '@melio/penny';
import { Payment } from '@melio/platform-api';

import { usePlatformIntl } from '@/translations/Intl';

export const RefundInProgressSectionBanner = ({ payment }: { payment: Payment }) => {
  const { formatMessage, formatDate } = usePlatformIntl();

  const isRefundSent = !!payment.processedTimeline?.refundSent;
  const widgetTitle = isRefundSent
    ? formatMessage('widgets.paymentDetails.failed.reason.refundInProgress.description', {
        date: formatDate(payment.processedTimeline?.refundSent, { dateStyle: 'medium' }),
      })
    : formatMessage('widgets.paymentDetails.failed.reason.refundInitiated.description');

  return <SectionBanner description={widgetTitle} variant="informative" data-testid="refund-in-progress-alert" />;
};
