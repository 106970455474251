import { PaymentIntent, PaymentModel, useDeliveryMethods, usePayment } from '@melio/platform-api';

export const useFailedToDeliverACHData = (
  paymentId: string,
  handleFail: (error: PlatformError) => void
): {
  isLoading: boolean;
  payment?: PaymentModel['data'];
  paymentIntent?: PaymentIntent;
  retryDeliver: PaymentModel['retryDeliver'];
  isUpdatingPayment: boolean;
  vendorBankAccountDMId?: string;
} => {
  const { data: payment, isLoading: isLoadingPayment, retryDeliver, isMutating } = usePayment({ id: paymentId });
  const {
    data: deliveryMethods,
    isLoading: isDeliveryMethodsLoading,
    error,
  } = useDeliveryMethods({
    vendorId: payment?.vendorId,
  });

  if (error) {
    handleFail(error);
  }
  const vendorBankAccountDM = deliveryMethods?.find((dm) => dm.type === 'bank-account');

  return {
    isLoading: isLoadingPayment || isDeliveryMethodsLoading,
    payment,
    retryDeliver,
    isUpdatingPayment: isMutating,
    vendorBankAccountDMId: vendorBankAccountDM?.id,
  };
};
