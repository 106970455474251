import { ApiKeys, BillsSyncStatusEnum } from '@melio/platform-api-axios-client';

export type BillProvider = {
  name: 'amazon' | 'gmail';
  status: BillsSyncStatusEnum | undefined;
  itemsSynced: number | null | undefined;
  isStatusLoading: boolean;
  isStatusError: boolean;
  connect: (returnUrl: string) => Promise<void>;
  isConnectLoading: boolean;
  isConnectError: boolean;
  disconnect: () => Promise<void>;
  isDisconnectLoading: boolean;
  isDisconnectError: boolean;
  isConnected: boolean;
  lastCompletionTime: Date | null | undefined;
};

export type ProviderConfiguration = {
  queryKey: ApiKeys;
  queryId: string;
  provider: SupportedProviders;
};

export type ProviderProps = {
  shouldRefetchStatus?: boolean;
  refetchStatusInterval?: number;
  enabled?: boolean | undefined;
};

export type BillsSyncApiProps = ProviderConfiguration & ProviderProps;

export type ProvidersConfiguration = {
  amazon: ProviderConfiguration;
  gmail: ProviderConfiguration;
};

export type ProvidersProps = {
  amazon?: ProviderProps;
  gmail?: ProviderProps;
};

export const providerConfiguration: ProvidersConfiguration = {
  gmail: {
    queryKey: ApiKeys.BillsSyncGmailApi,
    queryId: 'gmail-status',
    provider: 'gmail',
  },
  amazon: {
    queryKey: ApiKeys.BillsSyncAmazonApi,
    queryId: 'amazon-status',
    provider: 'amazon',
  },
};

export type SupportedProviders = keyof typeof providerConfiguration;
