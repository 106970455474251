import { useAnalytics } from '@melio/platform-analytics';

export const useDeleteCollaboratorAnalytics = () => {
  const { track } = useAnalytics();

  const commonEventProps = {
    Intent: 'remove-collaborator',
    PageName: 'are-you-sure-you-want-to-remove-collaborator-from-company',
  };

  const submit = () => track('Setting', 'Click', { ...commonEventProps, Cta: 'remove-collaborator' });
  const close = (reason: string) => track('Setting', 'Click', { ...commonEventProps, Cta: reason });

  return {
    submit,
    close,
  };
};
