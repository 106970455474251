import { Container, Group, Icon, IconButton, Table, Text, useTable } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { createPdfFile, useSystemMessage } from '@melio/platform-utils';
import React from 'react';

import { useSubscriptionBilling } from '../../../api';
import { SubscriptionBillingHistoryItemStatusEnum } from '../../../api/entities/subscriptions';
import { useSubscriptionReceiptPdf } from '../../../api/entities/subscriptions/useSubscriptionReceiptPdf';

export const SubscriptionBillingHistoryTable = () => {
  const { data: subscriptionBilling } = useSubscriptionBilling();
  const { getSubscriptionReceiptPdf } = useSubscriptionReceiptPdf();
  const {
    settings: { partnerProductName },
  } = useConfig();

  const { formatCurrency, formatDate } = useMelioIntl();
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const { showMessage } = useSystemMessage();

  const getStatusIcon = (status: SubscriptionBillingHistoryItemStatusEnum) => {
    switch (status) {
      case SubscriptionBillingHistoryItemStatusEnum.Failed:
        return (
          <Text color="global.critical.700">
            <Icon color="inherit" type="error" size="small" />
          </Text>
        );
      case SubscriptionBillingHistoryItemStatusEnum.Paid:
      case SubscriptionBillingHistoryItemStatusEnum.Pending:
        return (
          <Text color="global.success.700">
            <Icon color="inherit" type="checked-circle" size="small" />
          </Text>
        );
      default:
        return null;
    }
  };

  const onDownload = async ({ id, date }: { id: string; date: string }) => {
    track('SubscriptionBillingHistory', 'DownloadReceipt');
    const formattedYear = formatDate(date, { year: 'numeric' });
    const formattedMonth = formatDate(date, { month: '2-digit' });
    const formattedDate = `${formattedYear}-${formattedMonth}`;
    const fileName = formatMessage('activities.settings.subscriptionBillingHistory.pdf.fileNamePrefix', {
      date: formattedDate,
      partnerProductName,
    });
    try {
      const pdfBase64 = await getSubscriptionReceiptPdf(id);
      createPdfFile(pdfBase64.data.data, fileName);
    } catch (error) {
      track('SubscriptionBillingHistory', 'DownloadReceiptError', { error: (error as PlatformError).message });
      showMessage({
        type: 'error',
        title: formatMessage('activities.settings.subscriptionBillingHistory.failedToDownloadReceipt'),
      });
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const tableProps = useTable({
    headerVariant: 'dark',
    data: subscriptionBilling?.history || [],
    columns: [
      {
        id: 'status',
        cell: ({ row }) => <Table.Cell>{getStatusIcon(row.status ?? '')}</Table.Cell>,
        size: 40,
      },
      {
        id: 'date',
        header: formatMessage('activities.settings.subscriptionBillingHistory.table.column.date'),
        cell: ({ row }) => <Table.Cell>{formatDate(row.date, { month: 'long', year: 'numeric' })}</Table.Cell>,
      },
      {
        id: 'amount',
        header: formatMessage('activities.settings.subscriptionBillingHistory.table.column.amount'),
        cell: ({ row }) => (
          <Table.Cell>
            <Text textStyle="body3Semi">{formatCurrency(row.amount)}</Text>
          </Table.Cell>
        ),
        size: 's',
      },
      {
        id: 'receipt',
        header: formatMessage('activities.settings.subscriptionBillingHistory.table.column.receipt'),
        textAlign: 'end',
        cell: ({ row }) => (
          <Table.Cell textAlign="end">
            <IconButton
              aria-label="download recepit"
              icon="download"
              size="small"
              variant="naked"
              data-testid="download-receipt"
              onClick={() => onDownload({ id: row.id, date: row.date })}
            />
          </Table.Cell>
        ),
        size: 's',
      },
    ],
  });

  return (
    <Group variant="vertical" alignItems="flex-start" spacing="m" data-testid="subscription-billing-history">
      <Container border="regular" width="full">
        <Table {...tableProps} />
      </Container>
    </Group>
  );
};
