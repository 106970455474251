/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useRecoilValue } from 'recoil';

import { GroupedLists } from '@/cl/components/GroupedLists/GroupedLists.component';
import { NoResultsFound } from '@/cl/components/NoResultsFound/NoResultsFound.component';
import { usePartnerConfig } from '@/hooks/partners';
import { useSelectFirstCardFromGroup } from '@/hooks/payDashboard.hooks';
import { payDashboardQuerySearchSelector } from '@/store/PayDashboard/PayDashboards.model';
import { usePlatformIntl } from '@/translations/Intl';
import { PayDashboardPaidTabGroup, PayDashboardSectionEnum, PaymentGroupItem } from '@/types/payDashboard.types';
import { sortDates } from '@/utils/dates.util';

const sortGroupItems = (paymentA: PaymentGroupItem, paymentB: PaymentGroupItem) =>
  sortDates(paymentA.payment?.scheduledDate, paymentB.payment?.scheduledDate, 'desc');

export const PaidCardList = ({ groups }: { groups: PayDashboardPaidTabGroup[] }) => {
  const { formatMessage } = usePlatformIntl();
  const queryString = useRecoilValue(payDashboardQuerySearchSelector);
  const { partnerConfig } = usePartnerConfig();
  const { NoBillsFoundIcon } = partnerConfig.icons;

  const getTitleBySectionName = (section: PayDashboardSectionEnum) => {
    switch (section) {
      case PayDashboardSectionEnum.thisWeek:
        return formatMessage(`app.payDashboard.paidListSection.sections.thisWeek`);
      case PayDashboardSectionEnum.older:
      default:
        return formatMessage(`app.payDashboard.paidListSection.sections.older`);
    }
  };

  const sortedGroupsWithTitles = React.useMemo(
    () =>
      groups.map((group) => ({
        ...group,
        title: getTitleBySectionName(group.section),
        items: [...group.items].sort(sortGroupItems),
      })),
    [groups],
  );

  useSelectFirstCardFromGroup(sortedGroupsWithTitles);

  return sortedGroupsWithTitles.length === 0 ? (
    <NoResultsFound
      text={
        queryString
          ? formatMessage('widgets.emptySearchResult.billDescription')
          : formatMessage('widgets.emptySearchResult.paidBillDescription')
      }
      icon={<NoBillsFoundIcon />}
    />
  ) : (
    <GroupedLists groups={sortedGroupsWithTitles} size="small" listType="payCardList" />
  );
};
