import { Container, StatusModal } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export type DeleteIEntityDialogScreenProps = {
  id?: string;
  onSubmit: VoidFunction;
  onClose: VoidFunction;
  isDeleting?: boolean;
  isLoading?: boolean;
  header: string;
  description: string;
  primaryButtonLabel: string;
  secondaryButtonLabel: string;
};

export const DeleteIEntityDialogScreen = forwardRef<DeleteIEntityDialogScreenProps>(
  ({ onClose, onSubmit, id, isDeleting, primaryButtonLabel, secondaryButtonLabel, description, ...props }, ref) => (
    <StatusModal
      isOpen={!!id}
      onClose={isDeleting ? () => null : onClose}
      variant="alert"
      primaryButton={{
        variant: 'critical',
        label: primaryButtonLabel,
        onClick: onSubmit,
        isLoading: isDeleting,
      }}
      secondaryButton={{
        label: secondaryButtonLabel,
        onClick: onClose,
        variant: 'tertiary',
        isDisabled: isDeleting,
      }}
      {...props}
      ref={ref}
    >
      <Container paddingY="m">{description}</Container>
    </StatusModal>
  )
);
