import { Table, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export const DebitDateCell = ({ debitDate }: { debitDate: Date }) => {
  const { formatDate } = useMelioIntl();
  return (
    <Table.Cell data-testid="payments-late-approval-row-debit-date-cell">
      <Text textStyle="body3">{formatDate(debitDate, { dateStyle: 'medium' })}</Text>
    </Table.Cell>
  );
};
