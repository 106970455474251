import { InvoiceDiscount } from '@melio/ar-domain';
import { Group } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { DiscountsEmptyState, DiscountsTable, DiscountsTableToolbar } from '../components';

export type DiscountsTableScreenProps = {
  isLoading: boolean;
  onAdd: VoidFunction;
  discounts: InvoiceDiscount[];
  onEdit: (discountId: string) => unknown;
  onDelete: (discountId: string) => unknown;
};

export const DiscountsTableScreen = forwardRef<DiscountsTableScreenProps>(
  ({ onEdit, onDelete, discounts, onAdd, isLoading, ...props }, ref) => (
    <Group variant="vertical" {...props} ref={ref}>
      <DiscountsTableToolbar onAdd={onAdd} />
      <DiscountsTable onEdit={onEdit} onDelete={onDelete} discounts={discounts} isLoading={isLoading} />
      {!isLoading && discounts.length === 0 && <DiscountsEmptyState />}
    </Group>
  )
);
