import { Group, Icon, Popover, StatusIconSolid, TableSelectCellProps, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

type FundingSourceSelectTriggerProps = {
  item?: {
    label: string;
    description?: string;
  };
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isInvalid?: boolean;
  isLoading?: boolean;
  isReadOnly?: boolean;
  popoverProps: TableSelectCellProps['popoverProps'];
};
export const FundingSourceSelectTrigger = forwardRef<FundingSourceSelectTriggerProps, 'div'>(
  ({ item, isOpen, isInvalid, isLoading, isReadOnly, popoverProps }: FundingSourceSelectTriggerProps, ref) => {
    const showWarningIcon = !isLoading && isInvalid && popoverProps;
    const caretIcon = (
      <Group.Item grow={1}>
        <Group alignItems="center" justifyContent="flex-end" spacing="none">
          <Icon type={isOpen ? 'caret-up' : 'caret-down'} size="small" color="default" aria-hidden />
        </Group>
      </Group.Item>
    );
    const triggerRight = !isLoading && !isReadOnly && caretIcon;
    return (
      <Group width="full" alignItems="center" spacing="xs" ref={ref}>
        {item && (
          <Group variant="vertical" alignItems="flex-start" spacing="xxxs" width="fit-content">
            <Text textStyle="body3" shouldSupportEllipsis color="inherit">
              {item.label}
            </Text>
            {item.description && (
              <Text textStyle="body3" shouldSupportEllipsis color="global.neutral.800">
                {item.description}
              </Text>
            )}
          </Group>
        )}
        {showWarningIcon && (
          <Popover {...popoverProps}>
            <StatusIconSolid variant="warning" size="small" data-testid="funding-source-select-warning-icon" />
          </Popover>
        )}
        {triggerRight}
      </Group>
    );
  }
);
