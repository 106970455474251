import { AccountingPlatform, AccountingPlatformConnectionStatus, AccountingPlatformSlug } from '@melio/platform-api';

export const getQboAccountingId = (accountingPlatformData?: AccountingPlatform[]) => {
  const qboEntity = accountingPlatformData?.find(
    (accountingPlatform) => accountingPlatform.accountingSlug === AccountingPlatformSlug.QuickBooksOnline,
  );
  const qboAccountingId = qboEntity?.id;
  const qboAccountDisconnect =
    qboEntity?.connectionStatus === AccountingPlatformConnectionStatus.Disconnected ||
    qboEntity?.connectionStatus === AccountingPlatformConnectionStatus.Unlinked;

  return qboAccountDisconnect ? qboAccountingId : undefined;
};

export const getXeroAccountingPlatform = (accountingPlatforms?: AccountingPlatform[]) =>
  accountingPlatforms?.find((accountingPlatform) => accountingPlatform.accountingSlug === AccountingPlatformSlug.Xero);

export const getQuickBooksDesktopAccountingPlatform = (accountingPlatforms?: AccountingPlatform[]) =>
  accountingPlatforms?.find(
    (accountingPlatform) => accountingPlatform.accountingSlug === AccountingPlatformSlug.QuickBooksDesktop,
  );
