import { Box } from '@chakra-ui/react';
import { ARInvoice, useMelioIntl } from '@melio/ar-domain';
import { ActionsDropdownMenuItemProps, Table } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { Big } from 'big.js';

import {
  getActionLabel,
  getActionParameterValue,
  getActionVariant,
  getInvoiceAction,
  getInvoiceStatusActions,
  InvoiceActionsHandlers,
} from '../../../../utils';
import { useRowContextDescription } from '../../hooks';

type InvoiceActionCellProps = {
  row: ARInvoice;
  meta: InvoiceActionsHandlers;
};

const handleCellClick = (e: React.MouseEvent<HTMLDivElement>) => {
  e.stopPropagation();
};

export const InvoiceActionCell = ({ row: invoice, meta }: InvoiceActionCellProps) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const rowContextDecription = useRowContextDescription(invoice);
  const actions = getInvoiceStatusActions(invoice.displayStatus);
  if (actions.length == 0) {
    return null;
  }

  const actionItems = actions
    .map((action) => {
      const actionFn = getInvoiceAction(action, meta);
      const actionParameterValue = getActionParameterValue({ action, invoice });
      return actionFn
        ? {
            label: getActionLabel(action),
            variant: getActionVariant(action),
            onClick: () => {
              void actionFn(actionParameterValue);
              track('Invoice', 'Click', {
                ProductName: 'ar',
                PageName: 'dashboard',
                TabName: 'invoices',
                Flow: 'manage-invoice',
                Intent: action,
                Cta: action,
                InvoiceId: invoice.id,
                InvoiceStatus: invoice.displayStatus,
                DueDate: invoice.dueDate,
                Amount: new Big(invoice.totalAmount).div(100).toNumber(),
              });
            },
          }
        : null;
    })
    .filter(Boolean) as ActionsDropdownMenuItemProps[];

  return (
    <Box onClick={handleCellClick} height="full">
      <Table.ActionsCell
        tooltipLabel={formatMessage('ar.dashboard.activities.invoiceTable.columns.actions.tooltipLabel')}
        options={actionItems}
        data-testid={`invoice-actions-cell-${invoice.id}`}
        aria-label={rowContextDecription}
      />
    </Box>
  );
};
