import { InvoiceDiscount, InvoiceTaxRate, useInvoiceSummary as _useInvoiceSummary } from '@melio/ar-domain';
import { useDebouncedObject } from '@melio/platform-utils';
import { pick } from 'lodash';

import { ServiceChargeParams } from '../../../types';
import { InvoiceFormValuesLineItem } from '../types';

export type UseInvoiceSummaryProps = {
  taxRate?: InvoiceTaxRate;
  discount?: InvoiceDiscount;
  serviceCharges?: ServiceChargeParams[];
  lineItems?: InvoiceFormValuesLineItem[];
  onError?: ARErrorFunction;
};

export const useInvoiceSummary = ({ lineItems, discount, serviceCharges, taxRate, onError }: UseInvoiceSummaryProps) =>
  _useInvoiceSummary({
    params: useDebouncedObject(
      {
        discount: discount && pick(discount, 'type', 'value', 'name'),
        taxRate: taxRate && pick(taxRate, 'rate', 'name'),
        lineItems: lineItems?.filter(isValidLineItem).map((li) => pick(li, 'price', 'quantity', 'taxable')),
        serviceCharges: serviceCharges?.map((sc) => pick(sc, 'type', 'value', 'name')),
      },
      50
    ),
    onError,
  });

const isValidLineItem = (li: InvoiceFormValuesLineItem) => li.catalogItemId && li.quantity && li.price;
