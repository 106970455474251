import { UseMelioFormResults } from '@melio/penny';
import React, { useContext } from 'react';

import { AssignFirmClientPlanFormFields } from '../../types';

export const AssignFirmClientPlanFormFormContext =
  React.createContext<UseMelioFormResults<AssignFirmClientPlanFormFields> | null>(null);

export const useAssignFirmClientPlanFormContext = () => {
  const form = useContext(AssignFirmClientPlanFormFormContext);
  if (!form) {
    throw new Error('FormContext is not provided');
  }
  return form;
};
