import { Container, Group, Modal, SectionBanner, Text, Typography } from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';

import { Plan, Subscription, SubscriptionBillingCycleEnum, useSubscriptionsPreview } from '../../../../api';
import { usePlanInfo, useSubscriptionPlan } from '../../../../hooks';

export type SwitchSubscriptionBillingCycleModalScreenProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  onChange: VoidFunction;
  subscription: Subscription;
  isSubscribing: boolean;
  newPlanId?: Plan['id'];
  switchBillingCycleError?: boolean;
};

export const SwitchSubscriptionBillingCycleModalScreen = ({
  isOpen,
  onClose,
  onChange,
  subscription,
  isSubscribing,
  newPlanId: _newPlanId,
  switchBillingCycleError,
}: SwitchSubscriptionBillingCycleModalScreenProps) => {
  const { formatCurrency, formatMessage, formatDate } = useMelioIntl();
  const { track } = useAnalytics();

  const newCycle =
    subscription.planCyclePeriod === SubscriptionBillingCycleEnum.Monthly
      ? SubscriptionBillingCycleEnum.Annual
      : SubscriptionBillingCycleEnum.Monthly;

  const newPlanId = _newPlanId || subscription.planId;
  const { planName: currentPlanName } = usePlanInfo(subscription.planId);
  const { planName: newPlanName } = usePlanInfo(newPlanId);

  const { data: preview, isFetching: isPreviewFetching } = useSubscriptionsPreview({
    enabled: isOpen,
    params: {
      planCycle: newCycle,
      planId: newPlanId,
      unitQuantity: subscription.measurementInfo?.quantity || 0,
      fundingSourceId: subscription.fundingSourceId,
    },
  });

  const { currentPlan } = useSubscriptionPlan();

  const anaylticsBaseProps = {
    PageName: 'switch-to-a-yealy-billing-cycle',
    Flow: 'settings',
    Intent: 'change-payment-frequency',
    CurrentPlan: currentPlanName,
    PlanId: subscription.planId,
  };

  useAnalyticsView('Organization', isOpen, true, anaylticsBaseProps);

  if ((!isPreviewFetching && !preview) || !currentPlan) {
    return null;
  }

  const formatDateRange = (date: string) => formatDate(date, { month: 'long', day: 'numeric', year: 'numeric' });

  const handleClose = (intent: 'cancel' | 'exit') => {
    track('Organization', 'Click', {
      ...anaylticsBaseProps,
      Intent: intent,
      Cta: intent,
    });

    onClose();
  };

  const handleChange = () => {
    track('Organization', 'Click', {
      ...anaylticsBaseProps,
      Cta: 'switch-to-yearly-billing',
      TotalPrice: preview?.amount,
      Taxes: preview?.taxAmount,
      NumberOfSeats: subscription.measurementInfo?.quantity,
    });

    onChange();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => handleClose('exit')}
      isLoading={isPreviewFetching}
      header={formatMessage('activities.settings.subscriptionPlans.switchCycle.changeBillingCycle')}
      primaryButton={{
        label: formatMessage('activities.settings.subscriptionPlans.switchCycle.confirmButton'),
        onClick: handleChange,
        isLoading: isSubscribing,
        variant: 'primary',
      }}
      secondaryButton={{
        label: formatMessage('activities.settings.subscriptionPlans.switchCycle.cancelButton'),
        onClick: () => handleClose('cancel'),
        isLoading: isSubscribing,
        variant: 'tertiary',
      }}
      data-testid="switch-subscription-billing-cycle-modal"
    >
      {preview && (
        <Container alignItems="center" width="full" justifyContent="space-between">
          <Group spacing="m" variant="vertical" width="full">
            {switchBillingCycleError && (
              <Group role="status">
                <SectionBanner
                  description={formatMessage(
                    `activities.settings.subscriptionPlans.switchBillingCycle.toast.${newCycle}.error`
                  )}
                  variant="critical"
                  data-testid="switch-billimng-cycle-modal-error"
                  data-role="alert"
                />
              </Group>
            )}
            <Group spacing="xxxs" variant="vertical">
              <Group width="full" justifyContent="space-between">
                <Text data-testid="description">
                  {formatMessage(`activities.settings.subscriptionPlans.switchTo.${newCycle}.description`)}
                </Text>
              </Group>
            </Group>

            <Group spacing="s" variant="vertical" hasDivider width="full">
              <Group spacing="none" variant="vertical" width="full">
                <Text data-testid="current-plan-and-current-cycle-description">
                  {formatMessage(`activities.settings.subscriptionPlans.switchTo.${newCycle}.fromPlan`, {
                    planName: currentPlanName,
                  })}
                </Text>
                <Group width="full" justifyContent="space-between">
                  <Text color="global.neutral.800" textStyle="body3">
                    {formatMessage('activities.settings.subscriptionPlans.switchCycle.basePrice')}
                  </Text>
                  <Text
                    color="global.neutral.800"
                    textStyle="body3"
                    data-testid="current-plan-and-current-cycle-base-price"
                  >
                    {formatMessage(`activities.settings.subscriptionPlans.switchTo.${newCycle}.basePriceFromPeriod`, {
                      price: formatCurrency(subscription.nextBillingAmount || 0),
                    })}
                  </Text>
                </Group>
              </Group>

              <Group spacing="none" variant="vertical" width="full">
                <Text data-testid="new-plan-and-new-cycle-description">
                  {formatMessage(`activities.settings.subscriptionPlans.switchTo.${newCycle}.toPlan`, {
                    planName: newPlanName,
                  })}
                </Text>
                <Group width="full" justifyContent="space-between">
                  <Text color="global.neutral.800" textStyle="body3">
                    {formatMessage('activities.settings.subscriptionPlans.switchCycle.basePrice')}
                  </Text>
                  <Text color="global.neutral.800" textStyle="body3" data-testid="new-plan-and-new-cycle-base-price">
                    {formatMessage(`activities.settings.subscriptionPlans.switchTo.${newCycle}.basePriceToPeriod`, {
                      price: formatCurrency(preview.amountWithoutTax),
                    })}
                  </Text>
                </Group>
              </Group>

              <Group justifyContent="space-between" alignItems="flex-start">
                <Group spacing="s" variant="vertical">
                  <Text textStyle="body4" color="global.neutral.800">
                    <Typography.ParagraphList
                      size="small"
                      list={[
                        {
                          content: formatMessage(
                            'activities.settings.subscriptionPlans.switchCycle.subscriptionEndInformation',
                            {
                              endDate: formatDateRange(preview.billingItems[0]?.serviceEndDate || ''),
                            }
                          ),
                        },
                        {
                          content: formatMessage(
                            'activities.settings.subscriptionPlans.switchCycle.noPromotionIncluded'
                          ),
                        },
                      ]}
                      type="unordered"
                    />
                  </Text>
                </Group>
              </Group>
            </Group>
          </Group>
        </Container>
      )}
    </Modal>
  );
};
