import { Route, Routes } from 'react-router-dom';
import { useLocation } from '@melio/platform-utils';

import { RouterComponentWrapper } from '@/hoc/routerComponentWrapper';
import { useOrigin } from '@/hooks/analytics/useOrigin';
import { AddNewBillStateType } from '@/hooks/router.hooks';
import { EditBillScreen } from '@/screens/edit-bill/EditBill.screen';
import { AddBillAndSchedulePaymentScreen } from '@/screens/schedule-payment/AddBillAndSchedulePayment.screen';

type RouteState = AddNewBillStateType & { returnUrl?: string };

export const BillsRoute = () => {
  const origin = useOrigin();
  const { state } = useLocation<RouteState>();
  const { vendorId, deliveryMethodId, amount, categoryId, file, files, returnUrl } = state || {};

  return (
    <Routes>
      <Route
        path="/new/*"
        element={
          <RouterComponentWrapper
            Component={AddBillAndSchedulePaymentScreen}
            componentProps={{
              vendorId,
              deliveryMethodId,
              amount,
              categoryId,
              file,
              files,
              returnUrl,
              origin,
            }}
          />
        }
      />
      <Route
        path="/:id"
        element={
          <RouterComponentWrapper componentProps={{ id: { _pathParam: 'id' }, returnUrl }} Component={EditBillScreen} />
        }
      />
    </Routes>
  );
};
