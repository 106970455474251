import { RoleUniqueNames } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { usePermissions } from '@melio/platform-permissions';
import { useConfig } from '@melio/platform-provider';

export const useAllowedActions = () => {
  const { can } = usePermissions();
  const { settings } = useConfig();
  const [approvalWorkflowsEnabled] = useFeature<boolean>(FeatureFlags.ApprovalWorkflows, false);

  const canEditCollaborator = ({ roleUniqueName }: { roleUniqueName: RoleUniqueNames | null }) =>
    can({ action: 'update', subject: 'collaborator', subjectData: { roleUniqueName } });

  const canInviteCollaborator =
    can({ action: 'create', subject: 'invitation' }) && settings.collaborator.isAddCollaboratorEnabled;

  const canViewPaymentApprovalLimit =
    settings.isPaymentApprovalLimitEnabled &&
    can({ action: 'read', subject: 'approvalWorkflow:approvalLimit' }) &&
    approvalWorkflowsEnabled;

  return { canInviteCollaborator, canEditCollaborator, canViewPaymentApprovalLimit };
};
