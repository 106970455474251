import { Link, SectionBanner } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import React from 'react';

export const UsHolidayChecksBanner: React.FC = () => {
  const {
    settings: {
      eoyCheck: { link: eoyCheckLink },
    },
  } = useConfig();
  const { formatMessage } = useMelioIntl();

  return (
    <SectionBanner
      data-testid="batch-payments-holiday-checks-banner"
      title={formatMessage('activities.batchPayments.components.banners.endOfYearCheck.title')}
      description={formatMessage('activities.batchPayments.components.banners.endOfYearCheck.description', {
        learnMoreLink: (
          <Link
            href={eoyCheckLink}
            label={formatMessage('activities.batchPayments.components.banners.endOfYearCheck.learnMoreLink')}
            newTab
          />
        ),
      })}
    />
  );
};
