/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { DashboardDetailsCarouselWidget } from '@melio/ap-widgets';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { InboxItem, useInboxItems } from '@melio/platform-api';

import { payDashboardItemSelectionsSelector } from '@/store/PayDashboard/PayDashboards.model';
import { AbsoluteCenter } from '../../cl/components/AbsoluteCenter/AbsoluteCenter.component';
import { WithLoading } from '../../hoc/withLoading.hoc';

export const PayDashboardDetailsCarouselScreen = withAnalyticsContext(() => {
  const itemSelections = useRecoilValue(payDashboardItemSelectionsSelector);

  const { data: inboxItemsData, isLoading: isInboxItemsLoading } = useInboxItems({});

  const isSelected = (inboxItem: InboxItem) => itemSelections.findIndex((id) => id === inboxItem.payload.id) > -1;

  const selectedInboxItems = useMemo(
    () => inboxItemsData?.data.filter(isSelected) ?? [],
    [itemSelections, inboxItemsData],
  );

  return (
    <WithLoading isLoading={isInboxItemsLoading} isAbsoluteCenter>
      <AbsoluteCenter>
        <Box
          css={{
            width: '756px',
          }}
        >
          <DashboardDetailsCarouselWidget inboxItems={selectedInboxItems} />
        </Box>
      </AbsoluteCenter>
    </WithLoading>
  );
});
