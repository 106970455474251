import { useAccountMeLimitations } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';

export const useMtlKycUpliftEnabled = () => {
  const [mtlKycUpliftEnabledFeature] = useFeature(FeatureFlags.MtlKycUplift, false);
  const { isLoading, data: accountLimitations } = useAccountMeLimitations({ enabled: mtlKycUpliftEnabledFeature });

  return {
    isLoading,
    mtlKycUpliftEnabled: mtlKycUpliftEnabledFeature && !accountLimitations?.orgHasInitiatedPayments,
  };
};
