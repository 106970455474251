import { addWildcardToRoutes, useNavigate, withOutlet } from '@melio/ar-domain';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import { Route, Routes } from 'react-router-dom';

import { EditInvoiceActivity, EditInvoiceActivityProps } from '../activities';
import { useInvoiceModals } from './useInvoiceModals';

export type EditInvoiceFlowProps = {
  invoiceId: string;
  onClose: VoidFunction;
  onDone: (id?: string) => void;
  onIssueInvoice: (id: string) => void;
  onError?: ARErrorFunction;
};

export const EditInvoiceFlow = withAnalyticsContext<EditInvoiceFlowProps>(
  forwardRef(({ setAnalyticsProperties, invoiceId, onClose, onDone, onError, onIssueInvoice, ...props }, ref) => {
    const { Paths, goToEditInvoice } = useEditInvoiceFlowNavigation();

    const {
      renderModals,
      openTaxRateModal,
      openDiscountModal,
      openServiceChargeModal,
      removeServiceCharge,
      discount,
      serviceCharges,
      removeDiscount,
      taxRate,
      onCreateCustomer,
      onUpdateCustomer,
      customerId,
    } = useInvoiceModals({ onClose: goToEditInvoice, invoiceId, onError });

    const { track } = useAnalytics();

    setAnalyticsProperties({
      InvoiceId: invoiceId,
      ProductName: 'ar',
      PageName: 'update-invoice',
      Flow: 'update-invoice',
      Intent: 'update-invoice',
    });

    const onEditDone = (target: Parameters<EditInvoiceActivityProps['onDone']>[0]) => {
      track('Invoice', 'Status', {
        StatusType: 'success',
        Cta: target,
        Intent: 'save-changes',
      });
      if (target === 'save-and-close') {
        onDone(invoiceId);
      } else {
        onIssueInvoice(invoiceId);
      }
    };

    const onErrorWithTracking = (error: ARPlatformError) => {
      track('Invoice', 'Status', {
        StatusType: 'failure',
        Cta: 'update-invoice',
        Intent: 'save-changes',
      });
      onError?.(error);
    };

    return (
      <Routes>
        <Route
          path={Paths.Home}
          element={withOutlet(
            <EditInvoiceActivity
              invoiceId={invoiceId}
              discount={discount}
              onSetDiscount={openDiscountModal}
              onRemoveDiscount={removeDiscount}
              onClose={onClose}
              onDone={onEditDone}
              onError={onErrorWithTracking}
              taxRate={taxRate}
              onSetTaxRate={openTaxRateModal}
              serviceCharges={serviceCharges}
              onAddServiceCharge={openServiceChargeModal}
              onRemoveServiceCharge={removeServiceCharge}
              onCreateCustomer={onCreateCustomer}
              onUpdateCustomer={onUpdateCustomer}
              customerId={customerId}
              {...props}
              ref={ref}
            />
          )}
        >
          {renderModals()}
        </Route>
      </Routes>
    );
  })
);

EditInvoiceFlow.displayName = 'EditInvoiceFlow';

const useEditInvoiceFlowNavigation = () => {
  enum Paths {
    Home = '',
  }

  const navigate = useNavigate<Paths>({ withSearchparams: true });
  const goToEditInvoice = (options?: Parameters<typeof navigate>[1]) => navigate(Paths.Home, options);

  return {
    Paths: addWildcardToRoutes(Paths),
    goToEditInvoice,
  };
};
