import { _createFormFieldInput, TextField, TextFieldProps } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export type NumberFieldInputProps = Omit<TextFieldProps, 'maskProps'> & {
  allowDecimal?: boolean;
};

export const NumberFieldInput = _createFormFieldInput(
  forwardRef<NumberFieldInputProps, 'input'>(({ onChange, allowDecimal = false, ...inputProps }, ref) => (
    <TextField
      {...inputProps}
      type="number"
      onKeyDown={(e) => {
        if (e.key === '.' && !allowDecimal) {
          e.preventDefault();
        }
      }}
      onChange={(e) => {
        const parsedValue = e.target.value === '' ? '' : parseFloat(e.target.value);
        onChange?.({ ...e, target: { ...e.target, value: parsedValue as never } });
      }}
      onDoubleClick={(e) => e.currentTarget.select()}
      ref={ref}
    />
  ))
);

NumberFieldInput.displayName = 'NumberFieldInput';
