/* eslint-disable max-lines */
import { BillSubscriptionEndPolicyEnum, BillSubscriptionIntervalTypeEnum } from '@melio/platform-api';
import { MessageKey } from '@melio/platform-i18n';
import { calculateMonthlyCountFromDateRange, DaysInOrder } from '@melio/platform-utils';
import {
  add,
  addDays,
  addMonths,
  addWeeks,
  addYears,
  differenceInDays,
  differenceInMonths,
  differenceInWeeks,
  differenceInYears,
  endOfMonth,
  getDaysInMonth,
  getMonth,
  getYear,
  isBefore,
  isSameMonth,
  setDate,
} from 'date-fns';

import { INTERVALS_MAP, RECURRING_SINGLE_PAYMENT } from './constants';

export const calculateWeeklyOccurrencesInDateRange = (startDate: Date, endDate: Date): number => {
  const normalizedStartDate = new Date(startDate).setHours(0, 0, 0, 0); // eslint-disable-line no-restricted-syntax
  const normalizedEndDate = new Date(endDate).setHours(0, 0, 0, 0); // eslint-disable-line no-restricted-syntax

  if (differenceInDays(normalizedStartDate, normalizedEndDate) > 0) {
    throw new Error('Start date must be sooner than end date');
  }

  return differenceInWeeks(normalizedEndDate, normalizedStartDate) + 1;
};

const diffFuncMapper: {
  [key in BillSubscriptionIntervalTypeEnum]?: (startDate: Date, endDate: Date, options: object) => number;
} = {
  [BillSubscriptionIntervalTypeEnum.Weekly]: differenceInWeeks,
  [BillSubscriptionIntervalTypeEnum.Monthly]: differenceInMonths,
  [BillSubscriptionIntervalTypeEnum.Yearly]: differenceInYears,
};

export const calculateRecurringEffectiveEndDate = (
  paymentFrequency: string,
  startDate?: Date,
  generalEndDate?: Date
) => {
  if (!startDate || !generalEndDate || paymentFrequency === RECURRING_SINGLE_PAYMENT) {
    return null;
  }
  const diffFunc = diffFuncMapper[paymentFrequency as BillSubscriptionIntervalTypeEnum];
  if (!diffFunc) {
    throw new Error('Unsupported payment frequency');
  }

  const diff = diffFunc(generalEndDate, startDate, { roundingMethod: 'floor' });
  const interval = INTERVALS_MAP[paymentFrequency as BillSubscriptionIntervalTypeEnum];
  if (!interval) {
    throw new Error('Unsupported payment frequency');
  }
  const estimatedEndDate = add(startDate, {
    [interval]: diff,
  });
  return estimatedEndDate;
};

export const getRecurringStartDateHelperText = (
  paymentFrequency: BillSubscriptionIntervalTypeEnum,
  startDate: Date,
  formatMessage: (id: MessageKey, values?: Record<string, unknown>) => string
): string => {
  if (!startDate) {
    return '';
  }
  switch (paymentFrequency) {
    case BillSubscriptionIntervalTypeEnum.Weekly:
      return formatMessage('widgets.addBillForm.startDate.helperText.weekly', {
        weekDay: DaysInOrder[startDate.getDay()] as string,
      });
    case BillSubscriptionIntervalTypeEnum.Monthly: {
      return formatMessage('widgets.addBillForm.startDate.helperText.monthly', {
        day: startDate.getDate(),
      });
    }

    default:
      return '';
  }
};

export const isBillRecurringFrequency = (frequency: string): frequency is BillSubscriptionIntervalTypeEnum =>
  Object.values(BillSubscriptionIntervalTypeEnum).includes(frequency as BillSubscriptionIntervalTypeEnum);

/**
 * This constant represents the day of the month that is the cutoff for the decision if we need to create two or one payments
 * on the first month of a bill subscription that have 'twice a month' interval.
 * if the first payment date is after the cutoff date, we will create only one payment on the first month.
 * else we will create two payments on the first month using {@link TWICE_A_MONTH_DAYS_OFFSET} as the days offset between the payments.
 **/
const TWICE_A_MONTH_CUTOFF = 16;

/**
 * This constant represents the days offset between the first and the second payment of a bill subscription that have 'twice a month' interval.
 **/
const TWICE_A_MONTH_DAYS_OFFSET = 15;

export const getSecondDueDateForTwiceAMonth = (startDate: Date) => {
  const startDateDay = startDate.getDate();
  const isFirstDateIsBeforeCutOff = startDateDay <= TWICE_A_MONTH_CUTOFF;
  const nextDate = addDays(startDate, TWICE_A_MONTH_DAYS_OFFSET);

  if (isFirstDateIsBeforeCutOff && !isSameMonth(startDate, nextDate)) {
    return endOfMonth(startDate);
  }

  return nextDate;
};

const isDayValidForTwiceAMonthEndDate = ({ day, endDate }: { day: number; endDate: Date }) => {
  const endDateDay = endDate.getDate();
  const endDateMonth = getMonth(endDate);
  const isEndDateIsLastDayOfEndMonth = getDaysInMonth(endDate) === endDateDay;

  const isDayExistsInEndMonth = getMonth(setDate(endDate, day)) === endDateMonth;

  if (isDayExistsInEndMonth && day <= endDateDay) {
    return true;
  }
  if (!isDayExistsInEndMonth && isEndDateIsLastDayOfEndMonth) {
    return true;
  }

  return false;
};

const getNumberOfOccurrencesForTwiceAMonth = (startDate: Date, endDate: Date) => {
  const secondDate = getSecondDueDateForTwiceAMonth(startDate);

  const yearsOccurrences = (getYear(endDate) - getYear(startDate)) * 24;
  const monthsOccurrences = (getMonth(endDate) - getMonth(startDate) + 1) * 2;

  let occurrencesCounter = yearsOccurrences + monthsOccurrences;

  const isOnlyOneOccurrenceInTheFirstMonth = startDate.getDate() > TWICE_A_MONTH_CUTOFF;

  if (isOnlyOneOccurrenceInTheFirstMonth) {
    occurrencesCounter--;
  }

  if (!isDayValidForTwiceAMonthEndDate({ day: startDate.getDate(), endDate })) {
    occurrencesCounter--;
  }
  if (!isDayValidForTwiceAMonthEndDate({ day: secondDate.getDate(), endDate })) {
    occurrencesCounter--;
  }

  return occurrencesCounter;
};

const getEndDateByNumOfOccurrencesForTwiceAMonth = (startDate: Date, numOfOccurrences: number) => {
  const isNumOfOccurrencesOdd = numOfOccurrences % 2 > 0;
  const isStartDateAfterCutoff = startDate.getDate() > TWICE_A_MONTH_CUTOFF;
  const cutoffMonthOffset = isStartDateAfterCutoff ? 0 : -1;
  const mathRoundFunction = isStartDateAfterCutoff ? Math.floor : Math.ceil;
  const numberOfMonthToAdd = mathRoundFunction(numOfOccurrences / 2) + cutoffMonthOffset;

  const nextDate = addMonths(startDate, numberOfMonthToAdd);

  if (isNumOfOccurrencesOdd) {
    return nextDate;
  }

  const secondDateDay = getSecondDueDateForTwiceAMonth(startDate).getDate();

  const nextDateLastDayOfMonth = getDaysInMonth(nextDate);

  const nextDateDay = Math.min(secondDateDay, nextDateLastDayOfMonth);

  return setDate(nextDate, nextDateDay);
};

const getEndDateByWeeks = (startDate: Date, numOfOccurrences: number, factor = 1) =>
  addWeeks(startDate, (numOfOccurrences - 1) * factor);
const getEndDateByMonths = (startDate: Date, numOfOccurrences: number, factor = 1) =>
  addMonths(startDate, (numOfOccurrences - 1) * factor);
const getEndDateByYears = (startDate: Date, numOfOccurrences: number, factor = 1) =>
  addYears(startDate, (numOfOccurrences - 1) * factor);

const PAYMENT_FREQUENCY_TO_CALCULATORS: {
  [key in BillSubscriptionIntervalTypeEnum]: {
    calcOccurrences: (startDate: Date, endDate: Date) => number;
    calcEndDate?: (startDate: Date, numOfOccurrences: number, factor?: number) => Date;
    factor: 1 | 2 | 3 | 4 | 6;
  };
} = {
  [BillSubscriptionIntervalTypeEnum.Weekly]: {
    calcOccurrences: calculateWeeklyOccurrencesInDateRange,
    calcEndDate: getEndDateByWeeks,
    factor: 1,
  },
  [BillSubscriptionIntervalTypeEnum.Monthly]: {
    calcOccurrences: calculateMonthlyCountFromDateRange,
    calcEndDate: getEndDateByMonths,
    factor: 1,
  },
  [BillSubscriptionIntervalTypeEnum.Every2Months]: {
    calcOccurrences: calculateMonthlyCountFromDateRange,
    calcEndDate: getEndDateByMonths,
    factor: 2,
  },
  [BillSubscriptionIntervalTypeEnum.Every3Months]: {
    calcEndDate: getEndDateByMonths,
    calcOccurrences: calculateMonthlyCountFromDateRange,
    factor: 3,
  },
  [BillSubscriptionIntervalTypeEnum.Every4Months]: {
    calcEndDate: getEndDateByMonths,
    calcOccurrences: calculateMonthlyCountFromDateRange,
    factor: 4,
  },
  [BillSubscriptionIntervalTypeEnum.Every6Months]: {
    calcEndDate: getEndDateByMonths,
    calcOccurrences: calculateMonthlyCountFromDateRange,
    factor: 6,
  },
  [BillSubscriptionIntervalTypeEnum.Every2Weeks]: {
    calcOccurrences: calculateWeeklyOccurrencesInDateRange,
    calcEndDate: getEndDateByWeeks,
    factor: 2,
  },
  [BillSubscriptionIntervalTypeEnum.Every4Weeks]: {
    calcOccurrences: calculateWeeklyOccurrencesInDateRange,
    calcEndDate: getEndDateByWeeks,
    factor: 4,
  },
  [BillSubscriptionIntervalTypeEnum.TwiceAMonth]: {
    calcOccurrences: getNumberOfOccurrencesForTwiceAMonth,
    calcEndDate: getEndDateByNumOfOccurrencesForTwiceAMonth,
    factor: 1,
  },
  [BillSubscriptionIntervalTypeEnum.Yearly]: {
    calcOccurrences: (startDate: Date, endDate: Date) => differenceInYears(endDate, startDate) + 1,
    calcEndDate: getEndDateByYears,
    factor: 1,
  },
};

export const calculateRecurringEndDateByNumOfOccurrences = ({
  paymentFrequency,
  startDate,
  numOfOccurrences,
}: {
  paymentFrequency: BillSubscriptionIntervalTypeEnum;
  startDate: Date;
  numOfOccurrences?: number;
}): Date | null => {
  if (!numOfOccurrences) {
    return null;
  }

  const { calcEndDate, factor } = PAYMENT_FREQUENCY_TO_CALCULATORS[paymentFrequency] || {};
  if (!calcEndDate || !factor) {
    return null;
  }
  const endDate = calcEndDate(startDate, numOfOccurrences, factor);

  return endDate;
};

export const getNumberOfOccurrences = ({
  paymentFrequency,
  startDate,
  numOfOccurrences,
  endDate,
}: {
  paymentFrequency: BillSubscriptionIntervalTypeEnum;
  numOfOccurrences?: number;
  startDate: Date;
  endDate?: Date;
}) => {
  if (numOfOccurrences) {
    return numOfOccurrences;
  }

  if (!endDate || isBefore(endDate, startDate)) {
    return;
  }

  const { calcOccurrences, factor } = PAYMENT_FREQUENCY_TO_CALCULATORS[paymentFrequency] || {};
  if (!calcOccurrences || !factor) {
    return;
  }

  return Math.ceil(calcOccurrences(startDate, endDate) / factor);
};
export const getRecurringFieldsToShow = (
  endPolicy: BillSubscriptionEndPolicyEnum,
  isRecurringPaymentImprovementsEnabled: boolean
) => ({
  endDate: endPolicy === BillSubscriptionEndPolicyEnum.EndDate,
  numberOfOccurrences: endPolicy === BillSubscriptionEndPolicyEnum.NumOfOccurrences,
  lastAmount: endPolicy !== BillSubscriptionEndPolicyEnum.NoEndDate && isRecurringPaymentImprovementsEnabled,
});
