import { ModelError, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils';
import { useState } from 'react';

import { getMessageKey } from './apiErrorsUtils';
import { ConfirmVendorDeletionModal } from './ConfirmVendorDeletionModal';
import { DeleteVendorErrorModal } from './DeleteVendorErrorModal';

type Props = {
  vendor: Vendor;
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: (vendor: Vendor) => void;
};

export const DeleteVendorModal = ({ vendor, isOpen, onClose, onSuccess }: Props) => {
  const { formatMessage } = useMelioIntl();
  const [openModal, setOpenModal] = useState<'confirm' | 'error'>('confirm');
  const { showMessage } = useSystemMessage();

  const onDeletionSuccess = () => {
    onSuccess?.(vendor);
    onClose();
  };

  const onDeletionFailure = (e: ModelError) => {
    if (e?.message === 'Vendor has existing bills' || e?.message === 'Vendor has existing payments') {
      setOpenModal('error');
      return;
    }
    try {
      const messageKey = getMessageKey(e);

      const errorMessage = formatMessage(messageKey);
      showMessage({
        type: 'error',
        title: errorMessage,
      });
    } catch (error) {
      const fallbackMessage = formatMessage('activities.payDashboard.deleteVendorModal.toast.error');
      showMessage({
        type: 'error',
        title: fallbackMessage,
      });
    }
    onClose();
  };

  return (
    <>
      <ConfirmVendorDeletionModal
        vendor={vendor}
        onSuccess={onDeletionSuccess}
        onFail={onDeletionFailure}
        onClose={onClose}
        isOpen={isOpen && openModal === 'confirm'}
      />
      <DeleteVendorErrorModal onClose={onClose} vendor={vendor} isOpen={isOpen && openModal === 'error'} />
    </>
  );
};
