import { Flex } from '@chakra-ui/react';
import { Group, IconButton, useTheme } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { PageTitle } from '@melio/platform-utils';
import { VFC } from 'react';

export const NewBillHeader: VFC<{ onClose: VoidFunction }> = ({ onClose }) => {
  const theme = useTheme();
  const { formatMessage } = useMelioIntl();

  return (
    <Flex
      alignItems="center"
      backgroundColor={theme.colors.global.neutral['100']}
      paddingX="s"
      paddingY="s"
      width="full"
      zIndex="overlay"
      borderBottom="surface.default"
      position="static"
    >
      <Group alignItems="center" spacing="xs-s">
        <IconButton
          aria-label="Back"
          icon="arrow-left"
          size="small"
          variant="naked"
          onClick={onClose}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          autoFocus
          role="button"
          tabIndex={0}
        />

        <PageTitle textStyle="heading2Semi" aria-live="polite">
          {formatMessage('activities.addBillV2.billForm.title')}
        </PageTitle>
      </Group>
    </Flex>
  );
};
