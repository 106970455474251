import { Box } from '@chakra-ui/react';
import { Form, FormSelectNewOption, FormSelectNewProps, Group, Icon, Text } from '@melio/penny';
import { AccountingPlatform, AccountingPlatformCategory, AccountingPlatformSlug } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { useAccountingPlatformCategorySelect } from './useAccountingPlatformCategorySelect';

export type AccountingPlatformCategorySelectWidgetProps = {
  value?: AccountingPlatformCategory['id'];
  activeAccountingPlatform?: AccountingPlatform;
} & Omit<FormSelectNewProps, 'value' | 'options'>;

export const AccountingPlatformCategorySelectWidget = forwardRef<AccountingPlatformCategorySelectWidgetProps, 'input'>(
  (
    { placeholder: _placeholder, isReadOnly, isViewMode, viewModePlaceholder, activeAccountingPlatform, ...props },
    ref
  ) => {
    const { data: categories, isFetched } = useAccountingPlatformCategorySelect();
    const { formatMessage } = useMelioIntl();

    const getPlaceholder = (activeAccountingPlatform?: AccountingPlatform, isViewMode?: boolean) => {
      const isQuickBooksOnline = activeAccountingPlatform?.accountingSlug === AccountingPlatformSlug.QuickBooksOnline;
      const isXero = activeAccountingPlatform?.accountingSlug === AccountingPlatformSlug.Xero;
      if (isQuickBooksOnline && isViewMode) {
        return formatMessage('form.categorySelect.viewModePlaceholder.quickbooks');
      }

      if (isQuickBooksOnline) {
        return formatMessage('form.categorySelect.placeholder.quickbooks');
      }

      if (isXero) {
        return formatMessage('form.categorySelect.placeholder.xero');
      }

      if (isViewMode) {
        return formatMessage('form.categorySelect.viewModePlaceholder.general');
      }

      return formatMessage('form.categorySelect.placeholder.general');
    };

    const placeholder = getPlaceholder(activeAccountingPlatform, isViewMode);

    const optionRenderer = (option: FormSelectNewOption) => (
      <Group alignItems="center" spacing="s">
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        {option.nestingLevel > 0 && (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <Box paddingLeft={option.nestingLevel === 2 ? 'm' : 'none'}>
            <Icon type="sub-arrow" size="small" />
          </Box>
        )}
        <Group variant="vertical" spacing="xxxs">
          <Text textStyle="inline" shouldSupportEllipsis color="inherit">
            {option.label}
          </Text>
          <Text textStyle="inline" shouldSupportEllipsis color="global.neutral.800">
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            {option.description}
          </Text>
        </Group>
      </Group>
    );

    return (
      <Form.SelectNew
        options={categories}
        data-component="AccountingPlatformCategorySelect"
        optionRenderer={optionRenderer}
        ref={ref}
        isLoading={!isFetched && !isReadOnly && !isViewMode}
        placeholder={placeholder}
        viewModePlaceholder={placeholder}
        isReadOnly={isReadOnly}
        isViewMode={isViewMode}
        {...props}
      />
    );
  }
);

AccountingPlatformCategorySelectWidget.displayName = 'AccountingPlatformCategorySelect';
