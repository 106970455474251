import { useVendorDirectoryInfoComplete } from '@melio/ap-widgets';
import { Table } from '@melio/penny';
import { SelectableDropdownMenuItem } from '@melio/penny/dist/types/containers';
import { DeliveryMethod, DeliveryMethodByPayor, PaymentIntent, useDeliveryMethod, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { BankAccountFormModal } from '../../../../../payment-flow/PaymentFlowActivity/PaymentFlowForm/components/modals/BankAccountFormModal/BankAccountFormModal';
import { useBankAccountDetailsFormModal } from '../../../../../payment-flow/PaymentFlowActivity/PaymentFlowForm/components/modals/BankAccountFormModal/useBankAccountDetailsFormModal';
import { PaperCheckFormModal } from '../../../../../payment-flow/PaymentFlowActivity/PaymentFlowForm/components/modals/PaperCheckFormModal/PaperCheckFormModal';
import { usePaperCheckFormModal } from '../../../../../payment-flow/PaymentFlowActivity/PaymentFlowForm/components/modals/PaperCheckFormModal/usePaperCheckFormModal';
import { useGetDeliveryMethodWarning } from '../../PaymentIntentsTable.screen.utils';

type DeliveryMethodSelectCellProps = {
  paymentIntent: PaymentIntent;
  vendor?: Vendor;
  selectedId?: DeliveryMethod['id'];
  deliveryMethods: DeliveryMethodByPayor[];
  onSelect: (selectedId: DeliveryMethod['id']) => void;
};

type GetDeliveryMethodOptionsProps = {
  formatMessage: ReturnType<typeof useMelioIntl>['formatMessage'];
  deliveryMethods: DeliveryMethodByPayor[];
  isDisabled: boolean;
};

const getDeliveryMethodOptions = ({
  formatMessage,
  deliveryMethods,
  isDisabled,
}: GetDeliveryMethodOptionsProps): SelectableDropdownMenuItem[] => {
  const findIdByDeliveryMethodType = (type: DeliveryMethod['type']) => {
    const deliveryMethod = deliveryMethods.find((dm) => dm.type === type);
    return deliveryMethod?.id || type;
  };

  return [
    {
      label: formatMessage('activities.paymentFlow.form.content.deliveryMethodCard.bank-account.title'),
      value: findIdByDeliveryMethodType('bank-account'),
      icon: 'bank-out',
      disabled: {
        isDisabled,
      },
    },
    {
      label: formatMessage(
        'activities.addDeliveryMethodByPayeeWithoutPayment.screens.selectDeliveryMethodType.check.title'
      ),
      value: findIdByDeliveryMethodType('paper-check'),
      icon: 'paper-check',
      disabled: {
        isDisabled,
      },
    },
  ];
};

const getManagedDeliveryMethodOptions = (
  formatMessage: ReturnType<typeof useMelioIntl>['formatMessage'],
  deliveryMethod: DeliveryMethod
): SelectableDropdownMenuItem[] => {
  const deliveryMethodType = deliveryMethod.type;

  switch (deliveryMethodType) {
    case 'managed-account':
      if (deliveryMethod.details.deliveryType === 'check') {
        return [
          {
            label: formatMessage('activities.paymentFlow.form.content.deliveryMethodCard.managed-account.check.title'),
            value: deliveryMethod.id,
            icon: 'paper-check',
          },
        ];
      }
      return [
        {
          label: formatMessage(
            'activities.paymentFlow.form.content.deliveryMethodCard.managed-account.bank-account.title'
          ),
          value: deliveryMethod.id,
          icon: 'bank-out',
        },
      ];
  }
  return [
    {
      label: formatMessage('activities.paymentFlow.form.content.deliveryMethodCard.managed-account.bank-account.title'),
      value: deliveryMethod.id,
      icon: 'bank-out',
    },
  ];
};

export const DeliveryMethodFixedListSelectCell = forwardRef<DeliveryMethodSelectCellProps, 'div'>(
  ({ paymentIntent, deliveryMethods, selectedId, onSelect, vendor, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const bankAccountModal = useBankAccountDetailsFormModal({
      vendor,
      onDone: (deliveryMethod) => onSelect(deliveryMethod.id),
    });

    const paperCheckModal = usePaperCheckFormModal({
      vendor,
      onDone: (deliveryMethod) => onSelect(deliveryMethod.id),
    });

    const { data: deliveryMethod } = useDeliveryMethod({
      id: selectedId,
      enabled: selectedId !== undefined,
    });

    const isVendorDirectoryDetailsCompleted = useVendorDirectoryInfoComplete(vendor);

    const { warningInfo } = useGetDeliveryMethodWarning({
      paymentIntent,
      selectedDeliveryMethodId: selectedId,
      shouldShowMissingVendorInfoWarning: !isVendorDirectoryDetailsCompleted,
    });

    const options = deliveryMethod?.isManaged
      ? getManagedDeliveryMethodOptions(formatMessage, deliveryMethod)
      : getDeliveryMethodOptions({
          formatMessage,
          deliveryMethods,
          isDisabled: !isVendorDirectoryDetailsCompleted,
        });

    const handleSelect = (selectedId: DeliveryMethod['id']) => {
      const selectedDeliveryMethod = vendor?.deliveryMethods.find((dm) => dm.id === selectedId);

      if (!selectedDeliveryMethod) {
        if (selectedId === 'bank-account') {
          bankAccountModal.open();
        } else if (selectedId === 'paper-check') {
          paperCheckModal.open();
        }
      } else {
        const requireConfirmation = selectedDeliveryMethod.requireConfirmationForPayment;
        if (!requireConfirmation) {
          onSelect(selectedId);
        } else {
          if (selectedDeliveryMethod.type === 'bank-account') {
            bankAccountModal.open();
          } else if (selectedDeliveryMethod.type === 'paper-check') {
            paperCheckModal.open();
          }
        }
      }
    };

    return (
      <>
        <Table.SelectCell
          data-testid="delivery-method-fiserv-select-cell"
          {...props}
          placeholder={formatMessage(
            'activities.batchPayments.screens.paymentIntentsTable.deliveryMethodCell.placeholder'
          )}
          ref={ref}
          options={options}
          isReadOnly={deliveryMethod?.isManaged}
          value={selectedId}
          onSelect={handleSelect}
          isInvalid={warningInfo.shouldShowWarning || !isVendorDirectoryDetailsCompleted}
          tooltipProps={warningInfo.tooltipProps}
          popoverProps={warningInfo.popoverProps}
        />{' '}
        <BankAccountFormModal onClose={bankAccountModal.close} {...bankAccountModal} />
        <PaperCheckFormModal onClose={paperCheckModal.close} {...paperCheckModal} />
      </>
    );
  }
);

DeliveryMethodFixedListSelectCell.displayName = 'DeliveryMethodFixedListSelectCell';
