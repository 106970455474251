import { Button, Group, useFormSubmissionController } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export const MissingVendorInfoDrawerFooter = ({
  submitButtonProps,
  cancelButtonProps,
  onCancelClick,
}: {
  submitButtonProps: ReturnType<typeof useFormSubmissionController>['submitButtonProps'];
  cancelButtonProps: ReturnType<typeof useFormSubmissionController>['cancelButtonProps'];
  onCancelClick: VoidFunction;
}) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Group width="full" justifyContent="space-between">
      <Button
        variant="tertiary"
        data-testid="vendor-btn-link-to-directory-cancel"
        label={formatMessage('widgets.missingVendorInfoDrawer.footer.secondary')}
        loadingText={formatMessage('app.loading')}
        onClick={onCancelClick}
        {...cancelButtonProps}
      />
      <Button
        variant="primary"
        data-testid="vendor-btn-link-to-directory-primary"
        loadingText={formatMessage('app.loading')}
        label={formatMessage('widgets.missingVendorInfoDrawer.footer.primary')}
        {...submitButtonProps}
      />
    </Group>
  );
};
