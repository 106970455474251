/* eslint-disable react-hooks/exhaustive-deps */
import {
  AccountingPlatformSyncStatus,
  useAccountingPlatformSync,
  useAccountingPlatformVerifyQuickBooksDesktop,
  useMelioQueryClient,
} from '@melio/platform-api';
import { useDateUtils } from '@melio/platform-utils';
import { differenceInMilliseconds } from 'date-fns';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { isQuickBooksDesktopUnavailable } from './utils';

export type AccountingPlatformsSyncWithPollingProps = {
  organizationId?: string;
  accountingPlatformId: string;
  isQuickBooksDesktopAccountingPlatform?: boolean;
  onSyncDone?: VoidFunction;
  onSyncError?: VoidFunction;
  onCommunicateError?: VoidFunction;
  enabled?: boolean;
};

export const useAccountingPlatformPollingSync = ({
  organizationId,
  accountingPlatformId,
  isQuickBooksDesktopAccountingPlatform,
  onSyncDone,
  onSyncError,
  onCommunicateError,
  enabled,
}: AccountingPlatformsSyncWithPollingProps) => {
  const [isTriggeredSyncInProgress, setIsTriggeredSyncInProgress] = useState<boolean>(false);

  const {
    data,
    sync,
    isError: isSyncError,
    isFetched: isSyncFetched,
  } = useAccountingPlatformSync({
    id: accountingPlatformId,
    refetchInterval: () => (isTriggeredSyncInProgress ? 2000 : 10000),
    enabled,
  });
  const { verifyQuickBooksDesktop, verifyQuickBooksDesktopData } = useAccountingPlatformVerifyQuickBooksDesktop({
    id: accountingPlatformId,
    enabled: false,
  });
  const { createDate } = useDateUtils();
  const lastSyncOrMountDateRef = useRef<Date>(createDate());
  const queryClient = useMelioQueryClient();

  useEffect(() => {
    if (!data?.lastCompletionTime) {
      return;
    }

    const currentSyncDate = createDate(data.lastCompletionTime.toISOString());
    const currentToLastSyncDiff = differenceInMilliseconds(currentSyncDate, lastSyncOrMountDateRef.current);
    // If diff=0, it means that the current sync is the same as the last sync so we should ignore it.
    // If 0<diff, it means that the current sync was done after the last sync, so we should consider it as a new sync.
    // If -100<diff<0, it means that the current sync was done before the component mounted,
    // meaning it's the first sync after the user connected their accounting platform, so we should consider it as a new sync.
    const isNewSync =
      currentToLastSyncDiff !== 0 &&
      currentToLastSyncDiff > -100 &&
      data.syncStatus !== AccountingPlatformSyncStatus.Running;
    const shouldDisplayQuickBooksDesktopSyncError =
      isTriggeredSyncInProgress &&
      isQuickBooksDesktopAccountingPlatform &&
      data.syncStatus === AccountingPlatformSyncStatus.Error;
    if (isNewSync) {
      void queryClient.invalidateQueries('AccountingPlatformsApi');
      lastSyncOrMountDateRef.current = currentSyncDate;

      if (data.syncStatus === AccountingPlatformSyncStatus.Done) {
        void queryClient.invalidateQueries('InboxItemsApi');
        void queryClient.invalidateQueries('VendorsApi');
        void queryClient.invalidateQueries('PaymentsApi');
        setIsTriggeredSyncInProgress(false);
        onSyncDone?.();
      } else if (data.syncStatus === AccountingPlatformSyncStatus.Error) {
        setIsTriggeredSyncInProgress(false);
        onSyncError?.();
      }
    } else if (shouldDisplayQuickBooksDesktopSyncError) {
      setIsTriggeredSyncInProgress(false);
      onCommunicateError?.();
    }
  }, [data]);

  useEffect(() => {
    if (isSyncError) {
      setIsTriggeredSyncInProgress(false);
    }
  }, [isSyncError]);

  const triggerSync = useCallback(async () => {
    setIsTriggeredSyncInProgress(true);

    if (isQuickBooksDesktopAccountingPlatform) {
      const result = await verifyQuickBooksDesktop(organizationId || '');

      if (isQuickBooksDesktopUnavailable(result)) {
        setIsTriggeredSyncInProgress(false);
        onCommunicateError?.();
        return;
      }
    }

    await sync();
  }, [sync, verifyQuickBooksDesktop]);

  const isError = useMemo(
    () =>
      !isTriggeredSyncInProgress &&
      (isSyncError ||
        data?.syncStatus === AccountingPlatformSyncStatus.Error ||
        isQuickBooksDesktopUnavailable(verifyQuickBooksDesktopData)),
    [isTriggeredSyncInProgress, isSyncError, data, verifyQuickBooksDesktopData]
  );

  return {
    triggerSync,
    isRunning: isTriggeredSyncInProgress || data?.syncStatus === AccountingPlatformSyncStatus.Running,
    isError,
    isSyncFetched,
  };
};
