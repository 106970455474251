import { isEbill } from '@melio/ap-domain';
import { Bill, Vendor, VendorsApiGetVendorsRequest } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useCallback } from 'react';

import { PayDashboardSortingType } from '../../types';

export const convertVendorsTabSortToApiSort = ({
  id,
  sortDirection,
}: NonNullable<PayDashboardSortingType['sortingState']>): VendorsApiGetVendorsRequest['sort'] => {
  if (id === 'lastPaid') {
    return [
      { field: 'lastPaymentDate', order: sortDirection },
      { field: 'name', order: 'asc' },
    ];
  }
  if (id === 'openBalance') {
    return [
      { field: 'openBalance', order: sortDirection },
      { field: 'name', order: 'asc' },
    ];
  }
  return [{ field: 'name', order: sortDirection }];
};

export const getMaskedAccountNumber = (accountNumber: string) => {
  const maskedNumber = accountNumber.slice(-4);
  return `****${maskedNumber}`;
};

const isMaskedAccountNumber = (accountNumber: string) => accountNumber.includes('*');

export const displayAccountNumber = (accountNumber?: string) => {
  if (accountNumber) {
    if (isMaskedAccountNumber(accountNumber)) {
      return accountNumber;
    }
    return getMaskedAccountNumber(accountNumber);
  }
  return;
};

export const getTotalBillsBalance = (bills: Bill[]) =>
  bills.reduce(
    (acc, bill) => acc + (isEbill(bill) && bill.eBillAmountDue != null ? bill.eBillAmountDue : bill.balance),
    0
  );
export const MIN_AMOUNT_TO_REQUIRE_INVOICE = 10_000;

export const useAriaLabelContextForVendor = () => {
  const { formatMessage, formatCurrency } = useMelioIntl();

  const getAriaLabelContextForVendor = (vendor: Vendor, meta: { unpaidBills: Bill[] }) => {
    const vendorUnpaidBills = meta.unpaidBills.filter((bill) => bill.vendorId === vendor.id);
    const openBalance = getTotalBillsBalance(vendorUnpaidBills);
    const vendorName = vendor.name;

    return formatMessage('activities.payDashboard.vendorsTab.table.ariaLabel.context', {
      vendorName,
      openBalance: formatCurrency(openBalance),
      numOfUnpaidBills: vendorUnpaidBills.length,
    });
  };

  return {
    getAriaLabelContextForVendor: useCallback(getAriaLabelContextForVendor, [formatMessage, formatCurrency]),
  };
};

export const getFooterHeight = () => {
  const footerElement = document.getElementById('vendors-selection-footer');
  return footerElement ? footerElement.offsetHeight : 0;
};
