import { useEffect, useState } from 'react';

type RequiredDataProps = {
  stepId: string;
};

type UseStepManagerProps<T extends RequiredDataProps> = {
  data: T[];
  defaultActiveId?: string;
};

export const useStepManager = <T extends RequiredDataProps>({ data, defaultActiveId }: UseStepManagerProps<T>) => {
  const defaultActiveStep = defaultActiveId ? data.findIndex((step) => step.stepId === defaultActiveId) : 0;
  const [steps, setSteps] = useState<
    (T & {
      isExpanded: boolean;
      isDisabled: boolean;
      isFilled: boolean;
    })[]
  >([]);

  useEffect(() => {
    setSteps(
      data.map((item, index) => ({
        ...item,
        isExpanded: index === defaultActiveStep,
        isDisabled: index > defaultActiveStep,
        isFilled: index < defaultActiveStep,
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const goToNextStep = () => {
    const expandedStepIndex = steps.findIndex((step) => step.isExpanded);
    if (expandedStepIndex < steps.length - 1) {
      const nextStepId = steps[expandedStepIndex + 1]?.stepId;
      if (nextStepId) {
        setSteps((currentSteps) =>
          currentSteps.map((step, index) => ({
            ...step,
            isFilled: index === expandedStepIndex || step.isFilled,
          }))
        );
        expandSelectedStep(nextStepId);
      }
    }
  };

  const expandSelectedStep = (expandedStepId: string, keepOtherOpenState?: boolean) =>
    setSteps((currentSteps) =>
      currentSteps.map((step) => ({
        ...step,
        isDisabled: step.stepId === expandedStepId ? false : step.isDisabled,
        isExpanded: step.stepId === expandedStepId || (!!keepOtherOpenState && step.isExpanded),
      }))
    );

  const collapseSelectedStep = (collapsedStepId: string) =>
    setSteps((currentSteps) =>
      currentSteps.map((step, index) => ({
        ...step,
        isExpanded: step.stepId === collapsedStepId && index < currentSteps.length - 1 ? false : step.isExpanded,
      }))
    );

  const focusErrorStep = (errorStepId: string, error: boolean) => {
    error ? expandSelectedStep(errorStepId, true) : collapseSelectedStep(errorStepId);
  };

  const expandedStep = steps.find((step) => !!step.isExpanded)?.stepId;

  return { steps, expandedStep, goToNextStep, expandSelectedStep, focusErrorStep };
};
