import { useFeeCatalog, useFundingSources, useUserHasPayments, useVendor } from '@melio/platform-api';

import { ExtendedPaymentIntent } from '../../../utils/pay-flow/useExtendedPaymentIntent';

export function usePreFetch(paymentIntent?: ExtendedPaymentIntent) {
  useFeeCatalog();
  useFundingSources();
  useVendor({ id: paymentIntent?.vendorId });
  useUserHasPayments();
}
