import { FormattedMessage, useMelioIntl } from '@melio/ar-domain';
import { Button, Form, Group, Text, useMelioForm, useWatch } from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { useMonitoring } from '@melio/platform-monitoring';
import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';
import { object, SchemaOf, string } from 'yup';

import { AddBankFundingSourceFormFields } from './types';

export type AddBankFundingSourceFormScreenProps = {
  onSubmit: (data: AddBankFundingSourceFormFields) => void;
  isSaving: boolean;
  onEmailChanged: (email: string) => void;
  isLoading: boolean;
  EmailBanner?: React.ReactNode;
};

const useSchema = () => {
  const { formatMessage } = useMelioIntl();

  return object().shape({
    email: string()
      .email(formatMessage('ar.guestPayment.activities.cardHolder.form.fields.email.valid.text'))
      .required(formatMessage('ar.guestPayment.activities.cardHolder.form.fields.email.required.text')),
  }) as SchemaOf<AddBankFundingSourceFormFields>;
};

export const AddBankFundingSourceFormScreen = forwardRef<AddBankFundingSourceFormScreenProps>(
  ({ onSubmit, isSaving, isLoading, onEmailChanged, EmailBanner }, ref) => {
    const { formProps, registerField, submitButtonProps, control, formState } =
      useMelioForm<AddBankFundingSourceFormFields>({
        onSubmit,
        schema: useSchema(),
        isSaving,
      });
    const email = useWatch({ control, name: 'email' });

    useEffect(() => {
      onEmailChanged(email);
    }, [email, onEmailChanged]);

    const { formatMessage } = useMelioIntl();
    const { routeReady } = useMonitoring();

    useAnalyticsView('PaymentRequest', true, true, {
      PaymentMethodType: 'ach',
    });

    const { track } = useAnalytics();

    const handleOnSubmit = () => {
      submitButtonProps.onClick();

      if (!formState.isValid) {
        track('PaymentRequest', 'Status', {
          ErrorType: 'invalid-email-address',
        });
      }
    };

    return (
      <Group variant="vertical" spacing="l" ref={ref}>
        <Group variant="vertical" spacing="m">
          <Group variant="vertical" spacing="xxs">
            <Text textStyle="body2Semi">
              <FormattedMessage id="ar.guestPayment.payByBank.form.title.text" />
            </Text>
            <Text textStyle="body3">
              <FormattedMessage id="ar.guestPayment.payByBank.form.description.text" />
            </Text>
          </Group>
          <Group variant="vertical" spacing="xs">
            <Form data-component="AddBankFundingSourceForm" {...formProps} columns={2}>
              <Form.TextField
                colSpan={2}
                labelProps={{ label: formatMessage('ar.guestPayment.activities.cardHolder.form.fields.email.label') }}
                isLoading={isLoading}
                {...registerField('email')}
              />
            </Form>
            {EmailBanner}
          </Group>
        </Group>
        <Button
          ref={routeReady}
          data-testid="add-bank-submit-button"
          size="large"
          label={formatMessage('ar.guestPayment.payByBank.form.button.submit.text')}
          {...submitButtonProps}
          onClick={handleOnSubmit}
        />
      </Group>
    );
  }
);
AddBankFundingSourceFormScreen.displayName = 'AddBankFundingSourceFormScreen';
