import { SelectionCard } from '@melio/penny';
import {
  DeliveryMethodByPayor,
  FreeChecksData,
  FundingSource,
  SupportedDeliveryMethodTypeOption,
  Vendor,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';

import { useDeliveryMethodCardHelpers } from './utils';

export type DeliveryMethodCardProps = {
  deliveryMethod: DeliveryMethodByPayor;
  vendorName: Vendor['name'];
  fundingSource?: FundingSource;
  freeChecks?: FreeChecksData;
  isSelected?: boolean;
  onClick: VoidFunction;
  onEditClick?: VoidFunction;
  isDisabled?: boolean;
  disabledReason?: SupportedDeliveryMethodTypeOption['reason'];
};

export const DeliveryMethodCard = forwardRef<DeliveryMethodCardProps>(
  (
    {
      deliveryMethod,
      vendorName,
      fundingSource,
      isSelected = false,
      onClick,
      onEditClick,
      freeChecks,
      isDisabled,
      disabledReason,
      ...props
    },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();

    const { getDescription, getLabel, getIconKey } = useDeliveryMethodCardHelpers(
      fundingSource,
      freeChecks,
      isDisabled,
      disabledReason
    );

    const actionProps = onEditClick
      ? { action: { label: formatMessage('widgets.deliveryMethodCard.edit'), onClick: onEditClick } }
      : {};

    return (
      <SelectionCard
        data-component="DeliveryMethodCard"
        data-testid={`delivery-method-card-${deliveryMethod.type}`}
        {...props}
        ref={ref}
        icon={getIconKey(deliveryMethod, isSelected)}
        mainLabelProps={{ label: getLabel(deliveryMethod, vendorName) }}
        descriptionProps={{ label: getDescription(deliveryMethod) }}
        {...actionProps}
        isSelected={isSelected}
        onClick={onClick}
        isDisabled={isDisabled}
      />
    );
  }
);

DeliveryMethodCard.displayName = 'DeliveryMethodCard';
