import { StatusModal, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { useSubscriptionContext } from '../../../context';

export type ClaimAccountingClientDiscountProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  onClaimDiscountClick: VoidFunction;
};

export const ClaimAccountingClientDiscountModal = ({
  isOpen,
  onClose,
  onClaimDiscountClick,
}: ClaimAccountingClientDiscountProps) => {
  const { formatMessage } = useMelioIntl();
  const { clientPlanDiscountPercent } = useSubscriptionContext();

  return (
    <StatusModal
      data-testid="claim-accounting-client-discount-modal"
      isOpen={isOpen}
      onClose={onClose}
      header={formatMessage('activities.subscription.claimAccountingClientDiscount.modal.title')}
      variant="warning"
      primaryButton={{
        label: formatMessage('activities.subscription.claimAccountingClientDiscount.modal.primary.button.label'),
        onClick: onClose,
        variant: 'primary',
      }}
      secondaryButton={{
        label: formatMessage('activities.subscription.claimAccountingClientDiscount.modal.secondary.button.label'),
        onClick: onClaimDiscountClick,
        variant: 'tertiary',
      }}
    >
      <Text>
        {formatMessage('activities.subscription.claimAccountingClientDiscount.modal.content', {
          discountPercentage: clientPlanDiscountPercent,
        })}
      </Text>
    </StatusModal>
  );
};
