import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import { date, mixed, object, SchemaOf, string } from 'yup';

import { PaymentFlowFormFields } from '../../../payment-flow';

export const useEditAmountFormSchema = (): SchemaOf<PaymentFlowFormFields> => {
  const { formatMessage } = useMelioIntl();
  const { messageKey: positiveAmountMessageKey, validation: positiveAmountValidation } = createPositiveAmountTest();

  return object().shape({
    amountToPay: string()
      .required(formatMessage('activities.paymentFlow.form.content.amountToPay.required'))
      .test('amountToPayPositive', formatMessage(positiveAmountMessageKey), positiveAmountValidation),
    vendorId: string().notRequired(),
    deliveryMethodId: string().notRequired(),
    fundingSourceId: string().notRequired(),
    scheduleDate: date().notRequired(),
    deliveryDate: date().notRequired(),
    deliveryPreference: mixed().notRequired(),
    noteToVendor: string().notRequired(),
    vendorEmail: string().notRequired(),

    paymentPurpose: string().notRequired(),
    description: string().notRequired(),
    invoice: mixed().notRequired(),
    recurrenceType: mixed().notRequired(),
    intervalType: mixed().notRequired(),
    startDate: mixed().notRequired(),
    endPolicy: mixed().notRequired(),
    endDate: mixed().notRequired(),
    numOfOccurrences: mixed().notRequired(),
    lastAmount: string().notRequired(),
  });
};

const createPositiveAmountTest = (): { messageKey: MessageKey; validation: () => boolean } => ({
  messageKey: 'activities.paymentFlow.form.content.amountToPay.validGreaterThanZero.label',
  validation: (value?: string) => {
    if (!value) {
      return true;
    }
    const number = Number(value);
    return number > 0;
  },
});
