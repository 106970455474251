import { StatusModal, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import type { Collaborator } from '../../../types';

type Props = {
  isOpen: boolean;
  onApprove: VoidFunction;
  onDecline: VoidFunction;
  onClose: VoidFunction;
  isLoading?: boolean;
  collaborator?: Collaborator | null;
};

export const ApproveAccountingFirmModalScreen = ({
  isOpen,
  onApprove,
  onClose,
  onDecline,
  isLoading,
  collaborator,
}: Props) => {
  const { formatMessage } = useMelioIntl();
  return (
    <StatusModal
      data-testid="approve-accounting-firm-modal-screen"
      variant="informative"
      isOpen={isOpen}
      isLoading={isLoading}
      onClose={onClose}
      header={formatMessage('activities.manageCollaborators.screens.approveAccountingFirmModel.title')}
      primaryButton={{
        variant: 'primary',
        label: formatMessage('activities.manageCollaborators.screens.approveAccountingFirmModel.submitBtn'),
        onClick: onApprove,
        isDisabled: isLoading,
        isLoading,
      }}
      secondaryButton={{
        variant: 'secondary',
        label: formatMessage('activities.manageCollaborators.screens.approveAccountingFirmModel.cancel'),
        onClick: onDecline,
        isDisabled: isLoading,
        isLoading,
      }}
    >
      <Text>
        {formatMessage('activities.manageCollaborators.screens.approveAccountingFirmModel.content', {
          firmName: `${collaborator?.firstName || ''} ${collaborator?.lastName || ''}`,
        })}
      </Text>
    </StatusModal>
  );
};
