import { useMelioQueryClient } from '@melio/api-client';
import { TaxRatesApiClient, TaxRatesApiInstance } from '@melio/platform-api-axios-client';
import { useMemo } from 'react';

import { useCollection, UseCollectionProps } from '../../api-client';

export type UseTaxRatesProps = UseCollectionProps<
  typeof TaxRatesApiInstance.getTaxRates,
  typeof TaxRatesApiClient.postTaxRate,
  typeof TaxRatesApiClient.updateTaxRateById
>;

export const useTaxRates = ({ onUpdate, ...props }: UseTaxRatesProps = {}) => {
  const client = useMelioQueryClient();

  const query = useCollection({
    ...props,
    queryKey: 'TaxRatesApi',
    queryFn: TaxRatesApiInstance.getTaxRates,
    createFn: TaxRatesApiClient.postTaxRate,
    deleteFn: TaxRatesApiClient.deleteTaxRateById,
    updateFn: TaxRatesApiClient.updateTaxRateById,
    onUpdate: (data, ...args) => {
      void client.invalidateQueries('TaxRatesApi');
      void onUpdate?.(data, ...args);
    },
    populateModels: true,
  });

  return {
    ...query,
    defaultTaxRate: useMemo(() => query.data?.find((taxRate) => taxRate.isDefault) ?? query.data?.[0], [query.data]),
  };
};

export type TaxRateCollection = ReturnType<typeof useTaxRates>;
