import { withAnalyticsContext } from '@melio/platform-analytics';
import { Card, FundingSource } from '@melio/platform-api';
import { useState } from 'react';

import { AddBankAccountActivity } from '../AddBankAccount';
import { AddCardFundingSourceActivity } from '../AddCardFundingSource';
import { SelectPaymentMethodTypeScreen } from './screens/SelectPaymentMethodType/SelectPaymentMethodType.screen';
import { CreateFundingSourceActivityProps } from './types';
import { useCreateFundingSourceActivityStep } from './useCreateFundingSourceActivityStep';

export const CreateFundingSourceActivity = withAnalyticsContext<CreateFundingSourceActivityProps>(
  ({ onClose, onError, onDone, fundingSourceTypesOptions = [] }) => {
    const { currentStep, goToPreviousStep, goToStep } = useCreateFundingSourceActivityStep();
    const [cardType, setCardType] = useState<Card['type']>();

    const handleAdd = (type: FundingSource['type'] | Card['type']) => {
      if (type === 'bank-account') {
        goToStep('ADD_BANK');
      } else {
        if (type !== 'card') {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          setCardType(type);
        }

        goToStep('ADD_CARD');
      }
    };

    switch (currentStep) {
      case 'SELECT_PAYMENT_METHOD_TYPE':
      default:
        return (
          <SelectPaymentMethodTypeScreen
            onClose={onClose}
            onDone={handleAdd}
            fundingSourceTypesOptions={fundingSourceTypesOptions}
          />
        );

      case 'ADD_CARD':
        return (
          <AddCardFundingSourceActivity
            cardType={cardType}
            onClose={onClose}
            onBack={goToPreviousStep}
            onDone={onDone}
            onError={onError}
          />
        );

      case 'ADD_BANK':
        return <AddBankAccountActivity onClose={onClose} onBack={goToPreviousStep} onDone={onDone} onError={onError} />;
    }
  }
);

CreateFundingSourceActivity.displayName = 'CreateFundingSourceActivity';
