import { AutoPayment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export enum AutoPaymentAction {
  Create = 'create',
  Edit = 'edit',
}

export type AutoPaymentAmountDetails = {
  paymentAmountType: AutoPayment['paymentAmountType'];
  fixedAmount: AutoPayment['fixedAmount'];
};

export const useAutoPaymentActivationSuccessMessages = () => {
  const { formatMessage, formatCurrency } = useMelioIntl();

  const getTitleMessage = ({ action }: { action: AutoPaymentAction }) => {
    switch (action) {
      case AutoPaymentAction.Create:
        return formatMessage('activities.vendor.autoPaymentActivationFlow.screens.success.title');
      case AutoPaymentAction.Edit:
        return formatMessage('activities.vendor.autoPaymentEditFlow.screens.success.title');
    }
  };

  const getDescriptionMessage = ({
    autoPaymentAmountDetails,
    vendorName,
  }: {
    autoPaymentAmountDetails: AutoPaymentAmountDetails | undefined;
    vendorName: string;
  }) => {
    const { paymentAmountType, fixedAmount } = autoPaymentAmountDetails || {};

    switch (paymentAmountType) {
      case 'minimumAmountDue':
        return formatMessage('activities.vendor.autoPaymentActivationFlow.screens.success.minimumAmountDue.label', {
          vendorName,
        });
      case 'accountBalance':
        return formatMessage('activities.vendor.autoPaymentActivationFlow.screens.success.balance.label', {
          vendorName,
        });
      case 'fixedAmount': {
        if (fixedAmount) {
          return formatMessage('activities.vendor.autoPaymentActivationFlow.screens.success.fixedAmount.label', {
            vendorName,
            amount: formatCurrency(fixedAmount),
          });
        }
        return formatMessage('activities.vendor.autoPaymentActivationFlow.screens.success.amountDue.label', {
          vendorName,
        });
      }
      default:
        return formatMessage('activities.vendor.autoPaymentActivationFlow.screens.success.amountDue.label', {
          vendorName,
        });
    }
  };

  return {
    getTitleMessage,
    getDescriptionMessage,
  };
};
