import { apiClient, Collaborator, CollaboratorExpandableFields } from '@melio/javascript-sdk';

import { useCollectionApi, UseCollectionApiProps } from '../../core';

type Props<TData = never> = UseCollectionApiProps<Collaborator<'user'>, TData>;

export const useCollaborators = <TData = Collaborator<'user'>>({
  expand,
  ...props
}: Props<TData> & {
  expand?: CollaboratorExpandableFields[];
}) => {
  const client = apiClient.collaborators();

  const query = useCollectionApi<Collaborator<'user'>, never, never, TData>({
    ...props,
    queryKey: ['collaborator', expand],
    queryFn() {
      return client.fetch(expand) as unknown as Promise<Collaborator<'user'>[]>;
    },
  });

  return query;
};
