import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { every, isEmpty } from 'lodash';
import { Group } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useDeliveryMethods, useOrganizationPreferences } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { SectionHeader } from '@melio/platform-utils';

import { Loader } from '@/cl/components/Loader/Loader.component';
import { SettingsCardIdEnum } from '@/cl/components/SettingsCard/SettingsCard.component';
import { SettingsSectionContainer } from '@/cl/components/SettingsSectionContainer/SettingsSectionContainer.component';
import { useActiveScreen } from '@/hooks/useActiveScreen';
import { useArProcessingFeeEnabled } from '@/hooks/useArProcessingFeeEnabled';
import { useIsMsnDashboardDisplay } from '@/hooks/useIsMsnDashboardDisplay.hooks';
import { ActiveFlowEnum, ScreensEnum } from '@/store/app/app.types';
import { usePlatformIntl } from '@/translations/Intl';
import { AchTransferFee } from '@/widgets/settings-page/ReceivingMethodsPage/AchTransferFee';
import { CreditCardFeePayment } from '@/widgets/settings-page/ReceivingMethodsPage/CreditCardFeePayment.widget';
import { ReceivingMethodsSettingsPage } from '@/widgets/settings-page/ReceivingMethodsPage/ReceivingMethodsSettingsPage.widget';

export const ReceivingMethods = () => {
  useActiveScreen(ScreensEnum.settings, ActiveFlowEnum.receivingMethods);
  const { data: deliveryMethods, isLoading: isDeliveryMethodsLoading } = useDeliveryMethods({ isGetOwn: true });
  const { data: orgPreferences, isLoading: isOrgPreferencesLoading } = useOrganizationPreferences();

  const { shouldDisplayAchTransferFee, isLoading: isArProcessingCalculationLoading } = useArProcessingFeeEnabled();

  const isVendorAbsorbedFee = orgPreferences?.find((item) => item.key === 'isVendorAbsorbedFee')?.value;
  const isArEnabledOrgPreferences = orgPreferences?.find((item) => item.key === 'isArEnabled')?.value === 'true';

  const [arDashboardEnabled] = useFeature(FeatureFlags.ARDashboard, false);
  const isArEnabled = arDashboardEnabled || isArEnabledOrgPreferences;
  const isLoading = isDeliveryMethodsLoading || isOrgPreferencesLoading || isArProcessingCalculationLoading;
  const hasVerifiedDeliveryMethod = !every(deliveryMethods, ['details.verifiedStatus', 'not-verified']);
  const { track } = useAnalytics();

  const { formatMessage } = usePlatformIntl();

  const isMSNPortalVendor = useIsMsnDashboardDisplay();

  useEffect(() => {
    track('Settings', 'View', { PageName: SettingsCardIdEnum.ReceivingMethodsSettings });
  }, [track]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box>
      <SettingsSectionContainer data-component="ReceivingMethodsComponent">
        <ReceivingMethodsSettingsPage deliveryMethods={deliveryMethods} />
      </SettingsSectionContainer>
      {!isMSNPortalVendor && !isEmpty(deliveryMethods) && !isArEnabled && (
        <Box mt={8}>
          <SettingsSectionContainer>
            <Group variant="vertical" spacing="xl">
              <SectionHeader
                data-testid="transaction-fees-title"
                title={formatMessage('widgets.receivingMethodsSettings.transactionFees.title')}
              />
              <Group spacing="m" hasDivider variant="vertical">
                {shouldDisplayAchTransferFee && <AchTransferFee />}
                <CreditCardFeePayment disabled={!hasVerifiedDeliveryMethod} isVendorAbsorbedFee={isVendorAbsorbedFee} />
              </Group>
            </Group>
          </SettingsSectionContainer>
        </Box>
      )}
    </Box>
  );
};
