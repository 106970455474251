import React from 'react';
import { Stack } from '@chakra-ui/react';
import { Icon, Text } from '@melio/penny';
import { DeliveryMethodType } from '@melio/platform-api';
import { forwardRef } from '@melio/platform-utils';

import { MethodCard } from '@/cl/components/MethodCard/MethodCard.component';
import { useAppTheme } from '@/cl/theme/theme.hooks';
import { DeliveryMethodIconsEnum } from '@/types/vendors.types';
import { DeliveryMethodCardTheme } from '@/widgets/vendorsDashboard/DeliveryMethod/components/DeliveryMethodCard.component.theme';
import { useBillerAccountMessages } from '@/widgets/vendorsDashboard/DeliveryMethod/hooks/useBillerAccountMessages';

export const BillerAccountDeliveryMethodCard = forwardRef((props, ref) => {
  const styles = useAppTheme(DeliveryMethodCardTheme, {});
  const { displayName, helperText, additionalInfo } = useBillerAccountMessages();

  return (
    <MethodCard
      type={DeliveryMethodType.BillerAccount}
      icon={<Icon type={DeliveryMethodIconsEnum.RPPS} />}
      displayName={displayName}
      helperText={helperText}
      data-component="BillerAccountDeliveryMethodCard"
      ref={ref}
      {...props}
    >
      <Stack flexDirection="column" sx={styles['container']}>
        <Text>{additionalInfo}</Text>
      </Stack>
    </MethodCard>
  );
});

BillerAccountDeliveryMethodCard.displayName = 'BillerAccountDeliveryMethodCard';
