import { useInvoicePreference, useMelioIntl } from '@melio/ar-domain';
import { useToast } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { CustomPaymentDetailsFormFields, EditCustomPaymentDetailsDialogScreen } from './screens';

type EditInvoicePaymentOptionsDialogActivityProps = {
  onError?: ARErrorFunction;
  onDone: VoidFunction;
  onClose: VoidFunction;
};

export const EditInvoicePaymentOptionsDialogActivity = forwardRef<EditInvoicePaymentOptionsDialogActivityProps>(
  ({ onDone, onClose, onError, ...props }, ref) => {
    const { data: invoicePreferences, isFetching, isMutating, update } = useInvoicePreference({ onError });
    const { toast } = useToast();
    const { formatMessage } = useMelioIntl();

    const onSubmit = (values: CustomPaymentDetailsFormFields) => {
      update(values)
        .then(() => {
          onDone();
          toast({
            type: 'success',
            title: formatMessage(
              'ar.settings.flows.invoiceSettings.sections.editInvoiceCustomPaymentDetails.toast.success.label'
            ),
          });
        })
        .catch((err: ARPlatformError) => {
          toast({
            type: 'error',
            title: formatMessage(
              'ar.settings.flows.invoiceSettings.sections.editInvoiceCustomPaymentDetails.toast.failure.label'
            ),
          });
          onError?.(err);
        });
    };

    return (
      <EditCustomPaymentDetailsDialogScreen
        data-testid="edit-invoice-payment-options-dialog"
        onSubmit={onSubmit}
        onClose={onClose}
        isOpen
        defaultValues={{ customPayInstructions: invoicePreferences?.customPayInstructions ?? '' }}
        isLoading={isFetching}
        isSaving={isMutating}
        ref={ref}
        {...props}
      />
    );
  }
);
