import { Button, Container, Divider, Group, Text } from '@melio/penny';
import { useFundingSources } from '@melio/platform-api';
import {
  AccountAddress,
  Plan,
  SubscriptionBillingCycleEnum,
  SubscriptionPreview,
} from '@melio/platform-api-axios-client';
import { useMelioIntl } from '@melio/platform-i18n';

import { useCyclesPricing, useSubscriptionPreviewItems } from '../../../../../hooks';
import {
  SubscriptionCheckoutBillingTotal,
  SubscriptionCheckoutPromoCode,
  SubscriptionCheckoutSummaryPlan,
  SubscriptionDetailedTotal,
} from '../../../../components/SubscriptionCheckoutSummary';
import { SubscriptionCheckoutSummaryDisclaimer } from './SubscriptionCheckoutSummaryDisclaimer';

type SubscriptionCheckoutSummaryProps = {
  preview?: SubscriptionPreview;
  selectedPlan: Plan;
  isSubscribing: boolean;
  onSubmit: () => void;
  promoCode?: string | null;
  onChangePromoCode: (promoCode?: string) => void;
  onDoneFetchingPromoCodeData: () => void;
  currentCycle: SubscriptionBillingCycleEnum;
  isLoading: boolean;
  shouldPrefetchPromoCode: boolean;
  legalAddress: AccountAddress | null;
};
export const SubscriptionCheckoutSummary = ({
  preview,
  selectedPlan,
  isSubscribing,
  onSubmit,
  promoCode,
  onChangePromoCode,
  onDoneFetchingPromoCodeData,
  currentCycle,
  isLoading,
  shouldPrefetchPromoCode,
  legalAddress,
}: SubscriptionCheckoutSummaryProps) => {
  const { formatMessage } = useMelioIntl();
  const { freeTrialData, paidBillingItem: paidPeriodData } = useSubscriptionPreviewItems({
    subscriptionPreview: preview,
    plan: selectedPlan,
  });

  const { data: fundingSources } = useFundingSources();
  const haveFundingSources = !!fundingSources?.length;

  const isAnnualCycle = currentCycle === SubscriptionBillingCycleEnum.Annual;
  const { getAnnualPlanSavingPercents } = useCyclesPricing();

  return (
    <Group variant="vertical" spacing="s">
      <Text as="h2" textStyle="body2Semi" color="global.neutral.800">
        {formatMessage('activities.subscription.checkout.summary.title')}
      </Text>

      <Container border="regular" paddingX="m" paddingY="m">
        <Group variant="vertical" spacing="m">
          <SubscriptionCheckoutSummaryPlan
            selectedPlan={selectedPlan}
            currentCycle={currentCycle}
            preview={preview}
            isLoading={isLoading}
            predefinedDiscountPercentage={isAnnualCycle ? getAnnualPlanSavingPercents() : undefined}
          />

          <Divider />

          <SubscriptionCheckoutPromoCode
            promoCode={promoCode}
            isLoading={isLoading}
            shouldPrefetchPromoCode={shouldPrefetchPromoCode}
            preview={preview}
            onChangePromoCode={onChangePromoCode}
            onDoneFetchingPromoCodeData={onDoneFetchingPromoCodeData}
          />

          <SubscriptionDetailedTotal
            legalAddress={legalAddress}
            preview={preview}
            freeTrialPeriodData={freeTrialData}
            paidPeriodData={paidPeriodData}
            isLoading={isLoading}
          />

          <Divider />
          <SubscriptionCheckoutBillingTotal preview={preview} isFreeTrial={!!freeTrialData} isLoading={isLoading} />

          <Button
            label={
              freeTrialData
                ? formatMessage('activities.subscription.checkout.summary.submitWithFreeTrial')
                : formatMessage('activities.subscription.checkout.summary.submit')
            }
            size="large"
            onClick={onSubmit}
            isLoading={isSubscribing}
            isDisabled={!haveFundingSources || isLoading}
            data-testid="subscription-checkout-submit"
          />
          <SubscriptionCheckoutSummaryDisclaimer
            plan={selectedPlan}
            isLoading={isLoading}
            planCycle={currentCycle}
            preview={preview}
          />
        </Group>
      </Container>
    </Group>
  );
};
