import { useModelApi, UseModelApiProps } from '@melio/api-client';
import { ApprovalWorkflow, ApprovalWorkflowsApiClient } from '@melio/platform-api-axios-client';

type QueryFN = typeof ApprovalWorkflowsApiClient.getApprovalWorkflowsApprovalWorkflowId;
type UpdateFN = typeof ApprovalWorkflowsApiClient.updateApprovalWorkflowsApprovalWorkflowId;
type DeleteFN = never;
type CreateFN = typeof ApprovalWorkflowsApiClient.postApprovalWorkflows;

type Props = UseModelApiProps<QueryFN, ApprovalWorkflow> & {
  onCreate?: (workflow: ApprovalWorkflow) => void;
  onCreateError?: ErrorFunction;
  onUpdate?: () => void;
  onUpdateError?: ErrorFunction;
};

export const useApprovalWorkflow = (props: Props = {}) => {
  const query = useModelApi<QueryFN, UpdateFN, DeleteFN, CreateFN>({
    ...props,
    queryKey: 'ApprovalWorkflowsApi',
    createFn: ApprovalWorkflowsApiClient.postApprovalWorkflows,
    queryFn: ApprovalWorkflowsApiClient.getApprovalWorkflowsApprovalWorkflowId,
    updateFn: ApprovalWorkflowsApiClient.updateApprovalWorkflowsApprovalWorkflowId,
  });

  return query;
};
