import React, { useEffect, useRef } from 'react';
import { Link, Modal } from '@melio/penny';

import { useAccountCentricViewCase } from '@/hooks/useAccountCentricViewCase';
import { useDisclosure } from '@/hooks/useDisclosure';
import { usePlatformIntl } from '@/translations/Intl';
import { AccountCentricViewMessagingModalContent } from './AccountCentricViewMessagingModalContent.component';

export const AccountCentricViewMessagingModal = () => {
  const { shouldPopupModal, onCloseModal } = useAccountCentricViewCase();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { formatMessage } = usePlatformIntl();
  const isModalSeenRef = useRef(false);

  useEffect(() => {
    if (!isModalSeenRef.current && shouldPopupModal) {
      onOpen();
      isModalSeenRef.current = true;
    }
  }, [shouldPopupModal, onOpen]);

  const openModal = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onOpen();
  };

  const closeModal = () => {
    void onCloseModal();
    onClose();
  };

  return (
    <>
      <Link
        href="#"
        label={formatMessage('widgets.accountCentricViewMessaging.banner.link')}
        onClick={openModal}
        variant="inline"
      />
      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        header={formatMessage('widgets.accountCentricViewMessaging.modal.title')}
        primaryButton={{
          label: formatMessage('widgets.accountCentricViewMessaging.modal.okButton'),
          onClick: closeModal,
          variant: 'primary',
        }}
        footer={undefined}
      >
        <AccountCentricViewMessagingModalContent />
      </Modal>
    </>
  );
};
