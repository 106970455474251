import { Box } from '@chakra-ui/react';
import {
  BillingFeeSetting,
  FeeCatalog,
  hasAchToCheckBillingFeeMethod,
  hasAchToCheckFeeType,
  PaymentFee,
  PaymentIntent,
  useFeeCatalog,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { sumBy } from '@melio/platform-utils';
import React from 'react';

export const getTotalFee = (estimatedFees: PaymentIntent['estimatedFees']) =>
  sumBy(estimatedFees || [], (fee) => fee.amount);

export const getPercent = (paymentFee: PaymentFee, feeCatalogEntries?: FeeCatalog[]) => {
  const matchingEntry = feeCatalogEntries?.find((entry) => entry.feeType === paymentFee.type);
  if (!matchingEntry || matchingEntry.valueType !== 'percent') {
    return;
  }
  const isCapped = paymentFee.amount === matchingEntry.cap;
  return !isCapped ? matchingEntry.value : undefined;
};

export const getMessageKey = (hasFeeBillingMethod: boolean | undefined) =>
  hasFeeBillingMethod
    ? 'activities.batchPayments.screens.paymentIntentsTable.amountCell.detailedFeesTooltip.title.withBillingFeeMethod'
    : 'activities.batchPayments.screens.paymentIntentsTable.amountCell.detailedFeesTooltip.title';

export const usePaymentFees = (
  estimatedFees: PaymentIntent['estimatedFees'],
  orgBillingFeeSettings: BillingFeeSetting[]
) => {
  const { formatMessage, formatCurrency, formatPercents } = useMelioIntl();

  const { data: feeCatalogEntries } = useFeeCatalog();
  const {
    settings: {
      batchPayments: { showFeesTooltipTitle },
    },
  } = useConfig();

  const orgHasBillingFeeMethod = hasAchToCheckBillingFeeMethod(orgBillingFeeSettings);
  const hasAchToCheckFees = hasAchToCheckFeeType(estimatedFees);

  const tooltipMessageKey = getMessageKey(orgHasBillingFeeMethod && hasAchToCheckFees);
  const feesTooltipTitle = formatMessage(tooltipMessageKey);
  const feesWithAmount = estimatedFees?.filter((fee) => fee.amount);

  const getFeesTooltip = () =>
    feesWithAmount?.length
      ? {
          title: showFeesTooltipTitle
            ? ((
                <Box data-testid="batch-payments-amount-cell-fees-tooltip-title">{feesTooltipTitle}</Box>
              ) as unknown as string)
            : undefined,
          label: (
            <>
              {showFeesTooltipTitle && <br />}
              {feesWithAmount.map((fee, index) => {
                const feeAmount = formatCurrency(fee.amount);
                const feePercent = getPercent(fee, feeCatalogEntries);

                const feeMessage = feePercent
                  ? formatMessage(
                      `activities.batchPayments.screens.paymentIntentsTable.amountCell.detailedFeesTooltip.fees.${fee.type}.withPercent`,
                      {
                        feeAmount,
                        feePercent: formatPercents(feePercent, { divide: true }),
                      }
                    )
                  : formatMessage(
                      `activities.batchPayments.screens.paymentIntentsTable.amountCell.detailedFeesTooltip.fees.${fee.type}.withoutPercent`,
                      {
                        feeAmount,
                      }
                    );

                return (
                  <React.Fragment key={fee.type}>
                    {index > 0 && <br />}
                    {feeMessage}
                  </React.Fragment>
                );
              })}
            </>
          ) as unknown as string,
        }
      : undefined;

  return { getFeesTooltip };
};
