import { TableColumnDef, useBreakpoint } from '@melio/penny';
import { Collaborator } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMemo } from 'react';

import { ApprovalLimitHeaderCell } from './components/ApprovalLimitHeaderCell';
import { ActionCell } from './components/cells/ActionCell';
import { ApprovalLimitCell } from './components/cells/ApprovalLimitCell';
import { RoleCell } from './components/cells/RoleCell';
import { UserCell } from './components/cells/UserCell';
import { CollaboratorsTableMeta } from './types';
import { useAllowedActions } from './useAllowedActions';

export const useCollaboratorsTableColumns = ({ actor }: { actor?: Collaborator<'user'> }) => {
  const { formatMessage } = useMelioIntl();
  const { canViewPaymentApprovalLimit } = useAllowedActions();
  const { isSmallScreen } = useBreakpoint();

  return useMemo<TableColumnDef<Collaborator<'user'>, CollaboratorsTableMeta>[]>(() => {
    const userCell: TableColumnDef<Collaborator<'user'>, CollaboratorsTableMeta> = {
      id: 'user',
      size: 'm',
      isPinnedToLeft: !isSmallScreen,
      header: formatMessage('activities.settings.collaborators.table.headers.user'),
      cell: ({ row }) => (actor ? <UserCell actor={actor} collaborator={row} /> : null),
    };
    const roleCell: TableColumnDef<Collaborator<'user'>, CollaboratorsTableMeta> = {
      id: 'role',
      size: 140,
      header: formatMessage('activities.settings.collaborators.table.headers.role'),
      cell: ({ row }) => actor && <RoleCell actor={actor} collaborator={row} />,
    };
    const approvalLimitCell: TableColumnDef<Collaborator<'user'>, CollaboratorsTableMeta> = {
      id: 'approvalLimit',
      size: 165,
      header: <ApprovalLimitHeaderCell />,
      cell: ({ row }) => <ApprovalLimitCell collaborator={row} />,
    };
    const actionCell: TableColumnDef<Collaborator<'user'>, CollaboratorsTableMeta> = {
      id: 'action',
      size: 140,
      header: formatMessage('activities.settings.collaborators.table.headers.action'),
      textAlign: 'end',
      cell: ({ row, meta }) =>
        meta && actor ? (
          <ActionCell
            actor={actor}
            collaborator={row}
            onViewClick={meta?.onViewClick}
            onEditClick={meta?.onEditClick}
          />
        ) : null,
    };

    if (!canViewPaymentApprovalLimit) {
      return [userCell, roleCell, actionCell];
    }
    return [userCell, roleCell, approvalLimitCell, actionCell];
  }, [isSmallScreen, formatMessage, canViewPaymentApprovalLimit, actor]);
};
