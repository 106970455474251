import {
  Payment,
  PaymentMetadataRefundStatusEnum,
  PaymentMetadataVoidCheckStatusEnum,
  PaymentStatusEnum,
} from '@melio/platform-api';

export const getPaymentAmount = (amount: number, foreignAmount?: number | null): number => foreignAmount || amount;

export const isPartialPayment = (payment: Payment) => {
  const paymentAmount = getPaymentAmount(payment.amount, payment.foreignAmount);
  return (
    payment.bills?.length === 1 &&
    payment.bills?.[0]?.amount &&
    paymentAmount < payment.bills?.[0]?.amount &&
    payment.status !== PaymentStatusEnum.Canceled
  );
};
export const isValidAccountNumber = (str: string) => !str.includes('*');
const isRefund = (payment: Payment): boolean => !!payment.metadata?.isRefund;
const isVoidCheck = (payment: Payment): boolean => !!payment.metadata?.isVoidCheck;
const isVoidCheckStatusInProgressOrCompleted = (payment: Payment) =>
  payment?.metadata?.voidCheckStatus === PaymentMetadataVoidCheckStatusEnum.InProgress ||
  payment?.metadata?.voidCheckStatus === PaymentMetadataVoidCheckStatusEnum.Completed;

export const isPaymentFailedRefund = (payment: Payment): boolean =>
  !isVoidCheck(payment) &&
  isRefund(payment) &&
  payment?.metadata?.refundStatus === PaymentMetadataRefundStatusEnum.Failed;

export const isPaymentScheduledRefund = (payment: Payment): boolean =>
  !isVoidCheck(payment) &&
  isRefund(payment) &&
  payment?.metadata?.refundStatus === PaymentMetadataRefundStatusEnum.InProgress;

export const isPaymentCompletedRefund = (payment: Payment): boolean =>
  !isVoidCheck(payment) &&
  isRefund(payment) &&
  payment?.metadata?.refundStatus === PaymentMetadataRefundStatusEnum.Completed;

export const isPaymentScheduledVoid = (payment: Payment): boolean =>
  isVoidCheck(payment) &&
  !isRefund(payment) &&
  payment?.metadata?.voidCheckStatus === PaymentMetadataVoidCheckStatusEnum.InProgress;

export const isPaymentScheduledVoidToRefund = (payment: Payment): boolean =>
  isPaymentScheduledVoid(payment) && Boolean(payment?.metadata?.isVoidingToRefund);

export const isPaymentScheduledVoidToResend = (payment: Payment): boolean =>
  isPaymentScheduledVoid(payment) && Boolean(payment?.metadata?.isVoidingToResend);

export const isPaymentScheduledVoidAndRefund = (payment: Payment): boolean =>
  isVoidCheck(payment) &&
  isRefund(payment) &&
  isVoidCheckStatusInProgressOrCompleted(payment) &&
  payment?.metadata?.refundStatus === PaymentMetadataRefundStatusEnum.InProgress;

export const isPaymentCompletedVoidAndRefund = (payment: Payment): boolean =>
  isVoidCheck(payment) &&
  isRefund(payment) &&
  isVoidCheckStatusInProgressOrCompleted(payment) &&
  payment?.metadata?.refundStatus === PaymentMetadataRefundStatusEnum.Completed;
