import { ApiPagination, CustomersDashboardSortQueryParam, DashboardCustomer } from '@melio/ar-domain';
import { Group, Pagination, Table, useTable } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { useCallback } from 'react';

import { CustomerActions, OnSort } from '../types';
import { CustomersTableListItem } from './CustomersTableListItem.mobile';
import { useCustomersTableColumns } from './hooks';

export type CustomersTableProps = {
  customers: DashboardCustomer[];
  tableActions: CustomerActions;
  pagination: ApiPagination;
  sortParams?: CustomersDashboardSortQueryParam;
  onSort: OnSort;
  isLoading?: boolean;
};

export const CustomersTable = forwardRef<CustomersTableProps>(
  ({ customers, isLoading, tableActions, pagination, sortParams, onSort, ...props }, ref) => {
    const columns = useCustomersTableColumns({ sortParams, onSort });
    const mobileRowRenderer = useCallback(
      (row: DashboardCustomer, meta?: CustomerActions) => (
        <CustomersTableListItem customer={row} customerActions={meta} />
      ),
      []
    );

    const tableProps = useTable<DashboardCustomer, CustomerActions>({
      isLoading,
      data: customers,
      columns,
      headerVariant: 'dark',
      meta: tableActions,
      mobileRowRenderer,
    });

    return (
      <Group variant="vertical" alignItems="flex-end" spacing="l">
        <Table
          data-testid="customers-table"
          data-component={CustomersTable.displayName}
          isLoading={isLoading}
          {...props}
          {...tableProps}
          ref={ref}
        />
        {!isLoading && pagination.totalCount > pagination.limit && (
          <Pagination
            currentPage={pagination.pageNumber}
            pageSize={pagination.limit}
            totalItems={pagination.totalCount}
            onPageChange={pagination.goToPage}
          />
        )}
      </Group>
    );
  }
);
CustomersTable.displayName = 'CustomersTable';
