import { ComponentThemeType } from '@/cl/theme/theme.hooks';

export const ContactSummaryTheme: ComponentThemeType = (_currentTheme) => ({
  baseStyle: {
    container: {
      gridGap: '8px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      overflow: 'hidden',
    },
    contactDetails: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: 0,
    },
  },
  sizes: {
    'extra-small': {
      checkbox: {
        pl: 0,
      },
    },
    small: {
      checkbox: {
        pl: 0,
      },
    },
    medium: {
      checkbox: {
        pl: '8px',
      },
    },
    large: {
      checkbox: {
        pl: '12px',
      },
    },
  },
});
