import { useState } from 'react';

import { AddCardFundingSourceActivityProps, AddCardFundingSourceActivityStep } from './types';

const initialStep: AddCardFundingSourceActivityStep = 'ADD_CARD_DETAILS';

export const useAddCardFundingSourceActivityStep = ({
  onFirstStepBack,
}: {
  onFirstStepBack: AddCardFundingSourceActivityProps['onBack'];
}) => {
  const [currentStep, setCurrentStep] = useState<AddCardFundingSourceActivityStep>(initialStep);

  const goToPreviousStep = (): void => {
    if (currentStep === initialStep) {
      onFirstStepBack();
    }

    const previousStepMap: Partial<Record<AddCardFundingSourceActivityStep, AddCardFundingSourceActivityStep>> = {
      ADD_CARD_HOLDER_DETAILS: 'ADD_CARD_DETAILS',
    };

    setCurrentStep(previousStepMap[currentStep] ?? initialStep);
  };

  return {
    currentStep,
    goToPreviousStep,
    goToStep: setCurrentStep,
  };
};
