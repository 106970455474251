import { Container } from '@melio/penny';
import { useDebounce } from '@melio/platform-utils';
import { ComponentProps } from 'react';

export function AriaLive(
  props: Omit<ComponentProps<typeof Container>, 'aria-live' | 'aria-relevant'> & { shown: boolean }
) {
  const debounced = useDebounce(props.shown, 0);

  const { shown, children, ...rest } = props;

  const parentRender = shown || debounced;
  const childRender = shown && debounced;

  return parentRender ? (
    <Container {...rest} aria-live="polite" aria-relevant="additions removals">
      {childRender ? children : null}
    </Container>
  ) : null;
}
