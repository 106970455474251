import {
  Bill,
  BillSubscriptionEndPolicyEnum,
  BillSubscriptionIntervalTypeEnum,
  BillSubscriptionOptions,
  DeliveryPreferenceType,
  Payment,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { isHoliday } from '@melio/platform-utils';
import { getDay, isAfter, isPast } from 'date-fns';
import { date, mixed, number, object, SchemaOf, string, StringSchema, TestFunction } from 'yup';

import { PaymentFlowFormFields } from '../../../types';
import { useAmountSchema } from './useAmountSchema';

export const usePaymentFlowFormSchema = (options?: {
  bill: Bill | undefined;
  payment: Payment | undefined;
  billSubscriptionOptions: BillSubscriptionOptions | undefined;
}): SchemaOf<PaymentFlowFormFields> => {
  const { formatMessage } = useMelioIntl();
  const { bill, payment, billSubscriptionOptions } = options || {};
  const { amountToPay, lastAmount } = useAmountSchema({ bill, billSubscriptionOptions, payment });
  const deliveryDateTest = createDateTest(false);
  const startDateTest = createDateTest(false);
  const endDateTest = createDateTest(false);

  return object().shape({
    amountToPay: amountToPay(),
    vendorId: string().nullable().required(formatMessage('activities.paymentFlow.form.content.vendor.required')),
    deliveryMethodId: string().required(formatMessage('activities.paymentFlow.form.content.deliveryMethod.required')),
    fundingSourceId: string().required(formatMessage('activities.paymentFlow.form.content.fundingSource.required')),
    scheduleDate: date().nullable().required(),
    deliveryDate: date().when('vendorId', {
      is: (vendorId: string) => !!vendorId,
      then: validDate(
        deliveryDateTest,
        formatMessage('activities.paymentFlow.form.content.deliveryDate.invalid')
      ).required(formatMessage('activities.paymentFlow.form.content.deliveryDate.required')),
      otherwise: validDate(
        deliveryDateTest,
        formatMessage('activities.paymentFlow.form.content.deliveryDate.invalid')
      ).notRequired(),
    }),
    deliveryPreference: new StringSchema<DeliveryPreferenceType>(),
    noteToVendor: string().optional().nullable(),
    vendorEmail: string()
      .email(formatMessage('activities.paymentFlow.form.content.vendorEmail.validation.format'))
      .optional()
      .nullable(),

    paymentPurpose: string(),
    description: string(),
    invoice: mixed<File>(),

    recurrenceType: new StringSchema<'one_time' | 'recurring'>().oneOf(['one_time', 'recurring']).required(),
    intervalType: mixed().when('recurrenceType', {
      is: 'recurring',
      then: new StringSchema<BillSubscriptionIntervalTypeEnum>().required(
        formatMessage('activities.paymentFlow.form.content.recurring.frequency.required')
      ),
      otherwise: new StringSchema<BillSubscriptionIntervalTypeEnum>().notRequired(),
    }),
    startDate: mixed().when('recurrenceType', {
      is: 'recurring',
      then: validDate(
        startDateTest,
        formatMessage('activities.paymentFlow.form.content.recurring.firstPaymentDeliverBy.invalid')
      ).required(
        billSubscriptionOptions
          ? formatMessage('activities.paymentFlow.form.content.recurring.nextPaymentDeliverBy.required')
          : formatMessage('activities.paymentFlow.form.content.recurring.firstPaymentDeliverBy.required')
      ),
      otherwise: validDate(
        startDateTest,
        formatMessage('activities.paymentFlow.form.content.recurring.firstPaymentDeliverBy.invalid')
      ).notRequired(),
    }),
    endPolicy: mixed().when('recurrenceType', {
      is: 'recurring',
      then: new StringSchema<BillSubscriptionEndPolicyEnum>().required(
        formatMessage('activities.paymentFlow.form.content.recurring.paymentDuration.required')
      ),
      otherwise: new StringSchema<BillSubscriptionEndPolicyEnum>().notRequired(),
    }),
    endDate: mixed().when('endPolicy', {
      is: BillSubscriptionEndPolicyEnum.EndDate,
      then: validDate(endDateTest, formatMessage('activities.paymentFlow.form.content.recurring.endDate.invalid'))
        .test('isAfterStartDate', (endDate, context) => {
          const { startDate } = context.parent as PaymentFlowFormFields;
          if (!startDate || !endDate) {
            return true;
          }
          return (
            isAfter(endDate, startDate) ||
            context.createError({
              message: formatMessage('activities.paymentFlow.form.content.recurring.endDate.invalid.before-start-date'),
            })
          );
        })
        .required(formatMessage('activities.paymentFlow.form.content.recurring.endDate.required')),
      otherwise: validDate(
        endDateTest,
        formatMessage('activities.paymentFlow.form.content.recurring.endDate.invalid')
      ).notRequired(),
    }),
    numOfOccurrences: mixed().when('endPolicy', {
      is: BillSubscriptionEndPolicyEnum.NumOfOccurrences,
      then: number().required(formatMessage('activities.paymentFlow.form.content.recurring.occurrences.required')),
      otherwise: number().notRequired(),
    }),
    lastAmount: lastAmount(),
  });
};

const validDate = (test: TestFunction<Date | null | undefined>, message: string) =>
  date().nullable().test('isValidDate', message, test);

const createDateTest =
  (allowHoliday: boolean, validWeekDays: (0 | 1 | 2 | 3 | 4 | 5 | 6)[] = [1, 2, 3, 4, 5]) =>
  (date: Date | null | undefined) => {
    if (!date) {
      return true;
    }

    if (!allowHoliday && isHoliday(date)) {
      return false;
    }

    if (isPast(date)) {
      return false;
    }

    return validWeekDays.includes(getDay(date));
  };
