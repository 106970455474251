// eslint-disable-next-line import/no-extraneous-dependencies
import { useMelioIntl } from '@melio/ar-domain';
import { validateCompanyName, ValidationResponse } from '@melio/compliance-validator';
import { BaseSchema, string, TestFunction } from 'yup';

type ValidationErrorMessages =
  | 'ar.mtl.risk.validations.CompanyNameMustBe3CharsOrMore'
  | 'ar.mtl.risk.validations.CompanyNameMustHaveAtLeast2CharsAlphanumeric';

type Validator<T> = (value: T) => ValidationResponse;

export type MtlValidationFields = 'customerName';

export type MtlMandatoryValidationFields = Partial<Record<MtlValidationFields, boolean>>;

export const useRiskMtlSchemaValidations = (mandatoryFields: MtlMandatoryValidationFields) => {
  const { formatMessage } = useMelioIntl();

  const customTest = <T>(validator: Validator<T>): TestFunction<T> =>
    function (value: T) {
      const { isValid, errors } = validator(value);

      if (!isValid) {
        const error = errors?.[0];
        return this.createError({
          path: this.path,
          message: error && formatMessage(`ar.mtl.risk.validations.${error}` as ValidationErrorMessages),
        });
      }

      return true;
    };

  const isNotOptional = (fieldName: MtlValidationFields, schema: BaseSchema) => (value: unknown) =>
    !mandatoryFields[fieldName] && !value ? string().nullable().optional() : schema;

  const withOptional = <T = string>(fieldName: MtlValidationFields, schema: BaseSchema, validator: Validator<T>) =>
    schema.when(isNotOptional(fieldName, schema.test(customTest(validator))));

  return {
    customerName: () =>
      withOptional(
        'customerName',
        string().required(formatMessage('ar.mtl.risk.validations.CustomerNameMissing')),
        validateCompanyName
      ),
  };
};
