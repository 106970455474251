import { object, SchemaOf, string } from 'yup';
import { validateInvalidChars } from '@melio/ap-widgets';
import { EditVendorErrorCode } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { VendorDetailsMangedByFiservFormFields } from './types';
import { FormFields } from './types';

export const formErrors: Record<EditVendorErrorCode | string, FormFields | 'toast'> = {
  invalid_payee_account_number_1205: 'accountNumber',

  // Banner level errors
  payee_not_found: 'toast',
  invalid: 'toast',
  require_payee_name: 'toast',
  invalid_payee: 'toast',
  active_payee_exists_1215: 'toast',
  non_modifiable_fields_1253: 'toast',
  payee_inactive_1214: 'toast',
  modification_not_allowed: 'toast',
  cannot_modify_personal_payee: 'toast',
  cannot_modify_payee: 'toast',
  subscriber_invalid_status: 'toast',
};

export const useFormSchema = (): SchemaOf<VendorDetailsMangedByFiservFormFields> => {
  const { formatMessage } = useMelioIntl();
  return object().shape({
    companyName: string().trim().required(formatMessage('widgets.vendorDetails.form.companyName.validation.required')),
    nickname: string()
      .nullable()
      .max(30, formatMessage('widgets.vendorDetails.form.nickname.validation.maxLength'))
      .test(
        'validate-invalid-chars',
        '',
        validateInvalidChars(/[ ,.\-0-9A-Za-z\r\n]*/, (invalidChars) =>
          formatMessage('widgets.vendorDetails.form.nickname.validation.format', {
            invalidChars,
          }),
        ),
      ),
    accountNumber: string()
      .nullable()
      .required(formatMessage('widgets.vendorDetails.form.accountNumber.validation.required'))
      .max(32, formatMessage('widgets.vendorDetails.form.accountNumber.validation.maxLength'))
      .test(
        'validate-invalid-chars',
        '',
        validateInvalidChars(/[!"#$%&\-0-9A-Za-z]*/, (invalidChars) =>
          formatMessage('widgets.vendorDetails.form.accountNumber.validation.format', {
            invalidChars,
          }),
        ),
      ),
    confirmAccountNumber: string()
      .required(formatMessage('widgets.vendors.confirmAccountNumber.validation.required'))
      .test(
        'match-account-number',
        formatMessage('widgets.vendors.confirmAccountNumber.validation.match'),
        (confirmAccountNumber, context) =>
          confirmAccountNumber === (context.parent as VendorDetailsMangedByFiservFormFields).accountNumber,
      ),
  });
};
