import { useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { ActionsDropdownMenu, ActionsDropdownMenuItemProps, Card, StatusIndicator, Text, useTheme } from '@melio/penny';

import { PaymentMethodsIcon } from '@/cl/icons/system';
import { usePlatformIntl } from '@/translations/Intl';

type Props = {
  actions: ActionsDropdownMenuItemProps[];
};

export const BlockedSelectedMethodCard = ({ actions }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { formatMessage } = usePlatformIntl();
  const theme = useTheme();

  return (
    <Card variant="flat" paddingX="m" paddingY="m" data-testid="billing-method-card">
      <Flex w="inherit" justify="space-between" align="flex-start">
        <Flex align="center">
          <Flex mr="s">
            <StatusIndicator data-testid="sync-now-button-error-indicator" status="success">
              <PaymentMethodsIcon />
            </StatusIndicator>
          </Flex>
          <Flex direction="column">
            <Flex align="center" mb="xxs">
              <Box mr="xxs">
                <Text textStyle="body2Semi">
                  {formatMessage('widgets.billingSettings.paymentMethod.card.assignedByFirm.title')}
                </Text>
              </Box>
            </Flex>
            <Box textStyle={{ xs: 'body5Semi', s: 'body4' }} color={theme.colors.global.neutral['800']}>
              <Text>{formatMessage('widgets.billingSettings.paymentMethod.card.assignedByFirm.description')}</Text>
            </Box>
          </Flex>
        </Flex>
        <ActionsDropdownMenu
          isOpen={isMenuOpen}
          onOpenChange={setIsMenuOpen}
          size="small"
          label="menu action"
          data-testid="billing-method-actions-menu"
          items={actions}
        />
      </Flex>
    </Card>
  );
};
