import { useIsFirmOnClientOrganization, useIsSubscriptionBillingPayorIsFirm } from '../../../../hooks';

export const useManagePlanPermissions = () => {
  const { isLoading, isFirmOnClientOrganization } = useIsFirmOnClientOrganization();
  const isSubscriptionPaidByFirm = useIsSubscriptionBillingPayorIsFirm();

  return {
    shouldRequestPlanChangeFromAccountant: isSubscriptionPaidByFirm && !isFirmOnClientOrganization,
    isLoading,
  };
};
