import { Route, Routes } from 'react-router-dom';
import { BillingFeeActivity } from '@melio/ap-activities';

import { useRouter } from '@/hooks/router.hooks';

export const BillingMethodsRoute = () => {
  const { goToSettingsBilling } = useRouter();
  return (
    <Routes>
      <Route path="/*" element={<BillingFeeActivity goToSettingsBilling={goToSettingsBilling} />} />
    </Routes>
  );
};
