import { Card, Container, Group, Icon } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';

import { AvailableCredit } from './components/AvailableCredit.component';
import { Title } from './components/Title.component';

type PayWithFinancingCardComponentProps = {
  onClick: VoidFunction;
  isDisabled: boolean;
  isSelected: boolean;
  availableCredit?: number;
  testId?: string;
};

export const PayWithFinancingCardComponent = forwardRef<PayWithFinancingCardComponentProps>(
  ({ onClick, isDisabled, isSelected, availableCredit, testId, ...props }, ref) => (
    <Card
      data-component="PayWithFinancingCard"
      data-testid={testId ?? 'pay-with-financing-card'}
      onClick={onClick}
      isSelected={isSelected}
      isDisabled={isDisabled}
      ref={ref}
      {...props}
    >
      <Group width="full">
        <Group.Item shrink={0}>
          <Container alignItems="center" width="fit-content" height="full">
            <Icon size="large" type="installments" isDisabled={isDisabled} />
          </Container>
        </Group.Item>
        <Group.Item grow={1}>
          <Title isDisabled={isDisabled} />
        </Group.Item>
        <AvailableCredit availableCredit={availableCredit} isDisabled={isDisabled} />
      </Group>
    </Card>
  )
);
