import { usePlatformIntl } from '@/translations/Intl';

export const useBillerAccountMessages = () => {
  const { formatMessage } = usePlatformIntl();
  const displayName = formatMessage('widgets.addOrUpdateDeliveryMethod.billerAccount.title');
  const helperText = formatMessage('widgets.addOrUpdateDeliveryMethod.billerAccount.feeText');
  const additionalInfo = formatMessage('widgets.addOrUpdateDeliveryMethod.billerAccount.info.additionalInfo');

  return { displayName, helperText, additionalInfo };
};
