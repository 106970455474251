import { useNonEntityMutation } from '@melio/api-client';
import { apiClient, PostPlaidLinkTokenResponseData } from '@melio/javascript-sdk';
import {
  ApiKeys,
  ApiRequest,
  DeliveryMethodsApiClient,
  PostDeliveryMethodsPlaidLinkTokenRequest,
  PostPlaidLinkTokenRequest,
  PostPlaidLinkTokenResponse,
} from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useIsFetching } from 'react-query';

import { useModelApi, UseModelApiProps } from '../../core';
import { PlaidLinkTokenModel } from './types';

type PostDeliveryMethodsPlaidLinkTokenFn = ApiRequest<PostPlaidLinkTokenResponse>;

export const useDeliveryMethodPlaidLinkToken = () =>
  useNonEntityMutation<PostDeliveryMethodsPlaidLinkTokenFn, unknown, PostPlaidLinkTokenResponse>(
    (props: PostDeliveryMethodsPlaidLinkTokenRequest) =>
      DeliveryMethodsApiClient.postDeliveryMethodsPlaidLinkToken(props),
    [ApiKeys.DeliveryMethodsApi, 'collection', 'postDeliveryMethodsPlaidLinkToken']
  );

export const useFundingSourcePlaidLinkToken = (
  props: UseModelApiProps<PostPlaidLinkTokenResponseData> & { params: PostPlaidLinkTokenRequest }
): PlaidLinkTokenModel => {
  const client = apiClient.plaidLinkTokens();

  const query = useModelApi<PostPlaidLinkTokenResponseData>({
    ...props,
    id: 'my-token-id', // trick the system to fetch without an actual id
    queryKey: ['plaid-link-token', props.params.type, props.params.details?.fundingSourceId],
    queryFn: () => client.create(props.params),
  });

  return query;
};

export const useIsPlaidLinkVerificationTokenFetching = (fundingSourceId: string): boolean => {
  const { queryKey } = useFundingSourcePlaidLinkToken({
    params: { type: 'verification', details: { fundingSourceId } },
    enabled: false,
  });
  return !!useIsFetching(queryKey);
};
