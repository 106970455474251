import { Button } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useUserPreferences } from '@melio/platform-api';

import { useRouter } from '@/hooks/router.hooks';
import { usePlatformIntl } from '@/translations/Intl';

const payDashboardTourPreferenceKey = 'payDashboardTourActive';

export const LaunchTourButton = () => {
  const { goToNewDashboardTab } = useRouter();
  const { create, isMutating } = useUserPreferences({ enabled: false });
  const { formatMessage } = usePlatformIntl();
  const { track } = useAnalytics();

  const handleClick = async () => {
    track('Settings', 'Click', {
      PageName: 'profile-settings',
      Flow: 'settings',
      Intent: 'launch-tour',
      EntryPoint: 'settings-page',
      Cta: 'launch-tour',
    });

    await create({ userPreference: { key: payDashboardTourPreferenceKey, value: 'true' } });
    goToNewDashboardTab('vendors');
  };

  return (
    <Button
      variant="tertiary"
      label={formatMessage('app.settings.SupportSection.tour.button')}
      data-testid="launch-tour-button"
      onClick={handleClick}
      isLoading={isMutating}
    />
  );
};
