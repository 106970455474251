import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';

import { AccountingPlatformSyncErrorScreen } from '../screens';

type Props = {
  onCancel: VoidFunction;
  onTryAgain: VoidFunction;
};

export const SyncErrorStep = ({ onCancel, onTryAgain }: Props) => {
  const { createTrackHandler } = useAnalytics();
  const trackAndHandleClick = createTrackHandler<{
    Cta: 'cancel' | 'try-again';
  }>('SyncAccountingSoftware', 'Click');

  useAnalyticsView('connecting-to-accounting-software-error');

  const onCancelClick = () => {
    trackAndHandleClick({ Cta: 'cancel' }, onCancel);
  };

  const onTryAgainClick = () => {
    trackAndHandleClick({ Cta: 'try-again' }, onTryAgain);
  };

  return <AccountingPlatformSyncErrorScreen onCancel={onCancelClick} onTryAgain={onTryAgainClick} />;
};
