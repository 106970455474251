import { isValidAccountNumber } from './Payment.utils';

export const getDefaultMemoFromInvoiceNumbers = (invoiceNumbers: string[], accountNumber?: string) => {
  if (accountNumber && isValidAccountNumber(accountNumber)) {
    return accountNumber;
  }
  if (!invoiceNumbers.length) {
    return '';
  }

  return `Inv ${invoiceNumbers.map((number) => `${number}`).join(', ')}`;
};
