import { ServiceChargeCatalogsApiClient } from '@melio/platform-api-axios-client';

import { useModel, UseModelProps } from '../../api-client';

export type UseServiceChargeCatalogProps = UseModelProps<
  typeof ServiceChargeCatalogsApiClient.getServiceChargeCatalogById,
  typeof ServiceChargeCatalogsApiClient.updateServiceChargeCatalogById,
  typeof ServiceChargeCatalogsApiClient.postServiceChargeCatalog
>;

export const useServiceChargeCatalog = (props: UseServiceChargeCatalogProps = {}) =>
  useModel({
    ...props,
    queryKey: 'ServiceChargeCatalogsApi',
    queryFn: ServiceChargeCatalogsApiClient.getServiceChargeCatalogById,
    createFn: ServiceChargeCatalogsApiClient.postServiceChargeCatalog,
    deleteFn: ServiceChargeCatalogsApiClient.deleteServiceChargeCatalogById,
    updateFn: ServiceChargeCatalogsApiClient.updateServiceChargeCatalogById,
  });

export type ServiceChargeCatalogModel = ReturnType<typeof useServiceChargeCatalog>;
