import { BankAccountFormModel, useAchValidationSchema } from '@melio/ap-widgets';
import { useMelioForm } from '@melio/penny';
import {
  BankAccountDeliveryMethod,
  useDeliveryMethods,
  useDeliveryMethodUnmaskedAccountNumber,
  Vendor,
} from '@melio/platform-api';

import { useConfirmationModal } from '../useConfirmationModal';

export function useBankAccountDetailsFormModal({
  vendor,
  onDone,
}: {
  vendor?: Vendor;
  onDone: (deliveryMethod: BankAccountDeliveryMethod) => void;
}) {
  const {
    create,
    confirm,
    isMutating,
    _mutations: {
      create: { error: createError },
    },
  } = useDeliveryMethods({ enabled: false, vendorId: vendor?.id });

  const bankAccount = vendor?.deliveryMethods.find((method) => method.type === 'bank-account') as
    | BankAccountDeliveryMethod
    | undefined;

  const { data: bankAccountUnmaskedAccountNumber } = useDeliveryMethodUnmaskedAccountNumber({
    deliveryMethodId: bankAccount?.id || '',
  });

  const unmaskedAccountNumber = bankAccountUnmaskedAccountNumber?.accountNumber;

  const confirmationModal = useConfirmationModal({
    deliveryMethod: bankAccount,
  });

  const bankModalForm = useMelioForm<BankAccountFormModel>({
    schema: useAchValidationSchema(),
    onSubmit: async (data) => {
      if (
        !bankAccount ||
        unmaskedAccountNumber !== data.accountNumber ||
        bankAccount.details.routingNumber !== data.routingNumber
      ) {
        const newBankAccount = await create({
          type: 'bank-account',
          details: { ...data, accountType: 'checking' },
          requireConfirmationForPayment: false,
        });
        onDone(newBankAccount as BankAccountDeliveryMethod);
      } else {
        await confirm(bankAccount.id);
        onDone(bankAccount);
      }

      confirmationModal.close();
    },
    defaultValues:
      bankAccount?.details && unmaskedAccountNumber
        ? {
            accountNumber: unmaskedAccountNumber,
            routingNumber: bankAccount.details.routingNumber,
          }
        : {
            accountNumber: '',
            routingNumber: '',
          },
    subscribeToDefaultValuesChanges: true,
  });

  return {
    ...confirmationModal,
    ...bankModalForm,
    vendor,
    isError: !!createError,
    isSaving: isMutating,
    routingNumber: bankAccount?.details.routingNumber,
    unmaskedAccountNumber,
  };
}
