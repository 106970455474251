import { Card, FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { useFundingSourceFeeValueFormatting } from '../../utils';

export const useAddFundingSourceCardHelperTexts = () => {
  const { formatFeeValue } = useFundingSourceFeeValueFormatting();
  const { formatMessage } = useMelioIntl();

  return {
    getAddFundingSourceContentTextByType: (fundingType: FundingSource['type'], cardType?: Card['type']) => {
      if (fundingType === 'bank-account') {
        return formatMessage('widgets.fundingSources.addFundingSourceCard.bankAccount.content');
      } else if (cardType == 'credit') {
        return formatMessage('widgets.fundingSources.addFundingSourceCard.credit.content');
      } else if (cardType == 'debit') {
        return formatMessage('widgets.fundingSources.addFundingSourceCard.debit.content');
      } else if (fundingType == 'card') {
        return formatMessage('widgets.fundingSources.addFundingSourceCard.card.content');
      }

      return `Missing Label for fundingType: ${fundingType as string}, cardType: ${cardType ?? ''}`;
    },
    getAddFundingSourceHelperTextByType: (fundingType: FundingSource['type'], cardType?: Card['type']) => {
      const feeString = formatFeeValue(fundingType, cardType);

      if (fundingType === 'bank-account') {
        return formatMessage('widgets.fundingSources.addFundingSourceCard.bankAccount.helperText');
      } else if (cardType || fundingType === 'card') {
        return feeString
          ? formatMessage(`widgets.fundingSources.addFundingSourceCard.${cardType ?? 'card'}.helperTextWithFee`, {
              feeString,
            })
          : formatMessage(`widgets.fundingSources.addFundingSourceCard.${cardType ?? 'card'}.helperText`);
      }
      return `Missing Description for fundingType: ${fundingType as string}, cardType: ${cardType ?? ''}`;
    },
  };
};
