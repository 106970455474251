import { TableColumnDef } from '@melio/penny';
import { Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMemo } from 'react';

import { DebitDateCell } from './components/cells/DebitDateCell';
import { DeliveryDateCell } from './components/cells/DeliveryDateCell';
import { PaymentAmountCell } from './components/cells/PaymentAmountCell';
import { VendorCell } from './components/cells/VendorCell';
import { PaymentLateApprovalTableMeta } from './types';

export const usePaymentsLateApprovalTableColumns = () => {
  const { formatMessage } = useMelioIntl();

  return useMemo<TableColumnDef<Payment, PaymentLateApprovalTableMeta>[]>(() => {
    const vendorCell: TableColumnDef<Payment, PaymentLateApprovalTableMeta> = {
      id: 'vendor',
      size: 124,
      header: formatMessage('activities.paymentsLateApprovalModal.table.headers.vendor'),
      cell: ({ row: payment }) => (payment.vendor ? <VendorCell vendor={payment.vendor} /> : null),
    };
    const debitDateCell: TableColumnDef<Payment, PaymentLateApprovalTableMeta> = {
      id: 'debitDate',
      size: 124,
      header: formatMessage('activities.paymentsLateApprovalModal.table.headers.debitDate'),
      cell: ({ row: payment, meta }) => {
        const debitDate = meta?.lateApprovalPaymentsDates[payment.id]?.debitDate;
        return debitDate ? <DebitDateCell debitDate={debitDate} /> : null;
      },
    };
    const deliveryDateCell: TableColumnDef<Payment, PaymentLateApprovalTableMeta> = {
      id: 'deliveryDate',
      size: 124,
      header: formatMessage('activities.paymentsLateApprovalModal.table.headers.deliveryDate'),
      cell: ({ row: payment, meta }) => {
        const deliveryDate = meta?.lateApprovalPaymentsDates[payment.id]?.deliveryDate;
        return deliveryDate ? <DeliveryDateCell deliveryDate={deliveryDate} /> : null;
      },
    };
    const paymentAmountCell: TableColumnDef<Payment, PaymentLateApprovalTableMeta> = {
      id: 'paymentAmount',
      size: 140,
      header: formatMessage('activities.paymentsLateApprovalModal.table.headers.paymentAmount'),
      cell: ({ row: payment }) => <PaymentAmountCell payment={payment} />,
    };

    return [vendorCell, debitDateCell, deliveryDateCell, paymentAmountCell];
  }, [formatMessage]);
};
