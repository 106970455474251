import { forwardRef } from '@melio/platform-utils';

import { useOnboardingStepsTracker } from '../../components';
import { GetStartedScreen } from './screens';

export type GetStartedActivityProps = {
  onClose: VoidFunction;
  onDone: VoidFunction;
};

export const GetStartedActivity = forwardRef<GetStartedActivityProps>(({ onDone }, ref) => {
  const { steps } = useOnboardingStepsTracker();

  return <GetStartedScreen ref={ref} steps={steps} currentStepIndex={-1} onDone={onDone} />;
});

GetStartedActivity.displayName = 'GetStartedActivity';
