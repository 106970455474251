import { isFXCurrency } from '@melio/ap-domain';
import { CurrencyTooltip } from '@melio/ap-widgets';
import { Group, Table, Text } from '@melio/penny';
import { Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { usePaymentAmountDetails } from '../../../hooks/usePaymentAmountDetails';

export const PaymentsTabAmountCell = ({ payment }: { payment: Payment }) => {
  const { formatCurrency } = useMelioIntl();
  const description = usePaymentAmountDetails(payment);
  const isFxPayment = isFXCurrency(payment.currency);
  const formattedAmount = formatCurrency(
    isFxPayment && payment.foreignAmount ? payment.foreignAmount : payment.amount,
    payment.currency
  );

  return (
    <Table.Cell textAlign="end">
      <CurrencyTooltip currency={payment.currency}>
        <Group
          variant="vertical"
          spacing="xxs"
          alignItems="flex-end"
          data-testid={`payments-tab-row-${payment.id}-amount-cell`}
        >
          <Text textStyle="body3Semi">{formattedAmount}</Text>
          {description}
        </Group>
      </CurrencyTooltip>
    </Table.Cell>
  );
};
