import { Box } from '@chakra-ui/react';
import { Group, Skeleton, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { SubscriptionPreview } from '../../../../api';

type SubscriptionCheckoutBillingTotalProps = {
  preview?: SubscriptionPreview;
  isFreeTrial?: boolean;
  isLoading: boolean;
};

export const SubscriptionCheckoutBillingTotal = ({
  preview,
  isFreeTrial,
  isLoading,
}: SubscriptionCheckoutBillingTotalProps) => {
  const { formatMessage, formatCurrency } = useMelioIntl();

  return (
    <Group justifyContent="space-between" alignItems="flex-start">
      <Text textStyle="body2Semi">
        {formatMessage('activities.subscription.checkout.summary.total', { isFreeTrial })}
      </Text>
      <Text textStyle="body1Semi">
        {isLoading ? (
          <Box width="100px">
            <Skeleton height="12px" />
          </Box>
        ) : (
          <Group data-testid="subscription-checkout-total">
            {formatCurrency(isFreeTrial ? 0 : preview?.amount || 0)}
          </Group>
        )}
      </Text>
    </Group>
  );
};
