/* eslint-disable react-hooks/exhaustive-deps */
import { useAnalytics, useAnalyticsContext, withAnalyticsContext } from '@melio/platform-analytics';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { AddBankAccountScreen, PendingVerificationScreen } from './screens';
import { AddBankAccountMicroDepositsActivityProps } from './types';
import { useAddBankAccountMicroDeposits } from './useAddBankAccountMicroDeposits';
import { useAddBankAccountMicroDepositsStep } from './useAddBankAccountMicroDepositsStep';

export const AddBankAccountMicroDepositsActivity = withAnalyticsContext<AddBankAccountMicroDepositsActivityProps>(
  ({ onBack: onFirstStepBack, onClose, onError, onDone, isReceivingMethodFlow }) => {
    const { currentStep, goToStep, goToPreviousStep } = useAddBankAccountMicroDepositsStep({ onFirstStepBack });
    const { deliveryMethodId: updateDeliveryMethodId } = useParams();

    const { onSuccess, isMutating, fundingSource, deliveryMethod } = useAddBankAccountMicroDeposits({
      isReceivingMethodFlow,
      updateDeliveryMethodId,
      onError,
    });

    useAnalyticsContext({ globalProperties: { NewFundingSource: 'bank-account' } });
    const { track } = useAnalytics();

    useEffect(() => {
      if (fundingSource?.id || deliveryMethod?.id) {
        goToStep('ACH_MICRO_DEPOSITS_PENDING_VERIFICATION');
      }
    }, [fundingSource, deliveryMethod, goToStep]);

    switch (currentStep) {
      case 'ADD_ACH_MICRO_DEPOSITS':
      default:
        return (
          <AddBankAccountScreen onBack={goToPreviousStep} onClose={onClose} onDone={onSuccess} isSaving={isMutating} />
        );

      case 'ACH_MICRO_DEPOSITS_PENDING_VERIFICATION':
        return (
          <PendingVerificationScreen
            onDone={() => {
              track('VerifyDepositDetailsWhatNext', 'Chose');
              if (fundingSource) {
                onDone(fundingSource);
              } else if (deliveryMethod) {
                onDone(deliveryMethod);
              }
            }}
          />
        );
    }
  }
);

AddBankAccountMicroDepositsActivity.displayName = 'AddBankAccountMicroDepositsActivity';
