import { StatusModalProps } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { Bill, useBill } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils';
import { useState } from 'react';

export const useDeleteBillModal = ({
  isOpen,
  onClose,
  onSuccess,
  bill,
}: {
  bill?: Bill;
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}): StatusModalProps & { 'data-testid': string } & { description: string } => {
  const { track } = useAnalytics();

  const [isDeleteBillLoading, setIsDeleteBillLoading] = useState(false);

  const { formatMessage, formatCurrency } = useMelioIntl();
  const { showMessage } = useSystemMessage();

  const { delete: deleteBill } = useBill({ id: bill?.id, enabled: false });

  const confirmLabel = formatMessage('activities.payDashboard.billsTab.deleteBillModal.confirmButton');
  const cancelLabel = formatMessage('activities.payDashboard.billsTab.deleteBillModal.cancelButton');
  const header = formatMessage('activities.payDashboard.billsTab.deleteBillModal.header');
  const description = formatMessage('activities.payDashboard.billsTab.deleteBillModal.description', {
    vendorName: bill?.vendor?.name || '',
    billNumber: bill?.invoice.number || '',
    amount: formatCurrency(bill?.amount || 0),
  });

  const handleDialogClose = () => {
    track('Bill', 'Click', {
      Intent: 'cancel',
      Cta: 'cancel',
    });
    onClose();
  };

  const handleDialogConfirm = async () => {
    try {
      track('Bill', 'Click', { Intent: 'delete-bill', Cta: 'delete-bill', BillId: bill?.id });
      setIsDeleteBillLoading(true);
      await deleteBill();
      onSuccess?.();

      showMessage({
        type: 'informative',
        dataTestId: 'delete-bill-success',
        title: formatMessage('activities.payDashboard.billsTab.deleteBillModal.toast.successMessage', {
          vendorName: bill?.vendor?.name || '',
          amount: formatCurrency(bill?.amount || 0, bill?.currency || 'USD'),
        }),
      });
    } catch (error) {
      showMessage({
        type: 'error',
        dataTestId: 'delete-bill-error',
        title: formatMessage('activities.payDashboard.billsTab.deleteBillModal.toast.errorMessage'),
      });
    } finally {
      setIsDeleteBillLoading(false);
      onClose();
    }
  };

  return {
    'data-testid': 'delete-bill-modal',
    primaryButton: {
      label: confirmLabel,
      onClick: handleDialogConfirm,
      variant: 'critical',
      isLoading: isDeleteBillLoading,
      loadingText: formatMessage('app.loading'),
    },
    secondaryButton: {
      label: cancelLabel,
      onClick: handleDialogClose,
      variant: 'tertiary',
      isDisabled: isDeleteBillLoading,
    },
    header,
    description,
    variant: 'alert',
    isOpen,
    onClose: handleDialogClose,
  };
};
