import { CardNetwork } from '@melio/platform-api';

function isAmex(cardNetwork: CardNetwork): boolean {
  return cardNetwork === CardNetwork.Amex;
}

function getAmexRestrictedCountry(cardNetwork: CardNetwork): string {
  return isAmex(cardNetwork) ? 'us' : '';
}
export function useRestrictedCountry(cardNetwork: CardNetwork) {
  const restrictedCountry = getAmexRestrictedCountry(cardNetwork);
  return {
    restrictedCountry,
  };
}
