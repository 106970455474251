import { useFundingSourceIconKey } from '@melio/ap-widgets';
import { BrandSymbol, BrandSymbolKey, brandSymbolsMap, Container, Group, Icon, IconKey, ListItem } from '@melio/penny';
import { FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

export type FundingSourceFlatContainerCardProps = {
  fundingSource: FundingSource;
};

export const FundingSourceFlatContainerCard = forwardRef<FundingSourceFlatContainerCardProps, 'div'>(
  ({ fundingSource, ...props }, ref) => {
    const fundingSourceIconType = useFundingSourceIconKey(fundingSource);
    const { formatMessage } = useMelioIntl();
    const {
      settings: { partnerProductName },
    } = useConfig();

    // TODO: replace with utility from @melio/platform-api - https://meliorisk.atlassian.net/browse/ME-24864
    const isBrandSymbol = Object.keys(brandSymbolsMap).includes(fundingSourceIconType as BrandSymbolKey);

    const icon = isBrandSymbol ? (
      <BrandSymbol type={fundingSourceIconType as BrandSymbolKey} />
    ) : (
      <Icon type={fundingSourceIconType as IconKey} />
    );

    return (
      <Container
        border="regular"
        paddingX="s"
        paddingY="s"
        data-component="FundingSourceFlatContainerCard"
        data-testid={`funding-source-card-flat-container-${fundingSource.id}`}
        {...props}
        ref={ref}
      >
        <Group alignItems="center">
          {icon}
          <ListItem
            mainLabelProps={{ label: fundingSource.displayName, variant: 'bold' }}
            labelProps={{
              label: formatMessage('activities.reconciliationModalScreen.bankAccount.label', {
                productName: partnerProductName,
              }),
            }}
          />
        </Group>
      </Container>
    );
  }
);

FundingSourceFlatContainerCard.displayName = 'FundingSourceFlatContainerCard';
