import React from 'react';
import { object, SchemaOf, string } from 'yup';
import { masks, useMtlMessages, useMtlSchemaValidations } from '@melio/ap-domain';
import { AddressSearchWidget } from '@melio/ap-widgets';
import { Button, Form, useMelioForm } from '@melio/penny';
import { useFormSubmissionController } from '@melio/penny';
import { useAnalytics, useAnalyticsContext, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { Address, OAuthAccountNotCreatedReason } from '@melio/platform-api';
import { useSystemMessage } from '@melio/platform-utils';

import { FullScreenContainer } from '@/cl/components/FullScreenContainer/FullScreenContainer.component';
import { usePlatformIntl } from '@/translations/Intl';

export type MissingAccountDataFormFields = {
  firstName: string;
  lastName: string;
  companyName: string;
  address: Omit<Address, 'countryCode'>;
  dateOfBirth: string;
};

type MissingData = { [K in keyof MissingAccountDataFormFields]: boolean };

export const useSchema = ({ firstName, lastName, companyName, address, dateOfBirth }: MissingData) => {
  const {
    address: addressValidation,
    name,
    dateOfBirth: dateOfBirthValidation,
    companyName: companyNameValidation,
  } = useMtlSchemaValidations();

  return object().shape({
    firstName: firstName ? name('firstName') : string(),
    lastName: lastName ? name('lastName') : string(),
    dateOfBirth: dateOfBirth ? dateOfBirthValidation() : string(),
    companyName: companyName ? companyNameValidation() : string(),
    address: address ? addressValidation('operating-address') : object().nullable(),
  }) as SchemaOf<MissingAccountDataFormFields>;
};

type MissingAccountDataFormProps = {
  reasons: OAuthAccountNotCreatedReason[];
  onDone: (data: MissingAccountDataFormFields) => void;
};

export const MissingAccountDataForm = withAnalyticsContext<MissingAccountDataFormProps>(({ reasons, onDone }) => {
  const { showMessage } = useSystemMessage();
  const { formatMessage } = usePlatformIntl();
  const { track } = useAnalytics();
  const {
    labels: { user: userLabels, company: companyLabels },
    placeholders,
  } = useMtlMessages();
  const missingData: MissingData = React.useMemo(() => {
    const reasonsToIncludes = reasons.reduce<{ [key in OAuthAccountNotCreatedReason]?: true }>(
      (acc, reason) => ({
        ...acc,
        [reason]: true,
      }),
      {},
    );
    return {
      firstName: !!reasonsToIncludes[OAuthAccountNotCreatedReason.MissingFirstName],
      lastName: !!reasonsToIncludes[OAuthAccountNotCreatedReason.MissingLastName],
      dateOfBirth: !!reasonsToIncludes[OAuthAccountNotCreatedReason.MissingDateOfBirth],
      companyName: !!reasonsToIncludes[OAuthAccountNotCreatedReason.MissingCompanyName],
      address: !!reasonsToIncludes[OAuthAccountNotCreatedReason.MissingAddress],
    };
  }, [reasons]);

  const businessFieldsShown = React.useMemo(() => {
    return Object.keys(missingData).filter((key) => !!missingData[key as unknown as keyof MissingData]);
  }, [missingData]);

  useAnalyticsContext({
    globalProperties: {
      businessFieldsShown: businessFieldsShown,
    },
  });

  useAnalyticsView('CompanyInfo');

  const onSubmit = async (data: MissingAccountDataFormFields) => {
    try {
      track('CompanyInfo', 'Submitted');
      onDone(data);
    } catch (e) {
      showMessage({
        type: 'error',
        title: formatMessage('widgets.onboarding.missingAccountDataForm.apiError'),
      });
      console.error(e);
    }
  };

  const { submitButtonProps, onSubmissionStateChange } = useFormSubmissionController<MissingAccountDataFormFields>();

  const { formProps, registerField } = useMelioForm<MissingAccountDataFormFields>({
    onSubmit,
    onSubmissionStateChange,
    schema: useSchema(missingData),
  });

  return (
    <FullScreenContainer
      title={formatMessage('widgets.onboarding.missingAccountDataForm.title')}
      subtitle={formatMessage('widgets.onboarding.missingAccountDataForm.subtitle')}
    >
      <Form data-component="MissingAccountDataFormForm" size="large" {...formProps} columns={2}>
        <Form.TextField
          {...registerField('firstName')}
          isHidden={!missingData.firstName}
          labelProps={{ label: userLabels.firstName }}
          placeholder={placeholders.firstName}
          colSpan={!missingData.lastName ? 2 : 1}
        />
        <Form.TextField
          {...registerField('lastName')}
          isHidden={!missingData.lastName}
          labelProps={{ label: userLabels.lastName }}
          placeholder={placeholders.lastName}
          colSpan={!missingData.firstName ? 2 : 1}
        />
        <Form.TextField
          {...registerField('dateOfBirth')}
          isHidden={!missingData.dateOfBirth}
          maskProps={{
            mask: masks.dateOfBirth,
            keepCharPositions: false,
            guide: true,
            placeholderChar: ' ',
          }}
          labelProps={{ label: userLabels.dateOfBirth }}
          placeholder={placeholders.dateOfBirth}
          colSpan={2}
        />
        <Form.TextField
          {...registerField('companyName')}
          isHidden={!missingData.companyName}
          labelProps={{ label: companyLabels.name }}
          placeholder={placeholders.companyName}
          colSpan={2}
        />
        <AddressSearchWidget
          {...registerField('address')}
          isHidden={!missingData.address}
          labelProps={{ label: companyLabels.address }}
          placeholder={placeholders.address}
          colSpan={2}
        />
      </Form>
      <Button
        data-testid="missing-account-data-form-submit"
        variant={'primary'}
        size={'large'}
        {...submitButtonProps}
        label={formatMessage('widgets.onboarding.missingAccountDataForm.button.text')}
      />
    </FullScreenContainer>
  );
});
