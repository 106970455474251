// TODO: the text should be per plan and not just per feature. waiting for product answer https://www.figma.com/design/iFQTSLomTaSVgSdDxpiOej?node-id=951-86552#800202620
// for example: the "1teamMember" is not correct for all current plan. it may be the the current plan is the paid plan with 3 seats and it doesn't support the feature.
export const currentPlanConfigPerFeature = {
  collaborators: {
    capabilities: [
      'activities.subscription.contextualPlans.collaborators.current.capabilities.1teamMember',
      'activities.subscription.contextualPlans.collaborators.current.capabilities.ownerRole',
    ],
  },
  batchPayments: {
    capabilities: [
      'activities.subscription.contextualPlans.batchPayments.current.capabilities.paySingleBills',
      'activities.subscription.contextualPlans.batchPayments.current.capabilities.noCombinedPayments',
    ],
    actionButton: 'activities.subscription.contextualPlans.batchPayments.current.actionButtonLabel',
  },
  approvalWorkflows: {
    capabilities: [],
    actionButton: 'activities.subscription.contextualPlans.approvalWorkflows.current.actionButtonLabel',
  },
  advancedApprovalWorkflows: {
    capabilities: [],
    actionButton: 'activities.subscription.contextualPlans.advancedApprovalWorkflows.current.actionButtonLabel',
  },

  accountingPlatformQbo: { capabilities: [] },
  accountingPlatformXero: { capabilities: [] },
  accountingPlatformQbdt: { capabilities: [] },
  lineItems: {
    capabilities: [
      'activities.subscription.contextualPlans.lineItems.current.actionButtonLabel',
      'activities.subscription.contextualPlans.lineItems.current.capabilities.noCombinedPayments',
    ],
  },
  w9: {
    capabilities: [
      'activities.subscription.contextualPlans.w9.current.capabilities.vendorContractorsManagement',
      'activities.subscription.contextualPlans.w9.current.capabilities.w9PDFUpload',
      'activities.subscription.contextualPlans.w9.current.capabilities.fiveFreeACHTransfers',
    ],
    actionButton: 'activities.subscription.contextualPlans.w9.current.actionButtonLabel',
  },
  tax1099: { capabilities: [] },
  classesAndLocations: {
    capabilities: [
      'activities.subscription.contextualPlans.classesAndLocations.current.capabilities.sync',
      'activities.subscription.contextualPlans.classesAndLocations.current.capabilities.freeACH',
      'activities.subscription.contextualPlans.classesAndLocations.current.capabilities.billLineItems',
    ],
  },
  ar: { capabilities: [] },
  requestCallbackSupport: { capabilities: [] },
  achTPlusTwo: { capabilities: [] },
  freeAchPayments: { capabilities: [] },
  syncedPayments: { capabilities: [] },
} as const;

export const upgradedPlanConfigPerFeature = {
  collaborators: {
    capabilities: [
      'activities.subscription.contextualPlans.collaborators.upgrade.capabilities.unlimitedCollaborators',
      'activities.subscription.contextualPlans.collaborators.upgrade.capabilities.multipleRoles',
    ],
  },
  batchPayments: {
    capabilities: [
      'activities.subscription.contextualPlans.batchPayments.upgrade.capabilities.multipleBills',
      'activities.subscription.contextualPlans.batchPayments.upgrade.capabilities.combinedBillPayments',
    ],
  },
  approvalWorkflows: {
    capabilities: [
      'activities.subscription.contextualPlans.approvalWorkflows.upgrade.capabilities.paymentAmount',
      'activities.subscription.contextualPlans.approvalWorkflows.upgrade.capabilities.userOrRole',
      'activities.subscription.contextualPlans.approvalWorkflows.upgrade.capabilities.multiApprovers',
    ],
  },
  advancedApprovalWorkflows: {
    capabilities: [
      'activities.subscription.contextualPlans.advancedApprovalWorkflows.upgrade.capabilities.coreFeatures',
      'activities.subscription.contextualPlans.advancedApprovalWorkflows.upgrade.capabilities.specificApprovers',
      'activities.subscription.contextualPlans.advancedApprovalWorkflows.upgrade.capabilities.multiLayeredApprovals',
      'activities.subscription.contextualPlans.advancedApprovalWorkflows.upgrade.capabilities.specificVendor',
    ],
  },
  accountingPlatformQbo: { capabilities: [] },
  accountingPlatformXero: { capabilities: [] },
  accountingPlatformQbdt: {
    capabilities: [
      'activities.subscription.contextualPlans.accountingPlatformQbdt.upgrade.capabilities.freeAchTransfers',
      'activities.subscription.contextualPlans.accountingPlatformQbdt.upgrade.capabilities.syncWithAccountingSoftware',
      'activities.subscription.contextualPlans.accountingPlatformQbdt.upgrade.capabilities.advancedApprovalWorkflows',
      'activities.subscription.contextualPlans.accountingPlatformQbdt.upgrade.capabilities.prioritySupport',
    ],
  },
  lineItems: {
    capabilities: [
      'activities.subscription.contextualPlans.lineItems.upgrade.capabilities.addLineItems',
      'activities.subscription.contextualPlans.lineItems.upgrade.capabilities.combinedBillPayments',
      'activities.subscription.contextualPlans.lineItems.upgrade.capabilities.multipleBills',
    ],
  },
  w9: {
    capabilities: [
      'activities.subscription.contextualPlans.w9.upgrade.capabilities.w9Collection1099Efiling',
      'activities.subscription.contextualPlans.w9.upgrade.capabilities.automatedTINMatch',
      'activities.subscription.contextualPlans.w9.upgrade.capabilities.freeACHTransfers',
      'activities.subscription.contextualPlans.w9.upgrade.capabilities.batchPayMultipleBills',
      'activities.subscription.contextualPlans.w9.upgrade.capabilities.autoSyncWithQuickBooksXero',
      'activities.subscription.contextualPlans.w9.upgrade.capabilities.advancedBillLineItems',
      'activities.subscription.contextualPlans.w9.upgrade.capabilities.assignRolesPermissions',
    ],
  },
  tax1099: { capabilities: [] },
  classesAndLocations: {
    capabilities: [
      'activities.subscription.contextualPlans.classesAndLocations.upgrade.capabilities.additionalBillAttributes',
      'activities.subscription.contextualPlans.classesAndLocations.upgrade.capabilities.freeACH',
      'activities.subscription.contextualPlans.classesAndLocations.upgrade.capabilities.InvoiceLineItems',
      'activities.subscription.contextualPlans.classesAndLocations.upgrade.capabilities.rollsAndPermissions',
      'activities.subscription.contextualPlans.classesAndLocations.upgrade.capabilities.advancedApprovalWorkflows',
      'activities.subscription.contextualPlans.classesAndLocations.upgrade.capabilities.prioritySupport',
    ],
  },
  ar: { capabilities: [] },
  requestCallbackSupport: { capabilities: [] },
  achTPlusTwo: { capabilities: [] },
  freeAchPayments: { capabilities: [] },
  syncedPayments: { capabilities: [] },
} as const;
