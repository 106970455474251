import { Group, Table, useBreakpoint, useTable } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import {
  Collaborator,
  RoleUniqueNames,
  useCollaborator,
  useCollaborators,
  useUserPreferences,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { isCollaboratorBlocked } from '@melio/platform-utils';
import { SystemMessageProvider, useSystemMessage } from '@melio/platform-utils/system-message';
import { maxBy } from 'lodash';
import { useCallback, useEffect } from 'react';
import { Route, Routes, useNavigate, useParams, useResolvedPath } from 'react-router-dom';

import { CreateCollaboratorDrawerActivity, EditCollaboratorDrawerActivity } from '../../collaborator-drawer';
import { useEnrichedTodos, useTodosEnabled } from '../../todos-drawer';
import { CollaboratorListItem } from './components/CollaboratorListItem';
import { CollaboratorsSummary } from './components/CollaboratorsSummary/CollaboratorsSummary';
import { Header } from './components/Header';
import { useCollaboratorsTableColumns } from './useCollaboratorsTableColumns';

const sortCollaboratorsByUpdatedAt = (collaborator: Collaborator) =>
  collaborator.history?.updatedAt ?? collaborator.history?.createdAt;

export const CollaboratorsActivity = withAnalyticsContext(({ setAnalyticsProperties }) => {
  const navigate = useNavigate();
  const resolvedPathUrl = useResolvedPath('');
  const { showMessage } = useSystemMessage();
  const { formatMessage } = useMelioIntl();
  const { isExtraSmallScreen: isMobile } = useBreakpoint();
  const { settings } = useConfig();
  const { track } = useAnalytics();
  const { create } = useUserPreferences({ enabled: false });
  const { isEnabled: isTodosEnabled } = useTodosEnabled();
  const { refetch: refetchTodos } = useEnrichedTodos({ enabled: false });

  const selectCollaborators = useCallback(
    (collaborators: Collaborator<'user'>[]) => collaborators.sort((c) => (isCollaboratorBlocked(c) ? 1 : -1)),
    []
  );
  const { data: collaborators, isLoading: isCollaboratorsLoading } = useCollaborators({
    expand: ['user'],
    select: selectCollaborators,
  });
  const { data: actor, isLoading: isActorLoading } = useCollaborator<'user'>({
    id: 'me',
    params: { expand: ['user'] },
  });

  useEffect(() => {
    if (collaborators && collaborators?.length > 0) {
      const newestCollaborator = maxBy(collaborators, sortCollaboratorsByUpdatedAt);
      if (!newestCollaborator) {
        return;
      }
      const newestDate = newestCollaborator.history?.updatedAt || newestCollaborator.history?.createdAt;
      if (newestDate) {
        create({
          userPreference: {
            key: 'userManageLastUpdatedUserSeen',
            value: newestDate.toISOString(),
          },
        });
      }
      if (isTodosEnabled) {
        refetchTodos();
      }
    }
  }, [collaborators, create, isTodosEnabled, refetchTodos]);

  setAnalyticsProperties({
    Product: 'ap',
    PageName: 'user-and-roles',
    Flow: 'user-management',
    Intent: 'manage-users',
    UserUniqueRole: actor?.roleUniqueName,
    UserRoleMapping: {
      ...collaborators?.reduce((acc, { roleUniqueName }) => {
        if (roleUniqueName) {
          acc[roleUniqueName] = (acc[roleUniqueName] || 0) + 1;
        }
        return acc;
      }, {} as Record<RoleUniqueNames, number>),
      Inactive: collaborators?.filter((collaborator) => isCollaboratorBlocked(collaborator)).length,
    },
  });

  const mobileRowRenderer = useCallback(
    (row: Collaborator<'user'>) => (actor ? <CollaboratorListItem actor={actor} collaborator={row} /> : <></>),
    [actor]
  );

  const isLoading = isCollaboratorsLoading || isActorLoading;
  const columns = useCollaboratorsTableColumns({ actor });
  const tableProps = useTable({
    headerVariant: 'dark',
    isLoading,
    data: collaborators ?? [],
    onRowClick: ({ rowData: { id } }) => {
      track('Settings', 'Click', {
        Cta: 'user-name',
        ManagedUserId: collaborators?.find(({ id }) => id === id)?.userId,
      });
      navigate(`${id}`);
    },
    columns,
    mobileRowRenderer,
    meta: {
      onViewClick: (id: Collaborator['id']) => {
        track('Settings', 'Click', {
          Cta: 'view',
          ManagedUserId: collaborators?.find(({ id }) => id === id)?.userId,
        });
        navigate(`${id}`);
      },
      onEditClick: (id: Collaborator['id']) => {
        track('Settings', 'Click', {
          Cta: 'edit',
          ManagedUserId: collaborators?.find(({ id }) => id === id)?.userId,
        });
        navigate(`${id}`);
      },
    },
  });

  useEffect(() => {
    if (!isLoading) {
      track('Settings', 'View', { CountInTab: collaborators?.length ?? 0, PageName: 'collaborators-setting' });
    }
  }, [isLoading, collaborators, track]);

  const handleDrawerClose = () => navigate(resolvedPathUrl);

  const handleEditSuccess = (collaboratorId: Collaborator['id']) => {
    const updatedCollaborator = collaborators?.find(({ id }) => id === collaboratorId);
    const collaboratorName = updatedCollaborator
      ? `${updatedCollaborator.user.firstName} ${updatedCollaborator.user.lastName}`
      : '';

    showMessage({
      type: 'success',
      id: 'edit-collaborator-success-toast',
      title: formatMessage('activities.settings.collaborators.edit.toast.success', { name: collaboratorName }),
    });
  };

  const handleEditError = (collaboratorId: Collaborator['id']) => {
    const failedToUpdateCollaborator = collaborators?.find(({ id }) => id === collaboratorId);
    const collaboratorName = failedToUpdateCollaborator
      ? `${failedToUpdateCollaborator.user.firstName} ${failedToUpdateCollaborator.user.lastName}`
      : '';

    showMessage({
      type: 'error',
      id: 'edit-collaborator-error-toast',
      title: formatMessage('activities.settings.collaborators.edit.toast.error', { name: collaboratorName }),
    });
  };

  return (
    <>
      <Group variant="vertical" spacing={isMobile ? 'none' : 'm'} width="full">
        <Group variant="vertical" spacing="s">
          <Group.Item>
            <Header onAddCollaboratorClick={() => navigate('new')} />
          </Group.Item>

          {settings.collaborator.isChangeCollaboratorPermissionLevelEnabled && !isLoading ? (
            <Group.Item>
              <CollaboratorsSummary collaborators={collaborators ?? []} />
            </Group.Item>
          ) : null}
        </Group>

        <Group.Item>
          <Table {...tableProps} />
        </Group.Item>
      </Group>

      <Routes>
        <Route
          path=":collaboratorId"
          element={
            <EditCollaboratorDrawerActivityWithCollaboratorId
              onClose={handleDrawerClose}
              onSuccess={handleEditSuccess}
              onError={handleEditError}
            />
          }
        />
        <Route path="new" element={<CreateCollaboratorDrawerActivity onClose={handleDrawerClose} />} />
      </Routes>
    </>
  );
});

const EditCollaboratorDrawerActivityWithCollaboratorId = ({
  onClose,
  onSuccess,
  onError,
}: {
  onClose: VoidFunction;
  onSuccess: (collaboratorId: Collaborator['id']) => void;
  onError: (collaboratorId: Collaborator['id'], error: PlatformError) => void;
}) => {
  const { collaboratorId } = useParams();
  return collaboratorId ? (
    <SystemMessageProvider>
      <EditCollaboratorDrawerActivity
        collaboratorId={collaboratorId}
        onClose={onClose}
        onSuccess={onSuccess}
        onError={onError}
      />
    </SystemMessageProvider>
  ) : null;
};
