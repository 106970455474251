/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { CountryInternationalDeliveryDetails } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useBoolean } from '@melio/platform-utils';
import { useEffect } from 'react';

type UnsupportedInternationalCountryModalProps = { countryDetails?: CountryInternationalDeliveryDetails };

export const UnsupportedInternationalCountryModalScreen = ({
  countryDetails,
}: UnsupportedInternationalCountryModalProps) => {
  const { track } = useAnalytics();
  const [isModalOpen, setIsModalOpen] = useBoolean(false);
  const { formatMessage } = useMelioIntl();
  const countryName = countryDetails?.name;

  useEffect(() => {
    if (countryDetails && !countryDetails.isSupported) {
      setIsModalOpen.on();
    }
  }, [countryDetails]);

  useEffect(() => {
    if (isModalOpen) {
      track('DeliveryMethod', 'View', {
        PageName: 'payments-to-country-are-not-available-yet',
        Intent: 'choose-vendor-country',
        SearchValue: countryDetails?.name,
        CountryRiskScore: countryDetails?.risk,
        IsSupported: countryDetails?.isSupported,
      });
    }
  }, [track, isModalOpen, countryDetails]);

  const trackClick = (cta: string) => {
    track(`DeliveryMethod`, 'Click', {
      PageName: 'payments-to-country-are-not-available-yet',
      Intent: 'choose-vendor-country',
      SearchValue: countryDetails?.name,
      CountryRiskScore: countryDetails?.risk,
      IsSupported: countryDetails?.isSupported,
      Cta: cta,
    });
  };

  return (
    <Modal
      data-testid="international-bank-details-activity-unsupported-international-country-modal"
      header={formatMessage(
        'activities.internationalBankDetails.screens.internationalBankDetails.unsupportedModal.header',
        { countryName }
      )}
      isOpen={isModalOpen}
      onClose={() => {
        trackClick('exit');
        setIsModalOpen.off();
      }}
      primaryButton={{
        label: formatMessage(
          'activities.internationalBankDetails.screens.internationalBankDetails.unsupportedModal.buttonLabel'
        ),
        onClick: () => {
          trackClick('got-it');
          setIsModalOpen.off();
        },
        variant: 'primary',
      }}
    >
      <Text>
        {formatMessage(
          'activities.internationalBankDetails.screens.internationalBankDetails.unsupportedModal.description',
          { countryName }
        )}
      </Text>
    </Modal>
  );
};

UnsupportedInternationalCountryModalScreen.displayName = 'UnsupportedInternationalCountryModal';
