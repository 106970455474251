import { useCallback } from 'react';
import { ApprovePaymentRequestActivity } from '@melio/ap-activities';
import { PaymentRequest } from '@melio/platform-api';

import { useRouter } from '@/hooks/router.hooks';

export const SchedulePaymentRequestScreen = ({
  paymentRequestId,
  returnUrl,
}: {
  paymentRequestId: string;
  returnUrl?: string;
}) => {
  const { navigateToSchedulePayment } = useRouter();

  const handleDone = useCallback(
    (paymentRequest: PaymentRequest) => {
      if (paymentRequest.billId) {
        navigateToSchedulePayment({ billId: paymentRequest.billId, returnUrl });
      }
    },
    [navigateToSchedulePayment, returnUrl],
  );

  return <ApprovePaymentRequestActivity paymentRequestId={paymentRequestId} onDone={handleDone} />;
};
