import { InvoiceExpandedApiClient } from '@melio/platform-api-axios-client';

import { useModel, UseModelProps } from '../../api-client';

export type UseInvoiceExpandedProps = UseModelProps<typeof InvoiceExpandedApiClient.getExpandedInvoice>;

export const useInvoiceExpanded = (props: UseInvoiceExpandedProps = {}) =>
  useModel({
    ...props,
    queryKey: 'InvoiceExpandedApi',
    queryFn: InvoiceExpandedApiClient.getExpandedInvoice,
  });

export type InvoiceExpandedModel = ReturnType<typeof useInvoiceExpanded>;
