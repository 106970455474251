/* eslint-disable max-lines */
import { isContainsSensitiveInfo } from '@melio/ap-domain';
import { Currency, CurrencyAmountLimits, getCurrencyAmountLimits } from '@melio/international-payments-utils';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import * as yup from 'yup';

import { transformControlledNumberInput } from '../../../../utils/validation';
import {
  AddBillV2DFormInitialValues,
  AddBillV2FormFrequency,
  AddBillV2FormValues,
  AddBillV2RecurringEndBy,
} from '../types';
import {
  getBillPaidAmount,
  MAX_ALLOWED_OCCURRENCES,
  useValidateRecurringEndDate,
  useValidateRecurringStartDate,
} from '../utils';

type Params = {
  initialValues?: Partial<AddBillV2DFormInitialValues>;
};

export const useAddBillV2Schema = ({ initialValues }: Params) => {
  const {
    settings: {
      newBillExperience: { invoiceNumberFormatRegexp },
    },
  } = useConfig();

  const { formatMessage, formatCurrency } = useMelioIntl();
  const { isRecurringStartDateValid } = useValidateRecurringStartDate();
  const { isRecurringEndDateValid } = useValidateRecurringEndDate();
  const amountPaid = initialValues ? getBillPaidAmount(initialValues) : 0;

  const baseSchema = {
    vendorId: yup.string().required(formatMessage('widgets.addBillForm.vendorName.validation.required')).nullable(),
    amount: yup
      .string()
      .required(formatMessage('activities.addBillV2.billForm.billAmount.validation.required'))
      .test(
        'greaterThan',
        formatMessage('widgets.addBillForm.billAmount.validation.greaterThan', {
          minValue: formatCurrency(0),
        }),
        (value = '') => parseFloat(value) > 0
      )
      .test(
        'validateGreaterOrEqualToPaidAmount',
        formatMessage('activities.addBillV2.lineItems.categoryBased.amount.validation.greaterOrEqualToPaidAmount'),
        (value?: string) => Number(value) >= amountPaid
      )
      .when(['currency'], {
        is: (currency: string) => !!currency && currency !== 'USD',
        then: (schema) =>
          schema.test({
            name: 'validateCurrencyAmountLimits',
            test: (amount, context) => {
              const { currency } = context.parent as { currency: string };
              let limits: CurrencyAmountLimits | null;

              try {
                limits = getCurrencyAmountLimits(currency as Currency);
              } catch (error) {
                limits = null;
              }

              if (!limits || !amount) {
                return true;
              }

              const parsedAmount = parseFloat(amount);

              if (parsedAmount < limits.minimumAmount || parsedAmount > limits.maximumAmount) {
                return context.createError({
                  message: formatMessage('activities.addBillV2.billForm.amount.validation.amountLimit', {
                    minAmount: formatCurrency(limits.minimumAmount, currency || 'USD'),
                    maxAmount: formatCurrency(limits.maximumAmount, currency || 'USD'),
                  }),
                });
              }
              return true;
            },
          }),
        otherwise: (schema) => schema,
      }),
    lastAmount: yup
      .string()
      .nullable()
      .test(
        'greaterThan',
        formatMessage('widgets.addBillForm.billAmount.validation.greaterThan', {
          minValue: formatCurrency(0),
        }),
        (value) => value === '' || value === undefined || value === null || parseFloat(value) > 0
      ),
    frequency: yup.mixed<AddBillV2FormFrequency>().oneOf(Object.values(AddBillV2FormFrequency)).required(),
    categoryId: yup.string().nullable(),
    balance: yup.number().nullable(),
    saveType: yup.string(),
    recurringStartDate: yup
      .date()
      .nullable()
      .when('frequency', {
        is: AddBillV2FormFrequency.ONE_TIME,
        then: yup.date().nullable().optional(),
        otherwise: yup
          .date()
          .nullable()
          .required(formatMessage('activities.addBillV2.billForm.date.validation'))
          .test(
            'futureDateNotHoliday',
            formatMessage('activities.addBillV2.billForm.date.validation'),
            (value?: Date | null) => isRecurringStartDateValid(value)
          ),
      }),
    recurringEndDate: yup
      .date()
      .nullable()
      .when(['frequency', 'recurringEndBy'], {
        is: (frequency: AddBillV2FormFrequency, recurringEndBy: AddBillV2RecurringEndBy) =>
          frequency !== AddBillV2FormFrequency.ONE_TIME && recurringEndBy === AddBillV2RecurringEndBy.DATE,
        then: (schema) =>
          schema
            .required(formatMessage('activities.addBillV2.billForm.date.validation'))
            .when('recurringStartDate', (recurringStartDate: Date | null | undefined, schema: yup.BaseSchema) =>
              schema.test(
                'afterStartDateNotHoliday',
                formatMessage('activities.addBillV2.billForm.date.validation'),
                (value?: Date | null) => isRecurringEndDateValid(value, recurringStartDate)
              )
            ),
        otherwise: yup.date().nullable().optional(),
      }),
    recurringOccurrences: yup
      .number()
      .nullable()
      .transform(transformControlledNumberInput)
      .when(['frequency', 'recurringEndBy'], {
        is: (frequency: AddBillV2FormFrequency, recurringEndBy: AddBillV2RecurringEndBy) =>
          frequency !== AddBillV2FormFrequency.ONE_TIME && recurringEndBy === AddBillV2RecurringEndBy.OCCURRENCES,
        then: (schema) =>
          schema
            .typeError(formatMessage('activities.addBillV2.billForm.recurringOccurrences.validation.required'))
            .positive(formatMessage('activities.addBillV2.billForm.recurringOccurrences.validation.positive'))
            .integer()
            .required(formatMessage('activities.addBillV2.billForm.recurringOccurrences.validation.required'))
            .when('frequency', (frequency: AddBillV2FormFrequency, schema: yup.BaseSchema) =>
              schema.test(
                'not-exceed',
                formatMessage('activities.addBillV2.billForm.recurringOccurrences.validation.exceededLimit', {
                  maxNumOfPayments: MAX_ALLOWED_OCCURRENCES[frequency],
                }),
                (value) => !!value && value <= MAX_ALLOWED_OCCURRENCES[frequency]
              )
            ),
        otherwise: yup.number().nullable().optional(),
      }),
    recurringEndBy: yup.string().nullable(),
  };

  const invoiceNumberSchema = yup
    .string()
    .trim()
    .test(
      'notContainsSensitiveInfo',
      formatMessage('activities.addBillV2.invoiceNumber.validation.sensitive'),
      (invoiceNumber?: string) => (invoiceNumber ? !isContainsSensitiveInfo(invoiceNumber) : true)
    );

  const extendedSchema = {
    invoiceDate: yup.date().when('frequency', {
      is: AddBillV2FormFrequency.ONE_TIME,
      then: yup
        .date()
        .nullable()
        .test(
          'validFormat',
          formatMessage('activities.addBillV2.billForm.date.validation'),
          (value?: Date | null) => value !== null
        ),
      otherwise: yup.date().nullable().optional(),
    }),
    dueDate: yup.date().when('frequency', {
      is: AddBillV2FormFrequency.ONE_TIME,
      then: yup.date().nullable().required(formatMessage('activities.addBillV2.billForm.date.validation')),
      otherwise: yup.date().nullable().optional(),
    }),
    externalLabelId: yup.string().nullable(),
    noteToSelf: yup.string(),
    invoiceNumber: invoiceNumberFormatRegexp
      ? invoiceNumberSchema.matches(invoiceNumberFormatRegexp, {
          message: formatMessage('activities.addBillV2.invoiceNumber.validation'),
          excludeEmptyString: true,
        })
      : invoiceNumberSchema,
    nonSyncedLineItems: yup
      .array()
      .nullable()
      .when('frequency', {
        is: AddBillV2FormFrequency.ONE_TIME,
        then: yup.array().of(
          yup.object().shape({
            amount: yup
              .string()
              .required(formatMessage('activities.addBillV2.lineItems.categoryBased.amount.validation.required')),
            description: yup.string().nullable(),
            order: yup.number(),
          })
        ),
      }),
    categoryBasedLineItems: yup
      .array()
      .nullable()
      .when('frequency', {
        is: AddBillV2FormFrequency.ONE_TIME,
        then: yup.array().of(
          yup.object().shape({
            amount: yup
              .string()
              .required(formatMessage('activities.addBillV2.lineItems.categoryBased.amount.validation.required')),
            description: yup
              .string()
              .nullable()
              .when('amount', {
                is: '0',
                then: (schema) =>
                  schema.required(
                    formatMessage('activities.addBillV2.lineItems.categoryBased.description.validation.requiredOnZero')
                  ),
              }),
            order: yup.number(),
            externalCategoryId: yup.string().nullable(),
            externalLabelId: yup.string().nullable(),
          })
        ),
      }),
    itemBasedLineItems: yup
      .array()
      .nullable()
      .when('frequency', {
        is: AddBillV2FormFrequency.ONE_TIME,
        then: yup.array().of(
          yup.object().shape({
            amount: yup
              .string()
              .required(formatMessage('activities.addBillV2.lineItems.itemBased.amount.validation.required')),
            description: yup.string().nullable(),
            order: yup.number(),
            externalItemId: yup
              .string()
              .required(formatMessage('activities.addBillV2.lineItems.itemBased.itemPicker.validation.required'))
              .nullable(),
            unitPrice: yup
              .string()
              .required(formatMessage('activities.addBillV2.lineItems.itemBased.unitPrice.validation.required')),
            quantity: yup
              .string()
              .required(formatMessage('activities.addBillV2.lineItems.itemBased.quantity.validation.required'))
              .test(
                'is-number',
                formatMessage('activities.addBillV2.lineItems.itemBased.quantity.validation.required'),
                (value) => !Number.isNaN(parseInt(value ?? '', 10))
              ),
            externalLabelId: yup.string().nullable(),
          })
        ),
      }),
    xeroSyncedLineItems: yup
      .array()
      .nullable()
      .when('frequency', {
        is: AddBillV2FormFrequency.ONE_TIME,
        then: yup.array().of(
          yup.object().shape({
            amount: yup
              .string()
              .required(formatMessage('activities.addBillV2.lineItems.xeroSynced.amount.validation.required')),
            description: yup.string().nullable(),
            order: yup.number(),
            externalItemId: yup.string().nullable(),
            unitPrice: yup
              .string()
              .required(formatMessage('activities.addBillV2.lineItems.xeroSynced.unitPrice.validation.required')),
            quantity: yup
              .string()
              .required(formatMessage('activities.addBillV2.lineItems.xeroSynced.quantity.validation.required'))
              .test(
                'is-number',
                formatMessage('activities.addBillV2.lineItems.xeroSynced.quantity.validation.required'),
                (value) => !Number.isNaN(parseInt(value ?? '', 10))
              ),
            externalCategoryId: yup.string().nullable(),
          })
        ),
      }),
    file: yup.mixed(),
  };

  return yup.object().shape({ ...baseSchema, ...extendedSchema }) as unknown as yup.SchemaOf<AddBillV2FormValues>;
};
