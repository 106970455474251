/* eslint-disable react-hooks/exhaustive-deps */
import { PaymentIntent, useBills, usePaymentIntents, useVendors } from '@melio/platform-api';
import { useOneTimeEffect } from '@melio/platform-utils';
import { compact } from 'lodash';
import { useEffect, useState } from 'react';

import {
  createPaymentsIntentsWithDerivatives,
  getPaymentIntentSortedByLatestDueDate,
  getUniqueVendorIds,
  mapBillsToCreatePaymentIntentsPerVendor,
  mapBillToCreatePaymentIntentsPerBill,
} from './BatchPayments.activity.utils';
import { BatchPaymentsActivityProps, SchedulePaymentIntent } from './types';

type UseCreatePaymentIntentsFromBillsParams = Pick<BatchPaymentsActivityProps, 'billIds' | 'onError'>;

export const useCreatePaymentIntentsFromBills = ({ billIds, onError }: UseCreatePaymentIntentsFromBillsParams) => {
  const [isGroupedByVendor, setGroupByVendor] = useState<boolean | undefined>();

  const { data: allBills, update: updateBill } = useBills({
    params: {
      search: {
        'bill.id': billIds,
        'bill.status': {
          $in: ['unpaid', 'paid', 'partially_paid', 'scheduled', 'draft'],
        },
      },
      expand: ['file', 'payments', 'payments.timeline', 'paymentRequest'],
    },
  });

  const vendorsIds = getUniqueVendorIds(allBills);
  const { data: vendors, refetch: refetchVendors } = useVendors({
    enabled: !!vendorsIds.length,
    params: { search: { 'vendor.id': vendorsIds }, expand: ['deliveryMethods', 'traits'] },
  });

  const [paymentIntents, setPaymentIntents] = useState<PaymentIntent[] | undefined>();
  const [paymentIntentsWithDerivatives, setPaymentIntentsWithDerivatives] = useState<
    SchedulePaymentIntent[] | undefined
  >();

  const currentBills = paymentIntents
    ? compact(
        paymentIntents.flatMap((paymentIntent) =>
          paymentIntent.billPayments?.map(({ billId }) => allBills?.find((bill) => bill.id === billId))
        )
      )
    : allBills;

  const [isToggling, setIsToggling] = useState<boolean>();

  const { batchCreateFromBill } = usePaymentIntents({ enabled: false });

  const paymentIntentExpandParam = [
    'deliveryPreferences',
    'calculatedFees',
    'file',
    'minScheduleDate',
    'fundingSourceTypesOptions',
    'deliveryMethodTypeOptions',
  ];

  const create = (shouldGroupByVendor: boolean) => {
    if (!currentBills) {
      return Promise.reject();
    }

    setIsToggling(true);
    return batchCreateFromBill(
      {
        paymentIntents: shouldGroupByVendor
          ? mapBillsToCreatePaymentIntentsPerVendor(currentBills, paymentIntents)
          : mapBillToCreatePaymentIntentsPerBill({
              bills: currentBills,
              currentPaymentIntents: paymentIntents,
              vendors,
            }),
      },
      { expand: paymentIntentExpandParam }
    )
      .then((paymentIntents) => {
        setPaymentIntents(paymentIntents);
        setGroupByVendor(shouldGroupByVendor);
        setIsToggling(false);
        return paymentIntents;
      })
      .catch((err) => {
        setIsToggling(false);
        return onError?.(err as PlatformError);
      });
  };

  const toggleGroupByVendor = () => create(!isGroupedByVendor);
  const updatePaymentIntentsBills = () => create(!!isGroupedByVendor);

  useOneTimeEffect(
    () => {
      create(false);
    },
    () => !!currentBills?.length,
    [currentBills]
  );

  useEffect(() => {
    if (paymentIntents && allBills && vendors) {
      setPaymentIntentsWithDerivatives(
        getPaymentIntentSortedByLatestDueDate(createPaymentsIntentsWithDerivatives(paymentIntents, allBills, vendors))
      );
    }
  }, [paymentIntents, vendors, allBills?.map(({ id }) => id).join('')]);

  return {
    paymentIntentsWithDerivatives,
    vendors,
    refetchVendors,
    allBills,
    updateBill,
    paymentIntents,
    setPaymentIntents,
    isToggling,
    isGroupedByVendor,
    toggleGroupByVendor,
    updatePaymentIntentsBills,
  };
};
