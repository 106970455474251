import { BillProvider, providerConfiguration, ProviderProps, SupportedProviders } from './types';
import {
  useCreateBillsSyncConnectionMutation,
  useDeleteBillSyncConnectionMutation,
  useGetBillSyncStatus,
} from './useBillSyncApi';
import { isConnected, makeConnect } from './utils';

export function useProvider(providerId: SupportedProviders, props?: ProviderProps): BillProvider {
  const configuration = providerConfiguration[providerId];

  const {
    isLoading: isDisconnectLoading,
    createCallback: createDisconnectCallback,
    isError: isDisconnectError,
  } = useDeleteBillSyncConnectionMutation({ ...configuration, ...props });

  const {
    isLoading: isConnectLoading,
    mutateAsync: connect,
    isError: isConnectError,
  } = useCreateBillsSyncConnectionMutation({ ...configuration, ...props });

  const {
    isLoading: isStatusLoading,
    data: status,
    isError: isStatusError,
  } = useGetBillSyncStatus({ ...configuration, ...props });

  return {
    name: providerId,
    status: status?.syncStatus,
    itemsSynced: status?.itemsSynced,
    isStatusLoading,
    isStatusError,
    connect: makeConnect(connect),
    isConnectLoading,
    isConnectError,
    disconnect: createDisconnectCallback(),
    isDisconnectLoading,
    isDisconnectError,
    isConnected: isConnected(status),
    lastCompletionTime: status?.lastCompletionTime,
  };
}
