import {
  getDeliveryMethodIconKey,
  useGetDeliveryMethodDisplayText,
  usePaymentSchedulingPreference,
  usePaymentsLateApprovals,
  usePaymentsLateApprovalsEnabled,
} from '@melio/ap-domain';
import {
  PaymentReviewLineItem,
  useGetDeliveryMethodHelperText,
  useGetDeliveryMethodLabelText,
  usePaymentProcessingInfo,
  useShowMemoToVendor,
} from '@melio/ap-widgets';
import { Group } from '@melio/penny';
import { DeliveryMethodType, PaymentFullyExpanded } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';

import { useInternationalCountryDisplayName } from '../../../../../utils/pay-flow/deliveryMethods';
import { useVendorReceivesTexts } from '../../../hooks/useVendorReceivesTexts';
import { SectionBlock } from './SectionBlock';

export type VendorReceivesSectionProps = { vendorName: string; payment: PaymentFullyExpanded };
export const VendorReceivesSection = ({ vendorName, payment }: VendorReceivesSectionProps) => {
  const { formatMessage, formatDateTimeRange, formatDate } = useMelioIntl();
  const { settings } = useConfig();
  const { isByDeliveryDate } = usePaymentSchedulingPreference();
  const { countryDisplayName } = useInternationalCountryDisplayName(payment.deliveryMethod);
  const getPaymentsLateApprovalsEnabled = usePaymentsLateApprovalsEnabled();
  const { mainLabelTooltipProps } = usePaymentLateApprovalTooltip(payment);

  const paymentsLateApprovalsEnabled = getPaymentsLateApprovalsEnabled({ payments: [payment] });
  const paymentLateApprovalEnabled = !!paymentsLateApprovalsEnabled[payment.id]?.isEnabled;
  const deliveryMethodLabelText = useGetDeliveryMethodLabelText(
    payment.deliveryMethod,
    payment.timeline,
    payment.deliveryPreferenceType
  );
  const deliveryMethodDisplayText = useGetDeliveryMethodDisplayText(payment.deliveryMethod, vendorName);
  const deliveryMethodIconKey = getDeliveryMethodIconKey(payment.deliveryMethod, settings.icons.deliveryMethod);
  const deliveryMethodHelperText = useGetDeliveryMethodHelperText(
    payment.deliveryMethod,
    payment.timeline,
    countryDisplayName
  );
  const deliveryDateMainLabel = isByDeliveryDate
    ? formatDate(payment.estimatedDelivery, { dateStyle: 'medium' })
    : formatDateTimeRange(payment.estimatedDelivery, payment.maxEstimatedDelivery, { dateStyle: 'medium' });
  const { shouldDisplayMemoToVendor } = useShowMemoToVendor();

  const showMemoToVendor = shouldDisplayMemoToVendor(payment.deliveryMethod);

  const { label: deliveryDateLabel, description: deliveryDateDescription } = useVendorReceivesTexts({
    estimatedDelivery: payment.estimatedDelivery,
    paymentStatus: payment.status,
    processedTimeline: payment.processedTimeline,
    type: payment.deliveryMethod.type,
  });

  const { isPaymentProcessedByCapitalOne } = usePaymentProcessingInfo();
  const isPaymentProcessedInternally = !isPaymentProcessedByCapitalOne(payment.deliveryMethod.type);

  const labelProps = deliveryDateLabel
    ? {
        label: deliveryDateLabel,
        'data-testid': 'delivery-date-label',
      }
    : undefined;

  const descriptionProps = deliveryDateDescription
    ? {
        label: deliveryDateDescription,
        'data-testid': 'delivery-date-description',
      }
    : undefined;

  return (
    <Group variant="vertical" spacing="s">
      <SectionBlock.Title label={formatMessage('widgets.paymentDetails.deliveryMethod.title')} />

      {payment.deliveryMethod.type !== DeliveryMethodType.VirtualAccount && (
        <>
          <PaymentReviewLineItem
            data-testid="delivery-method-check-scheduled-label"
            contentTestId="delivery-method-details"
            labelProps={{ label: deliveryMethodLabelText }}
            mainLabelProps={{ label: deliveryMethodDisplayText }}
            icon={{ type: deliveryMethodIconKey }}
            descriptionProps={{ label: deliveryMethodHelperText }}
          />
        </>
      )}

      {isPaymentProcessedInternally && (
        <>
          <PaymentReviewLineItem
            contentTestId="delivery-eta-content"
            labelProps={labelProps}
            mainLabelProps={{
              label: deliveryDateMainLabel,
              'data-testid': 'delivery-eta-content-delivery-date',
              tooltipProps: paymentLateApprovalEnabled ? mainLabelTooltipProps : undefined,
            }}
            descriptionProps={descriptionProps}
            icon={{ type: 'scheduled' }}
          />
          {showMemoToVendor && (
            <>
              <PaymentReviewLineItem
                labelProps={{ label: formatMessage('widgets.paymentDetails.vendorReceives.memoToVendor.label') }}
                mainLabelProps={{
                  label: payment.note ?? undefined,
                  placeholder: formatMessage('widgets.paymentDetails.vendorReceives.memoToVendor.empty'),
                }}
                contentTestId="memo-to-vendor-content"
                icon={{ type: 'note-add' }}
              />
            </>
          )}
        </>
      )}
    </Group>
  );
};

const usePaymentLateApprovalTooltip = (payment: PaymentFullyExpanded) => {
  const { formatMessage } = useMelioIntl();
  const getPaymentsLateApprovals = usePaymentsLateApprovals();

  const paymentsLateApprovals = getPaymentsLateApprovals({ payments: [payment] });
  const paymentLateApproval = paymentsLateApprovals[payment.id];

  const getMainLabelTooltipProps = ():
    | {
        label: string;
        title?: string;
        triggerAriaLabel?: string;
        triggerStatus?: 'warning' | 'informative';
      }
    | undefined => {
    const isDebitDateAfterToday = !!paymentLateApproval?.isDebitDateAfterToday;

    if (payment.fundingSource?.type === 'bank-account') {
      return isDebitDateAfterToday
        ? {
            title: formatMessage('widgets.paymentDetails.deliveryMethod.lateDebitDate.bank-account.title'),
            label: formatMessage('widgets.paymentDetails.deliveryMethod.lateDebitDate.bank-account.description'),
            triggerStatus: 'warning',
          }
        : undefined;
    } else if (payment.fundingSource?.type === 'card') {
      return isDebitDateAfterToday
        ? {
            title: formatMessage('widgets.paymentDetails.deliveryMethod.lateDebitDate.card.title'),
            label: formatMessage('widgets.paymentDetails.deliveryMethod.lateDebitDate.card.description'),
            triggerStatus: 'warning',
          }
        : undefined;
    }
    return isDebitDateAfterToday
      ? {
          title: formatMessage('widgets.paymentDetails.deliveryMethod.lateDebitDate.default.title'),
          label: formatMessage('widgets.paymentDetails.deliveryMethod.lateDebitDate.default.description'),
          triggerStatus: 'warning',
        }
      : undefined;
  };

  return { mainLabelTooltipProps: getMainLabelTooltipProps() };
};
