import { Flex } from '@chakra-ui/react';
import { Button, Group, Icon, IconKey, Pill, Text, useBreakpoint } from '@melio/penny';
import { ButtonVariants } from '@melio/penny/dist/cjs/theme/utils/button.theme.utils';
import * as React from 'react';

type ButtonProps = {
  text: string;
  icon?: IconKey;
  testId: string;
  variant?: ButtonVariants | undefined;
  onClick?: VoidFunction;
};

type SectionHeaderWithButtonProps = {
  title: string;
  primaryButton?: ButtonProps;
  secondaryButton?: ButtonProps;
  badgeText?: React.ReactNode;
  titleAs?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
};

export const SectionHeaderWithButtons = ({
  title,
  badgeText,
  primaryButton,
  secondaryButton,
  titleAs = 'h2',
}: SectionHeaderWithButtonProps) => {
  const { isExtraSmallScreen, isSmallScreen } = useBreakpoint();
  const isVerticalLayout = (isExtraSmallScreen || isSmallScreen) && secondaryButton?.onClick;
  const isLeftAlign = (isExtraSmallScreen || isSmallScreen) && secondaryButton?.onClick;
  return (
    <Flex mb="m">
      <Group
        alignItems={isLeftAlign ? 'flex-start' : 'center'}
        variant={isVerticalLayout ? 'vertical' : 'horizontal'}
        justifyContent="space-between"
        width="full"
      >
        <Group alignItems="center">
          <Text as={titleAs} textStyle="heading3Semi">
            {title}
          </Text>
          {badgeText && <Pill status="brand" label={badgeText as string} />}
        </Group>
        <Group justifyContent="flex-start" alignItems="flex-start">
          {secondaryButton?.onClick && (
            <Button
              leftElement={
                secondaryButton.icon ? (
                  <Icon size="small" type={secondaryButton.icon} color="inherit" aria-hidden />
                ) : undefined
              }
              onClick={secondaryButton.onClick}
              label={secondaryButton.text}
              data-testid={secondaryButton.testId}
              variant={secondaryButton.variant}
            />
          )}
          {primaryButton?.onClick && (
            <Button
              leftElement={
                primaryButton.icon ? (
                  <Icon size="small" type={primaryButton.icon} color="inherit" aria-hidden />
                ) : undefined
              }
              onClick={primaryButton.onClick}
              label={primaryButton.text}
              data-testid={primaryButton.testId}
            />
          )}
        </Group>
      </Group>
    </Flex>
  );
};
