import { Group, Text } from '@melio/penny';
import { FundingSource, SelectedRepaymentOption } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';

import { InstallmentsAmount } from './components/InstallmentsAmount';
import { InstallmentsListContainer } from './components/InstallmentsListContainer';
import { RepaymentFrom } from './components/RepaymentFrom';

export type RepaymentSectionProps = {
  repaymentOption?: SelectedRepaymentOption;
  fundingSource: FundingSource;
  onEditFundingSource?: VoidFunction;
  onEditRepaymentTerm?: VoidFunction;
};

export const RepaymentSection = ({
  repaymentOption,
  fundingSource,
  onEditFundingSource,
  onEditRepaymentTerm,
}: RepaymentSectionProps) => {
  const { formatMessage } = useMelioIntl();

  if (!repaymentOption) {
    return null;
  }

  return (
    <Group variant="vertical" spacing="s" data-testid="payment-review-repayment-section">
      <Text textStyle="body4SemiUpper" color="global.neutral.900">
        {formatMessage('widgets.reviewAndConfirm.repaymentSection.title')}
      </Text>
      <Group variant="vertical" spacing="m" hasDivider>
        <InstallmentsAmount numOfInstallments={repaymentOption.installments.length || 0} onEdit={onEditFundingSource} />
        <InstallmentsListContainer onEdit={onEditRepaymentTerm} repaymentOption={repaymentOption} />
        <RepaymentFrom fundingSource={fundingSource} />
      </Group>
    </Group>
  );
};
