import {
  AutoPayment,
  PatchVendorAutoPaymentRequest,
  PostVendorAutoPaymentRequest,
} from '@melio/platform-api-axios-client';
import { convertCentsToDollars, convertDollarsToCents } from '@melio/platform-utils';

export const prepareCreateAutoPaymentParams = (params: PostVendorAutoPaymentRequest) =>
  convertDollarsToCents(params, ['maxAmount', 'fixedAmount']);

export const prepareUpdateAutoPaymentParams = (params: PatchVendorAutoPaymentRequest) =>
  convertDollarsToCents(params, ['maxAmount', 'fixedAmount']);

export const convertAutoPaymentResponseCentsToDollars = (autoPayment: AutoPayment) =>
  convertCentsToDollars(autoPayment, ['maxAmount', 'fixedAmount']);
