import { useMelioForm } from '@melio/penny';
import { TaxpayerInfo } from '@melio/platform-api';
import * as yup from 'yup';

import { W9SubmissionMethod } from './types';

type Fields = {
  W9SubmissionMethod: W9SubmissionMethod;
};

const schema = yup.object().shape({
  W9SubmissionMethod: yup.string().required(),
}) as yup.SchemaOf<Fields>;

export const useTaxPayerInfoForm = ({ taxPayerInfo }: { taxPayerInfo?: TaxpayerInfo }) => {
  const { registerField, formProps, watch, setValue } = useMelioForm({
    schema,
    onSubmit: () => void 0,
    defaultValues: { W9SubmissionMethod: taxPayerInfo?.file ? W9SubmissionMethod.Pdf : W9SubmissionMethod.Email },
    subscribeToDefaultValuesChanges: true,
  });

  return {
    registerField,
    setValue,
    formProps,
    watch,
  };
};
