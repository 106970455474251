import { Group } from '@melio/penny';
import { ExchangeRateBase, Vendor } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';

import { EditableScreenTitle } from './EditableScreenTitle';
import { FxSubtitle } from './FxSubtitle';
import { NonEditableScreenTitle } from './NonEditableScreenTitle';

type FundingSourceSelectionScreenTitleProps = {
  vendorName: Vendor['name'];
  paymentAmount: number;
  currency?: string;
  onChangePaymentAmount?: (newAmount: number) => void;
  isEbill: boolean;
  billBalance?: number;
  minimumAmount?: number;
  shouldAllowEditAmount?: boolean;
  onError?: VoidFunction;
  isRecurringPayment?: boolean;
  fxRate?: ExchangeRateBase;
  isLoadingFxRate: boolean;
};

export const FundingSourceSelectionScreenTitle = forwardRef<FundingSourceSelectionScreenTitleProps>(
  (
    {
      vendorName,
      paymentAmount,
      currency,
      isRecurringPayment,
      onChangePaymentAmount,
      isEbill,
      billBalance,
      minimumAmount,
      shouldAllowEditAmount,
      onError,
      fxRate,
      isLoadingFxRate,
      ...props
    },
    ref
  ) => {
    const isFxPayment = currency !== 'USD';
    const [isPartialPaymentsEnabled] = useFeature<boolean>(FeatureFlags.PartialPaymentsEnabled, false);

    return (
      <Group variant="vertical" alignItems="center" {...props} ref={ref} data-testid="funding-source-selection-title">
        {isPartialPaymentsEnabled && shouldAllowEditAmount && billBalance ? (
          <EditableScreenTitle
            vendorName={vendorName}
            paymentAmount={paymentAmount}
            onChangePaymentAmount={onChangePaymentAmount}
            isEbill={isEbill}
            billBalance={billBalance}
            minimumAmount={minimumAmount}
            onError={onError}
          />
        ) : (
          <NonEditableScreenTitle
            vendorName={vendorName}
            paymentAmount={paymentAmount}
            currency={currency}
            isRecurringPayment={isRecurringPayment}
          />
        )}
        {isFxPayment && (
          <FxSubtitle
            fxRate={fxRate as ExchangeRateBase}
            isLoadingFxRate={isLoadingFxRate}
            paymentAmount={paymentAmount}
            currency={currency}
          />
        )}
      </Group>
    );
  }
);
