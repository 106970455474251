import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import groupBy from 'lodash/groupBy';
import { Button, Form, Modal, useFormSubmissionController, useMelioForm, useToast } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useConfig } from '@melio/platform-provider';
import { useIsFeatureInSubscription, useIsSubscriptionsEnabled, useSubscription } from '@melio/subscriptions';

import { supportApi } from '@/api/apiClients';
import { usePlatformIntl } from '@/translations/Intl';
import {
  bindLocalMessageKey,
  CallbackFormFields,
  getFormData,
  IssueType,
} from '@/widgets/settings-page/SupportPage/RequestCallback/RequestCallback.utils';

const feature: Parameters<typeof useIsFeatureInSubscription>[0] = { featureName: 'requestCallbackSupport' };

const RequestCallbackWidget = () => {
  const {
    settings: { isRequestCallbackEnabled },
  } = useConfig();
  const { formatMessage } = usePlatformIntl();
  const { toast } = useToast();
  const { track } = useAnalytics();
  const { isOpen: isModalOpen, onOpen: onOpenModal, onClose: onCloseModal } = useDisclosure();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isOffHours, setIsOffHours] = useState<boolean>(true);
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const subscription = useSubscription();
  const { isEligible } = useIsFeatureInSubscription(feature);

  const formatLocalMessage = bindLocalMessageKey(formatMessage);
  const { formSchema, issueTypeOptions, formatIssueTypeForAnalytics, formLabels } = useRef(
    getFormData(formatLocalMessage),
  ).current;

  useEffect(() => {
    if (!isEligible) {
      return;
    }

    supportApi
      .getZdSchedule()
      .then(({ data: { offHours } }) => {
        setIsOffHours(offHours);
      })
      .catch(() => {
        setIsOffHours(true);
      });
  }, [isEligible]);

  const isWidgetEnabled = !isOffHours;

  const reportWidgetClicked = useCallback(() => {
    track('Support', 'Click', {
      PageName: 'support-hub',
      SupportType: 'phone',
      Flow: 'request-callback',
      Intent: 'request-callback',
      Cta: 'request-a-callback',
      PlanId: subscription?.planId,
    });
  }, [track, subscription?.planId]);
  const reportCallbackFormDisplayed = useCallback(() => {
    track('Support', 'View', {
      PageName: 'request-callback-form',
      Flow: 'request-callback',
      Intent: 'submit-request-callback',
      PlanId: subscription?.planId,
    });
  }, [track, subscription?.planId]);
  const reportIssueTypeClicked = useCallback(() => {
    track('Support', 'Click', {
      PageName: 'request-callback-form',
      SupportType: 'form',
      Flow: 'request-callback',
      Intent: 'select-issue',
      Cta: 'select-your-issue',
      PlanId: subscription?.planId,
    });
  }, [track, subscription?.planId]);
  const reportIssueTypeSelected = useCallback(
    (issueType: IssueType) => {
      track('Support', 'Click', {
        PageName: 'request-callback-form',
        SupportType: 'form',
        Flow: 'request-callback',
        Intent: 'select-issue-type',
        Cta: formatIssueTypeForAnalytics(issueType),
        PlanId: subscription?.planId,
      });
    },
    [formatIssueTypeForAnalytics, track, subscription?.planId],
  );
  const reportSubmitClicked = useCallback(() => {
    track('Support', 'Click', {
      PageName: 'request-callback-form',
      SupportType: 'form',
      Flow: 'request-callback',
      Intent: 'submit-callback-request',
      Cta: 'submit-request',
      PlanId: subscription?.planId,
    });
  }, [track, subscription?.planId]);
  const reportFormValidationFailed = useCallback(
    (errorType: 'missing-value' | 'invalid-value', errorFields: (keyof CallbackFormFields)[]) => {
      track('Support', 'Status', {
        PageName: 'request-callback-form',
        SupportType: 'form',
        Flow: 'request-callback',
        StatusType: 'failure',
        ErrorType: errorType,
        ErrorFields: errorFields.sort().join('_'),
        PlanId: subscription?.planId,
      });
    },
    [track, subscription?.planId],
  );
  const reportFormValidationSucceeded = useCallback(
    (fields: CallbackFormFields) => {
      const { issueType, paymentId, intent } = fields;

      track('Support', 'Status', {
        PageName: 'request-callback-form',
        SupportType: 'form',
        Flow: 'request-callback',
        StatusType: 'success',
        IssueType: formatIssueTypeForAnalytics(issueType),
        PaymentId: paymentId,
        RequestDescription: intent,
        PlanId: subscription?.planId,
      });
    },
    [formatIssueTypeForAnalytics, track, subscription?.planId],
  );
  const reportCallbackRequestedSuccessfully = useCallback(() => {
    track('Support', 'Status', {
      PageName: 'support-hub',
      Flow: 'request-callback',
      StatusType: 'success',
      PlanId: subscription?.planId,
    });
  }, [track, subscription?.planId]);
  const reportCallbackRequestedError = useCallback(
    (error: Error) => {
      track('Support', 'Status', {
        PageName: 'request-callback-form',
        SupportType: 'form',
        Flow: 'request-callback',
        StatusType: 'failure',
        ErrorType: 'api-failure',
        error: error.message.toString(),
        PlanId: subscription?.planId,
      });
    },
    [track, subscription?.planId],
  );

  const onSubmit = async (fields: CallbackFormFields) => {
    try {
      setIsSaving(true);
      reportFormValidationSucceeded(fields);
      await supportApi.postSupportRequestCallback({ supportRequestCallbackBody: fields });
      toast({
        title: formatLocalMessage('success'),
        type: 'success',
      });
      reportCallbackRequestedSuccessfully();
      onCloseModal();
    } catch (e) {
      reportCallbackRequestedError(e as Error);
      toast({
        title: formatLocalMessage('error'),
        type: 'error',
      });
    } finally {
      setIsSaving(false);
    }
  };

  const { submitButtonProps, onSubmissionStateChange } = useFormSubmissionController<CallbackFormFields>();
  const { registerField, formProps, watch, formState } = useMelioForm<CallbackFormFields>({
    schema: formSchema,
    onSubmit,
    onSubmissionStateChange,
    isSaving,
  });

  useEffect(() => {
    if (formState.isValid || formState.submitCount == 0 || !formState.errors || !Object.keys(formState.errors).length) {
      return;
    }

    const { required, invalid } = groupBy(
      Object.entries(formState.errors).map(
        ([field, { type }]) => ({ field, type } as { field: keyof CallbackFormFields; type: string }),
      ),
      ({ type }) => (type === 'required' ? 'required' : 'invalid'),
    );

    if (required?.length) {
      reportFormValidationFailed(
        'missing-value',
        required.map(({ field }) => field),
      );
    }

    if (invalid?.length) {
      reportFormValidationFailed(
        'invalid-value',
        invalid.map(({ field }) => field),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.submitCount, reportFormValidationFailed]);

  const issueType = watch('issueType');
  const shouldShowPaymentIdField = useMemo(() => issueType === IssueType.Payment, [issueType]);
  useEffect(() => {
    reportIssueTypeSelected(issueType);
  }, [issueType, reportIssueTypeSelected]);

  if (!isSubscriptionsEnabled || !isRequestCallbackEnabled) return null;

  const showModal = () => {
    onOpenModal();
    reportCallbackFormDisplayed();
  };

  const onWidgetClick = () => {
    reportWidgetClicked();
    showModal();
  };

  return (
    <>
      {isEligible ? (
        <Button
          variant={'secondary'}
          onClick={onWidgetClick}
          label={formatLocalMessage('cta')}
          isDisabled={!isWidgetEnabled}
        />
      ) : null}

      <Modal
        isOpen={isModalOpen}
        onClose={onCloseModal}
        header={formatLocalMessage('title')}
        data-testid="support-callback-modal-activity"
      >
        <Form {...formProps}>
          <Form.PhoneField
            {...registerField('phoneNumber')}
            labelProps={{
              label: formLabels.phoneNumber.label,
            }}
          />
          <Form.SelectNew
            {...registerField('issueType')}
            options={issueTypeOptions}
            labelProps={{
              label: formLabels.issueType.label,
            }}
            placeholder={formLabels.issueType.placeholder}
            onClick={reportIssueTypeClicked}
          />
          <Form.TextField
            {...registerField('paymentId')}
            labelProps={{
              label: formLabels.paymentId.label,
            }}
            isHidden={!shouldShowPaymentIdField}
            // setting 'required' explicitly since form cannot capture it from conditional yup schema
            isRequired={true}
          />
          <Form.TextArea
            {...registerField('intent')}
            labelProps={{
              label: formLabels.intent.label,
            }}
            placeholder={formLabels.intent.placeholder}
          />
          <Button
            variant={'primary'}
            label={formLabels.submit.label}
            {...submitButtonProps}
            data-testid="support-callback-modal-submit"
            onClick={(e) => {
              reportSubmitClicked();
              formProps.onSubmit(e);
            }}
          />
        </Form>
      </Modal>
    </>
  );
};

export default RequestCallbackWidget;
