import { Group } from '@melio/penny';
import {
  DeliveryPreference,
  FundingSource,
  PaymentIntent,
  RepaymentOption,
  SelectedRepaymentOption,
} from '@melio/platform-api';

import { SelectedRepaymentOptionFormSchema } from '../types';
import { RepaymentOptionCard } from './RepaymentOptionCard';

export type RepaymentOptionsListProps = {
  repaymentOptions: RepaymentOption[];
  onSelect: (numOfInstallments: number) => void;
  selectedNumOfInstallments?: number;
  repaymentFundingSources?: FundingSource[];
  paymentIntent?: PaymentIntent;
  selectedRepaymentTerm?: SelectedRepaymentOption;
  onPressContinue: (data: SelectedRepaymentOptionFormSchema) => void;
  selectedDate?: Date;
  setSelectedDate: (date?: Date) => void;
  deliveryPreferenceOption?: DeliveryPreference;
  onAddBankAccount: VoidFunction;
  isLoadingSelectRepaymentTerms?: boolean;
};
export const InstallmentOptionsList: React.VFC<RepaymentOptionsListProps> = (props) => (
  <Group variant="vertical" width="full">
    {props.repaymentOptions.map((option) => (
      <RepaymentOptionCard
        deliveryPreferenceOption={props.deliveryPreferenceOption}
        selectedDate={props.selectedDate}
        setSelectedDate={props.setSelectedDate}
        onPressContinue={props.onPressContinue}
        selectedRepaymentTerm={props.selectedRepaymentTerm}
        repaymentFundingSources={props.repaymentFundingSources}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        key={`option-${option.installmentAmount!}-${option.paymentAmount!}`}
        paymentIntent={props.paymentIntent}
        option={option}
        onSelect={props.onSelect}
        isSelected={props.selectedNumOfInstallments === option.numOfInstallments}
        onAddBankAccount={props.onAddBankAccount}
        isLoadingSelectRepaymentTerms={props.isLoadingSelectRepaymentTerms}
      />
    ))}
  </Group>
);
