import { Box } from '@chakra-ui/react';
import { Form, Group, SimpleGrid, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export type FormSectionProps = {
  colSpan?: Parameters<typeof SimpleGrid>[0]['columns'];
  columns?: Parameters<typeof SimpleGrid>[0]['columns'];
  title?: string;
  subTitle?: string;
  isHidden?: boolean;
};

export const FormSection = forwardRef<FormSectionProps>(
  ({ isHidden, title, subTitle, columns, children, ...props }, ref) => (
    <Form.ContentBox data-component={FormSection.displayName} {...props} ref={ref}>
      <Box hidden={isHidden}>
        <Group variant="vertical" spacing="m">
          <Group variant="vertical" spacing="s">
            <Text as="h2" textStyle="heading3Semi">
              {title}
            </Text>
            {subTitle ? (
              <Text as="p" textStyle="body4" color="global.neutral.800">
                {subTitle}
              </Text>
            ) : null}
          </Group>
          <SimpleGrid columns={columns} spacing="m">
            {children}
          </SimpleGrid>
        </Group>
      </Box>
    </Form.ContentBox>
  )
);
FormSection.displayName = 'FormSection';
