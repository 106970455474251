/* eslint-disable react-hooks/exhaustive-deps */
import { StatusModal } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';
import { useEffect, useState } from 'react';

export type ConfirmationModalScreenProps = {
  isOpen: boolean;
  vendors: { name: Vendor['name'] }[];
  onDone: VoidFunction;
};

export const ConfirmationModalScreen: React.VFC<ConfirmationModalScreenProps> = ({ vendors, isOpen, onDone }) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const [headerTitle, setHeaderTitle] = useState<string>('');
  const [content, setContent] = useState<string>();
  useEffect(() => {
    isOpen && track('VendorWillBeNotified', 'Viewed');
  }, [isOpen]);

  useEffect(() => {
    if (vendors.length === 1) {
      const vendorName = vendors[0]?.name;
      setHeaderTitle(
        formatMessage('activities.paymentScheduledNotifyVendor.screens.confirmation.title.singleVendor', {
          vendorName,
        })
      );
      setContent(
        formatMessage('activities.paymentScheduledNotifyVendor.screens.confirmation.description.singleVendor', {
          vendorName,
        })
      );
    } else {
      setHeaderTitle(
        formatMessage('activities.paymentScheduledNotifyVendor.screens.confirmation.title.multipleVendors')
      );
      setContent(
        formatMessage('activities.paymentScheduledNotifyVendor.screens.confirmation.description.multipleVendors')
      );
    }
  }, [vendors]);

  return (
    <StatusModal
      data-testid="payment-scheduled-notify-vendor-modal-activity-confirmation-modal-screen"
      variant="success"
      header={headerTitle}
      isOpen={isOpen}
      onClose={onDone}
      secondaryButton={{
        variant: 'tertiary',
        label: formatMessage('activities.paymentScheduledNotifyVendor.screens.confirmation.continue'),
        onClick: () => {
          track('VendorWillBeNotified', 'Acknowledge');
          onDone();
        },
      }}
    >
      {content}
    </StatusModal>
  );
};

ConfirmationModalScreen.displayName = 'PaymentScheduledNotifyVendorModalActivity.ConfirmationModalScreen';
