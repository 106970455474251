import { Group, Icon, IconKey, Text } from '@melio/penny';

export type DescriptionModalContent = {
  key: string;
  type: 'description';
  icon: IconKey;
  title: string;
  explanation: string;
};

export type DescriptionTypeContentProps = Omit<DescriptionModalContent, 'key'>;

export const DescriptionTypeContent: React.FC<DescriptionTypeContentProps> = ({ icon, title, explanation }) => (
  <Group>
    <Group.Item alignSelf="flex-start">
      <Icon type={icon} />
    </Group.Item>
    <Group variant="vertical" spacing="none">
      <Text textStyle="body3Semi">{title}</Text>
      <Text textStyle="body3">{explanation}</Text>
    </Group>
  </Group>
);
