import { Container } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-provider';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';
import { PaymentFlowDoneAction } from '../../../../types';

type ExternalEntryActionProps = {
  onDone: (action: PaymentFlowDoneAction) => void;
  externalOrigin?: string | null;
  isNewSuccessScreen?: boolean;
};

export const ExternalEntryAction = ({ onDone, externalOrigin, isNewSuccessScreen }: ExternalEntryActionProps) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();

  const onBack = () => {
    window.parent.postMessage({ type: 'BACK_TO_EXTERNAL' }, '*');
  };

  const notifyVendorProps = {
    onClick: () => {
      track('Payment', 'Click', {
        PageName: 'payment-scheduled',
        Intent: 'back-to-external',
        Cta: 'back-to-external',
      });
      onBack();
    },
    'data-testid': 'external-notify-vendors-button',
    label: formatMessage('activities.paymentScheduled.screens.paymentScheduled.externalEntry.cta', {
      externalOrigin: externalOrigin || '',
    }),
  };

  const continueProps = {
    onClick: () => {
      track('Payment', 'Click', {
        PageName: 'payment-scheduled',
        Intent: 'open-in-new-tab-dashboard',
        Cta: 'open-in-new-tab-dashboard',
      });
      onDone('openInNewTabDashboard');
    },
    label: formatMessage('activities.paymentScheduled.screens.paymentScheduled.externalEntry.secondary'),
  };

  if (isNewSuccessScreen) {
    return (
      <>
        <NewSinglePaymentStepLayout.NextButton {...continueProps} variant="tertiary" size="medium" />
        <NewSinglePaymentStepLayout.NextButton {...notifyVendorProps} size="medium" />
      </>
    );
  }

  return (
    <>
      <NewSinglePaymentStepLayout.NextButton {...notifyVendorProps} />
      <Container justifyContent="center">
        <NewSinglePaymentStepLayout.NakedNextButton {...continueProps} />
      </Container>
    </>
  );
};
