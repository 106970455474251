import {
  Todo,
  TodoActivateCollaboratorsTypeEnum,
  TodoFailedPaymentTypeEnum,
  TodoOverdueIn7DaysInboxItemsTypeEnum,
  TodoOverdueInboxItemsTypeEnum,
  TodoOverdueInMoreThan7DaysInboxItemsTypeEnum,
  TodoPaymentsToApproveTypeEnum,
} from '@melio/platform-api-axios-client';

export const todosOrder: Partial<Todo['type']>[] = [
  TodoFailedPaymentTypeEnum.FailedPayments,
  TodoActivateCollaboratorsTypeEnum.ActivateCollaborators,
  TodoPaymentsToApproveTypeEnum.PaymentsToApprove,
  TodoOverdueInboxItemsTypeEnum.OverdueInboxItems,
  TodoOverdueIn7DaysInboxItemsTypeEnum.OverdueIn7DaysInboxItems,
  TodoOverdueInMoreThan7DaysInboxItemsTypeEnum.OverdueInMoreThan7DaysInboxItems,
];

export const getTotalCount = (data: Todo[] | undefined): number =>
  data?.reduce((count, todo) => {
    const curAmount = todo.items.pagination.totalCount || 0;
    if (todo.type === TodoActivateCollaboratorsTypeEnum.ActivateCollaborators && curAmount > 0) {
      return count + 1;
    }
    return count + curAmount;
  }, 0) ?? 0;
