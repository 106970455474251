import { DeliveryMethod } from '@melio/platform-api';
import { useBoolean } from '@melio/platform-utils';
import { useCallback, useLayoutEffect, useState } from 'react';

export function useConfirmationModal({ deliveryMethod }: { deliveryMethod: DeliveryMethod | undefined }) {
  const [open, modalControls] = useBoolean(false);
  const [editing, setEditing] = useState(false);

  const resetEditing = useCallback(() => setEditing(!deliveryMethod?.requireConfirmationForPayment), [deliveryMethod]);
  useLayoutEffect(resetEditing, [resetEditing]);

  return {
    isOpen: open,
    editing,
    onEdit: () => setEditing(true),
    open: modalControls.on,
    close: () => {
      modalControls.off();
      resetEditing();
    },
  };
}
