import { Vendor } from '@melio/platform-api';

import { isRppsDeliveryMethod } from './delivery-method-utils';

export const getVendorAccountNumber = (vendor: Vendor) => {
  const rppsDeliveryMethod = vendor?.deliveryMethods?.find(isRppsDeliveryMethod);
  return rppsDeliveryMethod?.details.accountIdentifier || vendor?.accountNumber;
};

export const getIsRppsVendor = (vendor: Vendor) => vendor?.deliveryMethods?.some(isRppsDeliveryMethod);
