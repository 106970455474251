import { useMelioIntl } from '@melio/ar-domain';
import { Group } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { Footer, OnboardingLayout } from '../../../layouts';
import { TrackerStepData } from '../../../utils';
import { FinancialDetailsHeader } from '../components';
import { FinancialInformationFormFields } from '../types';

export type FinancialInformationScreenProps = {
  onSubmit: (data: FinancialInformationFormFields) => unknown;
  defaultValues?: Partial<FinancialInformationFormFields>;
  error?: ARPlatformError;
  isLoading: boolean;
  isSaving: boolean;
  steps: TrackerStepData[];
  currentStepIndex: number;
  onBack: VoidFunction;
};

export const FinancialInformationScreen = forwardRef<FinancialInformationScreenProps>(
  ({ steps, currentStepIndex, isLoading, onBack }) => {
    const { formatMessage } = useMelioIntl();

    return (
      <OnboardingLayout
        isLoading={isLoading}
        steps={steps}
        headerContent={formatMessage('ar.onboarding.layout.header.text')}
        currentStepIndex={currentStepIndex}
        footerContent={
          <Footer
            primaryButtonProps={{
              label: formatMessage('ar.onboarding.layout.buttons.continue.label'),
            }}
            secondaryButtonProps={{
              label: formatMessage('ar.onboarding.layout.buttons.back.label'),
              onClick: onBack,
            }}
            tertiaryButtonProps={{
              label: formatMessage('ar.onboarding.layout.buttons.cancel.label'),
            }}
          />
        }
        isStickyFooter
        data-component={FinancialInformationScreen.displayName}
        data-testid="financial-information-screen"
      >
        <Group variant="vertical" width="full" spacing="xl">
          <FinancialDetailsHeader />
        </Group>
      </OnboardingLayout>
    );
  }
);
FinancialInformationScreen.displayName = 'FinancialInformationScreen';
