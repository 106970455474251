import { isEbill } from '@melio/ap-domain';
import { EbillCellBadge } from '@melio/ap-widgets';
import { Group, Popover, StatusIconOutlined, Table, TableCellProps, Text } from '@melio/penny';
import { Bill, PaymentIntent, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import { identity } from 'lodash';

export type BillDetailsCellProps = TableCellProps & {
  vendor: Vendor;
  paymentIntent: PaymentIntent;
  bills: Bill[];
  onViewBillDetailsClick: (paymentIntentId: PaymentIntent['id'], bill: Bill) => void;
};

export const BillDetailsCell = forwardRef<BillDetailsCellProps, 'div'>(
  ({ paymentIntent, bills, vendor, onViewBillDetailsClick, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const {
      settings: {
        batchPayments: { showVendorDetails },
      },
    } = useConfig();

    const shouldShowInvoiceNumberTooltip = !!bills[0] && !!bills[0].invoice.number && bills[0].paymentRequest;

    const showBillDetails = () => {
      if (bills[0]) {
        onViewBillDetailsClick(paymentIntent.id, bills[0]);
      }
    };

    const onClick = bills.length === 1 ? showBillDetails : undefined;
    const showEbill = bills.length === 1 && bills[0] ? isEbill(bills[0]) : false;

    const invoiceNumber =
      bills.length === 1 && bills[0] && bills[0].invoice.number
        ? formatMessage('activities.batchPayments.screens.paymentIntentsTable.billDetailsCell.billNumber.number', {
            invoiceNumber: bills[0].invoice.number,
          })
        : formatMessage('activities.batchPayments.screens.paymentIntentsTable.billDetailsCell.billNumber.placeholder');
    const vendorDetails = () => [vendor.nickname, vendor.accountNumber, invoiceNumber].filter(identity).join(' | ');

    return (
      <Table.Cell
        data-testid="bill-details-cell"
        {...props}
        data-component="BillDetailsCell"
        onClick={onClick}
        ref={ref}
      >
        <Group width="full" variant="vertical" spacing="xxs">
          <Group alignItems="center" spacing="xxs">
            <Text shouldSupportEllipsis textStyle="body3" color="inherit">
              {vendor.name}
            </Text>
            {showEbill && <EbillCellBadge vendor={vendor} isReadOnly />}
          </Group>

          {bills.length === 1 && bills[0] ? (
            <Group spacing="xs" alignItems="center">
              <Text
                shouldSupportEllipsis
                textStyle="body4"
                color={bills[0].invoice.number ? 'global.neutral.900' : 'global.neutral.800'}
              >
                {showVendorDetails ? vendorDetails() : invoiceNumber}
              </Text>
              {shouldShowInvoiceNumberTooltip && (
                <Popover
                  description={formatMessage(
                    'activities.batchPayments.screens.paymentIntentsTable.billDetailsCell.billNumber.tooltip.description'
                  )}
                  actionProps={{
                    onClick: showBillDetails,
                    label: formatMessage(
                      'activities.batchPayments.screens.paymentIntentsTable.billDetailsCell.billNumber.tooltip.actionLabel'
                    ),
                  }}
                  triggerEvent="hover"
                >
                  <StatusIconOutlined variant="informative" size="small" data-testid="invoice-number-tooltip" />
                </Popover>
              )}
            </Group>
          ) : (
            <Text textStyle="body4" color="global.neutral.900">
              {formatMessage('activities.batchPayments.screens.paymentIntentsTable.billDetailsCell.numberOfBills', {
                numberOfBills: bills.length,
              })}
            </Text>
          )}
        </Group>
      </Table.Cell>
    );
  }
);

BillDetailsCell.displayName = 'BillDetailsCell';
