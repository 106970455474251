import { useCollectionApi, UseCollectionApiProps } from '@melio/api-client';
import { ApprovalDecision, PaymentsApiClient } from '@melio/platform-api-axios-client';
export type { ApprovalDecision } from '@melio/platform-api-axios-client';

type ListFn = typeof PaymentsApiClient.getPaymentApprovalDecisions;

export type UsePaymentApprovalDecisionsProps = UseCollectionApiProps<ListFn, ApprovalDecision> & {
  paymentId?: string;
};

export const usePaymentApprovalDecisions = ({ paymentId, ...props }: UsePaymentApprovalDecisionsProps) =>
  useCollectionApi({
    queryKey: ['PaymentsApi', paymentId, 'ApprovalDecisions'],
    queryFn: () => PaymentsApiClient.getPaymentApprovalDecisions(paymentId ?? ''),
    enabled: !!paymentId,
    ...props,
  });
