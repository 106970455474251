import { useMelioQueryClient, useModelApi, UseModelApiProps, useUpdateMutation } from '@melio/api-client';
import { AccountingPlatformsApiClient } from '@melio/platform-api-axios-client';

export type UseAccountingPlatformInitQuickBooksDesktop = UseModelApiProps<
  typeof AccountingPlatformsApiClient.initQuickbooksDesktop
> & {
  accountingPlatformId?: string;
};

export const useAccountingPlatformInitQuickBooksDesktop = (props: UseAccountingPlatformInitQuickBooksDesktop) => {
  const { accountingPlatformId } = props;
  const queryClient = useMelioQueryClient();
  const onSuccess = () => queryClient.invalidateQueries('AccountingPlatformsApi');
  const query = useModelApi<typeof AccountingPlatformsApiClient.initQuickbooksDesktop>({
    ...props,
    queryKey: ['AccountingPlatformsApi', 'initQuickBooksDesktop', accountingPlatformId],
    queryFn: () => AccountingPlatformsApiClient.initQuickbooksDesktop(accountingPlatformId as string),
  });

  const initQuickBooksDesktop = useUpdateMutation(AccountingPlatformsApiClient.initQuickbooksDesktop, query.queryKey, {
    onSuccess,
  });
  const initQuickBooksDesktopData = initQuickBooksDesktop.data;

  return {
    ...query,
    initQuickBooksDesktop: initQuickBooksDesktop.createCallback(props.id as string),
    initQuickBooksDesktopData,
  };
};
