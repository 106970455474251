import { useModelApi, UseModelApiProps } from '@melio/api-client';
// eslint-disable-next-line no-restricted-imports
import {
  AccountantsUserServicesApiClient,
  PostCheckEmailRequest,
  PostCheckEmailResponseData,
} from '@melio/platform-api-axios-client';

const checkEmail = () => AccountantsUserServicesApiClient.checkEmail();

export type UseIsClientExistsProps = UseModelApiProps<typeof checkEmail, PostCheckEmailResponseData> & {
  params?: PostCheckEmailRequest;
};

export const useIsClientExists = ({ params, ...props }: UseIsClientExistsProps = {}) =>
  useModelApi({
    ...props,
    queryKey: ['AccountantsUserServicesApi', params],
    queryFn: () => AccountantsUserServicesApiClient.checkEmail({ email: params?.email || '' }),
  });
