import { Box } from '@chakra-ui/react';
import { useMtlSchemaValidations } from '@melio/ap-domain';
import { FormWidgetProps } from '@melio/ap-widgets';
import { Button, Form, Group, Typography, useFormSubmissionController, useMelioForm } from '@melio/penny';
import { useAnalyticsView, useTrackAnalyticsOnFailedFormSubmit } from '@melio/platform-analytics';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import { defaults } from 'lodash';
import { object, string } from 'yup';

import { EmailInput } from './fields/EmailInput';
import { FirstNameInput } from './fields/FirstNameInput';
import { LastNameInput } from './fields/LastNameInput';

export type AccountingFirmInvitationFormFields = {
  firstName: string;
  lastName: string;
  email: string;
};

type Props = Omit<FormWidgetProps<AccountingFirmInvitationFormFields>, 'onSubmissionStateChange'>;

const useSchema = () => {
  const { formatMessage } = useMelioIntl();
  const { name } = useMtlSchemaValidations();

  return object().shape({
    firstName: name('firstName'),
    lastName: name('lastName'),
    email: string()
      .email(formatMessage('activities.manageCollaborators.screens.userDetailsForm.fields.email.validation.invalid'))
      .required(
        formatMessage('activities.manageCollaborators.screens.userDetailsForm.fields.email.validation.required')
      ),
  });
};

export const AccountingFirmInvitationForm = ({ onSubmit, isSaving, defaultValues }: Props) => {
  const { formatMessage } = useMelioIntl();

  const { onSubmissionStateChange, submitButtonProps } =
    useFormSubmissionController<AccountingFirmInvitationFormFields>();
  const { formProps, registerField, formState } = useMelioForm<AccountingFirmInvitationFormFields>({
    onSubmit,
    schema: useSchema(),
    defaultValues: defaults(defaultValues, {
      firstName: '',
      lastName: '',
      email: '',
    }),
    isSaving,
    onSubmissionStateChange,
  });

  useTrackAnalyticsOnFailedFormSubmit(formState, 'Setting', 'Status');

  useAnalyticsView('Settings', true, true, {
    PageName: 'send-an-invite',
    Flow: 'user-roles',
    Intent: 'invite-accounting-firm',
  });

  return (
    <>
      <Group variant="vertical" spacing="l" width="full">
        <Group variant="vertical" spacing="xxs" width="full">
          <Box mb="s">
            <Typography.Label
              label={formatMessage('activities.manageCollaborators.screens.accountingFirmDetailsForm.title')}
            />
          </Box>
          <Form columns={2} {...formProps} isDisabled={formProps.isDisabled}>
            <FirstNameInput {...registerField('firstName')} aria-label="first name" />
            <LastNameInput {...registerField('lastName')} aria-label="last name" />
            <EmailInput colSpan={2} {...registerField('email')} aria-label="email" />
          </Form>
        </Group>
        <Group variant="vertical" spacing="m" width="full" hasDivider alignItems="flex-end">
          <Group variant="vertical" spacing="s" width="full">
            <Typography.Label
              label={formatMessage(
                'activities.manageCollaborators.screens.accountingFirmDetailsForm.rolesSection.title'
              )}
            />
            <Typography.ParagraphList
              list={Array.from({ length: 5 })
                .map((_, index) => ({
                  content: formatMessage(
                    `activities.collaboratorDrawer.body.rolesSection.melio-accountant.${index + 1}` as MessageKey
                  ),
                }))
                .filter((s) => !!s.content)}
              type="unordered"
            />
          </Group>
          <Button
            variant="primary"
            data-testid="invitation-form-submit-button"
            label={formatMessage('activities.manageCollaborators.screens.invitationModal.submitBtn')}
            {...submitButtonProps}
            isDisabled={!!isSaving}
          />
        </Group>
      </Group>
    </>
  );
};
