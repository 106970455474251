import { Currency, Group, LinearProgress, Typography } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { ActiveOrCompletedLoan } from './types';

type RepaymentSectionProgressProps = {
  loan: ActiveOrCompletedLoan;
};

export const RepaymentSectionProgress = ({ loan }: RepaymentSectionProgressProps) => {
  const { formatMessage } = useMelioIntl();

  const {
    status,
    remainingAmount,
    installments,
    nextInstallmentNumber,
    fees: { totalAmount },
  } = loan;

  const repaidAmount = totalAmount ? totalAmount - remainingAmount : 0;

  const progressBarTotalSteps = installments.length;
  const progressBarCurrentStep = status === 'completed' ? installments.length : nextInstallmentNumber - 1;

  return (
    <>
      <Group width="full" alignItems="stretch" justifyContent="space-between">
        <Typography.SectionLabel
          label={formatMessage('activities.payDashboard.drawer.body.loan.active.repaid.sectionLabel')}
        />
        <Typography.SectionLabel
          label={formatMessage('activities.payDashboard.drawer.body.loan.active.remaining.sectionLabel')}
        />
      </Group>
      <Group width="full" alignItems="stretch" justifyContent="space-between">
        <Currency
          // @ts-expect-error https://meliopayments.slack.com/archives/C04RFB93DTQ/p1699872875165329?thread_ts=1699869894.464809&cid=C04RFB93DTQ
          color="global.neutral.800"
          value={repaidAmount}
        />
        <Currency value={remainingAmount} />
      </Group>
      <Group width="full">
        <LinearProgress totalSteps={progressBarTotalSteps} currentStep={progressBarCurrentStep} isFullWidth />
      </Group>
    </>
  );
};
