import { useParams, useSearchParams } from 'react-router-dom';

import { ErrorRoute } from '@/router/routes/Error.route';

type PropType = { _pathParam?: string; _optionalPathParam?: string; _searchParam?: string };

type RouterComponentWrapperProps<P> = {
  Component: (props: P) => JSX.Element | null;
  componentProps: {
    [K in keyof P]: P[K] | PropType;
  };
};

/**
 * the wrapper populates the required component props from the route's path params or from provided value.
 * @param Component
 * @param componentProps each prop is either explicitly defined (expected prop type is P[K]), or mapped to the corresponding path param
 * @example
 *    pathParams = { sound: 'woof' }
 *    Component = (props: { bark: string, name: string }) => <Dog {...props} />
 *    componentProps = {
 *      bark: { _pathParam: 'sound' },
 *      name: 'felix'
 *    }
 */
export const RouterComponentWrapper = <P extends Object>({
  Component,
  componentProps,
}: RouterComponentWrapperProps<P>) => {
  const pathParams = useParams();
  const [searchParams] = useSearchParams();
  const componentPropsPairs: Array<[string, P[keyof P] | PropType]> = Object.entries(componentProps);
  const resolvedProps = componentPropsPairs.reduce((accm, propObject) => {
    const [propKey, propVal] = propObject;
    if (propVal && typeof propVal === 'object') {
      const propObject = propVal as PropType;
      const requiredParamName = propObject['_pathParam'];
      const optionalParamName = propObject['_optionalPathParam'];
      const searchParamName = propObject['_searchParam'];

      if (requiredParamName) {
        if (!pathParams[requiredParamName]) {
          console.error(`ERROR: missing required path params. Required params: ${requiredParamName}`);
          return <ErrorRoute />;
        }
        return { ...accm, [propKey]: pathParams[requiredParamName] };
      }

      if (optionalParamName) {
        return { ...accm, [propKey]: pathParams[optionalParamName] };
      }

      if (searchParamName) {
        return { ...accm, [propKey]: searchParams.get(searchParamName) };
      }
    }

    return { ...accm, [propKey]: propVal };
  }, {}) as P;

  return <Component {...resolvedProps} />;
};
