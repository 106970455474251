// eslint-disable-next-line no-restricted-imports
import { useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { OrganizationPreference } from '@melio/platform-api';

import { getPreferences, OrganizationPreferences, setPreference } from '@/api/preferences.api';
import { APP_EVENTS, emitAppEvent } from '@/queries/event-emitter-query';
import { getPreferencesQueryKey } from '@/queries/reactQueryKeys';

export type OrganizationPreferenceList = { id: string; value: string }[];
export const usePreferencesQuery = (
  { refetchOnMount }: { refetchOnMount: boolean | 'always' } = { refetchOnMount: true },
) => {
  return useQuery<{}, AxiosError, OrganizationPreferenceList>(
    getPreferencesQueryKey(),
    () => getPreferences().then((prefs) => prefs.map(({ key, value }: OrganizationPreference) => ({ id: key, value }))),
    {
      refetchOnMount,
    },
  );
};

export const usePreferences = () => {
  const data = usePreferencesQuery().data;
  return {
    orgPreferences: data
      ? (Object.fromEntries(data.map((o) => [o.id, o.value])) as OrganizationPreferences)
      : undefined,
    setOrgPreference: useSetOrgPreferencesQuery().setPreference,
  };
};

export const useSetOrgPreferencesQuery = () => {
  const { mutateAsync, ...rest } = useMutation(
    ({ updatedParams }: { updatedParams: OrganizationPreference }) => {
      return setPreference(updatedParams);
    },
    {
      onSuccess: (data) => {
        emitAppEvent(APP_EVENTS.PREFERENCES_UPDATED, { preference: data });
      },
    },
  );

  return {
    setPreference: (updatedParams: OrganizationPreference) => {
      return mutateAsync({
        updatedParams,
      });
    },
    ...rest,
  };
};
