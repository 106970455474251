import { useMelioIntl } from '@melio/platform-provider';

import { DeliveryDateHeaderCellOption } from '../../../../types';

export const useDeliveryDateDescription = () => {
  const { formatMessage } = useMelioIntl();

  const getDescription = (deliveryDateOption: DeliveryDateHeaderCellOption) => {
    switch (deliveryDateOption) {
      case DeliveryDateHeaderCellOption.ByDueDate:
        return formatMessage(
          'activities.batchPayments.screens.paymentIntentsTable.deliveryDateHeaderCell.description.byDueDate'
        );

      case DeliveryDateHeaderCellOption.Multiple:
        return formatMessage(
          'activities.batchPayments.screens.paymentIntentsTable.deliveryDateHeaderCell.description.multiple'
        );

      default:
        return formatMessage(
          'activities.batchPayments.screens.paymentIntentsTable.deliveryDateHeaderCell.description.earliestPossible'
        );
    }
  };

  return { getDescription };
};
