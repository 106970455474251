import { useMelioIntl } from '@melio/platform-i18n';
import { useSetDocumentTitle } from '@melio/platform-sdk';
import { useWizardSimple } from '@melio/platform-utils';
import { Route, Routes, useParams } from 'react-router-dom';

import { EBillsSubscriptionFormActivity, EBillsSubscriptionSuccessActivity } from './components';

type WizardSteps = 'form' | 'success';
const locationsMap = {
  form: 'form',
  success: 'success',
};

export const EBillsSubscriptionFlow = ({ onDone, onClose }: { onDone: () => void; onClose: () => void }) => {
  const { formatMessage } = useMelioIntl();
  const { vendorId } = useParams<string>();
  useSetDocumentTitle(formatMessage('activities.ebillsSubscriptionForm.form.title'));

  const { goNext } = useWizardSimple<WizardSteps>({
    flowName: 'ebills-subscription',
    firstStep: 'form',
    locationsMap,
    navigationSequence: ['form', 'success'],
    cancelUrlFallback: '/',
  });

  if (!vendorId) {
    return null;
  }

  return (
    <Routes>
      <Route
        path={locationsMap.form}
        element={<EBillsSubscriptionFormActivity vendorId={vendorId} onDone={goNext} onClose={onClose} />}
      />
      <Route
        path={locationsMap.success}
        element={<EBillsSubscriptionSuccessActivity vendorId={vendorId} onDone={onDone} />}
      />
    </Routes>
  );
};
