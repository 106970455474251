import { useInvoiceDiscounts, useMelioIntl } from '@melio/ar-domain';
import { useToast } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { DiscountFormDialogScreen, DiscountFormFields } from './screens';

type AddDiscountDialogActivityProps = {
  onDone: (id: string) => unknown;
  onClose: VoidFunction;
  onError?: ARErrorFunction;
};

export const AddDiscountDialogActivity = forwardRef<AddDiscountDialogActivityProps>(
  ({ onDone: onSuccess, onClose, onError, ...props }, ref) => {
    const { create, isCreating } = useInvoiceDiscounts({ enabled: false });

    const { formatMessage } = useMelioIntl();
    const { toast } = useToast();

    const onSubmit = (values: DiscountFormFields) =>
      create(values)
        .then((InvoiceDiscount) => {
          onSuccess(InvoiceDiscount.id);
          toast({
            type: 'success',
            title: formatMessage('ar.settings.activities.addDiscount.toast.onAdd.label', {
              name: InvoiceDiscount.name,
            }),
          });
        })
        .catch(onError);

    return (
      <DiscountFormDialogScreen
        data-testid="add-discount-dialog"
        isOpen
        variant="add"
        isSaving={isCreating}
        onClose={onClose}
        onSubmit={onSubmit}
        {...props}
        ref={ref}
      />
    );
  }
);
AddDiscountDialogActivity.displayName = 'AddDiscountDialogActivity';
