import { StateValue } from '@melio/international-payments-utils';

export const getSelectedState = (values: StateValue[], state: string) =>
  values?.find(({ name }) => {
    const normalizedName = name.replace(/\s+/g, '').trim().toLowerCase();
    const normalizedState = state.replace(/\s+/g, '').trim().toLowerCase();
    return (
      normalizedName.toLowerCase().includes(normalizedState.toLowerCase()) ||
      normalizedState.toLowerCase().includes(normalizedName.toLowerCase())
    );
  });
