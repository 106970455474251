/* eslint-disable max-lines */
import type {
  CardChangeEvent,
  CardExpirationDateElement,
  CardNumberElement,
  CardVerificationCodeElement,
  ChangeEvent,
} from '@basis-theory/basis-theory-react/types';
import {
  AddressSearchWidget,
  AddressSearchWidgetProps,
  FormWidgetProps,
  TBTCardBrandLogosWidget,
  TBTCardNumberFieldWidget,
  TBTExpirationDateFieldWidget,
  TBTVerificationCodeFieldWidget,
} from '@melio/form-controls';
import { Button, Form, Group, NakedButton, useBreakpoint, useMelioForm } from '@melio/penny';
import { Address, US_STATES } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { CardBrand } from '@melio/platform-provider';
import { useBoolean } from '@melio/platform-utils';
import { uniqueId } from 'lodash';
import { useRef, useState } from 'react';

import { useSchema } from './CardFundingSourceForm.hooks';
import { useCardFundingSourceFormAnalytics } from './CardFundingSourceForm.utils';
import { CardFundingSourceFormFields } from './types';

export type CardFundingSourceFormProps = FormWidgetProps<CardFundingSourceFormFields> & {
  onReady?: VoidFunction;
  onCloseForm?: VoidFunction;
  showCloseButton?: boolean;
  isLoading?: boolean;
};

export const CardFundingSourceForm = ({
  onSubmit,
  isSaving,
  isDisabled,
  defaultValues,
  onCloseForm,
  showCloseButton,
  isLoading,
  onSubmissionStateChange,
}: CardFundingSourceFormProps) => {
  const { trackCardHolderDetailsClickOrFocus, trackCardDetailsFocus } = useCardFundingSourceFormAnalytics();
  const { formatMessage } = useMelioIntl();

  const [cardNumberEvent, setCardNumberEvent] = useState<CardChangeEvent>();
  const [cardExpirationEvent, setCardExpirationEvent] = useState<ChangeEvent>();
  const [cardVerificationCodeEvent, setCardVerificationCodeEvent] = useState<ChangeEvent>();
  const [shouldShowErrorOnEmptyCardDetails, showErrorOnEmptyCardDetails] = useBoolean();

  const cardNumberRef = useRef<CardNumberElement>(null);
  const cardExpirationRef = useRef<CardExpirationDateElement>(null);
  const cardVerificationRef = useRef<CardVerificationCodeElement>(null);

  const { isExtraSmallScreen } = useBreakpoint();

  const submit = () => {
    if (cardNumberEvent?.complete && cardExpirationEvent?.complete && cardVerificationCodeEvent?.complete) {
      const result = {
        cardNumber: cardNumberRef.current,
        cardExpiration: cardExpirationRef.current,
        cardVerificationCode: cardVerificationRef.current,
        firstName: getValues('firstName'),
        lastName: getValues('lastName'),
        line1: getValues('line1'),
        state: getValues('state'),
        city: getValues('city'),
        postalCode: getValues('postalCode'),
      } as CardFundingSourceFormFields;
      onSubmit(result);
    } else {
      showErrorOnEmptyCardDetails.on();
    }
  };

  const schema = useSchema();

  const { formProps, getValues, formState, registerField, setValue } = useMelioForm<CardFundingSourceFormFields>({
    onSubmit: submit,
    isSaving,
    defaultValues,
    schema,
    onSubmissionStateChange,
  });
  const showErrors = !!Object.keys(formState?.errors).length || shouldShowErrorOnEmptyCardDetails;

  const { error: cardNumberFormError, ...cardNumberRegisterField } = registerField('cardNumber');
  const { error: cardExpirationFormError, ...cardExpirationRegisterField } = registerField('cardExpiration');
  const { error: cardVerificationCodeFormError, ...cardVerificationCodeRegisterField } =
    registerField('cardVerificationCode');

  const handleAddressChange: AddressSearchWidgetProps['onChange'] = (event) => {
    const address = event.target.value as unknown as Address;
    const setFormField = (field: keyof Omit<Address, 'line2' | 'countryCode'>) => {
      if (address?.[field]) {
        setValue(field, address[field], {
          shouldValidate: true,
        });
      }
    };
    setFormField('line1');
    setFormField('state');
    setFormField('city');
    setFormField('postalCode');
  };

  return (
    <Group variant="vertical" spacing="l" data-component="CardFundingSourceForm" data-testid="card-funding-source-form">
      {showCloseButton && (
        <Group justifyContent="flex-end">
          <NakedButton
            label={formatMessage('activities.subscription.checkout.fundingSourceSelection.button.close')}
            variant="secondary"
            onClick={() => {
              trackCardHolderDetailsClickOrFocus('exit');
              onCloseForm?.();
            }}
            data-testid="close-card-funding-source-form-button"
          />
        </Group>
      )}
      <Form {...formProps} size="small" columns={2} isReadOnly={isLoading}>
        <TBTCardBrandLogosWidget contentAlignment="left" selectedBrand={cardNumberEvent?.cardBrand as CardBrand} />
        <TBTCardNumberFieldWidget
          {...cardNumberRegisterField}
          colSpan={2}
          name="cardNumber"
          labelProps={{
            label: formatMessage('activities.subscription.checkout.fundingSourceSelection.cardNumber.label'),
          }}
          placeholder={formatMessage('activities.subscription.checkout.fundingSourceSelection.cardNumber.placeholder')}
          isRequired
          isDisabled={isDisabled}
          onChange={(event) => {
            setCardNumberEvent(event as CardChangeEvent);
            showErrorOnEmptyCardDetails.off();
          }}
          error={
            cardNumberFormError ||
            (showErrors && cardNumberEvent?.empty
              ? {
                  message: formatMessage(
                    'activities.subscription.checkout.fundingSourceSelection.cardNumber.validation.required'
                  ),
                }
              : undefined)
          }
          id={uniqueId('cardNumber')}
          ref={cardNumberRef}
          onFocus={() => trackCardDetailsFocus('card-number')}
        />
        <TBTExpirationDateFieldWidget
          {...cardExpirationRegisterField}
          name="cardExpiration"
          labelProps={{
            label: formatMessage('activities.subscription.checkout.fundingSourceSelection.cardExpiration.label'),
          }}
          placeholder={formatMessage(
            'activities.subscription.checkout.fundingSourceSelection.cardExpiration.placeholder'
          )}
          isRequired
          isDisabled={isDisabled}
          onChange={(event) => {
            setCardExpirationEvent(event as CardChangeEvent);
            showErrorOnEmptyCardDetails.off();
          }}
          error={
            cardExpirationFormError ||
            (showErrors && cardExpirationEvent?.empty
              ? {
                  message: formatMessage(
                    'activities.subscription.checkout.fundingSourceSelection.cardExpiration.validation.required'
                  ),
                }
              : undefined)
          }
          id={uniqueId('cardExpiration')}
          ref={cardExpirationRef}
          onFocus={() => trackCardDetailsFocus('expiration-date')}
        />
        <TBTVerificationCodeFieldWidget
          {...cardVerificationCodeRegisterField}
          name="cardVerificationCode"
          labelProps={{
            label: formatMessage('activities.subscription.checkout.fundingSourceSelection.cardVerificationCode.label'),
          }}
          placeholder={formatMessage(
            'activities.subscription.checkout.fundingSourceSelection.cardVerificationCode.placeholder'
          )}
          isRequired
          isDisabled={isDisabled}
          onChange={(event) => {
            setCardVerificationCodeEvent(event as CardChangeEvent);
            showErrorOnEmptyCardDetails.off();
          }}
          error={
            cardVerificationCodeFormError ||
            (showErrors && cardVerificationCodeEvent?.empty
              ? {
                  message: formatMessage(
                    'activities.subscription.checkout.fundingSourceSelection.cardVerificationCode.validation.required'
                  ),
                }
              : undefined)
          }
          id={uniqueId('cardVerificationCode')}
          ref={cardVerificationRef}
          onFocus={() => trackCardDetailsFocus('cvc')}
        />
        <Form.TextField
          labelProps={{ label: formatMessage('widgets.cardHolderDetailsForm.firstName.label') }}
          placeholder={formatMessage('widgets.cardHolderDetailsForm.firstName.placeholder')}
          {...registerField('firstName')}
          onFocus={() => trackCardHolderDetailsClickOrFocus('first-name')}
        />
        <Form.TextField
          labelProps={{ label: formatMessage('widgets.cardHolderDetailsForm.lastName.label') }}
          placeholder={formatMessage('widgets.cardHolderDetailsForm.lastName.placeholder')}
          {...registerField('lastName')}
          onFocus={() => trackCardHolderDetailsClickOrFocus('last-name')}
        />
        <AddressSearchWidget
          {...registerField('line1')}
          labelProps={{ label: formatMessage('widgets.cardHolderDetailsForm.address.label') }}
          placeholder={formatMessage('widgets.cardHolderDetailsForm.address.placeholder')}
          onChange={handleAddressChange}
          // The 'OR' is a workaround for formatting the defaultValue we're getting for this field.
          formatSelectedValue={(option) =>
            (option.value as unknown as Address).line1 || (option.value as unknown as string)
          }
          colSpan={2}
          onFocus={() => trackCardHolderDetailsClickOrFocus('address-line')}
        />
        <Form.TextField
          labelProps={{ label: formatMessage('widgets.cardHolderDetailsForm.city.label') }}
          placeholder={formatMessage('widgets.cardHolderDetailsForm.city.placeholder')}
          {...registerField('city')}
          onFocus={() => trackCardHolderDetailsClickOrFocus('city')}
        />
        <Form.SelectNew
          labelProps={{ label: formatMessage('widgets.cardHolderDetailsForm.state.label') }}
          emptyState={{ label: '' }}
          placeholder={formatMessage('widgets.cardHolderDetailsForm.state.placeholder')}
          {...registerField('state')}
          options={US_STATES.map((state) => ({
            value: state,
            label: formatMessage(`local.USA.states.${state}`),
            testId: state,
          }))}
          onFocus={() => trackCardHolderDetailsClickOrFocus('state')}
        />
        <Form.TextField
          labelProps={{ label: formatMessage('widgets.cardHolderDetailsForm.zipcode.label') }}
          placeholder={formatMessage('widgets.cardHolderDetailsForm.zipcode.placeholder')}
          {...registerField('postalCode')}
          colSpan={2}
          onFocus={() => trackCardHolderDetailsClickOrFocus('zip-code')}
        />
        <Form.ContentBox colSpan={isExtraSmallScreen ? 1 : 2}>
          <Group justifyContent="flex-end">
            <Button
              isFullWidth={false}
              label={formatMessage('activities.subscription.checkout.fundingSourceSelection.submit.label')}
              size="medium"
              onClick={(e) => {
                formProps.onSubmit(e);
                trackCardHolderDetailsClickOrFocus('submit');
              }}
              data-testid="submit-card-details-button"
              isLoading={isLoading}
            />
          </Group>
        </Form.ContentBox>
      </Form>
    </Group>
  );
};
