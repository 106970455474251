import { Vendor, VendorAutoPayStatusEnum, VendorEBillStatusEnum } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { usePermissions } from '@melio/platform-permissions';

export const useVendorActions = (vendor?: Vendor) => {
  const [isAutoPayEnabled] = useFeature(FeatureFlags.AutoPay, false);

  const { can } = usePermissions();

  const defaultVendorActions = {
    edit: false,
    delete: false,
    eBillSubscribe: false,
    cancelEBillSubscription: false,
    autoPayActivation: false,
    editAutoPay: false,
    cancelAutoPay: false,
    newBill: false,
    newPayment: false,
  };

  if (!vendor) {
    return defaultVendorActions;
  }

  const canUpdateVendor = can({
    subject: 'vendor',
    action: 'update',
    subjectData: {
      createdById: vendor.createdById,
      managedBy: vendor.managedBy,
    },
  });
  const canActiveVendorAutopay = can({
    subject: 'vendor:autopay',
    action: 'update',
    subjectData: {
      createdById: vendor.createdById,
      managedBy: vendor.managedBy,
    },
  });
  const canDeleteVendor = can({
    subject: 'vendor',
    action: 'delete',
    subjectData: {
      createdById: vendor.createdById,
      managedBy: vendor.managedBy,
    },
  });
  const canCreateBill = can({
    subject: 'bill',
    action: 'create',
    subjectData: {
      vendor: {
        createdById: vendor.createdById,
        managedBy: vendor.managedBy,
      },
    },
  });
  const canCreatePayment = can({
    subject: 'payment',
    action: 'create',
    subjectData: {
      vendor: {
        createdById: vendor.createdById,
        managedBy: vendor.managedBy,
      },
    },
  });

  const canEBillSubscribe =
    canUpdateVendor &&
    ([VendorEBillStatusEnum.Capable, VendorEBillStatusEnum.Rejected] as VendorEBillStatusEnum[]).includes(
      vendor.eBillStatus
    );

  const canCancelEBillSubscription = canUpdateVendor && vendor.eBillStatus === VendorEBillStatusEnum.Active;

  const canAutoPayActivation =
    canActiveVendorAutopay && isAutoPayEnabled && vendor.autoPayStatus === VendorAutoPayStatusEnum.Capable;

  const editAutoPay =
    canActiveVendorAutopay && isAutoPayEnabled && vendor.autoPayStatus === VendorAutoPayStatusEnum.Active;

  const cancelAutoPay =
    canActiveVendorAutopay && isAutoPayEnabled && vendor.autoPayStatus === VendorAutoPayStatusEnum.Active;

  const edit = canUpdateVendor && vendor.eBillStatus !== VendorEBillStatusEnum.Pending;

  return {
    edit,
    delete: canDeleteVendor,
    eBillSubscribe: canEBillSubscribe,
    cancelEBillSubscription: canCancelEBillSubscription,
    autoPayActivation: canAutoPayActivation,
    editAutoPay,
    cancelAutoPay,
    newBill: canCreateBill,
    newPayment: canCreatePayment,
  };
};
