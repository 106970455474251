import { Text } from '@chakra-ui/react';
import { Group, Image, Modal } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { BillProvider } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useState } from 'react';

import { useProviderDisplayProps } from '../hooks/useProviderDisplayProps';

type Props = {
  provider: BillProvider;
  isOpen: boolean;
  onClose: VoidFunction;
};

export const SingleProviderModal = ({ provider, isOpen, onClose }: Props) => {
  const { logoSVG, isLoading, redirectURL, displayName } = useProviderDisplayProps(provider);
  const [isConnecting, setIsConnecting] = useState(false);

  const { track } = useAnalytics();
  const { formatMessage } = useMelioIntl();

  const onConnect = async () => {
    track('Bill', 'Click', {
      PageName: 'import-bills-automatically',
      Flow: 'dashboard',
      Intent: 'bill-import-connect-account',
      Cta: `connect-${provider.name}`,
      EntryPoint: ' dashboard-bills',
    });
    setIsConnecting(true);
    try {
      await provider.connect(redirectURL);
    } catch (error) {
      track('Bill', 'Status', {
        PageName: 'import-bills-automatically',
        Flow: 'dashboard',
        Intent: 'bill-import-connect-account',
        EntryPoint: 'dashboard-bills',
        BillImportAccountType: provider.name,
        Status: 'failure',
        Reason: (error as Error).message,
      });
      setIsConnecting(false);
    }
  };

  const header = formatMessage('activities.eBillsImport.singleProviderModal.title', { provider: displayName });
  const message = formatMessage(`activities.eBillsImport.singleProviderModal.providers.${provider.name}.message`);

  return (
    <Modal
      header={header}
      isOpen={isOpen}
      onClose={onClose}
      primaryButton={{
        onClick: onConnect,
        isDisabled: provider.isConnected,
        isLoading: isLoading || isConnecting,
        variant: 'primary',
        label: 'Connect',
      }}
      secondaryButton={{
        variant: 'tertiary',
        label: 'Cancel',
        isDisabled: isLoading || isConnecting,
        onClick: onClose,
      }}
    >
      <Group spacing="s" variant="vertical">
        <Group spacing="m" variant="vertical">
          <Group variant="horizontal">
            <Image alt={`${displayName} Logo`} src={logoSVG} width="64px" />
          </Group>
          <Text textStyle="heading2Semi">{header}</Text>
        </Group>
        <Text>{message}</Text>
      </Group>
    </Modal>
  );
};
