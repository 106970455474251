import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { Payment } from '@melio/platform-api';

import { getPaymentAnalyticsFields } from '../../../../utils';
import { DeliveryDateActivity, DeliveryDateActivityProps } from '../../DeliveryDate';

type Props = Omit<DeliveryDateActivityProps, 'origin' | 'step' | 'totalSteps'> & {
  payment: Payment;
};

export const ChooseDeliverySpeedStep = withAnalyticsContext<Props>(
  ({ setAnalyticsProperties, payment, onBack: _onBack, onClose: _onClose, onDone, ...props }) => {
    const { createTrackHandler } = useAnalytics();

    setAnalyticsProperties({
      ...getPaymentAnalyticsFields(payment),
      Intent: 'edit-check-delivery-method',
      EntryPoint: 'dashboard-payment',
      Flow: 'void-and-resend-check',
      PageName: 'choose-delivery-speed',
      EligibilityType: 'express-check_regular-check',
      EligibleExpressCheck: true,
    });

    useAnalyticsView('Payment', true, true);

    const trackAndHandleClick = createTrackHandler<{
      Cta: 'exit' | 'back';
      SpecialDeliveryMethod: 'express-check' | null;
      EligibleExpressCheck: true;
    }>('Payment', 'Click');

    const onClose = () => trackAndHandleClick({ Cta: 'exit' }, _onClose);
    const onBack = () => trackAndHandleClick({ Cta: 'back' }, _onBack);

    return (
      <DeliveryDateActivity
        {...props}
        onClose={onClose}
        onBack={onBack}
        onDone={onDone}
        origin="failedToCollect"
        totalSteps={2}
        step={1}
        analyticsProperties={{ Intent: 'edit-check-delivery-method' }}
      />
    );
  }
);
