import { useAnalytics } from '@melio/platform-analytics';

import { InvitationFormFields } from '../../screens/modals/InviteOrEdit/forms/InvitationForm';

export const useCreateInvitationAnalytics = () => {
  const { track } = useAnalytics();

  const status = (status: 'success' | 'failure') => {
    track('Setting', 'Status', {
      PageName: 'invitation-sent',
      Flow: 'user-roles',
      Intent: 'view-users',
      Status: status,
    });
  };

  const submit = (params: { invitedUserRole: InvitationFormFields['roleUniqueName']; requireApproval: boolean }) => {
    track('Setting', 'Click', {
      PageName: 'send-an-invite',
      Flow: 'user-roles',
      Intent: 'invite-user',
      Cta: 'send-invite',
      InvitedUserRole: params.invitedUserRole,
      IsAmountToggleOn: params.requireApproval,
    });
  };

  const close = () => {
    track('Setting', 'Click', {
      PageName: 'send-an-invite',
      Flow: 'user-roles',
      Intent: 'invite-user',
      Cta: 'exit',
    });
  };

  return { submit, close, status };
};
