import { Container, Group, SectionBanner, Text } from '@melio/penny';
import { BankAccountDeliveryMethod } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export function FailedToDeliveryToBankAccountWarning({ bankAccount }: { bankAccount: BankAccountDeliveryMethod }) {
  const { formatMessage } = useMelioIntl();
  return (
    <SectionBanner
      data-testid="failed-to-delivery-to-bank-account-warning"
      variant="warning"
      description={
        <Group variant="vertical" spacing="xxxs">
          {formatMessage(
            'activities.editVendorBankDetails.screens.editVendorBankDetails.failedBankAccountWarning.line1'
          )}
          <Container paddingX="m">
            <ul>
              <li>
                {formatMessage(
                  'activities.editVendorBankDetails.screens.editVendorBankDetails.failedBankAccountWarning.routingNumber',
                  {
                    routingNumber: (
                      <Text
                        textStyle="body3Semi"
                        data-testid="failed-to-delivery-to-bank-account-warning-routing-number"
                      >
                        {bankAccount.details.routingNumber}
                      </Text>
                    ),
                  }
                )}
              </li>
              <li>
                {formatMessage(
                  'activities.editVendorBankDetails.screens.editVendorBankDetails.failedBankAccountWarning.accountNumber',
                  {
                    accountNumber: (
                      <Text
                        textStyle="body3Semi"
                        data-testid="failed-to-delivery-to-bank-account-warning-account-number"
                      >
                        {bankAccount.details.accountNumber}
                      </Text>
                    ),
                  }
                )}
              </li>
            </ul>
          </Container>
        </Group>
      }
    />
  );
}
