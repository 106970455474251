import { Modal, useFormSubmissionController } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useTransferOwnership } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePermissions } from '@melio/platform-permissions';

import { useAsyncActionWithToast } from '../../../hooks/useAsyncActionWithToast';
import { Collaborator } from '../../../types';
import { toFullName } from '../../utils/toFullName';
import { MakeOwnerCollaboratorForm, MakeOwnerCollaboratorFormFields } from './forms/MakeOwnerCollaboratorForm';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  isSaving?: boolean;
  collaborator?: Collaborator | null;
  hasApprovalWorkflow?: boolean;
  actor: Collaborator;
};

export const MakeOwnerCollaboratorModalScreen = ({
  isOpen,
  onClose,
  isSaving,
  actor,
  collaborator,
  ...props
}: Props) => {
  const { track } = useAnalytics();
  const { transferOwnership, isUpdating } = useTransferOwnership({
    id: collaborator?.id,
  });
  const { onSubmissionStateChange, submitButtonProps } = useFormSubmissionController<MakeOwnerCollaboratorFormFields>();
  const { formatMessage } = useMelioIntl();
  const { can, roles } = usePermissions();

  const availableRoleOptions = roles.filter((roleUniqueName) =>
    can({
      subject: 'collaborator:ownership',
      action: 'update',
      subjectData: { roleUniqueName },
    })
  );

  const actionWithToast = useAsyncActionWithToast({
    successLabel: 'activities.manageCollaborators.messages.collaborator.update.success',
    errorLabel: 'activities.manageCollaborators.messages.collaborator.update.error',
    onSuccess: () => onClose(),
  });

  const onDone = async (data: MakeOwnerCollaboratorFormFields) => {
    await actionWithToast(() =>
      transferOwnership({
        data: { roleUniqueName: data.roleUniqueName },
      })
    );
    track('Setting', 'Click', {
      pageName: 'make-as-owner',
      intent: 'manage-collaborators',
      managedUserRole: `new role ${data.roleUniqueName} chose`,
      Cta: 'yes-make-owner',
    });
  };

  const onModalClose = (cta: string) => {
    track('Setting', 'Click', {
      Cta: cta,
      pageName: 'make-as-owner',
      intent: 'manage-collaborators',
    });
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onModalClose('exit')}
      data-testid="manage-collaborators-activity-make-owner-collaborator-modal-screen"
      header={formatMessage('activities.manageCollaborators.screens.makeOwnerCollaboratorModal.title', {
        conFullName: toFullName(collaborator?.firstName, collaborator?.lastName),
        ownerFullName: toFullName(actor.firstName, actor.lastName),
      })}
      secondaryButton={{
        variant: 'secondary',
        label: formatMessage('activities.manageCollaborators.screens.makeOwnerCollaboratorModal.cancel'),
        onClick: () => onModalClose('cancel'),
        isDisabled: isUpdating,
      }}
      primaryButton={{
        variant: 'primary',
        label: formatMessage('activities.manageCollaborators.screens.makeOwnerCollaboratorModal.submitBtn'),
        ...submitButtonProps,
        isLoading: isUpdating,
      }}
      {...props}
    >
      <MakeOwnerCollaboratorForm
        onSubmit={onDone}
        isSaving={isUpdating}
        onSubmissionStateChange={onSubmissionStateChange}
        availableRoleOptions={availableRoleOptions}
        collaboratorFullName={toFullName(collaborator?.firstName, collaborator?.lastName)}
        ownerFullName={toFullName(actor.firstName, actor.lastName)}
      />
    </Modal>
  );
};
