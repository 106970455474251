/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from 'react';
import { Group, Modal, Text } from '@melio/penny';
import { useFormSubmissionController } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useFundingSource } from '@melio/platform-api';
import { FundingSource } from '@melio/platform-api';
import { useSystemMessage } from '@melio/platform-utils';

import { usePlatformIntl } from '@/translations/Intl';
import {
  NicknameForm,
  NicknameFormDetails,
} from '@/widgets/settings-page/PaymentMethodsSettingsPage/NicknameForm.widget';

type PaymentSourceNicknameModalProps = {
  fundingSource: FundingSource;
  onClose: () => void;
  isOpen: boolean;
};

export const PaymentSourceNicknameModal = ({ isOpen, onClose, fundingSource }: PaymentSourceNicknameModalProps) => {
  const { nickname = '' } = fundingSource;
  const { track } = useAnalytics();
  const { formatMessage } = usePlatformIntl();
  const { submitButtonProps, onSubmissionStateChange } = useFormSubmissionController<NicknameFormDetails>();
  const { showMessage } = useSystemMessage();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const savedToast = formatMessage('widgets.paymentMethods.editLabelDialog.savedToast');
  const failedSavedToast = formatMessage('widgets.paymentMethods.editLabelDialog.failedSavedToast');
  const removedToast = formatMessage('widgets.paymentMethods.editLabelDialog.removedToast');
  const failedRemovedToast = formatMessage('widgets.paymentMethods.editLabelDialog.failedRemovedToast');

  const { update: updatePaymentMethod } = useFundingSource({ id: fundingSource.id, enabled: false });

  const updateNickname = useCallback(
    async ({ nickname: formNickname }: NicknameFormDetails) => {
      try {
        track('LabelFundingSourceModal', 'Submitted');
        setIsSaving(true);
        if (formNickname !== nickname) {
          await updatePaymentMethod({ nickname: formNickname });
        }
        track('LabelFundingSourceModal', 'Succeeded');
        if (formNickname) {
          showMessage({
            type: 'success',
            title: savedToast,
          });
        } else {
          showMessage({
            type: 'informative',
            title: removedToast,
          });
        }
      } catch (e) {
        showMessage({
          type: 'error',
          title: formNickname ? failedSavedToast : failedRemovedToast,
        });
        formNickname;
      } finally {
        setIsSaving(false);
        onClose();
      }
    },
    [fundingSource.id, nickname],
  );

  const title = formatMessage('widgets.paymentMethods.editLabelDialog.title');
  const content = formatMessage('widgets.paymentMethods.editLabelDialog.content');

  const primary = nickname
    ? formatMessage('widgets.paymentMethods.editLabelDialog.edit.primary')
    : formatMessage('widgets.paymentMethods.editLabelDialog.add.primary');
  const secondary = nickname ? formatMessage('widgets.paymentMethods.editLabelDialog.edit.secondary') : null;

  return (
    <Modal
      header={title}
      isOpen={isOpen}
      onClose={onClose}
      primaryButton={{ isLoading: isSaving, label: primary, variant: 'primary', ...submitButtonProps }}
      secondaryButton={
        secondary
          ? {
              onClick: () => updateNickname({ nickname: '' }),
              variant: 'tertiary',
              isDisabled: isSaving,
              label: secondary,
            }
          : undefined
      }
    >
      <Group variant="vertical">
        <Text>{content}</Text>
        <NicknameForm
          onSubmit={updateNickname}
          onSubmissionStateChange={onSubmissionStateChange}
          defaultValues={{
            nickname,
          }}
        />
      </Group>
    </Modal>
  );
};
