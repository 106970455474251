import { ActionsDropdownMenu, Button, Group, useBreakpoint } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

import { AddBillV2SubmitType, CustomFooterProps } from '../../add-bill';

type CustomFooterImplProps = {
  onDeleteButtonClicked: VoidFunction;
  markAsPaidFeatureFlag: boolean;
  isLoading: boolean;
} & CustomFooterProps;

export const CustomFooter = ({
  onSubmitForm,
  onDeleteButtonClicked,
  markAsPaidFeatureFlag,
  isLoading,
  onClose,
}: CustomFooterImplProps) => {
  const { formatMessage } = useMelioIntl();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const { isExtraSmallScreen: isMobile } = useBreakpoint();
  const groupItemFlexProps = isMobile ? { grow: 1, shrink: 1, basis: 1 } : { grow: 1 };

  return (
    <Group width="full" justifyContent="space-between">
      {!isMobile && (
        <Button
          data-testid="add-bill-v2-cancel-button"
          variant="tertiary"
          label={formatMessage('activities.addBillV2.footer.buttons.cancel')}
          onClick={onClose}
        />
      )}

      <Group width={isMobile ? 'full' : undefined}>
        <Group.Item {...groupItemFlexProps}>
          <ActionsDropdownMenu
            isOpen={isMenuOpen}
            onOpenChange={setIsMenuOpen}
            data-testid="review-scanned-invoice-actions-dropdown-menu"
            items={[
              {
                label: formatMessage('widgets.ReviewScannedInvoice.buttons.moreActions.actions.saveAndClose.label'),
                onClick: onSubmitForm(AddBillV2SubmitType.SAVE_AND_CLOSE),
                dataTestId: 'save-and-close-scanned-invoice',
              },
              ...(markAsPaidFeatureFlag
                ? [
                    {
                      label: formatMessage('widgets.ReviewScannedInvoice.buttons.moreActions.actions.markAsPaid.label'),
                      onClick: onSubmitForm(AddBillV2SubmitType.MARK_AS_PAID),
                      dataTestId: 'mark-scanned-invoice-as-paid',
                    },
                  ]
                : []),
              {
                label: formatMessage('widgets.ReviewScannedInvoice.buttons.moreActions.actions.delete.label'),
                onClick: onDeleteButtonClicked,
                variant: 'critical',
                dataTestId: 'delete-scanned-invoice',
              },
            ]}
            label={formatMessage('widgets.ReviewScannedInvoice.buttons.moreActions.label')}
          />
        </Group.Item>
        <Group.Item {...groupItemFlexProps}>
          <Button
            data-testid="submit"
            variant="primary"
            size="medium"
            isFullWidth
            onClick={onSubmitForm(AddBillV2SubmitType.CONTINUE_TO_PAY)}
            label={formatMessage('widgets.ReviewScannedInvoice.buttons.next.label')}
            isLoading={isLoading}
          />
        </Group.Item>
      </Group>
    </Group>
  );
};
