import { ReviewScannedInvoiceActivity } from '@melio/ap-activities';
import { useLocation, useNavigate } from '@melio/platform-utils';

import { ReviewScannedInvoiceStateType, useRouter } from '@/hooks/router.hooks';
import { useIsNewDashboardEnabled } from '@/hooks/useIsNewDashboardEnabled';
import { PayDashboardTabs } from '@/types/payDashboard.types';
import { ScannedInvoiceFilePreview } from '@/widgets/pay-dashboard/review-scanned-invoice/components/ScannedInvoiceFilePreview';
import { MarkAsPaidDialog } from '../bill-details/components/MarkAsPaidDialog';

export const NewReviewScannedInvoiceScreen = ({ scannedInvoiceId }: { scannedInvoiceId: string }) => {
  const { navigateToSchedulePayment, generateNPEDashboardLink, replaceToPayDashboardTab } = useRouter();
  const navigate = useNavigate();
  const { state } = useLocation<ReviewScannedInvoiceStateType>();
  const shouldRedirectToNewDashboard = useIsNewDashboardEnabled();

  const onSchedulePayment = (billId: string) => {
    navigateToSchedulePayment({
      billId,
      returnUrl: shouldRedirectToNewDashboard ? generateNPEDashboardLink('payments') : undefined,
      keepToast: true,
    });
  };
  const handleGoToBillsTab = () => {
    if (shouldRedirectToNewDashboard) {
      return navigate(generateNPEDashboardLink('bills'));
    }
    replaceToPayDashboardTab(PayDashboardTabs.Inbox, undefined, { keepToast: true });
  };

  const onClose = () => {
    if (state?.returnUrl) {
      return navigate(state?.returnUrl);
    }

    handleGoToBillsTab();
  };

  return (
    <ReviewScannedInvoiceActivity
      scannedInvoiceId={scannedInvoiceId}
      goToSchedulePayment={onSchedulePayment}
      markAsPaidDialog={MarkAsPaidDialog}
      invoicePreview={ScannedInvoiceFilePreview}
      goToBillsTab={handleGoToBillsTab}
      onClose={onClose}
    />
  );
};
