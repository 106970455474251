import { Container, Form, Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { useFormContext } from '../../../FormContext';
import { FormFieldsSettings } from '../../../types';

type Props = { formFieldsSettings: Pick<FormFieldsSettings, 'firstName' | 'lastName' | 'email'> };
export const DetailsSection = ({ formFieldsSettings }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { registerField } = useFormContext();

  const firstNameField = registerField('firstName');
  const lastNameField = registerField('lastName');
  const emailField = registerField('email');

  const { readonly: isFirstNameReadonly, required: isFirstNameRequired } = formFieldsSettings.firstName;
  const { readonly: isLastNameReadonly, required: isLastNameRequired } = formFieldsSettings.lastName;
  const { readonly: isEmailReadonly, required: isEmailRequired } = formFieldsSettings.email;
  return (
    <Container overflow="initial">
      <Group variant="vertical" spacing="m">
        <Group.Item>
          <Text as="h3" textStyle="heading3Semi">
            {formatMessage('activities.collaboratorDrawer.body.detailsSection.heading')}
          </Text>
        </Group.Item>

        <Group.Item>
          <Group variant="vertical" spacing="m">
            <Group.Item>
              <Form.TextField
                {...firstNameField}
                labelProps={{
                  label: formatMessage('activities.collaboratorDrawer.body.detailsSection.fields.firstName'),
                }}
                data-testid="collaborator-drawer-body-details-section-first-name-input"
                isRequired={isFirstNameRequired}
                isViewMode={isFirstNameReadonly}
              />
            </Group.Item>

            <Group.Item>
              <Form.TextField
                {...lastNameField}
                labelProps={{
                  label: formatMessage('activities.collaboratorDrawer.body.detailsSection.fields.lastName'),
                }}
                data-testid="collaborator-drawer-body-details-section-last-name-input"
                isRequired={isLastNameRequired}
                isViewMode={isLastNameReadonly}
              />
            </Group.Item>

            <Group.Item>
              <Form.TextField
                type="email"
                {...emailField}
                isTruncated
                labelProps={{
                  label: formatMessage('activities.collaboratorDrawer.body.detailsSection.fields.email'),
                }}
                data-testid="collaborator-drawer-body-details-section-email-input"
                isRequired={isEmailRequired}
                isViewMode={isEmailReadonly}
              />
            </Group.Item>
          </Group>
        </Group.Item>
      </Group>
    </Container>
  );
};
