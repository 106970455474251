import { Box } from '@chakra-ui/react';
import { useVendorActions } from '@melio/ap-domain';
import { ActionsDropdownMenuItemProps, Table } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { Bill, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { compact } from 'lodash';

import { useActivitiesNavigate } from '../../../../../utils';

export type VendorsTabMoreActionsPropsType = {
  vendor: Vendor;
  unpaidBills: Bill[];
  isDisabled?: boolean;
  onDeleteClick: (vendor: Vendor) => void;
  onAddNewBillClick: (vendorId: Vendor['id']) => void;
  onEditVendorClick: (vendorId: Vendor['id']) => void;
  onNewPaymentClick: (vendorId: string) => void;
  onViewVendorClick: (vendorId: Vendor['id']) => void;
  ariaLabel?: string;
};

export const VendorsTabMoreActionsCell = ({
  vendor: vendorsTabItem,
  unpaidBills,
  isDisabled,
  onDeleteClick,
  onAddNewBillClick,
  onEditVendorClick,
  onNewPaymentClick,
  onViewVendorClick,
  ariaLabel,
}: VendorsTabMoreActionsPropsType) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const vendorActions = useVendorActions(vendorsTabItem);
  const { navigateToEbillActivationForm, navigateToAutopayForm } = useActivitiesNavigate();

  const handleDeleteClick = () => {
    track('Dashboard', 'Click', {
      Cta: 'delete-vendor',
      Intent: 'delete-vendor',
      VendorId: vendorsTabItem.id,
    });
    onDeleteClick(vendorsTabItem);
  };

  const handleAddNewBillClick = () => {
    track('Dashboard', 'Click', { Cta: 'add-bill', Intent: 'add-a-bill', VendorId: vendorsTabItem.id });
    onAddNewBillClick(vendorsTabItem.id);
  };

  const handleEditVendorClick = () => {
    track('Dashboard', 'Click', { Cta: 'edit-vendor', Intent: 'edit-vendor', VendorId: vendorsTabItem.id });
    onEditVendorClick(vendorsTabItem.id);
  };

  const handleViewVendorClick = () => {
    track('Dashboard', 'Click', { Cta: 'view-vendor', Intent: 'view-vendor', VendorId: vendorsTabItem.id });
    onViewVendorClick(vendorsTabItem.id);
  };

  const handleNewPaymentClick = () => {
    track('Dashboard', 'Click', {
      Intent: 'pay-vendor',
      Cta: 'new-payment-tab',
      VendorId: vendorsTabItem.id,
    });
    onNewPaymentClick(vendorsTabItem.id);
  };

  const handleEBillSubscribeClick = () => {
    track('Dashboard', 'Click', {
      Cta: 'subscribe-to-ebill',
      VendorId: vendorsTabItem.id,
    });
    navigateToEbillActivationForm({ vendorId: vendorsTabItem.id });
  };

  const handleAutoPayActivationClick = () => {
    track('Dashboard', 'Click', {
      Cta: 'set-up-autopay',
      VendorId: vendorsTabItem.id,
    });
    navigateToAutopayForm({ vendorId: vendorsTabItem.id });
  };

  const options: ActionsDropdownMenuItemProps[] = compact([
    vendorActions.newBill && unpaidBills.length > 0
      ? {
          label: formatMessage('activities.payDashboard.vendorsTab.table.cells.actions.actionsMenu.newPayment'),
          onClick: handleNewPaymentClick,
          dataTestId: `vendors-tab-row-${vendorsTabItem.id}-more-actions-dropdown-menu-new-payment`,
        }
      : null,
    {
      label: formatMessage('activities.payDashboard.vendorsTab.table.cells.actions.actionsMenu.view'),
      onClick: () => handleViewVendorClick(),
      dataTestId: `vendors-tab-row-${vendorsTabItem.id}-more-actions-dropdown-menu-view`,
    },
    vendorActions.newBill
      ? {
          label: formatMessage('activities.payDashboard.vendorsTab.table.cells.actions.actionsMenu.addBill'),
          onClick: () => handleAddNewBillClick(),
          dataTestId: `vendors-tab-row-${vendorsTabItem.id}-more-actions-dropdown-menu-add-bill`,
        }
      : null,
    vendorActions.eBillSubscribe
      ? {
          label: formatMessage('activities.payDashboard.vendorsTab.table.cells.actions.actionsMenu.eBillSubscribe'),
          onClick: () => handleEBillSubscribeClick(),
          dataTestId: `vendors-tab-row-${vendorsTabItem.id}-more-actions-dropdown-menu-ebill-subscribe`,
        }
      : null,
    vendorActions.autoPayActivation
      ? {
          label: formatMessage('activities.payDashboard.vendorsTab.table.cells.actions.actionsMenu.autoPayActivation'),
          onClick: () => handleAutoPayActivationClick(),
          dataTestId: `vendors-tab-row-${vendorsTabItem.id}-more-actions-dropdown-menu-auto-pay-activation`,
        }
      : null,
    vendorActions.edit
      ? {
          label: formatMessage('activities.payDashboard.vendorsTab.table.cells.actions.actionsMenu.edit'),
          onClick: () => handleEditVendorClick(),
          dataTestId: `vendors-tab-row-${vendorsTabItem.id}-more-actions-dropdown-menu-edit`,
        }
      : null,
    vendorActions.delete
      ? {
          label: formatMessage('activities.payDashboard.vendorsTab.table.cells.actions.actionsMenu.delete'),
          onClick: () => handleDeleteClick(),
          dataTestId: `vendors-tab-row-${vendorsTabItem.id}-more-actions-dropdown-menu-delete`,
          variant: 'critical',
        }
      : null,
  ]);

  const handleCellClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const hasMoreThanOneOption = options.length > 1;

  return (
    <Box onClick={handleCellClick} height="full">
      <Table.ActionsCell
        data-testid={`vendors-tab-row-${vendorsTabItem.id}-more-actions-dropdown-menu`}
        options={options}
        isDisabled={!hasMoreThanOneOption || isDisabled}
        aria-label={ariaLabel}
      />
    </Box>
  );
};
