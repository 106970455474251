import { useMelioIntl } from '@melio/ar-domain';
import { Group, Icon, NakedButton, SectionBanner, Text } from '@melio/penny';

type SignInBannerProps = {
  onSignInClick: VoidFunction;
};

export const SignInBanner: React.FC<SignInBannerProps> = ({ onSignInClick }) => {
  const { formatMessage } = useMelioIntl();

  return (
    <SectionBanner
      // TODO: This is a temporary workaround until Penny supports changing the icon with brand variant.
      // Fix when https://meliorisk.atlassian.net/browse/ME-65890 is done.
      data-testid="sign-in-banner"
      hideIcon
      variant="brand"
      description={
        <Group>
          <Icon type="user-check" size="large" />
          <Group variant="vertical">
            <Text textStyle="body3">{formatMessage('ar.guestPayment.activities.cardHolder.signInBanner.label')}</Text>
            <NakedButton
              data-testid="sign-in-banner-link"
              label={formatMessage('ar.guestPayment.activities.cardHolder.signInBanner.link')}
              variant="secondary"
              onClick={onSignInClick}
            />
          </Group>
        </Group>
      }
    />
  );
};

SignInBanner.displayName = 'SignInBanner';
