import { PillProps } from '@melio/penny';
import { FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useFormatExpirationDate } from '@melio/platform-utils';

export const useFundingSourceCardProps = ({
  fundingSource,
}: {
  fundingSource: FundingSource;
}): { title: string; description?: string; badgeProps?: Array<PillProps & { 'data-testid': string }> } => {
  const { formatMessage } = useMelioIntl();
  const { formatExpirationDate } = useFormatExpirationDate();

  switch (fundingSource.type) {
    case 'bank-account':
      return {
        title: fundingSource.displayName,
        description: fundingSource.nickname,
      };
    case 'card':
      return {
        title: fundingSource.displayName,
        description: formatMessage('activities.paymentFlow.form.content.fundingSourceCard.card.description', {
          nickname: fundingSource.nickname,
          expirationDate: formatExpirationDate({
            month: fundingSource.details.expirationMonth,
            year: fundingSource.details.expirationYear,
          }),
        }),
      };
    default:
      return {
        title: 'not implemented',
        description: 'not implemented',
      };
  }
};
