import { RefundActivity } from '@melio/ap-activities';
import { useInboxItems } from '@melio/platform-api';
import { useNavigate } from '@melio/platform-utils';

import { useRouter } from '@/hooks/router.hooks';

export const RefundPaymentScreen = ({ paymentId }: { paymentId: string }) => {
  const navigate = useNavigate();
  const { goToRetryFailedToDeliverPayment, generateNPEDashboardLink } = useRouter();
  const { refetch: refetchInboxItems } = useInboxItems({ enabled: false });

  const onRetryDeliverLinkClicked = () => {
    goToRetryFailedToDeliverPayment(paymentId);
  };

  const goToDashboard = () => {
    refetchInboxItems();
    navigate(generateNPEDashboardLink(`payments?status=all`));
  };

  return (
    <RefundActivity
      paymentId={paymentId}
      goToDashboard={goToDashboard}
      onRetryDeliverLinkClicked={onRetryDeliverLinkClicked}
    />
  );
};
