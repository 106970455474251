/* eslint-disable no-unsafe-optional-chaining */

import { FundingSource } from '@melio/platform-api';
import { useBoolean } from '@melio/platform-utils';
import { useCallback } from 'react';

import { useReconciliation } from '../Reconciliation';

const useReconciliationModal = (): {
  isReconciliationEnabled: (fundingSource?: FundingSource | undefined) => boolean;
  shouldRunReconciliation: (fundingSource?: FundingSource | undefined) => boolean;
  shouldShowReconciliationModal: boolean;
  runReconciliationFlow: VoidFunction;
  onCloseReconciliationFlow: (onClose?: VoidFunction) => VoidFunction;
  onDoneReconciliationFlow: VoidFunction;
} => {
  const [shouldShowReconciliationModal, showReconciliationModal] = useBoolean(false);

  const { isReconciliationNeeded, isReconciliationEnabled } = useReconciliation();

  const runReconciliationFlow = () => showReconciliationModal.on();

  const onDoneReconciliationFlow = useCallback(() => {
    showReconciliationModal.off();
  }, [showReconciliationModal]);

  const onCloseReconciliationFlow = (onClose?: VoidFunction) => () => {
    if (onClose) {
      onClose();
    }
    showReconciliationModal.off();
  };

  return {
    isReconciliationEnabled,
    shouldRunReconciliation: isReconciliationNeeded,
    shouldShowReconciliationModal,
    runReconciliationFlow,
    onCloseReconciliationFlow,
    onDoneReconciliationFlow,
  };
};

export { useReconciliationModal };
