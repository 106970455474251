import { Box } from '@chakra-ui/react';
import { Container, Group, Icon, Pill, Skeleton, Text, Tooltip } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { Plan, SubscriptionBillingCycleEnum, SubscriptionPreview } from '../../../../api';
import { usePlanInfo, usePredefinedDiscountedPlanPricing, useSubscriptionPreviewItems } from '../../../../hooks';
import { getPaidSeatsPrice } from '../../../utils';

type SubscriptionCheckoutSummaryPlanProps = {
  selectedPlan: Plan;
  preview?: SubscriptionPreview;
  currentCycle: SubscriptionBillingCycleEnum;
  isLoading: boolean;
  predefinedDiscountPercentage?: number;
};

export const SubscriptionCheckoutSummaryPlan = ({
  selectedPlan,
  currentCycle,
  isLoading,
  preview,
  predefinedDiscountPercentage,
}: SubscriptionCheckoutSummaryPlanProps) => {
  const { freeTrialData, paidSeatsCount } = useSubscriptionPreviewItems({
    subscriptionPreview: preview,
    plan: selectedPlan,
  });
  const predefinedDiscountedPlanPricing = usePredefinedDiscountedPlanPricing({
    planCycle: selectedPlan.cycles?.[currentCycle],
    discountPercentage: predefinedDiscountPercentage,
  });
  const { formatMessage, formatCurrency } = useMelioIntl();
  const { planName } = usePlanInfo(selectedPlan.id);
  const { basePrice: currentBasePrice = 0, unitPrice: currentUnitPrice = 0 } =
    selectedPlan.cycles?.[currentCycle] || {};

  const additionalSeatsPrice = getPaidSeatsPrice({ quantity: paidSeatsCount, unitPrice: currentUnitPrice });

  return (
    <Container>
      <Group variant="vertical">
        <Group variant="horizontal" justifyContent="space-between" alignItems="center">
          <Text textStyle="body2Semi" data-testid="subscription-checkout-plan-title">
            {formatMessage('activities.subscription.plans.summary.description', {
              planName,
              cycle: formatMessage(`activities.subscription.checkout.billingCycle.${currentCycle}.label`),
            })}
          </Text>

          {freeTrialData && (
            <Pill
              data-testid="free-trial-badge"
              status="informative"
              type="secondary"
              label={formatMessage('activities.subscription.plans.summary.freeTrialBadge')}
            />
          )}
        </Group>

        <Group variant="vertical" spacing="xs">
          <Group justifyContent="space-between">
            <Text textStyle="body3" color="global.neutral.800">
              {formatMessage('activities.subscription.plans.summary.basePrice')}
            </Text>

            <Group variant="horizontal" alignItems="center" spacing="xs">
              {predefinedDiscountedPlanPricing?.priceBeforeDiscount && (
                <Text textStyle="body3" color="global.neutral.600">
                  <Box textDecoration="line-through" as="span">
                    {isLoading ? null : formatCurrency(predefinedDiscountedPlanPricing.priceBeforeDiscount)}
                  </Box>
                </Text>
              )}

              <Text textStyle="body3">
                {isLoading ? (
                  <Box width="100px">
                    <Skeleton height="12px" />
                  </Box>
                ) : (
                  formatCurrency(currentBasePrice)
                )}
              </Text>
            </Group>
          </Group>

          {paidSeatsCount > 0 && (
            <Group justifyContent="space-between" alignItems="flex-start">
              <Group variant="horizontal" spacing="xs" alignItems="center">
                <Text textStyle="body3" color="global.neutral.800">
                  {formatMessage('activities.subscription.plans.summary.additionalSeats', {
                    seats: paidSeatsCount,
                  })}
                </Text>
                <Tooltip label={formatMessage('activities.settings.editablePlanCardFiserv.tooltip.seats')}>
                  <Icon type="info" color="inherit" size="small" />
                </Tooltip>
              </Group>

              <Text textStyle="body3" data-testid="subscription-checkout-additional-seats">
                {isLoading ? (
                  <Box width="100px">
                    <Skeleton height="12px" />
                  </Box>
                ) : (
                  formatCurrency(additionalSeatsPrice)
                )}
              </Text>
            </Group>
          )}
        </Group>
      </Group>
    </Container>
  );
};
