import { Container, Group, Icon, Text } from '@melio/penny';

export const LostFeatureDetails = ({ label }: { label: string }) => (
  <Container color="neutral.700" alignItems="flex-start">
    <Group spacing="xs" variant="horizontal" alignItems="center">
      <Icon type="cancel-circle" size="small" color="inherit" aria-hidden="true" />
      <Text textStyle="body3">{label}</Text>
    </Group>
  </Container>
);
