import { Payment, PaymentStatusEnum } from '@melio/platform-api';

export const getPaymentDetailsTitlePostfix = (payment: Payment): string => {
  const isRefundOrVoidCheck = payment?.metadata?.isRefund || payment?.metadata?.isVoidCheck;
  const isFailedPayment = payment?.status === PaymentStatusEnum.Failed;
  if (isRefundOrVoidCheck) {
    return 'refundPayment';
  }

  if (isFailedPayment) {
    return 'failedPayment';
  }

  return 'fullPayment';
};
