/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { useSetAppActiveFLow, useSetAppActiveScreen } from '@/store/app/app.model';
import { ActiveFlowEnum, ScreensEnum } from '@/store/app/app.types';

export const useActiveScreen = (activeScreen: ScreensEnum, activeFlow?: ActiveFlowEnum) => {
  const setActiveScreen = useSetAppActiveScreen();
  const setActiveFlow = useSetAppActiveFLow();
  React.useEffect(() => {
    setActiveScreen(activeScreen);
    if (activeFlow) {
      setActiveFlow(activeFlow);
    }

    return () => {
      setActiveScreen(undefined);
    };
  }, [activeScreen, activeFlow]);
};
