import { useRecoilValue } from 'recoil';
import { LocalStorageKeys } from '@melio/local-storage';

import { CustomerIcon, GetPaidIcon } from '@/cl/icons/system';
import { LeftLayoutIconLink } from '@/cl/layouts/app-layout/leftLayout.component';
import { usePartnerLocalStorage } from '@/hooks/partners/usePartnerLocalStorage';
import { ScreensEnum } from '@/store/app/app.types';
import { appSelectedActiveScreenSelector } from '../store/app/app.model';
import { usePlatformIntl } from '../translations/Intl';
import { usePartnerConfig } from './partners/usePartnerConfig';
import { useRouter } from './router.hooks';

export const useGetPaidTabLinks = () => {
  const { generateGetPaidDashboardLink, generateCustomersLink } = useRouter();
  const { formatMessage } = usePlatformIntl();
  const activeScreen = useRecoilValue(appSelectedActiveScreenSelector);
  const localStorage = usePartnerLocalStorage();
  const accessToken = localStorage.getItem(LocalStorageKeys.accessToken);

  const { partnerConfig } = usePartnerConfig();

  if (!accessToken || !partnerConfig.features?.getPaid) {
    return {
      links: [],
    };
  }

  let links: LeftLayoutIconLink[] = [
    {
      id: 'GetPaid',
      Icon: GetPaidIcon,
      href: generateGetPaidDashboardLink(accessToken)!,
      linkText: formatMessage('app.navigation.getPaid'),
      isActive: activeScreen === ScreensEnum.getPaidDashboard,
      isExternal: true,
    },
    {
      id: 'Customers',
      Icon: CustomerIcon,
      href: generateCustomersLink(accessToken)!,
      linkText: formatMessage('app.navigation.customers'),
      isActive: activeScreen === ScreensEnum.customers,
      isExternal: true,
    },
  ];

  return {
    links,
  };
};
