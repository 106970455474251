import { useOnboarding } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';

import { useOnboardingStepsTracker } from '../../components';
import { FinancialInformationScreen } from './screens';
import { FinancialInformationFormFields } from './types';

type FinancialInformationActivityProps = {
  onDone: VoidFunction;
  onError?: ARErrorFunction;
  onBack: VoidFunction;
  onClose?: VoidFunction;
};

export const FinancialInformationActivity = forwardRef(
  ({ onDone, onBack, ...props }: FinancialInformationActivityProps, ref) => {
    const { isLoading: isOnboardingDataLoading, isMutating: isOnboardingDataMutating, update } = useOnboarding();
    const { steps, currentStepIndex, goToStepById } = useOnboardingStepsTracker();

    useEffect(() => {
      goToStepById('financial-information');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStepIndex]);

    const handleSubmit = async (data: FinancialInformationFormFields) => {
      const financialInformationRequest = {
        monthlySalesVolume: data.monthlySalesVolume,
        cardPaymentShare: data.cardPaymentShare,
        averageTransactionSize: data.averageTransactionSize,
      };
      await update({ businessDetails: financialInformationRequest });
      onDone();
    };

    return (
      <FinancialInformationScreen
        isLoading={isOnboardingDataLoading}
        steps={steps}
        currentStepIndex={currentStepIndex}
        onSubmit={handleSubmit}
        isSaving={isOnboardingDataMutating}
        onBack={onBack}
        {...props}
        ref={ref}
      />
    );
  }
);

FinancialInformationActivity.displayName = 'FinancialInformationActivity';
