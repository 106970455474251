import React, { forwardRef } from 'react';
import { VisuallyHidden } from '@chakra-ui/react';
import { Container } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';

import { DataComponentEnum } from '@/types/vendors.types';
import {
  VendorDetailsForm,
  VendorDetailsFormProps,
} from '@/widgets/vendorsDashboard/VendorDetails/VendorDetailsForm.widget';

export type VendorDrawerDetailsHandler = {
  scrollIntoView: () => void;
};

export const VendorDrawerDetails = forwardRef<VendorDrawerDetailsHandler, VendorDetailsFormProps>(
  (
    {
      isEditable,
      onSubmit,
      onSubmissionStateChange,
      defaultValues,
      isRPPSVendor,
      managedBy,
      initialManagedByType,
      isSaving,
      vendorId,
      inlineApiErrorCodes,
    },
    ref,
  ) => {
    const { track } = useAnalytics();
    const { formatMessage } = useMelioIntl();

    const boxRef = React.useRef<HTMLDivElement>(null);
    React.useImperativeHandle(ref, () => ({
      scrollIntoView: () => {
        boxRef.current?.scrollIntoView({ behavior: 'smooth' });
      },
    }));

    React.useEffect(() => {
      if (isEditable) {
        track('EditVendorsDetails', 'Viewed');
      }
    }, [isEditable, track]);

    return (
      <Container
        overflow="initial"
        ref={boxRef}
        data-testid="vendor-details"
        data-component={DataComponentEnum.VENDOR_DETAILS}
      >
        <VisuallyHidden
          role="status"
          aria-live="polite"
          aria-label={
            isEditable
              ? formatMessage('widgets.vendorDrawer.vendorDrawerDetails.screenTitle.edit')
              : formatMessage('widgets.vendorDrawer.vendorDrawerDetails.screenTitle.view')
          }
        />
        <VendorDetailsForm
          vendorId={vendorId}
          onSubmit={onSubmit}
          onSubmissionStateChange={onSubmissionStateChange}
          isEditable={isEditable}
          defaultValues={defaultValues}
          isRPPSVendor={isRPPSVendor}
          managedBy={managedBy}
          initialManagedByType={initialManagedByType}
          isSaving={isSaving}
          size="small"
          inlineApiErrorCodes={inlineApiErrorCodes}
        />
      </Container>
    );
  },
);

VendorDrawerDetails.displayName = 'VendorDrawerDetails';
