import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils';

export const useLineItemAccessibility = () => {
  const { showMessage } = useSystemMessage();
  const { formatMessage } = useMelioIntl();

  const onDeleteLineItemSuccess = () => {
    showMessage({
      title: formatMessage('activities.addBillV2.lineItems.deletedSuccessfullyToast'),
      type: 'success',
    });
  };

  return {
    onDeleteLineItemSuccess,
  };
};
