import React from 'react';
import { FormattedMessage } from 'react-intl';
import { HStack, Stack } from '@chakra-ui/react';
import { useSetRecoilState } from 'recoil';
import { Avatar, Button, IconButton, Text } from '@melio/penny';

import { useIsMobile } from '@/hooks/useIsMobile';
import { payDashboardSelectedCardSelector } from '@/store/PayDashboard/PayDashboards.model';
import { usePlatformIntl } from '@/translations/Intl';

type ScannedInvoiceDetailsScreenTopParams = {
  vendorName?: string;
  invoiceNumber?: string;
  onClick: VoidFunction;
};

export const ScannedInvoiceDetailsHeader = ({
  vendorName,
  invoiceNumber,
  onClick,
}: ScannedInvoiceDetailsScreenTopParams) => {
  const { formatMessage } = usePlatformIntl();
  const setSelectedPaymentCard = useSetRecoilState(payDashboardSelectedCardSelector);
  const isMobile = useIsMobile();

  return (
    <HStack justifyContent={'space-between'} alignItems={'center'} width={'full'}>
      <HStack alignItems={'center'}>
        {isMobile && (
          <IconButton
            icon="chevron-left"
            aria-label="Go back"
            variant="naked"
            size="medium"
            onClick={() => setSelectedPaymentCard({})}
          />
        )}
        <Avatar size="medium" name={vendorName || '–'} />
        <Stack flexDirection="column" justifyContent="center" spacing={0}>
          <Text textStyle="body2Semi" as="p">
            {vendorName || formatMessage('widgets.scannedInvoiceDetails.missingDetails')}
          </Text>
          {invoiceNumber && (
            <HStack alignItems={'center'} gridGap={'4px'} spacing={0}>
              <Text textStyle="body4" color="global.neutral.800">
                <FormattedMessage id={'widgets.scannedInvoiceDetails.invoiceNumber'} values={{ invoiceNumber }} />
              </Text>
            </HStack>
          )}
        </Stack>
      </HStack>
      <Button onClick={onClick} variant={'success'} label={formatMessage('widgets.scannedInvoiceDetails.reviewBill')} />
    </HStack>
  );
};
