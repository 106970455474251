import { Box, Flex, Spinner } from '@chakra-ui/react';
import { Icon, Link, Text, Theme, useTheme } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { UploadFile } from '../scheduled/types';

type DocumentsUploadIndicatorItemProps = {
  file: UploadFile;
  isMinimized: boolean;
};

export const DocumentsUploadIndicatorItemComponent = ({ file, isMinimized }: DocumentsUploadIndicatorItemProps) => {
  const style = useTheme();
  const { formatMessage } = useMelioIntl();

  const isSuccess = file.status === 'success';
  const isUploading = file.status === 'processing';
  const isError = file.status === 'error';

  const itemStyle = documentsUploadIndicatorItemTheme(style, isMinimized);

  return (
    <Box data-testid="documents-upload-indicator-item" sx={itemStyle.baseStyle.uploadIndicatorItem} key={file.name}>
      <Box sx={itemStyle.baseStyle.sideColumns}>
        {isUploading && (
          <Spinner
            label="loading"
            emptyColor={style.colors.global.neutral['400']}
            color={style.colors.global.brand['700']}
            size="md"
          />
        )}

        {isSuccess && (
          <Text color="global.success.700">
            <Icon type="checked-circle" size="large" color="inherit" />
          </Text>
        )}

        {isError && (
          <Text color="global.critical.700">
            <Icon type="error" size="large" color="inherit" />
          </Text>
        )}
      </Box>

      <Box sx={itemStyle.baseStyle.centerColumn}>
        <Flex direction="column">
          <Text textStyle="inline" color="inherit">
            {file.name}
          </Text>
          {file.size && (
            <Text textStyle="body4" color="global.neutral.600">
              {formatBytes(file.size)}
            </Text>
          )}
        </Flex>
      </Box>

      {file.url && (
        <Box sx={itemStyle.baseStyle.sideColumns}>
          <Text color="global.brand.700">
            <Link
              href="#"
              variant="standalone"
              color="inherit"
              label={formatMessage('widgets.documentsUploadIndicator.openFile')}
              onClick={() => window.open(file.url)}
            />
          </Text>
        </Box>
      )}
    </Box>
  );
};

export const documentsUploadIndicatorItemTheme = (currentTheme: Theme, minimize: boolean) => ({
  baseStyle: {
    uploadIndicatorItem: {
      position: 'relative',
      backgroundColor: currentTheme.colors.global.neutral['100'],
      width: '100%',
      height: '75px',
      padding: '0 17px',
      display: minimize ? 'none' : 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      borderTop: 'basic.light',
      ':not(:last-of-type)': {
        borderBottom: 'basic.light',
      },
    },
    centerColumn: {
      overflow: 'hidden',
      padding: '0 10px',
      textOverflow: 'ellipsis',
      flexGrow: 1,
    },
    sideColumns: {
      width: '10%',
    },
  },
});

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i] ?? ''}`;
};
