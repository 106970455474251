import { useModelApi, UseModelApiProps } from '@melio/api-client';
import { ApiKeys, DeliveryMethodsApiClient } from '@melio/platform-api-axios-client';

type Props = UseModelApiProps<typeof DeliveryMethodsApiClient.getDeliveryMethodUnmaskedAccountNumber> & {
  deliveryMethodId: string;
};

export const useDeliveryMethodUnmaskedAccountNumber = ({ deliveryMethodId, ...props }: Props) =>
  useModelApi({
    id: `unmasked-dm-account-number-${deliveryMethodId}`,
    enabled: !!deliveryMethodId,
    queryKey: [ApiKeys.DeliveryMethodsApi, 'unmasked-account-number', deliveryMethodId],
    queryFn: () => DeliveryMethodsApiClient.getDeliveryMethodUnmaskedAccountNumber(deliveryMethodId),
    ...props,
  });

export type DeliveryMethodUnmaskedAccountNumberModel = ReturnType<typeof useDeliveryMethodUnmaskedAccountNumber>;
