import { EmptyState, FormattedMessage } from '@melio/ar-domain';
import { Container, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export const DiscountsEmptyState = forwardRef((_props, ref) => (
  <EmptyState
    ref={ref}
    illustrationType="no-items"
    content={
      <Container textAlign="center">
        <Text textStyle="body4">
          <FormattedMessage id="ar.settings.activities.discountsTable.components.discountsTable.emptyState.text" />
        </Text>
      </Container>
    }
    footer={undefined}
  />
));

EmptyState.displayName = 'EmptyState';
