import {
  getFastDeliveryPreferenceOptionType,
  hasFastDeliveryPreferenceOption,
  isDeliveryPreferenceTypeFast,
} from '@melio/ap-widgets';
import { PaymentIntent } from '@melio/platform-api';
import { isBefore } from 'date-fns';

export const checkHasDeliveryFastOption = (
  updatedPaymentIntent: PaymentIntent,
  originalPaymentIntent: PaymentIntent
) => {
  const { payments: originalPayments } = originalPaymentIntent;
  const { deliveryPreferenceOptions: updatedDeliveryPreferenceOptions } = updatedPaymentIntent;

  const originalPaymentDeliveryPreferenceType = originalPayments?.[0]?.deliveryPreferenceType;

  const originalPaymentIsNotFast =
    originalPaymentDeliveryPreferenceType && !isDeliveryPreferenceTypeFast(originalPaymentDeliveryPreferenceType);

  const hasDeliveryFastOption = hasFastDeliveryPreferenceOption(updatedDeliveryPreferenceOptions);
  const updatedPaymentFastDeliveryPreferencesOption = getFastDeliveryPreferenceOptionType(
    updatedDeliveryPreferenceOptions
  );
  const updatedDeliveryPreferenceFast = updatedDeliveryPreferenceOptions?.find(
    (deliveryPreference) => deliveryPreference.type === updatedPaymentFastDeliveryPreferencesOption
  );
  const updatedDeliveryPreferenceRegular = updatedDeliveryPreferenceOptions?.find(
    (deliveryPreference) => deliveryPreference.type !== updatedPaymentFastDeliveryPreferencesOption
  );

  const isFastOptionImproveDate =
    updatedDeliveryPreferenceFast &&
    updatedDeliveryPreferenceRegular &&
    isBefore(updatedDeliveryPreferenceFast.minDeliveryDate, updatedDeliveryPreferenceRegular.minDeliveryDate);

  return !!hasDeliveryFastOption && !!originalPaymentIsNotFast && !!isFastOptionImproveDate;
};
