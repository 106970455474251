import {
  apiClient,
  BulkPostPaymentSettingsCalculatorRequest,
  PaymentSettingsCalculations,
  PaymentSettingsCalculatorRequestParams,
  PostPaymentSettingsCalculatorRequest,
} from '@melio/javascript-sdk';
import { getCentsFromDollars } from '@melio/platform-utils';

import { useCollectionApi, UseCollectionApiProps } from '../../core';
import { PaymentSettingsCollection } from './types';

const preparePaymentSettingParams = (param: PostPaymentSettingsCalculatorRequest) => ({
  ...param,
  amountToPay: getCentsFromDollars(param.amountToPay),
  invoicesData: param.invoicesData.map((invoice) => ({
    ...invoice,
    amount: getCentsFromDollars(invoice.amount),
  })),
});

const preparePaymentSettingsParams = (params: BulkPostPaymentSettingsCalculatorRequest) =>
  params.map(preparePaymentSettingParams);

export type UsePaymentSettingsProps = UseCollectionApiProps<PaymentSettingsCalculations> & {
  payload: BulkPostPaymentSettingsCalculatorRequest;
  params?: PaymentSettingsCalculatorRequestParams;
};
export const paymentSettingsQueryKey = 'payment-settings';
export const usePaymentSettings = ({ payload, params, ...props }: UsePaymentSettingsProps): PaymentSettingsCollection =>
  useCollectionApi<PaymentSettingsCalculations, BulkPostPaymentSettingsCalculatorRequest>({
    ...props,
    cacheTime: 0,
    queryKey: [paymentSettingsQueryKey, payload, params],
    queryFn: () => apiClient.paymentSettings().getPaymentSettings(preparePaymentSettingsParams(payload), params),
  });
