import React from 'react';
import { AvatarWithDetails, Group, Text } from '@melio/penny';
import { UserDetails } from '@melio/platform-api';

import { usePlatformIntl } from '@/translations/Intl';

type MarkedAsPaidSectionProp = {
  markedAsPayBy: UserDetails;
  scheduledDate: Date;
};

export const MarkedAsPaidSection = ({ markedAsPayBy, scheduledDate }: MarkedAsPaidSectionProp) => {
  const { formatMessage, formatDate } = usePlatformIntl();

  return (
    <Group variant="vertical" width="full" spacing="m" data-testid={'marked-as-pay-section'}>
      <Text textStyle="body4SemiUpper" color="global.neutral.800">
        {formatMessage('widgets.paymentDetails.markedAsPay.title')}
      </Text>
      <AvatarWithDetails
        avatarProps={{
          name: `${markedAsPayBy.firstName} ${markedAsPayBy.lastName}`,
        }}
        description={formatMessage('widgets.paymentDetails.markedAsPay.subtitle', {
          scheduledDate: formatDate(scheduledDate, { dateStyle: 'medium' }),
        })}
        mainLabelProps={{
          label: formatMessage('widgets.paymentDetails.markedAsPay.name', {
            lastName: markedAsPayBy.lastName,
            firstName: markedAsPayBy.firstName,
          }),
        }}
      />
    </Group>
  );
};
