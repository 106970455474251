import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useSearchTerm = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const urlSearch = searchParams.get('search');
  const searchTerm = useMemo(() => urlSearch ?? null, [urlSearch]);

  const setSearchTerm = useCallback(
    (searchTerm) => {
      setSearchParams((params) => {
        params.set('search', searchTerm);
        return params;
      });
    },
    [setSearchParams]
  );

  const clearSearchTerm = useCallback(() => {
    setSearchParams((params) => {
      params.delete('search');
      return params;
    });
  }, [setSearchParams]);

  return {
    searchTerm,
    setSearchTerm,
    clearSearchTerm,
  };
};
