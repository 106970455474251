import { Form, useMelioForm } from '@melio/penny';
import { useTrackAnalyticsOnFailedFormSubmit } from '@melio/platform-analytics';
import { useAccount } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { defaults } from 'lodash';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { BaseSchema } from 'yup';

import { FormWidgetProps } from '../../../types';

export type VirtualCardFormFields = {
  email: string;
  emailValidation: string;
};

const useSchema = () => {
  const { formatMessage } = useMelioIntl();
  const { data } = useAccount({ id: 'me' });

  return yup.object().shape({
    email: yup
      .string()
      .email(formatMessage('widgets.deliveryMethods.virtualCardForm.email.validation.format'))
      .required(formatMessage('widgets.deliveryMethods.virtualCardForm.email.validation.required'))
      .test(
        'is-user-email-address',
        formatMessage('widgets.deliveryMethods.virtualCardForm.emailValidation.validation.addressBelongsToUser'),
        (email) => !data?.user.email || (data?.user.email || '').toLocaleLowerCase() != email?.toLocaleLowerCase()
      ),
    emailValidation: yup
      .string()
      .required(formatMessage('widgets.deliveryMethods.virtualCardForm.emailValidation.validation.required'))
      .when('email', (email: string, schema: BaseSchema) =>
        schema.test(
          'are-addresses-match',
          formatMessage('widgets.deliveryMethods.virtualCardForm.emailValidation.validation.addressUnMatch'),
          (emailValidation: string) => email.toLocaleLowerCase() === emailValidation.toLocaleLowerCase()
        )
      ),
  });
};

export type VirtualCardFormProps = FormWidgetProps<VirtualCardFormFields>;

export const VirtualCardForm = forwardRef<VirtualCardFormProps, 'form'>(
  ({ defaultValues: _defaultValues, onSubmit, onSubmissionStateChange, isSaving, ...props }, ref) => {
    const [editMode, setEditMode] = useState<boolean>(!_defaultValues?.emailValidation);

    const defaultValues = defaults(_defaultValues, { email: '', emailValidation: '' });

    const { formatMessage } = useMelioIntl();

    const { formProps, registerField, setValue, formState } = useMelioForm<VirtualCardFormFields>({
      onSubmit,
      schema: useSchema(),
      defaultValues,
      isSaving,
      onSubmissionStateChange,
      subscribeToDefaultValuesChanges: true,
    });

    useEffect(() => {
      setEditMode(!_defaultValues?.emailValidation);
    }, [_defaultValues?.emailValidation]);

    useEffect(() => {
      if (editMode) {
        setValue('emailValidation', '');
      }
    }, [editMode, setValue]);

    useTrackAnalyticsOnFailedFormSubmit(formState, 'DeliveryMethod', 'Status');

    return (
      <Form data-component="VirtualCardForm" {...props} {...formProps} ref={ref}>
        <Form.TextField
          labelProps={{ label: formatMessage('widgets.deliveryMethods.virtualCardForm.email.label') }}
          placeholder={formatMessage('widgets.deliveryMethods.virtualCardForm.email.placeholder')}
          onCopy={(e) => e.preventDefault()}
          onPaste={(e) => e.preventDefault()}
          onInput={() => setEditMode(true)}
          autoComplete="off"
          autoFocus
          {...registerField('email')}
        />
        <Form.TextField
          labelProps={{ label: formatMessage('widgets.deliveryMethods.virtualCardForm.emailValidation.label') }}
          placeholder={formatMessage('widgets.deliveryMethods.virtualCardForm.email.placeholder')}
          onCopy={(e) => e.preventDefault()}
          onPaste={(e) => e.preventDefault()}
          autoComplete="off"
          isHidden={!editMode}
          {...registerField('emailValidation')}
        />
      </Form>
    );
  }
);

VirtualCardForm.displayName = 'VirtualCardForm';
