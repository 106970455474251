import { Stack } from '@chakra-ui/react';
import { useInternationalCountryDisplayName } from '@melio/ap-activities/src/utils/pay-flow/deliveryMethods';
import { PaymentReviewLineItem, usePaymentProcessingInfo } from '@melio/ap-widgets';
import { Divider, Text } from '@melio/penny';
import {
  DeliveryMethod as DeliveryMethodPlatform,
  DeliveryMethodType,
  Payment,
  PaymentStatusEnum,
  PaymentTimelineItem,
} from '@melio/platform-api';

import { usePlatformIntl } from '@/translations/Intl';
import { DeliveryMethod } from '../DeliveryMehod/DeliveryMethod';

export type VendorReceivesSectionProps = {
  vendorName: string;
  deliveryMethod: DeliveryMethodPlatform;
  estimatedDelivery: Date;
  maxEstimatedDelivery: Date;
  memoToVendor?: string | null;
  timeline: Array<PaymentTimelineItem>;
  paymentStatus?: PaymentStatusEnum;
  processedTimeline: Payment['processedTimeline'] | null;
  scheduledDate?: Date;
};

export const VendorReceivesSection = ({
  vendorName,
  deliveryMethod,
  estimatedDelivery,
  maxEstimatedDelivery,
  memoToVendor,
  timeline,
  paymentStatus,
  processedTimeline,
  scheduledDate,
}: VendorReceivesSectionProps) => {
  const { formatMessage, formatDateTimeRange, formatDate } = usePlatformIntl();
  const { countryDisplayName } = useInternationalCountryDisplayName(deliveryMethod);
  const { isPaymentProcessedByCapitalOne, calculateExpiryDate } = usePaymentProcessingInfo();
  const isPaymentProcessedInternally = !isPaymentProcessedByCapitalOne(deliveryMethod.type);

  const sectionTitleLabel =
    paymentStatus === PaymentStatusEnum.Completed
      ? 'widgets.paymentDetails.vendorReceives.completedPaymentTitle'
      : 'widgets.paymentDetails.vendorReceives.title';

  const getHelperTextLabel = () => {
    const { isLateDeposit } = processedTimeline || {};
    if (isLateDeposit) {
      return 'utils.deliveryMethod.bankAccount.etaHelperText';
    }

    if (deliveryMethod.type === DeliveryMethodType.VirtualAccount) {
      return 'utils.deliveryMethod.unilateral.etaHelperText';
    }

    return undefined;
  };

  const formattedExpiryDate = scheduledDate
    ? formatDate(calculateExpiryDate(scheduledDate), { day: 'numeric', month: 'short' })
    : undefined;

  const getDeliveryMethodHelperText = () => {
    switch (deliveryMethod?.type) {
      case 'virtual-card':
        return (
          scheduledDate &&
          formatMessage('utils.deliveryMethod.virtualCard.helperText', { expiryDate: formattedExpiryDate })
        );
      default:
        return undefined;
    }
  };

  const deliveryMethodHelperText = getDeliveryMethodHelperText();
  const helperTextLabel = getHelperTextLabel();
  const descriptionProps = helperTextLabel && {
    label: formatMessage(helperTextLabel),
    'data-testid': 'late-deposit-hint',
  };

  return (
    <Stack flexDirection="column" gridGap={'1rem'} spacing={0}>
      <Text textStyle="body4SemiUpper" color="global.neutral.800">
        {formatMessage(sectionTitleLabel)}
      </Text>
      {deliveryMethod.type !== DeliveryMethodType.VirtualAccount && (
        <DeliveryMethod
          deliveryMethod={deliveryMethod as unknown as DeliveryMethodPlatform}
          timeline={timeline}
          vendorName={vendorName}
          countryDisplayName={countryDisplayName}
          helperText={deliveryMethodHelperText}
        />
      )}
      {isPaymentProcessedInternally && (
        <>
          <Divider />
          <PaymentReviewLineItem
            contentTestId="delivery-eta-content"
            labelProps={{ label: formatMessage('widgets.paymentDetails.vendorReceives.deliveryDate.default.label') }}
            mainLabelProps={{
              label: formatDateTimeRange(estimatedDelivery, maxEstimatedDelivery, {
                dateStyle: 'medium',
              }),
            }}
            icon={{ type: 'scheduled' }}
            descriptionProps={descriptionProps}
          />
          <Divider />
          <PaymentReviewLineItem
            labelProps={{ label: formatMessage('widgets.paymentDetails.vendorReceives.memoToVendor.label') }}
            mainLabelProps={{
              label: memoToVendor ?? undefined,
              placeholder: formatMessage('widgets.paymentDetails.vendorReceives.memoToVendor.empty'),
            }}
          />
        </>
      )}
    </Stack>
  );
};
