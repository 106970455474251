import { Box, Stack } from '@chakra-ui/react';
import { Link, Text } from '@melio/penny';

import { SettingsSectionContainer } from '@/cl/components/SettingsSectionContainer/SettingsSectionContainer.component';
import { usePartnerConfig } from '@/hooks/partners';
import { useSupportQuestions } from '@/hooks/supportQuestions.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { SupportQuestionsWidget } from '@/widgets/settings-page/SupportPage/SupportQuestions.widget';

export const SupportAccordion = () => {
  const { formatMessage } = usePlatformIntl();
  const { partnerConfig } = usePartnerConfig();
  const { supportQuestions } = useSupportQuestions();

  if (!supportQuestions) {
    return null;
  }

  return (
    <SettingsSectionContainer>
      <Stack flexDirection="column" spacing={2} data-testid="support-accordion">
        <Text as="h2" textStyle="heading3Semi">
          {formatMessage('app.settings.SupportSection.accordion.title')}
        </Text>
        <SupportQuestionsWidget supportQuestions={supportQuestions} />
        <Box textStyle={'body4Semi'}>
          <Link
            variant="inline"
            href={partnerConfig.config.links.helpCenter}
            label={formatMessage('app.settings.SupportSection.accordion.link')}
            newTab
          />
        </Box>
      </Stack>
    </SettingsSectionContainer>
  );
};
