import { Container, Text, Typography } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export const InstallmentPrice: React.VFC<{
  feeAmount: number;
  feePercent: number;
  installmentAmount: number;
  totalPaymentAmount: number;
  testId: string;
}> = ({ testId, feePercent, feeAmount, installmentAmount, totalPaymentAmount }) => {
  const { formatCurrency, formatPercents, formatMessage } = useMelioIntl();
  return (
    <Container textAlign="end" data-testid="repayment-terms-card">
      <Text textStyle="body2Semi" data-testid={`${testId}-installment-amount`}>
        {formatCurrency(installmentAmount)}
      </Text>
      <Typography.Description
        data-testid={`${testId}-fee`}
        label={formatMessage(`activities.repaymentTerms.screens.repaymentTerms.RepaymentOptionsCard.totalFee`, {
          feePercentage: formatPercents(feePercent, { divide: true }),
          feeAmount: formatCurrency(feeAmount),
        })}
      />
      <Typography.Description
        data-testid={`${testId}-total-amount`}
        label={formatMessage(`activities.repaymentTerms.screens.repaymentTerms.RepaymentOptionsCard.totalAmount`, {
          totalAmount: formatCurrency(totalPaymentAmount),
        })}
      />
    </Container>
  );
};
