import { Form } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { ComponentProps } from 'react';

export const EmailInput = (props: Omit<ComponentProps<typeof Form.TextField>, 'aria-label'>) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Form.TextField
      labelProps={{ label: formatMessage('activities.manageCollaborators.screens.userDetailsForm.fields.email.label') }}
      {...props}
    />
  );
};
