import { useCallback } from 'react';

import { SubscriptionContextualPaywallProps, useSubscriptionContext } from '../context';
import { FeatureRequest, QuantityBasedFeatureRequest } from '../utils/planFeatures.utils';
import { useIsFeatureInSubscription } from './useIsFeatureInSubscription';

type TryUseFeatureParam = {
  onFeatureIsEligible?: () => unknown;
  onFeatureIsBlocked?: () => unknown;
  showSystemMessageHandler?: SubscriptionContextualPaywallProps['showSystemMessageHandler'];
};

type TryUseFeature = (param: TryUseFeatureParam) => void;

type FeatureEligibilityData = {
  isEligible: boolean;
  tryUseFeature: TryUseFeature;
};

type QuantityBasedFeatureEligibilityData = FeatureEligibilityData & {
  quota: { freeUnitsLimit: number; excessUnitFee?: number };
};

type BooleanBasedFeatureEligibilityData = FeatureEligibilityData;

// TODO: add tests for this hook
export function useSubscriptionFeature<T extends FeatureRequest>(
  feature: T
): T extends QuantityBasedFeatureRequest ? QuantityBasedFeatureEligibilityData : BooleanBasedFeatureEligibilityData;

export function useSubscriptionFeature(feature: FeatureRequest) {
  const {
    contextualPaywall: { showPaywall },
  } = useSubscriptionContext();

  const eligibilityData = useIsFeatureInSubscription(feature);

  const tryUseFeature = useCallback(
    ({ onFeatureIsEligible, onFeatureIsBlocked, showSystemMessageHandler }: TryUseFeatureParam) => {
      if (eligibilityData.isEligible) {
        onFeatureIsEligible?.();
        return;
      }

      showPaywall({ onDismiss: onFeatureIsBlocked, showSystemMessageHandler, ...feature });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(feature), eligibilityData.isEligible, showPaywall]
  );

  return {
    isEligible: eligibilityData.isEligible,
    ...('quota' in eligibilityData ? { quota: eligibilityData.quota } : {}),
    tryUseFeature,
  };
}
