import { AddCompanyActivity } from '@melio/ap-activities';
import { isAccountingFirmOrganization, Organization } from '@melio/platform-api';
import { useLocation } from '@melio/platform-utils';

import { AddCompanyRouteParams, useRouter } from '@/hooks/router.hooks';
import { useSwitchOrganization } from '@/hooks/useSwitchOrganization.hooks';

export const AddCompanyScreen = () => {
  const { switchOrganization } = useSwitchOrganization();
  const { goToAccountsDashboard } = useRouter();
  const {
    state: { entryPoint },
  } = useLocation<AddCompanyRouteParams>();

  const onDone = (organization?: Organization) => {
    if (organization) {
      const isAccountingFirm = isAccountingFirmOrganization(organization);
      switchOrganization({
        organizationId: organization.id,
        isAccountingFirm,
      });
    } else {
      goToAccountsDashboard();
    }
  };

  return <AddCompanyActivity onDone={onDone} entryPoint={entryPoint} />;
};

AddCompanyScreen.displayName = 'AddCompanyScreen';
