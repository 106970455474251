import { apiClient, PatchPaymentIntentsPaymentIntentIdRequest, PaymentIntent } from '@melio/javascript-sdk';
import { converDateToStringRepresentation, useDateUtils } from '@melio/platform-utils';

import { useModelApi, UseModelApiProps } from '../../core';
import { useDeleteMutation } from '../../core/useDeleteMutation';
import { useUpdateMutation } from '../../core/useUpdateMutation';
import { PaymentIntentModel, PaymentIntentWithDerivatives } from './types';
import { convertPaymentIntentCentsToDollars, convertUpdateParamsDollarsToCents } from './utils';

export type UsePaymentIntentProps = UseModelApiProps<PaymentIntentWithDerivatives> & {
  convertCurrency?: boolean;
  optimisticUpdate?: boolean;
};

export const usePaymentIntent = ({
  convertCurrency = true,
  optimisticUpdate = false,
  ...props
}: UsePaymentIntentProps): PaymentIntentModel => {
  const client = apiClient.paymentIntents();

  const query = useModelApi<PaymentIntentWithDerivatives, PatchPaymentIntentsPaymentIntentIdRequest>({
    ...props,
    queryKey: 'payment-intent',
    queryFn: () => client.get(props.id as string),
  });

  const update = useUpdateMutation<PaymentIntentWithDerivatives, PatchPaymentIntentsPaymentIntentIdRequest>(
    (data) =>
      // XXX hack to get around payment intent expects the scheduledDate to be YYYY-MM-DD and not a standard ISO date string
      // https://linear.app/meliopayments/issue/PLA-439
      client.update(props.id as string, {
        ...data,
        deliveryDate: converDateToStringRepresentation(data.deliveryDate),
        scheduledDate: converDateToStringRepresentation(data.scheduledDate),
      }),
    query,
    { optimisticUpdate }
  );

  const deleteFn = useDeleteMutation<PaymentIntentWithDerivatives>(() => client.delete(props.id as string), query);

  const confirm = useUpdateMutation<PaymentIntent, void>(() => client.confirm(props.id as string), query);

  return useEnhancedModel(
    {
      ...query,
      data: query.data,
      update,
      confirm,
      delete: deleteFn,
    },
    convertCurrency
  );
};

const useEnhancedModel = (model: PaymentIntentModel, convertCurrency: boolean): PaymentIntentModel => {
  const modelDataInCents = convertCurrency ? model.data && convertPaymentIntentCentsToDollars(model.data) : model.data;
  return {
    ...model,
    data: useDecorateComputedFields(modelDataInCents),
    update: (data) => {
      if (convertCurrency) {
        return model.update(convertUpdateParamsDollarsToCents(data)).then(convertPaymentIntentCentsToDollars);
      } else {
        return model.update(data);
      }
    },
  };
};

const useDecorateComputedFields = (paymentIntent?: PaymentIntent): PaymentIntentWithDerivatives | undefined => {
  const { isValidScheduledDate } = useDateUtils();

  if (!paymentIntent) {
    return;
  }

  const { scheduledDate } = paymentIntent;
  let computedScheduledDate: Date | undefined;

  const computedDeliveryPreference = paymentIntent.deliveryPreferenceOptions?.find(
    (deliveryPreferenceOption) => deliveryPreferenceOption.type === paymentIntent.selectedDeliveryPreferenceType
  );

  if (scheduledDate && isValidScheduledDate(scheduledDate)) {
    computedScheduledDate = scheduledDate;
  } else {
    computedScheduledDate = computedDeliveryPreference?.effectiveScheduleDate;
  }

  return {
    ...paymentIntent,
    computedDeliveryPreference,
    computedScheduledDate,
  };
};
