import { BillSubscriptionEndPolicyEnum, useBillSubscription } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSetDocumentTitle } from '@melio/platform-sdk';
import React, { ComponentProps } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { SeriesPaymentUpdatedSuccessfullyScreen } from '../../edit-bill-subscription/screens/newPaymentUpdated/SeriesPaymentUpdatedSuccessfully.screen';
import { PaymentFlowLoader } from '../components/PaymentFlowLoader';
import { PaymentFlowActivity } from '../PaymentFlowActivity/PaymentFlowActivity';
import { PaymentFlowFormFields } from '../types';
import { useSubmitErrorHandler } from '../util/useSubmitErrorHandler/useSubmitErrorHandler';

type EditRecurringActivityProps = {
  billSubscriptionId: string;
  onDone: VoidFunction;
  onClose: VoidFunction;
};

export const EditRecurringActivity = ({ billSubscriptionId, onDone, onClose }: EditRecurringActivityProps) => {
  const { formatMessage } = useMelioIntl();
  const title = formatMessage('activities.paymentFlow.form.header.title.editRecurringPayment');
  const navigate = useNavigate();

  useSetDocumentTitle(title);
  const {
    data: billSubscription,
    update: updateBillSubscription,
    isLoading: isLoadingBillSubscription,
    isMutating: isUpdatingBillSubscription,
  } = useBillSubscription({
    id: billSubscriptionId,
    params: { expand: ['nextOccurrence', 'vendor', 'fundingSource', 'deliveryMethod', 'occurrences'] },
    onUpdateError: useSubmitErrorHandler(),
  });

  const defaultValues: ComponentProps<typeof PaymentFlowActivity>['defaultValues'] = {
    vendorId: billSubscription?.vendorId,
    deliveryMethodId: billSubscription?.deliveryMethodId,
    fundingSourceId: billSubscription?.fundingSourceId,
    amountToPay: billSubscription?.amount?.toString(),
    noteToVendor: billSubscription?.memoToVendor,
    intervalType: billSubscription?.intervalType,
    endPolicy: billSubscription?.endPolicy,
    deliveryDate: billSubscription?.nextOccurrence?.deliveryDate || billSubscription?.nextOccurrence?.dueDate,
    startDate: billSubscription?.nextOccurrence?.deliveryDate || billSubscription?.nextOccurrence?.dueDate,
    endDate: billSubscription?.endDate || null,
    lastAmount: billSubscription?.lastAmount?.toString() || undefined,
    numOfOccurrences: billSubscription?.numOfOccurrences || null,
    recurrenceType: 'recurring',
  };

  const onSubmit = (data: PaymentFlowFormFields) =>
    updateBillSubscription({
      amount: Number(data.amountToPay),
      fundingSourceId: data.fundingSourceId || void 0,
      deliveryMethodId: data.deliveryMethodId || void 0,
      memoToVendor: data.noteToVendor || void 0,
      intervalType: data.intervalType,
      startDate: data.startDate || void 0,
      debitDate: data.scheduleDate || void 0,
      ...(data.endPolicy === BillSubscriptionEndPolicyEnum.NoEndDate
        ? {
            endPolicy: BillSubscriptionEndPolicyEnum.NoEndDate,
          }
        : data.endPolicy === BillSubscriptionEndPolicyEnum.EndDate
        ? {
            endPolicy: BillSubscriptionEndPolicyEnum.EndDate,
            endDate: data.endDate,
            lastAmount: data.lastAmount ? Number(data.lastAmount) : undefined,
          }
        : {
            endPolicy: BillSubscriptionEndPolicyEnum.NumOfOccurrences,
            numOfOccurrences: data.numOfOccurrences,
            lastAmount: data.lastAmount ? Number(data.lastAmount) : undefined,
          }),
    }).then(() => navigate('success'));

  if (isLoadingBillSubscription) {
    return <PaymentFlowLoader />;
  }

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <PaymentFlowActivity
            defaultValues={defaultValues}
            billSubscription={billSubscription}
            title={title}
            isSubmitting={isUpdatingBillSubscription}
            onSubmit={onSubmit}
            onClose={onClose}
          />
        }
      />
      <Route
        path="/success"
        element={
          billSubscription ? (
            <SeriesPaymentUpdatedSuccessfullyScreen billSubscription={billSubscription} onBackToDashboard={onDone} />
          ) : null
        }
      />
    </Routes>
  );
};
