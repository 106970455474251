import { BusinessResultItem, useBusinesses } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { useDebounceCallback } from '@melio/platform-utils';
import { useMemo, useState } from 'react';

import { BUSINESS_SEARCH_DEBOUNCE } from '../utils';
import { getBusinessesOptions } from './BusinessSearch.utils';

export const useBusinessSearchOptions = () => {
  const {
    settings: {
      showCheckMarkIconForManagedVendor,
      vendor: {
        forms: { shouldUseSearchVendorSectionLabel },
      },
    },
  } = useConfig();
  const [searchTerm, setSearchTerm] = useState<string>('');

  const [isSearchBusinessesInDirectoriesSupported] = useFeature<boolean>(
    FeatureFlags.IsSearchBusinessesInDirectoriesSupported,
    false
  );

  const shouldSearch = searchTerm.length > 2;

  const { isLoading: isLoadingBusinesses, data: businesses } = useBusinesses({
    params: { searchTerm },
    enabled: isSearchBusinessesInDirectoriesSupported && shouldSearch,
  });

  const { formatMessage } = useMelioIntl();

  const handleSearch = useDebounceCallback((query: string) => {
    setSearchTerm(query);
  }, BUSINESS_SEARCH_DEBOUNCE);

  const businessesOptions = useMemo(() => (businesses ? getBusinessesOptions(businesses) : []), [businesses]);
  const options = useMemo(() => {
    if (!shouldUseSearchVendorSectionLabel) {
      return businessesOptions;
    }

    const metadata = {
      icon: showCheckMarkIconForManagedVendor ? ('verified' as const) : undefined,
      label: formatMessage('widgets.businessSearch.businessNetworkTitle'),
    };

    return [
      {
        options: businessesOptions,
        metadata,
      },
    ];
  }, [businessesOptions, shouldUseSearchVendorSectionLabel, showCheckMarkIconForManagedVendor, formatMessage]);

  const getBusinessById = (self: string) => businesses?.find(({ business }) => business.self === self);

  const getSelectedItemAnalyticsData = (selectedBusiness?: BusinessResultItem) => {
    const fiservDirectoryItems = (businesses || []).filter((option) => option.directoryName === 'Fiserv');

    const itemPosition = selectedBusiness
      ? businessesOptions.findIndex((option) => option.value === selectedBusiness.business.self) + 1
      : null;
    const cta = selectedBusiness ? selectedBusiness.business.name : 'add-new';

    return {
      SearchValue: searchTerm,
      VendorType: selectedBusiness ? 'directory' : 'new-local',
      CountResults: (businesses || []).length,
      BillersCount: fiservDirectoryItems.length,
      ItemPosition: itemPosition,
      Cta: cta,
    };
  };

  return {
    getSelectedItemAnalyticsData,
    isLoadingBusinesses,
    options,
    handleSearch,
    getBusinessById,
    shouldSearch,
  };
};
