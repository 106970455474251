import { useInvoiceDiscounts } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';
import { useMemo } from 'react';

import { DiscountsTableScreen } from './screens';

type DiscountsTableActivityProps = {
  onError?: ARErrorFunction;
  onAdd: VoidFunction;
  onEdit: (discountId: string) => unknown;
  onDelete: (discountId: string) => unknown;
};

export const DiscountsTableActivity = forwardRef<DiscountsTableActivityProps>(
  ({ onAdd, onEdit, onDelete, onError, ...props }, ref) => {
    const { data, isLoading, isFetching } = useInvoiceDiscounts({ onError });

    const sortedData = useMemo(() => {
      if (!data) return [];

      return data.sort((a, b) => a.name.localeCompare(b.name));
    }, [data]);

    return (
      <DiscountsTableScreen
        isLoading={isLoading || isFetching}
        discounts={sortedData}
        onAdd={onAdd}
        onEdit={onEdit}
        onDelete={onDelete}
        {...props}
        ref={ref}
      />
    );
  }
);
