import { useState } from 'react';
import { ActionsDropdownMenu, ActionsDropdownMenuItemProps } from '@melio/penny';

import { usePlatformIntl } from '@/translations/Intl';

export type JoinOrganizationRequestCardMenuProps = {
  onRequestCancel: VoidFunction;
  onRequestResend: VoidFunction;
};

export const JoinOrganizationRequestCardMenu = ({
  onRequestCancel,
  onRequestResend,
}: JoinOrganizationRequestCardMenuProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { formatMessage } = usePlatformIntl();

  const actionMenuItems = [
    {
      label: formatMessage('widgets.joinOrganizationRequestCard.menu.resend'),
      onClick: onRequestResend,
      dataTestId: 'join-organization-request-resend',
    },
    {
      label: formatMessage('widgets.joinOrganizationRequestCard.menu.cancel'),
      onClick: onRequestCancel,
      variant: 'critical' as ActionsDropdownMenuItemProps['variant'],
      dataTestId: 'join-organization-request-cancel',
    },
  ];

  return (
    <ActionsDropdownMenu
      size="small"
      label="menu action"
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      items={actionMenuItems}
      data-testid="join-organization-request-card-menu"
    />
  );
};
