import { ComponentThemeType } from '@/cl/theme/theme.hooks';

export const NoResultsFoundTheme: ComponentThemeType = () => ({
  baseStyle: {
    container: {
      flexDirection: 'column',
      justifyContent: 'center',
      gridGap: '24px',
    },
    icon: {
      paddingTop: '80px',
      alignSelf: 'center',
    },
    image: {
      boxSize: '80px',
      objectFit: 'fill',
    },
  },
});
