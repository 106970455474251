export const PaymentMethod = {
  Cash: 'cash',
  Check: 'check',
  Ach: 'ach',
  Card: 'card',
  Other: 'other',
} as const;

export type PaymentMethod = (typeof PaymentMethod)[keyof typeof PaymentMethod];

export type MarkAsPaidFormField = {
  paymentDate: Date;
  amount: number;
  referenceNumber?: string;
  note?: string;
  paymentMethod?: PaymentMethod;
};
