import { ApprovalWorkflow, RoleUniqueNames } from '@melio/platform-api';

type DefaultValues = Pick<ApprovalWorkflow, 'criteria' | 'actions'>;
export function getDefaultValuesV2({ roles }: { roles: RoleUniqueNames[] }): DefaultValues {
  return {
    criteria: {
      operator: 'AND',
      conditions: [
        {
          attribute: 'payment.amount',
          condition: { type: 'workflowNumericCondition', configuration: { operator: 'greaterEqual', value: 0 } },
        },
      ],
    },
    actions: [{ type: 'role', configuration: { roles, numberOfApprovers: 1 } }],
  };
}
