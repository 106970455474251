import { Drawer, Text } from '@melio/penny';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { TodosDrawerBody } from './components/TodosDrawerBody';
import { useEnrichedTodos } from './hooks/useEnrichedTodos';

type Props = { isOpen: boolean; onClose: VoidFunction };
export const TodosDrawerActivity = withAnalyticsContext(({ isOpen, onClose }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { data: todos, isLoading, totalCount, markAsSeen, refetch } = useEnrichedTodos();
  const location = useLocation();
  const shouldReturnFocus = useRef(!!document.activeElement && document.activeElement !== document.body);

  useEffect(() => {
    onClose();
  }, [location, onClose]);

  useEffect(() => {
    if (isOpen) {
      refetch();
    }
  }, [isOpen, refetch]);

  return (
    <Drawer
      isOpen={isOpen}
      shouldReturnFocus={shouldReturnFocus.current}
      onClose={onClose}
      data-testid="todos-drawer"
      isLoading={isLoading}
      closeButtonAriaLabel={formatMessage('widgets.todosDrawer.closeButtonAriaLabel')}
      header={
        <Text as="h2" textStyle="heading2Semi" data-testid="todos-drawer-header-title">
          {formatMessage('widgets.todosDrawer.header.title')}
        </Text>
      }
      body={<TodosDrawerBody todos={todos ?? []} totalCount={totalCount} markAsSeen={markAsSeen} />}
    />
  );
});
