import { Box } from '@chakra-ui/react';
import { SectionBanner } from '@melio/penny';
import { Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export const ResendedCheckBanner = ({ payment }: { payment: Payment }) => {
  const { formatMessage } = useMelioIntl();
  const { tracingNumber } = payment;

  const description = formatMessage(`widgets.paymentDetails.inProgress.reason.voidAndResendInitiated.description`, {
    tracingNumber,
  });

  return (
    <Box pb="s">
      <SectionBanner description={description} variant="informative" data-testid="resended-check-alert" />
    </Box>
  );
};
