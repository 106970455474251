import { withAnalyticsContext } from '@melio/platform-analytics';
import { useWizardSimple, UseWizardSimpleArgs } from '@melio/platform-utils';
import { Route, Routes } from 'react-router-dom';

import { NewSinglePaymentStepLayout } from '../../NewSinglePaymentStepLayout';
import { RefundSentStep } from './steps/RefundSentStep';
import { ReviewAndConfirmStep } from './steps/ReviewAndConfirmStep';
import { RefundFlowDoneAction, Steps } from './types/types';
import { useRefundData } from './useRefundData';
import { getDefaultAnalyticsFields } from './utils';

const firstStep: Steps = 'review';
const locationsMap: UseWizardSimpleArgs<Steps>['locationsMap'] = {
  review: 'review',
  confirm: 'confirm',
};

export const RefundActivity = withAnalyticsContext(
  ({
    paymentId,
    goToDashboard,
    onRetryDeliverLinkClicked,
  }: {
    paymentId: string;
    goToDashboard: VoidFunction;
    onRetryDeliverLinkClicked: VoidFunction;
  }) => {
    const { payment, isLoading, refund, isUpdatingPayment, isRefundError, setIsRefundError } = useRefundData(paymentId);

    const { currentStep, goNext, cancelFlow, completeFlow } = useWizardSimple<Steps>({
      firstStep,
      flowName: 'refund-payment',
      locationsMap,
      navigationSequence: ['review', 'confirm'],
      cancelUrlFallback: '/',
    });

    if (isLoading || !payment) {
      return <NewSinglePaymentStepLayout isLoading />;
    }

    const defaultAnalyticsFields = getDefaultAnalyticsFields(currentStep, payment);

    const onConfirmRefund = async () => {
      try {
        await refund(paymentId);
        goNext();
      } catch (error) {
        if ((error as PlatformError)?.code === '400') {
          setIsRefundError(true);
          goNext();
        }
      }
    };

    const onFlowDone = (action: RefundFlowDoneAction = 'goToDashboard') => {
      if (action === 'goToDashboard') {
        completeFlow(goToDashboard);
      } else if (action === 'retryPayment') {
        onRetryDeliverLinkClicked();
      }
    };

    return (
      <div data-testid="refund-activity">
        <Routes>
          <Route
            path={locationsMap.review}
            element={
              <ReviewAndConfirmStep
                payment={payment}
                isLoading={isLoading}
                isLoadingButton={isUpdatingPayment}
                cancelFlow={cancelFlow}
                onFlowDone={onFlowDone}
                onConfirmRefund={onConfirmRefund}
                defaultAnalyticsFields={defaultAnalyticsFields}
              />
            }
          />
          <Route
            path={locationsMap.confirm}
            element={
              <RefundSentStep
                isRefundError={isRefundError}
                payment={payment}
                isLoading={isLoading}
                onFlowDone={onFlowDone}
                defaultAnalyticsFields={defaultAnalyticsFields}
              />
            }
          />
        </Routes>
      </div>
    );
  }
);
