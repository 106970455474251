import { Vendor } from '@melio/platform-api';
import { identity } from 'lodash';

export const isVendorDirectoryDetailsCompleted = ({
  vendor,
  isCollectMissingVendorInfoEnabled,
  requiredDirectoryFields,
}: {
  vendor?: Vendor;
  isCollectMissingVendorInfoEnabled: boolean;
  requiredDirectoryFields: string[];
}) => {
  if (!isCollectMissingVendorInfoEnabled || !requiredDirectoryFields.length || !vendor) {
    return true;
  }

  return requiredDirectoryFields.every((requiredFieldName) => !!vendor[requiredFieldName as keyof Vendor]);
};

export const getVendorNameForNotificationMessage = (vendor: Pick<Vendor, 'accountNumber' | 'nickname' | 'name'>) => {
  const vendorDescription = [vendor.nickname, vendor.accountNumber].filter(identity).join(' | ');
  return vendorDescription ? `${vendor.name} (${vendorDescription})` : vendor.name;
};
