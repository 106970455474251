import { ApprovalWorkflow, WorkflowActions } from '@melio/platform-api';
import { AnySchema, array, BaseSchema, lazy, mixed, number, object, SchemaOf, string } from 'yup';

export const schema: SchemaOf<ApprovalWorkflow> = object().shape({
  criteria: object().shape({
    conditions: array().of(
      lazy((value: ApprovalWorkflow['criteria']['conditions'][number]) => {
        switch (value.condition.type) {
          case 'workflowNumericCondition':
            return object().shape({
              condition: object().shape({
                type: string().oneOf(['workflowNumericCondition']),
                configuration: object().shape({
                  operator: string().oneOf(['greaterEqual']),
                  value: number().min(0).required(),
                }),
              }),
            });
          case 'workflowCollaboratorsCondition': {
            const config =
              value.condition.configuration.filterBy === 'role'
                ? object().shape({
                    filterBy: string().oneOf(['role']),
                    roles: array().of(string()).min(1),
                  })
                : object().shape({
                    filterBy: string().oneOf(['user']),
                    ids: array().of(string()).min(1),
                  });

            return object().shape({
              condition: object().shape({
                type: mixed().oneOf([value.condition.type]),
                configuration: config,
              }),
            });
          }
          default:
            return {} as unknown as BaseSchema;
        }
      }) as unknown as BaseSchema
    ) as unknown as AnySchema,
  }),
  actions: array<WorkflowActions>().of(
    object().shape({
      configuration: object().shape({
        numberOfApprovers: number().moreThan(0),
      }),
    })
  ),
}) as unknown as SchemaOf<ApprovalWorkflow>;
