import { Form, useMelioForm } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { object, SchemaOf, string } from 'yup';

import { FormWidgetProps } from '../../../../types';

export type MicroDepositsVerificationFormFields = {
  amount1: string;
  amount2: string;
};

export type MicroDepositsVerificationFormProps = FormWidgetProps<MicroDepositsVerificationFormFields> & {
  currency?: string;
};

const amountFormat = /^0.\d+$/;

const useSchema = () => {
  const { formatMessage } = useMelioIntl();

  return object().shape({
    amount1: string()
      .required(formatMessage('widgets.microDepositsVerificationForm.amount1.validation.required'))
      .matches(amountFormat, formatMessage('widgets.microDepositsVerificationForm.amount1.validation.minmax')),
    amount2: string()
      .required(formatMessage('widgets.microDepositsVerificationForm.amount2.validation.required'))
      .matches(amountFormat, formatMessage('widgets.microDepositsVerificationForm.amount2.validation.minmax')),
  }) as SchemaOf<MicroDepositsVerificationFormFields>;
};

export const MicroDepositsVerificationForm = forwardRef<MicroDepositsVerificationFormProps, 'form'>(
  ({ currency, defaultValues, onSubmit, onSubmissionStateChange, isSaving, autoFocus, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const { formProps, registerField } = useMelioForm<MicroDepositsVerificationFormFields>({
      onSubmit,
      schema: useSchema(),
      defaultValues,
      isSaving,
      onSubmissionStateChange,
    });

    return (
      <Form data-component="MicroDepositsVerificationForm" {...props} {...formProps} columns={2} ref={ref}>
        <Form.AmountField
          labelProps={{ label: formatMessage('widgets.microDepositsVerificationForm.amount1.label') }}
          {...registerField('amount1')}
          autoFocus
        />
        <Form.AmountField
          labelProps={{ label: formatMessage('widgets.microDepositsVerificationForm.amount2.label') }}
          {...registerField('amount2')}
        />
      </Form>
    );
  }
);

MicroDepositsVerificationForm.displayName = 'MicroDepositsVerificationForm';
