import { Group, IconButton, useBreakpointValue } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-provider';
import { PageTitle } from '@melio/platform-utils';

import { NewSinglePaymentStepLayout } from '../../NewSinglePaymentStepLayout';

type Props = {
  onClose: VoidFunction;
};

export const HeaderContent = ({ onClose }: Props) => {
  const { formatMessage } = useMelioIntl();
  const isMobile = useBreakpointValue({ xs: true, s: false });

  if (!isMobile) {
    return null;
  }

  return (
    <NewSinglePaymentStepLayout.Header gridTemplateColumns="1fr">
      <Group alignItems="center" spacing="s-m">
        <IconButton aria-label="Back" icon="arrow-left" size="small" variant="naked" onClick={onClose} />
        <PageTitle data-testid="layout-title" textStyle="heading1Semi">
          {formatMessage('activities.addVendor.screens.addVendorDetails.title')}
        </PageTitle>
      </Group>
    </NewSinglePaymentStepLayout.Header>
  );
};
