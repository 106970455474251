import { Container, Group, Tabs } from '@melio/penny';
import { Outlet } from 'react-router-dom';

import { SettingsTab, TabItem } from './types';

export type TabsLayoutProps = {
  tabs: TabItem[];
  activeTab: SettingsTab;
  onChange: (name: SettingsTab) => void;
};

export function TabsLayout({ activeTab, onChange, tabs, ...props }: TabsLayoutProps) {
  return (
    <Group variant="vertical" data-component={TabsLayout.displayName} {...props}>
      <Tabs tabs={tabs} activeTab={activeTab} onChange={onChange as never} />
      <Container data-testid={`tab-container-${activeTab}`}>
        <Outlet />
      </Container>
    </Group>
  );
}
TabsLayout.displayName = 'TabsLayout';
