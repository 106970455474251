import { Box, Stack } from '@chakra-ui/react';
import { Button, Container, Group, SectionBanner, Text } from '@melio/penny';
import { SectionHeader } from '@melio/platform-utils';

import { usePlatformIntl } from '@/translations/Intl';
import { ZenworkIcon } from './icons/zenwork.icon';
import { PremiumBadge } from './components';

export const TaxFormSection = () => {
  const { formatMessage } = usePlatformIntl();

  return (
    <Stack flexDirection="column" gridGap="16px" spacing={0} width="full">
      <SectionHeader title={formatMessage('widgets.taxAndReportsSettings.taxFormSection.title')} titleAs="h3" />
      <Container border="regular" paddingX="m" paddingY="m">
        <Group variant="vertical" spacing="m">
          <ZenworkIcon />
          <Group variant="horizontal" spacing="s" justifyContent="flex-start">
            <Text textStyle="heading3Semi">
              {formatMessage('widgets.taxAndReportsSettings.taxFormSection.eFilling.title')}
            </Text>
            <PremiumBadge />
          </Group>
          <SectionBanner
            description={formatMessage('widgets.taxAndReportsSettings.taxFormSection.eFilling.banner.description')}
          />
          <Text textStyle="body2">
            {formatMessage('widgets.taxAndReportsSettings.taxFormSection.eFilling.description')}
          </Text>
          <Box>
            <Button isDisabled label={formatMessage('widgets.taxAndReportsSettings.taxFormSection.eFilling.cta')} />
          </Box>
        </Group>
      </Container>
    </Stack>
  );
};
