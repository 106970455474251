import { Button, Container, Divider, Group, Icon, Menu, RadioGroup, Text } from '@melio/penny';
import { FC, useState } from 'react';

import { useMelioIntl } from '../i18n';

type FilterOption<T> = { id: T; label: string };

type FilterProps<T> = {
  options: FilterOption<T>[];
  activeFilter: T;
  defaultFilter: T;
  title: string;
  onChange: (id: T) => void;
};

export const MobileFilter = <T extends string>({
  options,
  activeFilter,
  defaultFilter,
  title,
  onChange,
}: FilterProps<T>) => {
  const { formatMessage } = useMelioIntl();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<T>(defaultFilter);
  const label = formatMessage('ar.dashboard.activities.invoiceTable.mobile.filters.triggerButton.label');

  const onApply = () => {
    onChange(filter);
    handleOpenMenuChange(false);
  };

  const onClear = () => {
    onChange(defaultFilter);
    handleOpenMenuChange(false);
  };

  const handleOpenMenuChange = (isOpen: boolean) => {
    setFilter(activeFilter);
    setIsMenuOpen(isOpen);
  };

  const isDefaultFilterSelected = filter === defaultFilter;

  const filterOptions = options.map(({ id, label }) => ({
    label: <FilterLabel defaultFilter={defaultFilter} id={id} label={label} />,
    value: id,
    ariaLabel: label,
    dataTestId: `filter-item-${id}`,
  }));

  return (
    <Menu
      data-testid="filter-menu"
      data-component={MobileFilter.displayName}
      trigger={
        <Button
          leftElement={<Icon size="small" type="filter" color="inherit" aria-hidden />}
          label={label}
          variant="tertiary"
          size="medium"
          aria-label="icon-button-filter"
          isFullWidth
          data-testid="mobile-filters-button"
        />
      }
      onOpenChange={handleOpenMenuChange}
      isOpen={isMenuOpen}
      title={title}
      size="fit-content"
    >
      <RadioGroup
        value={filter}
        variant="vertical"
        onChange={(e) => setFilter(e.target.value as T)}
        options={filterOptions}
        gap="none"
        paddingX="m"
      />
      <Container>
        <Divider />
        <Container data-testid="filter-footer-menu" paddingX="s" paddingY="s">
          <Group justifyContent="space-between" alignItems="center">
            <Button
              label={formatMessage('ar.domain.components.mobileFilter.footer.clear.label')}
              variant="secondary"
              onClick={onClear}
              data-testid="mobile-filter-clear"
              isDisabled={isDefaultFilterSelected}
            />
            <Button
              label={formatMessage('ar.domain.components.mobileFilter.footer.apply.label')}
              onClick={onApply}
              data-testid="mobile-filter-apply"
            />
          </Group>
        </Container>
      </Container>
    </Menu>
  );
};

type FilterLabelProps = FilterOption<string> & { defaultFilter: string };

const FilterLabel: FC<FilterLabelProps> = ({ id, label, defaultFilter }) => {
  const { formatMessage } = useMelioIntl();
  return (
    <Container data-testid={`filter-item-${id}`} key={`${id}`} paddingY="s" paddingLeft="xs" width="fit-content">
      <Group spacing="xs">
        <Text textStyle="body3Semi" color="global.neutral.1000">
          {label}
        </Text>
        {id === defaultFilter && (
          <Text textStyle="body3Semi" color="global.neutral.800">
            {formatMessage('ar.domain.components.mobileFilter.footer.default.label')}
          </Text>
        )}
      </Group>
    </Container>
  );
};

MobileFilter.displayName = 'MobileFilter';
