import { Box, useStyleConfig } from '@chakra-ui/react';
import { BaseMenu, ConditionalWrapper, Group, Icon, IconKey, Text, Tooltip, useBreakpoint } from '@melio/penny';
import { ReactNode } from 'react';

export type AddBillDropdownMenuItemProps = {
  index: number;
  label: string;
  disabled?: { isDisabled: boolean; message?: string };
  isSelected?: boolean;
  variant?: 'default' | 'critical';
  dataTestId?: string;
  onClick?: VoidFunction;
  icon?: IconKey;
  rightElement?: ReactNode;
};

export const AddBillDropdownMenuItem = (props: AddBillDropdownMenuItemProps) => {
  const { index, label, disabled, onClick, isSelected, variant, dataTestId, icon, rightElement, ...rest } = props;
  const styles = useStyleConfig('MenuItem', { variant });
  const { isExtraSmallScreen: isMobile } = useBreakpoint();

  const disabledMessage = disabled?.message;
  const shouldShowTooltip = Boolean(disabled?.isDisabled && disabledMessage);

  const itemContent = (
    <>
      <Group alignItems="center">
        {icon && (
          <Icon type={icon} size={isMobile ? 'large' : 'small'} color="inherit" isDisabled={disabled?.isDisabled} />
        )}
        <Text color="inherit" textStyle="inline" shouldSupportEllipsis>
          {label}
        </Text>
      </Group>
      {rightElement}
    </>
  );

  return (
    <ConditionalWrapper
      condition={shouldShowTooltip}
      wrapper={(children) => (
        <Tooltip label={disabledMessage as string} shouldAddTriggerFocus={disabled?.isDisabled}>
          {children}
        </Tooltip>
      )}
    >
      <BaseMenu.Item
        data-component="MenuItem"
        data-testid={dataTestId}
        onClick={!disabled?.isDisabled ? onClick : undefined}
      >
        <Box
          __css={styles}
          aria-selected={isSelected}
          aria-label={label}
          aria-disabled={disabled?.isDisabled}
          {...rest}
        >
          {itemContent}
        </Box>
      </BaseMenu.Item>
    </ConditionalWrapper>
  );
};

AddBillDropdownMenuItem.displayName = 'AddBillDropdownMenuItem';
