import React from 'react';
import { Box } from '@chakra-ui/react';
import { forwardRef } from '@melio/platform-utils';

import { useAppTheme } from '@/cl/theme/theme.hooks';
import { FooterTheme } from './Footer.component.theme';

export type FooterProps = {
  left: React.ReactNode;
  right: React.ReactNode;
};

export const Footer = forwardRef<FooterProps, 'div'>(({ left, right, ...restProps }, ref) => {
  const styles = useAppTheme(FooterTheme, {});

  return (
    <Box sx={styles.container} {...restProps} ref={ref}>
      <Box sx={styles.leftContainer}>{left}</Box>
      <Box sx={styles.rightContainer}>{right}</Box>
    </Box>
  );
});
