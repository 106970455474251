/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@chakra-ui/react';
import { Container, Group, GroupProps, IconButton, StackedContainer, Text, Tooltip } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { useEffect, useState } from 'react';

export type CarouselProps = {
  elements: React.ReactElement[];
  initialElementIndex?: number;
  isCyclical?: boolean;
  arrowSpacing?: GroupProps['spacing'];
  tooltipRightButtonLabel?: string;
  tooltipLeftButtonLabel?: string;
};

export const Carousel = forwardRef<CarouselProps, 'div'>((props, ref) => {
  const {
    elements,
    initialElementIndex = 0,
    isCyclical = true,
    arrowSpacing = 'xl',
    tooltipLeftButtonLabel,
    tooltipRightButtonLabel,
    ...rest
  } = props;
  const totalElements = elements.length;

  const lastItemIndex = totalElements > 0 ? totalElements - 1 : 0;

  const [currentElementIndex, setCurrentElementIndex] = useState(
    getInitialElementIndex(initialElementIndex, totalElements)
  );

  useEffect(() => {
    // if number of elements was reduced adjust index if it was last item
    if (currentElementIndex > lastItemIndex) {
      setCurrentElementIndex(lastItemIndex);
    }
  }, [totalElements]);

  const handlePrevClick = () => {
    if (totalElements < 1) {
      return;
    }
    setCurrentElementIndex(currentElementIndex === 0 ? lastItemIndex : currentElementIndex - 1);
  };

  const handleNextClick = () => {
    if (totalElements < 1) {
      return;
    }
    setCurrentElementIndex(currentElementIndex === lastItemIndex ? 0 : currentElementIndex + 1);
  };
  const { formatMessage } = useMelioIntl();

  const hasMultipleElements = totalElements > 1;
  const numberOfStacks = getNumberOfStacks(totalElements);

  const element = elements[currentElementIndex];
  if (totalElements === 0 || !element) {
    return null;
  }

  const buttonVisibility = hasMultipleElements ? undefined : 'hidden';

  const prevButton = (
    <Box visibility={buttonVisibility}>
      <Tooltip label={tooltipLeftButtonLabel ?? ''} isEnabled={!!tooltipLeftButtonLabel}>
        <IconButton
          aria-label="Previous"
          icon="chevron-left"
          variant="naked"
          size="medium"
          onClick={handlePrevClick}
          data-testid="carousel-prev-button"
          isDisabled={!isCyclical && currentElementIndex === 0}
        />
      </Tooltip>
    </Box>
  );

  const nextButton = (
    <Box visibility={buttonVisibility}>
      <Tooltip label={tooltipRightButtonLabel ?? ''} isEnabled={!!tooltipLeftButtonLabel}>
        <IconButton
          aria-label="Next"
          icon="chevron-right"
          variant="naked"
          size="medium"
          onClick={handleNextClick}
          data-testid="carousel-next-button"
          isDisabled={!isCyclical && currentElementIndex === lastItemIndex}
        />
      </Tooltip>
    </Box>
  );
  return (
    <Group data-component="Carousel" ref={ref} variant="vertical" spacing="s" alignItems="center" {...rest}>
      <Group spacing={arrowSpacing} width="full">
        {hasMultipleElements && (
          <Group.Item shrink={0}>
            <Container overflow="initial" paddingRight="xxs" width="fit-content" alignItems="center" height="full">
              {prevButton}
            </Container>
          </Group.Item>
        )}
        <StackedContainer width="full" stacksToDisplay={numberOfStacks}>
          {element}
        </StackedContainer>
        {hasMultipleElements && (
          <Group.Item shrink={0}>
            <Container overflow="initial" paddingLeft="xxs" width="fit-content" alignItems="center" height="full">
              {nextButton}
            </Container>
          </Group.Item>
        )}
      </Group>
      {hasMultipleElements && (
        <Text textStyle="body4" data-testid="carousel-pagination">
          {formatMessage('widgets.billsDetailsCarousel.carousel.pagination', {
            index: currentElementIndex + 1,
            total: totalElements,
          })}
        </Text>
      )}
    </Group>
  );
});

const getInitialElementIndex = (defaultIndex: number, numberOfElements: number) => {
  if (defaultIndex < 1) {
    return 0;
  }
  if (numberOfElements < 1) {
    return 0;
  }
  if (defaultIndex >= numberOfElements) {
    return 0;
  }

  return defaultIndex;
};

const getNumberOfStacks = (items: number) => {
  if (items <= 1) {
    return 0;
  }
  if (items === 2) {
    return 1;
  }
  return 2;
};

Carousel.displayName = 'Carousel';
