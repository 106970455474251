import { Button, useToast } from '@melio/penny';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { Logger } from '@melio/platform-logger';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { FullScreenLayout } from '../../components/FullScreenLayout/FullScreenLayout';
import { RedirectTarget } from '../../consts';

const useClientCreatedSuccessfullyAnalytics = () => {
  useAnalyticsView('Organization', true, true, { Intent: 'client-added' });

  const { track } = useAnalytics();

  const trackClick = (intent: 'go-to-dashboard' | 'setup-client-account') =>
    track('Organization', 'Click', { Cta: intent, Intent: intent });

  return { trackClick };
};

export const ClientCreatedSuccessfully = withAnalyticsContext<{
  onDone(params: { firmClientOrgId: string; returnTo: RedirectTarget }): Promise<void>;
}>(({ onDone, setAnalyticsProperties }) => {
  const { formatMessage } = useMelioIntl();

  const [isRedirectingToDashboard, setIsRedirectingToDashboard] = useState(false);
  const [isLoadingSwitchOrganization, setIsLoadingSwitchOrganization] = useState(false);
  const { toast } = useToast();
  const [searchParams] = useSearchParams();

  const inviteSent = searchParams.get('invited') === 'true';
  const clientCompanyName = searchParams.get('company');
  const newClientOrgId = searchParams.get('orgId') as string;

  const pageName = formatMessage(
    `activities.accountants.clientCreatedSuccessfullyScreen.${inviteSent ? 'withInvite' : 'withoutInvite'}.title`
  );

  setAnalyticsProperties({
    PageName: inviteSent ? 'client-added-an-invitation-sent' : 'client-added',
    Flow: 'subscription-accountant',
  });
  const { trackClick } = useClientCreatedSuccessfullyAnalytics();

  const handleRedirectToDashboard = async () => {
    try {
      trackClick('go-to-dashboard');
      setIsRedirectingToDashboard(true);
      await onDone({ firmClientOrgId: newClientOrgId, returnTo: RedirectTarget.ClientDashboard });
    } catch (error: unknown) {
      toast({
        type: 'error',
        title: formatMessage(
          'activities.accountants.activities.clientCreatedSuccessfullyScreen.redirect-to-client-dashboard.error'
        ),
      });
      Logger.log(`Error while trying redirect to melio dashboard`);
      setIsRedirectingToDashboard(false);
    } finally {
      // Should keep isLoading=true (location.href may be done before it actually redirects)
    }
  };

  const handleSwitchOrganization = async () => {
    try {
      trackClick('setup-client-account');
      setIsLoadingSwitchOrganization(true);
      await onDone({ firmClientOrgId: newClientOrgId, returnTo: RedirectTarget.SetupClient });
    } catch (error: unknown) {
      toast({
        type: 'error',
        title: formatMessage('activities.accountants.activities.clientCreatedSuccessfullyScreen.switch-org.error'),
      });
      Logger.log(`Error while trying switch to client organization ${(error as Error).message}`);
    } finally {
      setIsLoadingSwitchOrganization(false);
    }
  };

  return (
    <FullScreenLayout
      title={pageName}
      content={formatMessage(
        `activities.accountants.clientCreatedSuccessfullyScreen.${inviteSent ? 'withInvite' : 'withoutInvite'}.content`,
        { clientCompanyName }
      )}
      illustration="success"
      actions={
        <>
          <Button
            isFullWidth={false}
            data-testid="client-created-actions-secondary"
            variant="secondary"
            label={formatMessage(
              'activities.accountants.clientCreatedSuccessfullyScreen.action.redirectToDashboard.label'
            )}
            isLoading={isRedirectingToDashboard}
            onClick={() => void handleRedirectToDashboard()}
          />
          <Button
            isFullWidth={false}
            data-testid="client-created-actions-primary"
            variant="primary"
            label={formatMessage(
              'activities.accountants.clientCreatedSuccessfullyScreen.action.setupClientAccount.label'
            )}
            isLoading={isLoadingSwitchOrganization}
            isDisabled={!newClientOrgId}
            onClick={() => void handleSwitchOrganization()}
          />
        </>
      }
    />
  );
});
