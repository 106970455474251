import Axios from 'axios';
import { MessageKey } from '@melio/platform-i18n';

export enum OnboardingErrorType {
  Unknown = 'unknown',
  Forbidden = 'forbidden',
  BadRequest = 'badRequest',
  NonCompliance = 'missingOrInvalidInformation',
}

export type OnboardingError = { type: OnboardingErrorType; code?: string; missingFields?: string[] };

export const errorToOnboardingError = (error: unknown): OnboardingError => {
  if (!error || typeof error !== 'object' || !Axios.isAxiosError(error)) {
    return { type: OnboardingErrorType.Unknown };
  }
  const errorCode = error.response?.data?.error?.errorCode;

  const getErrorType = () => {
    if (errorCode === 'NonCompliance') {
      return OnboardingErrorType.NonCompliance;
    }
    switch (error.response?.status) {
      case 400:
        return OnboardingErrorType.BadRequest;
      case 403:
        return OnboardingErrorType.Forbidden;
      default:
        return OnboardingErrorType.Unknown;
    }
  };

  const type = getErrorType();
  return {
    type: type,
    code: errorCode,
    missingFields: error.response?.data?.error?.data?.map((item: { field: string }) => item.field),
  };
};

export const onboardingErrorTypeToPathValues = (
  errorType: OnboardingErrorType,
): { title: MessageKey; message: MessageKey } => {
  switch (errorType) {
    case OnboardingErrorType.Forbidden:
      return {
        title: 'widgets.onboarding.errorPage.forbidden.title',
        message: 'widgets.onboarding.errorPage.forbidden.message',
      };
    case OnboardingErrorType.BadRequest:
      return {
        title: 'widgets.onboarding.errorPage.badRequest.title',
        message: 'widgets.onboarding.errorPage.badRequest.message',
      };
    case OnboardingErrorType.NonCompliance:
      return {
        title: 'widgets.onboarding.errorPage.nonCompliance.title',
        message: 'widgets.onboarding.errorPage.nonCompliance.message',
      };
    case OnboardingErrorType.Unknown:
    default:
      return {
        title: 'widgets.onboarding.errorPage.unknown.title',
        message: 'widgets.onboarding.errorPage.unknown.message',
      };
  }
};

export const joinWith = (array: string[], delimiter: string, lastDelimiter: string = delimiter) => {
  if (array.length === 0) return '';
  if (array.length === 1) return array[0];
  if (array.length === 2) return array.join(` ${lastDelimiter} `);

  const lastItem = array.pop();
  return `${array.join(`${delimiter} `)} ${lastDelimiter} ${lastItem}`;
};

export const validationKeyToFormatKey: Record<string, MessageKey> = {
  dateOfBirth: 'widgets.onboarding.errorPage.missingFields.keys.dateOfBirth',
  firstName: 'widgets.onboarding.errorPage.missingFields.keys.firstName',
  lastName: 'widgets.onboarding.errorPage.missingFields.keys.lastName',
  phone: 'widgets.onboarding.errorPage.missingFields.keys.phone',
  businessPhone: 'widgets.onboarding.errorPage.missingFields.keys.businessPhone',
  email: 'widgets.onboarding.errorPage.missingFields.keys.email',
  companyName: 'widgets.onboarding.errorPage.missingFields.keys.companyName',
  legalCompanyName: 'widgets.onboarding.errorPage.missingFields.keys.legalCompanyName',
  address: 'widgets.onboarding.errorPage.missingFields.keys.address',
  legalAddress: 'widgets.onboarding.errorPage.missingFields.keys.legalAddress',
  taxIdType: 'widgets.onboarding.errorPage.missingFields.keys.taxIdType',
  taxId: 'widgets.onboarding.errorPage.missingFields.keys.taxId',
  businessType: 'widgets.onboarding.errorPage.missingFields.keys.businessType',
  industry: 'widgets.onboarding.errorPage.missingFields.keys.industry',
};
