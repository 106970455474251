import { CurrencyTooltip } from '@melio/ap-widgets';
import { Group, Table, Text } from '@melio/penny';
import {
  InboxItemBillTypeEnum,
  InboxItemPaymentRequestTypeEnum,
  InboxItemScannedInvoiceTypeEnum,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { getBillsTabItemAmount, getBillsTabItemCurrency, isPartiallyPaid } from '../BillsTab.utils';
import { BillsTabItem } from '../types';

export function getAmountToDisplay(billsTabItem: BillsTabItem) {
  const { totalAmount, isPartiallyPaid, balance } = getBillsTabAmountCellInfo(billsTabItem);

  const shouldShowTotalAmountLabel = !isPartiallyPaid && totalAmount != null;
  const shouldShowBalanceLabel = isPartiallyPaid && balance != null;

  return shouldShowTotalAmountLabel ? totalAmount : shouldShowBalanceLabel ? balance : null;
}

export const BillsTabAmountCell = ({ billsTabItem }: { billsTabItem: BillsTabItem }) => {
  const billCurrency = getBillsTabItemCurrency(billsTabItem);
  const { formatMessage, formatCurrency } = useMelioIntl();
  const { totalAmount, isPartiallyPaid, balance } = getBillsTabAmountCellInfo(billsTabItem);

  const shouldShowTotalAmountLabel = !isPartiallyPaid && totalAmount != null;
  const shouldShowBalanceLabel = isPartiallyPaid && balance != null;
  const shouldShowTotalAmountHelperText = isPartiallyPaid && totalAmount != null;
  const amountToDisplay = getAmountToDisplay(billsTabItem);
  return (
    <Table.Cell data-testid="bill-amount-cell" textAlign="end">
      <CurrencyTooltip currency={billCurrency}>
        <Group variant="vertical" spacing="xxs" alignItems="flex-end">
          {shouldShowTotalAmountLabel || shouldShowBalanceLabel ? (
            <Text textStyle="body3Semi">
              {amountToDisplay != null ? formatCurrency(amountToDisplay, billCurrency) : ''}
            </Text>
          ) : null}
          {shouldShowTotalAmountHelperText ? (
            <Text textStyle="body4" color="global.neutral.800">
              {formatMessage('activities.payDashboard.billsTab.table.cells.amount.helperText', {
                amount: formatCurrency(totalAmount, billCurrency),
              })}
            </Text>
          ) : null}
        </Group>
      </CurrencyTooltip>
    </Table.Cell>
  );
};

export const getBillsTabAmountCellInfo = (
  billsTabItem: BillsTabItem
): {
  totalAmount: number | null;
  isPartiallyPaid: boolean;
  balance?: number;
  currency?: string;
} => {
  switch (billsTabItem.type) {
    case InboxItemBillTypeEnum.Bill:
      return {
        totalAmount: getBillsTabItemAmount(billsTabItem),
        isPartiallyPaid: isPartiallyPaid(billsTabItem),
        balance: billsTabItem.payload.balance,
        currency: billsTabItem.payload.currency ?? '',
      };
    case InboxItemScannedInvoiceTypeEnum.ScannedInvoice:
      return {
        totalAmount: getBillsTabItemAmount(billsTabItem),
        isPartiallyPaid: false,
      };
    case InboxItemPaymentRequestTypeEnum.PaymentRequest:
      return {
        totalAmount: getBillsTabItemAmount(billsTabItem),
        isPartiallyPaid: false, //TODO: add the unpaid amount when we will support partial payment for payment request
      };
  }
};
