import React from 'react';
import { IconProps } from '@chakra-ui/react';
import { useAnalytics } from '@melio/platform-analytics';

import { BackToPartnerButton } from '@/cl/components/BackToPartnerButton/BackToPartnerButton.component';
import { useRouter } from '@/hooks/router.hooks';
import { usePlatformIntl } from '@/translations/Intl';

type BackToPartnerProps = {
  BackIcon: (props: IconProps) => JSX.Element;
};
export const BackToPartner = ({ BackIcon }: BackToPartnerProps) => {
  const { formatMessage } = usePlatformIntl();
  const { generateBackToPartnerUrl } = useRouter();
  const { track } = useAnalytics();

  return (
    <BackToPartnerButton
      BackIcon={BackIcon}
      onClick={() => track('ExitFromPlatformBackButton', 'Chose')}
      buttonText={formatMessage('app.navigation.back')}
      href={generateBackToPartnerUrl()}
    />
  );
};
