import { Box } from '@chakra-ui/react';
import { UnilateralForm, UnilateralFormFields } from '@melio/ap-widgets';
import { Group, useFormSubmissionController } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';
import { useDescriptionText } from './utils';

export type RequestVendorUnilateralDetailsProps = {
  isSaving: boolean;
  isLoading?: boolean;
  vendorName: Vendor['name'];
  defaultValues?: Partial<UnilateralFormFields>;
  onBack: VoidFunction;
  onClose: VoidFunction;
  onDone: (
    data: UnilateralFormFields,
    event?: React.MouseEvent<HTMLButtonElement>,
    target?: 'continue' | 'close'
  ) => void;
};

export const RequestVendorUnilateralDetailsScreen: React.VFC<RequestVendorUnilateralDetailsProps> = forwardRef<
  RequestVendorUnilateralDetailsProps,
  'div'
>(({ isSaving, isLoading, vendorName, defaultValues, onBack, onClose, onDone, ...props }, ref) => {
  const { formatMessage } = useMelioIntl();
  const { onSubmissionStateChange, cancelButtonProps, submitButtonProps, handleSubmit } =
    useFormSubmissionController<UnilateralFormFields>();

  useAnalyticsView('RequestVendorDetails');

  return (
    <NewSinglePaymentStepLayout
      data-component="RequestVendorUnilateralDetailsActivity.RequestVendorUnilateralDetailsScreen"
      data-testid="request-vendor-details-activity-request-vendor-unilateral-details-screen"
      {...props}
      isLoading={isLoading}
      ref={ref}
      headerContent={
        <NewSinglePaymentStepLayout.Header>
          <NewSinglePaymentStepLayout.CloseButton onClick={onClose} />
          <NewSinglePaymentStepLayout.BackButton {...cancelButtonProps} onClick={onBack} />
        </NewSinglePaymentStepLayout.Header>
      }
      footerContent={
        <Group variant="vertical" spacing="m">
          <NewSinglePaymentStepLayout.Actions>
            <Box width="full">
              <Group variant="vertical" spacing="m">
                <NewSinglePaymentStepLayout.NextButton
                  {...submitButtonProps}
                  onClick={handleSubmit?.('continue')}
                  label={formatMessage(
                    'activities.requestVendorUnilateralDetails.screens.requestVendorUnilateralDetails.continue'
                  )}
                />
                <NewSinglePaymentStepLayout.SecondaryNextButton
                  {...submitButtonProps}
                  variant="tertiary"
                  onClick={handleSubmit?.('close')}
                  label={formatMessage(
                    'activities.requestVendorUnilateralDetails.screens.requestVendorUnilateralDetails.close'
                  )}
                />
              </Group>
            </Box>
          </NewSinglePaymentStepLayout.Actions>
        </Group>
      }
      bottomContent={
        <NewSinglePaymentStepLayout.BottomTextContent textAlign="start" textStyle="body4" color="global.neutral.800">
          {formatMessage('activities.requestVendorUnilateralDetails.screens.requestVendorUnilateralDetails.footNotes', {
            vendorName,
          })}
        </NewSinglePaymentStepLayout.BottomTextContent>
      }
    >
      <NewSinglePaymentStepLayout.Title>
        {formatMessage('activities.requestVendorUnilateralDetails.screens.requestVendorUnilateralDetails.title')}
      </NewSinglePaymentStepLayout.Title>

      <NewSinglePaymentStepLayout.Description>{useDescriptionText()}</NewSinglePaymentStepLayout.Description>

      <NewSinglePaymentStepLayout.Content>
        <Group variant="vertical" spacing="m">
          <UnilateralForm
            onSubmit={onDone}
            isSaving={isSaving}
            defaultValues={defaultValues}
            onSubmissionStateChange={onSubmissionStateChange}
          />
        </Group>
      </NewSinglePaymentStepLayout.Content>
    </NewSinglePaymentStepLayout>
  );
});

RequestVendorUnilateralDetailsScreen.displayName =
  'RequestVendorUnilateralDetailsActivity.RequestVendorUnilateralDetailsScreen';
