import { usePaymentSchedulingPreference } from '@melio/ap-domain';
import { Group, Text } from '@melio/penny';
import {
  DeliveryMethod,
  DeliveryPreference,
  DeliveryPreferenceType,
  FundingSource,
  PaymentFee,
  PaymentIntent,
  Vendor,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { usePaymentProcessingInfo } from '../../../../hooks/paymentProcessingInfo.hook';
import { useShowMemoToVendor } from '../../../../hooks/showMemoToVendor.hook';
import { DeliveryDateListItemByPayorWidget } from '../../../DeliveryDateListItemByPayor';
import { DeliveryMethodListItemByPayorWidget } from '../../../DeliveryMethodListItemByPayor';
import { DeliverySpeedListItemByPayorWidget } from '../../../DeliverySpeedListItemByPayor';
import { PaymentReviewLineItem } from '../../../PaymentReviewLineItem';
import { RecurringPaymentProps } from '../../types';

export type ReviewAndConfirmVendorReceiveSectionProps = {
  vendorName: Vendor['name'];
  countryDisplayName?: string;
  deliveryPreference: DeliveryPreference;
  paymentFees: PaymentFee[];
  deliveryMethod: DeliveryMethod;
  fundingSource: FundingSource;
  memoToVendor?: PaymentIntent['noteToVendor'];
  onEditDeliveryMethod?: VoidFunction;
  onEditMemoToVendor?: VoidFunction;
  onEditDeliveryDate?: VoidFunction;
  updatedFields?: string[];
  dueDate?: Date;
  enableLateTag: boolean;
  hideMemoSection?: boolean;
  recurringPayment?: RecurringPaymentProps;
};

export const ReviewAndConfirmVendorReceiveSection = forwardRef<ReviewAndConfirmVendorReceiveSectionProps>(
  (
    {
      deliveryMethod,
      fundingSource,
      memoToVendor,
      vendorName,
      countryDisplayName,
      paymentFees,
      deliveryPreference,
      onEditDeliveryMethod,
      onEditMemoToVendor,
      onEditDeliveryDate,
      updatedFields,
      dueDate,
      enableLateTag,
      hideMemoSection,
      recurringPayment,
      ...props
    },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();
    const { isByDeliveryDate } = usePaymentSchedulingPreference();
    const { isPaymentProcessedByCapitalOne } = usePaymentProcessingInfo();
    const { shouldDisplayMemoToVendor } = useShowMemoToVendor();

    const isRtpDeliveryPreferenceType = deliveryPreference.type === DeliveryPreferenceType.Rtp;
    const isPaymentProcessedInternally =
      !isPaymentProcessedByCapitalOne(deliveryMethod?.type) && !isRtpDeliveryPreferenceType;
    const showMemoToVendor =
      isPaymentProcessedInternally && !hideMemoSection && shouldDisplayMemoToVendor(deliveryMethod);
    const isVirtualAccount = deliveryMethod.type === 'virtual-account';
    const onEdit = isByDeliveryDate ? onEditDeliveryDate : isVirtualAccount ? onEditDeliveryMethod : null;

    return (
      <Group variant="vertical" spacing="s" data-component="ReviewAndConfirmVendorReceiveSection" ref={ref} {...props}>
        <Text textStyle="body4SemiUpper" color="global.neutral.900">
          {formatMessage('widgets.reviewAndConfirm.vendorReceiveSection.title')}
        </Text>
        <Group variant="vertical" spacing="m" hasDivider>
          <Group variant="vertical">
            {deliveryMethod.type !== 'virtual-account' && (
              <DeliveryMethodListItemByPayorWidget
                countryDisplayName={countryDisplayName}
                deliveryMethod={deliveryMethod}
                deliveryPreference={deliveryPreference}
                vendorName={vendorName}
                onEdit={onEditDeliveryMethod}
                showUpdatedBadge={updatedFields?.includes('deliveryMethod')}
              />
            )}
            {isPaymentProcessedInternally && (
              <DeliveryDateListItemByPayorWidget
                {...(onEdit && {
                  onEdit,
                })}
                paymentFees={paymentFees}
                deliveryPreference={deliveryPreference}
                fundingSource={fundingSource}
                updatedFields={updatedFields}
                dueDate={dueDate}
                enableLateTag={enableLateTag}
                recurringPayment={recurringPayment}
              />
            )}
            {isRtpDeliveryPreferenceType && <DeliverySpeedListItemByPayorWidget />}
          </Group>
          {showMemoToVendor && (
            <PaymentReviewLineItem
              data-testid="memo-to-vendor-line-item"
              labelProps={{ label: formatMessage('widgets.reviewAndConfirm.vendorReceiveSection.memoToVendor.label') }}
              mainLabelProps={{
                label: memoToVendor ?? undefined,
                placeholder: formatMessage('widgets.reviewAndConfirm.vendorReceiveSection.memoToVendor.empty'),
              }}
              onEdit={onEditMemoToVendor}
              editText={formatMessage('widgets.reviewAndConfirm.vendorReceiveSection.memoToVendor.edit')}
              editTestId="review-and-confirm-memo-to-vendor-edit-button"
              contentTestId="review-and-confirm-memo-to-vendor"
            />
          )}
        </Group>
      </Group>
    );
  }
);

ReviewAndConfirmVendorReceiveSection.displayName = 'ReviewAndConfirmVendorReceiveSection';
