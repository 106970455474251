import { useState } from 'react';
import { useBillingFeeData } from '@melio/ap-activities/src/components/settings/BillingFee/useBillingFeeData';
import { ActionsDropdownMenuItemProps } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';

import { WithLoading } from '@/hoc/withLoading.hoc';
import { useRouter } from '@/hooks/router.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { EmptyMethodCard } from '../common/EmptyMethodCard';
import { RemoveBillingFeeSetting } from '../common/RemoveBillingFeeSettingModal';
import { SelectedMethodCard } from '../common/SelectedMethodCard';

export const SmbSelectedMethodCard = () => {
  const { formatMessage } = usePlatformIntl();
  const { goToAddBillingMethod } = useRouter();
  const { createTrackHandler } = useAnalytics();
  const { fundingSources, currentBillingFeeSetting, currentFundingSourceId, isLoading, isMethodAssignedByFirm } =
    useBillingFeeData();
  const currentFundingSource = fundingSources.find((fs) => fs.id === currentFundingSourceId);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState<boolean>(false);
  const shouldShowSelectedView = currentBillingFeeSetting?.fundingSourceId;

  const handleRemoveMethod = () => {
    setIsRemoveModalOpen(true);
  };

  const trackAndHandleClick = createTrackHandler<{
    Cta: 'change-billing-method' | 'remove-billing-method';
  }>('Settings', 'Click');

  const analyticsFields = { Intent: 'billing-method', EntryPoint: 'settings-page', PageName: 'billing' };
  const actions: ActionsDropdownMenuItemProps[] = [
    {
      label: formatMessage('widgets.billingSettings.paymentMethod.card.actions.change'),
      dataTestId: 'change-billing-method',
      onClick: () => trackAndHandleClick({ Cta: 'change-billing-method', ...analyticsFields }, goToAddBillingMethod),
    },
    {
      label: formatMessage('widgets.billingSettings.paymentMethod.card.actions.remove'),
      dataTestId: 'remove-billing-method',
      onClick: () => trackAndHandleClick({ Cta: 'remove-billing-method', ...analyticsFields }, handleRemoveMethod),
      variant: 'critical',
    },
  ];

  return (
    <WithLoading isLoading={isLoading}>
      {shouldShowSelectedView ? (
        <>
          <SelectedMethodCard
            actions={actions}
            fundingSource={currentFundingSource}
            isMethodAssignedByFirm={isMethodAssignedByFirm}
          />
          <RemoveBillingFeeSetting
            isOpen={isRemoveModalOpen}
            billingSettingId={currentBillingFeeSetting?.id}
            setIsOpen={setIsRemoveModalOpen}
          />
        </>
      ) : (
        <EmptyMethodCard />
      )}
    </WithLoading>
  );
};
