import { useEffect } from 'react';

import { FocusEvents, offFocusEvent, onFocusEvent } from './focus-event-emitter';

export type UseFocusOnEventParams = {
  event?: FocusEvents;
  ref: React.RefObject<HTMLElement>;
};

export const useFocusOnEvent = ({ event, ref }: UseFocusOnEventParams) => {
  useEffect(() => {
    const focusElement = () => {
      if (ref && event) {
        setTimeout(() => {
          ref.current?.focus();
        }, 0);
      }
    };

    if (event) {
      onFocusEvent(event, focusElement);
    }
    return function cleanup() {
      if (event) {
        offFocusEvent(event, focusElement);
      }
    };
  }, [event, ref]);
};
