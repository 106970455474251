import { DeliveryMethod } from '@melio/platform-api';
import { useConfig, useMelioIntl } from '@melio/platform-provider';

import { getDeliveryMethodIconKey } from '../../functions';
import { getDeliveryMethodDisplayText } from './useGetDeliveryMethodDisplayText';

export const useDeliveryMethodsSelectOptions = (deliveryMethods: DeliveryMethod[], vendorName: string) => {
  const { formatMessage } = useMelioIntl();
  const { settings } = useConfig();

  return deliveryMethods.map((deliveryMethod) => ({
    value: deliveryMethod.id,
    testId: deliveryMethod.id,
    label: getDeliveryMethodDisplayText(deliveryMethod, vendorName, formatMessage),
    icon: getDeliveryMethodIconKey(deliveryMethod, settings.icons.deliveryMethod),
  }));
};
