import { Box } from '@chakra-ui/react';
import { FormWidgetProps } from '@melio/ap-widgets';
import {
  Button,
  Container,
  Form,
  Group,
  Loader,
  SectionBanner,
  useFormSubmissionController,
  useMelioForm,
} from '@melio/penny';
import { Collaborator, useApprovalWorkflowRoles } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { defaults } from 'lodash';
import { object, string, StringSchema } from 'yup';

import { RoleUniqueNameEnum, RoleUniqueNames } from '../../../../types';
import { EmailInput } from './fields/EmailInput';
import { FirstNameInput } from './fields/FirstNameInput';
import { FormTitle } from './fields/FormTitle';
import { LastNameInput } from './fields/LastNameInput';
import { RoleRadioGroup } from './fields/RoleRadioGroup';

export type EditCollaboratorFormFields = {
  firstName: string;
  lastName: string;
  email: string;
  roleUniqueName: RoleUniqueNames;
};
type Props = Omit<FormWidgetProps<EditCollaboratorFormFields>, 'onSubmissionStateChange'> & {
  availableRoleOptions: RoleUniqueNames[];
  collaborator?: Collaborator | null;
  hasApprovalWorkflow?: boolean;
};

const schema = object().shape({
  firstName: string().required(),
  lastName: string().required(),
  email: string().required(),
  roleUniqueName: new StringSchema<RoleUniqueNames>().oneOf(Object.values(RoleUniqueNameEnum)).required(),
});

export const EditCollaboratorForm = ({
  onSubmit,
  isSaving,
  defaultValues,
  availableRoleOptions,
  collaborator,
  hasApprovalWorkflow = false,
}: Props) => {
  const { formatMessage } = useMelioIntl();
  const { onSubmissionStateChange, submitButtonProps } = useFormSubmissionController<EditCollaboratorFormFields>();
  const { formProps, registerField, watch } = useMelioForm<EditCollaboratorFormFields>({
    onSubmit,
    schema,
    defaultValues: defaults(defaultValues, {
      firstName: '',
      lastName: '',
      email: '',
      roleUniqueName: availableRoleOptions[0],
    }),
    isSaving,
    onSubmissionStateChange,
  });

  const { data, isLoading } = useApprovalWorkflowRoles({ enabled: !!collaborator && hasApprovalWorkflow });

  const roleUniqueNameIntent = watch('roleUniqueName');
  const renderApprovalSection = () => {
    if (isLoading) {
      return (
        <Form.ContentBox colSpan={2}>
          <Container paddingY="m">
            <Loader />
          </Container>
        </Form.ContentBox>
      );
    }
    return collaborator && data ? (
      <ApprovalSectionBanner
        wasApprover={collaborator.approvalActions.canApprovePayments}
        isApprover={data.paymentApprovalRoles.approvers.includes(roleUniqueNameIntent)}
      />
    ) : null;
  };

  return (
    <Group spacing="xl" variant="vertical">
      <Box>
        <FormTitle />
        <Form columns={2} {...formProps} isDisabled={formProps.isDisabled}>
          <FirstNameInput isReadOnly {...registerField('firstName')} aria-label="first name" />
          <LastNameInput isReadOnly {...registerField('lastName')} aria-label="last name" />
          <EmailInput colSpan={2} isReadOnly {...registerField('email')} aria-label="email" />
          <RoleRadioGroup
            colSpan={2}
            title={formatMessage('activities.manageCollaborators.screens.userDetailsForm.fields.roleUniqueName.label')}
            availableRoleOptions={availableRoleOptions}
            selectedRoleUniqueName={roleUniqueNameIntent}
            isDisabled={isSaving}
            {...registerField('roleUniqueName')}
          />
        </Form>
      </Box>
      {hasApprovalWorkflow ? renderApprovalSection() : null}
      <Button
        variant="primary"
        isFullWidth
        data-testid="edit-form-submit-button"
        label={formatMessage('activities.manageCollaborators.screens.editCollaboratorModal.submitBtn')}
        {...submitButtonProps}
        isDisabled={isLoading || isSaving}
      />
    </Group>
  );
};

const ApprovalSectionBanner = ({ wasApprover, isApprover }: { wasApprover: boolean; isApprover: boolean }) => {
  const { formatMessage } = useMelioIntl();

  const wasUpgraded = isApprover && !wasApprover;
  const wasDowngraded = !isApprover && wasApprover;
  if (wasUpgraded) {
    return (
      <Group spacing="l">
        <SectionBanner
          data-testid="upgrade-user-approval"
          variant="neutral"
          hideIcon
          description={formatMessage(
            'activities.manageCollaborators.screens.editCollaboratorModal.hasApproval.upgrade'
          )}
        />
      </Group>
    );
  }

  if (wasDowngraded) {
    return (
      <SectionBanner
        data-testid="downgrade-user-approval"
        variant="neutral"
        hideIcon
        description={formatMessage(
          'activities.manageCollaborators.screens.editCollaboratorModal.hasApproval.downgrade'
        )}
      />
    );
  }

  return null;
};
