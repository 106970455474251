import { PartnerName } from '@melio/platform-api';

import { partnerHostToPartnerName } from '@/partnersConfig.types';

export const generateAccountUrl = ({ accountId, partnerName }: { accountId: string; partnerName: PartnerName }) =>
  `${window.location.protocol}//${window.location.host}${
    partnerHostToPartnerName[window.location.hostname] === partnerName ? '' : `/${partnerName}`
  }/auth/demo/${accountId}`;

export const generateAccountOpenIdUrl = ({ accountId, partnerName }: { accountId: string; partnerName: PartnerName }) =>
  `${window.location.protocol}//${window.location.host}${
    partnerHostToPartnerName[window.location.hostname] === partnerName ? '' : `/${partnerName}`
  }/auth/demo-openid/${accountId}`;
