import { useEffect } from 'react';
import { Loader } from '@melio/penny';
import { useNavigate } from '@melio/platform-utils';

import { useRouter } from '@/hooks/router.hooks';

export const ExternalEntriesFallbackError = () => {
  const { generateNPEDashboardLink } = useRouter();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(generateNPEDashboardLink());
    window.parent.postMessage({ done: 'true' }, '*');
  }, [generateNPEDashboardLink, navigate]);

  return <Loader />;
};
