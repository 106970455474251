import { AddBillFormWidgetFields, SinglePaymentFields } from '@melio/ap-widgets';
import { Money } from '@melio/money';
import { useAnalytics } from '@melio/platform-analytics';
import { Bill, FileInfo, FileOCRData, useFile } from '@melio/platform-api';
import { getDollarsFromCents } from '@melio/platform-utils';
import { useCallback, useState } from 'react';

import { AddBillV2DFormInitialValues, AddBillV2FormLineItem, BillLineItemType } from './AddBillV2Form/types';

export type CreateBillFormTarget = 'continue' | 'close' | 'markAsPaid';

export const useTrackBillCreation = () => {
  const { track, trackMarketing } = useAnalytics();

  const trackBillCreation = (bill: Pick<Bill, 'id' | 'amount'>, target?: CreateBillFormTarget) => {
    const action = target === 'close' ? 'SavedAndClosed' : target === 'continue' ? 'SavedAndContinued' : 'Saved';
    const properties = {
      status: 'Succeeded',
      BillId: bill.id,
      Amount: getDollarsFromCents(bill.amount || 0),
      PaymentFrequency: 'One Time',
      RecurringPaymentEndBy: null,
      RecurringPaymentEndValue: null,
    };
    track('AddBillInfo', action, properties);
    trackMarketing('bill-create_save-success', properties);
  };

  return { trackBillCreation };
};

/**
 * Gets the difference between bill amount and line items sum
 * @param values original values
 * @returns 0 if the amounts are equal, positive if bill amount is greater than line items sum, negative if bill amount is less than line items sum
 */
export const isSinglePaymentFields = (fields?: AddBillFormWidgetFields): fields is SinglePaymentFields =>
  !!(fields && (fields as SinglePaymentFields)?.dueDate);

export function diffAmounts(values: AddBillV2DFormInitialValues): number {
  const { amount, lineItems } = values;
  // if no line items, then no mismatch
  if (!lineItems?.length) {
    return 0;
  }
  const billAmount = Money.fromNaturalUnit(amount ?? 0, 'USD');
  const lineItemsSum = Money.fromNaturalUnit(0, 'USD', {
    accepts: 'natural',
  }).add(lineItems.map(({ amount }) => amount ?? 0));
  return billAmount.sub(lineItemsSum).toNaturalUnit();
}

export const convertOcrDataToBillInitialValues = (
  fileOcrData: FileOCRData
): { values: AddBillV2DFormInitialValues; mismatchedAmount: number } => {
  const lineItems = fileOcrData.lines?.map(
    (line) =>
      ({
        unitPrice: line.unitPrice?.toString(),
        externalItemId: line.externalItemId,
        quantity: line.quantity,
        description: line.description,
        type: line.type,
        order: line.order,
        amount: line.amount?.toString(),
      } as AddBillV2FormLineItem)
  );
  const billFromOcr = {
    amount: fileOcrData.totalAmount?.toString(),
    invoiceNumber: fileOcrData.invoiceNumber,
    invoiceDate: fileOcrData.invoiceDate,
    vendorId: fileOcrData.vendorId ?? undefined,
    vendorName: fileOcrData.vendorName,
    currency: fileOcrData.currency,
    // eslint-disable-next-line no-restricted-syntax
    dueDate: fileOcrData.dueDate ? new Date(fileOcrData.dueDate) : undefined,
    lineItems,
  } as AddBillV2DFormInitialValues;
  const mismatchedAmount = diffAmounts(billFromOcr);
  return { values: billFromOcr, mismatchedAmount };
};

export function getInitialValueLineItemWithAmount(amount: number, description = '') {
  const lineAmount = String(Number(amount ?? ''));
  return {
    type: BillLineItemType.CATEGORY,
    description,
    amount: lineAmount,
  };
}

type Props = {
  billFileInfo?: FileInfo | null;
  onSuccess?: (billDetails: AddBillV2DFormInitialValues, mismatchedAmount?: number) => void;
  onFail?: (error: PlatformError) => void;
};

export type BillFileOcrData = { values: AddBillV2DFormInitialValues; mismatchedAmount: number } | undefined;

export const useGetBillFileOcrData = ({ billFileInfo, onFail }: Props) => {
  const { getOCRData } = useFile({ id: billFileInfo?.id, enabled: false });

  const [isLoading, setIsLoading] = useState(false);
  const [billFileOcrData, setBillFileOcrData] = useState<BillFileOcrData>();

  const getBillFileOcrData = useCallback(
    async (id?: string): Promise<BillFileOcrData> => {
      setIsLoading(true);

      try {
        const fileOcrData = await getOCRData(id);
        const { values, mismatchedAmount } = convertOcrDataToBillInitialValues(fileOcrData);
        setBillFileOcrData({ values, mismatchedAmount });
        return { values, mismatchedAmount };
      } catch (error) {
        onFail?.(error as PlatformError);
        return undefined;
      } finally {
        setIsLoading(false);
      }
    },
    [onFail, getOCRData]
  );

  return { isLoading, getBillFileOcrData, billFileOcrData };
};
