import { AccountAddress } from '@melio/platform-api';

import { isPOBox } from '../address-utils';
import { bannedStatesForInternationalPayments } from './constants';

const isStateProhibited = (state?: string | null): boolean =>
  bannedStatesForInternationalPayments.includes(state?.toUpperCase() || '');

export const isAddressValid = (address?: AccountAddress) =>
  !isPOBox(address?.line1) && !isStateProhibited(address?.state);
