import { useCollectionApi, UseCollectionApiProps, useMelioQueryClient } from '@melio/api-client';
import { PaymentsApiClient } from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useMutation } from 'react-query';

export type UsePaymentsETADatesProps = UseCollectionApiProps<typeof PaymentsApiClient.getPaymentEtaDates> & {
  paymentIds: string[];
};

export const usePaymentsETADates = ({ paymentIds, ...props }: UsePaymentsETADatesProps) => {
  const query = useCollectionApi({
    queryKey: ['PaymentsApi', paymentIds, 'ETADates'],
    queryFn: () => PaymentsApiClient.getPaymentEtaDates(paymentIds),
    enabled: !!paymentIds?.length,
    ...props,
  });

  const queryClient = useMelioQueryClient();

  const getPaymentsETADatesMutation = useMutation(
    query.queryKey,
    async ({ paymentIds }: { paymentIds: string[] }) =>
      (await PaymentsApiClient.getPaymentEtaDates(paymentIds)).data.data,
    { onSuccess: () => void queryClient.invalidateQueries(query.queryKey[0], 'collection') }
  );

  return { ...query, getPaymentsETADatesMutation: getPaymentsETADatesMutation.mutateAsync };
};
