import { Group, Icon, Link, Modal, Text, useBreakpointValue, Video } from '@melio/penny';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';

import { useLearnMoreLink } from '../../../hooks';
import { useGracePeriodIntroductionListItems } from './useGracePeriodIntroductionListItems';

type GracePeriodModalScreenProps = {
  claimOffer: () => void;
  onDismiss: () => void;
  canClaimOffer: boolean;
};

export const GracePeriodIntroductionModalScreen = withAnalyticsContext<GracePeriodModalScreenProps>(
  ({ onDismiss, claimOffer, canClaimOffer, setAnalyticsProperties }) => {
    const { formatMessage } = useMelioIntl();
    const isMobile = useBreakpointValue({ xs: true, s: false });
    const isMobileOrTablet = useBreakpointValue({ s: true, m: false });

    const learnMoreLink = useLearnMoreLink();
    const { whatsThisMeansList } = useGracePeriodIntroductionListItems();

    setAnalyticsProperties({
      PageName: 'introducing-premium-paid-plans',
      Flow: 'existing-user-transition',
      Intent: 'subscription-introduction',
    });

    const { track } = useAnalytics();
    useAnalyticsView('Organization', true, true);

    const onClose = () => {
      track('Organization', 'Click', {
        Cta: 'exit',
      });
      onDismiss();
    };

    const onGotIt = () => {
      track('Organization', 'Click', {
        Cta: 'got-it',
      });
      onDismiss();
    };

    const onClaimOffer = () => {
      track('Organization', 'Click', {
        Cta: 'claim-offer',
      });
      onDismiss();
      claimOffer();
    };

    const onLearnMore = () => {
      track('Organization', 'Click', {
        Cta: 'learn-more-about-melio-plans',
      });
    };

    const { photoOrder, textOrder } = isMobileOrTablet
      ? { photoOrder: 1, textOrder: 2 }
      : { photoOrder: 2, textOrder: 1 };

    return (
      <Modal
        isOpen
        size="medium"
        header={formatMessage('activities.subscription.gracePeriodIntroductionModal.header')}
        onClose={onClose}
        primaryButton={{
          label: formatMessage('activities.subscription.gracePeriodIntroductionModal.primaryButton.label'),
          variant: 'primary',
          onClick: onGotIt,
        }}
        {...(canClaimOffer && {
          secondaryButton: {
            label: formatMessage('activities.subscription.gracePeriodIntroductionModal.secondaryButton.label'),
            variant: 'secondary',
            onClick: onClaimOffer,
          },
        })}
        data-testid="grace-period-introduction-modal"
      >
        <Group variant={isMobileOrTablet ? 'vertical' : 'horizontal'}>
          <Group.Item basis="50%" order={textOrder}>
            <Group variant="vertical">
              <Text>{formatMessage('activities.subscription.gracePeriodIntroductionModal.description')}</Text>
              <Text textStyle="body3">
                {formatMessage('activities.subscription.gracePeriodIntroductionModal.whatsThisMeansList.header')}
              </Text>
              {whatsThisMeansList.map(({ label, iconType }) => (
                <Group key={label}>
                  <Icon type={iconType} />
                  <Text textStyle="body3">{label}</Text>
                </Group>
              ))}
              <Link
                onClick={onLearnMore}
                href={learnMoreLink}
                color="secondary"
                label={formatMessage('activities.subscription.gracePeriodIntroductionModal.learnMoreLink')}
                newTab
              />
            </Group>
          </Group.Item>
          <Group.Item basis="50%" order={photoOrder} alignSelf="center">
            <Video
              src="https://s3.amazonaws.com/platform-static.meliopayments.com/assets/melio/illustrations/vip-welcome.mp4"
              hideControls
              autoPlay
              loop
              height={isMobile ? '320px' : '360px'}
              width="100%"
            />
          </Group.Item>
        </Group>
      </Modal>
    );
  }
);
