import { Group, Illustration, Link, Text } from '@melio/penny';
import { sanitizeStringForAnalytics, useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { BankAccountDeliveryMethod, FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';
import { useReconciliationModal } from '../../../FundingSourceSelection';
import { ReconciliationModalActivity } from '../../../Reconciliation';

export type BankAccountCreatedScreenProps = {
  onDone: VoidFunction;
  onClose: VoidFunction;
  isReceivingMethodFlow?: boolean;
  fundingSource?: FundingSource;
  deliveryMethod?: BankAccountDeliveryMethod;
  isLoading?: boolean;
};

export const BankAccountCreatedScreen = forwardRef<BankAccountCreatedScreenProps, 'div'>(
  ({ onDone, onClose, fundingSource, deliveryMethod, isReceivingMethodFlow, isLoading = false, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const { track } = useAnalytics();
    const { links } = useConfig();

    const {
      shouldRunReconciliation,
      shouldShowReconciliationModal,
      onCloseReconciliationFlow,
      onDoneReconciliationFlow,
      runReconciliationFlow,
    } = useReconciliationModal();

    const isReconciliationNeeded = shouldRunReconciliation(fundingSource);

    let isAccountVerified = false;
    if (fundingSource) {
      isAccountVerified = fundingSource.isVerified;
    } else if (deliveryMethod) {
      if (deliveryMethod.details?.verifiedStatus === 'plaid-verified') {
        isAccountVerified = true;
      } else if (deliveryMethod.details?.verifiedStatus === 'micro-deposit-verified') {
        isAccountVerified = true;
      }
    }

    const messageKeySuffix = isAccountVerified ? '' : '.unverified';
    const ctaBtnText = formatMessage(
      `activities.addBankAccountPlaid.screens.bankAccountCreated.continue${messageKeySuffix}`
    );
    const pageTitle = formatMessage(
      `activities.addBankAccountPlaid.screens.bankAccountCreated.title${messageKeySuffix}`
    );

    useAnalyticsView('PaymentMethod', !isLoading && !isReceivingMethodFlow, true, {
      PageName: sanitizeStringForAnalytics(pageTitle),
      PaymentMethodType: fundingSource?.type,
      PaymentMethodId: fundingSource?.id,
    });

    const onDoneReconciliation = () => {
      onDoneReconciliationFlow();
      onDone();
    };

    return (
      <NewSinglePaymentStepLayout
        data-component="AddBankAccountPlaidActivity.BankAccountCreatedScreen"
        data-testid="add-bank-account-plaid-activity-bank-account-created-screen"
        {...props}
        ref={ref}
        isLoading={isLoading}
        footerContent={
          <Group variant="vertical" spacing="s" alignItems="center">
            <NewSinglePaymentStepLayout.Actions>
              <NewSinglePaymentStepLayout.NextButton
                label={ctaBtnText}
                isDisabled={shouldShowReconciliationModal}
                onClick={() => {
                  if (!isReceivingMethodFlow) {
                    track('PaymentMethod', 'Click', {
                      Cta: ctaBtnText,
                      PageName: sanitizeStringForAnalytics(pageTitle),
                    });
                  }
                  if (isReconciliationNeeded) {
                    runReconciliationFlow();
                  } else {
                    onDone();
                  }
                }}
              />
            </NewSinglePaymentStepLayout.Actions>
            {!isAccountVerified && links?.verifyBankAccountWithMicroDeposit && (
              <Text textStyle="body3" data-testid="bank-account-created-helper-link">
                {formatMessage('activities.addBankAccountPlaid.screens.bankAccountCreated.title.unverified.footer', {
                  link: (
                    <Link
                      href={links.verifyBankAccountWithMicroDeposit}
                      label={formatMessage(
                        'activities.addBankAccountPlaid.screens.bankAccountCreated.title.unverified.footer.link'
                      )}
                      color="secondary"
                      newTab
                    />
                  ),
                })}
              </Text>
            )}
          </Group>
        }
      >
        <Group justifyContent="center">
          <Illustration type={isAccountVerified ? 'bank-success' : 'pending'} size="large" />
        </Group>
        <NewSinglePaymentStepLayout.Title>
          {formatMessage(`activities.addBankAccountPlaid.screens.bankAccountCreated.title${messageKeySuffix}`)}
        </NewSinglePaymentStepLayout.Title>
        <NewSinglePaymentStepLayout.Description>
          {isReceivingMethodFlow
            ? formatMessage(
                `activities.addBankAccountPlaid.screens.bankAccountCreated.description.receivingMethod${messageKeySuffix}`
              )
            : formatMessage(
                `activities.addBankAccountPlaid.screens.bankAccountCreated.description.fundingSource${messageKeySuffix}`
              )}
        </NewSinglePaymentStepLayout.Description>

        {!!fundingSource && isReconciliationNeeded && (
          <ReconciliationModalActivity
            isOpen={shouldShowReconciliationModal}
            onClose={onCloseReconciliationFlow(onClose)}
            onDone={onDoneReconciliation}
            selectedFundingSourceId={fundingSource.id}
            isNewFundingSource
          />
        )}
      </NewSinglePaymentStepLayout>
    );
  }
);

BankAccountCreatedScreen.displayName = 'AddBankAccountPlaidActivity.BankAccountCreatedScreen';
