import { Payment } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';
import { convertDateToMidDay } from '@melio/platform-utils';

export function useDefaultDeliveryMethod(payment: Payment | undefined) {
  const {
    settings: {
      payment: {
        editing: { defaultToManagedAccountPreferredDeliveryMethod },
      },
    },
  } = useConfig();

  if (!payment) {
    return;
  }

  if (
    defaultToManagedAccountPreferredDeliveryMethod &&
    payment.deliveryMethod?.type === 'managed-account' &&
    !payment.deliveryMethod.details.isPreferred
  ) {
    return { deliveryMethod: undefined, deliveryDate: undefined };
  }

  return {
    deliveryMethod: payment.deliveryMethod,
    deliveryDate: payment?.estimatedDelivery ? convertDateToMidDay(payment?.estimatedDelivery) : undefined,
  };
}
