import { useVendorNameConstraints } from '@melio/ap-domain';
import { Form, Group, SectionBanner } from '@melio/penny';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { useEffect, useState } from 'react';
import { SchemaOf, string } from 'yup';

import { isValidOptionalPhone } from '../../../../../../../utils';
import { useVendorForm } from '../../../../../../useVendorForm';
import { useCompanyNameSchema, validateInvalidChars } from '../../../../../../VendorDetailsBaseSchema';
import { AddVendorFormFields, AddVendorFormWidgetProps } from '../../../../types';
import { CompanyField } from '../../../CompanyField';
import { getCreateVendorPayload } from './utils';

type AddUnmanagedVendorMinimalFormFields = Pick<
  AddVendorFormFields,
  'companyName' | 'fullName' | 'email' | 'phone' | 'accountNumber'
>;

const useSchema = ({
  showCompanyField,
  isManaged,
  isEmailRequired,
  emailRequiredMessage,
}: {
  showCompanyField: boolean;
  isManaged: boolean;
  isEmailRequired?: boolean;
  emailRequiredMessage?: string;
}) => {
  const { formatMessage } = useMelioIntl();

  const companyNameSchema = useCompanyNameSchema({ showCompanyField, isManaged });

  const emailSchema = string().nullable().email(formatMessage('widgets.vendors.email.validation.format'));

  return companyNameSchema.shape({
    fullName: string().nullable(),
    email: isEmailRequired
      ? emailSchema.required(emailRequiredMessage ?? formatMessage('widgets.vendors.email.validation.required'))
      : emailSchema,
    phone: string()
      .nullable()
      .test('valid-phone', formatMessage('widgets.vendors.phone.validation.format'), isValidOptionalPhone),
    accountNumber: string()
      .max(32, formatMessage('widgets.vendors.accountNumber.validation.maxLength'))
      .test(
        'validate-invalid-chars',
        '',
        validateInvalidChars(/[!"#$%&\-0-9A-Za-z]*/, (invalidChars) =>
          formatMessage('widgets.vendors.accountNumber.validation.format', {
            invalidChars,
          })
        )
      ),
  }) as SchemaOf<AddUnmanagedVendorMinimalFormFields>;
};

export const AddUnmanagedVendorFormMinimal = ({
  onClearCompanyNameSearchField,
  inlineApiErrorCodes,
  bannerApiErrorCodes,
  onSelectCompanyName,
  onSubmit,
  onSubmissionStateChange,
  shouldHideCompanyNameField,
  defaultValues,
  isSaving,
  titlesAs,
  isEmailRequired,
  emailRequiredMessage,
  ...props
}: AddVendorFormWidgetProps) => {
  const { formatMessage } = useMelioIntl();
  const isManaged = !!props.managed;
  const [isCompanyNameBlurred, setIsCompanyNameBlurred] = useState(false);
  const [isSearchBusinessesInDirectoriesSupported] = useFeature<boolean>(
    FeatureFlags.IsSearchBusinessesInDirectoriesSupported,
    false
  );

  const {
    settings: {
      vendor: {
        createVendor: { shouldCollectAccountNumberForUnmanagedVendor },
      },
    },
  } = useConfig();

  const { formProps, registerField, watch, setValue, reset, formState } =
    useVendorForm<AddUnmanagedVendorMinimalFormFields>({
      inlineApiErrorCodes,
      bannerApiErrorCodes,
      onSubmit: (data, _e, target) => onSubmit(getCreateVendorPayload(data), target),
      schema: useSchema({
        showCompanyField: !shouldHideCompanyNameField,
        isManaged,
        isEmailRequired,
        emailRequiredMessage,
      }),
      isSaving,
      defaultValues,
      onSubmissionStateChange,
    });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const companyNameInputProps = registerField('companyName');

  const { vendorNameBannerDescription, vendorNameConstraintType } = useVendorNameConstraints({
    vendorName: watch('companyName') ?? undefined,
    vendorNameFieldErrorType: companyNameInputProps?.error?.type,
    vendorNameFreeTextInputProps: isSearchBusinessesInDirectoriesSupported
      ? undefined
      : { isBlurred: isCompanyNameBlurred },
    isManaged,
    isFormSubmitted: formState.isSubmitted,
  });
  return (
    <Form rowGap="m" data-component="AddVendorFormWidget" {...props} {...formProps}>
      {!shouldHideCompanyNameField && (
        <Form.ContentBox>
          <Group spacing="xs" variant="vertical">
            <CompanyField
              onSelectCompanyName={onSelectCompanyName}
              onClearSearchField={() => {
                setValue('companyName', null);
                onClearCompanyNameSearchField?.();
              }}
              onBlur={() => setIsCompanyNameBlurred(true)}
              {...companyNameInputProps}
              autoFocus
            />
            {vendorNameBannerDescription && (
              <SectionBanner
                data-testid={`company-name-suggestion-${vendorNameConstraintType}`}
                description={vendorNameBannerDescription}
              />
            )}
          </Group>
        </Form.ContentBox>
      )}
      <Form.TextField
        {...registerField('fullName')}
        labelProps={{ label: formatMessage('widgets.vendors.fullName.label') }}
        placeholder={formatMessage('widgets.vendors.fullName.placeholder')}
        autoFocus={shouldHideCompanyNameField}
      />
      {shouldCollectAccountNumberForUnmanagedVendor && (
        <Form.TextField
          {...registerField('accountNumber')}
          labelProps={{
            label: formatMessage('widgets.vendors.accountNumber.label'),
            tooltipProps: {
              label: formatMessage('widgets.vendors.accountNumber.optional.labelTooltip'),
            },
          }}
          autoFocus={shouldHideCompanyNameField}
        />
      )}
      <Form.TextField
        {...registerField('email')}
        labelProps={{ label: formatMessage('widgets.vendors.email.label') }}
        placeholder={formatMessage('widgets.vendors.email.placeholder')}
      />
      <Form.PhoneField
        {...registerField('phone')}
        labelProps={{ label: formatMessage('widgets.vendors.phone.label') }}
        placeholder={formatMessage('widgets.vendors.phone.placeholder')}
      />
    </Form>
  );
};

AddUnmanagedVendorFormMinimal.displayName = 'AddVendorFormWidget';
