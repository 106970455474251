import { Form, FormSelectProps, useWatch } from '@melio/penny';
import { ApprovalWorkflowRequest, RoleUniqueNames } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

import { useFormContext } from '../../FormContext';
import { CollaboratorsSelector } from './CollaboratorsSelector';
import { RolesSelector } from './RolesSelector';

export const SchedulerCondition = ({ index }: { index: number }) => {
  const { formatMessage } = useMelioIntl();
  const { registerField, setValue, control } = useFormContext();
  const config = useWatch<ApprovalWorkflowRequest, `criteria.conditions.${number}.condition`>({
    name: `criteria.conditions.${index}.condition`,
    control,
  });

  const conditionTypesOptions: FormSelectProps['options'] = [
    {
      label: formatMessage('activities.create-approval-workflows.conditions.scheduler.options.specificUsers.title'),
      value: 'user',
      testId: 'scheduler-condition-type-user',
    },
    {
      label: formatMessage('activities.create-approval-workflows.conditions.scheduler.options.specificRoles.title'),
      value: 'role',
      testId: 'scheduler-condition-type-role',
      description: formatMessage(
        'activities.create-approval-workflows.conditions.scheduler.options.specificRoles.description'
      ),
    },
  ];

  const onSelectConfiguration = (value: React.ChangeEvent<HTMLInputElement>): void => {
    if (value.target.value === 'role') {
      setValue(
        `criteria.conditions.${index}.condition`,
        {
          type: 'workflowCollaboratorsCondition',
          configuration: {
            filterBy: 'role',
            roles: [RoleUniqueNames.MelioAccountant, RoleUniqueNames.MelioContributor],
          },
        },
        {
          shouldValidate: true,
        }
      );
    } else if (value.target.value === 'user') {
      setValue(
        `criteria.conditions.${index}.condition`,
        {
          type: 'workflowCollaboratorsCondition',
          configuration: {
            filterBy: 'user',
            ids: [],
          },
        },
        {
          shouldValidate: true,
        }
      );
    }
  };

  const shouldRenderCollaboratorsSelector =
    config?.type === 'workflowCollaboratorsCondition' && config?.configuration.filterBy === 'user';
  const shouldRenderRolesSelector =
    config?.type === 'workflowCollaboratorsCondition' && config?.configuration.filterBy === 'role';

  return (
    <>
      <Form.ContentBox colSpan={3} data-testid="scheduler-condition">
        <Form.Select
          {...registerField(`criteria.conditions.${index}.condition.configuration.filterBy`)}
          onChange={onSelectConfiguration}
          emptyState={{ label: '' }}
          formatSelectedValue={(option) => option.label.toLowerCase()}
          data-testid="scheduler-condition-type-list"
          size="large"
          aria-label="scheduler"
          options={conditionTypesOptions}
        />
      </Form.ContentBox>
      <Form.ContentBox colSpan={4}>
        {shouldRenderCollaboratorsSelector && <CollaboratorsSelector index={index} />}
        {shouldRenderRolesSelector && <RolesSelector index={index} />}
      </Form.ContentBox>
    </>
  );
};
