import { useCollaborators } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { ActionFormatter } from '../types';
import { formatList } from './formatters.utils';

export const useWorkflowActionsFormatter = () => {
  const { formatMessage, locale } = useMelioIntl();
  const { data: collaborators, isLoading } = useCollaborators({
    expand: ['user'],
    suspense: true,
    useErrorBoundary: true,
  });

  const format: ActionFormatter = ({ action, index }) => {
    if (action.type === 'role') {
      const numberOfApprovers = action.configuration.numberOfApprovers;

      return formatMessage('activities.settings.approval-workflow-settings.action.require-approval', {
        numberOfApprovers,
      });
    } else if (action.type === 'user') {
      const collaboratorNames: string[] = action.configuration.ids.map((id) => {
        const user = collaborators?.find((c) => c.userId === id)?.user;
        return formatMessage('activities.settings.approval-workflow-settings.formatter.collaborator.value.user', {
          firstName: user?.firstName,
          lastName: user?.lastName,
        });
      });
      const formattedUsersList = formatList(collaboratorNames, locale, 'conjunction');

      return formatMessage(
        index
          ? 'activities.settings.approval-workflow-settings.actions.require-approval.user.nth'
          : 'activities.settings.approval-workflow-settings.actions.require-approval.user.first',
        {
          users: formattedUsersList,
        }
      );
    }

    return '';
  };

  return { format, isLoading };
};
