import { Container, Group, IconButton } from '@melio/penny';

type Props = { onClick: VoidFunction; index: number };
export const DeleteAction = ({ onClick, index }: Props) => (
  <Group alignItems="flex-start" justifyContent="center" width="full" height="full">
    <Container paddingTop="xs">
      <IconButton
        aria-label="delete step"
        icon="delete"
        variant="naked"
        size="medium"
        onClick={onClick}
        data-testid={`approval-action-delete-step-${index}`}
      />
    </Container>
  </Group>
);
