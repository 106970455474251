import { useAnalytics } from '@melio/platform-analytics';
import { useVendor } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';
import { useSystemMessage } from '@melio/platform-utils';

import { getSkipVendorEmail, getVendorEmail } from '../single-payment/utils/scheduleFlowStepsUtils';
import { MemoToVendorActivityProps } from './types';

type UseMemoToVendorProps = Omit<MemoToVendorActivityProps, 'onClose'>;

export const useMemoToVendor = ({
  vendorId,
  onError,
  onDone,
  memo: currentMemo,
  defaultMemo,
  isUpdatingMemo,
}: UseMemoToVendorProps) => {
  const {
    isLoading: isLoadingVendorData,
    data: vendor,
    isMutating: isUpdatingVendor,
    update: updateVendor,
  } = useVendor({ id: vendorId });

  const { track } = useAnalytics();

  const { showMessage } = useSystemMessage();
  const {
    settings: {
      payment: {
        memoToVendor: { allowSendingEmailToVendorWhenAddingMemo },
      },
    },
  } = useConfig();

  const memoString = currentMemo || defaultMemo || '';
  const mode = currentMemo ? ('edit' as const) : ('add' as const);

  const handleFail = (error: PlatformError) => {
    showMessage({ type: 'error', title: error.message });
    onError?.(error);
  };

  const handleDone = (memo?: string, email?: string) => {
    const done = () => {
      track('NoteForVendor', 'Submitted');
      onDone(memo);
    };
    if (email) {
      updateVendor({ contact: { email } }).then(done).catch(handleFail);
    } else {
      done();
    }
  };

  const isSaving = isUpdatingVendor || isUpdatingMemo;
  const isLoading = isLoadingVendorData;

  const hideEmailField = !allowSendingEmailToVendorWhenAddingMemo || getSkipVendorEmail(vendor);

  const vendorName = vendor?.name || '';
  const vendorEmail = (!hideEmailField && getVendorEmail(vendor)) || '';

  return {
    hideEmailField,
    vendorName,
    vendorEmail,
    isSaving,
    mode,
    vendor,
    isLoading,
    isUpdatingVendor,
    handleDone,
    memoString,
  };
};
