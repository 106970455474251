import axios from 'axios';
import qs from 'qs';
import { PartnerName } from '@melio/platform-api';

import { BASE_PATH } from '../consts';

const axiosDemoInstance = axios.create();

export const createNewDemoAccount = (partnerName: PartnerName, accountId: string) =>
  axiosDemoInstance.post(`${BASE_PATH}/auth/demo`, {
    partnerName,
    accountId,
  });

export const createMockLink = ({
  partnerName,
  providerName,
  payload,
}: {
  partnerName: PartnerName;
  providerName: string;
  payload?: Record<string, unknown>;
}) =>
  axiosDemoInstance.post<{ link: string }>(`${BASE_PATH}/auth/providers/${providerName}/create-mock-link`, {
    providerName,
    partnerName,
    ...payload,
  });

export const authGoogleCreateNewDemoAccount = (
  googleAccessToken: string,
  partnerName: PartnerName,
  accountId: string,
) =>
  axiosDemoInstance.post(`${BASE_PATH}/auth/demo/google-create`, {
    googleAccessToken,
    partnerName,
    accountId,
  });

export const getGoogleSSOUrl = (partnerName: PartnerName, accountId: string) =>
  `${BASE_PATH}/auth/demo/redirect?${qs.stringify({
    state: JSON.stringify({ accountId, partnerName }),
  })}`;
