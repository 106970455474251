import { Plan, SubscriptionPreview } from '../api';

export type UseSubscriptionPreviewItemsProps = {
  plan: Plan;
  subscriptionPreview?: SubscriptionPreview;
};

export const useSubscriptionPreviewItems = ({ plan, subscriptionPreview }: UseSubscriptionPreviewItemsProps) => {
  const freeBillingItem = subscriptionPreview?.billingItems.find((item) => item.planId === plan.id && item.isFreeTrial);
  const paidBillingItem = subscriptionPreview?.billingItems.find(
    (item) => item.planId === plan.id && !item.isFreeTrial
  );

  const seatsBillingItem = subscriptionPreview?.billingItems.find(
    (item) => item.planId === plan.id && !item.isFreeTrial && item.measurementInfo?.measurementUnit === 'Seat'
  );

  const numberOfSeats = (seatsBillingItem?.measurementInfo?.quantity || 0) - plan.numberOfFreeUsers;

  return {
    ...(freeBillingItem && {
      freeTrialData: {
        freeBillingItem,
        chargingAmount: subscriptionPreview?.amount,
      },
    }),
    paidBillingItem,
    paidSeatsCount: Math.max(numberOfSeats, 0),
  };
};
