import { useSubscriptionContext } from '../context';

export const useIsSubscriptionBillingPayorIsFirm = () => {
  const { plans, subscription } = useSubscriptionContext();

  if (!subscription) {
    return false;
  }

  const currentPlan = plans?.find((plan) => plan.id === subscription.planId);

  if (!currentPlan) {
    return false;
  }

  const isFreePlan = currentPlan.cycles.monthly.basePrice === 0;

  if (isFreePlan) {
    return false;
  }

  // in case the billed organization is external, the subscription doesn't hold the funding source.
  // The only organization that can pay for another organization is a firm
  const isSubscriptionPaidByFirm = !subscription.fundingSourceId;

  return isSubscriptionPaidByFirm;
};
