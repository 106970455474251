import { EbillsSubscriptionOptionsField, EbillsSubscriptionOptionsReceivingOptionNameEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { boolean, object, SchemaOf, string, StringSchema } from 'yup';

import { EbillsSubscriptionFormFields } from './types';
import { formatFieldName } from './utils';

export const useEBillsSusbscriptionFormSchema = ({
  fields,
  isAddressRequired,
}: {
  fields: EbillsSubscriptionOptionsField[];
  isAddressRequired: boolean;
}): SchemaOf<EbillsSubscriptionFormFields> => {
  const { formatMessage } = useMelioIntl();

  const fieldSchemas: Record<string, SchemaOf<string>> = fields.reduce(
    (acc, field) => ({
      ...acc,
      [formatFieldName(field.name)]: field.regularExpression
        ? string()
            .trim()
            .required(
              formatMessage('activities.ebillsSubscriptionForm.form.dynamicFields.errors.required', {
                fieldName: field.name,
              })
            )
            .matches(
              new RegExp(field.regularExpression),
              formatMessage('activities.ebillsSubscriptionForm.form.errors.invalid')
            )
            .nullable()
        : string()
            .trim()
            .required(
              formatMessage('activities.ebillsSubscriptionForm.form.dynamicFields.errors.required', {
                fieldName: field.name,
              })
            )
            .nullable(),
    }),
    {}
  );

  const nameSchema = object({
    firstName: string()
      .trim()
      .required(formatMessage('activities.ebillsSubscriptionForm.form.errors.firstName.required')),
    middleName: string().trim().nullable(),
    lastName: string()
      .trim()
      .required(formatMessage('activities.ebillsSubscriptionForm.form.errors.lastName.required')),
  });

  const addressSchema = object({
    line1: string().required(),
    line2: string().notRequired(),
    city: string().required(),
    state: string().required(),
    postalCode: string().required(),
  }).nullable();

  return object().shape({
    address: isAddressRequired
      ? addressSchema.required(formatMessage('activities.ebillsSubscriptionForm.form.errors.address.required'))
      : addressSchema,
    name: isAddressRequired ? nameSchema : nameSchema.nullable(),
    receivingOption: new StringSchema<EbillsSubscriptionOptionsReceivingOptionNameEnum>()
      .nullable()
      .oneOf(Object.values(EbillsSubscriptionOptionsReceivingOptionNameEnum))
      .required(formatMessage('activities.ebillsSubscriptionForm.form.errors.receivingOptions.required')),
    acceptedTerms: boolean()
      .required()
      .oneOf([true], formatMessage('activities.ebillsSubscriptionForm.form.errors.terms')),
    fields: object(fieldSchemas),
  }) as SchemaOf<EbillsSubscriptionFormFields>;
};
