import { FieldValues, UseMelioFormResults } from '@melio/penny';
import { useEffect } from 'react';

type UseFocusErrorStepProps<T extends FieldValues> = {
  formState: UseMelioFormResults<T>['formState'];
  stepFields: Array<keyof T>;
  focusErrorStep: (error: boolean) => void;
};

export const useFocusStepOnError = <T extends FieldValues>({
  formState: { submitCount, errors },
  stepFields,
  focusErrorStep,
}: UseFocusErrorStepProps<T>) => {
  useEffect(() => {
    if (!submitCount) {
      return;
    }
    const filteredErrors = stepFields.reduce<Partial<Record<keyof T, boolean>>>((acc, field) => {
      if (errors[field]) {
        acc[field] = true;
      }
      return acc;
    }, {});

    const hasErrorsInStep = Object.keys(filteredErrors).length > 0;
    focusErrorStep(hasErrorsInStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, submitCount]);
};
