import React from 'react';
import { Box } from '@chakra-ui/react';
import { Text } from '@melio/penny';

type payBillCardHeaderProps = {
  title: string;
  description: string;
};

export const PayBillCardHeader = ({ description, title }: payBillCardHeaderProps) => {
  return (
    <>
      <Box marginBottom={2}>
        <Text textStyle="heading2Semi">{title}</Text>
      </Box>
      <Box marginBottom={10}>
        <Text color="global.neutral.800">{description}</Text>
      </Box>
    </>
  );
};
