import { MouseEventHandler } from 'react';
import { useVendorActions } from '@melio/ap-domain';
import { Group, Icon, Link, StackedContainer, Text, Typography } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { Vendor, VendorAutoPayStatusEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { useRouter } from '@/hooks/router.hooks';

type VendorAutoPaySectionProps = {
  vendor: Vendor;
};

export const VendorAutoPaySection = ({ vendor }: VendorAutoPaySectionProps) => {
  const { formatMessage } = useMelioIntl();
  const { goToAutoPaymentActivation } = useRouter();
  const { track } = useAnalytics();
  const vendorActions = useVendorActions(vendor);

  const onAutoPaySetup: MouseEventHandler<HTMLLinkElement> = (e) => {
    track('Vendor', 'Click', {
      Cta: 'autopay-set-up',
      AutoPayStatus: vendor.autoPayStatus,
      VendorId: vendor.id,
    });
    e.preventDefault();
    e.stopPropagation();
    goToAutoPaymentActivation(vendor.id);
  };

  const description = () => {
    switch (vendor.autoPayStatus) {
      case VendorAutoPayStatusEnum.Capable:
        return formatMessage('widgets.vendorDrawer.ebillSection.autoPayment.capable.description');

      case VendorAutoPayStatusEnum.Active:
        return formatMessage('widgets.vendorDrawer.ebillSection.autoPayment.active.description');

      case VendorAutoPayStatusEnum.NotCapable:
      default:
        return formatMessage('widgets.vendorDrawer.ebillSection.autoPayment.notCapable.description');
    }
  };

  return (
    <StackedContainer
      stacksToDisplay={0}
      paddingX="m"
      paddingY="m"
      data-testid="widgets-vendorDrawer-ebillSection-autoPayment"
    >
      <Group variant="vertical" spacing="xs">
        <Group alignItems="center" justifyContent="space-between">
          <Group alignItems="center" spacing="xxs">
            <Text as="h4" textStyle="body2Semi">
              {formatMessage('widgets.vendorDrawer.ebillSection.autoPayment.title')}
            </Text>
            {vendor.autoPayStatus === VendorAutoPayStatusEnum.Active ? (
              <Text color="global.success.700">
                <Group alignItems="center">
                  <Icon
                    size="small"
                    aria-label={formatMessage('widgets.vendorDrawer.ebillSection.autoPayment.setup.checked')}
                    role="image"
                    type="checked-circle"
                    color="inherit"
                  />
                </Group>
              </Text>
            ) : null}
          </Group>
          {vendorActions.autoPayActivation && (
            <Link
              data-testid="widgets-vendorDrawer-ebillSection-autoPayment-setup-button"
              href="#"
              onClick={onAutoPaySetup}
              label={formatMessage('widgets.vendorDrawer.ebillSection.autoPayment.setup.button')}
              aria-label={formatMessage('widgets.vendorDrawer.ebillSection.autoPayment.setup.button.aria')}
              size="medium"
              isBold
              color="secondary"
              variant="standalone"
            />
          )}
        </Group>
        <Group>
          <Typography.Description
            data-testid="widgets-vendorDrawer-ebillSection-autoPayment-description"
            label={description()}
          />
        </Group>
      </Group>
    </StackedContainer>
  );
};
