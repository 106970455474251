import { getDeliveryMethodDisplayText, useGetDeliveryMethodDisplayText } from '@melio/ap-domain';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { DeliveryMethod, Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { getPaymentAnalyticsFields } from '../../../../utils';
import {
  ChooseHowToResendScreen,
  ChooseHowToResendScreenProps,
} from '../screens/ChooseHowToResend/ChooseHowToResend.screen';

type Props = Omit<ChooseHowToResendScreenProps, 'vendorName' | 'paperCheckAddress' | 'descriptionLabel'> & {
  payment: Payment;
  paperCheckDeliveryMethod: DeliveryMethod;
  bankAccountDeliveryMethod?: DeliveryMethod;
};

export const ChooseHowToResendStep = withAnalyticsContext<Props>(
  ({
    payment,
    paperCheckDeliveryMethod,
    bankAccountDeliveryMethod,
    setAnalyticsProperties,
    onClose: _onClose,
    onResendPaperCheck: _onResendPaperCheck,
    onSwitchToBankTransfer: _onSwitchToBankTransfer,
    ...props
  }) => {
    const { formatMessage } = useMelioIntl();
    const { createTrackHandler } = useAnalytics();

    setAnalyticsProperties({
      ...getPaymentAnalyticsFields(payment),
      Intent: 'choose-delivery-method-type',
      EntryPoint: 'dashboard-payment',
      Flow: 'void-and-resend-check',
      PageName: 'choose-how-to-resend-this-payment-to-vendor',
      EligibilityType: 'ach_check',
    });

    useAnalyticsView('Payment', true, true);

    const trackAndHandleClick = createTrackHandler<{
      Cta: 'exit' | 'resend-a-paper-check' | 'switch-to-bank-transfer';
    }>('Payment', 'Click');

    const onClose = () => trackAndHandleClick({ Cta: 'exit' }, _onClose);
    const onResendPaperCheck = () => trackAndHandleClick({ Cta: 'resend-a-paper-check' }, _onResendPaperCheck);
    const onSwitchToBankTransfer = () =>
      trackAndHandleClick({ Cta: 'switch-to-bank-transfer' }, _onSwitchToBankTransfer);

    const vendorName = payment.vendor?.name ?? '';
    const paperCheckAddress = useGetDeliveryMethodDisplayText(paperCheckDeliveryMethod, vendorName);
    const achDetails =
      bankAccountDeliveryMethod && getDeliveryMethodDisplayText(bankAccountDeliveryMethod, vendorName, formatMessage);

    return (
      <ChooseHowToResendScreen
        {...props}
        onSwitchToBankTransfer={onSwitchToBankTransfer}
        onResendPaperCheck={onResendPaperCheck}
        onClose={onClose}
        paperCheckAddress={paperCheckAddress}
        vendorName={vendorName}
        achDetails={achDetails}
      />
    );
  }
);
