import { useCollectionApi } from '@melio/api-client';
// eslint-disable-next-line no-restricted-imports
import { AccountantsFirmClientApiClient, FirmClientExpandedExpandableFields } from '@melio/platform-api-axios-client';

type QueryFn = typeof AccountantsFirmClientApiClient.getAccountantFirmClients;

const fullyExpandedFields: FirmClientExpandedExpandableFields[] = [
  'firmMembers',
  'organization.logoUrl',
  'organization.billsAndPaymentsOverview',
  'subscriptionInfo',
];

export const useAccountingFirmClientsExpanded = () =>
  useCollectionApi<QueryFn, never, never, never>({
    queryKey: ['AccountantsFirmClientApi', 'collection', 'expanded'],
    queryFn: () => AccountantsFirmClientApiClient.getAccountantFirmClients(fullyExpandedFields),
  });

export const useAccountingFirmClientsNonExpanded = () =>
  useCollectionApi<QueryFn, never, never, never>({
    queryKey: ['AccountantsFirmClientApi', 'collection', 'bare-bones'],
    queryFn: () => AccountantsFirmClientApiClient.getAccountantFirmClients('none'),
  });
