import { useAccount } from '@melio/platform-api';
import { useState } from 'react';

import { useSubscriptionContext } from '../../../context';
import { BannerDetails } from './BannerDetails';

export const SESSION_STORAGE_GRACE_BANNER_CLOSED = 'grace_period_banner_closed';

export const SubscriptionGracePeriodBanner = () => {
  const { data: dataAccountMe } = useAccount({ id: 'me' });

  const storageKey = `${SESSION_STORAGE_GRACE_BANNER_CLOSED}-${dataAccountMe?.organizationId || ''}`;
  const isBannerManuallyClosed = sessionStorage.getItem(storageKey);

  const {
    gracePeriod: { isEligibleForGracePeriod, isTodayInGracePeriod },
  } = useSubscriptionContext();

  const [isOpen, setIsOpen] = useState(!isBannerManuallyClosed && isEligibleForGracePeriod && isTodayInGracePeriod);

  const handleOnClose = () => {
    sessionStorage.setItem(storageKey, 'closed');
    setIsOpen(false);
  };

  if (!isOpen) {
    return null;
  }

  return <BannerDetails onClose={handleOnClose} />;
};
