import { withAnalyticsContext } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';

import { ErrorType } from '../../types';
import { GuestPaymentRequestErrorScreen } from './screens';

type GuestPaymentRequestErrorsActivityProps = {
  paymentRequestLink: string;
  errorType: ErrorType | undefined;
};

export const GuestPaymentRequestErrorsActivity = withAnalyticsContext<GuestPaymentRequestErrorsActivityProps>(
  forwardRef(({ setAnalyticsProperties, paymentRequestLink, errorType }, ref) => {
    setAnalyticsProperties({
      ProductName: 'ar',
      Flow: 'payment-request',
      PaymentRequestLink: paymentRequestLink,
    });

    return <GuestPaymentRequestErrorScreen errorType={errorType} ref={ref} />;
  })
);

GuestPaymentRequestErrorsActivity.displayName = 'GuestPaymentRequestErrorsActivity';
