import { ModalEvents, useToast } from '@melio/penny';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useEffect } from 'react';

export function useAutoCloseToast(current: string, options?: { keepToast?: boolean }) {
  const [isPlatformA11yAutoCloseToastOnNavigationEnabled] = useFeature(
    FeatureFlags.PlatformA11yAutoCloseToastOnNavigation,
    false,
    { shouldTrack: false }
  );
  const { closeToast } = useToast();
  useEffect(() => {
    if (!options?.keepToast && isPlatformA11yAutoCloseToastOnNavigationEnabled) {
      closeToast();
    }
    const onModalOpen = () => {
      closeToast();
    };
    if (isPlatformA11yAutoCloseToastOnNavigationEnabled) {
      document.addEventListener(ModalEvents.OPENED, onModalOpen);
    }

    return () => document.removeEventListener(ModalEvents.OPENED, onModalOpen);
  }, [closeToast, current, options, isPlatformA11yAutoCloseToastOnNavigationEnabled]);
}
