import { SelectOption } from '@melio/penny';
import { BusinessResultItem } from '@melio/platform-api';

export const getBusinessesOptions = (businesses: BusinessResultItem[]): SelectOption[] =>
  businesses.map((option) => ({
    label: option.business.name,
    value: option.business.self,
    avatarProps: {
      name: option.business.name,
      src: option.business.logoUri,
    },
  }));
