import { ARIndustry, BusinessStructure, useMelioIntl } from '@melio/ar-domain';
import { Form, GridItem, SectionBanner } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { useBusinessDetailsFormContext } from '../../../utils';
import { useBusinessDetailsState } from '../hooks';
import { AddressDetailsFormSection } from './AddressDetailsFormSection';
import { FormSection } from './FormSection';

type BusinessDetailsFormSectionProps = {
  industries?: ARIndustry[];
};

export const BusinessDetailsFormSection = forwardRef<BusinessDetailsFormSectionProps>(
  ({ industries = [], ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { registerField } = useBusinessDetailsFormContext();

    const { shouldShowIrsName, shouldShowDeliveryAddressFields, industrySubCategoryOptions, industryValue } =
      useBusinessDetailsState({
        industries,
      });

    return (
      <FormSection
        ref={ref}
        title={formatMessage('ar.onboarding.activities.businessDetails.form.sections.businessDetails.label')}
        data-testid="form-section-business-details"
        {...props}
        columns={4}
      >
        <GridItem colSpan={4}>
          <SectionBanner
            data-testid="onboarding-business-details-section-banner-info"
            variant="informative"
            description={formatMessage(
              'ar.onboarding.activities.businessDetails.form.sections.businessDetails.sectionBanner.info',
              { FirstFinancial: 'FirstFinancial', phone: '1-800-xxxxxxx' }
            )}
          />
        </GridItem>
        <Form.TextField
          placeholder={formatMessage('ar.onboarding.activities.businessDetails.form.inputs.businessName.placeHolder')}
          {...registerField('legalName')}
          colSpan={4}
          labelProps={{
            label: formatMessage('ar.onboarding.activities.businessDetails.form.inputs.businessName.label'),
          }}
        />
        <Form.Checkbox
          colSpan={4}
          {...registerField('isBusinessNameConsistent')}
          label={formatMessage('ar.onboarding.activities.businessDetails.form.fields.isLegal.label.text')}
        />
        {shouldShowIrsName ? (
          <Form.TextField
            placeholder={formatMessage('ar.onboarding.activities.businessDetails.form.inputs.irsName.placeHolder')}
            {...registerField('irsLegalName')}
            colSpan={4}
            labelProps={{
              label: formatMessage('ar.onboarding.activities.businessDetails.form.inputs.irsName.label'),
            }}
          />
        ) : null}
        <GridItem colSpan={4}>
          <AddressDetailsFormSection
            addressKey="legalAddress"
            columns={4}
            addressLabel={formatMessage('ar.onboarding.activities.businessDetails.form.businessAddress.label')}
            placeholder={formatMessage(
              'ar.onboarding.activities.businessDetails.form.inputs.businessAddress.placeHolder'
            )}
          />
        </GridItem>
        <Form.Checkbox
          colSpan={4}
          {...registerField('isDeliveryAddressConsistent')}
          label={formatMessage(
            'ar.onboarding.activities.businessDetails.form.fields.isDeliveryAddressConsistent.label.text'
          )}
        />
        {shouldShowDeliveryAddressFields ? (
          <GridItem colSpan={4} hidden={!shouldShowDeliveryAddressFields}>
            <AddressDetailsFormSection
              addressKey="deliveryAddress"
              columns={4}
              addressLabel={formatMessage('ar.onboarding.activities.businessDetails.form.deliveryAddress.label')}
              placeholder={formatMessage(
                'ar.onboarding.activities.businessDetails.form.inputs.businessDeliveryAddress.placeHolder'
              )}
            />
          </GridItem>
        ) : null}

        <Form.Select
          emptyState={{
            label: formatMessage(
              'ar.onboarding.activities.businessDetails.form.inputs.businessStructure.emptyState.label'
            ),
          }}
          labelProps={{
            label: formatMessage('ar.onboarding.activities.businessDetails.form.inputs.businessStructure.label'),
          }}
          placeholder={formatMessage(
            'ar.onboarding.activities.businessDetails.form.inputs.businessStructure.placeHolder'
          )}
          colSpan={2}
          {...registerField('businessStructure')}
          options={Object.values(BusinessStructure).map((value) => ({
            label: value,
            value,
          }))}
        />
        <Form.DateField
          {...registerField('dateIncorporated')}
          labelProps={{
            label: formatMessage('ar.onboarding.activities.businessDetails.form.inputs.dateIncorporated.label'),
          }}
          colSpan={2}
        />
        <Form.Select
          emptyState={{
            label: formatMessage('ar.onboarding.activities.businessDetails.form.inputs.industry.emptyState.label'),
          }}
          labelProps={{
            label: formatMessage('ar.onboarding.activities.businessDetails.form.inputs.industry.label'),
          }}
          placeholder={formatMessage('ar.onboarding.activities.businessDetails.form.inputs.industry.placeHolder')}
          colSpan={4}
          {...registerField('industry')}
          options={industries.map((industry) => ({
            label: industry.name,
            value: industry.id.toString(),
          }))}
        />
        <Form.Select
          emptyState={{
            label: formatMessage(
              'ar.onboarding.activities.businessDetails.form.inputs.industrySubCategory.emptyState.label'
            ),
          }}
          labelProps={{
            label: formatMessage('ar.onboarding.activities.businessDetails.form.inputs.industrySubCategory.label'),
          }}
          placeholder={formatMessage(
            'ar.onboarding.activities.businessDetails.form.inputs.industrySubCategory.placeHolder'
          )}
          colSpan={4}
          {...registerField('industrySubCategory')}
          options={industrySubCategoryOptions}
          isDisabled={!industryValue}
        />
      </FormSection>
    );
  }
);

BusinessDetailsFormSection.displayName = 'BusinessDetailsFormSection';
