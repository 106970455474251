import { useAnalytics } from '@melio/platform-analytics';

import { TaxPayerInfoForm, TaxPayerInfoFormProps } from './TaxPayerInfoForm';

export const TaxPayerInfoFormWidget = (props: TaxPayerInfoFormProps) => {
  const { track } = useAnalytics();

  const handleSuccessUploadW9File = () => {
    track('Vendor', 'Status', {
      Intent: 'upload-w9-file',
      TaxId: 'file',
      Status: 'success',
    });
  };

  const handleSelectAndUploadFile = () => {
    track('Vendor', 'Click', {
      Intent: 'upload-w9-file',
      TaxId: 'file',
      Cta: 'upload-w9-file',
    });
  };

  return (
    <TaxPayerInfoForm
      onSuccessUploadW9File={handleSuccessUploadW9File}
      onSelectAndUploadFile={handleSelectAndUploadFile}
      {...props}
    />
  );
};
