import {
  JoinOrganizationRequestRole,
  PostJoinOrganizationRequestsRequest,
  PostOrganizationsOnBehalfRequest,
} from '@melio/platform-api';

import { CompanyDetailsFormWidgetFields } from '../CompanyDetails/widgets';
import { OwnerDetailsFormWidgetFields } from './widgets';

type CreateOrganizationOnBehalfProps = CompanyDetailsFormWidgetFields & OwnerDetailsFormWidgetFields;

export const buildCreateOrganizationOnBehalfParams = ({
  contactFirstName,
  contactLastName,
  contactEmail,
  phone,
  name,
  address,
}: CreateOrganizationOnBehalfProps): PostOrganizationsOnBehalfRequest => ({
  companyName: name,
  address,
  contactFirstName,
  contactLastName,
  contactEmail,
  phoneNumber: phone,
  role: 'admin',
});

type CreateJoinOrganizationRequestProps = CompanyDetailsFormWidgetFields & OwnerDetailsFormWidgetFields;

export const buildCreateJoinOrganizationRequestParams = ({
  name,
  address,
  contactFirstName,
  contactLastName,
  contactEmail,
  phone,
}: CreateJoinOrganizationRequestProps): PostJoinOrganizationRequestsRequest => ({
  role: JoinOrganizationRequestRole.Admin,
  companyName: name,
  address,
  firstName: contactFirstName,
  lastName: contactLastName,
  email: contactEmail,
  phone,
});
