import { Container, Group, OnSubmissionStateChange, useBreakpoint } from '@melio/penny';
import { FundingSourceType } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { Logger } from '@melio/platform-logger';

import { CardFundingSourceForm, CardFundingSourceFormProps } from '../../CardFundingSourceForm/CardFundingSourceForm';
import { FundingSourceTypeCard } from '../../CardFundingSourceForm/FundingSourceTypeCard';
import { CardFundingSourceFormFields } from '../../CardFundingSourceForm/types';

export type AddNewFundingSourceViewProps = {
  onSubmit: CardFundingSourceFormProps['onSubmit'];
  fundingSourceType: FundingSourceType;
  onClose: VoidFunction;
  onAddBank: VoidFunction;
  onAddCard: VoidFunction;
  hasFundingSources: boolean;
  isVerifyingCard: boolean;
};
export const AddNewFundingSourceView = ({
  onSubmit,
  fundingSourceType,
  onClose,
  onAddBank,
  onAddCard,
  isVerifyingCard,
  hasFundingSources,
}: AddNewFundingSourceViewProps) => {
  const { formatMessage } = useMelioIntl();
  const { isExtraSmallScreen } = useBreakpoint();

  const onSubmissionStateChange: OnSubmissionStateChange<CardFundingSourceFormFields> = () => {
    Logger.log(`Submission state changed`, 'debug');
  };

  return (
    <Container border="regular" paddingX="m" paddingY="m">
      <Group variant="vertical" spacing="l" width="full">
        {hasFundingSources ? null : (
          <Group variant={isExtraSmallScreen ? 'vertical' : 'horizontal'}>
            <FundingSourceTypeCard
              data-testid="funding-source-type-card"
              isSelected={fundingSourceType === FundingSourceType.Card}
              label={formatMessage('activities.subscription.checkout.fundingSourceSelection.sourceType.card.label')}
              icon="credit-card"
              onClick={onAddCard}
            />
            <FundingSourceTypeCard
              data-testid="funding-source-type-bank-account"
              isSelected={fundingSourceType === FundingSourceType.BankAccount}
              label={formatMessage('activities.subscription.checkout.fundingSourceSelection.sourceType.bank.label')}
              icon="bank"
              onClick={onAddBank}
            />
          </Group>
        )}
        {fundingSourceType === FundingSourceType.Card && (
          <CardFundingSourceForm
            onSubmit={onSubmit}
            showCloseButton={hasFundingSources}
            onCloseForm={onClose}
            isLoading={isVerifyingCard}
            onSubmissionStateChange={onSubmissionStateChange}
          />
        )}
      </Group>
    </Container>
  );
};
