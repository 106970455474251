import { useFormSubmissionController } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';

import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';
import { CompanyDetailsFormWidget, CompanyDetailsFormWidgetFields } from './widgets';

export type CompanyDetailsScreenOnDone = (data: CompanyDetailsFormWidgetFields) => void;

type Props = {
  defaultFields?: Partial<CompanyDetailsFormWidgetFields>;
  isSaving: boolean;
  onBack: VoidFunction;
  onClose: VoidFunction;
  onDone: CompanyDetailsScreenOnDone;
};

export const CompanyDetailsScreen = withAnalyticsContext<Props>(
  ({ defaultFields, onBack, onClose, onDone, isSaving, setAnalyticsProperties, ...props }) => {
    const { formatMessage } = useMelioIntl();
    const { track } = useAnalytics();
    setAnalyticsProperties({
      PageName: 'add-company-details',
    });

    const trackClick = (cta: string, isCompanyOwner?: boolean) =>
      track(`User`, 'Click', {
        Cta: cta,
        ...(isCompanyOwner ? { CompanyOwner: isCompanyOwner } : {}),
      });

    useEffect(() => {
      track('User', 'View');
    }, [track]);

    const { onSubmissionStateChange, cancelButtonProps, submitButtonProps } =
      useFormSubmissionController<CompanyDetailsFormWidgetFields>();

    const onSubmit = (formData: CompanyDetailsFormWidgetFields) => {
      trackClick('continue', formData.isOwner);
      onDone(formData);
    };

    const handleClose = () => {
      trackClick('exit');
      onClose();
    };

    return (
      <NewSinglePaymentStepLayout
        data-component="AddCompanyActivity.CompanyDetailsScreen"
        data-testid="add-company-activity-company-details-screen"
        {...props}
        headerContent={
          <NewSinglePaymentStepLayout.Header>
            <NewSinglePaymentStepLayout.CloseButton onClick={handleClose} />
            <NewSinglePaymentStepLayout.ProgressBar currentStep={1} totalSteps={2} />
            <NewSinglePaymentStepLayout.BackButton {...cancelButtonProps} onClick={onBack} />
          </NewSinglePaymentStepLayout.Header>
        }
        footerContent={
          <NewSinglePaymentStepLayout.Actions>
            <NewSinglePaymentStepLayout.NextButton
              {...submitButtonProps}
              label={formatMessage('activities.addCompany.screens.companyDetails.continue')}
            />
          </NewSinglePaymentStepLayout.Actions>
        }
      >
        <NewSinglePaymentStepLayout.Title>
          {formatMessage('activities.addCompany.screens.companyDetails.title')}
        </NewSinglePaymentStepLayout.Title>
        <NewSinglePaymentStepLayout.Content>
          <CompanyDetailsFormWidget
            defaultFields={defaultFields}
            onSubmit={onSubmit}
            onSubmissionStateChange={onSubmissionStateChange}
            isSaving={isSaving}
          />
        </NewSinglePaymentStepLayout.Content>
      </NewSinglePaymentStepLayout>
    );
  }
);

CompanyDetailsScreen.displayName = 'AddCompanyActivity.CompanyDetailsScreen';
