import { SubscriptionBillingPayor } from '../../types';

export enum NewFirmClientFormSteps {
  BasicDetails = 'basic-details',
  BillingDetails = 'billing-details',
  PlanSelection = 'plan-selection',
  ReviewAndConfirm = 'review-and-confirm',
}

export type NewFirmClientFormStepDetails = {
  title: string;
  description: string;
  stepId: NewFirmClientFormSteps;
  component: React.FunctionComponent<StepFormProps>;
};

export type NewFirmClientFormFields = {
  businessName: string;
  businessDBA: string;
  shouldInviteClient: boolean;
  clientEmailAddress: string;
  clientFirstName: string;
  clientLastName: string;
  whoPays: SubscriptionBillingPayor;
  fundingSourceId: string;
  subscriptionPlanId?: string;
};

export type StepFormProps = {
  submitLabel: string;
  focusErrorStep: (error: boolean) => void;
  onContinue: () => void;
  isSaving: boolean;
};
